import React, { FormEvent } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { SiteUrls } from '../urls'
import { AppOptions } from '../utils/AppOptions'
import { SiteFooter } from './SiteFooter'

interface Props {
  onSubmitLogin: (email: string, password: string) => void
}

const SITE_FOOTER_HEIGHT = 60

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - ${SITE_FOOTER_HEIGHT}px);
`
const Inner = styled.div`
  width: 289px;
  padding-top: 150px;
  margin: 0 auto 0;
`
const FormWrapper = styled.form`
  margin: 0 auto;
  width: 286px;
  display: flex;
  flex-direction: column;
`
const InputTitle = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00c5c3;
  margin: 25px 0 13px 0;
`
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #c8ced3;
`
const Icon = styled.i`
  width: 16px;
  padding-top: 5px;
  margin-left: 2px;
`
const Input = styled.input`
  width: 100%;
  margin: 0 auto;
  border: 0;
  padding-left: 35px;
  color: #2f353a;
  font-size: 18px;
`
const LoginButton = styled.button`
  background-color: #00c5c3;
  border: 0;
  width: 100%;
  height: 57px;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
  line-height: 57px;
`

const FILL_MOCK_DATA = AppOptions.FILL_MOCK_DATA

export const AdminLoginPage: React.FC<Props> = ({ onSubmitLogin }) => {
  const [email, setEmail] = React.useState(FILL_MOCK_DATA ? 'storyplay@odcode.com' : '')
  const [password, setPassword] = React.useState(FILL_MOCK_DATA ? '12345678a!' : '')

  const onClickLogin = () => onSubmitLogin(email, password)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Wrapper>
        <Inner>
          <FormWrapper onSubmit={handleSubmit}>
            <InputTitle>Email</InputTitle>
            <InputWrapper>
              <Icon className="cil-envelope-closed" />
              <Input
                type="text"
                placeholder="Email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </InputWrapper>
            <InputTitle>Password</InputTitle>
            <InputWrapper>
              <Icon className="cil-lock-locked" />
              <Input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </InputWrapper>
            <Link
              to={SiteUrls.Admin.FindPassword}
              style={{
                float: 'right',
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <Button
                type="button"
                color="link"
                className="px-0"
                style={{
                  fontFamily: 'NotoSansKR',
                  fontSize: '12px',
                  color: '#c6c6c6',
                  marginTop: '16px',
                }}
              >
                Forgot password?
              </Button>
            </Link>
            <LoginButton color="#00c5c3" onClick={onClickLogin}>
              Login
            </LoginButton>
          </FormWrapper>
        </Inner>
      </Wrapper>
      <SiteFooter wrapperStyle={{ height: '60px', margin: '0px 30px' }} />
    </div>
  )
}
