"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2Editor = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const errors_1 = require("../../../../errors");
const EntityEditor_1 = require("../EntityEditor");
const fields_1 = require("../fields");
const DBFileFieldWithUI_1 = require("../fields/DBFileFieldWithUI");
const playerReportContainerHTML_1 = require("./playerReportContainerHTML");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOPlayerReport2Editor extends EntityEditor_1.EntityEditor {
    constructor(report, ide) {
        super(report);
        this.ogTitleField = null;
        this.ogDescField = null;
        this.ogImageFileField = null;
        this.renderError = null;
        this.outputHTML = null;
        this.ide = ide;
        const stringFields = [
            {
                key: 'name',
                options: {
                    label: trans('legacy_DOPlayerReport2Editor_Player_Report_Name'),
                    placeholder: trans('legacy_DOPlayerReport2Editor_Input_your_name_please'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'sampleData',
                options: {
                    label: trans('legacy_DOPlayerReport2Editor_Sample_data'),
                    placeholder: trans('legacy_DOPlayerReport2Editor_Please_paste_the_sample_data_from_the_developer'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'code',
                options: {
                    label: trans('legacy_DOPlayerReport2Editor_Player_Report_Template_Code'),
                    placeholder: trans('legacy_DOPlayerReport2Editor_Please_fill_in_the_code'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'ogTitle',
                options: {
                    label: trans('legacy_SPABannerList_title'),
                    placeholder: trans('legacy_DOPlayerReport2Editor_Please_enter_the_subject'),
                    options: { noLabel: false },
                },
            },
            {
                key: 'ogDesc',
                options: {
                    label: trans('legacy_DOPlayerReport2Editor_explanation'),
                    placeholder: trans('legacy_DOPlayerReport2Editor_Please_enter_the_description'),
                    inputType: 'textarea',
                    options: { noLabel: false },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new fields_1.TextFieldWithUI(fld.key, () => { var _a; return (_a = report[fld.key]) !== null && _a !== void 0 ? _a : ''; }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        this.nameField.input.setRequired(true);
        this.nameField.input.setAutoFocus();
        const fileFields = [
            {
                key: 'ogImage',
                options: {
                    label: trans('legacy_ods_image'),
                    options: { showLabel: true },
                },
            },
        ];
        fileFields.forEach(fld => {
            ;
            this[`${fld.key}FileField`] = new DBFileFieldWithUI_1.DBFileFieldWithUI(`${fld.key}File`, () => {
                const link = report[`${fld.key}Link`];
                return { link };
            }, () => null, fld.options);
        });
        (0, mobx_1.makeObservable)(this, {
            renderError: mobx_1.observable,
            outputHTML: mobx_1.observable,
            updatedAt: mobx_1.computed,
        });
    }
    get updatedAt() {
        return this._target.updatedAt;
    }
    get prId() {
        return this._target.serverId;
    }
    get nunjucks() {
        return this.ide.env;
    }
    get playContext() {
        // tslint:disable-next-line:no-eval
        let o = JSON.parse(this.sampleDataField.value || '{}');
        if (!(0, lodash_1.isObject)(o)) {
            (0, mobx_1.runInAction)(() => (this.renderError = new errors_1.SPCError(errors_1.ErrorCode.PLAYER_REPORT2_SAMPLE_DATA_INVALID)));
            o = {};
        }
        return o;
    }
    get context() {
        const play = this.playContext;
        return {
            play,
            rb: (templateName, param) => {
                const renderBlock = this.ide.renderBlockStore.getByName(templateName);
                if (!renderBlock) {
                    throw new Error(trans('legacy_DOPlayerReport2Editor_Rendable_blocks_cannot_be_found', { value: templateName }));
                }
                return this.nunjucks.renderString(renderBlock.code, {
                    play,
                    param,
                });
            },
        };
    }
    generateSampleHTML() {
        (0, mobx_1.runInAction)(() => {
            var _a, _b, _c;
            const code = this.codeField.value;
            this.renderError = null;
            try {
                this.nunjucks.addGlobal('play', this.playContext);
                const html = this.nunjucks.renderString(code, this.context);
                this.outputHTML = (0, playerReportContainerHTML_1.createPlayerReport2HTML)(this.ide.env, {
                    ogTitle: (_a = this._target.ogTitle) !== null && _a !== void 0 ? _a : trans('legacy_DOPlayerReport2Editor_Splay_Report'),
                    ogImageLink: (_b = this._target.ogImageLink) !== null && _b !== void 0 ? _b : undefined,
                    ogDesc: (_c = this._target.ogDesc) !== null && _c !== void 0 ? _c : undefined,
                    title: this._target.name,
                    html,
                    cssLink: this._target.rootStore.playerReportCSSLink,
                    includeExtra: false,
                });
            }
            catch (ex) {
                this.renderError = ex;
            }
        });
    }
}
exports.DOPlayerReport2Editor = DOPlayerReport2Editor;
