"use strict";
/**
 * CHAPTER => 1화, 2화, 3화, 작가의 말, etc.
 * BLOCK => 하나의 연속된 시퀀스
 * STATEMENT => 블록안에 존재하는 하나의 "명령어"
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockType = exports.STORY_PROP_TYPE = void 0;
var STORY_PROP_TYPE;
(function (STORY_PROP_TYPE) {
    STORY_PROP_TYPE["NUMBER"] = "NUMBER";
    STORY_PROP_TYPE["STRING"] = "STRING";
})(STORY_PROP_TYPE = exports.STORY_PROP_TYPE || (exports.STORY_PROP_TYPE = {}));
var BlockType;
(function (BlockType) {
    BlockType["StartingBlock"] = "StartingBlock";
    BlockType["NormalBlock"] = "NormalBlock";
    BlockType["EndingBlock"] = "EndingBlock";
})(BlockType = exports.BlockType || (exports.BlockType = {}));
