import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLUserReportChapterComment } from '../../../@types/server'
import { Utils } from '../../../utils/utils'
import {
  IChapterCommentListableOption,
  UserReportChapterCommnetPrimaryKey,
} from '../ChapterCommentCommon'
import { useChapterCommentReportListDataLoader } from './ChapterCommentReportListDataLoader'

interface IChapterCommentReportListableTableProps {
  commentId: number | null
}

export const ChapterCommentReportListableTable: React.FC<IChapterCommentReportListableTableProps> = (
  props
) => {
  const { commentId } = props
  const { Provider, Context } = useODListableContext<
    GQLUserReportChapterComment,
    IChapterCommentListableOption
  >()
  const [token] = useCounter()
  const dataLoader = useChapterCommentReportListDataLoader(commentId)
  const [redirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  // const toolbarStyle = { display: 'flex', marginBottom: 15 }
  // const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[UserReportChapterCommnetPrimaryKey].toString()}
      pageSize={500}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          {/* <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="검색"
              style={searchBoxStyle}
            />
          </div> */}
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>신고 사유</th>
                <th>신고자</th>
                <th style={{ minWidth: 150 }}>기타 사유</th>
                <th>신고 시각</th>
              </tr>
            )}
            renderRow={(value: GQLUserReportChapterComment, context) => (
              <tr>
                <td>{value.urccId}</td>
                <td>{value.reason.title}</td>
                <td>{`${value.reporter?.name}(${value.reporterId})`}</td>
                <td>{value.customReason}</td>
                <td>{Utils.formatDate(value.createdAt)}</td>
              </tr>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
