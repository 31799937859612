"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resources = exports.i18next = void 0;
const i18next = require("i18next");
exports.i18next = i18next;
// tslint:disable-next-line:no-var-requires
const enTranslation = require('./locales/en/translation.json');
// tslint:disable-next-line:no-var-requires
const koTranslation = require('./locales/ko/translation.json');
const resources = {
    en: {
        translation: {
            sp: enTranslation,
            // hb: en_hb_translation,
        },
    },
    ko: {
        translation: {
            sp: koTranslation,
            // hb: ko_hb_translation,
        },
    },
};
exports.resources = resources;
i18next.init({
    lng: 'ko',
    resources,
    fallbackLng: 'ko',
    interpolation: {
        escapeValue: false,
    },
});
