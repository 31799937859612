"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNameForAlgorithmBlock = void 0;
/**
 * 특정 {parentBlockName}에 연결된 알고리즘 블록의 이름을 반환한다.
 */
function getNameForAlgorithmBlock(parentBlockName) {
    return `${parentBlockName}-algorithm-node`;
}
exports.getNameForAlgorithmBlock = getNameForAlgorithmBlock;
