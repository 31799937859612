import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLFeatured } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { COLORS } from '../../../styles/colors'
import { SiteUrls } from '../../../urls'
import { FeaturedPrimaryKey, IFeaturedListableOption } from '../FeturedCommon'
import { useFeaturedListDataLoader } from './FeaturedListDataLoader'

interface IFeaturedListableTableProps {}

export const FeaturedListableTable: React.FC<IFeaturedListableTableProps> = (
  props
) => {
  const { Provider, Context } = useODListableContext<
    GQLFeatured,
    IFeaturedListableOption
  >()
  const [token] = useCounter()
  const dataLoader = useFeaturedListDataLoader()
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[FeaturedPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginBottom: 15,
            }}
          >
            <Link
              to={SiteUrls.Admin.Featured.Create}
              style={{ textDecoration: 'none' }}
            >
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{ minWidth: 135, borderRadius: 5 }}
              >
                생성
              </ODButton>
            </Link>
          </div>
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>작품명</th>
                <th>커버 이미지</th>
                <th>링크</th>
                <th>숨김?</th>
              </tr>
            )}
            renderRow={(value: GQLFeatured) => (
              <HoverTR
                key={value.featuredId}
                onClick={() =>
                  setRedirect(SiteUrls.Admin.Featured.Edit(value.featuredId))
                }
              >
                <td>{value.featuredId}</td>
                <td>{value.story.name}</td>
                <td>
                  {value.coverImageFile?.link ? (
                    <img
                      src={value.coverImageFile!.link}
                      width={150}
                      height={150}
                      alt="cover"
                    />
                  ) : (
                    <span>미등록</span>
                  )}
                </td>
                <td>{value.link}</td>
                <td>
                  {value.isHidden ? (
                    <span style={{ fontWeight: 'bold', color: COLORS.Primary }}>
                      Y
                    </span>
                  ) : (
                    <span style={{ color: COLORS.GRAY600 }}>N</span>
                  )}
                </td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
