import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import {
  GQLAppConfig,
  GQLListableAppConfig,
  GQLListableAppConfigInput,
} from '../../@types/server'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useCounter, useODQuery } from '../../context/ODCommon'
import {
  ODListableOption,
  ODListableResponseType,
  useODListableContext,
} from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import {
  ODListablePaginatedTable,
  ODListableTableDefinition,
} from '../../ODListable/ODListablePaginationTable'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

interface Props {}

export interface AppConfigDataLoaderOption extends ODListableOption {}

const APP_CONFIG_FULL_SNAPSHOT = `
  configId
  iOSVersion
  aOSVersion
  createdAt
  description
  iOSFeatureFlag
  aOSFeatureFlag
`

const GQL_LIST = `
query listAppConfig($data: ListableAppConfigInput!) {
  listAppConfig(data: $data) {
    list {
      ${APP_CONFIG_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const AppConfigListContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<
    GQLAppConfig,
    AppConfigDataLoaderOption
  >()

  const listAppConfig = useODQuery<
    GQLListableAppConfigInput,
    GQLListableAppConfig
  >(GQL_LIST)
  const [token] = useCounter()

  const dataLoader = React.useCallback(
    async function AppConfigDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: AppConfigDataLoaderOption
    ): Promise<ODListableResponseType<GQLAppConfig>> {
      const r = await listAppConfig({
        page,
        pageSize,
      })
      return r as ODListableResponseType<GQLAppConfig>
    },
    [listAppConfig]
  )

  const TableDefinition: ODListableTableDefinition<
    GQLAppConfig,
    AppConfigDataLoaderOption,
    any
  > = [
    {
      id: 'configId',
      title: 'ID',
      transform: (v) => v.configId,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'iOSVersion',
      title: 'iOS 강제 버전',
      transform: (v) => v.iOSVersion,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'aOSVersion',
      title: '안드로이드 강제 버전',
      transform: (v) => v.aOSVersion,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'description',
      title: '버전 업데이트 내용',
      transform: (v) => v.description,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'iOSFeatureFlag',
      title: 'iOS 피쳐 플래그',
      transform: (v) => {
        const result: Array<string> = []
        if (!v.iOSFeatureFlag) {
          return ''
        }
        const f = JSON.parse(v.iOSFeatureFlag)
        Object.entries(f).forEach(([k, v]) => {
          result.push(`${k}:${v}`)
        })
        return result.join(', ')
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'aOSFeatureFlag',
      title: 'aOS 피쳐 플래그',
      transform: (v) => {
        const result: Array<string> = []
        if (!v.aOSFeatureFlag) {
          return ''
        }
        const f = JSON.parse(v.aOSFeatureFlag)
        Object.entries(f).forEach(([k, v]) => {
          result.push(`${k}:${v}`)
        })
        return result.join(', ')
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'createdAt',
      title: '등록 시각',
      transform: (v) => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.configId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 15,
              }}
            >
              <div>
                <Link
                  to={SiteUrls.Admin.AppConfig.Update}
                  style={{ textDecoration: 'none' }}
                >
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    환경 설정 수정
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩중..'}
              renderEmpty={() => '데이터가 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination
              hideIfSinglePage={false}
              listableContext={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
