"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbMessageInputBase = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const yup = require("yup");
const ui_1 = require("../../../ui");
const DOSTHbBase_1 = require("../../DOSTHbBase");
const IDOStatement_1 = require("../../IDOStatement");
const DOHbMessageNext_1 = require("./next/DOHbMessageNext");
const DOHbSaveProp_1 = require("./prop/DOHbSaveProp");
const MAX_NUMBER = 1000000;
const validationSchema = yup.number().min(0).required().max(MAX_NUMBER);
class DOSTHbMessageInputBase extends DOSTHbBase_1.DOSTHbBase {
    constructor(data, block, uniqueId) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbUserInput,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.HbMessageInput;
        this.hasModalForEdit = true;
        this.saveProps = []; // 사용자 속성 저장 및 보여주기(모달)
        this.messageNext = null; // 블록연결 및 조건 저장용
        this.showMessageNext = null; // 현재는 블록연결쪽만 사용중
        this.keyboardField = new ui_1.SelectionInput('keyboardType', '', (_a = data === null || data === void 0 ? void 0 : data.data.keyboard) !== null && _a !== void 0 ? _a : 'general', [
            { name: '기본 키보드', value: 'general' },
            { name: '숫자 키보드', value: 'number' },
        ], {
            uiType: 'radio',
            noLabel: true,
        });
        this.rangeStartField = new ui_1.InputWithValidation('title', '', '', 'number', (_b = data === null || data === void 0 ? void 0 : data.data.rangeStart) !== null && _b !== void 0 ? _b : 0, validationSchema, {
            noLabel: true,
        });
        this.rangeEndField = new ui_1.InputWithValidation('title', '', '', 'number', (_c = data === null || data === void 0 ? void 0 : data.data.rangeEnd) !== null && _c !== void 0 ? _c : 0, validationSchema, {
            noLabel: true,
        });
        if (!!((_d = data === null || data === void 0 ? void 0 : data.data) === null || _d === void 0 ? void 0 : _d.linkAttributes)) {
            this.saveProps = (_e = data === null || data === void 0 ? void 0 : data.data) === null || _e === void 0 ? void 0 : _e.linkAttributes.map(item => new DOHbSaveProp_1.DOHbSaveProp(this, item));
        }
        if (!!((_f = data === null || data === void 0 ? void 0 : data.data) === null || _f === void 0 ? void 0 : _f.linkBlocks)) {
            this.messageNext = new DOHbMessageNext_1.DOHbMessageNext(this, {
                linkBlocks: (_h = (_g = data === null || data === void 0 ? void 0 : data.data) === null || _g === void 0 ? void 0 : _g.linkBlocks) !== null && _h !== void 0 ? _h : [],
            });
        }
        (0, mobx_1.makeObservable)(this, {
            keyboardField: mobx_1.observable,
            saveProps: mobx_1.observable,
            messageNext: mobx_1.observable,
            showMessageNext: mobx_1.observable,
            flowChartNodesOfOtherGroupBlock: mobx_1.computed,
        });
    }
    get isValidAll() {
        if (this.keyboardField.value === 'number') {
            return (this.rangeEndField.value > this.rangeStartField.value &&
                this.rangeStartField.value > 0 &&
                this.rangeEndField.value > 0 &&
                this.rangeStartField.value < MAX_NUMBER &&
                this.rangeEndField.value < MAX_NUMBER &&
                this.saveProps.every(p => p.isValidAll));
        }
        return this.saveProps.every(p => p.isValidAll);
    }
    getDataForSubmit() {
        throw new Error('getDataForSubmit 를 구현하세요');
    }
    // 속성 추가
    addSaveProp() {
        (0, mobx_1.runInAction)(() => {
            this.saveProps.push(new DOHbSaveProp_1.DOHbSaveProp(this, {}));
        });
    }
    // 속성 삭제
    removeSaveProp(indexIn) {
        (0, mobx_1.runInAction)(() => {
            this.saveProps = this.saveProps.filter((_, index) => index !== indexIn);
        });
    }
    cancelEditing() {
        super.cancelEditing();
        // if(this.dataForSubmitField.hasChange)
        this.updateFields(this.st);
    }
    // 블록연결 모달
    onShowMessageNext() {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.showMessageNext = new DOHbMessageNext_1.DOHbMessageNext(this, {
                linkBlocks: (_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.getFullData().linkBlocks) !== null && _b !== void 0 ? _b : [],
            });
        });
    }
    // 블록연결 모달 닫기
    onCloseMessageNext() {
        (0, mobx_1.runInAction)(() => {
            this.showMessageNext = null;
        });
    }
    // 블록연결 모달 저장
    onSubmitMessageNext() {
        (0, mobx_1.runInAction)(() => {
            this.messageNext = this.showMessageNext;
            // this.showLinkBlocks = this.showMessageNext?.getFullData().linkBlocks ?? []
            // 모달 닫기
            this.showMessageNext = null;
        });
    }
    updateData(data) {
        if (data.data) {
            super.updateData(data);
            this.updateFields(data);
            if (data === null || data === void 0 ? void 0 : data.data.linkBlocks) {
                this.generateMessageNext(data === null || data === void 0 ? void 0 : data.data.linkBlocks);
            }
        }
    }
    generateMessageNext(linkBlocks) {
        if (linkBlocks.length < 1) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.messageNext = new DOHbMessageNext_1.DOHbMessageNext(this, { linkBlocks });
            (_b = (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.reLayoutFlowChartElements) === null || _b === void 0 ? void 0 : _b.call(_a);
        });
    }
    get blocksTo() {
        var _a, _b;
        return (_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.blocksTo) !== null && _b !== void 0 ? _b : [];
    }
    get flowChartNodesOfOtherGroupBlock() {
        var _a, _b;
        return (_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.flowChartNodesOfOtherGroupBlock) !== null && _b !== void 0 ? _b : [];
    }
    // undo redo를 위해 재 생성.
    updateFields(data) {
        var _a, _b, _c;
        if (!data.data)
            return;
        this.keyboardField = new ui_1.SelectionInput('keyboardType', '', (_a = data === null || data === void 0 ? void 0 : data.data.keyboard) !== null && _a !== void 0 ? _a : 'general', [
            { name: '기본 키보드', value: 'general' },
            { name: '숫자 키보드', value: 'number' },
        ], {
            uiType: 'radio',
            noLabel: true,
        });
        this.rangeStartField = new ui_1.InputWithValidation('title', '', '', 'number', (_b = data === null || data === void 0 ? void 0 : data.data.rangeStart) !== null && _b !== void 0 ? _b : 0, validationSchema, {
            noLabel: true,
        });
        this.rangeEndField = new ui_1.InputWithValidation('title', '', '', 'number', (_c = data === null || data === void 0 ? void 0 : data.data.rangeEnd) !== null && _c !== void 0 ? _c : 0, validationSchema, {
            noLabel: true,
        });
    }
}
exports.DOSTHbMessageInputBase = DOSTHbMessageInputBase;
