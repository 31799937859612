import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { StoryItemEditPage } from './StoryItemEditPage'

interface IStoryItemEditPageContainerProps {
  storyId: number
  storyItemId: number | null
}

export const StoryItemEditPageContainer: React.FC<IStoryItemEditPageContainerProps> = (
  props
) => {
  const { storyId, storyItemId } = props
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 10)} />
          <hr />
          <StoryItemEditPage storyId={storyId} storyItemId={storyItemId} />
        </CardBody>
      </Card>
    </>
  )
}
