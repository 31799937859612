import preval from 'preval.macro'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SiteUrls } from '../urls'

type SiteFooterProps = {
  wrapperStyle?: object
}

const Wrapper = styled.div`
  margin: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TermsWrapper = styled.div`
  height: 17px;
  font-size: 14px;
  color: #9b9b9b;
`

const BuildInfo = styled.div`
  font-size: 11px;
  color: #c9c9c9;
`

const buildTime = preval`module.exports = new Date().toLocaleString();`

export const SiteFooter: React.FC<SiteFooterProps> = ({ wrapperStyle = {} }) => {
  return (
    <Wrapper style={wrapperStyle}>
      <BuildInfo>Web build : {buildTime}</BuildInfo>
      <TermsWrapper>
        <Link to={SiteUrls.Terms} style={{ color: '#9b9b9b' }}>
          <span style={{ marginRight: 10 }}>Terms</span>
        </Link>{' '}
        |
        <Link to={SiteUrls.Privacy} style={{ color: '#9b9b9b' }}>
          <span style={{ marginLeft: 10 }}>Privacy</span>
        </Link>
      </TermsWrapper>
    </Wrapper>
  )
}
