import { ODListableResponseType } from '@odc/od-react-belt'
import { AGE_GROUP, GQLTtsSpeaker, SEX_DISTINCTION } from '@storyplay/common'
import React from 'react'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { ITtsSpeakerListableOption } from '../TtsSpeakerCommon'

export function useTtsSpeakerListDataLoader(
  sexDistinction: SEX_DISTINCTION | undefined,
  age: AGE_GROUP | undefined
) {
  const { listTtsSpeaker } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ITtsSpeakerListableOption
    ): Promise<ODListableResponseType<GQLTtsSpeaker>> {
      const r = await listTtsSpeaker({
        page,
        pageSize: 10,
        sexDistinction,
        age,
        ...options,
      })
      return r as ODListableResponseType<GQLTtsSpeaker>
    },
    [listTtsSpeaker, sexDistinction, age]
  )
}
