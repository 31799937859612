"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBundleBannerEditor = void 0;
const core_1 = require("@storyplay/core");
const moment = require("moment");
const store_1 = require("../../store");
const EntityEditor_1 = require("../../store/entity/EntityEditor");
const DateWithNullableField_1 = require("../../store/entity/fields/DateWithNullableField");
const DBFileFieldWithUI_1 = require("../../store/entity/fields/DBFileFieldWithUI");
const SelectionFieldWithUI_1 = require("../../store/entity/fields/SelectionFieldWithUI");
const mobx_1 = require("mobx");
const lodash_1 = require("lodash");
const WEB_LINK_TYPE_OPTIONS = [
    core_1.GQLBANNER_LINK_TYPE.WebCurrentWindowLink,
    core_1.GQLBANNER_LINK_TYPE.WebNewWindowLink,
    core_1.GQLBANNER_LINK_TYPE.DeepLink,
    null,
].map(item => ({
    name: !!item ? core_1.BANNER_LINK_TYPE_TO_KO_STRING[item] : '링크 없음',
    value: item,
}));
const APP_LINK_TYPE_OPTIONS = [
    ...Object.values(core_1.GQLBANNER_LINK_TYPE).filter(item => ![
        core_1.GQLBANNER_LINK_TYPE.WebCurrentWindowLink,
        core_1.GQLBANNER_LINK_TYPE.WebNewWindowLink,
    ].includes(item)),
    null,
].map(item => ({
    name: !!item ? core_1.BANNER_LINK_TYPE_TO_KO_STRING[item] : '링크 없음',
    value: item,
}));
const getOptionNameOfStoryIdSelection = (story) => {
    var _a;
    return `${story.storyId} : ${story.name} (${core_1.STORY_PLAY_TYPE_TO_KO_STRING[(_a = story.playType) !== null && _a !== void 0 ? _a : core_1.GQLSTORY_PLAY_TYPE.Interactive]})`;
};
class DOBundleBannerEditor extends EntityEditor_1.EntityEditor {
    constructor(banner, location) {
        var _a;
        super(banner);
        this.countryCodeField = null;
        // type === Custom
        this.languageCodeField = null;
        this.tempOptionForSearchingStory = null;
        this.storyOptions = null;
        if (!!banner.storyByStoryId) {
            this.tempOptionForSearchingStory = {
                name: getOptionNameOfStoryIdSelection(banner.storyByStoryId),
                value: banner.storyByStoryId.storyId,
            };
        }
        (0, mobx_1.makeObservable)(this, {
            languageCodeField: mobx_1.observable,
            countryCodeField: mobx_1.observable,
        });
        this.loadCountryCode(banner).catch();
        this.loadLanguageCode(banner).catch();
        const stringFields = [
            {
                key: 'title',
                options: {
                    label: '제목',
                    placeholder: '제목을 입력해 주세요(최대 15글자)',
                    options: { noLabel: true, maxLength: 15 },
                },
            },
            {
                key: 'content',
                options: {
                    label: '본문',
                    placeholder: '본문을 입력해 주세요(최대 25글자)',
                    options: { noLabel: true, maxLength: 25 },
                },
            },
            {
                key: 'link',
                options: {
                    label: 'link',
                    placeholder: 'URL을 입력해 주세요.',
                    options: { noLabel: true },
                },
            },
            {
                key: 'iOSFallbackLink',
                options: {
                    label: 'iOS 연결 링크',
                    placeholder: '딥링크 이동 실패 시 연결할 URL을 입력해주세요.',
                    options: { noLabel: true },
                },
            },
            {
                key: 'aOSFallbackLink',
                options: {
                    label: 'AOS 연결 링크',
                    placeholder: '딥링크 이동 실패 시 연결할 URL을 입력해주세요.',
                    options: { noLabel: true },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new store_1.TextFieldWithUI(fld.key, () => { var _a; return (_a = banner[fld.key]) !== null && _a !== void 0 ? _a : ''; }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        const fileFields = [
            {
                key: 'bannerImageFile',
                options: { label: '이미지', options: { showLabel: false } },
            },
        ];
        fileFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new DBFileFieldWithUI_1.DBFileFieldWithUI(`${fld.key}`, () => {
                const link = banner[`${fld.key}Link`];
                return { link };
            }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        const selectionFields = [
            {
                key: 'type',
                options: {
                    label: '배너 유형',
                    selection: Object.values(core_1.GQLBANNER_TYPE).map(item => ({
                        name: core_1.BANNER_TYPE_TO_KO_STRING[item],
                        value: item,
                    })),
                    options: {
                        onChangeBeforeSubmit: v => {
                            if (this.typeField.defValue === core_1.GQLBANNER_TYPE.Story &&
                                v === core_1.GQLBANNER_TYPE.Custom) {
                                this.bannerImageFileField.input.onRemove();
                                this.linkTypeField.input.onChange(null);
                                this.linkField.input.onChange('');
                                this.iOSFallbackLinkField.input.onChange('');
                                this.aOSFallbackLinkField.input.onChange('');
                                this.titleField.input.onChange('');
                                this.contentField.input.onChange('');
                                return;
                            }
                            if (this.typeField.defValue === core_1.GQLBANNER_TYPE.Custom &&
                                v === core_1.GQLBANNER_TYPE.Story) {
                                this.storyIdField.input.onChange(null);
                            }
                        },
                    },
                },
            },
            {
                key: 'targetClient',
                options: {
                    label: '노출 위치',
                    selection: Object.values(core_1.GQLCLIENT).map(item => ({
                        name: core_1.CLIENT_TO_KO_STRING[item],
                        value: item,
                    })),
                    options: {
                        onChangeBeforeSubmit: v => {
                            this.linkTypeField.input.onChangeOptions(v === core_1.GQLCLIENT.App
                                ? APP_LINK_TYPE_OPTIONS
                                : WEB_LINK_TYPE_OPTIONS);
                            this.linkTypeField.input.onChange(v === core_1.GQLCLIENT.App
                                ? APP_LINK_TYPE_OPTIONS[0].value
                                : WEB_LINK_TYPE_OPTIONS[0].value);
                        },
                    },
                },
            },
            {
                key: 'location',
                options: {
                    label: 'location',
                    selection: Object.values(core_1.GQLBANNER_LOCATION).map(item => ({
                        name: core_1.BANNER_LOCATION_TO_KO_STRING[item],
                        value: item,
                    })),
                },
            },
            {
                key: 'isShow',
                options: {
                    label: '노출 여부',
                    selection: [true, false].map(item => ({
                        name: String(item),
                        value: item,
                    })),
                },
            },
            {
                key: 'linkType',
                options: {
                    label: '연결 링크',
                    selection: banner.targetClient === core_1.GQLCLIENT.Web
                        ? WEB_LINK_TYPE_OPTIONS
                        : banner.targetClient === core_1.GQLCLIENT.App
                            ? APP_LINK_TYPE_OPTIONS
                            : [],
                },
            },
            {
                key: 'storyId',
                options: {
                    label: '작품 선택',
                    selection: [],
                    options: {
                        placeholder: '작품명을 검색해주세요',
                        onChangeBeforeSubmit: v => {
                            const find = this.storyIdField.input.options.find(option => option.value === v);
                            if (!!find) {
                                this.tempOptionForSearchingStory = find;
                            }
                        },
                        loadOptionsByInputValue: async (v) => {
                            try {
                                if (!v) {
                                    if (!!this.tempOptionForSearchingStory) {
                                        return [{ ...this.tempOptionForSearchingStory }];
                                    }
                                    return [];
                                }
                                const res = await this._target.rootStore.di.server.listStoryByAdmin({
                                    page: 1,
                                    pageSize: 30,
                                    filter: !!v ? v : undefined,
                                    playType: this.locationField.value === core_1.GQLBANNER_LOCATION.Home
                                        ? null
                                        : this.locationField.value ===
                                            core_1.GQLBANNER_LOCATION.Interactive
                                            ? core_1.GQLSTORY_PLAY_TYPE.Interactive
                                            : core_1.GQLSTORY_PLAY_TYPE.EPUB,
                                });
                                this.storyOptions = res.list;
                                const list = res.list
                                    .filter(story => {
                                    var _a;
                                    return story.serviceCountries
                                        .map(country => country.code)
                                        .includes(((_a = this.countryCodeField) === null || _a === void 0 ? void 0 : _a.value) || '');
                                })
                                    .map(item => ({
                                    name: getOptionNameOfStoryIdSelection(item),
                                    value: item.storyId,
                                }));
                                if (!!this.tempOptionForSearchingStory &&
                                    !list.find(item => { var _a; return item.value === ((_a = this.tempOptionForSearchingStory) === null || _a === void 0 ? void 0 : _a.value); })) {
                                    list.push({
                                        ...this.tempOptionForSearchingStory,
                                    });
                                }
                                return list;
                            }
                            catch (ex) {
                                this._target.rootStore.showError(ex);
                                return [];
                            }
                        },
                    },
                },
            },
        ];
        selectionFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new SelectionFieldWithUI_1.SelectionFieldWithUI(fld.key, () => banner[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        if (!this.isEditing) {
            (_a = this.countryCodeField) === null || _a === void 0 ? void 0 : _a.input.onChange(core_1.COUNTRY_CODE_BY_STUDIO_WEB.KR);
            this.typeField.input.onChange(core_1.GQLBANNER_TYPE.Story);
            this.targetClientField.input.onChange(core_1.GQLCLIENT.App);
            this.isShowField.onChange(false);
            if (!!location) {
                this.locationField.onChange(location);
            }
        }
        this.willOpenAtField = new DateWithNullableField_1.DateWithNullableField('willOpenAt', () => {
            var _a;
            return (_a = this._target.willOpenAt) !== null && _a !== void 0 ? _a : null;
        }, () => null);
        this.willCloseAtField = new DateWithNullableField_1.DateWithNullableField('willCloseAt', () => {
            var _a;
            return (_a = this._target.willCloseAt) !== null && _a !== void 0 ? _a : null;
        }, () => null);
    }
    get rootStore() {
        return this._target.rootStore;
    }
    get isEditing() {
        return this._target.serverId !== -1;
    }
    async loadCountryCode(banner) {
        try {
            const res = await this.rootStore.di.server.getServiceCountries();
            const countryOptions = res
                .map(country => {
                return {
                    name: core_1.COUNTRY_CODE_TO_KO_STRING[country.code],
                    value: country.code,
                };
            })
                .sort((a, b) => a.name.localeCompare(b.name)) || [];
            this.countryCodeField = new SelectionFieldWithUI_1.SelectionFieldWithUI('countryCode', () => { var _a, _b; return (_b = (_a = banner.countryCode) !== null && _a !== void 0 ? _a : countryOptions[0].value) !== null && _b !== void 0 ? _b : ''; }, () => null, {
                label: '노출 국가',
                selection: countryOptions,
            });
        }
        catch (error) {
            this.rootStore.showError(error);
        }
    }
    async loadLanguageCode(banner) {
        try {
            const res = await this.rootStore.di.server.getServiceSystemLanguaes();
            const languageOptions = res
                .map(language => {
                return {
                    name: language.display,
                    value: language.code,
                };
            })
                .sort((a, b) => a.name.localeCompare(b.name)) || [];
            this.languageCodeField = new SelectionFieldWithUI_1.SelectionFieldWithUI('languageCode', () => { var _a, _b; return (_b = (_a = banner.languageCode) !== null && _a !== void 0 ? _a : languageOptions[0].value) !== null && _b !== void 0 ? _b : ''; }, () => null, {
                label: '언어',
                selection: languageOptions,
            });
        }
        catch (error) {
            this.rootStore.showError(error);
        }
    }
    createChangeSet() {
        const changeSet = {};
        this.editorFields.forEach(fld => {
            this.onFieldValueChangeFound(fld, fld.value, changeSet);
        });
        return (0, lodash_1.isEmpty)(changeSet) ? null : changeSet;
    }
    async submitChanges(onError) {
        var _a, _b;
        if (!((_a = this.countryCodeField) === null || _a === void 0 ? void 0 : _a.value)) {
            this._target.rootStore.showError('노출 국가를 입력해주세요.');
            return false;
        }
        if (!this.typeField.value) {
            this._target.rootStore.showError('배너 유형을 선택해주세요.');
            return false;
        }
        if (!this.targetClientField.value) {
            this._target.rootStore.showError('노출 위치를 선택해주세요.');
            return false;
        }
        if (!this.willOpenAtField.value) {
            this._target.rootStore.showError('노출 기간을 입력해주세요.');
            return false;
        }
        if (moment(this.willCloseAtField.value).isBefore(this.willOpenAtField.value)) {
            this._target.rootStore.showError('노출이 끝나는 날짜가 열리는 날짜보다 빠릅니다.');
            return false;
        }
        if (this.typeField.value === core_1.GQLBANNER_TYPE.Story) {
            if (!this.storyIdField.value) {
                this._target.rootStore.showError('작품을 선택해주세요.');
                return false;
            }
        }
        if (this.typeField.value === core_1.GQLBANNER_TYPE.Custom) {
            if (!this.bannerImageFileField.value) {
                this._target.rootStore.showError('배너 이미지를 업로드해주세요.');
                return false;
            }
            if (!((_b = this.languageCodeField) === null || _b === void 0 ? void 0 : _b.value)) {
                this._target.rootStore.showError('언어를 선택해주세요');
                return false;
            }
            if (!!this.linkTypeField.value && !this.linkField.value) {
                this._target.rootStore.showError('연결 링크를 입력해주세요.');
                return false;
            }
            if (this.targetClientField.value === core_1.GQLCLIENT.Web &&
                this.linkTypeField.value === core_1.GQLBANNER_LINK_TYPE.DeepLink) {
                if (!this.iOSFallbackLinkField.value ||
                    !this.aOSFallbackLinkField.value) {
                    this._target.rootStore.showError('딥링크의 폴백링크를 입력해주세요.');
                    return false;
                }
            }
        }
        return super.submitChanges(onError);
    }
}
exports.DOBundleBannerEditor = DOBundleBannerEditor;
