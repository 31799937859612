"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioAPIServer = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const consts_1 = require("../consts");
const studio_1 = require("../studio");
const DOBanner_1 = require("../studio/admin/banner/DOBanner");
const DOBundleBanner_1 = require("../studio/admin/bundleBanner/DOBundleBanner");
const DOMonthlyStoryService_1 = require("../studio/admin/monthlyStoryService/DOMonthlyStoryService");
const store_1 = require("../studio/store");
const ending_1 = require("../studio/store/entity/ending");
const DOStoryProperty_1 = require("../studio/store/entity/storyProperty/DOStoryProperty");
const studio_gqls_1 = require("./studio_gqls");
class StudioAPIServer extends core_1.APIServerBaseImpl {
    constructor(client, defaultOptions = {}) {
        super(client, defaultOptions);
    }
    setRootStore(rootStore) {
        this.rootStore = rootStore;
    }
    async createStoryForStudio(input) {
        return this.mutate(studio_gqls_1.GQL_CREATE_STORY_FOR_STUDIO, [{ fieldName: 'mainImageFile', mimeType: core_1.MIME_TYPE.Image }], input, { pickFirstKey: true, operationName: 'createStoryForStudio' });
    }
    async deleteChapterForStudio(chapterId) {
        return this.mutate(studio_gqls_1.GQL_REMOVE_CHAPTER_FOR_STUDIO, [], { id: chapterId }, { pickFirstKey: true, operationName: 'removeChapterForStudio' });
    }
    async getWriterProfileForStudio() {
        return this.query(studio_gqls_1.GQL_GET_WRITER_PROFILE_FOR_STUDIO, {}, { pickFirstKey: true, operationName: 'getWriterProfileForStudio' });
    }
    async loginWriterWithEmailForStudio(input) {
        return this.mutate(studio_gqls_1.GQL_LOGIN_WRITER_WITH_EMAIL_FOR_STUDIO, [], input, { pickFirstKey: true, operationName: 'loginWriterWithEmailForStudio' });
    }
    async listStoryForWriter(input) {
        return this.query(studio_gqls_1.GQL_LIST_STORY_FOR_WRITER, {
            page: 1,
            pageSize: 100,
            filter: null,
            published: null,
            sectionType: null,
            uiSectionType: null,
            isFinished: null,
            genre: null,
            sortOption: null,
            sorting: null,
            weekday: null,
            ...input,
        }, { pickFirstKey: true, operationName: 'listStoryForWriter' });
    }
    async getStoryForStudio(storyId) {
        return this.query(studio_gqls_1.GQL_GET_STORY_FOR_STUDIO, { id: storyId }, { pickFirstKey: true, operationName: 'getStoryForStudio' });
    }
    async listColors() {
        return this.query(studio_gqls_1.GQL_LIST_COLOR, { filter: null, page: 1, pageSize: 1000 }, { pickFirstKey: true, operationName: 'listColorFromStudio' });
    }
    async exportStudioChapterAsSheet(input) {
        return this.mutate(studio_gqls_1.GQL_EXPORT_STUDIO_CHAPTER_AS_SHEET, [], input, { pickFirstKey: true, operationName: 'exportStudioChapterAsSheet' });
    }
    async exportStudioStoryAsSheet(input) {
        return this.mutate(studio_gqls_1.GQL_EXPORT_STUDIO_STORY_AS_SHEET, [], input, { pickFirstKey: true, operationName: 'exportStudioStoryAsSheet' });
    }
    async createStoryHasStudioFile(data) {
        // TDD 환경에서는 파일명을 string 으로 받고 있기에 이런 현상이 발생한다. 강제로 동일하게 표시한다.
        if ((0, lodash_1.isString)(data.file) && data.resName === undefined) {
            data.resName = data.file;
        }
        return this.mutate(studio_gqls_1.GQL_CREATE_STORY_HAS_STUDIO_FILE, [
            {
                fieldName: 'file',
                mimeType: core_1.MIME_TYPE.Image,
            },
        ], data, {
            pickFirstKey: true,
            operationName: 'createStoryHasStudioFile',
        });
    }
    async updateStoryHasStudioFile(data) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_HAS_STUDIO_FILE, [], data, {
            pickFirstKey: true,
            operationName: 'updateStoryHasStudioFile',
        });
    }
    async removeStoryHasStudioFile(data) {
        return this.mutate(studio_gqls_1.GQL_REMOVE_STORY_HAS_STUDIO_FILE, [], data, {
            pickFirstKey: true,
            operationName: 'removeStoryHasStudioFile',
        });
    }
    async createChapterForStudio(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_CHAPTER_FOR_STUDIO, [], data, {
            pickFirstKey: true,
            operationName: 'createChapterForStudio',
        });
    }
    createEntityForStudio(entity, createInput) {
        if (entity instanceof DOStoryProperty_1.DOStoryProperty) {
            return this.mutate(studio_gqls_1.GQL_CREATE_STORY_PROPERTY_FOR_STUDIO, [{ fieldName: 'imageFile', mimeType: core_1.MIME_TYPE.Image }], createInput, {
                pickFirstKey: true,
                operationName: 'createEntityForStudio_storyProperty',
            });
        }
        else if (entity instanceof store_1.DOStoryItem) {
            return this.mutate(studio_gqls_1.GQL_CREATE_STORY_ITEM, [{ fieldName: 'imageFile', mimeType: core_1.MIME_TYPE.Image }], createInput, {
                pickFirstKey: true,
                operationName: 'createEntityForStudio_storyItem',
            });
        }
        else if (entity instanceof store_1.DOChr) {
            return this.mutate(studio_gqls_1.GQL_CREATE_CHR, [{ fieldName: 'imageFile', mimeType: core_1.MIME_TYPE.Image }], createInput, {
                pickFirstKey: true,
                operationName: 'createEntityForStudio_chr',
            });
        }
        throw new Error(`createEntityForStudio not implemented for some entity type.`);
    }
    async updateEntityForStudio(entity, changeSet) {
        if (entity instanceof store_1.DOStory) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_FOR_STUDIO, [
                { fieldName: 'mainImageFile', mimeType: core_1.MIME_TYPE.Image },
                { fieldName: 'previewImageFile', mimeType: core_1.MIME_TYPE.Image },
                { fieldName: 'introImageFile', mimeType: core_1.MIME_TYPE.Image },
                { fieldName: 'wideImageFile', mimeType: core_1.MIME_TYPE.Image },
                { fieldName: 'mainCharacterImageFile', mimeType: core_1.MIME_TYPE.Image },
                { fieldName: 'teaserVideoFile', mimeType: core_1.MIME_TYPE.Video },
                { fieldName: 'storygameCoverImageFile', mimeType: core_1.MIME_TYPE.Image },
            ], {
                ...changeSet,
                id: entity.storyId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_story',
            });
        }
        else if (entity instanceof DOStoryProperty_1.DOStoryProperty) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_PROPERTY_FOR_STUDIO, [{ fieldName: 'imageFile', mimeType: core_1.MIME_TYPE.Image }], {
                ...changeSet,
                id: entity.propId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_storyProperty',
            });
        }
        else if (entity instanceof store_1.DOChapter) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_CHAPTER_FOR_STUDIO, [], {
                ...changeSet,
                id: entity.id,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_chapter',
            });
        }
        else if (entity instanceof store_1.DOPlayerReport2) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_PLAYER_REPORT2, [], {
                ...changeSet,
                id: entity.prId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_playerReport2',
            });
        }
        else if (entity instanceof store_1.DOStoryItem) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_ITEM, [{ fieldName: 'imageFile', mimeType: core_1.MIME_TYPE.Image }], {
                ...changeSet,
                id: entity.storyItemId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_storyItem',
            });
        }
        else if (entity instanceof store_1.DOPlayerReport2Function) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_PLAYER_REPORT2_FUNCTION, [], {
                ...changeSet,
                id: entity.prfId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_playerReport2Function',
            });
        }
        else if (entity instanceof store_1.DOPlayerReport2RenderBlock) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_PLAYER_REPORT2_RENDER_BLOCK, [], {
                ...changeSet,
                id: entity.prbId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_playerReport2RenderBlock',
            });
        }
        else if (entity instanceof store_1.DOUser) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_USER, [
            // 불필요함.
            // { fieldName: 'imageFile', mimeType: MIME_TYPE.Image }
            ], {
                ...changeSet,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_user',
            });
        }
        else if (entity instanceof store_1.DOChr) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_CHR, [{ fieldName: 'imageFile', mimeType: core_1.MIME_TYPE.Image }], {
                ...changeSet,
                id: entity.id,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_chr',
            });
        }
        else if (entity instanceof ending_1.DOEnding) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_ENDING, [
                { fieldName: 'imageFile', mimeType: core_1.MIME_TYPE.Image },
                { fieldName: 'previewImageFile', mimeType: core_1.MIME_TYPE.Image },
            ], {
                ...changeSet,
                id: entity.endingId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_ending',
            });
        }
        else if (entity instanceof DOBanner_1.DOBanner) {
            if (!entity.bannerId) {
                throw new Error(`배너 아이디가 없습니다.`);
            }
            const cs = this.convertBannerInput(changeSet);
            return this.mutate(studio_gqls_1.GQL_UPDATE_BANNER, [{ fieldName: 'bannerImageFile', mimeType: core_1.MIME_TYPE.Image }], {
                ...cs,
                id: entity.bannerId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_banner',
            });
        }
        else if (entity instanceof DOBundleBanner_1.DOBundleBanner) {
            if (!entity.bundleBannerId) {
                throw new Error(`배너 아이디가 없습니다.`);
            }
            const cs = this.convertBundleBannerInput(changeSet);
            return this.mutate(studio_gqls_1.GQL_UPDATE_BUNDLE_BANNER, [{ fieldName: 'bannerImageFile', mimeType: core_1.MIME_TYPE.Image }], {
                ...cs,
                id: entity.bundleBannerId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_banner',
            });
        }
        else if (entity instanceof DOMonthlyStoryService_1.DOMonthlyStoryService) {
            // @ts-ignore
            if (!entity.monthlyBundleId) {
                throw new Error(`아이디가 없습니다.`);
            }
            const cs = this.convertBundleBannerInput(changeSet);
            return this.mutate(studio_gqls_1.GQL_UPDATE_MONTHLY_BUNDLE, [], {
                ...changeSet,
                id: entity.monthlyBundleId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_monthlyBundle',
            });
        }
        else if (entity instanceof studio_1.DOBottomBanner) {
            if (!entity.bottomBannerId) {
                throw new Error(`하단 띠배너 아이디가 없습니다.`);
            }
            const cs = changeSet;
            return this.mutate(studio_gqls_1.GQL_UPDATE_BOTTOM_BANNER, [], {
                ...cs,
                id: entity.bottomBannerId,
            }, {
                pickFirstKey: true,
                operationName: 'updateEntityForStudio_bottomBanner',
            });
        }
        else if (entity instanceof store_1.DOStoryPrice) {
            return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_PRICE_SETTING, [], {
                ...changeSet,
                id: entity.storyPriceSettingId,
            }, {
                pickFirstKey: true,
                operationName: 'updateStoryPriceSetting',
            });
        }
        throw new Error(`updateEntityForStudio not implemented for some entity type.`);
    }
    async loadPlayerReportIDEData(prId) {
        const res = await this.query(studio_gqls_1.GQL_LOAD_PLAYER_REPORT_IDE_DATA, { id: prId }, { pickFirstKey: false, operationName: 'loadPlayerReportIDEData' });
        return {
            report: res.getPlayerReport2,
            functions: res.listPlayerReport2Function.list,
            renderBlocks: res.listPlayerReport2RenderBlock.list,
        };
    }
    /**
     * 스튜디오에서 사용하기 위한 챕터 정보 (및 관련된 정보들)를 로드한다.
     */
    async getChapterForEditing(chapterId) {
        return this.query(studio_gqls_1.GQL_GET_CHAPTER_FOR_EDITING, { id: chapterId }, { pickFirstKey: true, operationName: 'getChapterForEditing' });
    }
    async upsertChapterDraft(chapterId, bundle, chapterScript, numBlocks, numChars, numSens, stats) {
        return this.mutate(studio_gqls_1.GQL_UPSERT_CHAPTER_DRAFT, [], {
            chapterId,
            bundle: JSON.stringify(bundle),
            chapterScript: chapterScript ? JSON.stringify(chapterScript) : null,
            numBlocks,
            numChars,
            numSens,
            stats: JSON.stringify(stats),
        }, { pickFirstKey: true, operationName: 'upsertChapterDraft' });
    }
    async updatePrimaryProperty(id, isPrimary) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_PROPERTY_FOR_STUDIO, [], {
            id,
            isPrimary,
        }, {
            pickFirstKey: true,
            operationName: 'updateStoryPropertyForStudio',
        });
    }
    async createPlayerReport2RenderBlock(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_PLAYER_REPORT2_RENDER_BLOCK, [], data, {
            operationName: 'createPlayerReport2RenderBlock',
        });
    }
    async removePlayerReport2RenderBlock(prbId) {
        return this.mutate(studio_gqls_1.GQL_REMOVE_PLAYER_REPORT2_RENDER_BLOCK, [], { id: prbId }, {
            operationName: 'removePlayerReport2RenderBlock',
        });
    }
    async createPlayerReport2Function(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_PLAYER_REPORT2_FUNCTION, [], data, {
            operationName: 'createPlayerReport2Function',
        });
    }
    async removePlayerReport2Function(prfId) {
        return this.mutate(studio_gqls_1.GQL_REMOVE_PLAYER_REPORT2_FUNCTION, [], { id: prfId }, {
            operationName: 'removePlayerReport2Function',
        });
    }
    async getChapter(chapterId) {
        return this.query(studio_gqls_1.GQL_GET_CHAPTER, { id: chapterId }, { pickFirstKey: true, operationName: 'getChapter' });
    }
    async listChapterForStudio(data) {
        return this.query(studio_gqls_1.GQL_LIST_CHAPTER_FOR_STUDIO, data, { pickFirstKey: true, operationName: 'listChapterForStudio' });
    }
    async listMinChapterForStudio(data) {
        return this.query(studio_gqls_1.GQL_LIST_MIN_CHAPTER_FOR_STUDIO, data, { pickFirstKey: true, operationName: 'listMinChapterForStudio' });
    }
    async duplicateChapterDraft(chapterId) {
        return this.mutate(studio_gqls_1.GQL_DUPLICATE_CHAPTER_DRAFT, [], { id: chapterId }, {
            operationName: 'duplicateChapterDraft',
        });
    }
    async removeChapterDraft(chapterId) {
        return this.mutate(studio_gqls_1.GQL_DISCARD_CHAPTER_DRAFT, [], { id: chapterId }, { operationName: 'discardChapterDraft' });
    }
    async createTestSession(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_TEST_SESSION, [], data, { operationName: 'createTestSession' });
    }
    async getLastTestSession(chapterId) {
        return this.query(studio_gqls_1.GQL_GET_LAST_TEST_SESSION, { id: chapterId }, { operationName: 'getLastTestSession' });
    }
    updateChapterIndexes(storyId, field) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_CHAPTER_INDEXES, [], { storyId, field }, { operationName: 'updateChapterIndexes' });
    }
    async getChapterDraftArchive(chapterDraftArchiveId) {
        return this.query(studio_gqls_1.GQL_GET_CHAPTER_DRAFT_ARCHIVE, { id: chapterDraftArchiveId }, { operationName: 'getChapterDraftArchive' });
    }
    async listSampleStoryForStudio(data) {
        return this.query(studio_gqls_1.GQL_LIST_SAMPLE_STORY_FOR_STUDIO, data, { operationName: 'listSampleStoryForStudio' });
    }
    async getContentHasStory(data) {
        return this.mutate(studio_gqls_1.GQL_GET_CONTEST_HAS_STORY, [], data, { operationName: 'getContentHasStory' });
    }
    createContestHasStory(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_CONTEST_HAS_STORY, [], data, { operationName: 'createContestHasStory' });
    }
    updateContestHasStory(data) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_CONTEST_HAS_STORY, [], data, { operationName: 'updateContestHasStory' });
    }
    savePublicResourceToStudioFile(data) {
        return this.mutate(studio_gqls_1.GQL_SAVE_PUBLIC_RESOURCE_TO_STUDIO_FILE, [], data, {
            operationName: 'savePublicResourceToStudioFile',
        });
    }
    createOnboardingPopup(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_ONBOARDINGPOPUP, [], data, { operationName: 'createOnboardingPoppup' });
    }
    updateOnboardingPopup(data) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_ONBOARDINGPOPUP, [], data, { operationName: 'updateOnboardingPopup' });
    }
    removeOnboardingPopup(data) {
        return this.mutate(studio_gqls_1.GQL_REMOVE_ONBOARDINGPOUP, [], data, { operationName: 'removeOnboardingPopup' });
    }
    getPlayerData(data) {
        return this.query(studio_gqls_1.GQL_GET_PLAYER_DATA, data, { operationName: 'getPlayerData' });
    }
    markUserStudioTutorialProgress(data) {
        return this.mutate(studio_gqls_1.GQL_MARK_USER_STUDIO_TUTORIAL_PROGRESS, [], data, {
            operationName: 'markUserStudioTutorialProgress',
        });
    }
    resetUserStudioTutorialProgress(data) {
        return this.mutate(studio_gqls_1.GQL_RESET_USER_STUDIO_TUTORIAL_PROGRESS, [], data, {
            operationName: 'resetUserStudioTutorialProgress',
        });
    }
    publishChapter(data) {
        return this.mutate(studio_gqls_1.GQL_PUBLISH_CHAPTER_FOR_STUDIO, [], data, {
            operationName: 'publishChapter',
        });
    }
    listStudioTutorialCompletion() {
        return this.query(studio_gqls_1.GQL_LIST_STUDIO_TUTORIAL_COMPLETION, {}, { operationName: 'listStudioTutorialCompletion' });
    }
    getCurrentContest() {
        return this.query(studio_gqls_1.GQL_GET_CURRENT_CONTEST, {}, { operationName: 'getCurrentContestForStudio' });
    }
    getCurrentWebNovelContest() {
        return this.query(studio_gqls_1.GQL_GET_CURRENT_WEB_NOVEL_CONTEST, {}, { operationName: 'getCurrentEpubContestForStudio' });
    }
    getLatestWebNovelContest() {
        return this.query(studio_gqls_1.GQL_GET_LATEST_WEB_NOVEL_CONTEST, {}, { operationName: 'getLatestEpubContest' });
    }
    getFallbackRemoteScriptByStudio(data) {
        return this.query(studio_gqls_1.GQL_GET_FALLBACK_REMOTE_SCRIPT_BY_STUDIO, data, {
            operationName: 'getFallbackRemoteScriptByStudio',
        });
    }
    listRemoteScriptProvider(data) {
        return this.query(studio_gqls_1.GQL_LIST_REMOTE_SCRIPT_PROVIDER, data, {
            operationName: 'listRemoteScriptProvider',
        });
    }
    getListStoryCountsByStudio() {
        return this.query(studio_gqls_1.GQL_GET_LIST_STORY_COUNTS_BY_STUDIO, {}, {
            operationName: 'getListStoryCountsByStudio',
        });
    }
    getMainChrOnboardingPopup(data) {
        return this.query(studio_gqls_1.GQL_GET_MAIN_CHR_ONBOARDING_POPUP, data, { operationName: 'getMainChrOnboardingPopup' });
    }
    upsertMainChrOnboardingPopup(data) {
        return this.mutate(studio_gqls_1.GQL_UPSERT_MAIN_CHR_ONBOARDING_POPUP, [], data, {
            operationName: 'upsertMainChrOnboardingPopup',
        });
    }
    getNumPlayStatOfStory(data) {
        return this.query(studio_gqls_1.GQL_GET_NUM_PLAY_STAT_OF_STORY, data, {
            operationName: 'getNumPlayStatOfStory',
        });
    }
    getConversionStatOfStory(data) {
        return this.query(studio_gqls_1.GQL_GET_CONVERSION_STAT_OF_STORY, data, {
            operationName: 'getConversionStatOfStory',
        });
    }
    getNumLikeStatOfStory(data) {
        return this.query(studio_gqls_1.GQL_GET_NUM_LIKE_STAT_OF_STORY, data, {
            operationName: 'getNumLikeStatOfStory',
        });
    }
    getNumCommentStatOfStory(data) {
        return this.query(studio_gqls_1.GQL_GET_NUM_COMMENT_STAT_OF_STORY, data, {
            operationName: 'getNumCommentStatOfStory',
        });
    }
    listStoryRankCache(data) {
        return this.query(studio_gqls_1.GQL_LIST_STORY_RANK_CACHE, data, {
            operationName: 'listStoryRankCache',
        });
    }
    listStoryConversionRankCache(data) {
        return this.query(studio_gqls_1.GQL_LIST_STORY_CONVERSION_RANK_CACHE, data, {
            operationName: 'listStoryConversionRankCache',
        });
    }
    listChapterComment(data) {
        return this.query(studio_gqls_1.GQL_LIST_CHAPTER_COMMENT, data, {
            operationName: 'listChapterComment',
        });
    }
    listStoryWeekday(data) {
        return this.query(studio_gqls_1.GQL_LIST_STORY_WEEKDAY, data, {
            operationName: 'listStoryWeekday',
        });
    }
    createStoryWeekday(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_STORY_WEEKDAY, [], data, {
            operationName: 'createStoryWeekday',
        });
    }
    deleteStoryWeekday(data) {
        return this.mutate(studio_gqls_1.GQL_DELETE_STORY_WEEKDAY, [], data, {
            operationName: 'deleteStoryWeekday',
        });
    }
    listBlockBounceStatOfChapter(data) {
        return this.query(studio_gqls_1.GQL_LIST_BLOCK_BOUNCE_STAT_OF_CHAPTER, data, {
            operationName: 'listBlockBounceStatOfChapter',
        });
    }
    listChapterDraft(data) {
        return this.query(studio_gqls_1.GQL_LIST_CHAPTER_DRAFT, data, {
            operationName: 'listChapterDraft',
        });
    }
    changeChapterFreeSetting(data) {
        return this.mutate(studio_gqls_1.GQL_CHANGE_CHAPTER_FREE_SETTING, [], data, {
            operationName: 'changeChapterFreeSetting',
        });
    }
    // 탭 클릭시 탭에 맞춰 알맞은 api함수 호출.
    async tabClickForStudio(data) {
        const { tab, page, sort, filter } = data;
        let listable = [];
        let totalCount = 0;
        if (tab === consts_1.TabForStoryList.Sample) {
            const res = await this.listSampleStoryForStudio({
                page,
                pageSize: 8,
                sortOption: sort === core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.PUBLISHED_AT_DESC
                    ? core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.STORY_ID_DESC
                    : core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.STORY_ID_ASC,
                filter,
            });
            listable = res.list;
            totalCount = res.totalCount;
        }
        else {
            const sortOption = (() => {
                if ([consts_1.TabForStoryList.UnPublished, consts_1.TabForStoryList.Sample].includes(tab)) {
                    return sort === core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.PUBLISHED_AT_DESC
                        ? core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.STORY_ID_DESC
                        : core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.STORY_ID_ASC;
                }
                return sort;
            })();
            const res = await this.listStoryForWriter({
                page,
                pageSize: 8,
                published: tab !== consts_1.TabForStoryList.UnPublished,
                isFinished: tab === consts_1.TabForStoryList.Finished,
                sortOption,
                filter,
            });
            listable = res.list;
            totalCount = res.totalCount;
        }
        // tslint:disable-next-line:no-object-literal-type-assertion
        return {
            listable,
            totalCount,
        };
    }
    getStoryPlayType(id) {
        return this.query(studio_gqls_1.GQL_GET_STORY_PLAY_TYPE, { id }, {
            operationName: 'getStoryPlayType',
        });
    }
    getWebNovelStoryForStudio(id) {
        return this.query(studio_gqls_1.GQL_GET_WEB_NOVEL_STORY_FOR_STUDIO, { id }, {
            operationName: 'getStoryPlayType',
        });
    }
    async upsertWebNovelChapterDraft(chapterId, bundle, chapterScript, epubFile) {
        return this.mutate(studio_gqls_1.GQL_UPSERT_CHAPTER_DRAFT, [], {
            chapterId,
            bundle,
            chapterScript,
            epubFile,
        }, { pickFirstKey: true, operationName: 'upsertWebNovelChapterDraft' });
    }
    listStoryByAdmin(input) {
        return this.query(studio_gqls_1.GQL_LIST_STORY_BY_ADMIN, input, { operationName: 'listStoryByAdmin' });
    }
    createBanner(input) {
        return this.mutate(studio_gqls_1.GQL_CREATE_BANNER, [{ fieldName: 'bannerImageFile', mimeType: core_1.MIME_TYPE.Image }], this.convertBannerInput(input), {
            pickFirstKey: true,
            operationName: 'createEntityForStudio_banner',
        });
    }
    createBundleBanner(input) {
        return this.mutate(studio_gqls_1.GQL_CREATE_BUNDLE_BANNER, [{ fieldName: 'bannerImageFile', mimeType: core_1.MIME_TYPE.Image }], this.convertBundleBannerInput(input), {
            pickFirstKey: true,
            operationName: 'createEntityForStudio_bundleBanner',
        });
    }
    createBottomBanner(input) {
        return this.mutate(studio_gqls_1.GQL_CREATE_BOTTOM_BANNER, [], input, {
            pickFirstKey: true,
            operationName: 'createEntityForStudio_bottomBanner',
        });
    }
    createMonthlyBundle(input) {
        return this.mutate(studio_gqls_1.GQL_CREATE_MONTHLY_BUNDLE, [], input, {
            operationName: 'createMonthlyBundleByAdmin',
        });
    }
    convertBannerInput(input) {
        const cs = input;
        if (cs.type === core_1.GQLBANNER_TYPE.Story) {
            cs.bannerImageFile = null;
            cs.title = null;
            cs.content = null;
            cs.linkType = null;
            cs.link = null;
            cs.iOSFallbackLink = null;
            cs.aOSFallbackLink = null;
        }
        if (cs.type === core_1.GQLBANNER_TYPE.Custom) {
            cs.storyId = null;
        }
        if ((0, lodash_1.isNull)(cs.linkType)) {
            cs.link = null;
        }
        if (cs.targetClient === core_1.GQLCLIENT.App) {
            cs.aOSFallbackLink = null;
            cs.iOSFallbackLink = null;
        }
        return cs;
    }
    convertBundleBannerInput(input) {
        const cs = input;
        if (cs.type === core_1.GQLBANNER_TYPE.Story) {
            cs.bannerImageFile = null;
            cs.title = null;
            cs.content = null;
            cs.linkType = null;
            cs.link = null;
            cs.iOSFallbackLink = null;
            cs.aOSFallbackLink = null;
        }
        if (cs.type === core_1.GQLBANNER_TYPE.Custom) {
            cs.storyId = null;
        }
        if ((0, lodash_1.isNull)(cs.linkType)) {
            cs.link = null;
        }
        if (cs.targetClient === core_1.GQLCLIENT.App) {
            cs.aOSFallbackLink = null;
            cs.iOSFallbackLink = null;
        }
        return cs;
    }
    listNumPlayOfChapter(data) {
        return this.query(studio_gqls_1.GQL_LIST_NUM_PLAY_OF_CHAPTER, data, {
            operationName: 'listNumPlayOfChapter',
        });
    }
    listNumCommentOfChapter(data) {
        return this.query(studio_gqls_1.GQL_LIST_NUM_COMMENT_OF_CHAPTER, data, {
            operationName: 'listNumCommentOfChapter',
        });
    }
    getLastChapterIndexByStory(storyId) {
        return this.query(studio_gqls_1.GQL_GET_LAST_CHAPTER_INDEX_BY_STORY, { id: storyId }, {
            operationName: 'getLastChapterIndexByStory',
        });
    }
    getPresignedUrlForStoryImport(data) {
        return this.mutate(studio_gqls_1.GQL_GET_PRESIGNED_URL_FOR_STORY_IMPORT, [], data, {
            operationName: 'getPresignedUrlForStoryImportV3',
        });
    }
    getAllContentLanguages() {
        return this.query(studio_gqls_1.GQL_GET_ALL_CONTENTS_LANGUAGES, {
            operationName: 'getAllContentsLanguagesByAdmin',
        });
    }
    getStoryBundleCountriesInfo(bundleId) {
        return this.query(studio_gqls_1.GQL_GET_STORY_BUNDLE_COUNTRIES_INFO, { bundleId }, {
            operationName: 'getStoryBundleCountriesInfoForStudio',
        });
    }
    updateStoryBundleCountries({ bundleId, serviceCountryCodes, storyId, }) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_BUNDLE_COUNTRIES, [], { bundleId, serviceCountryCodes, storyId }, { operationName: 'updateStoryBundleCountries' });
    }
    getServiceCountries() {
        return this.query(studio_gqls_1.GQL_GET_SERVICE_COUNTRIES, {
            operationName: 'getServiceCountriesByAdmin',
        });
    }
    getServiceSystemLanguaes() {
        return this.query(studio_gqls_1.GQL_GET_SERVICE_SYSTEM_LANGUAGES, {
            operationName: 'getServiceSystemLanguagesByAdmin',
        });
    }
    getStoryPriceSetting(storyId, countryCode) {
        return this.query(studio_gqls_1.GQL_STORY_PRICE_SETTING, { storyId, countryCode }, {
            operationName: 'getStoryPriceSetting',
        });
    }
    getNoticeListFilteredTimeLeapKo() {
        return this.query(studio_gqls_1.GQL_LIST_NOTICE_FILTERED_TIMELEAP_KO, {
            operationName: 'listNoticeFilteredTimeLeapKo',
        });
    }
    getNoticeListFilteredTimeLeapEn() {
        return this.query(studio_gqls_1.GQL_LIST_NOTICE_FILTERED_TIMELEAP_EN, {
            operationName: 'listNoticeFilteredTimeLeapEn',
        });
    }
    createBackground({ storyId, imageFile, displayName, }) {
        return this.mutate(studio_gqls_1.GQL_CREATE_BACKGROUND, [], {
            storyId,
            imageFile,
            displayName,
        }, {
            operationName: 'createBackground',
        });
    }
    createStorygameBackground(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_STORYGAME_BACKGROUND, [], data, {
            operationName: 'createStorygameBackground',
        });
    }
    updateStorygameBackgrounds({ id, imageFile, }) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_STORYGAME_BACKGROUND, [], {
            id,
            imageFile,
        }, {
            operationName: 'updateStorygameBackground',
        });
    }
    updateBackground({ id, imageFile, displayName, }) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_BACKGROUND, [], {
            id: id,
            imageFile,
            displayName,
        }, {
            operationName: 'updateBackground',
        });
    }
    createStoryPreviewForStudio({ storyId, fileType, file, }) {
        return this.mutate(studio_gqls_1.GQL_CREATE_STORY_PREVIEW_FOR_STUDIO, [], {
            storyId,
            fileType,
            file,
        }, {
            operationName: 'createStoryPreviewForStudio',
        });
    }
    createTag({ name }) {
        return this.mutate(studio_gqls_1.GQL_CREATE_STORY_TAG_FOR_STUDIO, [], { name }, {
            operationName: 'createStoryTagForStudio',
        });
    }
    searchTags({ name }) {
        return this.query(studio_gqls_1.GQL_SEARCH_STORY_TAG_FOR_STUDIO, { name }, {
            operationName: 'searchStoryTagForStudio',
        });
    }
    getAllStoryGameTabs() {
        return this.query(studio_gqls_1.GQL_GET_ALL_STORY_GAME_TABS, {}, {
            operationName: 'getAllStoryGameTabs',
        });
    }
    updateStoryGameTabOrder(data) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_GAME_TAB_ORDER, [], data, {
            operationName: 'updateStoryGameTabOrder',
        });
    }
    getAllStoryGameSections(data) {
        return this.query(studio_gqls_1.GQL_GET_ALL_STORY_GAME_SECTIONS, data, {
            operationName: 'getAllStoryGameSections',
        });
    }
    getStorygameSection(data) {
        return this.query(studio_gqls_1.GQL_GET_STORY_GAME_SECTION, data, {
            operationName: 'getStorygameSection',
        }, {
            sectionId: { id: data.id },
        });
    }
    createStoryGameSection(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_STORY_GAME_SECTION, [], data, {
            operationName: 'createStoryGameSection',
        });
    }
    updateStoryGameSection(data) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_GAME_SECTION, [], data, {
            operationName: 'updateStoryGameSection',
        }, {
            sectionId: { id: data.id },
        });
    }
    removeStoryGameSection(data) {
        return this.mutate(studio_gqls_1.GQL_REMOVE_STORY_GAME_SECTION, [], data, {
            operationName: 'removeStoryGameSection',
        });
    }
    updateStoryGameSectionOrder(data) {
        return this.mutate(studio_gqls_1.GQL_UPDATE_STORY_GAME_SECTION_ORDER, [], data, {
            operationName: 'updateStoryGameSectionOrder',
        });
    }
    toggleStorygameSectionCountry(data) {
        return this.mutate(studio_gqls_1.GQL_TOGGLE_STORY_GAME_SECTION_COUNTRY, [], data, {
            operationName: 'toggleStorygameSectionCountry',
        });
    }
    createImageFile(data) {
        return this.mutate(studio_gqls_1.GQL_CREATE_IMAGE_FILE, [], data, {
            operationName: 'createImageFile',
        });
    }
    getImageFile(data) {
        return this.query(studio_gqls_1.GQL_GET_IMAGE_FILE, data, {
            operationName: 'getImageFile',
        });
    }
    publishStorygameSection() {
        return this.mutate(studio_gqls_1.GQL_PUBLISH_STORY_GAME_SECTION, [], {}, {
            operationName: 'publishStorygameSection',
        });
    }
    getAllStorygameSectionPreviews(data) {
        return this.query(studio_gqls_1.GQL_GET_ALL_STORY_GAME_SECTION_PREVIEWS, data, {
            operationName: 'getAllStorygameSectionPreviews',
        });
    }
    toggleStorygameCountry(data) {
        return this.mutate(studio_gqls_1.GQL_TOGGLE_STORY_GAME_COUNTRY, [], data, {
            operationName: 'toggleStorygameCountry',
        });
    }
    toggleStorygameLanguage(data) {
        return this.mutate(studio_gqls_1.GQL_TOGGLE_STORY_GAME_LANGUAGE, [], data, {
            operationName: 'toggleStorygameLanguage',
        });
    }
    toggleStorygameVisibility(data) {
        return this.mutate(studio_gqls_1.GQL_TOGGLE_STORY_GAME_VISIBILITY, [], data, {
            operationName: 'toggleStorygameVisibility',
        });
    }
    getStorySimpleInfo(storyId) {
        return this.query(studio_gqls_1.GQL_GET_STORY_SIMPLE_INFO, { id: storyId }, {
            operationName: 'getStorySimpleInfo',
        });
    }
}
exports.StudioAPIServer = StudioAPIServer;
