import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLStoryHasBackground } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IStoryHasBackgroundListableOption } from '../BackgroundCommon'

export function useStoryHasBackgroundListDataLoader(storyId: number) {
  const { listBackgroundOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IStoryHasBackgroundListableOption
    ): Promise<ODListableResponseType<GQLStoryHasBackground>> {
      const r = await listBackgroundOfStory({
        page,
        pageSize: 100,
        storyId,
        ...options,
      })
      return r as ODListableResponseType<GQLStoryHasBackground>
    },
    [listBackgroundOfStory, storyId]
  )
}
