"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOMainChrOnboardingPopupEditor = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const ui_1 = require("../ui");
const DOOnboardingPopup_1 = require("./DOOnboardingPopup");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOMainChrOnboardingPopupEditor {
    constructor(editStore, data) {
        var _a, _b, _c, _d;
        this.isActive = true;
        this.onboardingPopup = null;
        this.editStore = editStore;
        this.chr = editStore.chr;
        this.onboardingPopup = data ? new DOOnboardingPopup_1.DOOnboardingPopup(data) : null;
        this.isOnlyName =
            (_b = (_a = this.onboardingPopup) === null || _a === void 0 ? void 0 : _a.onlyName) !== null && _b !== void 0 ? _b : !this.chr.defaultLastName;
        this.isFixMainChrName = !data;
        this.descriptionField = new ui_1.InputWithValidation('description', trans('legacy_BlockEditorInput_passage_from_a_novel'), trans('legacy_DOChrOnboardingPopupEditor_Please_enter_the_fingerprint'), 'text', (_d = (_c = this.onboardingPopup) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : trans('legacy_DOMainChrOnboardingPopupEditor_Please_write_your_name'));
        (0, mobx_1.makeObservable)(this, {
            isOnlyName: mobx_1.observable,
            isFixMainChrName: mobx_1.observable,
            onboardingPopup: mobx_1.observable,
        });
    }
    setIsOnlyName() {
        (0, mobx_1.runInAction)(() => {
            this.isOnlyName = !this.isOnlyName;
        });
    }
    setIsFixMainChrName(isFix) {
        (0, mobx_1.runInAction)(() => {
            this.isFixMainChrName = isFix;
        });
    }
    prepareSubmit() {
        if (this.isFixMainChrName) {
            return;
        }
        if (this.editStore.defaultFirstName.value.trim().length === 0) {
            throw new core_1.SPCError(core_1.ErrorCode.ChrOnboardingRequireFirstName);
        }
        if (!this.isOnlyName &&
            this.editStore.defaultLastName.value.trim().length === 0) {
            throw new core_1.SPCError(core_1.ErrorCode.ChrOnboardingRequireLastName);
        }
    }
    async submit() {
        this.prepareSubmit();
        const server = this.editStore.rootStore.di.server;
        const res = await server.upsertMainChrOnboardingPopup({
            storyId: this.chr.store.story.storyId,
            isFixMainChrName: this.isFixMainChrName,
            onlyName: this.isOnlyName,
            description: this.descriptionField.value.trim(),
        });
        (0, mobx_1.runInAction)(() => {
            this.onboardingPopup = new DOOnboardingPopup_1.DOOnboardingPopup(res);
            this.chr.store.story.isFixMainChrName = this.isFixMainChrName;
        });
    }
}
exports.DOMainChrOnboardingPopupEditor = DOMainChrOnboardingPopupEditor;
