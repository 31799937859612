import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLPreviewEnding } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IPreviewEndingListableOption } from '../PreviewEndingCommon'

export function usePreviewEndingListDataLoader(storyId: number) {
  const { listPreviewEndingOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IPreviewEndingListableOption
    ): Promise<ODListableResponseType<GQLPreviewEnding>> {
      const r = await listPreviewEndingOfStory({
        page,
        pageSize: 100,
        storyId,
        ...options,
      })
      return r as ODListableResponseType<GQLPreviewEnding>
    },
    [listPreviewEndingOfStory, storyId]
  )
}
