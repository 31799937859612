"use strict";
/**
 * 현재 스크립트 시트만으로는 설정이 불가능하여 어드민을 통한 관리만 가능한 내용들을
 * _관리_ 시트를 통해서 관리할 수 있도록 정의하는 인터페이스
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvertedMetaDataActionSheetCommand = exports.MetaDataActionSheetCommand = exports.MetaDataAction = void 0;
const lodash_1 = require("lodash");
var MetaDataAction;
(function (MetaDataAction) {
    MetaDataAction["PropSetMain"] = "PropSetMain";
    MetaDataAction["EndingSetImage"] = "EndingSetImage";
    MetaDataAction["BackgroundSetImage"] = "BackgroundSetImage";
    MetaDataAction["ChrSetProperties"] = "ChrSetProperties";
})(MetaDataAction = exports.MetaDataAction || (exports.MetaDataAction = {}));
exports.MetaDataActionSheetCommand = {
    [MetaDataAction.PropSetMain]: '속성_핵심속성',
    [MetaDataAction.EndingSetImage]: '엔딩_이미지',
    [MetaDataAction.BackgroundSetImage]: '배경_이미지',
    [MetaDataAction.ChrSetProperties]: '등장_인물',
};
exports.InvertedMetaDataActionSheetCommand = (0, lodash_1.invert)(exports.MetaDataActionSheetCommand);
