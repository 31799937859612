"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTCallRemoteScriptSubBlock = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const __1 = require("../../../../../..");
const parser_1 = require("../../../../../../playData/formula/parser");
const SLGParser_1 = require("../../../../../../slg/SLGParser");
const IFlowChartPositionCalculator_1 = require("../../../chapter/IFlowChartPositionCalculator");
const SelectionFieldWithUI_1 = require("../../../fields/SelectionFieldWithUI");
const BlockEditableUtils_1 = require("../../../ui/BlockEditableUtils");
const BlockEditorInput_interface_1 = require("../../../ui/BlockEditorInput.interface");
const IBlockEditable_1 = require("../../../ui/IBlockEditable");
const DOBaseScriptSubBlock_1 = require("../../DOBaseScriptSubBlock");
const DOScriptStatementFactory_1 = require("../../DOScriptStatementFactory");
const IDOStatement_1 = require("../../IDOStatement");
const ACCEPTABLE_STATEMENTS = [
    __1.STATEMENT_TYPE.CallRemoteScript,
    __1.STATEMENT_TYPE.FinishRemoteScript,
    ...SLGParser_1.SLGSupportStatementTypes,
];
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTCallRemoteScriptSubBlock extends DOBaseScriptSubBlock_1.DOBaseScriptSubBlock {
    constructor(statements, block, uniqueId) {
        super(statements, block, IDOStatement_1.DOSubBlockType.CallRemoteScript, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.CallRemoteScript;
        // 향후 스니펫이 구현되게 되면 computed 로 처리하여 챗봇과 스니펫 구분해야 한다.
        this.blockTagType = IDOStatement_1.BlockTagType.ChatBotBlock;
        this.didSetupFields = false;
        // 헬봇일때 사용할 메시지 순서 변경에 따른 로딩(여기서는 사용할 일 없음, 인터페이스 맞추기)
        this.isChangingOrder = false;
        this.inputButtonsToHide = [
            BlockEditorInput_interface_1.BlockEditorInputButtonType.BGM,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.BackgroundImage,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Choice,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Condition,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.GoToBlock,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.CallChatBot,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Ending,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.FinalEnding,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Template,
        ];
        queueMicrotask(() => this.setupFields());
        (0, mobx_1.makeObservable)(this, {
            hasFallbackStatements: mobx_1.computed,
            isFeatureEnabled: mobx_1.computed,
            startingBackground: mobx_1.computed,
            setupFields: mobx_1.action,
            blocksFrom: mobx_1.computed,
            nodeName: mobx_1.computed,
            nodeNamesFrom: mobx_1.computed,
            nodesTo: mobx_1.computed,
        });
    }
    async onClickScriptId() {
        if (this.typeInput.value !== core_1.REMOTE_SCRIPT_TYPE.ChatGPT) {
            return;
        }
        await this.di.copyToClipboard(`{{${trans('legacy_DOSTCallRemoteScriptSubBlock_property_userRemoteScript', { value: this.stCallRemoteScript.st.scriptId })}`);
        this.di.showMessage(trans('legacy_DOSTCallRemoteScriptSubBlock_chatgpt_answer_property_copied'));
    }
    // 챕터가 draft 가 아닐때만 fallbackScript 를 불러와 반환합니다.
    async loadFallbackScript() {
        const hasDraft = this.block.parentChapter.hasDraft;
        // 해당 섭블록이 생성될 때
        // + 헬로우봇 타입 및 스토리플레이 타입 일 때
        // provider 가 있는상태라면 이미 존재하는 데이터
        // + chatGPT 타입일 때
        // prompt 가 있는상태라면 이미 존재하는 데이터
        const doesExist = !!this.stCallRemoteScript.st.provider ||
            !!this.stCallRemoteScript.st.prompt;
        if (!hasDraft && doesExist) {
            try {
                const res = await this.rootStore.di.server.getFallbackRemoteScriptByStudio({
                    chapterId: this.parentChapter.id,
                    remoteScriptId: this.stCallRemoteScript.st.scriptId,
                });
                return JSON.parse(res.statements);
            }
            catch (ex) {
                this.rootStore.showError(ex);
            }
        }
        return [];
    }
    get isFeatureEnabled() {
        return this.rootStore.di.isFeatureEnabled(__1.FEATURE_FLAG.CHAT_BOT_BLOCK);
    }
    mergeStatement(statement) {
        var _a;
        const hasFinishStatement = ((_a = (0, lodash_1.last)(this.statements)) === null || _a === void 0 ? void 0 : _a.statementType) ===
            __1.STATEMENT_TYPE.FinishRemoteScript;
        if (statement.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            ACCEPTABLE_STATEMENTS.includes(statement.statementType) &&
            !hasFinishStatement) {
            (0, mobx_1.runInAction)(() => {
                this.statements.push(statement);
            });
            return true;
        }
        return false;
    }
    validate() {
        const results = super.validate();
        // 챗봇블럭에 아무 것도 입력되지 않으면 [EditorLine, SingleLineScript, SingleLineScript] 로 구성되어 있다.
        if (this.statements.length === 3) {
            results.push({
                type: __1.StudioValidationType.RemoteScriptHasNoFallbackScript,
                source: this,
                severity: __1.StudioValidationSeverity.Error,
                stack: [],
                extra: {},
            });
        }
        return results;
    }
    fixStatements() {
        //
    }
    getOrderForChoiceStatement(st) {
        return 0;
    }
    get remoteScriptId() {
        return this.stCallRemoteScript.st.scriptId;
    }
    get stCallRemoteScript() {
        return this.statements.find(v => v instanceof __1.DOSTCallRemoteScript);
    }
    get stFinishRemoteScript() {
        return this.statements.find(v => v instanceof __1.DOSTFinishRemoteScript);
    }
    onLoadedFromBundle(bundle) {
        super.onLoadedFromBundle(bundle);
        this.setupFields();
    }
    /**
     * 외부에서 호출하기 위한 용도는 아니나, sub-statement 에서 호출해주어야 하여 public 으로 둡니다.
     */
    async setupFields(forceSetup = false) {
        if (this.didSetupFields && !forceSetup) {
            return;
        }
        this.didSetupFields = true;
        this.typeInput = new SelectionFieldWithUI_1.SelectionFieldWithUI('remoteScriptType', () => this.stCallRemoteScript.st.remoteScriptType, value => {
            (0, mobx_1.runInAction)(() => {
                this.stCallRemoteScript.st.remoteScriptType = value;
            });
        }, {
            label: trans('legacy_DOSTCallRemoteScriptSubBlock_set_chatbot_type'),
            selection: Object.values(core_1.REMOTE_SCRIPT_TYPE)
                .filter(item => {
                if (!this.rootStore.di.isFeatureEnabled(__1.FEATURE_FLAG.REMOTE_SCRIPT_CHAT_GPT)) {
                    return item === core_1.REMOTE_SCRIPT_TYPE.HelloBot;
                }
                return true;
            })
                .map(item => ({
                name: item,
                value: item,
            })),
            options: {
                placeholder: trans('legacy_DOSTCallRemoteScriptSubBlock_please_set_chatbot_type'),
                noLabel: true,
                onChangeBeforeSubmit: value => {
                    this.providerInput.onChange('');
                    // change 가 완료된 이후에 호출한다.
                    // change 가 된 이후에 호출될 함수가 필요한데 지금은 microtask queue 에 집어넣는 것으로 대체
                    queueMicrotask(() => {
                        this.providerInput.input.loadOptionsByInputValue('');
                    });
                },
            },
        });
        this.providerInput = new SelectionFieldWithUI_1.SelectionFieldWithUI('provider', () => { var _a; return (_a = this.stCallRemoteScript.st.provider) !== null && _a !== void 0 ? _a : ''; }, value => {
            (0, mobx_1.runInAction)(() => {
                this.stCallRemoteScript.st.provider = value;
            });
        }, {
            label: trans('legacy_DOSTCallRemoteScriptSubBlock_chatbot_id'),
            selection: [],
            options: {
                placeholder: trans('legacy_DOSTCallRemoteScriptSubBlock_enter_chatbot_id'),
                noLabel: true,
                loadOptionsByInputValue: async (v) => {
                    if (this.typeInput.value === core_1.REMOTE_SCRIPT_TYPE.StoryPlay) {
                        return [{ name: 'CustomScript20203', value: 'CustomScript20203' }];
                    }
                    try {
                        let input = {
                            page: 1,
                            pageSize: 100,
                        };
                        if (!!v) {
                            input = { ...input, filter: v };
                        }
                        const res = await this.rootStore.di.server.listRemoteScriptProvider(input);
                        return res.list.map(item => ({
                            name: `${item.provider}: ${item.description}`,
                            value: item.provider,
                        }));
                    }
                    catch (ex) {
                        this.rootStore.showError(ex);
                        return [];
                    }
                },
            },
        });
        this.formulaInput = new __1.TextFieldWithUI('params1', () => this.rootStore.textStore.encodeSPExprFormulaAfterParse(!!this.stCallRemoteScript.st.params &&
            this.stCallRemoteScript.st.params.length > 0
            ? this.stCallRemoteScript.st.params[0]
            : '""'), value => {
            // TODO: multiple params 지원
            this.stCallRemoteScript.st.params = [
                JSON.stringify(this.rootStore.textStore.parseFormulaWithFallback(value)),
            ];
        }, {
            label: trans('legacy_DOSTCallRemoteScriptSubBlock_chatbot_parameter1'),
            placeholder: trans('legacy_DOSTCallRemoteScriptSubBlock_enter_value_for_chatbot_parameter1'),
        });
        this.promptInput = new __1.TextFieldWithUI('prompt', () => { var _a; return (_a = this.stCallRemoteScript.st.prompt) !== null && _a !== void 0 ? _a : ''; }, value => {
            this.stCallRemoteScript.st.prompt = value;
        }, {
            label: trans('legacy_DOSTCallRemoteScriptSubBlock_chatgpt_answer_setting'),
            placeholder: trans('legacy_DOSTCallRemoteScriptSubBlock_write_prompt'),
        });
        this.scriptFormInput = new __1.TextFieldWithUI('scriptForm', () => { var _a; return (_a = this.stCallRemoteScript.st.scriptForm) !== null && _a !== void 0 ? _a : ''; }, value => {
            this.stCallRemoteScript.st.scriptForm = value;
        });
        const fallbackScripts = await this.loadFallbackScript();
        // 반환되는 값이 있을 때, 즉 draft 가 아니면서 fallbackScript 가 서버에 저장되어 있을 때
        // fallbackScript 를 사이에 넣어줍니다.
        if (fallbackScripts.length > 0) {
            const convertedFallbackScripts = [];
            for (const st of fallbackScripts) {
                const obj = (0, DOScriptStatementFactory_1.mapStatementToDomainObject)(st, this.block, 
                // 엑셀시트에서 "챕터 아이디"라는 스튜디오에서 사용하지 않는 코드가 있기 때문에 존재, fallback 스크립트에는 해당 구문이 나올 일이 없으므로 다음과 같이 신경 X
                () => null, undefined, false);
                if (!obj) {
                    continue;
                }
                convertedFallbackScripts.push(obj);
            }
            (0, mobx_1.runInAction)(() => {
                this.statements = [
                    this.stCallRemoteScript,
                    ...convertedFallbackScripts,
                    this.stFinishRemoteScript,
                ];
            });
        }
        (0, mobx_1.runInAction)(() => {
            this.statements.unshift(new __1.DOBlockHeadStatement(this));
        });
    }
    // stCallRemoteScript 의 background 가 변경될 때마다 반영되도록
    get startingBackground() {
        var _a;
        return (_a = this.stCallRemoteScript.background) !== null && _a !== void 0 ? _a : '';
    }
    get isPublishedEnding() {
        return !!this.block.parentChapter.publishedAt;
    }
    startEditing() {
        this.stFinishRemoteScript.updateBlockOptions();
        this.editorFields.forEach(fld => fld.revertChanges());
        return super.startEditing();
    }
    get editorFields() {
        return [
            ...super.editorFields,
            this.providerInput,
            this.formulaInput,
            this.typeInput,
            this.promptInput,
            this.scriptFormInput,
        ];
    }
    async submitEditing() {
        var _a;
        const op = this.helper.opFactory().startBulk();
        const blockOptions = this.stFinishRemoteScript.blockOptions;
        const blockTo = blockOptions.value instanceof __1.DOBlock
            ? blockOptions.value.id
            : (_a = blockOptions.value) !== null && _a !== void 0 ? _a : __1.BLOCK_EMPTY;
        op.createNewBlock(blockOptions.value, this.background);
        if (this.stFinishRemoteScript.st.toBlockAfter !== blockTo) {
            op.changeToBlockToBlock(this.block.uniqueId, this.uniqueId, blockTo);
        }
        const r = await super.submitEditing(op);
        this.stFinishRemoteScript.updateBlockOptions();
        // // 장면 연결 수정시 현 위치를 연결된 블럭의 첫번째로 이동시킨다.
        // if (
        //   this.blockTo &&
        //   this.rootStore.chapterEditing &&
        //   this.rootStore.chapterEditing.blockEditor
        // ) {
        //   this.rootStore.chapterEditing.blockEditor.onBlockClickedForJump(
        //     this.parentBlock,
        //     this.blockTo.name
        //   )
        //
        //   if (this.blockTo.statements.length > 0) {
        //     this.rootStore.chapterEditing.blockEditor.onLineClickedForCursorMove(
        //       this.blockTo.statements[0]
        //     )
        //   }
        // }
        return r;
    }
    async applyChangeOp(op, type) {
        switch (op.opType) {
            case __1.StudioChangeOpType.ChangeToBlockToBlock:
                return this.changeToBlockInternal(op.blockName);
            default:
                break;
        }
        return super.applyChangeOp(op, type);
    }
    changeToBlockInternal(toBlockName) {
        const prevBlockName = this.stFinishRemoteScript.st.toBlockAfter;
        (0, mobx_1.runInAction)(() => {
            this.stFinishRemoteScript.changeToBlockAfterAtStatement(toBlockName);
        });
        this.stFinishRemoteScript.updateBlockOptions();
        return {
            reverse: {
                opType: __1.StudioChangeOpType.ChangeToBlockToBlock,
                target: __1.StudioChangeOpTarget.Block,
                blockUniqueId: this.block.uniqueId,
                blockName: prevBlockName,
                lineUniqueId: this.uniqueId,
            },
            lineToFocus: this,
        };
    }
    cancelEditing() {
        super.cancelEditing();
        this.stFinishRemoteScript.updateBlockOptions();
    }
    //
    // IFlowChartNode
    //
    get nodeName() {
        return this.remoteScriptId;
    }
    get nodeType() {
        return IFlowChartPositionCalculator_1.FlowChartNodeType.RemoteScriptSubBlock;
    }
    shouldComeBeforeOnFlowChart(otherBlock) {
        if (otherBlock.nodeType === IFlowChartPositionCalculator_1.FlowChartNodeType.Block) {
            return false;
        }
        return true;
    }
    get nodeNamesFrom() {
        return this.blocksFrom.map(item => item.nodeName);
    }
    get nodesTo() {
        return this.blocksTo.map(item => ({
            label: item.label,
            extra: item.extra,
            nodeName: item.block.nodeName,
        }));
    }
    get blocksTo() {
        return (0, lodash_1.flatten)(this.statements.map(st => st.blocksTo));
    }
    get blocksFrom() {
        // 원격 스크립트 서브블록을 포함하고 있는 블록이 나에게 연결되어야 한다.
        return [this.block];
    }
    //
    // IBlockEditable
    //
    get id() {
        return this.remoteScriptId;
    }
    get name() {
        return this.remoteScriptId;
    }
    get blockEditableType() {
        return IBlockEditable_1.BlockEditableType.CallRemoteScriptSubBlock;
    }
    get canChangeName() {
        return false;
    }
    changeName(name) {
        // cannot change name
    }
    get doBlock() {
        return this.block;
    }
    get canUpsertNewEndBlock() {
        return false;
    }
    /**
     * 입력된 값이 없을 때에는 null, 그 외에는 유효성 여부를 반환한다.
     */
    validateFormula() {
        if (this.formulaInput.value.length === 0) {
            return __1.SPExprFormulaStatus.None;
        }
        try {
            (0, parser_1.parseSPExprFormula)(this.formulaInput.value);
            return __1.SPExprFormulaStatus.Ok;
        }
        catch {
            return __1.SPExprFormulaStatus.Fail;
        }
    }
    upsertNewEndBlock(endBlockType, openAfterChange) {
        this.rootStore.di.showError(trans('legacy_DOSTCallRemoteScriptSubBlock_cannot_add_sentence_to_block'));
    }
    canChangeOrderUp(index) {
        const targetIndex = index - 1;
        // 0 번째 Head
        // 1 번째 CallRemoteScript
        return targetIndex >= 2;
    }
    canChangeOrderDown(index) {
        const targetIndex = index + 1;
        // 마지막 문장은 FinishRemoteScript 고정
        return targetIndex < this.statements.length - 1;
    }
    changeOrderUpOf(line) {
        const index = this.statements.indexOf(line);
        if (!this.canChangeOrderUp(index)) {
            return;
        }
        (0, BlockEditableUtils_1.changeOrderOf)(this, line, true, this.rootStore);
    }
    changeOrderDownOf(line) {
        const index = this.statements.indexOf(line);
        if (!this.canChangeOrderDown(index)) {
            return;
        }
        (0, BlockEditableUtils_1.changeOrderOf)(this, line, false, this.rootStore);
    }
    get isRemovable() {
        return false;
    }
    addStatementAtIndex(line, indexToAdd) {
        this.parentChapter
            .applyChangesOnChapter(__1.StudioChangeOpFactory.addLinesToSubBlock(this.block.uniqueId, this.uniqueId, 
        // Op 의 버그라고 볼 수 있는데, addLinesToSubBlock 은 Head statement 가
        // 존재하면 index 를 +1 해준다. 하지만 BlockEditorStore 에서 전달하는
        // indexToAdd 는 해당 문장을 포함한 index 로 전달된다.
        // 이 서브블록은 헤드를 무조건 갖고 있으므로 강제로 -1을 해준다.
        [{ line, index: indexToAdd - 1 }]))
            .catch(); // ignore returned promise.
    }
    getBackgroundOfStatement(statementOrIndex) {
        return (0, BlockEditableUtils_1.getBackgroundOfStatement)(this, statementOrIndex);
    }
    changeStartingBackground(backgroundName) {
        new __1.StudioChangeOpFactory(this.parentChapter)
            .changeBlockStartingBackground(this.uniqueId, backgroundName)
            .submitSingle()
            .catch();
    }
    /**
     * Fallback 용 문장 (서버에서 원격 스크립트 오류 발생시 사용할 스크립트)이 존재하는가?
     */
    get hasFallbackStatements() {
        // current line 이 있을 수도 있어서 length 로 체크하기는 어렵다.
        return !!this.statements.find(st => st.isScript &&
            ![
                __1.STATEMENT_TYPE.CallRemoteScript,
                __1.STATEMENT_TYPE.FinishRemoteScript,
            ].includes(st.statementType));
    }
    /**
     * 이 SubBlock 은 IEditableBlock 이므로 붙여넣기 이벤트에 반응해야 한다.
     */
    async onPasteEvent(data) {
        return (0, BlockEditableUtils_1.handleBlockEditablePasteEvent)(this, data);
    }
    canContainStatement(statement) {
        if (statement.lineType !== IDOStatement_1.UILineType.SingleLineScript) {
            return false;
        }
        const stType = statement.statementType;
        // BackgroundImage 문장은 export 할 때 처리되고 사라지므로 한줄문법에 없어도
        // 처리가 가능하다.
        return (stType === __1.STATEMENT_TYPE.BackgroundImage ||
            SLGParser_1.SLGSupportStatementTypes.includes(stType));
    }
}
exports.DOSTCallRemoteScriptSubBlock = DOSTCallRemoteScriptSubBlock;
