"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storyplayDiConstants = exports.hellobotDiConstants = void 0;
const hellobotDiConstants = {
    mainCharacterName: '사용자'
};
exports.hellobotDiConstants = hellobotDiConstants;
const storyplayDiConstants = {
    mainCharacterName: '주인공'
};
exports.storyplayDiConstants = storyplayDiConstants;
