import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import {
  GQLSTORY_HAS_RECOMMENDATION_TYPE,
  GQLStoryHasRecommendSection,
} from '@storyplay/core'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { HoverTR } from '../../../components/commonStyle'
import { SiteUrls } from '../../../urls'
import {
  IStoryHasRecommendSectionListableOption,
  StoryHasRecommendSectionPrimaryKey,
} from '../StoryHasRecommendSectionCommon'
import { useStoryHasRecommendSectionListDataLoader } from './StoryHasRecommendSectionDataLoader'

interface IStoryHasRecommendSectionListableTableProps {
  storyId: number
}

export const StoryHasRecommendSectionListableTable: React.FC<
  IStoryHasRecommendSectionListableTableProps
> = (props) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<
    GQLStoryHasRecommendSection,
    IStoryHasRecommendSectionListableOption
  >()
  const [token, refresh] = useCounter()
  const dataLoader = useStoryHasRecommendSectionListDataLoader(storyId)
  const [storyHasRecommendSectionId, setStoryHasRecommendSectionId] =
    React.useState(0)
  const [title, setTitle] = React.useState('')
  const [type, setType] = React.useState<GQLSTORY_HAS_RECOMMENDATION_TYPE>(
    GQLSTORY_HAS_RECOMMENDATION_TYPE.AdminSelect
  )
  const [redirect, setRedirect] = React.useState('')
  if (redirect) {
    return <Redirect to={redirect} />
  }
  return (
    <>
      <Provider
        dataLoader={dataLoader}
        keyExtractor={(v) => v[StoryHasRecommendSectionPrimaryKey].toString()}
        pageSize={10}
        refreshToken={token.toString()}
        onDataLoaderError={ODWebUtils.showError}
        searchOnLoad
      >
        <Card style={{ flexGrow: 2 }}>
          <CardBody>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 15,
                float: 'right',
                marginLeft: 15,
              }}
            >
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{ minWidth: 135, borderRadius: 5, height: 35 }}
                onClick={() =>
                  setRedirect(
                    SiteUrls.Admin.EPUBStory.EditStoryHasRecommendSection(storyId, 0)
                  )
                }
              >
                추천 섹션 생성
              </ODButton>
            </div>
            <ODListablePaginatedTable2
              numColumns={11}
              listableContext={Context}
              renderHeader={() => (
                <tr>
                  <th>섹션 아이디</th>
                  <th>타입</th>
                  <th>회차</th>
                  <th>타이틀</th>
                  <th>서브 타이틀</th>
                </tr>
              )}
              renderRow={(value: GQLStoryHasRecommendSection, context) => (
                <>
                  <HoverTR
                    key={value.storyHasRecommendSectionId}
                    onClick={() =>
                      setRedirect(
                        SiteUrls.Admin.EPUBStory.EditStoryHasRecommendSection(
                          storyId,
                          value.storyHasRecommendSectionId
                        )
                      )
                    }
                  >
                    <td>{value.storyHasRecommendSectionId}</td>
                    <td>{value.type}</td>
                    <td>{value.chapter?.name ?? '없음'}</td>
                    <td>{value.title}</td>
                    <td>{value.subTitle}</td>
                  </HoverTR>
                </>
              )}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage listableContext={Context} />
          </CardBody>
        </Card>
      </Provider>
    </>
  )
}
