"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOChapterEditor = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const EntityEditor_1 = require("../EntityEditor");
const fields_1 = require("../fields");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOChapterEditor extends EntityEditor_1.EntityEditor {
    constructor(chapter) {
        super(chapter);
        const stringFields = [
            {
                key: 'name',
                options: {
                    label: trans('legacy_DOChapterEditor_work_name'),
                    placeholder: trans('legacy_DOChapterEditor_enter_work_name'),
                    options: { noLabel: true },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new fields_1.TextFieldWithUI(fld.key, () => chapter[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        this.nameField.input.setRequired(true);
        this.nameField.input.setAutoFocus();
        (0, mobx_1.makeObservable)(this, {
        //
        });
    }
    get rootStore() {
        return this._target.story.rootStore;
    }
    get chapterId() {
        return this._target.id;
    }
}
exports.DOChapterEditor = DOChapterEditor;
