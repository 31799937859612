"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTMetaSaveProp = exports.MetaSavePropPropType = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../../@types/server.schema");
const consts_1 = require("../../../../../consts");
const models_1 = require("../../../../../models");
const parser_1 = require("../../../../../playData/formula/parser");
const scripter_1 = require("../../../../../scripter/scripter");
const validations_1 = require("../../../../validations");
const interface_1 = require("../../../interface");
const storeUtils_1 = require("../../../utils/storeUtils");
const validation_1 = require("../../../validation");
const fields_1 = require("../../fields");
const PropOpFieldWithUI_1 = require("../../fields/PropOpFieldWithUI");
const SelectionFieldWithUI_1 = require("../../fields/SelectionFieldWithUI");
const DOStoryItemEditStore_1 = require("../../storyItem/DOStoryItemEditStore");
const DOStoryPropertyEditStore_1 = require("../../storyProperty/DOStoryPropertyEditStore");
const studioTutorial_1 = require("../../studioTutorial");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const IDOStatement_1 = require("../IDOStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
var MetaSavePropPropType;
(function (MetaSavePropPropType) {
    MetaSavePropPropType["Prop"] = "Prop";
    MetaSavePropPropType["MainProp"] = "MainProp";
    MetaSavePropPropType["Item"] = "Item";
    MetaSavePropPropType["Achievement"] = "Achievement";
})(MetaSavePropPropType = exports.MetaSavePropPropType || (exports.MetaSavePropPropType = {}));
/**
 * 내부의 편집 과정에서 자유롭게 편집이 되도록 데이터를 수정한다.
 */
function makeUnionStatement(st) {
    var _a, _b, _c;
    st.propUpdate = st.propUpdate || {
        propName: IDOStatement_1.PROP_EMPTY,
        propOp: models_1.PROP_OPERATION.SET_NUMBER,
        value: '0',
    };
    st.itemUpdate = st.itemUpdate || {
        itemName: IDOStatement_1.ITEM_EMPTY,
        itemOp: models_1.PROP_OPERATION.SET_NUMBER,
        itemCount: '0',
    };
    st.event = (_a = st.event) !== null && _a !== void 0 ? _a : IDOStatement_1.ACHIEVEMENT_EMPTY;
    st.numParam1 = (_b = st.numParam1) !== null && _b !== void 0 ? _b : '0';
    st.numParam2 = (_c = st.numParam2) !== null && _c !== void 0 ? _c : '0';
    return st;
}
class DOSTMetaSaveProp extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(makeUnionStatement(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: models_1.STATEMENT_TYPE.SaveProp,
            background: '',
            propUpdate: {
                propName: block.parentChapter
                    .getStory()
                    .storyPropertyStore.getByName('', false).name,
                propOp: models_1.PROP_OPERATION.SET_NUMBER,
                value: '0',
            },
        }), block, uniqueId);
        this.hasModalForEdit = true;
        // 속성을 편집할 일이 있을 때 설정된다.
        this.propEditor = null;
        // 아이템을 편집할 일이 있을 때 설정된다.
        this.itemEditor = null;
        this.currentPropType = this.determineMetaPropType();
        const propTypeSelection = [
            {
                name: trans('legacy_DOSTChoiceIf_common'),
                value: MetaSavePropPropType.Prop,
            },
            {
                name: trans('legacy_DOSTChoiceIf_my_value'),
                value: MetaSavePropPropType.MainProp,
            },
            {
                name: trans('legacy_SLGParser_item'),
                value: MetaSavePropPropType.Item,
            },
        ];
        if (!!block.story.canUseAchievement) {
            propTypeSelection.push({
                name: trans('legacy_DOSTChoiceIf_achievement'),
                value: MetaSavePropPropType.Achievement,
            });
        }
        this.propTypeField = new SelectionFieldWithUI_1.SelectionFieldWithUI('savePropPropType', () => this.currentPropType, propType => (0, mobx_1.runInAction)(() => {
            this.changePropType(propType);
            this.changeStatementTypeToCurrentPropType();
        }), {
            label: trans('legacy_DOSTChoiceIf_division'),
            selection: propTypeSelection,
            options: {
                creatable: false,
                onChangeBeforeSubmit: (value) => {
                    this.changePropType(value);
                    this.propValueField.onChange('0');
                    if (!this.isPropStringFeatureEnabled()) {
                        return;
                    }
                    if (value === MetaSavePropPropType.Prop ||
                        value === MetaSavePropPropType.MainProp) {
                        const prop = value === MetaSavePropPropType.MainProp
                            ? this.mainPropNameField.value
                            : this.propNameField.value;
                        if (!!prop) {
                            this.propOpField.onChange(prop.propType === server_schema_1.GQLSTORY_PROP_TYPE.STRING
                                ? IDOStatement_1.CommonPropOperation.SET_TEXT
                                : IDOStatement_1.CommonPropOperation.SET_NUMBER);
                        }
                    }
                    if (value === MetaSavePropPropType.Item) {
                        this.propOpField.onChange(IDOStatement_1.CommonPropOperation.SET_NUMBER);
                    }
                },
            },
        });
        const chapter = this.block.parentChapter;
        const story = chapter.getStory();
        this.propNameField = new fields_1.PropFieldWithUI(story.storyPropertyStore, false, 'prop', () => {
            var _a;
            return story.storyPropertyStore.getByName((_a = this.stSaveProp.propUpdate) === null || _a === void 0 ? void 0 : _a.propName);
        }, prop => {
            (0, mobx_1.runInAction)(() => (this.stSaveProp.propUpdate.propName = prop.propName));
        }, {
            options: {
                creatable: true,
                onChangeBeforeSubmit: prop => {
                    if (!this.isPropStringFeatureEnabled()) {
                        return;
                    }
                    this.propValueField.onChange('0');
                    this.propOpField.onChange(prop.propType === server_schema_1.GQLSTORY_PROP_TYPE.STRING
                        ? IDOStatement_1.CommonPropOperation.SET_TEXT
                        : IDOStatement_1.CommonPropOperation.SET_NUMBER);
                },
                onCreateBeforeSubmit: name => {
                    this.showPropEditor(name, false);
                },
            },
        });
        this.mainPropNameField = new fields_1.PropFieldWithUI(story.storyPropertyStore, true, 'mainProp', () => {
            var _a;
            return story.storyPropertyStore.getByName((_a = this.stSaveProp.propUpdate) === null || _a === void 0 ? void 0 : _a.propName, true);
        }, prop => (0, mobx_1.runInAction)(() => (this.stSaveProp.propUpdate.propName = prop.propName)), {
            options: {
                creatable: true,
                onChangeBeforeSubmit: prop => {
                    if (!this.isPropStringFeatureEnabled()) {
                        return;
                    }
                    this.propValueField.onChange('0');
                    this.propOpField.onChange(prop.propType === server_schema_1.GQLSTORY_PROP_TYPE.STRING
                        ? IDOStatement_1.CommonPropOperation.SET_TEXT
                        : IDOStatement_1.CommonPropOperation.SET_NUMBER);
                },
                onCreateBeforeSubmit: name => {
                    this.showPropEditor(name, true);
                },
            },
        });
        this.itemNameField = new fields_1.ItemFieldWithUI(story.storyItemStore, 'item', () => { var _a; return story.storyItemStore.getByName((_a = this.stItem.itemUpdate) === null || _a === void 0 ? void 0 : _a.itemName); }, item => (0, mobx_1.runInAction)(() => (this.stItem.itemUpdate.itemName = item.name)), {
            options: {
                creatable: true,
                onCreateBeforeSubmit: name => {
                    this.showItemEditor(name);
                },
            },
        });
        this.aEventField = new fields_1.AEventFieldWithUI(story.aEventDefStore, 'aEvent', () => story.aEventDefStore.getByName(this.stAchievement.event), ev => (0, mobx_1.runInAction)(() => (this.stAchievement.event = ev.name)));
        this.propOpField = new PropOpFieldWithUI_1.PropOpFieldWithUI('op', () => {
            var _a;
            return ((_a = (this.currentPropType === MetaSavePropPropType.Item
                ? this.stItem.itemUpdate.itemOp
                : this.stSaveProp.propUpdate.propOp)) !== null && _a !== void 0 ? _a : IDOStatement_1.CommonPropOperation.INCREASE_NUMBER);
        }, v => {
            (0, mobx_1.runInAction)(() => {
                if (v !== IDOStatement_1.CommonPropOperation.SET_TEXT) {
                    this.stItem.itemUpdate.itemOp = v;
                }
                this.stSaveProp.propUpdate.propOp = v;
            });
        }, {
            options: {
                placeholder: '=',
            },
        });
        this.propValueField = new fields_1.TextFieldWithUI('propValue', () => {
            var _a;
            const v = (_a = (this.currentPropType === MetaSavePropPropType.Item
                ? this.stItem.itemUpdate.itemCount
                : this.stSaveProp.propUpdate.value)) !== null && _a !== void 0 ? _a : '0';
            if (this.propOpField.value === IDOStatement_1.CommonPropOperation.CALCULATE) {
                return this.rootStore.textStore.encodeSPExprFormulaAfterParse(v);
            }
            if (this.propOpField.value === IDOStatement_1.CommonPropOperation.SET_TEXT) {
                return this.stSaveProp.propUpdate.value;
            }
            return ((0, lodash_1.parseInt)(v, 10) || 0).toString();
        }, v => {
            (0, mobx_1.runInAction)(() => {
                if (this.propOpField.value === IDOStatement_1.CommonPropOperation.CALCULATE) {
                    this.stItem.itemUpdate.itemCount = JSON.stringify(this.rootStore.textStore.parseFormulaWithFallback(v));
                    this.stSaveProp.propUpdate.value = JSON.stringify(this.rootStore.textStore.parseFormulaWithFallback(v));
                }
                else if (this.propOpField.value === IDOStatement_1.CommonPropOperation.SET_TEXT) {
                    this.stSaveProp.propUpdate.value = v.toString();
                }
                else {
                    this.stItem.itemUpdate.itemCount = v.toString();
                    this.stSaveProp.propUpdate.value = v.toString();
                }
            });
        }, { options: { noLabel: true } });
        this.aNumParam1Field = new fields_1.NumberFieldWithUI('num1', () => this.stAchievement.numParam1, v => (this.stAchievement.numParam1 = v), { options: { noLabel: true } });
        this.aNumParam2Field = new fields_1.NumberFieldWithUI('num2', () => this.stAchievement.numParam2, v => (this.stAchievement.numParam2 = v), { options: { noLabel: true } });
        (0, mobx_1.makeObservable)(this, {
            currentPropType: mobx_1.observable,
            propEditor: mobx_1.observable,
            itemEditor: mobx_1.observable,
            itemImageLink: mobx_1.computed,
        });
    }
    isPropStringFeatureEnabled() {
        return this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.STORY_PROP_STRING);
    }
    getStoryPropertyStore() {
        return this.block.parentChapter.getStory().storyPropertyStore;
    }
    determineMetaPropType() {
        switch (this.data.statementType) {
            case models_1.STATEMENT_TYPE.SaveProp: {
                const propName = this.stSaveProp.propUpdate.propName;
                const isMain = this.getStoryPropertyStore().allMain.find(v => v.propName === propName);
                return isMain
                    ? MetaSavePropPropType.MainProp
                    : MetaSavePropPropType.Prop;
            }
            case models_1.STATEMENT_TYPE.AchievementEvent:
                return MetaSavePropPropType.Achievement;
            case models_1.STATEMENT_TYPE.UpdateItem:
                return MetaSavePropPropType.Item;
            default:
                throw new Error(`DOSTMetaSaveProp.currentPropType : Invalid statementType (${this.data.statementType})`);
        }
    }
    get stAchievement() {
        return this.data;
    }
    get stSaveProp() {
        return this.data;
    }
    get stItem() {
        return this.data;
    }
    /**
     * 아이템 획득 속성일 때 보여줄 아이템의 이미지
     */
    get itemImageLink() {
        var _a, _b;
        if (this.statementType !== models_1.STATEMENT_TYPE.UpdateItem) {
            return null;
        }
        const item = this.block.parentChapter
            .getStory()
            .storyItemStore.getByName(this.stItem.itemUpdate.itemName);
        // 아이템 이미지를 스튜디오로 업로드를 하는 방안은 현재 미구현이므로 어드민에 등록된 것으로 활용한다.
        return (_b = (_a = item === null || item === void 0 ? void 0 : item.imageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null;
    }
    changePropType(propType) {
        (0, mobx_1.runInAction)(() => (this.currentPropType = propType));
    }
    get editorFields() {
        return [
            ...super.editorFields,
            this.propTypeField,
            this.propNameField,
            this.mainPropNameField,
            this.itemNameField,
            this.propOpField,
            this.propValueField,
            this.aEventField,
            this.aNumParam1Field,
            this.aNumParam2Field,
        ];
    }
    async submitEditing(opFactory) {
        this.changeStatementTypeToCurrentPropType();
        const op = opFactory !== null && opFactory !== void 0 ? opFactory : this.helper.opFactory().startBulk();
        await super.submitEditing(opFactory);
        if ([MetaSavePropPropType.Prop, MetaSavePropPropType.MainProp].includes(this.currentPropType)) {
            await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_PROP);
        }
        if (this.currentPropType === MetaSavePropPropType.Item) {
            await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_ITEM);
        }
        return op;
    }
    changeStatementTypeToCurrentPropType() {
        (0, mobx_1.runInAction)(() => {
            const t = this.mapPropTypeToStatementType(this.currentPropType);
            this.data.statementType = t;
            this.statementType = t;
        });
    }
    mapPropTypeToStatementType(propType) {
        return {
            [MetaSavePropPropType.Prop]: models_1.STATEMENT_TYPE.SaveProp,
            [MetaSavePropPropType.MainProp]: models_1.STATEMENT_TYPE.SaveProp,
            [MetaSavePropPropType.Item]: models_1.STATEMENT_TYPE.UpdateItem,
            [MetaSavePropPropType.Achievement]: models_1.STATEMENT_TYPE.AchievementEvent,
        }[propType];
    }
    get scriptMessageType() {
        const statementType = {
            [MetaSavePropPropType.Prop]: models_1.STATEMENT_TYPE.SaveProp,
            [MetaSavePropPropType.MainProp]: models_1.STATEMENT_TYPE.SaveProp,
            [MetaSavePropPropType.Item]: models_1.STATEMENT_TYPE.UpdateItem,
            [MetaSavePropPropType.Achievement]: models_1.STATEMENT_TYPE.AchievementEvent,
        }[this.currentPropType];
        return scripter_1.INVERTED_STATEMENT_TYPE_MAP[statementType];
    }
    validatePlayDataFormulaIfOpIsCal(formula) {
        if (this.propOpField.value === IDOStatement_1.CommonPropOperation.CALCULATE) {
            try {
                (0, parser_1.parseSPExprFormula)(formula);
                return consts_1.SPExprFormulaStatus.Ok;
            }
            catch (ex) {
                return consts_1.SPExprFormulaStatus.Fail;
            }
        }
        return consts_1.SPExprFormulaStatus.None;
    }
    validate() {
        const result = super.validate();
        if (this.currentPropType === MetaSavePropPropType.Prop ||
            this.currentPropType === MetaSavePropPropType.MainProp) {
            result.push(...this.runYupValidation(validations_1.PropValidations.name, this.stSaveProp.propUpdate.propName));
            if (this.stSaveProp.propUpdate.propOp === models_1.PROP_OPERATION.SET_TEXT &&
                !this.stSaveProp.propUpdate.value.trim()) {
                result.push({
                    type: validation_1.StudioValidationType.StringSavePropHasNoValue,
                    extra: {},
                    severity: validation_1.StudioValidationSeverity.Error,
                    source: this,
                    stack: [],
                });
            }
        }
        if (this.currentPropType === MetaSavePropPropType.Item) {
            result.push(...this.runYupValidation(validations_1.ItemValidations.name, this.stItem.itemUpdate.itemName));
        }
        const IS_TYPE_HAS_NUMBER_VALUE = (this.currentPropType === MetaSavePropPropType.Prop ||
            this.currentPropType === MetaSavePropPropType.MainProp ||
            this.currentPropType === MetaSavePropPropType.Item) &&
            this.propOpField.value !== IDOStatement_1.CommonPropOperation.SET_TEXT &&
            this.propOpField.value !== IDOStatement_1.CommonPropOperation.CALCULATE;
        // Number 타입을 가지고 있는 타입에서 값을 parseInt 로 변환시 NaN 이면 오류를 발생시킨다.
        // 1. '' 인 경우, 2. 'text' 인 경우
        if (IS_TYPE_HAS_NUMBER_VALUE &&
            (0, lodash_1.isNaN)((0, lodash_1.parseInt)(this.propValueField.value, 10))) {
            result.push({
                type: validation_1.StudioValidationType.MetaSavePropNoValue,
                extra: {},
                severity: validation_1.StudioValidationSeverity.Error,
                source: this,
                stack: [],
            });
        }
        if (this.currentPropType === MetaSavePropPropType.Achievement) {
            result.push(...this.runYupValidation(validations_1.AchievementValidations.name, this.stAchievement.event));
        }
        (0, mobx_1.runInAction)(() => {
            this.lastValidationResults = result;
        });
        return result;
    }
    generateSheetColumnsAfterBackground() {
        switch (this.currentPropType) {
            case MetaSavePropPropType.Prop:
            case MetaSavePropPropType.MainProp: {
                const result = [
                    '',
                    (0, storeUtils_1.encodePropUpdate)(this.stSaveProp.propUpdate),
                    '',
                    '',
                    '',
                    '',
                    '',
                ];
                if (!!this.executeConditionOfStatement) {
                    result.push(this.executeConditionOfStatement);
                }
                return result;
            }
            case MetaSavePropPropType.Item: {
                const result = [
                    '',
                    (0, storeUtils_1.encodeItemUpdate)(this.stItem.itemUpdate),
                    '',
                    '',
                    '',
                    '',
                    '',
                ];
                if (!!this.executeConditionOfStatement) {
                    result.push(this.executeConditionOfStatement);
                }
                return result;
            }
            case MetaSavePropPropType.Achievement: {
                const result = [
                    this.stAchievement.event,
                    this.stAchievement.numParam1.toString(),
                    this.stAchievement.numParam2.toString(),
                    '',
                    '',
                    '',
                    '',
                ];
                if (!!this.executeConditionOfStatement) {
                    result.push(this.executeConditionOfStatement);
                }
                return result;
            }
        }
        return [];
    }
    showPropEditor(name, isMain) {
        (0, mobx_1.runInAction)(() => {
            this.propEditor = new DOStoryPropertyEditStore_1.DOStoryPropertyEditStore(this.block.parentChapter.getStory(), isMain, null, propCreated => {
                const f = isMain ? this.mainPropNameField : this.propNameField;
                if (!propCreated) {
                    f.reload();
                    f.revertChanges();
                }
                else {
                    f.reload();
                    f.onChange(propCreated);
                }
                (0, mobx_1.runInAction)(() => (this.propEditor = null));
            });
            this.propEditor.propNameInput.onChange(name);
        });
    }
    showItemEditor(name) {
        (0, mobx_1.runInAction)(() => {
            this.itemEditor = new DOStoryItemEditStore_1.DOStoryItemEditStore(this.block.parentChapter.getStory(), null, itemCreated => {
                const f = this.itemNameField;
                if (!itemCreated) {
                    f.reload();
                    f.revertChanges();
                }
                else {
                    f.reload();
                    f.onChange(itemCreated);
                }
                (0, mobx_1.runInAction)(() => (this.itemEditor = null));
            });
            this.itemEditor.itemNameInput.onChange(name);
        });
    }
    async getMessagesToTranslate() {
        // 모든 속성/아이템 등의 번역은 displayName 을 활용하는게 맞다.
        return null;
    }
}
exports.DOSTMetaSaveProp = DOSTMetaSaveProp;
