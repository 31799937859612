import { ODWebUtils } from '@odc/od-react-belt'
import { STORY_PROP_TYPE } from '@storyplay/common'
import { cloneDeep } from 'apollo-utilities'
import { produce } from 'immer'
import { isNumber, range } from 'lodash'
import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import {
  GQLChapter,
  GQLStory,
  GQLStoryPropsMigrationInput,
} from '../../@types/server'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { ODInput } from '../../ODEntityEditor/FormComponents/ODInput'
import { Utils } from '../../utils/utils'
import { ODButton, ODButtonTheme } from '../base/ODButton'
import { ODTabBarButton, ODTabBarContainer } from '../base/ODTabBar'
import { ODModal, ODModalSize } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

export interface IPropsInfo {
  name: string
  dataType: STORY_PROP_TYPE
}

interface IPropsMigrationDialogProps {
  storyId: number
  propsToMigrate: IPropsInfo[] // 신규로 추가되는 속성
  onClose: () => void
  onConfirm: (result: GQLStoryPropsMigrationInput) => void
}

const NUMBER_VALUE_NOT_ENTERED = -1
const STRING_VALUE_NOT_ENTERED = ''

export const PropsMigrationDialog: React.FC<IPropsMigrationDialogProps> = (
  props
) => {
  const { storyId, propsToMigrate, onConfirm, onClose } = props

  const { apiGetStory } = useStoryPlayAPIs()
  const [story, setStory] = useState<GQLStory | null>(null)
  const [result, setResult] = useState<GQLStoryPropsMigrationInput | null>(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const handleConfirm = async () => {
    const propError = result!.props.find((prop) => {
      return (
        !prop.numberValues.every((v) => v !== NUMBER_VALUE_NOT_ENTERED) ||
        !prop.stringValues.every((v) => v.trim().length > 0)
      )
    })

    if (propError) {
      Utils.showError(
        `${propError.propName} 속성값에 입력되지 않은 값이 있습니다.`
      )
      return
    }

    // 스토리에 있는 props 를 추가한다. 서버에 저장할 때 데이터를 변경하여 저장하기 때문에 머지에 조금 복잡한 작업을 수행한다.
    const existingProps = JSON.parse(story?.propsMigration ?? '{}')
    const propsMerged: GQLStoryPropsMigrationInput = cloneDeep(result!)
    Object.keys(existingProps).forEach((keyName) => {
      const isNumberValue = isNumber(existingProps[keyName][0])

      // 신규 데이터에 해당 props 가 존재하면 overwrite 하지 않는다.
      if (result!.props.find((p) => p.propName === keyName)) {
        return
      }

      propsMerged.props.push({
        propName: keyName,
        stringValues: !isNumberValue ? existingProps[keyName] : [],
        numberValues: isNumberValue ? existingProps[keyName] : [],
      })
    })
    onConfirm(propsMerged)
  }

  const selectedPropName = propsToMigrate[selectedTabIndex].name
  const isSelectedPropNumber =
    propsToMigrate[selectedTabIndex].dataType === STORY_PROP_TYPE.NUMBER

  React.useEffect(() => {
    async function loadStory() {
      try {
        const story = await apiGetStory({ id: storyId })
        const numChapters = story.allChapters.length

        // TODO: 불러온 데이터를 머지해야 한다. => 일단 onConfirm 에서 머지하도록 함.
        setResult({
          storyId,
          props: propsToMigrate.map(({ name, dataType }) => {
            const isNumber = dataType === STORY_PROP_TYPE.NUMBER
            return {
              propName: name,
              numberValues: isNumber
                ? range(0, numChapters).map(() => NUMBER_VALUE_NOT_ENTERED)
                : [],
              stringValues: !isNumber
                ? range(0, numChapters).map(() => STRING_VALUE_NOT_ENTERED)
                : [],
            }
          }),
        })
        setStory(story)
      } catch (ex) {
        ODWebUtils.showError(ex)
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    loadStory()
  }, [storyId, propsToMigrate, apiGetStory])

  const isLoading = story === null || result === null

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.XLarge}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="속성 마이그레이션" onClose={onClose} />
        <ODModalBody>
          {isLoading && <span>불러오는 중...</span>}
          {!isLoading && (
            <ODTabBarContainer>
              {propsToMigrate.map(
                ({ name: propName, dataType }, index: number) => {
                  const isSelected = index === selectedTabIndex
                  return (
                    <ODTabBarButton
                      active={isSelected}
                      onClick={() => setSelectedTabIndex(index)}
                      tabProps={{ width: '150' }}
                      key={index}
                    >
                      {isSelected && <strong>{propName}</strong>}
                      {!isSelected && <span>{propName}</span>}
                    </ODTabBarButton>
                  )
                }
              )}
            </ODTabBarContainer>
          )}
          {!isLoading && (
            <Card>
              <CardBody>
                {story!.allChapters.map((chapter, index) => {
                  const prop = result!.props.find(
                    (v) => v.propName === selectedPropName
                  )!
                  const value = isSelectedPropNumber
                    ? prop.numberValues[index]
                    : prop.stringValues[index]
                  return (
                    <PropsValueInput
                      key={index}
                      value={value}
                      chapter={chapter}
                      propType={
                        isSelectedPropNumber
                          ? STORY_PROP_TYPE.NUMBER
                          : STORY_PROP_TYPE.STRING
                      }
                      onChange={(v) => {
                        setResult((res) => {
                          return produce(res!, (draft) => {
                            if (isSelectedPropNumber) {
                              const value = !v
                                ? 0
                                : parseInt(v.toString(), 10) ?? 0
                              draft.props.find(
                                (v) => v.propName === selectedPropName
                              )!.numberValues[index] = value
                            } else {
                              draft.props.find(
                                (v) => v.propName === selectedPropName
                              )!.stringValues[index] = v as string
                            }
                            return draft
                          })
                        })
                      }}
                    />
                  )
                })}
              </CardBody>
            </Card>
          )}
        </ODModalBody>
        {!isLoading && (
          <ODModalFooter>
            <ODButton
              fullWidth
              theme={ODButtonTheme.Primary}
              onClick={handleConfirm}
            >
              진행
            </ODButton>
          </ODModalFooter>
        )}
      </div>
    </ODModal>
  )
}

interface IPropsValueInputProps {
  propType: STORY_PROP_TYPE
  value: number | string
  chapter: GQLChapter
  onChange: (value: number | string) => void
}

export const PropsValueInput: React.FC<IPropsValueInputProps> = (props) => {
  const { chapter, propType, value, onChange } = props
  return (
    <PropsWrapper>
      <PropsLabel>
        [{chapter.chapterIndex}] {chapter.name}
      </PropsLabel>
      <ODInput
        placeholder="기본값을 입력해주세요."
        inputProps={{
          type: propType === STORY_PROP_TYPE.NUMBER ? 'number' : 'text',
        }}
        value={value}
        onChange={(v) => {
          if (propType === STORY_PROP_TYPE.NUMBER) {
            onChange(parseInt(v, 10))
          } else {
            onChange(v)
          }
        }}
      />
    </PropsWrapper>
  )
}

const PropsWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
`

const PropsLabel = styled.div`
  min-width: 70px;
  font-weight: bold;
`
