"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbSheetAPI = void 0;
class HbSheetAPI {
    constructor(server) {
        this.server = server;
    }
    async list(blockId) {
        return this.server.get(`/v1/sheets/block/${blockId}`);
    }
}
exports.HbSheetAPI = HbSheetAPI;
