"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTScript = void 0;
const DOSTBubbleBase_1 = require("./DOSTBubbleBase");
class DOSTScript extends DOSTBubbleBase_1.DOSTBubbleBase {
    //
    get canResetColor() {
        return !!this.backgroundColor;
    }
    onResetColor() {
        this.backgroundColorField.resetWithValue(undefined);
        this.submitEditing();
    }
}
exports.DOSTScript = DOSTScript;
