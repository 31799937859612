import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLOnboardingPopup } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IOnboardingPopupListableOption } from '../OnboardingPopupCommon'

export function useOnboardingPopupListDataLoader(
  storyId: number | undefined,
  chapterId: number | undefined
) {
  const { listOnboardingPopup } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IOnboardingPopupListableOption
    ): Promise<ODListableResponseType<GQLOnboardingPopup>> {
      const r = await listOnboardingPopup({
        page,
        pageSize: 10,
        ...options,
        storyId,
        chapterId,
      })
      return r as ODListableResponseType<GQLOnboardingPopup>
    },
    [listOnboardingPopup, storyId, chapterId]
  )
}
