"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionCompletedTutorial = void 0;
const definition_1 = require("../../definition");
const ActionModalBase_1 = require("./ActionModalBase");
class ActionCompletedTutorial extends ActionModalBase_1.ActionModalBase {
    constructor() {
        super();
    }
    buildAction() {
        return {
            type: definition_1.ActionType.CompletedTutorial,
            ...this.buildActionBase(),
            onClose: () => {
                this.cancelAction();
            },
        };
    }
}
exports.ActionCompletedTutorial = ActionCompletedTutorial;
