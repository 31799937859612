"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOAEventDefStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const DOAEventDef_1 = require("./DOAEventDef");
const IDOAEventDef_1 = require("./IDOAEventDef");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOAEventDefStore {
    constructor(story) {
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        this.errorEvent = this.merge({
            aEventDefId: IDOAEventDef_1.AEventItemErrorId,
            name: trans('legacy_DOAEventDefStore_no_event'),
            badges: [],
            isAchievement: false,
            description: '',
        });
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            allAEventDefs: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    getByName(name) {
        var _a;
        return ((_a = Object.values(this.data).find(v => v.name === name)) !== null && _a !== void 0 ? _a : this.errorEvent);
    }
    merge(data) {
        const id = data.aEventDefId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOAEventDef_1.DOAEventDef(this, data);
        }
        return this.data[id];
    }
    get allAEventDefs() {
        return Object.values(this.data).filter(v => !v.isError);
    }
    generateOptions() {
        return this.allAEventDefs.map(v => ({
            value: v,
            name: v.name,
        }));
    }
}
exports.DOAEventDefStore = DOAEventDefStore;
