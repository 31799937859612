"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2FunctionEditor = void 0;
const core_1 = require("@storyplay/core");
const EntityEditor_1 = require("../EntityEditor");
const fields_1 = require("../fields");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOPlayerReport2FunctionEditor extends EntityEditor_1.EntityEditor {
    constructor(report, env) {
        super(report);
        this.env = env;
        const stringFields = [
            {
                key: 'name',
                options: {
                    label: trans('legacy_DOPlayerReport2FunctionEditor_Function_name'),
                    placeholder: trans('legacy_DOPlayerReport2FunctionEditor_Please_enter_the_function_name'),
                    options: { noLabel: true, editable: false },
                },
            },
            {
                key: 'desc',
                options: {
                    label: trans('legacy_DOPlayerReport2FunctionEditor_Function_function_description'),
                    placeholder: trans('legacy_DOPlayerReport2FunctionEditor_Please_enter_a_description_of_the_function'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'code',
                options: {
                    label: trans('legacy_DOPlayerReport2FunctionEditor_Function_code'),
                    placeholder: trans('legacy_DOPlayerReport2FunctionEditor_Please_enter_the_actual_function_code'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'codeSample',
                options: {
                    label: trans('legacy_DOPlayerReport2FunctionEditor_Sample_code'),
                    placeholder: trans('legacy_DOPlayerReport2FunctionEditor_Please_enter_the_code_to_be_copied'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'paramDesc',
                options: {
                    label: trans('legacy_DOPlayerReport2FunctionEditor_Parameter_description'),
                    inputType: 'textarea',
                    placeholder: trans('legacy_DOPlayerReport2FunctionEditor_Please_enter_a_description_of_the_parameter'),
                    options: {
                        noLabel: true,
                        textareaTheme: ui_1.TextareaTheme.NormalSize,
                    },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new fields_1.TextFieldWithUI(fld.key, () => report[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        this.nameField.input.setRequired(true);
        this.nameField.input.setAutoFocus();
    }
    get prfId() {
        return this._target.serverId;
    }
    async submitChanges(onError) {
        try {
            // tslint:disable-next-line:no-eval
            this.env.addGlobal(this.nameField.value, eval(this.codeField.value));
        }
        catch (ex) {
            this._target.rootStore.showError(ex);
            return false;
        }
        return super.submitChanges(onError);
    }
}
exports.DOPlayerReport2FunctionEditor = DOPlayerReport2FunctionEditor;
