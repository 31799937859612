import {
  ODButton,
  ODButtonTheme,
  ODModal,
  ODModalBody,
  ODModalFooter,
  ODModalHeader,
  ODModalSize,
} from '@odc/od-react-belt'
import {
  TextType,
  GQLTTS_DPE_ID,
  GQLTTS_EMOTION,
  TTS_PITCH,
  TTS_SPEED,
} from '@storyplay/common'
import { isNumber } from 'lodash'
import React from 'react'
import Select from 'react-select'
import { Col, Row } from 'reactstrap'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { Utils } from '../../utils/utils'
import { AudioListenerInternal } from './AudioListenerInternal'
import {
  EMOTION_LIST,
  PITCH_LIST,
  SAMPLE_TEXT,
  SPEED_LIST,
} from './TtsSpeakerCommon'

interface ISetTtsOptionsModalProps {
  ttsServerSpeakerId?: number
  ttsServerSpeakerName?: string
  onClose: () => void
  onConfirm: () => void
}

export const SetTtsOptionsModal: React.FC<ISetTtsOptionsModalProps> = (
  props
) => {
  const { ttsServerSpeakerId, ttsServerSpeakerName, onClose, onConfirm } = props
  const [speedOptions, setSpeedOptions] = React.useState<
    { label: string; value: TTS_SPEED }[]
  >([])
  const [speed, setSpeed] = React.useState<TTS_SPEED>(TTS_SPEED.Default)
  const [pitchOptions, setPitchOptions] = React.useState<
    { label: string; value: TTS_PITCH }[]
  >([])
  const [pitch, setPitch] = React.useState<TTS_PITCH>(TTS_PITCH.Default)
  const [emotionOptions, setEmotionOptions] = React.useState<
    { label: string; value: GQLTTS_EMOTION }[]
  >([])
  const [emotion, setEmotion] = React.useState<GQLTTS_EMOTION>(
    GQLTTS_EMOTION.Default
  )
  const [emotionChange, setEmotionChange] = React.useState<boolean>(true)
  const [speakerOptions, setSpeakerOptions] = React.useState<
    { label: string; value: number }[]
  >([])
  const [speakerId, setSpeakerId] = React.useState<number | undefined>(
    undefined
  )
  const [speakerInfo, setSpeakerInfo] = React.useState<string>('')
  const [speakerChange, setSpeakerChange] = React.useState<boolean>(true)
  const [dpeIdChange, setDpeIdChange] = React.useState<boolean>(true)
  const { listTtsSpeaker } = useStoryPlayAPIs()
  React.useEffect(() => {
    if (isNumber(ttsServerSpeakerId)) {
      setSpeakerId(ttsServerSpeakerId)
      setSpeakerInfo(`[${ttsServerSpeakerId}] ${ttsServerSpeakerName}`)
    }
    setSpeedOptions(
      SPEED_LIST.map(({ label, value }) => ({
        label,
        value,
      }))
    )
    setPitchOptions(
      PITCH_LIST.map(({ label, value }) => ({
        label,
        value,
      }))
    )
    setEmotionOptions(
      EMOTION_LIST.map(({ label, value }) => ({
        label,
        value,
      }))
    )
    listTtsSpeaker({ pageSize: 1000 }).then((speakerList) => {
      setSpeakerOptions(
        speakerList.list.map((t) => ({
          label: t.name,
          value: t.ttsServerSpeakerId,
        }))
      )
    })
  }, [])

  const handleConfirm = async () => {
    if (!isNumber(speakerId)) return Utils.showError('발화자를 선택해주세요!')
    const options = {
      textType: TextType.TTS,
      emotion,
      speakerId,
      // @ts-ignore
      dpeId: GQLTTS_DPE_ID[`${speed}_${pitch}`],
    }
    navigator.clipboard.writeText(JSON.stringify(options))
    Utils.showSuccess('JSON이 복사되었습니다! 엑셀에 붙여 넣어주세요!')
    onConfirm()
  }

  const handleSpeakerInfo = (v: { label: string; value: number }) => {
    setSpeakerInfo(`[${v.value}] ${v.label}`)
    setSpeakerId(v.value)
  }

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Normal}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="TTS 옵션 만들기" onClose={onClose} />
        <ODModalBody>
          {!isNumber(ttsServerSpeakerId) && (
            <Row style={{ marginBottom: 20 }}>
              <Col md={4}>발화자 선택</Col>
              <Col md={8}>
                <Select
                  isSearchable={true}
                  placeholder="발화자"
                  onChange={(v) => {
                    if (v) {
                      // @ts-ignore
                      setSpeakerId(v.value)
                      // @ts-ignore
                      handleSpeakerInfo(v)
                      setSpeakerChange(true)
                    }
                  }}
                  options={speakerOptions}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 2 }),
                  }}
                />
              </Col>
            </Row>
          )}
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>발화자</Col>
            <Col md={8}>
              <input type="text" value={speakerInfo} disabled={true} />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>감정</Col>
            <Col md={8}>
              <Select
                isSearchable={true}
                placeholder="감정"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    setEmotion(v.value)
                    setEmotionChange(true)
                  }
                }}
                defaultValue={emotionOptions.find(
                  (s) => s.value === GQLTTS_EMOTION.Default
                )}
                options={emotionOptions}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>속도</Col>
            <Col md={8}>
              <Select
                isSearchable={true}
                placeholder="속도"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    setSpeed(v.value)
                    setDpeIdChange(true)
                  }
                }}
                defaultValue={speedOptions.find(
                  (s) => s.value === TTS_SPEED.Default
                )}
                options={speedOptions}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>높낮이</Col>
            <Col md={8}>
              <Select
                isSearchable={true}
                placeholder="높낮이"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    setPitch(v.value)
                    setDpeIdChange(true)
                  }
                }}
                defaultValue={pitchOptions.find(
                  (s) => s.value === TTS_PITCH.Default
                )}
                options={pitchOptions}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </Col>
          </Row>
          {speakerInfo && (
            <Row style={{ marginBottom: 20 }}>
              <Col md={4}>들어보기</Col>
              <Col md={8}>
                <AudioListenerInternal
                  text={SAMPLE_TEXT}
                  speakerId={speakerId!}
                  speakerChange={speakerChange}
                  emotion={emotion}
                  emotionChange={emotionChange}
                  // @ts-ignore
                  dpeId={GQLTTS_DPE_ID[`${speed}_${pitch}`]}
                  dpeIdChange={dpeIdChange}
                  onUpdate={() => {
                    setEmotionChange(false)
                    setSpeakerChange(false)
                    setDpeIdChange(false)
                  }}
                />
              </Col>
            </Row>
          )}
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            fullWidth
            theme={ODButtonTheme.Primary}
            onClick={handleConfirm}
          >
            복사하기
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
