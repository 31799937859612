"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateLSBackupBundleKey = exports.createEmptyBookScript = exports.isValidBlockName = exports.addHbStatementsInternal = exports.addStatementsInternal = exports.removeStatementsInternal = exports.removeHbStatementsInternal = exports.roundImageOffset = exports.convertLinesToSheetCSV = exports.encodeItemUpdate = exports.encodePropUpdate = exports.assignIf = void 0;
const json2csv_1 = require("json2csv");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const models_1 = require("../../../models");
const entity_1 = require("../entity");
var core_1 = require("@storyplay/core");
Object.defineProperty(exports, "assignIf", { enumerable: true, get: function () { return core_1.assignIf; } });
/**
 * PROP_UPDATE 형태의 데이터를 다시 스크립트 형태의 문자열로 전환한다.
 */
function encodePropUpdate(propUpdate) {
    var _a;
    if (propUpdate.propOp === models_1.PROP_OPERATION.CALCULATE ||
        propUpdate.propOp === models_1.PROP_OPERATION.SET_TEXT) {
        return `${propUpdate.propName}:${propUpdate.value}`;
    }
    const numValue = (_a = parseInt(propUpdate.value, 10)) !== null && _a !== void 0 ? _a : 0;
    const op = propUpdate.propOp === models_1.PROP_OPERATION.INCREASE_NUMBER
        ? numValue >= 0
            ? '+'
            : '-'
        : '';
    return `${propUpdate.propName}:${op}${Math.abs(numValue)}`;
}
exports.encodePropUpdate = encodePropUpdate;
/**
 * ITEM_UPDATE 형태의 데이터를 다시 스크립트 형태의 문자열로 전환한다.
 */
function encodeItemUpdate(itemUpdate) {
    var _a;
    if (itemUpdate.itemOp === models_1.ITEM_OPERATION.CALCULATE) {
        return `${itemUpdate.itemName}:${itemUpdate.itemCount}`;
    }
    const numValue = (_a = parseInt(itemUpdate.itemCount, 10)) !== null && _a !== void 0 ? _a : 0;
    const op = itemUpdate.itemOp === models_1.ITEM_OPERATION.INCREASE_NUMBER
        ? numValue > 0
            ? '+'
            : '-'
        : '';
    return `${itemUpdate.itemName}:${op}${Math.abs(numValue)}`;
}
exports.encodeItemUpdate = encodeItemUpdate;
function convertLinesToSheetCSV(lines, includeHeader = false) {
    const fields = [
        'f1',
        'f2',
        'f3',
        'f4',
        'f5',
        'f6',
        'f7',
        'f8',
        'f9',
        'f10',
        'f11',
        'f12',
    ];
    const fieldNames = [
        '',
        '블록명',
        '블록 타입',
        '배경 이미지',
        '메시지 타입',
        '등장인물',
        '입력값1',
        '입력값2',
        '조건식',
        '조건식 파라메터',
        '액션',
        '액션 파라메터',
        'JSON 조건식',
    ];
    const converted = lines.map(line => (0, lodash_1.zipObject)(fields, line.columns));
    const parser = new json2csv_1.Parser({ fields, header: false });
    const csv = parser.parse(converted);
    if (includeHeader) {
        return `${fieldNames.join(',')}\n` + csv;
    }
    return csv;
}
exports.convertLinesToSheetCSV = convertLinesToSheetCSV;
/**
 * 텍스트 이미지 오버레이에서 cropper-js 가 소수점을 반환하는 것을 해결하기 위해
 * 일관되게 이를 바꾸어준다.
 */
function roundImageOffset(v) {
    return Math.round(v);
}
exports.roundImageOffset = roundImageOffset;
/**
 * removeLines 가 될 때 헬봇용 제거 api
 */
async function removeHbStatementsInternal(holder, linesUniqueIds) {
    const removes = [];
    let lastNonRemovedLine;
    let scriptIndex = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < holder.statements.length; index += 1) {
        const st = holder.statements[index];
        if (st.isScript &&
            linesUniqueIds.includes(st.uniqueId)) {
            removes.push({
                line: st,
                actualIndex: index,
                index: scriptIndex, // block head statement 등은 피한 index 여야 한다.
            });
            continue;
        }
        lastNonRemovedLine = st;
        if (st.isScript) {
            scriptIndex += 1;
        }
    }
    const listSuccessIndex = [];
    const hbStore = holder.rootStore;
    for (const r of removes) {
        const hbStatement = r.line.data;
        if ((hbStatement === null || hbStatement === void 0 ? void 0 : hbStatement.hbExtensionData) && !!hbStatement.hbExtensionData.id) {
            try {
                await hbStore.apiServer.message.delete(hbStatement.hbExtensionData.id, hbStore.isAlgorithmStore);
                listSuccessIndex.push(r.actualIndex);
            }
            catch (ex) {
                holder.rootStore.showError(ex);
            }
        }
    }
    // 뒷쪽부터 지운다. index 문제가 없도록. 실제로 다시 op 로 나갈 땐 원복한다.
    (0, mobx_1.runInAction)(() => {
        removes
            .filter(r => listSuccessIndex.includes(r.actualIndex))
            .reverse()
            .forEach(({ actualIndex }) => {
            holder.statements.splice(actualIndex, 1);
        });
    });
    return {
        removes: removes.reverse().map(v => ({
            line: v.line,
            index: v.index,
        })),
        lastNonRemovedLine,
    };
}
exports.removeHbStatementsInternal = removeHbStatementsInternal;
/**
 * DOBlock, DOBaseScriptSubBlock 에서 함께 사용하기 위한 문장 제거 후 reverse-op 반환.
 */
function removeStatementsInternal(holder, linesUniqueIds) {
    const removes = [];
    let lastNonRemovedLine;
    let scriptIndex = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < holder.statements.length; index += 1) {
        const st = holder.statements[index];
        if (st.isScript &&
            linesUniqueIds.includes(st.uniqueId)) {
            removes.push({
                line: st,
                actualIndex: index,
                index: scriptIndex, // block head statement 등은 피한 index 여야 한다.
            });
            continue;
        }
        lastNonRemovedLine = st;
        if (st.isScript) {
            scriptIndex += 1;
        }
    }
    // 뒷쪽부터 지운다. index 문제가 없도록. 실제로 다시 op 로 나갈 땐 원복한다.
    (0, mobx_1.runInAction)(() => {
        removes.reverse().forEach(({ actualIndex }) => {
            holder.statements.splice(actualIndex, 1);
        });
    });
    return {
        removes: removes.reverse().map(v => ({
            line: v.line,
            index: v.index,
        })),
        lastNonRemovedLine,
    };
}
exports.removeStatementsInternal = removeStatementsInternal;
// doblock ->
/**
 * DOBlock, DOBaseScriptSubBlock 에서 함께 사용하기 위한 문장 추가 후 reverse-op 반환.
 */
function addStatementsInternal(holder, blockHolder, lines) {
    const firstStatement = holder.statements[0];
    const hasHeadStatement = !firstStatement.isScript &&
        firstStatement.editorStatementType ===
            entity_1.EDITOR_STATEMENT_TYPE.BlockHead;
    // 앞에서부터 index 가 들어갔을 때 위치가 맞도록 구현되어 있으므로,
    (0, mobx_1.runInAction)(() => {
        for (const { line, index } of lines) {
            // Head statement 를 피하기 위해, index 에 +1 을 해준다.
            holder.statements.splice(index + (hasHeadStatement ? 1 : 0), 0, line);
            // @ts-ignore
            line.parentBlock = blockHolder;
        }
    });
    return lines.map(l => l.line.uniqueId);
}
exports.addStatementsInternal = addStatementsInternal;
/**
 * 기존 스플 스튜디오의 addLines opType 에 대응하기 위해 만듬,
 * 헬봇의 경우는 배열의 0번째에 하나만 온다.
 */
async function addHbStatementsInternal(holder, blockHolder, lines) {
    const firstStatement = holder.statements[0];
    const hasHeadStatement = !firstStatement.isScript &&
        firstStatement.editorStatementType ===
            entity_1.EDITOR_STATEMENT_TYPE.BlockHead;
    const asHbBase = lines[0].line;
    if (blockHolder.rootStore.serviceType === 'hb' && !!lines[0]) {
        try {
            await asHbBase.createHb(asHbBase.getDataForSubmit(), asHbBase.st.hbExtensionData);
        }
        catch (ex) {
            blockHolder.rootStore.showError(ex);
            return null;
        }
    }
    // 앞에서부터 index 가 들어갔을 때 위치가 맞도록 구현되어 있으므로,
    (0, mobx_1.runInAction)(() => {
        for (const { line, index } of lines) {
            // Head statement 를 피하기 위해, index 에 +1 을 해준다.
            holder.statements.splice(index + (hasHeadStatement ? 1 : 0), 0, line);
            // @ts-ignore
            line.parentBlock = blockHolder;
        }
    });
    return lines.map(l => l.line.uniqueId);
}
exports.addHbStatementsInternal = addHbStatementsInternal;
function isValidBlockName(name) {
    return name.trim().length > 0;
}
exports.isValidBlockName = isValidBlockName;
function createEmptyBookScript() {
    const emptyBook = {
        props: {},
        backgrounds: {},
        characters: {},
        chapterSequence: ['챕터1'],
        chapters: {
            챕터1: {
                name: '챕터1',
                blocks: {
                    시작: {
                        name: '시작',
                        isEndingBlock: false,
                        statements: [],
                    },
                },
                customId: 'Chapter1',
                startingBlock: '시작',
                hasBGM: false,
            },
        },
        choices: {},
        endings: {},
        items: [],
        fallbackRemoteScripts: {},
    };
    return emptyBook;
}
exports.createEmptyBookScript = createEmptyBookScript;
// chapter backup bundle 을 로컬스토리지에 저장하기 위한 키를 만듬
function generateLSBackupBundleKey(chapterId, prefix = '') {
    return `${prefix}${chapterId}_backup_bundle`;
}
exports.generateLSBackupBundleKey = generateLSBackupBundleKey;
