import {
  ODEntityInput,
  ODModal,
  ODModalBody,
  ODModalHeader,
  ODModalSize,
  useCounter,
} from '@odc/od-react-belt'
import { GQLChoice, GQLUpdateChoiceInput } from '@storyplay/core'
import React from 'react'
import * as Yup from 'yup'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../ODEntityEditor/ODEntityEditorFooter'
import { Utils } from '../../utils/utils'

interface IUpdateChoicePropModalProps {
  choiceId: number
  onClose: () => void
  onConfirm: () => void
}

type Entity = GQLChoice

function getValidationSchema(values: Partial<GQLUpdateChoiceInput>) {
  return Yup.object().shape({})
}

export const UpdateChoicePropModal: React.FC<IUpdateChoicePropModalProps> = (
  props
) => {
  const { choiceId, onClose, onConfirm } = props
  const { getChoice, updateChoice } = useStoryPlayAPIs()
  const [token] = useCounter()
  const innerRef = React.useRef(null)

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<Entity, Partial<GQLUpdateChoiceInput>>
  >(
    () => ({
      initialValueLoader: async () => {
        if (choiceId) {
          return getChoice({ id: choiceId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            choiceId: 0,
            choice1PropName: '',
            choice2PropName: '',
            choice3PropName: '',
            choice4PropName: '',
            choice5PropName: '',
          }
        }
        return {
          choiceId,
          choice1PropName: data.choice1PropName,
          choice2PropName: data.choice2PropName,
          choice3PropName: data.choice3PropName,
          choice4PropName: data.choice4PropName,
          choice5PropName: data.choice5PropName,
        }
      },
      saveClientValueToServer: async (data: Partial<GQLUpdateChoiceInput>) => {
        const {
          choice1PropName,
          choice2PropName,
          choice3PropName,
          choice4PropName,
          choice5PropName,
        } = data as GQLUpdateChoiceInput
        await updateChoice({
          id: choiceId,
          choice1PropName,
          choice2PropName,
          choice3PropName,
          choice4PropName,
          choice5PropName,
        })
        Utils.showSuccess('선택지 통계 속성이 수정되었습니다.', 'Success')
        onConfirm()
        return ''
      },
      getValidationSchema,
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token)
        Utils.showError(ex)
      },
      __innerReference: innerRef,
    }),
    [choiceId, getChoice, updateChoice, onConfirm, onClose]
  )
  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<Entity, Partial<GQLUpdateChoiceInput>>
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<GQLUpdateChoiceInput>>(options)
  )

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Large}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader
          title={`선택지 통계 속성 수정하기`}
          onClose={onClose}
        ></ODModalHeader>
        <ODModalBody>
          <Provider title={'설정하기'}>
            <ODEntityInput
              label="선택지 1번 통계속성"
              name="choice1PropName"
              keyPath="choice1PropName"
              inputType="text"
              placeholder="선택지 1번 통계속성"
            />
            <ODEntityInput
              label="선택지 2번 통계속성"
              name="choice2PropName"
              keyPath="choice2PropName"
              inputType="text"
              placeholder="선택지 2번 통계속성"
            />
            <ODEntityInput
              label="선택지 3번 통계속성"
              name="choice3PropName"
              keyPath="choice3PropName"
              inputType="text"
              placeholder="선택지 3번 통계속성"
            />
            <ODEntityInput
              label="선택지 4번 통계속성"
              name="choice4PropName"
              keyPath="choice4PropName"
              inputType="text"
              placeholder="선택지 4번 통계속성"
            />
            <ODEntityInput
              label="선택지 5번 통계속성"
              name="choice5PropName"
              keyPath="choice5PropName"
              inputType="text"
              placeholder="선택지 5번 통계속성"
            />
            <ODEntityEditorFooter saveButtonName="설정" context={Context} />
          </Provider>
        </ODModalBody>
      </div>
    </ODModal>
  )
}
