// All registered component type
import { CSSProperties } from 'react'
import { ODBooleanRendererComponentDef } from './ODBooleanRenderer'

export enum ODRendererComponentType {
  BooleanRenderer = 'BooleanRenderer',
  StringRenderer = 'StringRenderer',
  ImageFileRenderer = 'ImageFileRenderer',
  ColorRenderer = 'ColorRenderer',
}

// Renderer definition root type
export interface IODRendererDef {
  component: ODRendererComponentType
  eventKey: string
}

export interface IODImageFileRendererDef extends IODRendererDef {
  component: ODRendererComponentType.ImageFileRenderer
  width: number
  height: number
  alt: string
}

export interface IODStringRendererDef extends IODRendererDef {
  component: ODRendererComponentType.StringRenderer
  clickEnabled?: boolean
  style?: CSSProperties
}

export interface IODColorRendererDef extends IODRendererDef {
  component: ODRendererComponentType.ColorRenderer
}

// All registered boolean renderer def.
export type IODBooleanRendererDef = ODBooleanRendererComponentDef

// All registered renderer def.
export type ODRendererDef =
  | IODBooleanRendererDef
  | IODImageFileRendererDef
  | IODStringRendererDef
  | IODColorRendererDef

export interface IODRendererProps<
  VALUE_TYPE,
  COMPONENT_DEFINITION extends IODRendererDef,
  ParentContextType,
  OriginalValueType
> {
  value: VALUE_TYPE
  def: COMPONENT_DEFINITION
  context: IODRendererContext<ParentContextType>
  originalValue: OriginalValueType
}

//
//
//
export enum ODRendererEventType {
  ChangeValue = 'ChangeValue',
  FileAttached = 'FileAttached',
  OnClick = 'OnClick',
}

export interface IODRendererEvent {
  type: ODRendererEventType
  eventKey: string
}

export type ODRendererChangeValueEvent<VALUE_TYPE> = IODRendererEvent & {
  type: ODRendererEventType
  valueFrom: VALUE_TYPE
  valueTo: VALUE_TYPE
}

export type ODRendererFileAttachedEvent = IODRendererEvent & {
  type: ODRendererEventType.FileAttached
  file: File
}

export type ODRendererOnClickEvent = IODRendererEvent & {
  type: ODRendererEventType.OnClick
  valueFrom: any
}

export type ODRendererEvent<VALUE_TYPE> =
  | ODRendererChangeValueEvent<VALUE_TYPE>
  | ODRendererFileAttachedEvent
  | ODRendererOnClickEvent

export interface IODRendererContext<ParentContextType> {
  parentContext: ParentContextType

  onEvent(event: ODRendererEvent<any>, parentContext: ParentContextType): void
}
