"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const errors_1 = require("../../../../errors");
const interface_1 = require("../../interface");
const Waiter_1 = require("../../utils/Waiter");
const story_1 = require("../story");
const user_1 = require("../user");
const InputWithValidation_1 = require("./InputWithValidation");
const spDoStoryStoreStrategy_1 = require("../../strategy/doStoryStoreStrategy/spDoStoryStoreStrategy");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
const delayForLogin = 700;
class LoginStore {
    constructor(rootStore) {
        /** 현재 로그인 상태. 맞추어 UI 가 그려져야 함 */
        this.loginState = interface_1.StudioLoginState.Checking;
        /** 로그인 과정에서 발생한 오류, 메시지가 있으면 표시 */
        this.errorMessage = null;
        /** 현재 로그인한 사용자 */
        this.user = null;
        this.isPasswordTooOld = false;
        this.rootStore = rootStore;
        const mock = rootStore.di.devFillMockData;
        this.emailInput = new InputWithValidation_1.InputWithValidation('login_email', trans('legacy_LoginStore.ts_email_address'), trans('legacy_LoginStore.ts_enter_email'), 'email', mock ? 'storyplay@odcode.com' : '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            onChange: () => {
                this.clearErrorMessage();
            },
        }).setAutoFocus();
        this.passwordInput = new InputWithValidation_1.InputWithValidation('login_password', trans('legacy_LoginStore.ts_password'), trans('legacy_LoginStore.ts_enter_password'), 'password', mock ? '12345678a!' : '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            onChange: () => {
                this.clearErrorMessage();
            },
        });
        (0, mobx_1.makeObservable)(this, {
            loginState: mobx_1.observable,
            errorMessage: mobx_1.observable,
            user: mobx_1.observable,
            isPasswordTooOld: mobx_1.observable,
            canSubmitLogin: mobx_1.computed,
            isLoggingIn: mobx_1.computed,
        });
        // noinspection JSIgnoredPromiseFromCall
        this.startChecking();
    }
    get localStorage() {
        return this.rootStore.di.localStorage;
    }
    /**
     * 로그인 되어 있는 계정이 있는지 체크한다.
     */
    async startChecking() {
        var _a;
        const di = this.rootStore.di;
        const token = this.localStorage.getItem(di.tokenStorageKey);
        if (!token) {
            (0, mobx_1.runInAction)(() => (this.loginState = interface_1.StudioLoginState.LoggedOut));
            return;
        }
        try {
            const gqlUser = await di.server.getWriterProfileForStudio();
            (0, mobx_1.runInAction)(() => {
                this.user = this.rootStore.userStore.merge(gqlUser);
                this.loginState = interface_1.StudioLoginState.LoggedIn;
            });
            if (!((_a = this.user) === null || _a === void 0 ? void 0 : _a.isNotAuthor)) {
                await this.rootStore.loadUserData();
            }
        }
        catch (ex) {
            console.error(39, 'getWriterProfileForStudio() error : ', ex);
            (0, mobx_1.runInAction)(() => {
                this.loginState = interface_1.StudioLoginState.LoggedOut;
                this.errorMessage = this.rootStore.textStore.translateError(ex);
            });
        }
    }
    /** 현재 로그인 중인지 */
    get isLoggingIn() {
        return this.loginState === interface_1.StudioLoginState.LoggingIn;
    }
    /** true 일 때만 loginWithEmail 을 호출할 수 있음 */
    get canSubmitLogin() {
        return [interface_1.StudioLoginState.LoggedOut, interface_1.StudioLoginState.LoggedIn].includes(this.loginState);
    }
    /** 아직 지원하지 않는 버튼들을 클릭하였을 때 에러 메시지 표시 */
    setErrorMessageIfNotSupported() {
        (0, mobx_1.runInAction)(() => {
            this.errorMessage = this.rootStore.textStore.translateError(new errors_1.SPCError(errors_1.ErrorCode.NOT_SUPPORTED));
        });
    }
    // SSOLogin 과정은 아래와 같습니다.
    // SSO Login 버튼을 클릭하면
    // 1. https://dev.storyplay.com/auth/studio?redirect={studioURL} 로 리다이렉트 시킵니다.
    // 2. 로그인을 하면, 다시 스튜디오 로그인 페이지로 이동됩니다.(token 파라메터와 함께)
    //  - token 파라메터 값에 토큰 값이 있으면 로그인 처리를 합니다.
    //  - token 파라메터 값에 need-sign-up 값이 있으면 회원가입 필요
    //  - token 파라메터 값에 sign-in-failed 값이 있으면 SSO 로그인 실패
    async checkSSOToken(token) {
        if (!token.trim()) {
            return;
        }
        if (token === 'back') {
            return;
        }
        if (token === 'need-sign-up') {
            (0, mobx_1.runInAction)(() => {
                this.errorMessage = trans('legacy_LoginStore.ts_not_registered_user');
            });
            return;
        }
        if (token === 'sign-in-failed') {
            (0, mobx_1.runInAction)(() => {
                this.errorMessage = trans('legacy_LoginStore.ts_social_login_failed');
            });
            return;
        }
        const di = this.rootStore.di;
        this.localStorage.setItem(di.tokenStorageKey, token);
        await this.startChecking();
    }
    async loginWithEmail() {
        var _a;
        const di = this.rootStore.di;
        const waiter = new Waiter_1.Waiter();
        if (!this.canSubmitLogin) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.loginState = interface_1.StudioLoginState.LoggingIn;
        });
        this.setEditableOfInputs(false);
        this.clearErrorMessage();
        try {
            await waiter.waitMinimum(delayForLogin);
            let res;
            res = await di.server.loginWriterWithEmailForStudio({
                email: this.emailInput.value,
                password: this.passwordInput.value,
            });
            // if (di.useHellobot) {
            //   res = await di.server.loginWriterWithEmailForHellobot({
            //   })
            // } else {
            //   res = await di.server.loginWriterWithEmailForStudio({
            //     email: this.emailInput.value,
            //     password: this.passwordInput.value,
            //   })
            // }
            this.localStorage.setItem(di.tokenStorageKey, res.token);
            (0, mobx_1.runInAction)(() => {
                this.setEditableOfInputs(true);
                this.user = this.rootStore.userStore.merge(res.me);
                this.loginState = interface_1.StudioLoginState.LoggedIn;
            });
            if (!((_a = this.user) === null || _a === void 0 ? void 0 : _a.isNotAuthor)) {
                await this.rootStore.loadUserData();
            }
        }
        catch (ex) {
            console.error(75, 'loginWithEmail() error : ', ex);
            (0, mobx_1.runInAction)(() => {
                this.setEditableOfInputs(true);
                this.loginState = interface_1.StudioLoginState.LoggedOut;
                this.errorMessage = this.rootStore.textStore.translateError(ex);
            });
        }
    }
    logout() {
        (0, mobx_1.runInAction)(() => {
            this.localStorage.clear();
            this.errorMessage = null;
            this.user = null;
            this.isPasswordTooOld = false;
            this.rootStore.storyEditing = null;
            this.rootStore.storyStore = new story_1.DOStoryStore(this.rootStore, spDoStoryStoreStrategy_1.SpDoStoryStoreStrategy);
            this.rootStore.userStore = new user_1.DOUserStore(this.rootStore);
            this.loginState = interface_1.StudioLoginState.LoggedOut;
        });
    }
    clearErrorMessage() {
        if (!!this.errorMessage) {
            (0, mobx_1.runInAction)(() => {
                this.errorMessage = null;
            });
        }
    }
    /** LoginStore 에 있는 input 들의 editable 상태를 조정 */
    setEditableOfInputs(isEditable) {
        this.emailInput.setEditable(isEditable);
        this.passwordInput.setEditable(isEditable);
    }
}
exports.LoginStore = LoginStore;
