import { clone } from 'lodash'
import moment from 'moment'
import React from 'react'
import { ODListableOption } from '../../../ODListable/ODListableContext'
import { ODListableTableColumnDefinition } from '../../../ODListable/ODListablePaginationTable'
import {
  IODBooleanRendererDef,
  IODColorRendererDef,
  IODImageFileRendererDef,
  IODRendererContext,
  IODStringRendererDef,
} from './renderer/common'
import { ODDefaultBooleanDef } from './renderer/ODBooleanRenderer'
import { ODDefaultColorDef } from './renderer/ODColorRenderer'
import { ODRendererFactory } from './renderer/ODRendererFactory'
import { ODDefaultStringDef } from './renderer/ODStringRenderer'

export type ODListColumnContext<ParentContextType> = {
  parentContext: ParentContextType
  onEvent?: (event: any, parentContext: ParentContextType) => void
}

type ODListColumnBuildFunc<T, O extends ODListableOption, ParentContext> = (
  org: ODListableTableColumnDefinition<T, O>,
  context: ODListColumnContext<ParentContext>
) => ODListableTableColumnDefinition<T, O>

function getKeyIn<T>(keyPath: string, value: any, defValue: T) {
  const comps = keyPath.split('.')
  let parent = value
  for (const comp of comps) {
    if (parent.hasOwnProperty(comp)) {
      parent = parent[comp]
      if (parent === null || parent === undefined) {
        return defValue
      }
    } else {
      return defValue
    }
  }
  return parent
}

export class ODListColumnBuilder<T, O extends ODListableOption, ParentContextType> {
  private buildFunctions: ODListColumnBuildFunc<T, O, ParentContextType>[] = []
  private readonly initialData: ODListableTableColumnDefinition<T, O>
  private readonly defaultKey: string

  constructor(column: string, title: string) {
    this.defaultKey = column.toString()
    this.initialData = {
      id: column.toString(),
      title,
      transform: (v) => '',
      thClass: 'text-left',
      className: 'text-left user-td',
    }
  }

  string<K extends keyof T>({
    key = this.defaultKey as K,
    rendererDef = ODDefaultStringDef,
  }: {
    key?: K
    rendererDef?: IODStringRendererDef
  } = {}) {
    this.buildFunctions.push((data, context) => {
      // for now, use ODListColumnContext as renderer's context.
      data.transform = (v: T) => (
        <ODRendererFactory
          def={rendererDef}
          value={v[key]}
          context={context as IODRendererContext<ParentContextType>}
          originalValue={v}
        />
      )
      return data
    })
    return this
  }

  time<K extends keyof T>({
    key = this.defaultKey as K,
    rendererDef = ODDefaultStringDef,
  }: {
    key?: K
    rendererDef?: IODStringRendererDef
  } = {}) {
    this.buildFunctions.push((data, context) => {
      data.transform = (v: T) => (
        <ODRendererFactory
          def={rendererDef}
          value={v[key] ? moment(v[key]).format('YYYY-MM-DD HH:mm') : '-'}
          context={context as IODRendererContext<ParentContextType>}
          originalValue={v}
        />
      )
      return data
    })
    return this
  }

  boolean<K extends keyof T>({
    key = this.defaultKey as K,
    rendererDef = ODDefaultBooleanDef,
  }: {
    key?: K
    rendererDef?: IODBooleanRendererDef
  }) {
    this.buildFunctions.push((data, context) => {
      // for now, use ODListColumnContext as renderer's context.
      data.transform = (v: T) => (
        <ODRendererFactory
          def={rendererDef}
          value={!!v[key]}
          context={context as IODRendererContext<ParentContextType>}
          originalValue={v}
        />
      )
      return data
    })
    return this
  }

  color<K extends keyof T>({
    key = this.defaultKey as K,
    rendererDef = ODDefaultColorDef,
  }: {
    key?: K
    rendererDef?: IODColorRendererDef
  }) {
    this.buildFunctions.push((data, context) => {
      // for now, use ODListColumnContext as renderer's context.
      data.transform = (v: T) => (
        <ODRendererFactory
          def={rendererDef}
          value={v[key]}
          context={context as IODRendererContext<ParentContextType>}
          originalValue={v}
        />
      )
      return data
    })
    return this
  }

  image({ key = this.defaultKey, rendererDef }: { key?: string; rendererDef: IODImageFileRendererDef }) {
    this.buildFunctions.push((data, context) => {
      data.transform = (v: T) => {
        return (
          <ODRendererFactory
            def={rendererDef}
            value={getKeyIn(key, v, '')}
            context={context as IODRendererContext<ParentContextType>}
            originalValue={v}
          />
        )
      }
      return data
    })
    return this
  }

  build(context: ODListColumnContext<ParentContextType>) {
    return this.buildFunctions.reduce((acc, fn) => fn(acc, context), clone(this.initialData))
  }
}

export function tptp<T, O extends ODListableOption, ParentContextType>(column: string, title: string) {
  return new ODListColumnBuilder<T, O, ParentContextType>(column, title)
}
