"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTFinishRemoteScript = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const validation_1 = require("../../../validation");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
class DOSTFinishRemoteScript extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            scriptId: '',
            statementType: core_1.STATEMENT_TYPE.FinishRemoteScript,
            background: '',
            toBlockAfter: '',
        }, block, uniqueId);
        this.isDeletable = false;
        this.canBeCopied = false;
        this.hasModalForEdit = true;
        this.updateBlockOptions();
        (0, mobx_1.makeObservable)(this, {
        //
        });
    }
    get st() {
        return this.data;
    }
    get blocksTo() {
        const block = this.block.store.getById(this.st.toBlockAfter);
        if (!block) {
            return [];
        }
        return [
            {
                block,
                label: '',
                extra: { statement: this },
            },
        ];
    }
    updateBlockOptions() {
        (0, mobx_1.runInAction)(() => {
            this.blockOptions = this.block.parentChapter.getBlockOptions(this.st.toBlockAfter, this.background, 
            // DOSTCallRemoteScriptSubBlock 에서 st.toBlockAfter 수정을 처리합니다.
            // tslint:disable-next-line:no-empty
            () => { }, true);
        });
    }
    changeToBlockAfterAtStatement(blockName) {
        (0, mobx_1.runInAction)(() => {
            this.st.toBlockAfter = blockName;
        });
    }
    /**
     * 내가 연결된 블록, 연결된 블록이 없으면 null
     */
    get blockTo() {
        const doBlock = this.block.store.getById(this.st.toBlockAfter);
        return doBlock !== null && doBlock !== void 0 ? doBlock : null;
    }
    validate() {
        const res = [];
        if (!this.st.toBlockAfter) {
            res.push({
                type: validation_1.StudioValidationType.FinishRemoteScriptHasNoToBlock,
                extra: {},
                severity: validation_1.StudioValidationSeverity.Error,
                source: this,
                stack: [],
            });
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = res));
        return res;
    }
    /**
     * 이 문장에서 수정을 수행해도 감싸는 서브블록을 변경해야 한다.
     * 따라서 편의함수로 서브블록을 만들어둔다.
     */
    getCallRemoteScriptSubBlock() {
        return this.block.getMySubBlock(this);
    }
    generateSheetColumnsAfterBackground() {
        const generatedOption = JSON.stringify({
            toBlockAfter: this.st.toBlockAfter,
        });
        return [this.getCallRemoteScriptSubBlock().remoteScriptId, generatedOption];
    }
}
exports.DOSTFinishRemoteScript = DOSTFinishRemoteScript;
