"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextStore = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const moment = require("moment");
const errors_1 = require("../../errors");
const models_1 = require("../../models");
const permission_1 = require("../../permission");
const encoder_1 = require("../../playData/formula/encoder");
const parser_1 = require("../../playData/formula/parser");
const i18n_1 = require("../i18n");
const entity_1 = require("./entity");
const BlockEditorInput_interface_1 = require("./entity/ui/BlockEditorInput.interface");
const template_1 = require("./template");
const validation_1 = require("./validation");
const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
class TextStore {
    constructor() {
        this.errorMessageMap = {};
        this.blockTagType = {};
        this.validationMessageMap = {};
        this.statementTypeMap = {};
        this.subBlockTypeMap = {};
        this.data = {
            BlockEditorInputButtonType: {
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Character]: trans('legacy_TextStore_character'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Talk]: trans('legacy_TextStore_line'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Think]: trans('legacy_TextStore_inner_heart'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Action]: trans('legacy_BlockEditorInput_passage_from_a_novel'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Toast]: trans('legacy_ODSToastModal_toast'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.FullWidthText]: trans('legacy_ODSFullWidthTextModal_novel_text'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.SFX]: trans('legacy_ODSSoundLayout_sound_effect'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.BGM]: trans('legacy_ods_bg_music'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Vibration]: trans('legacy_TextStore_vibration'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.BackgroundImage]: trans('legacy_BEMoveToEditBackImageDrawer_background_image'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.FullWidthImage]: trans('legacy_SPSFullWidthImageModal_illustration_image'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.ChrImage]: trans('legacy_ods_image'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.SaveProp]: trans('legacy_ODSSavePropModal_save_attribute_value'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Choice]: trans('legacy_ODSKeyboardShortcutInfoModal_option_branch'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Condition]: trans('legacy_BEConditionSubBlock_condition_branch'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.GoToBlock]: trans('legacy_ODSToBlock_scene_connection'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.CallChatBot]: trans('legacy_BECallRemoteScript_chatbot_connection'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Ending]: trans('legacy_TextStore_chapter_ending'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.FinalEnding]: trans('legacy_EndingListPage_final_ending'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Template]: trans('legacy_TextStore_additional_function'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.MorePlus]: '+',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Pause]: trans('legacy_sps_pause'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.FullScreenEffect]: trans('legacy_SPSFullScreenEffectOnOffModal_full_screen_effect'),
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.Pause]: '잠시 멈춤',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.FullScreenEffect]: '전체 화면효과',
                // 여기부터 헬봇
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageNext]: '블록 연결',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.AlgorithmBlock]: '알고리즘 블록',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageInput]: '자유답변',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageTemplate]: '빈칸 채우기',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageSelect]: '선택지',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageTarot]: '타로카드',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageFeedback]: '피드백',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageRest]: '쉬어가기',
                [BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageImage]: '이미지',
            },
            TemplateTag: {
                [template_1.TemplateTag.BulkEditing]: trans('legacy_TextStore_mass_editing'),
                [template_1.TemplateTag.Image]: trans('legacy_ods_image'),
                [template_1.TemplateTag.Block]: trans('legacy_TextStore_block'),
            },
        };
        this.loadMessages('ko');
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
        });
    }
    loadMessages(locale) {
        this.errorMessageMap = {
            [core_1.ErrorCode.ServerWrongLoginInfo]: trans('legacy_TextStore_cannot_check_subscriber_info'),
            [core_1.ErrorCode.ServerParamError]: trans('legacy_TextStore_input_value_wrong'),
            [core_1.ErrorCode.Fatal]: trans('legacy_TextStore_unexpected_problem_occurred'),
            [core_1.ErrorCode.BLOCK_NAME_MIN_LEN_NOT_MET]: trans('legacy_TextStore_block_name_at_least_one'),
            [core_1.ErrorCode.BLOCK_NAME_MAX_LEN_NOT_MET]: trans('legacy_TextStore_block_name_max_twenty'),
            [core_1.ErrorCode.BLOCK_NAME_IS_REQUIRED]: trans('legacy_TextStore_block_name_essential'),
            [core_1.ErrorCode.BLOCK_NAME_EXISTS]: trans('legacy_TextStore_Existing_Block_Name'),
            [core_1.ErrorCode.StudioServerAccountDoesNotHavePermission]: trans('legacy_TextStore_No_Permission_For_Sheet'),
            [core_1.ErrorCode.StudioServerAccountRaisedUnknownError]: trans('legacy_TextStore_Unknown_Error_During_Sheet_Export'),
            [core_1.ErrorCode.StudioServerAccountCannotFindEntity]: trans('legacy_TextStore_Cannot_Confirm_Sheet_Check_ID'),
            [core_1.ErrorCode.StudioPrivNotAuthor]: trans('legacy_TextStore_No_Author_Permission'),
            [core_1.ErrorCode.ErrorCommunicatingWithAchievementServer]: trans('legacy_TextStore_Server_Error_Related_To_Achievement_Linkage'),
            [core_1.ErrorCode.CHANGE_NOT_FOUND]: trans('legacy_TextStore_No_Changes'),
            [core_1.ErrorCode.SUBMIT_IN_PROGRESS]: trans('legacy_TextStore_Saving'),
            [core_1.ErrorCode.NOT_SUPPORTED]: trans('legacy_TextStore_Feature_Not_Supported_Yet'),
            [core_1.ErrorCode.StudioNotEnoughToAuthor]: trans('legacy_TextStore_Fill_All_Required_Inputs'),
            [core_1.ErrorCode.StudioUserHasBeenBanned]: trans('legacy_TextStore_Paused_Writer_Account'),
            [core_1.ErrorCode.ApplyScriptInProgress]: trans('legacy_TextStore_Save_In_Progress'),
            [core_1.ErrorCode.CannotApplyScriptWhenValidationErrorExists]: trans('legacy_TextStore_Inspection_Error'),
            [core_1.ErrorCode.DuplicateChapterDraftRequiresChapterDraftInEditing]: trans('legacy_TextStore_Draft_Data_Needed_For_Draft_Clone'),
            [core_1.ErrorCode.SameDisplayPropNameAlreadyExist]: trans('legacy_TextStore_Same_Property_Value_Exists'),
            [core_1.ErrorCode.CannotReserveChapterPublishIfEarlierThanThePresent]: trans('legacy_TextStore_Cannot_Schedule_Chapter_Publication_Earlier_Than_Current_Time'),
            [core_1.ErrorCode.CannotPublishChapterWithoutPrecedingChapterNotPublished]: trans('legacy_ChapterRow_previous_not_published'),
            [core_1.ErrorCode.CannotReserveChapterPublishLaterNextChapter]: trans('legacy_TextStore_Must_Publish_Before_Next_Chapter'),
            [core_1.ErrorCode.CannotReserveChapterPublishEarlierThenPrevChapter]: trans('legacy_TextStore_Cannot_Schedule_Faster_Than_Previous_Chapter'),
            [core_1.ErrorCode.StudioPermissionSchemaJsonInvalid]: trans('legacy_TextStore_Permission_Schema_Error'),
            [core_1.ErrorCode.StudioCannotRemoveLastOwner]: trans('legacy_TextStore_At_Least_One_Responsible_Writer_Needed'),
            [core_1.ErrorCode.StudioTargetUserPrivNotAuthor]: trans('legacy_TextStore_User_Permission_Lower_Than_Writer'),
            [core_1.ErrorCode.NoPermission]: trans('legacy_TextStore_No_Permission_To_Perform_Task'),
            [core_1.ErrorCode.StudioCannotChangePublishStatusNotApprovedStory]: trans('legacy_TextStore_Work_Not_Approved_By_Admin'),
            [core_1.ErrorCode.SameDisplayPropNameAlreadyExist]: trans('legacy_TextStore_Existing_Property_Name'),
            [core_1.ErrorCode.StoryPropertyAlreadyRegistered]: trans('legacy_TextStore_Existing_Property_Value_ID'),
            [core_1.ErrorCode.StudioCannotRemoveIsPublished]: trans('legacy_TextStore_Cannot_Delete_Published_Chapter'),
            [core_1.ErrorCode.UserEmailDoesNotExist]: trans('legacy_TextStore_Cannot_Find_Registered_Email'),
            [core_1.ErrorCode.PasswordPatternTooSimple]: trans('legacy_TextStore_Enter_New_Password_With_All_Characters'),
            [core_1.ErrorCode.OldPasswordDoesNotMatch]: trans('legacy_TextStore_current_password_incorrect'),
            [core_1.ErrorCode.CurrentPasswordIsTheSameAsNewOne]: trans('legacy_TextStore_enter_current_new_password_differently'),
            [core_1.ErrorCode.PropNameShouldNotIncludeColon]: trans('legacy_TextStore_guest_property_name_should_not_include'),
            [core_1.ErrorCode.ItemNameShouldNotIncludeColon]: trans('legacy_TextStore_guest_item_name_should_not_include'),
            [core_1.ErrorCode.CHR_NAME_MIN_LEN_NOT_MET]: trans('legacy_TextStore_character_name_at_least_one_korean'),
            [core_1.ErrorCode.CHR_NAME_MAX_LEN_NOT_MET]: trans('legacy_TextStore_character_name_max_140_chars'),
            [core_1.ErrorCode.ITEM_NAME_MIN_LEN_NOT_MET]: trans('legacy_TextStore_item_name_at_least_one_korean'),
            [core_1.ErrorCode.ITEM_NAME_MAX_LEN_NOT_MET]: trans('legacy_TextStore_item_name_max_20_chars'),
            [core_1.ErrorCode.StudioStoryRequireGenre]: trans('legacy_TextStore_select_genre'),
            [core_1.ErrorCode.UserLeft]: trans('legacy_TextStore_withdrawn_user_login_valid_account'),
            [core_1.ErrorCode.ChapterNameForbiddenCharacterIsIncluded]: trans('legacy_TextStore_chapter_name_no_colon'),
            [core_1.ErrorCode.OtherNamePopupNeedDefaultFirstName]: trans('legacy_TextStore_enter_default_character_name'),
            [core_1.ErrorCode.OtherNamePopupNeedDefaultLastName]: trans('legacy_TextStore_enter_default_character_surname'),
            [core_1.ErrorCode.ActiveChapterDraftDoesNotExists]: trans('legacy_TextStore_no_temporary_save'),
            [core_1.ErrorCode.PropNameShouldContainCharacter]: trans('legacy_TextStore_enter_attribute_value'),
            [core_1.ErrorCode.PropNameShouldNotStartOrEndWithSpace]: trans('legacy_TextStore_no_space_before_after_attribute_name'),
            [core_1.ErrorCode.ItemNameShouldContainCharacter]: trans('legacy_TextStore_guest_please_enter_the_item_name'),
            [core_1.ErrorCode.ItemNameShouldNotStartOrEndWithSpace]: trans('legacy_TextStore_no_space_before_after_item_name'),
            [core_1.ErrorCode.StoryNotFoundOnLoad]: trans('legacy_TextStore_cannot_check_work_info'),
            [core_1.ErrorCode.ErrorOnChapterLoad]: trans('legacy_TextStore_error_loading_work_info'),
            [core_1.ErrorCode.CannotFindChapter]: trans('legacy_TextStore_cannot_find_episode_in_work'),
            [core_1.ErrorCode.StartEditingChapterFailed]: trans('legacy_TextStore_failed_loading_episode_script'),
            [core_1.ErrorCode.PropNameMinLengthNotMet]: trans('legacy_TextStore_attribute_name_at_least_one_char'),
            [core_1.ErrorCode.PropNameMinLengthNotMet]: trans('legacy_TextStore_attribute_name_at_least_one_char'),
            [core_1.ErrorCode.TTSSpeakerNotFound]: trans('legacy_TextStore_select_speaker'),
            [core_1.ErrorCode.HasScriptErrorBeforeStartingInstant]: trans('legacy_TextStore_cannot_ignore_scripter_error'),
            [core_1.ErrorCode.InstantTestingHasNoPreviousEnding]: trans('legacy_TextStore_select_previous_ending'),
            [core_1.ErrorCode.InstantTestingHasNoStartingBlock]: trans('legacy_TextStore_no_start_block_for_test'),
            [core_1.ErrorCode.EndingHasNoImage]: trans('legacy_TextStore_no_ending_image'),
            [core_1.ErrorCode.ItemHasNoName]: trans('legacy_TextStore_enter_item_name'),
            [core_1.ErrorCode.PropHasNoName]: trans('legacy_ODSEditPropModalLayout_enter_property_value_id'),
            [core_1.ErrorCode.TTSHasNoPlayText]: trans('legacy_TextStore_enter_sentence'),
            [core_1.ErrorCode.TTSPlayTextMaxLenNotMet]: trans('legacy_TextStore_tts_max_200_chars'),
            [core_1.ErrorCode.ChapterBundleLoadFailed]: trans('legacy_TextStore_failed_to_load_bundle'),
            [core_1.ErrorCode.ContestNumberDuplicated]: trans('legacy_TextStore_no_duplicate_numbers'),
            [core_1.ErrorCode.HasNoAuthorBeforeAddingMember]: trans('legacy_TextStore_select_writer'),
            [core_1.ErrorCode.NeedToApplyChapterBeforeAddingEndingPreview]: trans('legacy_TextStore_the_ending_preview_can_be_added_after_apply'),
            [core_1.ErrorCode.ChapterIsSavingAlready]: trans('legacy_TextStore_saving'),
            [core_1.ErrorCode.PositionThatCanNotAddLine]: trans('legacy_TextStore_cannot_add_sentence'),
            [core_1.ErrorCode.ChrOnboardingRequireFirstName]: trans('legacy_TextStore_enter_character_name'),
            [core_1.ErrorCode.ChrOnboardingRequireLastName]: trans('legacy_TextStore_enter_character_surname'),
            [core_1.ErrorCode.PaidPerChapterCanNotChangePaidPerStory]: '이미 회차 단위로 판매되고 있는 작품은 통구매 설정이 불가합니다.',
            [core_1.ErrorCode.StoryHasAdultChapter]: '해당 작품에 청소년 이용불가 회차가 이미 존재하여 청소년 이용불가 작품으로 변경이 불가합니다.',
            [core_1.ErrorCode.ChapterHasAdultStory]: '헤당 작품은 청소년 이용불가 작품으로, 개별회차를 청소년 이용불가 회차로 설정할 수 없습니다.',
        };
        this.blockTagType = {
            [entity_1.BlockTagType.Start]: trans('legacy_FlowChartBlock_start'),
            [entity_1.BlockTagType.NoTag]: trans('legacy_TextStore_general_block'),
            [entity_1.BlockTagType.ChapterEnding]: trans('legacy_TextStore_chapter_ending'),
            [entity_1.BlockTagType.FinalEnding]: trans('legacy_TexㄴtStore_final_ending'),
            [entity_1.BlockTagType.ChatBotBlock]: trans('legacy_FlowChartBlock_chatbot_block'),
        };
        this.validationMessageMap = {
            [validation_1.StudioValidationType.BlockHasNoFinish]: trans('legacy_TextStore_block_end_not_ended'),
            [validation_1.StudioValidationType.ChoiceHasNoToBlock]: trans('legacy_TextStore_empty_choice_block'),
            [validation_1.StudioValidationType.ChoiceHasInvalidBlockId]: trans('legacy_TextStore_nonexistent_block_in_choice'),
            [validation_1.StudioValidationType.ToBlockHasNoBlock]: trans('legacy_TextStore_empty_block_in_connection'),
            [validation_1.StudioValidationType.ToBlockHasInvalidBlockId]: trans('legacy_TextStore_nonexistent_block_in_connection'),
            [validation_1.StudioValidationType.ConditionBlockHasNoToBlock]: trans('legacy_TextStore_unconnected_condition_in_branch'),
            [validation_1.StudioValidationType.ConditionBlockHasInvalidBlockId]: trans('legacy_TextStore_nonexistent_block_in_condition'),
            [validation_1.StudioValidationType.FinalEndingHasNoImage]: trans('legacy_TextStore_must_set_image_for_ending'),
            [validation_1.StudioValidationType.NoBackgroundImage]: trans('legacy_TextStore_recommend_background_image'),
            [validation_1.StudioValidationType.DefaultEndingDoesNotExist]: trans('legacy_TextStore_one_default_ending_required'),
            [validation_1.StudioValidationType.BGMOffIsRequired]: trans('legacy_TextStore_bgm_on_requires_bgm_off'),
            [validation_1.StudioValidationType.EndingExistsButOtherChapter]: trans('legacy_TextStore_ending_id_exists_in_other_chapter'),
            [validation_1.StudioValidationType.FinalEndingHasNoDesc]: trans('legacy_TextStore_final_ending_description_required'),
            [validation_1.StudioValidationType.EndingIdDuplicate]: res => {
                var _a, _b, _c, _d, _e, _f;
                return trans('legacy_TextStore_duplicate_ending_id', {
                    value1: (_c = (_b = (_a = res.extra) === null || _a === void 0 ? void 0 : _a.chapter) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '-',
                    value2: (_f = (_e = (_d = res.extra) === null || _d === void 0 ? void 0 : _d.block) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : '-',
                });
            },
            [validation_1.StudioValidationType.EndingNameDuplicate]: res => {
                var _a, _b, _c, _d, _e, _f;
                return trans('legacy_TextStore_duplicate_ending_name', {
                    value1: (_c = (_b = (_a = res.extra) === null || _a === void 0 ? void 0 : _a.chapter) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '-',
                    value2: (_f = (_e = (_d = res.extra) === null || _d === void 0 ? void 0 : _d.block) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : '-',
                });
            },
            [validation_1.StudioValidationType.ChoiceNameDuplicate]: res => {
                var _a, _b, _c, _d, _e, _f;
                return trans('legacy_TextStore_duplicate_choice_name', {
                    value1: (_c = (_b = (_a = res.extra) === null || _a === void 0 ? void 0 : _a.chapter) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '-',
                    value2: (_f = (_e = (_d = res.extra) === null || _d === void 0 ? void 0 : _d.block) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : '-',
                });
            },
            [validation_1.StudioValidationType.BlockNameShouldBeTrimmed]: trans('legacy_TextStore_block_name_has_blank'),
            [validation_1.StudioValidationType.UserInputChoiceNoImage]: trans('legacy_TextStore_no_image_in_user_input'),
            [validation_1.StudioValidationType.UserInputChoiceNoLeftText]: trans('legacy_TextStore_no_dialogue_in_user_input'),
            [validation_1.StudioValidationType.ChrNameMaxLenNotMet]: trans('legacy_TextStore_character_name_max_140_chars'),
            [validation_1.StudioValidationType.ImageChoiceNoImage]: res => `${trans('legacy_TextStore_the_image_for_the_nth_image_option_is_missing', { value: res.extra.index + 1 })}`,
            [validation_1.StudioValidationType.RemovedEndingHasPlayDataSoCannotApply]: res => `${trans('legacy_TextStore_the_ending_with_id_n_cannot_be_deleted_as_there_are_users_who_have_acquired_it_ending_name_n', { value1: res.extra.endingId, value2: res.extra.endingName })}`,
            [validation_1.StudioValidationType.UnhandledServerTransQueryError]: res => trans('legacy_TextStore_error_during_publishing', {
                value: res.extra.error,
            }),
            [validation_1.StudioValidationType.CallRemoteScriptHasNoProvider]: trans('legacy_TextStore_enter_chatbot_provider'),
            [validation_1.StudioValidationType.FinishRemoteScriptHasNoToBlock]: trans('legacy_TextStore_select_scene_after_chatbot'),
            [validation_1.StudioValidationType.CallRemoteScriptHasNoParam]: trans('legacy_TextStore_enter_advanced_formula'),
            [validation_1.StudioValidationType.StatsChoiceHasNoDisplayName]: trans('legacy_TextStore_statistical_choice_needs_title'),
            [validation_1.StudioValidationType.PropNameShouldNotIncludeCommaInCondition]: trans('legacy_TextStore_in_the_condition_for_branching_general_properties_and_my_values_should_not_contain'),
            [validation_1.StudioValidationType.ItemNameShouldNotIncludeCommaInCondition]: trans('legacy_TextStore_in_the_condition_for_branching_items_should_not_contain'),
            [validation_1.StudioValidationType.PropNameShouldNotBeEmptyValue]: trans('legacy_TextStore_select_attribute'),
            [validation_1.StudioValidationType.ItemNameShouldNotBeEmptyValue]: trans('legacy_TextStore_select_item'),
            [validation_1.StudioValidationType.AchievementNameShouldNotBeEmptyValue]: trans('legacy_TextStore_select_event'),
            [validation_1.StudioValidationType.RemoteScriptHasNoFallbackScript]: trans('legacy_TextStore_direct_scene_if_chatbot_fails'),
            [validation_1.StudioValidationType.ChatGPTCallRemoteScriptHasNoPrompt]: trans('legacy_TextStore_enter_prompt_in_chatgpt'),
            [validation_1.StudioValidationType.ChatGPTCallRemoteScriptHasNoScriptForm]: trans('legacy_TextStore_complete_answer_setting_in_chatgpt'),
            [validation_1.StudioValidationType.ChatGPTCallRemoteScriptHasNoBackground]: trans('legacy_TextStore_set_background_in_chatgpt'),
            [validation_1.StudioValidationType.ChatGPTCallRemoteScriptScriptFormInvalidSourceLine]: trans('legacy_TextStore_incorrect_sourceline_in_chatgpt'),
            [validation_1.StudioValidationType.ChatGPTCallRemoteScriptScriptFormInvalidMessage]: trans('legacy_TextStore_incorrect_message_in_chatgpt'),
            [validation_1.StudioValidationType.ChatGPTCallRemoteScriptScriptFormHasNoStatementType]: trans('legacy_TextStore_incorrect_sentence_in_chatgpt'),
            [validation_1.StudioValidationType.ChatGPTCallRemoteScriptScriptFormTalkAndThinkHasNoChr]: trans('legacy_TextStore_no_character_selected_in_chatgpt'),
            [validation_1.StudioValidationType.StringPropHasNoValueInCondition]: trans('legacy_TextStore_enter_value_to_compare_in_condition'),
            [validation_1.StudioValidationType.StringSavePropHasNoValue]: trans('legacy_TextStore_enter_value_in_text_attribute'),
            [validation_1.StudioValidationType.StringPropHasNoValueInChoice]: trans('legacy_TextStore_enter_value_in_choice_attribute'),
            [validation_1.StudioValidationType.StringPropInvalidConditionType]: trans('legacy_TextStore_invalid_condition_type_set'),
            [validation_1.StudioValidationType.FullScreenEffectOnHasInvalidFileLink]: trans('legacy_TextStore_register_file_for_fullscreen_effect'),
            [validation_1.StudioValidationType.FullScreenEffectOnHasInvalidImageWidth]: trans('legacy_TextStore_no_image_width_in_fullscreen_effect'),
            [validation_1.StudioValidationType.FullScreenEffectOnHasInvalidImageHeight]: trans('legacy_TextStore_no_image_height_in_fullscreen_effect'),
            [validation_1.StudioValidationType.FullScreenEffectOnHasInvalidFileType]: trans('legacy_TextStore_set_file_type_for_fullscreen_effect'),
            [validation_1.StudioValidationType.FullScreenEffectOnHasInvalidScreenFillMethod]: trans('legacy_TextStore_set_fullscreen_effect_on_image_fill_method'),
            [validation_1.StudioValidationType.FullScreenEffectOnHasInvalidTransitionStyle]: trans('legacy_TextStore_set_fullscreen_effect_on_effect'),
            [validation_1.StudioValidationType.FullScreenEffectOnHasInvalidAnimationDuration]: trans('legacy_TextStore_set_fullscreen_effect_on_animation_duration'),
            [validation_1.StudioValidationType.FullScreenEffectOffHasInvalidTransitionStyle]: trans('legacy_TextStore_set_fullscreen_effect_off_animation_effect'),
            [validation_1.StudioValidationType.FullScreenEffectOffHasInvalidTransitionDuration]: trans('legacy_TextStore_set_fullscreen_effect_off_animation_duration'),
            [validation_1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidImageLink]: trans('legacy_TextStore_register_illustration_image_popup_image'),
            [validation_1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidWidth]: trans('legacy_TextStore_set_illustration_image_popup_image_width'),
            [validation_1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidHeight]: trans('legacy_TextStore_set_illustration_image_popup_image_height'),
            [validation_1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidDuration]: trans('legacy_TextStore_set_illustration_image_popup_image_animation_duration'),
            [validation_1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidShowTransition]: trans('legacy_TextStore_set_illustration_image_popup_image_open_effect'),
            [validation_1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidHideTransition]: trans('legacy_TextStore_set_illustration_image_popup_image_close_effect'),
            [validation_1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidFillMethod]: trans('legacy_TextStore_set_illustration_image_popup_image_fill_method'),
            [validation_1.StudioValidationType.PropNameShouldNotIncludeComma]: '일반 속성, 내 수치에는 ","가 포함되지 않아야 합니다.',
            [validation_1.StudioValidationType.ItemNameShouldNotIncludeComma]: '아이템에는 ","가 포함되지 않아야 합니다.',
            [validation_1.StudioValidationType.MetaSavePropNoValue]: '속성 또는 아이템에 값이 존재해야 합니다.',
        };
        this.statementTypeMap = {
            [models_1.STATEMENT_TYPE.Condition]: trans('legacy_TextStore_condition'),
            [models_1.STATEMENT_TYPE.Choice]: trans('legacy_TextStore_choice'),
            [models_1.STATEMENT_TYPE.ToBlock]: trans('legacy_TextStore_block_connection'),
            [models_1.STATEMENT_TYPE.Script]: trans('legacy_BlockEditorInput_passage_from_a_novel'),
            [models_1.STATEMENT_TYPE.MainCharacterTalk]: trans('legacy_TextStore_main_character_dialogue'),
            [models_1.STATEMENT_TYPE.CharacterTalk]: trans('legacy_TextStore_line'),
            [models_1.STATEMENT_TYPE.MainCharacterThink]: trans('legacy_TextStore_main_character_inner_thoughts'),
            [models_1.STATEMENT_TYPE.CharacterThink]: trans('legacy_TextStore_inner_heart'),
            [models_1.STATEMENT_TYPE.BackgroundImage]: trans('legacy_BEMoveToEditBackImageDrawer_background_image'),
            [models_1.STATEMENT_TYPE.Image]: trans('legacy_ods_image'),
            [models_1.STATEMENT_TYPE.EndingSummary]: trans('legacy_TextStore_chapter_ending'),
            [models_1.STATEMENT_TYPE.FinalEnding]: trans('legacy_EndingListPage_final_ending'),
            [models_1.STATEMENT_TYPE.SaveProp]: trans('legacy_TextStore_property_storage'),
            [models_1.STATEMENT_TYPE.Toast]: trans('legacy_ODSToastModal_toast'),
            [models_1.STATEMENT_TYPE.MessageImage]: trans('legacy_TextStore_message_image'),
            [models_1.STATEMENT_TYPE.MainCharacterMessageImage]: trans('legacy_TextStore_main_character_message_image'),
            [models_1.STATEMENT_TYPE.FullWidthImage]: trans('legacy_ods_image'),
            [models_1.STATEMENT_TYPE.Vibration]: trans('legacy_TextStore_vibration'),
            [models_1.STATEMENT_TYPE.SoundEffect]: trans('legacy_TextStore_sound_effect'),
            [models_1.STATEMENT_TYPE.AchievementEvent]: trans('legacy_TextStore_achievement_event'),
            [models_1.STATEMENT_TYPE.BGMon]: trans('legacy_TextStore_turn_on_background_music'),
            [models_1.STATEMENT_TYPE.BGMoff]: trans('legacy_TextStore_turn_off_background_music'),
            [models_1.STATEMENT_TYPE.FullWidthText]: trans('legacy_ODSFullWidthTextModal_novel_text'),
        };
        this.subBlockTypeMap = {
            [entity_1.DOSubBlockType.Choice]: trans('legacy_TextStore_choice'),
            [entity_1.DOSubBlockType.ConditionSubBlock]: trans('legacy_TextStore_condition'),
            [entity_1.DOSubBlockType.FinalEnding]: trans('legacy_EndingListPage_final_ending'),
        };
    }
    parseGraphQLError(error) {
        var _a, _b;
        try {
            const graphQLErrorCode = (_b = (_a = error.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.extensions.code;
            if (!!graphQLErrorCode) {
                return new core_1.SPError(graphQLErrorCode);
            }
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.log(`TextStore.parsingError => ${ex}`);
        }
        return error;
    }
    translateError(error) {
        let err = error;
        err = this.parseGraphQLError(err);
        if (!(err instanceof errors_1.SPCError) && !(err instanceof core_1.SPError)) {
            return `UError [${err.toString()}]`;
        }
        const reg = this.errorMessageMap[err.code];
        if (err instanceof core_1.SPError) {
            if (!reg) {
                return `SPError [${err.code}]`;
            }
            if (err.code === core_1.ErrorCode.Fatal && err.message) {
                return `SP-FATAL [${err.message}]`;
            }
        }
        if (err instanceof errors_1.SPCError) {
            if (!reg) {
                return `SPCError [${err.code}]`;
            }
            if (err.code === core_1.ErrorCode.Fatal && err.extra) {
                return `SPC-FATAL [${err.extra.message}]`;
            }
        }
        if (reg && (0, lodash_1.isString)(reg)) {
            return reg;
        }
        return reg(err);
    }
    getUserPropNameText(propUpdate) {
        if (propUpdate.propName === entity_1.PROP_EMPTY) {
            return trans('legacy_TextStore_not_selected');
        }
        return propUpdate.propName;
    }
    getUserPropUpdateSetText(propUpdate) {
        const val = parseInt(propUpdate.value, 10);
        if (propUpdate.propOp === models_1.PROP_OPERATION.SET_NUMBER ||
            propUpdate.propOp === models_1.PROP_OPERATION.SET_TEXT) {
            return `${trans('legacy_TextStore_set_to_n', {
                value: propUpdate.value,
            })}`;
        }
        else if (propUpdate.propOp === models_1.PROP_OPERATION.CALCULATE) {
            try {
                return `${trans('legacy_TextStore_set_to_n', {
                    value: this.encodeSPExprFormulaAfterParse(propUpdate.value),
                })}`;
            }
            catch (ex) {
                return `${trans('legacy_TextStore_cannot_interpret_the_saved_syntax_n', { value: propUpdate.value })}`;
            }
        }
        else {
            return `${val < 0 ? '-' : '+'}${Math.abs(val)}`;
        }
    }
    getUserItemNameText(itemUpdate) {
        if (itemUpdate.itemName === entity_1.ITEM_EMPTY) {
            return trans('legacy_TextStore_item_value2');
        }
        return itemUpdate.itemName;
    }
    getUserItemUpdateSetText(itemUpdate) {
        const val = parseInt(itemUpdate.itemCount, 10);
        return val < 0
            ? trans('legacy_TextStore_item_consumed')
            : trans('legacy_TextStore_got_item');
    }
    translateValidationResults(res, verbose = false) {
        var _a;
        if (res.type === validation_1.StudioValidationType.UnhandledScripterError) {
            return res.extra.message;
        }
        const fnOrMsg = this.validationMessageMap[res.type];
        if (!fnOrMsg) {
            // ErrorCode 인 경우에 대한 처리
            const reg = this.errorMessageMap[res.type];
            if (reg) {
                if ((0, lodash_1.isString)(reg)) {
                    return reg;
                }
                return reg(new errors_1.SPCError(res.type, (_a = res.extra) !== null && _a !== void 0 ? _a : {}));
            }
        }
        const msg = (0, lodash_1.isString)(fnOrMsg)
            ? fnOrMsg
            : (0, lodash_1.isFunction)(fnOrMsg)
                ? fnOrMsg(res)
                : `UNKNOWN [${res.type}]`;
        return verbose ? `${res.source.validatorName}: ${msg}` : msg;
    }
    getStatementTypeTextForDeleteModal(statementType) {
        const convertedType = this.statementTypeMap[statementType];
        switch (statementType) {
            case models_1.STATEMENT_TYPE.Script: {
                return {
                    name: convertedType,
                    assistantText: trans('legacy_TextStore_second'),
                };
            }
            default: {
                return {
                    name: trans('legacy_TextStore_message'),
                    assistantText: trans('legacy_TextStore_cast'),
                };
            }
        }
    }
    getSubBlockTypeTextForDeleteModal(subBlockType) {
        // const convertedType = this.subBlockTypeMap[subBlockType]
        switch (subBlockType) {
            default: {
                return {
                    name: trans('legacy_TextStore_message'),
                    assistantText: trans('legacy_TextStore_cast'),
                };
            }
        }
    }
    getDateTimeText(t) {
        return moment(t).format('lll');
    }
    getTextForStoryUserPriv(priv) {
        return {
            [permission_1.STORY_USER_PRIV.Owner]: trans('legacy_TextStore_responsible_writer'),
            [permission_1.STORY_USER_PRIV.CoEditor]: trans('legacy_TextStore_assistant_writer'),
            [permission_1.STORY_USER_PRIV.Guest]: trans('legacy_TextStore_guest'),
            [permission_1.STORY_USER_PRIV.Pending]: '-',
            [permission_1.STORY_USER_PRIV.Rejected]: '--',
        }[priv];
    }
    getTextForStoryUserPrivTrans(priv) {
        const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
        return {
            [permission_1.STORY_USER_PRIV.Owner]: trans('legacy_TextStore_responsible_writer'),
            [permission_1.STORY_USER_PRIV.CoEditor]: trans('legacy_TextStore_assistant_writer'),
            [permission_1.STORY_USER_PRIV.Guest]: trans('legacy_TextStore_guest'),
            [permission_1.STORY_USER_PRIV.Pending]: '-',
            [permission_1.STORY_USER_PRIV.Rejected]: '--',
        }[priv];
    }
    /**
     * json 으로 인코딩된 SPExpr 을 사용자가 이해할 수 있는 formula 로 변경한다.
     */
    encodeSPExprFormulaAfterParse(exprInJson, messageOnError = '') {
        try {
            const pdOperation = JSON.parse(exprInJson);
            return (0, encoder_1.encodeSPExprFormula)(pdOperation);
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.error(`Error while encodeSPExprFormulaAfterParse : `, exprInJson, ex);
            return messageOnError;
        }
    }
    parseFormulaWithFallback(formula) {
        try {
            return (0, parser_1.parseSPExprFormula)(formula);
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.error(386, ex);
            // 잘못된 수식을 입력했을 때에 대한 처리 방법이 없어 우선 '0'으로 반환한다.
            return '0';
        }
    }
}
exports.TextStore = TextStore;
