import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Button, Card, CardBody, Label } from 'reactstrap'
import {
  GQLChapterComment,
  GQLChapterCommentOrderBy,
  GQLCHAPTER_COMMENT_ORDER_FIELD,
  GQLORDER_BY_DIRECTION,
  GQLStory,
} from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { USER_PRIV_TO_STRING } from '../../../utils/constants'
import { Utils } from '../../../utils/utils'
import {
  ChapterCommentPrimaryKey,
  IChapterCommentListableOption,
} from '../ChapterCommentCommon'
import {
  ChapterCommentChapterSelect,
  ChapterCommentChapterSelectFilterType,
} from './ChapterCommentChapterSelect'
import { ChapterCommentChoiceSelect } from './ChapterCommentChoiceSelect'
import { useChapterCommentListDataLoader } from './ChapterCommentListDataLoader'
import { ChapterCommentListItem } from './ChapterCommentListItem'
import { ChapterCommentSortSelect } from './ChapterCommentSortSelect'
import { ChapterCommentTypeSelect } from './ChapterCommentTypeSelect'

interface IChapterCommentListableTableProps {
  storyId: number
  parentId: number | null
}

export const ChapterCommentListableTable: React.FC<
  IChapterCommentListableTableProps
> = (props) => {
  const { storyId, parentId } = props
  const { Provider, Context } = useODListableContext<
    GQLChapterComment,
    IChapterCommentListableOption
  >()
  const [chapterIdFilter, setChapterIdFilter] = React.useState<number | null>(
    null
  )
  const [choiceIdFilter, setChoiceIdFilter] = React.useState<number | null>(
    null
  )
  const [typeFilter, setTypeFilter] = React.useState<string | null>(null)
  const defaultOrderFilterValue = !!parentId
    ? [
        {
          field: 'UserLikes' as GQLCHAPTER_COMMENT_ORDER_FIELD,
          direction: 'DESC' as GQLORDER_BY_DIRECTION,
        },
        {
          field: 'CreatedDate' as GQLCHAPTER_COMMENT_ORDER_FIELD,
          direction: 'ASC' as GQLORDER_BY_DIRECTION,
        },
      ]
    : [
        {
          field: 'CreatedDate' as GQLCHAPTER_COMMENT_ORDER_FIELD,
          direction: 'DESC' as GQLORDER_BY_DIRECTION,
        },
      ]
  const [orderFilterValue, setOrderFilterValue] = React.useState<
    GQLChapterCommentOrderBy[]
  >(defaultOrderFilterValue)
  const [chapterOptions, setChapterOptions] = React.useState<
    ChapterCommentChapterSelectFilterType[]
  >([])
  const [token] = useCounter()
  const dataLoader = useChapterCommentListDataLoader(
    storyId,
    parentId,
    chapterIdFilter,
    choiceIdFilter,
    typeFilter,
    orderFilterValue
  )
  const [redirect, setRedirect] = React.useState('')
  const { getAllChaptersById: apiGetAllChapters } = useStoryPlayAPIs()

  React.useEffect(() => {
    apiGetAllChapters({ id: storyId }).then((story: GQLStory) => {
      setChapterOptions(
        story.allChapters.map((chapter) => ({
          chapterIndex: chapter.chapterIndex,
          chapterId: chapter.chapterId,
          chapterName: chapter.name,
        }))
      )
    })
  }, [apiGetAllChapters, storyId])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = { display: 'flex', marginBottom: 15 }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[ChapterCommentPrimaryKey].toString()}
      pageSize={500}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="댓글 본문 내용으로 검색"
              style={searchBoxStyle}
            />
            <Label style={{ marginLeft: 15, alignItems: 'center' }}>
              댓글 타입 선택
            </Label>
            <div style={{ width: 250, marginLeft: 5 }}>
              <ChapterCommentTypeSelect
                value={typeFilter}
                onChange={setTypeFilter}
              />
            </div>
            {!parentId && (
              <>
                <Label style={{ marginLeft: 15, alignItems: 'center' }}>
                  회차 선택
                </Label>
                <div style={{ width: 250, marginLeft: 5 }}>
                  <ChapterCommentChapterSelect
                    value={chapterIdFilter}
                    onChange={setChapterIdFilter}
                    filterTypes={chapterOptions}
                  />
                </div>
              </>
            )}
            {!parentId && chapterIdFilter && (
              <>
                <Label style={{ marginLeft: 15 }}>선택지 선택</Label>
                <div style={{ width: 250, marginLeft: 5 }}>
                  <ChapterCommentChoiceSelect
                    value={choiceIdFilter}
                    onChange={setChoiceIdFilter}
                    chapterId={chapterIdFilter}
                  />
                </div>
              </>
            )}
            {!parentId && (
              <>
                <Label style={{ marginLeft: 15 }}>정렬 기준</Label>
                <div style={{ width: 250, marginLeft: 5 }}>
                  <ChapterCommentSortSelect
                    defaultLabel="최신 생성 순"
                    onChange={(option) => {
                      // for list to refresh
                      setOrderFilterValue(option.value)
                    }}
                  />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <Link
                    to={SiteUrls.Admin.Story.EditChapterComment(storyId, 0)}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button block color="primary" style={{ minWidth: 135 }}>
                      댓글 쓰기
                    </Button>
                  </Link>
                </div>
              </>
            )}
          </div>
          <ODListablePaginatedTable2
            numColumns={10}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th style={{ minWidth: 50 }}>댓글 내용</th>
                <th>작성자</th>
                <th>회차/타입/선택지</th>
                <th>좋아요 수</th>
                {!parentId && <th>대댓글 수</th>}
                <th>누적 신고 수</th>
                <th>스포일러 포함?</th>
                <th>작성된 시각</th>
                <th>내용 수정 시각</th>
              </tr>
            )}
            renderRow={(value: GQLChapterComment, context) => (
              <HoverTR
                key={value.ccId}
                onClick={() =>
                  setRedirect(
                    SiteUrls.Admin.Story.EditChapterComment(storyId, value.ccId)
                  )
                }
              >
                <td>{value.ccId}</td>
                <td style={{ wordBreak: 'break-all' }}>
                  <ChapterCommentListItem
                    chapterComment={value}
                    contentType={'content'}
                  />
                </td>
                <td>
                  {`${value.author?.name}(${
                    USER_PRIV_TO_STRING[value.author?.priv!]
                  })` || '알 수 없음'}
                </td>
                <td>
                  <ChapterCommentListItem
                    chapterComment={value}
                    contentType={'type'}
                  />
                </td>
                <td>{`${value.numOfLikes} = (유저)${value.numOfUserLikes} + (어드민)${value.numOfBaseLikes}`}</td>
                {!parentId && (
                  <td style={{ maxWidth: 300 }}>{value.numOfNestedComments}</td>
                )}
                <td>{value.reports.length}</td>
                <td>{value.isSpoiler ? 'Y' : 'N'}</td>
                <td>{Utils.formatDate(value.createdAt)}</td>
                <td>
                  {value.contentEditedAt
                    ? Utils.formatDate(value.contentEditedAt)
                    : `수정된 적 없음`}
                </td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
