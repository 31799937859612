"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALTextModal = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ALTextModal {
    constructor(modalTitle, text, rightButtonText, onRightButtonClick, leftButtonText, onLeftButtonClick, showCancelButton = true) {
        this.leftButtonText = trans('legacy_ALCreateNewChapter_cancel'); // leftButton 은 대부분 취소 버튼인데, 예외의 경우도 있으므로 커스텀 가능하도록 작업
        this.onLeftButtonClick = null;
        this.showCancelButton = false;
        this.modalTitle = modalTitle;
        this.textUnderTitle = text;
        this.rightButtonText = rightButtonText;
        this.onRightButtonClick = onRightButtonClick;
        if (!!leftButtonText) {
            this.leftButtonText = leftButtonText;
        }
        if (!!onLeftButtonClick) {
            this.onLeftButtonClick = onLeftButtonClick;
        }
        this.showCancelButton = showCancelButton;
    }
    async submit() {
        return this.onRightButtonClick();
    }
    buildActionChain() {
        const builder = new ActionChainBuilder_1.ActionChainBuilder('ALTextModal');
        builder.addModal(modalBuilder => {
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: this.modalTitle,
                textUnderTitle: this.textUnderTitle,
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    ...(this.showCancelButton
                        ? [
                            {
                                theme: autoLayout_1.ButtonTheme.General,
                                text: this.leftButtonText,
                                paddingSize: autoLayout_1.ButtonPaddingSize.PX6PY2,
                                onClick: () => {
                                    if (!!this.onLeftButtonClick) {
                                        this.onLeftButtonClick();
                                        modalBuilder.finishAction(null);
                                        return;
                                    }
                                    modalBuilder.cancelAction(new Error(trans('legacy_ALTextModal.ts_user_cancelled')));
                                },
                            },
                        ]
                        : []),
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: this.rightButtonText,
                        paddingSize: autoLayout_1.ButtonPaddingSize.PX6PY2,
                        onClick: async () => {
                            if (await this.submit()) {
                                modalBuilder.finishAction(null);
                            }
                        },
                    },
                ],
            });
        });
        return builder.buildActionChain();
    }
}
exports.ALTextModal = ALTextModal;
