"use strict";
/* tslint:disable:no-string-literal */
// noinspection NonAsciiCharacters
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertAIBlockGeneratorResponseToChapterScript = void 0;
const core_1 = require("@storyplay/core");
/**
 * AI 블록 생성기에서 전달된 response 데이터를 chapter script 호환 형식으로 변경
 */
function convertAIBlockGeneratorResponseToChapterScript(response) {
    const { data: blocks } = response;
    const blockMapById = blocks.reduce((acc, item) => {
        acc[item.ID] = item;
        return acc;
    }, {});
    const ret = {
        startingBlock: blocks[0]['제목'],
        blocks: {},
    };
    let sourceLine = 1000;
    const step = 100;
    blocks.forEach(block => {
        const statements = [];
        const st = {
            sourceLine,
            statementType: core_1.STATEMENT_TYPE.Script,
            message: block['내용 한줄요약'],
            background: '',
        };
        sourceLine += step;
        statements.push(st);
        const blocksTo = block['연결되는 블록 ID'];
        const numConnectsTo = blocksTo.length;
        switch (numConnectsTo) {
            case 0:
                {
                    // 회차 엔딩
                    const stChapterEnding = {
                        sourceLine,
                        statementType: core_1.STATEMENT_TYPE.EndingSummary,
                        endingId: block.제목,
                        endingName: block.제목,
                        background: '',
                    };
                    sourceLine += step;
                    statements.push(stChapterEnding);
                }
                break;
            case 1:
                {
                    // 장면 연결
                    const stToBlock = {
                        sourceLine,
                        statementType: core_1.STATEMENT_TYPE.ToBlock,
                        block: blockMapById[blocksTo[0]].제목,
                        background: '',
                    };
                    sourceLine += step;
                    statements.push(stToBlock);
                }
                break;
            default: {
                // 선택지 연결
                const stChoice = {
                    sourceLine,
                    statementType: core_1.STATEMENT_TYPE.Choice,
                    choiceName: `choice-${block.제목}`,
                    choices: blocksTo.map(blockId => blockMapById[blockId].제목),
                    background: '',
                };
                sourceLine += step;
                statements.push(stChoice);
                const stChoiceBlock = {
                    sourceLine,
                    statementType: core_1.STATEMENT_TYPE.ChoiceToBlock,
                    choices: blocksTo.map(blockId => blockMapById[blockId].제목),
                    background: '',
                };
                sourceLine += step;
                statements.push(stChoiceBlock);
            }
        }
        ret.blocks[block.제목] = {
            name: block.제목,
            isEndingBlock: numConnectsTo === 0,
            statements,
        };
    });
    return ret;
}
exports.convertAIBlockGeneratorResponseToChapterScript = convertAIBlockGeneratorResponseToChapterScript;
