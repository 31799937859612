import { GQLChapter } from '../../../@types/server'

export enum FreeSettingType {
  NoFree,
  ByDate,
  WaitAndFree,
}

export function getFreeSettingType(
  chapter: Pick<
    GQLChapter,
    | 'freedAtRaw'
    | 'willFreeAtRaw'
    | 'freeDependencyPeriod'
    | 'freeDependencyChapterIndex'
  >
): FreeSettingType {
  if (chapter.freedAtRaw || chapter.willFreeAtRaw) {
    return FreeSettingType.ByDate
  }

  if (
    chapter.freeDependencyChapterIndex !== null &&
    chapter.freeDependencyPeriod !== null
  ) {
    return FreeSettingType.WaitAndFree
  }

  return FreeSettingType.NoFree
}
