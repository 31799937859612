import {
  ODEntityInput,
  ODEntityLabeled,
  ODImageFileInput,
  useCounter,
  useODMutation,
  useODQuery,
} from '@odc/od-react-belt'
import {
  GQLFeaturedCreationInput,
  GQLFeaturedUpdateInput,
  GQLListableStory,
  GQLListableStoryInput,
} from '@storyplay/common'
import React from 'react'
import Select from 'react-select'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLFeatured,
  GQLOkResponse,
  GQLPreviewEndingPropsInput,
  GQLSingleIDInput,
} from '../../../@types/server'
import {
  ODEntityEditorContextOptions,
  createODEntityEditorContext,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import {
  GQL_CREATE_FEATURED,
  GQL_DELETE_FEATURED,
  GQL_GET_FEATURED,
  GQL_LIST_STORY_FOR_FEATURED,
  GQL_UPDATE_FEATURED,
} from '../../../hooks/gqls_admin'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IFeaturedEditPageProps {
  featuredId: number | null
}

type Entity = GQLFeatured

function getValidationSchema(values: Partial<GQLPreviewEndingPropsInput>) {
  return Yup.object().shape({})
}

export const FeaturedEditPage: React.FC<IFeaturedEditPageProps> = (props) => {
  const { featuredId } = props
  const innerRef = React.useRef(null)
  const apiCreate = useODMutation<
    Partial<GQLFeaturedCreationInput>,
    Partial<Entity>
  >(GQL_CREATE_FEATURED)
  const apiUpdate = useODMutation<
    Partial<GQLFeaturedUpdateInput>,
    Partial<Entity>
  >(GQL_UPDATE_FEATURED)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET_FEATURED)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DELETE_FEATURED
  )

  const apiListStory = useODQuery<
    Partial<GQLListableStoryInput>,
    GQLListableStory
  >(GQL_LIST_STORY_FOR_FEATURED)
  const [token] = useCounter()
  const isCreating = !featuredId

  const [storyOptions, setStoryOptions] = React.useState<
    { label: string; value: number }[]
  >([])
  // const selectedEndingRateRangeRef = React.useRef(0)

  React.useEffect(() => {
    apiListStory({ pageSize: 5000, published: true }).then((r) => {
      setStoryOptions(
        r.list.map((story) => ({
          label: `${story.name}(ID: ${story.storyId})`,
          value: story.storyId,
        }))
      )
    })
  }, [apiListStory])

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLFeaturedCreationInput | GQLFeaturedUpdateInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (featuredId) {
          const r = await apiGet({ id: featuredId })
          selectedStoryIdRef.current = r.storyId
          setSelectedStoryId(r.storyId)
          return r
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            storyId: null,
            isHidden: true,
            coverImageFile: null,
            link: null,
          }
        }

        return {
          isHidden: data.isHidden,
          storyId: data.storyId,
          coverImageFile: data.coverImageFile,
          link: data.link,
        }
      },
      saveClientValueToServer: async (
        data: Partial<GQLFeaturedCreationInput | GQLFeaturedUpdateInput>
      ) => {
        console.log('@@link:', data.link)
        if (featuredId) {
          await apiUpdate({
            id: featuredId,
            isHidden: data.isHidden,
            storyId: selectedStoryIdRef.current,
            coverImageFile: data.coverImageFile,
            link: data.link,
          })
          Utils.showSuccess('피쳐드가 수정되었습니다.', 'Success')
        } else {
          const storyId = selectedStoryIdRef.current
            ? selectedStoryIdRef.current
            : undefined
          const { coverImageFile, isHidden, link } =
            data as GQLFeaturedCreationInput
          await apiCreate({
            storyId,
            coverImageFile,
            isHidden,
            link,
          })
          Utils.showSuccess('피쳐드가 생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Featured.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (featuredId) {
          await apiRemove({ id: featuredId })
          Utils.showSuccess('featured가 삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Featured.List
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [featuredId, apiGet, apiUpdate, apiCreate, token, apiRemove]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<GQLFeaturedCreationInput | GQLFeaturedUpdateInput>
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<GQLFeaturedCreationInput | GQLFeaturedUpdateInput>
    >(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          Partial<GQLFeaturedCreationInput | GQLFeaturedUpdateInput>
        >(options)
      ),
    [options]
  )

  const selectedStoryIdRef = React.useRef(0)
  const [selectedStoryId, setSelectedStoryId] = React.useState(0)

  const title = isCreating ? '피쳐드 생성' : '피쳐드 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            <hr />
            <ODEntityLabeled label="커버 이미지 파일" name="coverImageFile">
              <ODImageFileInput
                name="coverImageFile"
                height={200}
                width={200}
                keyPath="coverImageFile.link"
              />
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="link"
              label="링크"
              name="link"
              placeholder="클릭시 연결할 링크"
              inputType="text"
            />
            <ODEntityLabeled label="숨김?" name="isHidden">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isHidden_true"
                  name="isHidden"
                  keyPath="isHidden"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isHidden_false"
                  name="isHidden"
                  keyPath="isHidden"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled name={'작품'} label={'작품'}>
              <Select
                isSearchable={true}
                placeholder="연결할 작품을 선택해주세요"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    selectedStoryIdRef.current = v.value
                    // @ts-ignore
                    setSelectedStoryId(v.value as number)
                  }
                }}
                options={storyOptions}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
                value={storyOptions.filter(
                  (opt) => opt.value === selectedStoryId
                )}
              />
            </ODEntityLabeled>
            <hr />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={featuredId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
