"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOABadge = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
/**
 * Character domain object.
 */
class DOABadge {
    constructor(store, data) {
        this.store = store;
        this.data = data;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            description: mobx_1.observable,
            display: mobx_1.observable,
            displayLocked: mobx_1.observable,
            customData: mobx_1.observable,
            hideBeforeAcquire: mobx_1.observable,
            sortOrder: mobx_1.observable,
            imageLink: mobx_1.observable,
            imageLockedLink: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    merge(data) {
        const fields = [
            'aBadgeId',
            'name',
            'description',
            'display',
            'displayLocked',
            'customData',
            'hideBeforeAcquire',
            'sortOrder',
            'imageLink',
            'imageLockedLink',
            'customData',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
}
exports.DOABadge = DOABadge;
