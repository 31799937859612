import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { GQLEnding, GQLListableEnding, GQLListableEndingInput } from '../../@types/server'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useCounter, useODQuery } from '../../context/ODCommon'
import { ODListableOption, ODListableResponseType, useODListableContext } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { Utils } from '../../utils/utils'

interface Props {}

export interface EndingDataLoaderOption extends ODListableOption {
  filter: string | null
}

const ENDING_FULL_SNAPSHOT = `
  endingId
  name
  shareKey
  story {
    storyId
    name
  }
`

const GQL_LIST = `
query listEnding($data: ListableEndingInput!) {
  listEnding(data: $data) {
    list {
      ${ENDING_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const EndingListContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<GQLEnding, EndingDataLoaderOption>()

  const listEnding = useODQuery<GQLListableEndingInput, GQLListableEnding>(GQL_LIST)
  const [token] = useCounter()

  const dataLoader = React.useCallback(
    async function EndingDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: EndingDataLoaderOption
    ): Promise<ODListableResponseType<GQLEnding>> {
      const r = await listEnding({
        page,
        pageSize,
        storyId: null,
        isFinal: null,
        filter: options.filter || null,
      })
      return r as ODListableResponseType<GQLEnding>
    },
    [listEnding]
  )

  const TableDefinition: ODListableTableDefinition<GQLEnding, EndingDataLoaderOption, any> = [
    {
      id: 'noticeId',
      title: '공지사항 아이디',
      transform: (v) => v.endingId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'title',
      title: '엔딩명',
      transform: (v) => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'story',
      title: '작품 정보',
      transform: (v) => (
        <span>
          {v.story.name} (ID:{v.story.storyId})
        </span>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'shareKey',
      title: '공유키',
      transform: (v) => v.shareKey,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.endingId.toString()}
            pageSize={500}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩중..'}
              renderEmpty={() => '엔딩이 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
