"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOHbRestButton = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const Yup = require("yup");
const ui_1 = require("../../../../ui");
class DOHbRestButton {
    constructor(parent, data) {
        this.parent = parent;
        this.data = (0, lodash_1.cloneDeep)(data);
        this.initInputs(this.data);
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            isValidAll: mobx_1.computed,
        });
    }
    create() {
        this.parent.helper
            .opFactory()
            .createSelectButton(this.parent, this.getDataForSubmit())
            .submitSingle();
    }
    update() {
        this.parent.helper
            .opFactory()
            .updateSelectButton(this.parent, this.getDataForSubmit())
            .submitSingle();
    }
    get isValidAll() {
        return !!this.buttonNameInput.value.trim();
    }
    getDataForSubmit() {
        const dataById = !!this.data.id
            ? { id: this.data.id, turn: this.data.turn }
            : {};
        return {
            ...dataById,
            text: this.buttonNameInput.value,
        };
    }
    getOriginDataForSubmit() {
        return (0, lodash_1.cloneDeep)(this.data);
    }
    initInputs(data) {
        (0, mobx_1.runInAction)(() => {
            var _a;
            this.buttonNameInput = new ui_1.InputWithValidation('buttonName', '버튼명', '버튼명을 입력해 주세요.', 'text', (_a = data.text) !== null && _a !== void 0 ? _a : '', Yup.string().min(1).required(), {
                noLabel: true,
                required: true,
            });
        });
    }
}
exports.DOHbRestButton = DOHbRestButton;
