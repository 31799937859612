import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODImageFileInput,
  useCounter,
  ODEntityRaw,
  useODEntityAPI,
} from '@odc/od-react-belt'
import { STORY_PROP_TYPE } from '@storyplay/common'
import gql from 'graphql-tag'
import React from 'react'
import {
  GQLStoryProperty,
  GQLStoryPropertyCreationInput,
  GQLStoryPropertyUpdateInput,
} from '../../../@types/server'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import {
  GQL_CREATE_STORY_PROPERTY,
  GQL_GET_STORY_PROPERTY,
  GQL_REMOVE_STORY_PROPERTY,
  GQL_UPDATE_STORY_PROPERTY,
} from '../../../hooks/gqls_admin'
import { SiteUrls } from '../../../urls'
import {
  ICTStoryProperty,
  StoryPropertyPrimaryKey,
} from '../StoryPropertyCommon'

interface IStoryPropertyEditPageProps {
  storyId: number
  propId: number | null
}

const createDefaultData = (storyId: number): Partial<ICTStoryProperty> => {
  return {
    storyId,
    propId: 0,
    // @ts-ignore
    propType: STORY_PROP_TYPE.NUMBER,
    maxValue: 100,
    propName: ``,
    imageFile: null,
    isMain: false,
    showOrder: 1000,
  }
}

export const StoryPropertyEditPage: React.FC<IStoryPropertyEditPageProps> = (
  props
) => {
  const { storyId, propId } = props
  const apis = useODEntityAPI<
    GQLStoryProperty,
    ICTStoryProperty,
    GQLStoryPropertyCreationInput,
    GQLStoryPropertyUpdateInput
  >({
    createGQL: gql(GQL_CREATE_STORY_PROPERTY),
    updateGQL: gql(GQL_UPDATE_STORY_PROPERTY),
    readGQL: gql(GQL_GET_STORY_PROPERTY),
    deleteGQL: gql(GQL_REMOVE_STORY_PROPERTY),
    primaryKeyName: StoryPropertyPrimaryKey,
  })

  const [token] = useCounter()
  const [propTypeString, setPropTypeString] = React.useState('')
  const isCreating = propId === 0

  return (
    <>
      <ODEntity
        resourceId={isCreating ? null : propId}
        // @ts-ignore
        api={apis}
        saveButtonName="저장"
        // deleteButtonName="삭제"
        titleCreation="추가"
        titleUpdate="수정"
        updateSuccessTitle="성공"
        updateSuccessMessage="수정하였습니다."
        urlAfterCreation={() => SiteUrls.Admin.Story.Edit(storyId, 6)}
        urlAfterDelete={() => SiteUrls.Admin.Story.Edit(storyId, 6)}
        deleteSuccessTitle="성공"
        // deleteSuccessMessage="삭제하였습니다."
        defaultCreateClientData={createDefaultData(storyId)}
        urlAfterUpdate={() => SiteUrls.Admin.Story.Edit(storyId, 6)}
        noCardWrap
        refreshToken={token}
      >
        {!isCreating && (
          <ODEntityInput
            keyPath="propId"
            label="속성 아이디"
            name="propId"
            placeholder="속성 아이디 (자동부여)"
            inputType="text"
            inputProps={{ disabled: true }}
          />
        )}
        <ODEntityInput
          keyPath="showOrder"
          label="노출 우선순위"
          name="showOrder"
          placeholder="노출 우선순위"
          inputType="number"
        />
        <ODEntityInput
          keyPath="propNameRaw"
          label="속성명"
          name="propNameRaw"
          placeholder="속성명"
          inputType="text"
          inputProps={{ disabled: true }}
        />
        <ODEntityInput
          keyPath="displayPropName"
          label="노출 속성명"
          name="displayPropName"
          placeholder="노출 속성명"
          inputType="text"
        />
        <ODEntityInput
          keyPath="unit"
          label="노출 속성 단위"
          name="unit"
          placeholder="노출 속성 단위"
          inputType="text"
        />
        <ODEntityLabeled label="속성타입" name="속성타입">
          <ODEntityRaw
            name="propType"
            keyPath="propType"
            render={({ value }) => {
              setPropTypeString(value)
              const propMap: { [key: string]: string } = {
                STRING: '텍스트',
                NUMBER: '숫자',
              }
              return <span>{value ? propMap[value] : '없음'}</span>
            }}
          />
        </ODEntityLabeled>
        <ODEntityInput
          keyPath="maxValue"
          label="속성치 최대값 (숫자)"
          name="maxValue"
          placeholder="속성치 최대값"
          inputType="number"
        />
        <ODEntityLabeled label="이미지파일" name="imageFile">
          <ODImageFileInput
            name="imageFile"
            height={92}
            width={92}
            keyPath="imageFile.link"
          />
        </ODEntityLabeled>
        {propTypeString === 'NUMBER' && (
          <ODEntityLabeled label="핵심속성?" name="isMainProp">
            <ToggleButtonWrapper>
              <ODFormToggleButton
                key="isMainProp_true"
                name="isMainProp"
                keyPath="isMain"
                value={true}
              >
                Y
              </ODFormToggleButton>
              <ODFormToggleButton
                key="isMainProp_false"
                name="isMainProp"
                keyPath="isMain"
                value={false}
              >
                N
              </ODFormToggleButton>
            </ToggleButtonWrapper>
          </ODEntityLabeled>
        )}
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <hr />
        </div>
      </ODEntity>
    </>
  )
}
