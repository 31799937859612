"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioLoginState = exports.ChannelMessage = void 0;
var ChannelMessage;
(function (ChannelMessage) {
    ChannelMessage["ErrorToast"] = "ErrorToast";
    ChannelMessage["ScrollToCurrentLine"] = "ScrollToCurrentLine";
    ChannelMessage["ScrollToGivenLine"] = "ScrollToGivenLine";
})(ChannelMessage = exports.ChannelMessage || (exports.ChannelMessage = {}));
var StudioLoginState;
(function (StudioLoginState) {
    StudioLoginState["Checking"] = "Checking";
    StudioLoginState["LoggedOut"] = "LoggedOut";
    StudioLoginState["LoggingIn"] = "LoggingIn";
    StudioLoginState["LoggedIn"] = "LoggedIn";
    StudioLoginState["LoggingOut"] = "LoggingOut";
})(StudioLoginState = exports.StudioLoginState || (exports.StudioLoginState = {}));
