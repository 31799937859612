"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateNumCharsOf = exports.calculateNumRoutesOf = void 0;
const entity_1 = require("../entity");
// 문장에 연결된 분기의 수를 구한다.
const calculateNumRoutesOf = (st) => {
    if (st instanceof entity_1.DOSTChoiceSubBlock) {
        return st.choices.length;
    }
    if (st instanceof entity_1.DOSTConditionSubBlock) {
        return st.statements.filter(v => v instanceof entity_1.DOSTCondition || v instanceof entity_1.DOSTToBlock).length;
    }
    return 0;
};
exports.calculateNumRoutesOf = calculateNumRoutesOf;
const calculateNumCharsOf = (st) => {
    if (st instanceof entity_1.DOSTToast ||
        st instanceof entity_1.DOSTScript ||
        st instanceof entity_1.DOSTRightTalk ||
        st instanceof entity_1.DOSTRightThink ||
        st instanceof entity_1.DOSTLeftThink ||
        st instanceof entity_1.DOSTLeftTalk ||
        st instanceof entity_1.DOSTFullWidthText) {
        return st.st.message.length;
    }
    else if (st instanceof entity_1.DOSTEndingSummary) {
        return st.st.endingName.length;
    }
    else if (st instanceof entity_1.DOSTFinalEndingSubBlock) {
        return st.statements.reduce((pv, cv) => {
            const numChars = (() => {
                if (cv instanceof entity_1.DOSTFinalEnding) {
                    return cv.st.endingName.length;
                }
                if (cv instanceof entity_1.DOSTCollectionDesc) {
                    return cv.st.message.length;
                }
                return 0;
            })();
            return pv + numChars;
        }, 0);
    }
    else if (st instanceof entity_1.DOSTConditionSubBlock) {
        return 0;
    }
    else if (st instanceof entity_1.DOSTChoiceSubBlock) {
        return st.statements.reduce((pv, cv) => {
            const numChars = (() => {
                if (cv instanceof entity_1.DOSTChoice) {
                    return cv.st.choices.reduce((acc, n) => acc + n.length, 0);
                }
                return 0;
            })();
            return pv + numChars;
        }, 0);
    }
    return 0;
};
exports.calculateNumCharsOf = calculateNumCharsOf;
