"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryPriceEditor = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const moment = require("moment");
const EntityEditor_1 = require("../EntityEditor");
const fields_1 = require("../fields");
const SelectionFieldWithUI_1 = require("../fields/SelectionFieldWithUI");
const ui_1 = require("../ui");
const PaidPerChapterRow_1 = require("./PaidPerChapterRow");
const PaidPerStoryRow_1 = require("./PaidPerStoryRow");
const AdPerChapterRow_1 = require("./AdPerChapterRow");
const FreedAtPerChapterRow_1 = require("./FreedAtPerChapterRow");
const DATETIME_LOCAL_FMT = 'YYYY-MM-DDTHH:mm:ss';
class DOStoryPriceEditor extends EntityEditor_1.EntityEditor {
    constructor(storyPrice) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        super(storyPrice);
        this.noticeData = [];
        this.countryCodeField = null;
        this.paidPerChapterActivatedField = null;
        this.paidPerChapterPriceField = null;
        this.paidPerChapterIndexesFields = null;
        this.paidPerChapterSettingField = null;
        this.paidPerStoryActivatedField = null;
        this.paidPerStoryPriceField = null;
        this.paidPerStoryDiscountPriceFields = null;
        this.paidPerStoryFreeChapterFields = null;
        this.paidPerStorySettingField = null;
        this.adPerChapterActivatedField = null;
        this.adPerChapterIndexesFields = null;
        this.adPerChapterSettingField = null;
        this.freedAtPerChapterActivatedField = null;
        this.freedAtPerChapterIndexesFields = null;
        this.freedAtPerChapterFreedAtField = null;
        this.freedAtPerChapterSettingField = null;
        this.afterSomePeriodFreePerChapterActivatedField = null;
        this.afterSomePeriodFreePerChapterIndexField = null;
        this.afterSomePeriodFreePerChapterPeriodField = null;
        this.afterSomePeriodFreePerChapterSettingField = null;
        this.waitFreePerChapterActivatedField = null;
        this.waitFreePerChapterIndexField = null;
        this.waitFreePerChapterPeriodField = null;
        this.waitFreePerChapterSettingField = null;
        this.timeLeapFreePerChapterActivatedField = null;
        this.timeLeapFreePerChapterPeriodField = null;
        this.timeLeapFreePerChapterNoticeIdField = null;
        this.timeLeapFreePerChapterSettingField = null;
        this.paidPerStorySetting = null;
        // 회차 단위 판매 설정 필드가 보여지는가?
        this.showPaidPerChapterPriceActivateField = false;
        // 회차 단위 판매 설정 하위 필드 그룹이 보여지는가?
        this.showPaidPerChapterPriceFieldGroup = false;
        // 작품 단위 판매 설정 필드가 보여지는가?
        this.showPaidPerStoryPriceActivateField = false;
        // 작품 단위 판매 설정 하위 필드 그룹이 보여지는가?
        this.showPaidPerStoryPriceFieldGroup = false;
        // 광고 설정 필드가 보여지는가?
        this.showAdPerChapterPriceActivateField = false;
        // 광고 설정 하위 필드 그룹이 보여지는가?
        this.showAdPerChapterPriceFieldGroup = false;
        // 기간 무료 설정 필드가 보여지는가?
        this.showFreeAtPerChapterPriceActivateField = false;
        // 기간 무료 설정 하위 필드 그룹이 보여지는가?
        this.showFreeAtPerChapterPriceFieldGroup = false;
        // 발행 후 기간 무료 설정 필드가 보여지는가?
        this.showAfterSomePeriodFreeAtPerChapterPriceActivateField = false;
        // 발행 후 기간 무료 설정 하위 필드 그룹이 보여지는가
        this.showAfterSomePeriodFreeAtPerChapterPriceFieldGroup = false;
        // 기스무 설정 필드가 보여지는가?
        this.showWaitFreeAtPerChapterPriceActivateField = false;
        // 기스무 설정 하위 필드 그룹이 보여지는가?
        this.showWaitFreeAtPerChapterPriceFieldGroup = false;
        // 타임리프 무료 설정 필드가 보여지는가?
        this.showTimeLeapFreeAtPerChapterPriceActivateField = false;
        // 타임리프 무료 설정 하위 필드 그룹이 보여지는가
        this.showTimeLeapFreeAtPerChapterPriceFieldGroup = false;
        this.serviceCountries = [];
        this.storyPrice = storyPrice;
        this.serviceCountries = storyPrice.story.serviceCountries;
        this.storyIdField = new fields_1.TextFieldWithUI('storyId', () => storyPrice.storyId.toString(), () => null, {
            label: '스토리 아이디',
            inputType: 'text',
            options: {
                editable: false,
                noLabel: true,
            },
        });
        this.storyNameField = new fields_1.TextFieldWithUI('storyName', () => storyPrice.story.name, () => null, {
            label: '스토리명',
            inputType: 'text',
            options: {
                editable: false,
                noLabel: true,
            },
        });
        this.statusField = new fields_1.TextFieldWithUI('status', () => (storyPrice.story.isFinished ? '완결' : '연재중'), () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
        {
            label: '연재 상태',
            options: {
                editable: false,
                noLabel: true,
            },
        });
        this.playTypeField = new fields_1.TextFieldWithUI('playType', () => core_1.STORY_PLAY_TYPE_TO_KO_STRING[storyPrice.story.playType], () => null, {
            label: '작품 유형',
            options: {
                editable: false,
                noLabel: true,
            },
        });
        // 가격
        // key와 parameter 동일
        this.isFreeField = new SelectionFieldWithUI_1.SelectionFieldWithUI('isFree', () => storyPrice.isFree, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
        {
            label: '유/무료 설정',
            selection: [
                { name: '유료', value: false },
                { name: '무료', value: true },
            ],
            options: {
                onChangeBeforeSubmit: value => {
                    (0, mobx_1.runInAction)(() => {
                        var _a, _b, _c, _d;
                        (_a = this.paidPerChapterActivatedField) === null || _a === void 0 ? void 0 : _a.input.onChange(!value);
                        this.showPaidPerChapterPriceActivateField = !value;
                        this.showPaidPerStoryPriceActivateField = !value;
                        if (value === false) {
                            // 유료로 변경
                            this.showPaidPerChapterPriceActivateField = true;
                            this.showPaidPerStoryPriceActivateField = true;
                            // 회차 단위 구매, 작품 단위 구매 모두 false이면 기본으로 회차 단위 구매 설정
                            if (!((_b = this.paidPerChapterActivatedField) === null || _b === void 0 ? void 0 : _b.input.value) &&
                                !((_c = this.paidPerStoryActivatedField) === null || _c === void 0 ? void 0 : _c.input.value)) {
                                (_d = this.paidPerChapterActivatedField) === null || _d === void 0 ? void 0 : _d.input.onChange(true);
                            }
                        }
                        else {
                            // 무료로 변경 시 가격, 프로모션 모두 숨기기
                            this.showPaidPerChapterPriceActivateField = false;
                            this.showPaidPerStoryPriceActivateField = false;
                            this.showFreeAtPerChapterPriceActivateField = false;
                            this.showAfterSomePeriodFreeAtPerChapterPriceActivateField =
                                false;
                            this.showWaitFreeAtPerChapterPriceActivateField = false;
                            this.showTimeLeapFreeAtPerChapterPriceActivateField = false;
                        }
                    });
                },
            },
        });
        // 회차 단위 구매
        // key와 parameter 동일
        this.paidPerChapterActivatedField = new SelectionFieldWithUI_1.SelectionFieldWithUI('paidPerChapterActivated', () => storyPrice.paidPerChapterActivated, () => null, {
            label: '회차 단위 판매 설정',
            selection: [
                { name: '판매함', value: true },
                { name: '판매 안 함', value: false },
            ],
            onChange: value => {
                (0, mobx_1.runInAction)(() => {
                    var _a, _b;
                    this.showPaidPerChapterPriceFieldGroup = value;
                    // 회차 단위 판매 설정 on 클릭 시 프로모션 메뉴 on
                    this.showFreeAtPerChapterPriceActivateField = value;
                    this.showAfterSomePeriodFreeAtPerChapterPriceActivateField = value;
                    this.showWaitFreeAtPerChapterPriceActivateField = value;
                    this.showTimeLeapFreeAtPerChapterPriceActivateField = value;
                    if (storyPrice.story.isFinished) {
                        // 완결작이고, 회차 단위 판매 설정 on/off 클릭 시 작품 단위 설정 off/on
                        (_a = this.paidPerStoryActivatedField) === null || _a === void 0 ? void 0 : _a.input.onChange(!value);
                    }
                    if (this.isFreeField.input.value === false &&
                        (!storyPrice.story.isFinished ||
                            storyPrice.story.playType === core_1.GQLSTORY_PLAY_TYPE.EPUB)) {
                        // 유료작품이고, 연재중인 작품이거나(통구매 설정 disabled 상태임), 웹소설인 경우(통구매 필드 없음),
                        // '판매 안 함' 선택 못하게 막기
                        if (value === false) {
                            this._target.rootStore.showError('유료작품인 경우 구매 설정이 필수입니다.');
                            (_b = this.paidPerChapterActivatedField) === null || _b === void 0 ? void 0 : _b.input.onChange(true);
                            this.showPaidPerChapterPriceFieldGroup = true;
                            // 회차 단위 판매 설정 on 클릭 시 프로모션 메뉴 on
                            this.showFreeAtPerChapterPriceActivateField = true;
                            this.showAfterSomePeriodFreeAtPerChapterPriceActivateField =
                                true;
                            this.showWaitFreeAtPerChapterPriceActivateField = true;
                            this.showTimeLeapFreeAtPerChapterPriceActivateField = true;
                        }
                    }
                });
            },
        });
        const paidPerChapterSetting = storyPrice.paidPerChapterSetting &&
            JSON.parse(storyPrice.paidPerChapterSetting);
        this.paidPerChapterPriceField = new ui_1.SelectionInput('paidPerChapterPrice', '1회차 당 가격', Number(paidPerChapterSetting === null || paidPerChapterSetting === void 0 ? void 0 : paidPerChapterSetting.price), Array.from({ length: 50 }, (_, index) => {
            return { name: String(index + 1), value: index + 1 };
        }), {
            placeholder: '가격을 입력하세요',
            textAfterField: '코인',
            onChangeBeforeSubmit: v => {
                var _a, _b, _c;
                const parsedValue = JSON.parse((_b = (_a = this.paidPerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                parsedValue.price = Number(v);
                (_c = this.paidPerChapterSettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(parsedValue));
            },
        });
        this.paidPerChapterIndexesFields = new PaidPerChapterRow_1.PaidPerChapterRow(this, (_b = (_a = paidPerChapterSetting === null || paidPerChapterSetting === void 0 ? void 0 : paidPerChapterSetting.freeChapterIndexes) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : null);
        this.paidPerChapterSettingField = new fields_1.CustomField('paidPerChapterSetting', () => { var _a; return (_a = this._target.paidPerChapterSetting) !== null && _a !== void 0 ? _a : '{}'; }, () => null);
        if (storyPrice.story.playType !== core_1.GQLSTORY_PLAY_TYPE.EPUB) {
            // 작품 단위 구매(인터랙티브일 경우에만 보여줌)
            this.paidPerStoryActivatedField = new SelectionFieldWithUI_1.SelectionFieldWithUI('paidPerStoryActivated', () => storyPrice.paidPerStoryActivated, () => null, {
                label: '통구매 판매 설정',
                selection: [
                    // name 뒤에 띄어쓰기 안넣으면 paidPerChapterActivatedField selection과 동일해져서 데이터가 꼬이는 듯 합니다.
                    { name: '판매함 ', value: true },
                    { name: '판매 안 함 ', value: false },
                ],
                onChange: value => {
                    (0, mobx_1.runInAction)(() => {
                        var _a;
                        this.showPaidPerStoryPriceFieldGroup = value;
                        // 작품 단위 판매(통구매) 설정 on/off 클릭 시 회차 단위 설정 off/on
                        (_a = this.paidPerChapterActivatedField) === null || _a === void 0 ? void 0 : _a.input.onChange(!value);
                        // 작품 단위 판매(통구매) 설정 off 클릭 시 프로모션 메뉴 off
                        this.showFreeAtPerChapterPriceActivateField = !value;
                        this.showAfterSomePeriodFreeAtPerChapterPriceActivateField =
                            !value;
                        this.showWaitFreeAtPerChapterPriceActivateField = !value;
                        this.showTimeLeapFreeAtPerChapterPriceActivateField = !value;
                    });
                },
            });
            this.paidPerStorySetting =
                storyPrice.paidPerStorySetting &&
                    JSON.parse(storyPrice.paidPerStorySetting);
            this.paidPerStoryPriceField = new ui_1.InputWithValidation('paidPerStoryPrice', '통구매 가격', '가격을 입력하세요', 'string', String(((_c = this.paidPerStorySetting) === null || _c === void 0 ? void 0 : _c.originalPrice) || ''), null, {
                noLabel: true,
                textAfterInput: '코인',
                onBlur: () => {
                    var _a;
                    if (!this.paidPerStorySetting || !this.paidPerStoryPriceField)
                        return;
                    if (Number(this.paidPerStoryPriceField.value) <
                        Number(this.paidPerStorySetting.price)) {
                        this._target.rootStore.showError('통구매 가격이 유효하지 않습니다.');
                        this.paidPerStoryPriceField.onChange(String(this.paidPerStorySetting.originalPrice));
                        return;
                    }
                    this.paidPerStorySetting.originalPrice = Number((_a = this.paidPerStoryPriceField) === null || _a === void 0 ? void 0 : _a.value);
                    const newDiscountRate = ((Number(this.paidPerStoryPriceField.value) -
                        Number(this.paidPerStorySetting.price)) /
                        Number(this.paidPerStoryPriceField.value)) *
                        100;
                    discountRateField.onChange(String(newDiscountRate));
                },
                onChange: v => {
                    var _a, _b, _c;
                    const parsedValue = JSON.parse((_b = (_a = this.paidPerStorySettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                    parsedValue.originalPrice = Number(v);
                    (_c = this.paidPerStorySettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(parsedValue));
                },
            });
            const discountPriceField = new ui_1.InputWithValidation('discountPrice', '통구매 할인 가격', '가격을 입력하세요', 'string', String(((_d = this.paidPerStorySetting) === null || _d === void 0 ? void 0 : _d.price) || ''), null, {
                noLabel: true,
                textAfterInput: '코인',
                onBlur: () => {
                    if (!this.paidPerStorySetting)
                        return;
                    if (isNaN(Number(discountPriceField.value)) ||
                        Number(discountPriceField.value) >
                            this.paidPerStorySetting.originalPrice ||
                        Number(discountPriceField.value) < 0) {
                        this._target.rootStore.showError('통구매 할인 가격이 유효하지 않습니다.');
                        discountPriceField.onChange(String(Math.round(this.paidPerStorySetting.price)));
                        return;
                    }
                    this.paidPerStorySetting.price = Number(discountPriceField.value);
                    const newDiscountRate = ((this.paidPerStorySetting.originalPrice -
                        Number(this.paidPerStorySetting.price)) /
                        this.paidPerStorySetting.originalPrice) *
                        100;
                    discountRateField.onChange(String(newDiscountRate));
                },
                onChange: v => {
                    var _a, _b, _c;
                    const parsedValue = JSON.parse((_b = (_a = this.paidPerStorySettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                    parsedValue.price = Number(v);
                    (_c = this.paidPerStorySettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(parsedValue));
                },
            });
            const discountRateField = new ui_1.InputWithValidation('discountRate', '', '할인률을 입력하세요', 'string', this.paidPerStorySetting
                ? String(((this.paidPerStorySetting.originalPrice -
                    this.paidPerStorySetting.price) /
                    this.paidPerStorySetting.originalPrice) *
                    100 || '')
                : '', null, {
                noLabel: true,
                textAfterInput: '% 할인율',
                onBlur: () => {
                    if (!this.paidPerStorySetting)
                        return;
                    if (isNaN(Number(discountRateField.value)) ||
                        Number(discountRateField.value) > 100 ||
                        Number(discountPriceField.value) < 0) {
                        this._target.rootStore.showError('통구매 할인율이 유효하지 않습니다.');
                        discountRateField.onChange(String(((this.paidPerStorySetting.originalPrice -
                            this.paidPerStorySetting.price) /
                            this.paidPerStorySetting.originalPrice) *
                            100));
                        return;
                    }
                    this.paidPerStorySetting.price =
                        this.paidPerStorySetting.originalPrice -
                            (this.paidPerStorySetting.originalPrice *
                                Number(discountRateField.value)) /
                                100;
                    discountPriceField.onChange(String(this.paidPerStorySetting.price));
                },
                onChange: v => {
                    var _a, _b, _c;
                    if (!this.paidPerStorySetting)
                        return;
                    const parsedValue = JSON.parse((_b = (_a = this.paidPerStorySettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                    parsedValue.price = Math.round(this.paidPerStorySetting.originalPrice * (1 - Number(v) / 100));
                    (_c = this.paidPerStorySettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(parsedValue));
                },
            });
            const paidPerStoryDiscountPriceField = {
                key: 'paidPerStoryDiscountPrice',
                discountPriceField,
                discountRateField,
            };
            this.paidPerStoryDiscountPriceFields = paidPerStoryDiscountPriceField;
            this.paidPerStoryFreeChapterFields = new PaidPerStoryRow_1.PaidPerStoryRow(this, ((_e = this.paidPerStorySetting) === null || _e === void 0 ? void 0 : _e.chapterIndex) || null);
            this.paidPerStorySettingField = new fields_1.CustomField('paidPerStorySetting', () => { var _a; return (_a = this._target.paidPerStorySetting) !== null && _a !== void 0 ? _a : '{}'; }, () => null);
        }
        // 광고
        this.showAdPerChapterPriceFieldGroup = storyPrice.adPerChapterActivated;
        this.adPerChapterActivatedField = new SelectionFieldWithUI_1.SelectionFieldWithUI('adPerChapterActivated', () => storyPrice.adPerChapterActivated, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
        {
            label: '광고 설정',
            selection: [
                { name: '광고 있음', value: true },
                { name: '광고 없음', value: false },
            ],
            onChange: value => {
                (0, mobx_1.runInAction)(() => {
                    this.showAdPerChapterPriceFieldGroup = value;
                });
            },
        });
        const adPerChapterSetting = storyPrice.adPerChapterSetting &&
            JSON.parse(storyPrice.adPerChapterSetting);
        this.adPerChapterIndexesFields = new AdPerChapterRow_1.AdPerChapterRow(this, (_g = (_f = adPerChapterSetting === null || adPerChapterSetting === void 0 ? void 0 : adPerChapterSetting.adChapterIndexes) === null || _f === void 0 ? void 0 : _f[0]) !== null && _g !== void 0 ? _g : null);
        this.adPerChapterSettingField = new fields_1.CustomField('adPerChapterSetting', () => { var _a; return (_a = this._target.adPerChapterSetting) !== null && _a !== void 0 ? _a : '{}'; }, () => null);
        // 프로모션
        // 기간 무료
        this.freedAtPerChapterActivatedField = new SelectionFieldWithUI_1.SelectionFieldWithUI('freedAtPerChapterActivated', () => storyPrice.freedAtPerChapterActivated, () => null, {
            label: '기간 무료 설정',
            selection: [
                { name: '설정함', value: true },
                { name: '설정 안함', value: false },
            ],
            onChange: value => {
                (0, mobx_1.runInAction)(() => {
                    this.showFreeAtPerChapterPriceFieldGroup = value;
                });
            },
        });
        const freedAtPerChapterSetting = storyPrice.freedAtPerChapterSetting &&
            JSON.parse(storyPrice.freedAtPerChapterSetting);
        const freedAtPerChapterIndexesFieldsStartIndex = new SelectionFieldWithUI_1.SelectionFieldWithUI('freedAtPerChapterIndexesFieldsStartIndex', () => { var _a; return (_a = freedAtPerChapterSetting === null || freedAtPerChapterSetting === void 0 ? void 0 : freedAtPerChapterSetting.freedAtChapterIndexes) === null || _a === void 0 ? void 0 : _a[0].startIndex; }, () => null, {
            label: '기간 무료 적용 회차',
            selection: this.chapterIndexSelection,
            options: {
                placeholder: '회차를 입력하세요',
                textAfterField: '부터',
                onChangeBeforeSubmit: value => {
                    freedAtPerChapterIndexesFields.endIndexField.input.onChangeOptions(this.chapterIndexSelection.slice(Number(value) - 1));
                },
            },
        });
        const freedAtPerChapterIndexesFieldsEndIndex = new SelectionFieldWithUI_1.SelectionFieldWithUI(`freedAtPerChapterIndexesFieldsEndIndex`, () => { var _a; return (_a = freedAtPerChapterSetting === null || freedAtPerChapterSetting === void 0 ? void 0 : freedAtPerChapterSetting.freedAtChapterIndexes) === null || _a === void 0 ? void 0 : _a[0].endIndex; }, () => null, {
            selection: this.chapterIndexSelection,
            options: {
                placeholder: '회차를 입력하세요',
                textAfterField: '까지',
            },
        });
        const freedAtPerChapterIndexesFields = {
            key: 'freedAtPerChapterIndexesFields',
            startIndexField: freedAtPerChapterIndexesFieldsStartIndex,
            endIndexField: freedAtPerChapterIndexesFieldsEndIndex,
            hasDeleteButton: false,
            hasAddRowButton: false,
        };
        this.freedAtPerChapterIndexesFields = new FreedAtPerChapterRow_1.FreedAtPerChapterRow(this, (_j = (_h = freedAtPerChapterSetting === null || freedAtPerChapterSetting === void 0 ? void 0 : freedAtPerChapterSetting.freedAtChapterIndexes) === null || _h === void 0 ? void 0 : _h[0]) !== null && _j !== void 0 ? _j : null);
        this.freedAtPerChapterSettingField = new fields_1.CustomField('freedAtPerChapterSetting', () => { var _a; return (_a = this._target.freedAtPerChapterSetting) !== null && _a !== void 0 ? _a : '{}'; }, () => null);
        this.freedAtPerChapterFreedAtField = new ui_1.InputWithValidation('freedAtPerChapterFreedAt', '기간 무료 시작일시', '', 'datetime-local', ((_k = freedAtPerChapterSetting === null || freedAtPerChapterSetting === void 0 ? void 0 : freedAtPerChapterSetting.freedAtChapterIndexes) === null || _k === void 0 ? void 0 : _k[0].freedAt)
            ? moment((_l = freedAtPerChapterSetting === null || freedAtPerChapterSetting === void 0 ? void 0 : freedAtPerChapterSetting.freedAtChapterIndexes) === null || _l === void 0 ? void 0 : _l[0].freedAt).format(DATETIME_LOCAL_FMT)
            : undefined, null, {
            noLabel: true,
            onChange: v => {
                var _a, _b, _c, _d, _e;
                const valueParsed = JSON.parse((_b = (_a = this.freedAtPerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                let indexItem = (_c = valueParsed.freedAtChapterIndexes) === null || _c === void 0 ? void 0 : _c[0];
                if (indexItem) {
                    if (!v)
                        return;
                    const date = new Date(v);
                    indexItem.freedAt = date.toISOString();
                    (_d = this.freedAtPerChapterSettingField) === null || _d === void 0 ? void 0 : _d.onChange(JSON.stringify(valueParsed));
                }
                else {
                    if (!v)
                        return;
                    const date = new Date(v);
                    (_e = this.freedAtPerChapterSettingField) === null || _e === void 0 ? void 0 : _e.onChange(JSON.stringify([{ freedAt: date.toISOString() }]));
                }
            },
        });
        // 발행 후 기간 무료 설정
        this.afterSomePeriodFreePerChapterActivatedField = new SelectionFieldWithUI_1.SelectionFieldWithUI('afterSomePeriodFreePerChapterActivated', () => storyPrice.afterSomePeriodFreePerChapterActivated, () => null, {
            label: '발행 후 기간 무료 설정',
            selection: [
                // name 뒤에 띄어쓰기 안넣으면 freedAtPerChapterActivatedField의 selection과 동일해져서 데이터가 꼬이는 듯 합니다.
                { name: '설정함 ', value: true },
                { name: '설정 안함 ', value: false },
            ],
            onChange: value => {
                (0, mobx_1.runInAction)(() => {
                    this.showAfterSomePeriodFreeAtPerChapterPriceFieldGroup = value;
                });
            },
        });
        const afterSomePeriodFreePerChapterSetting = storyPrice.afterSomePeriodFreePerChapterSetting &&
            JSON.parse(storyPrice.afterSomePeriodFreePerChapterSetting);
        this.afterSomePeriodFreePerChapterIndexField = new ui_1.SelectionInput('afterSomePeriodFreePerChapterIndex', '일괄 적용 시작 회차', afterSomePeriodFreePerChapterSetting === null || afterSomePeriodFreePerChapterSetting === void 0 ? void 0 : afterSomePeriodFreePerChapterSetting.chapterIndex, this.chapterIndexSelection, {
            placeholder: '회차를 입력하세요',
            textAfterField: '부터 발행되는 회차에 일괄 적용',
            onChangeBeforeSubmit: v => {
                var _a, _b, _c;
                const valueParsed = JSON.parse((_b = (_a = this.afterSomePeriodFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                valueParsed.chapterIndex = Number(v);
                (_c = this.afterSomePeriodFreePerChapterSettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(valueParsed));
            },
        });
        const afterSomePeriodFreePerChapterPeriod = afterSomePeriodFreePerChapterSetting &&
            moment.duration(afterSomePeriodFreePerChapterSetting.period, 'seconds');
        const afterSomePeriodFreePerChapterIndexesFields = {
            firstField: new ui_1.InputWithValidation('AfterSomePeriodFreePerChapterIndexesFieldsStartIndex', '발행 후 무료 설정', '날짜를 입력하세요', 'number', afterSomePeriodFreePerChapterPeriod
                ? afterSomePeriodFreePerChapterPeriod === null || afterSomePeriodFreePerChapterPeriod === void 0 ? void 0 : afterSomePeriodFreePerChapterPeriod.days()
                : undefined, null, {
                noLabel: true,
                textAfterInput: '일',
                onChange: v => {
                    var _a, _b, _c, _d;
                    const valueParsed = JSON.parse((_b = (_a = this.afterSomePeriodFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                    valueParsed.period =
                        Number(v) * 24 * 60 * 60 +
                            Number(((_c = this.afterSomePeriodFreePerChapterPeriodField) === null || _c === void 0 ? void 0 : _c.secondField.value) || 0) *
                                60 *
                                60;
                    (_d = this.afterSomePeriodFreePerChapterSettingField) === null || _d === void 0 ? void 0 : _d.onChange(JSON.stringify(valueParsed));
                },
            }),
            secondField: new ui_1.InputWithValidation('AfterSomePeriodFreePerChapterIndexesFieldsEndIndex', '', '시간을 입력하세요', 'number', afterSomePeriodFreePerChapterPeriod
                ? afterSomePeriodFreePerChapterPeriod === null || afterSomePeriodFreePerChapterPeriod === void 0 ? void 0 : afterSomePeriodFreePerChapterPeriod.hours()
                : undefined, null, {
                noLabel: true,
                textAfterInput: '시간 후 무료 회차로 변경',
                onChange: v => {
                    var _a, _b, _c, _d;
                    const valueParsed = JSON.parse((_b = (_a = this.afterSomePeriodFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                    valueParsed.period =
                        Number(((_c = this.afterSomePeriodFreePerChapterPeriodField) === null || _c === void 0 ? void 0 : _c.firstField.value) || 0) *
                            24 *
                            60 *
                            60 +
                            Number(v) * 60 * 60;
                    (_d = this.afterSomePeriodFreePerChapterSettingField) === null || _d === void 0 ? void 0 : _d.onChange(JSON.stringify(valueParsed));
                },
            }),
        };
        this.afterSomePeriodFreePerChapterPeriodField =
            afterSomePeriodFreePerChapterIndexesFields;
        this.afterSomePeriodFreePerChapterSettingField = new fields_1.CustomField('afterSomePeriodFreePerChapterSetting', () => { var _a; return (_a = this._target.afterSomePeriodFreePerChapterSetting) !== null && _a !== void 0 ? _a : '{}'; }, () => null);
        // 기스무 설정
        this.waitFreePerChapterActivatedField = new SelectionFieldWithUI_1.SelectionFieldWithUI('waitFreePerChapterActivated', () => storyPrice.waitFreePerChapterActivated, () => null, {
            label: '기스무 설정',
            selection: [
                // name 뒤에 띄어쓰기 안넣으면 freedAtPerChapterActivatedField의 selection과 동일해져서 데이터가 꼬이는 듯 합니다.
                { name: '설정함  ', value: true },
                { name: '설정 안함  ', value: false },
            ],
            onChange: value => {
                (0, mobx_1.runInAction)(() => {
                    this.showWaitFreeAtPerChapterPriceFieldGroup = value;
                });
            },
        });
        const waitFreePerChapterSetting = storyPrice.waitFreePerChapterSetting &&
            JSON.parse(storyPrice.waitFreePerChapterSetting);
        this.waitFreePerChapterIndexField = new ui_1.SelectionInput('waitFreePerChapterIndex', '기스무 적용 회차', waitFreePerChapterSetting === null || waitFreePerChapterSetting === void 0 ? void 0 : waitFreePerChapterSetting.chapterIndex, this.chapterIndexSelection, {
            placeholder: '회차를 입력하세요',
            textAfterField: '이후 발행되는 회차부터 적용',
            onChangeBeforeSubmit: v => {
                var _a, _b, _c;
                const valueParsed = JSON.parse((_b = (_a = this.waitFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                valueParsed.chapterIndex = Number(v);
                (_c = this.waitFreePerChapterSettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(valueParsed));
            },
        });
        const waitFreePerChapterPeriod = waitFreePerChapterSetting &&
            moment.duration(waitFreePerChapterSetting.period, 'seconds');
        const waitFreePerChapterIndexesFields = {
            firstField: new ui_1.InputWithValidation('waitFreePerChapterIndexesFieldsFirstField', '기스무 시작일시', '시간을 입력하세요', 'number', waitFreePerChapterPeriod
                ? waitFreePerChapterPeriod.hours() +
                    waitFreePerChapterPeriod.days() * 24
                : undefined, null, {
                noLabel: true,
                textAfterInput: '시간',
                onChange: v => {
                    var _a, _b, _c, _d;
                    const valueParsed = JSON.parse((_b = (_a = this.waitFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                    valueParsed.period =
                        Number(v) * 60 * 60 +
                            Number(((_c = this.waitFreePerChapterPeriodField) === null || _c === void 0 ? void 0 : _c.secondField.value) || 0) *
                                60;
                    (_d = this.waitFreePerChapterSettingField) === null || _d === void 0 ? void 0 : _d.onChange(JSON.stringify(valueParsed));
                },
            }),
            secondField: new ui_1.InputWithValidation('waitFreePerChapterIndexesFieldsSecondField', '', '시간을 입력하세요', 'number', waitFreePerChapterPeriod
                ? waitFreePerChapterPeriod.minutes()
                : undefined, null, {
                noLabel: true,
                textAfterInput: '분 후 무료 회차로 변경',
                onChange: v => {
                    var _a, _b, _c, _d;
                    const valueParsed = JSON.parse((_b = (_a = this.waitFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                    valueParsed.period =
                        Number(((_c = this.waitFreePerChapterPeriodField) === null || _c === void 0 ? void 0 : _c.firstField.value) || 0) *
                            60 *
                            60 +
                            Number(v) * 60;
                    (_d = this.waitFreePerChapterSettingField) === null || _d === void 0 ? void 0 : _d.onChange(JSON.stringify(valueParsed));
                },
            }),
        };
        this.waitFreePerChapterPeriodField = waitFreePerChapterIndexesFields;
        this.waitFreePerChapterSettingField = new fields_1.CustomField('waitFreePerChapterSetting', () => { var _a; return (_a = this._target.waitFreePerChapterSetting) !== null && _a !== void 0 ? _a : '{}'; }, () => null);
        if (storyPrice.story.playType !== core_1.GQLSTORY_PLAY_TYPE.EPUB) {
            // 타임리프 무료 설정(인터랙티브인 경우에만 보여줌)
            this.timeLeapFreePerChapterActivatedField = new SelectionFieldWithUI_1.SelectionFieldWithUI('timeLeapFreePerChapterActivated', () => storyPrice.timeLeapFreePerChapterActivated, () => null, {
                label: '타임리프 무료 설정',
                selection: [
                    // name 뒤에 띄어쓰기 안넣으면 freedAtPerChapterActivatedField의 selection과 동일해져서 데이터가 꼬이는 듯 합니다.
                    { name: '설정함   ', value: true },
                    { name: '설정 안함   ', value: false },
                ],
                onChange: value => {
                    (0, mobx_1.runInAction)(() => {
                        this.showTimeLeapFreeAtPerChapterPriceFieldGroup = value;
                    });
                },
            });
            const timeLeapFreePerChapterSetting = storyPrice.timeLeapFreePerChapterSetting &&
                JSON.parse(storyPrice.timeLeapFreePerChapterSetting);
            const timeLeapFreePerChapterPeriodField = {
                firstField: new ui_1.InputWithValidation('timeLeapFreePerChapterPeriodFieldFirstField', '타임리프 무료 기간', '', 'datetime-local', (timeLeapFreePerChapterSetting === null || timeLeapFreePerChapterSetting === void 0 ? void 0 : timeLeapFreePerChapterSetting.startAt)
                    ? moment(timeLeapFreePerChapterSetting === null || timeLeapFreePerChapterSetting === void 0 ? void 0 : timeLeapFreePerChapterSetting.startAt).format(DATETIME_LOCAL_FMT)
                    : '', null, {
                    noLabel: true,
                    textAfterInput: '~',
                    onChange: v => {
                        var _a, _b, _c;
                        const valueParsed = JSON.parse((_b = (_a = this.timeLeapFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                        valueParsed.startAt = new Date(v).toISOString();
                        (_c = this.timeLeapFreePerChapterSettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(valueParsed));
                    },
                }),
                secondField: new ui_1.InputWithValidation('timeLeapFreePerChapterPeriodFieldSecondField', '', '', 'datetime-local', (timeLeapFreePerChapterSetting === null || timeLeapFreePerChapterSetting === void 0 ? void 0 : timeLeapFreePerChapterSetting.endAt)
                    ? moment(timeLeapFreePerChapterSetting === null || timeLeapFreePerChapterSetting === void 0 ? void 0 : timeLeapFreePerChapterSetting.endAt).format(DATETIME_LOCAL_FMT)
                    : '', null, {
                    noLabel: true,
                    onChange: v => {
                        var _a, _b, _c;
                        const valueParsed = JSON.parse((_b = (_a = this.timeLeapFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                        valueParsed.endAt = new Date(v).toISOString();
                        (_c = this.timeLeapFreePerChapterSettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(valueParsed));
                    },
                }),
            };
            this.timeLeapFreePerChapterPeriodField = timeLeapFreePerChapterPeriodField;
            this.timeLeapFreePerChapterSettingField = new fields_1.CustomField('timeLeapFreePerChapterSetting', () => { var _a; return (_a = this._target.timeLeapFreePerChapterSetting) !== null && _a !== void 0 ? _a : '{}'; }, () => null);
            this.loadNoticeListFilteredTimeLeap(timeLeapFreePerChapterSetting === null || timeLeapFreePerChapterSetting === void 0 ? void 0 : timeLeapFreePerChapterSetting.noticeId).catch();
        }
        // UI 초기 설정
        // 가격 필드들은 유/무료 여부에 따라 보여주거나 숨긴다.
        this.showPaidPerChapterPriceActivateField = !this.isFreeField.value;
        this.showPaidPerStoryPriceActivateField = !this.isFreeField.value;
        // 광고 필드는 무조건 보여준다.
        this.showAdPerChapterPriceActivateField = true;
        // 프로모션 필드는 회차 단위 구매시에만 보여준다.
        this.showAfterSomePeriodFreeAtPerChapterPriceActivateField =
            !!this.paidPerChapterActivatedField.input.value;
        this.showWaitFreeAtPerChapterPriceActivateField =
            !!this.paidPerChapterActivatedField.input.value;
        this.showFreeAtPerChapterPriceActivateField =
            !!this.paidPerChapterActivatedField.input.value;
        this.showTimeLeapFreeAtPerChapterPriceActivateField =
            !!this.paidPerChapterActivatedField.input.value;
        // 필드의 하위 그룹들은 해당 필드의 Activated 여부에 따라 보여준다.
        this.showPaidPerChapterPriceFieldGroup = storyPrice.paidPerChapterActivated;
        this.showPaidPerStoryPriceFieldGroup = storyPrice.paidPerStoryActivated;
        this.showFreeAtPerChapterPriceFieldGroup =
            storyPrice.freedAtPerChapterActivated;
        this.showAfterSomePeriodFreeAtPerChapterPriceFieldGroup =
            storyPrice.afterSomePeriodFreePerChapterActivated;
        this.showWaitFreeAtPerChapterPriceFieldGroup =
            storyPrice.waitFreePerChapterActivated;
        this.showTimeLeapFreeAtPerChapterPriceFieldGroup =
            storyPrice.timeLeapFreePerChapterActivated;
        // 웹소설인 경우 작품 단위 구매 필드, 타임리프 무료 설정 필드 제거
        if (storyPrice.story.playType === core_1.GQLSTORY_PLAY_TYPE.EPUB) {
            this.paidPerStoryActivatedField = null;
            this.timeLeapFreePerChapterActivatedField = null;
        }
        // 연재중인 경우 작품 단위 구매 설정 불가
        if (!storyPrice.story.isFinished) {
            if (this.paidPerStoryActivatedField) {
                this.paidPerStoryActivatedField.input.isDisabled = true;
            }
        }
        this.loadServiceCountries(storyPrice.story.bundleId).catch();
        (0, mobx_1.makeObservable)(this, {
            storyPrice: mobx_1.observable,
            noticeData: mobx_1.observable,
            timeLeapFreePerChapterNoticeIdField: mobx_1.observable,
            countryCodeField: mobx_1.observable,
            paidPerStorySetting: mobx_1.observable,
            serviceCountries: mobx_1.observable,
            showPaidPerChapterPriceActivateField: mobx_1.observable,
            showPaidPerChapterPriceFieldGroup: mobx_1.observable,
            showPaidPerStoryPriceActivateField: mobx_1.observable,
            showPaidPerStoryPriceFieldGroup: mobx_1.observable,
            showAdPerChapterPriceActivateField: mobx_1.observable,
            showAdPerChapterPriceFieldGroup: mobx_1.observable,
            showFreeAtPerChapterPriceActivateField: mobx_1.observable,
            showFreeAtPerChapterPriceFieldGroup: mobx_1.observable,
            showAfterSomePeriodFreeAtPerChapterPriceActivateField: mobx_1.observable,
            showAfterSomePeriodFreeAtPerChapterPriceFieldGroup: mobx_1.observable,
            showWaitFreeAtPerChapterPriceActivateField: mobx_1.observable,
            showWaitFreeAtPerChapterPriceFieldGroup: mobx_1.observable,
            showTimeLeapFreeAtPerChapterPriceActivateField: mobx_1.observable,
            showTimeLeapFreeAtPerChapterPriceFieldGroup: mobx_1.observable,
            loadNoticeListFilteredTimeLeap: mobx_1.action,
        });
    }
    get chapterIndexSelection() {
        var _a;
        const lastChapterIndex = ((_a = this._target.story) === null || _a === void 0 ? void 0 : _a.lastChapterIndex) || 0;
        return Array.from({ length: lastChapterIndex }, (_, index) => {
            return { name: String(index + 1) + '화', value: index + 1 };
        });
    }
    get storyId() {
        return this._target.storyId;
    }
    get rootStore() {
        return this._target.rootStore;
    }
    createChangeSet() {
        const changeSet = {};
        this.editorFields.forEach(fld => {
            // 매 요청 시 모든 에디터 필드를 보낸다. 다만 GQLPriceSettingInput에 포함되지 않은 필드는 제외한다.
            if (fld.fieldId !== 'playType' &&
                fld.fieldId !== 'countryCode' &&
                fld.fieldId !== 'status' &&
                fld.fieldId !== 'storyName' &&
                fld.fieldId !== 'storyId') {
                this.onFieldValueChangeFound(fld, fld.value, changeSet);
            }
        });
        return (0, lodash_1.isEmpty)(changeSet) ? null : changeSet;
    }
    async submitChanges(onError) {
        return super.submitChanges(onError);
    }
    /**
     * 타임리프 문자열이 필터링된 공지사항 리스트를 가져온다.
     */
    async loadNoticeListFilteredTimeLeap(noticeId) {
        const di = this.rootStore.di;
        const noticeListKo = await di.server.getNoticeListFilteredTimeLeapKo();
        const noticeListEn = await di.server.getNoticeListFilteredTimeLeapEn();
        const noticeList = [...noticeListKo.list, ...noticeListEn.list];
        this.timeLeapFreePerChapterNoticeIdField = new ui_1.SelectionInput('timeLeapFreePerChapterNoticeId', '타임리프 무료 공지 ID', noticeId, noticeList.map(notice => {
            return { value: notice.noticeId, name: notice.title };
        }), {
            placeholder: '타임리프 무료 공지사항 ID를 선택해주세요',
            onChangeBeforeSubmit: v => {
                var _a, _b, _c;
                const valueParsed = JSON.parse((_b = (_a = this.timeLeapFreePerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                valueParsed.noticeId = v;
                (_c = this.timeLeapFreePerChapterSettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(valueParsed));
            },
        });
    }
    async loadServiceCountries(bundleId) {
        try {
            if (!bundleId)
                return;
            const res = (await this.rootStore.di.server.getStoryBundleCountriesInfo(bundleId));
            const countries = (res === null || res === void 0 ? void 0 : res.serviceCountries) || this._target.serviceCountries;
            // 국가 선택
            const countryFieldOptions = countries.map(country => {
                return {
                    name: core_1.COUNTRY_CODE_TO_KO_STRING[country.code],
                    value: country.code,
                    description: '',
                };
            }) || [];
            const sortedCountryFieldOptions = [...countryFieldOptions];
            const krIndex = sortedCountryFieldOptions.findIndex(option => option.value === 'KR');
            if (krIndex !== -1) {
                const krOption = sortedCountryFieldOptions.splice(krIndex, 1)[0];
                sortedCountryFieldOptions.unshift(krOption);
            }
            (0, mobx_1.runInAction)(() => {
                // key와 parameter 동일
                this.countryCodeField = new SelectionFieldWithUI_1.SelectionFieldWithUI('countryCode', () => this._target.countryCode, () => null, {
                    label: '국가 선택',
                    selection: sortedCountryFieldOptions,
                    onChange: async (value) => {
                        this._target.store.rootStore.di.redirectToUrl(`/story/${this.storyId}/price?country=${value}`);
                    },
                });
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
}
exports.DOStoryPriceEditor = DOStoryPriceEditor;
