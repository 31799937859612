import React from 'react'
import Select from 'react-select'

export type ChoiceFromPlayerClassSelectFilterType = {
  playerClassId: number | undefined
  name: string
}

interface IChoiceFromPlayerClassSelectProps {
  value: number | undefined
  filterTypes: ChoiceFromPlayerClassSelectFilterType[]
  onChange: (value: number | undefined) => void
}

export const ChoiceFromPlayerClassSelect: React.FC<
  IChoiceFromPlayerClassSelectProps
> = (props) => {
  const { value, filterTypes, onChange } = props

  const selectOptions = [
    {
      label: '모든 플레이어 클래스의 통계',
      value: undefined,
    },
    ...filterTypes.map((filterType) => ({
      label: `${filterType.name}`,
      value: filterType.playerClassId,
    })),
  ]

  return (
    <Select
      placeholder="플레이어 클래스"
      value={selectOptions.find((v) => v.value === value)}
      // @ts-ignore
      onChange={(v) => onChange(v.value)}
      options={selectOptions}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
