import React from 'react'
import Select from 'react-select'
import { GQLChapterCommentOrderBy, GQLCHAPTER_COMMENT_ORDER_FIELD, GQLORDER_BY_DIRECTION } from '../../../@types/server'

export type ChapterCommentSelectOrderOptionLabel =
  | '좋아요 많은 순(합계)'
  | '좋아요 많은 순(유저만)'
  | '대댓글 많은 순'
  | '최신 생성 순'
  | '최신 생성 역순'
  | '신고 많은 순'

type ChapterCommentOrderSelectProps = {
  defaultLabel: ChapterCommentSelectOrderOptionLabel
  onChange: (option: ChapterCommentOrderSelectOption) => void
}

type ChapterCommentOrderSelectOption = {
  label: ChapterCommentSelectOrderOptionLabel
  value: GQLChapterCommentOrderBy[]
}

const SELECT_OPTIONS: ChapterCommentOrderSelectOption[] = [
  {
    label: '좋아요 많은 순(합계)',
    value: [
      {
        field: 'UserLikes' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
      {
        field: 'CreatedDate' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
    ],
  },
  {
    label: '좋아요 많은 순(유저만)',
    value: [
      {
        field: 'UserLikesByUsers' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
      {
        field: 'CreatedDate' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
    ],
  },
  {
    label: '대댓글 많은 순',
    value: [
      {
        field: 'NestedComment' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
      {
        field: 'CreatedDate' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
    ],
  },
  {
    label: '최신 생성 순',
    value: [
      {
        field: 'CreatedDate' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
    ],
  },
  {
    label: '최신 생성 역순',
    value: [
      {
        field: 'CreatedDate' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'ASC' as GQLORDER_BY_DIRECTION,
      },
    ],
  },
  {
    label: '신고 많은 순',
    value: [
      {
        field: 'Reports' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
      {
        field: 'CreatedDate' as GQLCHAPTER_COMMENT_ORDER_FIELD,
        direction: 'DESC' as GQLORDER_BY_DIRECTION,
      },
    ],
  },
]

export const ChapterCommentSortSelect: React.FC<ChapterCommentOrderSelectProps> = (props) => {
  const { defaultLabel, onChange } = props

  return (
    <Select
      placeholder="정렬 기준"
      defaultValue={SELECT_OPTIONS.find((o) => o.label === defaultLabel)}
      // @ts-ignore
      onChange={(v) => onChange(v)}
      options={SELECT_OPTIONS}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
