"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTConditionSubBlock = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const __1 = require("../../../../../..");
const changeOp_1 = require("../../../../../../changeOp");
const validation_1 = require("../../../../validation");
const DOBaseScriptSubBlock_1 = require("../../DOBaseScriptSubBlock");
const IDOStatement_1 = require("../../IDOStatement");
const DOSTCondition_1 = require("../DOSTCondition");
const DOSTToBlock_1 = require("../DOSTToBlock");
// 컨디션에 연결되는 ToBlock 은 스튜디오에서 "그 외에는" 으로 처리한다. 단, 1개만.
const ACCEPTABLE_STATEMENTS = [__1.STATEMENT_TYPE.Condition, __1.STATEMENT_TYPE.ToBlock];
const ConditionStatementOrder = {
    [__1.STATEMENT_TYPE.Condition]: 1,
    [__1.STATEMENT_TYPE.ToBlock]: 2,
};
class DOSTConditionSubBlock extends DOBaseScriptSubBlock_1.DOBaseScriptSubBlock {
    constructor(statements, block, uniqueId) {
        super(statements, block, IDOStatement_1.DOSubBlockType.ConditionSubBlock, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.Condition;
        // startEditing() 이후에 사본이 반영된다.
        this.conditionFields = [];
        this.elseToField = null;
        (0, mobx_1.makeObservable)(this, {
            conditionFields: mobx_1.observable,
            elseToField: mobx_1.observable,
            elseBlock: mobx_1.computed,
            conditionStatements: mobx_1.computed,
            canAddRow: mobx_1.computed,
        });
    }
    mergeStatement(statement) {
        if ((0, lodash_1.last)(this.statements) instanceof DOSTToBlock_1.DOSTToBlock) {
            return false;
        }
        if (statement.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            ACCEPTABLE_STATEMENTS.includes(statement.statementType)) {
            (0, mobx_1.runInAction)(() => {
                if (statement instanceof DOSTToBlock_1.DOSTToBlock) {
                    statement.isInsideSubBlock = true;
                }
                this.statements.push(statement);
            });
            return true;
        }
        return false;
    }
    fixStatements() {
        var _a, _b;
        // 컨디션 블록의 마지막은 언제나 DOSTToBlock 이어야 한다. (else 구문)
        const lastSt = (0, lodash_1.last)(this.statements);
        if (!(lastSt instanceof DOSTToBlock_1.DOSTToBlock)) {
            // 우리는 여기서 블록이동만 액션으로 존재한다고 가정하고 있다.
            const defaultBlock = (_b = (_a = (0, lodash_1.last)(this.conditionStatements)) === null || _a === void 0 ? void 0 : _a.st.conditionActionParam) !== null && _b !== void 0 ? _b : '';
            (0, mobx_1.runInAction)(() => {
                this.statements.push(new DOSTToBlock_1.DOSTToBlock({
                    statementType: __1.STATEMENT_TYPE.ToBlock,
                    sourceLine: this.block.store.rootStore.di.generateSourceLine(),
                    block: defaultBlock,
                    background: '',
                }, this.block, true));
            });
        }
    }
    getOrderForChoiceStatement(st) {
        // @ts-ignore
        return ConditionStatementOrder[st.statementType];
    }
    /**
     * 이 조건부 블록의 조건들 문장을 반환한다.
     */
    get conditionStatements() {
        return this.statements.filter(v => v instanceof DOSTCondition_1.DOSTCondition);
    }
    //
    // IStudioEditor implementation
    //
    startEditing() {
        (0, mobx_1.runInAction)(() => {
            this.conditionFields = this.statements
                .filter(st => st instanceof DOSTCondition_1.DOSTCondition)
                .map(st => {
                const c = st.clone();
                return c;
            });
            this.elseToField = this.statements
                .filter(st => st instanceof DOSTToBlock_1.DOSTToBlock)
                .map(st => st.clone())[0];
        });
        return super.startEditing();
    }
    cancelEditing() {
        super.cancelEditing();
        (0, mobx_1.runInAction)(() => {
            this.conditionFields = [];
            this.elseToField = null;
        });
    }
    async submitEditing() {
        const op = this.helper.opFactory().startBulk();
        const numConditions = this.conditionStatements.length;
        // 새롭게 생성된 블록이 있다면, 생성한다.
        this.conditionFields.forEach(f => {
            op.createNewBlock(f.blockOptions.value, this.background);
        });
        op.createNewBlock(this.elseToField.blockOptions.value, this.background);
        const max = Math.max(numConditions, this.conditionFields.length);
        // 현재 실제 데이터와 비교를 통해 수정을 가한다.
        const indexesToRemove = [];
        for (let index = 0; index < max; index += 1) {
            const isCreating = numConditions <= index;
            const isDeleting = index >= this.conditionFields.length;
            if (isCreating) {
                const f = this.conditionFields[index];
                const conditionData = (0, lodash_1.cloneDeep)(f.data);
                op.addCondition(this, conditionData, index);
            }
            else if (isDeleting) {
                indexesToRemove.push(index);
            }
            else {
                const f = this.conditionFields[index];
                const current = this.conditionStatements[index];
                if (!f.isSame(current)) {
                    const conditionData = (0, lodash_1.cloneDeep)(f.data);
                    op.changeCondition(this, index, conditionData);
                }
            }
        }
        (0, mobx_1.runInAction)(() => {
            indexesToRemove
                .sort((a, d) => d - a)
                .forEach(index => op.removeConditionAtIndex(this, index));
        });
        if (!this.elseBlock.isSame(this.elseToField)) {
            op.changeConditionElseToBlock(this, (0, lodash_1.cloneDeep)(this.elseToField.data));
        }
        return super.submitEditing(op).then(r => {
            this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(__1.GA4_EVENT_NAME.DO_ROUTE_OF_CONDITION);
            return r;
        });
    }
    //
    // IStudioChangeOpConsumer implementations
    //
    async applyChangeOp(op, type) {
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.AddCondition: {
                return this.addConditionInternal(op);
            }
            case changeOp_1.StudioChangeOpType.ChangeConditionAtIndex: {
                return this.changeConditionAtIndexInternal(op);
            }
            case changeOp_1.StudioChangeOpType.RemoveConditionAtIndex: {
                return this.removeConditionAtIndexInternal(op);
            }
            case changeOp_1.StudioChangeOpType.ChangeConditionElse: {
                return this.changeConditionElseInternal(op);
            }
            default:
                break;
        }
        return super.applyChangeOp(op, type);
    }
    addConditionInternal(op) {
        const line = new DOSTCondition_1.DOSTCondition(op.conditionData, this.block, op.uniqueId);
        (0, mobx_1.runInAction)(() => {
            this.statements.splice(op.index, 0, line);
        });
        return {
            reverse: changeOp_1.StudioChangeOpFactory.removeConditionAtIndex(this, op.index),
            lineToFocus: this,
        };
    }
    changeConditionAtIndexInternal(op) {
        const statements = this.statements.filter(s => s instanceof DOSTCondition_1.DOSTCondition);
        const { conditionData: data, index } = op;
        if (index >= statements.length) {
            this.helper.di.showError(`26803: Condition statement invalid condition index`);
            return null;
        }
        const line = statements[index];
        const original = (0, lodash_1.cloneDeep)(line.data);
        (0, mobx_1.runInAction)(() => (line.data = data));
        return {
            reverse: changeOp_1.StudioChangeOpFactory.changeCondition(this, index, original),
            lineToFocus: this,
        };
    }
    removeConditionAtIndexInternal(op) {
        const statements = this.statements.filter(s => s instanceof DOSTCondition_1.DOSTCondition);
        const { index } = op;
        if (index >= statements.length) {
            this.helper.di.showError(`27513: Condition statement invalid condition index`);
            return null;
        }
        const line = statements[index];
        const original = (0, lodash_1.cloneDeep)(line.data);
        (0, mobx_1.runInAction)(() => this.statements.splice(index, 1));
        return {
            reverse: changeOp_1.StudioChangeOpFactory.addCondition(this, original, index, line.uniqueId),
            lineToFocus: this,
        };
    }
    changeConditionElseInternal(op) {
        const line = (0, lodash_1.last)(this.statements);
        const original = (0, lodash_1.cloneDeep)(line.data);
        (0, mobx_1.runInAction)(() => (line.data = op.elseBlockData));
        return {
            reverse: changeOp_1.StudioChangeOpFactory.changeConditionElseToBlock(this, original),
            lineToFocus: this,
        };
    }
    /**
     * "그 외에는" 조건절에 해당하는 Statement.
     * fixStatements() 에 의해 무조건 true 여야 한다.
     */
    get elseBlock() {
        const lastSt = (0, lodash_1.last)(this.statements);
        return lastSt;
    }
    get canAddRow() {
        return true;
    }
    addConditionRow() {
        (0, mobx_1.runInAction)(() => {
            this.conditionFields.push(new DOSTCondition_1.DOSTCondition(null, this.block));
        });
    }
    removeConditionRow(index) {
        (0, mobx_1.runInAction)(() => {
            this.conditionFields.splice(index, 1);
        });
    }
    getEdgeNameForIndex(index) {
        return `index ${index}`;
    }
    get blocksTo() {
        // 그 외에는, 블록은 다른 구문에서 연결되지 않은 경우에만 플로우차트에 연결한다.
        const isElseBlockOptional = !!this.statements.find(v => {
            var _a, _b, _c, _d;
            return v instanceof DOSTCondition_1.DOSTCondition &&
                ((_b = (_a = v.blocksTo[0]) === null || _a === void 0 ? void 0 : _a.block) === null || _b === void 0 ? void 0 : _b.id) === ((_d = (_c = this.elseBlock) === null || _c === void 0 ? void 0 : _c.blockTo) === null || _d === void 0 ? void 0 : _d.id);
        });
        return (0, lodash_1.flatten)(this.statements
            .filter(v => {
            if (!isElseBlockOptional) {
                return true;
            }
            return (v.lineType === IDOStatement_1.UILineType.SingleLineScript &&
                v.statementType !== __1.STATEMENT_TYPE.ToBlock);
        })
            .map(v => v.blocksTo));
    }
    selectNumberByKeyboard(index) {
        var _a, _b, _c, _d;
        const block = (_c = (_b = (_a = this.conditionStatements[index]) === null || _a === void 0 ? void 0 : _a.blocksTo) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.block;
        if (block) {
            (_d = this.block.parentChapter.blockEditor) === null || _d === void 0 ? void 0 : _d.onBlockClickedForJump(this.block, block.id);
        }
    }
    validate() {
        const res = super.validate();
        const results = res.map(v => {
            // "그 외에는" 오류로 변경
            if (v.source instanceof DOSTToBlock_1.DOSTToBlock) {
                v.type =
                    v.type === validation_1.StudioValidationType.ToBlockHasNoBlock
                        ? validation_1.StudioValidationType.ConditionBlockHasNoToBlock
                        : validation_1.StudioValidationType.ConditionBlockHasInvalidBlockId;
            }
            return v;
        });
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    onLoadedFromBundle(bundle) {
        super.onLoadedFromBundle(bundle);
        (0, mobx_1.runInAction)(() => (this.elseBlock.isInsideSubBlock = true));
    }
    //
    // Studio translator
    //
    async getMessagesToTranslate() {
        return null;
    }
}
exports.DOSTConditionSubBlock = DOSTConditionSubBlock;
