import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLChoice } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IChoiceListableOption } from '../ChoiceCommon'

export function useChoiceListDataLoader(storyId: number) {
  const { listChoiceOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IChoiceListableOption
    ): Promise<ODListableResponseType<GQLChoice>> {
      const r = await listChoiceOfStory({
        page,
        pageSize: 10,
        storyId,
        ...options,
      })
      return r as ODListableResponseType<GQLChoice>
    },
    [listChoiceOfStory, storyId]
  )
}
