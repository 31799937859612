import classNames from 'classnames'
import * as React from 'react'
import { Modal } from 'reactstrap'

export enum ODModalTheme {
  Primary = 'Primary',
}

export enum ODModalSize {
  Small = 'Small',
  Normal = 'Normal',
  XLarge = 'XLarge',
}

type ODModalProps = {
  theme?: ODModalTheme
  size?: ODModalSize
  isOpen: boolean
  fade?: boolean
  toggle: () => void
}

export const ODModal: React.FC<ODModalProps> = ({ children, ...props }) => {
  const { isOpen, toggle, fade = true, size = ODModalSize.Normal } = props

  const sizeCls = { 'modal-sm': size === ODModalSize.Small, 'modal-xl': size === ODModalSize.XLarge }

  return (
    <Modal fade={fade} isOpen={isOpen} toggle={toggle} className={classNames('modal-primary', 'modal-rect', sizeCls)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>{children}</div>
    </Modal>
  )
}
