"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemFieldWithUI = void 0;
const mobx_1 = require("mobx");
const SelectionFieldWithUI_1 = require("./SelectionFieldWithUI");
class ItemFieldWithUI extends SelectionFieldWithUI_1.SelectionFieldWithUI {
    constructor(itemStore, fieldId, getter, setter, uiOptions = {}) {
        super(fieldId, getter, setter, {
            ...uiOptions,
            selection: itemStore.generateOptions(),
        });
        this.itemStore = itemStore;
    }
    reload() {
        (0, mobx_1.runInAction)(() => {
            this.input.options = this.itemStore.generateOptions();
        });
        super.reload();
    }
}
exports.ItemFieldWithUI = ItemFieldWithUI;
