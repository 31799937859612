"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryHasBackground = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
/**
 * Character domain object.
 */
class DOStoryHasBackground {
    constructor(store, data) {
        this.file = null;
        this.displayName = null;
        this.storygameBackgrounds = [];
        this.store = store;
        this.data = data;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            displayName: mobx_1.observable,
            file: mobx_1.observable,
            data: mobx_1.observable,
            storygameBackgrounds: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    merge(data) {
        const fields = [
            'shfId',
            'name',
            'displayName',
            'file',
            'fileId',
            'storygameBackgrounds',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
}
exports.DOStoryHasBackground = DOStoryHasBackground;
