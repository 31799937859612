import React from 'react'
import { Col, Input, Row } from 'reactstrap'
import { ODButton, ODButtonTheme } from '../../components/base/ODButton'
import { ODModal, ODModalSize } from '../../components/ODModal/ODModal'
import { ODModalBody } from '../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../components/ODModal/ODModalHeader'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { Utils } from '../../utils/utils'

interface IColorAddModalProps {
  onClose: () => void
  onConfirm: () => void
}

export const ColorAddModal: React.FC<IColorAddModalProps> = (props) => {
  const { onConfirm, onClose } = props
  const { createColor } = useStoryPlayAPIs()
  const [name, setName] = React.useState('')
  const [hex, setHex] = React.useState('')
 
  const handleConfirm = async () => {
    if (!name) return Utils.showError('색상 이름을 적어주세요!')
    if (!hex) return Utils.showError('색상을 지정해 주세요!')

    try {
      await createColor({ name, hex })
      Utils.showSuccess('색상이 추가되었습니다.')
      onConfirm()
    } catch (e) {
      // @ts-ignore
      Utils.showError(e)
    }
  }

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Normal}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="작품 색상 추가하기" onClose={onClose} />
        <ODModalBody>
        <Row style={{ marginBottom: 20 }}>
          <Col md={4}>색상 이름</Col>
          <Col md={8}>
              <Input 
                value={name}
                type="text" 
                onChange={(v) => setName(v.target.value)} 
              />
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col md={4}>색상 선택</Col>
          <Col md={8}>
              <Input 
                value={hex} 
                type="color"
                onChange={(v) => setHex(v.target.value)}  
              />
          </Col>
        </Row>
        </ODModalBody>
        <ODModalFooter>
          <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={handleConfirm}>
            추가하기
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
