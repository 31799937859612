"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateCreateNewBlock = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const StudioChangeOpFactory_1 = require("../../../changeOp/StudioChangeOpFactory");
const consts_1 = require("../../../consts");
const entity_1 = require("../entity");
const TemplateBase_1 = require("./TemplateBase");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class TemplateCreateNewBlock extends TemplateBase_1.TemplateBase {
    constructor(editor) {
        super(editor);
        this.name = trans('legacy_TemplateCreateNewBlock.ts_add_new_block');
        this.description = trans('legacy_TemplateCreateNewBlock.ts_add_one_unconnected_new_block');
        this.tags = [TemplateBase_1.TemplateTag.Block, TemplateBase_1.TemplateTag.BulkEditing];
    }
    submit(value) {
        const c = this.editor.lineBlockEditing.parentChapter;
        const blocks = value.split('\n').filter(v => !!v);
        const block = this.editor.lineBlockEditing;
        const op = new StudioChangeOpFactory_1.StudioChangeOpFactory(block.parentChapter).startBulk();
        for (const name of blocks) {
            if (!c.blockStore.getById(name)) {
                op.createNewBlock(name, this.editor.backgroundForCurrentLine);
            }
        }
        return op.submitBulk();
    }
    buildActionChain() {
        const nameField = new entity_1.InputWithValidation('newBlockName', trans('legacy_TemplateAddConnections.ts_block_name'), trans('legacy_TemplateCreateNewBlock.ts_block_names'), 'textarea', '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
        }).setAutoFocus();
        nameField.setEditing(true);
        const builder = new ActionChainBuilder_1.ActionChainBuilder('TemplateCreateNewBlockAction');
        builder.addModal(modalBuilder => {
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: trans('legacy_TemplateCreateNewBlock.ts_create_new_block'),
                imageName: consts_1.SPImageName.MenuNovel,
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(nameField));
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: trans('legacy_ALCreateNewStory_create'),
                        onClick: () => {
                            this.submit(nameField.value);
                            modalBuilder.finishAction(nameField.value);
                        },
                    },
                ],
            });
        });
        return { ...builder.buildActionChain(), editorFields: [] };
    }
}
exports.TemplateCreateNewBlock = TemplateCreateNewBlock;
