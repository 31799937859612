import { ODEntity, ODEntityInput, ODEntityLabeled, ODEntityRaw, useODEntityAPI } from '@odc/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap'
import { GQLEmailSignupByAdmin, GQLUser, GQLUserPropsInput } from '../../@types/server'
import { ODButton, ODButtonTheme } from '../../components/base/ODButton'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { ODIcon, ODIcons } from '../../components/ODIcon'
import { ODModal, ODModalSize } from '../../components/ODModal/ODModal'
import { ODModalBody } from '../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../components/ODModal/ODModalHeader'
import { useAppContext } from '../../context/AppContext'
import { useODMutation } from '../../context/ODCommon'
import { GQL_GET_USER_PROFILE, GQL_UPDATE_USER } from '../../hooks/gqls_admin'
import { Utils } from '../../utils/utils'
import { ICTUser, UserPrimaryKey } from './UserCommon'

interface IUserMyProfileEditContainerProps {}

export const UserMyProfileEditContainer: React.FC<IUserMyProfileEditContainerProps> = (props) => {
  const updateMyProfile = useODMutation<Partial<GQLUserPropsInput>, GQLUser>(GQL_UPDATE_USER)

  const apis = useODEntityAPI<GQLUser, ICTUser, GQLEmailSignupByAdmin, any>({
    updateAPI: (input: GQLUserPropsInput & { id: number }) => {
      const { id, ...data } = input
      return updateMyProfile(data)
    },
    readGQL: gql(GQL_GET_USER_PROFILE),
    primaryKeyName: UserPrimaryKey,
  })

  const [showPasswordModal, setShowPasswordModal] = React.useState(false)
  const [isChangingPassword, setIsChangingPassword] = React.useState(false)
  const [oldPassword, setOldPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('')
  const { setPasswordTooOld } = useAppContext()

  React.useEffect(() => {
    setPasswordTooOld(false)
  }, [setPasswordTooOld])

  const handlePWChange = async () => {
    if (isChangingPassword) {
      return
    }
    setIsChangingPassword(true)

    if (oldPassword.trim().length === 0) {
      Utils.showError('예전 비밀번호를 입력하세요.')
      setIsChangingPassword(false)
      return
    }

    if (newPassword.trim().length === 0) {
      Utils.showError('변경할 비밀번호를 입력하세요.')
      setIsChangingPassword(false)
      return
    }

    if (newPassword !== newPasswordConfirm) {
      Utils.showError('변경할 비밀번호가 서로 다릅니다. 다시 입력해주세요.')
      setIsChangingPassword(false)
      return
    }

    try {
      await updateMyProfile({ oldPassword, password: newPassword })
      Utils.showSuccess('비밀번호가 변경되었습니다.')
      setShowPasswordModal(false)
    } catch (ex) {
      Utils.showError(ex)
    }
    setIsChangingPassword(false)
  }

  return (
    <Container style={{ padding: 0, margin: 0, maxWidth: '100%' }}>
      <Card>
        <CardBody>
          <ODEntity
            resourceId={1}
            // @ts-ignore
            api={apis}
            saveButtonName="저장"
            updateSuccessTitle="성공"
            updateSuccessMessage="수정하였습니다."
            urlAfterUpdate={() => null}
            noCardWrap
            footerProps={{ buttonStyle: { borderRadius: 9999 } }}
          >
            <ODEntityLabeled name="일련번호" label="일련번호">
              <ODEntityRaw
                name="userId"
                keyPath="userId"
                render={({ value }) => {
                  return <div style={{ marginTop: 7 }}>{value ?? '없음'}</div>
                }}
              />
            </ODEntityLabeled>
            <ODEntityLabeled name="이메일" label="이메일">
              <ODEntityRaw
                name="email"
                keyPath="email"
                render={({ value }) => {
                  return <span>{value}</span>
                }}
              />
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="name"
              label="이름"
              name="name"
              placeholder="이름을 입력해주세요."
              inputType="text"
            />
            <ODEntityLabeled name="가입일" label="가입일">
              <ODEntityRaw
                name="registeredAt"
                keyPath="registeredAt"
                render={({ value }) => {
                  return <div style={{ marginTop: 7 }}>{value ? Utils.formatDate(value) : '없음'}</div>
                }}
              />
            </ODEntityLabeled>
            <ODEntityLabeled name="마지막접속" label="마지막접속">
              <ODEntityRaw
                name="lastAccessTime"
                keyPath="lastAccessTime"
                render={({ value }) => {
                  return <div style={{ marginTop: 7 }}>{value ? Utils.formatDate(value) : '없음'}</div>
                }}
              />
            </ODEntityLabeled>
            <ODEntityLabeled name="마지막접속" label="마지막접속">
              <ODButton theme={ODButtonTheme.Primary} onClick={() => setShowPasswordModal(true)}>
                비밀번호 변경하기
              </ODButton>
            </ODEntityLabeled>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <hr />
            </div>
          </ODEntity>
        </CardBody>
      </Card>
      {showPasswordModal && (
        <ODModal isOpen toggle={() => setShowPasswordModal(false)} size={ODModalSize.Normal}>
          <BlockingLoadBox show={isChangingPassword} message="비밀번호 변경중입니다." />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ODModalHeader
              title="비밀번호 변경하기"
              onClose={() => setShowPasswordModal(false)}
              headerBottomPadding={30}
            >
              <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 60 }} />
            </ODModalHeader>
            <ODModalBody>
              <Row style={{ marginBottom: 20 }}>
                <Col md={4}>예전 비밀번호</Col>
                <Col md={8}>
                  <Input onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} type="password" />
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col md={4}>신규 비밀번호</Col>
                <Col md={8}>
                  <Input onChange={(e) => setNewPassword(e.target.value)} value={newPassword} type="password" />
                </Col>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Col md={4}>신규 비밀번호 확인</Col>
                <Col md={8}>
                  <Input
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    value={newPasswordConfirm}
                    type="password"
                  />
                </Col>
              </Row>
            </ODModalBody>
            <ODModalFooter>
              <ODButton style={{ width: '100%' }} theme={ODButtonTheme.Primary} onClick={() => handlePWChange()}>
                변경하기
              </ODButton>
            </ODModalFooter>
          </div>
        </ODModal>
      )}
    </Container>
  )
}
