import React from 'react'
import Select from 'react-select'

export type IChapterCommentTypeSelectFilterType = string | null

interface IChapterCommentTypeSelectProps {
  value: IChapterCommentTypeSelectFilterType
  onChange: (value: IChapterCommentTypeSelectFilterType) => void
}

const SELECT_OPTIONS = [
  { value: null, label: '모든 타입' },
  { value: 'Text', label: '일반' },
  { value: 'Statistics', label: '통계' },
  { value: 'UserInputChoice', label: '사용자 입력' },
]

export const ChapterCommentTypeSelect: React.FC<
  IChapterCommentTypeSelectProps
> = (props) => {
  const { value, onChange } = props
  return (
    <Select
      placeholder="댓글"
      value={SELECT_OPTIONS.find((v) => v.value === value)}
      // @ts-ignore
      onChange={(v) => onChange(v.value)}
      options={SELECT_OPTIONS}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
