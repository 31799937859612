"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbMessageTarot = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const DOSTHbBase_1 = require("../../../DOSTHbBase");
const IDOStatement_1 = require("../../../IDOStatement");
const DOHbMessageNext_1 = require("../next/DOHbMessageNext");
const SelectionFieldWithUI_1 = require("../../../../fields/SelectionFieldWithUI");
const tarotCardcountOptions = [
    { name: '카드 1개 뽑기', value: 1 },
    { name: '카드 2개 뽑기', value: 2 },
    { name: '카드 3개 뽑기', value: 3 },
    { name: '카드 4개 뽑기', value: 4 },
    { name: '카드 5개 뽑기', value: 5 },
    { name: '카드 6개 뽑기', value: 6 },
    { name: '카드 7개 뽑기', value: 7 },
    { name: '카드 10개 뽑기', value: 10 },
    { name: '카드 13개 뽑기', value: 13 },
];
class DOSTHbMessageTarot extends DOSTHbBase_1.DOSTHbBase {
    constructor(data, block, uniqueId) {
        var _a;
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbUserTarot,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.HbMessageTarot;
        this.hasModalForEdit = true;
        this.messageNext = null;
        this.generateMessageNext((_a = this.st.data.linkBlocks) !== null && _a !== void 0 ? _a : []);
        this.tarotOptionsFields = new SelectionFieldWithUI_1.SelectionFieldWithUI('tarotOption', () => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.data.pickCount) !== null && _a !== void 0 ? _a : 1; }, () => null, {
            selection: tarotCardcountOptions,
            options: {
                noLabel: true,
                required: true,
            },
        });
        (0, mobx_1.makeObservable)(this, {
            messageNext: mobx_1.observable,
            tarotOptionsFields: mobx_1.observable,
            flowChartNodesOfOtherGroupBlock: mobx_1.computed,
        });
    }
    updateData(data) {
        var _a, _b;
        super.updateData(data);
        if ((_a = data.data) === null || _a === void 0 ? void 0 : _a.linkBlocks) {
            this.generateMessageNext((_b = data.data) === null || _b === void 0 ? void 0 : _b.linkBlocks);
        }
    }
    cancelEditing() {
        var _a, _b;
        super.cancelEditing();
        if ((_a = this.st.data) === null || _a === void 0 ? void 0 : _a.linkBlocks) {
            this.generateMessageNext((_b = this.st.data) === null || _b === void 0 ? void 0 : _b.linkBlocks);
        }
    }
    get isValidAll() {
        return true;
    }
    getDataForSubmit() {
        var _a, _b, _c, _d, _e;
        if (!this.isFirstCreated) {
            return {
                type: 'tarot',
            };
        }
        return {
            type: 'tarot',
            pickCount: (_b = (_a = this.tarotOptionsFields) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 1,
            linkBlocks: ((_d = (_c = this.messageNext) === null || _c === void 0 ? void 0 : _c.getDataForSubmit().linkBlocks) !== null && _d !== void 0 ? _d : []).length > 0
                ? (_e = this.messageNext) === null || _e === void 0 ? void 0 : _e.getDataForSubmit().linkBlocks
                : undefined,
        };
    }
    //
    // flowChart
    //
    get blocksTo() {
        var _a, _b;
        return (_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.blocksTo) !== null && _b !== void 0 ? _b : [];
    }
    get flowChartNodesOfOtherGroupBlock() {
        var _a, _b;
        return (_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.flowChartNodesOfOtherGroupBlock) !== null && _b !== void 0 ? _b : [];
    }
    getEdgeInfoForFlowChartBy(toBlockUniqueId) {
        var _a;
        return (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.getEdgeInfoForFlowChartBy(toBlockUniqueId);
    }
    generateMessageNext(linkBlocks) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.messageNext = new DOHbMessageNext_1.DOHbMessageNext(this, { linkBlocks }, true);
            (_b = (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.reLayoutFlowChartElements) === null || _b === void 0 ? void 0 : _b.call(_a);
        });
    }
}
exports.DOSTHbMessageTarot = DOSTHbMessageTarot;
