"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBannerEditor = void 0;
const core_1 = require("@storyplay/core");
const moment = require("moment");
const store_1 = require("../../store");
const EntityEditor_1 = require("../../store/entity/EntityEditor");
const DateWithNullableField_1 = require("../../store/entity/fields/DateWithNullableField");
const DBFileFieldWithUI_1 = require("../../store/entity/fields/DBFileFieldWithUI");
const SelectionFieldWithUI_1 = require("../../store/entity/fields/SelectionFieldWithUI");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
const WEB_LINK_TYPE_OPTIONS = [
    core_1.GQLBANNER_LINK_TYPE.WebCurrentWindowLink,
    core_1.GQLBANNER_LINK_TYPE.WebNewWindowLink,
    core_1.GQLBANNER_LINK_TYPE.DeepLink,
    null,
].map(item => ({
    name: !!item
        ? core_1.BANNER_LINK_TYPE_TRANSLATED[item]
        : trans('legacy_DOBannerEditor_No_link'),
    value: item,
}));
const APP_LINK_TYPE_OPTIONS = [
    ...Object.values(core_1.GQLBANNER_LINK_TYPE).filter(item => ![
        core_1.GQLBANNER_LINK_TYPE.WebCurrentWindowLink,
        core_1.GQLBANNER_LINK_TYPE.WebNewWindowLink,
    ].includes(item)),
    null,
].map(item => ({
    name: !!item
        ? core_1.BANNER_LINK_TYPE_TRANSLATED[item]
        : trans('legacy_DOBannerEditor_No_link'),
    value: item,
}));
const getOptionNameOfStoryIdSelection = (story) => {
    var _a;
    return `${story.storyId} : ${story.name} (${core_1.STORY_PLAY_TYPE_TRANSLATED[(_a = story.playType) !== null && _a !== void 0 ? _a : core_1.GQLSTORY_PLAY_TYPE.Interactive]})`;
};
class DOBannerEditor extends EntityEditor_1.EntityEditor {
    constructor(banner, location) {
        super(banner);
        this.tempOptionForSearchingStory = null;
        if (!!banner.storyByStoryId) {
            this.tempOptionForSearchingStory = {
                name: getOptionNameOfStoryIdSelection(banner.storyByStoryId),
                value: banner.storyByStoryId.storyId,
            };
        }
        const stringFields = [
            {
                key: 'title',
                options: {
                    label: trans('legacy_convert_title'),
                    placeholder: trans('legacy_DOBannerEditor_Enter_title_max_15_chars'),
                    options: { noLabel: true, maxLength: 15 },
                },
            },
            {
                key: 'content',
                options: {
                    label: trans('legacy_DOBannerEditor_main_text'),
                    placeholder: trans('legacy_DOBannerEditor_Enter_text_max_25_chars'),
                    options: { noLabel: true, maxLength: 25 },
                },
            },
            {
                key: 'link',
                options: {
                    label: 'link',
                    placeholder: trans('legacy_DOBannerEditor_Enter_URL'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'iOSFallbackLink',
                options: {
                    label: trans('legacy_DOBannerEditor_iOS_connection_link'),
                    placeholder: trans('legacy_DOBannerEditor_Enter_URL_for_failed_deep_link'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'aOSFallbackLink',
                options: {
                    label: trans('legacy_DOBannerEditor_AOS_connection_link'),
                    placeholder: trans('legacy_DOBannerEditor_Enter_URL_for_failed_deep_link'),
                    options: { noLabel: true },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new store_1.TextFieldWithUI(fld.key, () => { var _a; return (_a = banner[fld.key]) !== null && _a !== void 0 ? _a : ''; }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        const fileFields = [
            {
                key: 'bannerImageFile',
                options: {
                    label: trans('legacy_SPABannerList_image'),
                    options: { showLabel: false },
                },
            },
        ];
        fileFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new DBFileFieldWithUI_1.DBFileFieldWithUI(`${fld.key}`, () => {
                const link = banner[`${fld.key}Link`];
                return { link };
            }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        const selectionFields = [
            {
                key: 'langCode',
                options: {
                    label: trans('legacy_DOBannerEditor_Exposed_country'),
                    selection: Object.values(core_1.GQLLANGUAGE_CODE).map(item => ({
                        name: core_1.LANGUAGE_CODE_TRANSLATED[item],
                        value: item,
                    })),
                    options: {
                        onChangeBeforeSubmit: () => {
                            if (this.typeField.value === core_1.GQLBANNER_TYPE.Story) {
                                this.storyIdField.input.onChange(null);
                            }
                        },
                    },
                },
            },
            {
                key: 'type',
                options: {
                    label: trans('legacy_DOBannerEditor_Registration_method'),
                    selection: Object.values(core_1.GQLBANNER_TYPE).map(item => ({
                        name: core_1.BANNER_TYPE_TRANSLATED[item],
                        value: item,
                    })),
                    options: {
                        onChangeBeforeSubmit: v => {
                            if (this.typeField.defValue === core_1.GQLBANNER_TYPE.Story &&
                                v === core_1.GQLBANNER_TYPE.Custom) {
                                this.bannerImageFileField.input.onRemove();
                                this.linkTypeField.input.onChange(null);
                                this.linkField.input.onChange('');
                                this.iOSFallbackLinkField.input.onChange('');
                                this.aOSFallbackLinkField.input.onChange('');
                                this.titleField.input.onChange('');
                                this.contentField.input.onChange('');
                                return;
                            }
                            if (this.typeField.defValue === core_1.GQLBANNER_TYPE.Custom &&
                                v === core_1.GQLBANNER_TYPE.Story) {
                                this.storyIdField.input.onChange(null);
                            }
                        },
                    },
                },
            },
            {
                key: 'targetClient',
                options: {
                    label: trans('legacy_DOBannerEditor_Exposure_location'),
                    selection: Object.values(core_1.GQLCLIENT).map(item => ({
                        name: core_1.CLIENT_TRANSLATED[item],
                        value: item,
                    })),
                    options: {
                        onChangeBeforeSubmit: v => {
                            this.linkTypeField.input.onChangeOptions(v === core_1.GQLCLIENT.App
                                ? APP_LINK_TYPE_OPTIONS
                                : WEB_LINK_TYPE_OPTIONS);
                            this.linkTypeField.input.onChange(v === core_1.GQLCLIENT.App
                                ? APP_LINK_TYPE_OPTIONS[0].value
                                : WEB_LINK_TYPE_OPTIONS[0].value);
                        },
                    },
                },
            },
            {
                key: 'location',
                options: {
                    label: 'location',
                    selection: Object.values(core_1.GQLBANNER_LOCATION).map(item => ({
                        name: core_1.BANNER_LOCATION_TRANSLATED[item],
                        value: item,
                    })),
                },
            },
            {
                key: 'isShow',
                options: {
                    label: trans('legacy_DOBannerEditor_Exposure_status'),
                    selection: [true, false].map(item => ({
                        name: String(item),
                        value: item,
                    })),
                },
            },
            {
                key: 'linkType',
                options: {
                    label: trans('legacy_DOBannerEditor_Connection_link'),
                    selection: banner.targetClient === core_1.GQLCLIENT.Web
                        ? WEB_LINK_TYPE_OPTIONS
                        : banner.targetClient === core_1.GQLCLIENT.App
                            ? APP_LINK_TYPE_OPTIONS
                            : [],
                },
            },
            {
                key: 'storyId',
                options: {
                    label: trans('legacy_DOBannerEditor_Select_work'),
                    selection: [],
                    options: {
                        placeholder: trans('legacy_DOBannerEditor_Search_work_name'),
                        onChangeBeforeSubmit: v => {
                            const find = this.storyIdField.input.options.find(option => option.value === v);
                            if (!!find) {
                                this.tempOptionForSearchingStory = find;
                            }
                        },
                        loadOptionsByInputValue: async (v) => {
                            try {
                                if (!v) {
                                    if (!!this.tempOptionForSearchingStory) {
                                        return [{ ...this.tempOptionForSearchingStory }];
                                    }
                                    return [];
                                }
                                const res = await this._target.rootStore.di.server.listStoryByAdmin({
                                    page: 1,
                                    pageSize: 30,
                                    filter: !!v ? v : undefined,
                                    playType: this.locationField.value === core_1.GQLBANNER_LOCATION.Home
                                        ? null
                                        : this.locationField.value ===
                                            core_1.GQLBANNER_LOCATION.Interactive
                                            ? core_1.GQLSTORY_PLAY_TYPE.Interactive
                                            : core_1.GQLSTORY_PLAY_TYPE.EPUB,
                                    langCode: this.langCodeField.input
                                        .value,
                                });
                                const list = res.list.map(item => ({
                                    name: getOptionNameOfStoryIdSelection(item),
                                    value: item.storyId,
                                }));
                                if (!!this.tempOptionForSearchingStory &&
                                    !list.find(item => { var _a; return item.value === ((_a = this.tempOptionForSearchingStory) === null || _a === void 0 ? void 0 : _a.value); })) {
                                    list.push({
                                        ...this.tempOptionForSearchingStory,
                                    });
                                }
                                return list;
                            }
                            catch (ex) {
                                this._target.rootStore.showError(ex);
                                return [];
                            }
                        },
                    },
                },
            },
        ];
        selectionFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new SelectionFieldWithUI_1.SelectionFieldWithUI(fld.key, () => banner[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        if (!this.isEditing) {
            this.langCodeField.input.onChange(core_1.GQLLANGUAGE_CODE.ko);
            this.typeField.input.onChange(core_1.GQLBANNER_TYPE.Story);
            this.targetClientField.input.onChange(core_1.GQLCLIENT.App);
            this.isShowField.onChange(false);
            if (!!location) {
                this.locationField.onChange(location);
            }
        }
        this.willOpenAtField = new DateWithNullableField_1.DateWithNullableField('willOpenAt', () => {
            var _a;
            return (_a = this._target.willOpenAt) !== null && _a !== void 0 ? _a : null;
        }, () => null);
        this.willCloseAtField = new DateWithNullableField_1.DateWithNullableField('willCloseAt', () => {
            var _a;
            return (_a = this._target.willCloseAt) !== null && _a !== void 0 ? _a : null;
        }, () => null);
    }
    get isEditing() {
        return this._target.serverId !== -1;
    }
    async submitChanges(onError) {
        if (!this.langCodeField.value) {
            this._target.rootStore.showError(trans('legacy_DOBannerEditor_Enter_exposed_country'));
            return false;
        }
        if (!this.typeField.value) {
            this._target.rootStore.showError(trans('legacy_DOBannerEditor_Select_banner_type'));
            return false;
        }
        if (!this.targetClientField.value) {
            this._target.rootStore.showError(trans('legacy_DOBannerEditor_Select_exposure_location'));
            return false;
        }
        if (this.typeField.value === core_1.GQLBANNER_TYPE.Story) {
            if (!this.storyIdField.value) {
                this._target.rootStore.showError(trans('legacy_DOBannerEditor_Select_a_work'));
                return false;
            }
        }
        if (this.typeField.value === core_1.GQLBANNER_TYPE.Custom) {
            if (!this.bannerImageFileField.value) {
                this._target.rootStore.showError(trans('legacy_DOBannerEditor_Upload_banner_image'));
                return false;
            }
            if (!!this.linkTypeField.value && !this.linkField.value) {
                this._target.rootStore.showError(trans('legacy_DOBannerEditor_Enter_connection_link'));
                return false;
            }
            if (this.targetClientField.value === core_1.GQLCLIENT.Web &&
                this.linkTypeField.value === core_1.GQLBANNER_LINK_TYPE.DeepLink) {
                if (!this.iOSFallbackLinkField.value ||
                    !this.aOSFallbackLinkField.value) {
                    this._target.rootStore.showError(trans('legacy_DOBannerEditor_Enter_deep_link_fallback'));
                    return false;
                }
            }
        }
        if (!this.willOpenAtField.value) {
            this._target.rootStore.showError(trans('legacy_DOBannerEditor_Enter_exposure_period'));
            return false;
        }
        if (moment(this.willCloseAtField.value).isBefore(this.willOpenAtField.value)) {
            this._target.rootStore.showError(trans('legacy_DOBannerEditor_End_date_earlier_than_start'));
            return false;
        }
        return super.submitChanges(onError);
    }
}
exports.DOBannerEditor = DOBannerEditor;
