import { ODListableOption } from '@odc/od-react-belt'
import { GQLStoryProperty } from '../../@types/server'

export const StoryPropertyPrimaryKey = 'propId'

// modifiable fields only.
export interface ICTStoryProperty extends Omit<GQLStoryProperty, 'propId'> {
  propId: number | undefined
}

export interface IStoryPropertyListableOption extends ODListableOption {
  filter: string
  storyId: number | null
}
