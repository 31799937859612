import {
  ODButton,
  ODButtonTheme,
  ODModal,
  ODModalBody,
  ODModalFooter,
  ODModalHeader,
  ODModalSize,
} from '@odc/od-react-belt'
import React from 'react'
import { Col, Input, Row } from 'reactstrap'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { Utils } from '../../utils/utils'

interface IUpsertPlayerClassModalProps {
  storyId: number
  playerClassId?: number
  onClose: () => void
  onConfirm: () => void
}

export const UpsertPlayerClassModal: React.FC<IUpsertPlayerClassModalProps> = (
  props
) => {
  const { storyId, playerClassId, onClose, onConfirm } = props
  const { createPlayerClass, updatePlayerClass, getPlayerClass } =
    useStoryPlayAPIs()
  const [name, setName] = React.useState('')

  React.useEffect(() => {
    if (playerClassId) {
      getPlayerClass({ id: playerClassId }).then((playerClass) => {
        setName(playerClass.name)
      })
    }
  }, [getPlayerClass, playerClassId])

  const handleConfirm = async () => {
    if (!name) return Utils.showError('이름을 입력해 주세요!')
    try {
      if (playerClassId) {
        await updatePlayerClass({ id: playerClassId, name })
      } else {
        await createPlayerClass({ storyId, name })
      }
      Utils.showSuccess(
        `플레이어 클래스가 ${playerClassId ? '수정' : '생성'}되었습니다!`
      )
      onConfirm()
    } catch (e) {
      // @ts-ignore
      Utils.showError(e)
    }
  }

  const buttonTitle = playerClassId ? '수정하기' : '생성하기'

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Normal}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="" onClose={onClose}></ODModalHeader>
        <ODModalBody>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>이름</Col>
            <Col md={8}>
              <Input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
              />
            </Col>
          </Row>
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            fullWidth
            theme={ODButtonTheme.Primary}
            onClick={handleConfirm}
          >
            {buttonTitle}
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
