import React from 'react'
import { Label } from 'reactstrap'
import { ODFBuilder } from '../ODEntityEditorUIBuilder'
import { RS } from '../ODResources/ODResources'
import { useStringResource } from '../ODResources/ResourceProvider'
import { ODF_LABEL, ODF_TYPE } from './index'

type ODFLabelProps = Omit<ODF_LABEL, 'type' | 'size'>

export const ODFLabel: React.FC<ODFLabelProps> = (props) => {
  const { labelKey, text } = props
  const { getString } = useStringResource()

  return (
    <Label htmlFor={`${labelKey}-input`} style={{ paddingTop: 7 }}>
      {getString(text)}
    </Label>
  )
}

export function createODFLabel(key: string, { text = RS.Undefined, labelKey = '', size = 2 }: Omit<ODF_LABEL, 'type'>) {
  return new ODFBuilder(key, {
    type: ODF_TYPE.LABEL,
    size,
    text,
    labelKey,
  })
}
