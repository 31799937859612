"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertChapterToBook = exports.ScriptParseMessageType = void 0;
const core_1 = require("@storyplay/core");
const scripter_1 = require("../../../../scripter/scripter");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
var ScriptParseMessageType;
(function (ScriptParseMessageType) {
    ScriptParseMessageType["Info"] = "Info";
    ScriptParseMessageType["Warning"] = "Warning";
    ScriptParseMessageType["Error"] = "Error";
})(ScriptParseMessageType = exports.ScriptParseMessageType || (exports.ScriptParseMessageType = {}));
function convertChapterToBook(chapter, runScripterInDumbMode = false) {
    const rs = chapter.store.rootStore;
    const scripter = new scripter_1.Scripter(true, runScripterInDumbMode);
    scripter.clearEnsure();
    // clearEnsure()
    // console.log(gapi.client.sheets)
    let finalDataToRet = null;
    let book = null;
    const logs = [];
    try {
        const colorMap = rs.messageColorMap;
        logs.push({
            type: ScriptParseMessageType.Info,
            message: trans('legacy_convertChapterToBook_loading_chapter', {
                value: chapter.name,
            }),
        });
        const lines = chapter.generateLinesWithStartingBlock(); // GMBAEK
        const sheetLikeData = lines.map(l => l.columns.slice(1));
        scripter.loadGoogleSheet('', // 사용하지 않고 있음.
        '', // 사용하지 않고 있음.
        chapter.name, sheetLikeData, colorMap);
        const finalData = scripter.finish();
        // setFinalData(finalData)
        book = JSON.parse(finalData);
        // console.log(80, `generated book data : `, { finalData })
        // setBook(book)
        finalDataToRet = finalData;
        // scripter.loadGoogleSheet(spreadsheetId,)
    }
    catch (ex) {
        logs.push({
            type: ScriptParseMessageType.Error,
            message: trans('legacy_convertChapterToBook_scripter_error', {
                value: ex.message,
            }),
        });
        return {
            ex,
            logs,
            json: null,
            book: null,
        };
    }
    // const chaptersCustomId = Object.keys(book!.chapters)
    //   .map(c => `[${book!.chapters[c].name}] => [${book!.chapters[c].customId}]`)
    //   .join(', ')
    //
    // setLogs(arr => [
    //   ...arr,
    //   {
    //     type: SCRIPT_PARSE_MESSAGE_TYPE.Info,
    //     message: `챕터 커스텀 아이디 매핑 정보 => \n${chaptersCustomId}`,
    //   },
    // ])
    // setLogs(arr => [
    //   ...arr,
    //   { type: SCRIPT_PARSE_MESSAGE_TYPE.Info, message: '완료했습니다.' },
    // ])
    return { json: finalDataToRet, book, logs, ex: null };
}
exports.convertChapterToBook = convertChapterToBook;
