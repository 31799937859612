import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLDirectAds } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IDirectAdsListableOption } from '../DirectAdsCommon'

export function useDirectAdsListDataLoader() {
  const { listDirectAds } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IDirectAdsListableOption
    ): Promise<ODListableResponseType<GQLDirectAds>> {
      const r = await listDirectAds({
        page,
        pageSize: 100,
        ...options,
      })
      return r as ODListableResponseType<GQLDirectAds>
    },
    [listDirectAds]
  )
}
