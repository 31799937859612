import {
  ODEntityInput,
  ODEntityLabeled,
  useCounter,
  useODMutation,
  useODQuery,
} from '@odc/od-react-belt'
import {
  GQLHomeSection,
  GQLHomeSectionCreationInput,
  GQLHomeSectionUpdateInput,
  GQLHOME_SECTION_TYPE,
  GQLOkResponse,
  GQLSingleIDInput,
} from '@storyplay/common'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import * as Yup from 'yup'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { SPFormToggleButton } from '../../../components/SPFormToggleButton'
import {
  GQL_CREATE_HOME_SECTION,
  GQL_DELETE_HOME_SECTION,
  GQL_GET_HOME_SECTION,
  GQL_UPDATE_HOME_SECTION,
} from '../../../hooks/gqls_admin'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'
import { HomeSectionStoryEditPage } from './HomeSectionStoryEditPage'

interface IHomeSectionEditPageProps {
  homeSectionId: number | null
  type: string
}

type Entity = GQLHomeSection

function getValidationSchema(values: any) {
  return Yup.object().shape({})
}

export const HomeSectionEditPage: React.FC<IHomeSectionEditPageProps> = (
  props
) => {
  const { homeSectionId } = props
  const innerRef = React.useRef(null)
  const apiCreate = useODMutation<
    Partial<GQLHomeSectionCreationInput>,
    Partial<Entity>
  >(GQL_CREATE_HOME_SECTION)
  const apiUpdate = useODMutation<
    Partial<GQLHomeSectionUpdateInput>,
    Partial<Entity>
  >(GQL_UPDATE_HOME_SECTION)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET_HOME_SECTION)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DELETE_HOME_SECTION
  )

  const [token] = useCounter()
  const isCreating = !homeSectionId
  const isEditing = !isCreating

  const [type] = useQueryParam('type', withDefault(StringParam, null))

  console.log('@@type:', type)
  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLHomeSectionCreationInput | GQLHomeSectionUpdateInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (homeSectionId) {
          return apiGet({ id: homeSectionId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            title: '',
            customData: '',
            key: '',
            type: GQLHOME_SECTION_TYPE.Favorite,
          }
        }

        return {
          title: data.title,
          customData: data.customData,
          key: data.key,
          type: data.type,
        }
      },
      saveClientValueToServer: async (
        data: Partial<GQLHomeSectionCreationInput | GQLHomeSectionUpdateInput>
      ) => {
        if (homeSectionId) {
          const updateData = { ...data } as GQLHomeSectionUpdateInput
          await apiUpdate({
            ...updateData,
            id: homeSectionId,
          })
          Utils.showSuccess('홈 섹션이 수정되었습니다.', 'Success')
        } else {
          const createData = { ...data } as GQLHomeSectionCreationInput
          await apiCreate({
            ...createData,
          })
          Utils.showSuccess('홈 섹션이 생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.HomeSection.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (homeSectionId) {
          await apiRemove({ id: homeSectionId })
          Utils.showSuccess('홈 섹션이 삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.HomeSection.List
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [homeSectionId, apiGet, apiUpdate, apiCreate, token, apiRemove]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<GQLHomeSectionCreationInput | GQLHomeSectionUpdateInput>
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<GQLHomeSectionCreationInput | GQLHomeSectionUpdateInput>
    >(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          Partial<GQLHomeSectionCreationInput | GQLHomeSectionUpdateInput>
        >(options)
      ),
    [options]
  )

  const title = isCreating ? '홈 섹션 생성' : '홈 섹션 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            <hr />
            <ODEntityInput
              keyPath="title"
              name="title"
              label="타이틀"
              placeholder="섹션 타이틀을 입력하세요"
              inputType="text"
            />
            <ODEntityInput
              keyPath="key"
              name="key"
              label="트랙킹 key"
              placeholder="이벤트 트랙킹에 사용할 key를 입력하세요"
              inputType="text"
            />
            <ODEntityLabeled label="섹션 타입" name="섹션 타입">
              <ToggleButtonWrapper>
                <SPFormToggleButton
                  name="type"
                  keyPath="type"
                  value={GQLHOME_SECTION_TYPE.Favorite}
                >
                  내가 좋아한 작품
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="type"
                  keyPath="type"
                  value={GQLHOME_SECTION_TYPE.Genre}
                >
                  장르
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="type"
                  keyPath="type"
                  value={GQLHOME_SECTION_TYPE.UserGeneration}
                >
                  세대별 작품
                </SPFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="customData"
              name="customData"
              label="커스텀 데이터"
              placeholder="개발자가 활용 가능한 커스텀 데이터"
              inputType="text"
              // inputProps={{ rows: 10 }}
            />
            <hr />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={homeSectionId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
      {isEditing && type !== GQLHOME_SECTION_TYPE.Favorite ? (
        <HomeSectionStoryEditPage
          homeSectionId={homeSectionId!}
        ></HomeSectionStoryEditPage>
      ) : undefined}
    </>
  )
}
