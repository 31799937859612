import {
  ODEntityInput,
  ODEntityLabeled,
  useCounter,
  useODMutation,
  useODQuery,
} from '@odc/od-react-belt'
import {
  GQLRecommendationForFirstUser,
  GQLRecommendationForFirstUserUpdateInput,
} from '@storyplay/core'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import { GQLOkResponse, GQLSingleIDInput } from '../../../@types/server'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import {
  GQL_DELETE_RECOMMENDATION_FOR_FIRST_USER,
  GQL_GET_RECOMMENDATION_FOR_FIRST_USER,
  GQL_UPDATE_RECOMMENDATION_FOR_FIRST_USER,
} from '../../../hooks/gqls_admin'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IRecommendationForFirstUserEditPageProps {
  rfuId: number | null
}

type Entity = GQLRecommendationForFirstUser

function getValidationSchema(values: any) {
  return Yup.object().shape({})
}

export const RecommendationForFirstUserEditPage: React.FC<
  IRecommendationForFirstUserEditPageProps
> = (props) => {
  const { rfuId } = props
  const innerRef = React.useRef(null)
  const apiUpdate = useODMutation<
    Partial<GQLRecommendationForFirstUserUpdateInput>,
    Partial<Entity>
  >(GQL_UPDATE_RECOMMENDATION_FOR_FIRST_USER)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(
    GQL_GET_RECOMMENDATION_FOR_FIRST_USER
  )
  const apiDelete = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DELETE_RECOMMENDATION_FOR_FIRST_USER
  )

  const [token] = useCounter()
  const isCreating = !rfuId

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLRecommendationForFirstUserUpdateInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (rfuId) {
          return apiGet({ id: rfuId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            storyId: 0,
            story: null,
            isHidden: true,
            showOrder: 1000,
          }
        }

        return {
          storyId: data.storyId,
          story: data.story,
          isHidden: data.isHidden,
          showOrder: data.showOrder,
        }
      },
      saveClientValueToServer: async (
        data: Partial<GQLRecommendationForFirstUserUpdateInput>
      ) => {
        if (rfuId) {
          await apiUpdate({
            id: rfuId,
            isHidden: data.isHidden,
            showOrder: data.showOrder,
            storyId: data.storyId,
          })
          Utils.showSuccess('수정되었습니다.', 'Success')
        }
        return SiteUrls.Admin.RecommendationForFirstUser.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (rfuId) {
          await apiDelete({ id: rfuId })
          Utils.showSuccess('삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.RecommendationForFirstUser.List
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [rfuId, apiGet, apiUpdate, token, apiDelete]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<GQLRecommendationForFirstUserUpdateInput>
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<GQLRecommendationForFirstUserUpdateInput>
    >(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          Partial<GQLRecommendationForFirstUserUpdateInput>
        >(options)
      ),
    [options]
  )

  const title = '첫방문 유저 추천 작품 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            <hr />
            <ODEntityInput
              keyPath="story.name"
              label="작품명"
              name="story.name"
              placeholder="이름"
              inputType="text"
              inputProps={{ disabled: true }}
            />
            <ODEntityLabeled label="숨김?" name="isHidden">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isHidden_true"
                  name="isHidden"
                  keyPath="isHidden"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isHidden_false"
                  name="isHidden"
                  keyPath="isHidden"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="showOrder"
              label="노출 우선순위"
              name="showOrder"
              placeholder="노출 우선순위"
              inputType="number"
            />
            <hr />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={rfuId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
