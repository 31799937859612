import { ReactNodeLike } from 'prop-types'
import React from 'react'
import { Col, FormGroup, Label } from 'reactstrap'

type ODEntityLabeledProps = {
  name: string
  label: string
  children?: ReactNodeLike
  labelMDSize?: number
}

export const ODEntityLabeled: React.FC<ODEntityLabeledProps> = (props) => {
  const { name, label, children, labelMDSize = 3 } = props
  return (
    <FormGroup row>
      <Col md={labelMDSize}>
        <Label htmlFor={`${name}-input`} style={{ paddingTop: 7 }}>
          {label}
        </Label>
      </Col>
      <Col xs="12" md={12 - labelMDSize}>
        {children}
      </Col>
    </FormGroup>
  )
}
