"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTToast = exports.TOAST_OPTION = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const shortcutUtils_1 = require("../../../utils/shortcutUtils");
const fields_1 = require("../../fields");
const SelectionFieldWithUI_1 = require("../../fields/SelectionFieldWithUI");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
var TOAST_OPTION;
(function (TOAST_OPTION) {
    TOAST_OPTION[TOAST_OPTION["NoIcon"] = 0] = "NoIcon";
    TOAST_OPTION[TOAST_OPTION["Icon1"] = 1] = "Icon1";
    TOAST_OPTION[TOAST_OPTION["Icon2"] = 2] = "Icon2";
})(TOAST_OPTION = exports.TOAST_OPTION || (exports.TOAST_OPTION = {}));
const TOAST_OPTION_TO_SCRIPT = {
    [TOAST_OPTION.NoIcon]: '아이콘없음',
    [TOAST_OPTION.Icon1]: '아이콘1',
    [TOAST_OPTION.Icon2]: '아이콘2',
};
class DOSTToast extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.Toast,
            toastOption: TOAST_OPTION.NoIcon,
            message: '',
            background: '',
        }, block, uniqueId);
        this.hasModalForEdit = true;
        this.messageField = new fields_1.TextFieldWithUI('message', () => this.st.message, message => (0, mobx_1.runInAction)(() => (this.st.message = message)), {
            placeholder: trans('legacy_DOSTToast_enter_toast_text'),
            inputType: 'textarea',
        });
        this.messageField.input.setAutoFocus();
        this.toastOptionField = new SelectionFieldWithUI_1.SelectionFieldWithUI('toastOption', () => this.st.toastOption, v => (0, mobx_1.runInAction)(() => (this.st.toastOption = v)), {
            selection: [
                {
                    name: TOAST_OPTION_TO_SCRIPT[TOAST_OPTION.NoIcon],
                    value: TOAST_OPTION.NoIcon,
                },
                {
                    name: TOAST_OPTION_TO_SCRIPT[TOAST_OPTION.Icon1],
                    value: TOAST_OPTION.Icon1,
                },
                {
                    name: TOAST_OPTION_TO_SCRIPT[TOAST_OPTION.Icon2],
                    value: TOAST_OPTION.Icon2,
                },
            ],
        });
        (0, mobx_1.makeObservable)(this, {
            // messageInEditing: observable,
            // toastOptionInEditing: observable,
            toastOption: mobx_1.computed,
            message: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get toastOption() {
        return this.st.toastOption;
    }
    get message() {
        return this.st.message;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [...super.editorFields, this.messageField, this.toastOptionField];
    }
    generateSheetColumnsAfterBackground() {
        // [아이콘 형태, 대사]
        const result = [
            TOAST_OPTION_TO_SCRIPT[this.toastOption],
            this.message,
            '',
            '',
            '',
            '',
            '',
        ];
        if (!!this.executeConditionOfStatement) {
            result.push(this.executeConditionOfStatement);
        }
        return result;
    }
    setNextOption(jump) {
        const options = [
            TOAST_OPTION.NoIcon,
            TOAST_OPTION.Icon1,
            TOAST_OPTION.Icon2,
        ];
        const optionToSelect = options[(options.findIndex(v => v === this.toastOption) +
            jump +
            options.length) %
            options.length];
        this.helper
            .opFactory()
            .changeField(this, 'toastOption', optionToSelect)
            .submitSingle()
            .catch();
    }
    handleKeyboardShortcut(shortcut, editor) {
        switch (shortcut) {
            case shortcutUtils_1.StudioShortcut.SelectNextCharacter:
            case shortcutUtils_1.StudioShortcut.MoveSelectionLeft:
                this.setNextOption(-1);
                break;
            case shortcutUtils_1.StudioShortcut.SelectPrevCharacter:
            case shortcutUtils_1.StudioShortcut.MoveSelectionRight:
                this.setNextOption(1);
                break;
            default:
                break;
        }
    }
    //
    // IStudioTranslator interface
    //
    async getMessagesToTranslate() {
        return {
            messages: [this.message],
        };
    }
    async applyTranslatedMessages(translated) {
        (0, mobx_1.runInAction)(() => {
            this.st.message = translated.messages[0];
        });
    }
}
exports.DOSTToast = DOSTToast;
