"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Waiter = void 0;
const moment = require("moment");
class Waiter {
    constructor() {
        this.started = new Date();
    }
    async waitMinimum(ms = 1000) {
        const now = moment();
        const started = moment(this.started);
        const elapsed = now.diff(started, 'millisecond');
        const waitFor = ms - elapsed;
        return new Promise(resolve => setTimeout(resolve, waitFor));
    }
    async wait(ms = 1) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
exports.Waiter = Waiter;
