export const NOTICE_FULL_SNAPSHOT = `
  noticeId
  title
  contents
  createdAt
`
const COMMON_IP_SOURCING_FIELDS = ['ipSourcingId', 'name', 'iconImageFileId']

const IP_SOURCING_FULL_SNAPSHOT = `
  ${COMMON_IP_SOURCING_FIELDS}
  iconImageFile {
    link
  }
`

const STORY_WEEKDAY_FULL_SNAPSHOT = `
weekday
`

export const STORY_FULL_SNAPSHOT = `
  storyId
  name
  defaultFirstName
  defaultLastName
  mainCharacterPrompt
  mainCharacterGender
  mainCharacterLabel
  storyEndsAt
  willPublishAt
  script
  publishedAt
  topic
  targetAge
  oneLineDesc
  coverText
  userProps
  propsMigration
  updatedAt
  sectionType
  showOrder
  endingArrivalRateRange
  endingArrivalRateType
  shortDesc
  maxTicket
  isTimeLeapFree
  representedAt
  monthText
  type
  isDummy
  storyDetailInfo
  freeTimeLeapStartAt
  freeTimeLeapEndAt
  purchaseFreeStartAt
  noticeId
  authorName
  isFinished
  isFixMainChrName
  studioPriceSetting
  isAdult
  playType
  refStory {
    name
    storyId
  }
  notice {
    ${NOTICE_FULL_SNAPSHOT}
  }
  ipSourcingId
  ipSourcing {
    ${IP_SOURCING_FULL_SNAPSHOT}
  }
  mainImageFile {
    link
  }
  previewImageFile {
    link
  }
  mainCharacterImageFile {
    link
  }
  introImageFile {
    link
  }
  bannerImageFile {
    link
  }
  wideImageFile {
    link
  }
  teaserVideoFile {
    link
  }
  backgrounds {
    shfId
    name
    file {
      link
    }
  }
  allChapters {
    chapterId
    customId
    chapterIndex
    name
    storyId
    publishedAt
    willPublishAt
    price
    freedAt
    willFreeAt
    isFinal
    isHidden
    isTimeLeapFree
    choices {
      choiceId
      name
      numChoice
      choice1Selected
      choice2Selected
      choice3Selected
      choice4Selected
      choice5Selected
    }
    endings {
      endingId
      customId
      name
      collectionDescription
      isFinal
      imageFile {
        link
      }
    }
  }
  characters {
    chrId
    name
    imageFile {
      link
    }
    main
    color
  }
  isBanner
  onHiatus
  purchased
  purchasableRaw
  price
  originalPrice
  discountRate
  lastPreviewChapterIndex
  genre
  langCode
  languageCode
  finishedAt
  storyWeekdays {
    ${STORY_WEEKDAY_FULL_SNAPSHOT}
  }
`
const COMMON_ONBOARDING_POPUP_FIELDS = [
  'popupId',
  'storyId',
  'chapterId',
  'description',
  'onlyName',
  'type',
  'status',
  'showOrder',
  'characterId',
  'canUploadProfileImage',
  'visibleConditions',
  'canAICharacter',
  'price',
  'maxAdCount',
]
const COMMON_ONBOARDING_POPUP_CHOICE_FIELDS = [
  'popupChoiceId',
  'popupId',
  'imageFileId',
  'choiceProps',
  'content',
  'showOrder',
  'status',
  'playerClassId',
]
const COMMON_USER_SELECTED_CHOICE_FIELDS = [
  'uscId',
  'selectedChoice',
  'leftUserInputText',
  'rightUserInputText',
]
const USER_SELECTED_CHOICE_FULL_SNAPSHOT = `
${COMMON_USER_SELECTED_CHOICE_FIELDS}
choice {
    choice1Content
    choice2Content
    choice3Content
    choice4Content
    choice5Content
}
`
const ONBOARDING_POPUP_CHOICE_FULL_SNAPSHOT = `
${COMMON_ONBOARDING_POPUP_CHOICE_FIELDS}
imageFile {
  link
  fileId
}
popup {
  type
  description
}
`
const ONBOARDING_POPUP_FULL_SNAPSHOT = `
  ${COMMON_ONBOARDING_POPUP_FIELDS}
  popupChoices {
    ${ONBOARDING_POPUP_CHOICE_FULL_SNAPSHOT}
  }
  chapter {
    name
    chapterIndex
  }
  character {
    name
  }
`

const CHAPTER_OPTION_FULL_SNAPSHOT = `
  chapterOptionId
  chapterId
  bannerImageFileId
  bannerImageFile {
    link
  }
  bannerImageConnectUrl
  status
`

const CHAPTER_FULL_SNAPSHOT = `
  chapterId
  customId
  name
  chapterIndex
  storyId
  publishedAt
  willPublishAt
  price
  freedAt
  freedAtRaw
  willFreeAt
  willFreeAtRaw
  isFinal
  adsOn
  isHidden
  isTimeLeapFree
  remainingTimeToFree
  hasNewBadge
  freeDependencyChapterIndex
  freeDependencyPeriod
  chapterOption {
    ${CHAPTER_OPTION_FULL_SNAPSHOT}
  }
  chapterOptionByAdmin {
    ${CHAPTER_OPTION_FULL_SNAPSHOT}
  }
`

const USER_FULL_SNAPSHOT = `
  userId
  name
  email
  lastLoginToken
  lastAccessTime
  unread
  priv
  createdAt
  updatedAt
  isAnonymous
  isPushInfoRequired
  pushEnabledAt
  nightPushEnabledAt
  marketingPushEnabledAt
  currentCoin
  verifiedAt
  registeredAt
  birthDateToken
  canExportStory
  ssoLogins {
    ssoProvider
  }
`

const PLAYER_CLASS_FULL_SNAPSHOT = `
  playerClassId
  name
`

const CHOICE_FROM_PLAYER_CLASS_FULL_SNAPSHOT = `
  choiceFromPlayerClassId
  choiceId
  playerClassId
  selectedChoice
  totalCount
  adminAddCount
  choiceRate
  realChoiceRate
  playerClass {
    ${PLAYER_CLASS_FULL_SNAPSHOT}
  }
  choice {
    choice1PropName
    choice1Content
    choice2PropName
    choice2Content
    choice3PropName
    choice3Content
    choice4PropName
    choice4Content
    choice5PropName
    choice5Content
  }
`

export const GQL_CREATE_STORY = `
mutation createStory($data: StoryPropsInput!) {
  createStory(data: $data) {
    ${STORY_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_STORY = `
mutation updateStory($data: StoryPropsInput!) {
  updateStory(data: $data) {
    ${STORY_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_STORY = `
query getStory($data: SingleIDInput!) {
  getStory(data: $data) {
    ${STORY_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_ALL_CHAPTERS = `
query getStory($data: SingleIDInput!) {
  getStory(data: $data) {
    allChapters {
      chapterId
      chapterIndex
      name
    }
  }
}`

export const GQL_REMOVE_STORY = `
mutation removeStory($data: SingleIDInput!) {
  removeStory(data: $data) {
    ok
  }
}
`

export const GQL_CREATE_CHAPTER_FOR_STUDIO = `
mutation createChapter($data: ChapterCreationInput!) {
  createChapter(data: $data) {
    ${CHAPTER_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_CHAPTER = `
mutation updateChapter($data: ChapterPropsInput!) {
  updateChapter(data: $data) {
    ${CHAPTER_FULL_SNAPSHOT}
  }
}
`

export const GQL_CHANGE_CHAPTER_FREE_SETTING = `
mutation changeChapterFreeSetting($data: ChangeChapterFreeSettingInput!) {
  changeChapterFreeSetting(data: $data) {
    ok
  }
}
`

export const GQL_CHANGE_FREE = `
mutation changeChapterFree($data: ChangeChapterBooleanInput!) {
  changeChapterFree(data: $data) {
    ok
  }
}
`

export const GQL_PUBLISH_CHAPTER = `
mutation publishChapter($data: SingleIDInput!) {
  publishChapter(data: $data) {
    ok
  }
}
`

export const GQL_LIST_CHAPTER = `
query listChapter($data: ListableChapterInput!) {
  listChapter(data: $data) {
    list {
      ${CHAPTER_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const CHR_FULL_SNAPSHOT = `
  chrId
  name
  main
  imageFile {
    link
  }
  color
  defaultFirstName
  defaultLastName
  displayName
  gender
  prompt
  label
`

export const CHOICE_FULL_SNAPSHOT = `
  choiceId
  name
  numChoice
  choiceType
  choice1Selected
  choice1PropName
  choice1AdminAdd
  choice1Rate
  choice1RealRate
  choice1Content
  choice1ImageUrl
  choice2Selected
  choice2PropName
  choice2AdminAdd
  choice2Rate
  choice2RealRate
  choice2Content
  choice2ImageUrl
  choice3Selected
  choice3PropName
  choice3AdminAdd
  choice3Rate
  choice3RealRate
  choice3Content
  choice3ImageUrl
  choice4Selected
  choice4PropName
  choice4AdminAdd
  choice4Rate
  choice4RealRate
  choice4Content
  choice4ImageUrl
  choice5Selected
  choice5PropName
  choice5AdminAdd
  choice5Rate
  choice5RealRate
  choice5Content
  choice5ImageUrl
  displayName
  showStatistics
`

export const STORY_HAS_BACKGROUND_FULL_SNAPSHOT = `
  shfId
  name
  file {
    link
  }
`

export const ENDING_FULL_SNAPSHOT = `
  endingId
  customId
  name
  previewName
  collectionDescription
  arrivalRateByAdmin
  dailyChangeRate
  realArrivalRate
  chapter {
    chapterId
    name
  }
  isFinal
  imageFile {
    link
  }
  previewImageFile {
    link
  }
  shareKey
  showOrder
  storyProperties {
    espId
    storyPropId
    storyProp {
      propId
      propName
      maxValue
    }
    customImageFile {
      link
    }
  }
`

export const GQL_GET_CHR = `
query getChr($data: SingleIDInput!) {
  getChr(data: $data) {
    ${CHR_FULL_SNAPSHOT}
  }
}`

export const GQL_UPDATE_CHR = `
mutation updateChr($data: ChrPropsInput!) {
  updateChr(data: $data) {
    ${CHR_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_CHR = `
query listChr($data: ListableChrInput!) {
  listChr(data: $data) {
    list {
      ${CHR_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_CHOICE = `
query listChoice($data: ListableChoiceInput!) {
  listChoice(data: $data) {
    list {
      ${CHOICE_FULL_SNAPSHOT}
      chapter {
        chapterId
        name
      }
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_UPDATE_CHOICE = `
mutation updateChoice($data: UpdateChoiceInput!) {
  updateChoice(data: $data) {
    ${CHOICE_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_CHOICE = `
query getChoice($data: SingleIDInput!) {
  getChoice(data: $data) {
    ${CHOICE_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_BACKGROUND = `
query getStoryHasBackground($data: SingleIDInput!) {
  getStoryHasBackground(data: $data) {
    ${STORY_HAS_BACKGROUND_FULL_SNAPSHOT}
  }
}`

export const GQL_UPDATE_BACKGROUND = `
mutation updateBackground($data: StoryHasBackgroundPropsInput!) {
  updateBackground(data: $data) {
    ${STORY_HAS_BACKGROUND_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_STORY_HAS_BACKGROUND = `
query listStoryHasBackground($data: ListableStoryHasBackgroundInput!) {
  listStoryHasBackground(data: $data) {
    list {
      ${STORY_HAS_BACKGROUND_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_ENDING = `
query getEnding($data: SingleIDInput!) {
  getEnding(data: $data) {
    ${ENDING_FULL_SNAPSHOT}
  }
}`

export const GQL_UPDATE_ENDING = `
mutation updateEnding($data: EndingPropsInput!) {
  updateEnding(data: $data) {
    ${ENDING_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_ENDING = `
query listEnding($data: ListableEndingInput!) {
  listEnding(data: $data) {
    list {
      ${ENDING_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const STORY_PROPERTY_FULL_SNAPSHOT = `
  propId
  propName
  propNameRaw
  displayPropName
  unit
  propType
  maxValue
  imageFile {
    link
  }
  isMain
  showOrder
`

export const ENDING_SHOWS_PROPERTY_FULL_SNAPSHOT = `
  espId
  ending {
    endingId
    name
  }
  storyProp {
    ${STORY_PROPERTY_FULL_SNAPSHOT}
  }
  customImageFile {
    link
  }
`

export const PREVIEW_ENDING_FULL_SNAPSHOT = `
  createdAt
  updatedAt
  previewEndingId
  name
  storyId
  imageFile {
    link
  }
  imageFileId
  showOrder
  isHidden
  rateLevel
`

export const GQL_CREATE_STORY_PROPERTY = `
mutation createStoryPropertyByAdmin($data: StoryPropertyCreationInput!) {
  createStoryPropertyByAdmin(data: $data) {
    ${STORY_PROPERTY_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_STORY_PROPERTY = `
mutation updateStoryPropertyByAdmin($data: StoryPropertyUpdateInput!) {
  updateStoryPropertyByAdmin(data: $data) {
    ${STORY_PROPERTY_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_STORY_PROPERTY = `
query getStoryProperty($data: SingleIDInput!) {
  getStoryProperty(data: $data) {
    ${STORY_PROPERTY_FULL_SNAPSHOT}
  }
}`

export const GQL_REMOVE_STORY_PROPERTY = `
mutation removeStoryPropertyByAdmin($data: SingleIDInput!) {
  removeStoryPropertyByAdmin(data: $data) {
    ok
  }
}
`

export const GQL_LIST_STORY_PROPERTY = `
query listStoryPropertyByAdmin($data: ListableStoryPropertyInput!) {
  listStoryPropertyByAdmin(data: $data) {
    list {
      ${STORY_PROPERTY_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_ENDING_SHOWS_PROPERTY = `
mutation createEndingShowsPropertyByAdmin($data: EndingShowsPropertyCreationInput!) {
  createEndingShowsPropertyByAdmin(data: $data) {
    ${ENDING_SHOWS_PROPERTY_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_ENDING_SHOWS_PROPERTY = `
mutation updateEndingShowsPropertyByAdmin($data: EndingShowsPropertyUpdateInput!) {
  updateEndingShowsPropertyByAdmin(data: $data) {
    ${ENDING_SHOWS_PROPERTY_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_ENDING_SHOWS_PROPERTY = `
query getEndingShowsPropertyByAdmin($data: SingleIDInput!) {
  getEndingShowsPropertyByAdmin(data: $data) {
    ${ENDING_SHOWS_PROPERTY_FULL_SNAPSHOT}
  }
}`

export const GQL_REMOVE_ENDING_SHOWS_PROPERTY = `
mutation removeEndingShowsPropertyByAdmin($data: SingleIDInput!) {
  removeEndingShowsPropertyByAdmin(data: $data) {
    ok
  }
}
`

export const GQL_LIST_ENDING_SHOWS_PROPERTY = `
query listEndingShowsPropertyByAdmin($data: ListableEndingShowsPropertyInput!) {
  listEndingShowsPropertyByAdmin(data: $data) {
    list {
      ${ENDING_SHOWS_PROPERTY_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_REGISTER_USER_BY_ADMIN = `
mutation registerUserByAdmin($data: EmailSignupByAdmin!) {
  registerUserByAdmin(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_USER_BY_ADMIN = `
query getUserByAdmin($data: SingleIDInput!) {
  getUserByAdmin(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_USER_BY_ADMIN = `
mutation updateUserByAdmin($data: UserPropsInputByAdmin!) {
  updateUserByAdmin(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_DEACTIVATE_ACCOUNT_BY_ADMIN = `
mutation deactivateAccountByAdmin($data: SingleIDInput!) {
  deactivateAccountByAdmin(data: $data) {
    ok
  }
}
`

export const GQL_GET_USER_PROFILE = `
query {
  getUser {
    ${USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_USER = `
mutation updateUser($data: UserPropsInput!) {
  updateUser(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_MARK_PASSWORD_AS_CHANGED = `
mutation markPasswordAsChanged {
  markPasswordAsChanged {
    ${USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_PREVIEW_ENDING_BY_ADMIN = `
query listPreviewEndingByAdmin($data: ListablePreviewEndingInput!) {
  listPreviewEndingByAdmin(data: $data) {
    list {
      ${PREVIEW_ENDING_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_UPDATE_PREVIEW_ENDING = `
mutation updatePreviewEnding($data: PreviewEndingPropsInput!) {
  updatePreviewEnding(data: $data) {
    ${PREVIEW_ENDING_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_PREVIEW_ENDING = `
query getPreviewEnding($data: SingleIDInput!) {
  getPreviewEnding(data: $data) {
    ${PREVIEW_ENDING_FULL_SNAPSHOT}
  }
}
`

export const GQL_CREATE_PREVIEW_ENDING = `
mutation createPreviewEnding($data: PreviewEndingPropsInput!) {
  createPreviewEnding(data: $data) {
    ${PREVIEW_ENDING_FULL_SNAPSHOT}
  }
}
`

export const GQL_REMOVE_PREVIEW_ENDING = `
mutation removePreviewEnding($data: SingleIDInput!) {
  removePreviewEnding(data: $data) {
    ok
  }
}
`

export const GQL_GET_USER_METRIC_BY_ID = `
query getUserMetricById($data: SingleIDInput!) {
  getUserMetricById(data: $data) {
    storiesSetAlarm
    storiesPlayed {
      storyId
      storyName
      isWaitingFree
    }
    coinSubsHistory {
      isSubscribing
      lastSubDuration
      numSubStarted
    }
    coinPurchaseHistory {
      numPurchase
      numCoinBought
    }
    onBoardingStory {
      storyId
      storyName
      firstName
      lastName
    }
  }
}`

const USER_REPORT_CHAPTER_COMMENT_FULL_SNAPSHOT = `
  urccId
  reporter {
    userId
    name
  }
  reporterId
  comment {
    ccId
  }
  commentId
  reason {
    title
  }
  reasonId
  customReason
`

const CHAPTER_COMMENT_FULL_SNAPSHOT = `
  ccId
  storyId
  chapterId
  chapter {
    chapterIndex
    name
  }
  authorId
  author {
    name
    priv
  }
  numOfBaseLikes
  numOfLikes
  numOfUserLikes
  numOfNestedComments
  createdAt
  updatedAt
  deletedAt
  contentEditedAt
  parentId
  content
  isSpoiler
  isBestComment
  likedByMe
  isHidden
  deletedByAdmin
  reports {
    ${USER_REPORT_CHAPTER_COMMENT_FULL_SNAPSHOT}
  }
  choiceId
  choice {
    name
  }
  type
  imageFileId
  imageFile {
    link
  }
`

export const GQL_LIST_CHAPTER_COMMENT_BY_ADMIN = `
query listChapterCommentByAdmin($data: ListableChapterCommentInput!) {
  listChapterCommentByAdmin(data: $data) {
    list {
      ${CHAPTER_COMMENT_FULL_SNAPSHOT}
    }
    page
    pageSize
    totalCount
  }
}`

export const GQL_GET_CHAPTER_COMMENT_BY_ADMIN = `
query getChapterCommentByAdmin($data: SingleIDInput!) {
  getChapterCommentByAdmin(data: $data) {
    ${CHAPTER_COMMENT_FULL_SNAPSHOT}
  }
}`

export const GQL_UPDATE_CHAPTER_COMMENT_BY_ADMIN = `
mutation updateChapterCommentByAdmin($data: ChapterCommentUpdateByAdminInput!) {
  updateChapterCommentByAdmin(data: $data) {
    ${CHAPTER_COMMENT_FULL_SNAPSHOT}
  }
}`

export const GQL_CREATE_CHAPTER_COMMENT_BY_ADMIN = `
mutation createChapterCommentByAdmin($data: ChapterCommentCreationByAdminInput!) {
  createChapterCommentByAdmin(data: $data)  {
    ${CHAPTER_COMMENT_FULL_SNAPSHOT}
  }
}
`
export const GQL_DELETE_CHAPTER_COMMENT_BY_ADMIN = `
mutation deleteChapterCommentByAdmin($data: SingleIDInput!) {
  deleteChapterCommentByAdmin(data: $data) {
    ok
  }
}
`

export const GQL_LIST_USER_REPORT_CHAPTER_COMMENT_BY_ADMIN = `
query listChapterCommentReportByAdmin($data: ListableUserReportChapterCommentInput!) {
  listChapterCommentReportByAdmin(data: $data) {
    list {
      ${USER_REPORT_CHAPTER_COMMENT_FULL_SNAPSHOT}
    }
    page
    pageSize
    totalCount
  }
}
`

export const GQL_LIST_USERS = `
query users($data: ListableUserInput!) {
  listUser(data: $data) {
    list {
      ${USER_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const ENDING_RATE_RANGE_FULL_SNAPSHOT = `
  rangeId
  name
  level
  startRate
  endRate
`

export const GQL_LIST_ENDING_RATE_RANGE = `
query listEndingRateRange($data: ListableInput!) {
  listEndingRateRange(data: $data) {
    list {
      ${ENDING_RATE_RANGE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const STORY_ITEM_FULL_SNAPSHOT = `
  storyItemId
  storyId
  name
  desc
  imageFileId
  imageFile {
    link
  }
  isHidden
  createdAt
  updatedAt
`

export const GQL_CREATE_STORY_ITEM = `
mutation createStoryItem($data: StoryItemCreationInput!) {
  createStoryItem(data: $data) {
    ${STORY_ITEM_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_STORY_ITEM = `
query getStoryItem($data: SingleIDInput!) {
  getStoryItem(data: $data) {
    ${STORY_ITEM_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_STORY_ITEM = `
mutation removeStoryItem($data: SingleIDInput!) {
  removeStoryItem(data: $data) {
    ok
  }
}
`

export const GQL_LIST_STORY_ITEM = `
query listStoryItem($data: ListableStoryItemInput!) {
  listStoryItem(data: $data) {
    list {
      ${STORY_ITEM_FULL_SNAPSHOT}
    }
  }
}
`

export const GQL_UPDATE_STORY_ITEM = `
mutation updateStoryItem($data: StoryItemUpdateInput!) {
  updateStoryItem(data: $data) {
    ${STORY_ITEM_FULL_SNAPSHOT}
  }
}
`

const COMMON_ENDING_CARD_FIELDS = [
  'cardId',
  'type',
  'showOrder',
  'characterId',
  'endingId',
  'choiceCount',
  'arrivalRate',
  'rateLevel',
  'endingCollectRate',
]

export const ENDING_CARD_FULL_SNAPSHOT = `
  ${COMMON_ENDING_CARD_FIELDS}
  character {
    ${CHR_FULL_SNAPSHOT} 
  } 
`

export const GQL_CREATE_ENDING_CARD = `
mutation createEndingCard($data: EndingCardCreationInput!) {
  createEndingCard(data: $data) {
    ${ENDING_CARD_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_ENDING_CARD = `
query getEndingCard($data: SingleIDInput!) {
  getEndingCard(data: $data) {
    ${ENDING_CARD_FULL_SNAPSHOT}
  }
}`

export const GQL_DELETE_ENDING_CARD = `
mutation deleteEndingCard($data: SingleIDInput!) {
  deleteEndingCard(data: $data) {
    ok
  }
}
`

export const GQL_UPDATE_ENDING_CARD = `
mutation updateEndingCard($data: EndingCardUpdateInput!) {
  updateEndingCard(data: $data) {
    ${ENDING_CARD_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_ENDING_CARD_BY_ADMIN = `
query listEndingCardByAdmin($data: ListableEndingCardByAdminInput!) {
  listEndingCardByAdmin(data: $data) {
    list {
      ${ENDING_CARD_FULL_SNAPSHOT}
    }
  }
}
`

export const GQL_CREATE_ONBOARDING_POPUP = `
mutation createOnboardingPopup($data: CreateOnboardingPopupInput!) {
  createOnboardingPopup(data: $data) {
    ${ONBOARDING_POPUP_FULL_SNAPSHOT}
  } 
}
`

export const GQL_UPDATE_ONBOARDING_POPUP = `
mutation updateOnboardingPopup($data: UpdateOnboardingPopupInput!) {
  updateOnboardingPopup(data: $data) {
    ${ONBOARDING_POPUP_FULL_SNAPSHOT} 
  }
}
`

export const GQL_DELETE_ONBOARDING_POPUP = `
mutation deleteOnboardingPopup($data: SingleIDInput!) {
  deleteOnboardingPopup(data: $data) {
    ok
  }
}
`

export const GQL_GET_ONBOARDING_POPUP = `
query getOnboardingPopup($data: SingleIDInput!) {
  getOnboardingPopup(data: $data) {
    ${ONBOARDING_POPUP_FULL_SNAPSHOT} 
  }
}
`

export const GQL_LIST_ONBOARDING_POPUP = `
query listOnboardingPopup($data: ListableOnboardingPopupInput!) {
  listOnboardingPopup(data: $data) {
    list {
      ${ONBOARDING_POPUP_FULL_SNAPSHOT} 
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_ONBOARDING_POPUP_CHOICE = `
mutation createOnboardingPopupChoice($data: CreateOnboardingPopupChoiceInput!) {
  createOnboardingPopupChoice(data: $data) {
    ${ONBOARDING_POPUP_CHOICE_FULL_SNAPSHOT}
  } 
}
`

export const GQL_UPDATE_ONBOARDING_POPUP_CHOICE = `
mutation updateOnboardingPopupChoice($data: UpdateOnboardingPopupChoiceInput!) {
  updateOnboardingPopupChoice(data: $data) {
    ${ONBOARDING_POPUP_CHOICE_FULL_SNAPSHOT} 
  }
}
`

export const GQL_DELETE_ONBOARDING_POPUP_CHOICE = `
mutation deleteOnboardingPopupChoice($data: SingleIDInput!) {
  deleteOnboardingPopupChoice(data: $data) {
    ok
  }
}
`

export const GQL_GET_ONBOARDING_POPUP_CHOICE = `
query getOnboardingPopupChoice($data: SingleIDInput!) {
  getOnboardingPopupChoice(data: $data) {
    ${ONBOARDING_POPUP_CHOICE_FULL_SNAPSHOT}
    playerClass {
      name
    } 
  }
}
`

export const GQL_LIST_ONBOARDING_POPUP_CHOICE = `
query listOnboardingPopupChoice($data: ListableOnboardingPopupChoiceInput!) {
  listOnboardingPopupChoice(data: $data) {
    list {
      ${ONBOARDING_POPUP_CHOICE_FULL_SNAPSHOT}
      playerClass {
        name
      } 
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_NOTICE_LIST = `
query listNoticeByAdmin($data: ListableNoticeInput!) {
  listNoticeByAdmin(data: $data) {
    list {
      ${NOTICE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_ADD_COIN_BY_ADMIN = `
mutation addCoinByAdmin($data: AddCoinInput!) {
  addCoinByAdmin(data: $data) {
    ok
  }
}
`

export const COLOR_FULL_SNAPSHOT = `
  colorId
  name
  hex
`

export const GQL_GET_COLOR_LIST = `
query listColor($data: ListableColorInput!) {
  listColor(data: $data) {
    list {
      ${COLOR_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_ADD_COLOR_BY_ADMIN = `
mutation createColor($data: ColorCreationInput!) {
  createColor(data: $data) {
    ${COLOR_FULL_SNAPSHOT}
  }
}
`

export const GQL_APPLY_MGMT_SHEET_TO_STORY = `
mutation applyMgmtSheetToStory($data: ApplyMgmtSheetToStoryInput!) {
  applyMgmtSheetToStory(data: $data) {
    storyId
  }
}
`

export const GQL_GET_IP_SOURCING_LIST = `
query listIpSourcing($data: ListableInput!) {
  listIpSourcing(data: $data) {
    list {
      ${IP_SOURCING_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`
const COMMON_LEDGER_FIELDS = [
  'ledgerId',
  'userId',
  'amount',
  'reason',
  'createdAt',
]
const LEDGER_FULL_SNAPSHOT = `
  ${COMMON_LEDGER_FIELDS}
  user {
    name
  }
`

export const GQL_LIST_LEDGER_BY_ADMIN = `
query listLedgerByAdmin($data: ListableLedgerByAdminInput!) {
  listLedgerByAdmin(data: $data) {
    list {
      ${LEDGER_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const FEATURED_FULL_SNAPSHOT = `
  createdAt
  updatedAt
  featuredId
  storyId
  story {
    name
  }
  coverImageFile {
    link
  }
  isHidden
  link
`

export const GQL_LIST_FEATURED = `
query listFeatured($data: ListableFeaturedInput!) {
  listFeatured(data: $data) {
    list {
      ${FEATURED_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_FEATURED = `
mutation createFeatured($data: FeaturedCreationInput!) {
  createFeatured(data: $data) {
    ${FEATURED_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_FEATURED = `
mutation updateFeatured($data: FeaturedUpdateInput!) {
  updateFeatured(data: $data) {
    ${FEATURED_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_PLAYER_CLASS = `
query getPlayerClass($data: SingleIDInput!) {
  getPlayerClass(data: $data) {
    ${PLAYER_CLASS_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_FEATURED = `
query getFeaturedByAdmin($data: SingleIDInput!) {
  getFeaturedByAdmin(data: $data) {
    ${FEATURED_FULL_SNAPSHOT}
  }
}
`

export const GQL_CREATE_PLAYER_CLASS = `
mutation createPlayerClass($data: PlayerClassCreationInput!) {
  createPlayerClass(data: $data) {
    ${PLAYER_CLASS_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_FEATURED = `
mutation deleteFeatured($data: SingleIDInput!) {
  deleteFeatured(data: $data) {
    ok
  }
}
`

export const GQL_UPDATE_PLAYER_CLASS = `
mutation updatePlayerClass($data: PlayerClassUpdateInput!) {
  updatePlayerClass(data: $data) {
    ${PLAYER_CLASS_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_STORY_FOR_FEATURED = `
query listStoryForFeatured($data: ListableStoryInput!) {
  listStory(data: $data) {
    list {
      storyId
      name
    }
    totalCount
    page
    pageSize
  }
}`

export const HOME_SECTION_FULL_SNAPSHOT = `
  sectionId
  title
  key
  type
  customData
`

export const GQL_LIST_HOME_SECTION = `
query listHomeSectionByAdmin($data: ListableHomeSectionInput!) {
  listHomeSectionByAdmin(data: $data) {
    list {
      ${HOME_SECTION_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_HOME_SECTION = `
mutation createHomeSection($data: HomeSectionCreationInput!) {
  createHomeSection(data: $data) {
    ${HOME_SECTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_HOME_SECTION = `
mutation updateHomeSection($data: HomeSectionUpdateInput!) {
  updateHomeSection(data: $data) {
    ${HOME_SECTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_PLAYER_CLASS = `
query listPlayerClass($data: ListablePlayerClassInput!) {
  listPlayerClass(data: $data) {
    list {
      ${PLAYER_CLASS_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize 
  }
}
`

export const GQL_GET_HOME_SECTIONS = `
query getHomeSections($data: HomeSectionTypeInput!) {
  getHomeSections(data: $data) {
    ${HOME_SECTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_CHOICE_FROM_PLAYER_CLASS = `
query getChoiceFromPlayerClass($data: SingleIDInput!) {
  getChoiceFromPlayerClass(data: $data) {
    ${CHOICE_FROM_PLAYER_CLASS_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_HOME_SECTION = `
query getHomeSection($data: SingleIDInput!) {
  getHomeSection(data: $data) {
    ${HOME_SECTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_CREATE_CHOICE_FROM_PLAYER_CLASS = `
mutation createChoiceFromPlayerClass($data: ChoiceFromPlayerClassCreationInput!) {
  createChoiceFromPlayerClass(data: $data) {
    ${CHOICE_FROM_PLAYER_CLASS_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_HOME_SECTION = `
mutation deleteHomeSection($data: SingleIDInput!) {
  deleteHomeSection(data: $data) {
    ok
  }
}
`

export const GQL_UPDATE_CHOICE_FROM_PLAYER_CLASS = `
mutation updateChoiceFromPlayerClass($data: ChoiceFromPlayerClassUpdateInput!) {
  updateChoiceFromPlayerClass(data: $data) {
    ${CHOICE_FROM_PLAYER_CLASS_FULL_SNAPSHOT}
  }
}
`

export const HOME_SECTION_STORY_FULL_SNAPSHOT = `
  homeSectionStoryId
  sectionId
  storyId
  story {
    storyId
    name
    mainImageFile {
      link
    }
  }
`

export const GQL_CREATE_HOME_SECTION_STORY = `
mutation createHomeSectionStory($data: HomeSectionStoryCreationInput!) {
  createHomeSectionStory(data: $data) {
    ${HOME_SECTION_STORY_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_HOME_SECTION_STORY_BY_ADMIN = `
query listHomeSectionStoryByAdmin($data: ListableHomeSectionStoryInput!) {
  listHomeSectionStoryByAdmin(data: $data) {
    list {
      ${HOME_SECTION_STORY_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_CHOICE_FROM_PLAYER_CLASS = `
query listChoiceFromPlayerClass($data: ListableChoiceFromPlayerClassInput!) {
  listChoiceFromPlayerClass(data: $data) {
    list {
      ${CHOICE_FROM_PLAYER_CLASS_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_DELETE_HOME_SECTION_STORY = `
mutation deleteHomeSectionStory($data: SingleIDInput!) {
  deleteHomeSectionStory(data: $data) {
    ok
  }
}
`

export const TTS_SPEAKER_FULL_SNAPSHOT = `
  ttsSpeakerId
  name
  ttsServerSpeakerId
  sexDistinction
  ageGroupList
  pitchList
  speechList
  extraCharacter
  status
`

export const GQL_LIST_TTS_SPEAKER_LIST = `
query listTtsSpeaker($data: ListableTtsSpeakerInput!) {
  listTtsSpeaker(data: $data) {
    list {
      ${TTS_SPEAKER_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_TTS_URL = `
mutation getTtsUrl($data: ConvertTextToVoiceInput!) {
  getTtsUrl(data: $data) {
    url
  }
}
`

export const GQL_LIST_USER_SELECTED_CHOICE = `
query listUserSelectedChoice($data: ListableUserSelectedChoiceInput!) {
  listUserSelectedChoice(data: $data) {
    list {
      ${USER_SELECTED_CHOICE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_AFFECTION_BY_ADMIN = `
query getAffectionByAdmin($data: GetAffectionByAdminInput!) {
  getAffectionByAdmin(data: $data) {
    affection
  }
}
`

const ONBOARDING_POPUP_PROFILE_IMAGE_FULL_SNAPSHOT = `
  popupProfileImageId
  popupId
  profileImageFileId
  description
  status
  showOrder
  profileImageFile {
    link
  }
`

export const GQL_CREATE_ONBOARDING_POPUP_PROFILE_IMAGE = `
mutation createOnboardingPopupProfileImage($data: CreateOnboardingPopupProfileImageInput!) {
  createOnboardingPopupProfileImage(data: $data) {
    ${ONBOARDING_POPUP_PROFILE_IMAGE_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_ONBOARDING_POPUP_PROFILE_IMAGE = `
query getOnboardingPopupProfileImage($data: SingleIDInput!) {
  getOnboardingPopupProfileImage(data: $data) {
    ${ONBOARDING_POPUP_PROFILE_IMAGE_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_ONBOARDING_POPUP_PROFILE_IMAGE = `
mutation updateOnboardingPopupProfileImage($data: UpdateOnboardingPopupProfileImageInput!) {
  updateOnboardingPopupProfileImage(data: $data) {
    ${ONBOARDING_POPUP_PROFILE_IMAGE_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_ONBOARDING_POPUP_PROFILE_IMAGE = `
mutation deleteOnboardingPopupProfileImage($data: SingleIDInput!) {
  deleteOnboardingPopupProfileImage(data: $data) {
    ok
  }
}
`

export const GQL_LIST_ONBOARDING_POPUP_PROFILE_IMAGE = `
query listOnboardingPopupProfileImage($data: ListableOnboardingPopupProfileImageInput!) {
  listOnboardingPopupProfileImage(data: $data) {
    list {
      ${ONBOARDING_POPUP_PROFILE_IMAGE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_CHAPTER_OPTION = `
mutation createChapterOption($data: ChapterOptionCreationInput!) {
  createChapterOption(data: $data) {
    ${CHAPTER_OPTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_CHAPTER_OPTION = `
query getChapterOption($data: SingleIDInput!) {
  getChapterOption(data: $data) {
    ${CHAPTER_OPTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_CHAPTER_OPTION = `
mutation deleteChapterOption($data: SingleIDInput!) {
  deleteChapterOption(data: $data) {
    ok
  }
}
`

export const GQL_UPDATE_CHAPTER_OPTION = `
mutation updateChapterOption($data: ChapterOptionUpdateInput!) {
  updateChapterOption(data: $data) {
    ${CHAPTER_OPTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_CHAPTER_OPTION = `
query listChapterOption($data: ListableChapterOptionInput!) {
  listChapterOption(data: $data) {
    list {
      ${CHAPTER_OPTION_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_STORY_WEEKDAY = `
mutation createStoryWeekday($data: StoryWeekdayCreationInput!) {
  createStoryWeekday(data: $data) {
    ${STORY_WEEKDAY_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_STORY_WEEKDAY = `
mutation deleteStoryWeekday($data: StoryWeekdayDeleteInput!) {
  deleteStoryWeekday(data: $data) {
    ok
  }
}
`

export const GQL_GET_IMPORT_GOOGLE_INFO = `
query getImportStudioGoogleInfo {
  getImportStudioGoogleInfo {
    pkey
    clientEmail
  }
}
`

const DIRECT_ADS_FULL_SNAPSHOT = `
  createdAt
  updatedAt
  deletedAt
  directAdsId
  title
  type
  imageFileId
  link
  aOSFallbackLink
  iOSFallbackLink
  isHidden
  imageFile {
    link
  }
`

export const GQL_CREATE_DIRECT_ADS = `
mutation createDirectAds($data: DirectAdsCreationInput!) {
  createDirectAds(data: $data) {
    ${DIRECT_ADS_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_DIRECT_ADS = `
mutation updateDirectAds($data: DirectAdsUpdateInput!) {
  updateDirectAds(data: $data) {
    ${DIRECT_ADS_FULL_SNAPSHOT}
  }
}
`

export const GQL_REMOVE_DIRECT_ADS = `
mutation removeDirectAds($data: SingleIDInput!) {
  removeDirectAds(data: $data) {
    ok
  }
}
`

export const GQL_GET_DIRECT_ADS_BY_ADMIN = `
query getDirectAdsByAdmin($data: SingleIDInput!) {
  getDirectAdsByAdmin(data: $data) {
    ${DIRECT_ADS_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_DIRECT_ADS_BY_ADMIN = `
query listDirectAdsByAdmin($data: ListableDirectAdsInput!) {
  listDirectAdsByAdmin(data: $data) {
    list {
      ${DIRECT_ADS_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const APP_SPLASH_IMAGE_FULL_SNAPSHOT = `
  createdAt
  updatedAt
  appSplashImageId
  isHidden
  imageFileId
  imageFile {
    link
  }
`

export const GQL_CREATE_APP_SPLASH_IMAGE_ = `
mutation createAppSplashImage($data: AppSplashImageCreationInput!) {
  createAppSplashImage(data: $data) {
    ${APP_SPLASH_IMAGE_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_APP_SPLASH_IMAGE = `
mutation updateAppSplashImage($data: AppSplashImageUpdateInput!) {
  updateAppSplashImage(data: $data) {
    ${APP_SPLASH_IMAGE_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_APP_SPLASH_IMAGE = `
mutation deleteAppSplashImage($data: SingleIDInput!) {
  deleteAppSplashImage(data: $data) {
    ok
  }
}
`

export const GQL_GET_APP_SPLASH_IMAGE = `
query getAppSplashImage($data: SingleIDInput!) {
  getAppSplashImage(data: $data) {
    ${APP_SPLASH_IMAGE_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_APP_SPLASH_IMAGE = `
query listAppSplashImage($data: ListableAppSplashImageInput!) {
  listAppSplashImage(data: $data) {
    list {
      ${APP_SPLASH_IMAGE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const RECOMMENDATION_FOR_FIRST_USER_FULL_SNAPSHOT = `
  rfuId
  storyId
  story {
    name
  }
  showOrder
  isHidden
`

export const GQL_UPDATE_RECOMMENDATION_FOR_FIRST_USER = `
mutation updateRecommendationForFirstUser($data: RecommendationForFirstUserUpdateInput!) {
  updateRecommendationForFirstUser(data: $data) {
    ${RECOMMENDATION_FOR_FIRST_USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_RECOMMENDATION_FOR_FIRST_USER = `
mutation deleteRecommendationForFirstUser($data: SingleIDInput!) {
  deleteRecommendationForFirstUser(data: $data) {
    ok
  }
}
`

export const GQL_GET_RECOMMENDATION_FOR_FIRST_USER = `
query getRecommendationForFirstUser($data: SingleIDInput!) {
  getRecommendationForFirstUser(data: $data) {
    ${RECOMMENDATION_FOR_FIRST_USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_RECOMMENDATION_FOR_FIRST_USER = `
query listRecommendationForFirstUser($data: ListableRecommendationForFirstUserInput!) {
  listRecommendationForFirstUser(data: $data) {
    list {
      ${RECOMMENDATION_FOR_FIRST_USER_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const RECOMMEND_SECTION_STORY_FULL_SNAPSHOT = `
  recommendSectionStoryId
  sectionId
  recommendStoryId
  recommendStory {
    name
  }
`

export const GQL_LIST_RECOMMEND_SECTION_STORY = `
query listRecommendSectionStory($data: ListableRecommendSectionStoryInput!) {
  listRecommendSectionStory(data: $data) {
    list {
      ${RECOMMEND_SECTION_STORY_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_RECOMMEND_SECTION_STORY = `
mutation createRecommendSectionStory($data: RecommendSectionStoryCreationInput!) {
  createRecommendSectionStory(data: $data) {
    ${RECOMMEND_SECTION_STORY_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_RECOMMEND_SECTION_STORY = `
mutation deleteRecommendSectionStory($data: SingleIDInput!) {
  deleteRecommendSectionStory(data: $data) {
    ok
  }
}
`

export const GQL_GET_RECOMMEND_SECTION_STORY = `
query getRecommendSectionStory($data: SingleIDInput!) {
  getRecommendSectionStory(data: $data) {
    ${RECOMMEND_SECTION_STORY_FULL_SNAPSHOT}
  }
}
`

const STORY_HAS_RECOMMEND_SECTION_FULL_SNAPSHOT = `
  storyHasRecommendSectionId
  storyId
  chapterId
  chapter {
    chapterIndex
    name
  }
  type
  title
  subTitle
`

export const GQL_LIST_STORY_HAS_RECOMMEND_SECTION = `
query listStoryHasRecommendSection($data: ListableStoryHasRecommendSectionInput!) {
  listStoryHasRecommendSection(data: $data) {
    list {
      ${STORY_HAS_RECOMMEND_SECTION_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_STORY_HAS_RECOMMEND_SECTION = `
mutation createStoryHasRecommendSection($data: StoryHasRecommendSectionCreationInput!) {
  createStoryHasRecommendSection(data: $data) {
    ${STORY_HAS_RECOMMEND_SECTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_STORY_HAS_RECOMMEND_SECTION = `
mutation updateStoryHasRecommendSection($data: StoryHasRecommendSectionUpdateInput!) {
  updateStoryHasRecommendSection(data: $data) {
    ${STORY_HAS_RECOMMEND_SECTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_DELETE_STORY_HAS_RECOMMEND_SECTION = `
mutation deleteStoryHasRecommendSection($data: SingleIDInput!) {
  deleteStoryHasRecommendSection(data: $data) {
    ok
  }
}
`

export const GQL_GET_STORY_HAS_RECOMMEND_SECTION = `
query getStoryHasRecommendSection($data: SingleIDInput!) {
  getStoryHasRecommendSection(data: $data) {
    ${STORY_HAS_RECOMMEND_SECTION_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_STORYGAME_CHARACTER_PROMPT = `
query listStorygameCharacterPrompt($data: ListableStorygameCharacterPromptInput!) {
  listStorygameCharacterPrompt(data: $data) {
    list {
      id
      gender
      prompt
      negativePrompt
      context
      negativeContext
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_STORYGAME_CHARACTER_PROMPT = `
mutation createStorygameCharacterPrompt($data: StorygameCharacterPromptPropsInput!) {
  createStorygameCharacterPrompt(data: $data) {
    id
    gender
    prompt
    negativePrompt
    context
    negativeContext
  }  
}
`

export const GQL_GET_STORYGAME_CHARACTER_PROMPT = `
query getStorygameCharacterPrompt($data: SingleIDInput!) {
  getStorygameCharacterPrompt(data: $data) {
    id
    gender
    prompt
    negativePrompt
    context
    negativeContext
  }  
}
`

export const GQL_UPDATE_STORYGAME_CHARACTER_PROMPT = `
mutation updateStorygameCharacterPrompt($data: StorygameCharacterPromptPropsInput!) {
  updateStorygameCharacterPrompt(data: $data) {
    id
    gender
    prompt
    negativePrompt
    context
    negativeContext
  }  
}
`

export const GQL_LIST_STORYGAME_CHARACTER_ADDITIONAL_PROMPT = `
query listStorygameCharacterAdditionalPrompt($data: ListableStorygameCharacterAdditionalPromptInput!) {
  listStorygameCharacterAdditionalPrompt(data: $data) {
    list {
      id
      gender
      key
      category
      prompt
      negativePrompt
      order
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT = `
mutation createStorygameCharacterAdditionalPrompt($data: StorygameCharacterAdditionalPromptPropsInput!) {
  createStorygameCharacterAdditionalPrompt(data: $data) {
    id
    gender
    key
    category
    prompt
    negativePrompt
    order
  }  
}
`

export const GQL_GET_STORYGAME_CHARACTER_ADDITIONAL_PROMPT = `
query getStorygameCharacterAdditionalPrompt($data: SingleIDInput!) {
  getStorygameCharacterAdditionalPrompt(data: $data) {
    id
    gender
    key
    category
    prompt
    negativePrompt
    order
  }
}
`

export const GQL_UPDATE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT = `
mutation updateStorygameCharacterAdditionalPrompt($data: StorygameCharacterAdditionalPromptPropsInput!) {
  updateStorygameCharacterAdditionalPrompt(data: $data) {
    id
    gender
    key
    category
    prompt
    negativePrompt
    order 
  }
}  
`

export const GQL_DELETE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT = `
mutation deleteStorygameCharacterAdditionalPrompt($data: SingleIDInput!) {
  deleteStorygameCharacterAdditionalPrompt(data: $data) {
    ok
  }
}
`
