import React from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import {
  ODTabBarButton,
  ODTabBarContainer,
} from '../../components/base/ODTabBar'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { ChapterListableTable } from '../chapter/list/ChapterListableTable'
import { ChapterCommentEditContainer } from '../chapterComment/detail/ChapterCommentEditContainer'
import { ChapterCommentListableTable } from '../chapterComment/list/ChapterCommentListableTable'
import { EPUBStoryMainPropsEditPage } from './main/EPUBStoryMainPropsEditPage'
import { StoryHasRecommendSectionListableTable } from '../EPUBStoryHasRecommendSection/list/StoryHasRecommendSectionLiastableTable'
import { StoryHasRecommendSectionEditPageContainer } from '../EPUBStoryHasRecommendSection/detail/StoryHasRecommendSectionEditPageContainer'

interface IEPUBStoryEditMainContainerProps {
  storyId: number | null
}

interface ITabInfo {
  name: string
  showOnCreate?: boolean
}

const Items: ITabInfo[] = [
  {
    name: '작품 관리',
    showOnCreate: true,
  },
  {
    name: '챕터 관리',
  },
  {
    name: '댓글 관리',
  },
  {
    name: '추천 섹션 관리',
  },
]

export const EPUBStoryEditMainContainer: React.FC<
  IEPUBStoryEditMainContainerProps
> = (props) => {
  const { storyId } = props
  const { apiGetStory } = useStoryPlayAPIs()
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const [chapterCommentIdEditing] = useQueryParam(
    'ccId',
    withDefault(NumberParam, null)
  )
  const [storyHasRecommendSectionIdEditing] = useQueryParam(
    'storyHasRecommendSectionId',
    withDefault(NumberParam, null)
  )

  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  const isCreating = storyId === null
  const isEditingChapterComment = tab === 2 && chapterCommentIdEditing !== null
  const isEditingStoryHasRecommendSection = tab === 3 && storyHasRecommendSectionIdEditing !== null

  const [storyName, setStoryName] = React.useState<string>('')

  React.useEffect(() => {
    if (storyId && storyId > 0) {
      apiGetStory({ id: storyId! }).then((r) => {
        setStoryName(r.name)
      })
    }
  }, [apiGetStory])

  return (
    <FlexContentsContainer>
      {storyName && (
        <div style={{ backgroundColor: 'white', padding: 12 }}>
          <b>
            [ {storyId} ] {storyName}
          </b>
        </div>
      )}
      <ODTabBarContainer>
        {Items.map((info, index) => {
          const { name: text, showOnCreate } = info
          const isSelected = index === tab
          if (isCreating && !showOnCreate) {
            return null
          }
          return (
            <ODTabBarButton
              active={tab === index}
              onClick={() => setTab(index)}
              key={index}
            >
              {isSelected && <strong>{text}</strong>}
              {!isSelected && <span>{text}</span>}
            </ODTabBarButton>
          )
        })}
      </ODTabBarContainer>
      {tab === 0 && <EPUBStoryMainPropsEditPage storyId={storyId} />}
      {tab === 1 && <ChapterListableTable storyId={storyId!} />}
      {tab === 2 && !isEditingChapterComment && (
        <ChapterCommentListableTable storyId={storyId!} parentId={null} />
      )}
      {tab === 2 && isEditingChapterComment && (
        <ChapterCommentEditContainer
          ccId={chapterCommentIdEditing}
          storyId={storyId!}
        />
      )}
      {tab === 3 && !isEditingStoryHasRecommendSection && (
        <StoryHasRecommendSectionListableTable storyId={storyId!} />
      )}
      {tab === 3 && isEditingStoryHasRecommendSection && (
        <StoryHasRecommendSectionEditPageContainer
          storyId={storyId!}
          storyHasRecommendSectionId={storyHasRecommendSectionIdEditing!}
        />
      )}
    </FlexContentsContainer>
  )
}
