"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoHbAlgorithmStoryStrategy = void 0;
const hbAlgorithmDoChapterStoreStrategy_1 = require("../doChapterStoreStrategy/hbAlgorithmDoChapterStoreStrategy");
const baseDoStoryStrategy_1 = require("./baseDoStoryStrategy");
class DoHbAlgorithmStoryStrategy extends baseDoStoryStrategy_1.BaseDoStoryStrategy {
    constructor() {
        super(...arguments);
        this.doChapterStoreStrategyConstructor = hbAlgorithmDoChapterStoreStrategy_1.HbAlgorithmDoChapterStoreStrategy;
    }
    async getChapterForEditing(chapterId) {
        const server = this.doStory.rootStore.di.server;
        return server.getAlgorithmChapterforEditing(chapterId, 0, 1); // TODO 페이지 처리
    }
    getChapterDraftArchive(chapterDraftArchiveId) {
        // 헬로봇은 실시간 저장이므로 만들 필요가 없다.
        throw new Error('Method not implemented.');
    }
    exportStudioStoryAsSheet(input) {
        // 알고리즘 블록에 대해서 아직 필요없다.
        throw new Error('Method not implemented.');
    }
    updateEntityForStudio(entity, changeSet) {
        // 수정 구현시에 만들어야 한다.
        throw new Error('Method not implemented.');
    }
    async createChapterForStudio(input) {
        // 알고리즘 블록 그룹 추가 동작 구현시에 만들어야 한다.
        const server = this.doStory.rootStore.di.server;
        return server.createChapterForStudio({
            botId: input.botId,
            logicId: input.logicId,
            title: input.title,
        }); // TODO 페이지 처리
        // throw new Error('Method not implemented.')
    }
    deleteChapterForStudio(chapterId) {
        // 알고리즘 블록 그룹 삭제 동작 구현시 만들어야 한다.
        return this.doStory.rootStore.di.server.deleteChapterForStudio(chapterId);
        // throw new Error('Method not implemented.')
    }
    async load(storyId) {
        const hbStudioAPIServer = this.doStory.rootStore.di
            .server;
        // TODO : 여기를 헬로봇 알고리즘 블록 그룹 로드 함수로 교체해야 한다.
        return hbStudioAPIServer.getAlgorithmStoryForStudio(storyId, {
            page: {
                index: 0,
                size: 15,
            },
            sort: {
                key: 'regDate',
                order: 'asc',
            },
        });
    }
    postLoad(story) { }
}
exports.DoHbAlgorithmStoryStrategy = DoHbAlgorithmStoryStrategy;
