"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalTaskQueue = void 0;
const exponential_backoff_1 = require("exponential-backoff");
// 최신 버전 4.0.0 부터는 node v12.22.0 과 현재 jest 버전에서 오류가 나서 3.1.0 버전으로 수행
const pLimit = require("p-limit");
class LocalTaskQueue {
    constructor(maxConcurrency = 10, retryBackOffOptions = {
        numOfAttempts: 30,
        maxDelay: 5000,
    }, logProgress = false) {
        this.maxConcurrency = maxConcurrency;
        this.retryBackOffOptions = retryBackOffOptions;
        this.logProgress = logProgress;
        //
    }
    setTaskHandler(taskHandler) {
        this.taskHandler = taskHandler;
        return this;
    }
    async enqueueTasks(mappedTasks, onReduce) {
        if (!this.taskHandler) {
            throw new Error(`Task handler is not set.`);
        }
        const taskHandler = this.taskHandler;
        const limit = pLimit(this.maxConcurrency);
        const results = mappedTasks.map(() => null);
        const total = mappedTasks.length;
        const ts = new Date();
        let taskCompleted = 0;
        const log = this.logProgress
            ? (msg) => {
                const elapsed = ((new Date().getTime() - ts.getTime()) /
                    1000).toFixed(1);
                // tslint:disable-next-line:no-console
                console.log(`${msg} : ${elapsed}s`);
            }
            : () => null;
        const wrapTask = async (task, index) => {
            let retryCount = 0;
            return (0, exponential_backoff_1.backOff)(async () => {
                try {
                    log(`Task ${index} starting..`);
                    results[index] = await taskHandler(task, retryCount);
                    taskCompleted += 1;
                    log(`Task ${index} finished. [ ${taskCompleted}/${total} ] ${Math.floor((taskCompleted * 100) / total)}%`);
                }
                catch (ex) {
                    log(`Task ${index} raised error => ${ex.message}`);
                    retryCount += 1;
                    throw ex;
                }
            }, this.retryBackOffOptions);
        };
        const allTasks = mappedTasks.map((task, index) => limit(() => wrapTask(task, index)));
        await Promise.all(allTasks);
        const pairs = results.map((result, index) => ({
            task: mappedTasks[index],
            result,
        }));
        log(`Starting reduce task..`);
        return onReduce(pairs);
    }
}
exports.LocalTaskQueue = LocalTaskQueue;
