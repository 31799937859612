import { GQLSTORY_PLAY_TYPE } from '@storyplay/core'
import React from 'react'
import {
  BooleanParam,
  NumberParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import {
  ODTabBarButton,
  ODTabBarContainer,
} from '../../components/base/ODTabBar'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { AchievementConnectContainer } from '../achievement/AchievementConnectContainer'
import { BackgroundEditPageContainer } from '../background/detail/BackgroundEditPageContainer'
import { BackgroundListableTable } from '../background/list/BackgroundListableTable'
import { ChapterListableTable } from '../chapter/list/ChapterListableTable'
import { ChapterCommentEditContainer } from '../chapterComment/detail/ChapterCommentEditContainer'
import { ChapterCommentListableTable } from '../chapterComment/list/ChapterCommentListableTable'
import { ChoiceListableTable } from '../choice/list/ChoiceListableTable'
import { ChrEditPageContainer } from '../chr/detail/ChrEditPageContainer'
import { ChrListableTable } from '../chr/list/ChrListableTable'
import { EndingEditPageContainer } from '../ending/detail/EndingEditPageContainer'
import { EndingListableTable } from '../ending/list/EndingListableTable'
import { EndingCardEditPageContainer } from '../endingCard/detail/EndingCardEditPageContainer'
import { EndingShowsPropertyEditPageContainer } from '../endingShowsProperty/detail/EndingShowsPropertyEditPageContainer'
import { OnboardingPopupEditPageContainer } from '../onboardingPopup/detail/OnboardingPopupEditPageContainer'
import { OnboardingPopupListableTable } from '../onboardingPopup/list/OnboardingPopupListableTable'
import { OnboardingPopupChoiceEditPageContainer } from '../onboardingPopupChoice/detail/OnboardingPopupChoiceEditPageContainer'
import { PlayerClassListableTable } from '../playerClass/list/PlayerClassListableTable'
import { PreviewEndingEditPageContainer } from '../previewEnding/detail/PreviewEndingEditPageContainer'
import { PreviewEndingListableTable } from '../previewEnding/list/PreviewEndingListableTable'
import { StorygameCharacterAdditionalPromptListableTable } from '../storygameCharacter/characterAdditionalPrompt/list/StorygameCharacterAdditionalPromptListableTable'
import { StorygameCharacterAdditionalPromptEditPage } from '../storygameCharacter/characterAdditionalPrompt/StorygameCharacterAddtionalPromptEditPage'
import { StorygameCharacterPromptListableTable } from '../storygameCharacter/characterPrompt/list/StorygameCharacterPromptListableTable'
import { StorygameCharacterPromptEditPage } from '../storygameCharacter/characterPrompt/StorygameCharacterPromptEditPage'
import { StoryHasRecommendSectionListableTable } from '../storyHasRecommendSection/list/StoryHasRecommendSectionLiastableTable'
import { StoryItemEditPageContainer } from '../storyItem/detail/StoryItemEditPageContainer'
import { StoryItemListableTable } from '../storyItem/list/StoryItemListableTable'
import { StoryPropertyEditPageContainer } from '../storyProperty/detail/StoryPropertyEditPageContainer'
import { StoryPropertyListableTable } from '../storyProperty/list/StoryPropertyListableTable'
import { StoryMainPropsEditPage } from './main/StoryMainPropsEditPage'
import { StoryHasRecommendSectionEditPageContainer } from '../storyHasRecommendSection/detail/StoryHasRecommendSectionEditPageContainer'

interface IStoryEditMainContainerProps {
  storyId: number | null
}

interface ITabInfo {
  name: string
  showOnCreate?: boolean
}

const Items: ITabInfo[] = [
  {
    name: '작품 관리',
    showOnCreate: true,
  },
  {
    name: '챕터 관리',
  },
  {
    name: '등장 캐릭터',
  },
  {
    name: '분기 관리',
  },
  {
    name: '배경 관리',
  },
  {
    name: '엔딩 관리',
  },
  {
    name: '속성 관리',
  },
  {
    name: '프리뷰 엔딩 관리',
  },
  {
    name: '댓글 관리',
  },
  {
    name: '업적 관리',
  },
  {
    name: '아이템 관리',
  },
  {
    name: '온보딩 팝업 관리',
  },
  // {
  //   name: '플레이어 보고서 템플릿',
  // },
  {
    name: '플레이어 클래스 관리',
  },
  {
    name: '추천 섹션 관리',
  },
  { name: 'AI 자캐 프롬프트' },
  { name: 'AI 자캐 추가 프롬프트' },
]

export const StoryEditMainContainer: React.FC<IStoryEditMainContainerProps> = (
  props
) => {
  const { storyId } = props
  const { apiGetStory } = useStoryPlayAPIs()
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const [chrIdEditing] = useQueryParam('chrId', withDefault(NumberParam, null))
  const [shfIdEditing] = useQueryParam('shfId', withDefault(NumberParam, null))
  const [endingIdEditing] = useQueryParam(
    'endingId',
    withDefault(NumberParam, null)
  )
  const [espIdEditing] = useQueryParam('espId', withDefault(NumberParam, null))
  const [cardIdEditing] = useQueryParam(
    'cardId',
    withDefault(NumberParam, null)
  )
  const [propIdEditing] = useQueryParam(
    'propId',
    withDefault(NumberParam, null)
  )
  const [previewEndingId] = useQueryParam(
    'previewEndingId',
    withDefault(NumberParam, null)
  )
  const [popupIdEditing] = useQueryParam(
    'popupId',
    withDefault(NumberParam, null)
  )
  const [storyHasRecommendSectionIdEditing] = useQueryParam(
    'storyHasRecommendSectionId',
    withDefault(NumberParam, null)
  )
  const [popupChoiceIdEditing] = useQueryParam(
    'popupChoiceId',
    withDefault(NumberParam, null)
  )
  const [chapterCommentIdEditing] = useQueryParam(
    'ccId',
    withDefault(NumberParam, null)
  )
  const [storyItemIdEditing] = useQueryParam(
    'storyItemId',
    withDefault(NumberParam, null)
  )
  const [storygameCharacterPromptId] = useQueryParam(
    'storygameCharacterPromptId',
    withDefault(NumberParam, null)
  )
  const [storygameCharacterAdditionalPromptId] = useQueryParam(
    'storygameCharacterAdditionalPromptId',
    withDefault(NumberParam, null)
  )

  const [isFinal] = useQueryParam('isFinal', withDefault(BooleanParam, false))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  const isCreating = storyId === null
  const isEditingChr = tab === 2 && !!chrIdEditing
  const isEditingBackground = tab === 4 && !!shfIdEditing
  const isEditingESP = tab === 5 && espIdEditing !== null
  const isEditingEnding = tab === 5 && !!endingIdEditing && !isEditingESP
  const isEditingEndingCard = tab === 5 && cardIdEditing !== null
  const isEditingProp = tab === 6 && propIdEditing !== null
  const isEditingEndingPreview = tab === 7 && previewEndingId !== null
  const isEditingChapterComment = tab === 8 && chapterCommentIdEditing !== null
  const isEditingStoryItem = tab === 10 && storyItemIdEditing !== null
  const isEditingOnboardingPopup = tab === 11 && popupIdEditing !== null
  const isEditingOnboardingPopupChoice =
    tab === 11 && popupChoiceIdEditing !== null
  const isEditingStoryHasRecommendSection =
    tab === 13 && storyHasRecommendSectionIdEditing !== null
  const isEditingStorygameCharacterPrompt =
    tab === 14 && storygameCharacterPromptId !== null
  const isEditingStorygameCharacterAdditionalPrompt =
    tab === 15 && storygameCharacterAdditionalPromptId !== null

  const [storyName, setStoryName] = React.useState<string>('')
  const [storyPlayType, setStoryPlayType] = React.useState<GQLSTORY_PLAY_TYPE>(
    GQLSTORY_PLAY_TYPE.Interactive
  )

  React.useEffect(() => {
    if (storyId && storyId > 0) {
      apiGetStory({ id: storyId! }).then((r) => {
        setStoryName(r.name)
        setStoryPlayType(r.playType)
      })
    }
  }, [apiGetStory, storyId])

  const tabItems = (playType: GQLSTORY_PLAY_TYPE) => {
    if (playType === GQLSTORY_PLAY_TYPE.Storygame) {
      return Items
    }

    if (playType === GQLSTORY_PLAY_TYPE.Interactive) {
      return Items.filter((v, i) => ![14, 15].includes(i))
    }

    return Items
  }

  return (
    <FlexContentsContainer>
      {storyName && (
        <div style={{ backgroundColor: 'white', padding: 12 }}>
          <b>
            [ {storyId} ] {storyName}
          </b>
        </div>
      )}
      <ODTabBarContainer>
        {tabItems(storyPlayType).map((info, index) => {
          const { name: text, showOnCreate } = info
          const isSelected = index === tab
          if (isCreating && !showOnCreate) {
            return null
          }
          return (
            <ODTabBarButton
              active={tab === index}
              onClick={() => setTab(index)}
              key={index}
            >
              {isSelected && <strong>{text}</strong>}
              {!isSelected && <span>{text}</span>}
            </ODTabBarButton>
          )
        })}
      </ODTabBarContainer>
      {tab === 0 && <StoryMainPropsEditPage storyId={storyId} />}
      {tab === 1 && <ChapterListableTable storyId={storyId!} />}
      {tab === 2 && !isEditingChr && <ChrListableTable storyId={storyId!} />}
      {tab === 2 && isEditingChr && (
        <ChrEditPageContainer storyId={storyId!} chrId={chrIdEditing!} />
      )}
      {tab === 3 && <ChoiceListableTable storyId={storyId!} />}
      {tab === 4 && !isEditingBackground && (
        <BackgroundListableTable storyId={storyId!} />
      )}
      {tab === 4 && isEditingBackground && (
        <BackgroundEditPageContainer storyId={storyId!} shfId={shfIdEditing!} />
      )}
      {tab === 5 && !isEditingEnding && !isEditingESP && (
        <EndingListableTable storyId={storyId!} />
      )}
      {tab === 5 && isEditingEnding && !isEditingEndingCard && (
        <EndingEditPageContainer
          storyId={storyId!}
          endingId={endingIdEditing!}
          isFinal={isFinal!}
        />
      )}
      {tab === 5 && isEditingESP && !isEditingEndingCard && (
        <EndingShowsPropertyEditPageContainer
          storyId={storyId!}
          endingId={endingIdEditing!}
          espId={espIdEditing}
          isFinal={isFinal}
        />
      )}
      {tab === 5 && !isEditingESP && isEditingEndingCard && (
        <EndingCardEditPageContainer
          storyId={storyId!}
          endingId={endingIdEditing!}
          isFinal={isFinal}
          cardId={cardIdEditing!}
        />
      )}
      {tab === 6 && !isEditingProp && (
        <StoryPropertyListableTable storyId={storyId!} />
      )}
      {tab === 6 && isEditingProp && (
        <StoryPropertyEditPageContainer
          storyId={storyId!}
          propId={propIdEditing!}
        />
      )}
      {/* {tab === 7 && <PreviewEndingListableTable storyId={storyId!} />} */}
      {tab === 7 && !isEditingEndingPreview && (
        <PreviewEndingListableTable storyId={storyId!} />
      )}
      {tab === 7 && isEditingEndingPreview && (
        <PreviewEndingEditPageContainer
          storyId={storyId!}
          previewEndingId={previewEndingId!}
        />
      )}
      {tab === 8 && !isEditingChapterComment && (
        <ChapterCommentListableTable storyId={storyId!} parentId={null} />
      )}
      {tab === 8 && isEditingChapterComment && (
        <ChapterCommentEditContainer
          ccId={chapterCommentIdEditing}
          storyId={storyId!}
        />
      )}
      {tab === 9 && !!storyId && (
        <AchievementConnectContainer storyId={storyId!} />
      )}
      {tab === 10 && !isEditingStoryItem && (
        <StoryItemListableTable storyId={storyId!} />
      )}
      {tab === 10 && isEditingStoryItem && (
        <StoryItemEditPageContainer
          storyId={storyId!}
          storyItemId={storyItemIdEditing}
        />
      )}
      {tab === 11 && !isEditingOnboardingPopup && (
        <OnboardingPopupListableTable storyId={storyId!} />
      )}
      {tab === 11 &&
        isEditingOnboardingPopup &&
        !isEditingOnboardingPopupChoice && (
          <OnboardingPopupEditPageContainer
            storyId={storyId!}
            popupId={popupIdEditing!}
          />
        )}
      {tab === 11 && isEditingOnboardingPopupChoice && (
        <OnboardingPopupChoiceEditPageContainer
          storyId={storyId!}
          popupId={popupIdEditing!}
          popupChoiceId={popupChoiceIdEditing!}
        />
      )}
      {tab === 12 && <PlayerClassListableTable storyId={storyId!} />}
      {tab === 13 && !isEditingStoryHasRecommendSection && (
        <StoryHasRecommendSectionListableTable storyId={storyId!} />
      )}
      {tab === 13 && isEditingStoryHasRecommendSection && (
        <StoryHasRecommendSectionEditPageContainer
          storyId={storyId!}
          storyHasRecommendSectionId={storyHasRecommendSectionIdEditing!}
        />
      )}
      {tab === 14 && !isEditingStorygameCharacterPrompt && (
        <StorygameCharacterPromptListableTable storyId={storyId!} />
      )}
      {tab === 14 && isEditingStorygameCharacterPrompt && (
        <StorygameCharacterPromptEditPage
          storyId={storyId!}
          id={storygameCharacterPromptId!}
        />
      )}
      {tab === 15 && !isEditingStorygameCharacterAdditionalPrompt && (
        <StorygameCharacterAdditionalPromptListableTable storyId={storyId} />
      )}
      {tab === 15 && isEditingStorygameCharacterAdditionalPrompt && (
        <StorygameCharacterAdditionalPromptEditPage
          storyId={storyId}
          id={storygameCharacterAdditionalPromptId}
        />
      )}
    </FlexContentsContainer>
  )
}
