"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectionFieldWithUI = void 0;
// noinspection ES6PreferShortImport
const SelectionInput_1 = require("../ui/SelectionInput");
const SelectionField_1 = require("./SelectionField");
/**
 * 기존 IInputWithValidation 과의 공존을 위하여 존재한다. SelectionField 를 사용하도록 하되,
 * InputWithValidation 을 연동한다.
 */
class SelectionFieldWithUI extends SelectionField_1.SelectionField {
    constructor(fieldId, getter, setter, uiOptions) {
        var _a, _b;
        super(fieldId, getter, setter);
        this.noPropagation = false;
        this.options = uiOptions;
        this.input = new SelectionInput_1.SelectionInput(fieldId, (_a = uiOptions.label) !== null && _a !== void 0 ? _a : '', this.defValue, uiOptions.selection, {
            ...((_b = uiOptions.options) !== null && _b !== void 0 ? _b : {}),
            onChangeBeforeSubmit: v => {
                var _a, _b;
                if (!this.noPropagation) {
                    (_b = (_a = uiOptions.options) === null || _a === void 0 ? void 0 : _a.onChangeBeforeSubmit) === null || _b === void 0 ? void 0 : _b.call(_a, v);
                    this.onChange(v);
                }
            },
            onChange: v => {
                var _a, _b;
                if (!this.noPropagation) {
                    (_b = (_a = uiOptions.options) === null || _a === void 0 ? void 0 : _a.onChange) === null || _b === void 0 ? void 0 : _b.call(_a, v);
                    this.onChange(v);
                }
            },
        });
    }
    reload() {
        super.reload();
        this.noPropagation = true;
        this.input.onChange(this.defValue);
        this.noPropagation = false;
    }
    onChange(newValue) {
        var _a, _b;
        super.onChange(newValue);
        this.noPropagation = true;
        this.input.onChange(newValue);
        (_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.onChange) === null || _b === void 0 ? void 0 : _b.call(_a, newValue);
        this.noPropagation = false;
    }
}
exports.SelectionFieldWithUI = SelectionFieldWithUI;
