import {
  ODEntityLabeled,
  ODImageFileInput,
  useCounter,
  useODMutation,
  useODQuery,
} from '@odc/od-react-belt'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLAppSplashImage,
  GQLAppSplashImageCreationInput,
  GQLAppSplashImageUpdateInput,
  GQLDirectAdsCreationInput,
  GQLOkResponse,
  GQLSingleIDInput,
} from '../../../@types/server'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import {
  GQL_CREATE_APP_SPLASH_IMAGE_,
  GQL_DELETE_APP_SPLASH_IMAGE,
  GQL_GET_APP_SPLASH_IMAGE,
  GQL_UPDATE_APP_SPLASH_IMAGE,
} from '../../../hooks/gqls_admin'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IAppSplashImageEditPageProps {
  appSplashImageId: number | null
}

type Entity = GQLAppSplashImage
type EntityInput = GQLAppSplashImageCreationInput | GQLAppSplashImageUpdateInput

function getValidationSchema(values: any) {
  return Yup.object().shape({})
}

export const AppSplashImageEditPage: React.FC<IAppSplashImageEditPageProps> = (
  props
) => {
  const { appSplashImageId } = props
  const innerRef = React.useRef(null)
  const apiCreate = useODMutation<
    Partial<GQLAppSplashImageCreationInput>,
    Partial<Entity>
  >(GQL_CREATE_APP_SPLASH_IMAGE_)
  const apiUpdate = useODMutation<
    Partial<GQLAppSplashImageUpdateInput>,
    Partial<Entity>
  >(GQL_UPDATE_APP_SPLASH_IMAGE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET_APP_SPLASH_IMAGE)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DELETE_APP_SPLASH_IMAGE
  )

  const [token] = useCounter()
  const isCreating = !appSplashImageId

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<Entity, Partial<EntityInput>>
  >(
    () => ({
      initialValueLoader: async () => {
        if (appSplashImageId) {
          return apiGet({ id: appSplashImageId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            imageFile: null,
            isHidden: true,
          }
        }

        return {
          imageFile: data.imageFile,
          isHidden: data.isHidden,
        }
      },
      saveClientValueToServer: async (data: Partial<EntityInput>) => {
        if (appSplashImageId) {
          await apiUpdate({
            id: appSplashImageId,
            imageFile: data.imageFile,
            isHidden: data.isHidden,
          })
          Utils.showSuccess('수정되었습니다.', 'Success')
        } else {
          const { isHidden, imageFile } = data as GQLDirectAdsCreationInput
          await apiCreate({
            isHidden,
            imageFile,
          })
          Utils.showSuccess('생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.AppSplashImage.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (appSplashImageId) {
          await apiRemove({ id: appSplashImageId })
          Utils.showSuccess('삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.AppSplashImage.List
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [appSplashImageId, apiGet, apiUpdate, apiCreate, token, apiRemove]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<Entity, Partial<EntityInput>>
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<EntityInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<Entity, Partial<EntityInput>>(options)
      ),
    [options]
  )

  const title = isCreating ? '스플래쉬 이미지 생성' : '스플래쉬 이미지 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            <hr />
            <ODEntityLabeled label="이미지 파일" name="imageFile">
              <ODImageFileInput
                name="imageFile"
                height={200}
                width={200}
                keyPath="imageFile.link"
              />
            </ODEntityLabeled>
            <ODEntityLabeled label="숨김?" name="isHidden">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isHidden_true"
                  name="isHidden"
                  keyPath="isHidden"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isHidden_false"
                  name="isHidden"
                  keyPath="isHidden"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <hr />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={appSplashImageId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
