"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdviceContainer = void 0;
const multiMap_1 = require("../adt/multiMap/multiMap");
class AdviceContainer {
    constructor() {
        this.preAdvisorMultiMap = new multiMap_1.MultiMap();
        this.postAdvisorMultiMap = new multiMap_1.MultiMap();
        /**
         * 이 어드바이스 컨테이너에 등록할 수 있는 데코레이터를 돌려준다. 이 데코레이터를 달고 선언하면 함수는 자동으로 데코레이터에 등록된다.
         * 물론 수동으로 등록할 수도 있다.
         */
        this.getAdvisorDecorator = () => {
            return (jointPoint, advisorType) => {
                return (target, property, descriptor) => {
                    if (advisorType === 'pre') {
                        this.preAdvisorMultiMap.add(jointPoint, descriptor.value.bind(target));
                    }
                    else if (advisorType === 'post') {
                        this.postAdvisorMultiMap.add(jointPoint, descriptor.value.bind(target));
                    }
                    return descriptor;
                };
            };
        };
        this.getPreAdvisors = (sym) => {
            return this.preAdvisorMultiMap.get(sym);
        };
        this.getPostAdvisors = (sym) => {
            return this.postAdvisorMultiMap.get(sym);
        };
    }
}
exports.AdviceContainer = AdviceContainer;
