"use strict";
/**
 * 문제 상황에 대한 결과값
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioValidationSeverity = void 0;
var StudioValidationSeverity;
(function (StudioValidationSeverity) {
    StudioValidationSeverity["Error"] = "Error";
    StudioValidationSeverity["Warning"] = "Warning";
    StudioValidationSeverity["Recommend"] = "Recommend";
})(StudioValidationSeverity = exports.StudioValidationSeverity || (exports.StudioValidationSeverity = {}));
