import { AGE_GROUP, SEX_DISTINCTION } from '@storyplay/common'
import React from 'react'
import Select from 'react-select'

interface ITtsSpeakerAgeSelectProps {
  value: AGE_GROUP | undefined
  onChange: (value: AGE_GROUP | undefined) => void
}

export const TtsSpeakerAgeSelect: React.FC<ITtsSpeakerAgeSelectProps> = (
  props
) => {
  const { value, onChange } = props
  const filterTypes = [
    AGE_GROUP.Ten,
    AGE_GROUP.Twenty,
    AGE_GROUP.Thirty,
    AGE_GROUP.Forty,
    AGE_GROUP.Fifty,
    AGE_GROUP.Sixty,
  ]

  const selectOptions = [
    {
      label: '모든 연령대',
      value: undefined,
    },
    ...filterTypes.map((filterType) => ({
      label: filterType,
      value: filterType,
    })),
  ]

  return (
    <Select
      placeholder="스피커 연령대"
      value={selectOptions.find((v) => v.value === value)}
      // @ts-ignore
      onChange={(v) => onChange(v.value)}
      options={selectOptions}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
