"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adviceContainerProxy = void 0;
class AdviceContainerProxy {
    constructor() {
        this.setAdviceContainer = (container) => {
            this.adviceContainer = container;
        };
        this.getPreAdvisors = (sym) => {
            var _a;
            return (_a = this.adviceContainer) === null || _a === void 0 ? void 0 : _a.getPreAdvisors(sym);
        };
        this.getPostAdvisors = (sym) => {
            var _a;
            return (_a = this.adviceContainer) === null || _a === void 0 ? void 0 : _a.getPostAdvisors(sym);
        };
    }
}
const adviceContainerProxy = new AdviceContainerProxy();
exports.adviceContainerProxy = adviceContainerProxy;
