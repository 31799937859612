import { ReactNodeLike } from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { ODButton, ODButtonTheme } from '../base/ODButton'
import { ODIcon, ODIcons } from '../ODIcon'
import { ODModal, ODModalSize, ODModalTheme } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

export type UseConfirmModalOptions = {
  theme?: ODModalTheme
  size?: ODModalSize
  title?: string
  message: ReactNodeLike
  icon?: ODIcons
  yes: string
  no: string
}

const Message = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2f353a;
`

type ODModalConfirmProps = {
  isOpen: boolean
  setIsOpen: (open: boolean) => any
}

export function useModalConfirm(options: UseConfirmModalOptions) {
  const { title, message, yes, no, size, icon = ODIcons.MaterialError } = options

  const [isOpen, setIsOpen] = React.useState(false)
  const onClickYes = React.useRef<() => void>(() => undefined)
  const onClickNo = React.useRef<() => void>(() => undefined)

  const confirm = React.useCallback(() => {
    return new Promise((resolve) => {
      onClickYes.current = () => {
        setIsOpen(false)
        resolve(true)
      }
      onClickNo.current = () => {
        setIsOpen(false)
        resolve(false)
      }
      setIsOpen(true)
    })
  }, [])

  const [Component] = React.useState(() => {
    return (props: ODModalConfirmProps) => (
      <ODModal isOpen={props.isOpen} toggle={() => props.setIsOpen(false)} size={size}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title={title} onClose={() => onClickNo.current()} headerBottomPadding={30}>
            <ODIcon icon={icon} style={{ fontSize: 60 }} />
          </ODModalHeader>
          <ODModalBody>
            <Message>{message}</Message>
          </ODModalBody>
          <ODModalFooter>
            <ODButton style={{ width: '35%' }} theme={ODButtonTheme.Cancel} onClick={() => onClickNo.current()}>
              {no}
            </ODButton>
            <ODButton style={{ width: '65%' }} theme={ODButtonTheme.Primary} onClick={() => onClickYes.current()}>
              {yes}
            </ODButton>
          </ODModalFooter>
        </div>
      </ODModal>
    )
  })

  const props: ODModalConfirmProps = {
    isOpen,
    setIsOpen: (v: boolean) => {
      setIsOpen(v)
    },
  }

  return { Component, confirm, props }
}
