import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { EndingCardEditPage } from './EndingCardEditPage'

interface IEndingCardContainerProps {
  storyId: number
  endingId: number
  isFinal: boolean
  cardId: number | null
}

export const EndingCardEditPageContainer: React.FC<IEndingCardContainerProps> = (
  props
) => {
  const { storyId, endingId, isFinal, cardId } = props
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow
            link={SiteUrls.Admin.Story.EditEnding(storyId, endingId, isFinal)}
          />
          <hr />
          <EndingCardEditPage
            storyId={storyId}
            endingId={endingId}
            cardId={cardId}
            isFinal={isFinal}
          />
        </CardBody>
      </Card>
    </>
  )
}
