import React from 'react'
import * as Yup from 'yup'
import { ObjectSchema, Schema, Shape } from 'yup'
import { ODEntityEditorContextOptions, ODEntityEditorContextType } from '../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorUIBuilder } from './ODEntityEditorUIBuilder'
import { RS } from './ODResources/ODResources'

export enum OD_DATA_TYPE {
  VARCHAR, // 통상 150자 이내의 짧은 텍스트
  LONGTEXT, // 150자 이상으로 길어질 수도 있는 텍스트
  INTEGER, // 정수
  IMAGE, // 이미지 파일
  DATETIME, // 시각
  CUSTOM, // 커스텀 컴포넌트로 볼 경우
}

/**
 * Yup 으로 구성된 field schema 를 wrapping 하여
 * @param schema
 */
export function wrapYup<FIELD_FORM_TYPE>(schema: Schema<FIELD_FORM_TYPE>) {
  return (fieldData: any) => {
    try {
      return schema.validate(fieldData)
    } catch (ex) {
      throw new Error(ex.errors[0])
    }
  }
}

type FieldDefinition<
  SERVER_OBJECT_TYPE,
  CLIENT_FORM_TYPE,
  FIELD_FORM_TYPE,
  SK extends keyof SERVER_OBJECT_TYPE,
  CK extends keyof CLIENT_FORM_TYPE
> = {
  name: SK // 서버 데이터 필드의 이름입니다.
  formField: CK // 클라이언트 필드의 이름입니다.
  isKeyField?: boolean // 이 리소스를 정의하는 데이터인가?
  hide?: boolean // UI 에 표시하지 않는다.
  readOnly?: boolean // 수정이 불가능하다.
  dataType: OD_DATA_TYPE // 내부 데이터 타입입니다. (단순히 string, number 등이 아닌, 자체적으로 정의하고 있는 의미가 있는 데이터타입입니다)
  initialValue: FIELD_FORM_TYPE // 객체 생성시 최초 값을 제공합니다.
  transformToFormData?: (data: SERVER_OBJECT_TYPE) => FIELD_FORM_TYPE // 보통 서버의 필드를 그대로 활용하지만, transform 이 필요하면 적용합니다.
  transformToServerData?: (changeSet: Partial<CLIENT_FORM_TYPE>, isUpdating: boolean) => FIELD_FORM_TYPE // 보통 폼의 필드를 그대로 활용하지만, transform 이 필요하면 적용합니다.
  populateInput?: () => FIELD_FORM_TYPE // 테스트용 데이터를 제공합니다.
  validateInput?: Schema<any>
  // 만약 제공되면, ODEntityEditorUIBuilder 에서 addField 함수를 사용하여 디폴트 설정을 할 수 있다. (추천)
  ui?: {
    label?: RS
    placeholder?: RS
    rows?: number
    width?: number
    height?: number
  }
}

// RESOURCE_KEY => 하나의 서버 아이템을 특정하기 위한 키 (normally GQLSingleIDInput)
// SERVER_OBJECT_TYPE => 우리가 편집하고자 하는 하나의 아이템에 대한 서버 형상
// CLIENT_OBJECT_TYPE => 서버 형상을 에디터에서 사용하기 위해 변환된 값 (시나리오가 복잡하지 않다면 SERVER_OBJECT_TYPE 을 그대로 사용할 수도 있다)
// UI_PROPS => 외부에서 UI 변경을 수행할 수 있도록 내어주는 값의 타입

export type OD_CREATE_API_CALLABLE<SERVER_CREATE_INPUT, SERVER_OBJECT_TYPE> = (
  data: SERVER_CREATE_INPUT
) => Promise<SERVER_OBJECT_TYPE>
export type OD_READ_API_CALLABLE<RESOURCE_KEY, SERVER_OBJECT_TYPE> = (key: RESOURCE_KEY) => Promise<SERVER_OBJECT_TYPE>
export type OD_UPDATE_API_CALLABLE<RESOURCE_KEY, SERVER_UPDATE_INPUT, SERVER_OBJECT_TYPE> = (
  key: RESOURCE_KEY,
  updates: SERVER_UPDATE_INPUT
) => Promise<SERVER_OBJECT_TYPE>
export type OD_DELETE_API_CALLABLE<RESOURCE_KEY> = (key: RESOURCE_KEY) => Promise<any>

export type EntityEditorConfig<
  RESOURCE_KEY,
  SERVER_OBJECT_TYPE,
  SERVER_CREATE_INPUT,
  SERVER_UPDATE_INPUT,
  CLIENT_FORM_DATA,
  UI_PROPS
> = {
  fields: Array<
    FieldDefinition<SERVER_OBJECT_TYPE, CLIENT_FORM_DATA, any, keyof SERVER_OBJECT_TYPE, keyof CLIENT_FORM_DATA>
  >
  api: {
    create: OD_CREATE_API_CALLABLE<SERVER_CREATE_INPUT, SERVER_OBJECT_TYPE>
    update: OD_UPDATE_API_CALLABLE<RESOURCE_KEY, SERVER_UPDATE_INPUT, SERVER_OBJECT_TYPE>
    read: OD_READ_API_CALLABLE<RESOURCE_KEY, SERVER_OBJECT_TYPE>
    delete: OD_DELETE_API_CALLABLE<RESOURCE_KEY>
  }
  urlAfterCreation: (returnData: SERVER_OBJECT_TYPE) => string
  urlAfterUpdate: (returnData: SERVER_OBJECT_TYPE) => string
  urlAfterDelete: () => string
  optionHooks?: ODEntityEditorHooks<
    RESOURCE_KEY,
    SERVER_OBJECT_TYPE,
    SERVER_CREATE_INPUT,
    SERVER_UPDATE_INPUT,
    CLIENT_FORM_DATA,
    UI_PROPS
  >
  alwaysUpdateFields?: string[]
}

export type ODEntityEditorHooks<
  RESOURCE_KEY,
  SERVER_OBJECT_TYPE,
  SERVER_CREATE_INPUT,
  SERVER_UPDATE_INPUT,
  CLIENT_FORM_DATA,
  UI_PROPS
> = {
  beforeMapServerValueToClient?: (data: SERVER_OBJECT_TYPE | null) => Promise<void> | void
  beforeCallCreateApi?: (data: SERVER_CREATE_INPUT) => Promise<void> | void
  afterCallCreateApi?: (ret: SERVER_OBJECT_TYPE, input: SERVER_CREATE_INPUT) => Promise<void> | void
  beforeCallUpdateApi?: (data: SERVER_UPDATE_INPUT) => Promise<void> | void
  afterCallUpdateApi?: (ret: SERVER_OBJECT_TYPE, input: SERVER_UPDATE_INPUT) => Promise<void> | void
  beforeCallDeleteApi?: () => Promise<void> | void
  afterCallDeleteApi?: () => Promise<void> | void
  onUpdateWithNoChangeSet?: () => Promise<void> | void
  onItemLoaded?: (item: SERVER_OBJECT_TYPE) => void
  onUnexpectedError?: (ex: Error) => void
}

/**
 * ODEntityEditor 의 입력으로 포함됩니다.
 */
export class ODEntityEditorConfig<
  RESOURCE_KEY,
  SERVER_OBJECT_TYPE extends object,
  SERVER_CREATE_INPUT extends object,
  SERVER_UPDATE_INPUT extends object,
  CLIENT_FORM_DATA extends object,
  UI_PROPS
> {
  readonly config: EntityEditorConfig<
    RESOURCE_KEY,
    SERVER_OBJECT_TYPE,
    SERVER_CREATE_INPUT,
    SERVER_UPDATE_INPUT,
    CLIENT_FORM_DATA,
    UI_PROPS
  >
  uiBuilder: ODEntityEditorUIBuilder<
    RESOURCE_KEY,
    SERVER_OBJECT_TYPE,
    SERVER_CREATE_INPUT,
    SERVER_UPDATE_INPUT,
    CLIENT_FORM_DATA,
    UI_PROPS
  > = new ODEntityEditorUIBuilder(this)

  // 현재 수정하고 있는 리소스의 키, 생성시에는 null
  private readonly resourceEditing: RESOURCE_KEY | null

  constructor(
    config: EntityEditorConfig<
      RESOURCE_KEY,
      SERVER_OBJECT_TYPE,
      SERVER_CREATE_INPUT,
      SERVER_UPDATE_INPUT,
      CLIENT_FORM_DATA,
      UI_PROPS
    >,
    resourceEditing: RESOURCE_KEY | null
  ) {
    this.config = config
    this.resourceEditing = resourceEditing || null
  }

  get isCreating() {
    return !this.resourceEditing
  }

  genPopulateInput(): () => CLIENT_FORM_DATA {
    const populate = () => {
      const obj: any = {}
      this.config.fields
        .filter(def => def.populateInput)
        .forEach(def => {
          obj[def.name] = def.populateInput!()
        })
      return obj as CLIENT_FORM_DATA
    }
    return populate
  }

  genValidationSchema(): (formData: CLIENT_FORM_DATA) => ObjectSchema<Shape<object, object>> {
    const obj: any = {}
    this.config.fields
      .filter(def => def.validateInput)
      .forEach(def => {
        obj[def.name] = def.validateInput
      })
    return () => Yup.object().shape(obj)
  }

  createODEntityEditorContextOptions(options: {
    setItemLoaded: (item: SERVER_OBJECT_TYPE) => void
    __innerReference: React.RefObject<ODEntityEditorContextType<SERVER_OBJECT_TYPE, CLIENT_FORM_DATA> | null>
  }): ODEntityEditorContextOptions<SERVER_OBJECT_TYPE, CLIENT_FORM_DATA> {
    const resourceEditing = this.resourceEditing
    const isCreating = !this.resourceEditing
    const optionHooks = this.config.optionHooks || {}
    const alwaysUpdateFields = this.config.alwaysUpdateFields ?? []

    return {
      __innerReference: options.__innerReference,
      initialValueLoader: async () => {
        if (!isCreating) {
          const item = await this.config.api.read(this.resourceEditing!)
          optionHooks.onItemLoaded?.(item)
          options.setItemLoaded(item)
          return item
        }
        // enter creation mode.
        return null
      },
      mapServerValueToClient: async data => {
        console.assert(isCreating || !!data, `[85123] Logic error : isCreating = ${isCreating}, data = `, data)
        optionHooks.beforeMapServerValueToClient && (await optionHooks.beforeMapServerValueToClient(data))

        let finalData: CLIENT_FORM_DATA
        finalData = this.config.fields.reduce((form, field) => {
          const { isKeyField, name, formField } = field
          if (isCreating) {
            if (!isKeyField) {
              form[formField] = field.initialValue
            }
          } else {
            form[formField] = field.transformToFormData ? field.transformToFormData(data!) : data![name]
          }

          return form
        }, {} as Partial<CLIENT_FORM_DATA>) as CLIENT_FORM_DATA

        return finalData
      },
      saveClientValueToServer: async (
        changeSet: CLIENT_FORM_DATA | Partial<CLIENT_FORM_DATA>,
        formData?: CLIENT_FORM_DATA,
        initialData?: CLIENT_FORM_DATA | null
      ) => {
        if (isCreating) {
          const serverInput = this.config.fields.reduce((input, field) => {
            const { formField, name, transformToServerData } = field

            if (changeSet.hasOwnProperty(formField)) {
              // @ts-ignore
              input[name] = transformToServerData ? transformToServerData(changeSet, false) : changeSet[formField]
            }

            return input
          }, {} as Partial<SERVER_CREATE_INPUT>) as SERVER_CREATE_INPUT

          // 모든 formField 를 대상으로 하는 field definition 으로 서버 데이터를 업데이트한다.
          await optionHooks.beforeCallCreateApi?.(serverInput)
          const res = await this.config.api.create(serverInput)
          await optionHooks.afterCallCreateApi?.(res, serverInput)
          return this.config.urlAfterCreation(res)
        } else {
          const serverUpdateInput = this.config.fields.reduce((input, field) => {
            const { formField, name, transformToServerData } = field

            if (changeSet.hasOwnProperty(formField)) {
              // @ts-ignore
              input[name] = transformToServerData ? transformToServerData(changeSet, true) : changeSet[formField]
            } else if (alwaysUpdateFields.includes(formField as string)) {
              // @ts-ignore
              input[name] = formData[formField]
            }

            return input
          }, {} as Partial<SERVER_UPDATE_INPUT>) as SERVER_UPDATE_INPUT

          if (Object.keys(serverUpdateInput).length === 0) {
            await optionHooks.onUpdateWithNoChangeSet?.()
            return null
          }
          await optionHooks.beforeCallUpdateApi?.(serverUpdateInput)
          console.log(277, serverUpdateInput)
          const res = await this.config.api.update(resourceEditing!, serverUpdateInput)
          await optionHooks.afterCallUpdateApi?.(res, serverUpdateInput)
          return this.config.urlAfterUpdate(res)
        }
      },
      onUnexpectedError: (ex: Error) => {
        if (optionHooks.onUnexpectedError) {
          optionHooks.onUnexpectedError?.(ex)
        } else {
          console.error(ex)
        }
      },
      getValidationSchema: this.genValidationSchema(),
      populateDevData: this.genPopulateInput(),
      deleteItem: async () => {
        if (!isCreating) {
          await optionHooks.beforeCallDeleteApi?.()
          await this.config.api.delete(resourceEditing!)
          await optionHooks.afterCallDeleteApi?.()
        }
        return this.config.urlAfterDelete()
      },
    }
  }
}
