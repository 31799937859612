"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioUrls = void 0;
const createQueryParams = (params) => Object.keys(params)
    .filter(v => params[v] !== undefined)
    .map(k => `${k}=${params[k].toString()}`)
    .join('&');
exports.StudioUrls = {
    // 어드민에서 분리되면서, 스튜디오용으로 주소를 하나씩 분리해 나갈 예정입니다.
    Login: '/login',
    EditAuthorInfo: '/edit/authorInfo',
    FirstEditCompletedAuthorInfo: '/edit/authorInfo/firstEditCompleted',
    Logout: '/logout',
    ResetPassword: '/password/reset',
    FinishResetPassword: '/password/reset/finish',
    ChangePassword: '/password/change',
    BannedAccount: '/bannedAccount',
    ViewTerms: '/viewTerms',
    ReleaseVersion: '/releaseVersion',
    Main: '/story',
    GlobalError: '/error',
    Story: {
        List: '/story',
        Detail: {
            Root: (storyId) => `/story/${storyId}`,
            ManageStory: {
                Main: (storyId) => `/story/${storyId}/detail`,
                Edit: (storyId) => `/story/${storyId}/detail/edit`,
            },
            StoryGame: {
                Edit: (storyId) => `/story/${storyId}/storygame/edit`,
            },
            Chapter: {
                List: (storyId) => `/story/${storyId}/chapter`,
                // 인터랙티브 편집
                Edit: (storyId, chapterId, blockId, version // chapterDraftArchiveId
                ) => {
                    const query = createQueryParams({
                        blockId,
                        version,
                    });
                    return `/story/${storyId}/chapter/${chapterId}${query ? `?${query}` : ''}`;
                },
                WebNovelEdit: (storyId, chapterId, version // chapterDraftArchiveId
                ) => {
                    const query = createQueryParams({
                        version,
                    });
                    return `/story/${storyId}/chapter/novel/${chapterId}${query ? `?${query}` : ''}`;
                },
            },
            Character: {
                List: (storyId) => `/story/${storyId}/characters`,
            },
            ManageBackground: {
                List: (storyId) => `/story/${storyId}/backgrounds`,
            },
            ManageEnding: {
                List: (storyId) => `/story/${storyId}/endings`,
            },
            Prop: {
                List: (storyId) => `/story/${storyId}/props`,
            },
            ManageItem: {
                List: (storyId) => `/story/${storyId}/items`,
            },
            PlayerReport: {
                List: (storyId) => `/story/${storyId}/playerReport`,
                IDE: {
                    Root: (storyId, playerReportId) => `/story/${storyId}/playerReport/${playerReportId}`,
                    Edit: (storyId, playerReportId) => `/story/${storyId}/playerReport/${playerReportId}/edit`,
                    RenderBlock: {
                        Edit: (storyId, playerReportId, renderBlockId) => `/story/${storyId}/playerReport/${playerReportId}/renderBlock/${renderBlockId}`,
                    },
                    Code: {
                        Edit: (storyId, playerReportId, codeId) => `/story/${storyId}/playerReport/${playerReportId}/code/${codeId}`,
                    },
                    Function: {
                        Edit: (storyId, playerReportId, functionId) => `/story/${storyId}/playerReport/${playerReportId}/function/${functionId}`,
                    },
                },
            },
            ManageMember: {
                List: (storyId) => `/story/${storyId}/members`,
            },
            ManagePrice: {
                Edit: (storyId) => `/story/${storyId}/price`,
            },
            Dashboard: {
                Root: (storyId) => `/story/${storyId}/dashboard`,
                Story: (storyId) => `/story/${storyId}/dashboard/story`,
                Chapter: (storyId) => `/story/${storyId}/dashboard/chapter`,
                PopularStory: (storyId) => `/story/${storyId}/dashboard/popularStory`,
            },
            Etc: {
                Main: (storyId) => `/story/${storyId}/etc`,
            },
        },
        NotFound: `/storyNotFound`,
    },
    Admin: {
        Root: '/admin',
        Story: {
            Main: '/admin/stories',
        },
        Author: {
            Main: '/admin/authors',
        },
        Stats: {
            Main: '/admin/stats',
        },
        Banner: {
            Main: '/admin/banners',
            Create: '/admin/banners/create',
            Edit: (bannerId) => `/admin/banners/${bannerId}`,
        },
        MonthlyService: {
            Main: '/admin/monthlyService',
            Create: '/admin/monthlyService/create',
            Edit: (storyId) => `/admin/monthlyService/${storyId}`,
        },
        BundleBanner: {
            Main: '/admin/bundleBanners',
            Create: '/admin/bundleBanners/create',
            Edit: (bundleBannerId) => `/admin/bundleBanners/${bundleBannerId}`,
        },
        BottomBanner: {
            Create: '/admin/bottomBanners/create',
            Edit: (bottomBannerId) => `/admin/bottomBanners/${bottomBannerId}`,
        },
        Version: {
            Main: '/admin/versions',
            Create: `/admin/versions/create`,
            Detail: (releaseId) => `/admin/versions/${releaseId}`,
            Item: {
                Create: (releaseId) => `/admin/versions/${releaseId}/item/create`,
                Edit: (releaseId, itemId) => `/admin/versions/${releaseId}/item/${itemId}`,
            },
        },
        Chatbot: {
            Main: '/admin/chatbots',
            Create: '/admin/chatbots/create',
            Edit: (remoteScriptProviderId) => `/admin/chatbots/${remoteScriptProviderId}`,
        },
        Settings: {
            Main: '/admin/settings',
        },
        DirectAd: {
            Main: '/admin/directAds',
            Create: '/admin/directAds/create',
            Edit: (bundleBannerId) => `/admin/directAds/${bundleBannerId}`,
        },
        // 스토리 게임 지면 관리
        ManageStoryGameTabs: {
            Main: '/admin/manageStoryGameTabs',
            Section: {
                Main: (tabId) => `/admin/manageStoryGameTabs/${tabId}/sections`,
                Edit: (tabId, sectionId) => `/admin/manageStoryGameTabs/${tabId}/sections/${sectionId}`,
            },
        },
    },
};
