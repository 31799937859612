export enum RS {
  Undefined,
  NewStory,
  EditStory,
  LabelStoryScript,
  LabelStoryName,
  LabelStoryTopic,
  LabelStoryOneLineDesc,
  LabelStoryTargetAge,
  LabelStoryCoverText,
  LabelStoryDefaultFirstName,
  LabelStoryDefaultLastName,
  LabelStoryNamePlaceHolder,
  LabelStoryDefaultFirstNamePlaceHolder,
  LabelStoryDefaultLastNamePlaceHolder,
  LabelStoryTopicPlaceHolder,
  LabelStoryOneLineDescPlaceHolder,
  LabelStoryTargetAgePlaceHolder,
  LabelStoryScriptPlaceHolder,
  LabelStoryCover,
  LabelStoryCoverPreview,
  LabelStoryId,
  LabelStoryMainCharacterImage,
  LabelStoryStoryEndsAt,
  LabelStoryWillPublishAt,
}
