"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOChoice = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
/**
 * Character domain object.
 */
class DOChoice {
    constructor(store, data, chapter) {
        this.choice1PropName = null;
        this.choice2PropName = null;
        this.choice3PropName = null;
        this.choice4PropName = null;
        this.choice5PropName = null;
        this.store = store;
        this.parentChapter = chapter;
        this.data = data;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            choice1PropName: mobx_1.observable,
            choice2PropName: mobx_1.observable,
            choice3PropName: mobx_1.observable,
            choice4PropName: mobx_1.observable,
            choice5PropName: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    merge(data) {
        const fields = [
            'choiceId',
            'name',
            'choice1PropName',
            'choice2PropName',
            'choice3PropName',
            'choice4PropName',
            'choice5PropName',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    getChoiceNameOfIndex(index) {
        switch (index) {
            case 0:
                return this.choice1PropName;
            case 1:
                return this.choice2PropName;
            case 2:
                return this.choice3PropName;
            case 3:
                return this.choice4PropName;
            case 4:
                return this.choice5PropName;
        }
        return null;
    }
}
exports.DOChoice = DOChoice;
