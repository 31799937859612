"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBlockHelper = void 0;
const entity_1 = require("../entity");
const BlockEditorInput_interface_1 = require("../entity/ui/BlockEditorInput.interface");
/**
 * 서버에서 TC 작성을 하고자 하거나, 무언가 자동화를 할 때 사용하기 위한 helper class.
 * 실제 studio web 에서는 사용할 일이 없다. (물론 추후 사용가능성은 존재한다.)
 */
class DOBlockHelper {
    constructor(block, storeHelper) {
        this.storeHelper = storeHelper;
        this.block = block;
    }
    /**
     * 현재 라인에 속성값 저장을 하고 새로운 Property 를 생성하고 속성값 저장 구문을 추가한다.
     */
    async createNewPropAndInsert(isMain, name, image) {
        const editor = this.storeHelper.editor;
        editor.input.onClickButton(BlockEditorInput_interface_1.BlockEditorInputButtonType.SaveProp);
        const line = editor.showEditModalFor;
        if (isMain) {
            line.propTypeField.input.onChange(entity_1.MetaSavePropPropType.MainProp);
            line.mainPropNameField.input.onCreate(name);
        }
        else {
            line.propTypeField.input.onChange(entity_1.MetaSavePropPropType.Prop);
            line.propNameField.input.onCreate(name);
        }
        line.propEditor.propNameInput.onChange(name);
        if (image) {
            line.propEditor.imageFileInput.onChange(image, '');
        }
        await line.propEditor.onSubmit();
        line.propValueField.input.onChange('1');
        await line.submitEditing();
        await this.storeHelper.waitSubmitFinish();
        return line;
    }
    /**
     * 현재 라인에 속성값 저장을 하고 새로운 Property 를 생성하고 속성값 저장 구문을 추가한다.
     */
    async createNewItemAndInsert(name, desc = '', image = null) {
        const editor = this.storeHelper.editor;
        editor.input.onClickButton(BlockEditorInput_interface_1.BlockEditorInputButtonType.SaveProp);
        const line = editor.showEditModalFor;
        line.propTypeField.input.onChange(entity_1.MetaSavePropPropType.Item);
        line.itemNameField.input.onCreate(name);
        line.itemEditor.itemNameInput.onChange(name);
        line.itemEditor.itemDescInput.onChange(desc);
        if (image) {
            // 이미지를 업로드하고 이를 설정한다.
            const imageUploaded = await this.storeHelper.uploadFile(image, name);
            line.itemEditor.onChangeItemImageFile(imageUploaded);
        }
        await line.itemEditor.onSubmit();
        line.propValueField.input.onChange('1');
        await line.submitEditing();
        await this.storeHelper.waitSubmitFinish();
        return line;
    }
}
exports.DOBlockHelper = DOBlockHelper;
