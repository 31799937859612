import React, { CSSProperties } from 'react'
import { IODRendererDef, IODRendererProps, ODRendererComponentType, ODRendererEventType } from './common'

export interface ODBooleanRendererComponentDef extends IODRendererDef {
  component: ODRendererComponentType.BooleanRenderer
  eventKey: string
  trueString: string
  falseString: string
  style?: CSSProperties
}

interface ODBooleanRendererProps<ParentContextType, OriginalValueType>
  extends IODRendererProps<boolean, ODBooleanRendererComponentDef, ParentContextType, OriginalValueType> {
  value: boolean
  def: ODBooleanRendererComponentDef
}

export const ODBooleanRenderer: React.FC<ODBooleanRendererProps<any, any>> = (props) => {
  const {
    def: { eventKey, trueString = 'Y', falseString = 'N', style = {} },
    value,
    context,
    originalValue,
  } = props

  const handleClick = React.useCallback(
    (currentValue: boolean) => {
      context.onEvent?.(
        {
          type: ODRendererEventType.ChangeValue,
          eventKey,
          valueFrom: currentValue,
          valueTo: !currentValue,
        },
        { ...context.parentContext, renderedFromOriginalValue: originalValue }
      )
    },
    [eventKey, originalValue, context]
  )

  return (
    <span style={style} onClick={() => handleClick(value)}>
      {value ? trueString : falseString}
    </span>
  )
}

export const ODDefaultBooleanDef: ODBooleanRendererComponentDef = {
  component: ODRendererComponentType.BooleanRenderer,
  eventKey: 'NotProvided',
  trueString: 'Y',
  falseString: 'N',
}
