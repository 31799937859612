"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HellobotToStoryplayConverter = void 0;
const core_1 = require("@storyplay/core");
class HellobotToStoryplayConverter {
    /**
     *
     * @param blockGroup 헬로봇의 블록그룹
     * @param messagesMap 블록그룹에 나열된 블록들이 가진 메시지리스트를 맵으로 제공(api에서 한 번에 들어오지 않는다)
     * @returns
     */
    hbBlockDatasToSpChapterScript(blockGroup) {
        var _a;
        const blocksObject = {};
        (_a = blockGroup.blockList) === null || _a === void 0 ? void 0 : _a.forEach(block => {
            if (!!block.messageList) {
                blocksObject[block.title || ''] = this.hbBlockAndMessagesToSpBlock(block, block.messageList);
            }
        });
        return {
            startingBlock: blockGroup.blockList[0].title || '0',
            blocks: blocksObject,
            hbExtensionData: blockGroup,
        };
    }
    hbAlgorithmBlockDatasToSpChapterScript(blocklist) {
        var _a, _b;
        const blocksObject = {};
        (_a = blocklist.items) === null || _a === void 0 ? void 0 : _a.map(block => {
            var _a;
            blocksObject[(_a = block.title) !== null && _a !== void 0 ? _a : ''] = this.hbAlgorithmBlockToSpBlock(block);
        });
        return {
            startingBlock: blocklist.items && blocklist.items.length > 0
                ? (_b = blocklist.items[0].title) !== null && _b !== void 0 ? _b : 'Invalid block'
                : '',
            blocks: blocksObject,
            hbExtensionData: blocklist,
        };
    }
    hbAlgorithmBlockToSpBlock(block) {
        var _a;
        const statements = block.items
            ? block.items
                .map(message => {
                return this.hbMessageToStatement(message);
            })
                .flat()
            : [];
        return {
            isEndingBlock: false,
            name: (_a = block.title) !== null && _a !== void 0 ? _a : '',
            statements,
            hbExtensionData: block,
        };
    }
    // this is hbBlockToSpBlock
    hbBlockAndMessagesToSpBlock(block, messages) {
        var _a, _b;
        const chatBotMessages = messages
            .filter(m => { var _a; return m.kind === 'chatbot' || (m.kind === 'user' && ((_a = m.data) === null || _a === void 0 ? void 0 : _a.type) === 'rest'); })
            .sort((a, b) => { var _a, _b; return ((_a = a === null || a === void 0 ? void 0 : a.turn) !== null && _a !== void 0 ? _a : 1) - ((_b = b === null || b === void 0 ? void 0 : b.turn) !== null && _b !== void 0 ? _b : 1); })
            .map(item => this.hbMessageToStatement(item))
            .flat();
        const userMessages = messages
            .filter(m => { var _a; return m.kind === 'user' && ((_a = m.data) === null || _a === void 0 ? void 0 : _a.type) !== 'rest'; })
            .map(item => { var _a; return (_a = this.hbUserMessageAndNextTypeToStatement(item)) !== null && _a !== void 0 ? _a : []; })
            .flat();
        const statements = [...chatBotMessages, ...userMessages];
        if ((_b = (_a = block.sheetList) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) {
            return {
                isEndingBlock: false,
                name: block.title || '',
                statements,
                hbExtensionData: {
                    ...block,
                    sheetId: block.sheetList[0].id,
                },
            };
        }
        throw new Error('messages.items?.[0].sheetId는 무조건 존재해야합니다');
    }
    hbMessageToStatement(message) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const type = (_a = message.data) === null || _a === void 0 ? void 0 : _a.type;
        if (type === 'typing') {
            const statement = {
                statementType: core_1.STATEMENT_TYPE.Pause,
                sourceLine: (_b = message.id) !== null && _b !== void 0 ? _b : -1,
                background: '',
                timeout: !!((_c = message.data) === null || _c === void 0 ? void 0 : _c.speed) ? message.data.speed : -1,
                hbExtensionData: message,
            };
            return statement;
        }
        if (type === 'next') {
            const statements = this.hbUserMessageAndNextTypeToStatement(message);
            if (statements) {
                return statements.map(value => {
                    return { ...value, hbExtensionData: message };
                });
            }
            else {
                return [];
            }
        }
        if (type === 'algorithm') {
            return this.hbAlgorithmConverter(message);
        }
        const findStatementType = this.messageTypeToStatementType((_e = (_d = message.data) === null || _d === void 0 ? void 0 : _d.type) !== null && _e !== void 0 ? _e : null);
        if (!!findStatementType) {
            const res = {
                statementType: findStatementType,
                sourceLine: (_f = message.id) !== null && _f !== void 0 ? _f : 0,
                background: '',
                data: message.data,
                hbExtensionData: message,
            };
            return res;
        }
        return {
            statementType: core_1.STATEMENT_TYPE.CharacterTalk,
            sourceLine: (_g = message.id) !== null && _g !== void 0 ? _g : 0,
            background: '',
            chrName: message.senderName || 'Undefined senderName',
            message: `${(_h = message.data) === null || _h === void 0 ? void 0 : _h.type}: fallback message`,
            hbExtensionData: message,
        };
    }
    // 헬로우봇의 userMessage 와 봇메시지의 타입이 next 인 경우에는 ToBlock 문장으로 변환한다.
    hbUserMessageAndNextTypeToStatement(message) {
        var _a, _b, _c, _d, _e, _f;
        const findStatementType = this.messageTypeToStatementType((_b = (_a = message.data) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : null);
        if (!!findStatementType) {
            const res = {
                statementType: findStatementType,
                sourceLine: (_c = message.id) !== null && _c !== void 0 ? _c : 0,
                background: '',
                data: message.data,
                hbExtensionData: message,
            };
            return [res];
        }
        const linkBlocks = (_e = (_d = message.data) === null || _d === void 0 ? void 0 : _d.linkBlocks) !== null && _e !== void 0 ? _e : [];
        const linkBlockLength = linkBlocks.length;
        // 조건 분기 converter (표기 이름은 "블록 연결")
        if (linkBlockLength >= 1) {
            return [
                {
                    statementType: core_1.STATEMENT_TYPE.HbMessageNext,
                    sourceLine: (_f = message.id) !== null && _f !== void 0 ? _f : 0,
                    nextData: message.data,
                    background: '',
                    hbExtensionData: message,
                },
            ];
        }
        return null;
    }
    hbTextMessageToStatement(message) {
        var _a, _b, _c;
        return {
            statementType: core_1.STATEMENT_TYPE.CharacterTalk,
            sourceLine: (_a = message.id) !== null && _a !== void 0 ? _a : 0,
            background: '',
            chrName: message.senderName || 'Undefined senderName',
            message: (_c = (_b = message.data) === null || _b === void 0 ? void 0 : _b.text) !== null && _c !== void 0 ? _c : '',
            hbExtensionData: message,
        };
    }
    hbImageMessageToStatement(message) {
        var _a, _b, _c, _d, _e, _f, _g;
        return {
            statementType: core_1.STATEMENT_TYPE.MessageImage,
            background: '',
            sourceLine: (_a = message.id) !== null && _a !== void 0 ? _a : 0,
            chrName: (_b = message.senderName) !== null && _b !== void 0 ? _b : 'Undefined senderName',
            link: (_c = message.data) === null || _c === void 0 ? void 0 : _c.imageUrl,
            width: (_e = (_d = message.data) === null || _d === void 0 ? void 0 : _d.width) !== null && _e !== void 0 ? _e : 0,
            height: (_g = (_f = message.data) === null || _f === void 0 ? void 0 : _f.height) !== null && _g !== void 0 ? _g : 0,
            hbExtensionData: message,
        };
    }
    /** 입력값 있음, 입력값 없음 매칭불가. 현재 속성으로만 맵핑 */
    HbOperatorToConditionType(HbOperator) {
        switch (HbOperator) {
            case 'equals':
                return core_1.CONDITION_TYPE.PropertyEqualsTo;
            case 'notequals':
                return core_1.CONDITION_TYPE.PropertyNotEqualsTo;
            case 'isgreater':
                return core_1.CONDITION_TYPE.PropertyGTE;
            case 'isless':
                return core_1.CONDITION_TYPE.PropertyLTE;
            default:
                return null;
        }
    }
    hbPropertTostoryPropert(data) {
        return {
            propId: data.id,
            propName: data.title,
            propNameRaw: data.title,
            desc: null,
            propType: this.hbPropTypeToStoryPropType(data.type),
            maxValue: 100,
            unit: '',
            imageFile: null,
            isUsed: true,
            isMain: false,
            showOrder: 1000,
            displayPropName: data.title,
        };
    }
    hbPropTypeToStoryPropType(type) {
        switch (type) {
            case 'number':
                return core_1.GQLSTORY_PROP_TYPE.NUMBER;
            default:
                return core_1.GQLSTORY_PROP_TYPE.STRING;
        }
    }
    hbConditionConverter(d, message) {
        var _a, _b, _c, _d;
        let toBlocks;
        // toBlocks = d?.blocks?.map(lb => {
        //   return {
        //     statementType: STATEMENT_TYPE.ToBlock,
        //     sourceLine: 0,
        //     block: lb.title,
        //   } as STATEMENT
        // })
        // if (isLast) {
        //   toBlocks = {
        //     statementType: STATEMENT_TYPE.ToBlock,
        //     sourceLine: 0,
        //     block: d?.blocks?.[0]?.title,
        //   } as STATEMENT
        // }
        // input은 헬로우봇에서 속성이 1개만 들어가서 0index로 가져옴.
        // 헬로우봇은 행 추가가 있어서 item이 여러개가 가능함. 스플은 없음.
        const statement = d.items && ((_a = d.items) === null || _a === void 0 ? void 0 : _a.length) > 0
            ? (_c = (_b = d === null || d === void 0 ? void 0 : d.blocks) === null || _b === void 0 ? void 0 : _b.map(lb => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                const conditionType = this.HbOperatorToConditionType((_b = (_a = d.items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.operator);
                if (conditionType === null)
                    throw new Error(`conditionType이 없습니다.${(_d = (_c = d.items) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.operator}`);
                return {
                    statementType: core_1.STATEMENT_TYPE.Condition,
                    background: '',
                    sourceLine: message.id,
                    conditionAction: core_1.CONDITION_ACTION.MoveToBlock,
                    conditionActionParam: lb.title,
                    conditionNumberParam: ((_f = (_e = d.items) === null || _e === void 0 ? void 0 : _e[0].inputs) === null || _f === void 0 ? void 0 : _f[0].type) === 'number'
                        ? Number.parseInt((_h = (_g = d.items) === null || _g === void 0 ? void 0 : _g[0].compareValue) !== null && _h !== void 0 ? _h : '-1')
                        : -1,
                    conditionParam: (_k = (_j = d.items) === null || _j === void 0 ? void 0 : _j[0].inputs) === null || _k === void 0 ? void 0 : _k[0].title,
                    conditionStringParam: ((_m = (_l = d.items) === null || _l === void 0 ? void 0 : _l[0].inputs) === null || _m === void 0 ? void 0 : _m[0].type) !== 'number'
                        ? (_o = d.items) === null || _o === void 0 ? void 0 : _o[0].compareValue
                        : undefined,
                    conditionType: this.HbOperatorToConditionType((_q = (_p = d.items) === null || _p === void 0 ? void 0 : _p[0]) === null || _q === void 0 ? void 0 : _q.operator),
                    hbExtensionData: message,
                };
            })) !== null && _c !== void 0 ? _c : []
            : [
                {
                    statementType: core_1.STATEMENT_TYPE.ToBlock,
                    background: '',
                    sourceLine: message.id,
                    block: (d === null || d === void 0 ? void 0 : d.blocks) ? d === null || d === void 0 ? void 0 : d.blocks[0].title : '',
                    blocks: (_d = d === null || d === void 0 ? void 0 : d.blocks) === null || _d === void 0 ? void 0 : _d.map(d => d.title),
                    hbExtensionData: message,
                },
            ];
        return toBlocks ? statement.concat(toBlocks) : statement;
    }
    hbChoiceConverter(message) {
        var _a;
        const id = message.id;
        const buttons = (_a = message.data) === null || _a === void 0 ? void 0 : _a.buttons;
        if (message.id) {
            const choiceBlockNames = buttons
                .map(item => { var _a; return (_a = item.linkBlocks) === null || _a === void 0 ? void 0 : _a.map(item => { var _a; return (_a = item.blocks) === null || _a === void 0 ? void 0 : _a.map(d => d.title); }).flat(); })
                .filter(d => !!d)
                .flat(); // 연결된 블록 1차원배열로
            const choiceArray = [
                {
                    statementType: core_1.STATEMENT_TYPE.ConditionalChoice,
                    background: '',
                    sourceLine: message.id,
                    choices: buttons.map((item) => item.text),
                    choiceName: 'choice_' + id,
                    choiceBlockNames,
                    hbExtensionData: message,
                    buttons,
                },
            ];
            return choiceArray;
        }
        throw new Error('hbChoiceConverter message.id 없음');
    }
    hbAlgorithmConverter(message) {
        var _a, _b, _c, _d, _e;
        const id = message.id;
        const algorithmId = (_b = (_a = message.data) === null || _a === void 0 ? void 0 : _a.group) === null || _b === void 0 ? void 0 : _b.id;
        if (id && algorithmId && algorithmId !== 0) {
            return {
                sourceLine: id,
                statementType: core_1.STATEMENT_TYPE.Algorithm,
                blockName: (_d = (_c = message.data) === null || _c === void 0 ? void 0 : _c.group) === null || _d === void 0 ? void 0 : _d.title,
                algorithmGroupId: algorithmId,
                background: '',
                algorithmData: (_e = message.data) === null || _e === void 0 ? void 0 : _e.group,
                hbExtensionData: message,
                id,
            };
        }
        throw new Error('hbChoiceConverter message.id or algorithmId 없음');
    }
    async hbAlgorithGroupBlockToHbAlgorithmGQLChapter(blockGroup, blocks) {
        var _a, _b, _c;
        const chapterScript = JSON.stringify(this.hbAlgorithmBlockDatasToSpChapterScript(blocks));
        const defaultTimeString = '1970-01-01T00:00:00.000Z';
        return {
            createdAt: new Date((_a = blockGroup.regDate) !== null && _a !== void 0 ? _a : defaultTimeString),
            updatedAt: new Date((_b = blockGroup.modDate) !== null && _b !== void 0 ? _b : defaultTimeString),
            chapterId: blockGroup.id,
            name: blockGroup.title || '',
            story: '',
            storyId: blockGroup.botId,
            publishedAt: new Date((_c = blockGroup.modDate) !== null && _c !== void 0 ? _c : defaultTimeString),
            willPublishAt: new Date(),
            price: 7777,
            freedAt: new Date(),
            freedAtRaw: new Date(),
            willFreeAt: new Date(),
            willFreeAtRaw: new Date(),
            isFinal: true,
            chapterScript,
            scriptFile: null,
            scriptFileId: null,
            endings: [],
            choices: [],
            chapterIndex: 0,
            isHidden: false,
            syncToken: '',
            remainingTimeToFree: null,
            hasNewBadge: false,
            customId: null,
            defaultEnding: null,
            defaultEndingId: null,
            hasGraphIcon: false,
            freeDependencyChapterIndex: null,
            freeDependencyPeriod: null,
            isDraft: 1,
            isVerified: 1,
            hasBGM: false,
            onboardingPopups: null,
            isTimeLeapFree: false,
            draft: null,
            numBlocks: null,
            numChars: null,
            numSens: null,
            stats: null,
            numBlocksDraft: null,
            numCharsDraft: null,
            numSensDraft: null,
            statsDraft: null,
            adsOn: false,
            chapterOption: null,
            chapterOptionByAdmin: null,
            appliedDraftId: -1,
            hbExtensionData: blockGroup,
        };
    }
    messageTypeToStatementType(type) {
        if (type === 'text') {
            return core_1.STATEMENT_TYPE.HbText;
        }
        if (type === 'image') {
            return core_1.STATEMENT_TYPE.HbImage;
        }
        if (type === 'rest') {
            return core_1.STATEMENT_TYPE.HbRest;
        }
        if (type === 'resultImage') {
            return core_1.STATEMENT_TYPE.HbResultImage;
        }
        if (type === 'carousel') {
            return core_1.STATEMENT_TYPE.HbCarousel;
        }
        if (type === 'report') {
            return core_1.STATEMENT_TYPE.HbReport;
        }
        if (type === 'adjustment') {
            return core_1.STATEMENT_TYPE.HbAdjustment;
        }
        if (type === 'charge') {
            return core_1.STATEMENT_TYPE.HbCharge;
        }
        if (type === 'url') {
            return core_1.STATEMENT_TYPE.HbUrl;
        }
        if (type === 'audio') {
            return core_1.STATEMENT_TYPE.HbAudio;
        }
        if (type === 'limit') {
            return core_1.STATEMENT_TYPE.HbLimit;
        }
        if (type === 'advertisement') {
            return core_1.STATEMENT_TYPE.HbAdvertisement;
        }
        if (type === 'matching') {
            return core_1.STATEMENT_TYPE.HbMatching;
        }
        if (type === 'jsonApi') {
            return core_1.STATEMENT_TYPE.HbJsonApi;
        }
        if (type === 'feedback') {
            return core_1.STATEMENT_TYPE.HbUserFeedBack;
        }
        if (type === 'select') {
            return core_1.STATEMENT_TYPE.HbUserSelect;
        }
        if (type === 'input') {
            return core_1.STATEMENT_TYPE.HbUserInput;
        }
        if (type === 'template') {
            return core_1.STATEMENT_TYPE.HbUserTemplate;
        }
        if (type === 'tarot') {
            return core_1.STATEMENT_TYPE.HbUserTarot;
        }
        if (type === 'imageUpload') {
            return core_1.STATEMENT_TYPE.HbUserImageUpload;
        }
        return null;
    }
}
exports.HellobotToStoryplayConverter = HellobotToStoryplayConverter;
