"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PDOperationName = void 0;
var PDOperationName;
(function (PDOperationName) {
    /**
     * Rand operation.
     *
     * - args[0] 값과 args[1] 값 사이의 임의의 정수값을 반환한다.
     * - args[0]가 args[1]보다 큰 경우에도 동작해야 한다.
     */
    PDOperationName["Rand"] = "Rand";
    /**
     * GetValue operation.
     *
     * - args 의 갯수는 여러개일 수 있습니다. (여러개여야 하는 경우가 대부분입니다)
     * - PlayData 에서 앞에서부터 순차적으로 따라 들어가서 나오는 최종 값을 반환합니다.
     */
    PDOperationName["GetValue"] = "GetValue";
    /**
     * Sum operation.
     *
     * - 모든 args 를 더합니다. 만약 args 에 숫자로 변환이 불가능한 값이 있다면 0으로 취급합니다.
     */
    PDOperationName["Sum"] = "Sum";
    /**
     * Add operation.
     *
     * - args[0] + args[1]
     */
    PDOperationName["Add"] = "Add";
    /**
     * Minus operation.
     *
     * - args[0] - args[1]
     */
    PDOperationName["Minus"] = "Minus";
    //
    // Boolean expressions
    //  - 값이 참이면 '1', 거짓이면 '0' 을 반환한다. (반환값은 문자열이다)
    //  - 숫자로 파싱가능한 문자열이 아닌 경우 false 로 판단한다.
    //  - 숫자로 파싱가능한 문자열인 경우 0이 아니면 참값으로 판단한다.
    //
    /**
     * Multiply operation.
     *
     * - args[0] * args[1]
     */
    PDOperationName["Mul"] = "Mul";
    /**
     * Divide operation.
     *
     * - args[0] / args[1]
     */
    PDOperationName["Div"] = "Div";
    /**
     * Power operation.
     *
     * - args[0] ^ args[1]
     */
    PDOperationName["Pow"] = "Pow";
    /**
     * args[0] < args[1]
     */
    PDOperationName["LT"] = "LT";
    /**
     * args[0] > args[1]
     */
    PDOperationName["GT"] = "GT";
    /**
     * args[0] <= args[1]
     */
    PDOperationName["LTE"] = "LTE";
    /**
     * args[0] >= args[1]
     */
    PDOperationName["GTE"] = "GTE";
    /**
     * args[0] == args[1]
     */
    PDOperationName["Equals"] = "Equals";
    /**
     * args[0] != args[1]
     */
    PDOperationName["NotEquals"] = "NotEquals";
    /**
     * args[0] ... args[N] 이 모두 참값인가?
     */
    PDOperationName["AND"] = "AND";
    /**
     * args[0] ... args[N] 중 하나라도 참값인가?
     */
    PDOperationName["OR"] = "OR";
    /**
     * MinPropKey operation.
     *
     * - 모든 args들 중에 가장 작은 속성값의 Key를 반환 합니다.
     * 예시) 매력
     */
    PDOperationName["MinPropKey"] = "MinPropKey";
    /**
     * MaxPropKey operation.
     *
     * - 모든 args들 중에 가장 큰 속성값의 Key를 반환 합니다.
     * 예시) 지력
     */
    PDOperationName["MaxPropKey"] = "MaxPropKey";
    /**
     * MinItemKey operation.
     *
     * - 모든 args들 중에 가장 작은 아이템값의 Key를 반환 합니다.
     * 예시) 방패
     */
    PDOperationName["MinItemKey"] = "MinItemKey";
    /**
     * MaxItemKey operation.
     *
     * - 모든 args들 중에 가장 큰 아이템값의 Key를 반환 합니다.
     * 예시) 방패
     */
    PDOperationName["MaxItemKey"] = "MaxItemKey";
    /**
     * 해당 값을 문자열로 처리한다.
     */
    PDOperationName["String"] = "String";
    /**
     * SetProp
     *
     * - tempProps[args[0]] <- args[1]
     */
    // SetProp = 'SetProp',
})(PDOperationName = exports.PDOperationName || (exports.PDOperationName = {}));
