"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityEditor = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const errors_1 = require("../../../errors");
const Waiter_1 = require("../utils/Waiter");
const fields_1 = require("./fields");
class EntityEditor {
    constructor(target) {
        this._editorFields = [];
        this._editorFieldsLoaded = false;
        this.isSubmitting = false;
        this._target = target;
        (0, mobx_1.makeObservable)(this, {
            isSubmitting: mobx_1.observable,
        });
    }
    get editorFields() {
        if (!this._editorFieldsLoaded) {
            this._editorFields = (0, fields_1.enumerateEditorField)(this);
        }
        return this._editorFields;
    }
    /**
     * 특별한 방법으로 override 를 할 수 있도록 하기 위한 함수 포인트
     * @protected
     */
    onFieldValueChangeFound(field, v, changeSet) {
        ;
        changeSet[field.fieldId] = v;
    }
    createChangeSet() {
        const changeSet = {};
        this.editorFields.forEach(fld => {
            if (fld.hasChange) {
                this.onFieldValueChangeFound(fld, fld.value, changeSet);
            }
        });
        return (0, lodash_1.isEmpty)(changeSet) ? null : changeSet;
    }
    async submitChanges(onError) {
        if (this.isSubmitting) {
            // tslint:disable-next-line:no-console
            console.warn(`isSubmitting = true => isSubmitting 중일 때는 로딩창을 넣어주세요.`);
            onError === null || onError === void 0 ? void 0 : onError(new errors_1.SPCError(errors_1.ErrorCode.SUBMIT_IN_PROGRESS));
            return false;
        }
        const waiter = new Waiter_1.Waiter();
        (0, mobx_1.runInAction)(() => (this.isSubmitting = true));
        try {
            const cs = this.createChangeSet();
            if (cs === null) {
                onError === null || onError === void 0 ? void 0 : onError(new errors_1.SPCError(errors_1.ErrorCode.CHANGE_NOT_FOUND));
                (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
                return false;
            }
            const res = await this._target.update(cs);
            this._target.merge(res);
            // 변경된 defValue 의 값을 적용한다.
            this._editorFields.forEach(fld => fld.hasChange && fld.commitChanges());
            await waiter.waitMinimum(700);
        }
        catch (ex) {
            (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
            onError === null || onError === void 0 ? void 0 : onError(ex);
            return false;
        }
        (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
        return true;
    }
}
exports.EntityEditor = EntityEditor;
