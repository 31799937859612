export const TOOLBAR_HEIGHT = 40

const createFields = (fieldArray: string[]) => fieldArray.join('\n')
const COMMON_USER_FIELDS = [
  'userId',
  'name',
  'email',
  'lastLoginToken',
  'lastAccessTime',
  'unread',
  'priv',
  'createdAt',
  'updatedAt',
  'isTester',
  'leftAt',
  'deactivateReason',
  'bannedAt',
]
const COMMON_STORY_FIELDS = [
  'storyId',
  'name',
  'script',
  'publishedAt',
  'createdAt',
  'shareKey',
  'showOrder',
  'representedAt',
  'isFinished',
  'studioPriceSetting',
  'isAdult',
  'storygameId',
]

export const USER_FULL_SNAPSHOT = `
  ${createFields(COMMON_USER_FIELDS)}
  sysLang {
    code
  }
`

export const STORY_FULL_SNAPSHOT = `
  ${createFields(COMMON_STORY_FIELDS)}
`

export const USER_PRIV = {
  Normal: 1, // Anonymous
  Virtual: 2, // 가상 유저
  Formal: 3, // 정식 가입
  Author: 5, // 작가
  SuperAuthor: 6, // 모든 작품을 조회/수정할 수 있는 어드민이 지정한 상위 작가
  Admin: 7, // 어드민
  SuperAdmin: 10, // 슈퍼 어드민
}

export const USER_PRIV_TO_STRING = {
  [USER_PRIV.Normal]: '익명',
  [USER_PRIV.Virtual]: '가상유저',
  [USER_PRIV.Formal]: '정식가입',
  [USER_PRIV.Author]: '작가',
  [USER_PRIV.SuperAuthor]: '슈퍼작가',
  [USER_PRIV.Admin]: '관리자',
  [USER_PRIV.SuperAdmin]: '슈퍼관리자',
}
