import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { GQLEndingCard } from '../../../@types/server'
import { ENDING_CARD_TYPE } from '../../../common'
import { HoverTR } from '../../../components/commonStyle'
import { SiteUrls } from '../../../urls'
import {
  EndingCardPrimaryKey,
  IEndingCardListableOption,
} from '../EndingCardCommon'
import { useEndingCardListDataLoader } from './EndingCardListDataLoader'

interface IEndingCardListableTableProps {
  storyId: number
  endingId: number
  isFinal: boolean
}

export const EndingCardListableTable: React.FC<IEndingCardListableTableProps> = (
  props
) => {
  const { endingId, storyId, isFinal } = props
  const [loading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLEndingCard,
    IEndingCardListableOption
  >()
  const [token] = useCounter()
  const dataLoader = useEndingCardListDataLoader(storyId, endingId)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[EndingCardPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 15,
          float: 'right',
        }}
      >
        <ODButton
          theme={ODButtonTheme.Primary}
          size={ODButtonSize.Small}
          style={{ minWidth: 135, borderRadius: 5, height: 35 }}
          onClick={() =>
            setRedirect(
              SiteUrls.Admin.Story.EditEndingCard(storyId, endingId, isFinal, 0)
            )
          }
        >
          엔딩 카드 생성
        </ODButton>
      </div>
      <ODListablePaginatedTable2
        numColumns={4}
        listableContext={Context}
        renderHeader={() => (
          <tr>
            <th>카드 아이디</th>
            <th>카드 타입</th>
            <th>노출 우선순위</th>
            <th>캐릭터 이름</th>
            <th>캐릭터 이미지</th>
          </tr>
        )}
        renderRow={(value: GQLEndingCard, context) => {
          const cardTypeName =
            {
              [ENDING_CARD_TYPE.ArrivalRate]: '[작품] 최종 엔딩 도착률',
              [ENDING_CARD_TYPE.ChoiceCount]: '[전체] 선택 누적 횟수',
              [ENDING_CARD_TYPE.EndingCollectRate]: '[작품] 엔딩 수집률',
            }[value.type] || ''
          return (
            <HoverTR
              key={value.cardId}
              onClick={() =>
                setRedirect(
                  SiteUrls.Admin.Story.EditEndingCard(
                    storyId,
                    endingId,
                    isFinal,
                    value.cardId
                  )
                )
              }
            >
              <td>{value.cardId}</td>
              <td>{cardTypeName}</td>
              <td>{value.showOrder}</td>
              <td>{value.character.name}</td>
              <td>
                {value.character.imageFile?.link ? (
                  <img
                    src={value.character.imageFile!.link}
                    width={100}
                    height={100}
                    alt="ending shows property"
                  />
                ) : (
                  <span>미등록</span>
                )}
              </td>
            </HoverTR>
          )
        }}
        eventParentContext={{}}
      />
      <ODListablePagination hideIfSinglePage listableContext={Context} />
    </Provider>
  )
}
