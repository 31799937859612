"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbBotAPI = void 0;
const utils_1 = require("../../../utils");
class HbBotAPI {
    constructor(server) {
        this.server = server;
    }
    async get(id) {
        return this.server.get(`/v1/bots/${id}`);
    }
    create(payload) {
        return this.server.postFormData(`/v1/bots`, (0, utils_1.jsonToFormData)(payload));
    }
    async update(id, payload) {
        return this.server.postFormData(`/v1/bots/${id}`, (0, utils_1.jsonToFormData)(payload));
    }
    async getBotList(data) {
        const res = await this.server.get('/v1/bots', data);
        return res;
    }
    async checkDuplicate(name) {
        return this.server
            .get(`/v2/bots/name?name=${name}`)
            .catch(() => null);
    }
    async checkUrl(url) {
        return this.server.post('/v1/bots/url/check', { url });
    }
    async updateCache(id) {
        return this.server.post(`/v1/bots/cache/${id}`, {}, { useErrorResponse: true });
    }
}
exports.HbBotAPI = HbBotAPI;
