"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOChr = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
const DOOnboardingPopup_1 = require("../onboardingPopup/DOOnboardingPopup");
/**
 * Character domain object.
 */
class DOChr {
    constructor(store, data) {
        var _a, _b, _c, _d, _e;
        this.displayName = '';
        // 주인공 캐릭터는, "가상"이다.
        this.isHero = false;
        this.store = store;
        this.data = data;
        this.id = data.chrId;
        this.name = data.name.trim();
        this.isMain = data.main;
        this.color = data.color;
        this.imageFile = (_a = data.imageFile) !== null && _a !== void 0 ? _a : null;
        this.portraitFile = (_b = data.portraitFile) !== null && _b !== void 0 ? _b : null;
        this.defaultFirstName = (_c = data.defaultFirstName) !== null && _c !== void 0 ? _c : null;
        this.defaultLastName = (_d = data.defaultLastName) !== null && _d !== void 0 ? _d : null;
        this.displayName = data.displayName;
        this.onboardingPopups = ((_e = data.onboardingPopups) !== null && _e !== void 0 ? _e : []).map(v => new DOOnboardingPopup_1.DOOnboardingPopup(v));
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            imageFile: mobx_1.observable,
            portraitFile: mobx_1.observable,
            isMain: mobx_1.observable,
            color: mobx_1.observable,
            displayName: mobx_1.observable,
            onboardingPopups: mobx_1.observable,
            merge: mobx_1.action,
            link: mobx_1.computed,
            chrName: mobx_1.computed,
        });
    }
    merge(data) {
        (0, mobx_1.runInAction)(() => {
            (0, storeUtils_1.assignIf)(data, 'name', v => (this.name = v.trim()));
            (0, storeUtils_1.assignIf)(data, 'imageFile', v => {
                this.imageFile = v;
            });
            (0, storeUtils_1.assignIf)(data, 'portraitFile', v => {
                this.portraitFile = v;
            });
            (0, storeUtils_1.assignIf)(data, 'main', v => (this.isMain = v));
            (0, storeUtils_1.assignIf)(data, 'color', v => (this.color = v));
            (0, storeUtils_1.assignIf)(data, 'defaultFirstName', v => (this.defaultFirstName = v));
            (0, storeUtils_1.assignIf)(data, 'defaultLastName', v => (this.defaultLastName = v));
            (0, storeUtils_1.assignIf)(data, 'displayName', v => (this.displayName = v));
            (0, storeUtils_1.assignIf)(data, 'onboardingPopups', v => (this.onboardingPopups = (v !== null && v !== void 0 ? v : []).map((p) => new DOOnboardingPopup_1.DOOnboardingPopup(p))));
            (0, storeUtils_1.assignIf)(data, 'onboardingPopups', v => {
                if (v) {
                    for (const popup of v) {
                        const existing = this.onboardingPopups.find(p => p.popupId === popup.popupId);
                        if (existing) {
                            existing.merge(v);
                        }
                        else {
                            this.onboardingPopups = [
                                ...this.onboardingPopups,
                                new DOOnboardingPopup_1.DOOnboardingPopup(v),
                            ];
                        }
                    }
                }
            });
        });
        return this;
    }
    get link() {
        var _a, _b;
        return (_b = (_a = this.imageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null;
    }
    get portraitLink() {
        var _a, _b;
        return (_b = (_a = this.portraitFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null;
    }
    get chrName() {
        if (this.name === this.displayName) {
            return this.name;
        }
        return `${this.displayName}(${this.name})`;
    }
    exportMetaDataUpdateActions() {
        var _a, _b;
        return [
            {
                action: core_1.MetaDataAction.ChrSetProperties,
                scope: (0, core_1.genScopeString)(null),
                chrName: this.name,
                isMain: this.isMain,
                color: this.color,
                link: (_a = this.link) !== null && _a !== void 0 ? _a : '',
                hash: (_b = this.link) !== null && _b !== void 0 ? _b : '',
                isHero: false,
            },
        ];
    }
    toString() {
        return this.name;
        // return JSON.stringify(this.data)
    }
    get serverId() {
        return this.id;
    }
    async create() {
        var _a, _b;
        const input = {
            name: this.name,
            storyId: this.store.story.storyId,
            main: this.isMain,
            color: this.color,
            defaultFirstName: null,
            defaultLastName: null,
        };
        if ((_a = this.imageFile) === null || _a === void 0 ? void 0 : _a.fileId) {
            input.imageFromStudioFileId = this.imageFile.fileId;
        }
        if ((_b = this.portraitFile) === null || _b === void 0 ? void 0 : _b.fileId) {
            input.portraitFromStudioFileId = this.portraitFile.fileId;
        }
        const entity = await this.store.rootStore.di.server.createEntityForStudio(this, input);
        // 로컬에서 사용하던 아이디를 버리고, 서버와 동기화된 아이디로 새롭게 등록한다.
        this.id = entity.chrId;
        this.store.merge(entity);
        return entity;
    }
    async update(changeSet) {
        return this.store.rootStore.di.server.updateEntityForStudio(this, changeSet);
    }
    removeOnboardingPopup(id) {
        (0, mobx_1.runInAction)(() => {
            this.onboardingPopups = this.onboardingPopups.filter(v => v.popupId !== id);
        });
    }
    shouldBeFilteredBy(keyword) {
        return this.chrName.toLowerCase().includes(keyword);
    }
}
exports.DOChr = DOChr;
