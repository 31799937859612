import {
  ODEntityInput,
  ODEntityLabeled,
  ODImageFileInput,
  useCounter,
  useODMutation,
  useODQuery,
} from '@odc/od-react-belt'
import { DIRECT_ADS_TYPE } from '@storyplay/core'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLDirectAds,
  GQLDirectAdsCreationInput,
  GQLDirectAdsUpdateInput,
  GQLOkResponse,
  GQLSingleIDInput,
} from '../../../@types/server'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import {
  GQL_CREATE_DIRECT_ADS,
  GQL_GET_DIRECT_ADS_BY_ADMIN,
  GQL_REMOVE_DIRECT_ADS,
  GQL_UPDATE_DIRECT_ADS,
} from '../../../hooks/gqls_admin'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IDirectAdsEditPageProps {
  directAdsId: number | null
}

type Entity = GQLDirectAds

function getValidationSchema(values: any) {
  return Yup.object().shape({})
}

export const DirectAdsEditPage: React.FC<IDirectAdsEditPageProps> = (props) => {
  const { directAdsId } = props
  const innerRef = React.useRef(null)
  const apiCreate = useODMutation<
    Partial<GQLDirectAdsCreationInput>,
    Partial<Entity>
  >(GQL_CREATE_DIRECT_ADS)
  const apiUpdate = useODMutation<
    Partial<GQLDirectAdsUpdateInput>,
    Partial<Entity>
  >(GQL_UPDATE_DIRECT_ADS)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(
    GQL_GET_DIRECT_ADS_BY_ADMIN
  )
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_REMOVE_DIRECT_ADS
  )

  const [token] = useCounter()
  const isCreating = !directAdsId

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLDirectAdsCreationInput | GQLDirectAdsUpdateInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (directAdsId) {
          return apiGet({ id: directAdsId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            title: null,
            imageFile: null,
            type: DIRECT_ADS_TYPE.Splash,
            link: null,
            isHidden: true,
            aOSFallbackLink: null,
            iOSFallbackLink: null,
          }
        }

        return {
          title: data.title,
          imageFile: data.imageFile,
          type: data.type,
          link: data.link,
          isHidden: data.isHidden,
          aOSFallbackLink: data.aOSFallbackLink,
          iOSFallbackLink: data.iOSFallbackLink,
        }
      },
      saveClientValueToServer: async (
        data: Partial<GQLDirectAdsCreationInput | GQLDirectAdsUpdateInput>
      ) => {
        if (directAdsId) {
          await apiUpdate({
            id: directAdsId,
            title: data.title,
            imageFile: data.imageFile,
            isHidden: data.isHidden,
            link: data.link,
            aOSFallbackLink: data.aOSFallbackLink,
            iOSFallbackLink: data.iOSFallbackLink,
          })
          Utils.showSuccess('수정되었습니다.', 'Success')
        } else {
          const {
            title,
            isHidden,
            type,
            link,
            aOSFallbackLink,
            iOSFallbackLink,
            imageFile,
          } = data as GQLDirectAdsCreationInput
          await apiCreate({
            title,
            type,
            isHidden,
            imageFile,
            link,
            aOSFallbackLink,
            iOSFallbackLink,
          })
          Utils.showSuccess('생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.DirectAds.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (directAdsId) {
          await apiRemove({ id: directAdsId })
          Utils.showSuccess('삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.DirectAds.List
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [directAdsId, apiGet, apiUpdate, apiCreate, token, apiRemove]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<GQLDirectAdsCreationInput | GQLDirectAdsUpdateInput>
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<GQLDirectAdsCreationInput | GQLDirectAdsUpdateInput>
    >(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          Partial<GQLDirectAdsCreationInput | GQLDirectAdsUpdateInput>
        >(options)
      ),
    [options]
  )

  const title = isCreating ? '직영광고 생성' : '직영광고 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            <hr />
            <ODEntityInput
              keyPath="title"
              label="이름"
              name="title"
              placeholder="이름"
              inputType="text"
              // inputProps={{ disabled: true }}
            />
            <ODEntityLabeled label="이미지 파일" name="imageFile">
              <ODImageFileInput
                name="imageFile"
                height={200}
                width={200}
                keyPath="imageFile.link"
              />
            </ODEntityLabeled>
            {!isCreating ? (
              <></>
            ) : (
              <ODEntityLabeled label="타입" name="type">
                <ToggleButtonWrapper>
                  <ODFormToggleButton
                    key="type_splash"
                    name="type"
                    keyPath="type"
                    value={DIRECT_ADS_TYPE.Splash}
                  >
                    스플래쉬
                  </ODFormToggleButton>
                  <ODFormToggleButton
                    key="type_home"
                    name="type"
                    keyPath="type"
                    value={DIRECT_ADS_TYPE.Home}
                  >
                    홈
                  </ODFormToggleButton>
                  <ODFormToggleButton
                    key="type_details"
                    name="type"
                    keyPath="type"
                    value={DIRECT_ADS_TYPE.Details}
                  >
                    작품상세
                  </ODFormToggleButton>
                  <ODFormToggleButton
                    key="type_history"
                    name="type"
                    keyPath="type"
                    value={DIRECT_ADS_TYPE.History}
                  >
                    기록탭
                  </ODFormToggleButton>
                  <ODFormToggleButton
                    key="type_more"
                    name="type"
                    keyPath="type"
                    value={DIRECT_ADS_TYPE.More}
                  >
                    더보기
                  </ODFormToggleButton>
                  <ODFormToggleButton
                    key="type_chapterEnding"
                    name="type"
                    keyPath="type"
                    value={DIRECT_ADS_TYPE.ChapterEnding}
                  >
                    회차 엔딩
                  </ODFormToggleButton>
                </ToggleButtonWrapper>
              </ODEntityLabeled>
            )}
            <ODEntityLabeled label="숨김?" name="isHidden">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isHidden_true"
                  name="isHidden"
                  keyPath="isHidden"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isHidden_false"
                  name="isHidden"
                  keyPath="isHidden"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="link"
              label="링크"
              name="link"
              placeholder="링크"
              inputType="text"
              // inputProps={{ disabled: true }}
            />
            <ODEntityInput
              keyPath="aOSFallbackLink"
              label="aOS 폴백 링크"
              name="aOSFallbackLink"
              placeholder="aOS 폴백 링크"
              inputType="text"
              // inputProps={{ disabled: true }}
            />
            <ODEntityInput
              keyPath="iOSFallbackLink"
              label="iOS 폴백 링크"
              name="iOSFallbackLink"
              placeholder="iOS 폴백 링크"
              inputType="text"
              // inputProps={{ disabled: true }}
            />
            <hr />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={directAdsId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
