"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbBase = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const HbMessageDataField_1 = require("../fields/HbMessageDataField");
const DOBaseScriptStatement_1 = require("./DOBaseScriptStatement");
class DOSTHbBase extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.dataForSubmitField = new HbMessageDataField_1.HbMessageDataField('dataForSubmit', () => (!this.st.hbExtensionData ? null : this.getDataForSubmit()), async (v) => {
            if (!v) {
                // 삭제 처리 (언두시에) 이거는 지금 처리 안 해도 됨 자동으로 되고 있음, 확인은 필요 (removeLine)
                return;
            }
            // 초기 데이터가 없었다면 생성 처리
            if (!this.isFirstCreated) {
                await this.createHb(v);
                return;
            }
            await this.updateHb(v);
            // 초기 데이터가 있었다면 업데이트 처리
        });
        (0, mobx_1.makeObservable)(this, {
            isValidAll: mobx_1.computed,
            isFirstCreated: mobx_1.computed,
            st: mobx_1.computed,
            selectedChrOfInput: mobx_1.computed,
            selectedChrOfMessage: mobx_1.computed,
            chr: mobx_1.computed,
        });
    }
    // api 호출 성공시 호출할 데이터 업데이트 함수
    updateData(data) {
        (0, mobx_1.runInAction)(() => {
            this.st.hbExtensionData = data;
            if ('data' in this.st) {
                this.st.data = data.data;
            }
        });
    }
    /**
     * 메시지의 캐릭터의 정보를 반환해줍니다. (챗봇 타입의 메시지에만 사용)
     */
    get chr() {
        var _a, _b, _c;
        const rootStore = this.rootStore;
        if (this.isUserKindMessage &&
            this.statementType === core_1.STATEMENT_TYPE.HbRest) {
            return null;
        }
        // 알고리즘 블록의 경우에는 메시지가 sender에 대한 정보를 이미 갖고 있습니다.
        if (rootStore.isAlgorithmStore) {
            return {
                name: this.st.hbExtensionData.senderName || '-',
                link: (_a = this.st.hbExtensionData.senderProfileUrl) !== null && _a !== void 0 ? _a : null,
            };
        }
        const bot = this.block.parentChapter.story;
        const charsWithoutUser = bot.characterList.filter(c => !c.isHero);
        const charByBot = charsWithoutUser.find(c => c.name === bot.name);
        const chrId = (_b = this.st.hbExtensionData.senderId) !== null && _b !== void 0 ? _b : charByBot === null || charByBot === void 0 ? void 0 : charByBot.id;
        // 헬봇의 사용자는 isHero 가 true 입니다.
        // 사용자는 조회할 필요가 없으므로 패스,
        let chr = charsWithoutUser.find(c => c.id === chrId);
        return chr ? { name: chr.name, link: (_c = chr.link) !== null && _c !== void 0 ? _c : null } : null;
    }
    get apiServer() {
        return this.rootStore.apiServer;
    }
    // 인풋 영역에서 선택된 캐릭터, 메시지를 생성할때 사용
    get selectedChrOfInput() {
        var _a;
        return (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.input.selectedCharacter;
    }
    // 메시지의 발화자
    get selectedChrOfMessage() {
        var _a;
        return (_a = this.rootStore.storyEditing) === null || _a === void 0 ? void 0 : _a.chrStore.all.find(chr => chr.id === this.st.hbExtensionData.senderId);
    }
    get editorFields() {
        return [...super.editorFields, this.dataForSubmitField];
    }
    get st() {
        return this.data;
    }
    get isFirstCreated() {
        var _a;
        return !!((_a = this.st.hbExtensionData) === null || _a === void 0 ? void 0 : _a.id);
    }
    removeSelf() {
        const op = this.helper.opFactory();
        op.startBulk()
            .removeLinesFromBlock(this.block.uniqueId, [this.uniqueId])
            .submitBulk();
    }
    async submitEditing(opFactory) {
        // 인터페이스 맞추기용, 에러 발생할때만 return 해 줄 용도
        const op = opFactory !== null && opFactory !== void 0 ? opFactory : this.helper.opFactory();
        try {
            if (!this.isValidAll) {
                throw new Error('입력한 값이 올바르지 않습니다.');
            }
            // 실제 setter 가 실행되기 이전에 밸리데이션 검사를 하고 수정을 해준다.
            this.dataForSubmitField.onChange(this.getDataForSubmit());
            if (!this.dataForSubmitField.value) {
                throw new Error('입력한 값이 올바르지 않습니다.');
            }
        }
        catch (ex) {
            this.rootStore.showError(ex);
            return op;
        }
        return super.submitEditing(opFactory);
    }
    get isUserKindMessage() {
        return [
            core_1.STATEMENT_TYPE.HbUserSelect,
            core_1.STATEMENT_TYPE.HbUserInput,
            core_1.STATEMENT_TYPE.HbUserTemplate,
            core_1.STATEMENT_TYPE.HbUserTarot,
            core_1.STATEMENT_TYPE.HbUserFeedBack,
        ].includes(this.statementType);
    }
    async createHb(createPayload, messageFullData) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        const blockOfSt = this.block;
        const isUserKind = this.isUserKindMessage;
        const isRest = this.statementType === core_1.STATEMENT_TYPE.HbRest;
        const objByGroup = this.rootStore.isAlgorithmStore
            ? {
                algorithmId: blockOfSt.hbExtensionData.id,
            }
            : isUserKind
                ? {
                    blockId: blockOfSt.hbExtensionData.id,
                }
                : {
                    sheetId: (_a = blockOfSt.hbExtensionData) === null || _a === void 0 ? void 0 : _a.sheetId,
                    turn: (_b = messageFullData === null || messageFullData === void 0 ? void 0 : messageFullData.turn) !== null && _b !== void 0 ? _b : ((_d = (_c = this.blockEditor) === null || _c === void 0 ? void 0 : _c.indexToAdd) !== null && _d !== void 0 ? _d : 1) + 1,
                };
        console.log(messageFullData, this.selectedChrOfInput);
        const payload = {
            data: createPayload,
            kind: isUserKind ? 'user' : 'chatbot',
            senderId: isUserKind || isRest
                ? undefined
                : (_g = (_e = messageFullData === null || messageFullData === void 0 ? void 0 : messageFullData.senderId) !== null && _e !== void 0 ? _e : (_f = this.selectedChrOfInput) === null || _f === void 0 ? void 0 : _f.id) !== null && _g !== void 0 ? _g : undefined,
            senderName: isUserKind || isRest
                ? undefined
                : (_k = (_h = messageFullData === null || messageFullData === void 0 ? void 0 : messageFullData.senderName) !== null && _h !== void 0 ? _h : (_j = this.selectedChrOfInput) === null || _j === void 0 ? void 0 : _j.chrName) !== null && _k !== void 0 ? _k : undefined,
            senderProfileUrl: isUserKind || isRest
                ? undefined
                : (_o = (_l = messageFullData === null || messageFullData === void 0 ? void 0 : messageFullData.senderProfileUrl) !== null && _l !== void 0 ? _l : (_m = this.selectedChrOfInput) === null || _m === void 0 ? void 0 : _m.link) !== null && _o !== void 0 ? _o : undefined,
            ...objByGroup,
        };
        try {
            const res = await this.rootStore.di.server.hbClient.message.create(payload, this.rootStore.isAlgorithmStore);
            this.updateData(res);
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw ex;
        }
    }
    async updateHb(payload) {
        var _a;
        try {
            if (!((_a = this.st.hbExtensionData) === null || _a === void 0 ? void 0 : _a.id)) {
                throw new Error('업데이트를 하기 위한 데이터가 없습니다.');
            }
            const res = await this.rootStore.di.server.hbClient.message.update(this.st.hbExtensionData.id, payload, this.rootStore.isAlgorithmStore);
            this.updateData(res);
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw ex;
        }
    }
}
exports.DOSTHbBase = DOSTHbBase;
