"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ensureC = exports.SPCError = exports.ensure = exports.SPError = exports.ErrorCode = void 0;
/**
 * StudioValidationType 과 ErrorCode 가 혼용되는 경우를 대비하여,
 * 절대적으로 아래의 규칙을 지키도록 합니다.
 *
 * - ErrorCode 는 300_000 미만의 숫자를 사용합니다.
 */
var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["NIY"] = -2] = "NIY";
    ErrorCode[ErrorCode["Fatal"] = -1] = "Fatal";
    ErrorCode[ErrorCode["FATAL"] = -1] = "FATAL";
    ErrorCode[ErrorCode["JWTExpired"] = 10] = "JWTExpired";
    ErrorCode[ErrorCode["JWTNotFound"] = 11] = "JWTNotFound";
    ErrorCode[ErrorCode["InvalidApiKey"] = 12] = "InvalidApiKey";
    ErrorCode[ErrorCode["NoUpdateFound"] = 13] = "NoUpdateFound";
    ErrorCode[ErrorCode["UserLeft"] = 14] = "UserLeft";
    ErrorCode[ErrorCode["ServerParamError"] = 30000] = "ServerParamError";
    ErrorCode[ErrorCode["AdminBlockedAccount"] = 30101] = "AdminBlockedAccount";
    ErrorCode[ErrorCode["PasswordPatternTooSimple"] = 30102] = "PasswordPatternTooSimple";
    ErrorCode[ErrorCode["EmailAlreadyTaken"] = 31000] = "EmailAlreadyTaken";
    ErrorCode[ErrorCode["ServerUnauthorized"] = 31001] = "ServerUnauthorized";
    ErrorCode[ErrorCode["ServerWrongLoginInfo"] = 31002] = "ServerWrongLoginInfo";
    ErrorCode[ErrorCode["LastSuperAdmin"] = 31003] = "LastSuperAdmin";
    ErrorCode[ErrorCode["ServerItemNotFound"] = 31004] = "ServerItemNotFound";
    ErrorCode[ErrorCode["StatDateRangeTooLarge"] = 31005] = "StatDateRangeTooLarge";
    ErrorCode[ErrorCode["SSOAlreadySignedUp"] = 31006] = "SSOAlreadySignedUp";
    ErrorCode[ErrorCode["SSONotSignedUp"] = 31007] = "SSONotSignedUp";
    ErrorCode[ErrorCode["NotEnoughCoin"] = 31008] = "NotEnoughCoin";
    ErrorCode[ErrorCode["StoryNotPublished"] = 31009] = "StoryNotPublished";
    ErrorCode[ErrorCode["CannotRestartStoryNoExistingStory"] = 31010] = "CannotRestartStoryNoExistingStory";
    ErrorCode[ErrorCode["CannotStartStoryPlayExists"] = 31011] = "CannotStartStoryPlayExists";
    ErrorCode[ErrorCode["CannotPublishStoryWithoutFirstChapter"] = 31012] = "CannotPublishStoryWithoutFirstChapter";
    ErrorCode[ErrorCode["CannotPublishStoryWithoutFirstChapterPublished"] = 31013] = "CannotPublishStoryWithoutFirstChapterPublished";
    ErrorCode[ErrorCode["CannotPublishChapterWithoutPrecedingChapterNotPublished"] = 31014] = "CannotPublishChapterWithoutPrecedingChapterNotPublished";
    ErrorCode[ErrorCode["NoCurrentPlayingStory"] = 31015] = "NoCurrentPlayingStory";
    ErrorCode[ErrorCode["NoChoiceFound"] = 31016] = "NoChoiceFound";
    ErrorCode[ErrorCode["ChoiceOutOfBound"] = 31017] = "ChoiceOutOfBound";
    ErrorCode[ErrorCode["ChoiceAlreadyMade"] = 31018] = "ChoiceAlreadyMade";
    ErrorCode[ErrorCode["UpdatedUserPropsHaveDifferentPropKeys"] = 31019] = "UpdatedUserPropsHaveDifferentPropKeys";
    ErrorCode[ErrorCode["NoEndingFound"] = 31020] = "NoEndingFound";
    ErrorCode[ErrorCode["PlayStoryAlreadyHasFinalEnding"] = 31021] = "PlayStoryAlreadyHasFinalEnding";
    ErrorCode[ErrorCode["PlayChapterAlreadyHasEnding"] = 31022] = "PlayChapterAlreadyHasEnding";
    ErrorCode[ErrorCode["CannotRestartChapterNotPlayed"] = 31023] = "CannotRestartChapterNotPlayed";
    ErrorCode[ErrorCode["CannotStartChapterNotCorrectSequence"] = 31024] = "CannotStartChapterNotCorrectSequence";
    ErrorCode[ErrorCode["NoChapterFound"] = 31025] = "NoChapterFound";
    ErrorCode[ErrorCode["ChapterIsNotPublishedYet"] = 31026] = "ChapterIsNotPublishedYet";
    ErrorCode[ErrorCode["StartingChapterRequiresWillUseCoin"] = 31027] = "StartingChapterRequiresWillUseCoin";
    ErrorCode[ErrorCode["TransactionErrorPleaseRefresh"] = 31028] = "TransactionErrorPleaseRefresh";
    ErrorCode[ErrorCode["FatalCannotFindPreviousChapterPlay"] = 31029] = "FatalCannotFindPreviousChapterPlay";
    ErrorCode[ErrorCode["FatalPrevPlayingChapterUpdateFailed"] = 31030] = "FatalPrevPlayingChapterUpdateFailed";
    ErrorCode[ErrorCode["UserIsNotAnonymous"] = 31031] = "UserIsNotAnonymous";
    ErrorCode[ErrorCode["TryingToUpdateOtherUserPlayedChapter"] = 31032] = "TryingToUpdateOtherUserPlayedChapter";
    ErrorCode[ErrorCode["BlockHistoryContainsInvalidBlockName"] = 31033] = "BlockHistoryContainsInvalidBlockName";
    ErrorCode[ErrorCode["CannotFindCurrentPlayStoryOfUser"] = 31034] = "CannotFindCurrentPlayStoryOfUser";
    ErrorCode[ErrorCode["NoChaptersToBuy"] = 31035] = "NoChaptersToBuy";
    ErrorCode[ErrorCode["BuyAllChaptersCoinDoesNotMatch"] = 31036] = "BuyAllChaptersCoinDoesNotMatch";
    ErrorCode[ErrorCode["CannotBuyAllChaptersAfterPlayFinished"] = 31037] = "CannotBuyAllChaptersAfterPlayFinished";
    ErrorCode[ErrorCode["SSOPayloadDoesNotMatch"] = 31038] = "SSOPayloadDoesNotMatch";
    ErrorCode[ErrorCode["UserEmailDoesNotExist"] = 31039] = "UserEmailDoesNotExist";
    ErrorCode[ErrorCode["UserAlreadyDeactivated"] = 31040] = "UserAlreadyDeactivated";
    ErrorCode[ErrorCode["StoryPropertyAlreadyRegistered"] = 31041] = "StoryPropertyAlreadyRegistered";
    ErrorCode[ErrorCode["StoryPropertyAlreadyRegisteredToEnding"] = 31042] = "StoryPropertyAlreadyRegisteredToEnding";
    ErrorCode[ErrorCode["FatalAfterPlayingChapterUpdateFailed"] = 31043] = "FatalAfterPlayingChapterUpdateFailed";
    ErrorCode[ErrorCode["NoPermission"] = 31044] = "NoPermission";
    ErrorCode[ErrorCode["ChallengeStoryCannotHaveWillPublishAt"] = 31045] = "ChallengeStoryCannotHaveWillPublishAt";
    ErrorCode[ErrorCode["InvalidStoryEndingArrivalRateRange"] = 31046] = "InvalidStoryEndingArrivalRateRange";
    ErrorCode[ErrorCode["RepresentDateAlreadyExist"] = 31047] = "RepresentDateAlreadyExist";
    ErrorCode[ErrorCode["NoPlayerClassFound"] = 31048] = "NoPlayerClassFound";
    ErrorCode[ErrorCode["NoCurrentPlayingChapter"] = 31049] = "NoCurrentPlayingChapter";
    ErrorCode[ErrorCode["ChoiceFromPlayerClassAlreadyExist"] = 31050] = "ChoiceFromPlayerClassAlreadyExist";
    ErrorCode[ErrorCode["SameNamePlayerClassAlreadyExist"] = 31051] = "SameNamePlayerClassAlreadyExist";
    ErrorCode[ErrorCode["SameDisplayPropNameAlreadyExist"] = 31052] = "SameDisplayPropNameAlreadyExist";
    ErrorCode[ErrorCode["DormantUserNotFound"] = 31054] = "DormantUserNotFound";
    ErrorCode[ErrorCode["IsNotEPUBTypeStory"] = 31055] = "IsNotEPUBTypeStory";
    ErrorCode[ErrorCode["InappCoinAlreadyRegistered"] = 32000] = "InappCoinAlreadyRegistered";
    ErrorCode[ErrorCode["CoinHasAlreadyDepositByInappPurchase"] = 32001] = "CoinHasAlreadyDepositByInappPurchase";
    ErrorCode[ErrorCode["InappCoinNotFoundInReceipt"] = 32002] = "InappCoinNotFoundInReceipt";
    ErrorCode[ErrorCode["SubsCoinAlreadyRegistered"] = 32100] = "SubsCoinAlreadyRegistered";
    ErrorCode[ErrorCode["SubsCoinNotFoundInReceipt"] = 32101] = "SubsCoinNotFoundInReceipt";
    ErrorCode[ErrorCode["CoinPurchaseRequirementNotMet"] = 32102] = "CoinPurchaseRequirementNotMet";
    ErrorCode[ErrorCode["NotExistActiveSystemLanguage"] = 33000] = "NotExistActiveSystemLanguage";
    ErrorCode[ErrorCode["UserHasBlacklistAlreadyRegistered"] = 34000] = "UserHasBlacklistAlreadyRegistered";
    ErrorCode[ErrorCode["cantNotPlayInYourCountry"] = 35000] = "cantNotPlayInYourCountry";
    ErrorCode[ErrorCode["differentPlayType"] = 35001] = "differentPlayType";
    ErrorCode[ErrorCode["canNotCertificateInThisCountry"] = 35002] = "canNotCertificateInThisCountry";
    ErrorCode[ErrorCode["OldPasswordDoesNotMatch"] = 40000] = "OldPasswordDoesNotMatch";
    ErrorCode[ErrorCode["CurrentPasswordIsTheSameAsNewOne"] = 40001] = "CurrentPasswordIsTheSameAsNewOne";
    ErrorCode[ErrorCode["StoryMigrationStoryIdDoesNotMatch"] = 50000] = "StoryMigrationStoryIdDoesNotMatch";
    ErrorCode[ErrorCode["StoryMigrationInvalidValuesParam"] = 50001] = "StoryMigrationInvalidValuesParam";
    ErrorCode[ErrorCode["EndingMigrationStoryIdDoesNotMatch"] = 51000] = "EndingMigrationStoryIdDoesNotMatch";
    ErrorCode[ErrorCode["EndingCustomIdIsDuplicated"] = 51100] = "EndingCustomIdIsDuplicated";
    ErrorCode[ErrorCode["OTPKeyIsNotRegistered"] = 52000] = "OTPKeyIsNotRegistered";
    ErrorCode[ErrorCode["OTPTokenIsNotCorrect"] = 52001] = "OTPTokenIsNotCorrect";
    ErrorCode[ErrorCode["ChapterCreationAfterChapterIndexInvalid"] = 53000] = "ChapterCreationAfterChapterIndexInvalid";
    ErrorCode[ErrorCode["ActiveChapterDraftExists"] = 53001] = "ActiveChapterDraftExists";
    ErrorCode[ErrorCode["ChapterDraftUpdateNoPermission"] = 53002] = "ChapterDraftUpdateNoPermission";
    ErrorCode[ErrorCode["ChapterDraftInvalidOperation"] = 53003] = "ChapterDraftInvalidOperation";
    ErrorCode[ErrorCode["ChapterDraftApplyTransactionFailed"] = 53004] = "ChapterDraftApplyTransactionFailed";
    ErrorCode[ErrorCode["ChapterDraftUpdateNotInEditing"] = 53005] = "ChapterDraftUpdateNotInEditing";
    ErrorCode[ErrorCode["ActiveChapterDraftDoesNotExists"] = 53006] = "ActiveChapterDraftDoesNotExists";
    // CS = ChapterScript
    ErrorCode[ErrorCode["CSIsNotJson"] = 54000] = "CSIsNotJson";
    ErrorCode[ErrorCode["CSInvalidKeys"] = 54001] = "CSInvalidKeys";
    ErrorCode[ErrorCode["CSBlockMustBeObject"] = 54002] = "CSBlockMustBeObject";
    ErrorCode[ErrorCode["CSStartingBlockMustBeString"] = 54003] = "CSStartingBlockMustBeString";
    ErrorCode[ErrorCode["BonusTicketCodeAlreadyRegistered"] = 55000] = "BonusTicketCodeAlreadyRegistered";
    ErrorCode[ErrorCode["BonusTicketCodeAlreadyTaken"] = 55001] = "BonusTicketCodeAlreadyTaken";
    ErrorCode[ErrorCode["BonusTicketCodeInactivated"] = 55002] = "BonusTicketCodeInactivated";
    ErrorCode[ErrorCode["BonusTicketCodeNotActivatedYet"] = 55003] = "BonusTicketCodeNotActivatedYet";
    ErrorCode[ErrorCode["BonusTicketCodeExpired"] = 55004] = "BonusTicketCodeExpired";
    ErrorCode[ErrorCode["BonusTicketCodeNotFound"] = 55005] = "BonusTicketCodeNotFound";
    ErrorCode[ErrorCode["BonusTicketCodeHasExhausted"] = 55006] = "BonusTicketCodeHasExhausted";
    ErrorCode[ErrorCode["BonusTicketCodeTargetUserNotFound"] = 55007] = "BonusTicketCodeTargetUserNotFound";
    ErrorCode[ErrorCode["ChapterCommentCannotPostYet"] = 56000] = "ChapterCommentCannotPostYet";
    ErrorCode[ErrorCode["ChapterCommentAccessDeniedToUpdate"] = 56001] = "ChapterCommentAccessDeniedToUpdate";
    ErrorCode[ErrorCode["ChapterCommentAccessDeniedToDelete"] = 56002] = "ChapterCommentAccessDeniedToDelete";
    ErrorCode[ErrorCode["ChapterCommentCannotPostOnNestedComment"] = 56003] = "ChapterCommentCannotPostOnNestedComment";
    ErrorCode[ErrorCode["ChapterCommentCannotPostForNotPlayedChapter"] = 56004] = "ChapterCommentCannotPostForNotPlayedChapter";
    ErrorCode[ErrorCode["ChapterCommentDisplayUserMustBeVirtualPriv"] = 56005] = "ChapterCommentDisplayUserMustBeVirtualPriv";
    ErrorCode[ErrorCode["ChapterCommentAlreadyReported"] = 56100] = "ChapterCommentAlreadyReported";
    ErrorCode[ErrorCode["CannotLikeChapterCommentYet"] = 57000] = "CannotLikeChapterCommentYet";
    ErrorCode[ErrorCode["CannotLikeChapterComment"] = 57001] = "CannotLikeChapterComment";
    ErrorCode[ErrorCode["CannotCancelLikeChapterCommentYet"] = 57002] = "CannotCancelLikeChapterCommentYet";
    ErrorCode[ErrorCode["CannotCancelLikeChapterComment"] = 57003] = "CannotCancelLikeChapterComment";
    ErrorCode[ErrorCode["ErrorCommunicatingWithAchievementServer"] = 58000] = "ErrorCommunicatingWithAchievementServer";
    ErrorCode[ErrorCode["AppEventInvalidParam"] = 59000] = "AppEventInvalidParam";
    ErrorCode[ErrorCode["NamePopupAlreadyExist"] = 60000] = "NamePopupAlreadyExist";
    ErrorCode[ErrorCode["NameAndTextCanNotAddPopupChoice"] = 60001] = "NameAndTextCanNotAddPopupChoice";
    ErrorCode[ErrorCode["NeedToAddPopupChoice"] = 60002] = "NeedToAddPopupChoice";
    ErrorCode[ErrorCode["NamePopupCanNotInactive"] = 60003] = "NamePopupCanNotInactive";
    ErrorCode[ErrorCode["OtherNamePopupAlreadyExistWithSameCharacter"] = 60004] = "OtherNamePopupAlreadyExistWithSameCharacter";
    ErrorCode[ErrorCode["OtherPopupHaveToPutCharacterId"] = 60005] = "OtherPopupHaveToPutCharacterId";
    ErrorCode[ErrorCode["NeedToOneMoreActivePopupChoice"] = 60006] = "NeedToOneMoreActivePopupChoice";
    ErrorCode[ErrorCode["NamePopupCanNotGetVisibleCondition"] = 60007] = "NamePopupCanNotGetVisibleCondition";
    ErrorCode[ErrorCode["OtherNamePopupNeedDefaultFirstName"] = 60008] = "OtherNamePopupNeedDefaultFirstName";
    ErrorCode[ErrorCode["OtherNamePopupNeedDefaultLastName"] = 60009] = "OtherNamePopupNeedDefaultLastName";
    ErrorCode[ErrorCode["ProfilePopupChoiceNeedImageFile"] = 60010] = "ProfilePopupChoiceNeedImageFile";
    ErrorCode[ErrorCode["NameOrOtherNameCanCreatePopupProfileImage"] = 60011] = "NameOrOtherNameCanCreatePopupProfileImage";
    ErrorCode[ErrorCode["StudioServerAccountRaisedUnknownError"] = 60100] = "StudioServerAccountRaisedUnknownError";
    ErrorCode[ErrorCode["StudioServerAccountDoesNotHavePermission"] = 60101] = "StudioServerAccountDoesNotHavePermission";
    ErrorCode[ErrorCode["StudioServerAccountCannotFindEntity"] = 60102] = "StudioServerAccountCannotFindEntity";
    ErrorCode[ErrorCode["StudioFileHasDuplicateResName"] = 60103] = "StudioFileHasDuplicateResName";
    ErrorCode[ErrorCode["StudioMetaDataUpdateActionNotHandled"] = 60104] = "StudioMetaDataUpdateActionNotHandled";
    ErrorCode[ErrorCode["StudioPrivNotAuthor"] = 60105] = "StudioPrivNotAuthor";
    ErrorCode[ErrorCode["StudioCannotRemoveChapterHasPurchase"] = 60106] = "StudioCannotRemoveChapterHasPurchase";
    ErrorCode[ErrorCode["StudioCannotRemoveIsPublished"] = 60107] = "StudioCannotRemoveIsPublished";
    ErrorCode[ErrorCode["PublishedPlayerReportNotFound"] = 60108] = "PublishedPlayerReportNotFound";
    ErrorCode[ErrorCode["StudioNotEnoughToAuthor"] = 60109] = "StudioNotEnoughToAuthor";
    ErrorCode[ErrorCode["StudioUserHasBeenBanned"] = 60110] = "StudioUserHasBeenBanned";
    ErrorCode[ErrorCode["StudioPermissionSchemaJsonInvalid"] = 60111] = "StudioPermissionSchemaJsonInvalid";
    ErrorCode[ErrorCode["StudioCannotRemoveLastOwner"] = 60112] = "StudioCannotRemoveLastOwner";
    ErrorCode[ErrorCode["StudioTargetUserPrivNotAuthor"] = 60113] = "StudioTargetUserPrivNotAuthor";
    ErrorCode[ErrorCode["CannotReserveChapterPublishLaterNextChapter"] = 60114] = "CannotReserveChapterPublishLaterNextChapter";
    ErrorCode[ErrorCode["CannotReserveChapterPublishIfEarlierThanThePresent"] = 60115] = "CannotReserveChapterPublishIfEarlierThanThePresent";
    ErrorCode[ErrorCode["CannotReserveChapterPublishEarlierThenPrevChapter"] = 60116] = "CannotReserveChapterPublishEarlierThenPrevChapter";
    ErrorCode[ErrorCode["ContestIsNotInProgress"] = 60117] = "ContestIsNotInProgress";
    ErrorCode[ErrorCode["StoryAlreadyInContest"] = 60118] = "StoryAlreadyInContest";
    ErrorCode[ErrorCode["StudioStoryRequireGenre"] = 60119] = "StudioStoryRequireGenre";
    ErrorCode[ErrorCode["PublicResourceTypeShouldBeSameCategoryType"] = 60200] = "PublicResourceTypeShouldBeSameCategoryType";
    ErrorCode[ErrorCode["AlreadyExistSameConditionStoryHasRecommendSection"] = 60300] = "AlreadyExistSameConditionStoryHasRecommendSection";
    ErrorCode[ErrorCode["StoryBundleIDIsNULL"] = 60400] = "StoryBundleIDIsNULL";
    ErrorCode[ErrorCode["StoryToggleReactionAlreadLiked"] = 61000] = "StoryToggleReactionAlreadLiked";
    ErrorCode[ErrorCode["StoryToggleReactionNeverLiked"] = 61001] = "StoryToggleReactionNeverLiked";
    ErrorCode[ErrorCode["StudioCannotChangePublishStatusNotApprovedStory"] = 61002] = "StudioCannotChangePublishStatusNotApprovedStory";
    ErrorCode[ErrorCode["StoryBundleAlreadyLiked"] = 61003] = "StoryBundleAlreadyLiked";
    ErrorCode[ErrorCode["PurchaseStoryCoinDoesNotMatch"] = 62000] = "PurchaseStoryCoinDoesNotMatch";
    ErrorCode[ErrorCode["PurchaseStoryAlreadyPurchased"] = 62001] = "PurchaseStoryAlreadyPurchased";
    ErrorCode[ErrorCode["PurchaseStoryInvalidPurchase"] = 62002] = "PurchaseStoryInvalidPurchase";
    ErrorCode[ErrorCode["AttendanceCoinRewardHasExhausted"] = 62100] = "AttendanceCoinRewardHasExhausted";
    ErrorCode[ErrorCode["PurchaseSkipAdsCoinDoesNotMatch"] = 62200] = "PurchaseSkipAdsCoinDoesNotMatch";
    ErrorCode[ErrorCode["PurchasePackageProductIdDoesNotMatch"] = 62300] = "PurchasePackageProductIdDoesNotMatch";
    ErrorCode[ErrorCode["PurchasePackageTimeSaleIsFinished"] = 62301] = "PurchasePackageTimeSaleIsFinished";
    ErrorCode[ErrorCode["PurchasePackageHasAlreadyPurchased"] = 62302] = "PurchasePackageHasAlreadyPurchased";
    ErrorCode[ErrorCode["PushNotificationAccessDeniedToDelete"] = 62400] = "PushNotificationAccessDeniedToDelete";
    ErrorCode[ErrorCode["CannotReportNotChallengeStory"] = 62500] = "CannotReportNotChallengeStory";
    ErrorCode[ErrorCode["ChallengeStoryAlreadyReported"] = 62501] = "ChallengeStoryAlreadyReported";
    ErrorCode[ErrorCode["InvalidChoiceAffectionRuleIndex"] = 63001] = "InvalidChoiceAffectionRuleIndex";
    ErrorCode[ErrorCode["IamportFetchAccessTokenFailed"] = 64000] = "IamportFetchAccessTokenFailed";
    ErrorCode[ErrorCode["IamportPaymentStateIsNotRequested"] = 64001] = "IamportPaymentStateIsNotRequested";
    ErrorCode[ErrorCode["IamportPaymentCancelFailed"] = 64002] = "IamportPaymentCancelFailed";
    ErrorCode[ErrorCode["IamportInvalidRequest"] = 64003] = "IamportInvalidRequest";
    ErrorCode[ErrorCode["TossPaymentsStateIsNotReady"] = 64004] = "TossPaymentsStateIsNotReady";
    ErrorCode[ErrorCode["TossPaymentsInvalidRequest"] = 64005] = "TossPaymentsInvalidRequest";
    ErrorCode[ErrorCode["TossPaymentsConfirmInvalid"] = 64006] = "TossPaymentsConfirmInvalid";
    ErrorCode[ErrorCode["TossPaymentsPayTypeDifferent"] = 64007] = "TossPaymentsPayTypeDifferent";
    ErrorCode[ErrorCode["XsollaInvalidRequest"] = 64008] = "XsollaInvalidRequest";
    ErrorCode[ErrorCode["CanNotAccessHelloBotServer"] = 65000] = "CanNotAccessHelloBotServer";
    ErrorCode[ErrorCode["CanNotMatchRemoteScriptType"] = 65001] = "CanNotMatchRemoteScriptType";
    ErrorCode[ErrorCode["DirectAdsLinkRequired"] = 66000] = "DirectAdsLinkRequired";
    ErrorCode[ErrorCode["CheckPaidSetting"] = 67000] = "CheckPaidSetting";
    ErrorCode[ErrorCode["CheckPaidPerChapterSetting"] = 67001] = "CheckPaidPerChapterSetting";
    ErrorCode[ErrorCode["CheckPaidPerChapterSettingPrice"] = 67002] = "CheckPaidPerChapterSettingPrice";
    ErrorCode[ErrorCode["CheckPaidPerChapterSettingFreeChapterIndexes"] = 67003] = "CheckPaidPerChapterSettingFreeChapterIndexes";
    ErrorCode[ErrorCode["CheckPaidPerStorySetting"] = 67004] = "CheckPaidPerStorySetting";
    ErrorCode[ErrorCode["CheckPaidPerStorySettingPrice"] = 67005] = "CheckPaidPerStorySettingPrice";
    ErrorCode[ErrorCode["CheckPaidPerStorySettingChapterIndex"] = 67006] = "CheckPaidPerStorySettingChapterIndex";
    ErrorCode[ErrorCode["FreeCanNotSettingPromotion"] = 67007] = "FreeCanNotSettingPromotion";
    ErrorCode[ErrorCode["CheckAdPerChapterSetting"] = 67008] = "CheckAdPerChapterSetting";
    ErrorCode[ErrorCode["CheckAdPerChapterSettingFreeChapterIndexes"] = 67009] = "CheckAdPerChapterSettingFreeChapterIndexes";
    ErrorCode[ErrorCode["CheckFreedAtPerChapterSetting"] = 67010] = "CheckFreedAtPerChapterSetting";
    ErrorCode[ErrorCode["CheckFreedAtPerChapterSettingFreedAtChapterIndexes"] = 67011] = "CheckFreedAtPerChapterSettingFreedAtChapterIndexes";
    ErrorCode[ErrorCode["CheckWaitFreePerChapterSetting"] = 67012] = "CheckWaitFreePerChapterSetting";
    ErrorCode[ErrorCode["CheckWaitFreePerChapterSettingPeriod"] = 67013] = "CheckWaitFreePerChapterSettingPeriod";
    ErrorCode[ErrorCode["CheckWaitFreePerChapterSettingChapterIndex"] = 67014] = "CheckWaitFreePerChapterSettingChapterIndex";
    ErrorCode[ErrorCode["AfterSomePeriodFreePerChapterSetting"] = 67015] = "AfterSomePeriodFreePerChapterSetting";
    ErrorCode[ErrorCode["AfterSomePeriodFreePerChapterSettingPeriod"] = 67016] = "AfterSomePeriodFreePerChapterSettingPeriod";
    ErrorCode[ErrorCode["AfterSomePeriodFreePerChapterSettingChapterIndex"] = 67017] = "AfterSomePeriodFreePerChapterSettingChapterIndex";
    ErrorCode[ErrorCode["TimeLeapFreePerChapterSetting"] = 67018] = "TimeLeapFreePerChapterSetting";
    ErrorCode[ErrorCode["TimeLeapFreePerChapterSettingPeriod"] = 67019] = "TimeLeapFreePerChapterSettingPeriod";
    ErrorCode[ErrorCode["TimeLeapFreePerChapterSettingNoticeId"] = 67020] = "TimeLeapFreePerChapterSettingNoticeId";
    ErrorCode[ErrorCode["PaidPerChapterCanNotChangePaidPerStory"] = 67021] = "PaidPerChapterCanNotChangePaidPerStory";
    ErrorCode[ErrorCode["InvalidDanalRequest"] = 70000] = "InvalidDanalRequest";
    ErrorCode[ErrorCode["DanalTIdAreadyExists"] = 70001] = "DanalTIdAreadyExists";
    // 웹소설 관련 오류
    ErrorCode[ErrorCode["CannotChangeChapterContentType"] = 71001] = "CannotChangeChapterContentType";
    ErrorCode[ErrorCode["InvalidChapterContentType"] = 71002] = "InvalidChapterContentType";
    ErrorCode[ErrorCode["CannotRefStorySelf"] = 71003] = "CannotRefStorySelf";
    ErrorCode[ErrorCode["EpubChapterRequiredEpubFile"] = 71004] = "EpubChapterRequiredEpubFile";
    ErrorCode[ErrorCode["InvalidNovelScriptType"] = 71005] = "InvalidNovelScriptType";
    ErrorCode[ErrorCode["InvalidStudioPriceSettingFormat"] = 71006] = "InvalidStudioPriceSettingFormat";
    ErrorCode[ErrorCode["BannerStoryAlreadyRegistered"] = 72000] = "BannerStoryAlreadyRegistered";
    ErrorCode[ErrorCode["BannerBundleAlreadyRegistered"] = 72001] = "BannerBundleAlreadyRegistered";
    ErrorCode[ErrorCode["BundleNotFound"] = 72100] = "BundleNotFound";
    ErrorCode[ErrorCode["CountryCodeNotActivated"] = 72101] = "CountryCodeNotActivated";
    ErrorCode[ErrorCode["MonthlyBundleAlreadyRegistered"] = 72102] = "MonthlyBundleAlreadyRegistered";
    ErrorCode[ErrorCode["TermsAlreadyRegistered"] = 72200] = "TermsAlreadyRegistered";
    ErrorCode[ErrorCode["TermsNotFound"] = 72201] = "TermsNotFound";
    ErrorCode[ErrorCode["AdultVerificationRequired"] = 72210] = "AdultVerificationRequired";
    ErrorCode[ErrorCode["StoryHasAdultChapter"] = 72211] = "StoryHasAdultChapter";
    ErrorCode[ErrorCode["ChapterHasAdultStory"] = 72212] = "ChapterHasAdultStory";
    // 작품 추출 관련 오류
    ErrorCode[ErrorCode["ReturnedErrorGetStoryForExportStory"] = 80000] = "ReturnedErrorGetStoryForExportStory";
    ErrorCode[ErrorCode["ReturnedErrorGetAchievementForExportStory"] = 80001] = "ReturnedErrorGetAchievementForExportStory";
    ErrorCode[ErrorCode["UnhandledErrorForExportStory"] = 80002] = "UnhandledErrorForExportStory";
    // 스튜디오 CLIENT 영역 오류
    // 100,000 부터 110,000 까지는 클라가 사용하자.
    ErrorCode[ErrorCode["BLOCK_NAME_MIN_LEN_NOT_MET"] = 100000] = "BLOCK_NAME_MIN_LEN_NOT_MET";
    ErrorCode[ErrorCode["BLOCK_NAME_MAX_LEN_NOT_MET"] = 100001] = "BLOCK_NAME_MAX_LEN_NOT_MET";
    ErrorCode[ErrorCode["BLOCK_NAME_IS_REQUIRED"] = 100002] = "BLOCK_NAME_IS_REQUIRED";
    ErrorCode[ErrorCode["BLOCK_NAME_EXISTS"] = 100003] = "BLOCK_NAME_EXISTS";
    ErrorCode[ErrorCode["NOT_SUPPORTED"] = 100004] = "NOT_SUPPORTED";
    ErrorCode[ErrorCode["PropNameShouldNotIncludeColon"] = 100005] = "PropNameShouldNotIncludeColon";
    ErrorCode[ErrorCode["ItemNameShouldNotIncludeColon"] = 100006] = "ItemNameShouldNotIncludeColon";
    ErrorCode[ErrorCode["CHR_NAME_MIN_LEN_NOT_MET"] = 100007] = "CHR_NAME_MIN_LEN_NOT_MET";
    ErrorCode[ErrorCode["CHR_NAME_MAX_LEN_NOT_MET"] = 100008] = "CHR_NAME_MAX_LEN_NOT_MET";
    ErrorCode[ErrorCode["ITEM_NAME_MIN_LEN_NOT_MET"] = 100009] = "ITEM_NAME_MIN_LEN_NOT_MET";
    ErrorCode[ErrorCode["ITEM_NAME_MAX_LEN_NOT_MET"] = 100010] = "ITEM_NAME_MAX_LEN_NOT_MET";
    ErrorCode[ErrorCode["CHANGE_NOT_FOUND"] = 101000] = "CHANGE_NOT_FOUND";
    ErrorCode[ErrorCode["SUBMIT_IN_PROGRESS"] = 101001] = "SUBMIT_IN_PROGRESS";
    ErrorCode[ErrorCode["PLAYER_REPORT2_SAMPLE_DATA_INVALID"] = 101002] = "PLAYER_REPORT2_SAMPLE_DATA_INVALID";
    ErrorCode[ErrorCode["NotInEditingState"] = 101003] = "NotInEditingState";
    ErrorCode[ErrorCode["CannotApplyScriptWhenValidationErrorExists"] = 101004] = "CannotApplyScriptWhenValidationErrorExists";
    ErrorCode[ErrorCode["ApplyScriptInProgress"] = 101005] = "ApplyScriptInProgress";
    ErrorCode[ErrorCode["DuplicateChapterDraftRequiresChapterDraftInEditing"] = 101006] = "DuplicateChapterDraftRequiresChapterDraftInEditing";
    ErrorCode[ErrorCode["ChapterNameForbiddenCharacterIsIncluded"] = 101007] = "ChapterNameForbiddenCharacterIsIncluded";
    ErrorCode[ErrorCode["PropNameShouldContainCharacter"] = 101008] = "PropNameShouldContainCharacter";
    ErrorCode[ErrorCode["PropNameShouldNotStartOrEndWithSpace"] = 101009] = "PropNameShouldNotStartOrEndWithSpace";
    ErrorCode[ErrorCode["ItemNameShouldContainCharacter"] = 101010] = "ItemNameShouldContainCharacter";
    ErrorCode[ErrorCode["ItemNameShouldNotStartOrEndWithSpace"] = 101011] = "ItemNameShouldNotStartOrEndWithSpace";
    ErrorCode[ErrorCode["PropNameMinLengthNotMet"] = 101012] = "PropNameMinLengthNotMet";
    ErrorCode[ErrorCode["PropNameMaxLengthNotMet"] = 101013] = "PropNameMaxLengthNotMet";
    ErrorCode[ErrorCode["StoryNotFoundOnLoad"] = 101014] = "StoryNotFoundOnLoad";
    ErrorCode[ErrorCode["ErrorOnChapterLoad"] = 101015] = "ErrorOnChapterLoad";
    ErrorCode[ErrorCode["CannotFindChapter"] = 101016] = "CannotFindChapter";
    ErrorCode[ErrorCode["StartEditingChapterFailed"] = 101017] = "StartEditingChapterFailed";
    ErrorCode[ErrorCode["SLGParserError"] = 101020] = "SLGParserError";
    ErrorCode[ErrorCode["TTSSpeakerNotFound"] = 101030] = "TTSSpeakerNotFound";
    ErrorCode[ErrorCode["HasScriptErrorBeforeStartingInstant"] = 101031] = "HasScriptErrorBeforeStartingInstant";
    ErrorCode[ErrorCode["InstantTestingHasNoPreviousEnding"] = 101032] = "InstantTestingHasNoPreviousEnding";
    ErrorCode[ErrorCode["InstantTestingHasNoStartingBlock"] = 101033] = "InstantTestingHasNoStartingBlock";
    ErrorCode[ErrorCode["EndingHasNoImage"] = 101034] = "EndingHasNoImage";
    ErrorCode[ErrorCode["ItemHasNoName"] = 101034] = "ItemHasNoName";
    ErrorCode[ErrorCode["PropHasNoName"] = 101035] = "PropHasNoName";
    ErrorCode[ErrorCode["TTSHasNoPlayText"] = 101036] = "TTSHasNoPlayText";
    ErrorCode[ErrorCode["TTSPlayTextMaxLenNotMet"] = 101037] = "TTSPlayTextMaxLenNotMet";
    ErrorCode[ErrorCode["ChapterBundleLoadFailed"] = 101038] = "ChapterBundleLoadFailed";
    ErrorCode[ErrorCode["ContestNumberDuplicated"] = 101039] = "ContestNumberDuplicated";
    ErrorCode[ErrorCode["HasNoAuthorBeforeAddingMember"] = 101039] = "HasNoAuthorBeforeAddingMember";
    ErrorCode[ErrorCode["NeedToApplyChapterBeforeAddingEndingPreview"] = 101040] = "NeedToApplyChapterBeforeAddingEndingPreview";
    ErrorCode[ErrorCode["ChapterIsSavingAlready"] = 101041] = "ChapterIsSavingAlready";
    ErrorCode[ErrorCode["PositionThatCanNotAddLine"] = 101042] = "PositionThatCanNotAddLine";
    ErrorCode[ErrorCode["ChrOnboardingRequireFirstName"] = 101043] = "ChrOnboardingRequireFirstName";
    ErrorCode[ErrorCode["ChrOnboardingRequireLastName"] = 101044] = "ChrOnboardingRequireLastName";
    ErrorCode[ErrorCode["OpenAIIncorrectModel"] = 102000] = "OpenAIIncorrectModel";
    // OAuth Error
    ErrorCode[ErrorCode["OAuthInvalidRedirectUrl"] = 103000] = "OAuthInvalidRedirectUrl";
    ErrorCode[ErrorCode["OAuthInvalidClientCredential"] = 103001] = "OAuthInvalidClientCredential";
    ErrorCode[ErrorCode["OAuthNotFoundRefreshToken"] = 103002] = "OAuthNotFoundRefreshToken";
    ErrorCode[ErrorCode["OAuthExpiredRefreshToken"] = 103003] = "OAuthExpiredRefreshToken";
    // Ai Character Error
    ErrorCode[ErrorCode["AiChrInvalidSession"] = 104000] = "AiChrInvalidSession";
    ErrorCode[ErrorCode["AiChrAlreadyExists"] = 104001] = "AiChrAlreadyExists";
    ErrorCode[ErrorCode["AiChrInvalidChr"] = 104002] = "AiChrInvalidChr";
    ErrorCode[ErrorCode["AiChrNoFaceDetect"] = 104003] = "AiChrNoFaceDetect";
    ErrorCode[ErrorCode["AiChrUnhandledError"] = 104004] = "AiChrUnhandledError";
    ErrorCode[ErrorCode["AiChrInvalidTossPayments"] = 104005] = "AiChrInvalidTossPayments";
    ErrorCode[ErrorCode["AiChrInvalidInappPurchase"] = 104006] = "AiChrInvalidInappPurchase";
    ErrorCode[ErrorCode["AiChrTossPaymentsRewardAlreadyClaimed"] = 104005] = "AiChrTossPaymentsRewardAlreadyClaimed";
    ErrorCode[ErrorCode["AiChrInappPurchaseRewardAlreadyClaimed"] = 104006] = "AiChrInappPurchaseRewardAlreadyClaimed";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
class SPError extends Error {
    constructor(_code, message, status = 400) {
        const code = _code || ErrorCode.Fatal;
        const msg = ErrorCode[code];
        super(message);
        this.name = 'SPError';
        this.code = code;
        this.status = status;
    }
}
exports.SPError = SPError;
function ensure(expr, errorCode, message) {
    if (!expr)
        throw new SPError(errorCode, message);
}
exports.ensure = ensure;
/**
 * SPC=StoryPlayClient
 *
 * 스튜디오와 앱에서 사용한다.
 * extra 를 이용하여 추가 context 정보를 저장할 수 있도록 한다.
 */
class SPCError extends Error {
    constructor(code, extra) {
        super(`SP-${code} : ${ErrorCode[code]}`);
        this.code = code;
        this.extra = extra;
    }
}
exports.SPCError = SPCError;
function ensureC(expr, code, extra) {
    if (!expr) {
        throw new SPCError(code, extra);
    }
}
exports.ensureC = ensureC;
