import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { AppSplashImageEditPage } from './AppSplashImageEditPage'

interface IAppSplashImageEditPageContainerProps {
  appSplashImageId: number
}

export const AppSplashImageEditPageContainer: React.FC<
  IAppSplashImageEditPageContainerProps
> = (props) => {
  const { appSplashImageId } = props
  return (
    <>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.AppSplashImage.List} />
          <hr />
          <AppSplashImageEditPage appSplashImageId={appSplashImageId} />
        </CardBody>
      </Card>
    </>
  )
}
