"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionShowMobileModalBuilder = void 0;
const definition_1 = require("../../definition");
const row_1 = require("../row");
const ActionModalBase_1 = require("./ActionModalBase");
class ActionShowMobileModalBuilder extends ActionModalBase_1.ActionModalBase {
    constructor() {
        super();
        this.bodyBuilders = [];
        this.position = 'end';
        this.isFullScreen = false;
        this.canCloseWhenClickOutside = true;
    }
    setModalPosition(position) {
        this.position = position;
    }
    setIsFullScreen(isFullScreen) {
        this.isFullScreen = isFullScreen;
    }
    setCanCloseWhenClickOutside(can) {
        this.canCloseWhenClickOutside = can;
    }
    setTitle(def) {
        this.title = def;
        return this;
    }
    setFooter(def) {
        this.footer = def;
        return this;
    }
    addRow(cb) {
        const builder = new row_1.RowWithColumnBuilder();
        cb(builder);
        this.bodyBuilders.push(builder);
        return builder;
    }
    buildAction() {
        const rows = this.bodyBuilders.map(b => b.buildRowLayout());
        if (this.title) {
            rows.unshift(this.title);
        }
        if (this.footer) {
            rows.push(this.footer);
        }
        return {
            type: definition_1.ActionType.ShowMobileModal,
            position: this.position,
            isFullScreen: this.isFullScreen,
            ...this.buildActionBase(),
            rows,
            onClose: this.canCloseWhenClickOutside
                ? () => {
                    this.cancelAction();
                }
                : undefined,
        };
    }
}
exports.ActionShowMobileModalBuilder = ActionShowMobileModalBuilder;
