import React from 'react'

interface IProps {
  setValue: (value: object) => void
  value: object
}

export const KeyValueInput: React.FC<IProps> = (props) => {
  const { setValue, value } = props

  const pairs = Object.keys(value).map((k) => ({ key: k, value: value[k] }))

  const convertToValue = (v: Array<{ key: string; value: string }>) => {
    return v.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})
  }

  const handleChange = (index: number, key: string, value: string) => {
    const newPairs = [...pairs]
    newPairs[index] = { ...newPairs[index], [key]: value }
    setValue(convertToValue(newPairs))
  }

  const addPair = () => {
    setValue(convertToValue([...pairs, { key: '', value: '' }]))
  }

  const removePair = (index: number) => {
    setValue(convertToValue(pairs.filter((_, i) => i !== index)))
  }

  return (
    <div>
      {pairs.map((pair, index) => (
        <div key={index} className="mb-4 flex items-center">
          <input
            type="text"
            placeholder="Key"
            value={pair.key}
            onChange={(e) => handleChange(index, 'key', e.target.value)}
            className="flex-1 px-3 py-2 mr-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="Value"
            value={pair.value}
            onChange={(e) => handleChange(index, 'value', e.target.value)}
            className="flex-1 px-3 py-2 mr-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={() => removePair(index)}
            className="p-2 text-red-500 hover:text-red-700 focus:outline-none"
            aria-label="Remove pair"
          >
            del
          </button>
        </div>
      ))}
      <button
        onClick={addPair}
        className="mt-2 flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Add Pair
      </button>
    </div>
  )
}
