import {
  GQLCreateOnboardingPopupProfileImageInput,
  GQLOnboardingPopupProfileImage,
  GQLUpdateOnboardingPopupProfileImageInput,
} from '@storyplay/core'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import * as Yup from 'yup'
import React from 'react'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../ODEntityEditor/ODEntityEditorContext'
import { Utils } from '../../utils/utils'
import { SiteUrls } from '../../urls'
import {
  ODEntityInput,
  ODEntityLabeled,
  ODImageFileInput,
  ODModal,
  ODModalBody,
  ODModalHeader,
  ODModalSize,
  useCounter,
} from '@odc/od-react-belt'
import { ToggleButtonWrapper } from '../../components/commonStyle'
import { STATUS } from '../../common'
import { ODFormToggleButton } from '../../components/ODFormToggleButton'
import { ODEntityEditorFooter } from '../../ODEntityEditor/ODEntityEditorFooter'

interface IOnboardingPopupProfileImageModalProps {
  storyId: number
  popupId: number
  popupProfileImageId: number
  onClose: () => void
  onConfirm: () => void
}

type Entity = GQLOnboardingPopupProfileImage

function getValidationSchema(
  values: Partial<
    | GQLCreateOnboardingPopupProfileImageInput
    | GQLUpdateOnboardingPopupProfileImageInput
  >
) {
  return Yup.object().shape({})
}

export const OnboardingPopupProfileImageModal: React.FC<
  IOnboardingPopupProfileImageModalProps
> = (props) => {
  const { storyId, popupId, popupProfileImageId, onClose, onConfirm } = props
  const {
    createOnboardingPopupProfileImage,
    updateOnboardingPopupProfileImage,
    getOnboardingPopupProfileImage,
    deleteOnboardingPopupProfileImage,
  } = useStoryPlayAPIs()
  const [token] = useCounter()
  const isCreating = popupProfileImageId === 0
  const innerRef = React.useRef(null)

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<
        | GQLCreateOnboardingPopupProfileImageInput
        | GQLUpdateOnboardingPopupProfileImageInput
      >
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (popupProfileImageId) {
          return getOnboardingPopupProfileImage({ id: popupProfileImageId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            popupId,
            showOrder: 1000,
            profileImageFile: null,
            description: '',
          }
        }
        return {
          popupProfileImageId,
          popupId: data.popupId,
          description: data.description,
          profileImageFile: data.profileImageFile,
          showOrder: data.showOrder,
          status: data.status,
        }
      },
      saveClientValueToServer: async (
        data: Partial<
          | GQLCreateOnboardingPopupProfileImageInput
          | GQLUpdateOnboardingPopupProfileImageInput
        >
      ) => {
        const { profileImageFile, showOrder, description, status } =
          data as GQLCreateOnboardingPopupProfileImageInput
        if (popupProfileImageId) {
          await updateOnboardingPopupProfileImage({
            id: popupProfileImageId,
            showOrder,
            description,
            status,
            profileImageFile,
          })
          Utils.showSuccess('이미지가 수정되었습니다.', 'Success')
        } else {
          await createOnboardingPopupProfileImage({
            popupId,
            description,
            showOrder,
            profileImageFile,
            status,
          })
          Utils.showSuccess('이미지가 등록되었습니다.', 'Success')
        }
        onConfirm()
        return SiteUrls.Admin.Story.EditOnboardingPopup(storyId, popupId)
      },
      getValidationSchema,
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token)
        Utils.showError(ex)
      },
      deleteItem: async () => {
        if (popupProfileImageId) {
          await deleteOnboardingPopupProfileImage({ id: popupProfileImageId })
          Utils.showSuccess('이미지가 삭제되었습니다.', 'Success')
        }
        onConfirm()
        return SiteUrls.Admin.Story.EditOnboardingPopup(storyId, popupId)
      },
      __innerReference: innerRef,
    }),
    [
      popupId,
      storyId,
      popupProfileImageId,
      createOnboardingPopupProfileImage,
      updateOnboardingPopupProfileImage,
      deleteOnboardingPopupProfileImage,
      getOnboardingPopupProfileImage,
      onConfirm,
      onClose,
    ]
  )
  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<
        | GQLCreateOnboardingPopupProfileImageInput
        | GQLUpdateOnboardingPopupProfileImageInput
      >
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<
        | GQLCreateOnboardingPopupProfileImageInput
        | GQLUpdateOnboardingPopupProfileImageInput
      >
    >(options)
  )
  React.useEffect(
    () => setOptions(createOptions()),
    [createOptions, createOnboardingPopupProfileImage]
  )

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Large}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader
          title={`기본 프로필 이미지 ${isCreating ? '등록' : '수정'}하기`}
          onClose={onClose}
        ></ODModalHeader>
        <ODModalBody>
          <Provider title={'설정하기'}>
            {!isCreating && (
              <ODEntityInput
                keyPath="popupId"
                label="팝업 아이디"
                name="popupId"
                placeholder="팝업 아이디 (자동부여)"
                inputType="text"
                inputProps={{ disabled: true }}
              />
            )}
            <ODEntityLabeled label="이미지 파일" name="profileImageFile">
              <ODImageFileInput
                name="profileImageFile"
                height={200}
                width={200}
                keyPath="profileImageFile.link"
              />
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="description"
              label="설명 지문"
              name="description"
              placeholder="설명 지문"
              inputType="text"
            />
            <ODEntityLabeled
              label="온보딩 선택지 상태"
              name="온보딩 선택지 상태"
            >
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="statusActive"
                  name="status"
                  keyPath="status"
                  value={STATUS.Active}
                >
                  활성
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="statusInactive"
                  name="status"
                  keyPath="status"
                  value={STATUS.Inactive}
                >
                  비활성
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="showOrder"
              label="노출 우선순위"
              name="showOrder"
              placeholder="노출 우선순위를 설정해주세요. 숫자가 높을 수록 먼저 등장합니다."
              inputType="number"
            />
            <ODEntityEditorFooter
              saveButtonName="설정"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={popupProfileImageId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </ODModalBody>
      </div>
    </ODModal>
  )
}
