import {
  ODListablePaginatedTable2,
  ODWebUtils,
  useCounter,
  useODListableContext,
  useWrappedAPI,
} from '@odc/od-react-belt'
import { GQLChoice } from '@storyplay/common'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { Clickable } from '../../../components/base/Clickable'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { ChoicePrimaryKey, ICTChoice } from '../ChoiceCommon'
import { useChoiceDetailDataLoader } from './ChoiceDetailDataLoader'
import { useODModalContext } from '../../../components/ODModal/context/useODModalContext'

interface IChoiceDetailTableProps {
  choiceId: number
}

export const ChoiceDetailTable: React.FC<IChoiceDetailTableProps> = (props) => {
  const { choiceId } = props
  const [token, refresh] = useCounter()
  const [loading, setLoading] = React.useState(false)
  const { Provider, Context } = useODListableContext<GQLChoice, ICTChoice>()
  const dataLoader = useChoiceDetailDataLoader(choiceId)
  const { confirm, confirmInput } = useODModalContext()
  const { updateChoice: apiUpdateChoice } = useStoryPlayAPIs()

  const updateChoice = useWrappedAPI(
    ({
      choiceId,
      field,
      value,
    }: {
      choiceId: number
      field: keyof GQLChoice
      value: number
    }) =>
      apiUpdateChoice({
        id: choiceId,
        [field]: value,
      }),
    loading,
    setLoading,
    {
      deps: [apiUpdateChoice],
      onSuccess: () => {
        ODWebUtils.showSuccess('수정했습니다.')
        refresh()
      },
    }
  )

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[ChoicePrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card>
        <CardBody>
          <ODListablePaginatedTable2
            numColumns={7}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>선택지</th>
                <th>사용자 선택</th>
                <th>이미지</th>
                <th>어드민 조정 값(+/-)</th>
                <th>최종 합계(사용자+어드민)</th>
                <th>사용자 노출 확률(+어드민)</th>
                <th>실제 확률</th>
              </tr>
            )}
            renderRow={(value: GQLChoice) => {
              return Array.from({ length: value.numChoice }).map((_, idx) => {
                const index = idx + 1
                const adminAddColor = // @ts-ignore
                  value[`choice${index}AdminAdd`] >= 0 ? 'green' : 'red'
                return (
                  <tr key={index}>
                    <td>
                      {index}번
                      {
                        // @ts-ignore
                        value[`choice${index}PropName`]
                          ? // @ts-ignore
                            ` (${value[`choice${index}PropName`]})`
                          : ''
                      }
                      {
                        // @ts-ignore
                        value[`choice${index}Content`]
                          ? // @ts-ignore
                            ` ${value[`choice${index}Content`]}`
                          : ''
                      }
                    </td>
                    <td>
                      {
                        // @ts-ignore
                        value[`choice${index}Selected`]
                      }
                    </td>
                    <td>
                      {
                        // @ts-ignore
                        value[`choice${index}ImageUrl`] ? (
                          <img
                            // @ts-ignore
                            src={value[`choice${index}ImageUrl`]}
                            width={50}
                            height={50}
                            alt="choice image url"
                          />
                        ) : (
                          <span>없음</span>
                        )
                      }
                    </td>
                    <td>
                      <Clickable
                        onClick={async () => {
                          const toValue = await confirmInput({
                            // @ts-ignore
                            initial: value[`choice${index}AdminAdd`],
                            inputType: 'number',
                            title: '어드민 조정 값 수정',
                            message: `어드민에서 +/- 할 값을 입력해주세요.`,
                          })

                          if (!toValue) {
                            return
                          }

                          return updateChoice({
                            choiceId: value.choiceId,
                            // @ts-ignore
                            field: `choice${index}AdminAdd`,
                            value: +toValue,
                          })
                        }}
                      >
                        <p style={{ color: adminAddColor }}>
                          {
                            // @ts-ignore
                            value[`choice${index}AdminAdd`]
                          }
                        </p>
                      </Clickable>
                    </td>
                    <td>
                      {
                        // @ts-ignore
                        value[`choice${index}Selected`] +
                          // @ts-ignore
                          value[`choice${index}AdminAdd`]
                      }
                    </td>
                    <td>
                      {
                        // @ts-ignore
                        value[`choice${index}Rate`]
                      }{' '}
                      %
                    </td>
                    <td>
                      {
                        // @ts-ignore
                        value[`choice${index}RealRate`]
                      }{' '}
                      %
                    </td>
                  </tr>
                )
              })
            }}
            eventParentContext={{}}
          />
        </CardBody>
      </Card>
    </Provider>
  )
}
