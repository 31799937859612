import {
  GQLListableStory,
  GQLListableStoryInput,
  GQLOkResponse,
  GQLRecommendationForFirstUser,
  GQLRecommendationForFirstUserCreationInput,
  GQLSTORY_PLAY_TYPE,
  GQLSTORY_SECTION_TYPE,
  GQLSingleIDInput,
  GQLStory,
} from '@storyplay/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import {
  ODListableOption,
  ODListableResponseType,
  useODListableContext,
} from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import {
  ODListablePaginatedTable,
  ODListableTableDefinition,
} from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { STORY_SECTION_TYPE } from '../../common'
import { useModalConfirm } from '../../components/ODModal/ODModalConfirm'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import {
  ODTabBarButton,
  ODTabBarContainer,
} from '../../components/base/ODTabBar'
import { useCounter, useODMutation, useODQuery } from '../../context/ODCommon'
import { COLORS } from '../../styles/colors'
import { SiteUrls } from '../../urls'
import { STORY_FULL_SNAPSHOT } from '../../utils/constants'
import { Utils } from '../../utils/utils'
import { EPUBStoryListItem } from './EPUBStoryListItem'

interface Props {}

export interface StoryDataLoaderOption extends ODListableOption {
  filter: string | null
}

const GQL_LIST = `
query listStory($data: ListableStoryInput!) {
  listStory(data: $data) {
    list {
      ${STORY_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const GQL_REGISTER_RECOMMENDED_STORY_FOR_FIRST_USER = `
mutation createRecommendationForFirstUser($data: RecommendationForFirstUserCreationInput!) {
  createRecommendationForFirstUser(data: $data) {
    rfuId
  }
}
`

export const GQL_UNPUBLISH_STORY = `
mutation unpublishStory($data: SingleIDInput!) {
  unpublishStory(data: $data) {
    ok
  }
}
`

const StoryTab = {
  PublishedOnStage: 'published_on_stage',
  PendingOnStage: 'pending_on_stage',
  PublishedBanner: 'published_banner',
  PublishedDropped: 'published_dropped',
  PublishedGlobal: 'published_global',
  PendingGlobal: 'pending_global',
  PublishedAdult: 'published_adult',
  PendingAdult: 'pending_adult',
  PublishedUGC: 'published_UGC',
  PendingUGC: 'pending_UGC',
}

export const EPUBStoryListContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<
    GQLStory,
    StoryDataLoaderOption
  >()

  const registerForFirstUser = useODMutation<
    GQLRecommendationForFirstUserCreationInput,
    GQLRecommendationForFirstUser
  >(GQL_REGISTER_RECOMMENDED_STORY_FOR_FIRST_USER)

  const listStory = useODQuery<GQLListableStoryInput, GQLListableStory>(
    GQL_LIST
  )
  const unpublishStory = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_UNPUBLISH_STORY
  )

  const [selected, setSelected] = useQueryParam(
    'tab',
    withDefault(StringParam, StoryTab.PublishedOnStage)
  )
  const [token, refresh] = useCounter()
  const canAddNewStory = true
  const isPublishedTab = [
    StoryTab.PublishedOnStage,
    StoryTab.PublishedDropped,
    StoryTab.PublishedGlobal,
    StoryTab.PublishedAdult,
    StoryTab.PublishedUGC,
  ].includes(selected)
  const sectionType = {
    [StoryTab.PublishedOnStage]: STORY_SECTION_TYPE.OnStage,
    [StoryTab.PublishedDropped]: STORY_SECTION_TYPE.Dropped,
    [StoryTab.PublishedGlobal]: STORY_SECTION_TYPE.Global,
    [StoryTab.PendingGlobal]: STORY_SECTION_TYPE.Global,
    [StoryTab.PublishedAdult]: STORY_SECTION_TYPE.Adult,
    [StoryTab.PendingAdult]: STORY_SECTION_TYPE.Adult,
    [StoryTab.PublishedUGC]: STORY_SECTION_TYPE.UGC,
    [StoryTab.PendingUGC]: STORY_SECTION_TYPE.UGC,
  }[selected]
  const [onlyAdult, setOnlyAdult] = useState(false)

  const dataLoader = React.useCallback(
    async function StoryDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: StoryDataLoaderOption
    ): Promise<ODListableResponseType<GQLStory>> {
      const r = await listStory({
        page,
        pageSize,
        filter: options.filter || null,
        published: isPublishedTab,
        sectionType: sectionType as unknown as GQLSTORY_SECTION_TYPE,
        uiSectionType: null,
        genre: options.genre || null,
        sorting: null,
        sortOption: null,
        isFinished: null,
        weekday: null,
        version: null,
        playType: GQLSTORY_PLAY_TYPE.EPUB,
        isAdult: onlyAdult,
      })
      return r as ODListableResponseType<GQLStory>
    },
    [listStory, isPublishedTab, sectionType, onlyAdult]
  )

  const {
    Component: Confirm,
    confirm,
    props: confirmProps,
  } = useModalConfirm({
    title: '비공개로 전환',
    message: (
      <>
        작품을 비공개로 전환합니다. 공개예정일 또한 삭제됩니다. <p />
        계속하시겠습니까?
      </>
    ),
    yes: '전환',
    no: '취소',
  })

  const unpublish = React.useCallback(
    async (storyId: number) => {
      if (!(await confirm())) {
        return
      }

      try {
        await unpublishStory({ id: storyId })
        Utils.showSuccess('비공개로 전환하였습니다.')
        refresh()
      } catch (ex: any) {
        Utils.showError(ex)
      }
    },
    [refresh, confirm, unpublishStory]
  )

  const TableDefinition: ODListableTableDefinition<
    GQLStory,
    StoryDataLoaderOption,
    any
  > = [
    {
      id: 'storyId',
      title: '스토리 아이디',
      transform: (v) => v.storyId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'name',
      title: '스토리명',
      transform: (v) => <EPUBStoryListItem story={v} />,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'publishedAt',
      title: '출간 시각',
      transform: (v) => Utils.formatDate(v.publishedAt),
      thClass: 'text-left',
      className: 'text-left user-td',
      hide: !isPublishedTab,
    },
    {
      id: 'regDate',
      title: '등록 시각',
      transform: (v) => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'showOrder',
      title: '노출 우선순위',
      transform: (v) => v.showOrder,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  if (isPublishedTab) {
    TableDefinition.push({
      id: 'unpublish',
      title: '비공개로 돌리기',
      transform: (v) => (
        <div
          style={{ color: COLORS.Primary, cursor: 'pointer' }}
          onClick={() => unpublish(v.storyId)}
        >
          비공개로 전환
        </div>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    })
  }

  TableDefinition.push({
    id: '',
    title: '첫방문 추천작',
    transform: (v) => (
      <div
        style={{ color: COLORS.Primary, cursor: 'pointer' }}
        onClick={() =>
          registerForFirstUser({
            storyId: v.storyId,
            showOrder: 1000,
            isHidden: true,
          })
            .then(() => {
              Utils.showSuccess('첫방문 추천작 등록 완료')
            })
            .catch((e) => {
              Utils.showError(e.message)
            })
        }
      >
        등록
      </div>
    ),
    thClass: 'text-left',
    className: 'text-left user-td',
  })

  return (
    <FlexContentsContainer>
      <Confirm {...confirmProps} />
      <ODTabBarContainer>
        <ODTabBarButton
          active={selected === StoryTab.PublishedOnStage}
          onClick={() => setSelected(StoryTab.PublishedOnStage)}
        >
          <strong>서비스중인 정식 작품</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === StoryTab.PendingOnStage}
          onClick={() => setSelected(StoryTab.PendingOnStage)}
        >
          <strong>비공개 정식 작품</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === StoryTab.PublishedUGC}
          onClick={() => setSelected(StoryTab.PublishedUGC)}
        >
          <strong>서비스중인 UGC</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === StoryTab.PendingUGC}
          onClick={() => setSelected(StoryTab.PendingUGC)}
        >
          <strong>비공개 UGC</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === StoryTab.PublishedGlobal}
          onClick={() => setSelected(StoryTab.PublishedGlobal)}
        >
          <strong>서비스중인 글로벌</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === StoryTab.PendingGlobal}
          onClick={() => setSelected(StoryTab.PendingGlobal)}
        >
          <strong>비공개 글로벌</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === StoryTab.PublishedAdult}
          onClick={() => setSelected(StoryTab.PublishedAdult)}
        >
          <strong>서비스중인 성인 인증</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === StoryTab.PendingAdult}
          onClick={() => setSelected(StoryTab.PendingAdult)}
        >
          <strong>비공개 성인 인증</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === StoryTab.PublishedDropped}
          onClick={() => setSelected(StoryTab.PublishedDropped)}
        >
          <strong>보물상자</strong>
        </ODTabBarButton>
      </ODTabBarContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.storyId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="스토리명,작가명으로 검색하세요."
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <label>
                <input
                  type="checkbox"
                  checked={onlyAdult}
                  onChange={({ target: { checked } }) => setOnlyAdult(checked)}
                />
                성인 작품만 보기
              </label>
              {canAddNewStory && (
                <div>
                  <Link
                    to={SiteUrls.Admin.EPUBStory.Create}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button block color="primary" style={{ minWidth: 135 }}>
                      새로운 스토리 추가
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩중..'}
              renderEmpty={() => '작품이 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination
              hideIfSinglePage={false}
              listableContext={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
