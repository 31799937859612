"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTEndingSummary = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const validations_1 = require("../../../../validations");
const IStudioClipboard_1 = require("../../../clipboard/IStudioClipboard");
const fields_1 = require("../../fields");
const SelectionFieldWithUI_1 = require("../../fields/SelectionFieldWithUI");
const ui_1 = require("../../ui");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const IDOStatement_1 = require("../IDOStatement");
const StudioFileFieldWithUI_1 = require("../../fields/StudioFileFieldWithUI");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTEndingSummary extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: __1.STATEMENT_TYPE.EndingSummary,
            endingId: block.parentChapter.getStory().generateEndingId(block),
            endingName: block.parentChapter.getStory().generateEndingName(),
            displayName: '',
            background: '',
        }, block, uniqueId);
        this.hasModalForEdit = true;
        this.blockTagType = IDOStatement_1.BlockTagType.ChapterEnding;
        this.endBlockType = IDOStatement_1.EndBlockType.ChapterEnding;
        // 이미지가 변경된 경우 링크 주소. 이 주소가 존재하면, StudioHasStudioFile 로 변경되었다는 의미이다.
        this.uploadedImage = null;
        this.setupFields();
        (0, mobx_1.makeObservable)(this, {
            displayName: mobx_1.computed,
            endingName: mobx_1.computed,
            endingId: mobx_1.computed,
            isDefaultEnding: mobx_1.computed,
            imageLink: mobx_1.computed,
        });
    }
    setupFields() {
        var _a;
        this.idInput = new ui_1.InputWithValidation('endingId', trans('legacy_DOEndingEditor_custom_ending_id'), '', 'text', this.endingId, null, { editable: false });
        this.endingNameField = new fields_1.TextFieldWithUI('endingName', () => this.st.endingName, name => (0, mobx_1.runInAction)(() => (this.st.endingName = name)), {
            label: trans('legacy_DOSTEndingSummary_ending_name_id_unchangeable'),
            inputType: 'text',
            placeholder: trans('legacy_DOSTEndingSummary_enter_ending_name'),
            validation: validations_1.EndingSummaryValidations.content,
            options: {
                editable: false,
                maxLength: 174,
            },
        });
        this.displayNameField = new fields_1.TextFieldWithUI('displayName', () => this.st.displayName || this.st.endingName, name => (0, mobx_1.runInAction)(() => (this.st.displayName = name)), {
            label: trans('legacy_DOEndingEditor_ending_name'),
            inputType: 'text',
            placeholder: trans('legacy_DOEndingEditor_enter_ending_name'),
        });
        this.isDefaultEndingField = new SelectionFieldWithUI_1.SelectionFieldWithUI('isDefaultEnding', () => this.isDefaultEnding, v => {
            this.block.parentChapter.setDefaultEndingCustomId(v ? this.endingId : null);
        }, {
            label: trans('legacy_DOEndingEditor_default_ending'),
            selection: [
                { name: 'Y', value: true },
                { name: 'N', value: false },
            ],
        });
        this.imageField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('endingImage', () => {
            var _a, _b;
            const story = this.block.parentChapter.getStory();
            const ending = story.endingStore.getByName(this.endingName);
            return (_b = (_a = this.uploadedImage) !== null && _a !== void 0 ? _a : ending === null || ending === void 0 ? void 0 : ending.imageFile) !== null && _b !== void 0 ? _b : null;
        }, file => (0, mobx_1.runInAction)(() => (this.uploadedImage = file)), this.helper.story, {
            options: { imageLink: (_a = this.imageLink) !== null && _a !== void 0 ? _a : undefined },
        });
    }
    get st() {
        return this.data;
    }
    get endingName() {
        return this.st.endingName;
    }
    get displayName() {
        return this.st.displayName || this.displayNameField.value;
    }
    get endingId() {
        return this.st.endingId;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [
            ...super.editorFields,
            this.endingNameField,
            this.displayNameField,
            this.isDefaultEndingField,
            this.imageField,
        ];
    }
    /**
     * 이 엔딩이 스튜디오에서 보여줄 이미지 링크.
     */
    get imageLink() {
        var _a, _b, _c, _d;
        const story = this.block.parentChapter.getStory();
        const ending = story.endingStore.getByName(this.endingName);
        // 현재 교체된 업로드 이미지 > 업로드된 엔딩 이미지 > 기본 플레이스홀더 이미지
        return (_d = (_b = (_a = this.uploadedImage) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : (_c = ending === null || ending === void 0 ? void 0 : ending.imageFile) === null || _c === void 0 ? void 0 : _c.link) !== null && _d !== void 0 ? _d : null;
    }
    getStory() {
        return this.block.parentChapter.getStory();
    }
    getEnding() {
        const story = this.block.parentChapter.getStory();
        return story.endingStore.getByName(this.endingName);
    }
    canUploadPreviewImage() {
        return !!this.getEnding();
    }
    startEditing() {
        this.isDefaultEndingField.reload();
        super.startEditing();
    }
    /**
     * 이 엔딩이 디폴트 엔딩인가?
     */
    get isDefaultEnding() {
        var _a;
        return ((_a = this.block.parentChapter) === null || _a === void 0 ? void 0 : _a.defaultEndingCustomId) === this.endingId;
    }
    generateSheetColumnsAfterBackground() {
        // ['displayName', 엔딩명, 엔딩아이디, 디폴트엔딩?]
        return [
            this.displayName,
            this.endingName,
            this.endingId,
            this.isDefaultEnding ? '디폴트 엔딩' : '',
            // this.imageLink, // TODO: 엔딩 프리뷰 체크
        ];
    }
    validate() {
        const results = super.validate();
        const story = this.block.parentChapter.story;
        story.chapterStore.all.forEach(chapter => {
            chapter.blockStore.all.forEach(block => {
                block.statements.forEach(st => {
                    if ((st instanceof DOSTEndingSummary ||
                        st instanceof __1.DOSTFinalEndingSubBlock) &&
                        st !== this) {
                        if (st.endingId === this.endingId) {
                            results.push({
                                type: __1.StudioValidationType.EndingIdDuplicate,
                                source: this,
                                severity: __1.StudioValidationSeverity.Error,
                                stack: [],
                                extra: { chapter, block, st },
                            });
                        }
                        if (st.endingName === this.endingName) {
                            results.push({
                                type: __1.StudioValidationType.EndingNameDuplicate,
                                source: this,
                                severity: __1.StudioValidationSeverity.Error,
                                stack: [],
                                extra: { chapter, block, st },
                            });
                        }
                    }
                });
            });
        });
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    onCopyEvent() {
        const data = (0, lodash_1.cloneDeep)(this.data);
        data.endingId = this.block.parentChapter
            .getStory()
            .generateEndingId(this.block);
        data.endingName = trans('legacy_DOSTEndingSummary_copy_of_value', {
            value: data.endingName,
        });
        return {
            isStudio: true,
            dataType: IStudioClipboard_1.StudioClipboardDataType.Statements,
            lines: [data],
        };
    }
    async submitEditing(opFactory) {
        return super.submitEditing(opFactory).then(res => {
            if (this.isDefaultEndingField.value) {
                this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(__1.GA4_EVENT_NAME.CREATE_DEFAULT_CHAPTER_ENDING);
            }
            return res;
        });
    }
    replaceData(data) {
        const op = super.replaceData(data);
        // 데이터가 변경되었을 때 실제 필드값은 변경되지 않는 문제가 있다.
        this.setupFields();
        return op;
    }
    get isPublishedEnding() {
        return !!this.block.parentChapter.publishedAt;
    }
    //
    // IStudioTranslator interface
    //
    async getMessagesToTranslate() {
        return {
            messages: [this.st.endingName],
        };
    }
    async applyTranslatedMessages(translated) {
        (0, mobx_1.runInAction)(() => {
            this.st.endingName = translated.messages[0];
        });
    }
}
exports.DOSTEndingSummary = DOSTEndingSummary;
