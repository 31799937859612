"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStudioTutorialStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const ActionChainBuilder_1 = require("../../../../autoLayout/builder/ActionChainBuilder");
const studioTutorial_const_1 = require("./studioTutorial.const");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
// 다른 store 처럼 entity 자체를 관리하진 않고,
// StudioTutorialCompletionStore 모든 리스트 및 로직만 관리합니다.
class DOStudioTutorialStore {
    constructor(rootStore) {
        // api 를 통해 받아온 완료된 튜토리얼 리스트
        // 받아올 때 과거에는 존재했지만, 현재는 존재하지 않는 코드는 필터링 됩니다.
        this._allTutorialCompletions = [];
        // 완료된 튜토리얼을 UI 에서 표시하지 않기 위해 추가되었습니다.
        this.isHideCompletedTutorial = false;
        // api 를 호출하는 경우 해당 필드로 loading 여부를 판단합니다.
        this.isLoading = false;
        // loadAllTutorialCompletions 메서드에서 사용됩니다.
        // 서버에서 완료된 튜토리얼 정보들을 받아왔는지에 대한 필드입니다.
        // 중복 호출을 방지하기 위해 추가되었습니다.
        this.isLoaded = false;
        // popover 를 오픈시킬 수 있는 함수를 버튼이 렌더 될 때 가져와서 필드로써 사용합니다.
        this.openPopover = null;
        this.rootStore = rootStore;
        (0, mobx_1.makeObservable)(this, {
            _allTutorialCompletions: mobx_1.observable,
            isLoading: mobx_1.observable,
            isLoaded: mobx_1.observable,
            isHideCompletedTutorial: mobx_1.observable,
            openPopover: mobx_1.observable,
            codesThatCanBeCompleted: mobx_1.computed,
            completionCodes: mobx_1.computed,
            _tutorialCompletionCounts: mobx_1.computed,
            tutorialProgressRate: mobx_1.computed,
            markUserStudioTutorialProgress: mobx_1.action,
            resetUserStudioTutorialProgress: mobx_1.action,
        });
    }
    getTutorialUrl(tutorialEventName) {
        var _a;
        return (_a = studioTutorial_const_1.tutorialUrlMapByTutorialEvent[tutorialEventName]) !== null && _a !== void 0 ? _a : '';
    }
    // 모든 tutorial 코드를 반환합니다.
    _getAllTutorialCodes() {
        return Object.keys(this.tutorialMapByCode);
    }
    // 이미 완료되었거나, 완료되어야 할 코드들을 배열로 반환합니다.
    // 해당 getter 는 튜토리얼을 순차적으로 완료시키기 위하여 추가된 메서드입니다.
    // 반환된 값에 포함되지 않는 코드는 전부 lock 처리 됩니다.
    get codesThatCanBeCompleted() {
        const allTutorialCode = this._getAllTutorialCodes();
        const result = [];
        for (const c of allTutorialCode) {
            if (this.completionCodes.includes(c)) {
                result.push(c);
            }
            else {
                result.push(c);
                break;
            }
        }
        return result;
    }
    // 모든 tutorial 은 프론트 코드에서 객체 상태로 존재합니다.
    // 모든 튜토리얼 관련 데이터를 아래와 같은 타입으로 반환합니다.
    // { [key: string]: { categoryName: string; codeMap: TutorialCodeMap } }
    get tutorialMapByCode() {
        return studioTutorial_const_1.StudioTutorialCodeMap;
    }
    // tutorial 의 갯수를 반환합니다.
    get tutorialTotalCounts() {
        return Object.keys(studioTutorial_const_1.StudioTutorialCodeMap).length;
    }
    // 완료된 튜토리얼 갯수를 반환합니다.
    get _tutorialCompletionCounts() {
        return this._allTutorialCompletions.length;
    }
    // 튜토리얼의 진척도를 0 ~ 1 으로 반환합니다.
    get tutorialProgressRate() {
        const noHaveTutorialCompletion = this._tutorialCompletionCounts === 0;
        return noHaveTutorialCompletion
            ? 0
            : this._tutorialCompletionCounts / this.tutorialTotalCounts;
    }
    // UI 에서 표시할 수 있도록 카테고리를 기준으로 하여, 하위 튜토리얼을 같이 반환해줍니다.
    get listTutorialCategories() {
        return studioTutorial_const_1.ListStudioTutorialByCategory;
    }
    // code 는 사실, string 이 아닌 프론트에서 생성한 enum 값입니다.
    get completionCodes() {
        return this._allTutorialCompletions.map(tutorial => tutorial.code);
    }
    // 최초 렌더시에 popover 를 오픈할 수 있는 함수를, 컴포넌트에서 받아옵니다.
    setOpenPopover(openPopover) {
        (0, mobx_1.runInAction)(() => {
            this.openPopover = openPopover;
        });
    }
    // isHideCompletedTutorial 을 변경시키기 위한 메서드입니다.
    changeIsHideCompletedTutorial(isHide) {
        (0, mobx_1.runInAction)(() => {
            this.isHideCompletedTutorial = isHide;
        });
    }
    // 모든 tutorialCompletion 을 불러옵니다.
    // 호출 시점은 로그인 이후 -> rootStore.loadUserData 메서드 실행될 때 호출
    // 로딩은 rootStore 의 loadUserData 에서 관리
    // 이전에는 있었지만, 현재는 없어진 코드는 필터링 해줍니다.
    async loadAllTutorialCompletions() {
        if (!this.rootStore.di.canPlayTutorial) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isLoaded = false;
        });
        try {
            const res = await this.rootStore.di.server.listStudioTutorialCompletion();
            // 혹시나 이전에는 있었지만, 없어진 코드가 있을 경우 필터링합니다.
            (0, mobx_1.runInAction)(() => {
                this._allTutorialCompletions = res.list.filter(item => this._getAllTutorialCodes().includes(item.code));
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isLoaded = true;
            });
        }
    }
    // 해당 스토어에서 가장 중요한 로직입니다.
    // 해당 메서드를 통해
    // 이벤트 발생시 GA4 에 무조건 이벤트를 보냅니다.
    // 1. 이벤트 발생 (무조건)
    // 2. 최초 이벤트 발생 (최초에만) -> 튜토리얼 완료
    // 이미 완료된 코드는 이벤트 발생만 적용됩니다.
    // 락이 걸려 있는 튜토리얼또한 이벤트 발생만 적용됩니다.
    // 최초 이벤트 발생시에만 이벤트 발생 + 최초 이벤트 발생(코드의 튜토리얼이 완료되는 순서일 때) + 튜토리얼 완료(코드의 튜토리얼이 완료되는 순서일 때)
    // 최종 완료시 최종 완료 모달을 오픈시킵니다.
    // 튜토리얼 완료시마다 중간 완료 모달을 오픈시킵니다.
    // - 최종 완료시의 튜토리얼은 중간 완료 모달은 오픈이 안 되며 최종 완료 모달이 오픈됩니다.
    async markUserStudioTutorialProgress(code) {
        if (!this.rootStore.di.canPlayTutorial) {
            return;
        }
        if (!this.isLoaded || this.isLoading) {
            return;
        }
        this.rootStore.di.reactGA4.event(code, {
            event_name: code,
        });
        // 이미 완료된 코드일경우 또는 lock 되어 있는 튜토리얼은 튜토리얼 완료 처리를 하지 않습니다.
        if (this.completionCodes.includes(code) ||
            !this.codesThatCanBeCompleted.includes(code)) {
            return;
        }
        this.isLoading = true;
        try {
            await this.rootStore.di.server.markUserStudioTutorialProgress({
                code,
                extra: null,
            });
            // 완료 api 를 호출하고 난 뒤 리스트를 불러옵니다.
            await this.loadAllTutorialCompletions();
            this.rootStore.di.reactGA4.event(`FIRST_${code}`, {
                event_name: `FIRST_${code}`,
            });
            if (this._tutorialCompletionCounts >= this.tutorialTotalCounts) {
                this.rootStore.di.reactGA4.event(`TUTORIAL_ALL_FINISHED`, {
                    event_name: `TUTORIAL_ALL_FINISHED`,
                });
                const builder = new ActionChainBuilder_1.ActionChainBuilder('CompletedTutorial');
                builder.completedTutorial();
                this.rootStore.autoLayoutManager.addActionChain(builder.buildActionChain());
            }
            else {
                const findCompletionCode = this._getAllTutorialCodes().findIndex(item => item === code);
                // 못 찾을 일은 없지만 혹시 모르니 방어 코드
                if (findCompletionCode < 0) {
                    return;
                }
                const nextCode = this._getAllTutorialCodes()[findCompletionCode + 1];
                // 로직상 있을테지만 혹시 모르니 방어 코드
                if (!nextCode) {
                    return;
                }
                const nextTutorial = this.tutorialMapByCode[nextCode];
                this.rootStore.showTutorialCheck(trans('legacy_DOStudioTutorialStore_tutorial_completed', {
                    value1: this._tutorialCompletionCounts,
                    value2: this.tutorialTotalCounts,
                }), trans('legacy_DOStudioTutorialStore_completed_tutorial', {
                    value: this.tutorialMapByCode[code].title,
                }), trans('legacy_DOStudioTutorialStore_next', {
                    value: nextTutorial.title,
                }), () => {
                    var _a;
                    (_a = this.openPopover) === null || _a === void 0 ? void 0 : _a.call(this);
                    this.changeIsHideCompletedTutorial(true);
                });
            }
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        finally {
            // 튜토리얼이 연속으로 완료될 수 있으므로,
            // 동기 코드보다 늦게 isLoading 을 false 로 재할당합니다.
            setTimeout(() => {
                this.isLoading = false;
            }, 0);
        }
    }
    // 스튜디오 어드민에서 튜토리얼 진척도를 초기화시키기 위해 추가되었습니다.
    async resetUserStudioTutorialProgress(id) {
        if (!this.rootStore.di.canPlayTutorial) {
            return true;
        }
        this.isLoading = true;
        try {
            await this.rootStore.di.server.resetUserStudioTutorialProgress({ id });
        }
        catch (ex) {
            this.rootStore.showError(ex);
            return false;
        }
        finally {
            this.isLoading = false;
        }
        return true;
    }
}
exports.DOStudioTutorialStore = DOStudioTutorialStore;
