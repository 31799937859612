"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryItem = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
const IDOStoryItem_1 = require("./IDOStoryItem");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
/**
 * Character domain object.
 */
class DOStoryItem {
    constructor(store, data, isLocallyCreated = false) {
        this.desc = '';
        this.changes = [];
        this.imageFile = null;
        this.store = store;
        this.data = data;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            isHidden: mobx_1.observable,
            imageFile: mobx_1.observable,
            merge: mobx_1.action,
            imageLink: mobx_1.computed,
            convertedItemName: mobx_1.computed,
        });
        if (isLocallyCreated) {
            this.changes.push(IDOStoryItem_1.DOStoryItemChange.ItemCreated);
        }
    }
    merge(data) {
        const fields = [
            'storyItemId',
            'name',
            'desc',
            'isHidden',
            'imageFile',
            'displayName',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    get serverId() {
        return this.storyItemId;
    }
    get imageLink() {
        var _a, _b;
        return (_b = (_a = this.imageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null;
    }
    setName(name) {
        if (this.isLocallyCreated) {
            (0, mobx_1.runInAction)(() => {
                this.name = name;
            });
        }
        else {
            throw new Error(trans('legacy_DOStoryItem_unimplemented_change_of_existing_attribute_name'));
        }
    }
    setDesc(desc) {
        if (this.isLocallyCreated) {
            (0, mobx_1.runInAction)(() => {
                this.desc = desc;
            });
        }
        else {
            throw new Error(trans('legacy_DOStoryItem_unimplemented_change_of_existing_attribute_name'));
        }
    }
    get isError() {
        return this.storyItemId === IDOStoryItem_1.StoryItemErrorId;
    }
    get isLocallyCreated() {
        return this.changes.includes(IDOStoryItem_1.DOStoryItemChange.ItemCreated);
    }
    async create() {
        var _a;
        const input = {
            storyId: this.store.story.storyId,
            name: this.name,
            desc: this.desc,
            isHidden: this.isHidden,
        };
        if ((_a = this.imageFile) === null || _a === void 0 ? void 0 : _a.fileId) {
            input.imageFromStudioFileId = this.imageFile.fileId;
        }
        const e = await this.store.rootStore.di.server.createEntityForStudio(this, input);
        // 로컬에서 사용하던 아이디를 버리고, 서버와 동기화된 아이디로 새롭게 등록한다.
        this.storyItemId = e.storyItemId;
        this.store.merge(e);
        this.changes.length = 0;
        return e;
    }
    async update(changeSet) {
        return this.store.rootStore.di.server.updateEntityForStudio(this, changeSet);
    }
    get convertedItemName() {
        if (!this.displayName) {
            return this.name;
        }
        if (this.displayName !== this.name) {
            return `${this.displayName} (${this.name})`;
        }
        return this.displayName;
    }
    shouldBeFilteredBy(keyword) {
        return this.convertedItemName.toLowerCase().includes(keyword);
    }
}
exports.DOStoryItem = DOStoryItem;
