import { AppSidebarToggler } from '@coreui/react'
import { ODIcon } from '@odc/od-react-belt'
import { padStart } from 'lodash'
import preval from 'preval.macro'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Nav } from 'reactstrap'
import styled from 'styled-components'
import { GQLUser } from '../@types/server'
import { TOKEN_EXPIRE_AFTER_SEC_WRITER, TOKEN_EXPIRE_AFTER_SEC_ADMIN } from '../common'
import { useAppContext } from '../context/AppContext'
import { SiteUrls } from '../urls'
import { AppOptions } from '../utils/AppOptions'
import { USER_PRIV } from '../utils/constants'
import { ODIcons } from './ODIcon'
import { ProfileDropdown } from './ProfileDropdown'

const buildTime = preval`module.exports = new Date().toLocaleString();`

interface Props {
  name?: string | null
  profile: GQLUser | null
  isInAdmin: boolean // 현재 슈퍼 관리자 페이지 안에 있나?
  isInOrgAdmin: number | null | false // 현재 특정 기관 관리자 페이지 안에 있나? (orgId)
}

const BuildTime = styled.div`
  font-size: 12px;
  color: #c9c9c9;
  margin-right: 10px;
`

export const ODHeader: React.FC<Props> = ({ name, profile, isInAdmin, isInOrgAdmin }) => {
  const { tokenExpireAt, extendSession, state } = useAppContext()
  const [, setTick] = React.useState(0)

  const isWriter = (state.userProfile?.priv ?? 0) <= USER_PRIV.Author
  const [expireAfterText, isUrgent] = (() => {
    if (!tokenExpireAt) {
      return ['', false]
    }

    const afterSec = Math.floor(
      (tokenExpireAt +
        (isWriter ? TOKEN_EXPIRE_AFTER_SEC_WRITER : TOKEN_EXPIRE_AFTER_SEC_ADMIN) * 1000 -
        new Date().getTime()) /
        1000
    )
    const isUrgent = afterSec < 60 * 10
    const text = `${padStart(Math.floor(afterSec / 60).toString(), 2, '0')}:${padStart(
      (afterSec % 60).toString(),
      2,
      '0'
    )}`
    return [text, isUrgent]
  })()

  React.useEffect(() => {
    const handler = setInterval(() => {
      setTick((t) => t + 1)
    }, 1000)
    return () => clearInterval(handler)
  }, [])

  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <Link to={SiteUrls.Admin.Main}>
        <span style={{ fontSize: 24, fontWeight: 'bold', paddingLeft: 20 }}>STORY PLAY</span>
      </Link>
      <Nav className="ml-auto" navbar>
        {AppOptions.SITE_MANUAL_URL && (
          <input
            style={{ 
              marginRight: 10,
              padding: 7,
              backgroundColor: '#b5944b',
              borderRadius: 5,
              color: 'white',
            }}
            type="button"
            value="어드민 설명서"
            onClick={() => {
              window.open(AppOptions.SITE_MANUAL_URL, '_blank')
            }}
          ></input>
        )}
        {expireAfterText && (
          <ExpireAfterText
            style={{
              fontSize: isUrgent ? 18 : 14,
              fontWeight: isUrgent ? 'bold' : 'normal',
              color: isUrgent ? 'red' : '#393939',
            }}
            onClick={extendSession}
          >
            {expireAfterText} | 로그인 연장하기
          </ExpireAfterText>
        )}
        <BuildTime>Web build : {buildTime}</BuildTime>
        <ProfileDropdown name={name} isAdmin />
      </Nav>
    </React.Fragment>
  )
}

const ExpireAfterText = styled.div`
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
`
