import { ODWebUtils } from '@odc/od-react-belt'
import { BOOK, ENDING_INFO } from '@storyplay/common'
import produce from 'immer'
import { partition, sortBy } from 'lodash'
import React, { useState } from 'react'
import Select from 'react-select'
import { Card, CardBody, Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { GQLEndingMigrationInput, GQLStory } from '../../@types/server'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { Utils } from '../../utils/utils'
import { ODButton, ODButtonTheme } from '../base/ODButton'
import { ODTabBarButton, ODTabBarContainer } from '../base/ODTabBar'
import { ODModal, ODModalSize } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

export interface IEndingToMigrateInfo {
  endingCustomId: string // 이 엔딩의 customId
  name: string
  isFinal: boolean // 종료 엔딩인가?
  chapterId: number // 챕터의 디비상 id
  chapterCustomId: string // 기존에 존재하던 챕터의 custom id.
  chapterName: string // 기존에 존재하던 챕터의 제목.
}

interface IEndingsMigrationDialogProps {
  endingsToMigrate: IEndingToMigrateInfo[]
  newScriptBook: Pick<BOOK, 'endings' | 'chapters'> // 새롭게 파싱된 book 에서의 챕터 및 엔딩 정보
  storyId: number
  onClose: () => void
  onConfirm: (result: GQLEndingMigrationInput) => void
}

interface ITabInfo {
  chapterId: number | null // null = 챕터가 삭제된다. 사라질 예정
  chapterCustomId: string
  chapterName: string
  endingCustomIds: string[] // 이 챕터에 속한 엔딩의 customId
}

interface IOptionType {
  key: string | number
  value: string // mapped ending's custom id
  label: string // name of the new ending
}

export const EndingsMigrationDialog: React.FC<IEndingsMigrationDialogProps> = (
  props
) => {
  const { endingsToMigrate, newScriptBook, onConfirm, onClose, storyId } = props

  const { apiGetStory } = useStoryPlayAPIs()
  const [story, setStory] = useState<GQLStory | null>(null)
  const [result, setResult] = useState<GQLEndingMigrationInput>(() => {
    const r: GQLEndingMigrationInput = {
      mapping: endingsToMigrate.map((en) => {
        return { chapterId: en.chapterId, from: en.endingCustomId, to: '' }
      }),
      storyId,
    }
    return r
  })
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const [tabs] = useState<ITabInfo[]>(() => {
    const book = newScriptBook
    const tabs: ITabInfo[] = []

    const getTabWithChapterId = (
      chapterId: number | null,
      chapterName: string,
      chapterCustomId: string
    ) => {
      const tab = tabs.find((t) => t.chapterId === chapterId)
      if (!tab) {
        const newTab: ITabInfo = {
          chapterId,
          chapterName,
          chapterCustomId,
          endingCustomIds: [],
        }
        tabs.push(newTab)
        return newTab
      }
      return tab
    }

    endingsToMigrate.forEach((ending) => {
      const chapterCustomId = ending.chapterCustomId
      const chapterFoundInNewBook = Object.keys(book.chapters).find(
        (k) => book.chapters[k].customId === chapterCustomId
      )
      if (!chapterFoundInNewBook) {
        // 챕터가 사라졌다.
        getTabWithChapterId(
          null,
          ending.chapterName,
          ending.chapterCustomId
        ).endingCustomIds.push(ending.endingCustomId)
        return
      }

      getTabWithChapterId(
        ending.chapterId,
        ending.chapterName,
        ending.chapterCustomId
      ).endingCustomIds.push(ending.endingCustomId)
    })

    return sortBy(tabs, (v) => v.chapterId)
  })

  // 스토리를 읽어와서 마이그레이션이 어떻게 발생해야 하는지 결정한다.
  React.useEffect(() => {
    async function loadStory() {
      try {
        const story = await apiGetStory({ id: storyId })
        setStory(story)
      } catch (ex) {
        ODWebUtils.showError(ex)
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    loadStory()
  }, [storyId, apiGetStory])

  const handleConfirm = async () => {
    const endingError = result!.mapping.find((m) => m.to === '')
    if (endingError) {
      const ending = endingsToMigrate.find(
        (v) => v.endingCustomId === endingError.from
      )!
      Utils.showError(`${ending.name} 엔딩에 대한 매핑이 없습니다.`)
      return
    }

    onConfirm(result)
  }

  const isLoading = story === null
  const endingsToMigrateInThisTab = tabs[selectedTabIndex].endingCustomIds.map(
    (customId) => endingsToMigrate.find((e) => e.endingCustomId === customId)!
  )
  const currentTabCustomChapterId = tabs[selectedTabIndex].chapterCustomId
  const allEndingsInThisTabChapter: ENDING_INFO[] = Object.keys(
    newScriptBook.endings
  )
    .map((k) => newScriptBook.endings[k])
    .filter((e) => {
      const chapterName = e.chapter // id 기반이 아니라서 이름으로 찾아야 한다.
      const chapterKey = Object.keys(newScriptBook.chapters).find(
        (k) => newScriptBook.chapters[k].name === chapterName
      )
      !chapterKey &&
        console.warn(
          `Unexpected! => Cannot find chapter named [${chapterName}] !!!`
        )
      const chapter = newScriptBook.chapters[chapterKey!]
      return chapter.customId === currentTabCustomChapterId
    })

  const [finalEndingsForThisTab, chapterEndingsForThisTab] = partition(
    allEndingsInThisTabChapter,
    (v) => v.isFinal
  )
  const finalEndingOptions: IOptionType[] = finalEndingsForThisTab.map(
    (en) => ({
      key: en.id,
      label: `${en.name} [${en.id}]`,
      value: en.id,
    })
  )
  const chapterEndingOptions: IOptionType[] = chapterEndingsForThisTab.map(
    (en) => ({
      key: en.id,
      label: `${en.name} [${en.id}]`,
      value: en.id,
    })
  )

  // const onChange = ()

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.XLarge}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="엔딩 마이그레이션" onClose={onClose} />
        <ODModalBody>
          {isLoading && <span>불러오는 중...</span>}
          {!isLoading && (
            <ODTabBarContainer>
              {tabs.map(({ chapterId, chapterName }, index: number) => {
                const isSelected = index === selectedTabIndex
                const willBeDeleted = chapterId === null
                const title = `${
                  willBeDeleted ? '[삭제 예정]' : ''
                } ${chapterName}`

                return (
                  <ODTabBarButton
                    active={isSelected}
                    onClick={() => setSelectedTabIndex(index)}
                    key={index}
                  >
                    {isSelected && <strong>{title}</strong>}
                    {!isSelected && <span>{title}</span>}
                  </ODTabBarButton>
                )
              })}
            </ODTabBarContainer>
          )}
          {!isLoading && (
            <Card>
              <CardBody>
                <Row style={{ marginBottom: 20 }}>
                  <Col md={5} style={{ textAlign: 'center' }}>
                    <LabelDiv style={{ fontWeight: 'bold', fontSize: 15 }}>
                      사라질 엔딩
                    </LabelDiv>
                  </Col>
                  <Col md={1}>
                    <LabelDiv style={{ fontWeight: 'bold', fontSize: 15 }}>
                      ==&gt;
                    </LabelDiv>
                  </Col>
                  <Col md={5} style={{ textAlign: 'center' }}>
                    <LabelDiv style={{ fontWeight: 'bold', fontSize: 15 }}>
                      변경할 엔딩
                    </LabelDiv>
                  </Col>
                </Row>
                {endingsToMigrateInThisTab.map((value, index) => {
                  const isFinal = value.isFinal
                  const options = isFinal
                    ? finalEndingOptions
                    : chapterEndingOptions
                  const mapping = result.mapping.find(
                    (v) => v.from === value.endingCustomId
                  )!
                  const mappedValue = mapping.to
                    ? options.find((o) => o.value === mapping.to)
                    : null

                  return (
                    <Row key={index} style={{ marginBottom: 10 }}>
                      <Col md={5}>
                        <LabelDiv>
                          {value.name}&nbsp;
                          <span style={{ fontWeight: 'bold' }}>
                            [{isFinal ? '종료 엔딩' : '챕터 엔딩'}]
                          </span>
                        </LabelDiv>
                        <LabelDiv style={{ color: '#393939' }}>
                          ID: {value.endingCustomId}
                        </LabelDiv>
                      </Col>
                      <Col md={1}>
                        <LabelDiv>==&gt;</LabelDiv>
                      </Col>
                      <Col md={5}>
                        <Select
                          value={mappedValue}
                          options={options}
                          placeholder="매핑할 엔딩을 선택하세요."
                          isMulti={false}
                          onChange={(v) => {
                            setResult((r) =>
                              produce(r, (draft) => {
                                draft.mapping.find(
                                  (m) => m.from === value.endingCustomId
                                )!.to = v ? (v as IOptionType).value : ''
                                return draft
                              })
                            )
                          }}
                        />
                      </Col>
                    </Row>
                  )
                })}
              </CardBody>
            </Card>
          )}
        </ODModalBody>
        {!isLoading && (
          <ODModalFooter>
            <ODButton
              fullWidth
              theme={ODButtonTheme.Primary}
              onClick={handleConfirm}
            >
              진행
            </ODButton>
          </ODModalFooter>
        )}
      </div>
    </ODModal>
  )
}

const LabelDiv = styled.div`
  margin-top: 7px;
`
