import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLStoryProperty } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IStoryPropertyListableOption } from '../StoryPropertyCommon'

export function useStoryPropertyListDataLoader(storyId: number) {
  const { listStoryPropertyOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IStoryPropertyListableOption
    ): Promise<ODListableResponseType<GQLStoryProperty>> {
      const r = await listStoryPropertyOfStory({
        page,
        pageSize: 100,
        storyId,
        ...options,
      })
      return r as ODListableResponseType<GQLStoryProperty>
    },
    [listStoryPropertyOfStory, storyId]
  )
}
