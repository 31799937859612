"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTChoiceSubBlock = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../../../changeOp");
const consts_1 = require("../../../../../../consts");
const models_1 = require("../../../../../../models");
const scripter_1 = require("../../../../../../scripter/scripter");
const storeUtils_1 = require("../../../../utils/storeUtils");
const validation_1 = require("../../../../validation");
const StudioFileFieldWithUI_1 = require("../../../fields/StudioFileFieldWithUI");
const studioTutorial_1 = require("../../../studioTutorial");
const ui_1 = require("../../../ui");
const DOBaseScriptSubBlock_1 = require("../../DOBaseScriptSubBlock");
const IDOStatement_1 = require("../../IDOStatement");
const DOSTChoice_1 = require("../DOSTChoice");
const DOSTChoiceIf_1 = require("../DOSTChoiceIf");
const DOSTChoiceSaveProp_1 = require("../DOSTChoiceSaveProp");
const DOSTChoiceToBlock_1 = require("../DOSTChoiceToBlock");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
const CHOICE_DISPLAYNAME = ['높은 애정 표현', '낮은 애정 표현'];
const ACCEPTABLE_STATEMENTS = [
    models_1.STATEMENT_TYPE.ChoiceToBlock,
    models_1.STATEMENT_TYPE.ChoiceSaveProp,
    models_1.STATEMENT_TYPE.ChoiceCharacter,
    models_1.STATEMENT_TYPE.ChoiceIfSaveProp,
    models_1.STATEMENT_TYPE.ChoiceIfAchievementEvent,
    models_1.STATEMENT_TYPE.ChoiceIfUpdateItem,
];
const ChoiceStatementOrder = {
    [models_1.STATEMENT_TYPE.Choice]: 1,
    [models_1.STATEMENT_TYPE.ChoiceIfSaveProp]: 3,
    [models_1.STATEMENT_TYPE.ChoiceIfAchievementEvent]: 3,
    [models_1.STATEMENT_TYPE.ChoiceIfUpdateItem]: 3,
    // 스크립트 엑셀에서 ChoiceToBlock 이 마지막이어야 제대로 import 된다.
    [models_1.STATEMENT_TYPE.ChoiceToBlock]: 5,
};
class DOSTChoiceSubBlock extends DOBaseScriptSubBlock_1.DOBaseScriptSubBlock {
    constructor(statements, block, uniqueId) {
        super(statements, block, IDOStatement_1.DOSubBlockType.Choice, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.Choice;
        // startEditing() 을 호출했을 때부터만 실제 값이 보여지므로 주의할 것.
        this.choiceFieldRows = [];
        this.isEditing = false;
        (0, mobx_1.makeObservable)(this, {
            choiceFieldRows: mobx_1.observable,
            choices: mobx_1.computed,
            choiceToBlockStatement: mobx_1.computed,
            canAddRow: mobx_1.computed,
            canRemoveRow: mobx_1.computed,
            savePropStatements: mobx_1.computed,
            choiceFieldRowsByLayoutType: mobx_1.computed,
            isValidPlayData: mobx_1.computed,
        });
    }
    getOrderForChoiceStatement(st) {
        // @ts-ignore
        return ChoiceStatementOrder[st.statementType];
    }
    mergeStatement(statement) {
        if (statement.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            ACCEPTABLE_STATEMENTS.includes(statement.statementType)) {
            (0, mobx_1.runInAction)(() => {
                this.statements.push(statement);
                this.alignStatementsOrder();
            });
            return true;
        }
        return false;
    }
    get stChoice() {
        return this.statements.find(v => v instanceof DOSTChoice_1.DOSTChoice);
    }
    get stChoiceToBlock() {
        return this.statements.find(v => v instanceof DOSTChoiceToBlock_1.DOSTChoiceToBlock);
    }
    // choices 의 index 를 파라메터로 받아서
    // 해당 index 에 속해있는 저장된 속성(속성, 아이템, 업적)들을 반환한다.
    // 블록 에디터에서 각 choices 인덱스에 해당하는 저장된 속성들을 불러오기 위함
    getSavePropStatementsBy(choiceIndex) {
        return this.savePropStatements.filter(item => item.choiceIndex === choiceIndex);
    }
    fixStatements() {
        (0, mobx_1.runInAction)(() => {
            const st0 = this.statements.find(v => v instanceof DOSTChoice_1.DOSTChoice);
            if (!st0) {
                this.statements.push(new DOSTChoice_1.DOSTChoice(null, this.block));
            }
            const st2 = this.statements.find(v => v instanceof DOSTChoiceToBlock_1.DOSTChoiceToBlock);
            if (!st2) {
                this.statements.push(new DOSTChoiceToBlock_1.DOSTChoiceToBlock(null, this.block));
            }
            this.statements = (0, lodash_1.flatten)(this.statements.map(st => {
                if (st instanceof DOSTChoiceSaveProp_1.DOSTChoiceSaveProp) {
                    return DOSTChoiceIf_1.DOSTChoiceIf.convertChoiceSaveProp(st); // ts 가 제대로 인지를 못해서
                }
                return st;
            }));
            this.alignStatementsOrder();
            this.updateChoiceFieldRows();
        });
    }
    get blocksTo() {
        const choiceBlock = this.statements.find(v => v.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            v.statementType === models_1.STATEMENT_TYPE.Choice);
        return (0, lodash_1.flatten)(this.statements
            .filter(v => v.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            v.statementType === models_1.STATEMENT_TYPE.ChoiceToBlock)
            .map(b => {
            return b.blocksTo.map((bt, index) => {
                var _a;
                return ({
                    block: bt.block,
                    label: (_a = choiceBlock === null || choiceBlock === void 0 ? void 0 : choiceBlock.getChoiceAt(index)) !== null && _a !== void 0 ? _a : '',
                    extra: bt.extra,
                });
            });
        }));
    }
    get choiceToBlockStatement() {
        const st = this.statements.find(v => v.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            v.statementType === models_1.STATEMENT_TYPE.ChoiceToBlock);
        return st;
    }
    get choices() {
        const choice = this.statements.find(v => v.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            v.statementType === models_1.STATEMENT_TYPE.Choice);
        if (!choice) {
            return [];
        }
        const cst = choice;
        const choices = cst.st.choices;
        const st = this.choiceToBlockStatement;
        return choices.map((c, index) => {
            var _a, _b, _c, _d, _e;
            return {
                choiceName: c,
                choiceDisplayName: ((_c = (_b = (_a = this.choiceFieldRows) === null || _a === void 0 ? void 0 : _a.slice()[index]) === null || _b === void 0 ? void 0 : _b.choiceDisplayName) === null || _c === void 0 ? void 0 : _c.value) || '',
                choiceImageLink: cst.layoutType === models_1.ChoiceLayoutType.ImageChoice
                    ? cst.getImageForChoice(index)
                    : null,
                blockToName: (_e = (_d = st === null || st === void 0 ? void 0 : st.getBlockAt(index)) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : IDOStatement_1.BLOCK_EMPTY,
            };
        });
    }
    get savePropStatements() {
        return this.statements.filter(v => v instanceof DOSTChoiceIf_1.DOSTChoiceIf);
    }
    changeChoiceToBlockAtIndex(index, blockName) {
        return this.block.parentChapter
            .applyChangesOnChapter(changeOp_1.StudioChangeOpFactory.changeChoiceToBlock(this.block.uniqueId, this.uniqueId, index, blockName))
            .catch();
    }
    /**
     * {index} 번째의 선택지를 위한 choiceFieldRow 를 생성한다.
     * 만약 이미 존재하지 않는 선택지이면, 비어있는 선택을 추가한다.
     * @param index 추가할 index
     * @private
     */
    createChoiceFieldRowForIndex(index) {
        var _a, _b, _c, _d;
        const v = index < this.choices.length
            ? this.choices[index]
            : { blockToName: IDOStatement_1.BLOCK_EMPTY, choiceName: '' };
        const displayNameV = index < this.choices.length
            ? this.choices[index]
            : { blockToName: IDOStatement_1.BLOCK_EMPTY, choiceName: '' };
        const options = this.block.parentChapter.blockStore.all.map(block => ({
            value: block,
            name: block.name,
            description: block.name,
        }));
        const value = (_a = options.find(v2 => { var _a; return ((_a = v2.value) === null || _a === void 0 ? void 0 : _a.name) === v.blockToName; })) === null || _a === void 0 ? void 0 : _a.value;
        // 아래의 코드가 onXXX 와 onXXXBeforeSubmit 의 혼용으로 이상하게 되었으나,
        // #203 문제가 해결되는 상태의 코드이다.
        const blockOptions = new ui_1.SelectionInput('blockOptions', trans('legacy_DOChapter_select_block'), value, options, { creatable: true, placeholder: trans('legacy_DOSTChoiceSubBlock_scene') });
        const isImageChoice = this.stChoice.layoutField.value === models_1.ChoiceLayoutType.ImageChoice;
        const item = {
            choiceNum: index + 1,
            choiceName: new ui_1.InputWithValidation(`choice ${index + 1}`, `choice ${index + 1}`, trans('legacy_DOSTChoiceSubBlock_enter_choice'), 'text', v.choiceName, null, {
                onSubmit: (text) => {
                    this.helper
                        .opFactory()
                        .changeChoiceText(this, text, index)
                        .submitSingle()
                        .catch();
                },
                enableInPlaceEditing: false,
                editable: true,
                noLabel: true,
            }),
            choiceDisplayName: new ui_1.InputWithValidation(`choice ${index + 1}`, `choice ${index + 1}`, CHOICE_DISPLAYNAME[index], 'text', (_d = (_c = (_b = this.stChoice.st) === null || _b === void 0 ? void 0 : _b.displayNames) === null || _c === void 0 ? void 0 : _c[index]) !== null && _d !== void 0 ? _d : '', null, {
                onSubmit: (text) => {
                    this.helper
                        .opFactory()
                        .changeChoiceText(this, text, index)
                        .submitSingle()
                        .catch();
                },
                enableInPlaceEditing: false,
                editable: true,
                noLabel: true,
            }),
            choiceImage: isImageChoice
                ? new StudioFileFieldWithUI_1.StudioFileFieldWithUI(`choiceImage${index + 1}`, () => {
                    return {
                        link: this.stChoice.getImageForChoice(index),
                    };
                }, file => { var _a; return this.stChoice.setImageForChoice(index, (_a = file === null || file === void 0 ? void 0 : file.link) !== null && _a !== void 0 ? _a : ''); }, this.helper.story, {
                    // options: {imageLink: this}
                    options: {
                        aspectRatio: {
                            w: 1,
                            h: 1,
                        },
                    },
                })
                : null,
            blockOptions,
            saveProps: this.savePropStatements
                .filter(st => st.choiceIndex === index)
                .map(s => s.clone()),
        };
        return item;
    }
    /**
     * 현재 선택지 데이터를 이용하여 choiceFieldRows 를 다시 생성한다.
     * @private
     */
    updateChoiceFieldRows() {
        const choices = this.choices;
        const rows = choices.map((v, index) => {
            return this.createChoiceFieldRowForIndex(index);
        });
        (0, mobx_1.runInAction)(() => {
            this.choiceFieldRows = rows;
        });
    }
    /**
     * DOSTChoice 에서 layoutType 이 변경되었을 때 호출하도록 하기 위해 뚫어놓은 함수이다.
     */
    _updateChoiceFieldRows() {
        this.updateChoiceFieldRows();
    }
    get canAddRow() {
        if (this.stChoice.layoutField.value === models_1.ChoiceLayoutType.UserInputChoice) {
            return false;
        }
        return this.choiceFieldRows.length < models_1.NUM_MAX_CHOICES;
    }
    get canRemoveRow() {
        return this.choiceFieldRows.length > 2;
    }
    get choiceFieldRowsByLayoutType() {
        if (this.stChoice.isUserInputLayoutInEditing) {
            return this.choiceFieldRows.slice(0, 2);
        }
        return this.choiceFieldRows;
    }
    get isValidPlayData() {
        let isValidArr = [];
        this.choiceFieldRowsByLayoutType.forEach(row => {
            isValidArr = [
                ...isValidArr,
                ...row.saveProps.map(prop => {
                    var _a;
                    if (((_a = prop.propOpOptions) === null || _a === void 0 ? void 0 : _a.value) === IDOStatement_1.CommonPropOperation.CALCULATE) {
                        return prop.formulaStatus === consts_1.SPExprFormulaStatus.Ok;
                    }
                    return true;
                }),
            ];
        });
        return isValidArr.filter(r => !r).length === 0;
    }
    /**
     * 특정 index 의 선택지에 업적 이벤트가 존재하는가?
     */
    hasAchievementEventAtIndex(index) {
        if (this.isEditing) {
            return !!this.choiceFieldRows[index].saveProps.find(st => st.choiceIndex === index &&
                st.currentPropType === DOSTChoiceIf_1.ChoiceIfPropType.Achievement);
        }
        return !!this.statements.find(st => st instanceof DOSTChoiceIf_1.DOSTChoiceIf &&
            st.choiceIndex === index &&
            st.currentPropType === DOSTChoiceIf_1.ChoiceIfPropType.Achievement);
    }
    addFieldRow() {
        if (!this.canAddRow) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.choiceFieldRows.push(this.createChoiceFieldRowForIndex(this.choiceFieldRows.length));
        });
    }
    removeFieldRow(index) {
        if (!this.canRemoveRow) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.choiceFieldRows.splice(index, 1);
        });
    }
    /**
     * 특정 선택지에 ChoiceIf 를 추가한다.
     */
    addChoiceIf(index) {
        const line = new DOSTChoiceIf_1.DOSTChoiceIf({
            statementType: models_1.STATEMENT_TYPE.ChoiceIfSaveProp,
            choice: index,
            propUpdate: {
                propName: IDOStatement_1.PROP_EMPTY,
                propOp: models_1.PROP_OPERATION.SET_NUMBER,
                value: '0',
            },
            background: '',
            sourceLine: this.block.store.rootStore.di.generateSourceLine(),
        }, this.block);
        (0, mobx_1.runInAction)(() => {
            var _a;
            (_a = this.choiceFieldRows[index]) === null || _a === void 0 ? void 0 : _a.saveProps.push(line);
        });
    }
    /**
     * 주어진 removeChoiceIf 를 삭제한다.
     */
    removeChoiceIf(choiceIndex, choiceIfIndex) {
        (0, mobx_1.runInAction)(() => {
            var _a;
            (_a = this.choiceFieldRows[choiceIndex]) === null || _a === void 0 ? void 0 : _a.saveProps.splice(choiceIfIndex, 1);
        });
    }
    //
    // IStudioEditor interface
    //
    startEditing() {
        this.isEditing = true;
        this.updateChoiceFieldRows();
        // DOSTChoice 의 필드들도 모달을 닫았다가 다시 오픈할때 값을 실데이터값으로 초기화시키기 위함
        this.stChoice.startEditing();
        return super.startEditing();
    }
    async submitEditing() {
        const op = this.helper.opFactory().startBulk();
        const choices = (0, lodash_1.cloneDeep)(this.choices);
        const numChoices = this.choices.length;
        // op 관련 함수들이 실행되기전 choice 의 layoutType 이 사용자 입력형이라면, choiceFieldRows 를 2개로 줄인다.
        // DOSTChoice 의 실데이터 값이 변경되기 전이므로 실행 당시의 필드(layoutField) 밸류값으로 판단
        if (this.stChoice.layoutField.value === models_1.ChoiceLayoutType.UserInputChoice) {
            (0, mobx_1.runInAction)(() => {
                this.choiceFieldRows.splice(2);
            });
            this.choiceFieldRows[0].choiceName.onChange('높은 애정 표현');
            this.choiceFieldRows[1].choiceName.onChange('낮은 애정 표현');
            (0, mobx_1.runInAction)(() => {
                var _a, _b;
                this.stChoice.st.displayNames = this.choiceFieldRows.map(item => { var _a, _b; return (_b = (_a = item.choiceDisplayName) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ''; });
                !((_a = this.stChoice.st.displayNames) === null || _a === void 0 ? void 0 : _a[0]) &&
                    (this.stChoice.st.displayNames[0] = '높은 애정 표현');
                !((_b = this.stChoice.st.displayNames) === null || _b === void 0 ? void 0 : _b[1]) &&
                    (this.stChoice.st.displayNames[1] = '낮은 애정 표현');
            });
        }
        // new blocks
        this.choiceFieldRows.forEach(f => {
            const v = f.blockOptions.value;
            if ((0, lodash_1.isString)(v)) {
                // 신규 블록
                if ((0, storeUtils_1.isValidBlockName)(v)) {
                    op.createNewBlock(v, this.block.startingBackground);
                }
            }
        });
        // 나머지를 맞춰준다.
        this.choiceFieldRows.forEach((f, index) => {
            var _a;
            const isCreating = numChoices <= index;
            if (isCreating) {
                op.addChoice(this.block.uniqueId, this.uniqueId, f.choiceName.value, IDOStatement_1.BLOCK_EMPTY, index);
            }
            else {
                if (f.choiceName.value !== choices[index].choiceName) {
                    op.changeChoiceText(this, f.choiceName.value, index);
                }
            }
            const v = f.blockOptions.value;
            const blockName = (0, lodash_1.isString)(v) ? v : (_a = v === null || v === void 0 ? void 0 : v.name) !== null && _a !== void 0 ? _a : IDOStatement_1.BLOCK_EMPTY;
            if ((isCreating && blockName !== IDOStatement_1.BLOCK_EMPTY) ||
                (!isCreating && choices[index].blockToName !== blockName)) {
                op.changeChoiceToBlock(this.block.uniqueId, this.uniqueId, index, blockName);
            }
            const originals = this.getChoiceIfStatementsForChoiceAtIndex(index);
            const max = Math.max(f.saveProps.length, originals.length);
            for (let i = 0; i < max; i += 1) {
                if (i >= f.saveProps.length) {
                    // 삭제된 i
                    op.changeChoiceIf(this, index, i, null);
                }
                else {
                    // 선택지의 인덱스는 변경되었을 수 있다.
                    const newData = (0, lodash_1.cloneDeep)(f.saveProps[i].data);
                    newData.choice = index;
                    // 추가되거나 변경된 i
                    op.changeChoiceIf(this, index, i, newData);
                }
            }
        });
        // 삭제되어야 할 것들
        for (let indexToRemove = choices.length - 1; indexToRemove >= this.choiceFieldRows.length; indexToRemove -= 1) {
            op.removeChoiceAtIndex(this.block.uniqueId, this.uniqueId, indexToRemove);
        }
        // 레이아웃타입이 사용자 입력형이였다가 다른 타입으로 바뀌는 경우
        // 사용자 입력형과 관련된 필드들의 값을 변경하여 변경점(undo, redo 가 동작하기 위한)을 만들어준다.
        // SPSTUDIO-366 이슈가 해결되기 전까지 아래의 코드로 임시 동작이 가능함.
        const isChangedWhenLayoutTypeIsUserInputChoice = this.stChoice.layoutField.defValue === models_1.ChoiceLayoutType.UserInputChoice &&
            this.stChoice.layoutField.defValue !== this.stChoice.layoutField.value;
        if (isChangedWhenLayoutTypeIsUserInputChoice) {
            this.stChoice.choiceUserInputImage.onChange((0, DOSTChoice_1.makeChoiceUserInputImageDefaultValue)());
            this.stChoice.leftUserInputTextField.onChange('');
        }
        // - SPSTUDIO-366
        // 이미지 필드가 존재하면 해당 필드의 commit changes 호출, 단 layoutType 을 먼저 변경해주어야 함.
        // 그래야 options 필드가 제대로 적용되므로..
        await this.stChoice.layoutField.commitChanges();
        for (const f of this.choiceFieldRows) {
            if (f.choiceImage) {
                await f.choiceImage.commitChanges();
            }
        }
        // DOSTChoice 의 editor 변경점이 있으면 추가한다.
        await this.stChoice.editorManager.submitEditing(op);
        const r = await super.submitEditing(op);
        const eventMapByLayoutType = {
            [models_1.ChoiceLayoutType.ImageChoice]: studioTutorial_1.GA4_EVENT_NAME.USING_IMAGE_CHOICE,
            [models_1.ChoiceLayoutType.Default]: studioTutorial_1.GA4_EVENT_NAME.USING_TEXT_CHOICE,
            [models_1.ChoiceLayoutType.UserInputChoice]: studioTutorial_1.GA4_EVENT_NAME.USING_USER_INPUT_CHOICE,
            [models_1.ChoiceLayoutType.Statistics]: studioTutorial_1.GA4_EVENT_NAME.USING_STATS_CHOICE,
        };
        if (eventMapByLayoutType[this.stChoice.layoutField.value]) {
            await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(eventMapByLayoutType[this.stChoice.layoutField.value]);
            // 구현상 layoutField 에서는 stats 를 설정할 수 없으므로, 아래와 같은 필드로 구분합니다.
            if (this.stChoice.showStatisticsField.value) {
                await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.USING_STATS_CHOICE);
            }
        }
        this.updateChoiceFieldRows();
        this.isEditing = false;
        return r;
    }
    cancelEditing() {
        super.cancelEditing();
        this.isEditing = false;
    }
    getChoicePropName(index) {
        var _a, _b;
        const choice = this.block.parentChapter.choiceStore.getByName(this.stChoice.choiceName);
        return (
        // @ts-ignore
        (_b = (_a = choice === null || choice === void 0 ? void 0 : choice[`choice${index + 1}PropName`]) !== null && _a !== void 0 ? _a : choice === null || choice === void 0 ? void 0 : choice.getChoiceNameOfIndex(index)) !== null && _b !== void 0 ? _b : null);
    }
    generateLines() {
        var _a;
        const lines = super.generateLines();
        // "선택지"가 무조건 처음이어야 하는데, 순서가 바뀌는 경우가 있음.
        const index = lines.findIndex(v => v.columns[4] === scripter_1.INVERTED_STATEMENT_TYPE_MAP[models_1.STATEMENT_TYPE.Choice]);
        if (index >= 1) {
            const line = lines[index];
            lines.splice(index, 1);
            lines.unshift(line);
        }
        // "선택지 속성" 구문을 추가하기
        const choice = this.block.parentChapter.choiceStore.getByName(this.stChoice.choiceName);
        if (choice) {
            const columns = (0, lodash_1.clone)(lines[0].columns);
            const choicePropNames = this.choices.map((c, choiceIndex) => {
                var _a;
                return (_a = this.getChoicePropName(choiceIndex)) !== null && _a !== void 0 ? _a : c.choiceName;
            });
            lines.splice(1, 0, {
                columns: [
                    columns[0],
                    columns[1],
                    columns[2],
                    columns[3],
                    scripter_1.INVERTED_STATEMENT_TYPE_MAP[models_1.STATEMENT_TYPE.ChoiceProp],
                    '',
                    ...choicePropNames,
                ],
                errors: [],
            });
        }
        if (this.stChoice.layoutField.value === models_1.ChoiceLayoutType.UserInputChoice) {
            const columns = (0, lodash_1.clone)(lines[0].columns);
            lines.splice(2, 0, {
                columns: [
                    columns[0],
                    columns[1],
                    columns[2],
                    columns[3],
                    scripter_1.INVERTED_STATEMENT_TYPE_MAP[models_1.STATEMENT_TYPE.ChoiceDisplayName],
                    this.stChoice.choiceName,
                    ...((_a = this.stChoice.st.displayNames) !== null && _a !== void 0 ? _a : []).map(item => item),
                ],
                errors: [],
            });
        }
        return lines;
    }
    selectNumberByKeyboard(index) {
        var _a;
        const blockToName = this.choices[index].blockToName;
        if (blockToName) {
            (_a = this.block.parentChapter.blockEditor) === null || _a === void 0 ? void 0 : _a.onBlockClickedForJump(this.block, blockToName);
        }
    }
    /**
     * 특정 {index}의 선택지에 대한 ChoiceIf 구문을 모두 반환한다.
     */
    getChoiceIfStatementsForChoiceAtIndex(index) {
        return this.statements.filter(st => st instanceof DOSTChoiceIf_1.DOSTChoiceIf && st.choiceIndex === index);
    }
    addChoiceInternal(choiceName, blockTo, choiceIndex) {
        (0, mobx_1.runInAction)(() => {
            // 우리는 내부의 statement 의 정렬 순서를 알고 있으므로 그에 맞추어 reverse op 를 맞춰준다.
            for (const st of this.statements) {
                if (st instanceof DOSTChoice_1.DOSTChoice) {
                    st.st.choices.splice(choiceIndex, 0, choiceName);
                }
                else if (st instanceof DOSTChoiceToBlock_1.DOSTChoiceToBlock) {
                    st.st.choices.splice(choiceIndex, 0, blockTo);
                }
                else if (st instanceof DOSTChoiceIf_1.DOSTChoiceIf) {
                    if (st.choiceIndex >= choiceIndex) {
                        ;
                        st.data.choice += 1;
                    }
                }
            }
        });
        return {
            reverse: changeOp_1.StudioChangeOpFactory.removeChoiceAtIndex(this.block.uniqueId, this.uniqueId, choiceIndex),
            lineToFocus: this,
        };
    }
    removeChoiceAtIndexInternal(index) {
        const f = changeOp_1.StudioChangeOpFactory.startBulk(this.block.parentChapter);
        (0, mobx_1.runInAction)(() => {
            var _a, _b, _c, _d;
            // 우리는 내부의 statement 의 정렬 순서를 알고 있으므로 그에 맞추어 reverse op 를 맞춰준다.
            const linesToRemove = [];
            for (let stIndex = this.statements.length - 1; stIndex >= 0; stIndex -= 1) {
                const st = this.statements[stIndex];
                if (st instanceof DOSTChoice_1.DOSTChoice) {
                    const name = (_a = st.st.choices[index]) !== null && _a !== void 0 ? _a : '';
                    const blockName = (_c = (_b = this.stChoiceToBlock.getBlockAt(index)) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : IDOStatement_1.BLOCK_EMPTY;
                    st.st.choices.splice(index, 1);
                    f.addChoice(this.block.uniqueId, this.uniqueId, name, blockName, index, st.uniqueId);
                }
                else if (st instanceof DOSTChoiceToBlock_1.DOSTChoiceToBlock) {
                    const blockName = (_d = st.st.choices[index]) !== null && _d !== void 0 ? _d : IDOStatement_1.BLOCK_EMPTY;
                    st.st.choices.splice(index, 1);
                    f.changeChoiceToBlock(this.block.uniqueId, this.uniqueId, index, blockName);
                }
                else if (st instanceof DOSTChoiceIf_1.DOSTChoiceIf) {
                    if (st.choiceIndex === index) {
                        linesToRemove.push(st);
                        f.addLinesToSubBlock(this.block.uniqueId, this.uniqueId, [
                            { line: st, index: stIndex },
                        ]);
                    }
                    else if (st.choiceIndex > index) {
                        ;
                        st.data.choice -= 1;
                    }
                }
            }
            this.statements = this.statements.filter(st => !linesToRemove.includes(st));
        });
        return {
            reverse: f.finishBulk(),
            lineToFocus: this,
        };
    }
    changeChoiceIfInternal(choiceIndex, choiceIfIndex, data, uniqueIdRemoved // undo 시에 uniqueId 유지를 위해서
    ) {
        const statements = this.getChoiceIfStatementsForChoiceAtIndex(choiceIndex);
        if (data) {
            if (choiceIfIndex === statements.length) {
                // 추가
                const line = new DOSTChoiceIf_1.DOSTChoiceIf(data, this.block, uniqueIdRemoved);
                (0, mobx_1.runInAction)(() => this.statements.push(line));
                this.alignStatementsOrder();
                return {
                    reverse: changeOp_1.StudioChangeOpFactory.changeChoiceIf(this, choiceIndex, choiceIfIndex, null, uniqueIdRemoved),
                    lineToFocus: this,
                };
            }
            else if (choiceIfIndex < statements.length) {
                // 변경
                const line = statements[choiceIfIndex];
                const original = (0, lodash_1.cloneDeep)(line.data);
                (0, mobx_1.runInAction)(() => (line.data = data));
                return {
                    reverse: changeOp_1.StudioChangeOpFactory.changeChoiceIf(this, choiceIndex, choiceIfIndex, original, uniqueIdRemoved),
                    lineToFocus: this,
                };
            }
            else {
                this.helper.di.showError(`69511: ChoiceIf statement invalid choice-if index`);
            }
        }
        else {
            if (choiceIfIndex < statements.length) {
                // 삭제
                const line = statements[choiceIfIndex];
                const i = this.statements.indexOf(line);
                (0, mobx_1.runInAction)(() => this.statements.splice(i, 1));
                return {
                    reverse: changeOp_1.StudioChangeOpFactory.changeChoiceIf(this, choiceIndex, choiceIfIndex, (0, lodash_1.cloneDeep)(line.data), line.uniqueId),
                    lineToFocus: this,
                };
            }
            else {
                this.helper.di.showError(`70123: ChoiceIf statement invalid choice-if index`);
            }
        }
        return null;
    }
    async applyChangeOp(op, type) {
        var _a;
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.ChangeChoiceAddChoice: {
                return this.addChoiceInternal(op.choiceName, op.blockTo, (_a = op.choiceIndex) !== null && _a !== void 0 ? _a : this.choices.length);
            }
            case changeOp_1.StudioChangeOpType.ChangeChoiceRemoveChoice: {
                return this.removeChoiceAtIndexInternal(op.choiceIndex);
            }
            case changeOp_1.StudioChangeOpType.ChangeLineName:
            case changeOp_1.StudioChangeOpType.ChangeChoiceText: {
                return this.stChoice.applyChangeOp(op, type);
            }
            case changeOp_1.StudioChangeOpType.ChangeChoiceToBlock: {
                return this.stChoiceToBlock.applyChangeOp(op, type);
            }
            case changeOp_1.StudioChangeOpType.ChangeChoiceChoiceIf: {
                return this.changeChoiceIfInternal(op.choiceIndex, op.choiceIfIndex, op.data, op.uniqueIdRemoved);
            }
            default:
                break;
        }
        return super.applyChangeOp(op, type);
    }
    onAllBlocksOfChapterLoaded() {
        this.statements.forEach(st => st.onAllBlocksOfChapterLoaded());
    }
    validate() {
        const results = super.validate();
        const story = this.block.parentChapter.story;
        story.chapterStore.all.forEach(chapter => {
            chapter.blockStore.all.forEach(block => {
                block.statements.forEach(st => {
                    if (st instanceof DOSTChoiceSubBlock && st !== this) {
                        if (st.stChoice.choiceName === this.stChoice.choiceName) {
                            results.push({
                                type: validation_1.StudioValidationType.ChoiceNameDuplicate,
                                source: this,
                                severity: validation_1.StudioValidationSeverity.Error,
                                stack: [],
                                extra: { chapter, block, st },
                                fixable: async () => {
                                    (0, mobx_1.runInAction)(() => {
                                        this.stChoice.st.choiceName =
                                            block.store.rootStore.di.generateCustomChoiceId();
                                    });
                                },
                            });
                        }
                    }
                });
            });
        });
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    async onClickChoiceName() {
        if (this.stChoice.layoutField.value === models_1.ChoiceLayoutType.UserInputChoice) {
            await this.di.copyToClipboard(`[선택지답변:${this.stChoice.choiceName}]`);
            this.di.showMessage(trans('legacy_DOSTChoiceSubBlock_advanced_answer_value_copied'));
            return;
        }
        await this.di.copyToClipboard(`[선택지선택:${this.stChoice.choiceName}]`);
        this.di.showMessage(trans('legacy_DOSTChoiceSubBlock_advanced_property_value_copied'));
    }
    //
    // Studio translator
    //
    async getMessagesToTranslate() {
        const messages = [];
        if (this.stChoice.showStatisticsField.value) {
            messages.push(this.stChoice.displayName);
        }
        messages.push(...this.choices.map(c => c.choiceName));
        return {
            messages,
        };
    }
    async applyTranslatedMessages(translated) {
        (0, mobx_1.runInAction)(() => {
            var _a;
            const { messages } = translated;
            if (this.stChoice.showStatisticsField.value) {
                const displayName = messages.shift();
                ((_a = this.stChoice.st.options) !== null && _a !== void 0 ? _a : {}).displayName = displayName;
            }
            this.stChoice.st.choices = [...messages];
        });
    }
}
exports.DOSTChoiceSubBlock = DOSTChoiceSubBlock;
