import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ODButton, ODButtonSize, ODButtonTheme } from './base/ODButton'

interface IODBackRowProps {
  link: string
  name?: string
}

export const ODBackRow: React.FC<IODBackRowProps> = (props) => {
  const { link, name = '돌아가기' } = props
  return (
    <Wrapper>
      <Link to={link}>
        <ODButton theme={ODButtonTheme.PrimaryInvert} size={ODButtonSize.Small}>
          {name}
        </ODButton>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 400px;
  margin-bottom: 20px;
`
