import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { OnboardingPopupChoiceEditPage } from './OnboardingPopupChoiceEditPage'

interface IOnboardingPopupContainerProps {
  storyId: number
  popupId: number
  popupChoiceId: number
}

export const OnboardingPopupChoiceEditPageContainer: React.FC<IOnboardingPopupContainerProps> = (
  props
) => {
  const { storyId, popupId, popupChoiceId } = props
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow
            link={SiteUrls.Admin.Story.EditOnboardingPopup(storyId, popupId)}
          />
          <hr />
          <OnboardingPopupChoiceEditPage
            storyId={storyId}
            popupId={popupId}
            popupChoiceId={popupChoiceId}
          />
        </CardBody>
      </Card>
    </>
  )
}
