import { ODButton, ODButtonSize, ODButtonTheme } from '@odc/od-react-belt'
import { GQLStorygameCharacterPrompt } from '@storyplay/common'
import { GQLGENDER } from '@storyplay/core'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'
import { GENDER_SELECT_OPTIONS } from '../storygameCharacterCommon'
import { KeyValueInput } from './list/KeyValueInput'

interface Props {
  storyId: number
  id: number
}

export const StorygameCharacterPromptEditPage: React.FC<Props> = (props) => {
  const { storyId, id } = props

  const {
    getStorygameCharacterPrompt,
    updateStorygameCharacterPrompt,
    createStorygameCharacterPrompt,
  } = useStoryPlayAPIs()

  const [entity, setEntity] =
    React.useState<GQLStorygameCharacterPrompt | null>(null)
  const [redirect, setRedirect] = React.useState('')

  React.useEffect(() => {
    if (id > 0) {
      getStorygameCharacterPrompt({ id }).then((e) => {
        setEntity(e)
      })
    }
  }, [getStorygameCharacterPrompt, id])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const isCreating = !(id > 0)

  const title = isCreating
    ? '스토리 게임 캐릭터 프롬프트 수정'
    : '스토리 게임 캐릭터 프롬프트 생성'

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <ODBackRow
              style={{ width: 120 }}
              link={SiteUrls.Admin.Story.ListStorygameCharacterPrompt(storyId)}
            />
          </Row>
          <Row>
            <h1 className={'text-2xl font-bold mb-4'}>{title}</h1>
          </Row>

          {isCreating && (
            <Row style={{ marginBottom: 20 }}>
              <h1>성별</h1>
              <Col md={4}>
                <Select
                  defaultValue={GENDER_SELECT_OPTIONS.find(
                    (s) => s.value === (entity?.gender ?? GQLGENDER.Male)
                  )}
                  options={GENDER_SELECT_OPTIONS}
                  onChange={(selected) => {
                    if (selected && selected?.value) {
                      setEntity({
                        ...entity,
                        gender: selected.value as unknown as GQLGENDER,
                      })
                    }
                  }}
                />
              </Col>
            </Row>
          )}

          <Row style={{ marginBottom: 20 }}>
            <h1>프롬프트</h1>
            <Col md={4}>
              <textarea
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none resize-auto h-40"
                placeholder="프롬프트"
                onChange={(e) =>
                  setEntity({ ...entity, prompt: e.target.value })
                }
                value={entity?.prompt}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <h1>네거티브</h1>
            <Col md={4}>
              <textarea
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none resize-auto h-40"
                placeholder="네거티브 프롬프트"
                onChange={(e) =>
                  setEntity({ ...entity, negativePrompt: e.target.value })
                }
                value={entity?.negativePrompt}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <h1>context</h1>
            <Col md={4}>
              <KeyValueInput
                setValue={(value: object) =>
                  setEntity({ ...entity, context: value })
                }
                value={entity?.context ?? {}}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <h1>negative context</h1>
            <Col md={4}>
              <KeyValueInput
                setValue={(value: object) =>
                  setEntity({ ...entity, negativeContext: value })
                }
                value={entity?.negativeContext ?? {}}
              />
            </Col>
          </Row>

          <Col md={3}>
            <ODButton
              theme={ODButtonTheme.PrimaryInvert}
              size={ODButtonSize.Small}
              style={{ minWidth: 135, borderRadius: 5 }}
              onClick={() => {
                if (isCreating) {
                  createStorygameCharacterPrompt({
                    storyId,
                    prompt: entity?.prompt ?? '',
                    negativePrompt: entity?.negativePrompt ?? '',
                    contextRaw: JSON.stringify(entity?.context ?? {}),
                    negativeContextRaw: JSON.stringify(
                      entity?.negativeContext ?? {}
                    ),
                    gender: entity?.gender ?? GQLGENDER.Male,
                  })
                    .then(() => {
                      Utils.showSuccess('저장되었습니다.')
                      setRedirect(
                        SiteUrls.Admin.Story.ListStorygameCharacterPrompt(
                          storyId
                        )
                      )
                    })
                    .catch(Utils.showError)
                } else {
                  updateStorygameCharacterPrompt({
                    id,
                    prompt: entity?.prompt,
                    negativePrompt: entity?.negativePrompt,
                    contextRaw: JSON.stringify(entity?.context ?? {}),
                    negativeContextRaw: JSON.stringify(
                      entity?.negativeContext ?? {}
                    ),
                  })
                    .then(() => Utils.showSuccess('저장되었습니다.'))
                    .catch(Utils.showError)
                }
              }}
            >
              저장
            </ODButton>
          </Col>
        </CardBody>
      </Card>
    </>
  )
}
