"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryListStore = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../@types/server.schema");
const i18n_1 = require("../../../i18n");
const ALCreateNewStory_1 = require("../story/ALCreateNewStory");
const InputWithValidation_1 = require("./InputWithValidation");
const SelectionInput_1 = require("./SelectionInput");
const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
/**
 * StoryListStore는 헬로우봇인지 스플인지 알 수 없음. 알려면 rootstore에서 값을 넘겨주는 방법 뿐.
 * TabForStoryList는 탭을 눌렀을때 값. 이 부분 역시 헬봇용으로 바꿔야함. 'my-chat-bot','all'
 * server.listStoryForWriter input이  다름.
 *
 * StoryListStore<TAB_LIST_TYEP,SORT_OPTION> 로 제네릭 선언을 해서 확장 하려고 했으나
 * StudioApiServer에 tabClickForStudio의 함수를 만들면서 이 함수에도 제네릭 타입이 들어가게 되는데
 * 기존 함수 로직인 if(tab === TabForStoryList.Sample) 에서 타입 오류가 뜨는 이슈로 TabForList, FOR_WRITER_SORT_OPTION 타입 추가해서 작업 진행.
 */
class StoryListStore // SORT_OPTION 도 추가
 {
    constructor(rootStore, sectionDefaultValue, storiesSortingOptions) {
        this.page = 1;
        this.totalCount = 0;
        this.isLoading = false;
        this.stories = [];
        this.listStoryCounts = null;
        this.rootStore = rootStore;
        this.trans = (0, i18n_1.i18nTextTranslationByClass)().trans;
        this.selectedTab = sectionDefaultValue;
        this.filterInput = new InputWithValidation_1.InputWithValidation('search', 'search', this.trans('ts_search_by_story_name'), 'text', '', null, {
            noLabel: true,
            enableInPlaceEditing: false,
            onChange: () => {
                if (this.page !== 1) {
                    this.changePage(1);
                }
            },
        });
        this.storiesSortingOptions = new SelectionInput_1.SelectionInput('storiesFilter', trans('legacy_StoryListStore.ts_story_list_filter'), server_schema_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.PUBLISHED_AT_DESC, [
            {
                name: trans('legacy_StoryListStore.ts_recently_published_order'),
                value: server_schema_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.PUBLISHED_AT_DESC,
                description: trans('legacy_StoryListStore.ts_recently_published_order'),
            },
            {
                name: trans('legacy_StoryListStore.ts_old_published_order'),
                value: server_schema_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.PUBLISHED_AT_ASC,
                description: trans('legacy_StoryListStore.ts_old_published_order'),
            },
            // {
            //   name: '최신 변경순',
            //   value: StoriesSorting.UpdatedAtDesc,
            //   description: '최신 변경순',
            // },
            // 서버 구현될 때까지 막아둠.
            // {
            //   name: '인기순',
            //   value: StoriesSorting.PopularDesc,
            //   description: '인기순',
            // },
        ], {
            creatable: false,
        });
        this.storiesSortingOptions = storiesSortingOptions;
        (0, mobx_1.makeObservable)(this, {
            selectedTab: mobx_1.observable,
            page: mobx_1.observable,
            totalCount: mobx_1.observable,
            stories: mobx_1.observable,
            isLoading: mobx_1.observable,
            listStoryCounts: mobx_1.observable,
            storiesTotalCounts: mobx_1.computed,
        });
    }
    changePage(page) {
        (0, mobx_1.runInAction)(() => {
            this.page = page;
        });
    }
    async loadListStoryCounts() {
        try {
            const res = await this.rootStore.di.server.getListStoryCountsByStudio();
            (0, mobx_1.runInAction)(() => {
                this.listStoryCounts = res;
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    get storiesTotalCounts() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!this.listStoryCounts)
            return 0;
        // GQLListStoryCounts이면
        if ('finished' in this.listStoryCounts) {
            return (((_b = (_a = this.listStoryCounts) === null || _a === void 0 ? void 0 : _a.finished) !== null && _b !== void 0 ? _b : 0) +
                ((_d = (_c = this.listStoryCounts) === null || _c === void 0 ? void 0 : _c.published) !== null && _d !== void 0 ? _d : 0) +
                ((_f = (_e = this.listStoryCounts) === null || _e === void 0 ? void 0 : _e.noPublished) !== null && _f !== void 0 ? _f : 0));
        }
        // ChatbotListStoryCounts 이면
        if ('all' in this.listStoryCounts) {
            return (_h = (_g = this.listStoryCounts) === null || _g === void 0 ? void 0 : _g.all) !== null && _h !== void 0 ? _h : 0;
        }
        return 0;
    }
    async loadStories(tab, page, sort, filter) {
        (0, mobx_1.runInAction)(() => {
            this.isLoading = true;
        });
        await this.loadListStoryCounts();
        try {
            const res = await this.rootStore.di.server.tabClickForStudio({
                tab,
                page: this.page,
                sort,
                filter,
            });
            let listable = [];
            let totalCount = 0;
            listable = res.listable;
            totalCount = res.totalCount;
            (0, mobx_1.runInAction)(() => {
                this.stories = listable;
                this.totalCount = totalCount;
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isLoading = false;
            });
        }
    }
    openNewStoryModal(openBGSelector) {
        this.rootStore.autoLayoutManager.addActionChain(new ALCreateNewStory_1.ALCreateNewStory(this, openBGSelector).buildActionChain());
    }
    setSelectedTab(tab) {
        (0, mobx_1.runInAction)(() => {
            this.selectedTab = tab;
            this.page = 1;
        });
    }
    getStoryInfoBy(story) {
        var _a, _b, _c;
        const s = story;
        const lastPublishedChapter = (_a = s.allChapters
            .filter(v => !!v.publishedAt)
            .sort((a, d) => d.chapterIndex - a.chapterIndex)[0]) !== null && _a !== void 0 ? _a : null;
        const lc = (_b = (0, lodash_1.last)(s.allChapters)) !== null && _b !== void 0 ? _b : null;
        const storyCredits = Object.keys((_c = story.storyDetailInfo) !== null && _c !== void 0 ? _c : {}).map(key => {
            return {
                label: key,
                value: story.storyDetailInfo[key].join(', '),
            };
        });
        const coverImageLink = (() => {
            var _a, _b, _c, _d, _e, _f;
            if (!!story.publishedAt) {
                return (_d = (_b = (_a = story.previewImageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : (_c = story.mainImageFile) === null || _c === void 0 ? void 0 : _c.link) !== null && _d !== void 0 ? _d : null;
            }
            return (_f = (_e = story.mainImageFile) === null || _e === void 0 ? void 0 : _e.link) !== null && _f !== void 0 ? _f : null;
        })();
        return {
            lastPublishedChapter,
            lastChapter: (lastPublishedChapter === null || lastPublishedChapter === void 0 ? void 0 : lastPublishedChapter.chapterId) === (lc === null || lc === void 0 ? void 0 : lc.chapterId) ? null : lc,
            coverImageLink,
            storyCredits,
        };
    }
}
exports.StoryListStore = StoryListStore;
