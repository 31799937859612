import { GQLStoryHasRecommendSection } from '@storyplay/core'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { RecommendSectionStoryEditPage } from '../RecommendSectionStoryEditPage'
import { StoryHasRecommendSectionEditPage } from './StoryHasRecommendSectionEditPage'

interface IStoryHasRecommendSectionContainerProps {
  storyId: number
  storyHasRecommendSectionId: number
}

export const StoryHasRecommendSectionEditPageContainer: React.FC<
IStoryHasRecommendSectionContainerProps
> = (props) => {
  const { storyId, storyHasRecommendSectionId } = props
  const { getStoryHasRecommendSection } = useStoryPlayAPIs()
  const [section, setSection] = React.useState<GQLStoryHasRecommendSection | undefined>(
    undefined
  )
  React.useEffect(() => {
    if (storyHasRecommendSectionId !== 0) {
      getStoryHasRecommendSection({ id: storyHasRecommendSectionId }).then((s) => {
        setSection(s)
      })
      return
    }
    setSection(undefined)
  }, [storyHasRecommendSectionId, storyHasRecommendSectionId])
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 13)} />
          <hr />
          <StoryHasRecommendSectionEditPage storyId={storyId} storyHasRecommendSectionId={storyHasRecommendSectionId} />
        </CardBody>
      </Card>
      {section &&
          <Card>
            <CardBody>
              <div style={{ marginTop: 0 }}>
                <h1>추천 섹션 스토리 관리</h1>
                <p style={{ height: 10 }} />
                <RecommendSectionStoryEditPage
                  sectionId={storyHasRecommendSectionId}
                />
              </div>
            </CardBody>
          </Card>
        }
    </>
  )
}
