import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import styled from 'styled-components'
import { useGoogleAppContext } from '../context/GoogleAppContext'
import { SiteUrls } from '../urls'

interface Props {
  name?: string | null
  isAdmin?: boolean
}

type MenuHeaderProps = {
  title: string
}

export const MenuHeader: React.FC<MenuHeaderProps> = (props) => {
  return (
    <DropdownItem header tag="div" className="text-center">
      <strong>{props.title}</strong>
    </DropdownItem>
  )
}

const CenterBoldMenuItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #2f353a;
`

type ColorDotProps = {
  color: string
}

export const ProfileDropdown: React.FC<Props> = (props) => {
  const { name } = props
  const [isOpen, setIsOpen] = React.useState(false)
  const { state: googleState, logoutGoogle } = useGoogleAppContext()

  const userMenuItems = (() => {
    const comps = [<MenuHeader key="header" title="Account" />]

    comps.push(
      <Link key="user" to={SiteUrls.Admin.User.EditMyProfile}>
        <DropdownItem>내 정보 수정</DropdownItem>
      </Link>
    )

    return comps
  })()

  return (
    <Dropdown nav isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="sm">
      <DropdownToggle
        caret
        style={{
          fontWeight: 'bold',
          fontSize: 13,
          paddingRight: 10,
          marginRight: 16,
          backgroundColor: 'white',
          borderColor: 'white',
        }}
      >
        {name}&nbsp;&nbsp;
      </DropdownToggle>
      <DropdownMenu right>
        {userMenuItems}
        {/* <MenuHeader key="etc" title="Etc" /> */}
        <Link to={SiteUrls.Admin.Logout}>
          <DropdownItem>
            <CenterBoldMenuItem>Logout</CenterBoldMenuItem>
          </DropdownItem>
        </Link>
      </DropdownMenu>
    </Dropdown>
  )
}
