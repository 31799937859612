/**
 * ODListablePaginatedTable 은 ODContext 를 이용하여 동작하는 페이징이 가능한 테이블
 * 컴포넌트이다.
 *
 * (see ODListable.stories.tsx)
 */
import React from 'react'
import { Table } from 'reactstrap'
import {
  ODListColumnBuilder,
  ODListColumnContext,
} from '../components/ODEditor/ODListable/ODListColumnBuilder'
import {
  ODListableContextType,
  ODListableOption,
  ODListableReducerState,
} from './ODListableContext'
import {
  ODListableHeaders,
  ODListableTableColumnHeaderDefinition,
} from './ODListableHeader'

export interface ODListableTableColumnDataDefinition<
  T,
  O extends ODListableOption
> {
  id: string | number // ID to distinguish this <td /> from other <td />s.
  transform: (data: T, state: ODListableReducerState<T, O>) => React.ReactNode // if isHTML=true, should return string.
  className: string
  isHTML?: boolean
  tdStyle?: object
  hide?: boolean // if set to true, this column will not be evaluated.
}

// 동적으로 변경되는 케이스는 추후 필요에 따라 추가하도록 한다.
export type ODListableTableDefinition<
  T,
  O extends ODListableOption,
  ParentContextType
> = Array<
  | ODListableTableColumnDefinition<T, O>
  | ODListColumnBuilder<any, O, ParentContextType>
>

export interface ODListableTableColumnDefinition<T, O extends ODListableOption>
  extends ODListableTableColumnHeaderDefinition<T, O>,
    ODListableTableColumnDataDefinition<T, O> {}

interface Props<T, O extends ODListableOption, ParentContextType> {
  fields: ODListableTableDefinition<T, O, ParentContextType>
  listableContext: React.Context<ODListableContextType<T, O>>
  renderLoading?: (state: ODListableReducerState<T, O>) => React.ReactNode
  renderEmpty: (state: ODListableReducerState<T, O>) => React.ReactNode
  tableClassName?: string
  onEvent?: (event: any, parentContext: ParentContextType) => void // event from column definition
  eventParentContext: ParentContextType
}

export function ODListablePaginatedTable<
  T,
  O extends ODListableOption,
  ParentContextType
>(props: Props<T, O, ParentContextType>) {
  const {
    fields,
    listableContext,
    renderLoading,
    renderEmpty,
    tableClassName = '',
    onEvent = () => null,
    eventParentContext,
  } = props
  const [listColumnContext] = React.useState<
    ODListColumnContext<ParentContextType>
  >(() => ({
    onEvent,
    parentContext: eventParentContext,
  }))
  const buildFieldsDefinition = React.useCallback(
    (fields: ODListableTableDefinition<T, O, ParentContextType>) => {
      return fields.map((f) => {
        if (f instanceof ODListColumnBuilder) {
          return f.build(listColumnContext)
        }
        return f
      })
    },
    [listColumnContext]
  )

  const [fieldsBuilt, setFieldsBuilt] = React.useState<
    ODListableTableColumnDefinition<T, O>[]
  >(() => buildFieldsDefinition(fields))

  React.useEffect(() => {
    setFieldsBuilt(buildFieldsDefinition(fields))
  }, [setFieldsBuilt, buildFieldsDefinition, fields])

  const { state, keyExtractor }: ODListableContextType<T, O> =
    React.useContext(listableContext)
  const { loading, list } = state

  const rows = (() => {
    const colSpan = fields.length
    if (loading && renderLoading) {
      return (
        <tr key="loading_row">
          <td colSpan={colSpan} className="text-center">
            {renderLoading(state)}
          </td>
        </tr>
      )
    }

    if (list.length === 0) {
      return (
        <tr key="empty_row">
          <td colSpan={colSpan} className="text-center">
            {renderEmpty(state)}
          </td>
        </tr>
      )
    }

    return list.map((data) => {
      const tds = fieldsBuilt.map(
        ({ id, transform, className = '', isHTML, tdStyle, hide = false }) => {
          if (hide) {
            return null
          }

          if (isHTML) {
            return (
              <td key={id} className={className} style={tdStyle}>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: transform(data, state) as string,
                  }}
                />
              </td>
            )
          }
          return (
            <td key={id} className={className} style={tdStyle}>
              {transform(data, state)}
            </td>
          )
        }
      )

      return <tr key={`tr-${keyExtractor(data)}`}>{tds}</tr>
    })
  })()

  return (
    <>
      <Table
        responsive
        striped
        style={{ marginTop: 0, borderBottom: `1px solid #c8ced2` }}
        className={tableClassName}
      >
        <thead>
          <tr>
            <ODListableHeaders fields={fieldsBuilt} />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  )
}
