"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSPExprFormula = exports.InfixBinaryOpMap = exports.FORMULA_GRAMMAR = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const peg = require("pegjs");
const consts_1 = require("../../consts");
const IPDOperation_1 = require("../IPDOperation");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
// https://stackoverflow.com/a/35506340/388351
// https://stackoverflow.com/a/34019313/388351
exports.FORMULA_GRAMMAR = `
{
  var booleanOps = [">", "<", ">=", "<=", "==", "<>"];
}

start
 = logical
 
logical
 = left:boolean _ "and"i _ right: logical { return { op: 'AND', args: [left, right] }; }
 / left:boolean _ "or"i _ right: logical { return { op: 'OR', args: [left, right] }; }
 / boolean

// boolean op 는 >, <, = 의 1~3개로 이루어지고 정해진 키워드로 매칭한다.
// https://stackoverflow.com/questions/57062299/pegjs-reserved-keyword
booleanOp = op:$([>|<|=]+) &{ return booleanOps.includes(op); }
 
boolean
 = left:additive _ op:$(booleanOp) _ right: boolean  { return { op: op, args: [left, right] }; }
 / additive

additive
 = left:multiplicative _ op:[+|-] _ right:additive { return { op: op, args: [left, right] }; }
 / multiplicative

multiplicative
 = left:power _ op:[*|/] _ right:multiplicative { return { op: op, args: [left, right] }; }
 / power 

// evaluated left to right!
power
 = left:primary _ "^" _ right:power { return { op: 'Pow', args: [left, right] }; }
 / primary

primary
= float // 소수점은 아직 앱에서 지원하지 않아 처리하지 않습니다. 추후 지원 가능.
 / integer
 / propToken
 / itemToken
 / rawString
 / userChoiceInputTextToken
 / userChoiceToken
 / "(" _ e: logical _ ")"      { return e; }
 / op:id "(" _ ")" { return { op: op.join(''), args: [] }; }
 / op:id "(" _ e1: logical _ ")" { return { op: op.join(''), args: [e1] }; }
 / op:id "(" _ e1: logical _ "," _ e2: logical _ ")" { return { op: op.join(''), args: [e1, e2] }; }
 / op:id "(" _ e1: logical _ "," _ e2: logical _ "," _ e3: logical ")" { return { op: op.join(''), args: [e1, e2, e3] }; }
 / op:id "(" _ e1: logical _ "," _ e2: logical _ "," _ e3: logical "," _ e4:logical _ ")" { return { op: op.join(''), args: [e1, e2, e3, e4] }; }
 / op:id "(" _ e1: logical _ "," _ e2: logical _ "," _ e3: logical "," _ e4:logical _ "," _ e5:logical _ ")" { return { op: op.join(''), args: [e1, e2, e3, e4, e5] }; }

integer
  = digits:[0-9]+ { return digits.join('') }
  
float
  = a:integer+ "." b:integer+ { return a.join('') + '.' + b.join('') }

id
 = [a-zA-Z]+

propToken = "[속성:" propToken:regularChar "]" { return { op: 'GetValue', args: ['props', propToken] }; }
itemToken = "[아이템:" propToken:regularChar "]" { return { op: 'GetValue', args: ['items', propToken] }; }
userChoiceInputTextToken = "[선택지답변:" propToken:regularChar "]" { return { op: 'GetValue', args: ['props', '` +
    consts_1.UserPropUserChoiceInputTextPrefix +
    `' + propToken] }; }
userChoiceToken = "[선택지선택:" propToken:regularChar "]" { return { op: 'GetValue', args: ['props', '` +
    consts_1.UserPropUserChoicePrefix +
    `' + propToken] }; }
regularChar 
    = [^\\[\\]()"':]+ { return text(); }

rawString
  = '"' chars:DoubleStringCharacter* '"' { return { op: 'String', args: [chars.join('')] }; }
  / "'" chars:SingleStringCharacter* "'" { return { op: 'String', args: [chars.join('')] }; }

DoubleStringCharacter
  = !('"' / "\\\\") char:. { return char; }
  / "\\\\" sequence:EscapeSequence { return sequence; }

SingleStringCharacter
  = !("'" / "\\\\") char:. { return char; }
  / "\\\\" sequence:EscapeSequence { return sequence; }

EscapeSequence
  = "'"
  / '"'
  / "\\\\"
  / "b"  { return "\\b";   }
  / "f"  { return "\\f";   }
  / "n"  { return "\\n";   }
  / "r"  { return "\\r";   }
  / "t"  { return "\\t";   }
  / "v"  { return "\x0B"; }

_ "whitespace" = [ \\t\\n\\r]*
`;
exports.InfixBinaryOpMap = {
    [IPDOperation_1.PDOperationName.Add]: '+',
    [IPDOperation_1.PDOperationName.Minus]: '-',
    [IPDOperation_1.PDOperationName.Mul]: '*',
    [IPDOperation_1.PDOperationName.Div]: '/',
    [IPDOperation_1.PDOperationName.Pow]: '^',
    [IPDOperation_1.PDOperationName.LT]: '<',
    [IPDOperation_1.PDOperationName.LTE]: '<=',
    [IPDOperation_1.PDOperationName.GT]: '>',
    [IPDOperation_1.PDOperationName.GTE]: '>=',
    [IPDOperation_1.PDOperationName.Equals]: '==',
    [IPDOperation_1.PDOperationName.NotEquals]: '<>',
    [IPDOperation_1.PDOperationName.AND]: 'AND',
    [IPDOperation_1.PDOperationName.OR]: 'OR',
};
const InvertedInfixBinaryOpMap = (0, lodash_1.invert)(exports.InfixBinaryOpMap);
let parser = null;
/**
 * 사용자가 텍스트로 입력할 수 있는 수식을 PDOperation 형태로 파싱합니다.
 */
function parseSPExprFormula(formula) {
    if (!parser) {
        parser = peg.generate(exports.FORMULA_GRAMMAR, { optimize: 'speed', cache: true });
    }
    const numOpen = formula.split('(') || [];
    const numClose = formula.split(')') || [];
    if (numOpen.length !== numClose.length) {
        throw new Error(trans('legacy_parser_parentheses_count_mismatch'));
    }
    const op = parser.parse(formula.trim(), {});
    traverseOp(op, o => {
        if (InvertedInfixBinaryOpMap[o.op]) {
            o.op = InvertedInfixBinaryOpMap[o.op];
        }
        if (!IPDOperation_1.PDOperationName[o.op]) {
            throw new Error(trans('legacy_parser_nonexistent_function', {
                value: o.op,
            }));
        }
        return false;
    });
    return op;
}
exports.parseSPExprFormula = parseSPExprFormula;
/**
 * PDOperation 을 순회합니다.
 */
function traverseOp(op, callback) {
    if ((0, lodash_1.isString)(op)) {
        return false;
    }
    const stop = callback(op);
    if (stop) {
        return true;
    }
    for (const arg of op.args) {
        if ((0, lodash_1.isString)(arg)) {
            continue;
        }
        const shouldStop = traverseOp(arg, callback);
        if (shouldStop) {
            return true;
        }
    }
    return false;
}
