"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbMessageInput = void 0;
const core_1 = require("@storyplay/core");
const IDOStatement_1 = require("../../../IDOStatement");
const DOSTHbMessageInputBase_1 = require("../DOSTHbMessageInputBase");
class DOSTHbMessageInput extends DOSTHbMessageInputBase_1.DOSTHbMessageInputBase {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbUserInput,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.HbMessageInput;
        this.hasModalForEdit = true;
    }
    getDataForSubmit() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const keyboardType = ((_b = (_a = this.keyboardField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 'general');
        if (!this.isFirstCreated) {
            return {
                type: 'input',
            };
        }
        return {
            type: 'input',
            keyboard: keyboardType,
            linkAttributes: ((_c = this.saveProps) === null || _c === void 0 ? void 0 : _c.length) > 0
                ? (_d = this.saveProps) === null || _d === void 0 ? void 0 : _d.map(item => item === null || item === void 0 ? void 0 : item.getDataForSubmit())
                : [],
            linkBlocks: ((_f = (_e = this.messageNext) === null || _e === void 0 ? void 0 : _e.getDataForSubmit().linkBlocks) !== null && _f !== void 0 ? _f : []).length > 0
                ? (_g = this.messageNext) === null || _g === void 0 ? void 0 : _g.getDataForSubmit().linkBlocks
                : undefined,
            rangeEnd: keyboardType === 'number'
                ? (_h = this.rangeEndField.value) !== null && _h !== void 0 ? _h : null
                : undefined,
            rangeStart: keyboardType === 'number'
                ? (_j = this.rangeStartField.value) !== null && _j !== void 0 ? _j : null
                : undefined,
        };
    }
    updateData(data) {
        if (data.data) {
            super.updateData(data);
            this.updateFields(data);
        }
    }
}
exports.DOSTHbMessageInput = DOSTHbMessageInput;
