"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateSplitBlock = void 0;
const core_1 = require("@storyplay/core");
const StudioChangeOpFactory_1 = require("../../../changeOp/StudioChangeOpFactory");
const entity_1 = require("../entity");
const TemplateBase_1 = require("./TemplateBase");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class TemplateSplitBlock extends TemplateBase_1.TemplateBase {
    constructor(editor) {
        super(editor);
        this.isSimpleStringInput = true;
        this.name = trans('legacy_studioTutorial.const.ts_split_blocks');
        this.description = trans('legacy_TemplateSplitBlock.ts_split_block_from_current_choice');
        this.tags = [TemplateBase_1.TemplateTag.Block];
        this.field = new entity_1.InputWithValidation('templateSplitBlock', trans('legacy_TemplateChoiceAndMerge.ts_template_data_input'), trans('legacy_TemplateSplitBlock.ts_name_of_new_block_after_split'), 'textarea', '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
        }).setAutoFocus();
    }
    submit() {
        const lines = this.field.value
            .split('\n')
            .map(v => v.trim())
            .filter(v => v);
        if (lines.length !== 1) {
            this.editor.chapterEditing.store.rootStore.di.showError(trans('legacy_TemplateChoiceAndMerge.ts_template_data_is_incorrect'));
            return;
        }
        const block = this.editor.lineBlockEditing.doBlock; // source block
        const lineIndex = this.editor.lineEditingIndex; // line to break between
        const line = block.statements[lineIndex];
        if (!line) {
            return;
        }
        const linesToMove = block.statements
            .slice(lineIndex + 1, 999999)
            .filter(st => st.isScript);
        const newBlockName = lines[0];
        const di = this.chapter.store.rootStore.di;
        const newBlockUniqueId = `${newBlockName}_${di.generateInternalHashId()}`;
        const newToBlockUniqueId = `toBlock_${di.generateInternalHashId()}`;
        const bulk = new StudioChangeOpFactory_1.StudioChangeOpFactory(this.chapter);
        const op = bulk
            .startBulk()
            .createNewBlock(newBlockName, block.startingBackground, newBlockUniqueId)
            .removeLinesFromBlock(block.uniqueId, linesToMove.map(l => l.uniqueId))
            .addLinesToBlock(newBlockUniqueId, linesToMove.map((l, index) => ({ line: l, index })))
            .upsertNewEndBlock(block.uniqueId, entity_1.EndBlockType.ToBlock, newToBlockUniqueId)
            .changeToBlockToBlock(block.uniqueId, newToBlockUniqueId, newBlockName)
            .finishBulk();
        this.editor.chapterEditing.store.rootStore.studioTutorialStore
            .markUserStudioTutorialProgress(entity_1.GA4_EVENT_NAME.DIVIDE_BLOCKS)
            .then();
        return this.chapter.applyChangesOnChapter(op);
    }
    buildActionChain() {
        return null;
    }
}
exports.TemplateSplitBlock = TemplateSplitBlock;
