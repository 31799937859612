import { useCounter, useODMutation } from '@odc/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import {
  GQLListableSFXFile,
  GQLListableSFXFileInput,
  GQLOkResponse,
  GQLSFXFile,
  GQLSingleIDInput,
} from '../../@types/server'
import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
} from '../../components/base/ODButton'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useModalConfirm } from '../../components/ODModal/ODModalConfirm'
import { useODQuery } from '../../context/ODCommon'
import {
  ODListableOption,
  ODListableResponseType,
  useODListableContext,
} from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import {
  ODListablePaginatedTable,
  ODListableTableDefinition,
} from '../../ODListable/ODListablePaginationTable'
import { COLORS } from '../../styles/colors'
import { SiteUrls } from '../../urls'
import { GQL_REMOVE_SFX_FILE } from '../../utils/gqls_web'
import { Utils } from '../../utils/utils'

interface Props {}

export interface SFXFileDataLoaderOption extends ODListableOption {}

const GQL_LIST_SFX_FILES = `
query listSFXFile($data: ListableSFXFileInput!) {
  listSFXFile(data: $data) {
    list {
      fileId
      link
      mimeType
      fileName
    }
    totalCount
    page
    pageSize
  }
}
`

export const SFXFileListContainer: React.FC<Props> = () => {
  const { Provider, Context } = useODListableContext<
    GQLSFXFile,
    SFXFileDataLoaderOption
  >()
  const listSFXFiles = useODQuery<GQLListableSFXFileInput, GQLListableSFXFile>(
    GQL_LIST_SFX_FILES
  )
  const removeSFXFile = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_REMOVE_SFX_FILE
  )

  const [token, refresh] = useCounter()

  const dataLoader = React.useCallback(
    async function SFXFileDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: SFXFileDataLoaderOption
    ): Promise<ODListableResponseType<GQLSFXFile>> {
      const r = await listSFXFiles({
        page,
        pageSize,
      })
      return r as ODListableResponseType<GQLSFXFile>
    },
    [listSFXFiles]
  )

  const {
    Component: Confirm,
    confirm,
    props: confirmComponentProps,
  } = useModalConfirm({
    title: '효과음 삭제',
    message: '해당 효과음을 삭제합니다. 계속하시겠습니까?',
    yes: '삭제',
    no: '취소',
  })
  const TableDefinition: ODListableTableDefinition<
    GQLSFXFile,
    SFXFileDataLoaderOption,
    any
  > = [
    {
      id: 'fileId',
      title: '일련 번호',
      transform: (v) => v.fileId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'fileName',
      title: '파일명',
      transform: (v) => v.fileName,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'mimeType',
      title: '미디어 타입',
      transform: (v) => v.mimeType,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'link',
      title: '링크',
      transform: (v) => (
        <a href={v.link} target="_blank" rel="noopener noreferrer">
          {v.link}
        </a>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'delete',
      title: '삭제',
      transform: (v) => (
        <div
          style={{ color: COLORS.Primary, cursor: 'pointer' }}
          onClick={() => {
            confirm()
              .then((y) => {
                if (y) {
                  return removeSFXFile({ id: v.fileId }).then(() => refresh())
                }
              })
              .catch(Utils.showError)
          }}
        >
          삭제하기
        </div>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.fileId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <Confirm {...confirmComponentProps} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginBottom: 15,
              }}
            >
              <Link to={SiteUrls.Admin.SFXFile.Create}>
                <ODButton
                  theme={ODButtonTheme.Primary}
                  size={ODButtonSize.Small}
                  style={{ minWidth: 135 }}
                >
                  효과음 추가
                </ODButton>
              </Link>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩 중..'}
              renderEmpty={() => '등록된 효과음이 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination
              hideIfSinglePage={false}
              listableContext={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
