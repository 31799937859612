"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBundleBanner = void 0;
const core_1 = require("@storyplay/core");
class DOBundleBanner {
    constructor(rootStore, data) {
        this.countryCode = null;
        this.languageCode = null;
        this.bundleBannerId = null;
        this.type = null;
        this.targetClient = null;
        this.storyId = null;
        this.isShow = null;
        this.title = null;
        this.content = null;
        this.linkType = null;
        this.link = null;
        this.iOSFallbackLink = null;
        this.aOSFallbackLink = null;
        this.bannerImageFile = null;
        this.willOpenAt = null;
        this.willCloseAt = null;
        this.state = null;
        this.data = null;
        this.changeSet = null;
        this.rootStore = rootStore;
        this.data = data !== null && data !== void 0 ? data : null;
        this.merge(data);
    }
    get storyByStoryId() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.story) !== null && _b !== void 0 ? _b : null;
    }
    get serverId() {
        var _a;
        return (_a = this.bundleBannerId) !== null && _a !== void 0 ? _a : -1;
    }
    get bannerImageFileLink() {
        var _a, _b;
        return (_b = (_a = this.bannerImageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null;
    }
    merge(data) {
        if (!data) {
            return this;
        }
        const fields = [
            'bundleBannerId',
            'type',
            'location',
            'targetClient',
            'countryCode',
            'isShow',
            'title',
            'content',
            'linkType',
            'link',
            'iOSFallbackLink',
            'aOSFallbackLink',
            'bannerImageFile',
            'willOpenAt',
            'willCloseAt',
            'state',
            'languageCode',
            'storyId',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, core_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    create(changeSet) {
        const req = changeSet.hasOwnProperty('countryCode')
            ? changeSet
            : { ...changeSet, countryCode: '__' };
        if (changeSet.type === 'Custom') {
            const reqData = req.hasOwnProperty('languageCode')
                ? req
                : { ...req, languageCode: 'ko_KR' };
            const { storyId, ...otherChangeSet } = reqData;
            return this.rootStore.di.server.createBundleBanner(otherChangeSet);
        }
        else {
            const { languageCode, ...otherChangeSet } = req;
            return this.rootStore.di.server.createBundleBanner(otherChangeSet);
        }
    }
    update(changeSet) {
        this.changeSet = changeSet;
        if (this.serverId === -1) {
            return this.create(changeSet);
        }
        const { type, linkType, languageCode, title, content, ...others } = changeSet;
        let req;
        if (type === 'Story') {
            this.changeSet = others;
            req = others;
        }
        else {
            const { bannerImageFile, ...others } = changeSet;
            this.changeSet = others;
            req = others;
        }
        return this.rootStore.di.server.updateEntityForStudio(this, req);
    }
}
exports.DOBundleBanner = DOBundleBanner;
