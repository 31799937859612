import React from 'react'
import Select from 'react-select'
import { Col, Input, Row } from 'reactstrap'
import { ODButton, ODButtonTheme } from '../../components/base/ODButton'
import { ODModal, ODModalSize } from '../../components/ODModal/ODModal'
import { ODModalBody } from '../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../components/ODModal/ODModalHeader'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { Utils } from '../../utils/utils'

interface IUserAddCoinModalProps {
  userId: number
  onClose: () => void
  onConfirm: (value: number) => void
}

const WRITE_REASON = '직접 입력'

const REASON_LIST: string[] = [
  'CS 대응',
  '이벤트 충전',
  '마케팅 요청',
  '업무 지원',
  WRITE_REASON,
]

export const UserAddCoinModal: React.FC<IUserAddCoinModalProps> = (props) => {
  const { onConfirm, onClose, userId } = props
  const { addUserCoin } = useStoryPlayAPIs()
  const [amount, setAmount] = React.useState(0)
  const [reason, setReason] = React.useState('')
  const [selectReason, setSelectReason] = React.useState('')
  const [reasonOption, setReasonOption] = React.useState<
    { label: string; value: string }[]
  >([])

  React.useEffect(() => {
    setReasonOption(
      REASON_LIST.map((reason) => ({
        label: reason,
        value: reason,
      }))
    )
  }, [])

  const handleConfirm = async () => {
    if (!userId) return Utils.showError('사용자를 지정해 주세요!')
    if (!amount) return Utils.showError('충전할 티켓 수를 입력해 주세요!')
    if ((selectReason === WRITE_REASON && !reason) || !selectReason)
      return Utils.showError('충전 이유를 입력해 주세요!')
    try {
      const reasonInput = selectReason === WRITE_REASON ? reason : selectReason
      await addUserCoin({ userId, amount, reason: reasonInput })
      Utils.showSuccess('티켓이 충전 되었습니다.')
      onConfirm(amount)
    } catch (e) {
      // @ts-ignore
      Utils.showError(e)
    }
  }

  const handleReason = (e: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setReason(e.target.value)
  }

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Normal}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="사용자 티켓 충전하기" onClose={onClose} />
        <ODModalBody>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>티켓 개수</Col>
            <Col md={8}>
              <Input
                onChange={(e) => setAmount(+e.target.value)}
                value={amount}
                type="number"
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>충전 이유</Col>
            <Col md={8}>
              <Select
                isSearchable={true}
                placeholder="티켓 충전 이유"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    setSelectReason(v.value)
                    if (selectReason !== WRITE_REASON) setReason('')
                  }
                }}
                options={reasonOption}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </Col>
          </Row>
          {selectReason === WRITE_REASON && (
            <Row style={{ marginBottom: 20 }}>
              <Col md={4}>직접 입력</Col>
              <Col md={8}>
                <Input onChange={handleReason} value={reason} type="text" />
              </Col>
            </Row>
          )}
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            fullWidth
            theme={ODButtonTheme.Primary}
            onClick={handleConfirm}
          >
            충전하기
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
