"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChrStore = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const ui_1 = require("../ui");
const DOChr_1 = require("./DOChr");
const IDOChr_1 = require("./IDOChr");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ChrStore {
    constructor(story) {
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            merge: mobx_1.action,
            all: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    getByName(name) {
        return Object.values(this.data).find(v => v.name === name);
    }
    registerHero(chr) {
        // DOHerChr 는 IDOModifiableChr 가 아니지만, 구현 편의상 넣는다.
        (0, mobx_1.runInAction)(() => (this.data[chr.id] = chr));
    }
    merge(data) {
        const id = data.chrId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOChr_1.DOChr(this, data);
        }
        return this.data[id];
    }
    get all() {
        const mainChr = Object.values(this.data).find(v => v.id === IDOChr_1.HERO_CHR_ID);
        const others = Object.values(this.data)
            .filter(v => v.id !== IDOChr_1.HERO_CHR_ID)
            .sort((a, b) => {
            return a.isMain ? -1 : a.id < b.id ? -1 : 1;
        });
        return [mainChr, ...others];
    }
    /**
     * 선택가능한 캐릭터 옵션을 제공하는 SelectionInput 을 생성하여 반환합니다.
     */
    createSelectionOptions(defValue, excludeMainChr = false) {
        const options = this.all
            .filter(c => !excludeMainChr || !c.isMain)
            .map(c => ({
            value: c,
            name: c.name,
            description: c.name,
        }));
        return new ui_1.SelectionInput('characters', trans('legacy_ChrStore_characters'), defValue, options);
    }
    /**
     * 스튜디오에서 새롭게 정의하는 캐릭터를 위한 아이디를 생성한다.
     */
    getIdForNewChr() {
        const minId = Math.min(Math.min(...Object.values(this.data).map(c => c.id)), -1);
        return minId - 1;
    }
    exportMetaDataUpdateActions() {
        return (0, lodash_1.flatten)(this.all.map(v => v.exportMetaDataUpdateActions()));
    }
}
exports.ChrStore = ChrStore;
