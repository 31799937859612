"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleBlockEditablePasteEvent = exports.getBackgroundOfStatement = exports.changeOrderOf = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../changeOp");
const models_1 = require("../../../../models");
const IStudioClipboard_1 = require("../../clipboard/IStudioClipboard");
const statement_1 = require("../statement");
const DOScriptStatementFactory_1 = require("../statement/DOScriptStatementFactory");
const IBlockEditable_1 = require("./IBlockEditable");
async function changeOrderOf(holder, line, isUp, rootStore) {
    var _a;
    const lineIndex = holder.statements.findIndex(item => item === line);
    const canNotChanged = lineIndex < 0 ||
        (isUp && lineIndex === 0) ||
        (!isUp && lineIndex === holder.statements.length - 1);
    if (canNotChanged) {
        return;
    }
    const indexToChange = isUp ? lineIndex - 1 : lineIndex + 1;
    if (rootStore.serviceType === 'hb') {
        const hbStore = rootStore;
        try {
            const api = hbStore.apiServer;
            const hbMessage = line.data;
            const hbMessageId = hbMessage.hbExtensionData.id;
            if (!hbMessageId) {
                // tslint:disable-next-line:no-console
                console.warn('changeOrderOf-48, can not find hbMessageId');
                return;
            }
            await api.message.updateTurn(hbMessageId, indexToChange, hbStore.isAlgorithmStore);
        }
        catch (ex) {
            rootStore.showError(ex);
            return;
        }
    }
    const tempValue = holder.statements[indexToChange];
    (0, mobx_1.runInAction)(() => {
        holder.statements[indexToChange] = holder.statements[lineIndex];
        holder.statements[lineIndex] = tempValue;
        const blockEditor = holder.parentChapter.blockEditor;
        if (blockEditor &&
            blockEditor.lineBlockEditing === holder &&
            blockEditor.lineEditingIndex >= lineIndex) {
            blockEditor.lineEditingIndex = Math.max(0, indexToChange);
        }
    });
    (_a = holder.parentChapter.blockEditor) === null || _a === void 0 ? void 0 : _a.scrollToCurrentLine();
}
exports.changeOrderOf = changeOrderOf;
function getBackgroundOfStatement(holder, statementOrIndex) {
    let cur = holder.startingBackground;
    const findByIndex = (0, lodash_1.isNumber)(statementOrIndex);
    // cur 배경에서 시작하여 해당 statementOrIndex 를 찾을 때까지 배경의 변경을 tracking.
    holder.statements.find((st, index) => {
        if (st.lineType === statement_1.UILineType.SingleLineScript &&
            st.statementType === models_1.STATEMENT_TYPE.BackgroundImage) {
            cur = st.name;
        }
        if (findByIndex) {
            return index === statementOrIndex;
        }
        return statementOrIndex === st;
    });
    return cur || statement_1.BG_EMPTY;
}
exports.getBackgroundOfStatement = getBackgroundOfStatement;
async function handleBlockEditablePasteEvent(holder, data) {
    const di = holder.rootStore.di;
    const editor = holder.parentChapter.blockEditor;
    if ((editor === null || editor === void 0 ? void 0 : editor.lineBlockEditing) !== holder) {
        return false;
    }
    if (data.dataType !== IStudioClipboard_1.StudioClipboardDataType.Statements &&
        data.dataType !== IStudioClipboard_1.StudioClipboardDataType.Block) {
        return false;
    }
    data.lines.forEach((l) => {
        l.sourceLine = di.generateSourceLine();
    });
    const statements = (0, DOScriptStatementFactory_1.reduceStatementsToDomainObjects)(data.lines, holder.doBlock, () => null, true);
    const offset = editor.lineEditingIndex;
    const op = new changeOp_1.StudioChangeOpFactory(holder.parentChapter).startBulk();
    // 상황에 따라 다르게 처리해 주어야 하는 것들
    // 추후 더 많은 IBlockEditable 이 생기면 좀 더 인터페이스를 정리할 수 있을 듯 하다.
    const [ending, nonEnding] = (0, lodash_1.partition)(statements, st => !!st.endBlockType);
    switch (holder.blockEditableType) {
        case IBlockEditable_1.BlockEditableType.NormalBlock:
            if (editor.canAddToCurrentCursor) {
                op.addLinesToBlock(holder.uniqueId, nonEnding.map((line, index) => ({ line, index: index + offset })));
            }
            if (ending[0]) {
                const endBlock = holder.statements.find(v => !!v.endBlockType);
                if (endBlock) {
                    op.removeLinesFromBlock(holder.uniqueId, [endBlock.uniqueId]);
                }
                op.addLinesToBlock(holder.uniqueId, [
                    {
                        line: ending[0],
                        index: nonEnding.length + holder.statements.length,
                    },
                ]);
            }
            break;
        case IBlockEditable_1.BlockEditableType.CallRemoteScriptSubBlock:
            if (editor.canAddToCurrentCursor) {
                op.addLinesToSubBlock(holder.doBlock.uniqueId, holder.uniqueId, nonEnding
                    .filter(st => holder.canContainStatement(st))
                    .map((line, index) => ({ line, index: index + offset })));
            }
            break;
    }
    await op.submitBulk();
    return true;
}
exports.handleBlockEditablePasteEvent = handleBlockEditablePasteEvent;
