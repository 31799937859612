"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStory = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const errors_1 = require("../../../../errors");
const interface_1 = require("../../interface");
const studioUrls_1 = require("../../studioUrls/studioUrls");
const storeUtils_1 = require("../../utils/storeUtils");
const Waiter_1 = require("../../utils/Waiter");
const DOABadgeStore_1 = require("../aBadge/DOABadgeStore");
const DOAEventDefStore_1 = require("../aEventDef/DOAEventDefStore");
const chapter_1 = require("../chapter");
const ALCreateNewChapter_1 = require("../chapter/ALCreateNewChapter");
const chr_1 = require("../chr");
const DOEndingStore_1 = require("../ending/DOEndingStore");
const DOOnboardingPopupStore_1 = require("../onboardingPopup/DOOnboardingPopupStore");
const playerReport2_1 = require("../playerReport2");
const DOStoryHasBackground_1 = require("../storyHasBackground/DOStoryHasBackground");
const DOStoryHasBackgroundStore_1 = require("../storyHasBackground/DOStoryHasBackgroundStore");
const storyItem_1 = require("../storyItem");
const DOStoryPropertyStore_1 = require("../storyProperty/DOStoryPropertyStore");
const DOStudioFile_1 = require("../studioFile/DOStudioFile");
const DOStudioFileStore_1 = require("../studioFile/DOStudioFileStore");
const ui_1 = require("../ui");
const DOStoryEditor_1 = require("./DOStoryEditor");
const DOStoryGameEditor_1 = require("./DOStoryGameEditor");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
/**
 * Character domain object.
 */
class DOStory {
    constructor(store, data, doStoryStrategyConstructor) {
        this.createdAt = null;
        this.publishedAt = null;
        this.finishedAt = null;
        this.isFinished = false;
        this.onHiatus = false;
        this.storygameCoverImageLink = null;
        this.storygamePreviews = [];
        this.canPublish = null;
        this.numBlocks = null;
        this.numChars = null;
        this.numSens = null;
        this.stats = null;
        this.numBlocksDraft = null;
        this.numCharsDraft = null;
        this.numSensDraft = null;
        this.statsDraft = null;
        this.isSample = null;
        this.isContest = null;
        this.refStoryId = null;
        this.isOriginal = false;
        this.isFixMainChrName = false;
        this.playType = core_1.GQLSTORY_PLAY_TYPE.Interactive;
        this.refStoryName = null;
        this.language = null;
        this.languageCode = null;
        this.serviceCountries = [];
        // 확인 필요: assertion 꼭 필요한가?
        this.bundle = null;
        this.bundleId = null;
        this.bundleStories = [];
        this.isLoading = false;
        this.isLoaded = false;
        this.isAdult = false;
        this.isShowHome = false;
        // 업적 프로젝트 연동 ID. null 이면 미연동.
        this.aProjId = null;
        this.chapterIdEditing = null;
        this.chapterDraftArchiveIdEditing = null;
        this.localNumberId = -1;
        this.isExporting = false;
        this.lastExportingError = null;
        this.autoExportSheetId = '';
        this.isAutoExportEnabled = false;
        this.lastExportedAt = null;
        this.autoExportIntervalInSec = 60 * 5;
        this.isReadOnly = null;
        this.canUseAchievement = null;
        this.canUserPlayerReport = null;
        this.autoExportTimerHandle = null;
        this.hbExtensionData = null;
        this.storygameId = null;
        this.isStorygameHidden = false;
        this.storygameReleaseDate = null;
        this.storyTags = [];
        this.interactiveId = null;
        this.rootStore = store.rootStore;
        this.store = store;
        this.data = data;
        this.storyStrategy = new doStoryStrategyConstructor(this);
        this.chrStore = new chr_1.ChrStore(this);
        this.chapterStore = new chapter_1.ChapterStore(this, this.storyStrategy.doChapterStoreStrategyConstructor);
        this.studioFileStore = new DOStudioFileStore_1.DOStudioFileStore(this);
        this.endingStore = new DOEndingStore_1.DOEndingStore(this);
        this.storyPropertyStore = new DOStoryPropertyStore_1.DOStoryPropertyStore(this);
        this.storyItemStore = new storyItem_1.DOStoryItemStore(this);
        this.aBadgeStore = new DOABadgeStore_1.DOABadgeStore(this);
        this.aEventDefStore = new DOAEventDefStore_1.DOAEventDefStore(this);
        this.storyHasBackgroundStore = new DOStoryHasBackgroundStore_1.DOStoryHasBackgroundStore(this);
        this.createNewChapterModal = new ALCreateNewChapter_1.ALCreateNewChapter(this);
        this.playerReport2Store = new playerReport2_1.DOPlayerReport2Store(this);
        this.onboardingPopupStore = new DOOnboardingPopupStore_1.DOOnboardingPopupStore(this);
        this.merge(data);
        this.resetChaptersOption();
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            aProjId: mobx_1.observable,
            isExporting: mobx_1.observable,
            lastExportingError: mobx_1.observable,
            isAutoExportEnabled: mobx_1.observable,
            lastExportedAt: mobx_1.observable,
            chapterIdEditing: mobx_1.observable,
            chapterDraftArchiveIdEditing: mobx_1.observable,
            chaptersOption: mobx_1.observable,
            defaultFirstName: mobx_1.observable,
            defaultLastName: mobx_1.observable,
            topic: mobx_1.observable,
            shortDesc: mobx_1.observable,
            oneLineDesc: mobx_1.observable,
            targetAge: mobx_1.observable,
            mainImageLink: mobx_1.observable,
            previewImageLink: mobx_1.observable,
            introImageLink: mobx_1.observable,
            wideImageLink: mobx_1.observable,
            mainCharacterImageLink: mobx_1.observable,
            teaserVideoLink: mobx_1.observable,
            storygameCoverImageLink: mobx_1.observable,
            storygamePreviews: mobx_1.observable,
            totalNumOfChapterComments: mobx_1.observable,
            storyDetailInfo: mobx_1.observable,
            canPublish: mobx_1.observable,
            numBlocks: mobx_1.observable,
            numChars: mobx_1.observable,
            numSens: mobx_1.observable,
            stats: mobx_1.observable,
            numBlocksDraft: mobx_1.observable,
            numCharsDraft: mobx_1.observable,
            numSensDraft: mobx_1.observable,
            statsDraft: mobx_1.observable,
            isSample: mobx_1.observable,
            isReadOnly: mobx_1.observable,
            canUseAchievement: mobx_1.observable,
            canUserPlayerReport: mobx_1.observable,
            isContest: mobx_1.observable,
            genre: mobx_1.observable,
            sectionType: mobx_1.observable,
            finishedAt: mobx_1.observable,
            isOriginal: mobx_1.observable,
            isFixMainChrName: mobx_1.observable,
            hbExtensionData: mobx_1.observable,
            bannerImageLink: mobx_1.observable,
            refStoryName: mobx_1.observable,
            languageCode: mobx_1.observable,
            serviceCountries: mobx_1.observable,
            isLoaded: mobx_1.observable,
            isLoading: mobx_1.observable,
            storygameId: mobx_1.observable,
            isStorygameHidden: mobx_1.observable,
            storygameReleaseDate: mobx_1.observable,
            storyTags: mobx_1.observable,
            interactiveId: mobx_1.observable,
            serverId: mobx_1.computed,
            coverImageLink: mobx_1.computed,
            chapterEditing: mobx_1.computed,
            fileList: mobx_1.computed,
            isPublished: mobx_1.computed,
            characterList: mobx_1.computed,
            characterListWithoutMainChr: mobx_1.computed,
            merge: mobx_1.action,
            mergeHbExtensionData: mobx_1.action,
        });
    }
    mergeHbExtensionData(data) {
        (0, storeUtils_1.assignIf)(data, 'checkStatus', (v) => {
            this.hbExtensionData = {
                ...this.hbExtensionData,
                checkStatus: v,
            };
            this.isReadOnly = v === 'REQUEST' || v === 'CHECKING';
        });
    }
    merge(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        const fields = [
            'storyId',
            'name',
            'publishedAt',
            'isFinished',
            'finishedAt',
            'onHiatus',
            'defaultFirstName',
            'defaultLastName',
            'topic',
            'shortDesc',
            'oneLineDesc',
            'targetAge',
            'totalNumOfChapterComments',
            'storyDetailInfo',
            'userProps',
            'userItems',
            'canPublish',
            'numBlocks',
            'numChars',
            'numSens',
            'stats',
            'numBlocksDraft',
            'numCharsDraft',
            'numSensDraft',
            'statsDraft',
            'isSample',
            'isReadOnly',
            'canUseAchievement',
            'canUserPlayerReport',
            'isContest',
            'genre',
            'sectionType',
            'createdAt',
            'isOriginal',
            'isFixMainChrName',
            'playType',
            'refStoryId',
            'languageCode',
            'bundle',
            'bundleId',
            'language',
            'serviceCountries',
            'bundleStories',
            'isAdult',
            'isShowHome',
            'storygameId',
            'isStorygameHidden',
            'storygameReleaseDate',
            'storyTags',
            'storygamePreviews',
        ];
        // 필드명은 뒤에 File 이, 변수는 Link 가 붙는 파일들
        const fileFields = [
            'mainImage',
            'previewImage',
            'introImage',
            'wideImage',
            'mainCharacterImage',
            'mainCharacterPortraitImage',
            'teaserVideo',
            'bannerImage',
            'storygameCoverImage',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        fileFields.forEach(name => {
            (0, storeUtils_1.assignIf)(data, `${name}File`, v => { var _a; return (this[`${name}Link`] = (_a = v === null || v === void 0 ? void 0 : v.link) !== null && _a !== void 0 ? _a : null); });
        });
        (0, storeUtils_1.assignIf)(data, 'refStory', v => { var _a; return (this.refStoryName = (_a = v === null || v === void 0 ? void 0 : v.name) !== null && _a !== void 0 ? _a : null); });
        (0, storeUtils_1.assignIf)(data, 'hbExtensionData', (v) => {
            this.hbExtensionData = v !== null && v !== void 0 ? v : null;
            this.isReadOnly =
                (v === null || v === void 0 ? void 0 : v.checkStatus) === 'REQUEST' || (v === null || v === void 0 ? void 0 : v.checkStatus) === 'CHECKING';
        });
        (0, storeUtils_1.assignIf)(data, 'originalInteractiveStory', v => {
            var _a, _b;
            this.interactiveId = (_b = (_a = v) === null || _a === void 0 ? void 0 : _a.storyId) !== null && _b !== void 0 ? _b : null;
        });
        const story = data;
        const featSaveFromStudio = this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.SAVE_FROM_STUDIO);
        (_a = story.characters) === null || _a === void 0 ? void 0 : _a.forEach(gql => this.chrStore.merge(gql));
        if (!this.mainCharacter) {
            this.mainCharacter = new chr_1.DOHeroChr(this.chrStore, this.storyId, (_b = story.mainCharacterImageFile) !== null && _b !== void 0 ? _b : null, (_c = story.mainCharacterPortraitFile) !== null && _c !== void 0 ? _c : null, this.rootStore.di.diConstants.mainCharacterName);
        }
        else if (story.hasOwnProperty('mainCharacterImageFile')) {
            this.mainCharacter.setImageFile((_d = story.mainCharacterImageFile) !== null && _d !== void 0 ? _d : null);
        }
        this.chrStore.registerHero(this.mainCharacter);
        (_e = story.properties) === null || _e === void 0 ? void 0 : _e.forEach(gql => this.storyPropertyStore.merge(gql));
        (_f = story.storyItems) === null || _f === void 0 ? void 0 : _f.forEach(gql => this.storyItemStore.merge(gql));
        (_g = story.aEventDefs) === null || _g === void 0 ? void 0 : _g.forEach(gql => this.aEventDefStore.merge(gql));
        (_h = story.studioFiles) === null || _h === void 0 ? void 0 : _h.forEach(gql => this.studioFileStore.merge(gql));
        (_k = (_j = story.studio) === null || _j === void 0 ? void 0 : _j.allEndings) === null || _k === void 0 ? void 0 : _k.forEach(gql => this.endingStore.merge(gql));
        (_l = story.allChapters) === null || _l === void 0 ? void 0 : _l.forEach(gql => {
            this.chapterStore.merge(gql);
            // 작업시간이 너무 오래걸림. 사이드이펙트 없음 확인되면 완전 제거.
            // if (featSaveFromStudio) {
            //   queueMicrotask(() => {
            //     const draftBundle = gql.draft?.bundle
            //     if (draftBundle) {
            //       chapter.loadFromDraftBundle(JSON.parse(draftBundle))
            //     }
            //     chapter.init()
            //   })
            // }
        });
        if ((_m = story.studio) === null || _m === void 0 ? void 0 : _m.playerReportCSSLink) {
            (0, mobx_1.runInAction)(() => (this.rootStore.playerReportCSSLink =
                story.studio.playerReportCSSLink));
        }
        if (!featSaveFromStudio) {
            this.chapterStore.all.forEach(chapter => chapter.init());
        }
        this.resetChaptersOption();
        (_o = story.backgrounds) === null || _o === void 0 ? void 0 : _o.forEach(gql => this.storyHasBackgroundStore.merge(gql));
        if (story.hasOwnProperty('aProjId')) {
            (0, mobx_1.runInAction)(() => { var _a; return (this.aProjId = (_a = story.aProjId) !== null && _a !== void 0 ? _a : null); });
        }
        return this;
    }
    openCreateNewChapterModal() {
        this.rootStore.autoLayoutManager.addActionChain(this.createNewChapterModal.buildActionChain());
    }
    async load() {
        if (this.isLoading) {
            return;
        }
        (0, mobx_1.runInAction)(() => (this.isLoading = true));
        try {
            const story = await this.storyStrategy.load(this.storyId);
            this.merge(story);
            this.storyStrategy.postLoad(story);
            (0, mobx_1.runInAction)(() => {
                // console.log('here is called')
                this.isLoaded = true;
            });
        }
        catch (ex) {
            this.rootStore.di.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => (this.isLoading = false));
        }
    }
    setIsContest(isContest) {
        (0, mobx_1.runInAction)(() => {
            this.isContest = isContest;
        });
    }
    setServiceCountries(countries) {
        (0, mobx_1.runInAction)(() => {
            this.serviceCountries = countries;
        });
    }
    async startEditingChapter(chapterId, chapterDraftArchiveId, hasBlockId) {
        try {
            const featSaveFromStudio = this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.SAVE_FROM_STUDIO);
            const chapter = this.chapterStore.getById(chapterId);
            if (!chapter && this.rootStore.serviceType === 'sp') {
                // this.rootStore.di.showError(`Cannot find chapter id: ${chapterId}`)
                this.rootStore.setUnrecoverableError(new errors_1.SPCError(errors_1.ErrorCode.CannotFindChapter));
                return null;
            }
            (0, mobx_1.runInAction)(() => {
                this.chapterDraftArchiveIdEditing = chapterDraftArchiveId !== null && chapterDraftArchiveId !== void 0 ? chapterDraftArchiveId : null;
            });
            // console.log('chapterchapterchapterId', chapterId, this.storyStrategy)
            // get chapter once more for details.
            const detail = await this.storyStrategy.getChapterForEditing(chapterId);
            // console.log('detail', detail, chapterId)
            const chapterForHb = this.chapterStore.merge(detail);
            if (featSaveFromStudio) {
                // chapterDraftArchiveId 가 있을경우 draft 가 아닌 chapterDraftArchiveId 를 통하여
                // chapterDraftArchive 를 불러와서 불러온 번들을 통해 loadFromDraftBundle 을 호출한다.
                if (!chapterDraftArchiveId && detail.draft) {
                    chapter.loadFromDraftBundle(JSON.parse(detail.draft.bundle));
                    this.rootStore.showMessage(trans('legacy_DOStory_loaded_saved_draft')); // this.rootStore.showMessage('저장된 임시저장본을 로드하였습니다.')
                }
                if (!!chapterDraftArchiveId) {
                    const chapterDraftArchive = await this.storyStrategy.getChapterDraftArchive(chapterDraftArchiveId);
                    chapter.loadFromDraftBundle(JSON.parse(chapterDraftArchive.bundle));
                    this.rootStore.showMessage(trans('legacy_DOStory_loaded_draft_saved_in_history') // '히스토리에 저장된 임시저장본을 로드하였습니다.'
                    );
                }
            }
            const c = this.rootStore.serviceType === 'sp' ? chapter : chapterForHb;
            (0, mobx_1.runInAction)(() => {
                if (featSaveFromStudio) {
                    c.init();
                }
                this.chapterIdEditing = chapterId;
                // console.log('THIS.chapterEditing', this.chapterEditing, chapterId)
                this.resetChaptersOption();
            });
            setImmediate(() => {
                // 초기화 실패시에는 수행하지 않는다.
                if (!c.didInit) {
                    return;
                }
                const startingBlock = c.startingBlock;
                if (startingBlock && !hasBlockId) {
                    c.setBlockEditing(startingBlock);
                }
            });
            // 히스토리를 통해 들어오지 않았을 때만 로컬스토리지에 번들 데이터가 있는지 체크하고
            // 있다면 초안 불러오기 팝업 표시
            if (c && !chapterDraftArchiveId && this.rootStore.serviceType === 'sp') {
                c.checkBackupBundleFromLocalStorage();
            }
            return c;
        }
        catch (ex) {
            this.rootStore.setUnrecoverableError(new errors_1.SPCError(errors_1.ErrorCode.StartEditingChapterFailed, { ex }));
            return null;
        }
    }
    finishEditingChapter() {
        // console.log('Finish chapterIdEditing')
        (0, mobx_1.runInAction)(() => {
            this.chapterIdEditing = null;
            this.chapterDraftArchiveIdEditing = null;
        });
    }
    get isWebNovel() {
        return this.playType === core_1.GQLSTORY_PLAY_TYPE.EPUB;
    }
    get chapterEditing() {
        var _a;
        if (!this.chapterIdEditing) {
            return null;
        }
        return (_a = this.chapterStore.getById(this.chapterIdEditing)) !== null && _a !== void 0 ? _a : null;
    }
    get characterList() {
        return this.store.rootStore.serviceType === 'hb'
            ? this.characterListWithoutHeroChr.concat(this.chrStore.all.filter(v => v.isHero))
            : [...this.chrStore.all];
    }
    get characterListWithoutMainChr() {
        return [...this.chrStore.all.filter(v => !v.isMain)];
    }
    get characterListWithoutHeroChr() {
        return [...this.chrStore.all.filter(v => !v.isHero)];
    }
    get characterListWithoutMainAndHeroChr() {
        return [...this.characterListWithoutMainChr.filter(v => !v.isHero)];
    }
    /**
     * 목록상에서 현재 캐릭터의 다음 캐릭터를 반환한다.
     */
    getNextChrOf(currentChr, // currentChr: IDOChr,
    ignoreMainChr = false, ignoreHeroChr = false) {
        let all = this.characterList;
        if (ignoreMainChr && ignoreHeroChr) {
            all = this.characterListWithoutMainAndHeroChr;
        }
        if (ignoreMainChr && !ignoreHeroChr) {
            all = this.characterListWithoutMainChr;
        }
        if (!ignoreMainChr && ignoreHeroChr) {
            all = this.characterListWithoutHeroChr;
        }
        return all[(all.findIndex(v => v === currentChr) + 1) % all.length];
    }
    /**
     * 목록상에서 현재 캐릭터의 이전 캐릭터를 반환한다.
     */
    getPrevChrOf(currentChr, // currentChr: IDOChr,
    ignoreMainChr = false, ignoreHeroChr = false) {
        let all = this.characterList;
        if (ignoreMainChr && ignoreHeroChr) {
            all = this.characterListWithoutMainAndHeroChr;
        }
        if (ignoreMainChr && !ignoreHeroChr) {
            all = this.characterListWithoutMainChr;
        }
        if (!ignoreMainChr && ignoreHeroChr) {
            all = this.characterListWithoutHeroChr;
        }
        return all[(all.findIndex(v => v === currentChr) - 1 + all.length) % all.length];
    }
    get fileList() {
        return this.studioFileStore.all;
    }
    generateEndingId(block) {
        // generateEndingId(block: IDOBlock): string {
        let idToTry = 1;
        const hash = this.rootStore.di.generateInternalHashId();
        for (;;) {
            const id = `${block.id}-${idToTry}`;
            if (!this.containsEndingId(id)) {
                return `${id}-${hash}`; // 이미 디비에 들어있는 내용으로도 겹치지 않도록 하기 위해서
            }
            idToTry++;
        }
    }
    generateEndingName() {
        return `ending_${this.rootStore.di.generateInternalHashId()}`;
    }
    generateDisplayName() {
        return ''; // TODO: endingName으로 추가하기
    }
    generateChrId() {
        return this.chrStore.getIdForNewChr();
    }
    getNextLocalNumberId() {
        return this.localNumberId--;
    }
    exportMetaDataUpdateActions(chapterToExport) {
        var _a;
        const propertyActions = this.storyPropertyStore.exportMetaDataUpdateActions();
        const chrActions = this.chrStore.exportMetaDataUpdateActions();
        const bgActions = [];
        const allBackgrounds = (0, lodash_1.uniq)((0, lodash_1.flatten)(this.chapterStore.all.map(c => (0, lodash_1.flatten)(c.blockStore.all.map(b => b.getAllBackgrounds())))));
        allBackgrounds.forEach(bgName => {
            var _a, _b, _c;
            const file = this.getBackgroundFileNamed(bgName);
            const link = file instanceof DOStudioFile_1.DOStudioFile
                ? file.link
                : (_c = (_b = (_a = file) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.link) !== null && _c !== void 0 ? _c : '';
            if (file) {
                bgActions.push({
                    action: core_1.MetaDataAction.BackgroundSetImage,
                    scope: (0, core_1.genScopeString)(null),
                    link,
                    hash: link,
                    backgroundName: bgName,
                });
            }
        });
        let actions = propertyActions.concat(chrActions).concat(bgActions);
        if (chapterToExport) {
            return [
                ...actions,
                ...((_a = chapterToExport === null || chapterToExport === void 0 ? void 0 : chapterToExport.exportMetaDataUpdateActions()) !== null && _a !== void 0 ? _a : []),
            ];
        }
        for (const chapter of this.chapterStore.all) {
            const chapterActions = chapter.exportMetaDataUpdateActions();
            actions = actions.concat(chapterActions);
        }
        return actions;
    }
    clearLastExportingError() {
        (0, mobx_1.runInAction)(() => (this.lastExportingError = null));
    }
    /**
     * 특정 {name}을 갖고 있는 배경의 실제 파일 정보를 반환한다.
     * StoryHasBackground -> StudioFile 순서로 존재하는대로 반환한다.
     *  (이 부분은 여러가지 상황에 제대로 동작이 가능할지, 충돌이 발생하면 어떻게 처리할지 등 추가적인 고민이 필요하다)
     * 위의 로직에 조건을 추가, 링크가 있는 파일을 반환하도록
     */
    getBackgroundFileNamed(name) {
        var _a;
        const storyHasBackground = this.storyHasBackgroundStore.getByName(name);
        const studioFile = this.studioFileStore.getByResName(name);
        if (!!((_a = storyHasBackground === null || storyHasBackground === void 0 ? void 0 : storyHasBackground.file) === null || _a === void 0 ? void 0 : _a.link)) {
            return storyHasBackground;
        }
        if (!!(studioFile === null || studioFile === void 0 ? void 0 : studioFile.link)) {
            return studioFile;
        }
        return null;
    }
    getLinkOfBackgroundFileNamed(name) {
        const file = this.getBackgroundFileNamed(name);
        if (file instanceof DOStudioFile_1.DOStudioFile) {
            return file.link;
        }
        if (file instanceof DOStoryHasBackground_1.DOStoryHasBackground && file.file) {
            return file.file.link;
        }
        return null;
    }
    containsEndingId(endingId) {
        return !!this.chapterStore.all.find(v => v.blockStore.all.find(b => b.containsEndingId(endingId)));
    }
    /**
     * 현재 로드되어 있는 모든 챕터에 대한 데이터를 export 합니다. (+ _관리_)
     */
    async exportStorySheet(sheetId, enableAutoExport = false, clearBeforeRun = false) {
        const waiter = new Waiter_1.Waiter();
        const di = this.rootStore.di;
        if (this.isExporting) {
            di.showError(trans('legacy_DOChapter_current_export')); // di.showError('현재 진행중인 내보내기가 있습니다.')
            return;
        }
        if (this.autoExportTimerHandle) {
            clearTimeout(this.autoExportTimerHandle);
            this.autoExportTimerHandle = null;
        }
        (0, mobx_1.runInAction)(() => {
            this.isExporting = true;
            this.isAutoExportEnabled = enableAutoExport;
            this.autoExportSheetId = sheetId;
        });
        await waiter.wait(10); // give ui update timing.
        try {
            const allChapters = this.chapterStore.all;
            const chapters = allChapters.map(c => {
                c.init();
                return {
                    encodedLines: JSON.stringify(c.generateLinesWithStartingBlock()),
                    chapterName: c.name,
                };
            });
            const metaDataUpdateActions = JSON.stringify(this.exportMetaDataUpdateActions(null));
            await this.storyStrategy.exportStudioStoryAsSheet({
                chapters,
                sheetId,
                metaDataUpdateActions,
                clearBeforeRun,
            });
            (0, mobx_1.runInAction)(() => (this.lastExportedAt = new Date()));
        }
        catch (ex) {
            (0, mobx_1.runInAction)(() => (this.lastExportingError = ex));
        }
        finally {
            (0, mobx_1.runInAction)(() => (this.isExporting = false));
        }
        if (this.isAutoExportEnabled) {
            this.autoExportTimerHandle = +setTimeout(() => {
                this.onAutoExportTimer();
            }, this.autoExportIntervalInSec * 1000);
        }
    }
    exportNowIfAutoExportEnabled() {
        this.onAutoExportTimer();
    }
    onAutoExportTimer() {
        var _a;
        if (!this.isAutoExportEnabled || this.isExporting) {
            return;
        }
        if (((_a = this.rootStore.storyEditing) === null || _a === void 0 ? void 0 : _a.storyId) !== this.storyId) {
            this.resetAutoExport();
            return;
        }
        // noinspection JSIgnoredPromiseFromCall
        this.exportStorySheet(this.autoExportSheetId, true);
    }
    // 자동 저장을 취소합니다.
    resetAutoExport() {
        if (this.autoExportTimerHandle) {
            clearTimeout(this.autoExportTimerHandle);
            this.autoExportTimerHandle = null;
        }
        (0, mobx_1.runInAction)(() => {
            this.isAutoExportEnabled = false;
        });
    }
    onEndEditing() {
        this.resetAutoExport();
    }
    get isPublished() {
        return this.publishedAt !== null;
    }
    get serverId() {
        return this.storyId;
    }
    get coverImageLink() {
        if (!this.isPublished) {
            return this.previewImageLink || this.mainImageLink;
        }
        return this.mainImageLink;
    }
    async update(changeSet) {
        const req = changeSet.hasOwnProperty('storyDetailInfo')
            ? {
                ...changeSet,
                storyDetailInfo: this.storyDetailInfo,
            }
            : changeSet;
        const res = await this.rootStore.di.server.updateEntityForStudio(this, req);
        return res;
    }
    resetChaptersOption() {
        const di = this.rootStore.di;
        (0, mobx_1.runInAction)(() => {
            var _a;
            this.chaptersOption = new ui_1.SelectionInput('chaptersOption', trans('legacy_DOStory_chapter_selection'), // '챕터 선택',
            (_a = this.chapterIdEditing) !== null && _a !== void 0 ? _a : -1, this.chapterStore.all.map(chapter => {
                return {
                    value: chapter.id,
                    name: chapter.name,
                    description: chapter.name,
                };
            }), {
                // Redirect 사용하여 이동시 UI가 화면에 렌더 되지 않아 history 사용하였습니다.
                onChangeBeforeSubmit: selectedChapterId => {
                    if (this.chapterIdEditing !== selectedChapterId) {
                        di.redirectToUrl(studioUrls_1.StudioUrls.Story.Detail.Chapter.Edit(this.storyId, selectedChapterId));
                    }
                },
                onChange: selectedChapterId => {
                    if (this.chapterIdEditing !== selectedChapterId) {
                        di.redirectToUrl(studioUrls_1.StudioUrls.Story.Detail.Chapter.Edit(this.storyId, selectedChapterId));
                    }
                },
            });
        });
    }
    startEditStoryDetail() {
        return new DOStoryEditor_1.DOStoryEditor(this);
    }
    startEditStoryGameDetail() {
        return new DOStoryGameEditor_1.DOStoryGameEditor(this);
    }
    async createChapter(name, chapterData, onError) {
        try {
            if (name.includes(':')) {
                onError(new errors_1.SPCError(errors_1.ErrorCode.ChapterNameForbiddenCharacterIsIncluded));
                return null;
            }
            const customId = this.rootStore.di.generateCustomChapterId();
            const res = await this.storyStrategy.createChapterForStudio({
                ...chapterData,
                id: this.storyId,
                name,
                customId,
                afterChapterId: -1,
            });
            return this.chapterStore.merge(res);
        }
        catch (ex) {
            onError(ex);
            return null;
        }
    }
    async removeChapter(chapterId, onError) {
        try {
            await this.storyStrategy.deleteChapterForStudio(chapterId);
            this.chapterStore.removeById(chapterId);
            return true;
        }
        catch (ex) {
            onError(ex);
            return false;
        }
    }
    getTalkPreviewStorageKey(charName) {
        return `talk_preview_${this.storyId}_${charName}`;
    }
}
exports.DOStory = DOStory;
