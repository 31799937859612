"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbApiClient = void 0;
const api_1 = require("./api");
const algorithm_block_api_1 = require("./api/algorithm-block-api");
const algorithm_block_group_api_1 = require("./api/algorithm-block-group.api");
const check_api_1 = require("./api/check.api");
const media_1 = require("./api/media");
const menu_tag_api_1 = require("./api/menu-tag.api");
const menu_api_1 = require("./api/menu.api");
const grammar_api_1 = require("./api/grammar.api");
class HbApiClient {
    constructor(client) {
        this.client = client;
        this.user = new api_1.HbUserAPI(this.client);
        this.bot = new api_1.HbBotAPI(this.client);
        this.block = new api_1.HbBlockAPI(this.client);
        this.blockGroup = new api_1.HbBlockGroupApI(this.client);
        this.algorithmBlockGroup = new algorithm_block_group_api_1.HbAlgorithmBlockGroupApi(this.client);
        this.algorithmBlock = new algorithm_block_api_1.HbAlgorithmBlockApi(this.client);
        this.message = new api_1.HbMessageAPI(this.client);
        this.sender = new api_1.HbSenderAPI(this.client);
        this.sheet = new api_1.HbSheetAPI(this.client);
        this.attribute = new api_1.HbAttributeAPI(this.client);
        this.media = new media_1.HbMediaApi(this.client);
        this.menuTag = new menu_tag_api_1.HbMenuTagAPI(this.client);
        this.menu = new menu_api_1.HbMenuAPI(this.client);
        this.check = new check_api_1.HbCheckAPI(this.client);
        this.grammar = new grammar_api_1.HbGrammarAPI(this.client);
    }
}
exports.HbApiClient = HbApiClient;
