import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import { GQLNotice } from '../../@types/server'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useCounter } from '../../context/ODCommon'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import {
  ODListableOption,
  ODListableResponseType,
  useODListableContext,
} from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import {
  ODListablePaginatedTable,
  ODListableTableDefinition,
} from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

interface Props {}

export interface NoticeDataLoaderOption extends ODListableOption {
  filter: string | null
}

export const NoticeListContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<
    GQLNotice,
    NoticeDataLoaderOption
  >()

  const { listNotice } = useStoryPlayAPIs()
  const [token] = useCounter()

  const dataLoader = React.useCallback(
    async function NoticeDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: NoticeDataLoaderOption
    ): Promise<ODListableResponseType<GQLNotice>> {
      const r = await listNotice({
        page,
        pageSize,
        filter: options.filter || null,
      })
      return r as ODListableResponseType<GQLNotice>
    },
    [listNotice]
  )

  const TableDefinition: ODListableTableDefinition<
    GQLNotice,
    NoticeDataLoaderOption,
    any
  > = [
    {
      id: 'noticeId',
      title: '공지사항 아이디',
      transform: (v) => v.noticeId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'title',
      title: '제목',
      transform: (v) => (
        <Link to={SiteUrls.Admin.Notice.Edit(v.noticeId)}>{v.title}</Link>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'createdAt',
      title: '등록 시각',
      transform: (v) => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.noticeId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="검색하세요."
                style={{ flexGrow: 6, maxWidth: 600 }}
              />

              <div>
                <Link
                  to={SiteUrls.Admin.Notice.Create}
                  style={{ textDecoration: 'none' }}
                >
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    공지사항 추가
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩중..'}
              renderEmpty={() => '공지사항이 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination
              hideIfSinglePage={false}
              listableContext={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
