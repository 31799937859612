"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2RenderBlock = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
const playerReportContainerHTML_1 = require("../playerReport2/playerReportContainerHTML");
class DOPlayerReport2RenderBlock {
    constructor(store, data, ide) {
        this.desc = '';
        this.code = '';
        this.codeSample = '';
        this.sampleData = '';
        this.store = store;
        this.prbId = data.prbId;
        this.ide = ide;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            code: mobx_1.observable,
            sampleData: mobx_1.observable,
            merge: mobx_1.action,
            outputHTML: mobx_1.computed,
        });
    }
    merge(data) {
        const fields = [
            'name',
            'desc',
            'code',
            'codeSample',
            'sampleData',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    get serverId() {
        return this.prbId;
    }
    get rootStore() {
        return this.store.rootStore;
    }
    get outputHTML() {
        const param = JSON.parse(this.sampleData || '{}');
        // Todo: sampleData 가 객체 형태가 아니면 parse 시 오류가 발생한다. 해결 필요? 수정시에 막아 놓는 방법 필요할 듯?
        if (!!this.ide.env) {
            const html = this.ide.env.renderString(this.code, { param });
            return (0, playerReportContainerHTML_1.createPlayerReport2HTMLBlock)(this.ide.env, this.name, html, this.rootStore.playerReportCSSLink);
        }
        else {
            // playerReport2IDE > init 의 함수가 아직 수행되지 않아 env 가 없는 경우
            return this.code;
        }
    }
    async update(changeSet) {
        return this.rootStore.di.server.updateEntityForStudio(this, changeSet);
    }
}
exports.DOPlayerReport2RenderBlock = DOPlayerReport2RenderBlock;
