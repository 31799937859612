import { useCounter, useODMutation } from '@odc/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import {
  GQLImageFile,
  GQLListableImageFile,
  GQLListableImageFileInput,
  GQLOkResponse,
  GQLSingleIDInput,
} from '../../@types/server'
import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
} from '../../components/base/ODButton'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useModalConfirm } from '../../components/ODModal/ODModalConfirm'
import { useODQuery } from '../../context/ODCommon'
import {
  ODListableOption,
  ODListableResponseType,
  useODListableContext,
} from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import {
  ODListablePaginatedTable,
  ODListableTableDefinition,
} from '../../ODListable/ODListablePaginationTable'
import { COLORS } from '../../styles/colors'
import { SiteUrls } from '../../urls'
import {
  GQL_LIST_IMAGE_FILE,
  GQL_REMOVE_IMAGE_FILE,
} from '../../utils/gqls_web'
import { Utils } from '../../utils/utils'
import { ImageFileMakeJSONModal } from './ImageFileMakeJSONModal'

interface Props {}

export interface ImageFileDataLoaderOption extends ODListableOption {}

export const ImageFileListContainer: React.FC<Props> = () => {
  const [token, refresh] = useCounter()
  const { Provider, Context } = useODListableContext<
    GQLImageFile,
    ImageFileDataLoaderOption
  >()
  const listImageFiles = useODQuery<
    GQLListableImageFileInput,
    GQLListableImageFile
  >(GQL_LIST_IMAGE_FILE)
  const removeImageFile = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_REMOVE_IMAGE_FILE
  )
  const [showImageFileMakeJSONModal, setShowImageFileMakeJSONModal] =
    React.useState(false)

  const dataLoader = React.useCallback(
    async function ImageFileDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ImageFileDataLoaderOption
    ): Promise<ODListableResponseType<GQLImageFile>> {
      const r = await listImageFiles({
        page,
        pageSize,
      })
      return r as ODListableResponseType<GQLImageFile>
    },
    [listImageFiles]
  )

  const {
    Component: Confirm,
    confirm,
    props: confirmComponentProps,
  } = useModalConfirm({
    title: '이미지 삭제',
    message: '해당 이미지를 삭제합니다. 계속하시겠습니까?',
    yes: '삭제',
    no: '취소',
  })
  const TableDefinition: ODListableTableDefinition<
    GQLImageFile,
    ImageFileDataLoaderOption,
    any
  > = [
    {
      id: 'fileId',
      title: '일련 번호',
      transform: (v) => v.fileId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'link',
      title: '링크',
      transform: (v) => v.link,
      thClass: 'text-left',
      className: 'text-left user-td break-all',
    },
    {
      id: 'image',
      title: 'image',
      transform: (v) => (
        <img
          alt="uploaded"
          style={{ maxWidth: 100, maxHeight: 100 }}
          src={v.link}
        />
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'width',
      title: '가로(너비)',
      transform: (v) => v.width,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'height',
      title: '세로(너비)',
      transform: (v) => v.height,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'delete',
      title: '삭제',
      transform: (v) => (
        <div
          style={{ color: COLORS.Primary, cursor: 'pointer' }}
          onClick={() => {
            confirm()
              .then((y) => {
                if (y) {
                  return removeImageFile({ id: v.fileId }).then(() => refresh())
                }
              })
              .catch(Utils.showError)
          }}
        >
          삭제하기
        </div>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.fileId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <Confirm {...confirmComponentProps} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginBottom: 15,
              }}
            >
              <ODButton
                theme={ODButtonTheme.PrimaryInvert}
                size={ODButtonSize.Small}
                style={{ minWidth: 130 }}
                onClick={() => setShowImageFileMakeJSONModal(true)}
              >
                이미지 선택지 JSON
              </ODButton>
              <Link to={SiteUrls.Admin.ImageFile.Create}>
                <ODButton
                  theme={ODButtonTheme.Primary}
                  size={ODButtonSize.Small}
                  style={{ minWidth: 100 }}
                >
                  이미지 추가
                </ODButton>
              </Link>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩 중..'}
              renderEmpty={() => '등록된 이미지가 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination
              hideIfSinglePage={false}
              listableContext={Context}
            />
          </Provider>
          {showImageFileMakeJSONModal && (
            <ImageFileMakeJSONModal
              onClose={() => {
                setShowImageFileMakeJSONModal(false)
              }}
              onConfirm={() => {
                setShowImageFileMakeJSONModal(false)
              }}
            />
          )}
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
