"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTToBlock = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const changeOp_1 = require("../../../../../changeOp");
const validation_1 = require("../../../validation");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const IDOStatement_1 = require("../IDOStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTToBlock extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, isInsideSubBlock, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: __1.STATEMENT_TYPE.ToBlock,
            block: '',
            background: '',
        }, block, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.ToBlock;
        this.hasModalForEdit = true;
        this.isInsideSubBlock = isInsideSubBlock;
        this.updateBlockOptions();
        (0, mobx_1.makeObservable)(this, {
            blockOptions: mobx_1.observable,
            blockTo: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get blocksTo() {
        const block = this.block.store.getById(this.st.block);
        if (!block) {
            return [];
        }
        return [
            {
                block,
                label: '',
                extra: { statement: this },
            },
        ];
    }
    //
    // IStudioEditor implementations
    //  - 이 문장은 DOSTConditionSubBlock 내에서 동작할 때는 사용되지 않는다.
    //  - 장면연결로 독자적으로 활용될 때 사용된다.
    //
    startEditing() {
        this.updateBlockOptions();
        return super.startEditing();
    }
    async submitEditing() {
        var _a;
        const op = this.helper.opFactory().startBulk();
        const blockTo = this.blockOptions.value instanceof __1.DOBlock
            ? this.blockOptions.value.id
            : (_a = this.blockOptions.value) !== null && _a !== void 0 ? _a : __1.BLOCK_EMPTY;
        op.createNewBlock(this.blockOptions.value, this.background);
        if (this.st.block !== blockTo) {
            op.changeToBlockToBlock(this.block.uniqueId, this.uniqueId, blockTo);
        }
        const r = await super.submitEditing(op);
        await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(__1.GA4_EVENT_NAME.CONNECT_SCENE);
        this.updateBlockOptions(); // BEToBlock 에서도 blockOptions 를 사용중이라 업데이트가 필요함.
        // 장면 연결 수정시 현 위치를 연결된 블럭의 첫번째로 이동시킨다.
        if (this.blockTo &&
            this.rootStore.chapterEditing &&
            this.rootStore.chapterEditing.blockEditor) {
            this.rootStore.chapterEditing.blockEditor.onBlockClickedForJump(this.parentBlock, this.blockTo.name);
            if (this.blockTo.statements.length > 0) {
                this.rootStore.chapterEditing.blockEditor.onLineClickedForCursorMove(this.blockTo.statements[0]);
            }
        }
        return r;
    }
    cancelEditing() {
        super.cancelEditing();
        this.updateBlockOptions(); // BEToBlock 에서도 blockOptions 를 사용중이라 업데이트가 필요함.
    }
    /**
     * 내가 연결된 블록, 연결된 블록이 없으면 null
     */
    get blockTo() {
        const doBlock = this.block.store.getById(this.st.block);
        return doBlock !== null && doBlock !== void 0 ? doBlock : null;
    }
    updateBlockOptions() {
        (0, mobx_1.runInAction)(() => {
            this.blockOptions = this.block.parentChapter.getBlockOptions(this.st.block, this.background, block => {
                if (this.isInsideSubBlock) {
                    (0, mobx_1.runInAction)(() => (this.st.block = (0, lodash_1.isString)(block) ? block : block.id));
                }
            }, true);
        });
    }
    /**
     * 블록 이름이 변경되면 블록 이동에 대한 이름을 함께 변경해야 한다.
     */
    onBlockNameChanged(prevName, newName) {
        super.onBlockNameChanged(prevName, newName);
        if (this.st.block === prevName) {
            (0, mobx_1.runInAction)(() => {
                this.st.block = newName;
            });
        }
    }
    validate() {
        const res = [];
        const v = this.st.block;
        if (!v) {
            res.push({
                type: validation_1.StudioValidationType.ToBlockHasNoBlock,
                extra: {},
                source: this,
                severity: validation_1.StudioValidationSeverity.Error,
                stack: [],
            });
        }
        else {
            const block = this.block.store.getById(v);
            if (!block) {
                res.push({
                    type: validation_1.StudioValidationType.ToBlockHasInvalidBlockId,
                    extra: { blockName: v },
                    source: this,
                    severity: validation_1.StudioValidationSeverity.Error,
                    stack: [],
                });
            }
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = res));
        return res;
    }
    generateSheetColumnsAfterBackground() {
        var _a, _b;
        // ['', 블록]
        const blockId = (_b = (_a = this.blockTo) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '';
        if (!blockId) {
            throw new Error(trans('legacy_DOSTToBlock_no_block_name'));
        }
        return ['', blockId];
    }
    onBlockRemoved(blockId, changeOp) {
        if (this.st.block === blockId) {
            changeOp.push({
                opType: changeOp_1.StudioChangeOpType.ChangeToBlockToBlock,
                target: changeOp_1.StudioChangeOpTarget.Block,
                blockUniqueId: this.block.uniqueId,
                blockName: __1.BLOCK_EMPTY,
                lineUniqueId: this.uniqueId,
            });
        }
    }
    async applyChangeOp(op, type) {
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.ChangeToBlockToBlock:
                return this.changeToBlockInternal(op.blockName);
            default:
                break;
        }
        return super.applyChangeOp(op, type);
    }
    changeToBlockInternal(toBlockName) {
        const prevBlockName = this.st.block;
        (0, mobx_1.runInAction)(() => {
            this.st.block = toBlockName;
        });
        this.updateBlockOptions();
        return {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.ChangeToBlockToBlock,
                target: changeOp_1.StudioChangeOpTarget.Block,
                blockUniqueId: this.block.uniqueId,
                blockName: prevBlockName,
                lineUniqueId: this.uniqueId,
            },
            lineToFocus: this,
        };
    }
    selectNumberByKeyboard(index) {
        var _a;
        const block = this.blockTo;
        if (block) {
            (_a = this.block.parentChapter.blockEditor) === null || _a === void 0 ? void 0 : _a.onBlockClickedForJump(this.block, block.id);
        }
    }
    clone() {
        return new DOSTToBlock((0, lodash_1.cloneDeep)(this.data), this.block, this.isInsideSubBlock);
    }
    onAllBlocksOfChapterLoaded() {
        super.onAllBlocksOfChapterLoaded();
        // blockOptions.value 값이 제대로 설정되지 않으면 에디터에 제대로 보이지 않음.
        this.updateBlockOptions();
    }
    async getMessagesToTranslate() {
        return null;
    }
}
exports.DOSTToBlock = DOSTToBlock;
