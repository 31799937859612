"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTFullWidthText = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const shortcutUtils_1 = require("../../../utils/shortcutUtils");
const ui_1 = require("../../ui");
const DOSTBubbleBase_1 = require("./DOSTBubbleBase");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTFullWidthText extends DOSTBubbleBase_1.DOSTBubbleBase {
    constructor() {
        super(...arguments);
        this.hasModalForEdit = true;
    }
    onInputMount(provider, inputRef) {
        return (0, shortcutUtils_1.bindItems)(provider, () => true, bind => {
            bind(shortcutUtils_1.StudioShortcut.SubmitLongTextEditing, () => this.submitEditing());
            bind(shortcutUtils_1.StudioShortcut.CancelEditing, () => this.cancelEditing());
        });
    }
    startEditing(editForColorPicker = false) {
        if (editForColorPicker) {
            this.editorManager.startEditing();
            return;
        }
        super.startEditing();
    }
    async cancelEditing() {
        // 새로 생성된 경우에 "취소" 버튼을 누른다면, 소설 지문이 사라지도록
        // 새로 생성된 경우는 data 의 message 에 값이 없을 경우라고 가정
        if (this.st.message.trim().length === 0) {
            const op = this.helper.opFactory().startBulk();
            op.removeLinesFromBlock(this.block.uniqueId, [this.uniqueId]);
            await super.submitEditing(op);
        }
        else {
            super.cancelEditing();
        }
    }
    createFields() {
        super.createFields();
        (0, mobx_1.runInAction)(() => {
            this.field = new ui_1.InputWithValidation('fullWidthText', trans('legacy_DOSTFullWidthText_content'), trans('legacy_DOSTFullWidthText_enter_novel_form_text'), 'textarea', this.message, null, {
                enableInPlaceEditing: false,
                noLabel: false,
                editable: true,
            }).setAutoFocus();
        });
    }
}
exports.DOSTFullWidthText = DOSTFullWidthText;
