"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdPerChapterRow = void 0;
const ui_1 = require("../ui");
class AdPerChapterRow {
    constructor(editor, data) {
        var _a, _b, _c, _d;
        this.editor = editor;
        this.originData = data;
        this.startIndexField = new ui_1.SelectionInput(`startIndex`, '광고 적용 회차', (_b = (_a = this.originData) === null || _a === void 0 ? void 0 : _a.startIndex) !== null && _b !== void 0 ? _b : undefined, this.editor.chapterIndexSelection, {
            textAfterField: '부터',
            placeholder: '회차를 입력하세요.',
            onChangeBeforeSubmit: v => {
                var _a, _b, _c, _d, _e;
                this.endIndexField.onChangeOptions(this.editor.chapterIndexSelection.slice(Number(v) - 1));
                const valueParsed = JSON.parse((_b = (_a = this.editor.adPerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                const indexItem = (_c = valueParsed.adChapterIndexes) === null || _c === void 0 ? void 0 : _c[0];
                if (indexItem) {
                    indexItem.startIndex = Number(v);
                    (_d = this.editor.adPerChapterSettingField) === null || _d === void 0 ? void 0 : _d.onChange(JSON.stringify(valueParsed));
                }
                else {
                    (_e = this.editor.adPerChapterSettingField) === null || _e === void 0 ? void 0 : _e.onChange(JSON.stringify({ adChapterIndexes: [{ startIndex: Number(v) }] }));
                }
            },
        });
        this.endIndexField = new ui_1.SelectionInput(`endIndex`, '', (_d = (_c = this.originData) === null || _c === void 0 ? void 0 : _c.endIndex) !== null && _d !== void 0 ? _d : undefined, this.editor.chapterIndexSelection, {
            noLabel: true,
            textAfterField: '까지',
            placeholder: '회차를 입력하세요.',
            onChangeBeforeSubmit: v => {
                var _a, _b, _c, _d, _e;
                const valueParsed = JSON.parse((_b = (_a = this.editor.adPerChapterSettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                const indexItem = (_c = valueParsed.adChapterIndexes) === null || _c === void 0 ? void 0 : _c[0];
                if (indexItem) {
                    indexItem.endIndex = Number(v);
                    (_d = this.editor.adPerChapterSettingField) === null || _d === void 0 ? void 0 : _d.onChange(JSON.stringify(valueParsed));
                }
                else {
                    (_e = this.editor.adPerChapterSettingField) === null || _e === void 0 ? void 0 : _e.onChange(JSON.stringify({ adChapterIndexes: [{ endIndex: Number(v) }] }));
                }
            },
        });
    }
}
exports.AdPerChapterRow = AdPerChapterRow;
