"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTCondition = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../../@types/server.schema");
const changeOp_1 = require("../../../../../changeOp");
const consts_1 = require("../../../../../consts");
const models_1 = require("../../../../../models");
const parser_1 = require("../../../../../playData/formula/parser");
const validations_1 = require("../../../../validations");
const interface_1 = require("../../../interface");
const validation_1 = require("../../../validation");
const ui_1 = require("../../ui");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const IDOStatement_1 = require("../IDOStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTCondition extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: models_1.STATEMENT_TYPE.Condition,
            conditionType: models_1.CONDITION_TYPE.PropertyEqualsTo,
            conditionParam: '',
            conditionNumberParam: 0,
            conditionAction: models_1.CONDITION_ACTION.MoveToBlock,
            conditionActionParam: IDOStatement_1.BLOCK_EMPTY,
            background: '',
        }, block, uniqueId);
        // 왼쪽 선택
        this.condLeftOptions = null;
        // 연산자 선택
        this.condOpOptions = null;
        // 숫자 입력값
        this.condNumberInput = null;
        // 고급 조건식
        this.advancedCondInput = null;
        // 텍스트 입력값
        this.condStringInput = null;
        // private originalData: string | null = null
        this.formulaStatus = consts_1.SPExprFormulaStatus.Ok;
        this.condCategoryOptions = new ui_1.SelectionInput('condCategoryOptions', trans('legacy_DOSTChoiceIf_division'), this.currentCondType, [
            {
                name: trans('legacy_DOSTChoiceIf_common'),
                value: IDOStatement_1.ConditionCategory.Prop,
                description: '',
            },
            {
                name: trans('legacy_DOSTChoiceIf_my_value'),
                value: IDOStatement_1.ConditionCategory.MainProp,
                description: '',
            },
            {
                name: trans('legacy_ODSAddNewItemModal_item'),
                value: IDOStatement_1.ConditionCategory.Item,
                description: '',
            },
            {
                name: trans('legacy_DOSTCondition_all_round_ending'),
                value: IDOStatement_1.ConditionCategory.PreviousEnding,
                description: '',
            },
            {
                name: trans('legacy_DOSTCondition_advanced_condition'),
                value: IDOStatement_1.ConditionCategory.PlayDataExpr,
                description: '',
                isDisabled: !this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.ADVANCED_FORMULA_IN_STATEMENT),
            },
        ], {
            creatable: false,
            onChangeBeforeSubmit: (value) => {
                this.changeCondType(value);
            },
        });
        this.generateCondLeftOptions(null);
        this.generateCondOpOptions(null);
        this.generateNumberInput(null);
        this.generateBlockOptions();
        (0, mobx_1.makeObservable)(this, {
            condCategoryOptions: mobx_1.observable,
            condLeftOptions: mobx_1.observable,
            condOpOptions: mobx_1.observable,
            condNumberInput: mobx_1.observable,
            advancedCondInput: mobx_1.observable,
            condStringInput: mobx_1.observable,
            blockOptions: mobx_1.observable,
            formulaStatus: mobx_1.observable,
            conditionLeftText: mobx_1.computed,
            conditionOpText: mobx_1.computed,
            conditionRightText: mobx_1.computed,
            conditionStatementToText: mobx_1.computed,
        });
    }
    isPropStringFeatureEnabled() {
        return this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.STORY_PROP_STRING);
    }
    getPropByStConditionParam() {
        return this.block.parentChapter
            .getStory()
            .storyPropertyStore.getByName(this.st.conditionParam, this.currentCondType === IDOStatement_1.ConditionCategory.MainProp);
    }
    get currentCondType() {
        switch (this.st.conditionType) {
            case models_1.CONDITION_TYPE.PreviousEndingEqualsTo:
            case models_1.CONDITION_TYPE.PreviousEndingNotEqualsTo: {
                return IDOStatement_1.ConditionCategory.PreviousEnding;
            }
            case models_1.CONDITION_TYPE.PropertyEqualsTo:
            case models_1.CONDITION_TYPE.PropertyNotEqualsTo:
            case models_1.CONDITION_TYPE.PropertyGTE:
            case models_1.CONDITION_TYPE.PropertyLTE: {
                const propName = this.st.conditionParam;
                const isMain = this.block.parentChapter
                    .getStory()
                    .storyPropertyStore.allMain.find(v => v.propName === propName);
                return isMain ? IDOStatement_1.ConditionCategory.MainProp : IDOStatement_1.ConditionCategory.Prop;
            }
            case models_1.CONDITION_TYPE.NumOfStoryItemEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemNotEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemGTE:
            case models_1.CONDITION_TYPE.NumOfStoryItemLTE: {
                return IDOStatement_1.ConditionCategory.Item;
            }
            case models_1.CONDITION_TYPE.PlayDataExpr: {
                return IDOStatement_1.ConditionCategory.PlayDataExpr;
            }
            default:
                throw new Error(`DOSTCondition.currentCondType : Invalid condition type (${this.st.conditionType})`);
        }
    }
    changeCondType(condType) {
        (0, mobx_1.runInAction)(() => {
            switch (condType) {
                case IDOStatement_1.ConditionCategory.Prop:
                case IDOStatement_1.ConditionCategory.MainProp:
                    this.st.conditionType = models_1.CONDITION_TYPE.PropertyEqualsTo;
                    this.st.conditionParam = IDOStatement_1.COND_EMPTY;
                    break;
                case IDOStatement_1.ConditionCategory.Item:
                    this.st.conditionType = models_1.CONDITION_TYPE.NumOfStoryItemEqualsTo;
                    this.st.conditionParam = IDOStatement_1.COND_EMPTY;
                    break;
                case IDOStatement_1.ConditionCategory.PreviousEnding:
                    this.st.conditionType = models_1.CONDITION_TYPE.PreviousEndingEqualsTo;
                    this.st.conditionParam = IDOStatement_1.COND_EMPTY;
                    break;
                case IDOStatement_1.ConditionCategory.PlayDataExpr:
                    this.st.conditionType = models_1.CONDITION_TYPE.PlayDataExpr;
                    this.st.conditionParam = IDOStatement_1.COND_EMPTY;
                    break;
            }
            this.generateCondLeftOptions(condType);
            this.generateCondOpOptions(condType);
            this.generateNumberInput(condType);
            this.generateBlockOptions();
        });
    }
    generateCondLeftOptions(condTypeIn) {
        var _a, _b, _c;
        const condType = condTypeIn !== null && condTypeIn !== void 0 ? condTypeIn : this.currentCondType;
        const options = (() => {
            var _a, _b, _c;
            switch (condType) {
                case IDOStatement_1.ConditionCategory.Prop:
                    return this.block.parentChapter
                        .getStory()
                        .storyPropertyStore.allNonMain.map(v => v.propName);
                case IDOStatement_1.ConditionCategory.MainProp:
                    return this.block.parentChapter
                        .getStory()
                        .storyPropertyStore.allMain.map(v => v.propName);
                case IDOStatement_1.ConditionCategory.Item:
                    return this.block.parentChapter
                        .getStory()
                        .storyItemStore.allStoryItems.map(v => v.name);
                case IDOStatement_1.ConditionCategory.PreviousEnding:
                    return ((_c = (_b = (_a = this.block.parentChapter) === null || _a === void 0 ? void 0 : _a.previousChapter) === null || _b === void 0 ? void 0 : _b.chapterEndings) !== null && _c !== void 0 ? _c : []).map(v => v.name);
            }
            return [];
        })();
        const currentValue = (() => {
            switch (condType) {
                case IDOStatement_1.ConditionCategory.Prop:
                case IDOStatement_1.ConditionCategory.MainProp: {
                    return this.st.conditionParam || IDOStatement_1.PROP_EMPTY;
                }
                case IDOStatement_1.ConditionCategory.Item: {
                    return this.st.conditionParam || IDOStatement_1.ITEM_EMPTY;
                }
                case IDOStatement_1.ConditionCategory.PreviousEnding: {
                    return this.st.conditionParam;
                }
            }
            return '';
        })();
        // 속성의 경우 이름을 변환해주기 위해서
        const convertName = (name) => {
            switch (condType) {
                case IDOStatement_1.ConditionCategory.Prop:
                    return this.block.story.storyPropertyStore.getByName(name, false)
                        .convertedPropName;
                case IDOStatement_1.ConditionCategory.MainProp: {
                    return this.block.story.storyPropertyStore.getByName(name, true)
                        .convertedPropName;
                }
                default:
                    return name;
            }
        };
        const endingDisplayNames = ((_c = (_b = (_a = this.block.parentChapter) === null || _a === void 0 ? void 0 : _a.previousChapter) === null || _b === void 0 ? void 0 : _b.chapterEndings) !== null && _c !== void 0 ? _c : []).map(v => v.displayName);
        (0, mobx_1.runInAction)(() => {
            this.condLeftOptions =
                condType === IDOStatement_1.ConditionCategory.PlayDataExpr
                    ? null
                    : new ui_1.SelectionInput('condPropName', trans('legacy_PropList_property'), currentValue !== null && currentValue !== void 0 ? currentValue : '', options.map((op, index) => ({
                        name: condType === IDOStatement_1.ConditionCategory.PreviousEnding
                            ? endingDisplayNames[index]
                            : convertName(op),
                        value: op,
                        description: op,
                    })), {
                        creatable: false,
                        onChangeBeforeSubmit: value => {
                            (0, mobx_1.runInAction)(() => {
                                this.st.conditionParam = value;
                            });
                            if (this.isPropStringFeatureEnabled() &&
                                (this.currentCondType === IDOStatement_1.ConditionCategory.MainProp ||
                                    this.currentCondType === IDOStatement_1.ConditionCategory.Prop)) {
                                this.generateCondOpOptions(null);
                                this.generateNumberInput(null);
                            }
                        },
                    });
        });
    }
    generateCondOpOptions(condTypeIn) {
        const propType = condTypeIn !== null && condTypeIn !== void 0 ? condTypeIn : this.currentCondType;
        const options = (() => {
            switch (propType) {
                case IDOStatement_1.ConditionCategory.Prop:
                case IDOStatement_1.ConditionCategory.MainProp: {
                    const propFound = this.getPropByStConditionParam();
                    if (this.isPropStringFeatureEnabled() &&
                        !!propFound &&
                        propFound.propType === server_schema_1.GQLSTORY_PROP_TYPE.STRING) {
                        return [
                            {
                                value: models_1.CONDITION_TYPE.PropertyEqualsTo,
                                name: '=',
                                description: '',
                            },
                            {
                                value: models_1.CONDITION_TYPE.PropertyNotEqualsTo,
                                name: '<>',
                                description: '',
                            },
                        ];
                    }
                    return [
                        {
                            value: models_1.CONDITION_TYPE.PropertyEqualsTo,
                            name: '=',
                            description: '',
                        },
                        {
                            value: models_1.CONDITION_TYPE.PropertyNotEqualsTo,
                            name: '<>',
                            description: '',
                        },
                        { value: models_1.CONDITION_TYPE.PropertyLTE, name: '<=', description: '' },
                        { value: models_1.CONDITION_TYPE.PropertyGTE, name: '>=', description: '' },
                    ];
                }
                case IDOStatement_1.ConditionCategory.Item: {
                    return [
                        {
                            value: models_1.CONDITION_TYPE.NumOfStoryItemEqualsTo,
                            name: '=',
                            description: '',
                        },
                        {
                            value: models_1.CONDITION_TYPE.NumOfStoryItemNotEqualsTo,
                            name: '<>',
                            description: '',
                        },
                        {
                            value: models_1.CONDITION_TYPE.NumOfStoryItemLTE,
                            name: '<=',
                            description: '',
                        },
                        {
                            value: models_1.CONDITION_TYPE.NumOfStoryItemGTE,
                            name: '>=',
                            description: '',
                        },
                    ];
                }
                case IDOStatement_1.ConditionCategory.PreviousEnding:
                    return [
                        {
                            value: models_1.CONDITION_TYPE.PreviousEndingEqualsTo,
                            name: '=',
                            description: '',
                        },
                        {
                            value: models_1.CONDITION_TYPE.PreviousEndingNotEqualsTo,
                            name: '<>',
                            description: '',
                        },
                    ];
            }
            return [];
        })();
        if (propType === IDOStatement_1.ConditionCategory.PlayDataExpr) {
            (0, mobx_1.runInAction)(() => {
                this.condOpOptions = null;
            });
        }
        else {
            const currentValue = (() => {
                var _a, _b;
                switch (propType) {
                    case IDOStatement_1.ConditionCategory.Prop:
                    case IDOStatement_1.ConditionCategory.MainProp:
                    case IDOStatement_1.ConditionCategory.Item:
                    case IDOStatement_1.ConditionCategory.PreviousEnding: {
                        return (_a = this.st.conditionType) !== null && _a !== void 0 ? _a : options[0].value;
                    }
                    // default 까지 올 일은 없을 것 같습니다만,
                    // options[0] 가 있다면 해당 option 의 value 를 반환하도록 하고,
                    // 없다면 undefined 를 반환해 아무것도 선택되지 않은 Select 를 보여줍니다.
                    default: {
                        return (_b = options[0]) === null || _b === void 0 ? void 0 : _b.value;
                    }
                }
            })();
            (0, mobx_1.runInAction)(() => {
                this.condOpOptions = new ui_1.SelectionInput('opOptions', trans('legacy_DOSTChoiceIf_operator'), currentValue, options, {
                    creatable: false,
                    onChangeBeforeSubmit: value => {
                        (0, mobx_1.runInAction)(() => {
                            this.st.conditionType = value;
                        });
                    },
                });
            });
        }
    }
    generateNumberInput(condTypeIn) {
        const condType = condTypeIn !== null && condTypeIn !== void 0 ? condTypeIn : this.currentCondType;
        (0, mobx_1.runInAction)(() => {
            var _a;
            switch (condType) {
                case IDOStatement_1.ConditionCategory.Prop:
                case IDOStatement_1.ConditionCategory.MainProp: {
                    const propFound = this.getPropByStConditionParam();
                    if (this.isPropStringFeatureEnabled() &&
                        !!propFound &&
                        propFound.propType === server_schema_1.GQLSTORY_PROP_TYPE.STRING) {
                        this.condStringInput = new ui_1.InputWithValidation('condStringInput', trans('legacy_DOSTCondition_string_value_to_compare'), trans('legacy_DOSTCondition_string_value_to_compare'), 'text', (_a = this.st.conditionStringParam) !== null && _a !== void 0 ? _a : '', null, {
                            noLabel: true,
                            onChange: value => {
                                (0, mobx_1.runInAction)(() => {
                                    this.st.conditionStringParam = value;
                                });
                            },
                        });
                        this.st.conditionNumberParam = 0;
                        this.condNumberInput = null;
                    }
                    else {
                        this.condNumberInput = new ui_1.InputWithValidation('condNumInput', trans('legacy_DOSTCondition_value_to_compare'), trans('legacy_DOSTCondition_value_to_compare'), 'number', this.st.conditionNumberParam, null, {
                            noLabel: true,
                            onChange: value => {
                                (0, mobx_1.runInAction)(() => {
                                    this.st.conditionNumberParam = value;
                                });
                            },
                        });
                        this.st.conditionStringParam = undefined;
                        this.condStringInput = null;
                    }
                    this.advancedCondInput = null;
                    break;
                }
                case IDOStatement_1.ConditionCategory.Item: {
                    this.condNumberInput = new ui_1.InputWithValidation('condNumInput', trans('legacy_DOSTCondition_value_to_compare'), trans('legacy_DOSTCondition_number_value_to_compare'), 'number', this.st.conditionNumberParam, null, {
                        noLabel: true,
                        onChange: value => {
                            (0, mobx_1.runInAction)(() => {
                                this.st.conditionNumberParam = value;
                            });
                        },
                    });
                    this.advancedCondInput = null;
                    break;
                }
                case IDOStatement_1.ConditionCategory.PreviousEnding:
                    this.advancedCondInput = null;
                    this.condNumberInput = null;
                    this.condStringInput = null;
                    break;
                case IDOStatement_1.ConditionCategory.PlayDataExpr: {
                    this.condNumberInput = null;
                    this.condStringInput = null;
                    this.advancedCondInput = new ui_1.InputWithValidation('advancedCondInput', trans('legacy_DOSTCondition_advanced_condition'), trans('legacy_DOSTCondition_advanced_condition'), 'string', this.rootStore.textStore.encodeSPExprFormulaAfterParse(this.st.conditionParam), null, {
                        noLabel: true,
                        onChange: value => {
                            (0, mobx_1.runInAction)(() => {
                                try {
                                    const result = (0, parser_1.parseSPExprFormula)(value.toString());
                                    if ((0, lodash_1.isString)(result)) {
                                        this.formulaStatus = consts_1.SPExprFormulaStatus.OnlyCondition;
                                        return;
                                    }
                                    if ((0, lodash_1.isObject)(result) &&
                                        !consts_1.PDOperationNamesThatReturnTruthyOrFalsy.includes(result.op)) {
                                        this.formulaStatus = consts_1.SPExprFormulaStatus.OnlyCondition;
                                        return;
                                    }
                                    this.formulaStatus = consts_1.SPExprFormulaStatus.Ok;
                                    this.st.conditionParam = JSON.stringify(result);
                                }
                                catch (ex) {
                                    // tslint:disable-next-line:no-console
                                    console.error(ex);
                                    this.formulaStatus = consts_1.SPExprFormulaStatus.Fail;
                                }
                            });
                        },
                    });
                    this.st.conditionNumberParam = -1;
                }
            }
        });
    }
    generateBlockOptions() {
        var _a;
        this.blockOptions = this.block.parentChapter.getBlockOptions((_a = this.st.conditionActionParam) !== null && _a !== void 0 ? _a : IDOStatement_1.BLOCK_EMPTY, this.block.startingBackground, selectedBlock => {
            (0, mobx_1.runInAction)(() => {
                this.st.conditionActionParam = (0, lodash_1.isString)(selectedBlock)
                    ? selectedBlock
                    : selectedBlock.id;
            });
        }, true);
    }
    get st() {
        return this.data;
    }
    get endingBlockTo() {
        return this.block.store.getById(this.st.conditionActionParam);
    }
    get resultText() {
        var _a, _b;
        return ((_b = (_a = this.endingBlockTo) === null || _a === void 0 ? void 0 : _a.friendlyName) !== null && _b !== void 0 ? _b : trans('legacy_DOSTCondition_no_connected_block'));
    }
    get conditionStatementToText() {
        if (this.statementType !== models_1.STATEMENT_TYPE.Condition) {
            return '';
        }
        const st = this.data;
        switch (st.conditionType) {
            case models_1.CONDITION_TYPE.PreviousEndingEqualsTo:
                return trans('legacy_DOSTCondition_previous_ending_equal', {
                    value: st.conditionParam,
                });
            case models_1.CONDITION_TYPE.PreviousEndingNotEqualsTo:
                return trans('legacy_DOSTCondition_previous_ending_not_equal', {
                    value: st.conditionParam,
                });
            case models_1.CONDITION_TYPE.PropertyGTE:
                return `${st.conditionParam} >= ${st.conditionNumberParam}`;
            case models_1.CONDITION_TYPE.PropertyLTE:
                return `${st.conditionParam} <= ${st.conditionNumberParam}`;
            case models_1.CONDITION_TYPE.PropertyNotEqualsTo:
                return `${st.conditionParam} != ${st.conditionNumberParam}`;
            case models_1.CONDITION_TYPE.PropertyEqualsTo:
                return `${st.conditionParam} == ${st.conditionNumberParam}`;
            default:
                return trans('legacy_DOSTCondition_unknown_condition', {
                    value: st.conditionType,
                });
        }
    }
    get blocksTo() {
        if (this.st.conditionAction !== models_1.CONDITION_ACTION.MoveToBlock) {
            return [];
        }
        const block = this.block.store.getById(this.st.conditionActionParam);
        if (!block) {
            return [];
        }
        return [
            {
                block,
                label: this.conditionStatementToText,
                extra: { statement: this },
            },
        ];
    }
    /**
     * 블록 이름이 변경되면 블록 이동에 대한 이름을 함께 변경해야 한다.
     */
    onBlockNameChanged(prevName, newName) {
        super.onBlockNameChanged(prevName, newName);
        if (this.statementType !== models_1.STATEMENT_TYPE.Condition) {
            return;
        }
        const st = this.data;
        if (this.st.conditionActionParam === prevName) {
            (0, mobx_1.runInAction)(() => {
                st.conditionActionParam = newName;
                // tslint:disable-next-line:no-console
                console.log(210, `DOSTCondition block name changed.`);
            });
        }
    }
    validate() {
        var _a;
        const res = [];
        if (this.st.conditionAction === models_1.CONDITION_ACTION.MoveToBlock) {
            const block = this.st.conditionActionParam;
            if (!block) {
                res.push({
                    type: validation_1.StudioValidationType.ConditionBlockHasNoToBlock,
                    extra: {},
                    severity: validation_1.StudioValidationSeverity.Error,
                    source: this,
                    stack: [],
                });
            }
            else {
                const b = this.block.store.getById(block);
                if (!b) {
                    res.push({
                        type: validation_1.StudioValidationType.ConditionBlockHasInvalidBlockId,
                        extra: {},
                        severity: validation_1.StudioValidationSeverity.Error,
                        source: this,
                        stack: [],
                    });
                }
            }
        }
        if ([IDOStatement_1.ConditionCategory.Prop, IDOStatement_1.ConditionCategory.MainProp].includes(this.currentCondType)) {
            res.push(...this.runYupValidation(validations_1.PropValidations.name, this.st.conditionParam), ...this.runYupValidation(validations_1.PropValidations.nameInCondition, this.st.conditionParam));
            const propFound = this.getPropByStConditionParam();
            if (this.isPropStringFeatureEnabled() &&
                (propFound === null || propFound === void 0 ? void 0 : propFound.propType) === server_schema_1.GQLSTORY_PROP_TYPE.STRING) {
                if (![
                    models_1.CONDITION_TYPE.PropertyEqualsTo,
                    models_1.CONDITION_TYPE.PropertyNotEqualsTo,
                ].includes(this.st.conditionType)) {
                    res.push({
                        type: validation_1.StudioValidationType.StringPropInvalidConditionType,
                        extra: {},
                        severity: validation_1.StudioValidationSeverity.Error,
                        source: this,
                        stack: [],
                    });
                }
                if (!((_a = this.st.conditionStringParam) === null || _a === void 0 ? void 0 : _a.trim())) {
                    res.push({
                        type: validation_1.StudioValidationType.StringPropHasNoValueInCondition,
                        extra: {},
                        severity: validation_1.StudioValidationSeverity.Error,
                        source: this,
                        stack: [],
                    });
                }
            }
        }
        if (this.currentCondType === IDOStatement_1.ConditionCategory.Item) {
            res.push(...this.runYupValidation(validations_1.ItemValidations.name, this.st.conditionParam), ...this.runYupValidation(validations_1.ItemValidations.nameInCondition, this.st.conditionParam));
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = res));
        return res;
    }
    generateSheetColumnsAfterBackground() {
        // ['', '', '', 조건식, 조건식 파라메터, 액션, 액션 파라메터]
        const type = models_1.INVERTED_CONDITION_TYPE_MAP[this.st.conditionType];
        if (!type) {
            throw new Error(trans('legacy_DOSTCondition_unknown_condition_type', {
                value: this.st.conditionType,
            }));
        }
        const action = models_1.INVERTED_CONDITION_ACTION_MAP[this.st.conditionAction];
        if (!action) {
            throw new Error(trans('legacy_DOSTCondition_unknown_condition_action', {
                value: this.st.conditionAction,
            }));
        }
        if (this.st.conditionType === models_1.CONDITION_TYPE.PreviousEndingNotEqualsTo ||
            this.st.conditionType === models_1.CONDITION_TYPE.PreviousEndingEqualsTo) {
            return [
                '',
                '',
                '',
                type,
                this.st.conditionParam,
                action,
                this.st.conditionActionParam,
            ];
        }
        // ].includes(this.st.conditionType)
        const propertyParam = [
            models_1.CONDITION_TYPE.PropertyEqualsTo,
            models_1.CONDITION_TYPE.PropertyNotEqualsTo,
            models_1.CONDITION_TYPE.PropertyLTE,
            models_1.CONDITION_TYPE.PropertyGTE,
        ];
        const itemParam = [
            models_1.CONDITION_TYPE.NumOfStoryItemEqualsTo,
            models_1.CONDITION_TYPE.NumOfStoryItemNotEqualsTo,
            models_1.CONDITION_TYPE.NumOfStoryItemGTE,
            models_1.CONDITION_TYPE.NumOfStoryItemLTE,
        ];
        const propertyAndItemParam = [...propertyParam, ...itemParam];
        const isPlayDataExpr = this.st.conditionType === models_1.CONDITION_TYPE.PlayDataExpr;
        const param = (() => {
            if (propertyAndItemParam.includes(this.st.conditionType)) {
                const numberOfStringParam = (() => {
                    if (itemParam.includes(this.st.conditionType)) {
                        return this.st.conditionNumberParam.toString();
                    }
                    return this.getPropByStConditionParam().propType ===
                        server_schema_1.GQLSTORY_PROP_TYPE.STRING
                        ? this.st.conditionStringParam.toString()
                        : this.st.conditionNumberParam.toString();
                })();
                return [this.st.conditionParam, numberOfStringParam].join(',');
            }
            if (isPlayDataExpr) {
                return '';
            }
            return this.st.conditionActionParam;
        })();
        const advancedParam = (() => {
            if (isPlayDataExpr) {
                return this.st.conditionParam;
            }
            return '';
        })();
        return [
            '',
            '',
            '',
            type,
            param,
            action,
            this.st.conditionActionParam,
            advancedParam,
        ];
    }
    get conditionLeftText() {
        switch (this.st.conditionType) {
            case models_1.CONDITION_TYPE.PreviousEndingEqualsTo:
            case models_1.CONDITION_TYPE.PreviousEndingNotEqualsTo:
                return trans('legacy_DOSTCondition_previous_chapter_ending');
            case models_1.CONDITION_TYPE.PropertyEqualsTo:
            case models_1.CONDITION_TYPE.PropertyGTE:
            case models_1.CONDITION_TYPE.PropertyLTE:
            case models_1.CONDITION_TYPE.PropertyNotEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemNotEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemGTE:
            case models_1.CONDITION_TYPE.NumOfStoryItemLTE:
                return this.st.conditionParam;
            default:
                return `left_${this.st.conditionType}`;
        }
    }
    get conditionOpText() {
        switch (this.st.conditionType) {
            case models_1.CONDITION_TYPE.PreviousEndingEqualsTo:
            case models_1.CONDITION_TYPE.PropertyEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemEqualsTo:
                return '=';
            case models_1.CONDITION_TYPE.PreviousEndingNotEqualsTo:
            case models_1.CONDITION_TYPE.PropertyNotEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemNotEqualsTo:
                return '!=';
            case models_1.CONDITION_TYPE.PropertyGTE:
            case models_1.CONDITION_TYPE.NumOfStoryItemGTE:
                return '>=';
            case models_1.CONDITION_TYPE.PropertyLTE:
            case models_1.CONDITION_TYPE.NumOfStoryItemLTE:
                return '<=';
            default:
                return `op_${this.st.conditionType}`;
        }
    }
    get conditionRightText() {
        var _a, _b, _c, _d, _e;
        switch (this.st.conditionType) {
            case models_1.CONDITION_TYPE.PropertyEqualsTo:
            case models_1.CONDITION_TYPE.PropertyNotEqualsTo:
                if (((_a = this.getPropByStConditionParam()) === null || _a === void 0 ? void 0 : _a.propType) ===
                    server_schema_1.GQLSTORY_PROP_TYPE.STRING) {
                    return (_b = this.st.conditionStringParam) !== null && _b !== void 0 ? _b : '';
                }
                return this.st.conditionNumberParam.toString();
            case models_1.CONDITION_TYPE.PropertyGTE:
            case models_1.CONDITION_TYPE.PropertyLTE:
            case models_1.CONDITION_TYPE.NumOfStoryItemEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemNotEqualsTo:
            case models_1.CONDITION_TYPE.NumOfStoryItemGTE:
            case models_1.CONDITION_TYPE.NumOfStoryItemLTE:
                return this.st.conditionNumberParam.toString();
            case models_1.CONDITION_TYPE.PlayDataExpr:
                return this.rootStore.textStore.encodeSPExprFormulaAfterParse(this.st.conditionParam.toString());
            case models_1.CONDITION_TYPE.PreviousEndingEqualsTo:
                const endingDisplayName = ((_e = (_d = (_c = this.block.parentChapter) === null || _c === void 0 ? void 0 : _c.previousChapter) === null || _d === void 0 ? void 0 : _d.chapterEndings) !== null && _e !== void 0 ? _e : []).find(v => v.name === this.st.conditionParam.toString());
                return endingDisplayName === null || endingDisplayName === void 0 ? void 0 : endingDisplayName.displayName;
            default: {
                return this.st.conditionParam.toString();
            }
        }
    }
    onBlockRemoved(blockId, changeOp) {
        if (this.st.conditionAction === models_1.CONDITION_ACTION.MoveToBlock &&
            this.st.conditionActionParam === blockId) {
            changeOp.push({
                opType: changeOp_1.StudioChangeOpType.ChangeConditionToBlock,
                target: changeOp_1.StudioChangeOpTarget.Block,
                blockUniqueId: this.block.uniqueId,
                blockName: IDOStatement_1.BLOCK_EMPTY,
                lineUniqueId: this.uniqueId,
            });
        }
    }
    async applyChangeOp(op, type) {
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.ChangeConditionToBlock:
                return this.changeToBlock(op.blockName);
            default:
                break;
        }
        return super.applyChangeOp(op, type);
    }
    changeToBlock(blockId) {
        const blockName = this.st.conditionActionParam;
        (0, mobx_1.runInAction)(() => {
            this.st.conditionActionParam = blockId;
        });
        return {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.ChangeConditionToBlock,
                target: changeOp_1.StudioChangeOpTarget.Block,
                blockUniqueId: this.block.uniqueId,
                blockName,
                lineUniqueId: this.uniqueId,
            },
            lineToFocus: this,
        };
    }
    /**
     * 동일한 문장을 복제한다. unique id 는 다르게 생성될 것이다.
     * clone() 이 필요한 이유는, DOSTConditionSubBlock 에서 속성값에 대한 편집을 수행할 수 있기 위해서이다.
     */
    clone() {
        return new DOSTCondition((0, lodash_1.cloneDeep)(this.data), this.block);
    }
}
exports.DOSTCondition = DOSTCondition;
