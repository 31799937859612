import { ODButton, ODButtonSize, ODButtonTheme } from '@odc/od-react-belt'
import {
  GQLGENDER,
  GQLStorygameCharacterAdditionalPrompt,
} from '@storyplay/core'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import { Card, CardBody, Col, Input, Row } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'
import { GENDER_SELECT_OPTIONS } from '../storygameCharacterCommon'

interface Props {
  storyId: number
  id: number
}

export const StorygameCharacterAdditionalPromptEditPage: React.FC<Props> = (
  props
) => {
  const { storyId, id } = props

  const {
    createStorygameCharacterAdditionalPrompt,
    getStorygameCharacterAdditionalPrompt,
    updateStorygameCharacterAdditionalPrompt,
    deleteStorygameCharacterAdditionalPrompt,
  } = useStoryPlayAPIs()

  const [entity, setEntity] =
    React.useState<GQLStorygameCharacterAdditionalPrompt | null>(null)
  const [redirect, setRedirect] = React.useState('')

  React.useEffect(() => {
    if (id > 0) {
      getStorygameCharacterAdditionalPrompt({ id }).then((e) => {
        setEntity(e)
      })
    }
  }, [getStorygameCharacterAdditionalPrompt, id])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const title =
    id > 0
      ? '스토리 게임 캐릭터 추가 프롬프트 수정'
      : '스토리 게임 캐릭터 추가 프롬프트 생성'

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <ODBackRow
              link={SiteUrls.Admin.Story.ListStorygameCharacterAdditionalPrompt(
                storyId
              )}
            />
          </Row>

          <Row>
            <h1 className={'text-2xl font-bold mb-4'}>{title}</h1>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <h1>카테고리</h1>
            <Col md={4}>
              <Input
                type={'text'}
                value={entity?.category ?? ''}
                onChange={(e) =>
                  setEntity({ ...entity, category: e.target.value })
                }
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <h1>Key</h1>
            <Col md={4}>
              <Input
                type={'text'}
                value={entity?.key ?? ''}
                onChange={(e) => setEntity({ ...entity, key: e.target.value })}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <h1>성별</h1>
            <Col md={4}>
              {!!entity && (
                <Select
                  defaultValue={GENDER_SELECT_OPTIONS.find(
                    (o) => o.value === entity.gender
                  )}
                  options={GENDER_SELECT_OPTIONS}
                  onChange={(selected) => {
                    if (selected && selected?.value) {
                      setEntity({
                        ...entity,
                        gender: selected.value as unknown as GQLGENDER,
                      })
                    }
                  }}
                />
              )}
              {!entity && (
                <Select
                  options={GENDER_SELECT_OPTIONS}
                  onChange={(selected) => {
                    if (selected && selected?.value) {
                      setEntity({
                        ...entity,
                        gender: selected.value as unknown as GQLGENDER,
                      })
                    }
                  }}
                />
              )}
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <h1>프롬프트</h1>
            <Col md={4}>
              <Input
                type={'text'}
                value={entity?.prompt ?? ''}
                onChange={(e) =>
                  setEntity({ ...entity, prompt: e.target.value })
                }
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <h1>네거티브 프롬프트</h1>
            <Col md={4}>
              <Input
                type={'text'}
                value={entity?.negativePrompt ?? ''}
                onChange={(e) =>
                  setEntity({ ...entity, negativePrompt: e.target.value })
                }
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <h1>노출 순서</h1>
            <Col md={4}>
              <Input
                type={'number'}
                value={entity?.order ?? 1}
                onChange={(e) =>
                  setEntity({
                    ...entity,
                    order: parseInt(e.target.value ?? '1', 10),
                  })
                }
              />
            </Col>
          </Row>

          <Col md={3} style={{ display: 'flex' }}>
            <ODButton
              theme={ODButtonTheme.PrimaryInvert}
              size={ODButtonSize.Small}
              style={{ minWidth: 135, borderRadius: 5, marginRight: 10 }}
              onClick={async () => {
                if (id > 0) {
                  const e = entity!
                  updateStorygameCharacterAdditionalPrompt({
                    id: e.id,
                    category: e.category,
                    key: e.key,
                    prompt: e.prompt,
                    negativePrompt: e.negativePrompt,
                    order: e.order,
                  })
                    .then(() => {
                      Utils.showSuccess('저장되었습니다.')
                    })
                    .catch(Utils.showError)
                } else {
                  createStorygameCharacterAdditionalPrompt({
                    gender: GQLGENDER.Male,
                    order: 1,
                    ...entity,
                    storyId,
                  })
                    .then(() => {
                      setRedirect(
                        SiteUrls.Admin.Story.ListStorygameCharacterAdditionalPrompt(
                          storyId,
                          entity?.gender
                        )
                      )
                    })
                    .catch(Utils.showError)
                }
              }}
            >
              저장
            </ODButton>
            {id > 0 && (
              <ODButton
                theme={ODButtonTheme.PrimaryInvert}
                size={ODButtonSize.Small}
                style={{ minWidth: 135, borderRadius: 5 }}
                onClick={async () => {
                  deleteStorygameCharacterAdditionalPrompt({ id })
                    .then(() => {
                      Utils.showSuccess('삭제되었습니다.')
                      setRedirect(
                        SiteUrls.Admin.Story.ListStorygameCharacterAdditionalPrompt(
                          storyId,
                          entity?.gender
                        )
                      )
                    })
                    .catch(Utils.showError)
                }}
              >
                삭제
              </ODButton>
            )}
          </Col>
        </CardBody>
      </Card>
    </>
  )
}
