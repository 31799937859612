import {
  BlockingLoadBox,
  ODListableOption,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useODListableContext,
} from '@odc/od-react-belt'
import {
  GQLGENDER,
  GQLStorygameCharacterAdditionalPrompt,
} from '@storyplay/core'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import { Button, Card, CardBody } from 'reactstrap'
import { StringParam } from 'serialize-query-params/lib/params'
import { useQueryParam, withDefault } from 'use-query-params'
import { HoverTR } from '../../../../components/commonStyle'
import { SiteUrls } from '../../../../urls'
import { GENDER_SELECT_OPTIONS } from '../../storygameCharacterCommon'
import { useStorygameCharacterAdditionalPromptListDataLoader } from './StorygameCharacterAddtionalPromptDataLoader'

interface ITableProps {
  storyId: number
}

export const StorygameCharacterAdditionalPromptListableTable: React.FC<
  ITableProps
> = (props) => {
  const { storyId } = props
  const [loading] = React.useState(false)
  const [redirect, setRedirect] = React.useState('')
  const { Provider, Context } = useODListableContext<
    GQLStorygameCharacterAdditionalPrompt,
    { storyId: number } & ODListableOption
  >()

  const [selectedGender, setSelectedGender] = useQueryParam(
    'gender',
    withDefault(StringParam, GQLGENDER.Male)
  )

  const dataLoader =
    useStorygameCharacterAdditionalPromptListDataLoader(storyId)

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v['id'].toString()}
      pageSize={10}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
          >
            <div style={{ width: 150 }}>
              <Select
                defaultValue={GENDER_SELECT_OPTIONS.find(
                  (o) => o.value === selectedGender
                )}
                options={GENDER_SELECT_OPTIONS}
                onChange={(selected) => {
                  if (selected && selected?.value) {
                    setSelectedGender(selected.value)
                  }
                }}
              />
            </div>
            <Button
              block
              color="primary"
              style={{ width: 135 }}
              onClick={() =>
                setRedirect(
                  SiteUrls.Admin.Story.EditStorygameCharacterAdditionalPrompt(
                    storyId,
                    -1
                  )
                )
              }
            >
              추가하기
            </Button>
          </div>
          <ODListablePaginatedTable2
            numColumns={2}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>순서</th>
                <th>category</th>
                <th>key</th>
                <th>prompt</th>
                <th>negative prompt</th>
              </tr>
            )}
            renderRow={(value: GQLStorygameCharacterAdditionalPrompt) =>
              selectedGender === value.gender && (
                <HoverTR
                  key={value.id}
                  onClick={() =>
                    setRedirect(
                      SiteUrls.Admin.Story.EditStorygameCharacterAdditionalPrompt(
                        storyId,
                        value.id
                      )
                    )
                  }
                >
                  <td>{value.id}</td>
                  <td>{value.order}</td>
                  <td>{value.category}</td>
                  <td>{value.key}</td>
                  <td>{value.prompt}</td>
                  <td>{value.negativePrompt}</td>
                </HoverTR>
              )
            }
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
