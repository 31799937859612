"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOAEventDef = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
const IDOAEventDef_1 = require("./IDOAEventDef");
/**
 * Character domain object.
 */
class DOAEventDef {
    constructor(store, data) {
        this.badges = [];
        this.aEventDefId = data.aEventDefId;
        this.store = store;
        this.data = data;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            description: mobx_1.observable,
            isAchievement: mobx_1.observable,
            badges: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    merge(data) {
        const fields = [
            'name',
            'description',
            'isAchievement',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        if (data.badges) {
            this.badges = data.badges.map(b => {
                return this.store.story.aBadgeStore.merge(b);
            });
        }
        return this;
    }
    get isError() {
        return this.aEventDefId === IDOAEventDef_1.AEventItemErrorId;
    }
}
exports.DOAEventDef = DOAEventDef;
