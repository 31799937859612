import _ from 'lodash'

export const COLORS = {
  GRAY200: '#EEEEEE',
  GRAY600: '#939191',
  GRAY900: '#232222',
  GOLD500: '#b5944b',
  ACCENT: '#b5944b',
  Primary: '#b5944b',
  DimmedText: '#393939',
  DefaultIconColor: '',
  SIDEBAR_BG: '#000000',
  HEADER_BG: '#ffffff',
}

export const STUDIO_COLORS = {
  Primary100: '#F3E8FF',
  Primary300: '#AB85D4',
  Primary500: '#753BBD',
  Primary600: '#631FB7',
  PrimaryHover: '#EAE5EF',
  PrimaryDark: '#631FB7',
  PrimarySelectedChar: 'rgba(146, 109, 251, 0.3)',
  SubEmerald500: '#2CCCD3',
  DangerPrimary500: '#DB2B00',
  DangerPrimary600: '#FF5B56',
  DangerBlock: '#FFD9D8',
  White: '#FFFFFF',
  Gray50: '#FAFAFA',
  Gray100: '#f5f5f5',
  Gray200: '#EDEDEE',
  Gray300: '#DDDEE1',
  Gray400: '#c6c8cc',
  Gray500: '#A4A7AD',
  Gray600: '#7e8185',
  Gray700: '#57595E',
  Gray800: '#3f4142',
  Gray900: '#242526',
  PointYellow: '#FFFE79',
  PointAmber: '#FFD31A',
  PointOrange: '#FF970C',
  PointGreen: '#24CA8E',
  PointRedHeart: '#FF5B56',
  PointPrimaryDark2: '#450891',
}

export const alpha = (color: string, alpha: number) =>
  `${color}${_.padStart(Math.round(alpha * 255).toString(16), 2, '0')}`
