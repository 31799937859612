"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbAlgorithmBlockApi = void 0;
class HbAlgorithmBlockApi {
    constructor(server) {
        this.server = server;
    }
    getItems(listConfig) {
        return this.server.get(`/v1/algorithm`, listConfig);
    }
    getItem(id) {
        return this.server.get(`/v1/algorithm/${id}`);
    }
    createItem(payload) {
        return this.server.post(`/v1/algorithm`, payload);
    }
    copyItems(payload) {
        throw new Error('Method not implemented.');
    }
    updateItem(id, payload) {
        return this.server.put(`/v1/algorithm/${id}`, payload);
    }
    updateItemTurn(payload) {
        throw new Error('Method not implemented.');
    }
    deleteItems(ids) {
        throw new Error('Method not implemented.');
    }
    uploadExcel(payload) {
        throw new Error('Method not implemented.');
    }
    updateSenderByAlgorithmCardIdAndSenderId(algoCardId, senderId) {
        throw new Error('Method not implemented.');
    }
    copyCards(payload) {
        throw new Error('Method not implemented.');
    }
    getAlgorithmLogic() {
        return this.server.get('/v1/algorithm/logic');
    }
}
exports.HbAlgorithmBlockApi = HbAlgorithmBlockApi;
