"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTMessageImage = void 0;
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const fields_1 = require("../../fields");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
class DOSTMessageImage extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.isImageFileAcceptor = true;
        this.hasModalForEdit = true;
        this.imageField = new __1.StudioFileField('image', () => ({
            link: this.st.link,
            width: this.st.width,
            height: this.st.height,
            fileId: this.st.studioFileId,
        }), file => {
            (0, mobx_1.runInAction)(() => {
                var _a, _b, _c;
                this.st.link = (_a = file === null || file === void 0 ? void 0 : file.link) !== null && _a !== void 0 ? _a : '';
                this.st.width = (_b = file === null || file === void 0 ? void 0 : file.width) !== null && _b !== void 0 ? _b : 0;
                this.st.height = (_c = file === null || file === void 0 ? void 0 : file.height) !== null && _c !== void 0 ? _c : 0;
                this.st.studioFileId = file === null || file === void 0 ? void 0 : file.fileId;
            });
        });
        this.chrField = new fields_1.ChrField('chr', () => this.chr, chr => {
            (0, mobx_1.runInAction)(() => {
                this.st.chrName = chr.name;
            });
        });
        (0, mobx_1.makeObservable)(this, {
            link: mobx_1.computed,
            width: mobx_1.computed,
            height: mobx_1.computed,
            chrName: mobx_1.computed,
            chr: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get chrName() {
        return this.st.chrName.trim();
    }
    get chr() {
        var _a;
        return ((_a = this.block.parentChapter
            .getStory()
            .characterList.find(v => v.name === this.chrName)) !== null && _a !== void 0 ? _a : null);
    }
    get link() {
        return this.st.link;
    }
    get width() {
        return this.st.width;
    }
    get height() {
        return this.st.height;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [...super.editorFields, this.imageField, this.chrField];
    }
    /**
     * ODSImageEditModal 용 interface
     */
    onChangeFile(file) {
        var _a;
        (_a = this.imageField) === null || _a === void 0 ? void 0 : _a.onChange(file);
        return this.submitEditing();
    }
    generateSheetColumnsAfterBackground() {
        // [, 링크, 너비, 높이, 이미지 옵션]
        const result = [
            this.chrName,
            this.link,
            this.width.toString(),
            this.height.toString(),
            '',
            '',
            '',
        ];
        if (!!this.executeConditionOfStatement) {
            result.push(this.executeConditionOfStatement);
        }
        return result;
    }
    async getMessagesToTranslate() {
        return null;
    }
}
exports.DOSTMessageImage = DOSTMessageImage;
