import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import {
  GQLChapterComment,
  GQLChapterCommentOrderBy,
  GQLCHAPTER_COMMENT_TYPE,
} from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IChapterCommentListableOption } from '../ChapterCommentCommon'

type OptionalId = number | null

export function useChapterCommentListDataLoader(
  storyId: number,
  parentId: OptionalId,
  chapterIdFilter: OptionalId,
  choiceIdFilter: OptionalId,
  typeFilter: string | null,
  orderFilterValue: GQLChapterCommentOrderBy[]
) {
  const { listChapterCommentOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IChapterCommentListableOption
    ): Promise<ODListableResponseType<GQLChapterComment>> {
      const r = await listChapterCommentOfStory({
        page,
        pageSize: 10,
        storyId,
        parentId,
        chapterId: chapterIdFilter,
        choiceId: choiceIdFilter,
        type: typeFilter as GQLCHAPTER_COMMENT_TYPE,
        orderBy: orderFilterValue,
        ...options,
      })
      return r as ODListableResponseType<GQLChapterComment>
    },
    [
      listChapterCommentOfStory,
      storyId,
      parentId,
      chapterIdFilter,
      orderFilterValue,
      choiceIdFilter,
      typeFilter,
    ]
  )
}
