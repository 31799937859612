import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Loading } from '../components/Loading'
import { RequireLogin } from '../components/RequireLogin'
import { GoogleAppProvider } from '../context/GoogleAppContext'
import { SiteUrls } from '../urls'
import { AdminLoginContainer } from './AdminLoginContainer'
import { AdminLogoutContainer } from './AdminLogoutContainer'
import { AdminMainLayout } from './AdminMainLayout'
import { RegisterOTPKeyContainer } from './otp/RegisterOTPKeyContainer'
import { PasswordTooOldContainer } from './user/PasswordTooOldContainer'

export const AdminRoot: React.FC = () => {
  return (
    <GoogleAppProvider>
      <React.Suspense fallback={Loading}>
        <Switch>
          <Route exact path={SiteUrls.Admin.Login} render={(props: any) => <AdminLoginContainer {...props} />} />
          <Route
            path={SiteUrls.Admin.Logout}
            render={() => <AdminLogoutContainer urlAfterLogout={SiteUrls.Admin.Login} />}
          />
          <Route
            path={SiteUrls.Admin.PasswordTooOld}
            render={(props: any) => (
              <RequireLogin>
                <PasswordTooOldContainer {...props} />
              </RequireLogin>
            )}
          />
          <Route path={SiteUrls.Admin.RegisterOTPKey} render={(props: any) => <RegisterOTPKeyContainer {...props} />} />
          <Route
            path={SiteUrls.Admin.Root}
            render={(props: any) => (
              <RequireLogin>
                <AdminMainLayout {...props} />
              </RequireLogin>
            )}
          />
          <Redirect to={SiteUrls.Admin.Root} />
        </Switch>
      </React.Suspense>
    </GoogleAppProvider>
  )
}
