"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RowWithColumnBuilder = void 0;
const col_1 = require("../col");
const IRowLayout_1 = require("./IRowLayout");
class RowWithColumnBuilder {
    constructor() {
        this.builders = [];
        this.className = '';
    }
    addCol(cb) {
        const builder = new col_1.ColBuilder(this);
        cb(builder);
        this.builders.push(builder);
        return this;
    }
    setClassName(className) {
        this.className = className;
    }
    buildRowLayout() {
        return {
            layoutType: IRowLayout_1.RowType.RowWithColumns,
            cols: this.builders.map(b => b.buildColLayout()),
            className: this.className,
        };
    }
}
exports.RowWithColumnBuilder = RowWithColumnBuilder;
