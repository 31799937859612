import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  ODImageFileInput,
  useCounter,
  useODEntityAPI,
} from '@odc/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import {
  GQLEndingShowsProperty,
  GQLEndingShowsPropertyCreationInput,
  GQLEndingShowsPropertyUpdateInput,
} from '../../../@types/server'
import {
  GQL_CREATE_ENDING_SHOWS_PROPERTY,
  GQL_GET_ENDING_SHOWS_PROPERTY,
  GQL_REMOVE_ENDING_SHOWS_PROPERTY,
  GQL_UPDATE_ENDING_SHOWS_PROPERTY,
} from '../../../hooks/gqls_admin'
import { SiteUrls } from '../../../urls'
import {
  EndingShowsPropertyPrimaryKey,
  ICTEndingShowsProperty,
} from '../EndingShowsPropertyCommon'

interface IEndingShowsPropertyEditPageProps {
  storyId: number
  endingId: number
  espId: number | null
  isFinal: boolean
}

const createDefaultData = (
  endingId: number
): Partial<ICTEndingShowsProperty> => {
  return { endingId, storyPropId: 0, customImageFile: null }
}

export const EndingShowsPropertyEditPage: React.FC<IEndingShowsPropertyEditPageProps> = (
  props
) => {
  const { storyId, endingId, espId, isFinal } = props
  const apis = useODEntityAPI<
    GQLEndingShowsProperty,
    ICTEndingShowsProperty,
    GQLEndingShowsPropertyCreationInput,
    GQLEndingShowsPropertyUpdateInput
  >({
    createGQL: gql(GQL_CREATE_ENDING_SHOWS_PROPERTY),
    updateGQL: gql(GQL_UPDATE_ENDING_SHOWS_PROPERTY),
    readGQL: gql(GQL_GET_ENDING_SHOWS_PROPERTY),
    deleteGQL: gql(GQL_REMOVE_ENDING_SHOWS_PROPERTY),
    primaryKeyName: EndingShowsPropertyPrimaryKey,
  })

  const [token] = useCounter()
  const isCreating = espId === 0
  const url = SiteUrls.Admin.Story.EditEnding(storyId, endingId, isFinal)

  return (
    <>
      <ODEntity
        resourceId={isCreating ? null : espId}
        // @ts-ignore
        api={apis}
        saveButtonName="저장"
        deleteButtonName="삭제"
        titleCreation="추가"
        titleUpdate="수정"
        updateSuccessTitle="성공"
        updateSuccessMessage="수정하였습니다."
        urlAfterCreation={() => url}
        urlAfterDelete={() => url}
        deleteSuccessTitle="성공"
        deleteSuccessMessage="삭제하였습니다."
        defaultCreateClientData={createDefaultData(endingId)}
        urlAfterUpdate={() => url}
        noCardWrap
        refreshToken={token}
      >
        {!isCreating && (
          <ODEntityInput
            keyPath="espId"
            label="내부 할당 아이디"
            name="espId"
            placeholder="내부 할당 아이디 (자동부여)"
            inputType="text"
            inputProps={{ disabled: true }}
          />
        )}
        <ODEntityLabeled label="연결된 엔딩명" name="ending">
          <ODEntityRaw
            name="ending"
            keyPath="ending"
            render={(p) => <span>{p.value.name}</span>}
          />
        </ODEntityLabeled>
        <ODEntityLabeled label="연결된 속성명" name="storyProp">
          <ODEntityRaw
            name="storyProp"
            keyPath="storyProp"
            render={(p) => <span>{p.value.propName}</span>}
          />
        </ODEntityLabeled>
        <ODEntityLabeled label="커스텀 이미지파일" name="customImageFile">
          <ODImageFileInput
            name="customImageFile"
            height={92}
            width={92}
            keyPath="customImageFile.link"
          />
        </ODEntityLabeled>
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <hr />
        </div>
      </ODEntity>
    </>
  )
}
