import React from 'react'
import { SCRIPT_PARSE_MESSAGE } from '../../hooks/useScriptImporter'
import { ODEntityLabeled } from '../../ODEntityEditor/FormComponents/ODEntityLabeled'
import { LogMessage, LogRow, LogsWrapper, LogType } from './Components'

export type StoryScriptLogsProps = {
  logs: Array<SCRIPT_PARSE_MESSAGE>
}

export const StoryScriptLogs: React.FC<StoryScriptLogsProps> = ({ logs }) => {
  if (logs.length === 0) {
    return null
  }

  return (
    <>
      <hr />
      <ODEntityLabeled name="logs" label="스크립트 분석 로그">
        <LogsWrapper style={{ marginTop: 8 }}>
          {logs.map((log, index) => {
            return (
              <LogRow key={index}>
                <LogType type={log.type}>{log.type}</LogType>
                <LogMessage>{log.message}</LogMessage>
              </LogRow>
            )
          })}
        </LogsWrapper>
      </ODEntityLabeled>
    </>
  )
}
