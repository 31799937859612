"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTChoiceToBlock = void 0;
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const changeOp_1 = require("../../../../../changeOp");
const validation_1 = require("../../../validation");
const IFlowChartPositionCalculator_1 = require("../../chapter/IFlowChartPositionCalculator");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
class DOSTChoiceToBlock extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: __1.STATEMENT_TYPE.ChoiceToBlock,
            choices: ['', ''],
            background: '',
        }, block, uniqueId);
    }
    get st() {
        return this.data;
    }
    get blocksTo() {
        const blocks = this.st.choices
            .map((blockName, index) => {
            const block = this.block.store.getById(blockName);
            if (!block) {
                return null;
            }
            return { block, index };
        })
            .filter(v => !!v);
        // label will be injected by sub block
        return blocks.map(({ block, index }) => ({
            block,
            label: '',
            extra: { index },
        }));
    }
    getBlockAt(index) {
        var _a;
        if (index >= this.st.choices.length) {
            return null;
        }
        const name = this.st.choices[index];
        return (_a = this.block.store.getById(name)) !== null && _a !== void 0 ? _a : null;
    }
    setBlockAt(index, toBlock) {
        (0, mobx_1.runInAction)(() => {
            this.st.choices[index] = toBlock;
        });
    }
    // removeChoice(index: number) {
    //   runInAction(() => {
    //     this.st.choices.splice(index, 1)
    //   })
    // }
    /**
     * 블록 이름이 변경되면 블록 이동에 대한 이름을 함께 변경해야 한다.
     */
    onBlockNameChanged(prevName, newName) {
        super.onBlockNameChanged(prevName, newName);
        (0, mobx_1.runInAction)(() => {
            this.st.choices.forEach((blockName, index) => {
                if (blockName === prevName) {
                    this.st.choices[index] = newName;
                    // tslint:disable-next-line:no-console
                    console.log(76, `DOSTChoiceToBlock block name changed.`);
                }
            });
        });
    }
    validate() {
        const res = [];
        this.st.choices.forEach(v => {
            if (!v) {
                res.push({
                    type: validation_1.StudioValidationType.ChoiceHasNoToBlock,
                    extra: {},
                    source: this,
                    severity: validation_1.StudioValidationSeverity.Error,
                    stack: [],
                });
            }
            else {
                const block = this.block.store.getById(v);
                if (!block) {
                    res.push({
                        type: validation_1.StudioValidationType.ChoiceHasInvalidBlockId,
                        extra: { blockName: v },
                        source: this,
                        severity: validation_1.StudioValidationSeverity.Error,
                        stack: [],
                    });
                }
            }
        });
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = res));
        return res;
    }
    generateSheetColumnsAfterBackground() {
        // ['', 블록명1, ...]
        return [
            '',
            ...this.blocksTo
                .filter(v => v.block.nodeType === IFlowChartPositionCalculator_1.FlowChartNodeType.Block)
                .map(v => v.block.id),
        ];
    }
    onBlockRemoved(blockId, changeOp) {
        this.st.choices.forEach((blockName, index) => {
            if (blockId === blockName) {
                changeOp.push({
                    opType: changeOp_1.StudioChangeOpType.ChangeChoiceToBlock,
                    target: changeOp_1.StudioChangeOpTarget.Block,
                    blockUniqueId: this.block.uniqueId,
                    blockName: __1.BLOCK_EMPTY,
                    choiceIndex: index,
                    lineUniqueId: this.uniqueId,
                });
            }
        });
    }
    async applyChangeOp(op, type) {
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.ChangeChoiceToBlock:
                return this.changeToBlockAtIndexInternal(op.choiceIndex, op.blockName);
            default:
                break;
        }
        return super.applyChangeOp(op, type);
    }
    changeToBlockAtIndexInternal(index, toBlockName) {
        const prevBlockName = this.st.choices[index];
        (0, mobx_1.runInAction)(() => {
            this.st.choices[index] = toBlockName;
        });
        return {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.ChangeChoiceToBlock,
                target: changeOp_1.StudioChangeOpTarget.Block,
                blockUniqueId: this.block.uniqueId,
                blockName: prevBlockName,
                choiceIndex: index,
                lineUniqueId: this.uniqueId,
            },
            lineToFocus: this,
        };
    }
}
exports.DOSTChoiceToBlock = DOSTChoiceToBlock;
