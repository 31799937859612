"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiMap = void 0;
class MultiMap {
    constructor() {
        this.arrayMap = new Map();
        this.get = (key) => {
            return this.arrayMap.get(key);
        };
        this.set = (key, value) => {
            this.arrayMap.set(key, value);
            return this;
        };
        this.delete = (key) => {
            return this.arrayMap.delete(key);
        };
        this.clear = () => {
            return this.arrayMap.clear();
        };
        this.add = (key, value) => {
            const existingValue = this.arrayMap.get(key);
            if (existingValue) {
                existingValue.push(value);
            }
            else {
                const newArray = [value];
                this.arrayMap.set(key, newArray);
            }
            return this;
        };
        /**
         * Try to add all elements of given array into existing key. create new one if key does not exist.
         * @param key
         * @param value
         * @returns
         */
        this.concat = (key, value) => {
            const existingValue = this.arrayMap.get(key);
            if (existingValue) {
                const newArray = existingValue.concat(value);
                existingValue.concat(newArray);
            }
            else {
                const newArray = [...value];
                this.arrayMap.set(key, newArray);
            }
            return this;
        };
    }
}
exports.MultiMap = MultiMap;
