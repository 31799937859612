"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTRightTalk = void 0;
const core_1 = require("@storyplay/core");
const DOSTBubbleWithChrBase_1 = require("./DOSTBubbleWithChrBase");
const DOSTLeftTalk_1 = require("./DOSTLeftTalk");
class DOSTRightTalk extends DOSTBubbleWithChrBase_1.DOSTBubbleWithChrBase {
    constructor() {
        super(...arguments);
        this.isForMainChr = true;
    }
    get chr() {
        return this.block.parentChapter.mainCharacter;
    }
    get chrName() {
        return '{{성}}{{이름}}';
    }
    async setChr(chr) {
        // cannot change
    }
    replaceToOpposite(newChrName) {
        const dataChanging = {
            ...this.data,
            statementType: core_1.STATEMENT_TYPE.CharacterTalk,
            chrName: newChrName,
        };
        this.block.replaceStatement(this, new DOSTLeftTalk_1.DOSTLeftTalk(dataChanging, this.block));
    }
}
exports.DOSTRightTalk = DOSTRightTalk;
