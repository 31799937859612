"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeMessageWithEffect = void 0;
const core_1 = require("@storyplay/core");
/**
 * 주어진 MESSAGE_WITH_EFFECT[] 를 엑셀에 기입하는 형태의 구문으로 변경한다.
 */
function encodeMessageWithEffect(arr) {
    return arr
        .map(el => {
        let result = el.text;
        if (el.color) {
            result = `[c:${el.color}]${result}[/c]`;
        }
        if (el.style && core_1.STYLE_TAG_TO_TAG_TYPE[el.style]) {
            const tagType = core_1.STYLE_TAG_TO_TAG_TYPE[el.style];
            result = `[${tagType}]${result}[/${tagType}]`;
        }
        if (el.fontSizeOffset &&
            core_1.OFFSET_TO_FONT_SIZE_OFFSET_TAG_VALUE[el.fontSizeOffset]) {
            const tagValue = core_1.OFFSET_TO_FONT_SIZE_OFFSET_TAG_VALUE[el.fontSizeOffset];
            result = `[fs:${tagValue}]${result}[/fs]`;
        }
        return result;
    })
        .join('');
}
exports.encodeMessageWithEffect = encodeMessageWithEffect;
