/**
 * 언어에 따라 달라질 수 있는 리소스들을 최대한 편리하게 활용할 수 있는 기능을 제공하기 위한
 * context, react hooks, HOC 를 제공한다.
 */
import * as React from 'react'
import { getStringResource } from './getStringResource'
import { USER_LANGUAGE } from './index'
import { RS } from './ODResources'

export type ResourceProviderProps = {
  lang?: USER_LANGUAGE
  children?: React.ReactNode
}

export interface ContextType {
  lang: USER_LANGUAGE
  setLang: (lang: USER_LANGUAGE) => void
}

export const ResourceContext = React.createContext<ContextType>({} as ContextType)

export function ResourceProvider(props: ResourceProviderProps): React.ReactElement {
  const { lang: initialLang, children } = props
  const [lang, setLangState] = React.useState(
    initialLang || (parseInt(localStorage.getItem('lang') || USER_LANGUAGE.KR.toString(), 10) as USER_LANGUAGE)
  )

  const setLang = React.useCallback(
    (lang: USER_LANGUAGE) => {
      localStorage.setItem('lang', lang.toString())
      setLangState(lang)
    },
    [setLangState]
  )

  React.useEffect(() => {
    const getString = (key: RS) => getStringResource(lang!, key)
    // @ts-ignore
    window.getString = getString
  }, [lang])

  const context: ContextType = {
    lang,
    setLang,
  }

  return <ResourceContext.Provider value={context}>{children}</ResourceContext.Provider>
}

export const useResourceContext = () => React.useContext(ResourceContext)

export type FuncGetStringBound = (key: RS) => string
type FuncGetServerErrorStringBound = (ex: Error) => string

/**
 * GraphQL 서버 오류 코드를 메시지로부터 파싱하여 반환한다. 만약 파싱에 실패하면 0을 반환한다.
 * 오류 예시 : GraphQL error: FE-90216 Phone verification code is invalid.
 */
export function parseGQLErrorCode(ex: Error): number {
  try {
    return parseInt(ex.message.split('GraphQL error: SE-')[1].split(' ')[0], 10) || 0
  } catch (ex) {
    return 0
  }
}

/**
 * 언어설정에 따라 자동으로 번역된 텍스트를 반환하는 react-hook
 */
export function useStringResource(): {
  getString: FuncGetStringBound
  lang: USER_LANGUAGE
  // getServerError: FuncGetServerErrorStringBound
} {
  const { lang } = useResourceContext()

  const getStringBound = React.useCallback((key: RS) => getStringResource(lang!, key), [lang])
  // const getServerErrorString = React.useCallback(
  //   (ex: Error): string => {
  //     const errorCode = parseGQLErrorCode(ex)
  //     if (errorCode > 0) {
  //       const errorEnumName = `ServerError${errorCode}`
  //       if (RS.hasOwnProperty(errorEnumName)) {
  //         // @ts-ignore
  //         return getStringResource(lang!, RS[errorEnumName])
  //       }
  //     }
  //     return Utils.parseErrorMessage(ex) || ''
  //   },
  //   [lang]
  // )

  return {
    getString: getStringBound,
    lang,
    // getServerError: getServerErrorString
  }
}
