"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TTS_SPEAKER_STATUS = exports.TTS_DPE_ID = exports.EMOTION_LIST = exports.SPEED_LIST = exports.PITCH_LIST = exports.TTS_PITCH = exports.TTS_SPEED = exports.TTS_EMOTION = void 0;
const server_schema_1 = require("../@types/server.schema");
const i18n_1 = require("../i18n");
// i18n.const.ts 에 번역 변수 추가하려니 순환참조 이슈가 있어서,
// 해당 파일에서 바로 i18n 적용
const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
var TTS_EMOTION;
(function (TTS_EMOTION) {
    TTS_EMOTION[TTS_EMOTION["Default"] = 0] = "Default";
    TTS_EMOTION[TTS_EMOTION["Happy"] = 1] = "Happy";
    TTS_EMOTION[TTS_EMOTION["Kind"] = 2] = "Kind";
    TTS_EMOTION[TTS_EMOTION["Angry"] = 3] = "Angry";
    TTS_EMOTION[TTS_EMOTION["Annoyance"] = 4] = "Annoyance";
    TTS_EMOTION[TTS_EMOTION["WeekAngry"] = 5] = "WeekAngry";
    TTS_EMOTION[TTS_EMOTION["Howl"] = 6] = "Howl";
    TTS_EMOTION[TTS_EMOTION["Cry"] = 7] = "Cry";
    TTS_EMOTION[TTS_EMOTION["Surprised"] = 8] = "Surprised";
    TTS_EMOTION[TTS_EMOTION["Fear"] = 9] = "Fear";
    TTS_EMOTION[TTS_EMOTION["Whisper"] = 10] = "Whisper";
    TTS_EMOTION[TTS_EMOTION["Powerless"] = 11] = "Powerless";
    TTS_EMOTION[TTS_EMOTION["Drunken"] = 12] = "Drunken";
    TTS_EMOTION[TTS_EMOTION["Insipid"] = 13] = "Insipid";
})(TTS_EMOTION = exports.TTS_EMOTION || (exports.TTS_EMOTION = {}));
var TTS_SPEED;
(function (TTS_SPEED) {
    TTS_SPEED["Slow"] = "Slow";
    TTS_SPEED["Default"] = "Default";
    TTS_SPEED["Quick"] = "Quick";
})(TTS_SPEED = exports.TTS_SPEED || (exports.TTS_SPEED = {}));
var TTS_PITCH;
(function (TTS_PITCH) {
    TTS_PITCH["Low"] = "Low";
    TTS_PITCH["Default"] = "Default";
    TTS_PITCH["High"] = "High";
})(TTS_PITCH = exports.TTS_PITCH || (exports.TTS_PITCH = {}));
exports.PITCH_LIST = [
    { label: trans('tts.ts_highly'), value: TTS_PITCH.High },
    { label: trans('tts.ts_basic_tone'), value: TTS_PITCH.Default },
    { label: trans('tts.ts_low'), value: TTS_PITCH.Low },
];
exports.SPEED_LIST = [
    { label: trans('tts.ts_fast'), value: TTS_SPEED.Quick },
    { label: trans('tts.ts_basic_speed'), value: TTS_SPEED.Default },
    { label: trans('tts.ts_slow'), value: TTS_SPEED.Slow },
];
exports.EMOTION_LIST = [
    { label: trans('tts.ts_basic_sentiment'), value: server_schema_1.GQLTTS_EMOTION.Default },
    { label: trans('tts.ts_pleasure'), value: server_schema_1.GQLTTS_EMOTION.Happy },
    { label: trans('tts.ts_kindness'), value: server_schema_1.GQLTTS_EMOTION.Kind },
    { label: trans('tts.ts_anger'), value: server_schema_1.GQLTTS_EMOTION.Angry },
    { label: trans('tts.ts_petulance'), value: server_schema_1.GQLTTS_EMOTION.Annoyance },
    { label: trans('tts.ts_weakness'), value: server_schema_1.GQLTTS_EMOTION.WeekAngry },
    { label: trans('tts.ts_crying'), value: server_schema_1.GQLTTS_EMOTION.Howl },
    { label: trans('tts.ts_wool'), value: server_schema_1.GQLTTS_EMOTION.Cry },
    { label: trans('tts.ts_surprised'), value: server_schema_1.GQLTTS_EMOTION.Surprised },
    { label: trans('tts.ts_fear'), value: server_schema_1.GQLTTS_EMOTION.Fear },
    { label: trans('tts.ts_whisper'), value: server_schema_1.GQLTTS_EMOTION.Whisper },
    { label: trans('tts.ts_powerless'), value: server_schema_1.GQLTTS_EMOTION.Powerless },
    { label: trans('tts.ts_drunk'), value: server_schema_1.GQLTTS_EMOTION.Drunken },
    { label: trans('tts.ts_drying'), value: server_schema_1.GQLTTS_EMOTION.Insipid },
];
var TTS_DPE_ID;
(function (TTS_DPE_ID) {
    TTS_DPE_ID[TTS_DPE_ID["Default_Default"] = 0] = "Default_Default";
    TTS_DPE_ID[TTS_DPE_ID["Slow_Default"] = 1] = "Slow_Default";
    TTS_DPE_ID[TTS_DPE_ID["Quick_Default"] = 2] = "Quick_Default";
    TTS_DPE_ID[TTS_DPE_ID["Default_Low"] = 3] = "Default_Low";
    TTS_DPE_ID[TTS_DPE_ID["Default_High"] = 4] = "Default_High";
    TTS_DPE_ID[TTS_DPE_ID["Quick_Low"] = 5] = "Quick_Low";
    TTS_DPE_ID[TTS_DPE_ID["Quick_High"] = 6] = "Quick_High";
    TTS_DPE_ID[TTS_DPE_ID["Slow_Low"] = 7] = "Slow_Low";
    TTS_DPE_ID[TTS_DPE_ID["Slow_High"] = 8] = "Slow_High";
})(TTS_DPE_ID = exports.TTS_DPE_ID || (exports.TTS_DPE_ID = {}));
var TTS_SPEAKER_STATUS;
(function (TTS_SPEAKER_STATUS) {
    TTS_SPEAKER_STATUS["Active"] = "Active";
    TTS_SPEAKER_STATUS["Inactive"] = "Inactive";
})(TTS_SPEAKER_STATUS = exports.TTS_SPEAKER_STATUS || (exports.TTS_SPEAKER_STATUS = {}));
