"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelectionFieldWithUI = void 0;
const ui_1 = require("../ui");
const SelectionField_1 = require("./SelectionField");
/**
 * 기존 IInputWithValidation 과의 공존을 위하여 존재한다. SelectionField 를 사용하도록 하되,
 * InputWithValidation 을 연동한다.
 */
class MultiSelectionFieldWithUI extends SelectionField_1.SelectionField {
    constructor(fieldId, getter, setter, label, placeholder, options, uiOptions) {
        super(fieldId, getter, setter);
        this.noPropagation = false;
        this.input = new ui_1.MultiSelectionInput(fieldId, label, placeholder, getter(), options, {
            ...(uiOptions !== null && uiOptions !== void 0 ? uiOptions : {}),
            onChange: v => {
                var _a;
                if (!this.noPropagation) {
                    (_a = uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.onChange) === null || _a === void 0 ? void 0 : _a.call(uiOptions, v);
                    this.onChange(v);
                }
            },
        });
    }
    reload() {
        super.reload();
        this.noPropagation = true;
        this.input.onChangeSelectedOptions(this.defValue);
        this.noPropagation = false;
    }
    onChange(newValue) {
        super.onChange(newValue);
        this.noPropagation = true;
        this.input.onChangeSelectedOptions(newValue);
        this.noPropagation = false;
    }
}
exports.MultiSelectionFieldWithUI = MultiSelectionFieldWithUI;
