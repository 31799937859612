"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBottomBanner = void 0;
const core_1 = require("@storyplay/core");
class DOBottomBanner {
    constructor(rootStore, data) {
        this.bottomBannerId = null;
        this.isShow = null;
        this.title = null;
        this.styleTitle = null;
        this.webLink = null;
        this.willOpenAt = null;
        this.willCloseAt = null;
        this.state = null;
        this.data = null;
        this.changeSet = null;
        this.rootStore = rootStore;
        this.data = data !== null && data !== void 0 ? data : null;
        this.merge(data);
    }
    get serverId() {
        var _a;
        return (_a = this.bottomBannerId) !== null && _a !== void 0 ? _a : -1;
    }
    merge(data) {
        if (!data) {
            return this;
        }
        const fields = [
            'bottomBannerId',
            'title',
            'styleTitle',
            'isShow',
            'webLink',
            'willOpenAt',
            'willCloseAt',
            'state',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, core_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    create(changeSet) {
        return this.rootStore.di.server.createBottomBanner(changeSet);
    }
    update(changeSet) {
        this.changeSet = changeSet;
        if (this.serverId === -1) {
            return this.create(changeSet);
        }
        return this.rootStore.di.server.updateEntityForStudio(this, changeSet);
    }
}
exports.DOBottomBanner = DOBottomBanner;
