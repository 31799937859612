"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioChangeActionType = exports.StudioChangeOpTarget = exports.StudioChangeOpType = void 0;
var StudioChangeOpType;
(function (StudioChangeOpType) {
    StudioChangeOpType["AddLine"] = "AddLine";
    StudioChangeOpType["RemoveLine"] = "RemoveLine";
    StudioChangeOpType["ChangeMessage"] = "ChangeMessage";
    StudioChangeOpType["ChangeBubbleColor"] = "ChangeBubbleColor";
    StudioChangeOpType["ChangeChr"] = "ChangeChr";
    StudioChangeOpType["ChangeFile"] = "ChangeFile";
    StudioChangeOpType["ChangeOption"] = "ChangeOption";
    StudioChangeOpType["ChangeName"] = "ChangeName";
    StudioChangeOpType["ChangeLineName"] = "ChangeLineName";
    StudioChangeOpType["RemoveBlock"] = "RemoveBlock";
    StudioChangeOpType["AddBlock"] = "AddBlock";
    StudioChangeOpType["CreateBlock"] = "CreateBlock";
    StudioChangeOpType["ChangeBlockStartingBackground"] = "ChangeBlockStartingBackground";
    StudioChangeOpType["ChangeField"] = "ChangeField";
    StudioChangeOpType["ReplaceData"] = "ReplaceData";
    // ChangeLineData = 'ChangeLineData', // 문장의 .data 자체를 바꾸어버린다. (가장 쉽고, 근원적으로 처리가능하나 내부 상태처리에 유의할 것)
    StudioChangeOpType["ChangeToBlockToBlock"] = "ChangeToBlockToBlock";
    StudioChangeOpType["ChangeConditionToBlock"] = "ChangeConditionToBlock";
    StudioChangeOpType["ChangeChoiceRemoveChoice"] = "ChangeChoiceRemoveChoice";
    StudioChangeOpType["ChangeChoiceAddChoice"] = "ChangeChoiceAddChoice";
    StudioChangeOpType["ChangeChoiceText"] = "ChangeChoiceText";
    StudioChangeOpType["ChangeChoiceToBlock"] = "ChangeChoiceToBlock";
    StudioChangeOpType["ChangeChoiceChoiceIf"] = "ChangeChoiceChoiceIf";
    StudioChangeOpType["AddCondition"] = "AddCondition";
    StudioChangeOpType["RemoveConditionAtIndex"] = "RemoveConditionAtIndex";
    StudioChangeOpType["ChangeConditionAtIndex"] = "ChangeConditionAtIndex";
    StudioChangeOpType["ChangeConditionElse"] = "ChangeConditionElse";
    // AddChoiceIf = 'AddChoiceIf', // 선택지의 특정 선택에 ChoiceIf 관련 문장을 추가한다.
    StudioChangeOpType["RemoveLinesFromSubBlock"] = "RemoveLinesFromSubBlock";
    StudioChangeOpType["AddLinesToSubBlock"] = "AddLinesToSubBlock";
    StudioChangeOpType["RemoveLines"] = "RemoveLines";
    StudioChangeOpType["AddLines"] = "AddLines";
    StudioChangeOpType["UpsertNewEndBlock"] = "UpsertNewEndBlock";
    StudioChangeOpType["ChangeFlowChartPosition"] = "ChangeFlowChartPosition";
    // meta
    StudioChangeOpType["BulkChange"] = "BulkChange";
    StudioChangeOpType["RemoveToBlock"] = "RemoveToBlock";
    //
    // 헬봇
    //
    StudioChangeOpType["CreateSelectButton"] = "CreateSelectButton";
    StudioChangeOpType["SelectButtonChange"] = "SelectChange";
    StudioChangeOpType["RemoveSelectButton"] = "RemoveSelectButton";
})(StudioChangeOpType = exports.StudioChangeOpType || (exports.StudioChangeOpType = {}));
var StudioChangeOpTarget;
(function (StudioChangeOpTarget) {
    StudioChangeOpTarget["Chapter"] = "Chapter";
    StudioChangeOpTarget["Block"] = "Block";
    StudioChangeOpTarget["Line"] = "Line";
})(StudioChangeOpTarget = exports.StudioChangeOpTarget || (exports.StudioChangeOpTarget = {}));
var StudioChangeActionType;
(function (StudioChangeActionType) {
    StudioChangeActionType["Normal"] = "Normal";
    StudioChangeActionType["Undo"] = "Undo";
    StudioChangeActionType["Redo"] = "Redo";
})(StudioChangeActionType = exports.StudioChangeActionType || (exports.StudioChangeActionType = {}));
