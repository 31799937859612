import React from 'react'
import Select from 'react-select'

export type ChapterCommentChapterSelectFilterType = {
  chapterIndex: number
  chapterId: number | null
  chapterName: string
}

interface IChapterCommentChapterSelectProps {
  value: number | null
  filterTypes: ChapterCommentChapterSelectFilterType[]
  onChange: (value: number | null) => void
}

export const ChapterCommentChapterSelect: React.FC<IChapterCommentChapterSelectProps> = (props) => {
  const { value, filterTypes, onChange } = props

  const selectOptions = [
    {
      label: '모든 회차의 댓글',
      value: null,
    },
    ...filterTypes.map((filterType) => ({
      label: `${filterType.chapterIndex}화 (${filterType.chapterName})`,
      value: filterType.chapterId,
    })),
  ]

  return (
    <Select
      placeholder="회차"
      value={selectOptions.find((v) => v.value === value)}
      // @ts-ignore
      onChange={(v) => onChange(v.value)}
      options={selectOptions}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
