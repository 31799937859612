"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTBubbleBase = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../../changeOp");
const scripter_1 = require("../../../../../scripter/scripter");
const shortcutUtils_1 = require("../../../utils/shortcutUtils");
const fields_1 = require("../../fields");
const studioTutorial_1 = require("../../studioTutorial");
const ui_1 = require("../../ui");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const IMessageWithEffectStatement_1 = require("./IMessageWithEffectStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTBubbleBase extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.isDeletable = true;
        this.updateMessageInternal(this.message);
        this.createFields();
        (0, mobx_1.makeObservable)(this, {
            field: mobx_1.observable,
            slateDataField: mobx_1.observable,
            message: mobx_1.computed,
            bubbleEffect: mobx_1.computed,
            messageWithEffect: mobx_1.computed,
            messageWithoutEffect: mobx_1.computed,
            backgroundColor: mobx_1.computed,
            edgeColor: mobx_1.computed,
            canResetColor: mobx_1.computed,
            slateData: mobx_1.computed,
        });
    }
    get editorFields() {
        return [...super.editorFields, this.slateDataField];
    }
    get st() {
        return this.data;
    }
    get message() {
        return (0, IMessageWithEffectStatement_1.encodeMessageWithEffect)(this.messageWithEffect);
    }
    get bubbleEffect() {
        return this.st.effect;
    }
    get messageWithEffect() {
        var _a;
        return (_a = this.st.messageWithEffect) !== null && _a !== void 0 ? _a : [{ text: this.st.message }];
    }
    get messageWithoutEffect() {
        return this.messageWithEffect.map(v => v.text).join(' ');
    }
    get backgroundColor() {
        var _a;
        return (_a = this.st.effect) === null || _a === void 0 ? void 0 : _a.backgroundColor;
    }
    get edgeColor() {
        var _a;
        return (_a = this.st.effect) === null || _a === void 0 ? void 0 : _a.edgeColor;
    }
    get slateData() {
        return this.st.slateData;
    }
    openBorderColorPicker() {
        var _a;
        (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.setShowColorPickerModal({
            field: this.edgeColorField,
            line: this,
        });
    }
    openBackgroundColorPicker() {
        var _a;
        (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.setShowColorPickerModal({
            field: this.backgroundColorField,
            line: this,
        });
    }
    get canResetColor() {
        return !!this.edgeColor || !!this.backgroundColor;
    }
    onResetColor() {
        this.edgeColorField.resetWithValue(undefined);
        this.backgroundColorField.resetWithValue(undefined);
        this.submitEditing();
    }
    async applyChangeOp(op, type) {
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.ChangeMessage:
                const messageBefore = this.message;
                this.updateMessageInternal(op.message);
                return {
                    reverse: {
                        opType: changeOp_1.StudioChangeOpType.ChangeMessage,
                        target: changeOp_1.StudioChangeOpTarget.Block,
                        message: messageBefore,
                        blockUniqueId: this.block.uniqueId,
                        lineUniqueId: this.uniqueId,
                    },
                    lineToFocus: this,
                };
            case changeOp_1.StudioChangeOpType.ChangeBubbleColor: {
                const reverseOp = {
                    opType: changeOp_1.StudioChangeOpType.ChangeBubbleColor,
                    lineUniqueId: this.uniqueId,
                    target: changeOp_1.StudioChangeOpTarget.Block,
                    blockUniqueId: this.block.uniqueId,
                };
                (0, mobx_1.runInAction)(() => {
                    var _a, _b, _c, _d;
                    if (op.hasOwnProperty('backgroundColor')) {
                        reverseOp.backgroundColor = (_a = this.bubbleEffect) === null || _a === void 0 ? void 0 : _a.backgroundColor;
                        this.st.effect = (_b = this.st.effect) !== null && _b !== void 0 ? _b : {};
                        if (op.backgroundColor) {
                            this.st.effect.backgroundColor = op.backgroundColor;
                        }
                        else {
                            delete this.st.effect.backgroundColor;
                        }
                    }
                    if (op.hasOwnProperty('edgeColor')) {
                        reverseOp.edgeColor = (_c = this.bubbleEffect) === null || _c === void 0 ? void 0 : _c.edgeColor;
                        this.st.effect = (_d = this.st.effect) !== null && _d !== void 0 ? _d : {};
                        if (op.edgeColor) {
                            this.st.effect.edgeColor = op.edgeColor;
                        }
                        else {
                            delete this.st.effect.edgeColor;
                        }
                    }
                });
                // effect: {} 는 무조건 남겨두는 방향으로 일치시킨다.
                // if (!this.st.effect?.backgroundColor && !this.st.effect?.edgeColor) {
                //   delete this.st.effect
                // }
                return {
                    reverse: reverseOp,
                    lineToFocus: this,
                };
            }
            default:
                break;
        }
        return super.applyChangeOp(op, type);
    }
    updateMessageInternal(messageIn) {
        const colorMap = this.block.store.rootStore.messageColorMap;
        const scripter = new scripter_1.Scripter();
        const { message, messageWithEffect } = scripter.setMessageEffect.call({ ensureScript: () => null, getEndTag: scripter.getEndTag }, messageIn, this.sourceLine, colorMap);
        (0, mobx_1.runInAction)(() => {
            this.st.message = message;
            this.st.messageWithEffect = messageWithEffect;
        });
    }
    get inputShortcutMap() {
        const keyMap = new Map();
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.CancelEditing], {
            event: () => this.cancelEditing(),
        });
        return keyMap;
    }
    onInputMount(provider, inputRef) {
        return (0, shortcutUtils_1.bindItems)(provider, () => true, bind => {
            bind(shortcutUtils_1.StudioShortcut.SubmitEditing, () => this.submitEditing());
            bind(shortcutUtils_1.StudioShortcut.CancelEditing, () => this.cancelEditing());
        });
    }
    //
    // IStudioEditor implementations
    //
    startEditing() {
        this.field.setEditable(true);
        this.field.setEditing(true);
        super.startEditing();
    }
    async submitEditing(opFactory) {
        var _a, _b, _c, _d;
        const op = opFactory !== null && opFactory !== void 0 ? opFactory : this.helper.opFactory().startBulk();
        if (this.field.value.trim().length === 0) {
            op.removeLinesFromBlock(this.block.uniqueId, [this.uniqueId]);
        }
        else {
            if (this.field.value !== this.st.message) {
                op.changeMessage(this, this.field.value);
                if (this.field.value.includes('[c:') &&
                    this.field.value.includes('[/c]')) {
                    await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.CHANGE_TEXT_COLOR_OF_LINES);
                }
            }
            const bubbleColor = {
                edgeColor: (_a = this.bubbleEffect) === null || _a === void 0 ? void 0 : _a.edgeColor,
                backgroundColor: (_b = this.bubbleEffect) === null || _b === void 0 ? void 0 : _b.backgroundColor,
            };
            if (((_c = this.bubbleEffect) === null || _c === void 0 ? void 0 : _c.edgeColor) !== this.edgeColorField.value) {
                bubbleColor.edgeColor = this.edgeColorField.value;
            }
            if (((_d = this.bubbleEffect) === null || _d === void 0 ? void 0 : _d.backgroundColor) !== this.backgroundColorField.value) {
                bubbleColor.backgroundColor = this.backgroundColorField.value;
            }
            if (bubbleColor.hasOwnProperty('edgeColor') ||
                bubbleColor.hasOwnProperty('backgroundColor')) {
                op.changeBubbleColor(this, bubbleColor.backgroundColor, bubbleColor.edgeColor);
            }
            if (!!bubbleColor.edgeColor || !!bubbleColor.backgroundColor) {
                await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.DECORATE_SPEECH_BUBBLE);
            }
        }
        const r = await super.submitEditing(op);
        this.createFields();
        return r;
    }
    cancelEditing() {
        super.cancelEditing();
        this.createFields();
    }
    /**
     * 주의할 점 : 여기서처럼 개별 필드를 새롭게 생성하는 것은 최신의 구현방식이 아닙니다.
     * DOSTFullWidthImage 에서처럼 필드 하나를 유지하는 것이 더 좋은 방법입니다.
     * 하지만, 테스트가 이미 완료된 동작하는 코드이므로 유지합니다.
     *
     * @protected
     */
    createFields() {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.field = new ui_1.InputWithValidation(this.st.statementType, this.st.statementType, '', 'textarea', this.message, null, {
                enableInPlaceEditing: false,
                noLabel: true,
                editable: false,
            }).setAutoFocus();
            this.backgroundColorField = new ui_1.ColorInput(this.uniqueId, (_a = this.bubbleEffect) === null || _a === void 0 ? void 0 : _a.backgroundColor, { title: trans('legacy_DOSTBubbleBase_speech_bubble_background') });
            this.edgeColorField = new ui_1.ColorInput(this.uniqueId, (_b = this.bubbleEffect) === null || _b === void 0 ? void 0 : _b.edgeColor, { title: trans('legacy_DOSTBubbleBase_speech_bubble_border') });
            this.slateDataField = new fields_1.TextFieldWithUI('slateData', () => { var _a; return (_a = this.st.slateData) !== null && _a !== void 0 ? _a : ''; }, v => {
                (0, mobx_1.runInAction)(() => {
                    this.st.slateData = v;
                });
            });
        });
    }
    generateSheetColumnsAfterBackground() {
        var _a, _b, _c, _d, _e;
        const result = [
            '',
            this.message,
            (_b = (_a = this.bubbleEffect) === null || _a === void 0 ? void 0 : _a.backgroundColor) !== null && _b !== void 0 ? _b : '',
            (_d = (_c = this.bubbleEffect) === null || _c === void 0 ? void 0 : _c.edgeColor) !== null && _d !== void 0 ? _d : '',
            (_e = this.st.slateData) !== null && _e !== void 0 ? _e : '',
            '',
            '',
            !!this.executeConditionOfStatement
                ? this.executeConditionOfStatement
                : '',
            '', // extra
        ];
        return result;
    }
    onCopyRawEvent() {
        this.rootStore.studioTutorialStore
            .markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.COPY_TEXT_IN_SPEECH_BUBBLE)
            .then();
        return this.message;
    }
    getFindResults(keyword) {
        if (this.messageWithoutEffect.includes(keyword)) {
            return [this];
        }
        return [];
    }
    //
    // Studio translator
    //
    async getMessagesToTranslate() {
        return {
            messages: [this.st.message],
        };
    }
    async applyTranslatedMessages(translated) {
        (0, mobx_1.runInAction)(() => {
            this.updateMessageInternal(translated.messages[0]);
        });
    }
}
exports.DOSTBubbleBase = DOSTBubbleBase;
