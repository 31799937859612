"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorInput = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const IInputBase_1 = require("./IInputBase");
const IInputWithValidation_1 = require("./IInputWithValidation");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ColorInput {
    constructor(id, defValue, options) {
        this.inputModelType = IInputBase_1.InputModelType.ColorPicker;
        this.id = id;
        this.defValue = defValue;
        this.value = defValue;
        this.options = {
            theme: IInputWithValidation_1.InputTheme.Default,
            ...(options !== null && options !== void 0 ? options : {}),
        };
        (0, mobx_1.makeObservable)(this, {
            value: mobx_1.observable,
            theme: mobx_1.computed,
            title: mobx_1.computed,
        });
    }
    onChange(v) {
        var _a, _b;
        (0, mobx_1.runInAction)(() => {
            this.value = v;
        });
        (_b = (_a = this.options).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, v);
    }
    onSubmit(v) {
        var _a, _b;
        (0, mobx_1.runInAction)(() => {
            this.value = v;
        });
        (_b = (_a = this.options).onSubmit) === null || _b === void 0 ? void 0 : _b.call(_a, v);
    }
    resetWithValue(v) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.value = v;
            this.defValue = v;
            (_b = (_a = this.options).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, v);
        });
    }
    revertChange() {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.value = this.defValue;
            (_b = (_a = this.options).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.value);
        });
    }
    get theme() {
        return this.options.theme;
    }
    get title() {
        var _a;
        return (_a = this.options.title) !== null && _a !== void 0 ? _a : trans('legacy_ColorInput.ts_color_selection');
    }
}
exports.ColorInput = ColorInput;
