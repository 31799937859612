import {
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  ODImageFileInput,
  useCounter,
  useODMutation,
  useODQuery,
} from '@odc/od-react-belt'
import React from 'react'
import Select from 'react-select'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLListableEndingRateRange,
  GQLListableInput,
  GQLOkResponse,
  GQLPreviewEnding,
  GQLPreviewEndingPropsInput,
  GQLSingleIDInput,
} from '../../../@types/server'
import { ENDING_RATE_RANGE_LEVEL } from '../../../common'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import {
  GQL_CREATE_PREVIEW_ENDING,
  GQL_GET_PREVIEW_ENDING,
  GQL_LIST_ENDING_RATE_RANGE,
  GQL_REMOVE_PREVIEW_ENDING,
  GQL_UPDATE_PREVIEW_ENDING,
} from '../../../hooks/gqls_admin'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IPreviewEndingEditPageProps {
  storyId: number
  previewEndingId: number | null
}

type Entity = GQLPreviewEnding

function getValidationSchema(values: Partial<GQLPreviewEndingPropsInput>) {
  return Yup.object().shape({})
}

export const PreviewEndingEditPage: React.FC<IPreviewEndingEditPageProps> = (
  props
) => {
  const { storyId, previewEndingId } = props
  const innerRef = React.useRef(null)
  const apiListEndingRateRanges = useODQuery<
    Partial<GQLListableInput>,
    GQLListableEndingRateRange
  >(GQL_LIST_ENDING_RATE_RANGE)
  const apiCreate = useODMutation<
    Partial<GQLPreviewEndingPropsInput>,
    Partial<Entity>
  >(GQL_CREATE_PREVIEW_ENDING)
  const apiUpdate = useODMutation<
    Partial<GQLPreviewEndingPropsInput>,
    Partial<Entity>
  >(GQL_UPDATE_PREVIEW_ENDING)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET_PREVIEW_ENDING)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_REMOVE_PREVIEW_ENDING
  )

  const [token] = useCounter()
  const isCreating = previewEndingId === 0

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLPreviewEndingPropsInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (previewEndingId) {
          return apiGet({ id: previewEndingId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            storyId: null,
            name: '',
            isHidden: true,
            showOrder: 1000,
            endingRateRangeId: null,
            imageFile: null,
            rateLevel: null,
          }
        }

        const rateLevelName = data.rateLevel
          ? {
              [ENDING_RATE_RANGE_LEVEL.Normal]: '보통',
              [ENDING_RATE_RANGE_LEVEL.Rarity]: '희소',
              [ENDING_RATE_RANGE_LEVEL.VeryRarity]: '매우 희소',
              [ENDING_RATE_RANGE_LEVEL.Minority]: '최고 희소',
              [ENDING_RATE_RANGE_LEVEL.Unknown]: '알 수 없음',
            }[data.rateLevel]
          : ''

        return {
          previewEndingId: data.previewEndingId,
          storyId: data.storyId,
          name: data.name,
          isHidden: data.isHidden,
          showOrder: data.showOrder,
          endingRateRangeId: data.endingRateRangeId,
          imageFile: data.imageFile,
          rateLevelName,
        }
      },
      saveClientValueToServer: async (
        data: Partial<GQLPreviewEndingPropsInput>
      ) => {
        const { name, isHidden, showOrder, imageFile } =
          data as GQLPreviewEndingPropsInput
        const endingRateRangeId = selectedEndingRateRangeRef.current
          ? selectedEndingRateRangeRef.current
          : undefined

        if (previewEndingId) {
          await apiUpdate({ id: previewEndingId, endingRateRangeId, ...data })
          Utils.showSuccess('프리뷰 엔딩이 수정되었습니다.', 'Success')
        } else {
          await apiCreate({
            storyId,
            name,
            isHidden,
            showOrder,
            endingRateRangeId,
            imageFile,
          })
          Utils.showSuccess('프리뷰 엔딩이 생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.Edit(storyId, 7)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (previewEndingId) {
          await apiRemove({ id: previewEndingId })
          Utils.showSuccess('프리뷰 엔딩이 삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.Edit(storyId, 7)
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [previewEndingId, apiGet, storyId, apiUpdate, apiCreate, token, apiRemove]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<Entity, Partial<GQLPreviewEndingPropsInput>>
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<GQLPreviewEndingPropsInput>>(
      options
    )
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          Partial<GQLPreviewEndingPropsInput>
        >(options)
      ),
    [options]
  )

  const [endingRateRangeOption, setEndingRateRangeOption] = React.useState<
    { label: string; value: number }[]
  >([])
  const selectedEndingRateRangeRef = React.useRef(0)

  React.useEffect(() => {
    apiListEndingRateRanges({ pageSize: 500 }).then((r) => {
      setEndingRateRangeOption(
        r.list.map((endingRateRange) => ({
          label: endingRateRange.name!,
          value: endingRateRange.rangeId,
        }))
      )
    })
  }, [apiListEndingRateRanges])

  const title = !previewEndingId ? '프리뷰 엔딩 생성' : '프리뷰 엔딩 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            <hr />
            {!isCreating && (
              <ODEntityInput
                keyPath="previewEndingId"
                label="프리뷰엔딩 아이디"
                name="previewEndingId"
                placeholder="프리뷰엔딩 아이디 (자동부여)"
                inputType="text"
                inputProps={{ disabled: true }}
              />
            )}
            <ODEntityInput
              keyPath="showOrder"
              label="노출 우선순위"
              name="showOrder"
              placeholder="노출 우선순위"
              inputType="number"
            />
            <ODEntityInput
              keyPath="name"
              label="프리뷰엔딩 이름"
              name="name"
              placeholder="프리뷰엔딩 이름"
              inputType="text"
            />
            <ODEntityLabeled label="이미지 파일" name="imageFile">
              <ODImageFileInput
                name="imageFile"
                height={200}
                width={200}
                keyPath="imageFile.link"
              />
            </ODEntityLabeled>
            <ODEntityLabeled label="숨김?" name="isHidden">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isHidden_true"
                  name="isHidden"
                  keyPath="isHidden"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isHidden_false"
                  name="isHidden"
                  keyPath="isHidden"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            {!!previewEndingId && (
              <>
                <ODEntityLabeled
                  name={'설정된 엔딩 도착률 범위'}
                  label={'설정된 엔딩 도착률 범위'}
                >
                  <ODEntityRaw
                    name="rateLevelName"
                    keyPath="rateLevelName"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            <ODEntityLabeled
              name={'엔딩 도착률 범위'}
              label={'엔딩 도착률 범위'}
            >
              <Select
                isSearchable={true}
                placeholder="엔딩 도착률 범위"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    selectedEndingRateRangeRef.current = v.value
                  }
                }}
                options={endingRateRangeOption}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </ODEntityLabeled>
            <hr />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={previewEndingId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
