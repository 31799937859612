import { ODListableOption } from '@odc/od-react-belt'
import { GQLSFXFile } from '../../@types/server'

export const SFXFilePrimaryKey = 'fileId'

// modifiable fields only.
export interface ICTSFXFile extends Pick<GQLSFXFile, 'fileId' | 'link'> {
  // more fields, mapped (if needed)
}

export interface ISFXFileListableOption extends ODListableOption {}
