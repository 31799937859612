"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBlock = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const __1 = require("../../../..");
const changeOp_1 = require("../../../../changeOp");
const IStudioClipboard_1 = require("../../clipboard/IStudioClipboard");
const storeUtils_1 = require("../../utils/storeUtils");
const validation_1 = require("../../validation");
const IFlowChartPositionCalculator_1 = require("../chapter/IFlowChartPositionCalculator");
const statement_1 = require("../statement");
const DOScriptStatementFactory_1 = require("../statement/DOScriptStatementFactory");
const HbDoBlockHelper_1 = require("../statement/hbHelper/HbDoBlockHelper");
const StudioScriptHelper_1 = require("../statement/StudioScriptHelper");
const BlockEditableUtils_1 = require("../ui/BlockEditableUtils");
const IBlockEditable_1 = require("../ui/IBlockEditable");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
/**
 * Block domain object.
 */
class DOBlock {
    constructor(store, data, parentChapter, intrinsicOrder, uniqueId) {
        var _a, _b;
        this.statements = [];
        // readonly data: BLOCK
        // 블록의 첫번째 구문의 background 를 저장해 둔다.
        this.startingBackground = '';
        this.lastValidationResults = [];
        // 헬봇일때 사용할 메시지 순서 변경에 따른 로딩
        this.isChangingOrder = false;
        this.inputButtonsToHide = [];
        const di = store.rootStore.di;
        this.store = store;
        this.hbHelper = new HbDoBlockHelper_1.HbDoBlockHelper();
        this.parentChapter = parentChapter;
        this._intrinsicOrder = intrinsicOrder;
        this.uniqueId = uniqueId !== null && uniqueId !== void 0 ? uniqueId : `${data.name}_${di.generateInternalHashId()}`;
        if (data.hasOwnProperty('uniqueId')) {
            const b = data;
            this.statements = b.statements
                .map(statementBundle => (0, DOScriptStatementFactory_1.deserializeBundleStatement)(statementBundle, this))
                .filter(v => v);
            this.startingBackground = b.startingBackground;
            this.name = b.name;
        }
        else {
            const d = data;
            this.startingBackground = (_b = (_a = d.statements[0]) === null || _a === void 0 ? void 0 : _a.background) !== null && _b !== void 0 ? _b : '';
            this.statements = (0, DOScriptStatementFactory_1.reduceStatementsToDomainObjects)(d.statements, this, customId => (parentChapter.customId = customId), false);
            this.statements.forEach(st => st.fixStatements());
            this.name = d.name;
        }
        // hbExtensionData가 있을 때 추가. 헬로우봇 용 데이터
        if ('hbExtensionData' in data) {
            this.hbExtensionData = data.hbExtensionData;
            if ('id' in data.hbExtensionData) {
                this.uniqueId = data.hbExtensionData.id.toString();
            }
        }
        this.statements.unshift(new statement_1.DOBlockHeadStatement(this));
        this.helper = new StudioScriptHelper_1.StudioScriptHelper(this);
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            statements: mobx_1.observable,
            lastValidationResults: mobx_1.observable,
            startingBackground: mobx_1.observable,
            isChangingOrder: mobx_1.observable,
            isStartingBlock: mobx_1.computed,
            id: mobx_1.computed,
            blocksFrom: mobx_1.computed,
            blocksTo: mobx_1.computed,
            friendlyName: mobx_1.computed,
            blockTagType: mobx_1.computed,
            isChapterEndingBlock: mobx_1.computed,
            isStoryEndingBlock: mobx_1.computed,
            blockTypeForSheet: mobx_1.computed,
            isRemovable: mobx_1.computed,
            isEditing: mobx_1.computed,
            selectedLineIndex: mobx_1.computed,
            intrinsicOrder: mobx_1.computed,
            containsPublishedEnding: mobx_1.computed,
            nodeName: mobx_1.computed,
            nodeNamesFrom: mobx_1.computed,
            nodesTo: mobx_1.computed,
            // 이 블록이 포함하는 알고리즘 메시지들
            algorithmMessages: mobx_1.computed,
            hasAlgorithmMessage: mobx_1.computed,
            // 헬봇의 블록연결에서 다른 블록그룹의 블록을 연결하고 있을때 해당 블록들의 배열
            flowChartNodesOfOtherGroupBlock: mobx_1.computed,
            hasFlowChartNodesOfOtherGroupBlock: mobx_1.computed,
        });
    }
    merge(data) {
        (0, mobx_1.runInAction)(() => {
            (0, storeUtils_1.assignIf)(data, 'name', v => (this.name = v));
        });
        return this;
    }
    get id() {
        return this.name;
    }
    get isStartingBlock() {
        return this.parentChapter.startingBlockId === this.id;
    }
    get isChapterEndingBlock() {
        return !!this.statements.find(v => v instanceof statement_1.DOSTEndingSummary);
    }
    get isStoryEndingBlock() {
        return !!this.statements.find(v => v instanceof statement_1.DOSTFinalEndingSubBlock);
    }
    get intrinsicOrder() {
        if (this.isStartingBlock) {
            return -Infinity;
        }
        return this._intrinsicOrder;
    }
    /**
     * 삭제 가능한 블록인가?
     */
    get isRemovable() {
        return !this.isStartingBlock;
    }
    /**
     * 이 블록이 엔딩블록이면서, 디폴트 엔딩인가?
     */
    get isDefaultEndingBlock() {
        var _a;
        const block = this.statements.find(v => v instanceof statement_1.DOSTEndingSummary || v instanceof statement_1.DOSTFinalEndingSubBlock);
        return (_a = block === null || block === void 0 ? void 0 : block.isDefaultEnding) !== null && _a !== void 0 ? _a : false;
    }
    // Block 안의 마지막 Statement 를 가져온다.
    // statement 의 endBlockType 을 변경 시 만약 타입이 같다면 변경시키지 않기 위함
    get lastStatementInBlock() {
        const lastIndex = this.statements.length - 1;
        return this.statements[lastIndex];
    }
    /**
     * 이 블록을 대표하는 이름을 만들어 반환한다.
     */
    get friendlyName() {
        if (this.isStartingBlock) {
            return trans('legacy_DOBlock_start_block');
        }
        return this.id;
    }
    get blockTagType() {
        if (this.parentChapter.startingBlockId === this.id) {
            return statement_1.BlockTagType.Start;
        }
        for (let i = this.statements.length - 1; i >= 0; i -= 1) {
            const st = this.statements[i];
            if (st.lineType === __1.UILineType.EditorLine) {
                continue;
            }
            // 아래 분기문의 원래 의도는 블록에서 존재하는 서브블록들의 태그를 따라가도록 하기 위한 것
            // 하지만 챗봇블록(원격 블록)에 해당하는 DOSTCallRemoteScriptSubBlock 의 경우에는
            // 본인이 IBlockEditable 로 활용되는 경우에 사용하기 위한 태그이므로,
            // 여기선 챗봇 블록으로 처리되면 안된다.
            const type = st.blockTagType;
            if (type !== statement_1.BlockTagType.NoTag && type !== statement_1.BlockTagType.ChatBotBlock) {
                return type;
            }
        }
        return statement_1.BlockTagType.NoTag;
    }
    /** 해당 블록은 현재 Editing 상태 인지 */
    get isEditing() {
        const blockEditor = this.parentChapter.blockEditor;
        return !!(blockEditor && blockEditor.lineBlockEditing === this);
    }
    /** 현재 블록에디터에서 선택중인 라인의 인덱스를 반환한다. */
    get selectedLineIndex() {
        const blockEditor = this.parentChapter.blockEditor;
        if (!this.isEditing) {
            return -1;
        }
        const statements = this.statements.slice();
        return statements.findIndex(item => item === (blockEditor === null || blockEditor === void 0 ? void 0 : blockEditor.selectedLine));
    }
    /** 파라미터로 받은 라인이 EndBlockType 인지 */
    isEndBlockTypeOf(line) {
        return !!(line === null || line === void 0 ? void 0 : line.endBlockType);
    }
    /** 파라미터로 받은 라인이 DOBlockHeadStatement 타입인지 */
    isHeadTypeOf(line) {
        return line instanceof statement_1.DOBlockHeadStatement;
    }
    canChangeOrderUp(index) {
        const statements = this.statements.slice();
        // 인덱스를 찾지 못하였거나, 블록 헤드와 순서를 바꾸려 하거나, 선택된 라인이 Head 타입이면 false
        if (index < 0 ||
            this.isHeadTypeOf(statements[index - 1]) ||
            this.isHeadTypeOf(statements[index])) {
            return false;
        }
        // 마지막 구문은 순서 변경할 수 없다.
        return !this.isEndBlockTypeOf(statements[index]);
    }
    canChangeOrderDown(index) {
        const statements = this.statements.slice();
        // 인덱스를 찾지 못하였거나, 마지막 구문과 순서를 바꾸려 하거나, 선택된 라인이 Head 타입이면 false
        if (index < 0 ||
            this.isEndBlockTypeOf(statements[index + 1]) ||
            this.isHeadTypeOf(statements[index])) {
            return false;
        }
        // 마지막 구문은 순서 변경할 수 없다.
        return !this.isEndBlockTypeOf(statements[index]);
    }
    changeOrderOf(line, isUp) {
        if (this.isChangingOrder) {
            return;
        }
        if (this.rootStore.serviceType === 'hb') {
            (0, mobx_1.runInAction)(() => {
                this.isChangingOrder = true;
            });
        }
        (0, BlockEditableUtils_1.changeOrderOf)(this, line, isUp, this.rootStore).finally(() => {
            (0, mobx_1.runInAction)(() => {
                this.isChangingOrder = false;
            });
        });
    }
    /** 파라미터로 받아온 라인의 순서를 위로 올린다. */
    changeOrderUpOf(line) {
        const index = this.statements.indexOf(line);
        if (!this.canChangeOrderUp(index)) {
            return;
        }
        this.changeOrderOf(line, true);
    }
    /** 파라미터로 받아온 라인의 순서를 아래로 내린다. */
    changeOrderDownOf(line) {
        const index = this.statements.indexOf(line);
        if (!this.canChangeOrderDown(index)) {
            return;
        }
        this.changeOrderOf(line, false);
    }
    addStatementAtIndex(line, indexToAdd) {
        this.parentChapter
            .applyChangesOnChapter({
            opType: changeOp_1.StudioChangeOpType.AddLine,
            target: changeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId: this.uniqueId,
            lineToAdd: line,
            indexToAdd,
        })
            .catch(); // ignore returned promise.
    }
    async addStatementAtIndexInternal(line, indexToAdd) {
        if (this.rootStore.serviceType === 'hb') {
            const isSuccess = await this.hbHelper.addStatementAtIndexInternal(line.parentBlock, line, indexToAdd);
            if (!isSuccess) {
                return null;
            }
        }
        (0, mobx_1.runInAction)(() => {
            this.statements.splice(indexToAdd, 0, line);
        });
        return {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.RemoveLine,
                target: changeOp_1.StudioChangeOpTarget.Block,
                blockUniqueId: this.uniqueId,
                lineToRemove: line,
            },
            lineToFocus: line,
        };
    }
    pushStatement(line) {
        (0, mobx_1.runInAction)(() => {
            this.statements.push(line);
        });
    }
    /**
     * 이 블록에서 주어진 라인을 삭제한다.
     * @param line 삭제할 라인
     */
    removeLine(line) {
        this.parentChapter
            .applyChangesOnChapter({
            opType: changeOp_1.StudioChangeOpType.RemoveLine,
            target: changeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId: this.uniqueId,
            lineToRemove: line,
        })
            .catch();
    }
    /**
     * 이 블록에서 주어진 라인을 실제로 삭제하며, 반환값은 이를 다시 추가하는 op
     * @param line 삭제할 라인
     */
    removeLineInternal(line) {
        if (this.rootStore.serviceType === 'hb') {
            const isSuccess = this.hbHelper.removeLineInternal(line);
            if (!isSuccess) {
                return null;
            }
        }
        let retOp = null;
        (0, mobx_1.runInAction)(() => {
            const index = this.statements.findIndex(v => v === line);
            const blockEditor = this.parentChapter.blockEditor;
            if (blockEditor &&
                blockEditor.lineBlockEditing === this &&
                blockEditor.lineEditingIndex >= index) {
                blockEditor.lineEditingIndex = Math.max(0, blockEditor.lineEditingIndex - 1);
            }
            this.statements.splice(index, 1);
            const nextLine = this.statements[Math.max(0, index - 1)];
            if (line.uniqueId) {
                retOp = {
                    reverse: {
                        opType: changeOp_1.StudioChangeOpType.AddLine,
                        target: changeOp_1.StudioChangeOpTarget.Block,
                        blockUniqueId: this.uniqueId,
                        indexToAdd: index,
                        lineToAdd: line, // uniqueId 존재여부로 판단.
                    },
                    lineToFocus: nextLine,
                };
            }
        });
        return retOp;
    }
    /**
     * 한 번에 여러개의 문장들을 제거한다.
     * @param linesUniqueIds
     * @private
     */
    async removeLinesInternal(linesUniqueIds) {
        const { removes, lastNonRemovedLine } = this.rootStore.serviceType === 'hb'
            ? await (0, __1.removeHbStatementsInternal)(this, linesUniqueIds)
            : (0, storeUtils_1.removeStatementsInternal)(this, linesUniqueIds);
        return {
            reverse: changeOp_1.StudioChangeOpFactory.addLinesToBlock(this.uniqueId, removes.reverse() // 여러줄을 한 번에 추가하고 삭제하는 경우 인덱스 문제
            ),
            lineToFocus: lastNonRemovedLine,
        };
    }
    async addLinesInternal(lines) {
        var _a;
        let ids = null;
        if (this.rootStore.serviceType === 'hb') {
            ids = await (0, __1.addHbStatementsInternal)(this, this, lines);
        }
        if (this.rootStore.serviceType === 'sp') {
            ids = (0, storeUtils_1.addStatementsInternal)(this, this, lines);
        }
        if (!ids) {
            return null;
        }
        return {
            reverse: changeOp_1.StudioChangeOpFactory.removeLinesFromBlock(this.uniqueId, ids),
            lineToFocus: (_a = (0, lodash_1.last)(lines)) === null || _a === void 0 ? void 0 : _a.line,
        };
    }
    changeName(name) {
        if (this.name === name) {
            return null;
        }
        const blocks = this.parentChapter.blockStore.all.filter(v => v.name === name);
        if (blocks.length > 0) {
            throw new __1.SPCError(__1.ErrorCode.BLOCK_NAME_EXISTS, { name });
        }
        this.parentChapter
            .applyChangesOnChapter({
            opType: changeOp_1.StudioChangeOpType.ChangeName,
            target: changeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId: this.uniqueId,
            name,
        })
            .catch();
    }
    changeNameInternal(name) {
        let prevName = '';
        (0, mobx_1.runInAction)(() => {
            prevName = this.name;
            this.name = name;
            this.parentChapter.onBlockNameChanged(prevName, name);
            this.parentChapter.blockStore.all.forEach(block => {
                block.onBlockNameChanged(prevName, name);
            });
            this.parentChapter.blockStore.onBlockNameChanged(prevName, name);
        });
        const retOp = {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.ChangeName,
                target: changeOp_1.StudioChangeOpTarget.Block,
                blockUniqueId: this.uniqueId,
                name: prevName,
            },
            lineToFocus: this.statements[0],
        };
        return retOp;
    }
    /**
     * 종료 블록을 주어진 블록타입으로 추가/변경한다.
     */
    upsertNewEndBlock(endBlockType, openAfterChange = false) {
        this.parentChapter
            .applyChangesOnChapter(changeOp_1.StudioChangeOpFactory.startBulk(this.parentChapter)
            .upsertNewEndBlock(this.uniqueId, endBlockType)
            .finishBulk())
            .then(() => {
            var _a, _b;
            if (openAfterChange) {
                (_b = (_a = (0, lodash_1.last)(this.statements)) === null || _a === void 0 ? void 0 : _a.startEditing) === null || _b === void 0 ? void 0 : _b.call(_a);
            }
        });
    }
    /**
     * 이 블록을 삭제한다.
     */
    removeBlock(confirmOption = __1.BlockRemoveConfirmOption.NoConfirm) {
        if (!this.isRemovable) {
            return;
        }
        const editor = this.parentChapter.blockEditor;
        if (confirmOption === __1.BlockRemoveConfirmOption.Always ||
            confirmOption === __1.BlockRemoveConfirmOption.IfHasPublishedEnding) {
            const containsPublishedEnding = !!this.statements.find(v => v.isPublishedEnding);
            if (containsPublishedEnding) {
                editor === null || editor === void 0 ? void 0 : editor.setShowDeleteModalFor({
                    title: trans('legacy_DOBlock_delete_blocks_with_endings'),
                    bodyText: trans('legacy_DOBlock_check_to_delete'),
                    onDelete: () => {
                        this.parentChapter.removeBlock(this);
                        editor === null || editor === void 0 ? void 0 : editor.setShowDeleteModalFor(null);
                    },
                    onClose: () => {
                        editor === null || editor === void 0 ? void 0 : editor.setShowDeleteModalFor(null);
                    },
                    requiresCheckboxConfirm: true,
                    requiresCheckboxConfirmText: trans('legacy_DOBlock_really_delete_blocks'),
                });
                return;
            }
            if (confirmOption === __1.BlockRemoveConfirmOption.Always) {
                editor === null || editor === void 0 ? void 0 : editor.setShowDeleteModalFor({
                    title: trans('legacy_DOBlock_delete_block'),
                    bodyText: trans('legacy_DOBlock_delete_selected_block'),
                    onDelete: () => {
                        this.parentChapter.removeBlock(this);
                        editor === null || editor === void 0 ? void 0 : editor.setShowDeleteModalFor(null);
                    },
                    onClose: () => {
                        editor === null || editor === void 0 ? void 0 : editor.setShowDeleteModalFor(null);
                    },
                });
                return;
            }
        }
        this.parentChapter.removeBlock(this);
    }
    /**
     * 이 챕터에서 특정 블록이 삭제되는 경우 호출된다.
     * changeOp 에는 블록의 삭제 때문에 발생하는 변경점들을 담아준다.
     */
    onBlockRemoved(blockId, changeOp) {
        this.statements.forEach(st => st.onBlockRemoved(blockId, changeOp));
    }
    onBlockNameChanged(prevName, newName) {
        this.statements.forEach(st => {
            st.onBlockNameChanged(prevName, newName);
        });
    }
    get cmdString() {
        return this.statements.map(st => st.cmdString).join('\n');
    }
    validate() {
        const blockResults = [];
        const lastSt = (0, lodash_1.last)(this.statements);
        if (!lastSt || !lastSt.endBlockType) {
            blockResults.push({
                type: validation_1.StudioValidationType.BlockHasNoFinish,
                extra: {},
                severity: validation_1.StudioValidationSeverity.Error,
                source: this,
                stack: [],
            });
        }
        if (this.startingBackground === '' ||
            this.startingBackground === __1.BG_EMPTY) {
            blockResults.push({
                type: validation_1.StudioValidationType.NoBackgroundImage,
                extra: {},
                severity: validation_1.StudioValidationSeverity.Recommend,
                source: this,
                stack: [],
            });
        }
        if (this.name !== this.name.trim()) {
            blockResults.push({
                type: validation_1.StudioValidationType.BlockNameShouldBeTrimmed,
                extra: {},
                severity: validation_1.StudioValidationSeverity.Error,
                source: this,
                stack: [],
                fixable: async () => {
                    this.changeName(this.name.trim());
                },
            });
        }
        const results = [
            ...(0, lodash_1.flatten)(this.statements.map(st => st.validate())),
            ...blockResults,
        ];
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    clearValidationResults() {
        (0, mobx_1.runInAction)(() => (this.lastValidationResults.length = 0));
    }
    get validatorName() {
        return `[Block] ${this.name}`;
    }
    generateLines() {
        return (0, lodash_1.flatten)(this.statements.map(v => v.generateLines()));
    }
    /**
     * 주어진 statement 의 background 를 반환한다.
     */
    getBackgroundOfStatement(statementOrIndex) {
        return (0, BlockEditableUtils_1.getBackgroundOfStatement)(this, statementOrIndex);
    }
    /**
     * 블록의 시작 배경이미지를 변경한다.
     */
    changeStartingBackground(backgroundName) {
        new changeOp_1.StudioChangeOpFactory(this.parentChapter)
            .changeBlockStartingBackground(this.uniqueId, backgroundName)
            .submitSingle()
            .catch();
    }
    get blockTypeForSheet() {
        if (this.isStartingBlock) {
            return '시작 블록';
        }
        else if (this.isChapterEndingBlock || this.isStoryEndingBlock) {
            return '엔딩 블록';
        }
        return '일반 블록';
    }
    blockColumns() {
        return ['', this.id, this.blockTypeForSheet];
    }
    containsEndingId(endingId) {
        return !!this.statements.find(v => {
            if (v instanceof statement_1.DOSTEndingSummary) {
                return v.endingId === endingId;
            }
            else if (v instanceof statement_1.DOSTFinalEndingSubBlock) {
                return v.finalEnding.endingId === endingId;
            }
            return false;
        });
    }
    exportMetaDataUpdateActions() {
        return (0, lodash_1.flatten)(this.statements.map(s => s.exportMetaDataUpdateActions()));
    }
    getMySubBlock(statement) {
        return this.statements.find(v => {
            if (v instanceof __1.DOBaseScriptSubBlock) {
                return !!v.statements.find(st => st === statement);
            }
            return false;
        });
    }
    getAllBackgrounds() {
        return (0, lodash_1.uniq)(this.statements.map(v => v.background)).filter(v => v !== __1.BG_EMPTY);
    }
    get story() {
        return this.parentChapter.getStory();
    }
    /**
     * 이 블록의 end block 이 존재하는가?
     */
    checkIfEndBlockExists() {
        const lastSt = (0, lodash_1.last)(this.statements);
        // noinspection PointlessBooleanExpressionJS
        return !!(lastSt && !!(lastSt === null || lastSt === void 0 ? void 0 : lastSt.endBlockType));
    }
    async onPasteEvent(data) {
        return (0, BlockEditableUtils_1.handleBlockEditablePasteEvent)(this, data);
    }
    onCopyEvent() {
        this.store.rootStore.di.showMessage(trans('legacy_DOBlock_block_copied'));
        return {
            isStudio: true,
            dataType: IStudioClipboard_1.StudioClipboardDataType.Block,
            name: this.name,
            startingBackground: this.startingBackground,
            lines: (0, lodash_1.flatten)(this.statements
                .filter(v => v.lineType !== __1.UILineType.EditorLine)
                .map(s => s.lineType === __1.UILineType.SingleLineScript
                ? [s.data]
                : s.statements.map(ss => ss.data))),
        };
    }
    onCutEvent() {
        if (!this.isRemovable) {
            return this.onCopyEvent();
        }
        const data = this.onCopyEvent();
        this.removeBlock();
        return data;
    }
    // TODO: 헬봇일떄만 api 호출 실패 처리를 위해 여기서 aop 제거 후 이식.
    async applyChangeOp(op, type) {
        var _a;
        if (op.target !== changeOp_1.StudioChangeOpTarget.Block ||
            op.blockUniqueId !== this.uniqueId) {
            return null;
        }
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.AddLine: {
                return this.addStatementAtIndexInternal(op.lineToAdd, op.indexToAdd);
            }
            case changeOp_1.StudioChangeOpType.RemoveLine: {
                return this.removeLineInternal(op.lineToRemove);
            }
            case changeOp_1.StudioChangeOpType.ChangeName: {
                return this.changeNameInternal(op.name);
            }
            case changeOp_1.StudioChangeOpType.RemoveLines: {
                return this.removeLinesInternal(op.lineUniqueIds);
            }
            case changeOp_1.StudioChangeOpType.AddLines: {
                return this.addLinesInternal(op.lines);
            }
            case changeOp_1.StudioChangeOpType.ChangeBlockStartingBackground: {
                return this.changeStartingBackgroundInternal(op);
            }
        }
        // line target op.
        if (op.lineUniqueId) {
            // 해당 line, or sub-block 을 검색
            const line = this.statements.find(v => {
                return (v.uniqueId === op.lineUniqueId ||
                    (v instanceof __1.DOBaseScriptSubBlock &&
                        v.statements.find(s => s.uniqueId === op.lineUniqueId)));
            });
            return (_a = line === null || line === void 0 ? void 0 : line.applyChangeOp(op, type)) !== null && _a !== void 0 ? _a : null;
        }
        return null;
    }
    get snapshot() {
        return {
            id: this.id,
            uniqueId: this.uniqueId,
            statements: this.statements.map(s => s.snapshot),
            background: this.startingBackground,
        };
    }
    get scriptSimpleTypes() {
        return this.statements
            .filter(s => s.isScript)
            .map(s => s instanceof __1.DOBaseScriptSubBlock
            ? s.subBlockType.toString()
            : s.statementType.toString());
    }
    changeStartingBackgroundInternal(op) {
        const background = this.startingBackground;
        (0, mobx_1.runInAction)(() => (this.startingBackground = op.background));
        return {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.ChangeBlockStartingBackground,
                background,
                blockUniqueId: this.uniqueId,
                target: changeOp_1.StudioChangeOpTarget.Block,
            },
            lineToFocus: this.statements[0],
        };
    }
    onAllBlocksOfChapterLoaded() {
        this.statements.forEach(st => st.onAllBlocksOfChapterLoaded());
    }
    serializeToBundle() {
        return {
            uniqueId: this.uniqueId,
            name: this.name,
            intrinsicOrder: this.intrinsicOrder,
            startingBackground: this.startingBackground,
            statements: (0, lodash_1.flatten)(this.statements.map(v => {
                if (v instanceof __1.DOBaseScriptSubBlock ||
                    v instanceof __1.DOBaseScriptStatement) {
                    return v.serializeToBundle();
                }
                return [];
            })),
        };
    }
    getStatementBefore(statement) {
        const index = this.statements.findIndex(st => st === statement);
        if (index < 1) {
            return null;
        }
        return this.statements[index - 1];
    }
    getStatementAfter(statement) {
        const index = this.statements.findIndex(st => st === statement);
        if (index < 0 || index >= this.statements.length - 1) {
            return null;
        }
        return this.statements[index + 1];
    }
    replaceStatement(oldSt, newSt) {
        const foundIndex = this.statements.findIndex(st => st === oldSt);
        if (foundIndex < 0) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.statements[foundIndex] = newSt;
        });
    }
    //
    // IStudioFinderSource
    //
    get finderSourceName() {
        return `[Block] ${this.name}`;
    }
    getFindResults(keyword) {
        return (0, lodash_1.flatten)(this.statements.map(st => st.getFindResults(keyword)));
    }
    replaceTextWith(text, to) {
        this.statements.forEach(st => st.replaceTextWith(text, to));
    }
    selectBySearch() {
        // 블록이 직접 선택될 일은 없다.
    }
    get containsPublishedEnding() {
        return !!this.statements.find(v => v.isPublishedEnding);
    }
    //
    // IFlowChartNode
    //
    get nodeName() {
        return this.name;
    }
    get nodeType() {
        return IFlowChartPositionCalculator_1.FlowChartNodeType.Block;
    }
    shouldComeBeforeOnFlowChart(otherBlock) {
        if (!!this.intrinsicOrder && !!otherBlock.intrinsicOrder) {
            return this.intrinsicOrder > otherBlock.intrinsicOrder;
        }
        return true;
    }
    get nodeNamesFrom() {
        return this.blocksFrom.map(item => item.nodeName);
    }
    get nodesTo() {
        const blocksTo = this.blocksTo.map(item => ({
            label: item.label,
            extra: item.extra,
            nodeName: item.block.nodeName,
        }));
        // 알고리즘 블록이 있는 경우 edge 가 추가되어야 한다.
        if (this.hasAlgorithmMessage) {
            blocksTo.push({
                label: '',
                extra: this.algorithmMessages[0],
                nodeName: (0, __1.getNameForAlgorithmBlock)(this.name),
            });
        }
        return blocksTo;
    }
    get blocksTo() {
        const [remoteSubBlock, remaining] = (0, lodash_1.partition)(this.statements, st => st.nodeType === IFlowChartPositionCalculator_1.FlowChartNodeType.RemoteScriptSubBlock);
        // 원격 스크립트 서브블록에서의 연결선은 빼고 계산한다.
        const arrTo = (0, lodash_1.flatten)(remaining.map(st => st.blocksTo));
        // 이후 원격 스크립트 서브블록으로 연결선을 추가한다.
        remoteSubBlock.forEach(item => {
            const sb = item; // is DOSTCallRemoteScriptSubBlock
            arrTo.push({
                block: sb,
                extra: {},
                label: trans('legacy_DOBlock_remote_script_call'),
            });
        });
        return arrTo;
    }
    get blocksFrom() {
        return this.store.allNode.filter(b => {
            const asBlock = b;
            return asBlock.blocksTo.find(v => {
                return v.block.uniqueId === this.uniqueId;
            });
        });
    }
    get blockEditableType() {
        return IBlockEditable_1.BlockEditableType.NormalBlock;
    }
    get canChangeName() {
        return true;
    }
    get doBlock() {
        return this;
    }
    get rootStore() {
        return this.store.rootStore;
    }
    get canUpsertNewEndBlock() {
        return true;
    }
    canContainStatement(statement) {
        return true;
    }
    getLineIndexToStartOnInit() {
        // 맨 뒤쪽에서 시작하기
        return this.statements.length - 1;
    }
    updateHbExtensionData(hbExtensionData) {
        this.hbExtensionData = hbExtensionData;
    }
    /**
     * 이 블록이 포함하는 모든 알고리즘 메시지 문장을 반환한다.
     */
    get algorithmMessages() {
        // DOSTAlgorithm 메시지만 나온다.
        return this.statements.filter(f => f.lineType === __1.UILineType.SingleLineScript &&
            f.statementType === core_1.STATEMENT_TYPE.Algorithm);
    }
    get hasAlgorithmMessage() {
        return this.algorithmMessages.length > 0;
    }
    get flowChartNodesOfOtherGroupBlock() {
        var _a;
        const find = this.statements.find(f => f.lineType === __1.UILineType.SingleLineScript &&
            (f.statementType === core_1.STATEMENT_TYPE.HbMessageNext ||
                f.statementType === core_1.STATEMENT_TYPE.HbUserSelect ||
                f.statementType === core_1.STATEMENT_TYPE.HbUserTemplate ||
                f.statementType === core_1.STATEMENT_TYPE.HbUserInput ||
                f.statementType === core_1.STATEMENT_TYPE.HbUserFeedBack ||
                f.statementType === core_1.STATEMENT_TYPE.HbUserTarot));
        return (_a = find === null || find === void 0 ? void 0 : find.flowChartNodesOfOtherGroupBlock) !== null && _a !== void 0 ? _a : [];
    }
    get hasFlowChartNodesOfOtherGroupBlock() {
        return this.flowChartNodesOfOtherGroupBlock.length > 0;
    }
}
exports.DOBlock = DOBlock;
