import classNames from 'classnames'
import moment from 'moment'
import { useFormikContext } from 'formik'
import { ReactNodeLike } from 'prop-types'
import React from 'react'
import { InputAdapter, TextMask } from 'react-text-mask-hoc'
import { FormFeedback, Input } from 'reactstrap'
import { InputType } from 'reactstrap/lib/Input'
import { InputWithIcon } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import { ODIcons } from '../../ODIcon'
import { ODFBuilder } from '../ODEntityEditorUIBuilder'
import { useStringResource } from '../ODResources/ResourceProvider'
import { ODF_DATETIME, ODF_TEXT, ODF_TEXTAREA, ODF_TYPE } from './index'

type ODFTextProps = Omit<ODF_TEXT, 'size' | 'type'> & {
  required?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  useValidChecker?: boolean
  inputType?: InputType
  autoComplete?: string
  icon?: ODIcons
  iconAppend?: boolean // 뒤에 붙일 것인가?
  children?: ReactNodeLike
  prependChildren?: boolean
  appendChildren?: boolean
  mask?: Array<string | RegExp>
  inputProps?: object
  iconProps?: object
  options?: ReactNodeLike
}

export function ODFText(props: ODFTextProps) {
  const {
    formKey,
    placeholder,
    inputType,
    autoComplete,
    icon,
    iconAppend,
    required,
    readOnly,
    autoFocus,
    useValidChecker,
    appendChildren,
    prependChildren,
    children,
    mask,
    inputProps = {},
    iconProps = {},
    options,
  } = props

  const formikContext = useFormikContext()
  const { getString } = useStringResource()

  if (!formikContext) {
    return null
  }

  const { errors, touched, handleChange, handleBlur, values, isSubmitting } = formikContext

  // @ts-ignore
  const rawValue = values[formKey]
  const value =
    inputType === 'datetime-local' ? (rawValue ? moment(rawValue).format('YYYY-MM-DDTHH:mm') : '') : rawValue

  // @ts-ignore
  const error = errors[formKey]
  // @ts-ignore
  const touchedElement = !!touched[formKey]

  const inputItem = (() => {
    if (mask) {
      return (
        <InputWithIcon icon={icon} iconAppend={iconAppend} iconProps={iconProps}>
          <TextMask
            id={`${formKey}-input`}
            name={formKey}
            mask={mask}
            Component={InputAdapter}
            className={classNames('form-control', { 'is-invalid': touchedElement && !!error })}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            disabled={isSubmitting}
            {...inputProps}
          />
          {error && <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback>}
        </InputWithIcon>
      )
    }

    return (
      <InputWithIcon icon={icon} iconAppend={iconAppend} iconProps={iconProps}>
        <Input
          readOnly={readOnly}
          type={inputType}
          id={`${formKey}-input`}
          name={formKey}
          placeholder={placeholder ? getString(placeholder) : ''}
          autoComplete={autoComplete}
          valid={useValidChecker && touchedElement && !error}
          invalid={touchedElement && !!error}
          autoFocus={!!autoFocus}
          required={!!required}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          disabled={isSubmitting}
          {...inputProps}
        >
          {options}
        </Input>
        {error && <FormFeedback>{error}</FormFeedback>}
      </InputWithIcon>
    )
  })()

  return (
    <>
      {children && (
        <div style={{ display: 'flex', width: '100%' }}>
          {prependChildren && children}
          {inputItem}
          {appendChildren && children}
        </div>
      )}
      {!children && inputItem}
    </>
  )
}

export function createODFText(key: string, data: Omit<ODF_TEXT, 'type'>) {
  return new ODFBuilder(key, { type: ODF_TYPE.TEXT, ...data })
}

export function createODFTextArea(key: string, data: Omit<ODF_TEXTAREA, 'type'>) {
  return new ODFBuilder(key, { type: ODF_TYPE.TEXTAREA, ...data })
}

export function createODFDateTime(key: string, data: Omit<ODF_DATETIME, 'type'>) {
  return new ODFBuilder(key, { type: ODF_TYPE.DATETIME, ...data })
}
