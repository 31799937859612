"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbMenuTagAPI = void 0;
const hbApiUtils_1 = require("../hbApiUtils");
class HbMenuTagAPI {
    constructor(server) {
        this.server = server;
    }
    async create(name) {
        return this.server.post('/v1/menu-tag', { name });
    }
    async list(pageSize, pageIndex, filter) {
        return this.server.get(`/v1/menu-tag?${(0, hbApiUtils_1.createQueryParams)({
            pageSize,
            pageIndex,
            filter,
        })}`);
    }
}
exports.HbMenuTagAPI = HbMenuTagAPI;
// {
//     botId,
//     pageSize:100,
//     sort:'userCount',
//     sortDirection:'desc'
//     pageIndex:0
// }
