"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlockGroupIdInFlowChartNodeName = exports.getBlockNameInFlowChartNodeName = exports.getNameForOtherBlockGroup = void 0;
/**
 * 블록연결에서 다른 블록 그룹의 블록을 연결하고 있을 경우에 해당 함수로 이름을 생성
 */
function getNameForOtherBlockGroup(parentBlockName, blockGroupId) {
    return `${parentBlockName}-other-block-node-${blockGroupId}`;
}
exports.getNameForOtherBlockGroup = getNameForOtherBlockGroup;
/**
 * 플로우차트의 노드 이름(가장 위의 함수)에서 블록 이름만 추출한다.
 */
function getBlockNameInFlowChartNodeName(flowChartNodeName) {
    return flowChartNodeName.split('-other')[0];
}
exports.getBlockNameInFlowChartNodeName = getBlockNameInFlowChartNodeName;
/**
 * 플로우차트의 노드 이름(가장 위의 함수)에서 블록 그룹 아이디를 추출한다.
 */
function getBlockGroupIdInFlowChartNodeName(flowChartNodeName) {
    return flowChartNodeName.substring(flowChartNodeName.lastIndexOf('-') + 1);
}
exports.getBlockGroupIdInFlowChartNodeName = getBlockGroupIdInFlowChartNodeName;
