"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpDoChapterStoreStrategy = void 0;
const baseDoChapterStoreStrategy_1 = require("./baseDoChapterStoreStrategy");
// 원본 클래스인 챕터스토어에는 DO접두어가 없으나 실수로 보인다.
class SpDoChapterStoreStrategy extends baseDoChapterStoreStrategy_1.BaseDoChapterStoreStrategy {
    constructor() {
        super(...arguments);
        // this is a dummy value
        this.initialPageSortRequest = {
            page: {
                index: 0,
                size: 20,
            },
            sort: {
                order: 'asc',
                key: '',
            },
        };
    }
    // 스플이나 헬로봇의 일반 블록그룹은 페이지네이션 로딩을 하지 않으므로 처리할 필요가 없다.
    async loadPaginatedChapters(pageSortRequest) {
        throw new Error('Not implemented yet.');
    }
    // 스플이나 헬로봇의 일반 블록그룹은 페이지네이션 로딩을 하지 않으므로 처리할 필요가 없다.
    async loadPaginatedAlgorithmBlock(groupId, paginationConfig) {
        throw new Error('Not implemented yet.');
    }
}
exports.SpDoChapterStoreStrategy = SpDoChapterStoreStrategy;
