"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbSkillEditor = void 0;
const mobx_1 = require("mobx");
const moment = require("moment");
const Yup = require("yup");
const studioUrls_1 = require("../../studioUrls");
const HbEntityEditor_1 = require("../HbEntityEditor");
const ui_1 = require("../ui");
const DateInput_1 = require("../ui/DateInput");
class HbSkillEditor extends HbEntityEditor_1.HbEntityEditor {
    constructor(skill, rootStore, botId) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
        super(skill, rootStore);
        this.previewImages = [];
        this.botId = botId;
        this.iconImageField = new ui_1.FileInput('file', '아이콘 이미지', !!(skill === null || skill === void 0 ? void 0 : skill.imageUrl) ? { link: skill.imageUrl } : null, {
            theme: ui_1.FileTheme.HbSkillIcon,
            accept: 'image/png, image/jpeg, image/gif',
            required: true,
            showLabel: false,
            aspectRatio: {
                w: 1,
                h: 1,
            },
        });
        this.titleField = new ui_1.InputWithValidation('title', '제목', '제목을 입력해 주세요.', 'text', (_a = skill === null || skill === void 0 ? void 0 : skill.title) !== null && _a !== void 0 ? _a : '', Yup.string().min(1).required(), {
            noLabel: true,
        }).setRequired(true);
        this.subTextField = new ui_1.InputWithValidation('tag', '서브 텍스트', '서브 텍스트를 입력해 주세요.', 'text', (_b = skill === null || skill === void 0 ? void 0 : skill.tag) !== null && _b !== void 0 ? _b : '', null, {
            noLabel: true,
        });
        this.linkTagField = new ui_1.MultiSelectionInput('linkTag.tagIds', '태그', '내용을 입력해 주세요.', !!((_c = skill === null || skill === void 0 ? void 0 : skill.linkTag) === null || _c === void 0 ? void 0 : _c.tags) && skill.linkTag.tags.length > 0
            ? skill.linkTag.tags.map(tag => {
                var _a, _b;
                return ({
                    name: (_a = tag.name) !== null && _a !== void 0 ? _a : '-',
                    value: (_b = tag.id) !== null && _b !== void 0 ? _b : -1,
                });
            })
            : [], [], {
            onLoadMoreOptions: async (pageIndex, inputText) => {
                var _a, _b;
                try {
                    const res = await this.apiServer.menuTag.list(30, pageIndex, !!inputText.trim() ? inputText.trim() : undefined);
                    return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                        var _a, _b;
                        return ({
                            name: (_a = item.name) !== null && _a !== void 0 ? _a : '-',
                            value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                        });
                    })) !== null && _b !== void 0 ? _b : []);
                }
                catch (ex) {
                    this.rootStore.showError(ex);
                    return [];
                }
            },
            onCreate: async (inputText) => {
                await this.apiServer.menuTag.create(inputText);
            },
            createSuffix: '태그 만들기',
            pageSizeForOptions: 30,
            pageIndexForOptions: 0,
            required: true,
            theme: 'primary',
            validate: () => {
                return (this.linkTagField.value.length >= 2 &&
                    this.linkTagField.value.length < 6);
            },
        });
        this.referenceField = new ui_1.InputWithValidation('reference', '출처', '출처를 입력해 주세요.', 'text', (_d = skill === null || skill === void 0 ? void 0 : skill.reference) !== null && _d !== void 0 ? _d : '', null, {
            noLabel: true,
        });
        this.textCountField = new ui_1.InputWithValidation('textCount', '글자 수', '0', 'text', (_f = (_e = skill === null || skill === void 0 ? void 0 : skill.textCount) === null || _e === void 0 ? void 0 : _e.toString()) !== null && _f !== void 0 ? _f : '', Yup.mixed().test(this.isEmptyOrPositiveIntegerTestObj.name, this.isEmptyOrPositiveIntegerTestObj.desc, this.isEmptyOrPositiveIntegerTestObj.test), {
            noLabel: true,
            maxLength: 7,
        });
        this.htmlContentField = new ui_1.InputWithValidation('htmlContent', '상세 정보', '내용을 입력해주세요.', 'text', (_g = skill === null || skill === void 0 ? void 0 : skill.htmlContent) !== null && _g !== void 0 ? _g : '', Yup.string().min(1).required()).setRequired(true);
        this.typeField = new ui_1.SelectionInput('type', '연결유형', (_h = skill === null || skill === void 0 ? void 0 : skill.type) !== null && _h !== void 0 ? _h : 'block', [
            { name: '블록', value: 'block' },
            { name: '링크', value: 'url' },
            { name: '이메일', value: 'email' },
        ], {
            uiType: 'radio',
            onChangeBeforeSubmit: v => {
                if (v === 'block') {
                    this.setDisabledPriceInfoFields(false);
                    this.blockIdField.onChangeDisabled(false);
                    this.linkUrlField.setEditable(false);
                    this.emailField.setEditable(false);
                    return;
                }
                if (v === 'url') {
                    this.setDisabledPriceInfoFields(true);
                    this.linkUrlField.setEditable(true);
                    this.blockIdField.onChangeDisabled(true);
                    this.emailField.setEditable(false);
                    return;
                }
                if (v === 'email') {
                    this.setDisabledPriceInfoFields(true);
                    this.emailField.setEditable(true);
                    this.linkUrlField.setEditable(false);
                    this.blockIdField.onChangeDisabled(false);
                    return;
                }
            },
        }).setRequired(true);
        this.blockIdField = new ui_1.MultiSelectionInput('blockId', '시작블록', '내용을 입력해 주세요.', !!(skill === null || skill === void 0 ? void 0 : skill.blockId)
            ? [{ value: skill.blockId, name: (_j = skill.blockTitle) !== null && _j !== void 0 ? _j : '-' }]
            : [], [], {
            onLoadMoreOptions: async (pageIndex, inputText) => {
                var _a, _b;
                try {
                    const res = await this.rootStore.di.server.hbClient.block.getListBlock(this.botId, pageIndex, 30, !!inputText.trim() ? inputText.trim() : undefined);
                    return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                        var _a, _b, _c, _d;
                        return ({
                            name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                            value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                            category: (_d = (_c = item.groupName) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '-',
                        });
                    })) !== null && _b !== void 0 ? _b : []);
                }
                catch (ex) {
                    this.rootStore.showError(ex);
                    return [];
                }
            },
            pageSizeForOptions: 30,
            pageIndexForOptions: 0,
            required: true,
            isSingleValue: true,
            disabled: this.typeField.value !== 'block',
        });
        this.linkUrlField = new ui_1.InputWithValidation('linkUrl', '링크', '링크를 입력해주세요.', 'text', (_k = skill === null || skill === void 0 ? void 0 : skill.linkUrl) !== null && _k !== void 0 ? _k : '', Yup.string().min(1).required(), {
            noLabel: true,
            editable: this.typeField.value === 'url',
        }).setRequired(true);
        this.emailField = new ui_1.InputWithValidation('email', '이메일', '이메일을 입력해주세요.', 'text', (_l = skill === null || skill === void 0 ? void 0 : skill.email) !== null && _l !== void 0 ? _l : '', Yup.string().email().min(1).required(), {
            noLabel: true,
            editable: this.typeField.value === 'email',
        }).setRequired(true);
        this.isShowAdField = new ui_1.SelectionInput('isShowAd', '시작 광고', (_m = skill === null || skill === void 0 ? void 0 : skill.isShowAd) !== null && _m !== void 0 ? _m : false, [
            { name: '제공', value: true },
            { name: '미제공', value: false },
        ], {
            uiType: 'radio',
        }).setRequired(true);
        this.coinField = new ui_1.InputWithValidation('coin', '하트코인', '0', 'text', (_p = (_o = skill === null || skill === void 0 ? void 0 : skill.coin) === null || _o === void 0 ? void 0 : _o.toString()) !== null && _p !== void 0 ? _p : '0', Yup.number().min(0).required(), {
            noLabel: true,
            onChange: v => {
                if (isNaN(Number(v)) || !Number(v)) {
                    this.coinAmountField.onChange('');
                    this.setDisabledDiscountPriceInfoFields(true);
                    return;
                }
                this.setDisabledDiscountPriceInfoFields(false);
                this.coinAmountField.onChange((Number(v) * 300).toString());
            },
        }).setRequired(true);
        this.coinAmountField = new ui_1.InputWithValidation('coinAmount', '원화', '0', 'text', (_r = (_q = skill === null || skill === void 0 ? void 0 : skill.coinAmount) === null || _q === void 0 ? void 0 : _q.toString()) !== null && _r !== void 0 ? _r : '0', Yup.number().min(0).required(), {
            noLabel: true,
            onChange: v => {
                if (!isNaN(Number(v)) && Number(v) === 0) {
                    this.discountCoinAmountField.setEditable(false);
                    return;
                }
                this.discountCoinAmountField.setEditable(true);
            },
        }).setRequired(true);
        this.discountCoinField = new ui_1.InputWithValidation('discountCoin', '하트코인', '0', 'text', (_t = (_s = skill === null || skill === void 0 ? void 0 : skill.discountCoin) === null || _s === void 0 ? void 0 : _s.toString()) !== null && _t !== void 0 ? _t : '', Yup.mixed().test(this.isEmptyOrPositiveIntegerTestObj.name, this.isEmptyOrPositiveIntegerTestObj.desc, value => {
            const isValid = this.isEmptyOrPositiveIntegerTestObj.test(value);
            if (!isValid) {
                return false;
            }
            return (Number(this.discountCoinField.value) < Number(this.coinField.value));
        }), {
            noLabel: true,
            onChange: v => {
                if (isNaN(Number(v)) || !Number(v)) {
                    this.setDisabledDiscountPriceInfoOptionFields(true);
                    this.discountCoinAmountField.onChange('');
                    return;
                }
                this.setDisabledDiscountPriceInfoOptionFields(false);
                this.discountCoinAmountField.onChange((Number(v) * 300).toString());
            },
        });
        this.discountCoinAmountField = new ui_1.InputWithValidation('discountCoinAmount', '원화', '0', 'text', (_v = (_u = skill === null || skill === void 0 ? void 0 : skill.discountCoinAmount) === null || _u === void 0 ? void 0 : _u.toString()) !== null && _v !== void 0 ? _v : '', Yup.mixed().test(this.isEmptyOrPositiveIntegerTestObj.name, this.isEmptyOrPositiveIntegerTestObj.desc, value => {
            const isValid = this.isEmptyOrPositiveIntegerTestObj.test(value);
            if (!isValid) {
                return false;
            }
            return (Number(this.discountCoinAmountField.value) <
                Number(this.coinAmountField.value));
        }), {
            noLabel: true,
        });
        this.coinField.inputType = 'number';
        this.coinAmountField.inputType = 'number';
        this.discountCoinField.inputType = 'number';
        this.discountCoinAmountField.inputType = 'number';
        this.discountBlockIdField = new ui_1.MultiSelectionInput('discountBlockId', '할인 중 연결 블록', '할인 중 연결 블록을 선택해주세요.', !!(skill === null || skill === void 0 ? void 0 : skill.discountBlockId)
            ? [
                {
                    value: skill.discountBlockId,
                    name: (_w = skill.discountBlockTitle) !== null && _w !== void 0 ? _w : '-',
                },
            ]
            : [], [], {
            onLoadMoreOptions: async (pageIndex, inputText) => {
                var _a, _b;
                try {
                    const res = await this.rootStore.di.server.hbClient.block.getListBlock(this.botId, pageIndex, 30, !!inputText.trim() ? inputText.trim() : undefined);
                    return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                        var _a, _b, _c, _d;
                        return ({
                            name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                            value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                            category: (_d = (_c = item.groupName) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '-',
                            description: `${item.groupId}_${item.groupName}`,
                        });
                    })) !== null && _b !== void 0 ? _b : []);
                }
                catch (ex) {
                    this.rootStore.showError(ex);
                    return [];
                }
            },
            pageSizeForOptions: 30,
            pageIndexForOptions: 0,
            required: true,
            isSingleValue: true,
        });
        this.discountStartDateField = new DateInput_1.DateInput('discountStartDate', '할인 시작일시', '할인 시작일시를 선택해주세요.', (_y = (_x = this._target) === null || _x === void 0 ? void 0 : _x.discountStartDate) !== null && _y !== void 0 ? _y : '', {});
        this.discountEndDateField = new DateInput_1.DateInput('discountEndDate', '할인 종료일시', '할인 종료일시를 선택해주세요.', (_0 = (_z = this._target) === null || _z === void 0 ? void 0 : _z.discountEndDate) !== null && _0 !== void 0 ? _0 : '', {});
        this.isOpenField = new ui_1.SelectionInput('isOpen', '헬로우봇 노출여부', (_1 = skill === null || skill === void 0 ? void 0 : skill.isOpen) !== null && _1 !== void 0 ? _1 : true, [
            { name: '노출', value: true },
            { name: '미노출', value: false },
        ], {
            uiType: 'radio',
        }).setRequired(true);
        this.openDateField = new DateInput_1.DateInput('openDate', '헬로우봇 오픈일시', '헬로우봇 오픈일시를 선택해주세요.', (_3 = (_2 = this._target) === null || _2 === void 0 ? void 0 : _2.openDate) !== null && _3 !== void 0 ? _3 : '', {});
        this.isOpenWebField = new ui_1.SelectionInput('isOpenWeb', '스킬스토어 노출여부', (_4 = skill === null || skill === void 0 ? void 0 : skill.isOpenWeb) !== null && _4 !== void 0 ? _4 : true, [
            { name: '노출', value: true },
            { name: '미노출', value: false },
        ], {
            uiType: 'radio',
        }).setRequired(true);
        this.openDateWebField = new DateInput_1.DateInput('openDateWeb', '스킬스토어 오픈일시', '스킬스토어 오픈일시를 선택해주세요.', (_6 = (_5 = this._target) === null || _5 === void 0 ? void 0 : _5.openDateWeb) !== null && _6 !== void 0 ? _6 : '', {});
        this.newSkillBannerImageField = new ui_1.FileInput('newSkillBannerImage', '새 스킬 배너 이미지', !!(skill === null || skill === void 0 ? void 0 : skill.newSkillBannerImageUrl)
            ? { link: skill.newSkillBannerImageUrl }
            : null, {
            showLabel: false,
            accept: 'image/png, image/jpeg, image/gif',
            aspectRatio: {
                w: 4,
                h: 3,
            },
        });
        this.setDisabledPriceInfoFields(this.typeField.value !== 'block');
        this.setDisabledDiscountPriceInfoFields(!Number(this.coinField.value));
        this.createDefFieldValues();
        this.loadPreviewImages();
        (0, mobx_1.makeObservable)(this, {
            previewImages: mobx_1.observable,
        });
    }
    async remove() {
        var _a;
        if (!((_a = this._target) === null || _a === void 0 ? void 0 : _a.id)) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isSubmitting = true;
        });
        try {
            await this.apiServer.menu.deleteMenu(this._target.id);
            (0, mobx_1.runInAction)(() => {
                this.isSubmitting = false;
            });
            this.rootStore.showMessage('삭제되었습니다.');
            this.rootStore.di.redirectToUrl(studioUrls_1.HbStudioUrls.Bot.Detail.Skill.List(this.botId));
        }
        catch (ex) {
            this.rootStore.showError(ex);
            (0, mobx_1.runInAction)(() => {
                this.isSubmitting = false;
            });
        }
    }
    async loadPreviewImages() {
        var _a;
        const skillId = (_a = this._target) === null || _a === void 0 ? void 0 : _a.id;
        if (!skillId || this.status === 'create') {
            return;
        }
        try {
            const res = await this.apiServer.menu.getPreviewImages(skillId);
            (0, mobx_1.runInAction)(() => {
                var _a;
                this.previewImages = (_a = res.images) !== null && _a !== void 0 ? _a : [];
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    async changePreviewImageIndex(id, toTurn) {
        try {
            const res = await this.apiServer.menu.changePreviewImage({
                previewImageId: id,
                turn: toTurn,
            });
            (0, mobx_1.runInAction)(() => {
                this.previewImages = this.previewImages.filter(i => i.id !== res.id);
                this.previewImages.splice(toTurn - 1, 0, res);
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    async deletePreviewImage(id) {
        (0, mobx_1.runInAction)(() => {
            this.isSubmitting = true;
        });
        try {
            const res = await this.apiServer.menu.deletePreviewImage(id);
            (0, mobx_1.runInAction)(() => {
                this.previewImages = this.previewImages.filter(i => i.id !== res.id);
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isSubmitting = false;
            });
        }
    }
    async changePreviewImageFile(id, file) {
        if (file.size > ui_1.MAX_IMAGE_SIZE) {
            this.rootStore.showError('1MB 이하 용량 파일을 선택해주세요', '1MB 이하 용량 파일을 선택해주세요');
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isSubmitting = true;
        });
        try {
            const res = await this.apiServer.menu.changePreviewImage({
                previewImageId: id,
                image: file,
            });
            (0, mobx_1.runInAction)(() => {
                this.previewImages = this.previewImages.map(i => i.id === res.id ? res : i);
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isSubmitting = false;
            });
        }
    }
    async createPreviewImageFiles(files) {
        var _a;
        if (!((_a = this._target) === null || _a === void 0 ? void 0 : _a.id)) {
            return;
        }
        if (files.findIndex(f => f.size > ui_1.MAX_IMAGE_SIZE) !== -1) {
            this.rootStore.showError('1MB 이하 용량 파일을 선택해주세요', '1MB 이하 용량 파일을 선택해주세요');
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isSubmitting = true;
        });
        try {
            const res = await this.apiServer.menu.createPreviewImages(this._target.id, files);
            (0, mobx_1.runInAction)(() => {
                this.previewImages = [...this.previewImages, ...res];
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isSubmitting = false;
            });
        }
    }
    setDisabledPriceInfoFields(disabled) {
        this.coinField.setEditable(!disabled);
        this.coinAmountField.setEditable(!disabled);
        this.setDisabledDiscountPriceInfoFields(disabled);
    }
    setDisabledDiscountPriceInfoFields(disabled) {
        this.discountCoinField.setEditable(!disabled);
        this.discountCoinAmountField.setEditable(!disabled);
        this.setDisabledDiscountPriceInfoOptionFields(!Number(this.discountCoinField.value));
    }
    setDisabledDiscountPriceInfoOptionFields(disabled) {
        this.discountBlockIdField.onChangeDisabled(disabled);
        this.discountStartDateField.onChangeDisabled(disabled);
        this.discountEndDateField.onChangeDisabled(disabled);
    }
    async submit(onError) {
        if (!this.isChanged()) {
            this.rootStore.showError('변경된 값이 없습니다.');
            return;
        }
        try {
            (0, mobx_1.runInAction)(() => {
                this.isSubmitting = true;
            });
            if (!this.editorFields
                .map(item => {
                return item.getIsValid(item.value);
            })
                .every(v => v)) {
                if (this.newSkillBannerImageField.isSizeError) {
                    throw new Error('새 스킬 배너 이미지를 1MB 이하 용량 파일로 선택해주세요');
                }
                else if (this.iconImageField.isSizeError) {
                    throw new Error('아이콘 이미지를 1MB 이하 용량 파일로 선택해주세요');
                }
                else {
                    throw new Error('입력하지 않은 필드가 있습니다.');
                }
            }
            await this.onSaveImages();
        }
        catch (ex) {
            this.rootStore.showError(ex);
            return;
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isSubmitting = false;
            });
        }
        const onSubmit = async (cs) => {
            var _a;
            // 아래의 필드들이 falsy 한 값으로 온다면 보내지 않는다.
            const falsyAsUndefined = [
                'discountCoin',
                'discountCoinAmount',
                'discountStartDate',
                'discountEndDate',
                'newSkillBannerImage',
                'file',
            ];
            const input = {};
            for (const [key, value] of Object.entries(cs)) {
                if (falsyAsUndefined.includes(key)) {
                    if (!value) {
                        continue;
                    }
                }
                input[key] = value;
            }
            if (this.status === 'create') {
                await this.apiServer.menu.menuCreate({
                    botId: this.botId,
                    ...input,
                    kind: 'clickableMenu',
                });
                this.rootStore.di.redirectToUrl(studioUrls_1.HbStudioUrls.Bot.Detail.Skill.List(this.botId));
                return;
            }
            if (!((_a = this._target) === null || _a === void 0 ? void 0 : _a.id)) {
                return;
            }
            await this.apiServer.menu.menuUpdate(this._target.id, input);
        };
        return this.submitInternal(onSubmit, onError);
    }
    async onSaveImages() {
        const base64Images = this.getBase64Images(this.htmlContentField.value);
        if (base64Images.length > 0) {
            await this.imagesUploadAndReplace(base64Images);
        }
    }
    async imagesUploadAndReplace(base64Images) {
        const files = [];
        for (const base of base64Images) {
            const r = await this.base64ToFile(base);
            files.push(r);
        }
        const res = await this.apiServer.media.createItems(files, false);
        const links = res.map(item => { var _a; return (_a = item.imageUrl) !== null && _a !== void 0 ? _a : ''; });
        this.htmlContentField.onChange(this.replaceBase64WithUrls(this.htmlContentField.value, base64Images, links));
    }
    getBase64Images(content) {
        const regex = /<img[^>]+src="data:image\/([^";]+);base64,([^">]+)"[^>]*>/g;
        const matches = content.matchAll(regex);
        const base64Images = [];
        for (const match of matches) {
            const [, format, data] = match;
            base64Images.push(`data:image/${format};base64,${data}`);
        }
        return base64Images;
    }
    replaceBase64WithUrls(content, base64Images, imageUrls) {
        let processedContent = content;
        for (let i = 0; i < base64Images.length; i++) {
            const imageUrl = imageUrls[i];
            processedContent = processedContent.replace(base64Images[i], imageUrl);
        }
        return processedContent;
    }
    get isEmptyOrPositiveIntegerTestObj() {
        return {
            name: 'isEmptyOrPositiveInteger',
            desc: '빈 문자열이거나 0 이상의 정수만 허용',
            test: (v) => {
                if (v === '') {
                    return true;
                }
                return !isNaN(Number(v)) && Number(v) >= 0 && Number(v) <= 1000000;
            },
        };
    }
    async base64ToFile(base64) {
        var _a, _b, _c;
        const arr = base64.split(',');
        const mime = (_c = (_b = (_a = arr[0]) === null || _a === void 0 ? void 0 : _a.match(/:(.*?);/)) === null || _b === void 0 ? void 0 : _b[1]) !== null && _c !== void 0 ? _c : 'image/png';
        const blob = await (await fetch(base64)).blob();
        return new File([blob], `${moment().format('YYYY.MM.DD HH:mm:ss')}-image-on-studio`, {
            type: mime,
        });
        // const arr = base64.split(',')
        // const mime = arr[0]?.match(/:(.*?);/)?.[1] ?? 'image/png'
        // const bstr = atob(arr[1])
        // let n = bstr.length
        // const u8arr = new Uint8Array(n)
        //
        // while (n--) {
        //   u8arr[n] = bstr.charCodeAt(n)
        // }
        //
        // return new File([u8arr], `test_uploaded_image_file_on_studio`, {
        //   type: mime,
        // })
    }
}
exports.HbSkillEditor = HbSkillEditor;
