"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockStore = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const statement_1 = require("../statement");
const DOBlock_1 = require("./DOBlock");
class BlockStore {
    constructor(rootStore, parentChapter) {
        this.intrinsicOrder = 1;
        this.rootStore = rootStore;
        this.data = {};
        this.parentChapter = parentChapter;
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            mergeBundle: mobx_1.action,
            merge: mobx_1.action,
            all: mobx_1.computed,
            allNode: mobx_1.computed,
        });
    }
    init() {
        //
    }
    getById(blockId) {
        return this.data[blockId];
    }
    /**
     * 신규로 정의된 uniqueId 를 이용하여 찾는다. 추후 getById 를 대체해야 하나, 너무 오랜기간동안
     * id = name 으로 활용되어 추후 리팩토링이 필요하다.
     */
    getByUniqueId(blockUniqueId) {
        return this.all.find(v => v.uniqueId === blockUniqueId);
    }
    merge(data, chapter, uniqueId) {
        const id = data.name;
        if (this.data[id]) {
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOBlock_1.DOBlock(this, data, chapter, this.intrinsicOrder++, uniqueId);
        }
        return this.data[id];
    }
    mergeBundle(bundle, chapter) {
        const { name } = bundle;
        const b = new DOBlock_1.DOBlock(this, bundle, chapter, bundle.intrinsicOrder, bundle.uniqueId);
        this.data[name] = b;
        return b;
    }
    mergeBlock(data) {
        (0, mobx_1.runInAction)(() => (this.data[data.id] = data));
        return data;
    }
    get all() {
        return Object.values(this.data).sort((a, d) => a.intrinsicOrder - d.intrinsicOrder);
    }
    get allNode() {
        const all = this.all.map(item => {
            var _a;
            if (((_a = item.lastStatementInBlock) === null || _a === void 0 ? void 0 : _a.blockTagType) === statement_1.BlockTagType.ChatBotBlock) {
                return [item, item.lastStatementInBlock];
            }
            // 알고리즘 메시지를 포함하는 경우 1개의 알고리즘 블록 연결 분기도 Node 가 나와야 한다.
            // 정확하게 만들자면 상당한 설계 변화가 필요하기에, 첫번째 알고리즘 메시지를 Node 처럼 처리한다.
            if (item.hasAlgorithmMessage) {
                const algo = item
                    .algorithmMessages[0];
                return [item, algo];
            }
            if (item.hasFlowChartNodesOfOtherGroupBlock) {
                return [item, ...item.flowChartNodesOfOtherGroupBlock];
            }
            return item;
        });
        return (0, lodash_1.flatten)(all);
    }
    get startingNodeName() {
        return this.parentChapter.startingBlock.name;
    }
    onBlockNameChanged(prevName, name) {
        (0, mobx_1.runInAction)(() => {
            const block = this.data[prevName];
            if (block) {
                delete this.data[prevName];
                this.data[name] = block;
            }
        });
    }
    /**
     * 특정 블록을 제거합니다. 이와 관련된 후속 처리는 chapter 에서 수행합니다.
     */
    removeBlock(name) {
        (0, mobx_1.runInAction)(() => {
            delete this.data[name];
        });
    }
    getHbMessageNextStatements() {
        const res = [];
        this.all.forEach(block => {
            block.statements.forEach(st => {
                if (st.lineType === statement_1.UILineType.SingleLineScript &&
                    st.statementType === core_1.STATEMENT_TYPE.HbMessageNext) {
                    res.push(st);
                }
            });
        });
        return res;
    }
    /**
     * 헬로봇의 블록을 제거합니다.
     */
    async removeHbBlock(id) {
        var _a;
        try {
            const res = await this.rootStore.di.server.hbClient.block.delete(id);
            this.removeBlock((_a = res.title) !== null && _a !== void 0 ? _a : '');
            const hbMessageNextSts = this.getHbMessageNextStatements();
            hbMessageNextSts.forEach(st => st.removeHbBlocksBy(id));
            this.parentChapter.reLayoutFlowChartElements();
            return res;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            return null;
        }
    }
    getNewBlockId(desiredName) {
        let index = 0;
        let name = desiredName;
        do {
            if (!this.getById(name)) {
                return name;
            }
            index += 1;
            name = `${desiredName}_${index}`;
        } while (true);
    }
}
exports.BlockStore = BlockStore;
