import moment from 'moment'
import React from 'react'
import Select from 'react-select'
import { Col, Input, Row } from 'reactstrap'
import { GQLChapter } from '../../../@types/server'
import { ODButton, ODButtonTheme } from '../../../components/base/ODButton'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { ODModal, ODModalSize } from '../../../components/ODModal/ODModal'
import { ODModalBody } from '../../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../../components/ODModal/ODModalHeader'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { Utils } from '../../../utils/utils'
import { FreeSettingType, getFreeSettingType } from './FreeSettingUtils'

interface IFreeSettingModalProps {
  chapter: GQLChapter
  storyId: number
  onCancel: () => void
  onUpdate: (
    chapterId: number,
    freeSettingType: FreeSettingType,
    freeSetting: IFreeSetting
  ) => Promise<any>
}

export interface IFreeSetting {
  freedAt: string | null
  willFreeAt: string | null
  freeDependencyChapterIndex: number | null
  freeDependencyPeriod: number | null
}

const FREE_SETTING_OPTIONS = [
  { value: FreeSettingType.NoFree, label: '무료 설정 없음' },
  { value: FreeSettingType.ByDate, label: '날짜별 무료 설정' },
  { value: FreeSettingType.WaitAndFree, label: '기스무 (기다리면 무료)' },
]

interface IChapterOption {
  label: string
  value: Pick<GQLChapter, 'chapterId' | 'chapterIndex'>
}

const DATETIME_LOCAL_FMT = 'YYYY-MM-DDTHH:mm:ss'

export const FreeSettingModal: React.FC<IFreeSettingModalProps> = (props) => {
  const { chapter, storyId, onCancel, onUpdate } = props

  const [submitting, setSubmitting] = React.useState(false)
  const [freeSettingType, setFreeSettingType] = React.useState<FreeSettingType>(
    () => getFreeSettingType(chapter)
  )
  const [freeSetting, setFreeSetting] = React.useState<IFreeSetting>(() => {
    const initialSetting: IFreeSetting = {
      freedAt: chapter.freedAtRaw,
      willFreeAt: chapter.willFreeAtRaw ?? chapter.freedAtRaw,
      freeDependencyChapterIndex: chapter.freeDependencyChapterIndex,
      freeDependencyPeriod: chapter.freeDependencyPeriod,
    }
    return initialSetting
  })

  const [periodHour, setPeriodHour] = React.useState(() => {
    return Math.floor((freeSetting.freeDependencyPeriod ?? 0) / (60 * 60))
  })
  const [periodMin, setPeriodMin] = React.useState(() => {
    return Math.floor(
      ((freeSetting.freeDependencyPeriod ?? 0) - periodHour * 60 * 60) / 60
    )
  })

  const { listChapterOfStory } = useStoryPlayAPIs()

  const [chapterOptions, setChapterOptions] = React.useState<
    IChapterOption[] | null
  >(null) // null = loading

  React.useEffect(() => {
    listChapterOfStory({
      page: 1,
      pageSize: 1000,
      storyId,
      filter: null,
      sortOption: null,
    })
      .then((res) => {
        setChapterOptions(
          res.list
            .map((c) => ({
              label: `[${c.chapterIndex}화] ${c.name}`,
              value: c,
            }))
            .filter((v) => v.value.chapterId !== chapter.chapterId)
        )
      })
      .catch((ex) => {
        Utils.showError(ex)
        onCancel()
      })
  }, [
    onCancel,
    setChapterOptions,
    listChapterOfStory,
    storyId,
    chapter.chapterId,
  ])

  const handleCancel = () => {
    if (submitting) {
      return
    }
    onCancel()
  }

  const handleSave = async () => {
    if (submitting) {
      return
    }
    setSubmitting(true)

    try {
      // check validity and call onUpdate() with correct data.
      switch (freeSettingType) {
        case FreeSettingType.ByDate:
          if (!freeSetting.willFreeAt) {
            Utils.showError('무료 설정 시각이 올바르지 않습니다.')
            return
          }

          await onUpdate(chapter.chapterId, freeSettingType, {
            freedAt: null,
            willFreeAt: freeSetting.willFreeAt,
            freeDependencyPeriod: null,
            freeDependencyChapterIndex: null,
          })
          break
        case FreeSettingType.NoFree:
          await onUpdate(chapter.chapterId, freeSettingType, {
            freedAt: null,
            willFreeAt: null,
            freeDependencyPeriod: null,
            freeDependencyChapterIndex: null,
          })
          break
        case FreeSettingType.WaitAndFree:
          const period = (periodHour * 60 + periodMin) * 60
          if (isNaN(period) || period <= 0) {
            Utils.showError('무료 설정 시각이 올바르지 않습니다.')
            return
          }

          if (freeSetting.freeDependencyChapterIndex === null) {
            Utils.showError('챕터 설정이 올바르지 않습니다.')
            return
          }

          await onUpdate(chapter.chapterId, freeSettingType, {
            freedAt: null,
            willFreeAt: null,
            freeDependencyPeriod: period,
            freeDependencyChapterIndex: freeSetting.freeDependencyChapterIndex!,
          })
          break
      }
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <ODModal isOpen toggle={handleCancel} size={ODModalSize.XLarge}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader
          title="무료 설정 변경"
          onClose={handleCancel}
          headerBottomPadding={30}
        >
          <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 60 }} />
        </ODModalHeader>
        <ODModalBody>
          <ModalRow title="설정 챕터 정보">
            <div style={{ marginBottom: 10 }}>
              {chapter.chapterIndex}화 {chapter.name} (ID: {chapter.chapterId},
              CustomID: {chapter.customId})
            </div>
          </ModalRow>
          <ModalRow title="무료 종류">
            <Select
              value={
                FREE_SETTING_OPTIONS.find((v) => v.value === freeSettingType)!
              }
              options={FREE_SETTING_OPTIONS}
              placeholder="무료 종류"
              isMulti={false}
              onChange={(v) => {
                // @ts-ignore
                const selectedFreeSettingType = v.value as FreeSettingType
                setFreeSettingType(selectedFreeSettingType)

                if (
                  selectedFreeSettingType === FreeSettingType.WaitAndFree &&
                  freeSetting.freeDependencyChapterIndex === null &&
                  chapterOptions
                ) {
                  const prevChapter = chapterOptions.find(
                    (v) => v.value.chapterIndex === chapter.chapterIndex - 1
                  )
                  if (prevChapter) {
                    setFreeSetting((s) => {
                      return {
                        ...s,
                        freeDependencyChapterIndex:
                          prevChapter.value.chapterIndex,
                        freeDependencyPeriod: 24 * 60 * 60,
                      }
                    })
                  }
                }
              }}
            />
          </ModalRow>
          {freeSettingType === FreeSettingType.ByDate && (
            <ModalRow title="무료 설정 시각">
              <Input
                type="datetime-local"
                value={
                  freeSetting.willFreeAt
                    ? moment(freeSetting.willFreeAt).format(DATETIME_LOCAL_FMT)
                    : undefined
                }
                step={1}
                onChange={(v: any) => {
                  const { value } = v.target
                  setFreeSetting((s) => {
                    return {
                      ...s,
                      willFreeAt: moment(
                        value,
                        DATETIME_LOCAL_FMT
                      ).toISOString(),
                    }
                  })
                }}
              />
              <div style={{ padding: 10 }}>
                과거 시간으로 설정될 경우 바로 무료로 변경되며, 미래 시각으로
                설정되는 경우 현재 무료라도 유료로 변경됩니다.
              </div>
            </ModalRow>
          )}
          {freeSettingType === FreeSettingType.WaitAndFree &&
            !chapterOptions && (
              <ModalRow title="어떤 챕터 종료 후">로딩 중입니다.</ModalRow>
            )}
          {freeSettingType === FreeSettingType.WaitAndFree && chapterOptions && (
            <>
              <ModalRow title="어떤 챕터 종료 후">
                <Select
                  value={chapterOptions.find(
                    (v) =>
                      freeSetting.freeDependencyChapterIndex ===
                      v.value.chapterIndex
                  )}
                  options={chapterOptions}
                  placeholder="챕터를 선택하세요."
                  isMulti={false}
                  onChange={(v: any) => {
                    setFreeSetting((s) => {
                      return {
                        ...s,
                        freeDependencyChapterIndex: v.value.chapterIndex,
                      }
                    })
                  }}
                />
              </ModalRow>
              <ModalRow title="얼마 뒤에 무료가 되는가?">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    type="number"
                    style={{ maxWidth: 150 }}
                    value={periodHour}
                    onChange={(v) => {
                      setPeriodHour(v.target.valueAsNumber)
                    }}
                  />
                  &nbsp;&nbsp;시간&nbsp;
                  <Input
                    type="number"
                    style={{ maxWidth: 150 }}
                    value={periodMin}
                    onChange={(v) => {
                      setPeriodMin(v.target.valueAsNumber)
                    }}
                  />
                  &nbsp;&nbsp;분&nbsp;
                </div>
              </ModalRow>
            </>
          )}
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            style={{ width: '35%' }}
            theme={ODButtonTheme.Cancel}
            onClick={handleCancel}
          >
            취소
          </ODButton>
          <ODButton
            style={{ width: '65%' }}
            theme={ODButtonTheme.Primary}
            onClick={handleSave}
          >
            저장
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

interface IModalRowProps {
  title: string
}

const ModalRow: React.FC<IModalRowProps> = (props) => {
  const { title, children } = props
  return (
    <Row style={{ marginBottom: 10 }}>
      <Col md={3} style={{ fontWeight: 'bold' }}>
        {title}
      </Col>
      <Col md={9}>{children}</Col>
    </Row>
  )
}
