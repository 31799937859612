"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GA4_EVENT_NAME = exports.StudioTutorialCategory = void 0;
var StudioTutorialCategory;
(function (StudioTutorialCategory) {
    StudioTutorialCategory["Basic"] = "Basic";
    StudioTutorialCategory["BlockAndChoice"] = "BlockAndChoice";
    StudioTutorialCategory["PropAndItemAndSoOn"] = "PropAndItemAndSoOn";
    StudioTutorialCategory["DirectionsThatCanBeMade"] = "DirectionsThatCanBeMade";
    StudioTutorialCategory["PreparingForPublication"] = "PreparingForPublication";
    StudioTutorialCategory["AdvancedDirectionFunctions"] = "AdvancedDirectionFunctions";
    StudioTutorialCategory["AdvancedFunctions"] = "AdvancedFunctions";
})(StudioTutorialCategory = exports.StudioTutorialCategory || (exports.StudioTutorialCategory = {}));
// 최초의 이벤트 이름 같은 경우는
// 기본 이벤트 이름 앞에 FIRST_ 를 붙여서 사용
// 현재는 tutorial 의 완료되는 시점에 최초 수행이다. 즉 튜토리얼이 완료되는 시점에
// 기본 이벤트 이름 앞에 FIRST_ 를 붙여 event 를 전송한다.
var GA4_EVENT_NAME;
(function (GA4_EVENT_NAME) {
    // 새 작품 만들어 보기
    GA4_EVENT_NAME["CREATE_NEW_STORY"] = "CREATE_NEW_STORY";
    GA4_EVENT_NAME["ENTER_CHAPTER_EDIT_PAGE"] = "ENTER_CHAPTER_EDIT_PAGE";
    GA4_EVENT_NAME["INPUT_LINES"] = "INPUT_LINES";
    GA4_EVENT_NAME["CREATE_CHARACTER"] = "CREATE_CHARACTER";
    GA4_EVENT_NAME["CREATE_DEFAULT_CHAPTER_ENDING"] = "CREATE_DEFAULT_CHAPTER_ENDING";
    GA4_EVENT_NAME["SAVE_CHAPTER"] = "SAVE_CHAPTER";
    GA4_EVENT_NAME["DO_INSTANT_TEST"] = "DO_INSTANT_TEST";
    // 블록과 선택지
    GA4_EVENT_NAME["CONNECT_SCENE"] = "CONNECT_SCENE";
    GA4_EVENT_NAME["USING_TEXT_CHOICE"] = "USING_TEXT_CHOICE";
    GA4_EVENT_NAME["USING_IMAGE_CHOICE"] = "USING_IMAGE_CHOICE";
    GA4_EVENT_NAME["USING_USER_INPUT_CHOICE"] = "USING_USER_INPUT_CHOICE";
    GA4_EVENT_NAME["USING_STATS_CHOICE"] = "USING_STATS_CHOICE";
    // 인터렉티브한 스토리 만들기 2
    GA4_EVENT_NAME["SET_PROP"] = "SET_PROP";
    GA4_EVENT_NAME["SET_ITEM"] = "SET_ITEM";
    GA4_EVENT_NAME["DO_ROUTE_OF_CONDITION"] = "DO_ROUTE_OF_CONDITION";
    // 스플에서 할 수 있는 연출들
    GA4_EVENT_NAME["SET_CHARACTER"] = "SET_CHARACTER";
    GA4_EVENT_NAME["SET_BACKGROUND_IMAGE"] = "SET_BACKGROUND_IMAGE";
    GA4_EVENT_NAME["SET_SOUND_EFFECT"] = "SET_SOUND_EFFECT";
    GA4_EVENT_NAME["SET_BGM"] = "SET_BGM";
    // 작품 발간 준비하기
    GA4_EVENT_NAME["SET_CHAPTER_LIST"] = "EDIT_CHAPTER_LIST";
    GA4_EVENT_NAME["EDIT_STORY_INFORMATION"] = "EDIT_STORY_INFORMATION";
    GA4_EVENT_NAME["PUBLISH_CHAPTER"] = "PUBLISH_CHAPTER";
    // 고급 연출 기능들
    GA4_EVENT_NAME["CHANGE_TEXT_COLOR_OF_LINES"] = "CHANGE_TEXT_COLOR_OF_LINES";
    GA4_EVENT_NAME["DECORATE_SPEECH_BUBBLE"] = "DECORATE_SPEECH_BUBBLE";
    GA4_EVENT_NAME["SET_TTS"] = "SET_TTS";
    // 고급 기능
    GA4_EVENT_NAME["SHOW_KEYBOARD_SHORTCUT"] = "SHOW_KEYBOARD_SHORTCUT";
    GA4_EVENT_NAME["COPY_TEXT_IN_SPEECH_BUBBLE"] = "COPY_TEXT_IN_SPEECH_BUBBLE";
    GA4_EVENT_NAME["DIVIDE_BLOCKS"] = "DIVIDE_BLOCKS";
    GA4_EVENT_NAME["SET_FULL_WIDTH_IMAGE"] = "SET_FULL_WIDTH_IMAGE";
    GA4_EVENT_NAME["SET_TEXT_OVERLAY_IMAGE"] = "SET_TEXT_OVERLAY_IMAGE";
    GA4_EVENT_NAME["CREATE_VIBRATION"] = "CREATE_VIBRATION";
    GA4_EVENT_NAME["USING_PROP_FIGURE_OF_SENTENCE_TEMPLATE"] = "USING_PROP_FIGURE_OF_SENTENCE_TEMPLATE";
})(GA4_EVENT_NAME = exports.GA4_EVENT_NAME || (exports.GA4_EVENT_NAME = {}));
