import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

export const Clickable = styled.span`
  cursor: pointer;
  color: ${COLORS.GOLD500};

  :hover {
    text-decoration: underline;
  }
`
