import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAppContext } from '../context/AppContext'
import { Utils } from '../utils/utils'

type Props = {
  urlAfterLogout: string
}

export const AdminLogoutContainer: React.FC<Props> = ({ urlAfterLogout, children }) => {
  const { logoutUser } = useAppContext()
  const [redirect, setRedirect] = React.useState(false)

  React.useEffect(() => {
    logoutUser().then(() => setRedirect(true), Utils.showError)
  }, [logoutUser])

  if (redirect) {
    return <Redirect to={urlAfterLogout} />
  }

  return <div>{children}</div>
}
