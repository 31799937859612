"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOHbMessageNext = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const ui_1 = require("../../../../ui");
const DOHbLinkBlock_1 = require("./DOHbLinkBlock");
/**
 * 공용 블록연결 클래스 (하나의 블록연결 셀렉트만 있는 것은 제외 ex) 알고리즘 블록의 블록 연결, 매칭의 블록 연결)
 * 블록연결은 블록연결 메시지 말고도 다음의 메시지에 속할 수 있습니다.
 * 1.
 */
class DOHbMessageNext {
    constructor(parent, data, isSingleValue) {
        var _a, _b, _c;
        this.linkBlocks = [];
        this.isSingleValue = false;
        this.parent = parent;
        this.data = data;
        this.isSingleValue = isSingleValue !== null && isSingleValue !== void 0 ? isSingleValue : false;
        this.blocksIdSelectionField = this.createBlockIdsSelection(!!this.data.linkBlocks
            ? (_c = (_b = (_a = this.data.linkBlocks[this.data.linkBlocks.length - 1]) === null || _a === void 0 ? void 0 : _a.blocks) === null || _b === void 0 ? void 0 : _b.map(item => {
                var _a, _b, _c, _d;
                return ({
                    name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                    value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                    category: (_d = (_c = item.groupName) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '-',
                });
            })) !== null && _c !== void 0 ? _c : []
            : []);
        this.generateLinkBlocks(this.data);
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            linkBlocks: mobx_1.observable,
            flowChartNodesOfOtherGroupBlock: mobx_1.computed,
            isValidBlockIds: mobx_1.computed,
            isValidAll: mobx_1.computed,
            blocksTo: mobx_1.computed,
        });
    }
    get isValidBlockIds() {
        return this.blocksIdSelectionField.value.length > 0;
    }
    get isValidAll() {
        return [
            this.isValidBlockIds,
            this.linkBlocks.every(block => block.isValidAll),
        ].every(v => v);
    }
    /**
     * 서버로 보내기 위한 인풋 데이터
     */
    getDataForSubmit() {
        return {
            linkBlocks: [
                ...this.linkBlocks.map(block => block.getDataForSubmit()),
                {
                    blockIds: this.blocksIdSelectionField.value,
                },
            ],
        };
    }
    /**
     * 변경된 데이터에 대하여 보다 더 상세한 데이터
     */
    getFullData() {
        return {
            linkBlocks: [
                ...this.linkBlocks.map(item => item.getFullData()),
                {
                    blocks: this.blocksIdSelectionField.selectedOptions.map(item => ({
                        id: item.value,
                        title: item.name,
                        groupName: item.category,
                    })),
                },
            ],
        };
    }
    /**
     * 변경되기 전의 데이터를 인풋 데이터로 변환한다.
     */
    getOriginDataForSubmit() {
        var _a, _b;
        if (!this.data.linkBlocks || this.data.linkBlocks.length < 1) {
            return {};
        }
        return {
            linkBlocks: (_b = (_a = this.data.linkBlocks) === null || _a === void 0 ? void 0 : _a.map((b, index) => {
                var _a, _b, _c, _d, _e, _f;
                if (this.data.linkBlocks.length - 1 === index) {
                    return {
                        blockIds: (_b = (_a = b.blocks) === null || _a === void 0 ? void 0 : _a.map(block => block.id)) !== null && _b !== void 0 ? _b : [],
                    };
                }
                return {
                    blockIds: (_d = (_c = b.blocks) === null || _c === void 0 ? void 0 : _c.map(block => block.id)) !== null && _d !== void 0 ? _d : [],
                    items: (_f = (_e = b.items) === null || _e === void 0 ? void 0 : _e.map(item => {
                        var _a, _b;
                        return ({
                            compareValue: item.compareValue,
                            kind: item.kind,
                            operator: item.operator,
                            inputIds: (_b = (_a = item.inputs) === null || _a === void 0 ? void 0 : _a.map(input => input.id)) !== null && _b !== void 0 ? _b : [],
                        });
                    })) !== null && _f !== void 0 ? _f : [],
                    logic: b.logic,
                };
            })) !== null && _b !== void 0 ? _b : [],
        };
    }
    addLinkBlock() {
        const doLinkBlock = new DOHbLinkBlock_1.DOHbLinkBlock(null, this);
        (0, mobx_1.runInAction)(() => {
            this.linkBlocks.push(doLinkBlock);
        });
    }
    // true on success, false on failure(happens when index is invalid)
    removeLinkBlock(uniqueId) {
        const index = this.linkBlocks.findIndex(v => v.uniqueId === uniqueId);
        if (index !== -1) {
            (0, mobx_1.runInAction)(() => {
                this.linkBlocks = this.linkBlocks.filter((value, i) => i !== index);
            });
            return true;
        }
        return false;
    }
    createBlockIdsSelection(selectedOptions) {
        return new ui_1.MultiSelectionInput('blocksIdSelect', 'blocksIdSelect', '블록 검색', selectedOptions, [], {
            onCreate: async (inputText) => {
                await this.parent.block.parentChapter.createNewBlock(inputText, '');
            },
            createSuffix: '블록 만들기',
            onLoadMoreOptions: async (pageIndex, inputText) => {
                var _a, _b;
                try {
                    const res = await this.parent.rootStore.di.server.hbClient.block.getListBlock(this.parent.block.story.storyId, pageIndex, 30, !!inputText.trim() ? inputText.trim() : undefined);
                    return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                        var _a, _b, _c, _d;
                        return ({
                            name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                            value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                            category: (_d = (_c = item.groupName) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '-',
                        });
                    })) !== null && _b !== void 0 ? _b : []);
                }
                catch (ex) {
                    this.parent.rootStore.showError(ex);
                    return [];
                }
            },
            pageIndexForOptions: 0,
            pageSizeForOptions: 30,
            isSingleValue: this.isSingleValue,
        });
    }
    // 현재 블록그룹에 존재하는 블록인지 여부
    // blockId는 무조건 있어야 하지만 기존 헬로우봇 타입이 전부 옵셔널이라... 일단 이렇게..
    hasBlockInCurrentBlockGroup(blockId) {
        if (!blockId)
            return false;
        return !!this.parent.block.parentChapter.blockStore.all.find(d => d.uniqueId === String(blockId));
    }
    generateLinkBlocks(data) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.linkBlocks =
                (_b = (_a = data.linkBlocks) === null || _a === void 0 ? void 0 : _a.slice(0, data.linkBlocks.length - 1).map(b => new DOHbLinkBlock_1.DOHbLinkBlock(b, this))) !== null && _b !== void 0 ? _b : [];
        });
    }
    //
    // flowChart
    //
    // Todo: 추후 concat한 부분 리팩토링 필요. 그 외에는 부분이 분기도에 안그려지는 이슈가 있어 concat로 땜빵(?)
    get blocksTo() {
        var _a;
        const linkBlocks = this.data.linkBlocks;
        const blockTo = new DOHbLinkBlock_1.DOHbLinkBlock((_a = linkBlocks === null || linkBlocks === void 0 ? void 0 : linkBlocks[linkBlocks.length - 1]) !== null && _a !== void 0 ? _a : null, this).blocksTo;
        return (0, lodash_1.flatten)(this.linkBlocks.map(v => v.blocksTo).concat(blockTo));
    }
    // 다른 그룹의 블록을 참조하고 있다면 해당 블록들을 플로우차트에 그리기 위한 데이터를 생성해준다.
    // Todo: 추후 concat한 부분 리팩토링 필요. 그 외에는 부분이 분기도에 안그려지는 이슈가 있어 concat로 땜빵(?)
    get flowChartNodesOfOtherGroupBlock() {
        var _a;
        const linkBlocks = this.data.linkBlocks;
        const flowChartNodesOfOtherGroupBlock = new DOHbLinkBlock_1.DOHbLinkBlock((_a = linkBlocks === null || linkBlocks === void 0 ? void 0 : linkBlocks[linkBlocks.length - 1]) !== null && _a !== void 0 ? _a : null, this).flowChartNodesOfOtherGroupBlock;
        return (0, lodash_1.flatten)(this.linkBlocks
            .map(v => v.flowChartNodesOfOtherGroupBlock)
            .concat(flowChartNodesOfOtherGroupBlock));
    }
    // flowChart 위의 엣지에 표시할 데이터를 반환합니다.
    // 같은 이동할 블록을 가지고 있을 경우 데이터를 통일해서 보여줍니다.
    // 겹치지만, 데이터는 같기 때문에 하나만 보여짐
    // null 이면 그 외에는
    getEdgeInfoForFlowChartBy(toBlockUniqueId) {
        var _a, _b, _c, _d, _e, _f;
        let res = null;
        const visitedHbLinkBlockIds = [];
        for (const blockTo of this.blocksTo) {
            const hbLinkBlock = blockTo.extra.statement;
            if (visitedHbLinkBlockIds.includes(hbLinkBlock.uniqueId)) {
                continue;
            }
            if (toBlockUniqueId === blockTo.block.uniqueId) {
                visitedHbLinkBlockIds.push(hbLinkBlock.uniqueId);
                // 그 외에는이 아닌 조건 그룹일때에 대한 처리
                if (!!((_a = hbLinkBlock.data.items) === null || _a === void 0 ? void 0 : _a.slice()[0])) {
                    const condition = hbLinkBlock.data.items[0];
                    if (!res) {
                        res = {
                            name: (_c = (_b = condition.inputs) === null || _b === void 0 ? void 0 : _b[0].title) !== null && _c !== void 0 ? _c : '-',
                            operator: !!condition.operator
                                ? core_1.HbConditionOperatorToKoString[condition.operator]
                                : '-',
                            comparedValue: (_d = condition.compareValue) !== null && _d !== void 0 ? _d : '-',
                            numCondition: (_f = (_e = hbLinkBlock.data.items) === null || _e === void 0 ? void 0 : _e.length) !== null && _f !== void 0 ? _f : 1,
                            numConditionGroup: 1,
                        };
                    }
                    else {
                        res.numConditionGroup++;
                    }
                    // 그 외에는일때에 대한 처리
                }
                else {
                    if (!!res) {
                        res.numConditionGroup++;
                    }
                }
            }
        }
        return res;
    }
}
exports.DOHbMessageNext = DOHbMessageNext;
