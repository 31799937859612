import * as React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

export enum ODButtonTheme {
  Primary = 'Primary',
  PrimaryInvert = 'PrimaryInvert',
  Cancel = 'Cancel',
}

export enum ODButtonSize {
  Small = 'Small',
  Normal = 'Normal',
}

type ODButtonProps = {
  theme: ODButtonTheme
  size?: ODButtonSize
  fullWidth?: boolean
  onClick?: (e: any) => void
  style?: object
  type?: string
  disabled?: boolean
}

type P = {
  border: string | number
  backgroundColor: string
  textColor: string
  textSize: string
  insetTop: string
  insetBottom: string
  extra?: string
  disabled?: boolean
}

const ButtonWrapper = styled.button`
  border: ${(p: P) => p.border};
  background-color: ${(p: P) => (p.disabled ? 'silver' : p.backgroundColor)};
  cursor: ${(p: P) => (p.disabled ? 'not-allowed' : 'pointer')};
  color: ${(p: P) => p.textColor};
  font-size: ${(p: P) => p.textSize};
  text-align: center;
  ${(p: P) => p.extra || ''}
`

const TextWrapper = styled.div`
  margin-top: ${(p: P) => p.insetTop};
  margin-bottom: ${(p: P) => p.insetBottom};
`

const themeConfig = {
  [ODButtonTheme.Primary]: {
    backgroundColor: COLORS.Primary,
    textColor: 'white',
    border: 0,
    extra: 'border-radius: 3px;',
  },
  [ODButtonTheme.PrimaryInvert]: {
    backgroundColor: 'white',
    textColor: COLORS.Primary,
    border: `1px solid ${COLORS.Primary}`,
    extra: 'border-radius: 3px;',
  },
  [ODButtonTheme.Cancel]: { backgroundColor: '#c8ced3', textColor: '#2f353a', border: 0, extra: 'border-radius: 3px;' },
}

const sizeConfig = {
  [ODButtonSize.Small]: { textSize: '14px', insetTop: '6px', insetBottom: '7px' },
  [ODButtonSize.Normal]: { textSize: '16px', insetTop: '16px', insetBottom: '13px' },
}

export const ODButton: React.FC<ODButtonProps> = ({ children, style = {}, ...props }) => {
  const { theme, size, fullWidth, onClick, type = 'button', disabled = false } = props
  const p = { ...themeConfig[theme], ...sizeConfig[size || ODButtonSize.Normal] }
  const styleMerged = fullWidth ? { width: '100%' } : {}

  return (
    <ButtonWrapper
      // @ts-ignore
      type={type}
      disabled={disabled}
      {...p}
      style={Object.assign({}, styleMerged, style)}
      onClick={onClick}
    >
      <TextWrapper {...p}>{children}</TextWrapper>
    </ButtonWrapper>
  )
}
