"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTConditionalChoice = void 0;
const mobx_1 = require("mobx");
const SelectionFieldWithUI_1 = require("../../fields/SelectionFieldWithUI");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
class DOSTConditionalChoice extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        // 모달로 수정하는 경우는 필수.
        this.hasModalForEdit = true;
        this.updateBlockOptions();
        (0, mobx_1.makeObservable)(this, {
            // blockOptions: observable,
            choiceBlockOptions: mobx_1.observable,
        });
    }
    get st() {
        return this.data;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        const sceanFields = this.choiceMethodFields
            .map(d => d.conditionalChoiceField.map(d => d.field).flat())
            .flat();
        return [...super.editorFields, ...sceanFields];
    }
    generateSheetColumnsAfterBackground() {
        const result = ['', '', '', '', '', '', ''];
        if (!!this.executeConditionOfStatement) {
            result.push(this.executeConditionOfStatement);
        }
        return result;
    }
    get blocksTo() {
        var _a;
        const blockToNames = (_a = this.st) === null || _a === void 0 ? void 0 : _a.buttons.map(button => {
            var _a;
            return (_a = button.linkBlocks) === null || _a === void 0 ? void 0 : _a.map(item => { var _a; return (_a = item.blocks) === null || _a === void 0 ? void 0 : _a.map(blk => blk.title); }).flat().filter(d => !!d);
        }).flat();
        const blocks = blockToNames === null || blockToNames === void 0 ? void 0 : blockToNames.map(name => {
            return {
                block: this.block.store.getById(name),
                label: '',
                extra: { statement: this },
            };
        });
        return blocks.filter(d => d.block);
    }
    // 선택지에 맞는 blockTo 같이 넘겨주는 함수
    // get choices(): IConditionalChoice[] {
    //   return this.st.choices.map((c, index) => {
    //     // 선택지에서 가는 블록이름들
    //     const blockToNames = this.st?.buttons[index].linkBlocks
    //       ?.map(item => item.blocks?.map(blk => blk.title))
    //       .flat()
    //       .filter(d => !!d) as string[]
    //     const conditions = (
    //       this.st.buttons[index].linkBlocks?.map(item =>
    //         item.items?.map(condition =>
    //           condition.inputs?.map(({ title }) => title)
    //         )
    //       ) ?? []
    //     ).flat()
    //     console.log('conditions', conditions)
    //     return {
    //       choiceName: c,
    //       blockToNames: blockToNames ?? [],
    //       conditions: conditions,
    //     }
    //   })
    // }
    startEditing() {
        this.updateBlockOptions();
        return super.startEditing();
    }
    updateBlockOptions() {
        (0, mobx_1.runInAction)(() => {
            this.choiceBlockOptions = this.st.choices.map((choiceName, index) => {
                var _a, _b, _c, _d, _e, _f;
                const blockToNames = (_c = (_b = (_a = this.st) === null || _a === void 0 ? void 0 : _a.buttons[index].linkBlocks) === null || _b === void 0 ? void 0 : _b.map(item => { var _a; return (_a = item.blocks) === null || _a === void 0 ? void 0 : _a.map(blk => blk.title); }).filter(d => !!d)) !== null && _c !== void 0 ? _c : [['']];
                // 선택지 블록만있고 연결된 블록이 없는 경우.. 오류가남 스플과 헬봇의 차이임 스플은 저장이 안됨 블록연결없이. 헬봇은 됨..
                // const blockOptionsWithCondition = blockToNames?.map(blks =>
                //   blks.map(blk =>
                //     this.block.parentChapter.getBlockOptions(
                //       blk,
                //       this.background,
                //       block => {},
                //       true
                //     )
                //   )
                // )
                const conditions = (_d = this.st.buttons[index].linkBlocks) === null || _d === void 0 ? void 0 : _d.map(item => {
                    var _a;
                    return (_a = item.items) === null || _a === void 0 ? void 0 : _a.map(condition => { var _a; return (_a = condition.inputs) === null || _a === void 0 ? void 0 : _a.map(({ title }) => title); });
                });
                const conditionsData = (_f = (_e = this.st.buttons[index].linkBlocks) === null || _e === void 0 ? void 0 : _e.map(item => item)) !== null && _f !== void 0 ? _f : [];
                // conditionsData.items의 index와 blockToNames index가 매칭된다
                return {
                    choiceName,
                    blockToNames,
                    // blockOptionsWithCondition,
                    conditions,
                    conditionsData,
                };
            });
            this.updateChoiceMethodField();
        });
    }
    // choiceBlockOptions이 바뀌기 때문에 업데이트 해주어야함.
    updateChoiceMethodField() {
        const options = this.block.parentChapter.blockStore.all.map(block => ({
            value: block,
            name: block.name,
            description: block.name,
        }));
        this.choiceMethodFields = this.choiceBlockOptions.map((item, index1) => {
            return {
                choiceName: item.choiceName,
                conditionalChoiceField: item.blockToNames.map((blockToNames2, index2) => {
                    // 배열로 받아서 안에 데이터 값 전부 저장 linkBlocks[].items 랑 linkBlocks[].logic 이 두 개가 주 데이터
                    // conditionsData가 없을 가능성이 존재..
                    var _a, _b, _c, _d, _e, _f;
                    const conditionData = (_b = (_a = item.conditionsData) === null || _a === void 0 ? void 0 : _a[index2]) !== null && _b !== void 0 ? _b : [];
                    return {
                        conditionData,
                        conditionNames: index2 !== 0 && index2 + 1 === ((_d = (_c = item.conditions) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0)
                            ? ['그 외에는']
                            : (_f = (_e = item.conditions) === null || _e === void 0 ? void 0 : _e[index2]) !== null && _f !== void 0 ? _f : [],
                        field: blockToNames2.map((blockName, index3) => {
                            return new SelectionFieldWithUI_1.SelectionFieldWithUI('choiceMethodField_' + item.choiceName + '_' + index2, () => blockName, value => {
                                (0, mobx_1.runInAction)(() => {
                                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                                    if ((_e = (_d = (_c = (_b = (_a = this.st) === null || _a === void 0 ? void 0 : _a.buttons[index1]) === null || _b === void 0 ? void 0 : _b.linkBlocks) === null || _c === void 0 ? void 0 : _c[index2]) === null || _d === void 0 ? void 0 : _d.blocks) === null || _e === void 0 ? void 0 : _e[index3]) {
                                        const hbLinkBlock = (_k = (_j = (_h = (_g = (_f = this.st) === null || _f === void 0 ? void 0 : _f.buttons[index1]) === null || _g === void 0 ? void 0 : _g.linkBlocks) === null || _h === void 0 ? void 0 : _h[index2]) === null || _j === void 0 ? void 0 : _j.blocks) === null || _k === void 0 ? void 0 : _k[index3];
                                        if (hbLinkBlock) {
                                            const block = this.block.store.getById(value);
                                            hbLinkBlock.title = value;
                                            hbLinkBlock.id = (_l = block.hbExtensionData) === null || _l === void 0 ? void 0 : _l.id;
                                        }
                                        this.updateBlockOptions();
                                    }
                                });
                            }, {
                                options: {
                                    noLabel: false,
                                },
                                selection: options.map(item => ({
                                    name: item.name,
                                    value: item.name,
                                })),
                            });
                        }),
                    };
                }),
            };
        });
    }
}
exports.DOSTConditionalChoice = DOSTConditionalChoice;
