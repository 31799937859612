"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorManager = void 0;
/**
 * IEditorLine 에 대한 기능들을 공통적으로 처리한다.
 */
class EditorManager {
    constructor(editor) {
        this.editor = editor;
    }
    startEditing() {
        this.editor.editorFields.forEach(fld => fld.reload());
    }
    async submitEditing(opFactory) {
        const op = await (opFactory !== null && opFactory !== void 0 ? opFactory : this.editor.helper.opFactory());
        this.editor.editorFields.forEach(fld => {
            if (fld.hasChange) {
                op.changeField(this.editor, fld.fieldId, fld.value);
            }
        });
        return op;
    }
    // 편집을 취소한다.
    cancelEditing() {
        this.editor.editorFields.forEach(fld => fld.revertChanges());
    }
}
exports.EditorManager = EditorManager;
