"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignIf = void 0;
/**
 * mobx store 와 관련된 유틸리티를 제공한다.
 */
function assignIf(obj, key, runIfHasOwnProperty) {
    if (obj.hasOwnProperty(key)) {
        runIfHasOwnProperty(obj[key]);
    }
}
exports.assignIf = assignIf;
