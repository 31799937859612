"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbImage = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const ui_1 = require("../../../../ui");
const DOSTHbBase_1 = require("../../../DOSTHbBase");
class DOSTHbImage extends DOSTHbBase_1.DOSTHbBase {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbImage,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.showEditModal = null;
        this.initInputs(this.st.data);
        (0, mobx_1.makeObservable)(this, {
            showEditModal: mobx_1.observable,
        });
    }
    onToggleEditModal(open) {
        (0, mobx_1.runInAction)(() => {
            this.showEditModal = open;
        });
    }
    get isValidAll() {
        return (!!this.imageIdInput.value &&
            !isNaN(Number(this.imageIdInput.value)) &&
            Number(this.imageIdInput.value) > 0);
    }
    startEditing() {
        this.onToggleEditModal(this);
        super.startEditing();
    }
    cancelEditing() {
        var _a;
        this.updateData((_a = this.st.hbExtensionData) !== null && _a !== void 0 ? _a : {});
        super.cancelEditing();
    }
    getDataForSubmit() {
        var _a;
        return {
            type: 'image',
            imageId: !!this.imageIdInput
                ? Number(this.imageIdInput.value)
                : this.st.data.imageId,
            isBig: (_a = this.st.data.isBig) !== null && _a !== void 0 ? _a : false,
        };
    }
    updateData(data) {
        var _a;
        super.updateData(data);
        this.initInputs((_a = data.data) !== null && _a !== void 0 ? _a : {});
    }
    initInputs(data) {
        (0, mobx_1.runInAction)(() => {
            var _a;
            this.imageIdInput = new ui_1.InputWithValidation('imageId', 'imageId', 'imageId', 'number', (_a = data.imageId) !== null && _a !== void 0 ? _a : -1, null);
        });
    }
}
exports.DOSTHbImage = DOSTHbImage;
