"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseMessage = void 0;
const statement_1 = require("./statement");
// 태그 시작 및 끝 문자열 상수
const COLOR_TAG_START = '[c:';
const FONT_SIZE_TAG_START = '[fs:';
const BOLD_TAG_START = '[b]';
const ITALIC_BOLD_TAG_START = '[ib]';
const ITALIC_TAG_START = '[i]';
const COLOR_TAG_END = '[/c]';
const FONT_SIZE_TAG_END = '[/fs]';
const BOLD_TAG_END = '[/b]';
const ITALIC_BOLD_TAG_END = '[/ib]';
const ITALIC_TAG_END = '[/i]';
const LIST_START_END_TAG = [
    COLOR_TAG_START,
    FONT_SIZE_TAG_START,
    BOLD_TAG_START,
    ITALIC_BOLD_TAG_START,
    ITALIC_TAG_START,
    COLOR_TAG_END,
    FONT_SIZE_TAG_END,
    BOLD_TAG_END,
    ITALIC_BOLD_TAG_END,
    ITALIC_TAG_END,
];
const parseMessage = (str, colorMap) => {
    // 태그 스택과 결과를 저장할 배열 초기화
    const tagStack = [];
    const messageWithEffect = [];
    let message = '';
    let strChanging = str;
    // 오류가 발생할 경우 반환할 기본 값
    const valueWithError = {
        message: str,
        messageWithEffect: [{ text: str }],
        isError: true,
    };
    // 문자열을 처리할 동안 반복 ("" 까지)
    while (!!strChanging) {
        // 시작 태그 확인
        const isColorStartTag = strChanging.startsWith(COLOR_TAG_START);
        const isFontSizeStartTag = strChanging.startsWith(FONT_SIZE_TAG_START);
        const isBoldStartTag = strChanging.startsWith(BOLD_TAG_START);
        const isIBoldStartTag = strChanging.startsWith(ITALIC_BOLD_TAG_START);
        const isIStartTag = strChanging.startsWith(ITALIC_TAG_START);
        // 끝 태그 확인
        const isColorEndTag = strChanging.startsWith(COLOR_TAG_END);
        const isFontSizeEndTag = strChanging.startsWith(FONT_SIZE_TAG_END);
        const isBoldEndTag = strChanging.startsWith(BOLD_TAG_END);
        const isIBoldEndTag = strChanging.startsWith(ITALIC_BOLD_TAG_END);
        const isIEndTag = strChanging.startsWith(ITALIC_TAG_END);
        // 시작 태그 처리
        if (isColorStartTag ||
            isFontSizeStartTag ||
            isBoldStartTag ||
            isIBoldStartTag ||
            isIStartTag) {
            // 끝 대괄호의 인덱스를 찾음
            const endBracketIndex = getEndBracketIndex(strChanging);
            if (endBracketIndex === -1) {
                return valueWithError;
            }
            // 다음 시작 대괄호의 인덱스를 찾음
            const nextStartBracketIndex = getNextStartBracketIndex(strChanging, undefined);
            if (nextStartBracketIndex === -1) {
                return valueWithError;
            }
            // 태그 스택에 추가
            if (isColorStartTag) {
                const color = strChanging.slice(3, endBracketIndex);
                tagStack.push({
                    tag: 'c',
                    value: colorMap[color] || color,
                });
            }
            else if (isFontSizeStartTag) {
                tagStack.push({
                    tag: 'fs',
                    value: strChanging.slice(4, endBracketIndex),
                });
            }
            else if (isBoldStartTag) {
                tagStack.push({ tag: 'b', value: null });
            }
            else if (isIBoldStartTag) {
                tagStack.push({ tag: 'ib', value: null });
            }
            else if (isIStartTag) {
                tagStack.push({ tag: 'i', value: null });
            }
            // 태그 사이의 콘텐츠를 가져와서 결과에 추가
            const content = strChanging.slice(endBracketIndex + 1, nextStartBracketIndex);
            if (!!content) {
                messageWithEffect.push({
                    text: content,
                    ...getStyleByTagStack(tagStack),
                });
                message += content;
            }
            // 처리된 부분을 잘라내기
            strChanging = strChanging.slice(nextStartBracketIndex);
            // 끝 태그를 만나면 스택에서 제거
        }
        else if (isColorEndTag ||
            isFontSizeEndTag ||
            isBoldEndTag ||
            isIBoldEndTag ||
            isIEndTag) {
            const endBracketIndex = getEndBracketIndex(strChanging);
            if (endBracketIndex === -1) {
                return valueWithError;
            }
            const tagName = isColorEndTag
                ? 'c'
                : isFontSizeEndTag
                    ? 'fs'
                    : isBoldEndTag
                        ? 'b'
                        : isIBoldEndTag
                            ? 'ib'
                            : 'i';
            // 태그 스택에서 해당 태그 제거
            let findTagIndex = -1;
            // const findTagIndex = tagStack.findIndex(item => item.tag === tagName)
            tagStack.forEach((tag, index) => {
                if (tag.tag === tagName) {
                    findTagIndex = index;
                }
            });
            if (findTagIndex >= 0) {
                tagStack.splice(findTagIndex, 1);
            }
            strChanging = strChanging.slice(endBracketIndex + 1);
        }
        else {
            // 다음 시작 또는 종료 태그가 나타날 때까지의 텍스트를 처리
            let nextStartBracketIndex = -1;
            for (const tag of LIST_START_END_TAG) {
                const index = strChanging.indexOf(tag);
                if (nextStartBracketIndex === -1 && index > 0) {
                    nextStartBracketIndex = index;
                }
                else if (index > 0 && index < nextStartBracketIndex) {
                    nextStartBracketIndex = index;
                }
            }
            // 다음 시작 태그가 없으면 남은 텍스트를 처리
            if (nextStartBracketIndex < 0) {
                messageWithEffect.push({
                    text: strChanging,
                    ...getStyleByTagStack(tagStack),
                });
                message += strChanging;
                strChanging = '';
            }
            else {
                // 다음 시작 태그까지의 텍스트를 처리
                messageWithEffect.push({
                    text: strChanging.slice(0, nextStartBracketIndex),
                    ...getStyleByTagStack(tagStack),
                });
                message += strChanging.slice(0, nextStartBracketIndex);
                strChanging = strChanging.slice(nextStartBracketIndex);
            }
        }
    }
    if (tagStack.length > 0) {
        return valueWithError;
    }
    return { message, messageWithEffect };
};
exports.parseMessage = parseMessage;
// 다음 대괄호의 유효한 시작 인덱스를 찾는다.
function getNextStartBracketIndex(str, index = 1) {
    const startBracketIndex = str.indexOf('[', index);
    if (index < 0 || index >= str.length - 1 || startBracketIndex < 0) {
        return -1;
    }
    for (const tag of LIST_START_END_TAG) {
        if (str.slice(startBracketIndex).startsWith(tag)) {
            return startBracketIndex;
        }
    }
    return getNextStartBracketIndex(str, startBracketIndex + 1);
}
// 대괄호의 종료 인덱스를 찾는다.
function getEndBracketIndex(str) {
    const findIndex = str.indexOf(']');
    if (findIndex < 0) {
        return -1;
    }
    return findIndex;
}
// 태그 스택에 따라 스타일을 결정하는 함수
function getStyleByTagStack(tagStack) {
    const res = {};
    let isBold = false;
    let isItalic = false;
    tagStack.forEach(item => {
        var _a, _b;
        if (item.tag === 'c') {
            res.color = (_a = item.value) !== null && _a !== void 0 ? _a : '';
        }
        if (item.tag === 'b') {
            isBold = true;
        }
        if (item.tag === 'ib') {
            isBold = true;
            isItalic = true;
        }
        if (item.tag === 'i') {
            isItalic = true;
        }
        if (item.tag === 'fs') {
            res.fontSizeOffset =
                statement_1.FONT_SIZE_OFFSET_TAG_VALUE_TO_OFFSET[(_b = item.value) !== null && _b !== void 0 ? _b : 'md'];
        }
    });
    if (isBold && isItalic) {
        res.style = statement_1.STYLE_TAG.ITALIC_BOLD;
    }
    else if (isBold) {
        res.style = statement_1.STYLE_TAG.BOLD;
    }
    else if (isItalic) {
        res.style = statement_1.STYLE_TAG.ITALIC;
    }
    return res;
}
