"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbChatBotEditor = void 0;
const mobx_1 = require("mobx");
const Yup = require("yup");
const HbEntityEditor_1 = require("../HbEntityEditor");
const ui_1 = require("../ui");
class HbChatBotEditor extends HbEntityEditor_1.HbEntityEditor {
    constructor(bot, rootStore) {
        var _a, _b, _c, _d, _e, _f, _g;
        super(bot, rootStore);
        this.urlStatus = 'requiredCheck';
        this.nameField = new ui_1.InputWithValidation('name', '챗봇 이름', '챗봇 이름을 입력해 주세요.', 'text', (_a = bot.name) !== null && _a !== void 0 ? _a : '', Yup.string().min(2).max(20).required(), {
            noLabel: true,
            maxLength: 20,
            showMaxLength: true,
        }).setRequired(true);
        this.jobField = new ui_1.InputWithValidation('job', '챗봇 태그', '챗봇 태그를 입력해 주세요.', 'text', (_b = bot.job) !== null && _b !== void 0 ? _b : '', Yup.string().min(1).max(20).required(), {
            noLabel: true,
            maxLength: 20,
            showMaxLength: true,
        }).setRequired(true);
        this.profileMessageField = new ui_1.InputWithValidation('profileMessage', '프로필 소개', '프로필 인삿말을 작성해 주세요.', 'textarea', (_c = bot.profileMessage) !== null && _c !== void 0 ? _c : '', Yup.string().min(1).max(200).required(), {
            noLabel: true,
            maxLength: 200,
            showMaxLength: true,
        }).setRequired(true);
        this.defaultMessageField = new ui_1.InputWithValidation('defaultMessage', '한줄 소개', '챗봇에 대한 한 줄 소개 작성해 주세요.', 'text', (_d = bot.job) !== null && _d !== void 0 ? _d : '', Yup.string().min(1).max(20).required(), {
            noLabel: true,
            maxLength: 20,
            showMaxLength: true,
        }).setRequired(true);
        this.urlField = new ui_1.InputWithValidation('url', '프로필 링크', '영문,숫자,-입력가능', 'text', (_e = bot.url) !== null && _e !== void 0 ? _e : '', 
        // Yup.string().min(1).required(),
        Yup.mixed().test('프로필 링크', '프로필 링크', value => {
            if (this.urlStatus === 'requiredCheck' || this.urlStatus === 'error') {
                return false;
            }
            return typeof value === 'string' && value.trim().length > 0;
        }), {
            noLabel: true,
            onChange: v => {
                const foundDefValue = this._defFieldValues.url;
                if (!v || !foundDefValue) {
                    (0, mobx_1.runInAction)(() => {
                        this.urlStatus = 'requiredCheck';
                    });
                    return;
                }
                if (foundDefValue === v) {
                    (0, mobx_1.runInAction)(() => {
                        this.urlStatus = 'checked';
                    });
                }
                if (foundDefValue !== v) {
                    (0, mobx_1.runInAction)(() => {
                        this.urlStatus = 'requiredCheck';
                    });
                }
            },
        }).setRequired(true);
        this.urlStatus = !this.urlField.value.trim() ? 'requiredCheck' : 'checked';
        this.profileImageField = new ui_1.FileInput('profileImage', '프로필 이미지', !!bot.profileImage ? { link: bot.profileImage } : null, {
            theme: ui_1.FileTheme.HbProfileImage,
            accept: 'image/png, image/jpeg, image/gif',
            required: true,
            showLabel: false,
            aspectRatio: {
                w: 1,
                h: 1,
            },
        });
        this.coverImageField = new ui_1.FileInput('coverImage', '커버 이미지', !!bot.coverImageUrl ? { link: bot.coverImageUrl } : null, {
            theme: ui_1.FileTheme.HbCoverImage,
            accept: 'image/png, image/jpeg, image/gif',
            required: true,
            showLabel: false,
            aspectRatio: {
                w: 7,
                h: 3,
            },
        });
        this.coverImageTypeField = new ui_1.SelectionInput('coverImageType', 'coverImageType', (_f = bot.coverImageType) !== null && _f !== void 0 ? _f : 'file', []).setRequired(true);
        this.coverImageColorField = new ui_1.SelectionInput('coverImageColor', 'coverImageColor', this.coverImageTypeField.value === 'file'
            ? undefined
            : (_g = bot.coverImageColor) !== null && _g !== void 0 ? _g : undefined, [
            { name: 'file', value: undefined },
            {
                name: 'blue',
                value: 'blue',
                description: 'https://hellobot-prod.s3.ap-northeast-2.amazonaws.com/coverImage/defaultImage/20221125_%E1%84%92%E1%85%A6%E1%86%AF%E1%84%85%E1%85%A9%E1%84%8B%E1%85%AE%E1%84%87%E1%85%A9%E1%86%BA%E1%84%80%E1%85%B5%E1%84%87%E1%85%A9%E1%86%AB%E1%84%8F%E1%85%A5%E1%86%AF%E1%84%85%E1%85%A5%E1%84%87%E1%85%A2%E1%84%80%E1%85%A7%E1%86%BC.png',
            },
            {
                name: 'green',
                value: 'green',
                description: 'https://hellobot-prod.s3.ap-northeast-2.amazonaws.com/coverImage/defaultImage/20221125_%E1%84%92%E1%85%A6%E1%86%AF%E1%84%85%E1%85%A9%E1%84%8B%E1%85%AE%E1%84%87%E1%85%A9%E1%86%BA%E1%84%80%E1%85%B5%E1%84%87%E1%85%A9%E1%86%AB%E1%84%8F%E1%85%A5%E1%86%AF%E1%84%85%E1%85%A5%E1%84%87%E1%85%A2%E1%84%80%E1%85%A7%E1%86%BC_green.png',
            },
            {
                name: 'purple',
                value: 'purple',
                description: 'https://hellobot-prod.s3.ap-northeast-2.amazonaws.com/coverImage/defaultImage/20221125_%E1%84%92%E1%85%A6%E1%86%AF%E1%84%85%E1%85%A9%E1%84%8B%E1%85%AE%E1%84%87%E1%85%A9%E1%86%BA%E1%84%80%E1%85%B5%E1%84%87%E1%85%A9%E1%86%AB%E1%84%8F%E1%85%A5%E1%86%AF%E1%84%85%E1%85%A5%E1%84%87%E1%85%A2%E1%84%80%E1%85%A7%E1%86%BC_purple.png',
            },
            {
                name: 'yellow',
                value: 'yellow',
                description: 'https://hellobot-prod.s3.ap-northeast-2.amazonaws.com/coverImage/defaultImage/20221125_%E1%84%92%E1%85%A6%E1%86%AF%E1%84%85%E1%85%A9%E1%84%8B%E1%85%AE%E1%84%87%E1%85%A9%E1%86%BA%E1%84%80%E1%85%B5%E1%84%87%E1%85%A9%E1%86%AB%E1%84%8F%E1%85%A5%E1%86%AF%E1%84%85%E1%85%A5%E1%84%87%E1%85%A2%E1%84%80%E1%85%A7%E1%86%BC_yellow.png',
            },
            {
                name: 'grey',
                value: 'grey',
                description: 'https://hellobot-prod.s3.ap-northeast-2.amazonaws.com/coverImage/defaultImage/20221125_%E1%84%92%E1%85%A6%E1%86%AF%E1%84%85%E1%85%A9%E1%84%8B%E1%85%AE%E1%84%87%E1%85%A9%E1%86%BA%E1%84%80%E1%85%B5%E1%84%87%E1%85%A9%E1%86%AB%E1%84%8F%E1%85%A5%E1%86%AF%E1%84%85%E1%85%A5%E1%84%87%E1%85%A2%E1%84%80%E1%85%A7%E1%86%BC_grey.png',
            },
        ], {
            onChangeBeforeSubmit: v => {
                if (!v) {
                    this.coverImageTypeField.onChange('file');
                    return;
                }
                this.coverImageTypeField.onChange('color');
            },
        });
        this.createDefFieldValues();
        (0, mobx_1.makeObservable)(this, {
            urlStatus: mobx_1.observable,
        });
    }
    async submit() {
        if (!this._target.id) {
            return;
        }
        if (!this.isChanged()) {
            this.rootStore.showError('변경 사항이 없습니다.');
            return;
        }
        if (this.urlStatus === 'requiredCheck' || this.urlStatus === 'error') {
            this.rootStore.showError('프로필 링크를 중복체크 해주세요.');
            return;
        }
        if (!this.editorFields.every(v => v.getIsValid(v.value))) {
            this.rootStore.showError('필드를 입력해주세요.');
            return;
        }
        const onSubmit = async (cs) => {
            const input = {};
            for (const [key, value] of Object.entries(cs)) {
                if (!value) {
                    continue;
                }
                input[key] = value;
            }
            input.managerId = this._target.managerId;
            await this.apiServer.bot.update(this._target.id, input);
        };
        return this.submitInternal(onSubmit, ex => this.rootStore.showError(ex));
    }
    async checkUrl() {
        try {
            await this.apiServer.bot.checkUrl(this.urlField.value);
            (0, mobx_1.runInAction)(() => {
                this.urlStatus = 'newChecked';
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
            (0, mobx_1.runInAction)(() => {
                this.urlStatus = 'error';
            });
        }
    }
}
exports.HbChatBotEditor = HbChatBotEditor;
