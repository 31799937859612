import { createGlobalStyle } from 'styled-components'
import './assets/icoStyle.css'
import { COLORS } from './styles/colors'
import { ReactAnimateReplaceStyles } from './styles/react-animate-replace-styles'
import { ReactDatesStyles } from './styles/react-dates-styles'

// https://stackoverflow.com/a/54842791/388351
// @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap&subset=korean');
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    font-family: 'Noto Sans KR', sans-serif;
  }
 
  //
  // 우리는 기본 react-toastify 를 수정하여 커스텀 디자인을 활용할 것이므로 아래처럼 inset 을 없앤다.
  // 
  .Toastify__toast {
    padding: 0;
    margin: 0;
  }
  
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
  
  //
  // Modal dialog 에서 존재하는 둥근 엣지를 제거하기 위해 사용한다.
  //
  .modal-rect {
    .modal-content {
      border: 0;
      border-radius: 0;
    }
  }
  
  //
  // 좌측 사이드바의 아이콘 디폴트 컬러
  //
  .nav-icon {
    color: #708393;
  }
  
  table.v-center {
    th, td {
      vertical-align: middle;
    }
  }
  
  //
  // 우측 드롭다운 메뉴의 underline 제거
  //  
  .dropdown-menu {
    a {
      text-decoration: none;
    }
  }
 
  //
  // 기존 CoreUI 에서 dropdown-item 을 Nav 로 묶으면서 발생하는 css 문제 수정
  // 
  .app-header {
    a {
      .dropdown-item {
        border-bottom: 1px solid #c8ced3;
      }
      
      .dropdown-item:first-child {
        border-radius: 0;
      }
      .dropdown-item:last-child {
        border-radius: 0;
      }
    }
  }
  
  // 디폴트 10rem 에서 좀 더 키운다.
  .dropdown-menu {
    min-width: 14rem;
  }
  
  // 상단 메뉴에서 배지가 여러개 있을 때에 대한 처리
  .badge:not(:first-child) {
    margin-left: 0.2rem;
  }
  
  .modal-sm {
    max-width: 300px;
  }
  
  .modal-tcf {
    max-width: 100%;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  // 페이지 최상단에 라인을 넣어주는 역할을 한다.
  .app-header {
    border-top: 3px solid ${COLORS.Primary}
  }
  
  // 사이드 메뉴에서 선택과 hover 를 바꿔준다.
  .sidebar {
    .nav-link.active {
      background-color: ${COLORS.Primary} !important;
      
      .nav-icon {
        color: white !important;
      }
    }
    
    .nav-link:not(.active):hover {
      background-color: #3a4248 !important;
      
      .nav-icon {
        color: ${COLORS.Primary} !important;
      }
    }
  }
  
  // 좌측 사이드바 메뉴에 컬러를 강제로 지정하는 경우
  .nav-red {
    a, i {
      color: red !important;
    }
  }
  
  .nav-primary {
    a, i {
      color: ${COLORS.Primary} !important;
    }
  }

  // popper 의 기본 max-width 를 늘려준다. (for color-picker)
  .od-popper {
    .popover {
      max-width: 500px
    }  
  }
  
  // breadcrumb 의 bottom margin 을 제거한다.
  ol.breadcrumb {
    margin-bottom: 0;
  }
  
  ${ReactAnimateReplaceStyles}
  
  // <Col className="vcenter" />
  div.vcenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  ${ReactDatesStyles}
  
  // react_dates_override.css
  .DateRangePicker_picker {
    z-index: 10;
  }
  
  .sidebar {
    background-color: ${COLORS.SIDEBAR_BG};
  }
  
  .app-header {
    background-color: ${COLORS.HEADER_BG};
  }
`
