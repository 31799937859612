"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseDoStoryStoreStrategy = void 0;
class BaseDoStoryStoreStrategy {
    // DoStoryStrategy에도 동일한 주석을 달았지만, 쓸데없이 private을 쓰지 않는다. 숨겨야 할 것만 숨긴다. 그 외에는 드러낸다.
    constructor(doStoryStore) {
        this.doStoryStore = doStoryStore;
    }
}
exports.BaseDoStoryStoreStrategy = BaseDoStoryStoreStrategy;
