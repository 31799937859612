"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbMessageTemplate = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const IDOStatement_1 = require("../../../IDOStatement");
const fields_1 = require("../../../../fields");
const DOSTHbMessageInputBase_1 = require("../DOSTHbMessageInputBase");
class DOSTHbMessageTemplate extends DOSTHbMessageInputBase_1.DOSTHbMessageInputBase {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbUserTemplate,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.HbMessageTemplate;
        this.hasModalForEdit = true;
        this.onChangeTextSizeByRangeInput = (e) => {
            var _a;
            const fontSize = e.target.value;
            (_a = this.textSizeInput) === null || _a === void 0 ? void 0 : _a.onChange(fontSize);
        };
        this.generateTextFields = (data) => {
            this.textSizeInput = new fields_1.TextFieldWithUI('text-size', () => {
                var _a, _b;
                return (_b = '' + ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.size)) !== null && _b !== void 0 ? _b : '';
            }, v => null, {
                options: { noLabel: true },
            });
            this.prefixInput = new fields_1.TextFieldWithUI(`prefix`, () => {
                var _a, _b;
                return (_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.prefix) !== null && _b !== void 0 ? _b : '';
            }, v => null, {
                options: { noLabel: true, maxLength: 50 },
                placeholder: '앞에 들어가는말',
            });
            this.suffixInput = new fields_1.TextFieldWithUI(`suffix`, () => {
                var _a, _b;
                return (_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.postfix) !== null && _b !== void 0 ? _b : '';
            }, v => null, {
                options: { noLabel: true, maxLength: 50 },
                placeholder: '뒤에 들어가는말',
            });
        };
        this.generateTextFields(this.st);
        (0, mobx_1.makeObservable)(this, {
            textSizeInput: mobx_1.observable,
            prefixInput: mobx_1.observable,
            suffixInput: mobx_1.observable,
        });
    }
    // get isValidAll() {
    //   if (this.keyboardField.value === 'number') {
    //     return super.isValidAll
    //   }
    //   return super.isValidAll
    // }
    cancelEditing() {
        // TODO FIeld들 업데이트 처리 ㅎ야함.
        super.cancelEditing();
        this.updateData(this.st);
    }
    updateData(data) {
        var _a, _b;
        super.updateData(data);
        if (data.data) {
            this.generateTextFields(data);
        }
        (_b = (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.reLayoutFlowChartElements) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    getDataForSubmit() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        const keyboardType = ((_b = (_a = this.keyboardField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 'general');
        const size = Number((_c = this.textSizeInput) === null || _c === void 0 ? void 0 : _c.value);
        if (!this.isFirstCreated) {
            return {
                type: 'template',
            };
        }
        return {
            type: 'template',
            keyboard: keyboardType,
            linkAttributes: ((_d = this.saveProps) === null || _d === void 0 ? void 0 : _d.length) > 0
                ? (_e = this.saveProps) === null || _e === void 0 ? void 0 : _e.map(item => item === null || item === void 0 ? void 0 : item.getDataForSubmit())
                : [],
            linkBlocks: ((_g = (_f = this.messageNext) === null || _f === void 0 ? void 0 : _f.getDataForSubmit().linkBlocks) !== null && _g !== void 0 ? _g : []).length > 0
                ? (_h = this.messageNext) === null || _h === void 0 ? void 0 : _h.getDataForSubmit().linkBlocks
                : undefined,
            rangeEnd: keyboardType === 'number'
                ? (_j = this.rangeEndField.value) !== null && _j !== void 0 ? _j : null
                : undefined,
            rangeStart: keyboardType === 'number'
                ? (_k = this.rangeStartField.value) !== null && _k !== void 0 ? _k : null
                : undefined,
            prefix: ((_l = this.prefixInput) === null || _l === void 0 ? void 0 : _l.value) === '' ? undefined : (_m = this.prefixInput) === null || _m === void 0 ? void 0 : _m.value,
            postfix: ((_o = this.suffixInput) === null || _o === void 0 ? void 0 : _o.value) === '' ? undefined : (_p = this.suffixInput) === null || _p === void 0 ? void 0 : _p.value,
            size: Number.isNaN(size) ? 6 : size || 1,
        };
    }
}
exports.DOSTHbMessageTemplate = DOSTHbMessageTemplate;
