import { USER_LANGUAGE } from './index'
import { RS } from './ODResources'
import { KO_STRINGS } from './strings/ko'

/**
 * 번역된 텍스트를 반환한다.
 * 실제로는 이 함수를 활용할 필요가 없고 ResourceProvider 를 이용하도록 한다.
 */
export function getStringResource(lang: USER_LANGUAGE, key: RS): string {
  const missing = `Missing trans (${USER_LANGUAGE[lang]}[${lang}]:${key}[${RS[key]}])`

  switch (lang) {
    case USER_LANGUAGE.KR:
      return KO_STRINGS.hasOwnProperty(key) ? KO_STRINGS[key] : missing
    default:
      return missing
  }
}
