import { I_OD_ADMIN_OPTIONS, ODWebUtils } from '@odc/od-react-belt'
import { ToastOptions } from 'react-toastify'
import { LOCAL_STORAGE_KEY_TOKEN } from '../common'

export interface APP_OPTIONS extends I_OD_ADMIN_OPTIONS {
  SERVER_ADDRESS: string
  WS_SERVER_ADDRESS: string
  SHOW_BUILD_INFO: boolean
  GOOGLE_CLIENT_ID: string
  GOOGLE_API_KEY: string
  SIMULATE_DELAY: number
  TOAST_ERROR_OPTIONS: Partial<ToastOptions>
  TOAST_INFO_OPTIONS: Partial<ToastOptions>
  TOAST_SUCCESS_OPTIONS: Partial<ToastOptions>
  FILL_MOCK_DATA: boolean
  SHOW_DEV_MENU: boolean
  STUDIO_WEB_URL: string
  SITE_MANUAL_URL?: string
}

const DEF_TOAST_OPTIONS = { autoClose: 5000 }

const DevAppOptions: APP_OPTIONS = {
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS: 'http://localhost:8082',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  GOOGLE_CLIENT_ID:
    '911452634253-gn9gkabfu1hmbe429j8k703b978cqmie.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyDXtyyBdr-yzaHqNpBKR9ClU8LTyfwqxUk',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: true,
  SHOW_DEV_MENU: true,
  STUDIO_WEB_URL: 'http://localhost:3031',
  SITE_MANUAL_URL:
    'https://www.notion.so/thingsflow/965eb50f230940d6a3d454039e12a4ec',
}

const PlatformDevAppOptions: APP_OPTIONS = {
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS: 'https://platform-api.dev.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  GOOGLE_CLIENT_ID:
    '66077674487-kbj7dhdh2h6ajts3ccs0mhdftv5m2lm8.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyCJAqQC4hbtdvxFhPRve6J-WdsBNKYGWCo',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: false,
  SHOW_DEV_MENU: false,
  STUDIO_WEB_URL: 'https://studio.dev.storyplay.com',
  SITE_MANUAL_URL:
    'https://www.notion.so/thingsflow/965eb50f230940d6a3d454039e12a4ec',
}

const ContentDevAppOptions: APP_OPTIONS = {
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS: 'https://content-api.dev.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  GOOGLE_CLIENT_ID:
    '66077674487-csnkpb1nskqaiph1v0bl5isbhal98v3m.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyCJAqQC4hbtdvxFhPRve6J-WdsBNKYGWCo',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: false,
  SHOW_DEV_MENU: false,
  STUDIO_WEB_URL: 'https://studio.dev.storyplay.com',
  SITE_MANUAL_URL:
    'https://www.notion.so/thingsflow/965eb50f230940d6a3d454039e12a4ec',
}

const QAAppOptions: APP_OPTIONS = {
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS: 'https://api.dev.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  GOOGLE_CLIENT_ID:
    '213722218875-f4ohbasstcp8in8s7aiak23389ig823r.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyDn7470VQIjCh55AXp2POlhHm1bECRJ1Wk',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: false,
  SHOW_DEV_MENU: false,
  STUDIO_WEB_URL: 'https://studio.dev.storyplay.com',
  SITE_MANUAL_URL:
    'https://www.notion.so/thingsflow/965eb50f230940d6a3d454039e12a4ec',
}

const ProdAppOptions: APP_OPTIONS = {
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS: 'https://api.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: false,
  GOOGLE_CLIENT_ID:
    '213722218875-ohf5k9g3lhoudbl6ulhbomit7uotu8mh.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyDn7470VQIjCh55AXp2POlhHm1bECRJ1Wk',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: false,
  SHOW_DEV_MENU: false,
  STUDIO_WEB_URL: 'https://studio.storyplay.com',
}

const PlatformStageAppOptions: APP_OPTIONS = {
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS: 'https://platform-api.stg.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: false,
  GOOGLE_CLIENT_ID:
    '213722218875-ohf5k9g3lhoudbl6ulhbomit7uotu8mh.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyDn7470VQIjCh55AXp2POlhHm1bECRJ1Wk',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: false,
  SHOW_DEV_MENU: false,
  STUDIO_WEB_URL: 'https://studio.storyplay.com',
}

type APP_ENV = 'prod' | 'qa' | 'stg' | undefined
type SITE_TYPE = 'platform' | 'content' | undefined

const appOption = (): APP_OPTIONS => {
  const appEnv: APP_ENV = process.env.REACT_APP_ENV as APP_ENV
  const siteType: SITE_TYPE = process.env.REACT_APP_SITE_TYPE as SITE_TYPE

  switch (appEnv) {
    case 'prod': {
      return ProdAppOptions
    }
    case 'stg': {
      if (siteType === 'platform') {
        return PlatformStageAppOptions
      }
      return DevAppOptions
    }
    case 'qa': {
      if (siteType === 'platform') {
        return PlatformDevAppOptions
      }
      if (siteType === 'content') {
        return ContentDevAppOptions
      }
      // default
      return QAAppOptions
    }

    default:
      return DevAppOptions
  }
}

export const AppOptions = appOption()

ODWebUtils.setAppOptions(AppOptions)
