import React from 'react'
import Select from 'react-select'
import { LEDGER_AMOUNT_FILTER_FIELD } from '../../common'

export type UserLedgerSelectOptionLabel =
  | '모든 금액'
  | '사용 금액'
  | '충전 금액'

interface IUserLedgerAmountSelectProps {
  defaultLabel: UserLedgerSelectOptionLabel
  onChange: (value: LEDGER_AMOUNT_FILTER_FIELD | null) => void
}

export const UserLedgerAmountSelect: React.FC<IUserLedgerAmountSelectProps> = (props) => {
  const { defaultLabel, onChange } = props

  const selectOptions = [
    {
      label: '모든 금액',
      value: null,
    },
    {
      label: '사용 금액',
      value: LEDGER_AMOUNT_FILTER_FIELD.MinusAmount
    },
    {
      label: '충전 금액',
      value: LEDGER_AMOUNT_FILTER_FIELD.PlusAmount
    },
  ]

  return (
    <Select
      placeholder="회차"
      defaultValue={selectOptions.find((v) => v.label === defaultLabel)}
      // @ts-ignore
      onChange={(v) => onChange(v.value)}
      options={selectOptions}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
