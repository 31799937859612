import { ODListableResponseType } from '@odc/od-react-belt'
import { GQLChoiceFromPlayerClass } from '@storyplay/common'
import React from 'react'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IChoiceFromPlayerClassListableOption } from '../ChoiceFromPlayerClassCommon'

export function useChoiceFromPlayerClassListDataLoader(
  choiceId: number,
  playerClassId: number | undefined
) {
  const { listChoiceFromPlayerClass } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IChoiceFromPlayerClassListableOption
    ): Promise<ODListableResponseType<GQLChoiceFromPlayerClass>> {
      const r = await listChoiceFromPlayerClass({
        page,
        pageSize: 40,
        ...options,
        choiceId,
        playerClassId,
      })
      return r as ODListableResponseType<GQLChoiceFromPlayerClass>
    },
    [listChoiceFromPlayerClass, choiceId, playerClassId]
  )
}
