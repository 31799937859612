import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  useODEntityAPI,
} from '@odc/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import styled from 'styled-components'
import { GQLEmailSignupByAdmin, GQLUser } from '../../@types/server'
import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
} from '../../components/base/ODButton'
import { SPFormToggleButton } from '../../components/SPFormToggleButton'
import { useAppContext } from '../../context/AppContext'
import {
  GQL_DEACTIVATE_ACCOUNT_BY_ADMIN,
  GQL_GET_USER_BY_ADMIN,
  GQL_REGISTER_USER_BY_ADMIN,
  GQL_UPDATE_USER_BY_ADMIN,
} from '../../hooks/gqls_admin'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../urls'
import { USER_PRIV } from '../../utils/constants'
import { SSO_PROVIDER_STRING, Utils } from '../../utils/utils'
import { ICTUser, UserPrimaryKey } from './UserCommon'
import { UserAddCoinModal } from './UserAddCoinModal'
import { UserLedgerListableTable } from './list/UserLedgerListableTable'
import { ODFormToggleButton } from '../../components/ODFormToggleButton'

interface IUserEditContainerProps {
  userId: number | null
}

const DEFAULT_USER_DATA: Partial<ICTUser> = {
  userId: 0,
  email: '',
  password: '',
  name: '',
}

export const UserEditContainer: React.FC<IUserEditContainerProps> = (props) => {
  const { userId } = props
  const { getUserMetricById, updateUserByAdmin } = useStoryPlayAPIs()
  const apis = useODEntityAPI<GQLUser, ICTUser, GQLEmailSignupByAdmin, any>({
    createGQL: gql(GQL_REGISTER_USER_BY_ADMIN),
    updateGQL: gql(GQL_UPDATE_USER_BY_ADMIN),
    readGQL: gql(GQL_GET_USER_BY_ADMIN),
    deleteGQL: gql(GQL_DEACTIVATE_ACCOUNT_BY_ADMIN),
    primaryKeyName: UserPrimaryKey,
  })

  const {
    state: { userProfile },
  } = useAppContext()
  const hasUserMetricPriv = (userProfile?.priv ?? 0) >= USER_PRIV.Admin

  const [metric, setMetric] = React.useState('')

  const handleShowBraze = async () => {
    const userMetric = await getUserMetricById({ id: userId! })
    setMetric(JSON.stringify(userMetric, null, 2))
  }
  const [showAddCoinModal, setAddCoinModal] = React.useState(false)
  const [addedCoin, setAddedCoin] = React.useState(0)

  const resetOTPKey = async () => {
    await updateUserByAdmin({
      id: userId!,
      otpKey: null,
    })
    Utils.showSuccess('사용자의 OTP정보를 초기화 하였습니다.')
  }

  return (
    <Container style={{ padding: 0, margin: 0, maxWidth: '100%' }}>
      <Card>
        <CardBody>
          <ODEntity
            resourceId={userId}
            // @ts-ignore
            api={apis}
            saveButtonName="저장"
            titleCreation="추가"
            titleUpdate="수정"
            createSuccessTitle="성공"
            updateSuccessTitle="성공"
            updateSuccessMessage="수정하였습니다."
            urlAfterCreation={() => SiteUrls.Admin.User.Main}
            urlAfterDelete={(item: ICTUser) => SiteUrls.Admin.User.List}
            deleteSuccessTitle="성공"
            deleteConfirmOptions={{
              message: (
                <span>
                  정말 탈퇴처리 하시겠습니까?
                  <br />
                  되돌릴 수 없습니다.
                </span>
              ),
              yes: '탈퇴처리',
              no: '취소',
            }}
            deleteSuccessMessage="사용자를 탈퇴처리 하였습니다."
            deleteButtonName="탈퇴 처리하기"
            defaultCreateClientData={DEFAULT_USER_DATA}
            urlAfterUpdate={() => {
              setAddedCoin(0)
              return null
            }}
            noCardWrap
            footerProps={{ buttonStyle: { borderRadius: 9999 } }}
            onBeforeSubmit={(data) => {
              if (userId) {
                return true
              }

              if (!data.priv) {
                Utils.showError('권한을 선택해주세요.')
                return false
              }

              if (!data.email) {
                Utils.showError('이메일을 입력해주세요.')
                return false
              }

              if (data.password.length === 0) {
                Utils.showError('비밀번호를 입력해주세요.')
                return false
              }

              if (!data.name) {
                Utils.showError('이름을 입력해주세요.')
                return false
              }

              return true
            }}
          >
            {userId && (
              <>
                <ODEntityLabeled name="일련번호" label="일련번호">
                  <ODEntityRaw
                    name="userId"
                    keyPath="userId"
                    render={({ value }) => {
                      return (
                        <div style={{ marginTop: 7 }}>{value ?? '없음'}</div>
                      )
                    }}
                  />
                </ODEntityLabeled>
                <ODEntityLabeled name="이메일" label="이메일">
                  <ODEntityRaw
                    name="email"
                    keyPath="email"
                    render={({ value }) => {
                      return <span>{value}</span>
                    }}
                  />
                </ODEntityLabeled>
                <ODEntityLabeled name="생년월일" label="생년월일">
                  <ODEntityRaw
                    name="birthDateToken"
                    keyPath="birthDateToken"
                    render={({ value }) => {
                      return <span>{value}</span>
                    }}
                  />
                </ODEntityLabeled>
                <ODEntityInput
                  keyPath="name"
                  label="이름"
                  name="name"
                  placeholder="이름을 입력해주세요."
                  inputType="text"
                />
                <ODEntityLabeled name="연동 SNS" label="연동 SNS">
                  <ODEntityRaw
                    name="ssoLogins"
                    keyPath="ssoLogins"
                    render={({ value }) => {
                      return (
                        <div style={{ marginTop: 7 }}>
                          {value
                            .map(
                              (sso: any) => SSO_PROVIDER_STRING[sso.ssoProvider]
                            )
                            .join(',') || '없음'}
                        </div>
                      )
                    }}
                  />
                </ODEntityLabeled>
                <FormGroup row>
                  <Col md={3}>
                    <Label htmlFor={`sheetId-input`} style={{ paddingTop: 7 }}>
                      티켓 개수
                    </Label>
                  </Col>
                  <Col md={2}>
                    <ODEntityRaw
                      name="currentCoin"
                      keyPath="currentCoin"
                      render={({ value }) => {
                        value = value + addedCoin
                        return (
                          <div style={{ marginTop: 7 }}>
                            {value ? `${value} 개` : '없음'}
                          </div>
                        )
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <ODButton
                      theme={ODButtonTheme.Primary}
                      size={ODButtonSize.Small}
                      onClick={() => {
                        setAddCoinModal(true)
                      }}
                    >
                      티켓 충전 하기
                    </ODButton>
                  </Col>
                </FormGroup>
                {showAddCoinModal && userId && (
                  <UserAddCoinModal
                    userId={userId}
                    onClose={() => {
                      setAddCoinModal(false)
                    }}
                    onConfirm={(value) => {
                      setAddCoinModal(false)
                      const coinResult = addedCoin + value
                      setAddedCoin(coinResult)
                    }}
                  />
                )}
                <ODEntityLabeled
                  label="OTP 정보 초기화 하기"
                  name="OTP 정보 초기화 하기"
                >
                  <ODButton
                    theme={ODButtonTheme.Primary}
                    size={ODButtonSize.Small}
                    onClick={resetOTPKey}
                  >
                    초기화 하기
                  </ODButton>
                </ODEntityLabeled>
                <ODEntityLabeled
                  label="스튜디오 엑셀 내보내기 가능 여부"
                  name="스튜디오 엑셀 내보내기 가능 여부"
                >
                  <ToggleButtonWrapper>
                    <ODFormToggleButton
                      key="canExportStory_true"
                      name="canExportStory"
                      keyPath="canExportStory"
                      value={true}
                    >
                      Y
                    </ODFormToggleButton>
                    <ODFormToggleButton
                      key="canExportStory_false"
                      name="canExportStory"
                      keyPath="canExportStory"
                      value={false}
                    >
                      N
                    </ODFormToggleButton>
                  </ToggleButtonWrapper>
                </ODEntityLabeled>
                <ODEntityLabeled name="가입일" label="가입일">
                  <ODEntityRaw
                    name="registeredAt"
                    keyPath="registeredAt"
                    render={({ value }) => {
                      return (
                        <div style={{ marginTop: 7 }}>
                          {value ? Utils.formatDate(value) : '없음'}
                        </div>
                      )
                    }}
                  />
                </ODEntityLabeled>
                <ODEntityLabeled name="마지막접속" label="마지막접속">
                  <ODEntityRaw
                    name="lastAccessTime"
                    keyPath="lastAccessTime"
                    render={({ value }) => {
                      return (
                        <div style={{ marginTop: 7 }}>
                          {value ? Utils.formatDate(value) : '없음'}
                        </div>
                      )
                    }}
                  />
                </ODEntityLabeled>
                {userId && hasUserMetricPriv && (
                  <ODEntityLabeled
                    name="브레이즈 매트릭 체크"
                    label="브레이즈 매트릭 체크"
                  >
                    <ODEntityRaw
                      name="userMetric"
                      keyPath="userMetric"
                      render={() => {
                        return (
                          <ODButton
                            theme={ODButtonTheme.Primary}
                            onClick={handleShowBraze}
                          >
                            브레이즈 매트릭 확인하기
                          </ODButton>
                        )
                      }}
                    />
                    <br />
                    {metric && (
                      <Input
                        style={{ marginTop: 20 }}
                        type="textarea"
                        value={metric}
                        disabled
                        rows={20}
                      />
                    )}
                  </ODEntityLabeled>
                )}
              </>
            )}
            {!userId && (
              <>
                <ODEntityInput
                  keyPath="email"
                  label="이메일"
                  name="email"
                  placeholder="이메일을 입력해주세요."
                  inputType="email"
                />
                <ODEntityInput
                  keyPath="name"
                  label="이름"
                  name="name"
                  placeholder="이름을 입력해주세요."
                  inputType="text"
                />
                <ODEntityInput
                  keyPath="password"
                  label="비밀번호"
                  name="password"
                  placeholder="비밀번호를 입력해주세요."
                  inputType="password"
                />
                <ODEntityLabeled label="권한" name="권한">
                  <ToggleButtonWrapper>
                    <SPFormToggleButton
                      name="priv"
                      keyPath="priv"
                      value={USER_PRIV.Formal}
                    >
                      일반 가입자
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="priv"
                      keyPath="priv"
                      value={USER_PRIV.Virtual}
                    >
                      가상 유저
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="priv"
                      keyPath="priv"
                      value={USER_PRIV.Author}
                    >
                      작가
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="priv"
                      keyPath="priv"
                      value={USER_PRIV.SuperAuthor}
                    >
                      슈퍼작가
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="priv"
                      keyPath="priv"
                      value={USER_PRIV.Admin}
                    >
                      어드민
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="priv"
                      keyPath="priv"
                      value={USER_PRIV.SuperAdmin}
                    >
                      슈퍼 어드민
                    </SPFormToggleButton>
                  </ToggleButtonWrapper>
                </ODEntityLabeled>
              </>
            )}
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <hr />
            </div>
          </ODEntity>
        </CardBody>
      </Card>
      {userId && (
        <Card>
          <CardBody>
            <div style={{ marginTop: 0 }}>
              <h1>사용자 티켓 사용 이력</h1>
              <p style={{ height: 10 }} />
              <UserLedgerListableTable
                userId={userId}
              ></UserLedgerListableTable>
            </div>
          </CardBody>
        </Card>
      )}
    </Container>
  )
}

const ToggleButtonWrapper = styled.div`
  display: flex;
`
