"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOChrEditStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const validations_1 = require("../../../validations");
const DOChrOnboardingPopupEditor_1 = require("../onboardingPopup/DOChrOnboardingPopupEditor");
const ui_1 = require("../ui");
const DOChr_1 = require("./DOChr");
const IDOChr_1 = require("./IDOChr");
const StudioFileFieldWithUI_1 = require("../fields/StudioFileFieldWithUI");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOChrEditStore {
    constructor(chr, story) {
        var _a, _b, _c, _d, _e, _f;
        this.isSubmitting = false;
        this.chrImageFile = null;
        this.portraitChrImageFile = null;
        this.story = story;
        this.rootStore = story.rootStore;
        this.chr = chr;
        this.onboardingPopupEditor = chr
            ? new DOChrOnboardingPopupEditor_1.DOChrOnboardingPopupEditor(this, chr)
            : null;
        this.name = new ui_1.InputWithValidation('name', chr
            ? trans('legacy_DOChrEditStore_fixed_character_name')
            : trans('legacy_DOChrEditStore_character_name'), trans('legacy_DOChrEditStore_character_name'), 'text', (_a = chr === null || chr === void 0 ? void 0 : chr.name) !== null && _a !== void 0 ? _a : '', validations_1.ChrValidations.name, {
            noLabel: false,
            enableInPlaceEditing: false,
        });
        this.isMain = new ui_1.SelectionInput('isMain', trans('legacy_DOChrEditStore_main_character'), // 주연 여부,
        (_c = (_b = this.chr) === null || _b === void 0 ? void 0 : _b.isMain) !== null && _c !== void 0 ? _c : false, [
            {
                name: trans('legacy_DOChrEditStore_main'),
                value: true,
                description: '',
            },
            {
                name: trans('legacy_DOChrEditStore_assistant'),
                value: false,
                description: '',
            }, // { name: '보조', value: false, description: '' },
        ], { creatable: false });
        this.displayName = new ui_1.InputWithValidation('displayName', trans('legacy_DOChrEditStore_visible_character_name'), // 보여지는 캐릭터 이름,
        trans('legacy_DOChrEditStore_enter_visible_name'), // '사용자에게 노출될 이름을 입력해주세요',
        'text', (_d = chr === null || chr === void 0 ? void 0 : chr.displayName) !== null && _d !== void 0 ? _d : '', validations_1.ChrValidations.name, {
            noLabel: false,
        });
        this.defaultFirstName = new ui_1.InputWithValidation('defaultFirstName', trans('legacy_DOChrEditStore_default_name'), // '디폴트 이름',
        '', 'text', (_e = chr === null || chr === void 0 ? void 0 : chr.defaultFirstName) !== null && _e !== void 0 ? _e : '').setRequired(true);
        this.defaultLastName = new ui_1.InputWithValidation('defaultLastName', trans('legacy_DOChrEditStore_default_gender'), // '디폴트 성',
        '', 'text', (_f = chr === null || chr === void 0 ? void 0 : chr.defaultLastName) !== null && _f !== void 0 ? _f : '').setRequired(true);
        this.portraitFileField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('portraitFile', () => {
            var _a, _b, _c;
            return ({
                link: (_c = (_b = (_a = this.chr) === null || _a === void 0 ? void 0 : _a.portraitFile) === null || _b === void 0 ? void 0 : _b.link) !== null && _c !== void 0 ? _c : '',
            });
        }, () => null, this.story, {
            label: '상반신 이미지',
            options: {
                showLabel: true,
                theme: ui_1.FileTheme.BackgroundChrImage,
                accept: 'image/png, image/jpeg',
                aspectRatio: { w: 1, h: 1 },
            },
        });
        this.portraitFileField.onChange = newValue => {
            (0, mobx_1.runInAction)(() => {
                this.portraitFileField.value = newValue;
            });
        };
        (0, mobx_1.makeObservable)(this, {
            chrImageFile: mobx_1.observable,
            isSubmitting: mobx_1.observable,
            isCreating: mobx_1.computed,
            chrImageLink: mobx_1.computed,
            portraitChrImageLink: mobx_1.computed,
        });
    }
    onChangeChrImageFile(studioFile) {
        (0, mobx_1.runInAction)(() => {
            this.chrImageFile = studioFile;
        });
    }
    get chrImageLink() {
        var _a, _b, _c, _d, _e;
        return (_e = (_b = (_a = this.chrImageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : (_d = (_c = this.chr) === null || _c === void 0 ? void 0 : _c.imageFile) === null || _d === void 0 ? void 0 : _d.link) !== null && _e !== void 0 ? _e : null;
    }
    onChangePortraitChrImageFile(studioFile) {
        (0, mobx_1.runInAction)(() => {
            this.portraitFileField.value = studioFile;
        });
    }
    get portraitChrImageLink() {
        var _a, _b, _c, _d, _e;
        return ((_e = (_b = (_a = this.portraitFileField.value) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : (_d = (_c = this.chr) === null || _c === void 0 ? void 0 : _c.portraitFile) === null || _d === void 0 ? void 0 : _d.link) !== null && _e !== void 0 ? _e : null);
    }
    submit(onFinish) {
        // TODO: SPDEV-4325 썸네일형 이미지 필수로 입력받도록 처리하기
        // 지금은 name 만 검사하면 되지만,
        // 여러 개의 필드를 검사해야 하는 경우에는 한 번에 처리할 수 있는
        // 공용으로 사용할 수 있는 메서드를 구현하기
        const isPrepared = this.name.prepareSubmit();
        if (!isPrepared) {
            this.rootStore.showError(this.name.validationError);
            return;
        }
        if (this.isSubmitting) {
            return;
        }
        (0, mobx_1.runInAction)(() => (this.isSubmitting = true));
        return this.processSubmit()
            .then(() => onFinish === null || onFinish === void 0 ? void 0 : onFinish(null), err => {
            if (onFinish) {
                onFinish === null || onFinish === void 0 ? void 0 : onFinish(err);
            }
            else {
                this.story.rootStore.showError(err);
            }
        })
            .finally(() => {
            (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
        });
    }
    async processSubmit() {
        var _a, _b, _c, _d, _e, _f;
        if (this.name.value.trim().length === 0) {
            throw new Error(trans('legacy_DOChrEditStore_wrong_name'));
        }
        const file = this.chrImageFile;
        const portraitFile = this.portraitFileField.value;
        if (!this.isCreating) {
            if (this.chr.id === IDOChr_1.HERO_CHR_ID) {
                if (file) {
                    await this.story.mainCharacter.updateImage(file);
                }
                const c = this.chr;
                await c.update({
                    defaultFirstName: this.defaultFirstName.value,
                    defaultLastName: ((_a = c.onboardingEditor) === null || _a === void 0 ? void 0 : _a.isOnlyName)
                        ? ''
                        : this.defaultLastName.value,
                    mainCharacterImageStudioFileId: (_b = file === null || file === void 0 ? void 0 : file.fileId) !== null && _b !== void 0 ? _b : null,
                    mainCharacterPortraitStudioFileId: (_c = portraitFile === null || portraitFile === void 0 ? void 0 : portraitFile.fileId) !== null && _c !== void 0 ? _c : null,
                });
                await ((_d = c.onboardingEditor) === null || _d === void 0 ? void 0 : _d.submit());
            }
            else {
                const c = this.chr;
                const res = await c.update({
                    name: this.name.value.trim(),
                    color: '#000000',
                    main: this.isMain.value,
                    imageFromStudioFileId: (_e = file === null || file === void 0 ? void 0 : file.fileId) !== null && _e !== void 0 ? _e : null,
                    portraitFromStudioFileId: (_f = portraitFile === null || portraitFile === void 0 ? void 0 : portraitFile.fileId) !== null && _f !== void 0 ? _f : null,
                    displayName: this.displayName.value.trim(),
                    defaultFirstName: this.defaultFirstName.value.trim(),
                    defaultLastName: this.defaultLastName.value.trim(),
                });
                this.story.chrStore.merge(res);
                if (this.onboardingPopupEditor) {
                    await this.onboardingPopupEditor.submit();
                }
            }
        }
        else {
            const chr = new DOChr_1.DOChr(this.story.chrStore, {
                chrId: this.story.generateChrId(),
                imageFile: file,
                portraitFile: portraitFile,
                name: this.name.value.trim(),
                color: '#000000',
                main: this.isMain.value,
            });
            await chr.create();
        }
    }
    get isCreating() {
        return !this.chr;
    }
}
exports.DOChrEditStore = DOChrEditStore;
