import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListableOption,
  useODQuery,
} from '@odc/od-react-belt'
import {
  GQLListableStory,
  GQLListableStoryInput,
  GQLRecommendSectionStory,
} from '@storyplay/core'
import React from 'react'
import Select from 'react-select'
import { Card, CardBody, Col, Row } from 'reactstrap'
import {
  UseConfirmModalOptions,
  useModalConfirm,
} from '../../components/ODModal/ODModalConfirm'
import { GQL_LIST_STORY_FOR_FEATURED } from '../../hooks/gqls_admin'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { Utils } from '../../utils/utils'

interface IRecommendSectionStoryEditPageProps {
  sectionId: number
}

type Entity = GQLRecommendSectionStory

export interface StoryDataLoaderOption extends ODListableOption {
  filter: string | null
}

export const RecommendSectionStoryEditPage: React.FC<
  IRecommendSectionStoryEditPageProps
> = (props) => {
  const { sectionId } = props
  const {
    listRecommendSectionStory: apiList,
    createRecommendSectionStory: apiCreate,
    deleteRecommendSectionStory: apiDelete,
  } = useStoryPlayAPIs()
  const apiListStory = useODQuery<
    Partial<GQLListableStoryInput>,
    GQLListableStory
  >(GQL_LIST_STORY_FOR_FEATURED)

  const [storyOptions, setStoryOptions] = React.useState<
    { label: string; value: number }[]
  >([])

  const [selectedStoryId, setSelectedStoryId] = React.useState(0)
  const [filter, setFilter] = React.useState('')
  const [recommendSectionStoryList, setRecommendSectionStoryList] =
    React.useState<Array<Entity>>([])

  const defaultDeleteConfirmOptions: UseConfirmModalOptions = {
    message: (
      <>
        정말 삭제하시겠습니까?
        <br />
        되돌릴 수 없습니다.
      </>
    ),
    yes: 'Confirm',
    no: 'Cancel',
  }

  const {
    Component: DialogComponent,
    confirm,
    props: confirmComponentProps,
  } = useModalConfirm(defaultDeleteConfirmOptions)

  const searchStory = React.useCallback(async () => {
    const r = await apiListStory({ pageSize: 100, filter })
    setStoryOptions(
      r.list.map((story) => ({
        label: `${story.name}(ID: ${story.storyId})`,
        value: story.storyId,
      }))
    )
  }, [apiListStory, filter])

  React.useEffect(() => {
    apiList({ pageSize: 1000, sectionId })
      .then((r) => {
        setRecommendSectionStoryList(r.list)
      })
      .catch(Utils.showError)
  })
  return (
    <Card>
      <CardBody>
        <DialogComponent {...confirmComponentProps} />
        <div style={{ marginTop: 0 }}>
          <h3>어드민 추천 스토리 관리</h3>
          <p style={{ height: 10 }} />

          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>
              <input
                style={{ width: '100%' }}
                type="text"
                placeholder="1. 스토리 이름 입력 후 Enter!"
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && e.currentTarget.value != '') {
                    setFilter(e.currentTarget.value)
                    searchStory()
                  }
                }}
              />
            </Col>
            <Col md={4}>
              {!!storyOptions.length && (
                <Select
                  placeholder="2. 섹션에 추가할 스토리를 선택하세요"
                  // @ts-ignore
                  onChange={(v) => setSelectedStoryId(v.value)}
                  options={storyOptions}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 2 }),
                  }}
                />
              )}
            </Col>
            <Col md={4}>
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{ minWidth: 135, borderRadius: 5 }}
                onClick={() => {
                  apiCreate({
                    sectionId,
                    recommendStoryId: selectedStoryId,
                  }).catch(Utils.showError)
                }}
              >
                {'추가'}
              </ODButton>
            </Col>
          </Row>

          {recommendSectionStoryList.map((s) => (
            <Row style={{ marginBottom: 20 }}  key={s.recommendSectionStoryId}>
              <Col md={2}>{s.recommendSectionStoryId}</Col>
              <Col
                md={5}
              >{`[${s.recommendStoryId}] ${s.recommendStory.name}`}</Col>
              <Col md={3}>
                <ODButton
                  theme={ODButtonTheme.PrimaryInvert}
                  size={ODButtonSize.Small}
                  style={{ minWidth: 135, borderRadius: 5 }}
                  onClick={() => {
                    confirm().then((ok) =>
                      ok
                        ? apiDelete({
                            id: s.recommendSectionStoryId,
                          })
                            .then(() => {
                              const index = recommendSectionStoryList.findIndex(
                                (r: GQLRecommendSectionStory) =>
                                  r.recommendSectionStoryId ===
                                  s.recommendSectionStoryId
                              )
                              if (index > -1)
                                recommendSectionStoryList.splice(index, 1)
                              setRecommendSectionStoryList([
                                ...recommendSectionStoryList,
                              ])
                            })
                            .catch(Utils.showError)
                        : undefined
                    )
                  }}
                >
                  {'삭제'}
                </ODButton>
              </Col>
            </Row>
          ))}
        </div>
      </CardBody>
    </Card>
  )
}
