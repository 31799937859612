import { ODListableResponseType } from '@odc/od-react-belt'
import { GQLPlayerClass } from '@storyplay/common'
import React from 'react'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IPlayerClassListableOption } from '../PlayerClassCommon'

export function usePlayerClassListDataLoader(storyId: number) {
  const { listPlayerClass } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IPlayerClassListableOption
    ): Promise<ODListableResponseType<GQLPlayerClass>> {
      const r = await listPlayerClass({
        page,
        pageSize: 10,
        ...options,
        storyId,
      })
      return r as ODListableResponseType<GQLPlayerClass>
    },
    [listPlayerClass, storyId]
  )
}
