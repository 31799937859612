"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbText = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const shortcutUtils_1 = require("../../../../../utils/shortcutUtils");
const ui_1 = require("../../../../ui");
const DOSTHbBase_1 = require("../../../DOSTHbBase");
class DOSTHbText extends DOSTHbBase_1.DOSTHbBase {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbText,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.initInputs(this.st.data);
        (0, mobx_1.makeObservable)(this, {
            textInput: mobx_1.observable,
        });
    }
    async submitEditing(opFactory) {
        var _a;
        const r = await super.submitEditing(opFactory);
        this.textInput.setEditable(false);
        this.textInput.setEditing(false);
        this.updateData((_a = this.st.hbExtensionData) !== null && _a !== void 0 ? _a : {});
        return r;
    }
    get isValidAll() {
        return !!this.textInput.value.trim();
    }
    startEditing() {
        this.textInput.setEditable(true);
        this.textInput.setEditing(true);
        super.startEditing();
    }
    cancelEditing() {
        var _a;
        this.textInput.setEditable(false);
        this.textInput.setEditing(false);
        this.updateData((_a = this.st.hbExtensionData) !== null && _a !== void 0 ? _a : {});
        super.cancelEditing();
    }
    onInputMount(provider, inputRef) {
        return (0, shortcutUtils_1.bindItems)(provider, () => true, bind => {
            bind(shortcutUtils_1.StudioShortcut.SubmitEditing, () => this.submitEditing());
            bind(shortcutUtils_1.StudioShortcut.CancelEditing, () => this.cancelEditing());
        });
    }
    getDataForSubmit() {
        var _a, _b;
        return {
            type: 'text',
            text: (_b = (_a = this.textInput) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '',
        };
    }
    updateData(data) {
        var _a;
        super.updateData(data);
        this.initInputs((_a = data.data) !== null && _a !== void 0 ? _a : {});
    }
    initInputs(data) {
        (0, mobx_1.runInAction)(() => {
            var _a;
            this.textInput = new ui_1.InputWithValidation('hb_text', 'hb_text', '', 'text', (_a = data.text) !== null && _a !== void 0 ? _a : '', null, {
                noLabel: true,
            });
        });
    }
}
exports.DOSTHbText = DOSTHbText;
