"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermUtils = void 0;
const assert = require("assert");
const lodash_1 = require("lodash");
const permissionConsts_1 = require("./permissionConsts");
class PermUtils {
    constructor(userPriv, schema) {
        this.hasChange = false;
        this.userPriv = userPriv;
        if (schema) {
            this.schema = (0, lodash_1.isString)(schema) ? JSON.parse(schema) : schema;
        }
        else {
            this.schema = {
                version: permissionConsts_1.CURRENT_PERM_VERSION,
                overrides: {},
            };
        }
    }
    static isSchemaJsonValid(schemaJson) {
        try {
            const schema = JSON.parse(schemaJson);
            assert(
            // @ts-ignore
            schema.version && permissionConsts_1.STORY_PERM_VERSION[schema.version], `No valid version string found.`);
            assert(schema.overrides && (0, lodash_1.isObject)(schema.overrides), `No overrides object field.`);
            Object.keys(schema.overrides).forEach(key => {
                // @ts-ignore
                assert(permissionConsts_1.ACTION_ON_BOOK[key], `Unknown override role key : ${key}`);
                assert((0, lodash_1.isBoolean)(schema.overrides[key]), `Invalid override role value : ${schema.overrides[key]}. must be boolean.`);
            });
            return true;
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.error(`Parsing schema json has raised exception : ${ex.message}`);
            return false;
        }
    }
    changeUserPriv(userPriv) {
        this.userPriv = userPriv;
        this.hasChange = true;
    }
    /**
     * 이 사용자가 특정 role 에 대하여 권한이 허용되어 있는가?
     */
    isAllowed(role) {
        if (this.schema.overrides.hasOwnProperty(role)) {
            return !!this.schema.overrides[role];
        }
        return !!permissionConsts_1.DEFAULT_ROLES_PER_STORY_PERM[this.userPriv][role];
    }
    /**
     * 이 role 이 상속에 의한 것인가 아니면 강제 설정에 의해 오버라이드 된 것인가?
     */
    isOverridden(role) {
        return this.schema.overrides.hasOwnProperty(role);
    }
    /**
     * 특정 권한에 대하여 override 를 켜거나 끈다. (켜는 경우, setValue 값을 제공)
     */
    useOverride(role, override, setValue) {
        if (override) {
            assert(setValue !== undefined, `PermUtils.useOverride : invalid value.`);
            this.schema.overrides[role] = setValue;
        }
        else {
            delete this.schema.overrides[role];
        }
        this.hasChange = true;
    }
    migrateIfNeeded() {
        //
    }
    getSchemaAsJson() {
        return JSON.stringify(this.schema);
    }
}
exports.PermUtils = PermUtils;
