import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../../../ODIcon'

type WrapperType = {
  isOpen: boolean
}

const Wrapper = styled.div`
  visibility: ${(p: WrapperType) => (p.isOpen ? 'visible' : 'hidden')};
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  //transform: translate(-50%, 0);
  border: dashed 1px #29b6ca;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

type DragAndDropComponentProps = {
  isOpen: boolean
  onDragLeave?: () => void
  onChange: (file: File, previewUri: string) => void
  toggle: () => void
  onClose: () => void
  style?: object
  accept?: string | string[]
  inputId?: string
}

export const DragAndDropComponent: React.FC<DragAndDropComponentProps> = (
  props
) => {
  const {
    isOpen,
    onChange,
    onDragLeave,
    toggle,
    onClose,
    style = {},
    accept,
    inputId,
  } = props

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDragLeave: () => {
      onDragLeave?.()
    },
    onDrop: (v) => {
      if (v.length === 0) {
        onClose()
        return
      }

      v.forEach((file) => {
        onChange(file, URL.createObjectURL(file))
        toggle()
      })
    },
    accept,
  })

  // 특정 시점 이후 props 관련하여 ts 오류가 발생한다. 회피를 위해 타입을 강제로 any 로 변환하였다.
  return (
    <Wrapper isOpen={isOpen} style={style} {...(getRootProps() as any)}>
      <input id={inputId} {...(getInputProps() as any)} />
      <ODIcon
        icon={ODIcons.CoreImagePlus}
        style={{ fontSize: 50, color: '#28b5c9', marginBottom: 25 }}
      />
    </Wrapper>
  )
}
