"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbMessageAPI = void 0;
class HbMessageAPI {
    constructor(server) {
        this.server = server;
    }
    getPath(isAlgorithm = false) {
        return isAlgorithm ? `/v1/algorithm/cards` : `/v1/messages`;
    }
    async get(blockId) {
        return this.server.get(`/v1/messages/blocks/${blockId}`);
    }
    async create(payload, isAlgorithm) {
        return this.server.post(`${this.getPath(isAlgorithm)}/`, payload);
    }
    async update(id, payload, isAlgorithm) {
        return this.server.put(`${this.getPath(isAlgorithm)}/${id}`, payload);
    }
    async updateTurn(id, turn, isAlgorithm) {
        return this.server.put(`${this.getPath(isAlgorithm)}/turn`, { id, turn });
    }
    async delete(id, isAlgorithm) {
        return this.server.delete(`${this.getPath(isAlgorithm)}/${id}`, {});
    }
    async createButton(id, payload, isAlgorithm) {
        return this.server.post(`${this.getPath(isAlgorithm)}/${id}/buttons`, payload);
    }
    async updateButton(id, payload, isAlgorithm) {
        return this.server.put(`${this.getPath(isAlgorithm)}/${id}/buttons`, payload);
    }
    async deleteButton(id, buttonId, isAlgorithm) {
        return this.server.delete(`${this.getPath(isAlgorithm)}/${id}/buttons/${buttonId}`, {});
    }
}
exports.HbMessageAPI = HbMessageAPI;
