"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeBundleStatement = exports.reduceStatementsToDomainObjects = exports.mapStatementToDomainObject = void 0;
const __1 = require("../../../..");
const studio_1 = require("../../../../studio");
const DOBaseScriptStatement_1 = require("./DOBaseScriptStatement");
const DOSTAlgorithm_1 = require("./script/DOSTAlgorithm");
const DOSTBGMoff_1 = require("./script/DOSTBGMoff");
const DOSTBGMon_1 = require("./script/DOSTBGMon");
const DOSTCallRemoteScript_1 = require("./script/DOSTCallRemoteScript");
const DOSTChoice_1 = require("./script/DOSTChoice");
const DOSTChoiceSaveProp_1 = require("./script/DOSTChoiceSaveProp");
const DOSTChoiceToBlock_1 = require("./script/DOSTChoiceToBlock");
const DOSTCollectionDesc_1 = require("./script/DOSTCollectionDesc");
const DOSTCollectionImage_1 = require("./script/DOSTCollectionImage");
const DOSTCondition_1 = require("./script/DOSTCondition");
const DOSTConditionalChoice_1 = require("./script/DOSTConditionalChoice");
const DOSTEndingSummary_1 = require("./script/DOSTEndingSummary");
const DOSTFinalEnding_1 = require("./script/DOSTFinalEnding");
const DOSTFinishRemoteScript_1 = require("./script/DOSTFinishRemoteScript");
const DOSTFullScreenEffectOff_1 = require("./script/DOSTFullScreenEffectOff");
const DOSTFullScreenEffectOn_1 = require("./script/DOSTFullScreenEffectOn");
const DOSTFullWidthImage_1 = require("./script/DOSTFullWidthImage");
const DOSTFullWidthText_1 = require("./script/DOSTFullWidthText");
const DOSTLeftTalk_1 = require("./script/DOSTLeftTalk");
const DOSTLeftThink_1 = require("./script/DOSTLeftThink");
const DOSTMainCharacterMessageImage_1 = require("./script/DOSTMainCharacterMessageImage");
const DOSTMessageImage_1 = require("./script/DOSTMessageImage");
const DOSTMetaSaveProp_1 = require("./script/DOSTMetaSaveProp");
const DOSTRightTalk_1 = require("./script/DOSTRightTalk");
const DOSTRightThink_1 = require("./script/DOSTRightThink");
const DOSTScript_1 = require("./script/DOSTScript");
const DOSTSoundEffect_1 = require("./script/DOSTSoundEffect");
const DOSTToast_1 = require("./script/DOSTToast");
const DOSTToBlock_1 = require("./script/DOSTToBlock");
const DOSTVibration_1 = require("./script/DOSTVibration");
const DOSTHbMessageNext_1 = require("./script/hellobot/next/DOSTHbMessageNext");
const DOSTCallRemoteScriptSubBlock_1 = require("./script/subBlock/DOSTCallRemoteScriptSubBlock");
const DOSTChoiceSubBlock_1 = require("./script/subBlock/DOSTChoiceSubBlock");
const DOSTConditionSubBlock_1 = require("./script/subBlock/DOSTConditionSubBlock");
const DOSTFinalEndingSubBlock_1 = require("./script/subBlock/DOSTFinalEndingSubBlock");
/**
 * 주어진 문장 데이터 st 를 DOST-* 문장으로 만들어 반환한다.
 * isPasting = true 이면 중복되면 안되는 데이터들을 랜덤하게 생성하여 주입한다.
 */
function mapStatementToDomainObject(st, block, onChapterCustomIdFound, uniqueId, isPasting = false) {
    const di = block.rootStore.di;
    // console.log('test switch name', block.name, st)
    switch (st.statementType) {
        case __1.STATEMENT_TYPE.ChoiceUIThinking:
            break;
        case __1.STATEMENT_TYPE.Condition:
            return new DOSTCondition_1.DOSTCondition(st, block, uniqueId);
        case __1.STATEMENT_TYPE.BackgroundImage:
            return new studio_1.DOSTBackground(st, block, uniqueId);
        case __1.STATEMENT_TYPE.Script:
            return new DOSTScript_1.DOSTScript(st, block, uniqueId);
        case __1.STATEMENT_TYPE.Place:
        case __1.STATEMENT_TYPE.Time: {
            // Place, Time 은 지문으로 변경하기로 하였음.
            return new DOSTScript_1.DOSTScript({
                statementType: __1.STATEMENT_TYPE.Script,
                background: st.background,
                message: st.message,
                sourceLine: st.sourceLine,
            }, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.MainCharacterTalk:
            return new DOSTRightTalk_1.DOSTRightTalk(st, block, uniqueId);
        case __1.STATEMENT_TYPE.MainCharacterThink:
            return new DOSTRightThink_1.DOSTRightThink(st, block, uniqueId);
        case __1.STATEMENT_TYPE.CharacterTalk:
            return new DOSTLeftTalk_1.DOSTLeftTalk(st, block, uniqueId);
        case __1.STATEMENT_TYPE.CharacterThink:
            return new DOSTLeftThink_1.DOSTLeftThink(st, block, uniqueId);
        case __1.STATEMENT_TYPE.Choice:
            return new DOSTChoice_1.DOSTChoice(st, block, uniqueId);
        case __1.STATEMENT_TYPE.ChoiceSaveProp:
            return new DOSTChoiceSaveProp_1.DOSTChoiceSaveProp(st, block, uniqueId);
        case __1.STATEMENT_TYPE.ChoiceToBlock:
            return new DOSTChoiceToBlock_1.DOSTChoiceToBlock(st, block, uniqueId);
        case __1.STATEMENT_TYPE.ChoiceCharacter:
            break;
        case __1.STATEMENT_TYPE.ConditionalChoice:
            return new DOSTConditionalChoice_1.DOSTConditionalChoice(st, block, uniqueId);
        case __1.STATEMENT_TYPE.Algorithm:
            return new DOSTAlgorithm_1.DOSTAlgorithm(st, block, uniqueId);
        case __1.STATEMENT_TYPE.ToBlock:
            return new DOSTToBlock_1.DOSTToBlock(st, block, false /* sub-block merge 시에 변경가능 */, uniqueId);
        case __1.STATEMENT_TYPE.EndingSummary:
            return new DOSTEndingSummary_1.DOSTEndingSummary(st, block, uniqueId);
        case __1.STATEMENT_TYPE.FinalEnding:
            return new DOSTFinalEnding_1.DOSTFinalEnding(st, block, uniqueId);
        case __1.STATEMENT_TYPE.CollectionDesc:
            return new DOSTCollectionDesc_1.DOSTCollectionDesc(st, block, uniqueId);
        case __1.STATEMENT_TYPE.CollectionImage:
            return new DOSTCollectionImage_1.DOSTCollectionImage(st, block, uniqueId);
        case __1.STATEMENT_TYPE.Toast:
            return new DOSTToast_1.DOSTToast(st, block, uniqueId);
        case __1.STATEMENT_TYPE.MessageImage:
            return new DOSTMessageImage_1.DOSTMessageImage(st, block, uniqueId);
        case __1.STATEMENT_TYPE.MainCharacterMessageImage:
            return new DOSTMainCharacterMessageImage_1.DOSTMainCharacterMessageImage(st, block, uniqueId);
        case __1.STATEMENT_TYPE.FullWidthImage:
            return new DOSTFullWidthImage_1.DOSTFullWidthImage(st, block, uniqueId);
        case __1.STATEMENT_TYPE.Vibration:
            return new DOSTVibration_1.DOSTVibration(st, block, uniqueId);
        case __1.STATEMENT_TYPE.SoundEffect:
            return new DOSTSoundEffect_1.DOSTSoundEffect(st, block, uniqueId);
        case __1.STATEMENT_TYPE.FullWidthText:
            return new DOSTFullWidthText_1.DOSTFullWidthText(st, block, uniqueId);
        case __1.STATEMENT_TYPE.BGMon:
            return new DOSTBGMon_1.DOSTBGMon(st, block, uniqueId);
        case __1.STATEMENT_TYPE.BGMoff:
            return new DOSTBGMoff_1.DOSTBGMoff(st, block, uniqueId);
        // SaveProp, AchievementEvent, UpdateItem 은 MetaSaveProp 으로 관리한다.
        case __1.STATEMENT_TYPE.SaveProp:
        case __1.STATEMENT_TYPE.AchievementEvent:
        case __1.STATEMENT_TYPE.UpdateItem:
            return new DOSTMetaSaveProp_1.DOSTMetaSaveProp(st, block, uniqueId);
        // return new DOSTSaveProp(st, block, uniqueId)
        // return new DOSTAchievementEvent(st, block, uniqueId)
        // return new DOSTUpdateItem(st, block, uniqueId)
        case __1.STATEMENT_TYPE.ChapterId:
            // 주의할 점은, 실제로 스크립트에있는 챕터 아이디는 어드민을 통해 import 할 경우 스크립트에
            // 포함되지 않는다는 점이다. 우리는 서버에 저장된 customId 를 활용한다.
            onChapterCustomIdFound(st.customId);
            break;
        case __1.STATEMENT_TYPE.TTSCashing:
            // TTSCaching 구문은 스튜디오에서 매번 적용하기시 새롭게 만들어낸다.
            return null;
        case __1.STATEMENT_TYPE.ChoiceIfAchievementEvent:
        case __1.STATEMENT_TYPE.ChoiceIfUpdateItem:
        case __1.STATEMENT_TYPE.ChoiceIfSaveProp: {
            return new studio_1.DOSTChoiceIf(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.CallRemoteScript: {
            return new DOSTCallRemoteScript_1.DOSTCallRemoteScript({
                ...st,
                ...(isPasting ? { scriptId: di.generateCustomRemoteScriptId() } : {}),
            }, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.FinishRemoteScript:
            return new DOSTFinishRemoteScript_1.DOSTFinishRemoteScript(st, block, uniqueId);
        case __1.STATEMENT_TYPE.FullScreenEffectOn: {
            return new DOSTFullScreenEffectOn_1.DOSTFullScreenEffectOn(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.FullScreenEffectOff: {
            return new DOSTFullScreenEffectOff_1.DOSTFullScreenEffectOff(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.Pause: {
            return new __1.DOSTPause(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbMessageNext: {
            return new DOSTHbMessageNext_1.DOSTHbMessageNext(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbUserSelect: {
            return new __1.DOSTHbMessageSelect(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbUserInput: {
            return new __1.DOSTHbMessageInput(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbUserTarot: {
            return new __1.DOSTHbMessageTarot(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbUserTemplate: {
            return new __1.DOSTHbMessageTemplate(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbUserFeedBack: {
            return new __1.DOSTHbMessageFeedback(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbRest: {
            return new __1.DOSTHbRest(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbText: {
            return new __1.DOSTHbText(st, block, uniqueId);
        }
        case __1.STATEMENT_TYPE.HbImage: {
            return new __1.DOSTHbImage(st, block, uniqueId);
        }
        default:
            break;
    }
    // tslint:disable-next-line:no-console
    console.warn(`STATEMENT TYPE = ${st.statementType}, block = ${block.id}`);
    return new DOBaseScriptStatement_1.DOBaseScriptStatement(st, block);
}
exports.mapStatementToDomainObject = mapStatementToDomainObject;
/**
 * 주어진 block 내의 statements 들을 DOST-* statement 또는 서브블록으로 변환한다.
 * isPasting 이 true 인 경우 중복되면 안되는 아이디 등을 새롭게 발급한다.
 */
function reduceStatementsToDomainObjects(statements, block, onChapterCustomIdFound, isPasting) {
    let currentSubBlock = null;
    const ret = [];
    let background = null;
    for (const st of statements) {
        const obj = mapStatementToDomainObject(st, block, onChapterCustomIdFound, undefined, isPasting);
        if (!obj) {
            // 명시적으로 처리하지 않는 문장이 있다면 무시한다. (ex: TTSCaching)
            continue;
        }
        // 스크립트 시트에서 가지고 올 때 배경이 변경되면 DOSTBackground 로 추가되어야 한다.
        if (background === null) {
            background = st.background;
        }
        else if (st.background !== background && st.background) {
            const stBackground = new studio_1.DOSTBackground({
                statementType: __1.STATEMENT_TYPE.BackgroundImage,
                sourceLine: block.store.rootStore.di.generateSourceLine(),
                background: st.background,
                image: st.background,
            }, block);
            background = st.background;
            ret.push(stBackground);
        }
        if (currentSubBlock === null || currentSubBlock === void 0 ? void 0 : currentSubBlock.mergeStatement(obj)) {
            continue;
        }
        switch (st.statementType) {
            case __1.STATEMENT_TYPE.Condition: {
                currentSubBlock === null || currentSubBlock === void 0 ? void 0 : currentSubBlock.finishMerging();
                currentSubBlock = new DOSTConditionSubBlock_1.DOSTConditionSubBlock([obj], block);
                ret.push(currentSubBlock);
                break;
            }
            case __1.STATEMENT_TYPE.Choice: {
                currentSubBlock === null || currentSubBlock === void 0 ? void 0 : currentSubBlock.finishMerging();
                currentSubBlock = new DOSTChoiceSubBlock_1.DOSTChoiceSubBlock([obj], block);
                ret.push(currentSubBlock);
                break;
            }
            case __1.STATEMENT_TYPE.FinalEnding: {
                currentSubBlock === null || currentSubBlock === void 0 ? void 0 : currentSubBlock.finishMerging();
                currentSubBlock = new DOSTFinalEndingSubBlock_1.DOSTFinalEndingSubBlock([obj], block);
                ret.push(currentSubBlock);
                break;
            }
            case __1.STATEMENT_TYPE.CallRemoteScript: {
                currentSubBlock === null || currentSubBlock === void 0 ? void 0 : currentSubBlock.finishMerging();
                currentSubBlock = new DOSTCallRemoteScriptSubBlock_1.DOSTCallRemoteScriptSubBlock([obj], block);
                ret.push(currentSubBlock);
                break;
            }
            default: {
                currentSubBlock === null || currentSubBlock === void 0 ? void 0 : currentSubBlock.finishMerging();
                currentSubBlock = null;
                ret.push(obj);
                break;
            }
        }
    }
    return ret;
}
exports.reduceStatementsToDomainObjects = reduceStatementsToDomainObjects;
/**
 * IBundler 를 이용하여 serialize 된 번들을 deserialize 한다.
 */
function deserializeBundleStatement(bundle, block) {
    if (bundle.hasOwnProperty('subBlockType')) {
        const b1 = bundle;
        const subStatements = b1.statements.map(st => deserializeBundleStatement(st, block));
        let b;
        switch (b1.subBlockType) {
            case __1.DOSubBlockType.ConditionSubBlock:
                b = new DOSTConditionSubBlock_1.DOSTConditionSubBlock(subStatements, block, b1.uniqueId);
                break;
            case __1.DOSubBlockType.Choice:
                b = new DOSTChoiceSubBlock_1.DOSTChoiceSubBlock(subStatements, block, b1.uniqueId);
                break;
            case __1.DOSubBlockType.FinalEnding:
                b = new DOSTFinalEndingSubBlock_1.DOSTFinalEndingSubBlock(subStatements, block, b1.uniqueId);
                break;
            case __1.DOSubBlockType.CallRemoteScript:
                b = new DOSTCallRemoteScriptSubBlock_1.DOSTCallRemoteScriptSubBlock(subStatements, block, b1.uniqueId);
                break;
        }
        b.onLoadedFromBundle(b1);
        return b;
    }
    else {
        const b2 = bundle;
        const st = mapStatementToDomainObject(b2.data, block, () => null, b2.uniqueId, false);
        if (st) {
            st.onLoadedFromBundle(b2);
        }
        return st;
    }
}
exports.deserializeBundleStatement = deserializeBundleStatement;
