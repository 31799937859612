import React from 'react'
import styled from 'styled-components'
import { GQLChapter } from '../../../@types/server'
import { Clickable } from '../../../components/base/Clickable'
import { Utils } from '../../../utils/utils'

interface IFreeSettingTdProps {
  chapter: GQLChapter
  onClick: () => void
}

export const FreeSettingTd: React.FC<IFreeSettingTdProps> = (props) => {
  const {
    chapter: { freedAtRaw: freedAt, willFreeAtRaw: willFreeAt, freeDependencyChapterIndex, freeDependencyPeriod },
    onClick,
  } = props

  // 조건
  //  1. freedAt !== null 면 무료이다.
  //  2. freedAt === null 이고 willFreeAt !== null 이면 날짜별 무료이다.
  //  3. freedAt, willFreeAt 이 null 이고 freeDependencyPeriod,freeDependencyChapterIndex 가 null 이 아니면 기스무이다.

  if (freedAt) {
    return (
      <DivWrapper onClick={onClick}>
        날짜별 무료
        <br />
        {Utils.formatDate(freedAt)} 무료가 됨
      </DivWrapper>
    )
  }

  if (willFreeAt) {
    return (
      <DivWrapper onClick={onClick}>
        날짜별 무료
        <br />
        {Utils.formatDate(willFreeAt)} 무료 전환 예정
      </DivWrapper>
    )
  }

  if (freeDependencyPeriod !== null && freeDependencyChapterIndex !== null) {
    return (
      <DivWrapper onClick={onClick}>
        기스무
        <br />
        챕터 {freeDependencyChapterIndex}화 종료 {Utils.convertSecToString(freeDependencyPeriod)} 후 무료 전환
      </DivWrapper>
    )
  }

  return <DivWrapper onClick={onClick}>무료 설정 없음</DivWrapper>
}

const DivWrapper = styled(Clickable)`
  min-height: 30px;
`
