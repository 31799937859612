"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpDoStoryStrategy = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const spDoChapterStoreStrategy_1 = require("../doChapterStoreStrategy/spDoChapterStoreStrategy");
const baseDoStoryStrategy_1 = require("./baseDoStoryStrategy");
/**
 * 이 클래스는 실제로는 di.server.getStoryForStudio를 통해서 헬로봇의 스토리도 같이 불러오고 있다.
 */
class SpDoStoryStrategy extends baseDoStoryStrategy_1.BaseDoStoryStrategy {
    constructor() {
        super(...arguments);
        this.doChapterStoreStrategyConstructor = spDoChapterStoreStrategy_1.SpDoChapterStoreStrategy;
        this.load = async (storyId) => {
            const di = this.doStory.rootStore.di;
            const playType = this.doStory.playType;
            if (playType !== core_1.GQLSTORY_PLAY_TYPE.EPUB) {
                return await di.server.getStoryForStudio(storyId);
            }
            else {
                return await di.server.getWebNovelStoryForStudio(storyId);
            }
        };
        this.postLoad = (story) => {
            (0, mobx_1.runInAction)(() => {
                this.doStory.aProjId = story.aProjId;
            });
        };
    }
    async getChapterForEditing(chapterId) {
        return await this.doStory.rootStore.di.server.getChapterForEditing(chapterId);
    }
    async getChapterDraftArchive(chapterDraftArchiveId) {
        return await this.doStory.rootStore.di.server.getChapterDraftArchive(chapterDraftArchiveId);
    }
    async exportStudioStoryAsSheet(input) {
        const { chapters, sheetId, metaDataUpdateActions, clearBeforeRun } = input;
        return await this.doStory.rootStore.di.server.exportStudioStoryAsSheet({
            chapters,
            sheetId,
            metaDataUpdateActions,
            clearBeforeRun,
        });
    }
    async updateEntityForStudio(entity, changeSet) {
        return await this.doStory.rootStore.di.server.updateEntityForStudio(entity, changeSet);
    }
    async createChapterForStudio(input) {
        return this.doStory.rootStore.di.server.createChapterForStudio(input);
    }
    async deleteChapterForStudio(chapterId) {
        return this.doStory.rootStore.di.server.deleteChapterForStudio(chapterId);
    }
}
exports.SpDoStoryStrategy = SpDoStoryStrategy;
