/**
 * ODListablePagination 은 ODListablePaginatedTable 에서 사용하기 위한 컴포넌트이다.
 * 향후 다양한 기능을 추가할 가능성이 있으며 디자인 등의 이유로 교체도 가능하므로 독자 컴포넌트로
 * 만들었다.
 */
import { range } from 'lodash'
import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { ODListableContextType, ODListableOption } from './ODListableContext'

interface Props<T, O extends ODListableOption> {
  listableContext: React.Context<ODListableContextType<T, O>>
  pageSpan?: number
  hideIfSinglePage?: boolean
}

export function ODListablePagination<T, O extends ODListableOption>(props: Props<T, O>) {
  const { listableContext, pageSpan = 3, hideIfSinglePage = true } = props
  const {
    state: { page: currentPage, pageSize, totalCount },
    setPage,
  } = React.useContext(listableContext)

  const maxPage = Math.ceil(totalCount / pageSize)
  const isDataMissingOrLoading = totalCount === -1 || currentPage < 1
  if ((hideIfSinglePage && maxPage <= 1) || isDataMissingOrLoading) {
    return null
  }

  const paginationItems = range(currentPage - pageSpan, currentPage + pageSpan + 1)
    .filter((i) => (i > 0 && i <= maxPage) || i === 1)
    .map((page) => {
      return (
        <PaginationItem key={page} active={page === currentPage} onClick={() => page !== currentPage && setPage(page)}>
          <PaginationLink tag="button">{page}</PaginationLink>
        </PaginationItem>
      )
    })

  const prevItems = (() => {
    const hasPrev = currentPage > 1
    return (
      <PaginationItem disabled={!hasPrev} onClick={() => hasPrev && setPage(currentPage - 1)}>
        <PaginationLink previous tag="button">
          Prev
        </PaginationLink>
      </PaginationItem>
    )
  })()

  const nextItems = (() => {
    const hasNext = currentPage < maxPage
    return (
      <PaginationItem disabled={!hasNext} onClick={() => hasNext && setPage(currentPage + 1)}>
        <PaginationLink previous tag="button">
          Next
        </PaginationLink>
      </PaginationItem>
    )
  })()

  return (
    <div>
      <Pagination>
        {prevItems}
        {paginationItems}
        {nextItems}
      </Pagination>
    </div>
  )
}
