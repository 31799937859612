"use strict";
/**
 * 이 파일은 스튜디오의 DO와 관련된 AOP(Aspect Oriented Programming)을 하기 위해서 만들어졌다.
 * 스튜디오의 복잡한 로직을 담당하는 DO를 그 자체로 하나의 추상화된 실행구조로 취급하고, 기존의 DO 및 기타 데이터 오브젝트의 코드 수정을 최소화 하면서
 * 헬로봇/스플의 전용 동작을 구현하기 위해서 이 로직은 커먼 라이브러리에 오게 되었다.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./decorator"), exports);
__exportStar(require("./adviceContainer/adviceContainer"), exports);
__exportStar(require("./jointPoints"), exports);
__exportStar(require("./adviceContainterProxy"), exports);
__exportStar(require("./advisorType"), exports);
