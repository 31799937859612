import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { ChrEditPage } from './ChrEditPage'

interface IChrEditPageContainerProps {
  storyId: number
  chrId: number | null
}

export const ChrEditPageContainer: React.FC<IChrEditPageContainerProps> = (props) => {
  const { storyId, chrId } = props
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 2)} />
          <hr />
          <ChrEditPage storyId={storyId} chrId={chrId} />
        </CardBody>
      </Card>
    </>
  )
}
