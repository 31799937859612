"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOUserStore = void 0;
const mobx_1 = require("mobx");
const DOUser_1 = require("./DOUser");
class DOUserStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
        });
    }
    getById(id) {
        return this.data[id];
    }
    merge(data) {
        const id = data.userId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOUser_1.DOUser(this, data);
        }
        return this.data[id];
    }
}
exports.DOUserStore = DOUserStore;
