"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBaseScriptSubBlock = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../changeOp");
const IStudioClipboard_1 = require("../../clipboard/IStudioClipboard");
const contextmenu_1 = require("../../contextmenu");
const storeUtils_1 = require("../../utils/storeUtils");
const fields_1 = require("../fields");
const DOBaseScriptStatement_1 = require("./DOBaseScriptStatement");
const DOStatementBase_1 = require("./DOStatementBase");
const IDOStatement_1 = require("./IDOStatement");
const StudioScriptHelper_1 = require("./StudioScriptHelper");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOBaseScriptSubBlock extends DOStatementBase_1.DOStatementBase {
    constructor(statements, block, subBlockType, uniqueId) {
        super(block, uniqueId !== null && uniqueId !== void 0 ? uniqueId : `${subBlockType}_${block.store.rootStore.di.generateInternalHashId()}`);
        this.lineType = IDOStatement_1.UILineType.SubBlockScript;
        this.lastValidationResults = [];
        this.hasModalForEdit = true;
        this.subBlockType = subBlockType;
        this.block = block;
        this.statements = statements;
        this.helper = new StudioScriptHelper_1.StudioScriptHelper(this.block);
        this.editorManager = new fields_1.EditorManager(this);
        this.executeConditionField = new fields_1.TextFieldWithUI(`does not support executeConditionField`, () => {
            return '';
        }, v => null, {
            placeholder: 'does not support executeConditionField',
        });
        (0, mobx_1.makeObservable)(this, {
            statements: mobx_1.observable,
            blocksTo: mobx_1.computed,
        });
    }
    get blocksTo() {
        return (0, lodash_1.flatten)(this.statements.map(v => v.blocksTo));
    }
    get parentBlock() {
        return this.block;
    }
    set parentBlock(newBlock) {
        (0, mobx_1.runInAction)(() => {
            this.block = newBlock;
        });
    }
    get editorFields() {
        return [];
    }
    getStatementOfStatementType(statementType) {
        const st = this.statements.find(v => v.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            v.statementType === statementType);
        if (!st) {
            this.fixStatements();
            return this.statements.find(v => v.lineType === IDOStatement_1.UILineType.SingleLineScript &&
                v.statementType === statementType);
        }
        return st;
    }
    /**
     * statements 들의 순서를 조정한다.
     * @protected
     */
    alignStatementsOrder() {
        (0, mobx_1.runInAction)(() => {
            this.statements = this.statements.sort((a, d) => this.getOrderForChoiceStatement(a) -
                this.getOrderForChoiceStatement(d));
        });
    }
    onBlockNameChanged(prevName, newName) {
        this.statements.forEach(st => st.onBlockNameChanged(prevName, newName));
    }
    get cmdString() {
        return [
            `[SubBlock] ${this.subBlockType}`,
            this.statements.map(st => ` ${st.cmdString}`).join('\n'),
        ].join('\n');
    }
    validate() {
        const results = (0, lodash_1.flatten)(this.statements.map(v => {
            const res = v.validate();
            // 결정사항 : 개별 statement 들의 오류 내역은 statement 들에 두고, 여기서는
            // 이를 그냥 모으고, 블록레벨에서의 오류가 있다면 이를 추가하여 올려주는게 좋겠다.
            // // 하위에서 발생한 오류를 SubBlock source 로 변경한다.
            // v.clearValidationResults()
            // res.forEach(b => {
            //   b.stack.push(b.source)
            //   b.source = this
            // })
            return res;
        }));
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    get validatorName() {
        return `[SubBlock] ${this.subBlockType}`;
    }
    generateLines() {
        return (0, lodash_1.flatten)(this.statements.map(v => v.generateLines()));
    }
    exportMetaDataUpdateActions() {
        return (0, lodash_1.flatten)(this.statements.map(v => v.exportMetaDataUpdateActions()));
    }
    finishMerging() {
        // do nothing. override to add something.
    }
    onBlockRemoved(blockId, changeOp) {
        this.statements.forEach(st => st.onBlockRemoved(blockId, changeOp));
    }
    get key() {
        return this.statements[0].key;
    }
    onCopyEvent() {
        return {
            isStudio: true,
            dataType: IStudioClipboard_1.StudioClipboardDataType.Statements,
            lines: (0, lodash_1.flatten)(this.statements
                .filter(st => st instanceof DOBaseScriptStatement_1.DOBaseScriptStatement)
                .map(st => { var _a; return (_a = st.onCopyEvent()) === null || _a === void 0 ? void 0 : _a.lines; })
                .filter(v => !!v)),
        };
    }
    onCutEvent() {
        var _a, _b;
        const data = this.onCopyEvent();
        (_b = (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.lineBlockEditing) === null || _b === void 0 ? void 0 : _b.removeLine(this);
        return data;
    }
    onFlowChartEdgeClicked() {
        var _a, _b;
        this.block.parentChapter.setBlockEditing(this.block);
        (_a = this.block.parentChapter.blockEditor) === null || _a === void 0 ? void 0 : _a.onLineClickedForCursorMove(this);
        (_b = this.block.parentChapter.blockEditor) === null || _b === void 0 ? void 0 : _b.scrollToCurrentLine();
    }
    onContextMenuOpen(manager, ev, extra) {
        return {
            menus: [
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_DOBaseScriptSubBlock_movement'),
                    onClick: () => {
                        this.onFlowChartEdgeClicked();
                        manager.closeMenu();
                    },
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_DOBaseScriptSubBlock_correction'),
                    onClick: () => {
                        this.startEditing();
                        manager.closeMenu();
                    },
                },
            ],
            x: ev.pageX,
            y: ev.pageY,
        };
    }
    removeLinesInternal(linesUniqueIds) {
        const { removes, lastNonRemovedLine } = (0, storeUtils_1.removeStatementsInternal)(this, linesUniqueIds);
        return {
            reverse: changeOp_1.StudioChangeOpFactory.addLinesToSubBlock(this.block.uniqueId, this.uniqueId, removes),
            lineToFocus: lastNonRemovedLine,
        };
    }
    addLinesInternal(lines) {
        const ids = (0, storeUtils_1.addStatementsInternal)(this, this.block, lines);
        return {
            reverse: changeOp_1.StudioChangeOpFactory.removeLinesFromSubBlock(this.block.uniqueId, this.uniqueId, ids),
            lineToFocus: this,
        };
    }
    async applyChangeOp(op, type) {
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.RemoveLinesFromSubBlock: {
                return this.removeLinesInternal(op.lineUniqueIds);
            }
            case changeOp_1.StudioChangeOpType.AddLinesToSubBlock: {
                return this.addLinesInternal(op.lines);
            }
        }
        // 서브블록은 라인 타깃의 op 만 의미가 있다.
        const opLineUniqueId = op.lineUniqueId;
        if (!opLineUniqueId) {
            return null;
        }
        // 서브블록 자체에서 필드를 관리하는 경우 (이게 가장 좋은 방안이다!)
        if (opLineUniqueId === this.uniqueId) {
            return super.applyChangeOp(op, type);
        }
        const changes = [];
        for (const st of this.statements) {
            const uniqueId = st.uniqueId;
            if (!uniqueId || uniqueId !== opLineUniqueId) {
                continue;
            }
            const s = st;
            const ret = await s.applyChangeOp(op, type);
            if (ret) {
                if (ret.reverse.opType === changeOp_1.StudioChangeOpType.BulkChange) {
                    ret.reverse.changes.forEach(c => changes.push(c));
                }
                else {
                    changes.push(ret.reverse);
                }
            }
        }
        if (changes.length === 0) {
            return null;
        }
        if (changes.length === 1) {
            return {
                reverse: changes[0],
                lineToFocus: this,
            };
        }
        return {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.BulkChange,
                changes,
            },
        };
    }
    get snapshot() {
        return {
            uniqueId: this.uniqueId,
            subBlockType: this.subBlockType,
            statements: this.statements
                .filter(s => s.isScript)
                .map(s => s.serializeToBundle()),
        };
    }
    serializeToBundle() {
        return this.snapshot;
    }
    /**
     * 추가적으로 번들에서 무언가 해야할 경우 override 하여 처리.
     */
    onLoadedFromBundle(bundle) {
        //
    }
    get isReadOnly() {
        return !!this.block.story.isReadOnly;
    }
    //
    // IStudioFinderSource
    //
    get finderSourceName() {
        return `[SubBlock] ${this.subBlockType}`;
    }
    getFindResults(keyword) {
        return (0, lodash_1.flatten)(this.statements.map(st => st.getFindResults(keyword)));
    }
    replaceTextWith(text, to) {
        this.statements.forEach(st => st.replaceTextWith(text, to));
    }
    selectBySearch() {
        var _a, _b;
        const c = this.parentBlock.store.parentChapter;
        c.setBlockEditing(this.parentBlock, false);
        (_a = c.blockEditor) === null || _a === void 0 ? void 0 : _a.onLineClickedForCursorMove(this);
        (_b = c.blockEditor) === null || _b === void 0 ? void 0 : _b.scrollToCurrentLine(false);
    }
    //
    // ExecuteCondition
    //
    get doesExecuteConditionSupport() {
        return false;
    }
    get returnSetExecuteConditionModalOpen() {
        return undefined;
    }
    get hasExecuteCondition() {
        return false;
    }
    get hasSameExecuteConditionWithPrevStatement() {
        return false;
    }
    get hasSameExecuteConditionWithNextStatement() {
        return false;
    }
    get executeConditionOfStatement() {
        return undefined;
    }
    get parentChapter() {
        return this.block.parentChapter;
    }
    getLineIndexToStartOnInit() {
        // FinishRemoteScript 앞에서 시작하기
        return this.statements.length - 2;
    }
    removeLine(line) {
        this.parentChapter
            .applyChangesOnChapter(changeOp_1.StudioChangeOpFactory.removeLinesFromSubBlock(this.block.uniqueId, this.uniqueId, [line.uniqueId]))
            .catch();
    }
    async getMessagesToTranslate() {
        const type = this.subBlockType;
        console.error(442, `DOBaseScriptSubBlock::${this} getMessagesToTranslate() was not provided. ${type}`);
        return null;
    }
}
exports.DOBaseScriptSubBlock = DOBaseScriptSubBlock;
