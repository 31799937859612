"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTAlgorithm = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const index_1 = require("../../../../../index");
const MultiSelectionFieldWithUI_1 = require("../../fields/MultiSelectionFieldWithUI");
const DOSTHbBase_1 = require("../DOSTHbBase");
const attributeTitles = {
    attributeBased: '속성',
    palmLine_emotion_image: '손 이미지',
    palmLine_emotion_question: '손금 결과 속성', // 손금 설문지
};
const outputAttributeTitles = {
    palmLine_emotion_image: '손금 결과 속성',
};
// 블록그룹 타입에 따라 linkBlock 의 목적이 다르고, 노출해야할 제목이 달라진다.
const linkBlockTitles = {
    randomUnique: '소진시',
    DAILY: '소진시',
    attributeBased: '에러시',
    n015: '에러시',
    palmLine_emotion_image: '오류시',
    palmLine_emotion_question: '오류시', // 손금 감정선 질문지
};
// 알고리즘 블록은 헬봇만 사용
class DOSTAlgorithm extends DOSTHbBase_1.DOSTHbBase {
    // selectionInput: SelectionFieldWithUI<string>
    constructor(data, block, uniqueId) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        super(data, block, uniqueId);
        // 강제로 statementType 을 강제하여 이 속성값으로 IDOSTAlgorithm 변환이 가능
        this.statementType = core_1.STATEMENT_TYPE.Algorithm;
        // 모달로 수정하는 경우는 필수.
        this.hasModalForEdit = true;
        this.algorithmBlocks = {};
        // dataForSubmitField: HbMessageNextDataField // 서버로 보내기위한 인풋 데이터, 이것의 value가 null이면
        this.isAttributeModal = false; // 속성 생성모달
        // typeOptionField?: SelectionFieldWithUI<string> // 속성만들기 타입 셀렉션.
        this.createAttributeDefName = ''; // 사용자 속성 생성 팝업떄 들어갈 defaultName
        this.createAttributeType = '';
        // attribute field 만드는 함수
        this.attributeFieldUpdate = (attribute) => {
            var _a, _b, _c, _d, _e;
            this.attributeField = this.getAttributeFieldData('attribute', (_c = (_b = (_a = this.st.hbExtensionData) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.attribute) !== null && _c !== void 0 ? _c : undefined, this.tempAttributeLogicType);
            this.attributeField.onChange([
                {
                    name: (_d = attribute.title) !== null && _d !== void 0 ? _d : '',
                    value: (_e = attribute.id) !== null && _e !== void 0 ? _e : -1,
                },
            ]);
        };
        // output filed 만드는 함수
        this.outputAttributeFieldUpdate = (attribute) => {
            var _a, _b, _c, _d, _e;
            this.outputAttributeField = this.getAttributeFieldData('outputAttribute', (_c = (_b = (_a = this.st.hbExtensionData) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.outputAttribute) !== null && _c !== void 0 ? _c : undefined, 'attributeBased');
            this.outputAttributeField.onChange([
                {
                    name: (_d = attribute.title) !== null && _d !== void 0 ? _d : '',
                    value: (_e = attribute.id) !== null && _e !== void 0 ? _e : -1,
                },
            ]);
        };
        this.algorithmBlockOption = data.algorithmData;
        this.tempAttributeLogicType = (_a = data.algorithmData.logic) === null || _a === void 0 ? void 0 : _a.type;
        // 헬봇만 사용하므로 헬봇외에 들어오면 수정해주어야함
        this.algorithmStoryStore = this.block.parentChapter.blockStore.rootStore.algorithmStoryStore;
        this.selectionField = new MultiSelectionFieldWithUI_1.MultiSelectionFieldWithUI('blockOption', () => {
            var _a;
            return this.st.algorithmGroupId !== -1
                ? [
                    {
                        name: (_a = this.st.algorithmData.title) !== null && _a !== void 0 ? _a : '',
                        value: this.st.algorithmGroupId,
                    },
                ]
                : [];
        }, values => {
            (0, mobx_1.runInAction)(() => {
                // const value = values?.[0].value
                // this.st.algorithmGroupId = value
                // if (this.st.algorithmData.botId) {
                //   this.updateData({ groupId: value })
                // }
            });
        }, '블록 이름', '블록 이름', [], {
            isSingleValue: true,
            onLoadMoreOptions: async (pageIndex, inputText) => {
                var _a, _b;
                try {
                    const res = await this.rootStore.di.server.hbClient.algorithmBlockGroup.getItems({
                        botId: this.block.story.storyId,
                        pageIndex,
                        pageSize: 30,
                        sortDirection: 'desc',
                        sort: 'regDate',
                        filter: inputText,
                    });
                    return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                        var _a, _b, _c, _d, _e;
                        this.algorithmBlocks[(_a = item.id) !== null && _a !== void 0 ? _a : -1] = item;
                        return {
                            name: (_b = item.title) !== null && _b !== void 0 ? _b : '-',
                            value: (_c = item.id) !== null && _c !== void 0 ? _c : -1,
                            category: '-',
                            displayDescription: (_d = item.logic) === null || _d === void 0 ? void 0 : _d.description,
                            description: (_e = item.logic) === null || _e === void 0 ? void 0 : _e.type,
                        };
                    })) !== null && _b !== void 0 ? _b : []);
                }
                catch (ex) {
                    this.rootStore.showError(ex);
                    return [];
                }
            },
            pageIndexForOptions: 0,
            pageSizeForOptions: 30,
            onChange: v => {
                var _a, _b, _c;
                const logicType = v[0].description;
                this.tempAttributeLogicType = logicType;
                this.attributeField = this.getAttributeFieldData('attribute', (_c = (_b = (_a = this.st.hbExtensionData) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.attribute) !== null && _c !== void 0 ? _c : undefined, logicType);
            },
        }); // this.createBlockIdsSelection([])
        this.attributeField = this.getAttributeFieldData('attribute', (_d = (_c = (_b = this.st.hbExtensionData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attribute) !== null && _d !== void 0 ? _d : undefined, (_e = data.algorithmData.logic) === null || _e === void 0 ? void 0 : _e.type);
        this.outputAttributeField = this.getAttributeFieldData('outputAttribute', (_h = (_g = (_f = this.st.hbExtensionData) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.outputAttribute) !== null && _h !== void 0 ? _h : undefined, 'attributeBased');
        this.blocksIdSelectionField = this.createBlockIdsSelection(!!((_k = (_j = this.st.hbExtensionData) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.linkBlocks)
            ? (_p = (_o = (_l = this.st.hbExtensionData.data) === null || _l === void 0 ? void 0 : _l.linkBlocks[((_m = this.st.hbExtensionData.data) === null || _m === void 0 ? void 0 : _m.linkBlocks.length) - 1].blocks) === null || _o === void 0 ? void 0 : _o.map(item => {
                var _a, _b, _c, _d;
                return ({
                    name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                    value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                    category: (_d = (_c = item.groupName) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '-',
                });
            })) !== null && _p !== void 0 ? _p : []
            : []);
        (0, mobx_1.makeObservable)(this, {
            algorithmBlockOption: mobx_1.observable,
            attributeField: mobx_1.observable,
            outputAttributeField: mobx_1.observable,
            isAttributeModal: mobx_1.observable,
            getAttributeFieldData: mobx_1.action,
            tempAttributeLogicType: mobx_1.observable,
            createAttributeDefName: mobx_1.observable,
            createAttributeType: mobx_1.observable,
            isValidBlockIds: mobx_1.computed,
            connectedAlgorithmBlockGroupName: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    //
    // IEditor implementations
    //
    // get editorFields() {
    //   return [...super.editorFields, this.dataForSubmitField]
    // }
    generateSheetColumnsAfterBackground() {
        const result = ['', '', '', '', '', '', ''];
        if (!!this.executeConditionOfStatement) {
            result.push(this.executeConditionOfStatement);
        }
        return result;
    }
    get blocksTo() {
        // const block = {
        //   block: this.block.store.getById(this.st.blockName),
        //   label: '',
        //   extra: { statement: this },
        // } as IBlockTo
        return [];
    }
    startEditing() {
        return super.startEditing();
    }
    // @aop(jointPointSymbols.DOSTAlgorithm.updateAlgorithmBlockGroup)
    updateData(data) {
        var _a, _b;
        if (!((_a = data.data) === null || _a === void 0 ? void 0 : _a.group.id)) {
            return;
        }
        super.updateData(data);
        const algorithmData = this.algorithmBlocks[(_b = data.data) === null || _b === void 0 ? void 0 : _b.group.id];
        this.algorithmBlockOption = algorithmData;
        if (algorithmData) {
            this.st.algorithmData = algorithmData;
        }
    }
    /**
     * 이 알고리즘 메시지가 연결되어 있는 알고리즘 블록 그룹의 이름
     */
    get connectedAlgorithmBlockGroupName() {
        return this.st.algorithmData.title;
    }
    get connectedAlgorithmBlockGroupInfo() {
        var _a, _b, _c, _d;
        const data = this.st.algorithmData;
        // console.log(121, JSON.parse(JSON.stringify(data)))
        return {
            algorithmText: (_b = (_a = data.logic) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '-',
            blockGroupName: (_c = data.title) !== null && _c !== void 0 ? _c : '-',
            num: (_d = data.itemCount) !== null && _d !== void 0 ? _d : -1,
        };
    }
    /**
     * DOSTAlgorithm implements IFlowChartNode
     *
     * BlockStore.allNode 프로퍼티에도 설명을 참고한다.
     * 알고리즘 메시지가 IFlowChartNode 로 동작할 땐 사실 이 메시지가 포함된 블록의 모든 알고리즘 메시지를 대변하는 노드이다.
     */
    get nodeName() {
        return (0, index_1.getNameForAlgorithmBlock)(this.block.name);
    }
    get nodeNamesFrom() {
        return [this.block.name];
    }
    get nodeType() {
        return index_1.FlowChartNodeType.AlgorithmBlock;
    }
    get nodesTo() {
        return [];
    }
    get intrinsicOrder() {
        return this.block.intrinsicOrder;
    }
    shouldComeBeforeOnFlowChart(otherBlock) {
        if (!!this.intrinsicOrder && !!otherBlock.intrinsicOrder) {
            return this.intrinsicOrder > otherBlock.intrinsicOrder;
        }
        return true;
    }
    get isFirstCreated() {
        return !!this.data.hbExtensionData;
    }
    get needAttribute() {
        return this.tempAttributeLogicType
            ? !!attributeTitles[this.tempAttributeLogicType]
            : false;
    }
    get currentLogicType() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.st.algorithmData) === null || _a === void 0 ? void 0 : _a.logic) === null || _b === void 0 ? void 0 : _b.type) !== null && _c !== void 0 ? _c : undefined;
    }
    get attributeName() {
        var _a;
        return attributeTitles[(_a = this.tempAttributeLogicType) !== null && _a !== void 0 ? _a : ''];
    }
    get needOutputAttribute() {
        return this.tempAttributeLogicType
            ? !!outputAttributeTitles[this.tempAttributeLogicType]
            : false;
    }
    get outputAttributeName() {
        var _a;
        return outputAttributeTitles[(_a = this.tempAttributeLogicType) !== null && _a !== void 0 ? _a : ''];
    }
    // 블록연결 정보가 필요한지 여부를 반환한다.
    get needLinkBlocks() {
        var _a;
        // 현재 로직 타입 정보가 타이틀 정보를 갖고 있는지 여부로 판단한다.
        return !!linkBlockTitles[(_a = this.tempAttributeLogicType) !== null && _a !== void 0 ? _a : ''];
    }
    // 블록연결 정보가 필요한지 여부를 반환한다.
    get linkBlocksName() {
        var _a;
        // 현재 로직 타입 정보가 타이틀 정보를 갖고 있는지 여부로 판단한다.
        return linkBlockTitles[(_a = this.tempAttributeLogicType) !== null && _a !== void 0 ? _a : ''];
    }
    get isValidAll() {
        return [this.isValidBlockIds].every(v => v);
    }
    getAttributeFieldData(key, attribute, type) {
        const field = new MultiSelectionFieldWithUI_1.MultiSelectionFieldWithUI(key, () => {
            var _a, _b;
            return attribute
                ? [
                    {
                        name: (_a = attribute.title) !== null && _a !== void 0 ? _a : '',
                        value: (_b = attribute.id) !== null && _b !== void 0 ? _b : -1,
                    },
                ]
                : [];
        }, values => { }, 'attribute', 'attribute', [], {
            theme: 'attribute',
            isSingleValue: true,
            onCreate: async (inputText) => {
                this.openAttributeCreateModal(inputText, key);
                return Promise.resolve();
            },
            onLoadMoreOptions: async (pageIndex, inputText) => {
                var _a, _b;
                try {
                    const payload = {
                        botId: this.block.story.storyId,
                        pageIndex,
                        pageSize: 30,
                        sortDirection: 'desc',
                        sort: 'regDate',
                        extends: 'false',
                    };
                    if (type !== 'attributeBased') {
                        payload.types = 'url';
                    }
                    const res = await this.rootStore.di.server.hbClient.attribute.list(payload);
                    return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                        var _a, _b;
                        // this.algorithmBlocks[item.id ?? -1] = item
                        return {
                            name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                            value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                            category: '-',
                        };
                    })) !== null && _b !== void 0 ? _b : []);
                }
                catch (ex) {
                    this.rootStore.showError(ex);
                    return [];
                }
            },
            pageIndexForOptions: 0,
            pageSizeForOptions: 30,
        });
        // this.attributeField = field
        return field;
    }
    getDataForSubmit() {
        var _a, _b, _c, _d;
        if (!this.attributeField &&
            !this.selectionField &&
            !this.outputAttributeField &&
            !this.blocksIdSelectionField) {
            return {};
        }
        return {
            type: 'algorithm',
            attribute: { id: this.attributeField.input.value[0] },
            groupId: this.selectionField.input.value[0],
            isShowAd: (_c = (_b = (_a = this.st.hbExtensionData) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.isShowAd) !== null && _c !== void 0 ? _c : false,
            outputAttribute: { id: this.outputAttributeField.input.value[0] },
            linkBlocks: [{ blockIds: (_d = this.blocksIdSelectionField) === null || _d === void 0 ? void 0 : _d.value }],
        };
    }
    createBlockIdsSelection(selectedOptions) {
        return new index_1.MultiSelectionInput('blocksIdSelect', 'blocksIdSelect', '블록 검색', selectedOptions, [], {
            onCreate: async (inputText) => {
                await this.block.parentChapter.createNewBlock(inputText, '');
            },
            createSuffix: '블록 만들기',
            onLoadMoreOptions: async (pageIndex, inputText) => {
                var _a, _b;
                try {
                    const res = await this.rootStore.di.server.hbClient.block.getListBlock(this.block.story.storyId, pageIndex, 30, !!inputText.trim() ? inputText.trim() : undefined);
                    return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                        var _a, _b, _c, _d;
                        return ({
                            name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                            value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                            category: (_d = (_c = item.groupName) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '-',
                        });
                    })) !== null && _b !== void 0 ? _b : []);
                }
                catch (ex) {
                    this.rootStore.showError(ex);
                    return [];
                }
            },
            pageIndexForOptions: 0,
            pageSizeForOptions: 30,
        });
    }
    get isValidBlockIds() {
        // 특정 값 일때만 사용해야함 [공통룰] 헬봇에서 블록연결은 무조건 옵셔널.
        return true; // this.blocksIdSelectionField.value.length > 0
    }
    // 사용자 속성 생성 모달 열기
    openAttributeCreateModal(inputText, type) {
        (0, mobx_1.runInAction)(() => {
            this.createAttributeDefName = inputText;
            this.isAttributeModal = true;
            this.createAttributeType = type;
        });
    }
    // 사용자 속성 생성 모달 닫기
    closeAttributeCreateModal() {
        (0, mobx_1.runInAction)(() => {
            this.createAttributeDefName = '';
            this.isAttributeModal = false;
            this.createAttributeType = '';
        });
    }
}
exports.DOSTAlgorithm = DOSTAlgorithm;
