"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionChainBuilder = void 0;
const modal_1 = require("./modal");
const ActionCompletedTutorial_1 = require("./modal/ActionCompletedTutorial");
const ActionPickImageBuilder_1 = require("./modal/ActionPickImageBuilder");
const ActionShowMobileModalBuilder_1 = require("./modal/ActionShowMobileModalBuilder");
class ActionChainBuilder {
    constructor(name, shouldNotCloseIfClickOuter) {
        this.builders = [];
        this.name = name;
        this.shouldNotCloseIfClickOuter = shouldNotCloseIfClickOuter !== null && shouldNotCloseIfClickOuter !== void 0 ? shouldNotCloseIfClickOuter : false;
    }
    addModal(cb) {
        const builder = new modal_1.ActionShowModalBuilder(this.shouldNotCloseIfClickOuter);
        cb(builder);
        this.builders.push(builder);
        return builder;
    }
    addMobileModal(cb) {
        const builder = new ActionShowMobileModalBuilder_1.ActionShowMobileModalBuilder();
        cb(builder);
        this.builders.push(builder);
        return builder;
    }
    pickImage(cb) {
        const builder = new ActionPickImageBuilder_1.ActionPickImageBuilder();
        cb(builder);
        this.builders.push(builder);
        return builder;
    }
    completedTutorial() {
        const builder = new ActionCompletedTutorial_1.ActionCompletedTutorial();
        this.builders.push(builder);
        return builder;
    }
    buildActionChain() {
        return {
            name: this.name,
            actions: this.builders.map(b => b.buildAction()),
        };
    }
}
exports.ActionChainBuilder = ActionChainBuilder;
