import React from 'react'
import { GQLChapterComment } from '../../../@types/server'
import { COLORS } from '../../../styles/colors'

type ChapterCommentListItemProps = {
  chapterComment: GQLChapterComment
  contentType: 'content' | 'type'
}

export const ChapterCommentListItem: React.FC<ChapterCommentListItemProps> = (
  props
) => {
  const { chapterComment, contentType } = props
  switch (contentType) {
    case 'content':
      return (
        <>
          {chapterComment.isBestComment ? (
            <span>
              <span
                style={{
                  padding: '5px',
                  backgroundColor: COLORS.Primary,
                  color: 'white',
                }}
              >
                베스트
              </span>
              <span> </span>
            </span>
          ) : (
            ''
          )}
          {chapterComment.deletedByAdmin ? (
            <span style={{ color: COLORS.ACCENT }}>
              {'(운영자가 삭제) ' + chapterComment.content}
            </span>
          ) : chapterComment.isHidden ? (
            <span style={{ color: COLORS.ACCENT }}>
              {'(운영자가 숨김) ' + chapterComment.content}
            </span>
          ) : (
            chapterComment.content
          )}
          {chapterComment.imageFile?.link ? (
            <img
              src={chapterComment.imageFile!.link}
              width={100}
              height={100}
              alt="imageFile"
            />
          ) : (
            ''
          )}
        </>
      )
    case 'type':
      const typeStringMap: { [key: string]: string } = {
        Text: '일반',
        UserInputChoice: '사용자 입력',
        Statistics: '통계',
      }
      return (
        <>
          {chapterComment.chapter
            ? `${chapterComment.chapter.chapterIndex} 화`
            : '없음'}
          {` / ${typeStringMap[chapterComment.type.toString()]}`}
          {chapterComment.choice
            ? ` / ${chapterComment.choice.name}`
            : ' / 없음'}
        </>
      )
    default:
      return <></>
  }
}
