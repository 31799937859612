import {
  BlockingLoadBox,
  ODListableOption,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import { GQLStorygameCharacterPrompt } from '@storyplay/common'
import { HoverTR } from '../../../../components/commonStyle'
import { SiteUrls } from '../../../../urls'
import { useStorygameCharacterPromptListDataLoader } from './StorygameCharacterPromptDataLoader'

interface TableProps {
  storyId: number
}

export const StorygameCharacterPromptListableTable: React.FC<TableProps> = (
  props
) => {
  const { storyId } = props
  const [loading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLStorygameCharacterPrompt,
    { storyId: number } & ODListableOption
  >()
  const dataLoader = useStorygameCharacterPromptListDataLoader(storyId)
  const [redirect, setRedirect] = React.useState('')
  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v['id'].toString()}
      pageSize={10}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <div />
          <Button
            block
            color="primary"
            style={{ width: 135, marginRight: 15 }}
            onClick={() =>
              setRedirect(
                SiteUrls.Admin.Story.EditStorygameCharacterPrompt(storyId, -1)
              )
            }
          >
            추가하기
          </Button>
        </div>
        <CardBody>
          <ODListablePaginatedTable2
            numColumns={2}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>성별</th>
              </tr>
            )}
            renderRow={(value: GQLStorygameCharacterPrompt) => (
              <HoverTR
                key={value.id}
                onClick={() =>
                  setRedirect(
                    SiteUrls.Admin.Story.EditStorygameCharacterPrompt(
                      storyId,
                      value.id
                    )
                  )
                }
              >
                <td>{value.id}</td>
                <td>{value.gender}</td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
