import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODImageFileInput,
  useCounter,
  useODEntityAPI,
} from '@odc/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import {
  GQLStoryItem,
  GQLStoryItemCreationInput,
  GQLStoryItemUpdateInput,
} from '../../../@types/server'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import {
  GQL_GET_STORY_ITEM,
  GQL_UPDATE_STORY_ITEM,
} from '../../../hooks/gqls_admin'
import { SiteUrls } from '../../../urls'
import { ICTStoryItem, StoryItemPrimaryKey } from '../StoryItemCommon'

interface IStoryItemEditPageProps {
  storyId: number
  storyItemId: number | null
}

const createDefaultData = (): Partial<ICTStoryItem> => {
  // @ts-ignore
  return {}
}

export const StoryItemEditPage: React.FC<IStoryItemEditPageProps> = (props) => {
  const { storyId, storyItemId } = props
  const apis = useODEntityAPI<
    GQLStoryItem,
    ICTStoryItem,
    any,
    GQLStoryItemCreationInput | GQLStoryItemUpdateInput
  >({
    updateGQL: gql(GQL_UPDATE_STORY_ITEM),
    readGQL: gql(GQL_GET_STORY_ITEM),
    primaryKeyName: StoryItemPrimaryKey,
  })

  const [token] = useCounter()
  const isCreating = storyItemId === 0

  return (
    <>
      <ODEntity
        resourceId={isCreating ? null : storyItemId}
        // @ts-ignore
        api={apis}
        saveButtonName="저장"
        titleCreation="추가"
        titleUpdate="수정"
        updateSuccessTitle="성공"
        urlAfterCreation={() => SiteUrls.Admin.Story.Edit(storyId, 10)}
        urlAfterDelete={() => SiteUrls.Admin.Story.Edit(storyId, 10)}
        deleteSuccessTitle="성공"
        defaultCreateClientData={createDefaultData()}
        urlAfterUpdate={() => SiteUrls.Admin.Story.Edit(storyId, 10)}
        noCardWrap
        refreshToken={token}
      >
        {!isCreating && (
          <ODEntityInput
            keyPath="storyItemId"
            label="아이디"
            name="storyItemId"
            placeholder="아이템 아이디 (자동부여)"
            inputType="text"
            inputProps={{ disabled: true }}
          />
        )}
        <ODEntityInput
          keyPath="name"
          label="아이템 명"
          name="name"
          placeholder="아이템 명"
          inputType="text"
          inputProps={{ disabled: true }}
        />
        <ODEntityInput
          keyPath="desc"
          label="상세 설명"
          name="desc"
          placeholder="상세 설명"
          inputType="text"
        />
        <ODEntityLabeled label="이미지 파일" name="imageFile">
          <ODImageFileInput
            name="imageFile"
            height={200}
            width={200}
            keyPath="imageFile.link"
          />
        </ODEntityLabeled>
        <ODEntityLabeled label="숨김?" name="isHidden">
          <ToggleButtonWrapper>
            <ODFormToggleButton
              key="isHidden_true"
              name="isHidden"
              keyPath="isHidden"
              value={true}
            >
              Y
            </ODFormToggleButton>
            <ODFormToggleButton
              key="isHidden_false"
              name="isHidden"
              keyPath="isHidden"
              value={false}
            >
              N
            </ODFormToggleButton>
          </ToggleButtonWrapper>
        </ODEntityLabeled>
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <hr />
        </div>
      </ODEntity>
    </>
  )
}
