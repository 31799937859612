"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatBotProcessor = void 0;
const taskQueue_1 = require("../taskQueue");
class ChatBotProcessor {
    async run(chatBot, taskRecipe, taskQueue) {
        const tasks = await taskRecipe.map(chatBot);
        const queue = taskQueue !== null && taskQueue !== void 0 ? taskQueue : new taskQueue_1.LocalTaskQueue();
        queue.setTaskHandler(taskRecipe.process.bind(taskRecipe));
        return queue.enqueueTasks(tasks, taskRecipe.reduce.bind(taskRecipe));
    }
}
exports.ChatBotProcessor = ChatBotProcessor;
