import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLOnboardingPopupChoice } from '../../../@types/server'
import { STATUS } from '../../../common'
import { HoverTR } from '../../../components/commonStyle'
import { SiteUrls } from '../../../urls'
import {
  IOnboardingPopupChoiceListableOption,
  OnboardingPopupChoicePrimaryKey,
} from '../OnboardingPopupChoiceCommon'
import { useOnboardingPopupChoiceListDataLoader } from './OnboardingPopupChoiceListDataLoader'

interface IOnboardingPopupListableTableProps {
  storyId: number
  popupId: number
}

export const OnboardingPopupChoiceListableTable: React.FC<
  IOnboardingPopupListableTableProps
> = (props) => {
  const { storyId, popupId } = props
  const [loading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLOnboardingPopupChoice,
    IOnboardingPopupChoiceListableOption
  >()
  const [token] = useCounter()
  const dataLoader = useOnboardingPopupChoiceListDataLoader(storyId, popupId)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[OnboardingPopupChoicePrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <p style={{ color: 'red', marginLeft: 5, float: 'left' }}>
            <b>설명 팝업</b>과 <b>이름 팝업</b>은 선택지를 추가할 수 없습니다.
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
              float: 'right',
              marginLeft: 15,
            }}
          >
            <ODButton
              theme={ODButtonTheme.Primary}
              size={ODButtonSize.Small}
              style={{ minWidth: 135, borderRadius: 5, height: 35 }}
              onClick={() =>
                setRedirect(
                  SiteUrls.Admin.Story.EditOnboardingPopupChoice(
                    storyId,
                    popupId,
                    0
                  )
                )
              }
            >
              선택지 생성
            </ODButton>
          </div>
          <ODListablePaginatedTable2
            numColumns={8}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>선택지 아이디</th>
                <th>이미지</th>
                <th>내용</th>
                <th>플레이어 클래스</th>
                <th>유저에게 보이는 속성값</th>
                <th>영향 받는 속성값</th>
                <th>우선 순위</th>
                <th>선택지 상태</th>
              </tr>
            )}
            renderRow={(value: GQLOnboardingPopupChoice, context) => {
              const displayProps = value.choiceProps['display']
                ? JSON.stringify(value.choiceProps['display'])
                : ''
              const effectProps = value.choiceProps['effect']
                ? JSON.stringify(value.choiceProps['effect'])
                : ''
              const StatusName =
                {
                  [STATUS.Active]: '선택 가능',
                  [STATUS.Inactive]: '비활성',
                  [STATUS.Deleted]: '삭제',
                  [STATUS.Disabled]: '선택 불가',
                }[value.status] || ''
              const statusColor =
                {
                  [STATUS.Active]: 'green',
                  [STATUS.Inactive]: 'default',
                  [STATUS.Deleted]: 'default',
                  [STATUS.Disabled]: 'red',
                }[value.status] || 'default'
              return (
                <HoverTR
                  key={value.popupId}
                  onClick={() =>
                    setRedirect(
                      SiteUrls.Admin.Story.EditOnboardingPopupChoice(
                        storyId,
                        value.popupId,
                        value.popupChoiceId
                      )
                    )
                  }
                >
                  <td>{value.popupChoiceId}</td>
                  <td>
                    {value.imageFile?.link ? (
                      <img
                        src={value.imageFile!.link}
                        width={100}
                        height={100}
                        alt="choice shows property"
                      />
                    ) : (
                      <span>미등록</span>
                    )}
                  </td>
                  <td>{value.content}</td>
                  <td>
                    {value.playerClass ? value.playerClass!.name : '없음'}
                  </td>
                  <td>{displayProps}</td>
                  <td>{effectProps}</td>
                  <td>{value.showOrder}</td>
                  <td>
                    <p style={{ color: statusColor }}>{StatusName}</p>
                  </td>
                </HoverTR>
              )
            }}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
