"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBottomBannerEditor = void 0;
const core_1 = require("@storyplay/core");
const moment = require("moment");
const store_1 = require("../../store");
const EntityEditor_1 = require("../../store/entity/EntityEditor");
const DateWithNullableField_1 = require("../../store/entity/fields/DateWithNullableField");
const SelectionFieldWithUI_1 = require("../../store/entity/fields/SelectionFieldWithUI");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOBottomBannerEditor extends EntityEditor_1.EntityEditor {
    constructor(bottomBanner) {
        super(bottomBanner);
        const stringFields = [
            {
                key: 'title',
                options: {
                    label: trans('legacy_DOBottomBannerEditor_Banner_title'),
                    placeholder: trans('legacy_DOBottomBannerEditor_Enter_banner_title'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'styleTitle',
                options: {
                    label: trans('legacy_DOBottomBannerEditor_Bold_text'),
                    placeholder: trans('legacy_DOBottomBannerEditor_Enter_bold_text'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'webLink',
                options: {
                    label: trans('legacy_DOBottomBannerEditor_Link_on_banner_click'),
                    placeholder: trans('legacy_DOBannerEditor_Enter_URL'),
                    options: { noLabel: true },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new store_1.TextFieldWithUI(fld.key, () => { var _a; return (_a = bottomBanner[fld.key]) !== null && _a !== void 0 ? _a : ''; }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        const selectionFields = [
            {
                key: 'isShow',
                options: {
                    label: trans('legacy_DOBannerEditor_Exposure_status'),
                    selection: [true, false].map(item => ({
                        name: String(item),
                        value: item,
                    })),
                },
            },
        ];
        selectionFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new SelectionFieldWithUI_1.SelectionFieldWithUI(fld.key, () => bottomBanner[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        if (!this.isEditing) {
            this.isShowField.onChange(false);
        }
        this.willOpenAtField = new DateWithNullableField_1.DateWithNullableField('willOpenAt', () => {
            var _a;
            return (_a = this._target.willOpenAt) !== null && _a !== void 0 ? _a : null;
        }, () => null);
        this.willCloseAtField = new DateWithNullableField_1.DateWithNullableField('willCloseAt', () => {
            var _a;
            return (_a = this._target.willCloseAt) !== null && _a !== void 0 ? _a : null;
        }, () => null);
    }
    get isEditing() {
        return this._target.serverId !== -1;
    }
    async submitChanges(onError) {
        if (!this.titleField.value) {
            this._target.rootStore.showError(trans('legacy_DOBottomBannerEditor_select_banner_title'));
            return false;
        }
        if (!this.webLinkField.value) {
            this._target.rootStore.showError(trans('legacy_DOBottomBannerEditor_select_move_link'));
            return false;
        }
        if (!this.willOpenAtField.value) {
            this._target.rootStore.showError(trans('legacy_DOBannerEditor_Enter_exposure_period'));
            return false;
        }
        if (moment(this.willCloseAtField.value).isBefore(this.willOpenAtField.value)) {
            this._target.rootStore.showError(trans('legacy_DOBannerEditor_End_date_earlier_than_start'));
            return false;
        }
        return super.submitChanges(onError);
    }
}
exports.DOBottomBannerEditor = DOBottomBannerEditor;
