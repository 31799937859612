import { useFormikContext } from 'formik'
import React from 'react'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { UseConfirmModalOptions, useModalConfirm } from '../components/ODModal/ODModalConfirm'
import { ODEntityEditorContextType } from './ODEntityEditorContext'

const FormButtonsWrapper = styled.div`
  display: flex;
  place-content: flex-end flex-end;
`

type ODEntityEditorFooterProps<T, C> = {
  context: React.Context<ODEntityEditorContextType<T, C>>
  saveButtonName?: string
  deleteButtonName?: string
  deleteConfirmOptions?: UseConfirmModalOptions
  onDeleted?: () => void
  renderFooter?: () => React.ReactNode
}

const defaultDeleteConfirmOptions: UseConfirmModalOptions = {
  message: (
    <>
      정말 삭제하시겠습니까?
      <br />
      되돌릴 수 없습니다.
    </>
  ),
  yes: 'Confirm',
  no: 'Cancel',
}

export function ODEntityEditorFooter<T, C>(props: ODEntityEditorFooterProps<T, C>) {
  const { saveButtonName = 'Save', deleteButtonName, context, deleteConfirmOptions, onDeleted, renderFooter } = props
  const { isSubmitting } = useFormikContext()
  const { onDelete } = React.useContext(context)

  const { Component, confirm, props: confirmComponentProps } = useModalConfirm(
    deleteConfirmOptions || defaultDeleteConfirmOptions
  )

  const handleDelete = React.useCallback(async () => {
    if (await confirm()) {
      await onDelete()
      onDeleted?.()
    }
  }, [confirm, onDelete, onDeleted])

  return (
    <FormButtonsWrapper>
      <Component {...confirmComponentProps} />
      {renderFooter?.()}
      {deleteButtonName && (
        <Button
          type="button"
          color="danger"
          style={{ minWidth: 135, marginRight: 13 }}
          outline
          disabled={isSubmitting}
          onClick={handleDelete}
        >
          {deleteButtonName}
        </Button>
      )}
      <Button type="submit" color="primary" style={{ minWidth: 135 }} disabled={isSubmitting}>
        {saveButtonName}
      </Button>
    </FormButtonsWrapper>
  )
}
