import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODImageFileInput,
  useCounter,
  useODEntityAPI,
} from '@odc/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { GQLStoryHasBackground, GQLStoryHasBackgroundPropsInput } from '../../../@types/server'
import { GQL_GET_BACKGROUND, GQL_UPDATE_BACKGROUND } from '../../../hooks/gqls_admin'
import { SiteUrls } from '../../../urls'
import { ICTStoryHasBackground, StoryHasBackgroundPrimaryKey } from '../BackgroundCommon'

interface IStoryHasBackgroundEditPageProps {
  storyId: number
  shfId: number | null
}

const DEFAULT_DATA: Partial<ICTStoryHasBackground> = {
  shfId: 0,
  name: ``,
  file: null,
}

export const BackgroundEditPage: React.FC<IStoryHasBackgroundEditPageProps> = (props) => {
  const { storyId, shfId } = props
  const apis = useODEntityAPI<GQLStoryHasBackground, ICTStoryHasBackground, any, GQLStoryHasBackgroundPropsInput>({
    // createGQL: gql(GQL_CREATE_StoryHasBackground),
    updateGQL: gql(GQL_UPDATE_BACKGROUND),
    readGQL: gql(GQL_GET_BACKGROUND),
    // deleteGQL: gql(GQL_REMOVE_StoryHasBackground),
    primaryKeyName: StoryHasBackgroundPrimaryKey,
  })
  console.log(36, shfId)

  const [token] = useCounter()

  return (
    <>
      <ODEntity
        resourceId={shfId}
        // @ts-ignore
        api={apis}
        saveButtonName="저장"
        // deleteButtonName="삭제"
        titleCreation="추가"
        titleUpdate="수정"
        updateSuccessTitle="성공"
        updateSuccessMessage="수정하였습니다."
        // urlAfterCreation={() => SiteUrls.Admin.StoryHasBackground.Main}
        // urlAfterDelete={() => SiteUrls.Admin.StoryHasBackground.Main}
        // deleteSuccessTitle="성공"
        // deleteSuccessMessage="삭제하였습니다."
        defaultCreateClientData={DEFAULT_DATA}
        urlAfterUpdate={() => SiteUrls.Admin.Story.Edit(storyId, 4)}
        noCardWrap
        refreshToken={token}
      >
        <ODEntityInput
          keyPath="shfId"
          label="배경 아이디"
          name="shfId"
          placeholder="배경 아이디 (자동부여)"
          inputType="text"
          inputProps={{ disabled: true }}
        />
        <ODEntityInput
          keyPath="name"
          label="배경 이름"
          name="name"
          placeholder="배경 이름"
          inputType="text"
          inputProps={{ disabled: true }}
        />
        <ODEntityLabeled label="이미지파일" name="file">
          <ODImageFileInput name="file" height={200 * 2.165} width={200} keyPath="file.link" />
        </ODEntityLabeled>
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <hr />
        </div>
      </ODEntity>
    </>
  )
}
