"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowChartNodeType = void 0;
var FlowChartNodeType;
(function (FlowChartNodeType) {
    FlowChartNodeType["Block"] = "Block";
    FlowChartNodeType["RemoteScriptSubBlock"] = "RemoteScriptSubBlock";
    // 헬로우봇에서 <알고리즘 블록 연결>에 해당하는 점선 노드
    FlowChartNodeType["AlgorithmBlock"] = "AlgorithmBlock";
    FlowChartNodeType["NodeOtherGroupBlock"] = "NodeOtherGroupBlock";
})(FlowChartNodeType = exports.FlowChartNodeType || (exports.FlowChartNodeType = {}));
