"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbMessageFeedback = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const ui_1 = require("../../../../ui");
const DOSTHbBase_1 = require("../../../DOSTHbBase");
const IDOStatement_1 = require("../../../IDOStatement");
const DOHbMessageNext_1 = require("../next/DOHbMessageNext");
class DOSTHbMessageFeedback extends DOSTHbBase_1.DOSTHbBase {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbUserFeedBack,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.HbMessageFeedback;
        this.hasModalForEdit = true;
        this.messageNext = null;
        this.showMessageNext = null;
        this.generateMessageNextAndMenuId(this.st.data);
        (0, mobx_1.makeObservable)(this, {
            menuIdSelectionInput: mobx_1.observable,
            messageNext: mobx_1.observable,
            showMessageNext: mobx_1.observable,
            isValidMessageNext: mobx_1.computed,
            flowChartNodesOfOtherGroupBlock: mobx_1.computed,
        });
    }
    updateData(data) {
        super.updateData(data);
        this.generateMessageNextAndMenuId(data.data);
    }
    cancelEditing() {
        super.cancelEditing();
        this.generateMessageNextAndMenuId(this.st.data);
    }
    onShowMessageNext() {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.showMessageNext = new DOHbMessageNext_1.DOHbMessageNext(this, {
                linkBlocks: (_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.getFullData().linkBlocks) !== null && _b !== void 0 ? _b : [],
            });
        });
    }
    onCloseMessageNext() {
        (0, mobx_1.runInAction)(() => {
            this.showMessageNext = null;
        });
    }
    onSubmitMessageNext() {
        (0, mobx_1.runInAction)(() => {
            this.messageNext = this.showMessageNext;
            this.showMessageNext = null;
        });
    }
    get isValidMessageNext() {
        if (!!this.messageNext) {
            return this.messageNext.isValidAll;
        }
        return true;
    }
    get isValidAll() {
        return this.menuIdSelectionInput.value.length > 0 && this.isValidMessageNext;
    }
    getDataForSubmit() {
        var _a, _b, _c, _d, _e;
        const objByData = this.isFirstCreated
            ? {
                menuId: (_c = (_b = (_a = this.menuIdSelectionInput) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : this.st.data.menuId,
                linkBlocks: (_e = (_d = this.messageNext) === null || _d === void 0 ? void 0 : _d.getDataForSubmit().linkBlocks) !== null && _e !== void 0 ? _e : undefined,
            }
            : {};
        return {
            type: 'feedback',
            ...objByData,
        };
    }
    //
    // flowChart
    //
    get blocksTo() {
        var _a, _b;
        return (_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.blocksTo) !== null && _b !== void 0 ? _b : [];
    }
    get flowChartNodesOfOtherGroupBlock() {
        var _a, _b;
        return (_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.flowChartNodesOfOtherGroupBlock) !== null && _b !== void 0 ? _b : [];
    }
    getEdgeInfoForFlowChartBy(toBlockUniqueId) {
        var _a;
        return (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.getEdgeInfoForFlowChartBy(toBlockUniqueId);
    }
    removeHbBlocksBy(blockId) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b, _c;
            this.st.data = {
                ...this.st.data,
                linkBlocks: (_a = this.st.data.linkBlocks) === null || _a === void 0 ? void 0 : _a.map(lb => {
                    var _a;
                    return ({
                        ...lb,
                        blocks: (_a = lb.blocks) === null || _a === void 0 ? void 0 : _a.filter(b => b.id !== blockId),
                    });
                }),
            };
            this.st.hbExtensionData = {
                ...this.st.hbExtensionData,
                data: {
                    ...this.st.hbExtensionData.data,
                    linkBlocks: (_c = (_b = this.st.hbExtensionData.data) === null || _b === void 0 ? void 0 : _b.linkBlocks) === null || _c === void 0 ? void 0 : _c.map((lb) => {
                        var _a;
                        return ({
                            ...lb,
                            blocks: (_a = lb.blocks) === null || _a === void 0 ? void 0 : _a.filter(b => b.id !== blockId),
                        });
                    }),
                },
            };
        });
        if (!!this.st.data.linkBlocks) {
            this.generateMessageNext(this.st.data.linkBlocks);
        }
    }
    generateMessageNextAndMenuId(data) {
        if (!!data) {
            this.generateMenuIdSelectionInput(data);
            if (!!data.linkBlocks) {
                this.generateMessageNext(data.linkBlocks);
            }
        }
    }
    generateMessageNext(linkBlocks) {
        if (linkBlocks.length < 1) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.messageNext = new DOHbMessageNext_1.DOHbMessageNext(this, { linkBlocks });
            (_b = (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.reLayoutFlowChartElements) === null || _b === void 0 ? void 0 : _b.call(_a);
        });
    }
    generateMenuIdSelectionInput(data) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b, _c, _d;
            this.menuIdSelectionInput = new ui_1.MultiSelectionInput('menuIdSelection', '스킬 선택', '스킬 검색', !!data.menu
                ? [
                    {
                        name: (_a = data.menu.title) !== null && _a !== void 0 ? _a : '-',
                        value: (_b = data.menu.id) !== null && _b !== void 0 ? _b : -1,
                        heartNum: (_c = data.menu.coin) !== null && _c !== void 0 ? _c : undefined,
                        leftIconUrl: (_d = data.menu.imageUrl) !== null && _d !== void 0 ? _d : undefined,
                    },
                ]
                : [], [], {
                required: true,
                pageSizeForOptions: 20,
                pageIndexForOptions: 0,
                theme: 'menu',
                isSingleValue: true,
                validate: () => {
                    return this.menuIdSelectionInput.value.length > 0;
                },
                onLoadMoreOptions: async (pageIndex, inputText) => {
                    var _a, _b;
                    try {
                        const res = await this.apiServer.menu.getList(this.block.parentChapter.story.storyId, {
                            sort: 'turn',
                            sortDirection: 'asc',
                            pageIndex,
                            pageSize: 20,
                            filter: inputText,
                            kind: 'clickableMenu,premiumSkill',
                        });
                        return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                            var _a, _b, _c, _d;
                            return ({
                                name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                                value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                                heartNum: (_c = item.coin) !== null && _c !== void 0 ? _c : undefined,
                                leftIconUrl: (_d = item.imageUrl) !== null && _d !== void 0 ? _d : undefined,
                            });
                        })) !== null && _b !== void 0 ? _b : []);
                    }
                    catch (ex) {
                        this.rootStore.showError(ex);
                        return [];
                    }
                },
            });
        });
    }
}
exports.DOSTHbMessageFeedback = DOSTHbMessageFeedback;
