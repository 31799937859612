"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateAddConnections = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const changeOp_1 = require("../../../changeOp");
const consts_1 = require("../../../consts");
const entity_1 = require("../entity");
const TemplateBase_1 = require("./TemplateBase");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class TemplateAddConnections extends TemplateBase_1.TemplateBase {
    constructor(editor) {
        super(editor);
        this.name = trans('legacy_TemplateAddConnections.ts_connect_unconnected_blocks_at_once');
        this.description = trans('legacy_TemplateAddConnections.ts_connect_all_unconnected_blocks_to_specific_block');
        this.tags = [TemplateBase_1.TemplateTag.Block, TemplateBase_1.TemplateTag.BulkEditing];
    }
    submit(value) {
        const c = this.editor.lineBlockEditing.parentChapter;
        const blockName = value.split('\n').filter(v => !!v)[0];
        if (!blockName) {
            return;
        }
        const di = this.chapter.store.rootStore.di;
        const bulk = new changeOp_1.StudioChangeOpFactory(this.chapter).startBulk();
        if (!c.blockStore.getById(blockName)) {
            bulk.createNewBlock(blockName, this.editor.backgroundForCurrentLine);
        }
        this.editor.chapterEditing.blockStore.all.forEach(block => {
            if (!block.checkIfEndBlockExists()) {
                const uniqueId = di.generateInternalHashId();
                bulk.upsertNewEndBlock(block.uniqueId, entity_1.EndBlockType.ToBlock, uniqueId);
                bulk.changeToBlockToBlock(block.uniqueId, uniqueId, blockName);
            }
        });
        return bulk.submitBulk();
    }
    buildActionChain() {
        const nameField = new entity_1.InputWithValidation('newBlockName', trans('legacy_TemplateAddConnections.ts_block_name'), trans('legacy_TemplateAddConnections.ts_enter_one_existing_or_new_block_name'), 'textarea', '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
        }).setAutoFocus();
        nameField.setEditing(true);
        const builder = new ActionChainBuilder_1.ActionChainBuilder('TemplateAddConnectionsAction');
        builder.addModal(modalBuilder => {
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: trans('legacy_TemplateAddConnections.ts_connect_unconnected_blocks_at_once'),
                imageName: consts_1.SPImageName.MenuNovel,
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(nameField));
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: trans('legacy_ODSToBlock_make'),
                        onClick: () => {
                            this.submit(nameField.value);
                            modalBuilder.finishAction(nameField.value);
                        },
                    },
                ],
            });
        });
        return { ...builder.buildActionChain(), editorFields: [] };
    }
}
exports.TemplateAddConnections = TemplateAddConnections;
