"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SPExprFormulaStatus = exports.LIST_STORY_FOR_WRITER_SORT_OPTION = exports.TYPEORM_READER_NAME = exports.SPImageName = exports.STORY_GENRE_TITLE_TRANSLATED = exports.UserPropUserChoicePrefix = exports.UserPropUserChoiceInputTextPrefix = exports.USER_PRIV = exports.TTS_SPEED = exports.TTS_SPEAKER_STATUS = exports.TTS_PITCH = exports.TTS_EMOTION = exports.TTS_DPE_ID = exports.TIME_SALE_TYPE = exports.SystemLanguages = exports.StudioFileType = exports.StoryType = exports.StorySectionType = exports.StoryEndingArrivalRateType = exports.ScreenSizeToNumber = exports.ScreenSize = exports.STUDIO_SHEET_CONST = exports.STUDIO_FILE_TYPE = exports.STORY_STATUS_IN_STUDIO = exports.STORY_SORTING = exports.STORY_GENRE_TO_KO_STRING = exports.STORY_GENRE_TO_EN_STRING = exports.STORY_GENRE_TITLE_TO_KO_STRING = exports.STORY_GENRE_SUBTITLE_TO_KO_STRING = exports.SPEED_LIST = exports.SEX_DISTINCTION = exports.REMOTE_SCRIPT_TYPE_TO_KO_STRING = exports.REMOTE_SCRIPT_TYPE = exports.PUSH_CATEGORY = exports.PUBLIC_RESOURCE_TYPE = exports.PITCH_LIST = exports.PAUST_AFFECTION_LEVEL = exports.LANGUAGE_CODE = exports.IMP_STATE = exports.FALLBACK_LANGUAGE_CODE = exports.EndingRateRangeLevel = exports.EMOTION_LIST = exports.COUNTRY_CODE = exports.CHOICE_TYPE = exports.CHAPTER_COMMENT_TYPE = exports.CHALLENGE_STORY_SORTING = exports.CHALLENGE_STORY_GENRE_TO_KO_STRING = exports.CHALLENGE_STORY_GENRE = exports.AGE_GROUP = exports.ADULT_CERTIFICATION_AGE = void 0;
exports.TabForChatbotList = exports.TabForStoryList = exports.PDOperationNamesThatReturnTruthyOrFalsy = void 0;
const IPDOperation_1 = require("./playData/IPDOperation");
var core_1 = require("@storyplay/core");
Object.defineProperty(exports, "ADULT_CERTIFICATION_AGE", { enumerable: true, get: function () { return core_1.ADULT_CERTIFICATION_AGE; } });
Object.defineProperty(exports, "AGE_GROUP", { enumerable: true, get: function () { return core_1.AGE_GROUP; } });
Object.defineProperty(exports, "CHALLENGE_STORY_GENRE", { enumerable: true, get: function () { return core_1.CHALLENGE_STORY_GENRE; } });
Object.defineProperty(exports, "CHALLENGE_STORY_GENRE_TO_KO_STRING", { enumerable: true, get: function () { return core_1.CHALLENGE_STORY_GENRE_TO_KO_STRING; } });
Object.defineProperty(exports, "CHALLENGE_STORY_SORTING", { enumerable: true, get: function () { return core_1.CHALLENGE_STORY_SORTING; } });
Object.defineProperty(exports, "CHAPTER_COMMENT_TYPE", { enumerable: true, get: function () { return core_1.CHAPTER_COMMENT_TYPE; } });
Object.defineProperty(exports, "CHOICE_TYPE", { enumerable: true, get: function () { return core_1.CHOICE_TYPE; } });
Object.defineProperty(exports, "COUNTRY_CODE", { enumerable: true, get: function () { return core_1.COUNTRY_CODE; } });
Object.defineProperty(exports, "EMOTION_LIST", { enumerable: true, get: function () { return core_1.EMOTION_LIST; } });
Object.defineProperty(exports, "EndingRateRangeLevel", { enumerable: true, get: function () { return core_1.EndingRateRangeLevel; } });
Object.defineProperty(exports, "FALLBACK_LANGUAGE_CODE", { enumerable: true, get: function () { return core_1.FALLBACK_LANGUAGE_CODE; } });
Object.defineProperty(exports, "IMP_STATE", { enumerable: true, get: function () { return core_1.IMP_STATE; } });
Object.defineProperty(exports, "LANGUAGE_CODE", { enumerable: true, get: function () { return core_1.LANGUAGE_CODE; } });
Object.defineProperty(exports, "PAUST_AFFECTION_LEVEL", { enumerable: true, get: function () { return core_1.PAUST_AFFECTION_LEVEL; } });
Object.defineProperty(exports, "PITCH_LIST", { enumerable: true, get: function () { return core_1.PITCH_LIST; } });
Object.defineProperty(exports, "PUBLIC_RESOURCE_TYPE", { enumerable: true, get: function () { return core_1.PUBLIC_RESOURCE_TYPE; } });
Object.defineProperty(exports, "PUSH_CATEGORY", { enumerable: true, get: function () { return core_1.PUSH_CATEGORY; } });
Object.defineProperty(exports, "REMOTE_SCRIPT_TYPE", { enumerable: true, get: function () { return core_1.REMOTE_SCRIPT_TYPE; } });
Object.defineProperty(exports, "REMOTE_SCRIPT_TYPE_TO_KO_STRING", { enumerable: true, get: function () { return core_1.REMOTE_SCRIPT_TYPE_TO_KO_STRING; } });
Object.defineProperty(exports, "SEX_DISTINCTION", { enumerable: true, get: function () { return core_1.SEX_DISTINCTION; } });
Object.defineProperty(exports, "SPEED_LIST", { enumerable: true, get: function () { return core_1.SPEED_LIST; } });
Object.defineProperty(exports, "STORY_GENRE_SUBTITLE_TO_KO_STRING", { enumerable: true, get: function () { return core_1.STORY_GENRE_SUBTITLE_TO_KO_STRING; } });
Object.defineProperty(exports, "STORY_GENRE_TITLE_TO_KO_STRING", { enumerable: true, get: function () { return core_1.STORY_GENRE_TITLE_TO_KO_STRING; } });
Object.defineProperty(exports, "STORY_GENRE_TO_EN_STRING", { enumerable: true, get: function () { return core_1.STORY_GENRE_TO_EN_STRING; } });
Object.defineProperty(exports, "STORY_GENRE_TO_KO_STRING", { enumerable: true, get: function () { return core_1.STORY_GENRE_TO_KO_STRING; } });
Object.defineProperty(exports, "STORY_SORTING", { enumerable: true, get: function () { return core_1.STORY_SORTING; } });
Object.defineProperty(exports, "STORY_STATUS_IN_STUDIO", { enumerable: true, get: function () { return core_1.STORY_STATUS_IN_STUDIO; } });
Object.defineProperty(exports, "STUDIO_FILE_TYPE", { enumerable: true, get: function () { return core_1.STUDIO_FILE_TYPE; } });
Object.defineProperty(exports, "STUDIO_SHEET_CONST", { enumerable: true, get: function () { return core_1.STUDIO_SHEET_CONST; } });
Object.defineProperty(exports, "ScreenSize", { enumerable: true, get: function () { return core_1.ScreenSize; } });
Object.defineProperty(exports, "ScreenSizeToNumber", { enumerable: true, get: function () { return core_1.ScreenSizeToNumber; } });
Object.defineProperty(exports, "StoryEndingArrivalRateType", { enumerable: true, get: function () { return core_1.StoryEndingArrivalRateType; } });
Object.defineProperty(exports, "StorySectionType", { enumerable: true, get: function () { return core_1.StorySectionType; } });
Object.defineProperty(exports, "StoryType", { enumerable: true, get: function () { return core_1.StoryType; } });
Object.defineProperty(exports, "StudioFileType", { enumerable: true, get: function () { return core_1.StudioFileType; } });
Object.defineProperty(exports, "SystemLanguages", { enumerable: true, get: function () { return core_1.SystemLanguages; } });
Object.defineProperty(exports, "TIME_SALE_TYPE", { enumerable: true, get: function () { return core_1.TIME_SALE_TYPE; } });
Object.defineProperty(exports, "TTS_DPE_ID", { enumerable: true, get: function () { return core_1.TTS_DPE_ID; } });
Object.defineProperty(exports, "TTS_EMOTION", { enumerable: true, get: function () { return core_1.TTS_EMOTION; } });
Object.defineProperty(exports, "TTS_PITCH", { enumerable: true, get: function () { return core_1.TTS_PITCH; } });
Object.defineProperty(exports, "TTS_SPEAKER_STATUS", { enumerable: true, get: function () { return core_1.TTS_SPEAKER_STATUS; } });
Object.defineProperty(exports, "TTS_SPEED", { enumerable: true, get: function () { return core_1.TTS_SPEED; } });
Object.defineProperty(exports, "USER_PRIV", { enumerable: true, get: function () { return core_1.USER_PRIV; } });
Object.defineProperty(exports, "UserPropUserChoiceInputTextPrefix", { enumerable: true, get: function () { return core_1.UserPropUserChoiceInputTextPrefix; } });
Object.defineProperty(exports, "UserPropUserChoicePrefix", { enumerable: true, get: function () { return core_1.UserPropUserChoicePrefix; } });
Object.defineProperty(exports, "STORY_GENRE_TITLE_TRANSLATED", { enumerable: true, get: function () { return core_1.STORY_GENRE_TITLE_TRANSLATED; } });
var SPImageName;
(function (SPImageName) {
    SPImageName["IcnUploadPortraitImage"] = "IcnUploadPortraitImage";
    SPImageName["ImageEffectPreviewChat"] = "ImageEffectPreviewChat";
    SPImageName["ImageEffectPreviewMobile"] = "ImageEffectPreviewMobile";
    SPImageName["ImageEffectPreviewPC"] = "ImageEffectPreviewPC";
    SPImageName["ImageBgPreviewChatMo"] = "ImageBgPreviewChatMo";
    SPImageName["ImageBgPreviewStorygameMo"] = "ImageBgPreviewStorygameMo";
    SPImageName["ImageBgPreviewStorygamePc"] = "ImageBgPreviewStorygamePc";
    SPImageName["ImagePreviewStoryGamePc"] = "ImagePreviewStoryGamePc";
    SPImageName["ImagePreviewChatMo"] = "ImagePreviewChatMo";
    SPImageName["ImagePreviewChatProfile"] = "ImagePreviewChatProfile";
    SPImageName["ImagePreviewStoryGameMo"] = "ImagePreviewStoryGameMo";
    SPImageName["ImagePreviewChatMoHero"] = "ImagePreviewChatMoHero";
    SPImageName["Book"] = "Book";
    SPImageName["MenuNovel"] = "MenuNovel";
    SPImageName["IcnEditS"] = "IcnEditS";
    SPImageName["IcnPlus"] = "IcnPlus";
    SPImageName["BtnMessage"] = "BtnMessage";
    SPImageName["ArrowRight"] = "ArrowRight";
    SPImageName["UserProfileDefaultWithRing"] = "UserProfileDefaultWithRing";
    SPImageName["CharDefault"] = "CharDefault";
    SPImageName["CharDefault2"] = "CharDefault2";
    SPImageName["Upload"] = "Upload";
    SPImageName["UploadVideo"] = "UploadVideo";
    SPImageName["BtnNavClose"] = "BtnNavClose";
    SPImageName["IcnBlockAdd"] = "IcnBlockAdd";
    SPImageName["IcnStarS"] = "IcnStarS";
    SPImageName["StoryCover"] = "StoryCover";
    SPImageName["StoryCoverMini"] = "StoryCoverMini";
    SPImageName["UserProfileDefault"] = "UserProfileDefault";
    SPImageName["IcnEditFile"] = "IcnEditFile";
    SPImageName["IcnPlayerReport"] = "IcnPlayerReport";
    SPImageName["IcnPlayerReport2"] = "IcnPlayerReport2";
    SPImageName["IcnPlayerReportWithTitle"] = "IcnPlayerReportWithTitle";
    SPImageName["IcnBtnNavInfo"] = "IcnBtnNavInfo";
    SPImageName["IcnFlashMessageWarning"] = "IcnFlashMessageWarning";
    SPImageName["IcnRefresh"] = "IcnRefresh";
    SPImageName["IcnMenuGallery"] = "IcnMenuGallery";
    SPImageName["IcnEmptyCode"] = "IcnEmptyCode";
    SPImageName["IcnLink"] = "IcnLink";
    SPImageName["IcnBtnNavMore"] = "IcnBtnNavMore";
    SPImageName["IcnCopyWithTitle"] = "IcnCopyWithTitle";
    SPImageName["IcnCheckBoxWithTitle"] = "IcnCheckBoxWithTitle";
    SPImageName["IcnSmallEdit"] = "IcnSmallEdit";
    SPImageName["IcnCopy"] = "IcnCopy";
    SPImageName["IcnDel"] = "IcnDel";
    SPImageName["IcnBack"] = "IcnBack";
    SPImageName["IcnUploadImage"] = "IcnUploadImage";
    SPImageName["IcnNoImg"] = "IcnNoImg";
    SPImageName["IcnProfile2User"] = "IcnProfile2User";
    SPImageName["IcnAttack"] = "IcnAttack";
    SPImageName["IcnMenuItem"] = "IcnMenuItem";
    SPImageName["IcnBookSquare"] = "IcnBookSquare";
    SPImageName["IcnChapterEndingBadge"] = "IcnChapterEndingBadge";
    SPImageName["IcnFinalEndingBadge"] = "IcnFinalEndingBadge";
    SPImageName["LogoStudio"] = "LogoStudio";
    SPImageName["RedWarning"] = "RedWarning";
    SPImageName["IcnProp"] = "IcnProp";
    SPImageName["IcnPropSquare"] = "IcnPropSquare";
    SPImageName["IcnLinearBox"] = "IcnLinearBox";
    SPImageName["IcnPublish"] = "IcnPublish";
    SPImageName["IcnPublishUndo"] = "IcnPublishUndo";
    SPImageName["IcnCircleWarning"] = "IcnCircleWarning";
    SPImageName["Keyboard"] = "Keyboard";
    SPImageName["IcnNavPlay"] = "IcnNavPlay";
    SPImageName["IcnMenuGalleryProp"] = "IcnMenuGalleryProp";
    SPImageName["IcnMenuGalleryItem"] = "IcnMenuGalleryItem";
    SPImageName["IcnMenuGalleryEnding"] = "IcnMenuGalleryEnding";
    SPImageName["IcnMenuGalleryInfo"] = "IcnMenuGalleryInfo";
    SPImageName["IcnBtnHistory"] = "IcnBtnHistory";
    SPImageName["IcnDelS2"] = "IcnDelS2";
    SPImageName["IcnDeleteWarning"] = "IcnDeleteWarning";
    SPImageName["IcnDB"] = "IcnDB";
    SPImageName["IcnMenuStats"] = "IcnMenuStats";
    SPImageName["IcnSheets"] = "IcnSheets";
    SPImageName["IcnStatsInPaper"] = "IcnStatsInPaper";
    SPImageName["AppIconDev"] = "AppIconDev";
    SPImageName["AppIconHellobotDev"] = "AppIconHellobotDev";
    SPImageName["IcnContestCrown"] = "IcnContestCrown";
    SPImageName["AppIconBeta"] = "AppIconBeta";
    SPImageName["IcnQna"] = "IcnQna";
    SPImageName["LogoStudioBeta"] = "LogoStudioBeta";
    SPImageName["IcnEye"] = "IcnEye";
    SPImageName["IcnInformationCircle"] = "IcnInformationCircle";
    SPImageName["IcnSave"] = "IcnSave";
    SPImageName["IcnInspect"] = "IcnInspect";
    SPImageName["IcnReleaseNote"] = "IcnReleaseNote";
    SPImageName["IcnReleaseNoteNew"] = "IcnReleaseNoteNew";
    SPImageName["IcnLightWhite"] = "icnLightWhite";
    SPImageName["IcnCrown"] = "IcnCrown";
    SPImageName["IcnChangeImage"] = "IcnChangeImage";
    SPImageName["IcnChangeArea"] = "IcnChangeArea";
    SPImageName["IcnBtnTtsHasLeftBorder"] = "IcnBtnTtsHasLeftBorder";
    SPImageName["IcnTts"] = "IcnTts";
    SPImageName["IcnBackground"] = "IcnBackground";
    SPImageName["IcnBtnEditLeftSide"] = "IcnBtnEditLeftSide";
    SPImageName["IcnBtnExpandImageRightSide"] = "IcnBtnExpandImageRightSide";
    SPImageName["IcnComposeIfBlock"] = "IcnComposeIfBlock";
    SPImageName["IcnMicrophone"] = "IcnMicrophone";
    SPImageName["IcnBold"] = "IcnBold";
    SPImageName["IcnItalic"] = "IcnItalic";
    SPImageName["IcnUnderline"] = "IcnUnderline";
    SPImageName["IcnAlignLeft"] = "IcnAlignLeft";
    SPImageName["IcnAlignCenter"] = "IcnAlignCenter";
    SPImageName["IcnAlignRight"] = "IcnAlignRight";
    SPImageName["IcnUndoWithBorder"] = "IcnUndoWithBorder";
    SPImageName["IcnRedoWithBorder"] = "IcnRedoWithBorder";
    SPImageName["IcnBtnNewImgAtStart"] = "IcnBtnNewImgAtStart";
    SPImageName["IcnBtnNewCoverImgMiddleOf"] = "IcnBtnNewCoverImgMiddleOf";
    SPImageName["IcnBtnRemoveAtEnd"] = "IcnBtnRemoveAtEnd";
    SPImageName["IcnCrop"] = "IcnCrop";
    SPImageName["IcnCropHasLeftBorder"] = "IcnCropHasLeftBorder";
    SPImageName["IcnMenuStory"] = "IcnMenuStory";
    SPImageName["IcnMenuAuthor"] = "IcnMenuAuthor";
    SPImageName["IcnVibration"] = "IcnVibration";
    SPImageName["IcnBtnEdit"] = "IcnBtnEdit";
    SPImageName["IcnBtnSaveChapter"] = "IcnBtnSaveChapter";
    SPImageName["IcnBtnNavInfo2"] = "IcnBtnNavInfo2";
    SPImageName["IcnBtnNavPrev"] = "IcnBtnNavPrev";
    SPImageName["IcnBtnClose"] = "IcnBtnClose";
    SPImageName["IcnBtnCheck"] = "IcnBtnCheck";
    SPImageName["StoryPlayHeaderLogoOnMobile"] = "StoryPlayHeaderLogoOnMobile";
    SPImageName["IcnChrome"] = "IcnChrome";
    SPImageName["IcnMenuButton"] = "IcnMenuButton";
    SPImageName["IcnStoryCoverWithLeftDim"] = "IcnStoryCoverWithLeftDim";
    SPImageName["IcnInfoShare"] = "InfoShare";
    SPImageName["IcnUserEdit"] = "IcnUserEdit";
    SPImageName["IcnChrMenu"] = "IcnChrMenu";
    SPImageName["IcnValidationCheck"] = "IcnValidationCheck";
    SPImageName["IcnValidationError"] = "IcnValidationError";
    SPImageName["IcnTutorial"] = "IcnTutorial";
    SPImageName["IcnTutorialText"] = "IcnTutorialText";
    SPImageName["IcnNavClose"] = "IcnNavClose";
    SPImageName["IcnCheckInCircle"] = "IcnCheckInCircle";
    SPImageName["IcnTutorialFunctionOpen"] = "IcnTutorialFunctionOpen";
    SPImageName["IcnTutorialEx"] = "IcnTutorialEx";
    SPImageName["IcnStudioLogoInTutorial"] = "IcnStudioLogoInTutorial";
    SPImageName["IcnCloseInDarkCircle"] = "IcnCloseInDarkCircle";
    SPImageName["IcnTutorialCompletedText"] = "IcnTutorialCompletedText";
    SPImageName["IcnTutorialCompleted"] = "IcnTutorialCompleted";
    SPImageName["IcnTutorialLock"] = "IcnTutorialLock";
    SPImageName["IcnCheckInTutorialCheckModal"] = "IcnCheckInTutorialCheckModal";
    SPImageName["IcnReleaseVersion"] = "IcnReleaseVersion";
    SPImageName["IcnBook"] = "IcnBook";
    SPImageName["IcnStudioError"] = "IcnStudioError";
    SPImageName["IcnEventStar"] = "IcnEventStar";
    SPImageName["ImgEventGrape"] = "ImgEventGrape";
    SPImageName["IcnEventGrape2"] = "IcnEventGrape2";
    SPImageName["SPGrapePost"] = "SPGrapePost";
    SPImageName["IcnChapterInfoInGNB"] = "IcnChapterInfoInGNB";
    SPImageName["IcnTestPlayInGNB"] = "IcnTestPlayInGNB";
    SPImageName["IcnSearchInGNB"] = "IcnSearchInGNB";
    SPImageName["IcnInspectInGNB"] = "IcnInspectInGNB";
    SPImageName["IcnExportInGNB"] = "IcnExportInGNB";
    SPImageName["IcnPublishInGNB"] = "IcnPublishInGNB";
    SPImageName["IcnMenuMore"] = "IcnMenuMore";
    SPImageName["IcnTutorialDone"] = "IcnTutorialDone";
    SPImageName["IcnChatBotInCircle"] = "IcnChatBotInCircle";
    SPImageName["IcnValidationErrorGray"] = "IcnValidationErrorGray";
    SPImageName["IcnBtnQuestion"] = "IcnBtnQuestion";
    SPImageName["IcnBtnExecuteConditionLeftBorder"] = "IcnBtnExecuteConditionLeftBorder";
    SPImageName["IcnBtnExecuteConditionLeftBorderFirst"] = "IcnBtnExecuteConditionLeftBorderFirst";
    SPImageName["IcnChatbot"] = "IcnChatbot";
    SPImageName["IcnHasDraftText"] = "IcnHasDraftText";
    SPImageName["IcnDashboardNav"] = "IcnDashboardNav";
    SPImageName["IcnChartNav"] = "IcnChartNav";
    SPImageName["IcnPresentationChartNav"] = "IcnPresentationChartNav";
    SPImageName["IcnRankingChartNav"] = "IcnRankingChartNav";
    SPImageName["IcnChevronRight"] = "IcnChevronRight";
    SPImageName["IcnChevronLeft"] = "IcnChevronLeft";
    SPImageName["IcnMoreS"] = "IcnMoreS";
    SPImageName["IcnBtnCalendar"] = "IcnBtnCalendar";
    SPImageName["IcnArrowNextLight"] = "IcnArrowNextLight";
    SPImageName["IcnArrowBeforeLight"] = "IcnArrowBeforeLight";
    SPImageName["IcnArrowNarrowShortDown"] = "IcnArrowNarrowShortDown";
    SPImageName["IcnArrowNarrowShortUp"] = "IcnArrowNarrowShortUp";
    SPImageName["IcnBtnNavQuestion"] = "IcnBtnNavQuestion";
    SPImageName["IcnArrowNarrowShortLine"] = "IcnArrowNarrowShortLine";
    SPImageName["IcnCommentBest"] = "IcnCommentBest";
    SPImageName["IcnCommentLike"] = "IcnCommentLike";
    SPImageName["IcnCommentOfComment"] = "IcnCommentOfComment";
    SPImageName["IcnArrowDownGray"] = "IcnArrowDownGray";
    SPImageName["IcnStatusBadgeZero"] = "IcnStatusBadgeZero";
    SPImageName["IcnStatusBadgeUp"] = "IcnStatusBadgeUp";
    SPImageName["IcnSelectBoxArrowDown"] = "IcnSelectBoxArrowDown";
    SPImageName["IcnCrownYellow"] = "IcnCrownYellow";
    SPImageName["ContestLogo"] = "ContestLogo";
    SPImageName["IcnMenuNovel"] = "IcnMenuNovel";
    SPImageName["IcnBtnArrowRight"] = "IcnBtnArrowRight";
    SPImageName["IcnMobileNavStoryManage"] = "IcnMobileNavStoryManage";
    SPImageName["IcnMobileNavChapterList"] = "IcnMobileNavChapterList";
    SPImageName["IcnMobileNavChr"] = "IcnMobileNavChr";
    SPImageName["IcnMobileNavBackgroundManage"] = "IcnMobileNavBackgroundManage";
    SPImageName["IcnMobileNavEndingManage"] = "IcnMobileNavEndingManage";
    SPImageName["IcnMobileNavProp"] = "IcnMobileNavProp";
    SPImageName["IcnMobileNavItemManage"] = "IcnMobileNavItemManage";
    SPImageName["IcnMobileNavPlayerReport"] = "IcnMobileNavPlayerReport";
    SPImageName["IcnMobileNavMemberManage"] = "IcnMobileNavMemberManage";
    SPImageName["IcnMobileNavEtc"] = "IcnMobileNavEtc";
    SPImageName["IcnNavManageStory"] = "IcnNavManageStory";
    SPImageName["IcnNavListChapter"] = "IcnNavListChapter";
    SPImageName["IcnNavChar"] = "IcnNavChar";
    SPImageName["IcnNavBackground"] = "IcnNavBackground";
    SPImageName["IcnNavManageEnding"] = "IcnNavManageEnding";
    SPImageName["IcnNavProp"] = "IcnNavProp";
    SPImageName["IcnNavManageEtc"] = "IcnNavManageEtc";
    SPImageName["IcnMenuEffect"] = "IcnMenuEffect";
    SPImageName["IcnUploadImage2"] = "IcnUploadImage2";
    SPImageName["IcnMagic"] = "IcnMagic";
    SPImageName["IcnMenuPause"] = "IcnMenuPause";
    SPImageName["IcnBtnFloatingAdd"] = "IcnBtnFloatingAdd";
    SPImageName["IcnBtnEditPen"] = "IcnBtnEditPen";
    SPImageName["IcnExpand"] = "IcnExpand";
    SPImageName["IcnDeleteTrash"] = "IcnDeleteTrash";
    SPImageName["IcnBtnAddImgInCircle"] = "IcnBtnAddImgInCircle";
    SPImageName["IcnBtnDeleteInCircle"] = "IcnBtnDeleteInCircle";
    SPImageName["IcnLlamama"] = "IcnLlamama";
    SPImageName["LogoHellobotStudio"] = "LogoHellobotStudio";
    SPImageName["IcnHbAlgorithmBlock"] = "IcnHbAlgorithmBlock";
    SPImageName["IcnHbAttribute"] = "IcnHbAttribute";
    SPImageName["IcnHbBeginConversation"] = "IcnHbBeginConversation";
    SPImageName["IcnHbChatbotBlock"] = "IcnHbChatbotBlock";
    SPImageName["IcnHbDashboard"] = "IcnHbDashboard";
    SPImageName["IcnHbLibrary"] = "IcnHbLibrary";
    SPImageName["IcnHbMatching"] = "IcnHbMatching";
    SPImageName["IcnHbReportGroup"] = "IcnHbReportGroup";
    SPImageName["IcnHbSkillList"] = "IcnHbSkillList";
    SPImageName["IcnHbStatistics"] = "IcnHbStatistics";
    SPImageName["IcnHbTeachConversation"] = "IcnHbTeachConversation";
    SPImageName["IcnHbUser"] = "IcnHbUser";
    SPImageName["IcnHbIfBlock"] = "IcnHbIfBlock";
    SPImageName["IcnSideMenuLight"] = "IcnSideMenuLight";
    SPImageName["IcnEditLight"] = "IcnEditLight";
    SPImageName["IcnGnbMenuLight"] = "IcnGnbMenuLight";
    SPImageName["IcnStoryLine"] = "IcnStoryLine";
    SPImageName["IcnInteractiveLine"] = "IcnInteractiveLine";
    SPImageName["IcnEpubUpload"] = "IcnEpubUpload";
    SPImageName["IcnEpubUploaded"] = "IcnEpubUploaded";
    SPImageName["IcnModalCloseLight"] = "IcnModalCloseLight";
    SPImageName["IcnWriteWithPlus"] = "IcnWriteWithPlus";
    SPImageName["IcnEpubUploadInBottomSheet"] = "IcnEpubUploadInBottomSheet";
    SPImageName["IcnCheckInBottomSheet"] = "IcnCheckInBottomSheet";
    SPImageName["IcnCheckOff"] = "IcnCheckOff";
    SPImageName["IcnCheckOn"] = "IcnCheckOn";
    SPImageName["IcnIfBlockSayChatbot"] = "IcnIfBlockSayChatbot";
    SPImageName["IcnFlowChartLink"] = "IcnFlowChartLink";
    SPImageName["IcnBackButtonIcon"] = "IcnBackButtonIcon";
    SPImageName["IcnBlocklink"] = "IcnBlocklink";
    SPImageName["OtherBlockLink"] = "OtherBlockLink";
    SPImageName["IcnBtnHoverDel"] = "IcnBtnHoverDel";
    SPImageName["IcnHeart"] = "IcnHeart";
    SPImageName["IcnHeartInInput"] = "IcnHeartInInput";
    SPImageName["IcnLineDrawing"] = "IcnLineDrawing";
    SPImageName["IcnCloseLight"] = "IcnCloseLight";
    SPImageName["IcnTriangle"] = "IcnTriangle";
    SPImageName["IcnGrayHeart"] = "IcnGrayHeart";
    SPImageName["IcnAddWhite"] = "IcnAddWhite";
    SPImageName["IconPlatformHb"] = "IconPlatformHb";
    SPImageName["IcnBtnSecondaryAddSmall"] = "IcnBtnSecondaryAddSmall";
    SPImageName["IconHellobotPreviewLogo"] = "IconHellobotPreviewLogo";
    SPImageName["IcnStar"] = "IcnStar";
    SPImageName["IcnPhotoEdit"] = "IcnPhotoEdit";
    SPImageName["IcnSelected"] = "IcnSelected";
    SPImageName["IcnCheckBoxWithTitleHb"] = "IcnCheckBoxWithTitleHb";
    SPImageName["IcnHbProfile"] = "IcnHbProfile";
    SPImageName["IcnAttributeOn"] = "IcnAttributeOn";
    SPImageName["IcnNewChatBot"] = "IcnNewChatBot";
    SPImageName["IcnOutlineExport"] = "IcnOutlineExport";
    SPImageName["IcnVisibility"] = "IcnVisibility";
    SPImageName["IcnWorld"] = "IcnWorld";
    SPImageName["IcnDevice"] = "IcnDevice";
    SPImageName["IcnWatch"] = "IcnWatch";
    SPImageName["ImgWebNovelContest"] = "ImgWebNovelContest";
    SPImageName["RibbonTestWait"] = "RibbonTestWait";
    SPImageName["RibbonTesting"] = "RibbonTesting";
    SPImageName["IcnInspection"] = "IcnInspection";
    SPImageName["IcnInspectionWhite"] = "IcnInspectionWhite";
    SPImageName["IcnBlueCheck"] = "IcnBlueCheck";
    SPImageName["HellobotTextLogo"] = "HellobotTextLogo";
    SPImageName["MobileBackCircle"] = "MobileBackCircle";
    SPImageName["IcnFresh"] = "IcnFresh";
    SPImageName["IcnArrowLeft"] = "IcnArrowLeft";
    SPImageName["IcnArrowLeftWhite"] = "IcnArrowLeftWhite";
    SPImageName["IcnFlashMessageWarningOrange"] = "IcnFlashMessageWarningOrange";
    SPImageName["IcnSolidAdd"] = "IcnSolidAdd";
    SPImageName["QwertyKeyboard"] = "QwertyKeyboard";
    SPImageName["NumberKeyboard"] = "NumberKeyboard";
    SPImageName["TarotCard"] = "TarotCard";
    SPImageName["IcnLang"] = "IcnLang";
    SPImageName["IcnNavManagePrice"] = "IcnNavManagePrice";
    SPImageName["IcnMobileNavPrice"] = "IcnMobileNavPrice";
    SPImageName["IcnAddBtn"] = "IcnAddBtn";
    SPImageName["IcnAddBtnGray"] = "IcnAddBtnGray";
    SPImageName["IcnMenuBanner"] = "IcnMenuBanner";
    SPImageName["IcnMenuRecommend"] = "IcnMenuRecommend";
    SPImageName["IcnMenuMonthly"] = "IcnMenuMonthly";
    SPImageName["IcnAdult"] = "IcnAdult";
    SPImageName["ImgPropsPrimary"] = "ImgPropsPrimary";
    SPImageName["IcnBtnTalkPreviewHasLeftBorder"] = "IcnBtnTalkPreviewHasLeftBorder";
    SPImageName["IcnProfileNothing"] = "IcnProfileNothing";
    SPImageName["IcnBtnPreviewArrowLeft"] = "IcnBtnPreviewArrowLeft";
    SPImageName["IcnBtnPreviewArrowRight"] = "IcnBtnPreviewArrowRight";
    SPImageName["IcnBtnMoveArrowLeft"] = "IcnBtnMoveArrowLeft";
    SPImageName["IcnBtnMoveArrowRight"] = "IcnBtnMoveArrowRight";
    SPImageName["IcnVideo"] = "IcnVideo";
    SPImageName["IcnCropHasLeftBorderWithRightBorderRadius"] = "IcnCropHasLeftBorderWithRightBorderRadius";
    SPImageName["IcnArrowLeftLine"] = "IcnArrowLeftLine";
    SPImageName["IcnArrowRightLine"] = "IcnArrowRightLine";
    SPImageName["IcnCheckedLine"] = "IcnCheckedLine";
    SPImageName["IcnCommonMore"] = "IcnCommonMore";
    SPImageName["IcnCommonDetect"] = "IcnCommonDetect";
    SPImageName["IcnCommonLanguage"] = "IcnCommonLanguage";
    SPImageName["IcnCommonRegion"] = "IcnCommonRegion";
    SPImageName["IcnCommonDesktop"] = "IcnCommonDesktop";
    SPImageName["IcnCommonTablet"] = "IcnCommonTablet";
    SPImageName["IcnCommonMobile"] = "IcnCommonMobile";
    SPImageName["IcnCommonImage"] = "IcnCommonImage";
    SPImageName["IcnCommonDrag"] = "IcnCommonDrag";
})(SPImageName = exports.SPImageName || (exports.SPImageName = {}));
exports.TYPEORM_READER_NAME = 'reader';
// 스튜디오에서 작품 목록 조회 정렬 옵션
var LIST_STORY_FOR_WRITER_SORT_OPTION;
(function (LIST_STORY_FOR_WRITER_SORT_OPTION) {
    LIST_STORY_FOR_WRITER_SORT_OPTION["PUBLISHED_AT_DESC"] = "PUBLISHED_AT_DESC";
    LIST_STORY_FOR_WRITER_SORT_OPTION["PUBLISHED_AT_ASC"] = "PUBLISHED_AT_ASC";
    LIST_STORY_FOR_WRITER_SORT_OPTION["STORY_ID_DESC"] = "STORY_ID_DESC";
    LIST_STORY_FOR_WRITER_SORT_OPTION["STORY_ID_ASC"] = "STORY_ID_ASC";
})(LIST_STORY_FOR_WRITER_SORT_OPTION = exports.LIST_STORY_FOR_WRITER_SORT_OPTION || (exports.LIST_STORY_FOR_WRITER_SORT_OPTION = {}));
// formula -> PDOperation 으로 변환될 때의 Validation
var SPExprFormulaStatus;
(function (SPExprFormulaStatus) {
    // 아무런 상태도 아님
    SPExprFormulaStatus["None"] = "None";
    // 검증 실패
    SPExprFormulaStatus["Fail"] = "Fail";
    // 검증됨
    SPExprFormulaStatus["Ok"] = "Ok";
    // 참, 거짓을 반환하는 Operation 만 허용하는 경우
    SPExprFormulaStatus["OnlyCondition"] = "OnlyCondition";
})(SPExprFormulaStatus = exports.SPExprFormulaStatus || (exports.SPExprFormulaStatus = {}));
exports.PDOperationNamesThatReturnTruthyOrFalsy = [
    IPDOperation_1.PDOperationName.LT,
    IPDOperation_1.PDOperationName.GT,
    IPDOperation_1.PDOperationName.LTE,
    IPDOperation_1.PDOperationName.GTE,
    IPDOperation_1.PDOperationName.Equals,
    IPDOperation_1.PDOperationName.NotEquals,
    IPDOperation_1.PDOperationName.AND,
    IPDOperation_1.PDOperationName.OR,
];
// 홈화면 스토리 리스트 탭
var TabForStoryList;
(function (TabForStoryList) {
    TabForStoryList["Published"] = "Published";
    TabForStoryList["UnPublished"] = "UnPublished";
    TabForStoryList["Finished"] = "Finished";
    TabForStoryList["Sample"] = "Sample";
})(TabForStoryList = exports.TabForStoryList || (exports.TabForStoryList = {}));
// 홈화면 헬로우봇 챗봇 리스트 탭
var TabForChatbotList;
(function (TabForChatbotList) {
    TabForChatbotList["All"] = "all";
    TabForChatbotList["MyChatBot"] = "my-chat-bot";
})(TabForChatbotList = exports.TabForChatbotList || (exports.TabForChatbotList = {}));
