import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import React from 'react'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import {
  ODTabBarButton,
  ODTabBarContainer,
} from '../../components/base/ODTabBar'
import { ColorContainer } from '../color/ColorListContainer'
import { TtsSpeakerListableTable } from '../ttsSpeaker/list/TtsSpeakerListableTable'
import { PaustServerContainer } from '../paustServer/PaustServerContainer'

interface ITabInfo {
  name: string
}

const Items: ITabInfo[] = [
  {
    name: '색상 관리',
  },
  {
    name: '애정도 테스트',
  },
  {
    name: 'TTS 샘플 듣기',
  },
]

export const StoryOptionsContainer: React.FC = () => {
  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)
  return (
    <FlexContentsContainer>
      <ODTabBarContainer>
        {Items.map((info, index) => {
          const { name } = info
          const isSelected = index === tab
          return (
            <ODTabBarButton
              active={tab === index}
              onClick={() => setTab(index)}
              key={index}
            >
              {isSelected && <strong>{name}</strong>}
              {!isSelected && <span>{name}</span>}
            </ODTabBarButton>
          )
        })}
      </ODTabBarContainer>
      {tab === 0 && <ColorContainer />}
      {tab === 1 && <PaustServerContainer />}
      {tab === 2 && <TtsSpeakerListableTable />}
    </FlexContentsContainer>
  )
}
