import {
  BlockingLoadBox,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
  useWrappedAPI,
} from '@odc/od-react-belt'
import { StudioUrls } from '@storyplay/common'
import moment from 'moment'
import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import {
  GQLChapter,
  GQLChapterCreationInput,
  GQLChapterPropsInput,
} from '../../../@types/server'
import { Clickable } from '../../../components/base/Clickable'
import { useODModalContext } from '../../../components/ODModal/context/useODModalContext'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { AppOptions } from '../../../utils/AppOptions'
import { Utils } from '../../../utils/utils'
import { ChapterOptionModal } from '../../chpaterOption/ChapterOptionModal'
import { ChapterPrimaryKey, IChapterListableOption } from '../ChapterCommon'
import { useChapterListDataLoader } from './ChapterListDataLoader'
import { FreeSettingModal, IFreeSetting } from './FreeSettingModal'
import { FreeSettingTd } from './FreeSettingTd'
import { FreeSettingType } from './FreeSettingUtils'

interface IChapterListableTableProps {
  storyId: number
}

export const ChapterListableTable: React.FC<IChapterListableTableProps> = (
  props
) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<
    GQLChapter,
    IChapterListableOption
  >()
  const [loading, setLoading] = React.useState(false)
  const [token, refresh] = useCounter()
  const [showFreeSettingForChapter, setShowFreeSettingForChapter] =
    React.useState<GQLChapter | null>(null)
  const dataLoader = useChapterListDataLoader(storyId)
  const {
    createChapterForStudio: apiCreateChapterForStudio,
    updateChapter: apiUpdateChapter,
    // changeChapterFree: apiChangeChapterFree,
    publishChapter: apiPublishChapter,
    changeChapterFreeSetting: apiChangeChapterFreeSetting,
  } = useStoryPlayAPIs()
  const [showChapterOptionModal, setShowChapterOptionModal] =
    React.useState(false)
  const [clickedChapterId, setClickedChapterId] = React.useState(0)
  const [chapterOptionId, setChapterOptionId] = React.useState(0)
  const { confirm, confirmInput } = useODModalContext()

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  const updateChapter = useWrappedAPI(
    ({
      chapterId,
      field,
      value,
    }: {
      chapterId: number
      field: keyof GQLChapterPropsInput
      value: any
    }) =>
      apiUpdateChapter({
        id: chapterId,
        [field]: value,
      }),
    loading,
    setLoading,
    {
      deps: [apiUpdateChapter],
      onSuccess: () => {
        ODWebUtils.showSuccess('업데이트하였습니다.')
        refresh()
      },
    }
  )

  // const changeChapterFree = useWrappedAPI(
  //   ({ chapterId, value }: { chapterId: number; value: boolean }) =>
  //     apiChangeChapterFree({ id: chapterId, isTrue: value }),
  //   loading,
  //   setLoading,
  //   {
  //     deps: [apiChangeChapterFree],
  //     onSuccess: () => {
  //       ODWebUtils.showSuccess('업데이트하였습니다.')
  //       refresh()
  //     },
  //   }
  // )

  const publishChapter = useWrappedAPI(
    ({ chapterId }: { chapterId: number }) =>
      apiPublishChapter({ id: chapterId }),
    loading,
    setLoading,
    {
      deps: [apiPublishChapter],
      onSuccess: () => {
        ODWebUtils.showSuccess('출간하였습니다.')
        refresh()
      },
    }
  )

  const handleAddNewChapter = useWrappedAPI(
    (input: Partial<GQLChapterCreationInput>) =>
      apiCreateChapterForStudio(input),
    loading,
    setLoading,
    {
      deps: [apiCreateChapterForStudio],
      onSuccess: () => {
        ODWebUtils.showSuccess('챕터를 추가하였습니다.')
        refresh()
      },
    }
  )

  const updateChapterFreeSetting = React.useCallback(
    async (
      chapterId: number,
      freeSettingType: FreeSettingType,
      freeSetting: IFreeSetting
    ) => {
      await apiChangeChapterFreeSetting({
        id: chapterId,
        willFreeAt: freeSetting.willFreeAt
          ? moment(freeSetting.willFreeAt).toDate()
          : null,
        freeDependencyChapterIndex: freeSetting.freeDependencyChapterIndex,
        freeDependencyPeriod: freeSetting.freeDependencyPeriod,
      })

      Utils.showSuccess('무료 설정을 변경하였습니다.')
      refresh()

      // mount 이슈 처리
      setTimeout(() => {
        setShowFreeSettingForChapter(null)
      }, 100)
    },
    [refresh, apiChangeChapterFreeSetting, setShowFreeSettingForChapter]
  )

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[ChapterPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      {showFreeSettingForChapter && (
        <FreeSettingModal
          chapter={showFreeSettingForChapter}
          storyId={storyId}
          onCancel={() => setShowFreeSettingForChapter(null)}
          onUpdate={updateChapterFreeSetting}
        />
      )}
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="챕터명으로 검색"
              style={searchBoxStyle}
            />
            <div>
              <Button
                block
                color="primary"
                style={{ minWidth: 135 }}
                onClick={() =>
                  handleAddNewChapter({
                    id: storyId,
                    afterChapterId: -1,
                    name: '새로운 챕터',
                    customId: '',
                  })
                }
              >
                새로운 챕터 추가
              </Button>
            </div>
          </div>
          <ODListablePaginatedTable2
            numColumns={10}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>챕터 아이디</th>
                <th>회차 정보</th>
                <th>챕터명</th>
                <th>출간시각 (없으면 미출간)</th>
                <th>출간 예정 시각</th>
                <th>가격 정보</th>
                <th>무료 설정</th>
                <th>작성 완료?</th>
                <th>광고 on?</th>
                <th>숨김?</th>
                <th>옵션 설정</th>
                <th>스튜디오</th>
              </tr>
            )}
            renderRow={(value: GQLChapter, context) => (
              <tr key={value[ChapterPrimaryKey]}>
                <td>{value.chapterId}</td>
                <td>{value.chapterIndex}</td>
                <td>
                  <Clickable
                    onClick={async () => {
                      const toValue = await confirmInput({
                        initial: value.name,
                        inputType: 'text',
                        title: '챕터 이름 변경',
                        message: `엑셀에서 해당 챕터 시트명도 변경하시는 챕터명과 일치 시켜주세요!`,
                      })

                      if (!toValue) {
                        return
                      }

                      return updateChapter({
                        chapterId: value.chapterId,
                        field: 'name',
                        value: toValue,
                      })
                    }}
                  >
                    {value.name}
                  </Clickable>
                </td>
                <td>
                  <Clickable
                    onClick={async () => {
                      if (value.publishedAt) {
                        return
                      }

                      if (
                        !(await confirm({
                          title: '출간하기',
                          message:
                            '해당 챕터를 즉시 출간하시겠습니까? 이는 되돌릴 수 없습니다.',
                          yes: '네',
                          no: '아니오',
                        }))
                      ) {
                        return
                      }

                      return publishChapter({
                        chapterId: value.chapterId,
                      })
                    }}
                  >
                    {value.publishedAt
                      ? Utils.formatDate(value.publishedAt)
                      : '미출간'}
                  </Clickable>
                </td>
                <td>
                  <Clickable
                    onClick={async () => {
                      const toValue = await confirmInput({
                        initial: '',
                        inputType: 'datetime-local',
                        title: '챕터를 자동 공개할 시각 변경',
                        message: '자동으로 챕터를 공개할 시각을 입력해주세요.',
                      })

                      if (!toValue) {
                        return
                      }

                      return updateChapter({
                        chapterId: value.chapterId,
                        field: 'willPublishAt',
                        value: moment(toValue).toDate(),
                      })
                    }}
                  >
                    {value.willPublishAt
                      ? Utils.formatDate(value.willPublishAt)
                      : '미설정'}
                  </Clickable>
                </td>
                <td>
                  <Clickable
                    onClick={async () => {
                      const toValue = await confirmInput({
                        initial: value.price,
                        inputType: 'number',
                        title: '가격 변경',
                        message: '변경할 가격을 입력해주세요.',
                      })

                      if (!toValue) {
                        return
                      }

                      return updateChapter({
                        chapterId: value.chapterId,
                        field: 'price',
                        value: parseInt(toValue, 10),
                      })
                    }}
                  >
                    {value.price}
                  </Clickable>
                </td>
                <td>
                  <FreeSettingTd
                    chapter={value}
                    onClick={() => setShowFreeSettingForChapter(value)}
                  />
                </td>
                <td>
                  <Clickable
                    onClick={() =>
                      updateChapter({
                        chapterId: value.chapterId,
                        field: 'isFinal',
                        value: !value.isFinal,
                      })
                    }
                  >
                    {value.isFinal ? 'Y' : 'N'}
                  </Clickable>
                </td>
                <td>
                  <Clickable
                    onClick={() =>
                      updateChapter({
                        chapterId: value.chapterId,
                        field: 'adsOn',
                        value: !value.adsOn,
                      })
                    }
                  >
                    {value.adsOn ? 'Y' : 'N'}
                  </Clickable>
                </td>
                <td>
                  <Clickable
                    onClick={() =>
                      updateChapter({
                        chapterId: value.chapterId,
                        field: 'isHidden',
                        value: value.isHidden === 0 ? 1 : 0,
                      })
                    }
                  >
                    {value.isHidden === 1 ? 'Y' : 'N'}
                  </Clickable>
                </td>
                <td>
                  <Clickable
                    onClick={() => {
                      setClickedChapterId(value.chapterId)
                      setChapterOptionId(
                        value.chapterOptionByAdmin
                          ? value.chapterOptionByAdmin.chapterOptionId
                          : 0
                      )
                      setShowChapterOptionModal(true)
                    }}
                  >
                    {value.chapterOptionByAdmin ? '수정하기' : '생성하기'}
                  </Clickable>
                </td>
                <td>
                  <a
                    href={`${
                      AppOptions.STUDIO_WEB_URL
                    }${StudioUrls.Story.Detail.Chapter.Edit(
                      value.storyId,
                      value.chapterId
                    )}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    스튜디오
                  </a>
                </td>
              </tr>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
          {showChapterOptionModal && (
            <ChapterOptionModal
              storyId={storyId}
              chapterId={clickedChapterId}
              chapterOptionId={chapterOptionId}
              onClose={() => {
                setShowChapterOptionModal(false)
                refresh()
              }}
              onConfirm={() => {
                setShowChapterOptionModal(false)
                refresh()
              }}
            />
          )}
        </CardBody>
      </Card>
    </Provider>
  )
}
