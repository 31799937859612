import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLEndingCard } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IEndingCardListableOption } from '../EndingCardCommon'

export function useEndingCardListDataLoader(
  storyId: number | null,
  endingId: number | null
) {
  const { listEndingCardByAdmin } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IEndingCardListableOption
    ): Promise<ODListableResponseType<GQLEndingCard>> {
      const r = await listEndingCardByAdmin({
        page,
        pageSize: 10,
        ...options,
        endingId,
      })
      return r as ODListableResponseType<GQLEndingCard>
    },
    [listEndingCardByAdmin, endingId]
  )
}
