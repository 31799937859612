"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryHasBackgroundStore = void 0;
const mobx_1 = require("mobx");
const DOStoryHasBackground_1 = require("./DOStoryHasBackground");
class DOStoryHasBackgroundStore {
    constructor(story) {
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            all: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    getByName(name) {
        return Object.values(this.data).find(v => v.name === name);
    }
    updateStoryHasBackgroundImage(image) {
        return this.story.storyHasBackgroundStore.merge(image);
    }
    merge(data) {
        const id = data.shfId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOStoryHasBackground_1.DOStoryHasBackground(this, data);
        }
        return this.data[id];
    }
    get all() {
        return Object.values(this.data);
    }
}
exports.DOStoryHasBackgroundStore = DOStoryHasBackgroundStore;
