"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectionInput = void 0;
const mobx_1 = require("mobx");
const uuid = require("uuid");
const IInputBase_1 = require("./IInputBase");
class SelectionInput {
    constructor(id, label, value, options, extra = {}) {
        var _a, _b, _c, _d, _e, _f, _g;
        this.inputModelType = IInputBase_1.InputModelType.SelectionInput;
        this.isDisabled = false;
        this.isCreating = false;
        this.required = false;
        this._submit = () => null;
        this.id = id;
        this.label = label;
        this.value = value;
        this.originalValue = value;
        this.options = [...options];
        this.extra = extra;
        this.creatable = (_a = extra.creatable) !== null && _a !== void 0 ? _a : false;
        this.placeholder = (_b = extra.placeholder) !== null && _b !== void 0 ? _b : '';
        this.isDisabled = (_c = extra.isDisabled) !== null && _c !== void 0 ? _c : false;
        this.noLabel = (_d = extra.noLabel) !== null && _d !== void 0 ? _d : true;
        this.uiType = (_e = extra.uiType) !== null && _e !== void 0 ? _e : 'default';
        this.testId = (_f = extra.testId) !== null && _f !== void 0 ? _f : undefined;
        this.required = (_g = extra.required) !== null && _g !== void 0 ? _g : false;
        this.key = uuid.v4();
        (0, mobx_1.makeObservable)(this, {
            key: mobx_1.observable,
            value: mobx_1.observable,
            options: mobx_1.observable,
            isCreating: mobx_1.observable,
            required: mobx_1.observable,
            isDisabled: mobx_1.observable,
            hasChange: mobx_1.computed,
            disabled: mobx_1.computed,
        });
    }
    getIsValid(newValue) {
        if (this.required && !this.disabled) {
            if (typeof this.value === 'boolean') {
                return true;
            }
            return !!this.value;
        }
        return true;
    }
    reset(value) {
        (0, mobx_1.runInAction)(() => {
            this.value = value;
            this.originalValue = value;
            this._submit = () => null;
        });
    }
    setRequired(isRequired) {
        (0, mobx_1.runInAction)(() => {
            this.required = isRequired;
        });
        return this;
    }
    onChange(value) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            const isSameValueBefore = this.value === value;
            this.isCreating = false;
            this.value = value;
            (_b = (_a = this.extra).onChangeBeforeSubmit) === null || _b === void 0 ? void 0 : _b.call(_a, value, isSameValueBefore);
            const onChange = this.extra.onChange;
            this._submit = () => {
                onChange === null || onChange === void 0 ? void 0 : onChange(value);
            };
        });
    }
    onChangeOptions(newOptions) {
        (0, mobx_1.runInAction)(() => {
            this.options = newOptions;
        });
    }
    onCreate(value) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.isCreating = true;
            const newOption = {
                __isNew__: true,
                name: value,
                value,
                description: value,
                leftIconUrl: value,
            };
            this.options = this.options.filter(v => !v.hasOwnProperty('__isNew__'));
            this.options.push(newOption);
            this.value = value;
            (_b = (_a = this.extra).onCreateBeforeSubmit) === null || _b === void 0 ? void 0 : _b.call(_a, value);
            this._submit = () => { var _a, _b; return (_b = (_a = this.extra).onCreate) === null || _b === void 0 ? void 0 : _b.call(_a, value); };
        });
    }
    get disabled() {
        return this.isDisabled;
    }
    get shouldChangeOptions() {
        return !!this.extra.loadOptionsByInputValue;
    }
    async loadOptionsByInputValue(value) {
        var _a, _b, _c;
        if (!this.shouldChangeOptions) {
            return;
        }
        // catch 는 전달받은 함수에서 해준다고 가정합니다.
        const options = (_c = (await ((_b = (_a = this.extra).loadOptionsByInputValue) === null || _b === void 0 ? void 0 : _b.call(_a, value)))) !== null && _c !== void 0 ? _c : [];
        (0, mobx_1.runInAction)(() => {
            this.options = options;
        });
    }
    onSubmit() {
        this._submit();
    }
    reloadOptions() {
        (0, mobx_1.runInAction)(() => (this.key = uuid.v4()));
    }
    setIsDisabled(isDisabled) {
        (0, mobx_1.runInAction)(() => {
            this.isDisabled = isDisabled;
        });
    }
    get hasChange() {
        return this.value !== this.originalValue;
    }
}
exports.SelectionInput = SelectionInput;
