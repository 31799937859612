"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateInput = void 0;
const mobx_1 = require("mobx");
class DateInput {
    constructor(id, label, placeholder, value, extra) {
        var _a, _b;
        this.value = null;
        this.required = false;
        this.disabled = false;
        this.id = id;
        this.label = label;
        this.placeholder = placeholder;
        this.value = value;
        this.required = (_a = extra === null || extra === void 0 ? void 0 : extra.required) !== null && _a !== void 0 ? _a : false;
        this.disabled = (_b = extra === null || extra === void 0 ? void 0 : extra.disabled) !== null && _b !== void 0 ? _b : false;
        (0, mobx_1.makeObservable)(this, {
            value: mobx_1.observable,
            disabled: mobx_1.observable,
        });
    }
    getIsValid(value) {
        if (this.required && !this.disabled) {
            return !!this.value;
        }
        return true;
    }
    onChange(newValue) {
        (0, mobx_1.runInAction)(() => {
            this.value = newValue;
        });
    }
    onChangeDisabled(disabled) {
        (0, mobx_1.runInAction)(() => {
            this.disabled = disabled;
        });
    }
}
exports.DateInput = DateInput;
