import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLStoryItem } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { COLORS } from '../../../styles/colors'
import { SiteUrls } from '../../../urls'
import {
  IStoryItemListableOption,
  StoryItemPrimaryKey,
} from '../StoryItemCommon'
import { useStoryItemListDataLoader } from './StoryItemListDataLoader'

interface IStoryItemListableTableProps {
  storyId: number
}

export const StoryItemListableTable: React.FC<IStoryItemListableTableProps> = (
  props
) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<
    GQLStoryItem,
    IStoryItemListableOption
  >()
  const [token] = useCounter()
  const dataLoader = useStoryItemListDataLoader(storyId)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[StoryItemPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="이름으로 검색"
              style={searchBoxStyle}
            />
          </div>
          <ODListablePaginatedTable2
            numColumns={6}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>아이템 이름</th>
                <th>상세 설명</th>
                <th>이미지파일</th>
                <th>숨김?</th>
              </tr>
            )}
            renderRow={(value: GQLStoryItem) => (
              <HoverTR
                key={value.storyItemId}
                onClick={() =>
                  setRedirect(
                    SiteUrls.Admin.Story.EditStoryItem(
                      storyId,
                      value.storyItemId
                    )
                  )
                }
              >
                <td>{value.storyItemId}</td>
                <td>{value.name}</td>
                <td>{value.desc}</td>
                <td>
                  {value.imageFile?.link ? (
                    <img
                      src={value.imageFile!.link}
                      width={150}
                      height={150}
                      alt="item"
                    />
                  ) : (
                    <span>미등록</span>
                  )}
                </td>
                <td>
                  {value.isHidden ? (
                    <span style={{ fontWeight: 'bold', color: COLORS.Primary }}>
                      Y
                    </span>
                  ) : (
                    <span style={{ color: COLORS.GRAY600 }}>N</span>
                  )}
                </td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
