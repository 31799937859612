import { GQLChoice } from '@storyplay/core'
import React from 'react'
import { COLORS } from '../../styles/colors'

type ChoiceListItemProps = {
  choice: GQLChoice
}

export const ChoiceListItem: React.FC<ChoiceListItemProps> = (props) => {
  const { choice } = props
  return (
    <>
      {choice.showStatistics ? (
        <span>
          <span
            style={{
              padding: '5px',
              backgroundColor: COLORS.Primary,
              color: 'white',
              borderRadius: 5,
              fontSize: 'xx-small',
            }}
          >
            통계
          </span>
          <span> </span>
        </span>
      ) : (
        ''
      )}
      {choice.name}&nbsp;({choice.numChoice}개)
    </>
  )
}
