import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import {
  GQLListablePlayerClass,
  GQLOnboardingPopup,
  GQLStory,
} from '../../../@types/server'
import {
  ONBOARDING_POPUP_PROPERTY,
  ONBOARDING_POPUP_TYPE,
  STATUS,
} from '../../../common'
import { HoverTR } from '../../../components/commonStyle'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import {
  IOnboardingPopupListableOption,
  OnboardingPopupPrimaryKey,
} from '../OnboardingPopupCommon'
import { useOnboardingPopupListDataLoader } from './OnboardingPopupListDataLoader'
import {
  OnboardingPopupSelect,
  OnboardingPopupSelectFilterType,
} from './OnboardingPopupSelect'

interface IOnboardingPopupListableTableProps {
  storyId: number
}

export const OnboardingPopupListableTable: React.FC<
  IOnboardingPopupListableTableProps
> = (props) => {
  const { storyId } = props
  const [loading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLOnboardingPopup,
    IOnboardingPopupListableOption
  >()
  const [chapterIdFilter, setChapterIdFilter] = React.useState<
    number | undefined
  >(undefined)
  const [token] = useCounter()
  const [popupOptions, setPopupOptions] = React.useState<
    OnboardingPopupSelectFilterType[]
  >([])
  const dataLoader = useOnboardingPopupListDataLoader(storyId, chapterIdFilter)
  const [redirect, setRedirect] = React.useState('')
  const { getAllChaptersById: apiGetAllChapters, listPlayerClass } =
    useStoryPlayAPIs()
  const [playerClassMap, setPlayerClassMap] = React.useState<{
    [key: number]: string
  }>({})

  React.useEffect(() => {
    apiGetAllChapters({ id: storyId }).then((story: GQLStory) => {
      setPopupOptions(
        story.allChapters.map((chapter) => ({
          chapterIndex: chapter.chapterIndex,
          chapterId: chapter.chapterId,
          chapterName: chapter.name,
        }))
      )
    })
    listPlayerClass({ storyId, pageSize: 100 }).then(
      (playerClass: GQLListablePlayerClass) => {
        setPlayerClassMap(
          playerClass.list.reduce(
            (acc, p) => ({
              ...acc,
              [p.playerClassId]: p.name,
            }),
            {}
          )
        )
      }
    )
  }, [apiGetAllChapters, storyId, listPlayerClass])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[OnboardingPopupPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={{ width: 250, marginLeft: 5, float: 'left' }}>
            <OnboardingPopupSelect
              value={chapterIdFilter}
              onChange={setChapterIdFilter}
              filterTypes={popupOptions}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
              float: 'right',
              marginLeft: 15,
            }}
          >
            <ODButton
              theme={ODButtonTheme.Primary}
              size={ODButtonSize.Small}
              style={{ minWidth: 135, borderRadius: 5, height: 35 }}
              onClick={() =>
                setRedirect(
                  SiteUrls.Admin.Story.EditOnboardingPopup(storyId, 0)
                )
              }
            >
              온보딩 팝업 생성
            </ODButton>
          </div>
          <ODListablePaginatedTable2
            numColumns={7}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>팝업 아이디</th>
                <th>챕터명</th>
                <th>팝업 타입</th>
                <th style={{ minWidth: 50 }}>지문</th>
                <th>노출 우선순위</th>
                <th>팝업 노출 조건</th>
                <th>선택지 개수</th>
                <th>활성 여부</th>
              </tr>
            )}
            renderRow={(value: GQLOnboardingPopup, context) => {
              const PopupTypeName =
                {
                  [ONBOARDING_POPUP_TYPE.Name]: '주인공 이름 팝업',
                  [ONBOARDING_POPUP_TYPE.Choice]: '선택 팝업',
                  [ONBOARDING_POPUP_TYPE.Profile]: '주인공 프로필 팝업',
                  [ONBOARDING_POPUP_TYPE.Text]: '설명 팝업',
                  [ONBOARDING_POPUP_TYPE.OtherName]: `상대역 이름 팝업`,
                  [ONBOARDING_POPUP_TYPE.OtherProfile]: `상대역 프로필 팝업`,
                  [ONBOARDING_POPUP_TYPE.StorygameProfile]: `스토리게임 등장인물 설정 팝업`,
                }[value.type] || ''
              const StatusName =
                {
                  [STATUS.Active]: '활성',
                  [STATUS.Inactive]: '비활성',
                  [STATUS.Deleted]: '삭제',
                  [STATUS.Disabled]: '선택 불가',
                }[value.status] || ''
              return (
                <HoverTR
                  key={value.popupId}
                  onClick={() =>
                    setRedirect(
                      SiteUrls.Admin.Story.EditOnboardingPopup(
                        storyId,
                        value.popupId
                      )
                    )
                  }
                >
                  <td>{value.popupId}</td>
                  <td>{value.chapter?.name}</td>
                  <td>
                    {PopupTypeName}{' '}
                    {value.character ? `(${value.character.name})` : ''}
                  </td>
                  <td>{value.description}</td>
                  <td>{value.showOrder}</td>
                  <td>
                    {value.visibleConditions &&
                      value.visibleConditions.length > 0 &&
                      value.visibleConditions.map(
                        ({ property, value, inequality }) => {
                          if (
                            property ===
                            ONBOARDING_POPUP_PROPERTY.__playerClass__
                          ) {
                            return (
                              <>
                                <span>
                                  PlayerClass: {playerClassMap[value]}{' '}
                                  {inequality}
                                </span>
                                <br />
                              </>
                            )
                          }
                          return (
                            <>
                              <span>
                                Props: {property} {inequality} {value}
                              </span>
                              <br />
                            </>
                          )
                        }
                      )}
                  </td>
                  <td>{value.popupChoices?.length}</td>
                  <td>{StatusName}</td>
                </HoverTR>
              )
            }}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
