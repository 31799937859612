import { ODEntityLabeled } from '@odc/od-react-belt'
import React from 'react'

interface IStoryUploadWithScriptProps {
  onScriptFileLoadedAsText: (script: string) => void
}

/**
 * json 파일을 로컬에서 읽어서 문자열 형태로 콜백을 보내주는 컴포넌트
 */
export const StoryUploadScriptJson: React.FC<IStoryUploadWithScriptProps> = (
  props
) => {
  const { onScriptFileLoadedAsText } = props

  const handleFile = async (file: File | null) => {
    if (!file) {
      return
    }

    await new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onload = async () => {
        await onScriptFileLoadedAsText(fr.result as string)
        resolve(null)
      }
      fr.readAsText(file)
    })
  }

  return (
    <ODEntityLabeled label="스크립트 json 업로드" name="rawScriptFile">
      <input
        type="file"
        onChange={(e) => handleFile(e.target.files?.[0] ?? null)}
      />
    </ODEntityLabeled>
  )
}
