"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbUserAPI = void 0;
class HbUserAPI {
    constructor(server) {
        this.server = server;
    }
    async login(payload) {
        return this.server.put('/v1/signin', payload);
    }
}
exports.HbUserAPI = HbUserAPI;
