import { IODListableEventContext, ODListableOption } from '@odc/od-react-belt'
import { GQLORDER_BY_DIRECTION, GQLUser } from '../../@types/server'
import { LEDGER_AMOUNT_FILTER_FIELD } from '../../common'

export const UserPrimaryKey = 'userId'

// modifiable fields only.
export interface ICTUser
  extends Pick<GQLUser, 'userId' | 'email' | 'priv' | 'name'> {
  password: string
  // more fields, mapped (if needed)
}

export interface IUserListableOption extends ODListableOption {
  filter: string
}

export interface IUserLedgerListableOption extends ODListableOption {
  filter: string
  amountFilter: LEDGER_AMOUNT_FILTER_FIELD
  orderBy: GQLORDER_BY_DIRECTION
}

export interface IUserEntityContext extends IODListableEventContext<GQLUser> {
  //
}
