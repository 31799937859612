import React from 'react'
import { ODSearchBox } from '../components/ODSearchBox'
import { ODListableContextType, ODListableOption } from './ODListableContext'

interface Props<T, O> {
  listableContext: React.Context<ODListableContextType<T, O>>
  filterKey?: string
  placeholder: string
  style: object
}

export function ODListableSearchBox<T, O extends ODListableOption>(
  props: Props<T, O>
) {
  const {
    placeholder,
    style = {},
    listableContext,
    filterKey = 'filter',
  } = props
  const {
    updateLoadOption,
    state: { loadOption },
  } = React.useContext(listableContext)

  const runSearch = React.useCallback(
    (value: string) => {
      updateLoadOption({ ...loadOption, [filterKey]: value } as O)
    },
    [updateLoadOption, loadOption, filterKey]
  )

  return (
    <ODSearchBox
      placeholder={placeholder}
      style={style}
      value={loadOption[filterKey]}
      onChange={runSearch}
    />
  )
}
