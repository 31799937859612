import { ODListableOption } from '@odc/od-react-belt'
import { GQLChr } from '../../@types/server'

export const ChrPrimaryKey = 'chrId'

// modifiable fields only.
export interface ICTChr extends Omit<GQLChr, 'chrId'> {
  chrId: number | undefined
}

export interface IChrListableOption extends ODListableOption {
  filter: string
  main: boolean | null
}
