import { useODModalConfirm } from '@odc/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import {
  GQLBanWriterForStudioInput,
  GQLInputChangeUserPriv,
  GQLListableUser,
  GQLListableUserInput,
  GQLOkResponse,
  GQLSingleIDInput,
  GQLUser,
  GQLUserPropsInputByAdmin,
} from '../../@types/server'
import {
  ODListableOption,
  ODListableResponseType,
  useODListableContext,
} from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import {
  ODListablePaginatedTable,
  ODListableTableDefinition,
} from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { useModalSelect } from '../../components/ODModal/ODModalSelector'
import { Clickable } from '../../components/base/Clickable'
import { LastLoginToken } from '../../components/base/LastLoginToken'
import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
} from '../../components/base/ODButton'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import {
  ODTabBarButton,
  ODTabBarContainer,
} from '../../components/base/ODTabBar'
import { useCounter, useODMutation, useODQuery } from '../../context/ODCommon'
import { SiteUrls } from '../../urls'
import {
  USER_FULL_SNAPSHOT,
  USER_PRIV,
  USER_PRIV_TO_STRING,
} from '../../utils/constants'
import { Utils } from '../../utils/utils'
import { IUserBanModalInfoForOpen, UserBanModal } from './UserBanModal'

interface Props {}

export interface UserDataLoaderOption extends ODListableOption {
  filter: string | null
  userId: string | null
  userEmail: string | null
}

const GQL_LIST_USERS = `
query users($data: ListableUserInput!) {
  listUser(data: $data) {
    list {
      ${USER_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const GQL_CHANGE_USER_PRIV = `
mutation changeUserPriv($data: InputChangeUserPriv!) {
  changeUserPriv(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

const GQL_CHANGE_USER_IS_TESTER = `
mutation changeUserIsTester($data: UserPropsInputByAdmin!) {
  updateUserByAdmin(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

const GQL_BAN_WRITER_FOR_STUDIO = `
mutation banWriterForStudio($data: BanWriterForStudioInput!) {
  banWriterForStudio(data: $data) {
    ok
  }
}
`

const GQL_UN_BAN_WRITER_FOR_STUDIO = `
mutation unbanWriterForStudio($data: SingleIDInput!) {
  unbanWriterForStudio(data: $data) {
    ok
  }
}
`

export const UserListContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<
    GQLUser,
    UserDataLoaderOption
  >()
  const listUsers = useODQuery<Partial<GQLListableUserInput>, GQLListableUser>(
    GQL_LIST_USERS
  )
  const changeUserPriv = useODMutation<GQLInputChangeUserPriv, GQLUser>(
    GQL_CHANGE_USER_PRIV
  )
  const changeUserIsTester = useODMutation<
    Pick<GQLUserPropsInputByAdmin, 'id' | 'isTester'>,
    GQLUser
  >(GQL_CHANGE_USER_IS_TESTER)
  const banWriterForStudio = useODMutation<
    GQLBanWriterForStudioInput,
    GQLOkResponse
  >(GQL_BAN_WRITER_FOR_STUDIO)
  const unBanWriterForStudio = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_UN_BAN_WRITER_FOR_STUDIO
  )

  const [selected, setSelected] = React.useState(0)
  const [isEqualSearch, setIsEqualSearch] = React.useState(true)

  const [token, increaseToken] = useCounter()
  const [userBanModalInfoForOpen, setUserBanModalInfoForOpen] =
    React.useState<IUserBanModalInfoForOpen | null>(null)
  const {
    Component: Confirm,
    props: confirmProps,
    confirm,
  } = useODModalConfirm({})

  const {
    Component,
    choose,
    props: componentProps,
  } = useModalSelect({
    okTitle: '변경하기',
    selects: [
      {
        title: '익명 사용자',
        description: '익명 사용자',
        value: USER_PRIV.Normal,
      },
      {
        title: '가상 유저',
        description:
          '관리자가 작성하는 회차 댓글의 작성자로 참조 되는 사용자입니다.',
        value: USER_PRIV.Virtual,
      },
      {
        title: '일반 사용자',
        description: '일반 사용자는 서비스를 사용하는 일반 사용자입니다.',
        value: USER_PRIV.Formal,
      },
      {
        title: '작가',
        description:
          '작가는 관리자 사이트의 작가 페이지에 접근이 가능한 사용자입니다.',
        value: USER_PRIV.Author,
      },
      {
        title: '슈퍼작가',
        description:
          '슈퍼작가는 모든 작품을 조회/수정할 수 있는 권한을 갖는 작가입니다.',
        value: USER_PRIV.SuperAuthor,
      },
      {
        title: '관리자',
        description:
          '관리자는 서비스의 정해진 일부 기능을 수행할 수 있는 권한을 가진 사용자입니다.',
        value: USER_PRIV.Admin,
      },
      {
        title: '슈퍼관리자',
        description:
          '슈퍼관리자는 스토리플레이의 전반적인 서비스 변경 권한이 있는 사용자입니다.',
        value: USER_PRIV.SuperAdmin,
      },
    ],
    title: '사용자 권한 변경',
  })

  const {
    Component: ChangeUserIsTesterComponent,
    choose: chooseUserIsTester,
    props: changeUserIsTesterComponentProps,
  } = useModalSelect({
    okTitle: '변경하기',
    selects: [
      {
        title: '테스터',
        description: '해당 유저를 테스터로 변경합니다.',
        value: true,
      },
      {
        title: '테스터 아님',
        description: '해당 유저를 비테스터로 변경합니다.',
        value: false,
      },
    ],
    title: '사용자 테스터 여부 변경',
  })

  const handleChangeUserPriv = async (v: GQLUser) => {
    const priv = await choose(v.priv)
    if (priv !== null && priv !== v.priv) {
      await changeUserPriv({ id: v.userId, priv }).then(() => {
        Utils.showSuccess('사용자 권한을 변경하였습니다.', '성공')
        increaseToken()
      }, Utils.showError)
    }
  }

  const handleChangeUserIsTester = async (v: GQLUser) => {
    const isTester = await chooseUserIsTester(v.isTester)
    if (isTester !== null && isTester !== v.isTester) {
      await changeUserIsTester({
        id: v.userId,
        isTester: !v.isTester,
      }).then(() => {
        Utils.showSuccess('테스터 여부를 변경하였습니다.', '성공')
        increaseToken()
      }, Utils.showError)
    }
  }

  const handleBanUser = async (targetUserId: number, banReason: string) => {
    try {
      if (!banReason) {
        Utils.showError('정지 사유를 입력해 주세요.')
        return
      }

      const res = await banWriterForStudio({
        userId: targetUserId,
        banReason,
      })

      if (!!res.ok) {
        Utils.showSuccess('정지 되었습니다.')
        increaseToken()
        setUserBanModalInfoForOpen(null)
      } else {
        Utils.showInfo('알 수 없는 이유로 정지가 되지 않았습니다.')
      }
    } catch (ex) {
      // @ts-ignore
      Utils.showError(ex)
    }
  }

  const handleUnBanUser = async (userId: number) => {
    try {
      if (
        await confirm({
          title: '정지 해제',
          message: '정지 해제를 하시겠습니까?',
          yes: '해제',
          no: '취소',
        })
      ) {
        await unBanWriterForStudio({ id: userId })
        increaseToken()
      }
    } catch (ex) {
      // @ts-ignore
      Utils.showError(ex)
    }
  }

  const dataLoader = React.useCallback(
    async function UserDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: UserDataLoaderOption
    ): Promise<ODListableResponseType<GQLUser>> {
      const listUserData: Partial<GQLListableUserInput> = {
        page,
        pageSize,
        filter: options.filter || null,
        userId: options.userId || null,
        userEmail: options.userEmail || null,
        onlyLeftUser: selected === 4,
        isEqualSearch,
      }

      if (![0, 4].includes(selected)) {
        listUserData.priv =
          selected === 1
            ? [USER_PRIV.Author]
            : selected === 2
            ? [USER_PRIV.SuperAuthor]
            : selected === 5
            ? [USER_PRIV.Virtual]
            : [USER_PRIV.Admin, USER_PRIV.SuperAdmin]
      }
      const r = await listUsers(listUserData)
      return r as ODListableResponseType<GQLUser>
    },
    [selected, isEqualSearch, listUsers]
  )

  const TableDefinition: ODListableTableDefinition<
    GQLUser,
    UserDataLoaderOption,
    any
  > = [
    {
      id: 'userId',
      title: '사용자 번호',
      transform: (v) => v.userId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'name',
      title: '사용자명',
      transform: (v) => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'email',
      title: '이메일',
      transform: (v) => v.email,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    // 앱 언어 이벤트 디버깅을 위해 추가. 글로벌 적용되면 deprecated나 수정 되어야 함.
    {
      id: 'langCode',
      title: '앱 설정 언어',
      transform: (v) => v.sysLang?.code || 'null',
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'regDate',
      title: '가입시각',
      transform: (v) => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'perm',
      title: '권한레벨',
      transform: (v) => {
        if (v.leftAt) {
          return <span>탈퇴한 사용자</span>
        }
        return (
          <Clickable onClick={() => handleChangeUserPriv(v)}>
            {USER_PRIV_TO_STRING[v.priv]}
          </Clickable>
        )
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'lastLogin',
      title: '마지막 방문시각',
      transform: (v) => <LastLoginToken dateToken={v.lastAccessTime} />,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'isTester',
      title: '테스터 여부',
      transform: (v) => (
        <Clickable onClick={() => handleChangeUserIsTester(v)}>
          {v.isTester ? '테스터' : '테스터 아님'}
        </Clickable>
      ),
      thClass: 'text-left',
      className: 'text=left user-td',
    },
    {
      id: 'detail',
      title: '상세 정보',
      transform: (v) => (
        <Link to={SiteUrls.Admin.User.Edit(v.userId)}>정보 확인 및 수정</Link>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  if (selected === 1) {
    TableDefinition.push({
      id: 'manage',
      title: '관리',
      transform: (v) => (
        <>
          {!!v.bannedAt && (
            <ODButton
              theme={ODButtonTheme.Cancel}
              size={ODButtonSize.Small}
              onClick={() => handleUnBanUser(v.userId)}
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              정지 해제
            </ODButton>
          )}
          {!v.bannedAt && (
            <ODButton
              theme={ODButtonTheme.PrimaryInvert}
              size={ODButtonSize.Small}
              onClick={() =>
                setUserBanModalInfoForOpen({
                  userId: v.userId,
                  onClose: () => setUserBanModalInfoForOpen(null),
                  onConfirm: (banReason: string) =>
                    handleBanUser(v.userId, banReason),
                })
              }
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              정지
            </ODButton>
          )}
        </>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    })
  }

  if (selected === 3) {
    TableDefinition.push({
      id: 'leftAt',
      title: '탈퇴 시각',
      transform: (v) => <span>{Utils.formatDate(v.leftAt)}</span>,
      thClass: 'text-left',
      className: 'text-left user-td',
    })
    TableDefinition.push({
      id: 'leftReason',
      title: '탈퇴 사유',
      transform: (v) => <span>{v.deactivateReason ?? '-'}</span>,
      thClass: 'text-left',
      className: 'text-left user-td',
    })
  }

  return (
    <FlexContentsContainer>
      <Confirm {...confirmProps} />
      <Component {...componentProps} />
      <ChangeUserIsTesterComponent {...changeUserIsTesterComponentProps} />
      <UserBanModal infoForOpen={userBanModalInfoForOpen} />
      <ODTabBarContainer>
        <ODTabBarButton
          active={selected === 0}
          onClick={() => setSelected(0)}
          tabProps={{ width: '150' }}
        >
          <strong>전체</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === 1}
          onClick={() => setSelected(1)}
          tabProps={{ width: '150' }}
        >
          <strong>작가</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === 2}
          onClick={() => setSelected(2)}
          tabProps={{ width: '150' }}
        >
          <strong>슈퍼작가</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === 3}
          onClick={() => setSelected(3)}
          tabProps={{ width: '150' }}
        >
          <strong>관리자</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === 4}
          onClick={() => setSelected(4)}
          tabProps={{ width: '150' }}
        >
          <strong>탈퇴 사용자만</strong>
        </ODTabBarButton>
        <ODTabBarButton
          active={selected === 5}
          onClick={() => setSelected(5)}
          tabProps={{ width: '150' }}
        >
          <strong>가상유저</strong>
        </ODTabBarButton>
      </ODTabBarContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.userId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ODListableSearchBox
                  listableContext={Context}
                  filterKey="userId"
                  placeholder="사용자 번호로 검색하세요."
                  style={{ flexGrow: 6, maxWidth: 600 }}
                />

                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexGrow: 6,
                  }}
                >
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    <input
                      checked
                      type="radio"
                      name="isEqualSearch"
                      id="isEqualSearchTrue"
                      value="true"
                      onClick={(v) => {
                        setIsEqualSearch(true)
                      }}
                      style={{ marginRight: '5px' }}
                    />
                    <label>완전 일치</label>
                    <input
                      type="radio"
                      name="isEqualSearch"
                      id="isEqualSearchFalse"
                      value="false"
                      onClick={(v) => {
                        setIsEqualSearch(false)
                      }}
                      style={{ marginRight: '5px', marginLeft: '5px' }}
                    />
                    <label>부분 일치</label>
                  </div>
                  <ODListableSearchBox
                    listableContext={Context}
                    placeholder="이름, 이메일로 검색하세요."
                    style={{ flexGrow: 6, maxWidth: 600 }}
                  />
                </div>
                <div>
                  <Link
                    to={SiteUrls.Admin.User.Create}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button block color="primary" style={{ minWidth: 135 }}>
                      사용자 추가
                    </Button>
                  </Link>
                </div>
              </div>

              <p>이메일 전용 검색</p>
              <ODListableSearchBox
                listableContext={Context}
                filterKey="userEmail"
                placeholder="이메일로 검색하세요. (완전 일치만 가능)"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <p style={{ color: 'red', fontSize: 'smaller' }}>
                * 검색 할 항목의 검색어를 제외한 나머지 검색어들은 삭제해주세요.
              </p>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩 중..'}
              renderEmpty={() => '등록된 사용자가 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination
              hideIfSinglePage={false}
              listableContext={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
