import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLEnding } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IEndingListableOption } from '../EndingCommon'

export function useEndingListDataLoader(
  storyId: number,
  isFinalFilter: boolean | null
) {
  const { listEndingOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IEndingListableOption
    ): Promise<ODListableResponseType<GQLEnding>> {
      const r = await listEndingOfStory({
        page,
        pageSize: 100,
        isFinal: isFinalFilter,
        ...options,
        storyId,
      })
      return r as ODListableResponseType<GQLEnding>
    },
    [listEndingOfStory, isFinalFilter, storyId]
  )
}
