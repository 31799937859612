"use strict";
/**
 * 이것은 aop기능을 활성화할 함수를 지정한다. 이것이 지정된 함수만 실행시에 adviceContainerProxy에서 pre, post Advice를 찾아 실행한다.
 * @param jointPointSymbol
 * @returns
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.aop = void 0;
const adviceContainterProxy_1 = require("../adviceContainterProxy");
function aop(jointPointSymbol) {
    return function (target, propertyKey, descriptor) {
        const originalFunc = descriptor.value;
        descriptor.value = async function (...args) {
            // console.log("aop function called")
            console.log(jointPointSymbol, 'symbol for joint');
            //여기에서 pre advisor를 실행
            const preAdvisors = adviceContainterProxy_1.adviceContainerProxy.getPreAdvisors(jointPointSymbol);
            // console.log(preAdvisors, 'pre advisors')
            if (preAdvisors) {
                preAdvisors.forEach(value => {
                    value(this, ...args);
                });
            }
            //여기에서 목표 함수를 실행
            // console.log('original function', originalFunc)
            let retValue = await originalFunc.call(this, ...args);
            // console.log(retValue, 'original function return value')
            //여기에서 post advisor를 실행, post advisor는 함수 실행결과와 함수 매개변수를 넘겨받는다. 이렇게 하면 가변 매개변수를 처리할 수 있다.
            console.log(jointPointSymbol, 'symbol for joint, post search');
            const postAdvisors = adviceContainterProxy_1.adviceContainerProxy.getPostAdvisors(jointPointSymbol);
            console.log(postAdvisors, 'post advisors');
            if (postAdvisors) {
                postAdvisors.forEach(value => {
                    value(this, retValue, ...args);
                });
            }
            return retValue;
        };
        return descriptor;
    };
}
exports.aop = aop;
