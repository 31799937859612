"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseScopeString = exports.genScopeString = exports.decodeMetaDataUpdateActionFromSheet = exports.encodeMetaDataUpdateActionToSheet = void 0;
const IMetaDataUpdater_1 = require("./IMetaDataUpdater");
/**
 * MetaDataUpdateAction 을 액션 타입별로 구글 시트에 넣을 수 있는 string[] 형태로 변환한다.
 */
function encodeMetaDataUpdateActionToSheet(action) {
    const params = [];
    switch (action.action) {
        case IMetaDataUpdater_1.MetaDataAction.PropSetMain:
            params.push(action.propName);
            params.push(action.isMain ? 'Y' : 'N');
            break;
        case IMetaDataUpdater_1.MetaDataAction.EndingSetImage:
            params.push(action.endingId);
            params.push(action.endingName);
            params.push(action.link);
            params.push(action.hash);
            break;
        case IMetaDataUpdater_1.MetaDataAction.BackgroundSetImage:
            params.push(action.backgroundName);
            params.push(action.link);
            params.push(action.hash);
            break;
        case IMetaDataUpdater_1.MetaDataAction.ChrSetProperties:
            params.push(action.chrName);
            params.push(action.isMain ? 'Y' : 'N');
            params.push(action.color);
            params.push(action.link);
            params.push(action.hash);
            params.push(action.isHero ? 'Y' : 'N');
            break;
        default:
            const msg = `encodeMetaDataUpdateActionToSheet missing implementation for ${action.action}`;
            throw new Error(msg);
    }
    return [
        'Y',
        action.scope,
        IMetaDataUpdater_1.MetaDataActionSheetCommand[action.action],
        ...params,
    ];
}
exports.encodeMetaDataUpdateActionToSheet = encodeMetaDataUpdateActionToSheet;
/**
 * encodeMetaDataUpdateActionToSheet 를 통해 만들어진 string[] 를 다시 action 으로
 * 디코딩한다.
 */
function decodeMetaDataUpdateActionFromSheet(columnData) {
    var _a, _b, _c, _d, _e, _f, _g;
    const scope = (_a = columnData[1]) !== null && _a !== void 0 ? _a : '';
    const action = IMetaDataUpdater_1.InvertedMetaDataActionSheetCommand[columnData[2].trim()];
    const param1 = (_b = columnData[3]) !== null && _b !== void 0 ? _b : '';
    const param2 = (_c = columnData[4]) !== null && _c !== void 0 ? _c : '';
    const param3 = (_d = columnData[5]) !== null && _d !== void 0 ? _d : '';
    const param4 = (_e = columnData[6]) !== null && _e !== void 0 ? _e : '';
    const param5 = (_f = columnData[7]) !== null && _f !== void 0 ? _f : '';
    const param6 = (_g = columnData[8]) !== null && _g !== void 0 ? _g : '';
    const isYes = (s) => s.trim().toLowerCase() === 'y';
    switch (action) {
        case IMetaDataUpdater_1.MetaDataAction.PropSetMain:
            return {
                action,
                scope,
                propName: param1,
                isMain: isYes(param2),
            };
        case IMetaDataUpdater_1.MetaDataAction.EndingSetImage:
            return {
                action: IMetaDataUpdater_1.MetaDataAction.EndingSetImage,
                scope,
                endingId: param1,
                endingName: param2,
                link: param3,
                hash: param4,
            };
        case IMetaDataUpdater_1.MetaDataAction.BackgroundSetImage:
            return {
                action: IMetaDataUpdater_1.MetaDataAction.BackgroundSetImage,
                scope,
                backgroundName: param1,
                link: param2,
                hash: param3,
            };
        case IMetaDataUpdater_1.MetaDataAction.ChrSetProperties:
            return {
                action: IMetaDataUpdater_1.MetaDataAction.ChrSetProperties,
                scope,
                chrName: param1,
                isMain: isYes(param2),
                color: param3,
                link: param4,
                hash: param5,
                isHero: isYes(param6),
            };
        default:
            throw new Error(`decodeMetaDataUpdateActionFromSheet failed for command [${columnData[2]}]`);
    }
}
exports.decodeMetaDataUpdateActionFromSheet = decodeMetaDataUpdateActionFromSheet;
function genScopeString(chapterCustomId) {
    if (chapterCustomId === null) {
        return '작품';
    }
    return `챕터:${chapterCustomId}`;
}
exports.genScopeString = genScopeString;
function parseScopeString(scope) {
    if (scope === '작품') {
        return null;
    }
    return scope.split('챕터:')[1];
}
exports.parseScopeString = parseScopeString;
