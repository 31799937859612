"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTMainCharacterMessageImage = void 0;
const DOSTMessageImage_1 = require("./DOSTMessageImage");
class DOSTMainCharacterMessageImage extends DOSTMessageImage_1.DOSTMessageImage {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.chrField.setEditable(false);
    }
    get chr() {
        return this.block.parentChapter.mainCharacter;
    }
    get chrName() {
        return '{{성}}{{이름}}';
    }
    generateSheetColumnsAfterBackground() {
        // [캐릭터이름, 링크, 너비, 높이, 이미지 옵션]
        const result = [
            this.chrName,
            this.link,
            this.width.toString(),
            this.height.toString(),
            '',
            '',
            '',
        ];
        if (!!this.executeConditionOfStatement) {
            result.push(this.executeConditionOfStatement);
        }
        return result;
    }
    async getMessagesToTranslate() {
        return null;
    }
}
exports.DOSTMainCharacterMessageImage = DOSTMainCharacterMessageImage;
