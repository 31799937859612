import {
  GQLChapterOption,
  GQLChapterOptionCreationInput,
  GQLChapterOptionUpdateInput,
  GQLSTATUS,
} from '@storyplay/common'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import * as Yup from 'yup'
import React from 'react'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../ODEntityEditor/ODEntityEditorContext'
import { Utils } from '../../utils/utils'
import { SiteUrls } from '../../urls'
import {
  ODEntityInput,
  ODEntityLabeled,
  ODImageFileInput,
  ODModal,
  ODModalBody,
  ODModalHeader,
  ODModalSize,
  useCounter,
} from '@odc/od-react-belt'
import { ODEntityEditorFooter } from '../../ODEntityEditor/ODEntityEditorFooter'
import { ODFormToggleButton } from '../../components/ODFormToggleButton'
import { ToggleButtonWrapper } from '../../components/commonStyle'

interface IChapterOptionModalProps {
  storyId: number
  chapterId: number
  chapterOptionId: number
  onClose: () => void
  onConfirm: () => void
}

type Entity = GQLChapterOption

function getValidationSchema(
  values: Partial<GQLChapterOptionCreationInput | GQLChapterOptionUpdateInput>
) {
  return Yup.object().shape({})
}

export const ChapterOptionModal: React.FC<IChapterOptionModalProps> = (
  props
) => {
  const { storyId, chapterId, chapterOptionId, onClose, onConfirm } = props
  const {
    createChapterOption,
    updateChapterOption,
    getChapterOption,
    deleteChapterOption,
  } = useStoryPlayAPIs()
  const [token] = useCounter()
  const isCreating = chapterOptionId === 0
  const innerRef = React.useRef(null)

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLChapterOptionCreationInput | GQLChapterOptionUpdateInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (chapterOptionId) {
          return getChapterOption({ id: chapterOptionId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            chapterId,
            bannerImageFile: null,
            bannerImageConnectUrl: '',
            status: GQLSTATUS.Active,
          }
        }
        return {
          chapterOptionId,
          chapterId: data.chapterId,
          bannerImageConnectUrl: data.bannerImageConnectUrl,
          bannerImageFile: data.bannerImageFile,
          status: data.status,
        }
      },
      saveClientValueToServer: async (
        data: Partial<
          GQLChapterOptionCreationInput | GQLChapterOptionUpdateInput
        >
      ) => {
        const { bannerImageFile, bannerImageConnectUrl, chapterId, status } =
          data as GQLChapterOptionCreationInput
        if (chapterOptionId) {
          await updateChapterOption({
            id: chapterOptionId,
            bannerImageConnectUrl,
            status,
            bannerImageFile,
          })
          Utils.showSuccess('챕터 옵션이 수정되었습니다.', 'Success')
        } else {
          await createChapterOption({
            chapterId,
            bannerImageConnectUrl,
            bannerImageFile,
            status,
          })
          Utils.showSuccess('챕터 옵션이 등록되었습니다.', 'Success')
        }
        onConfirm()
        return SiteUrls.Admin.Story.ListChapter(storyId)
      },
      getValidationSchema,
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token)
        Utils.showError(ex)
      },
      deleteItem: async () => {
        if (chapterOptionId) {
          await deleteChapterOption({ id: chapterOptionId })
          Utils.showSuccess('삭제되었습니다.', 'Success')
        }
        onConfirm()
        return SiteUrls.Admin.Story.ListChapter(storyId)
      },
      __innerReference: innerRef,
    }),
    [
      storyId,
      chapterOptionId,
      chapterId,
      createChapterOption,
      updateChapterOption,
      getChapterOption,
      onConfirm,
      onClose,
    ]
  )
  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<GQLChapterOptionCreationInput | GQLChapterOptionUpdateInput>
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<GQLChapterOptionCreationInput | GQLChapterOptionUpdateInput>
    >(options)
  )
  React.useEffect(
    () => setOptions(createOptions()),
    [createOptions, createChapterOption]
  )

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Large}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader
          title={`챕터 옵션 ${isCreating ? '등록' : '수정'}하기`}
          onClose={onClose}
        ></ODModalHeader>
        <ODModalBody>
          <Provider title={'설정하기'}>
            {!isCreating && (
              <ODEntityInput
                keyPath="chapterId"
                label="챕터 아이디"
                name="chapterId"
                placeholder="챕터 아이디 (자동부여)"
                inputType="text"
                inputProps={{ disabled: true }}
              />
            )}
            <ODEntityLabeled label="배너 이미지 파일" name="bannerImageFile">
              <ODImageFileInput
                name="bannerImageFile"
                height={200}
                width={300}
                keyPath="bannerImageFile.link"
              />
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="bannerImageConnectUrl"
              label="배너이미지 이동 URL"
              name="bannerImageConnectUrl"
              placeholder="배너이미지 이동 URL"
              inputType="text"
            />
            <ODEntityLabeled label="배너 이미지 상태" name="배너 이미지 상태">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="statusActive"
                  name="status"
                  keyPath="status"
                  value={GQLSTATUS.Active}
                >
                  활성
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="statusInactive"
                  name="status"
                  keyPath="status"
                  value={GQLSTATUS.Inactive}
                >
                  비활성
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityEditorFooter
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={chapterOptionId ? '삭제' : undefined}
              saveButtonName="설정"
              context={Context}
            />
          </Provider>
        </ODModalBody>
      </div>
    </ODModal>
  )
}
