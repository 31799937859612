"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryPropertyErrorIdMain = exports.StoryPropertyErrorId = exports.DOStoryPropertyChange = void 0;
var DOStoryPropertyChange;
(function (DOStoryPropertyChange) {
    DOStoryPropertyChange["PropertyCreated"] = "PropertyCreated";
})(DOStoryPropertyChange = exports.DOStoryPropertyChange || (exports.DOStoryPropertyChange = {}));
// 존재하지 않는 프로퍼티, 오류가 발생했을 때를 대비하여 넣어둔다.
exports.StoryPropertyErrorId = -999999;
exports.StoryPropertyErrorIdMain = -999998;
