"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayEngineErrorCode = void 0;
/**
 * 플레이 엔진 에러는 800_000 이상으로 사용한다.
 */
var PlayEngineErrorCode;
(function (PlayEngineErrorCode) {
    PlayEngineErrorCode[PlayEngineErrorCode["NoCurrentBlock"] = 800000] = "NoCurrentBlock";
    PlayEngineErrorCode[PlayEngineErrorCode["NoMoreStatementLeftInThisBlock"] = 800001] = "NoMoreStatementLeftInThisBlock";
    PlayEngineErrorCode[PlayEngineErrorCode["CannotFindToBlockName"] = 800002] = "CannotFindToBlockName";
    PlayEngineErrorCode[PlayEngineErrorCode["TooManyLoopDetected"] = 800003] = "TooManyLoopDetected";
})(PlayEngineErrorCode = exports.PlayEngineErrorCode || (exports.PlayEngineErrorCode = {}));
