"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GNBStyleLayout = void 0;
var GNBStyleLayout;
(function (GNBStyleLayout) {
    GNBStyleLayout["Common"] = "Common";
    GNBStyleLayout["Release"] = "Release";
    GNBStyleLayout["Admin"] = "Admin";
    GNBStyleLayout["AdminManageStoryGameTabs"] = "AdminManageStoryGameTabs";
    GNBStyleLayout["EditChapter"] = "EditChapter";
    GNBStyleLayout["EditWebNovelChapter"] = "EditWebNovelChapter";
    GNBStyleLayout["StoryList"] = "StoryList";
    GNBStyleLayout["StoryManage"] = "StoryManage";
    GNBStyleLayout["StoryDashboard"] = "StoryDashboard";
    GNBStyleLayout["ChapterDashboard"] = "ChapterDashboard";
    GNBStyleLayout["PopularStoryDashboard"] = "PopularStoryDashboard";
    // Hb
    GNBStyleLayout["HbBotList"] = "HbBotList";
    GNBStyleLayout["HbBotRoot"] = "HbBotRoot";
    GNBStyleLayout["HbBotManage"] = "HbBotManage";
    GNBStyleLayout["HbBotManageEdit"] = "HbBotManageEdit";
    GNBStyleLayout["HbBotSkillList"] = "HbBotSkillList";
    GNBStyleLayout["HbBotSkillCreate"] = "HbBotSkillCreate";
    GNBStyleLayout["HbBotSkillEdit"] = "HbBotSkillEdit";
    GNBStyleLayout["HbBlockGroupList"] = "HbBlockGroupList";
    GNBStyleLayout["HbBlockGroupEdit"] = "HbBlockGroupEdit";
    GNBStyleLayout["HbAlgorithmList"] = "HbAlgorithmList";
})(GNBStyleLayout = exports.GNBStyleLayout || (exports.GNBStyleLayout = {}));
