"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryProperty = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../@types/server.schema");
const storeUtils_1 = require("../../utils/storeUtils");
const IDOStoryProperty_1 = require("./IDOStoryProperty");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
/**
 * Character domain object.
 */
class DOStoryProperty {
    constructor(store, data, isLocallyCreated = false) {
        this.propName = '';
        this.desc = '';
        this.imageFile = null;
        this.unit = null;
        this.propType = server_schema_1.GQLSTORY_PROP_TYPE.NUMBER;
        this.changes = [];
        this.store = store;
        this.data = data;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            propName: mobx_1.observable,
            isMain: mobx_1.observable,
            desc: mobx_1.observable,
            isUsed: mobx_1.observable,
            showOrder: mobx_1.observable,
            maxValue: mobx_1.observable,
            displayPropName: mobx_1.observable,
            propType: mobx_1.observable,
            merge: mobx_1.action,
            name: mobx_1.computed,
            imageLink: mobx_1.computed,
            convertedPropName: mobx_1.computed,
        });
        if (isLocallyCreated) {
            this.changes.push(IDOStoryProperty_1.DOStoryPropertyChange.PropertyCreated);
        }
    }
    merge(data) {
        const fields = [
            'propId',
            // propName 이 들어가면 안됨. propNameRaw 를 이용함.
            // https://jira.krafton.com/browse/SPSTUDIO-546
            // 'propName',
            'desc',
            'isMain',
            'isUsed',
            'showOrder',
            'maxValue',
            'displayPropName',
            'imageFile',
            'unit',
            'propType',
        ];
        (0, storeUtils_1.assignIf)(data, 'propNameRaw', v => (this.propName = v));
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    setName(name) {
        if (this.isLocallyCreated) {
            (0, mobx_1.runInAction)(() => {
                this.propName = name;
            });
        }
        else {
            throw new Error(trans('legacy_DOStoryItem_unimplemented_change_of_existing_attribute_name'));
        }
    }
    setDesc(desc) {
        if (this.isLocallyCreated) {
            (0, mobx_1.runInAction)(() => {
                this.desc = desc;
            });
        }
        else {
            throw new Error(trans('legacy_DOStoryItem_unimplemented_change_of_existing_attribute_name'));
        }
    }
    get name() {
        return this.propName;
    }
    get serverId() {
        return this.propId;
    }
    get imageLink() {
        var _a, _b;
        return (_b = (_a = this.imageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null;
    }
    get isLocallyCreated() {
        return this.changes.includes(IDOStoryProperty_1.DOStoryPropertyChange.PropertyCreated);
    }
    get convertedPropName() {
        if (!this.displayPropName) {
            return this.propName;
        }
        if (this.displayPropName !== this.propName) {
            return `${this.displayPropName}(${this.propName})`;
        }
        return this.displayPropName;
    }
    exportMetaDataUpdateActions() {
        const actions = [];
        // 관리 시트가 언제나 최신 버전의 정보를 갖게 하기 위하여 isLocallyCreated() 가 아니더라도,
        // 내보내기를 해준다.
        if (!this.isError) {
            actions.push({
                action: core_1.MetaDataAction.PropSetMain,
                scope: (0, core_1.genScopeString)(null),
                propName: this.propName,
                isMain: this.isMain,
            });
        }
        return actions;
    }
    get isError() {
        return (this.propId === IDOStoryProperty_1.StoryPropertyErrorId ||
            this.propId === IDOStoryProperty_1.StoryPropertyErrorIdMain);
    }
    async create() {
        var _a;
        const input = {
            propName: this.propName,
            desc: this.desc,
            isMain: this.isMain,
            storyId: this.store.story.storyId,
            displayPropName: this.propName,
            maxValue: this.maxValue,
            showOrder: this.showOrder,
            unit: null,
            propType: this.propType,
        };
        if ((_a = this.imageFile) === null || _a === void 0 ? void 0 : _a.fileId) {
            input.imageFromStudioFileId = this.imageFile.fileId;
        }
        const prop = await this.store.rootStore.di.server.createEntityForStudio(this, input);
        // 로컬에서 사용하던 아이디를 버리고, 서버와 동기화된 아이디로 새롭게 등록한다.
        this.propId = prop.propId;
        this.store.merge(prop);
        this.changes.length = 0;
        return prop;
    }
    async update(changeSet) {
        return this.store.rootStore.di.server.updateEntityForStudio(this, changeSet);
    }
    shouldBeFilteredBy(keyword) {
        return this.convertedPropName.toLowerCase().includes(keyword);
    }
}
exports.DOStoryProperty = DOStoryProperty;
