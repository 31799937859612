"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbEntityEditor = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const errors_1 = require("../../../errors");
const Waiter_1 = require("../utils/Waiter");
const ui_1 = require("./ui");
class HbEntityEditor {
    constructor(target, rootStore) {
        this._editorFields = [];
        this._editorFieldsLoaded = false;
        this._defFieldValues = {};
        this.status = 'create';
        this.isSubmitting = false;
        this._target = target;
        this.rootStore = rootStore;
        if (!!target) {
            this.status = 'update';
        }
        (0, mobx_1.makeObservable)(this, {
            _defFieldValues: mobx_1.observable,
            isSubmitting: mobx_1.observable,
        });
    }
    get apiServer() {
        return this.rootStore.di.server.hbClient;
    }
    createDefFieldValues() {
        const fieldValues = this.editorFields.map(f => ({
            id: f.id,
            value: f.value,
        }));
        const res = {};
        fieldValues.forEach(f => {
            res[f.id] = f.value;
        });
        (0, mobx_1.runInAction)(() => {
            this._defFieldValues = res;
        });
    }
    isChanged() {
        return this.editorFields.some(f => {
            if (f.value instanceof Array) {
                return !(0, lodash_1.isEqual)(f.value, this._defFieldValues[f.id]);
            }
            return f.value !== this._defFieldValues[f.id];
        });
    }
    get editorFields() {
        if (!this._editorFieldsLoaded) {
            this._editorFields = enumerateHbEditorField(this);
        }
        return this._editorFields;
    }
    /**
     * 특별한 방법으로 override 를 할 수 있도록 하기 위한 함수 포인트
     * @protected
     */
    onFieldValueChangeFound(field, v, changeSet) {
        if (field instanceof ui_1.MultiSelectionInput &&
            field.isSingleValue &&
            field.value[0]) {
            ;
            changeSet[field.id] = field.value[0];
        }
        else {
            ;
            changeSet[field.id] = v;
        }
    }
    createChangeSet() {
        const changeSet = {};
        this.editorFields.forEach(fld => {
            this.onFieldValueChangeFound(fld, fld.value, changeSet);
        });
        return changeSet;
    }
    async submitInternal(submit, onError) {
        if (this.isSubmitting) {
            // tslint:disable-next-line:no-console
            console.warn(`isSubmitting = true => isSubmitting 중일 때는 로딩창을 넣어주세요.`);
            onError === null || onError === void 0 ? void 0 : onError(new errors_1.SPCError(errors_1.ErrorCode.SUBMIT_IN_PROGRESS));
            return false;
        }
        const waiter = new Waiter_1.Waiter();
        (0, mobx_1.runInAction)(() => (this.isSubmitting = true));
        try {
            const cs = this.createChangeSet();
            if (cs === null) {
                onError === null || onError === void 0 ? void 0 : onError(new errors_1.SPCError(errors_1.ErrorCode.CHANGE_NOT_FOUND));
                (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
                return false;
            }
            await submit(cs);
            this.createDefFieldValues();
            this.rootStore.showMessage(this.status === 'create' ? '생성되었습니다.' : '수정되었습니다.');
            await waiter.waitMinimum(700);
        }
        catch (ex) {
            (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
            onError === null || onError === void 0 ? void 0 : onError(ex);
            return false;
        }
        (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
        return true;
    }
}
exports.HbEntityEditor = HbEntityEditor;
/**
 * 매번 필드를 반환하는 귀찮음을 줄이기 위해 class 멤버 필드에서 에디터 필드를 찾는다.
 */
function enumerateHbEditorField(target) {
    return Object.getOwnPropertyNames(target)
        .filter(name => {
        // IEditorField 를 이걸로 판별하였음.
        return (target[name] &&
            target[name].hasOwnProperty('id') &&
            target[name].hasOwnProperty('value') &&
            target[name].hasOwnProperty('disabled') &&
            !target[name].disabled);
    })
        .map(name => {
        return target[name];
    });
}
