"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTChoiceSaveProp = void 0;
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const IDOStatement_1 = require("../IDOStatement");
class DOSTChoiceSaveProp extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: __1.STATEMENT_TYPE.ChoiceSaveProp,
            propChoices: [
                {
                    propName: IDOStatement_1.PROP_EMPTY,
                    propOp: __1.PROP_OPERATION.INCREASE_NUMBER,
                    value: '0',
                },
                {
                    propName: IDOStatement_1.PROP_EMPTY,
                    propOp: __1.PROP_OPERATION.INCREASE_NUMBER,
                    value: '0',
                },
            ],
            background: '',
        }, block, uniqueId);
    }
    get st() {
        return this.data;
    }
    addChoice() {
        (0, mobx_1.runInAction)(() => {
            this.st.propChoices.push({
                propName: IDOStatement_1.PROP_EMPTY,
                propOp: __1.PROP_OPERATION.INCREASE_NUMBER,
                value: '0',
            });
        });
    }
    removeChoice(index) {
        (0, mobx_1.runInAction)(() => {
            this.st.propChoices.splice(index, 1);
        });
    }
}
exports.DOSTChoiceSaveProp = DOSTChoiceSaveProp;
