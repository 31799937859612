"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbAttributeAPI = void 0;
class HbAttributeAPI {
    constructor(server) {
        this.server = server;
    }
    async list(qs) {
        return this.server.get(`/v1/attributes`, qs);
    }
    async create(payload) {
        return this.server.post(`/v1/attributes`, payload);
    }
}
exports.HbAttributeAPI = HbAttributeAPI;
// {
//     botId,
//     pageSize:100,
//     sort:'userCount',
//     sortDirection:'desc'
//     pageIndex:0
// }
