import { produce } from 'immer'
import * as React from 'react'
import {
  GQLAppConfig,
  GQLEmailLogin,
  GQLExtendAdminLoginTokenInput,
  GQLLoginResponse,
  GQLOkResponse,
  GQLOTPKeyCheckInput,
  GQLOTPKeyRegisterInput,
  GQLOTPLoginResponse,
  GQLUser,
} from '../@types/server'
import {
  LOCAL_STORAGE_KEY_TOKEN,
  LOCAL_STORAGE_KEY_TOKEN_EXPIRE_AT,
  TOKEN_EXPIRE_AFTER_SEC_ADMIN,
  TOKEN_EXPIRE_AFTER_SEC_WRITER,
} from '../common'
import { AppOptions } from '../utils/AppOptions'
import { USER_PRIV } from '../utils/constants'
import { useODMutation, useODQuery } from './ODCommon'

const GQL_APP_CONFIG = `
query appConfig {
  appConfig {
    configId
  }
}`

const USER_FULL_SNAPSHOT = `
  userId
  name
  email
  lastLoginToken
  lastAccessTime
  unread
  priv
  createdAt
  updatedAt
  isTester
  otpKey
`

const GQL_GET_USER_PROFILE = `
query {
  getUser {
    ${USER_FULL_SNAPSHOT}
  }
}`

const GQL_USER_LOGIN = `
mutation loginAdmin($data: EmailLogin!) {
  loginAdmin(data: $data) {
    isRegisteredOTP
    token
  }
}`

const GQL_USER_LOGOUT = `
mutation {
  logoutUser {
    ok
  }
}
`

const GQL_REGISTER_OTP_KEY = `
mutation registerOtpKey($data: OTPKeyRegisterInput!) {
  registerOtpKey(data: $data) {
    me {
      ${USER_FULL_SNAPSHOT}
    }
    token
  }
}
`

const GQL_LOGIN_ADMIN_WITH_OTP = `
mutation loginAdminWithOTP($data: OTPKeyCheckInput!) {
  loginAdminWithOTP(data: $data) {
    me {
      ${USER_FULL_SNAPSHOT}
    }
    token
  }
}`

export const GQL_EXTEND_ADMIN_LOGIN_TOKEN = `
mutation extendAdminLoginToken($data: ExtendAdminLoginTokenInput!) {
  extendAdminLoginToken(data: $data) {
    me {
      ${USER_FULL_SNAPSHOT}
    }
    token
  }
}
`

type AppContextType = {
  state: AppReducerState
  tokenExpireAt: number | null
  getUserProfile: (input: void) => Promise<GQLUser>
  refreshProfile: () => Promise<void>

  loginUser: (input: GQLEmailLogin) => Promise<boolean>
  logoutUser: () => Promise<void>

  registerOTPKey: (input: GQLOTPKeyRegisterInput) => Promise<GQLUser>
  loginAdminWithOTP: (input: GQLOTPKeyCheckInput) => Promise<GQLUser>

  setLoggedIn: (profile: GQLUser) => void
  setPasswordTooOld: (old: boolean) => void
  extendSession: () => Promise<void>
}

export enum LOGIN_STATE {
  Checking = 'Checking',
  LoggedOut = 'LoggedOut',
  LoggingIn = 'LoggingIn',
  LoggedIn = 'LoggedIn',
  LoggingOut = 'LoggingOut',
}

type AppContextProviderProps = {}

type AppReducerState = {
  loginState: LOGIN_STATE
  userProfile: GQLUser | null
  passwordTooOld: boolean
  appConfig: GQLAppConfig | null
  directoryNodeCounter: number
}

enum AppActionType {
  SetLoggedIn = 'odApp/SetLoggedIn',
  SetLoggedOut = 'odApp/SetLoggedOut',
  SetAppConfig = 'odApp/SetAppConfig',
  SetPasswordTooOld = 'odApp/SetPasswordTooOld',
}

type AppActionSetLoggedIn = {
  type: AppActionType.SetLoggedIn
  profile: GQLUser
}
type AppActionSetLoggedOut = { type: AppActionType.SetLoggedOut }
type AppActionSetAppConfig = {
  type: AppActionType.SetAppConfig
  config: GQLAppConfig
}
type AppActionSetPasswordTooOld = {
  type: AppActionType.SetPasswordTooOld
  old: boolean
}

type AppReducerAction =
  | AppActionSetLoggedIn
  | AppActionSetLoggedOut
  | AppActionSetAppConfig
  | AppActionSetPasswordTooOld

interface AppReducer extends React.Reducer<AppReducerState, AppReducerAction> {}

function createAppReducer(): AppReducer {
  return function (
    state: AppReducerState,
    action: AppReducerAction
  ): AppReducerState {
    return produce(state, (draft) => {
      switch (action.type) {
        case AppActionType.SetLoggedIn:
          draft.loginState = LOGIN_STATE.LoggedIn
          draft.userProfile = action.profile
          break
        case AppActionType.SetLoggedOut:
          draft.loginState = LOGIN_STATE.LoggedOut
          break
        case AppActionType.SetAppConfig:
          draft.appConfig = action.config
          break
        case AppActionType.SetPasswordTooOld:
          draft.passwordTooOld = action.old
          break
        default:
          return
      }
    })
  }
}

const actionSetLoggedIn = (profile: GQLUser): AppActionSetLoggedIn => ({
  type: AppActionType.SetLoggedIn,
  profile,
})
const actionSetLoggedOut = (): AppActionSetLoggedOut => ({
  type: AppActionType.SetLoggedOut,
})
const actionSetAppConfig = (config: GQLAppConfig): AppActionSetAppConfig => ({
  type: AppActionType.SetAppConfig,
  config,
})

function createInitialAppReducerState(): AppReducerState {
  return {
    loginState: LOGIN_STATE.Checking,
    userProfile: null,
    passwordTooOld: false,
    appConfig: null,
    directoryNodeCounter: 0,
  }
}

function createAppContext() {
  const Context: React.Context<AppContextType> =
    React.createContext<AppContextType>({} as AppContextType)

  const AppProvider: React.FC<AppContextProviderProps> = (props) => {
    const { children } = props

    const simulateDelay = AppOptions.SIMULATE_DELAY || 0

    const apiGetAppConfig = useODQuery<void, GQLAppConfig>(
      GQL_APP_CONFIG,
      simulateDelay
    )
    const apiGetUserProfile = useODQuery<void, GQLUser>(
      GQL_GET_USER_PROFILE,
      simulateDelay
    )
    const apiLoginUser = useODMutation<GQLEmailLogin, GQLOTPLoginResponse>(
      GQL_USER_LOGIN,
      simulateDelay
    )
    const apiLogout = useODMutation<void, GQLOkResponse>(
      GQL_USER_LOGOUT,
      simulateDelay
    )
    const apiRegisterOTPKey = useODMutation<
      GQLOTPKeyRegisterInput,
      GQLLoginResponse
    >(GQL_REGISTER_OTP_KEY, simulateDelay)
    const apiLoginAdminWithOTP = useODMutation<
      GQLOTPKeyCheckInput,
      GQLLoginResponse
    >(GQL_LOGIN_ADMIN_WITH_OTP, simulateDelay)
    const apiExtendSession = useODMutation<
      GQLExtendAdminLoginTokenInput,
      GQLLoginResponse
    >(GQL_EXTEND_ADMIN_LOGIN_TOKEN, simulateDelay)

    const [state, dispatch] = React.useReducer<AppReducer>(
      createAppReducer(),
      createInitialAppReducerState()
    )
    const setLoggedIn = React.useCallback(
      (profile: GQLUser) => dispatch(actionSetLoggedIn(profile)),
      [dispatch]
    )
    const setPasswordTooOld = React.useCallback(
      (old: boolean) =>
        dispatch({ type: AppActionType.SetPasswordTooOld, old }),
      [dispatch]
    )

    // expire at
    const [tokenExpireAt, setTokenExpireAt] = React.useState<number | null>(
      () => {
        const expireAt = sessionStorage.getItem(
          LOCAL_STORAGE_KEY_TOKEN_EXPIRE_AT
        )
        if (expireAt) {
          return parseInt(expireAt, 10)
        }
        return null
      }
    )

    const isWriter = (state.userProfile?.priv ?? 0) <= USER_PRIV.SuperAuthor
    const extendSession = React.useCallback(async () => {
      const now = new Date().getTime()
      const res = await apiExtendSession({
        extendInSec: isWriter
          ? TOKEN_EXPIRE_AFTER_SEC_WRITER
          : TOKEN_EXPIRE_AFTER_SEC_ADMIN,
      })
      await sessionStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
      await sessionStorage.setItem(
        LOCAL_STORAGE_KEY_TOKEN_EXPIRE_AT,
        now.toString()
      )
      setTokenExpireAt(now)
      setLoggedIn(res.me)
    }, [apiExtendSession, setLoggedIn, setTokenExpireAt, isWriter])

    const checkLogin = React.useCallback(async () => {
      try {
        const appConfig = await apiGetAppConfig()
        dispatch(actionSetAppConfig(appConfig))

        const profile = await apiGetUserProfile()
        setLoggedIn(profile)
      } catch (ex) {
        // 로그인 실패, 로그아웃된 상태
        dispatch(actionSetLoggedOut())
      }
    }, [apiGetUserProfile, setLoggedIn, apiGetAppConfig])

    const loginUser = React.useCallback(
      async (data: GQLEmailLogin): Promise<boolean> => {
        const now = new Date().getTime()
        const res = await apiLoginUser({
          email: data.email,
          password: data.password,
        })
        await sessionStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
        await sessionStorage.setItem(
          LOCAL_STORAGE_KEY_TOKEN_EXPIRE_AT,
          now.toString()
        )
        setTokenExpireAt(now)

        return res.isRegisteredOTP
      },
      [apiLoginUser]
    )

    const registerOTPKey = React.useCallback(
      async (data: GQLOTPKeyRegisterInput): Promise<GQLUser> => {
        const { otpKey, token } = data
        const res = await apiRegisterOTPKey({ otpKey, token })
        await sessionStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
        setLoggedIn(res.me)
        if (res.me.passwordTooOld) {
          setPasswordTooOld(true)
        }

        return res.me
      },
      [apiRegisterOTPKey, setLoggedIn, setPasswordTooOld]
    )

    const loginAdminWithOTP = React.useCallback(
      async (data: GQLOTPKeyCheckInput) => {
        const { token } = data
        const res = await apiLoginAdminWithOTP({ token })
        await sessionStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
        setLoggedIn(res.me)
        if (res.me.passwordTooOld) {
          setPasswordTooOld(true)
        }

        return res.me
      },
      [apiLoginAdminWithOTP, setLoggedIn, setPasswordTooOld]
    )

    const logoutUser = React.useCallback(async () => {
      await apiLogout()
      await sessionStorage.clear()
      await localStorage.clear()
      dispatch(actionSetLoggedOut())
    }, [apiLogout])

    const refreshProfile = React.useCallback(async () => {
      const profile = await apiGetUserProfile()
      setLoggedIn(profile)
    }, [apiGetUserProfile, setLoggedIn])

    React.useEffect(() => {
      // noinspection JSIgnoredPromiseFromCall
      checkLogin()
    }, [checkLogin])

    const context: AppContextType = {
      state,
      getUserProfile: apiGetUserProfile,
      loginUser,
      logoutUser,
      setLoggedIn,
      refreshProfile,
      setPasswordTooOld,
      registerOTPKey,
      loginAdminWithOTP,
      tokenExpireAt,
      extendSession,
    }
    return <Context.Provider value={context}>{children}</Context.Provider>
  }

  return { Context, Provider: AppProvider }
}

const { Context, Provider } = createAppContext()

export const AppProvider = Provider

export function useAppContext(): AppContextType {
  return React.useContext(Context)
}
