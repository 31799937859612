import {
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  useCounter,
} from '@odc/od-react-belt'
import { GQLSTORY_HAS_RECOMMENDATION_TYPE, GQLStoryHasRecommendSection, GQLStoryHasRecommendSectionCreationInput, GQLStoryHasRecommendSectionUpdateInput } from '@storyplay/core'
import React from 'react'
import Select from 'react-select'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import {
  ODEntityEditorContextOptions,
  createODEntityEditorContext,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SPFormToggleButton } from '../../../components/SPFormToggleButton'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IStroyHasRecommendSectionPageProps {
  storyId: number
  storyHasRecommendSectionId: number
}

type Entity = GQLStoryHasRecommendSection

function getValidationSchema(
  values: Partial<GQLStoryHasRecommendSectionCreationInput | GQLStoryHasRecommendSectionUpdateInput>
) {
  return Yup.object().shape({})
}

export const StoryHasRecommendSectionEditPage: React.FC<IStroyHasRecommendSectionPageProps> = (
  props
) => {
  const { storyId, storyHasRecommendSectionId } = props
  const innerRef = React.useRef(null)
  const typeRef = React.useRef('')
  const [chapterName, setChapterName] = React.useState<string | undefined>(undefined)
  const setTypeRef = (v: string) => {
    typeRef.current = v
  }

  const [chapterOption, setChapterOption] = React.useState<
    { label: string; value: number }[]
  >([])

  const {
    createStoryHasRecommendSection,
    updateStoryHasRecommendSection,
    deleteStoryHasRecommendSection,
    getStoryHasRecommendSection,
    getAllChaptersById: apiGetAllChapters,
  } = useStoryPlayAPIs()

  const [token] = useCounter()
  const isCreating = storyHasRecommendSectionId === 0

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLStoryHasRecommendSectionCreationInput | GQLStoryHasRecommendSectionUpdateInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (storyHasRecommendSectionId) {
          return getStoryHasRecommendSection({ id: storyHasRecommendSectionId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            type: GQLSTORY_HAS_RECOMMENDATION_TYPE.AdminSelect,
            title: undefined,
            subTitle: undefined,
            storyId: 0,
            chapterId: 0,
          }
        }
        typeRef.current = data.type.toString()
        if (data.chapter) {
          setChapterName(`${data.chapter.chapterIndex}화 ${data.chapter.name}`)
        }
        return {
          storyId: data.storyId,
          storyHasRecommendSectionId: data.storyHasRecommendSectionId,
          type: data.type,
          title: data.title,
          subTitle: data.subTitle,
          chapterId: data.chapterId,
          chapterName: data.chapter?.name,
        }
      },
      saveClientValueToServer: async (
        data: Partial<
        GQLStoryHasRecommendSectionCreationInput | GQLStoryHasRecommendSectionUpdateInput
        >
      ) => {
        const {
          type,
          title,
          subTitle,
        } = data as GQLStoryHasRecommendSectionCreationInput
        const chapterId = selectedChapterRef.current
          ? selectedChapterRef.current
          : undefined

        if (storyHasRecommendSectionId) {
          await updateStoryHasRecommendSection({
            id: storyHasRecommendSectionId,
            title,
            subTitle,
          })
          Utils.showSuccess('섹션이 수정되었습니다.', 'Success')
        } else { 
          await createStoryHasRecommendSection({
            storyId,
            chapterId,
            type,
            title,
            subTitle,
          })
          Utils.showSuccess('섹션이 생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.Edit(storyId, 13)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (storyHasRecommendSectionId) {
          await deleteStoryHasRecommendSection({ id: storyHasRecommendSectionId })
          Utils.showSuccess('섹션이 삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.EPUBStory.Edit(storyId, 3)
      },
      __innerReference: innerRef,
    }),
    [
      storyHasRecommendSectionId,
      getStoryHasRecommendSection,
      storyId,
      updateStoryHasRecommendSection,
      createStoryHasRecommendSection,
      token,
      deleteStoryHasRecommendSection,
    ]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<GQLStoryHasRecommendSectionCreationInput | GQLStoryHasRecommendSectionUpdateInput>
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<GQLStoryHasRecommendSectionCreationInput | GQLStoryHasRecommendSectionUpdateInput>
    >(options)
  )

  React.useEffect(
    () => setOptions(createOptions()),
    [createOptions, createStoryHasRecommendSection]
  )

  const selectedChapterRef = React.useRef(0)
  React.useEffect(() => {
    apiGetAllChapters({ id: storyId }).then((story) => {
      setChapterOption(
        story.allChapters
          .map((chapter) => ({
            label: `${chapter.chapterIndex}화 ${chapter.name}`,
            value: chapter.chapterId,
          }))
      )
    })
  }, [apiGetAllChapters, storyId])

  const title = !storyHasRecommendSectionId ? '추천 섹션 생성' : '추천 섹션 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            {!isCreating && (
              <ODEntityInput
                keyPath="storyHasRecommendSectionId"
                label="섹션 아이디"
                name="storyHasRecommendSectionId"
                placeholder="추천 섹션 아이디 (자동부여)"
                inputType="text"
                inputProps={{ disabled: true }}
              />
            )}
            {!isCreating && chapterName && (
              <ODEntityLabeled name="챕터명" label="챕터명">
                <ODEntityRaw
                  name="chapterName"
                  keyPath="chapterName"
                  render={({ value }) => {
                    return <span>{value ? value : '없음'}</span>
                  }}
                />
            </ODEntityLabeled>
            )}
            {isCreating && (
              <>
                <ODEntityLabeled name={'섹션 챕터'} label={'섹션 챕터'}>
                  <Select
                    isSearchable={true}
                    placeholder="추천 섹션이 보일 챕터(선택 안하면, 작품 기준으로 설정됩니다.)"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedChapterRef.current = v.value
                      }
                    }}
                    options={chapterOption}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </ODEntityLabeled>

                <ODEntityLabeled
                  label="추천 섹션 타입"
                  name="추천 섹션 타입"
                >
                  <ToggleButtonWrapper>
                    <SPFormToggleButton
                      name="type"
                      keyPath="type"
                      value={GQLSTORY_HAS_RECOMMENDATION_TYPE.AdminSelect}
                      onClick={setTypeRef}
                    >
                      어드민 설정
                    </SPFormToggleButton>
                  </ToggleButtonWrapper>
                </ODEntityLabeled>
              </>
            )}
            {!isCreating && (
              <>
                <ODEntityLabeled name="타입" label="타입">
                  <ODEntityRaw
                    name="type"
                    keyPath="type"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            <ODEntityInput
              keyPath="title"
              label="섹션 타이틀"
              name="title"
              placeholder="섹션 타이틀"
              inputType="text"
            />
            <ODEntityInput
              keyPath="subTitle"
              label="섹션 서브 타이틀"
              name="subTitle"
              placeholder="섹션 서브 타이틀"
              inputType="text"
            />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: (
                  <>정말 삭제하시겠습니까?</>
                ),
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={storyHasRecommendSectionId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
