"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTLeftThink = void 0;
const core_1 = require("@storyplay/core");
const __1 = require("../../../../..");
const DOSTBubbleWithChrBase_1 = require("./DOSTBubbleWithChrBase");
class DOSTLeftThink extends DOSTBubbleWithChrBase_1.DOSTBubbleWithChrBase {
    constructor() {
        super(...arguments);
        this.isForMainChr = false;
    }
    replaceToOpposite(newChrName) {
        const dataChanging = {
            ...this.data,
            statementType: core_1.STATEMENT_TYPE.MainCharacterThink,
            chrName: core_1.STUDIO_SHEET_CONST.HERO_NAME,
        };
        this.block.replaceStatement(this, new __1.DOSTRightThink(dataChanging, this.block));
    }
}
exports.DOSTLeftThink = DOSTLeftThink;
