"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileInput = exports.MAX_IMAGE_SIZE = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const IFileInput_1 = require("./IFileInput");
const IInputBase_1 = require("./IInputBase");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
exports.MAX_IMAGE_SIZE = 1000000;
class FileInput {
    constructor(id, label, existingFile, options = {}) {
        var _a, _b, _c;
        this.inputModelType = IInputBase_1.InputModelType.FileInput;
        this.tempUrl = null;
        this.fileToUpload = null;
        this.showLabel = true;
        this.aspectRatio = undefined;
        this.id = id;
        this.label = label;
        this.existingFile = existingFile;
        this.options = options;
        this.showLabel = (_a = options.showLabel) !== null && _a !== void 0 ? _a : true;
        this.theme = options.theme;
        this.aspectRatio = options.aspectRatio;
        this.required = (_b = options.required) !== null && _b !== void 0 ? _b : false;
        this.disabled = (_c = options.disabled) !== null && _c !== void 0 ? _c : false;
        this.isSizeError = false;
        (0, mobx_1.makeObservable)(this, {
            existingFile: mobx_1.observable,
            tempUrl: mobx_1.observable,
            fileToUpload: mobx_1.observable,
            isSizeError: mobx_1.observable,
            imageLink: mobx_1.computed,
            uploadDragGuideText: mobx_1.computed,
            clickToUploadGuideText: mobx_1.computed,
            hasChange: mobx_1.computed,
            value: mobx_1.computed,
        });
    }
    get value() {
        return this.fileToUpload;
    }
    getIsValid(newValue) {
        if (this.required && !this.disabled && !this.existingFile) {
            return !!this.fileToUpload;
        }
        if (this.isSizeError) {
            return false;
        }
        return true;
    }
    resetWithExistingFile(existingFile) {
        (0, mobx_1.runInAction)(() => {
            this.existingFile = existingFile;
            this.fileToUpload = null;
            this.tempUrl = null;
        });
    }
    onChange(file, localUrl) {
        var _a, _b;
        (0, mobx_1.runInAction)(() => {
            if (file.size > exports.MAX_IMAGE_SIZE) {
                this.isSizeError = true;
            }
            else {
                this.isSizeError = false;
            }
            if (this.theme === IFileInput_1.FileTheme.StoryGameUploadItem) {
                return;
            }
            this.fileToUpload = file;
            this.tempUrl = localUrl;
        });
        (_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.onChange) === null || _b === void 0 ? void 0 : _b.call(_a, file);
    }
    onRemove() {
        var _a, _b;
        (0, mobx_1.runInAction)(() => {
            this.existingFile = null;
            this.fileToUpload = null;
            this.tempUrl = null;
            if (this.options.imageLink) {
                this.options.imageLink = undefined;
            }
        });
        (_b = (_a = this.options).onRemove) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    get imageLink() {
        var _a, _b;
        const link = (() => {
            if (this.existingFile && this.existingFile.uploaded) {
                return this.existingFile.uploaded.link;
            }
            else if (this.existingFile &&
                this.existingFile.link) {
                return this.existingFile.link;
            }
            return this.options.imageLink;
        })();
        return (_b = (_a = this.tempUrl) !== null && _a !== void 0 ? _a : link) !== null && _b !== void 0 ? _b : null;
    }
    get accept() {
        var _a;
        return (_a = this.options.accept) !== null && _a !== void 0 ? _a : undefined;
    }
    get uploadDragGuideText() {
        var _a;
        return ((_a = this.options.uploadDragGuideText) !== null && _a !== void 0 ? _a : trans('legacy_FileInput.ts_drop_file_to_upload'));
    }
    get clickToUploadGuideText() {
        var _a;
        return ((_a = this.options.clickToUploadGuideText) !== null && _a !== void 0 ? _a : trans('legacy_FileInput.ts_select_file'));
    }
    get hasChange() {
        return !!this.fileToUpload;
    }
}
exports.FileInput = FileInput;
