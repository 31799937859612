import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLEnding } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { COLORS } from '../../../styles/colors'
import { SiteUrls } from '../../../urls'
import { EndingPrimaryKey, IEndingListableOption } from '../EndingCommon'
import { EndingIsFinalSelect, IEndingIsFinalSelectFilterType } from './EndingIsFinalSelect'
import { useEndingListDataLoader } from './EndingListDataLoader'

interface IEndingListableTableProps {
  storyId: number
}

export const EndingListableTable: React.FC<IEndingListableTableProps> = (props) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<GQLEnding, IEndingListableOption>()
  const [isFinalFilter, setIsFinalFilter] = React.useState<IEndingIsFinalSelectFilterType>(null)
  const [token] = useCounter()
  const dataLoader = useEndingListDataLoader(storyId, isFinalFilter)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = { display: 'flex', marginBottom: 15 }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[EndingPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox listableContext={Context} placeholder="이름으로 검색" style={searchBoxStyle} />
            <div style={{ width: 250, marginLeft: 15 }}>
              <EndingIsFinalSelect value={isFinalFilter} onChange={setIsFinalFilter} />
            </div>
          </div>
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>엔딩 아이디</th>
                <th>CustomID</th>
                <th style={{ maxWidth: 300 }}>엔딩명</th>
                <th style={{ maxWidth: 100 }}>미리보기 엔딩명</th>
                <th style={{ maxWidth: 400 }}>엔딩설명</th>
                <th>최종엔딩 도착률<br/>[실제값] 어드민 설계값</th>
                <th>노출 우선순위</th>
                <th>챕터명</th>
                <th>엔딩이미지</th>
                <th>부분캡쳐이미지</th>
                <th>최종엔딩?</th>
              </tr>
            )}
            renderRow={(value: GQLEnding, context) => (
              <HoverTR
                key={value.endingId}
                onClick={() => setRedirect(SiteUrls.Admin.Story.EditEnding(storyId, value.endingId, value.isFinal))}
              >
                <td>{value.endingId}</td>
                <td>{value.customId}</td>
                <td style={{ maxWidth: 300 }}>{value.name}</td>
                <td style={{ maxWidth: 100 }}>{value.previewName}</td>
                <td style={{ maxWidth: 400 }}>{value.collectionDescription}</td>
                <td>
                  {value.isFinal ? `[${value.realArrivalRate || 0}%]` : ''}&nbsp;&nbsp;
                  {value.isFinal && value.arrivalRateByAdmin ? `${value.dailyChangeRate}%(${value.arrivalRateByAdmin})` : ''}
                </td>
                <td>{value.showOrder}</td>
                <td>{value.chapter.name}</td>
                <td>
                  {value.imageFile?.link ? (
                    <img src={value.imageFile!.link} width={100} height={100} alt="ending" />
                  ) : (
                    <span>미등록</span>
                  )}
                </td>
                <td>
                  {value.previewImageFile?.link ? (
                    <img src={value.previewImageFile!.link} width={100} height={100} alt="previewEnding" />
                  ) : (
                    <span>미등록</span>
                  )}
                </td>
                <td>
                  {value.isFinal ? (
                    <span style={{ fontWeight: 'bold', color: COLORS.Primary }}>최종</span>
                  ) : (
                    <span style={{ color: COLORS.GRAY600 }}>챕터 엔딩</span>
                  )}
                </td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
