"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbBlockGroupApI = void 0;
const hbApiUtils_1 = require("../hbApiUtils");
class HbBlockGroupApI {
    constructor(server) {
        this.server = server;
    }
    async create(payload) {
        return this.server.post(`/v1/group`, payload);
    }
    async update(blockGroupId, payload) {
        return this.server.put(`/v1/group/${blockGroupId}`, payload);
    }
    async listWithBlockMessages(groupId) {
        return this.server.get(`/v1/group/${groupId}?includeBlocksAndMessages=true`);
    }
    async listByBot(botId, options) {
        return this.server.get(`/v1/group?${(0, hbApiUtils_1.createQueryParams)({
            botId,
            sort: options === null || options === void 0 ? void 0 : options.sort,
            sortDirection: options === null || options === void 0 ? void 0 : options.sortDirection,
            pageIndex: options === null || options === void 0 ? void 0 : options.pageIndex,
            pageSize: options === null || options === void 0 ? void 0 : options.pageSize,
        })}`);
    }
    async remove(id) {
        await this.server.delete(`/v1/group/${id}`, {});
    }
}
exports.HbBlockGroupApI = HbBlockGroupApI;
