import React from 'react'
import { SAMPLE_TEXT } from './TtsSpeakerCommon'
import { GQLTTS_EMOTION, GQLTTS_DPE_ID } from '@storyplay/common'
import { Button } from 'reactstrap'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { isNumber } from 'lodash'
import { Utils } from '../../utils/utils'

export const AudioListenerInternal: React.FC<{
  text: string
  speakerId: number
  speakerChange?: boolean
  emotion?: GQLTTS_EMOTION
  emotionChange?: boolean
  dpeId?: GQLTTS_DPE_ID
  dpeIdChange?: boolean
  onUpdate: () => void
}> = (props) => {
  const {
    text,
    speakerId,
    speakerChange,
    emotion,
    emotionChange,
    dpeId,
    dpeIdChange,
    onUpdate,
  } = props
  const { getTtsUrl } = useStoryPlayAPIs()
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [audioUrl, setAudioUrl] = React.useState<string>('')
  const [playText, setPlayText] = React.useState<string>('')
  const getUrl = async () => {
    if (playText.length > 200) {
      return Utils.showError(
        'TTS 는 공백,특수 문자 포함 최대 200자 입력할 수 있습니다.'
      )
    }
    setIsSubmitting(true)
    try {
      const { url } = await getTtsUrl({
        speakerId,
        text: playText || text,
        emotion: emotion || GQLTTS_EMOTION.Default,
        dpeId: dpeId || GQLTTS_DPE_ID.Default_Default,
      })
      setAudioUrl(url)
      let audio = new Audio(url)
      audio.load()
      audio.play().catch((e) => console.log('Audio ERROR: ', e))
      onUpdate()
    } catch (e) {
      console.log('ERROR: ', e)
    } finally {
      setIsSubmitting(false)
    }
  }
  const start = () => {
    let audio = new Audio(audioUrl)
    audio.load()
    audio.play().catch((e) => console.log('Audio ERROR: ', e))
    onUpdate()
  }
  const setPlayTextOnChange = (e: any) => {
    setPlayText(e.target.value)
    setAudioUrl('')
  }
  const isNew = speakerChange || emotionChange || dpeIdChange

  return (
    <>
      <input
        type="text"
        key={speakerId}
        name={`${speakerId}`}
        value={playText}
        onChange={setPlayTextOnChange}
        placeholder={`${SAMPLE_TEXT} (최대 200자)`}
        maxLength={200}
      />
      &nbsp;&nbsp;
      {!isSubmitting && (!audioUrl || isNew) && (
        <Button onClick={getUrl}>듣기</Button>
      )}
      {!isSubmitting && audioUrl && !isNew && (
        <Button onClick={start}>듣기</Button>
      )}
    </>
  )
}
