"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockEditorInput = exports.BlockEditorButtonLayoutForImageModal = exports.BlockEditorButtonLayout = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const models_1 = require("../../../../models");
const helper_1 = require("../../helper");
const interface_1 = require("../../interface");
const statement_1 = require("../statement");
const DOSTFullScreenEffectOff_1 = require("../statement/script/DOSTFullScreenEffectOff");
const DOSTFullScreenEffectOn_1 = require("../statement/script/DOSTFullScreenEffectOn");
const studioTutorial_1 = require("../studioTutorial");
const BlockEditorInput_interface_1 = require("./BlockEditorInput.interface");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
var BlockEditorButtonLayout;
(function (BlockEditorButtonLayout) {
    BlockEditorButtonLayout["Normal"] = "Normal";
    BlockEditorButtonLayout["CharacterOpened"] = "CharacterOpened";
    BlockEditorButtonLayout["BackgroundImageOpened"] = "BackgroundImageOpened";
    BlockEditorButtonLayout["FullWidthImageOpened"] = "FullWidthImageOpened";
    BlockEditorButtonLayout["ChrImageOpened"] = "ChrImageOpened";
    BlockEditorButtonLayout["SFXOpened"] = "SFXOpened";
    BlockEditorButtonLayout["BGMOnOffOpened"] = "BGMOnOffOpened";
    BlockEditorButtonLayout["BGMOnOpened"] = "BGMOnOpened";
    BlockEditorButtonLayout["TemplateOpened"] = "TemplateOpened";
    BlockEditorButtonLayout["MoreOpened"] = "MoreOpened";
    BlockEditorButtonLayout["FullScreenEffectOnOffOpened"] = "FullScreenEffectOnOffOpened";
    BlockEditorButtonLayout["HbImageOpened"] = "HbImageOpened";
})(BlockEditorButtonLayout = exports.BlockEditorButtonLayout || (exports.BlockEditorButtonLayout = {}));
exports.BlockEditorButtonLayoutForImageModal = [
    BlockEditorButtonLayout.BackgroundImageOpened,
    BlockEditorButtonLayout.ChrImageOpened,
];
const ButtonsNotToShow = [BlockEditorInput_interface_1.BlockEditorInputButtonType.ChrImage];
/**
 * 하단의 input editor 의 상태를 modeling
 */
class BlockEditorInput {
    constructor(editor) {
        this.text = '';
        this.slateValue = '';
        this.width = 100;
        // selectedButton: BlockEditorInputButtonType = BlockEditorInputButtonType.Talk
        this.selectedButtonState = BlockEditorInput_interface_1.BlockEditorInputButtonType.Talk;
        this.layout = BlockEditorButtonLayout.Normal;
        this.recentUsedCharacter = [];
        this.editor = editor;
        this.selectedCharacter = editor.chapterEditing.characterList[0];
        this.helper = new helper_1.StudioStoreHelper(editor.chapterEditing.store.rootStore);
        (0, mobx_1.makeObservable)(this, {
            width: mobx_1.observable,
            text: mobx_1.observable,
            slateValue: mobx_1.observable,
            // selectedButton: observable,
            layout: mobx_1.observable,
            selectedCharacter: mobx_1.observable,
            selectedButtonState: mobx_1.observable,
            setText: mobx_1.action,
            setSlateValue: mobx_1.action,
            setSelectedCharacter: mobx_1.action,
            inputTextPlaceholder: mobx_1.computed,
            buttonsToShow: mobx_1.computed,
            buttons: mobx_1.computed,
            characterList: mobx_1.computed,
            imageList: mobx_1.computed,
            sfxList: mobx_1.computed,
        });
    }
    getRootStore() {
        return this.editor.chapterEditing.store.rootStore;
    }
    get inputTextPlaceholder() {
        switch (this.selectedButtonState) {
            case BlockEditorInput_interface_1.BlockEditorInputButtonType.Talk:
                return trans('legacy_BlockEditorInput.ts_enter_dialogue');
            case BlockEditorInput_interface_1.BlockEditorInputButtonType.Think:
                return trans('legacy_BlockEditorInput.ts_enter_inner_thoughts');
            case BlockEditorInput_interface_1.BlockEditorInputButtonType.Action:
                return trans('legacy_BlockEditorInput.ts_enter_description');
            // case BlockEditorInputButtonType.Image:
            //   return ''
            // case BlockEditorInputButtonType.MorePlus:
            //   break
        }
    }
    // 아이콘이 같이 존재하는 경우는 width 100
    // 텍스트가 5자 이상인 경우 92
    // 더보기 및 캐릭터인 경우 30
    // 그 외 54
    getButtonWidthOf(buttonType) {
        const hasTextWithIcon = buttonType === BlockEditorInput_interface_1.BlockEditorInputButtonType.Choice ||
            buttonType === BlockEditorInput_interface_1.BlockEditorInputButtonType.Condition ||
            buttonType === BlockEditorInput_interface_1.BlockEditorInputButtonType.GoToBlock ||
            buttonType === BlockEditorInput_interface_1.BlockEditorInputButtonType.CallChatBot ||
            buttonType === BlockEditorInput_interface_1.BlockEditorInputButtonType.FullScreenEffect;
        const name = this.editor.chapterEditing.store.rootStore.textStore.data
            .BlockEditorInputButtonType[buttonType];
        if (
        // HbButtonType[buttonType] ??
        BlockEditorInput_interface_1.HbUserButtonType[buttonType]
        // HbAlgorithmButtonType[buttonType]
        ) {
            return 65.75;
        }
        if (hasTextWithIcon) {
            return 100;
        }
        else if (name.length >= 5) {
            return 92;
        }
        else if (buttonType === BlockEditorInput_interface_1.BlockEditorInputButtonType.MorePlus ||
            buttonType === BlockEditorInput_interface_1.BlockEditorInputButtonType.Character) {
            return 30;
        }
        else {
            return 54;
        }
    }
    /**
     * more 를 포함하여 보여주어야 하는 모든 버튼들의 목록
     */
    get buttonsToShow() {
        const { parent } = this.editor.getParentEditableForLineAndIndex();
        const rootStore = this.getRootStore();
        if (rootStore.serviceType === 'hb') {
            if (this.selectedCharacter.id === -1) {
                return Object.values(BlockEditorInput_interface_1.BlockEditorInputButtonType).filter(item => !!BlockEditorInput_interface_1.HbUserButtonType[item]);
            }
            if (rootStore.isAlgorithmStore) {
                return Object.values(BlockEditorInput_interface_1.BlockEditorInputButtonType).filter(item => !!BlockEditorInput_interface_1.HbAlgorithmButtonType[item]);
            }
            return Object.values(BlockEditorInput_interface_1.BlockEditorInputButtonType).filter(item => !!BlockEditorInput_interface_1.HbButtonType[item]);
        }
        return (0, lodash_1.difference)(Object.values(BlockEditorInput_interface_1.SpButtonType), parent.inputButtonsToHide);
    }
    /**
     * 너비를 고려하여 보여주어야 하는 버튼들의 목록
     */
    get buttons() {
        const containerLPadding = 16;
        const containerRPadding = 12; // 실제 값은 16 이지만 spacing 하나를 빼야 하기 때문에 12로 설정
        const spacing = 4;
        // CharType 과 MorePlusType 은 항상 처음과 마지막에 보여지니 buttonsHaveWidth 에서 해당 타입들은 제외한다.
        const CharType = BlockEditorInput_interface_1.BlockEditorInputButtonType.Character;
        const MorePlusType = BlockEditorInput_interface_1.BlockEditorInputButtonType.MorePlus;
        const CharWidth = this.getButtonWidthOf(CharType) + spacing;
        const MorePlusWidth = this.getButtonWidthOf(MorePlusType) + spacing;
        // buttons 영역의 width
        // 몇 개의 button 을 보여줄지 알 수 없으니 spacing 은 buttonsHaveWidth 에서 width 에 spacing 을 더한다.
        const containerWidth = this.width -
            (containerLPadding + containerRPadding) -
            (CharWidth - MorePlusWidth);
        const excludedButtonTypes = [...ButtonsNotToShow, CharType, MorePlusType];
        if (!this.getRootStore().di.isFeatureEnabled(interface_1.FEATURE_FLAG.CHAT_BOT_BLOCK)) {
            excludedButtonTypes.push(BlockEditorInput_interface_1.BlockEditorInputButtonType.CallChatBot);
        }
        if (!this.getRootStore().di.isFeatureEnabled(interface_1.FEATURE_FLAG.DIRECTION)) {
            excludedButtonTypes.push(BlockEditorInput_interface_1.BlockEditorInputButtonType.FullScreenEffect);
            excludedButtonTypes.push(BlockEditorInput_interface_1.BlockEditorInputButtonType.Pause);
        }
        const buttonFilterFnc = (v) => {
            if (this.getRootStore().serviceType === 'hb') {
                return !excludedButtonTypes.includes(v);
            }
            return ![...excludedButtonTypes, ...Object.values(BlockEditorInput_interface_1.HbButtonType)].includes(v);
        };
        // width 를 포함한 버튼 객체 배열 생성
        const buttonsHaveWidth = this.buttonsToShow
            .filter(buttonFilterFnc)
            .map(buttonType => {
            return {
                buttonType,
                width: this.getButtonWidthOf(buttonType) + spacing,
            };
        });
        const buttonsForShow = []; // 보여줄 수 있는 버튼들
        let stackedWidth = 0; // 해당 값이 위의 containerWidth 보다 작을때만 버튼 추가되도록
        let hasMore = false;
        for (const button of buttonsHaveWidth) {
            stackedWidth += button.width;
            const isLessThan = Math.max(stackedWidth, containerWidth) <= containerWidth;
            if (isLessThan) {
                buttonsForShow.push(button.buttonType);
            }
            else {
                hasMore = true;
                break;
            }
        }
        buttonsForShow.unshift(CharType);
        if (hasMore) {
            buttonsForShow.push(MorePlusType);
        }
        // buttons by width
        return buttonsForShow;
    }
    /**
     * buttons 에서 생략된 버튼들을 보여준다.
     */
    get buttonsMore() {
        const excludedButtonTypes = [...ButtonsNotToShow, ...this.buttons];
        if (!this.getRootStore().di.isFeatureEnabled(interface_1.FEATURE_FLAG.CHAT_BOT_BLOCK)) {
            excludedButtonTypes.push(BlockEditorInput_interface_1.BlockEditorInputButtonType.CallChatBot);
        }
        if (!this.getRootStore().di.isFeatureEnabled(interface_1.FEATURE_FLAG.DIRECTION)) {
            excludedButtonTypes.push(BlockEditorInput_interface_1.BlockEditorInputButtonType.FullScreenEffect);
            excludedButtonTypes.push(BlockEditorInput_interface_1.BlockEditorInputButtonType.Pause);
        }
        if (this.getRootStore().serviceType === 'sp') {
            Object.values(BlockEditorInput_interface_1.HbButtonType)
                .filter(v => v !== BlockEditorInput_interface_1.BlockEditorInputButtonType.Pause)
                .forEach(t => excludedButtonTypes.push(t));
        }
        return this.buttonsToShow.filter(v => !excludedButtonTypes.includes(v));
    }
    getCharacterDisplay(statementType, currentChrName) {
        var _a;
        const savedSelected = localStorage.getItem(this.editor.chapterEditing.story.getTalkPreviewStorageKey(currentChrName));
        if (savedSelected) {
            const parsed = JSON.parse(savedSelected).chrDisplay;
            if (parsed === 'fit' || parsed === 'scaleLarge') {
                return parsed;
            }
        }
        const indexOfSelectedLine = this.editor.lines.indexOf(this.editor.selectedLine);
        if (statementType === models_1.STATEMENT_TYPE.MainCharacterTalk ||
            statementType === models_1.STATEMENT_TYPE.MainCharacterThink) {
            for (let index = indexOfSelectedLine; index >= 0; index--) {
                const line = this.editor.lines[index];
                if ((line instanceof statement_1.DOSTRightTalk ||
                    line instanceof statement_1.DOSTLeftTalk ||
                    line instanceof statement_1.DOSTRightThink ||
                    line instanceof statement_1.DOSTLeftThink) &&
                    ((_a = line.chr) === null || _a === void 0 ? void 0 : _a.isHero) !== true) {
                    return 'scaleLarge';
                }
            }
        }
        return 'fit';
    }
    getBackgroundChr(statementType, currentChrName) {
        var _a, _b, _c, _d;
        const savedSelected = localStorage.getItem(this.editor.chapterEditing.story.getTalkPreviewStorageKey(currentChrName));
        if (savedSelected) {
            const parsed = JSON.parse(savedSelected).backgroundChr;
            if (parsed) {
                return parsed;
            }
        }
        const indexOfSelectedLine = this.editor.lines.indexOf(this.editor.selectedLine);
        let isMainChr = false;
        if (statementType === models_1.STATEMENT_TYPE.MainCharacterTalk ||
            statementType === models_1.STATEMENT_TYPE.MainCharacterThink) {
            isMainChr = true;
        }
        if (isMainChr) {
            for (let index = indexOfSelectedLine; index >= 0; index--) {
                const line = this.editor.lines[index];
                if ((line instanceof statement_1.DOSTRightTalk ||
                    line instanceof statement_1.DOSTLeftTalk ||
                    line instanceof statement_1.DOSTRightThink ||
                    line instanceof statement_1.DOSTLeftThink) &&
                    ((_a = line.chr) === null || _a === void 0 ? void 0 : _a.isHero) !== true) {
                    return {
                        name: ((_b = line.chr) === null || _b === void 0 ? void 0 : _b.chrName) || ((_c = line.chr) === null || _c === void 0 ? void 0 : _c.name) || '',
                        isMainChr: ((_d = line.chr) === null || _d === void 0 ? void 0 : _d.isHero) || false,
                    };
                }
            }
        }
        return {
            name: currentChrName,
            isMainChr: isMainChr,
        };
    }
    setText(text) {
        this.text = text;
    }
    setSlateValue(value) {
        this.slateValue = value;
    }
    setWidth(width) {
        (0, mobx_1.runInAction)(() => {
            this.width = width;
        });
    }
    setSelectedCharacter(chr) {
        this.selectedCharacter = chr;
        this.layout = BlockEditorButtonLayout.Normal;
    }
    selectNextCharacter() {
        (0, mobx_1.runInAction)(() => {
            this.selectedCharacter = this.editor.chapterEditing
                .getStory()
                .getNextChrOf(this.selectedCharacter);
        });
    }
    selectPrevCharacter() {
        (0, mobx_1.runInAction)(() => {
            this.selectedCharacter = this.editor.chapterEditing
                .getStory()
                .getPrevChrOf(this.selectedCharacter);
        });
    }
    selectRecentCharacter() {
        (0, mobx_1.runInAction)(() => {
            const s = this.selectedCharacter;
            const r = this.recentUsedCharacter.find(c => c !== s);
            if (r) {
                this.selectedCharacter = r;
                this.recentUsedCharacter = this.recentUsedCharacter.filter(v => v !== r);
                this.recentUsedCharacter.unshift(r);
            }
        });
    }
    onCharacterProfileClicked(line, chr) {
        if (line === this.editor.selectedLine && chr) {
            this.setSelectedCharacter(chr);
        }
    }
    onClickButton(button) {
        const skipCursorCheck = [
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Character,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Template,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.MorePlus,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.GoToBlock,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Condition,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Choice,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Ending,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.FinalEnding,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.CallChatBot,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageNext,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageInput,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageTarot,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageTemplate,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageFeedback,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageSelect,
        ];
        if (!skipCursorCheck.includes(button) &&
            !this.editor.canAddToCurrentCursor) {
            // tslint:disable-next-line:no-console
            console.warn(`Cannot add to current line!!`);
            this.helper.store.di.showError(trans('legacy_BlockEditorInput.ts_cannot_add_here'));
            return;
        }
        (0, mobx_1.runInAction)(() => {
            var _a, _b, _c, _d, _e;
            // this.selectedButton = button
            switch (button) {
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Character: {
                    this.toggleLayout(BlockEditorButtonLayout.CharacterOpened);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Talk:
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Think:
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Action: {
                    this.layout = BlockEditorButtonLayout.Normal;
                    this.selectedButtonState = button;
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Toast: {
                    const line = new statement_1.DOSTToast(null, this.editor.lineBlockEditing.doBlock);
                    line.setDraft();
                    line.startEditing();
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.BackgroundImage:
                    this.toggleLayout(BlockEditorButtonLayout.BackgroundImageOpened);
                    break;
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.FullWidthImage: {
                    this.getRootStore()
                        .studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_FULL_WIDTH_IMAGE)
                        .then();
                    const l = this.editor.addLine(new statement_1.DOSTFullWidthImage({
                        sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                        statementType: models_1.STATEMENT_TYPE.FullWidthImage,
                        background: '',
                        width: 0,
                        height: 0,
                        link: '',
                    }, this.editor.lineBlockEditing.doBlock));
                    (_a = l === null || l === void 0 ? void 0 : l.startEditing) === null || _a === void 0 ? void 0 : _a.call(l);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.ChrImage:
                    this.toggleLayout(BlockEditorButtonLayout.ChrImageOpened);
                    break;
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.SFX:
                    this.toggleLayout(BlockEditorButtonLayout.SFXOpened);
                    break;
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.BGM:
                    this.toggleLayout(BlockEditorButtonLayout.BGMOnOffOpened);
                    break;
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Template:
                    this.toggleLayout(BlockEditorButtonLayout.TemplateOpened);
                    break;
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.MorePlus:
                    this.toggleLayout(BlockEditorButtonLayout.MoreOpened);
                    break;
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.SaveProp: {
                    const line = new statement_1.DOSTMetaSaveProp(null, this.editor.lineBlockEditing.doBlock);
                    line.setDraft();
                    line.startEditing();
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.FullWidthText: {
                    const line = new statement_1.DOSTFullWidthText({
                        sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                        statementType: models_1.STATEMENT_TYPE.FullWidthText,
                        background: '',
                        message: '',
                        effect: {},
                    }, this.editor.lineBlockEditing.doBlock);
                    (_c = (_b = this.editor.addLine(line)) === null || _b === void 0 ? void 0 : _b.startEditing) === null || _c === void 0 ? void 0 : _c.call(_b);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Vibration: {
                    this.getRootStore()
                        .studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.CREATE_VIBRATION)
                        .then();
                    this.editor.addLine(new statement_1.DOSTVibration({
                        sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                        statementType: models_1.STATEMENT_TYPE.Vibration,
                        background: '',
                        pattern: '',
                    }, this.editor.lineBlockEditing.doBlock));
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.GoToBlock: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.ToBlock, true);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageNext: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.HbMessageNext, true);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Condition: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.Condition);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Choice: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.Choice);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Ending: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.ChapterEnding);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.FinalEnding: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.FinalEnding);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.CallChatBot: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.CallRemoteScript);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.FullScreenEffect: {
                    this.toggleLayout(BlockEditorButtonLayout.FullScreenEffectOnOffOpened);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Pause: {
                    this.onPauseOnClicked();
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.AlgorithmBlock: {
                    const line = new statement_1.DOSTAlgorithm({
                        sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                        statementType: models_1.STATEMENT_TYPE.Algorithm,
                        blockName: '',
                        background: '',
                        algorithmData: {
                            botId: this.editor.chapterEditing.story.storyId,
                        },
                        algorithmGroupId: -1,
                        id: null,
                    }, this.editor.lineBlockEditing.doBlock);
                    (_e = (_d = this.editor.addLine(line)) === null || _d === void 0 ? void 0 : _d.startEditing) === null || _e === void 0 ? void 0 : _e.call(_d);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageSelect: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.HbMessageSelect, true);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageInput: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.HbMessageInput, true);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageTemplate: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.HbMessageTemplate, true);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageFeedback: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.HbMessageFeedback, true);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageTarot: {
                    this.editor.lineBlockEditing.upsertNewEndBlock(statement_1.EndBlockType.HbMessageTarot, true);
                    this.toggleLayout(BlockEditorButtonLayout.Normal);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageRest: {
                    const line = new statement_1.DOSTHbRest({
                        sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                        statementType: models_1.STATEMENT_TYPE.HbRest,
                        background: '',
                        data: {},
                        hbExtensionData: {},
                    }, this.editor.lineBlockEditing.doBlock);
                    this.editor.addLine(line);
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.HbMessageImage: {
                    this.toggleLayout(BlockEditorButtonLayout.HbImageOpened);
                    break;
                }
            }
        });
    }
    submitText() {
        const text = this.text;
        const slateValue = this.slateValue;
        if (this.editor.canAddToCurrentCursor) {
            this.setText('');
            this.setSlateValue('');
        }
        if (text.trim().length > 0) {
            if (this.selectedButtonState === BlockEditorInput_interface_1.BlockEditorInputButtonType.Talk ||
                this.selectedButtonState === BlockEditorInput_interface_1.BlockEditorInputButtonType.Think) {
                if (this.editor.canAddToCurrentCursor) {
                    this.editor.chapterEditing.store.rootStore.studioTutorialStore
                        .markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.INPUT_LINES)
                        .then();
                }
                (0, mobx_1.runInAction)(() => {
                    const s = this.selectedCharacter;
                    this.recentUsedCharacter = this.recentUsedCharacter.filter(v => v !== s);
                    this.recentUsedCharacter.unshift(s);
                });
            }
            if (this.getRootStore().serviceType === 'hb') {
                const line = new statement_1.DOSTHbText({
                    sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                    statementType: models_1.STATEMENT_TYPE.HbText,
                    background: '',
                    data: {},
                    hbExtensionData: {},
                }, this.editor.lineBlockEditing.doBlock);
                line.textInput.onChange(text);
                this.editor.addLine(line);
                return;
            }
            if (this.editor.canAddToCurrentCursor &&
                text.includes('{{속성:') &&
                text.includes('}}')) {
                this.editor.chapterEditing.store.rootStore.studioTutorialStore
                    .markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.USING_PROP_FIGURE_OF_SENTENCE_TEMPLATE)
                    .then();
            }
            switch (this.selectedButtonState) {
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Talk: {
                    const isHero = this.selectedCharacter.isHero;
                    if (isHero) {
                        this.editor.addLine(new statement_1.DOSTRightTalk({
                            statementType: models_1.STATEMENT_TYPE.MainCharacterTalk,
                            chrName: '{{성}}{{이름}}',
                            message: text,
                            background: '',
                            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                            effect: {},
                            slateData: slateValue,
                        }, this.editor.lineBlockEditing.doBlock));
                    }
                    else {
                        this.editor.addLine(new statement_1.DOSTLeftTalk({
                            statementType: models_1.STATEMENT_TYPE.CharacterTalk,
                            chrName: this.selectedCharacter.name,
                            message: text,
                            background: '',
                            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                            effect: {},
                            slateData: slateValue,
                        }, this.editor.lineBlockEditing.doBlock));
                    }
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Think: {
                    const isHero = this.selectedCharacter.isHero;
                    if (isHero) {
                        this.editor.addLine(new statement_1.DOSTRightThink({
                            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                            statementType: models_1.STATEMENT_TYPE.MainCharacterThink,
                            message: text,
                            chrName: '{{성}}{{이름}}',
                            background: '',
                            effect: {},
                            slateData: slateValue,
                        }, this.editor.lineBlockEditing.doBlock));
                    }
                    else {
                        this.editor.addLine(new statement_1.DOSTLeftThink({
                            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                            statementType: models_1.STATEMENT_TYPE.CharacterThink,
                            message: text,
                            chrName: this.selectedCharacter.name,
                            background: '',
                            effect: {},
                            slateData: slateValue,
                        }, this.editor.lineBlockEditing.doBlock));
                    }
                    break;
                }
                case BlockEditorInput_interface_1.BlockEditorInputButtonType.Action:
                    this.editor.addLine(new statement_1.DOSTScript({
                        sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                        statementType: models_1.STATEMENT_TYPE.Script,
                        message: text,
                        background: '',
                        effect: {},
                        slateData: slateValue,
                    }, this.editor.lineBlockEditing.doBlock));
                    break;
            }
            // this.editor.addLine()
        }
    }
    submitImage(image) {
        const layout = this.layout;
        const studioTutorialStore = this.editor.chapterEditing.story.rootStore.studioTutorialStore;
        (0, mobx_1.runInAction)(() => {
            switch (layout) {
                case BlockEditorButtonLayout.BackgroundImageOpened:
                    studioTutorialStore
                        .markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_BACKGROUND_IMAGE)
                        .then();
                    this.editor.addLine(new statement_1.DOSTBackground({
                        sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                        statementType: models_1.STATEMENT_TYPE.BackgroundImage,
                        image: image.resName,
                        background: '',
                    }, this.editor.lineBlockEditing.doBlock));
                    this.layout = BlockEditorButtonLayout.Normal;
                    break;
                case BlockEditorButtonLayout.ChrImageOpened: {
                    if (this.selectedCharacter.isHero) {
                        this.editor.addLine(new statement_1.DOSTMainCharacterMessageImage({
                            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                            statementType: models_1.STATEMENT_TYPE.MainCharacterMessageImage,
                            background: '',
                            chrName: this.selectedCharacter.name,
                            width: image.width,
                            height: image.height,
                            link: image.link,
                        }, this.editor.lineBlockEditing.doBlock));
                    }
                    else {
                        this.editor.addLine(new statement_1.DOSTMessageImage({
                            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                            statementType: models_1.STATEMENT_TYPE.MessageImage,
                            background: '',
                            chrName: this.selectedCharacter.name,
                            width: image.width,
                            height: image.height,
                            link: image.link,
                            studioFileId: image.fileId,
                        }, this.editor.lineBlockEditing.doBlock));
                    }
                    this.layout = BlockEditorButtonLayout.Normal;
                    break;
                }
            }
        });
    }
    submitSFX(file) {
        this.editor.chapterEditing.story.rootStore.studioTutorialStore
            .markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_SOUND_EFFECT)
            .then();
        (0, mobx_1.runInAction)(() => {
            this.editor.addLine(new statement_1.DOSTSoundEffect({
                sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                statementType: models_1.STATEMENT_TYPE.SoundEffect,
                background: '',
                link: file.link,
                fileId: file.fileId,
                name: file.fileName,
            }, this.editor.lineBlockEditing.doBlock));
            this.layout = BlockEditorButtonLayout.Normal;
        });
    }
    async submitHbImage(imageId) {
        const line = new statement_1.DOSTHbImage({
            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
            statementType: models_1.STATEMENT_TYPE.HbImage,
            background: '',
            data: {},
            hbExtensionData: {},
        }, this.editor.lineBlockEditing.doBlock);
        line.imageIdInput.onChange(imageId);
        this.editor.addLine(line);
        (0, mobx_1.runInAction)(() => {
            this.layout = BlockEditorButtonLayout.Normal;
        });
    }
    closeMore() {
        (0, mobx_1.runInAction)(() => {
            // 현재 more 버튼 이외에도 오픈된 팝업 다이얼로그를 닫을 때도 사용하고 있음
            this.layout = BlockEditorButtonLayout.Normal;
        });
    }
    get characterList() {
        return this.editor.chapterEditing.characterList;
    }
    get imageList() {
        return this.editor.chapterEditing.imageList;
    }
    get sfxList() {
        return this.editor.chapterEditing.sfxList;
    }
    onBGMOnClicked() {
        (0, mobx_1.runInAction)(() => {
            this.layout = BlockEditorButtonLayout.BGMOnOpened;
        });
    }
    onBGMOffClicked() {
        (0, mobx_1.runInAction)(() => {
            this.editor.addLine(new statement_1.DOSTBGMoff({
                sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                statementType: models_1.STATEMENT_TYPE.BGMoff,
                background: '',
            }, this.editor.lineBlockEditing.doBlock));
            this.layout = BlockEditorButtonLayout.Normal;
        });
    }
    onPauseOnClicked() {
        var _a, _b;
        if (!this.editor.canAddToCurrentCursor) {
            // tslint:disable-next-line:no-console
            console.warn(`Cannot add to current line!!`);
            this.helper.store.di.showError(trans('legacy_BlockEditorInput.ts_cannot_add_here'));
            return;
        }
        const line = new statement_1.DOSTPause({
            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
            background: '',
            statementType: models_1.STATEMENT_TYPE.Pause,
            timeout: this.getRootStore().serviceType === 'sp' ? 10000 : 0,
        }, this.editor.lineBlockEditing.doBlock);
        (_b = (_a = this.editor.addLine(line)) === null || _a === void 0 ? void 0 : _a.startEditing) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    onFullScreenEffectOnClicked() {
        var _a, _b;
        if (!this.editor.canAddToCurrentCursor) {
            // tslint:disable-next-line:no-console
            console.warn(`Cannot add to current line!!`);
            this.helper.store.di.showError(trans('legacy_BlockEditorInput.ts_cannot_add_here'));
            return;
        }
        const line = new DOSTFullScreenEffectOn_1.DOSTFullScreenEffectOn({
            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
            background: '',
            statementType: models_1.STATEMENT_TYPE.FullScreenEffectOn,
            fileType: core_1.FullScreenEffectFileType.Image,
            fileLink: '',
            width: 0,
            height: 0,
            transitionStyle: core_1.TransitionStyle.None,
            fillMethod: core_1.ScreenFillMethod.AspectRatio,
            duration: 300,
        }, this.editor.lineBlockEditing.doBlock);
        (_b = (_a = this.editor.addLine(line)) === null || _a === void 0 ? void 0 : _a.startEditing) === null || _b === void 0 ? void 0 : _b.call(_a);
        (0, mobx_1.runInAction)(() => {
            this.layout = BlockEditorButtonLayout.Normal;
        });
    }
    onFullScreenEffectOffClicked() {
        var _a, _b;
        if (!this.editor.canAddToCurrentCursor) {
            // tslint:disable-next-line:no-console
            console.warn(`Cannot add to current line!!`);
            this.helper.store.di.showError(trans('legacy_BlockEditorInput.ts_cannot_add_here'));
            return;
        }
        const line = new DOSTFullScreenEffectOff_1.DOSTFullScreenEffectOff({
            sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
            background: '',
            statementType: models_1.STATEMENT_TYPE.FullScreenEffectOff,
            transitionStyle: core_1.TransitionStyle.None,
            duration: 300,
        }, this.editor.lineBlockEditing.doBlock);
        (_b = (_a = this.editor.addLine(line)) === null || _a === void 0 ? void 0 : _a.startEditing) === null || _b === void 0 ? void 0 : _b.call(_a);
        (0, mobx_1.runInAction)(() => {
            this.layout = BlockEditorButtonLayout.Normal;
        });
    }
    submitBGM(file) {
        (0, mobx_1.runInAction)(() => {
            this.getRootStore()
                .studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_BGM)
                .then();
            this.editor.addLine(new statement_1.DOSTBGMon({
                sourceLine: this.editor.chapterEditing.store.rootStore.di.generateSourceLine(),
                statementType: models_1.STATEMENT_TYPE.BGMon,
                background: '',
                link: file.link,
            }, this.editor.lineBlockEditing.doBlock));
            this.layout = BlockEditorButtonLayout.Normal;
        });
    }
    /**
     * 선택된 형태의 버튼의 선택을 변경한다.
     */
    onMoveSelectedButton(offset) {
        const selectables = [
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Talk,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Think,
            BlockEditorInput_interface_1.BlockEditorInputButtonType.Action,
        ];
        (0, mobx_1.runInAction)(() => {
            const index = (0, lodash_1.clamp)(selectables.indexOf(this.selectedButtonState) + offset, 0, selectables.length - 1);
            this.selectedButtonState = selectables[index];
        });
    }
    toggleLayout(layoutTo) {
        (0, mobx_1.runInAction)(() => {
            this.layout =
                this.layout === layoutTo ? BlockEditorButtonLayout.Normal : layoutTo;
        });
    }
}
exports.BlockEditorInput = BlockEditorInput;
