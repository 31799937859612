import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLEndingShowsProperty } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IEndingShowsPropertyListableOption } from '../EndingShowsPropertyCommon'

export function useEndingShowsPropertyListDataLoader(
  storyId: number | null,
  endingId: number | null
) {
  const { listEndingShowsPropertyOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IEndingShowsPropertyListableOption
    ): Promise<ODListableResponseType<GQLEndingShowsProperty>> {
      const r = await listEndingShowsPropertyOfStory({
        page,
        pageSize: 100,
        ...options,
        storyId,
        endingId,
      })
      return r as ODListableResponseType<GQLEndingShowsProperty>
    },
    [listEndingShowsPropertyOfStory, endingId, storyId]
  )
}
