import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLUserReportChapterComment } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IChapterCommentListableOption } from '../ChapterCommentCommon'

type OptionalId = number | null

export function useChapterCommentReportListDataLoader(commentId: OptionalId) {
  const { listUserReportChapterComment } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IChapterCommentListableOption
    ): Promise<ODListableResponseType<GQLUserReportChapterComment>> {
      const r = await listUserReportChapterComment({
        page,
        pageSize: 10,
        commentId,
        ...options,
      })
      return r as ODListableResponseType<GQLUserReportChapterComment>
    },
    [listUserReportChapterComment, commentId]
  )
}
