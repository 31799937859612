import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { DirectAdsEditPage } from './DirectAdsEditPage'

interface IDirectAdsEditPageContainerProps {
  directAdsId: number
}

export const DirectAdsEditPageContainer: React.FC<
  IDirectAdsEditPageContainerProps
> = (props) => {
  const { directAdsId } = props
  return (
    <>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.DirectAds.List} />
          <hr />
          <DirectAdsEditPage directAdsId={directAdsId} />
        </CardBody>
      </Card>
    </>
  )
}
