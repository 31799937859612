import { ODListableOption, ODListableResponseType } from '@odc/od-react-belt'
import { GQLStorygameCharacterPrompt } from '@storyplay/common'
import React from 'react'
import { useStoryPlayAPIs } from '../../../../hooks/useStoryPlayAPIs'

export function useStorygameCharacterPromptListDataLoader(storyId: number) {
  const { listStorygameCharacterPrompt } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: { storyId: number } & ODListableOption
    ): Promise<ODListableResponseType<GQLStorygameCharacterPrompt>> {
      const r = await listStorygameCharacterPrompt({
        page,
        pageSize: 10,
        storyId,
        ...options,
      })
      return r as ODListableResponseType<GQLStorygameCharacterPrompt>
    },
    [listStorygameCharacterPrompt, storyId]
  )
}
