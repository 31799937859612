"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStudioFileStore = void 0;
const mobx_1 = require("mobx");
const interface_1 = require("../../interface");
const DOStudioFile_1 = require("./DOStudioFile");
class DOStudioFileStore {
    constructor(story) {
        this.selectedFile = null; // ODSSoundLayout, ODSImageModal 에서 선택한 파일
        this.isUploadingFile = {};
        this.isUpdatingFile = false;
        this.isRemovingFile = false;
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            selectedFile: mobx_1.observable,
            isUploadingFile: mobx_1.observable,
            isUpdatingFile: mobx_1.observable,
            isRemovingFile: mobx_1.observable,
            all: mobx_1.computed,
        });
    }
    setSelectedFile(file) {
        (0, mobx_1.runInAction)(() => (this.selectedFile = file));
    }
    getById(id) {
        return this.data[id];
    }
    /**
     * 해당 리소스의 이름으로 파일을 찾는다.
     */
    getByResName(name) {
        return Object.values(this.data).find(v => v.resName.normalize() === name.normalize());
    }
    merge(data) {
        const id = data.fileId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOStudioFile_1.DOStudioFile(this, data);
        }
        return this.data[id];
    }
    /**
     * StoryHasStudioFile 을 새롭게 업로드한다.
     */
    async uploadStudioFile(file, fileType, resName) {
        const rootStore = this.rootStore;
        const storyId = this.story.storyId;
        const server = rootStore.di.server;
        try {
            const r = await server.createStoryHasStudioFile({
                file,
                fileType: fileType,
                storyId,
                resName,
            });
            return this.story.studioFileStore.merge(r);
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.error(280, ex);
            rootStore.channel.emit(interface_1.ChannelMessage.ErrorToast, ex);
            throw ex;
        }
    }
    async updateStudioFile(id, displayName) {
        if (this.isUpdatingFile) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isUpdatingFile = true;
        });
        try {
            const rootStore = this.rootStore;
            const server = rootStore.di.server;
            const r = await server.updateStoryHasStudioFile({
                id,
                displayName,
            });
            return this.story.studioFileStore.merge(r);
        }
        catch (ex) {
            this.story.rootStore.di.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isUpdatingFile = false;
            });
        }
    }
    async removeStudioFile(id) {
        if (this.isRemovingFile) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isRemovingFile = true;
        });
        try {
            const rootStore = this.rootStore;
            const server = rootStore.di.server;
            const res = await server.removeStoryHasStudioFile({ id });
            if (res) {
                delete this.data[id];
            }
        }
        catch (ex) {
            this.story.rootStore.di.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isRemovingFile = false;
            });
        }
    }
    // 메서드가 실행되고 있을 때 같은 파일에 대해서 중복적인 호출이 들어오는 것을 막기 위함
    uploadFileToPreventMultipleCall(file, fileType, resName) {
        if (this.isUploadingFile[resName]) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isUploadingFile[resName] = true;
        });
        this.uploadStudioFile(file, fileType, resName)
            .then(r => {
            // 파일 업로드 후 해당 파일이 ODSSoundLayout, ODSImageModal 에서 선택되도록 합니다.
            // 파일을 벌크로 업로드시에는 마지막에 업로드된 파일이 선택되도록 합니다.
            if (Object.keys(this.isUploadingFile).length === 1) {
                this.setSelectedFile(r);
            }
        })
            .catch(ex => {
            this.story.rootStore.di.showError(ex);
        })
            .finally(() => {
            (0, mobx_1.runInAction)(() => {
                delete this.isUploadingFile[resName];
            });
        });
    }
    async savePublicResourceToStudioFile(publicResourceFileId) {
        const rootStore = this.rootStore;
        const server = rootStore.di.server;
        try {
            const res = await server.savePublicResourceToStudioFile({
                storyId: this.story.storyId,
                publicResourceFileId,
            });
            if (!!res) {
                return this.merge(res);
            }
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    get all() {
        return Object.values(this.data).sort((a, b) => a.fileId < b.fileId ? 1 : -1);
    }
}
exports.DOStudioFileStore = DOStudioFileStore;
