"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileTheme = void 0;
/** FileTheme 이 뒤늦게 추가되었기 때문에 ODSFileTheme 과 같이 동기화시켜주어야 합니다. */
var FileTheme;
(function (FileTheme) {
    FileTheme["EndingImage"] = "EndingImage";
    FileTheme["PropItemImage"] = "PropItemImage";
    FileTheme["CharImage"] = "CharImage";
    FileTheme["UploadVideo"] = "UploadVideo";
    FileTheme["WithCoverImage"] = "WithCoverImage";
    FileTheme["WithCropImage"] = "WithCropImage";
    FileTheme["StoryCoverImage"] = "StoryCoverImage";
    FileTheme["StoryGameCoverImage"] = "StoryGameCoverImage";
    FileTheme["StoryGameUploadItem"] = "StoryGameUploadItem";
    FileTheme["StoryGamePreviewImage"] = "StoryGamePreviewImage";
    FileTheme["StoryGamePreviewVideo"] = "StoryGamePreviewVideo";
    FileTheme["ChoiceImage"] = "ChoiceImage";
    FileTheme["OgImage"] = "OgImage";
    FileTheme["OnlySupportedCommonImageModal"] = "OnlySupportedCommonImageModal";
    FileTheme["HbSkillIcon"] = "HbSkillIcon";
    FileTheme["HbProfileImage"] = "HbProfileImage";
    FileTheme["HbCoverImage"] = "HbCoverImage";
    FileTheme["HbPreviewImage"] = "HbPreviewImage";
    FileTheme["BackgroundChrImage"] = "BackgroundChrImage";
    FileTheme["ThumbnailCharImage"] = "ThumbnailCharImage";
})(FileTheme = exports.FileTheme || (exports.FileTheme = {}));
