import React from 'react'
import { IODRendererContext, IODRendererDef, IODRendererProps, ODRendererComponentType, ODRendererDef } from './common'
import { ODBooleanRenderer } from './ODBooleanRenderer'
import { ODColorRenderer } from './ODColorRenderer'
import { ODImageFileRenderer } from './ODImageFileRenderer'
import { ODStringRenderer } from './ODStringRenderer'

interface ODRendererFactoryProps<ParentContextType, OriginalValue>
  extends IODRendererProps<any, IODRendererDef, ParentContextType, OriginalValue> {
  def: ODRendererDef
  value: any
  context: IODRendererContext<ParentContextType>
  originalValue: OriginalValue
}

export const ODRendererFactory: React.FC<ODRendererFactoryProps<any, any>> = (_props) => {
  const { def, ...props } = _props
  switch (def.component) {
    case ODRendererComponentType.BooleanRenderer:
      return <ODBooleanRenderer def={def} {...props} />
    case ODRendererComponentType.ImageFileRenderer:
      return <ODImageFileRenderer def={def} {...props} />
    case ODRendererComponentType.StringRenderer:
      return <ODStringRenderer def={def} {...props} />
    case ODRendererComponentType.ColorRenderer:
      return <ODColorRenderer def={def} {...props} />
    default:
      return null
  }
}
