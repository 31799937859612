"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOHbConditionItem = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const fields_1 = require("../../../../fields");
const SelectionFieldWithUI_1 = require("../../../../fields/SelectionFieldWithUI");
// 헬봇에서만 존재하며, 기존처럼 STATEMENT data 를 내려받지 않고, 헬봇 데이터만 내려 받습니다.
class DOHbConditionItem {
    constructor(data, parent) {
        var _a, _b;
        this.uniqueId =
            (_b = (_a = data.id) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : parent.parent.parent.rootStore.di.generateSourceLine().toString();
        this.data = data;
        this.parent = parent;
        this.propSelectionField = new SelectionFieldWithUI_1.SelectionFieldWithUI('propSelection', () => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.inputs) === null || _a === void 0 ? void 0 : _a[0].id; }, () => null, {
            selection: this.parent.parent.parent.block.parentChapter
                .getStory()
                .storyPropertyStore.all.map(item => ({
                name: item.name,
                value: item.propId,
                description: item.propType, // description 을 통해 string, number 타입을 판단
            })),
            options: {
                noLabel: true,
                placeholder: '속성 검색',
                onChangeBeforeSubmit: v => {
                    this.operatorSelectionField.input.onChangeOptions(this.getOperatorSelectionOptionsBy(v));
                },
            },
        });
        this.operatorSelectionField = new SelectionFieldWithUI_1.SelectionFieldWithUI('operatorSelection', () => data.operator, () => null, {
            selection: this.getOperatorSelectionOptionsBy(this.propSelectionField.value),
            options: {
                noLabel: true,
                placeholder: '연산자',
                onChangeBeforeSubmit: v => {
                    if (this.getIsExistOrNotIsExist(v)) {
                        this.compareValueField.input.setEditable(false);
                        this.compareValueField.input.onChange('');
                    }
                    else {
                        this.compareValueField.input.setEditable(true);
                    }
                },
            },
        });
        this.compareValueField = new fields_1.TextFieldWithUI('compareValue', () => { var _a; return (_a = data.compareValue) !== null && _a !== void 0 ? _a : ''; }, () => null, {
            placeholder: '값',
            options: {
                noLabel: true,
                editable: !this.getIsExistOrNotIsExist(data.operator),
            },
        });
        (0, mobx_1.makeObservable)(this, {
            isValidCompareValue: mobx_1.computed,
            isValidOperator: mobx_1.computed,
            isValidPropId: mobx_1.computed,
            isValidAll: mobx_1.computed,
        });
    }
    getOperatorSelectionOptionsBy(propId) {
        var _a;
        return ((_a = this.propSelectionField.input.options.find(option => option.value === propId)) === null || _a === void 0 ? void 0 : _a.description) === core_1.GQLSTORY_PROP_TYPE.NUMBER
            ? core_1.HbConditionOperatorsByGeneralAttribute.map(item => ({
                name: core_1.HbConditionOperatorToKoString[item],
                value: item,
            }))
            : core_1.HbConditionOperatorsByNumberAttribute.map(item => ({
                name: core_1.HbConditionOperatorToKoString[item],
                value: item,
            }));
    }
    get isValidCompareValue() {
        if (this.getIsExistOrNotIsExist(this.operatorSelectionField.value)) {
            return true;
        }
        const options = this.propSelectionField.input.options;
        const findProp = options.find(option => option.value === this.propSelectionField.value);
        if ((findProp === null || findProp === void 0 ? void 0 : findProp.description) === core_1.GQLSTORY_PROP_TYPE.STRING) {
            return !!this.compareValueField.value;
        }
        if ((findProp === null || findProp === void 0 ? void 0 : findProp.description) === core_1.GQLSTORY_PROP_TYPE.NUMBER) {
            return !isNaN(Number(this.compareValueField.value));
        }
        return false;
    }
    get isValidOperator() {
        return !!this.operatorSelectionField.value;
    }
    get isValidPropId() {
        return !!this.propSelectionField.value;
    }
    get isValidAll() {
        return [
            this.isValidCompareValue,
            this.isValidOperator,
            this.isValidPropId,
        ].every(v => v);
    }
    getIsExistOrNotIsExist(operator) {
        return operator === 'exists' || operator === 'notexists';
    }
    // 서버에 보낼 데이터 가공
    getDataForSubmit() {
        return {
            kind: 'attribute',
            // Todo: 실제 api 연동시 undefined 인 상태로 보냈을 때 성공한다면 괜찮지만, 아니라면 헬봇 스튜디오와 같이 null 로 보내주어야 함
            // Todo: 현재는 타입 오류 나서 일단 undefined 로
            compareValue: this.operatorSelectionField.value === 'exists' ||
                this.operatorSelectionField.value === 'notexists'
                ? undefined
                : this.compareValueField.value.toString(),
            inputIds: [this.propSelectionField.value],
            operator: this.operatorSelectionField.value,
        };
    }
    getFullData() {
        return {
            compareValue: this.compareValueField.value,
            inputs: !!this.propSelectionField.value
                ? [{ id: this.propSelectionField.value, title: '', type: 'general' }]
                : [],
            operator: this.operatorSelectionField.value,
        };
    }
}
exports.DOHbConditionItem = DOHbConditionItem;
