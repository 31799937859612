import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLPreviewEnding } from '../../../@types/server'
import { ENDING_RATE_RANGE_LEVEL } from '../../../common'
import { HoverTR } from '../../../components/commonStyle'
import { COLORS } from '../../../styles/colors'
import { SiteUrls } from '../../../urls'
import {
  IPreviewEndingListableOption,
  PreviewEndingPrimaryKey,
} from '../PreviewEndingCommon'
import { usePreviewEndingListDataLoader } from './PreviewEndingListDataLoader'

interface IPreviewEndingListableTableProps {
  storyId: number
}

export const PreviewEndingListableTable: React.FC<IPreviewEndingListableTableProps> = (
  props
) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<
    GQLPreviewEnding,
    IPreviewEndingListableOption
  >()
  const [token] = useCounter()
  const dataLoader = usePreviewEndingListDataLoader(storyId)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[PreviewEndingPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="이름으로 검색"
              style={searchBoxStyle}
            />
            <Link
              to={SiteUrls.Admin.Story.EditPreviewEnding(storyId, 0)}
              style={{ textDecoration: 'none' }}
            >
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{ minWidth: 135, borderRadius: 5 }}
              >
                생성
              </ODButton>
            </Link>
          </div>
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>프리뷰엔딩 아이디</th>
                <th>노출 우선순위</th>
                <th>프리뷰엔딩 이름</th>
                <th>엔딩 도착률 범위</th>
                <th>이미지파일</th>
                <th>숨김?</th>
              </tr>
            )}
            renderRow={(value: GQLPreviewEnding) => (
              <HoverTR
                key={value.previewEndingId}
                onClick={() =>
                  setRedirect(
                    SiteUrls.Admin.Story.EditPreviewEnding(
                      storyId,
                      value.previewEndingId
                    )
                  )
                }
              >
                <td>{value.previewEndingId}</td>
                <td>{value.showOrder}</td>
                <td>{value.name}</td>
                <td>
                  {value.rateLevel
                    ? {
                        [ENDING_RATE_RANGE_LEVEL.Normal]: '보통',
                        [ENDING_RATE_RANGE_LEVEL.Rarity]: '희소',
                        [ENDING_RATE_RANGE_LEVEL.VeryRarity]: '매우 희소',
                        [ENDING_RATE_RANGE_LEVEL.Minority]: '최고 희소',
                        [ENDING_RATE_RANGE_LEVEL.Unknown]: '알 수 없음',
                      }[value.rateLevel]
                    : ''}
                </td>
                <td>
                  {value.imageFile?.link ? (
                    <img
                      src={value.imageFile!.link}
                      width={150}
                      height={150}
                      alt="ending"
                    />
                  ) : (
                    <span>미등록</span>
                  )}
                </td>
                <td>
                  {value.isHidden ? (
                    <span style={{ fontWeight: 'bold', color: COLORS.Primary }}>
                      Y
                    </span>
                  ) : (
                    <span style={{ color: COLORS.GRAY600 }}>N</span>
                  )}
                </td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
