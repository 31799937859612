"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChapterStatsCalculator = void 0;
const statsUtils_1 = require("../../utils/statsUtils");
const statement_1 = require("../statement");
class ChapterStatsCalculator {
    constructor(chapter) {
        this.chapter = chapter;
    }
    calculateStats() {
        const numChars = this.calculateNumChars();
        const numSens = this.calculateNumSens();
        // 챕터의 문장 평균 글자 수를 계산한다.
        // 챕터의 글자 수를 챕터의 문장 수로 나눈다.
        const numAverageCharsInSentence = Math.round(numChars / numSens);
        const stats = {
            numAverageCharsInSentence,
            numRoutes: this.calculateNumRoutes(),
            numChoices: this.calculateNumChoices(),
            numImages: this.calculateNumImages(),
            numSoundEffects: this.calculateNumSoundEffects(),
        };
        return {
            numBlocks: this.calculateNumBlocks(),
            numChars,
            numSens,
            stats,
        };
    }
    // 챕터의 블록 수를 계산한다.
    calculateNumBlocks() {
        return this.chapter.blockStore.all.length;
    }
    // 챕터의 문장 수를 계산한다.
    calculateNumSens() {
        return this.chapter.blockStore.all.reduce((pv, cv) => {
            const numSens = cv.statements.filter(v => v instanceof statement_1.DOBaseScriptStatement ||
                v instanceof statement_1.DOBaseScriptSubBlock).length;
            return pv + numSens;
        }, 0);
    }
    // 챕터의 글자 수를 계산한다.
    calculateNumChars() {
        return this.chapter.blockStore.all.reduce((previousValue, currentBlock) => {
            const numChars = currentBlock.statements.reduce((pv, cs) => {
                return pv + (0, statsUtils_1.calculateNumCharsOf)(cs);
            }, 0);
            return previousValue + numChars;
        }, 0);
    }
    // 챕터의 분기 수를 계산한다.
    calculateNumRoutes() {
        return this.chapter.blockStore.all.reduce((pv, cv) => {
            const numRoutes = !!cv.lastStatementInBlock
                ? (0, statsUtils_1.calculateNumRoutesOf)(cv.lastStatementInBlock)
                : 0;
            return pv + numRoutes;
        }, 0);
    }
    // 챕터의 선택지 수를 계산한다.
    calculateNumChoices() {
        return this.chapter.blockStore.all.reduce((pv, cv) => {
            const numChoices = cv.lastStatementInBlock instanceof statement_1.DOSTChoiceSubBlock ? 1 : 0;
            return pv + numChoices;
        }, 0);
    }
    // 챕터의 이미지 수를 계산한다.
    calculateNumImages() {
        return this.chapter.blockStore.all.reduce((pv, cv) => {
            const numImages = cv.statements.filter(v => v instanceof statement_1.DOSTFullWidthImage || v instanceof statement_1.DOSTMessageImage).length;
            return pv + numImages;
        }, 0);
    }
    // 챕터의 사운드 이펙트의 수를 계산한다.
    calculateNumSoundEffects() {
        return this.chapter.blockStore.all.reduce((pv, cv) => {
            const numSoundEffects = cv.statements.filter(v => v instanceof statement_1.DOSTSoundEffect).length;
            return pv + numSoundEffects;
        }, 0);
    }
}
exports.ChapterStatsCalculator = ChapterStatsCalculator;
