"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HLB_ENTITY_TYPE = void 0;
/**
 * 헬로우봇 챗봇에 사용되는 객체 종류 (mongodb, postgres)
 */
var HLB_ENTITY_TYPE;
(function (HLB_ENTITY_TYPE) {
    ////
    // mongodb
    ////
    HLB_ENTITY_TYPE["ChatBot"] = "ChatBot";
    HLB_ENTITY_TYPE["Sender"] = "Sender";
    HLB_ENTITY_TYPE["Operation"] = "Operation";
    HLB_ENTITY_TYPE["Rule"] = "Rule";
    HLB_ENTITY_TYPE["RuleInput"] = "RuleInput";
    HLB_ENTITY_TYPE["RuleOutput"] = "RuleOutput";
    HLB_ENTITY_TYPE["RuleLink"] = "RuleLink";
    HLB_ENTITY_TYPE["Category"] = "Category";
    HLB_ENTITY_TYPE["Menu"] = "Menu";
    HLB_ENTITY_TYPE["PremiumSubSkill"] = "PremiumSubSkill";
    HLB_ENTITY_TYPE["Attribute"] = "Attribute";
    HLB_ENTITY_TYPE["ReportGroup"] = "ReportGroup";
    HLB_ENTITY_TYPE["Report"] = "Report";
    HLB_ENTITY_TYPE["ReportProduct"] = "ReportProduct";
    HLB_ENTITY_TYPE["BlockGroup"] = "BlockGroup";
    HLB_ENTITY_TYPE["Block"] = "Block";
    HLB_ENTITY_TYPE["Message"] = "Message";
    HLB_ENTITY_TYPE["Sheet"] = "Sheet";
    HLB_ENTITY_TYPE["UserMessage"] = "UserMessage";
    HLB_ENTITY_TYPE["ResultImage"] = "ResultImage";
    HLB_ENTITY_TYPE["QuickMatchingData"] = "QuickMatchingData";
    HLB_ENTITY_TYPE["MatchingAttribute"] = "MatchingAttribute";
    // 스튜디오에서 /message/chatbot 경로 entity
    HLB_ENTITY_TYPE["AdjustmentData"] = "AdjustmentData";
    HLB_ENTITY_TYPE["AdvertisementData"] = "AdvertisementData";
    HLB_ENTITY_TYPE["AlgorithmData"] = "AlgorithmData";
    HLB_ENTITY_TYPE["AudioData"] = "AudioData";
    HLB_ENTITY_TYPE["CarouselData"] = "CarouselData";
    HLB_ENTITY_TYPE["Slide"] = "Slide";
    HLB_ENTITY_TYPE["ChargeData"] = "ChargeData";
    HLB_ENTITY_TYPE["Answer"] = "Answer";
    HLB_ENTITY_TYPE["CheckData"] = "CheckData";
    HLB_ENTITY_TYPE["ImageData"] = "ImageData";
    HLB_ENTITY_TYPE["JsonApiData"] = "JsonApiData";
    HLB_ENTITY_TYPE["LimitData"] = "LimitData";
    HLB_ENTITY_TYPE["MatchingData"] = "MatchingData";
    HLB_ENTITY_TYPE["MatchingOption"] = "MatchingOption";
    HLB_ENTITY_TYPE["NextData"] = "NextData";
    HLB_ENTITY_TYPE["ReportData"] = "ReportData";
    HLB_ENTITY_TYPE["RestButton"] = "RestButton";
    HLB_ENTITY_TYPE["RestData"] = "RestData";
    HLB_ENTITY_TYPE["ResultImageData"] = "ResultImageData";
    HLB_ENTITY_TYPE["TextData"] = "TextData";
    HLB_ENTITY_TYPE["TypingData"] = "TypingData";
    // 스튜디오에서 /message/user 경로 entity
    HLB_ENTITY_TYPE["AnalysisData"] = "AnalysisData";
    HLB_ENTITY_TYPE["FeedbackData"] = "FeedbackData";
    HLB_ENTITY_TYPE["ImageUploadData"] = "ImageUploadData";
    HLB_ENTITY_TYPE["InputData"] = "InputData";
    HLB_ENTITY_TYPE["PickerData"] = "PickerData";
    HLB_ENTITY_TYPE["SelectData"] = "SelectData";
    HLB_ENTITY_TYPE["SelectButton"] = "SelectButton";
    HLB_ENTITY_TYPE["TarotData"] = "TarotData";
    HLB_ENTITY_TYPE["TemplateData"] = "TemplateData";
    // 스튜디오에서 /message/common 경로 entity
    HLB_ENTITY_TYPE["LinkBlock"] = "LinkBlock";
    HLB_ENTITY_TYPE["LinkOption"] = "LinkOption";
    HLB_ENTITY_TYPE["LinkAttribute"] = "LinkAttribute";
    // 알고리즘 블럭 관련
    HLB_ENTITY_TYPE["AlgorithmGroup"] = "AlgorithmGroup";
    HLB_ENTITY_TYPE["Algorithm"] = "Algorithm";
    HLB_ENTITY_TYPE["AlgorithmCard"] = "AlgorithmCard";
    HLB_ENTITY_TYPE["AdjustmentCard"] = "AdjustmentCard";
    HLB_ENTITY_TYPE["AudioCard"] = "AudioCard";
    HLB_ENTITY_TYPE["ImageCard"] = "ImageCard";
    HLB_ENTITY_TYPE["PartialCardLink"] = "PartialCardLink";
    HLB_ENTITY_TYPE["PartingCharge"] = "PartingCharge";
    HLB_ENTITY_TYPE["PartialChargeCard"] = "PartialChargeCard";
    HLB_ENTITY_TYPE["RestCard"] = "RestCard";
    HLB_ENTITY_TYPE["RestCardButton"] = "RestCardButton";
    HLB_ENTITY_TYPE["ResultImageCard"] = "ResultImageCard";
    HLB_ENTITY_TYPE["TextCard"] = "TextCard";
    HLB_ENTITY_TYPE["TypingCard"] = "TypingCard";
    ////
    // postgres
    ///
    HLB_ENTITY_TYPE["PGChatBot"] = "PGChatBot";
    HLB_ENTITY_TYPE["MenuLinkTag"] = "MenuLinkTag";
    HLB_ENTITY_TYPE["PGAlgorithmGroup"] = "PGAlgorithmGroup";
})(HLB_ENTITY_TYPE = exports.HLB_ENTITY_TYPE || (exports.HLB_ENTITY_TYPE = {}));
