"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbDoBlockHelper = void 0;
const core_1 = require("@storyplay/core");
const hellobot_storyplay_converter_1 = require("../../../../../hellobot-storyplay-converter");
class HbDoBlockHelper {
    async addStatementAtIndexInternal(target, line, indexToAdd) {
        if ([
            core_1.STATEMENT_TYPE.Algorithm,
            core_1.STATEMENT_TYPE.HbMessageNext,
            core_1.STATEMENT_TYPE.Pause,
        ].includes(line.statementType)) {
            const hbApiClient = target.rootStore.di.server
                .hbClient;
            try {
                const doBlock = target; // fix type
                const doStatement = line;
                const converter = new hellobot_storyplay_converter_1.StoryplayToHellobotConverter(target.rootStore);
                const hbMessage = converter.newStatementToNewHbMessage(doBlock, doStatement, this.isDoblockForAlgorithm(doBlock), indexToAdd);
                if (hbMessage) {
                    const addedMessage = await hbApiClient.message.create(hbMessage, this.isDoblockForAlgorithm(doBlock));
                    const hbDoStatement = doStatement;
                    const hbStatement = hbDoStatement.data;
                    hbStatement.hbExtensionData = addedMessage;
                    await this.onPostHellobotMessageCreation(doBlock, hbStatement);
                }
                return true;
            }
            catch (ex) {
                target.rootStore.showError(ex);
                return false;
            }
        }
        try {
            const asHbBase = line;
            await asHbBase.createHb(asHbBase.getDataForSubmit(), {
                ...asHbBase.st.hbExtensionData,
                turn: indexToAdd,
            });
            return true;
        }
        catch (ex) {
            target.rootStore.showError(ex);
            return false;
        }
    }
    async removeLineInternal(line) {
        const hbApiClient = line.parentBlock.rootStore.di.server.hbClient;
        try {
            const data = line.data;
            if (data.hbExtensionData) {
                await hbApiClient.message.delete(data.hbExtensionData.id, line.parentBlock.rootStore.isAlgorithmStore);
            }
            return true;
        }
        catch (ex) {
            line.parentBlock.rootStore.showError(ex);
            return false;
        }
    }
    // 스플과 달리 빈 메시지를 먼저 생성하고 수정해야 하는 타입의 헬로봇 메시지들이 있다. 그런 메시지들의 수정 동작을 위해 존재하는 함수다.
    async onPostHellobotMessageCreation(targetBlock, savedStatement) {
        const hbApiClient = targetBlock.rootStore.di.server
            .hbClient;
        if (savedStatement.statementType === core_1.STATEMENT_TYPE.CharacterTalk) {
            const hbStatement = savedStatement;
            // console.log('hbStatement', hbStatement)
            const originalHbMessage = hbStatement.hbExtensionData;
            if (originalHbMessage) {
                await hbApiClient.message.update(originalHbMessage.id, { type: 'text', text: hbStatement.message }, this.isDoblockForAlgorithm(targetBlock));
            }
        }
    }
    isDoblockForAlgorithm(doBlock) {
        var _a;
        // HACK, 데이터가 가진 챕터스토어의 객체 동일성 비교(실제 같은 객체인지 비교)를 통해 구별
        const storyId = doBlock.story.storyId;
        const blockChapterStore = doBlock.story.chapterStore;
        const algorithmChapterStore = (_a = doBlock.rootStore.algorithmStoryStore.getById(storyId)) === null || _a === void 0 ? void 0 : _a.chapterStore;
        return blockChapterStore === algorithmChapterStore;
    }
}
exports.HbDoBlockHelper = HbDoBlockHelper;
