"use strict";
/**
 * @deprecated
 *
 * core 에 있는 것을 사용합니다. 기존 코드 호환을 위해 만들어 둔 export 입니다.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockType = exports.STORY_PROP_TYPE = exports.ITEM_OPERATION = exports.PROP_OPERATION = exports.INVERTED_CONDITION_ACTION_MAP = exports.CONDITION_ACTION_MAP = exports.CLOSE_BRACKET = exports.TextType = exports.ChoiceLayoutType = exports.ImageOperationType = exports.STYLE_TAG = exports.CONDITION_ACTION = exports.INVERTED_CONDITION_TYPE_MAP = exports.CONDITION_TYPE_MAP = exports.CONDITION_TYPE = exports.STATEMENT_TYPE = exports.NUM_MAX_CHOICES = void 0;
var core_1 = require("@storyplay/core");
Object.defineProperty(exports, "NUM_MAX_CHOICES", { enumerable: true, get: function () { return core_1.NUM_MAX_CHOICES; } });
Object.defineProperty(exports, "STATEMENT_TYPE", { enumerable: true, get: function () { return core_1.STATEMENT_TYPE; } });
Object.defineProperty(exports, "CONDITION_TYPE", { enumerable: true, get: function () { return core_1.CONDITION_TYPE; } });
Object.defineProperty(exports, "CONDITION_TYPE_MAP", { enumerable: true, get: function () { return core_1.CONDITION_TYPE_MAP; } });
Object.defineProperty(exports, "INVERTED_CONDITION_TYPE_MAP", { enumerable: true, get: function () { return core_1.INVERTED_CONDITION_TYPE_MAP; } });
Object.defineProperty(exports, "CONDITION_ACTION", { enumerable: true, get: function () { return core_1.CONDITION_ACTION; } });
Object.defineProperty(exports, "STYLE_TAG", { enumerable: true, get: function () { return core_1.STYLE_TAG; } });
Object.defineProperty(exports, "ImageOperationType", { enumerable: true, get: function () { return core_1.ImageOperationType; } });
Object.defineProperty(exports, "ChoiceLayoutType", { enumerable: true, get: function () { return core_1.ChoiceLayoutType; } });
Object.defineProperty(exports, "TextType", { enumerable: true, get: function () { return core_1.TextType; } });
Object.defineProperty(exports, "CLOSE_BRACKET", { enumerable: true, get: function () { return core_1.CLOSE_BRACKET; } });
Object.defineProperty(exports, "CONDITION_ACTION_MAP", { enumerable: true, get: function () { return core_1.CONDITION_ACTION_MAP; } });
Object.defineProperty(exports, "INVERTED_CONDITION_ACTION_MAP", { enumerable: true, get: function () { return core_1.INVERTED_CONDITION_ACTION_MAP; } });
Object.defineProperty(exports, "PROP_OPERATION", { enumerable: true, get: function () { return core_1.PROP_OPERATION; } });
Object.defineProperty(exports, "ITEM_OPERATION", { enumerable: true, get: function () { return core_1.ITEM_OPERATION; } });
Object.defineProperty(exports, "STORY_PROP_TYPE", { enumerable: true, get: function () { return core_1.STORY_PROP_TYPE; } });
Object.defineProperty(exports, "BlockType", { enumerable: true, get: function () { return core_1.BlockType; } });
