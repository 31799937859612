import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { PreviewEndingEditPage } from './PreviewEndingEditPage'

interface IPreviewEndingEditPageContainerProps {
  storyId: number
  previewEndingId: number | null
}

export const PreviewEndingEditPageContainer: React.FC<IPreviewEndingEditPageContainerProps> = (props) => {
  const { storyId, previewEndingId } = props
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 7)} />
          <hr />
          <PreviewEndingEditPage storyId={storyId} previewEndingId={previewEndingId} />
        </CardBody>
      </Card>
    </>
  )
}
