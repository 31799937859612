import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { EndingCardListableTable } from '../../endingCard/list/EndingCardListableTable'
import { EndingShowsPropertyListableTable } from '../../endingShowsProperty/list/EndingShowsPropertyListableTable'
import { EndingEditPage } from './EndingEditPage'

interface IEndingEditPageContainerProps {
  storyId: number
  endingId: number | null
  isFinal: boolean
}

export const EndingEditPageContainer: React.FC<IEndingEditPageContainerProps> = (
  props
) => {
  const { storyId, endingId, isFinal } = props

  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 5)} />
          <hr />
          <EndingEditPage
            storyId={storyId}
            endingId={endingId}
            isFinal={isFinal}
          />
        </CardBody>
      </Card>
      {endingId && (
        <Card>
          <CardBody>
            <div style={{ marginTop: 0 }}>
              <h3>엔딩 핵심 속성 할당</h3>
              <p style={{ height: 10 }} />
              <EndingShowsPropertyListableTable
                storyId={storyId}
                endingId={endingId}
                isFinal={isFinal}
              />
            </div>
          </CardBody>
        </Card>
      )}
      {endingId && (
        <Card>
          <CardBody>
            <div style={{ marginTop: 0 }}>
              <h3 style={{ float: 'left' }}>엔딩 카드</h3>
              <EndingCardListableTable
                storyId={storyId}
                endingId={endingId}
                isFinal={isFinal}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  )
}
