"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonPaddingSize = exports.ButtonTheme = void 0;
var ButtonTheme;
(function (ButtonTheme) {
    ButtonTheme["General"] = "General";
    ButtonTheme["Disabled"] = "Disabled";
    ButtonTheme["Filled"] = "Filled";
    ButtonTheme["RedOutlined"] = "RedOutlined";
    ButtonTheme["PrimaryOutlined"] = "PrimaryOutlined";
    ButtonTheme["GreyFilled"] = "GreyFilled";
    ButtonTheme["RedFilled"] = "RedFilled";
    ButtonTheme["GrayOutline"] = "GrayOutline";
})(ButtonTheme = exports.ButtonTheme || (exports.ButtonTheme = {}));
var ButtonPaddingSize;
(function (ButtonPaddingSize) {
    ButtonPaddingSize["PX6PY2"] = "PX6PY2";
    ButtonPaddingSize["PX6PY3"] = "PX6PY3";
    ButtonPaddingSize["PX75PY2"] = "PX75PY2";
    ButtonPaddingSize["PX12PY2"] = "PX12PY2";
    ButtonPaddingSize["PX11PY3"] = "PX11PY3";
    ButtonPaddingSize["PY3"] = "PY3";
})(ButtonPaddingSize = exports.ButtonPaddingSize || (exports.ButtonPaddingSize = {}));
