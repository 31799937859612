"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTBubbleWithChrBase = exports.BackgroundChrField = exports.ChrDisplayField = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const validations_1 = require("../../../../validations");
const shortcutUtils_1 = require("../../../utils/shortcutUtils");
const validation_1 = require("../../../validation");
const fields_1 = require("../../fields");
const TextOptionsField_1 = require("../../fields/TextOptionsField");
const studioTutorial_1 = require("../../studioTutorial");
const DOSTBubbleBase_1 = require("./DOSTBubbleBase");
// TODO::GMBAEK 정상동작 확인 후 별도 파일로 옮길 예정
class ChrDisplayField extends fields_1.EditorFieldBase {
}
exports.ChrDisplayField = ChrDisplayField;
class BackgroundChrField extends fields_1.EditorFieldBase {
}
exports.BackgroundChrField = BackgroundChrField;
class DOSTBubbleWithChrBase extends DOSTBubbleBase_1.DOSTBubbleBase {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.getLuminance = (hex) => {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            const rgb = result
                ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16),
                }
                : null;
            return (rgb && ((0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255) * 100);
        };
        this.chrField = new fields_1.ChrField('chr', () => this.chr, chr => {
            (0, mobx_1.runInAction)(() => {
                this.st.chrName = chr.name.trim();
            });
        });
        this.textOptionsField = new TextOptionsField_1.TextOptionsField('textOptions', () => this.textOptions, textOptions => {
            (0, mobx_1.runInAction)(() => {
                this.st.textOptions = textOptions;
            });
        });
        this.chrDisplayField = new ChrDisplayField('chrDisplay', () => this.chrDisplay, chrDisplay => {
            (0, mobx_1.runInAction)(() => {
                this.st.chrDisplay = chrDisplay;
            });
        });
        this.backgroundChrField = new BackgroundChrField('backgroundChr', () => this.backgroundChr, backgroundChr => {
            (0, mobx_1.runInAction)(() => {
                this.st.backgroundChr = backgroundChr;
            });
        });
        (0, mobx_1.makeObservable)(this, {
            chrName: mobx_1.computed,
            chr: mobx_1.computed,
            textOptions: mobx_1.computed,
            chrDisplay: mobx_1.computed,
            backgroundChr: mobx_1.computed,
        });
    }
    // 주인공 대사 -> 등장인물 대사, 주인공 생각 -> 등장인물 생각 과 같이 반대되는 개념의 클래스로 변환합니다.
    replaceToOpposite(newChrName) {
        throw new Error('해당 클래스를 상속받는 클래스에서 구현이 필요합니다.');
    }
    // oldChrName 이 chrName 과 같다면 newChrName 으로 변경한다.
    replaceChrNameIfMatches(oldChrName, newChrName) {
        if (this.st.chrName !== oldChrName) {
            return;
        }
        const chrToHero = oldChrName !== core_1.STUDIO_SHEET_CONST.HERO_NAME &&
            newChrName === core_1.STUDIO_SHEET_CONST.HERO_NAME;
        const heroToChr = oldChrName === core_1.STUDIO_SHEET_CONST.HERO_NAME &&
            newChrName !== core_1.STUDIO_SHEET_CONST.HERO_NAME;
        if (chrToHero || heroToChr) {
            this.replaceToOpposite(newChrName);
        }
        else {
            (0, mobx_1.runInAction)(() => {
                this.st.chrName = newChrName;
            });
        }
    }
    // backgroundChr.name 이 oldChrName 과 같다면 newChrName 으로 변경한다.
    replaceBackgroundChrNameIfMatches(oldChrName, newChrName) {
        var _a;
        const HERO_NAMES = [core_1.STUDIO_SHEET_CONST.HERO_NAME, '주인공'];
        const heroToChr = HERO_NAMES.includes(oldChrName) && !HERO_NAMES.includes(newChrName);
        const isMainChr = HERO_NAMES.includes(newChrName);
        if (!this.st.backgroundChr) {
            return;
        }
        if (heroToChr || ((_a = this.st.backgroundChr) === null || _a === void 0 ? void 0 : _a.name) === oldChrName) {
            (0, mobx_1.runInAction)(() => {
                this.st.backgroundChr.name = newChrName;
                this.st.backgroundChr.isMainChr = isMainChr;
            });
        }
    }
    get chrName() {
        return this.st.chrName.trim();
    }
    get textOptions() {
        return this.st.textOptions;
    }
    get chr() {
        var _a;
        return ((_a = this.block.parentChapter.getStory().chrStore.getByName(this.chrName)) !== null && _a !== void 0 ? _a : null);
    }
    get editorFields() {
        return [
            ...super.editorFields,
            this.chrField,
            this.textOptionsField,
            this.chrDisplayField,
            this.backgroundChrField,
        ];
    }
    get chrDisplay() {
        return this.st.chrDisplay;
    }
    get backgroundChr() {
        return this.st.backgroundChr;
    }
    /**
     * 스토리게임 미리보기 정책에 따른 이전 등장인물 데이터 표시하기 위한 데이터
     * backgroundChr, chrDisplay 둘 모두 값이 있을 경우엔 무시
     */
    getPrevChrProfile() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        const currentIndex = this.block.statements.findIndex(st => st === this);
        const currentBlock = this.block;
        const statements = (_a = currentBlock === null || currentBlock === void 0 ? void 0 : currentBlock.statements) !== null && _a !== void 0 ? _a : [];
        const isHeroChr = !!((_b = this.chr) === null || _b === void 0 ? void 0 : _b.isHero);
        if (!isHeroChr) {
            return {
                backgroundChr: (_c = this.backgroundChr) !== null && _c !== void 0 ? _c : {
                    name: (_e = (_d = this.chr) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : '',
                    isMainChr: false,
                },
                chrDisplay: 'fit',
            };
        }
        for (let index = currentIndex - 1; index > 0; index--) {
            const _st = statements[index];
            if (!_st || !('statementType' in _st)) {
                continue;
            }
            // 삽화, 지문, 배경 만나면 주인공 이미지 리턴
            if ([
                core_1.STATEMENT_TYPE.FullWidthImage,
                core_1.STATEMENT_TYPE.Script,
                core_1.STATEMENT_TYPE.BackgroundImage,
            ].includes(_st.statementType)) {
                return {
                    backgroundChr: (_f = this.backgroundChr) !== null && _f !== void 0 ? _f : {
                        name: (_h = (_g = this.chr) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : '',
                        isMainChr: true,
                    },
                    chrDisplay: 'fit',
                };
            }
            // 주인공이 아닌 등장인물의 발화 만나면 해당 등장인물 이미지 리턴
            if ([core_1.STATEMENT_TYPE.CharacterTalk, core_1.STATEMENT_TYPE.CharacterThink].includes(_st.statementType)) {
                const chrSt = _st;
                return {
                    backgroundChr: (_j = chrSt.backgroundChr) !== null && _j !== void 0 ? _j : {
                        name: (_l = (_k = chrSt.chr) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : '',
                        isMainChr: false,
                    },
                    chrDisplay: 'scaleLarge',
                };
            }
        }
        // 현재 발화자가 주인공일 경우 위의 로직을 타지 않았을 때 디폴트 값
        return {
            backgroundChr: (_m = this.backgroundChr) !== null && _m !== void 0 ? _m : {
                name: (_p = (_o = this.chr) === null || _o === void 0 ? void 0 : _o.name) !== null && _p !== void 0 ? _p : '',
                isMainChr: true,
            },
            chrDisplay: 'fit',
        };
    }
    // 스토리게임 미리보기에서 보여줄 또는 스크립트에 저장될 chrDisplay, backgroundChr 를 가져온다.
    getBackgroundChrAndChrDisplay() {
        const chrProfile = this.getPrevChrProfile();
        const backgroundChr = (() => {
            if (!!this.backgroundChr) {
                return this.backgroundChr;
            }
            // 등장인물 없음으로 선택된 경우
            // 둘은 무조건 같이 설정되므로 다음과 같이 "등장인물 없음"을 판단한다.
            if (!!this.chrDisplay && !this.backgroundChr) {
                return undefined;
            }
            return chrProfile === null || chrProfile === void 0 ? void 0 : chrProfile.backgroundChr;
        })();
        return {
            chrDisplay: this.chrDisplay || (chrProfile === null || chrProfile === void 0 ? void 0 : chrProfile.chrDisplay),
            backgroundChr,
        };
    }
    /**
     * 즉시 다른 캐릭터로 변경한다.
     */
    setChr(chr) {
        return this.helper
            .opFactory()
            .changeField(this, this.chrField.fieldId, chr)
            .submitSingle();
    }
    setTtsTextOptions(ttsTextOptions) {
        if (!!ttsTextOptions) {
            this.rootStore.studioTutorialStore
                .markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_TTS)
                .then();
        }
        return this.helper
            .opFactory()
            .changeField(this, this.textOptionsField.fieldId, ttsTextOptions)
            .submitSingle();
    }
    setChrDisplayAndBackgroundChr(chrDisplay, backgroundChr) {
        return this.helper
            .opFactory()
            .startBulk()
            .changeField(this, this.chrDisplayField.fieldId, chrDisplay)
            .changeField(this, this.backgroundChrField.fieldId, backgroundChr)
            .submitBulk()
            .catch();
    }
    openTtsSelector() {
        var _a;
        (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.setShowTtsSelectorModalFor({
            playText: this.messageWithoutEffect,
            onFinish: ttsOption => {
                this.setTtsTextOptions(ttsOption);
            },
        });
    }
    openStoryGamePreview() {
        var _a, _b, _c;
        if (!this.chr) {
            return;
        }
        const luminance = this.getLuminance((_b = (_a = this.bubbleEffect) === null || _a === void 0 ? void 0 : _a.backgroundColor) !== null && _b !== void 0 ? _b : '#404040');
        const backgroundImage = (() => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const story = (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.chapterEditing.story;
            const storyHasBackground = story === null || story === void 0 ? void 0 : story.storyHasBackgroundStore.getByName(this.background);
            const studioFile = story === null || story === void 0 ? void 0 : story.studioFileStore.getByResName(this.background);
            const storygameBackgrounds = storyHasBackground === null || storyHasBackground === void 0 ? void 0 : storyHasBackground.storygameBackgrounds;
            const pcLink = (_c = (_b = storygameBackgrounds === null || storygameBackgrounds === void 0 ? void 0 : storygameBackgrounds.find(item => item.platformType === core_1.GQLSTORYGAME_IMAGE_PLATFORM_TYPE.PC)) === null || _b === void 0 ? void 0 : _b.file) === null || _c === void 0 ? void 0 : _c.link;
            const moLink = (_e = (_d = storygameBackgrounds === null || storygameBackgrounds === void 0 ? void 0 : storygameBackgrounds.find(item => item.platformType === core_1.GQLSTORYGAME_IMAGE_PLATFORM_TYPE.Mobile)) === null || _d === void 0 ? void 0 : _d.file) === null || _e === void 0 ? void 0 : _e.link;
            return ((_j = (_h = (_f = pcLink !== null && pcLink !== void 0 ? pcLink : moLink) !== null && _f !== void 0 ? _f : (_g = storyHasBackground === null || storyHasBackground === void 0 ? void 0 : storyHasBackground.file) === null || _g === void 0 ? void 0 : _g.link) !== null && _h !== void 0 ? _h : studioFile === null || studioFile === void 0 ? void 0 : studioFile.link) !== null && _j !== void 0 ? _j : null);
        })();
        const { backgroundChr, chrDisplay } = this.getBackgroundChrAndChrDisplay();
        (_c = this.blockEditor) === null || _c === void 0 ? void 0 : _c.setShowTalkStoryGamePreviewModalFor({
            currentChr: this.chr,
            characterList: this.block.parentChapter
                .getStory()
                .characterList.map(character => {
                var _a;
                return ({
                    ...character,
                    chrName: character.chrName,
                    chrStatementName: character.id === ((_a = this.chr) === null || _a === void 0 ? void 0 : _a.id) ? this.chrName : character.name,
                });
            }),
            backgroundImage,
            messageWithEffect: this.messageWithEffect,
            bubbleEffect: this.bubbleEffect,
            defaultTextColor: luminance
                ? luminance > 50
                    ? 'black'
                    : 'white'
                : 'white',
            chrDisplay,
            backgroundChr,
            statementType: this.statementType,
            onFinish: selected => {
                var _a;
                if (!selected) {
                    return;
                }
                this.setChrDisplayAndBackgroundChr((_a = selected.chrDisplay) !== null && _a !== void 0 ? _a : 'fit', selected.backgroundChr);
                localStorage.setItem(this.block.story.getTalkPreviewStorageKey(this.chrName), JSON.stringify(selected));
            },
        });
    }
    handleKeyboardShortcut(shortcut, editor) {
        if (this.isForMainChr) {
            return;
        }
        const curChr = this.chr;
        if (!curChr) {
            return;
        }
        switch (shortcut) {
            case shortcutUtils_1.StudioShortcut.SelectNextCharacter:
            case shortcutUtils_1.StudioShortcut.MoveSelectionLeft:
                this.setChr(editor.chapterEditing.getStory().getNextChrOf(curChr, false, true)).catch();
                break;
            case shortcutUtils_1.StudioShortcut.SelectPrevCharacter:
            case shortcutUtils_1.StudioShortcut.MoveSelectionRight:
                this.setChr(editor.chapterEditing.getStory().getPrevChrOf(curChr, false, true)).catch();
                break;
            default:
                break;
        }
    }
    serializeToBundle() {
        const { chrDisplay, backgroundChr } = this.getBackgroundChrAndChrDisplay();
        (0, mobx_1.runInAction)(() => {
            this.st.chrDisplay = chrDisplay;
            this.st.backgroundChr = backgroundChr;
        });
        return super.serializeToBundle();
    }
    generateSheetColumnsAfterBackground() {
        var _a, _b, _c, _d, _e;
        // 참고: TTS caching 은 starting block 에서 처리해야 하므로 DOBlock 에서 처리합니다.
        const { chrDisplay, backgroundChr } = this.getBackgroundChrAndChrDisplay();
        const result = [
            this.chrName,
            this.message,
            (_b = (_a = this.bubbleEffect) === null || _a === void 0 ? void 0 : _a.backgroundColor) !== null && _b !== void 0 ? _b : '',
            (_d = (_c = this.bubbleEffect) === null || _c === void 0 ? void 0 : _c.edgeColor) !== null && _d !== void 0 ? _d : '',
            !!this.textOptions ? JSON.stringify(this.textOptions) : '',
            '',
            (_e = this.st.slateData) !== null && _e !== void 0 ? _e : '',
            !!this.executeConditionOfStatement
                ? this.executeConditionOfStatement
                : '',
            chrDisplay !== null && chrDisplay !== void 0 ? chrDisplay : '',
            backgroundChr ? JSON.stringify(backgroundChr) : '', // extra2
        ];
        return result;
    }
    validate() {
        const result = super.validate();
        if (this.chrName.length > validations_1.ChrValidations.NAME_MAX_LENGTH) {
            result.push({
                type: validation_1.StudioValidationType.ChrNameMaxLenNotMet,
                extra: {},
                source: this,
                severity: validation_1.StudioValidationSeverity.Error,
                stack: [],
            });
        }
        (0, mobx_1.runInAction)(() => {
            this.lastValidationResults = result;
        });
        return result;
    }
}
exports.DOSTBubbleWithChrBase = DOSTBubbleWithChrBase;
