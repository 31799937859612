"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStatementBase = void 0;
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../changeOp");
const validation_1 = require("../../validation");
const fields_1 = require("../fields");
const IBlockEditable_1 = require("../ui/IBlockEditable");
const IDOStatement_1 = require("./IDOStatement");
const StudioScriptHelper_1 = require("./StudioScriptHelper");
class DOStatementBase {
    constructor(block, uniqueId) {
        this.isScript = true;
        this.blockTagType = IDOStatement_1.BlockTagType.NoTag; // 필요시 override.
        this.isDeletable = true;
        this.lastValidationResults = [];
        // 만약 이 문장의 수정이 modal dialog 로 이루어지면 true
        this.hasModalForEdit = false;
        // 신규 생성시 임시로 만들어진 상태로 편집하는 형태로 하면, submit 이전에
        // change 를 발생시키지 않고 submit 시에 생성 이벤트로 처리하게 한다.
        // 만약 draft 이면 submit 시에 블록에디터의 현재 위치에 추가한다.
        this.isDraft = false;
        const di = block.store.rootStore.di;
        this.uniqueId = uniqueId !== null && uniqueId !== void 0 ? uniqueId : di.generateInternalHashId();
        this.block = block;
        this.helper = new StudioScriptHelper_1.StudioScriptHelper(this.block);
        this.editorManager = new fields_1.EditorManager(this);
        (0, mobx_1.makeObservable)(this, {
            block: mobx_1.observable,
            lastValidationResults: mobx_1.observable,
            background: mobx_1.computed,
        });
    }
    setDraft() {
        this.isDraft = true;
    }
    get editorFields() {
        return [];
    }
    onBlockNameChanged(prevName, newName) {
        //
    }
    /**
     * 배경은 블록 내의 statement sequence 에 의하여 결정된다.
     */
    get background() {
        return this.block.getBackgroundOfStatement(this);
    }
    get isPublishedEnding() {
        return false;
    }
    clearValidationResults() {
        (0, mobx_1.runInAction)(() => (this.lastValidationResults.length = 0));
    }
    validate() {
        return [];
    }
    /**
     * RootStore 를 쉽게 액세스 하기 위한 헬퍼 함수
     */
    get rootStore() {
        return this.block.store.rootStore;
    }
    /**
     * 스토어 di 를 쉽게 액세스 하기 위한 헬퍼 함수
     */
    get di() {
        return this.block.store.rootStore.di;
    }
    /**
     * 각 statement 에서 접근할 수 있는 block editor 에 대한 helper function.
     */
    get blockEditor() {
        return this.block.parentChapter.blockEditor;
    }
    async applyChangeOp(op, type) {
        if (op.opType === changeOp_1.StudioChangeOpType.ChangeField) {
            const fld = this.editorFields.find(f => f.fieldId === op.field);
            if (fld) {
                // fld.value 는 편집과정에서 이미 변경되어있다.
                const prevValue = fld.defValue;
                fld.onChange(op.value);
                if (this.rootStore.serviceType === 'sp') {
                    await fld.commitChanges();
                }
                if (this.rootStore.serviceType === 'hb') {
                    try {
                        await fld.commitChanges();
                    }
                    catch {
                        fld.revertChanges();
                        return null;
                    }
                }
                return {
                    reverse: changeOp_1.StudioChangeOpFactory.changeField(this, fld.fieldId, prevValue),
                    lineToFocus: this,
                };
            }
        }
        // for (const consumer of this.opConsumers) {
        //   const result = await consumer.applyChangeOp(op, type)
        //   if (result) {
        //     return result
        //   }
        // }
        return null;
    }
    //
    // IStudioEditor implementations
    //
    // 편집을 시작한다.
    startEditing() {
        var _a;
        this.editorManager.startEditing();
        if (this.hasModalForEdit) {
            (_a = this.block.parentChapter.blockEditor) === null || _a === void 0 ? void 0 : _a.setShowEditModalFor(this);
        }
    }
    // 편집을 적용한다.
    // opFactory 가 주어지면, 여기에 추가적으로 변경점을 적용하고 opFactory 가 주어지지 않으면
    // 새롭게 생성하여 super 로 넘겨준다.
    async submitEditing(opFactory) {
        var _a;
        const op = opFactory !== null && opFactory !== void 0 ? opFactory : this.helper.opFactory().startBulk();
        if (this.isDraft) {
            // 문장이 추가되는 방식이 일관되지 못하여 안타깝지만, 서브블록/일반블록을 모두 고려하여
            // 제대로 된 위치에 추가되도록 한다.
            // 그냥 editor.addLine 을 해도 동작하지 않을까 싶기도 하다. 우선은 안전한 길을 택한다.
            const { parent } = this.block.parentChapter.blockEditor.getParentEditableForLineAndIndex();
            if (parent.blockEditableType === IBlockEditable_1.BlockEditableType.NormalBlock) {
                op.addLinesToBlock(this.block.uniqueId, [
                    {
                        line: this,
                        index: this.block.parentChapter.blockEditor.lineEditingIndex,
                    },
                ]);
            }
            else {
                op.addLinesToSubBlock(parent.doBlock.uniqueId, parent.uniqueId, [
                    {
                        line: this,
                        index: this.block.parentChapter.blockEditor.lineEditingIndex,
                    },
                ]);
            }
            this.isDraft = false;
        }
        await this.editorManager.submitEditing(op);
        await op.submitBulk();
        if (this.hasModalForEdit) {
            (_a = this.block.parentChapter.blockEditor) === null || _a === void 0 ? void 0 : _a.setShowEditModalFor(null);
        }
        return op;
    }
    // 편집을 취소한다.
    cancelEditing() {
        var _a;
        this.editorManager.cancelEditing();
        if (this.hasModalForEdit) {
            (_a = this.block.parentChapter.blockEditor) === null || _a === void 0 ? void 0 : _a.setShowEditModalFor(null);
        }
    }
    onAllBlocksOfChapterLoaded() {
        //
    }
    /**
     * yup 라이브러리 형태의 validation 을 수행하고 문제가 있는 경우 IStudioValidationResult
     * 배열 형태로 반환한다.
     */
    runYupValidation(yupLikeValidation, value) {
        try {
            yupLikeValidation.validateSync(value);
        }
        catch (ex) {
            return [
                {
                    type: parseInt(ex.message, 10),
                    severity: validation_1.StudioValidationSeverity.Error,
                    source: this,
                    stack: [],
                    extra: {},
                },
            ];
        }
        return [];
    }
    //
    // Studio translator
    //
    getUniquePathForTranslator() {
        return this.uniqueId;
    }
    async getMessagesToTranslate() {
        var _a, _b, _c, _d;
        const type = (_b = (_a = this) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.statementType;
        if ([undefined, 'BackgroundImage'].includes(type)) {
            return null;
        }
        console.error(243, `DOStatementBase::${this} getMessagesToTranslate() was not provided. ${(_d = (_c = this) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.statementType}`);
        return null;
    }
    async applyTranslatedMessages(translated) {
        // console.error(
        //   259,
        //   `DOStatementBase::${this} applyTranslatedMessages() not provided.`
        // )
    }
    async checkSanityForTranslatedMessages(original, translated) {
        if (original.messages.length !== translated.messages.length) {
            throw new Error(`Invalid number of translated messages. ${original.messages.length} !== ${translated.messages.length}`);
        }
        for (const m of translated.messages) {
            if (m.trim().length === 0) {
                throw new Error(`Translated message contains empty data.`);
            }
        }
    }
    async applyFixedTranslation(fixed) {
        // console.error(
        //   2612,
        //   `DOStatementBase::${this} applyFixedTranslation() not provided.`
        // )
    }
}
exports.DOStatementBase = DOStatementBase;
