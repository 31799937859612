import { useFormikContext } from 'formik'
import React from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'
import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
} from '../../components/base/ODButton'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'

export type StoryImportFieldProps = {
  sheetId: string
  setSheetId: (id: string) => void
  isCreating: boolean
  hideSpread: boolean
  handleImport: () => void
  handleStartFromStudio: () => void
  scriptData: string | null
}

export const FormikFieldUpdater: React.FC<{
  value: any
  formikFieldName: string
  enabled: boolean
}> = (props) => {
  const { value, formikFieldName, enabled } = props
  const { setFieldValue, values } = useFormikContext()
  React.useEffect(() => {
    // @ts-ignore
    if (values[formikFieldName] !== value && enabled) {
      setFieldValue(formikFieldName, value)
    }
  }, [setFieldValue, value, formikFieldName, values, enabled])
  return <div style={{ display: 'none' }} />
}

export const StoryImportField: React.FC<StoryImportFieldProps> = (props) => {
  const {
    setSheetId,
    sheetId,
    handleImport,
    handleStartFromStudio,
    scriptData,
    isCreating,
    hideSpread,
  } = props
  const [spreadSheetEmail, setSpreadSheetEmail] = React.useState('')
  const { getImportStudioGoogleInfo } = useStoryPlayAPIs()
  React.useEffect(() => {
    getImportStudioGoogleInfo().then((r) => {
      setSpreadSheetEmail(r.clientEmail)
    })
  }, [getImportStudioGoogleInfo])
  return (
    <>
      <FormGroup row>
        <Col md={3}>
          <Label htmlFor={`sheetId-input`} style={{ paddingTop: 7 }}>
            구글 스프레드시트에서 불러오기
          </Label>
        </Col>
        <Col xs="12" md={isCreating ? 5 : 7}>
          <Input
            type="text"
            id="sheetId-input"
            placeholder="구글 스프레드 시트의 아이디를 입력하세요."
            onChange={(e) => {
              setSheetId(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'enter') {
                handleImport()
              }
            }}
            value={sheetId}
          />
          <span>
            {' '}
            해당 스프레드 시트에{' '}
            <span
              style={{ color: 'blue', textDecoration: 'underline' }}
              onClick={() => navigator.clipboard.writeText(spreadSheetEmail)}
            >
              {spreadSheetEmail}
            </span>{' '}
            계정을 읽기권한 이상을 부여합니다.
          </span>
        </Col>
        <Col md={2}>
          <ODButton
            theme={ODButtonTheme.Primary}
            size={ODButtonSize.Small}
            disabled={!sheetId}
            fullWidth
            onClick={handleImport}
          >
            로드
          </ODButton>
        </Col>
        {isCreating && (
          <Col md={2}>
            <ODButton
              theme={ODButtonTheme.Primary}
              size={ODButtonSize.Small}
              disabled={!!sheetId}
              fullWidth
              onClick={handleStartFromStudio}
            >
              스튜디오로 시작
            </ODButton>
          </Col>
        )}
        <FormikFieldUpdater
          value={scriptData || ''}
          formikFieldName="script"
          enabled={!!scriptData}
        />
      </FormGroup>
    </>
  )
}
