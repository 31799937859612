import { ODListableOption } from '@odc/od-react-belt'
import { GQLImageFile } from '../../@types/server'

export const ImageFilePrimaryKey = 'fileId'

// modifiable fields only.
export interface ICTImageFile extends Pick<GQLImageFile, 'fileId' | 'link'> {
  // more fields, mapped (if needed)
}

export interface IImageFileListableOption extends ODListableOption {}
