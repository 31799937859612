import { ODEntity, ODEntityLabeled, ODImageFileInput, useCounter } from '@odc/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { GQLImageFile, GQLImageFileCreationInput } from '../../@types/server'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { SiteUrls } from '../../urls'
import { GQL_CREATE_IMAGE_FILE, GQL_GET_IMAGE_FILE, GQL_REMOVE_IMAGE_FILE } from '../../utils/gqls_web'
import { useODEntityAPI } from '../../utils/odGQLEntityLoader'
import { ICTImageFile, ImageFilePrimaryKey } from './ImageFileCommon'

interface IImageFileEditPageProps {
  id: number | null
}

const DEFAULT_DATA: Partial<ICTImageFile> = {
  fileId: 0,
}

export const ImageFileEditPage: React.FC<IImageFileEditPageProps> = (props) => {
  const { id } = props
  const apis = useODEntityAPI<GQLImageFile, ICTImageFile, GQLImageFileCreationInput, void>({
    createGQL: gql(GQL_CREATE_IMAGE_FILE),
    readGQL: gql(GQL_GET_IMAGE_FILE),
    deleteGQL: gql(GQL_REMOVE_IMAGE_FILE),
    primaryKeyName: ImageFilePrimaryKey,
  })

  const [token] = useCounter()

  return (
    <FlexContentsContainer>
      <Card>
        <CardBody>
          <ODEntity
            resourceId={id}
            // @ts-ignore
            api={apis}
            saveButtonName="저장"
            deleteButtonName="삭제"
            titleCreation="추가"
            titleUpdate="수정"
            updateSuccessTitle="성공"
            updateSuccessMessage="수정하였습니다."
            urlAfterCreation={() => SiteUrls.Admin.ImageFile.List}
            urlAfterDelete={() => SiteUrls.Admin.ImageFile.List}
            deleteSuccessTitle="성공"
            deleteSuccessMessage="삭제하였습니다."
            defaultCreateClientData={DEFAULT_DATA}
            urlAfterUpdate={() => null}
            noCardWrap
            refreshToken={token}
            footerProps={{ buttonStyle: { borderRadius: 9999 } }}
          >
            <ODEntityLabeled label="사진" name="link">
              <ODImageFileInput name="image" height={92} width={92} keyPath="link" />
            </ODEntityLabeled>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <hr />
            </div>
          </ODEntity>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
