"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scripter = exports.INVERTED_STATEMENT_TYPE_MAP = exports.STATEMENT_TYPE_MAP = void 0;
const _ = require("lodash");
const server_schema_1 = require("../@types/server.schema");
const const_1 = require("../const");
const I18nText_1 = require("../i18n/I18nText");
const message_parser_1 = require("./message.parser");
const MetaDataUtils_1 = require("./metaData/MetaDataUtils");
const models_1 = require("./models");
const statement_1 = require("./statement");
const { trans } = (0, I18nText_1.i18nTextTranslationByClass)();
// 아래 옵션을 변경하여 테스트시의 몇 가지 귀찮은(?) 사항들에 대하여 디폴트로 처리할 수 있다.
//  - Ending 의 커스텀 아이디가 없으면 엔딩 이름으로 사용
//  - 챕터의 디폴트 엔딩이 없다면 첫번째 챕터 엔딩을 사용
const FALLBACK_DEV_MODE = true;
const templateRegEx = /{{([\s\S]+?)}}/g;
const replaceTemplateRegEx = /([\s\S]+?):([\s\S]+?)/g;
_.templateSettings.interpolate = templateRegEx;
// noinspection NonAsciiCharacters
exports.STATEMENT_TYPE_MAP = {
    조건: statement_1.STATEMENT_TYPE.Condition,
    지문: statement_1.STATEMENT_TYPE.Script,
    장소: statement_1.STATEMENT_TYPE.Place,
    시간: statement_1.STATEMENT_TYPE.Time,
    '대사 주인공': statement_1.STATEMENT_TYPE.MainCharacterTalk,
    대사: statement_1.STATEMENT_TYPE.CharacterTalk,
    '속마음 주인공': statement_1.STATEMENT_TYPE.MainCharacterThink,
    속마음: statement_1.STATEMENT_TYPE.CharacterThink,
    선택지: statement_1.STATEMENT_TYPE.Choice,
    '선택지 옵션': statement_1.STATEMENT_TYPE.ChoiceOption,
    '선택지 속성 저장': statement_1.STATEMENT_TYPE.ChoiceSaveProp,
    '선택지 속성': statement_1.STATEMENT_TYPE.ChoiceProp,
    '선택지 보여지는 이름': statement_1.STATEMENT_TYPE.ChoiceDisplayName,
    '선택지 블록 연결': statement_1.STATEMENT_TYPE.ChoiceToBlock,
    '선택지 등장인물': statement_1.STATEMENT_TYPE.ChoiceCharacter,
    '블록 연결': statement_1.STATEMENT_TYPE.ToBlock,
    '배경 이미지': statement_1.STATEMENT_TYPE.BackgroundImage,
    이미지: statement_1.STATEMENT_TYPE.Image,
    '엔딩 타이틀': statement_1.STATEMENT_TYPE.EndingSummary,
    '최종화 엔딩 타이틀': statement_1.STATEMENT_TYPE.FinalEnding,
    '엔딩 요약': statement_1.STATEMENT_TYPE.EndingSummary,
    '최종 엔딩': statement_1.STATEMENT_TYPE.FinalEnding,
    '최종화 콜렉션 이미지': statement_1.STATEMENT_TYPE.CollectionImage,
    '최종화 콜렉션 설명': statement_1.STATEMENT_TYPE.CollectionDesc,
    '속성 저장': statement_1.STATEMENT_TYPE.SaveProp,
    토스트: statement_1.STATEMENT_TYPE.Toast,
    '메시지 이미지 주인공': statement_1.STATEMENT_TYPE.MainCharacterMessageImage,
    '메시지 이미지': statement_1.STATEMENT_TYPE.MessageImage,
    '최대너비 이미지': statement_1.STATEMENT_TYPE.FullWidthImage,
    진동: statement_1.STATEMENT_TYPE.Vibration,
    효과음: statement_1.STATEMENT_TYPE.SoundEffect,
    '챕터 아이디': statement_1.STATEMENT_TYPE.ChapterId,
    업적이벤트: statement_1.STATEMENT_TYPE.AchievementEvent,
    '배경음악 켜기': statement_1.STATEMENT_TYPE.BGMon,
    '배경음악 끄기': statement_1.STATEMENT_TYPE.BGMoff,
    '아이템 저장': statement_1.STATEMENT_TYPE.UpdateItem,
    '최대너비 지문': statement_1.STATEMENT_TYPE.FullWidthText,
    소설지문: statement_1.STATEMENT_TYPE.FullWidthText,
    '선택지 조건 아이템 저장': statement_1.STATEMENT_TYPE.ChoiceIfUpdateItem,
    '선택지 조건 업적이벤트': statement_1.STATEMENT_TYPE.ChoiceIfAchievementEvent,
    '선택지 조건 속성 저장': statement_1.STATEMENT_TYPE.ChoiceIfSaveProp,
    'TTS 캐싱': statement_1.STATEMENT_TYPE.TTSCashing,
    '원격스크립트 시작': statement_1.STATEMENT_TYPE.CallRemoteScript,
    '원격스크립트 종료': statement_1.STATEMENT_TYPE.FinishRemoteScript,
    '풀스크린이펙트 시작': statement_1.STATEMENT_TYPE.FullScreenEffectOn,
    '풀스크린이펙트 종료': statement_1.STATEMENT_TYPE.FullScreenEffectOff,
    '잠시 멈춤': statement_1.STATEMENT_TYPE.Pause,
};
exports.INVERTED_STATEMENT_TYPE_MAP = _.invert(exports.STATEMENT_TYPE_MAP);
const STYLE_TAG_MAP = {
    b: statement_1.STYLE_TAG.BOLD,
    ib: statement_1.STYLE_TAG.ITALIC_BOLD,
    i: statement_1.STYLE_TAG.ITALIC,
};
const STATEMENT_TYPES_ALLOWED_FOR_NON_ENDING_BLOCK = [
    statement_1.STATEMENT_TYPE.ChoiceToBlock,
    statement_1.STATEMENT_TYPE.ToBlock,
    statement_1.STATEMENT_TYPE.FinishRemoteScript,
];
const CONDITION_ACTION_TYPES_ALLOWED_FOR_NON_ENDING_BLOCK = [
    statement_1.CONDITION_ACTION.MoveToBlock,
];
// noinspection NonAsciiCharacters
const CONDITION_ACTION_MAP = {
    블록이동: statement_1.CONDITION_ACTION.MoveToBlock,
};
const pushUnique = (arr, item) => {
    if (arr.indexOf(item) < 0) {
        arr.push(item);
    }
};
///////
class Scripter {
    /**
     * @param isParsingSingleChapter 스튜디오에서 1개의 챕터만 파싱하는 경우에 해당한다.
     * @param runInDumbMode 스튜디오가 아닌 cli 또는 서버 등에서 처리할 때 오류를 최대한 무시하고 진행하기 위한 모드이다.
     */
    constructor(isParsingSingleChapter = false, runInDumbMode = false) {
        this.runInDumbMode = runInDumbMode;
        this.postBookAssertions = [];
        this.ensureErrors = [];
        this.isParsingSingleChapter = isParsingSingleChapter;
        this.book = {
            chapterSequence: [],
            chapters: {},
            props: {},
            characters: {},
            endings: {},
            choices: {},
            backgrounds: {},
            items: [],
            metaDataUpdateActions: [],
            fallbackRemoteScripts: {},
        };
    }
    log(...args) {
        // tslint:disable-next-line:no-console
        console.log(...args);
    }
    ensureScript(expr, message) {
        if (!expr) {
            this.ensureErrors.push(message);
            if (this.runInDumbMode) {
                return;
            }
            throw new Error(message);
        }
    }
    clearEnsure() {
        this.ensureErrors.splice(0, this.ensureErrors.length);
    }
    addEnsureError(message) {
        this.ensureErrors.push(message);
    }
    assertBackgroundExists(image, errMessage) {
        return (book) => {
            // TODO:
            // console.error(`assertBackgroundExists not implemented : ${image}`)
        };
    }
    assertCollectionImageExists(image, errMessage) {
        return (book) => {
            // TODO
            // console.error('assertCollectionImageExists')
        };
    }
    assertEndingExists(name, currentChapterName, errMessage) {
        return (book) => {
            try {
                const index = book.chapterSequence.indexOf(currentChapterName);
                const prevChapterName = book.chapterSequence[index - 1];
                this.ensureScript(book.endings[name] && book.endings[name].chapter === prevChapterName, errMessage);
            }
            catch (ex) {
                // console.log(71, errMessage)
                this.ensureScript(false, errMessage);
            }
        };
    }
    assertBlockExists(name, errMessage) {
        return (blocksInChapter) => {
            this.ensureScript(!!blocksInChapter[name], errMessage);
        };
    }
    createParseSavePropInput(lineNum, scripter, firstFoundChapterCustomId) {
        return (message) => {
            const index = message.indexOf(':');
            const propName = message.substring(0, index);
            const value = message.substring(index + 1);
            this.ensureScript(propName !== undefined, `${trans('scripter.ts_message_format_optional_property_storage_wrong', {
                value: 1,
            })} ${trans('scripter.ts_line_number', {
                value: lineNum,
            })}`);
            this.ensureScript(value !== undefined, `${trans('scripter.ts_message_format_optional_property_storage_wrong', {
                value: 2,
            })} ${trans('scripter.ts_line_number', {
                value: lineNum,
            })}`);
            const vString = value.trim();
            let op;
            // tslint:disable-next-line:prefer-conditional-expression
            if (_.startsWith(vString, '+') || _.startsWith(vString, '-')) {
                op = statement_1.PROP_OPERATION.INCREASE_NUMBER;
                // CALCULATE 형식으로 된 value 는 다음과 같이 올 수 있으므로,
                // "{...}" -> JSON.stringify 된 PDOperation
                // '"3"'   -> JSON.stringify 된 "3" 문자열
                // parse 하였을 때 value number 타입이면, op 를 SET_NUMBER 로 할당합니다.
            }
            else {
                try {
                    const v = JSON.parse(vString);
                    // tslint:disable-next-line:prefer-conditional-expression
                    if (_.isNumber(v)) {
                        op = statement_1.PROP_OPERATION.SET_NUMBER;
                    }
                    else {
                        op = statement_1.PROP_OPERATION.CALCULATE;
                    }
                }
                catch (e) {
                    if (vString.search(/(\{|\})/) > -1) {
                        this.ensureScript(false, `${trans('scripter.ts_check_json_form', {
                            value: propName,
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}, ${trans('scripter.ts_content', { value })}`);
                    }
                    else {
                        op = statement_1.PROP_OPERATION.SET_TEXT;
                    }
                }
            }
            if ([statement_1.PROP_OPERATION.SET_NUMBER, statement_1.PROP_OPERATION.INCREASE_NUMBER].includes(op)) {
                const numberValue = parseInt(value, 10);
                this.ensureScript(!!propName && _.isNumber(numberValue), `${trans('scripter.ts_message_format_optional_property_storage_wrong', {
                    value: 3,
                })} ${trans('scripter.ts_line_number', {
                    value: lineNum,
                })}, ${trans('scripter.ts_content', {
                    value: message,
                })}`);
            }
            // 지금은 INCREASE 만 한다.
            scripter.defineProperty(propName.trim(), firstFoundChapterCustomId, op);
            return {
                propName: propName.trim(),
                propOp: op,
                value: value.trim(),
            };
        };
    }
    createParseUpdateItemInput(lineNum, scripter) {
        return (message) => {
            const index = message.indexOf(':');
            const itemName = message.substring(0, index);
            const value = message.substring(index + 1);
            this.ensureScript(itemName !== undefined && value !== undefined, `${trans('scripter.ts_message_format_item_storage_wrong')} ${trans('scripter.ts_line_num')} ${lineNum}`);
            const trimedValue = value.trim();
            let op;
            // tslint:disable-next-line:prefer-conditional-expression
            if (_.startsWith(trimedValue, '+') || _.startsWith(trimedValue, '-')) {
                op = statement_1.ITEM_OPERATION.INCREASE_NUMBER;
                // CALCULATE 형식으로 된 value 는 다음과 같이 올 수 있으므로,
                // "{...}" -> JSON.stringify 된 PDOperation
                // '"3"'   -> JSON.stringify 된 "3" 문자열
                // parse 하였을 때 value number 타입이면, op 를 SET_NUMBER 로 할당합니다.
            }
            else if (_.isNumber(JSON.parse(trimedValue))) {
                op = statement_1.ITEM_OPERATION.SET_NUMBER;
            }
            else {
                op = statement_1.ITEM_OPERATION.CALCULATE;
            }
            if (op === statement_1.ITEM_OPERATION.CALCULATE) {
                try {
                    JSON.parse(value);
                }
                catch (e) {
                    this.ensureScript(false, `${trans('scripter.ts_check_json_form', {
                        value: itemName,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_content', {
                        value,
                    })}`);
                }
            }
            else {
                const numberValue = parseInt(trimedValue, 10);
                this.ensureScript(!_.isNaN(numberValue), `${trans('scripter.ts_message_format_optional_property_storage_wrong', {
                    value: 4,
                })} ${trans('scripter.ts_line_number', {
                    value: lineNum,
                })}, ${trans('scripter.ts_content', {
                    value: message,
                })}} `);
            }
            const trimedItemName = itemName.trim();
            scripter.defineItem(trimedItemName);
            return {
                itemName: trimedItemName,
                itemOp: op,
                itemCount: trimedValue,
            };
        };
    }
    loadGoogleSheet(spreadSheetId, range, chapterName, sheetData, colorMap) {
        this.loadDataAsChapter(sheetData, chapterName, spreadSheetId, range, colorMap);
    }
    /**
     * 관리 시트를 파싱하여 book.metaDataUpdateActions 필드를 채운다.
     * @param sheetData 관리 시트의 데이터
     */
    loadGoogleMgmtSheet(sheetData) {
        const actions = [];
        for (const line of sheetData) {
            actions.push((0, MetaDataUtils_1.decodeMetaDataUpdateActionFromSheet)(line));
        }
        this.book.metaDataUpdateActions = actions;
    }
    loadDataAsChapter(spreadAPIReturnedData, // ['1', '시작 블록', '하트코행성', '대사 주인공', '{{이름}}', '나 고양이 키우고 싶어']
    chapterName, sourceSheetId, sourceSheetRange, colorMap) {
        var _a;
        this.ensureScript(!this.book.chapters[chapterName], `Given chapter name (${chapterName}) is already exists.`);
        const { blocks, startingBlock, customId, hasBGM } = this.parseChapterRows(spreadAPIReturnedData, chapterName, colorMap);
        this.ensureScript(customId.length > 0, trans('scripter.ts_custom_id_not_specified', {
            value: chapterName,
        }));
        const existing = Object.values(this.book.chapters).find(chap => chap.customId === customId);
        this.ensureScript(!existing, trans('scripter.ts_custom_id_duplicated', {
            value1: chapterName,
            value2: customId,
            value3: (_a = existing === null || existing === void 0 ? void 0 : existing.name) !== null && _a !== void 0 ? _a : '',
        }));
        this.book.chapters[chapterName] = {
            customId,
            // data: spreadAPIReturnedData,
            name: chapterName,
            // source: [sourceSheetId, sourceSheetRange],
            startingBlock,
            blocks,
            hasBGM,
        };
        this.book.chapterSequence.push(chapterName);
    }
    defineCharacter(chrName) {
        if (!this.book.characters[chrName]) {
            this.book.characters[chrName] = {
                name: chrName,
            };
            // this.log(`새로운 캐릭터 발견 : ${chrName}`)
        }
    }
    defineTemplateString(tmplString, firstFoundChapterCustomId) {
        if (!this.book.props[tmplString]) {
            this.book.props[tmplString] = {
                name: tmplString,
                propType: models_1.STORY_PROP_TYPE.STRING,
                chapterId: firstFoundChapterCustomId,
            };
            // this.log(`새로운 속성 발견 : {{${tmplString}}} / 문자열`)
        }
    }
    defineProperty(propName, firstFoundChapterCustomId, op) {
        if (!this.book.props[propName]) {
            this.book.props[propName] = {
                name: propName,
                propType: op === statement_1.PROP_OPERATION.SET_TEXT
                    ? models_1.STORY_PROP_TYPE.STRING
                    : models_1.STORY_PROP_TYPE.NUMBER,
                chapterId: firstFoundChapterCustomId,
            };
            // this.log(`새로운 속성 발견 : {{${propName}}} / 숫자`)
        }
    }
    defineEnding(endingId, endingName, chapterName, isFinal, isDefault, displayName) {
        // this.ensureScript(!this.book.endings[endingName], `엔딩명 ${endingName} - 이미 존재합니다.`)
        this.book.endings[endingName] = {
            id: endingId,
            chapter: chapterName,
            name: endingName,
            isFinal,
            description: '',
            isChapterDefault: isDefault,
            displayName,
        };
    }
    defineItem(itemName) {
        if (!this.book.items.includes(itemName)) {
            this.book.items.push(itemName);
        }
    }
    updateEndingDescription(endingName, description) {
        const ending = this.book.endings[endingName];
        this.ensureScript(ending, trans('scripter.ts_ending_name_not_found', { value: endingName }));
        ending.description = description;
    }
    checkChoiceExist(choiceName, chapterName, lineNum) {
        this.ensureScript(this.book.choices[choiceName] &&
            this.book.choices[choiceName].chapter === chapterName, `${trans('scripter.ts_option_mapping_error_option_not_exist', {
            value: choiceName,
        })} ${trans('scripter.ts_line_number', { value: lineNum })}`);
    }
    defineChoice(choiceName, chapterName, choices) {
        this.ensureScript(!this.book.choices[choiceName], trans('scripter.ts_option_name_already_exist', { value: choiceName }));
        this.book.choices[choiceName] = {
            chapter: chapterName,
            name: choiceName,
            choices,
        };
    }
    defineChoiceProps(choiceName, propNames) {
        this.ensureScript(this.book.choices[choiceName], trans('scripter.ts_option_to_save_attributes_not_exist', {
            value: choiceName,
        }));
        this.book.choices[choiceName].choiceProps = propNames;
    }
    defineChoiceDisplayNames(choiceName, displayNames) {
        this.ensureScript(this.book.choices[choiceName], trans('scripter.ts_option_to_save_name_not_exist', {
            value: choiceName,
        }));
        this.book.choices[choiceName].choiceDisplayNames = displayNames;
    }
    defineBackground(backgroundName, chapterName) {
        if (!this.book.backgrounds[backgroundName]) {
            this.book.backgrounds[backgroundName] = {
                chapter: [chapterName],
                name: backgroundName,
            };
        }
        else {
            pushUnique(this.book.backgrounds[backgroundName].chapter, chapterName);
        }
    }
    defineFallbackRemoteScript(remoteScriptId, chapterName, lineNum, statement) {
        // default fallBackScript 생성
        if (!this.book.fallbackRemoteScripts[remoteScriptId]) {
            this.book.fallbackRemoteScripts[remoteScriptId] = {
                remoteScriptId,
                chapter: chapterName,
                statements: [],
            };
        }
        if (statement) {
            this.ensureScript(this.book.fallbackRemoteScripts[remoteScriptId].chapter === chapterName, trans('scripter.ts_remote_script_id_duplicated', {
                value1: lineNum,
                value2: remoteScriptId,
            }));
            this.book.fallbackRemoteScripts[remoteScriptId].statements.push(statement);
        }
    }
    extractTemplateString(str, firstFoundChapterCustomId) {
        // @ts-ignore
        const m = str.matchAll(templateRegEx);
        const matches = [...m];
        matches.forEach(([, propName]) => {
            const isReplaceProps = propName.match(replaceTemplateRegEx);
            if (!isReplaceProps) {
                this.defineTemplateString(propName, firstFoundChapterCustomId);
            }
        });
        return matches.length > 0 ? matches : null;
    }
    getEndTag(startEffectIndex, input) {
        const endIndex = input.indexOf(']');
        const result = input.slice(startEffectIndex + 1, endIndex);
        const tagList = result.split(':');
        this.ensureScript(tagList[0] !== 'c' ||
            ((tagList[0] === 'c' || tagList[1] === 'fs') && tagList.length === 2), `${trans('scripter.ts_tag_grammar_incorrect')} ${trans('scripter.ts_content', {
            value: input,
        })}`);
        return {
            tag: tagList[0],
            color: tagList[0] === 'c' ? tagList[1] : undefined,
            fontSizeOffsetTagValue: tagList[0] === 'fs' ? tagList[1] : undefined,
        };
    }
    setMessageEffect(input, lineNum, colorMap) {
        const { message, messageWithEffect, isError } = (0, message_parser_1.parseMessage)(input, colorMap);
        this.ensureScript(!isError, `${trans('scripter.ts_exit_tag_not_found')} ${trans('scripter.ts_line_number', {
            value: lineNum,
        })}, ${trans('scripter.ts_content', {
            value: input,
        })}`);
        return { message, messageWithEffect };
    }
    setEffect(colorMap, backgroundColor, edgeColor, scriptBackgroundColor) {
        const effect = {};
        if (backgroundColor && backgroundColor.trim()) {
            effect.backgroundColor =
                colorMap[backgroundColor.trim()] || backgroundColor.trim();
        }
        if (edgeColor && edgeColor.trim()) {
            effect.edgeColor = colorMap[edgeColor.trim()] || edgeColor.trim();
        }
        if (scriptBackgroundColor && scriptBackgroundColor.trim()) {
            effect.scriptBackgroundColor =
                colorMap[scriptBackgroundColor.trim()] || scriptBackgroundColor.trim();
        }
        return effect;
    }
    setTTSOptions(msgType, message, options, lineNum) {
        let textOptions;
        this.ensureScript(message.length <= 200, `${trans('scripter.ts_tts_input_value_1_length_limit', {
            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
        })} ${trans('scripter.ts_line_number', {
            value: lineNum,
        })}, ${trans('scripter.ts_content', {
            value: message,
        })}`);
        this.ensureScript(/\{/.test(options), `${trans('scripter.ts_json_form_required', {
            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
        })} ${trans('scripter.ts_line_number', {
            value: lineNum,
        })}, ${trans('scripter.ts_content', {
            value: options,
        })}`);
        try {
            JSON.parse(options);
        }
        catch (e) {
            this.ensureScript(false, `${trans('scripter.ts_check_json_form', {
                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
            })} ${trans('scripter.ts_line_number', {
                value: lineNum,
            })}, ${trans('scripter.ts_content', {
                value: options,
            })}`);
        }
        const condOptions = JSON.parse(options);
        this.ensureScript(typeof condOptions !== 'string', `${trans('scripter.ts_json_form_required', {
            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
        })} ${trans('scripter.ts_line_number', {
            value: lineNum,
        })}, ${trans('scripter.ts_content', {
            value: options,
        })}`);
        this.ensureScript(condOptions.textType, `${trans('scripter.ts_text_type_undefined', {
            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
        })} ${trans('scripter.ts_line_number', {
            value: lineNum,
        })}, ${trans('scripter.ts_content', {
            value: options,
        })}`);
        switch (condOptions.textType) {
            case statement_1.TextType.TTS:
                this.ensureScript(_.isNumber(condOptions.speakerId), `${trans('scripter.ts_tts_speakerid_undefined', {
                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                })} ${trans('scripter.ts_line_number', {
                    value: lineNum,
                })}, ${trans('scripter.ts_content', {
                    value: options,
                })}`);
                this.ensureScript(
                // @ts-ignore
                !!server_schema_1.GQLTTS_EMOTION[condOptions.emotion], `${trans('scripter.ts_tts_emotion_undefined', {
                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                })} ${trans('scripter.ts_line_number', {
                    value: lineNum,
                })}, ${trans('scripter.ts_content', {
                    value: options,
                })}`);
                this.ensureScript(
                // @ts-ignore
                !!server_schema_1.GQLTTS_DPE_ID[condOptions.dpeId], `${trans('scripter.ts_tts_dpeid_undefined', {
                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                })} ${trans('scripter.ts_line_number', {
                    value: lineNum,
                })}, ${trans('scripter.ts_content', {
                    value: options,
                })}`);
                textOptions = { ...condOptions };
                break;
            default:
                this.ensureScript(false, `${trans('scripter.ts_layout_type_not_exist', {
                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                })} ${trans('scripter.ts_line_number', {
                    value: lineNum,
                })}, ${trans('scripter.ts_content', {
                    value: options,
                })}`);
        }
        return textOptions;
    }
    parseChapterRows(data, chapterName, colorMap) {
        var _a, _b, _c, _d, _e, _f;
        const blocks = {};
        let chapterCustomId = '';
        let processedIndex = 0;
        const noLogAfter = 10;
        let startingBlock = null;
        const postAssertions = [];
        let currentBlock = {
            isEndingBlock: false,
            name: '',
            statements: [],
        };
        let currentChoice = null;
        let currentFinalEnding = null;
        let defaultEndingId = null;
        let firstEndingId = null;
        let hasChapterEnding = false;
        let numOfBGMon = 0;
        let numOfBGMoff = 0;
        let numOfFullScreenEffectOn = 0;
        let numOfFullScreenEffectOff = 0;
        let hasBGM = false;
        const remoteScriptStack = [];
        const existRemoteScriptIdMap = {};
        for (const row of data) {
            const lineNum = (processedIndex + 2) * 1000;
            const [blockName, _blockType, background, _msgType, chr, input1 = '', input2 = '', _cond, condParam, _condAction, condActionParam, executeCondition, extra1, extra2,] = row;
            // iOS에서 executeCondition가 null이 아닐때 문제가 생겨서 예외처리
            let jsonCondition = executeCondition === '' ? undefined : executeCondition;
            this.ensureScript(!!blockName, `${trans('scripter.ts_block_name_not_exist')} ${trans('scripter.ts_line_number', {
                value: lineNum,
            })}`);
            if ((blockName || '').trim() === '회차종료') {
                break;
            }
            if (!currentBlock.name) {
                currentBlock.name = blockName;
                blocks[blockName] = currentBlock;
            }
            else if (currentBlock.name !== blockName) {
                if (currentBlock.name && !currentBlock.isEndingBlock) {
                    const lastStatement = _.last(currentBlock.statements);
                    const allowedStatementType = STATEMENT_TYPES_ALLOWED_FOR_NON_ENDING_BLOCK.includes(lastStatement.statementType);
                    const allowedConditionActionType = CONDITION_ACTION_TYPES_ALLOWED_FOR_NON_ENDING_BLOCK.includes(lastStatement.conditionAction);
                    this.ensureScript(allowedStatementType || allowedConditionActionType, trans('scripter.ts_normal_block_must_connect_other_block', {
                        value: currentBlock.name,
                    }));
                }
                currentBlock = {
                    isEndingBlock: false,
                    name: blockName,
                    statements: [],
                };
                this.ensureScript(!blocks[blockName], `${trans('scripter.ts_block_name_already_exist', {
                    value: blockName,
                })} ${trans('scripter.ts_line_number', {
                    value: lineNum,
                })}`);
                blocks[blockName] = currentBlock;
            }
            const msgType = exports.STATEMENT_TYPE_MAP[(_msgType || '').trim()];
            this.ensureScript(msgType, `${trans('scripter.ts_unknown_message_type', {
                value: _msgType,
            })} ${trans('scripter.ts_line_number', {
                value: lineNum,
            })}`);
            const isStartingBlock = _blockType === '시작 블록';
            if (isStartingBlock) {
                this.ensureScript(startingBlock === blockName || startingBlock === null, `${trans('scripter.ts_one_start_block')} ${trans('scripter.ts_line_number', {
                    value: lineNum,
                })}`);
                startingBlock = blockName;
            }
            if (chr && chr.trim().length > 0) {
                const chrStatements = [
                    statement_1.STATEMENT_TYPE.CharacterThink,
                    statement_1.STATEMENT_TYPE.CharacterTalk,
                ];
                if (chrStatements.includes(msgType)) {
                    const isTemplate = !!this.extractTemplateString(chr.trim(), chapterCustomId);
                    if (!isTemplate) {
                        this.defineCharacter(chr.trim());
                    }
                }
            }
            if (background && background.trim().length > 0) {
                this.defineBackground(background, chapterName);
            }
            const statements = [];
            switch (msgType) {
                case statement_1.STATEMENT_TYPE.ChapterId: {
                    this.ensureScript(isStartingBlock, `${trans('scripter.ts_chapter_id_syntax_start_block')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const customId = input1.trim();
                    this.ensureScript(customId.length > 0, `${trans('scripter.ts_chapter_id_exist')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    chapterCustomId = customId;
                    break;
                }
                case statement_1.STATEMENT_TYPE.Condition: {
                    const conditionType = statement_1.CONDITION_TYPE_MAP[_cond];
                    const conditionAction = CONDITION_ACTION_MAP[_condAction];
                    if (jsonCondition) {
                        this.ensureScript(!!jsonCondition &&
                            statement_1.CONDITION_TYPE_MAP[_cond] === statement_1.CONDITION_TYPE.PlayDataExpr, `${trans('scripter.ts_unknown_condition_json_condition', {
                            value1: _cond,
                            value2: jsonCondition,
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        statements.push({
                            conditionAction,
                            conditionActionParam: condActionParam,
                            conditionParam: jsonCondition.trim(),
                            conditionNumberParam: -1,
                            conditionType,
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.Condition,
                            background,
                        });
                        break;
                    }
                    const storyItemTypes = [
                        statement_1.CONDITION_TYPE.NumOfStoryItemEqualsTo,
                        statement_1.CONDITION_TYPE.NumOfStoryItemNotEqualsTo,
                        statement_1.CONDITION_TYPE.NumOfStoryItemGTE,
                        statement_1.CONDITION_TYPE.NumOfStoryItemLTE,
                    ];
                    const includeNumberStringParam = [
                        statement_1.CONDITION_TYPE.PropertyEqualsTo,
                        statement_1.CONDITION_TYPE.PropertyNotEqualsTo,
                        statement_1.CONDITION_TYPE.PropertyLTE,
                        statement_1.CONDITION_TYPE.PropertyGTE,
                        ...storyItemTypes,
                    ];
                    this.ensureScript(!!conditionType, `${trans('scripter.ts_unknown_conditional_expression', {
                        value: _cond,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(!!conditionAction, `${trans('scripter.ts_unknown_conditional_action', {
                        value: _condAction,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const requiresNumberStringParam = includeNumberStringParam.includes(conditionType);
                    if (requiresNumberStringParam) {
                        this.ensureScript(condParam.includes(','), `${trans('scripter.ts_condition_paramter_attribute_numeric')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        const [_targetName, value] = condParam.split(',');
                        const targetName = _targetName.trim();
                        const valueString = value.trim();
                        const isStoryItemType = storyItemTypes.includes(conditionType);
                        if (isStoryItemType) {
                            this.ensureScript(targetName.length >= 1, `${trans('legacy_TextStore_item_name_at_least_one_korean')}, itemName: ${targetName}`);
                            this.ensureScript(targetName.length <= 20, `${trans('legacy_TextStore_item_name_max_20_chars')}, itemName: ${targetName}`);
                        }
                        if (!this.isParsingSingleChapter) {
                            if (isStoryItemType) {
                                this.postBookAssertions.push(() => {
                                    this.ensureScript(this.book.items.includes(targetName), `${trans('scripter.ts_condition_parameter_item_name_not_exist', {
                                        value: targetName,
                                    })} ${trans('scripter.ts_line_number', {
                                        value: lineNum,
                                    })}`);
                                });
                            }
                            else {
                                /**
                                 * 2024-04-17
                                 * 어드민에서 스프레드 시트로 업로드 할 때 문제가 발생할 수 있어 주석처리.
                                 * 아래의 유효성 검사는 현재 스플 스튜디오에서는 하고 있지 않아 큰 문제 없을 것으로 보임.
                                 * https://thingsflow.slack.com/archives/C03QK8CHUQG/p1713337863853759?thread_ts=1713258694.327359&cid=C03QK8CHUQG
                                 */
                                // 속성 타입
                                // this.postBookAssertions.push(() => {
                                //   this.ensureScript(
                                //     this.book.props[targetName],
                                //     `${trans(
                                //       'scripter.ts_condition_parameter_property_value_name_not_exist',
                                //       {
                                //         value: targetName,
                                //       }
                                //     )} ${trans('scripter.ts_line_number', {
                                //       value: lineNum,
                                //     })}`
                                //   )
                                // })
                            }
                        }
                        if (!_.isNaN(Number(valueString))) {
                            const paramNumber = parseInt(value.trim(), 10);
                            this.ensureScript(!isNaN(paramNumber) && paramNumber.toString() === value.trim(), `${trans('scripter.ts_condition_paramter_attribute_numeric_incorrect')} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            statements.push({
                                conditionAction,
                                conditionActionParam: condActionParam,
                                conditionParam: targetName,
                                conditionNumberParam: paramNumber,
                                conditionType,
                                sourceLine: lineNum,
                                statementType: statement_1.STATEMENT_TYPE.Condition,
                                background,
                            });
                        }
                        else {
                            this.ensureScript(valueString, `${trans('scripter.ts_condition_parameter_property_value_input_correctly')} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            statements.push({
                                conditionAction,
                                conditionActionParam: condActionParam,
                                conditionParam: targetName,
                                conditionNumberParam: -1,
                                conditionStringParam: valueString.toString(),
                                conditionType,
                                sourceLine: lineNum,
                                statementType: statement_1.STATEMENT_TYPE.Condition,
                                background,
                            });
                        }
                    }
                    else {
                        statements.push({
                            conditionAction,
                            conditionActionParam: condActionParam,
                            conditionParam: condParam,
                            conditionNumberParam: 0,
                            conditionType,
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.Condition,
                            background,
                        });
                    }
                    switch (conditionType) {
                        case statement_1.CONDITION_TYPE.PreviousEndingEqualsTo:
                        case statement_1.CONDITION_TYPE.PreviousEndingNotEqualsTo: {
                            this.ensureScript(!!condParam, `${trans('scripter.ts_condition_paramter_previous_ending_name_required')} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            if (!this.isParsingSingleChapter) {
                                this.postBookAssertions.push(this.assertEndingExists(condParam, chapterName, `${trans('scripter.ts_ending_of_that_name_not_exist', {
                                    value1: chapterName,
                                    value2: condParam,
                                })}`));
                            }
                            break;
                        }
                        case statement_1.CONDITION_TYPE.PropertyGTE:
                        case statement_1.CONDITION_TYPE.PropertyLTE:
                        case statement_1.CONDITION_TYPE.PropertyNotEqualsTo:
                        case statement_1.CONDITION_TYPE.PropertyEqualsTo:
                        case statement_1.CONDITION_TYPE.NumOfStoryItemEqualsTo:
                        case statement_1.CONDITION_TYPE.NumOfStoryItemNotEqualsTo:
                        case statement_1.CONDITION_TYPE.NumOfStoryItemGTE:
                        case statement_1.CONDITION_TYPE.NumOfStoryItemLTE:
                        case statement_1.CONDITION_TYPE.PlayDataExpr: {
                            break;
                        }
                        default:
                            this.ensureScript(false, `${trans('scripter.ts_unintentional_condition_type', {
                                value: conditionType,
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                    }
                    switch (conditionAction) {
                        case statement_1.CONDITION_ACTION.MoveToBlock: {
                            this.ensureScript(!!condActionParam, `${trans('scripter.ts_condition_action_parameter_block_name_required')} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            postAssertions.push(this.assertBlockExists(condActionParam, `${trans('scripter.ts_block_name_in_conditional_action_not_found', {
                                value: condActionParam,
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`));
                            break;
                        }
                        default:
                            this.ensureScript(false, `${trans('scripter.ts_unintentional_condition_action', {
                                value: conditionAction,
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.BackgroundImage: {
                    statements.push({
                        sourceLine: lineNum,
                        statementType: statement_1.STATEMENT_TYPE.BackgroundImage,
                        image: input1,
                        background,
                    });
                    this.ensureScript(!!input1, `${trans('scripter.ts_image_input_value_requires_image_info', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.postBookAssertions.push(this.assertBackgroundExists(input1, `${trans('scripter.ts_image_resource_not_found', {
                        value: chapterName,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_image_name', {
                        value: input1,
                    })}`));
                    break;
                }
                case statement_1.STATEMENT_TYPE.CollectionImage: {
                    statements.push({
                        sourceLine: lineNum,
                        statementType: statement_1.STATEMENT_TYPE.CollectionImage,
                        image: input1,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    this.ensureScript(!!input1, `${trans('scripter.ts_image_input_value_requires_image_info', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.postBookAssertions.push(this.assertCollectionImageExists(input1, `${trans('scripter.ts_image_resource_not_found', {
                        value: chapterName,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_image_name', {
                        value: input1,
                    })}`));
                    break;
                }
                case statement_1.STATEMENT_TYPE.CollectionDesc: {
                    statements.push({
                        sourceLine: lineNum,
                        statementType: msgType,
                        message: input1,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    this.ensureScript(!!input1, `${trans('scripter.ts_input_value_message', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.extractTemplateString(input1, chapterCustomId);
                    if (msgType === statement_1.STATEMENT_TYPE.CollectionDesc) {
                        // 최종화 엔딩 타이틀 이후에 최종화 콜렉션 설명이 등장해야 하며, 이 경우 ending 정보에 추가합니다.
                        this.ensureScript(currentFinalEnding, `${trans('scripter.ts_finalization_collection_description_no_ending_title')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        this.updateEndingDescription(currentFinalEnding, input1);
                    }
                    break;
                }
                // Place, Time 은 지문으로 변경하기로 하였음.
                case statement_1.STATEMENT_TYPE.Place:
                case statement_1.STATEMENT_TYPE.Time:
                case statement_1.STATEMENT_TYPE.Script: {
                    this.ensureScript(!!input1, `${trans('scripter.ts_input_value_message', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    // const splitLines = input1
                    //   .split('\n')
                    //   .filter((v: string) => v)
                    //   .map((v: string) => v.trim())
                    const splitLines = [input1.trim()].filter(v => v);
                    splitLines.forEach((text, index) => {
                        const { message, messageWithEffect } = this.setMessageEffect(text, lineNum, colorMap);
                        const effect = this.setEffect(colorMap, input2, _cond, undefined);
                        const statementScript = {
                            sourceLine: lineNum + index,
                            statementType: statement_1.STATEMENT_TYPE.Script,
                            message,
                            background,
                            messageWithEffect,
                            effect,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                            slateData: condParam && condParam,
                        };
                        if (remoteScriptStack.length) {
                            this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementScript);
                        }
                        else {
                            statements.push(statementScript);
                        }
                        this.extractTemplateString(message, chapterCustomId);
                    });
                    break;
                }
                case statement_1.STATEMENT_TYPE.MainCharacterTalk:
                case statement_1.STATEMENT_TYPE.MainCharacterThink:
                case statement_1.STATEMENT_TYPE.CharacterTalk:
                case statement_1.STATEMENT_TYPE.CharacterThink: {
                    this.ensureScript(!!input1, `${trans('scripter.ts_input_value_message', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    // const splitLines = input1
                    //   .split('\n')
                    //   .filter((v: string) => v)
                    //   .map((v: string) => v.trim())
                    const splitLines = [input1.trim()].filter(v => v);
                    splitLines.forEach((text, index) => {
                        const { message, messageWithEffect } = this.setMessageEffect(text, lineNum, colorMap);
                        const effect = this.setEffect(colorMap, input2, _cond, _condAction);
                        let textOptions;
                        if (condParam) {
                            textOptions = this.setTTSOptions(msgType, message, condParam, lineNum + index);
                        }
                        let backgroundChr;
                        if (extra2) {
                            backgroundChr = JSON.parse(extra2);
                        }
                        const statementTalkAndThink = {
                            sourceLine: lineNum + index,
                            statementType: msgType,
                            message,
                            messageWithEffect,
                            effect,
                            chrName: chr,
                            background,
                            textOptions,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                            slateData: condActionParam && condActionParam,
                            chrDisplay: extra1,
                            backgroundChr,
                        };
                        if (remoteScriptStack.length) {
                            this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementTalkAndThink);
                        }
                        else {
                            statements.push(statementTalkAndThink);
                        }
                        this.extractTemplateString(message, chapterCustomId);
                    });
                    break;
                }
                case statement_1.STATEMENT_TYPE.Choice: {
                    this.ensureScript(currentChoice === null, `${trans('scripter.ts_options_cannot_come_after_option')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(!!chr, `${trans('scripter.ts_options_name_in_character_compartment')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const choices = [input1, input2, _cond, condParam, _condAction]
                        .map(v => (v || '').trim())
                        .filter(v => v.length > 0);
                    this.ensureScript(choices.length >= 2, `${trans('scripter.ts_options_count')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.defineChoice(chr, chapterName, choices);
                    const statement = {
                        sourceLine: lineNum,
                        statementType: msgType,
                        choiceName: chr,
                        choices,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    };
                    statements.push(statement);
                    this.ensureScript(!!input1, `${trans('scripter.ts_optional_input_value_message_1')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(!!input2, `${trans('scripter.ts_optional_input_value_message_2')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    currentChoice = statement;
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceOption: {
                    this.ensureScript(currentChoice !== null, `${trans('scripter.ts_optional_option_connected_to_option')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(!!chr, `${trans('scripter.ts_optional_option_name_in_character_compartment')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const choiceStatementIndex = currentBlock.statements.findIndex(s => s.statementType === statement_1.STATEMENT_TYPE.Choice && s.choiceName === chr);
                    this.ensureScript(choiceStatementIndex > -1, `${trans('scripter.ts_optional_option_after_option_defined')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_content', {
                        value: chr,
                    })} `);
                    this.checkChoiceExist(chr, chapterName, lineNum);
                    this.ensureScript(!!input1, `${trans('scripter.ts_optional_option_input_value_1_content')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(/\{/.test(input1), `${trans('scripter.ts_input_value_1_json_form', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_content', {
                        value: input1,
                    })}`);
                    try {
                        JSON.parse(input1);
                    }
                    catch (e) {
                        this.ensureScript(false, `${trans('scripter.ts_check_json_form', {
                            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}, ${trans('scripter.ts_content', {
                            value: input1,
                        })}`);
                    }
                    const options = JSON.parse(input1);
                    this.ensureScript(typeof options !== 'string', `${trans('scripter.ts_input_value_1_json_form', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_content', {
                        value: input1,
                    })}`);
                    this.ensureScript(options.layoutType, `${trans('scripter.ts_layout_type_defined', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_content', {
                        value: input1,
                    })}`);
                    this.ensureScript((options.showStatistics && options.displayName) ||
                        !options.showStatistics, `${trans('scripter.ts_show_statistics_displayname_defined', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_content', {
                        value: input1,
                    })}`);
                    switch (options.layoutType) {
                        case statement_1.ChoiceLayoutType.Default:
                            currentBlock.statements[choiceStatementIndex] = {
                                ...currentBlock.statements[choiceStatementIndex],
                                options: {
                                    layoutType: options.layoutType,
                                    showStatistics: options.showStatistics,
                                    displayName: options.displayName,
                                    timeout: (_a = options.timeout) !== null && _a !== void 0 ? _a : null,
                                },
                            };
                            this.book.choices[chr.trim()].displayName =
                                options.displayName || '';
                            this.book.choices[chr.trim()].showStatistics =
                                options.showStatistics;
                            this.book.choices[chr.trim()].choiceType =
                                options.layoutType;
                            break;
                        case statement_1.ChoiceLayoutType.Statistics:
                            this.ensureScript(options.displayName, `${trans('scripter.ts_statistical_options_displayname_defined', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}, ${trans('scripter.ts_content', {
                                value: input1,
                            })}`);
                            currentBlock.statements[choiceStatementIndex] = {
                                ...currentBlock.statements[choiceStatementIndex],
                                options: {
                                    layoutType: options.layoutType,
                                    showStatistics: true,
                                    displayName: options.displayName,
                                    timeout: (_b = options.timeout) !== null && _b !== void 0 ? _b : null,
                                },
                            };
                            this.book.choices[chr.trim()].displayName =
                                options.displayName || '';
                            this.book.choices[chr.trim()].showStatistics = true;
                            this.book.choices[chr.trim()].choiceType = const_1.CHOICE_TYPE.Default;
                            break;
                        case statement_1.ChoiceLayoutType.UserInputChoice:
                            this.ensureScript(options.leftUserInputText, `${trans('scripter.ts_user_input_option_leftuserinputtext', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}, ${trans('scripter.ts_content', {
                                value: input1,
                            })}`);
                            this.ensureScript(Object.keys(const_1.PAUST_AFFECTION_LEVEL).length ===
                                this.book.choices[chr.trim()].choices.length, `${trans('scripter.ts_user_input_option_count', {
                                value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                                value2: Object.keys(const_1.PAUST_AFFECTION_LEVEL).length,
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}, ${trans('scripter.ts_option_count', {
                                value: this.book.choices[chr.trim()].choices.length,
                            })}`);
                            this.book.choices[chr.trim()].choices.map((c, idx) => {
                                this.ensureScript(
                                // @ts-ignore
                                const_1.PAUST_AFFECTION_LEVEL[c] || const_1.PAUST_AFFECTION_LEVEL_EN[c], `${trans('scripter.ts_user_input_option_require_setting', {
                                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                                })} ${trans('scripter.ts_line_number', {
                                    value: lineNum,
                                })}, ${trans('scripter.ts_content', {
                                    value: c,
                                })}`);
                                this.ensureScript((idx === 0 &&
                                    (c === '높은 애정 표현' || c === 'High Love Expression')) ||
                                    idx !== 0, `${trans('scripter.ts_user_input_option_require_setting2', {
                                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                                })} ${trans('scripter.ts_line_number', {
                                    value: lineNum,
                                })}, ${trans('scripter.ts_content', {
                                    value: c,
                                })}`);
                            });
                            if (options.choiceBackgroundImage) {
                                this.ensureScript(options.choiceBackgroundImage.width &&
                                    options.choiceBackgroundImage.height &&
                                    options.choiceBackgroundImage.imageUrl, `${trans('scripter.ts_user_input_option_background', {
                                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                                })} ${trans('scripter.ts_line_number', {
                                    value: lineNum,
                                })}, ${trans('scripter.ts_content', {
                                    value: input1,
                                })}`);
                            }
                            currentBlock.statements[choiceStatementIndex] = {
                                ...currentBlock.statements[choiceStatementIndex],
                                options: {
                                    layoutType: options.layoutType,
                                    showStatistics: options.showStatistics,
                                    displayName: options.displayName,
                                    choiceBackgroundImage: options.choiceBackgroundImage,
                                    leftUserInputText: options.leftUserInputText,
                                },
                            };
                            this.book.choices[chr.trim()].displayName = options.displayName;
                            this.book.choices[chr.trim()].showStatistics =
                                options.showStatistics;
                            this.book.choices[chr.trim()].choiceType =
                                options.layoutType;
                            this.book.choices[chr.trim()].backgroundImageInfo =
                                JSON.stringify(options.choiceBackgroundImage);
                            break;
                        case statement_1.ChoiceLayoutType.ImageChoice:
                            this.ensureScript(options.choiceImageUrls.length ===
                                this.book.choices[chr.trim()].choices.length, `${trans('scripter.ts_image_choice_option_url_count', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}, ${trans('scripter.ts_option_count', {
                                value: this.book.choices[chr.trim()].choices.length,
                            })}, ${trans('scripter.ts_option_image_count', {
                                value: options.choiceImageUrls.length,
                            })}`);
                            currentBlock.statements[choiceStatementIndex] = {
                                ...currentBlock.statements[choiceStatementIndex],
                                options: {
                                    layoutType: options.layoutType,
                                    showStatistics: options.showStatistics,
                                    displayName: options.displayName,
                                    choiceImageUrls: options.choiceImageUrls,
                                    timeout: (_c = options.timeout) !== null && _c !== void 0 ? _c : null,
                                },
                            };
                            this.book.choices[chr.trim()].displayName = options.displayName;
                            this.book.choices[chr.trim()].showStatistics =
                                options.showStatistics;
                            this.book.choices[chr.trim()].choiceType =
                                options.layoutType;
                            this.book.choices[chr.trim()].choiceImageUrls =
                                options.choiceImageUrls;
                            break;
                        default:
                            this.ensureScript(false, `${trans('scripter.ts_layout_type_not_exist', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}, ${trans('scripter.ts_content', {
                                value: input1,
                            })}`);
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceDisplayName: {
                    this.ensureScript(currentChoice !== null, `${trans('scripter.ts_option_name_connected_to_option')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(!!chr, `${trans('scripter.ts_option_name_in_character_compartment')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const choiceStatementIndex = currentBlock.statements.findIndex(s => s.statementType === statement_1.STATEMENT_TYPE.Choice && s.choiceName === chr);
                    this.ensureScript(choiceStatementIndex > -1, `${trans('scripter.ts_option_name_after_option_defined')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_content', {
                        value: chr,
                    })}`);
                    this.checkChoiceExist(chr, chapterName, lineNum);
                    const rawDisplayNames = [
                        input1,
                        input2,
                        _cond,
                        condParam,
                        _condAction,
                    ];
                    const displayNames = [];
                    for (let i = 0; i < currentChoice.choices.length; ++i) {
                        // 사용자 입력형 선택지에 기본값 할당
                        const displayName = rawDisplayNames[i]
                            ? rawDisplayNames[i].trim()
                            : i === 0
                                ? '높은 애정 표현'
                                : i === 1
                                    ? '낮은 애정 표현'
                                    : rawDisplayNames[i];
                        this.ensureScript(!!displayName, `${trans('scripter.ts_option_name_not_exist', {
                            value: i + 1,
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        displayNames.push(displayName);
                    }
                    currentBlock.statements[choiceStatementIndex] = {
                        ...currentBlock.statements[choiceStatementIndex],
                        displayNames,
                    };
                    this.defineChoiceDisplayNames(currentChoice.choiceName, displayNames);
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceSaveProp: {
                    this.ensureScript(currentChoice !== null, `${trans('scripter.ts_optional_property_storage_connected_to_option')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const parseSavePropInput = this.createParseSavePropInput(lineNum, this, chapterCustomId);
                    const choiceTexts = [input1, input2, _cond, condParam, _condAction];
                    const propChoices = [];
                    for (let i = 0; i < currentChoice.choices.length; i += 1) {
                        this.ensureScript(!!choiceTexts[i], `${trans('scripter.ts_option_value_not_exist', {
                            value: i + 1,
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        const choice = parseSavePropInput(choiceTexts[i]);
                        this.defineProperty(choice.propName.trim(), chapterCustomId, choice.propOp);
                        propChoices.push(choice);
                    }
                    const statementSaveProp = {
                        sourceLine: lineNum,
                        statementType: msgType,
                        propChoices,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    };
                    if (remoteScriptStack.length) {
                        this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementSaveProp);
                    }
                    else {
                        statements.push(statementSaveProp);
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceProp: {
                    this.ensureScript(currentChoice !== null, `${trans('scripter.ts_option_attributes_connected_to_option')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const rawPropNames = [input1, input2, _cond, condParam, _condAction];
                    const propNames = [];
                    for (let i = 0; i < currentChoice.choices.length; ++i) {
                        const propName = rawPropNames[i]
                            ? rawPropNames[i].trim()
                            : rawPropNames[i];
                        this.ensureScript(!!propName, `${trans('scripter.ts_option_attributes_name_not_exist', {
                            value: i + 1,
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        propNames.push(propName);
                    }
                    this.defineChoiceProps(currentChoice.choiceName, propNames);
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceToBlock: {
                    this.ensureScript(currentChoice !== null, `${trans('scripter.ts_optional_property_storage_connected_to_option')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const choiceTexts = [input1, input2, _cond, condParam, _condAction];
                    const choices = [];
                    for (let i = 0; i < currentChoice.choices.length; i += 1) {
                        const name = choiceTexts[i].trim();
                        this.ensureScript(!!name, `${trans('scripter.ts_option_block_name_not_exist', {
                            value: i + 1,
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        choices.push(name);
                    }
                    statements.push({
                        sourceLine: lineNum,
                        statementType: statement_1.STATEMENT_TYPE.ChoiceToBlock,
                        choices,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    postAssertions.push(this.assertBlockExists(input1.trim(), `${trans('scripter.ts_defined_block_name_not_found', {
                        value: input1.trim(),
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`));
                    postAssertions.push(this.assertBlockExists(input2.trim(), `${trans('scripter.ts_defined_block_name_not_found', {
                        value: input1.trim(),
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`));
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceCharacter: {
                    this.ensureScript(!!chr && chr.trim().length > 0, `${trans('scripter.ts_option_character_not_exist')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(currentChoice !== null, `${trans('scripter.ts_optional_character_connected_to_option')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const chrName = chr.trim();
                    statements.push({
                        sourceLine: lineNum,
                        statementType: statement_1.STATEMENT_TYPE.ChoiceCharacter,
                        chrName,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    break;
                }
                case statement_1.STATEMENT_TYPE.ToBlock: {
                    const block = (input1 || '').trim();
                    this.ensureScript(!!block, `${trans('scripter.ts_input_value_block_name', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    statements.push({
                        sourceLine: lineNum,
                        statementType: statement_1.STATEMENT_TYPE.ToBlock,
                        block,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    postAssertions.push(this.assertBlockExists(block, `${trans('scripter.ts_defined_block_name_not_found', {
                        value: block,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`));
                    break;
                }
                case statement_1.STATEMENT_TYPE.FinalEnding:
                case statement_1.STATEMENT_TYPE.EndingSummary: {
                    // 입력값1: 엔딩이름
                    const endingName = input1;
                    const endingId = (input2 || '').trim() || (FALLBACK_DEV_MODE && input1);
                    const isEndingDefault = (_cond || '').trim() === '디폴트 엔딩';
                    const displayName = (chr || '').trim();
                    this.ensureScript(!!endingName, `${trans('scripter.ts_ending_title_ending_name_in_input_value_1')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(!!endingId, `${trans('scripter.ts_ending_title_ending_id_in_input_value_2')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}, ${trans('scripter.ts_ending_name', {
                        value: endingName,
                    })}`);
                    if (FALLBACK_DEV_MODE &&
                        msgType === statement_1.STATEMENT_TYPE.EndingSummary &&
                        firstEndingId === null) {
                        firstEndingId = endingId;
                    }
                    if (isEndingDefault) {
                        this.ensureScript(defaultEndingId === null, `${trans('scripter.ts_multiple_default_endings')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        defaultEndingId = endingId;
                    }
                    hasChapterEnding =
                        hasChapterEnding || msgType === statement_1.STATEMENT_TYPE.EndingSummary;
                    this.defineEnding(endingId, endingName, chapterName, msgType === statement_1.STATEMENT_TYPE.FinalEnding, isEndingDefault, displayName);
                    const endingStatement = {
                        sourceLine: lineNum,
                        statementType: msgType,
                        endingId,
                        endingName,
                        displayName,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    };
                    if (displayName) {
                        endingStatement.displayName = displayName;
                    }
                    statements.push(endingStatement);
                    currentBlock.isEndingBlock = true;
                    if (msgType === statement_1.STATEMENT_TYPE.FinalEnding) {
                        currentFinalEnding = endingName;
                        postAssertions.push(() => {
                            this.ensureScript(this.book.endings[endingName].description.length > 0, `${trans('scripter.ts_ending_name_description_not_exist', {
                                value: endingName,
                            })}`);
                        });
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceIfSaveProp:
                case statement_1.STATEMENT_TYPE.SaveProp: {
                    const choiceIf = msgType === statement_1.STATEMENT_TYPE.ChoiceIfSaveProp;
                    const parseSavePropInput = this.createParseSavePropInput(lineNum, this, chapterCustomId);
                    if (choiceIf) {
                        const choiceIndex = parseInt(chr !== null && chr !== void 0 ? chr : '', 10) - 1; // -1 for 0-based index
                        this.ensureScript(currentChoice !== null, `${trans('scripter.ts_save_optional_properties_connected_to_option')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        this.ensureScript(_.isNumber(choiceIndex) &&
                            !isNaN(choiceIndex) &&
                            choiceIndex >= 0 &&
                            choiceIndex < currentChoice.choices.length, `${trans('scripter.ts_save_optional_properties_number_wrong')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        statements.push({
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.ChoiceIfSaveProp,
                            choice: choiceIndex,
                            propUpdate: parseSavePropInput(input1),
                            background,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                        });
                    }
                    else {
                        const statementSaveProp = {
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.SaveProp,
                            propUpdate: parseSavePropInput(input1),
                            background,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                        };
                        if (remoteScriptStack.length) {
                            this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementSaveProp);
                        }
                        else {
                            statements.push(statementSaveProp);
                        }
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.Toast: {
                    const options = (chr || '').trim();
                    // @ts-ignore
                    const iconOption = { 아이콘없음: 0, 아이콘1: 1, 아이콘2: 2 }[options];
                    this.ensureScript([0, 1, 2].includes(iconOption), `${trans('scripter.ts_toast_type_toast_content_in_input_value_require')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const message = input1.trim();
                    this.ensureScript(!!input1, `${trans('scripter.ts_toast_type_toast_content_in_input_value_1')} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const statementToast = {
                        sourceLine: lineNum,
                        statementType: statement_1.STATEMENT_TYPE.Toast,
                        toastOption: iconOption,
                        message,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    };
                    if (remoteScriptStack.length) {
                        this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementToast);
                    }
                    else {
                        statements.push(statementToast);
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.Image: {
                    break;
                }
                case statement_1.STATEMENT_TYPE.FullWidthImage:
                case statement_1.STATEMENT_TYPE.MessageImage:
                case statement_1.STATEMENT_TYPE.MainCharacterMessageImage: {
                    this.ensureScript(input2 && input2.trim().length > 0, `${trans('scripter.ts_input_value_2_image_width', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(_cond && _cond.trim().length > 0, `${trans('scripter.ts_conditional_formula_image_height', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const chrName = (chr || '').trim();
                    const link = (input1 || '').trim();
                    const width = parseInt((input2 || '').trim(), 10);
                    const height = parseInt((_cond || '').trim(), 10);
                    const bg = (background || '').trim();
                    const option = (condParam && condParam.trim()) || null;
                    const isFullWidthImage = msgType === statement_1.STATEMENT_TYPE.FullWidthImage;
                    if (option) {
                        this.ensureScript(/\{"effects":/.test(option), // TODO: 형태가 바뀌면 수정 필요
                        `${trans('scripter.ts_conditional_parameter_json_form', {
                            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}, ${trans('scripter.ts_content', {
                            value: option,
                        })}`);
                        try {
                            JSON.parse(option);
                        }
                        catch (e) {
                            this.ensureScript(false, `${trans('scripter.ts_conditional_parameter_json_form_check', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}, ${trans('scripter.ts_content', {
                                value: option,
                            })}`);
                        }
                    }
                    this.ensureScript(!!chrName || isFullWidthImage, `${trans('scripter.ts_character_name_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(!!link, `${trans('scripter.ts_input_value_1_link_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(width > 0, `${trans('scripter.ts_input_value_2_image_width_positive', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(height > 0, `${trans('scripter.ts_conditional_formula_image_height_positive', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    this.ensureScript(bg.length > 0, `${trans('scripter.ts_conditional_formula_background_image', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    if (isFullWidthImage) {
                        const statementFullWidthImage = {
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.FullWidthImage,
                            link,
                            width,
                            height,
                            background,
                            option: (option && JSON.parse(option)) || null,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                            images: extra1,
                        };
                        const fullScreenOptions = (_condAction && _condAction.trim()) || null;
                        if (!!fullScreenOptions) {
                            const parsedOptions = JSON.parse(fullScreenOptions);
                            if (!!((_d = parsedOptions.fullScreenImageLink) === null || _d === void 0 ? void 0 : _d.trim())) {
                                this.ensureScript(_.isNumber(Number(parsedOptions.width)), `${trans('scripter.ts_action_width_incorrect', {
                                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                                })} ${trans('scripter.ts_line_number', {
                                    value: lineNum,
                                })}`);
                                this.ensureScript(_.isNumber(Number(parsedOptions.height)), `${trans('scripter.ts_action_height_incorrect', {
                                    value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                                })} ${trans('scripter.ts_line_number', {
                                    value: lineNum,
                                })}`);
                            }
                            this.ensureScript(_.isNumber(Number(parsedOptions.duration)), `${trans('scripter.ts_action_duration_incorrect', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            this.ensureScript(Object.values(statement_1.TransitionStyle).includes(parsedOptions.showTransition), `${trans('scripter.ts_action_showtransition_incorrect', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            this.ensureScript(Object.values(statement_1.TransitionStyle).includes(parsedOptions.hideTransition), `${trans('scripter.ts_action_hidetransition_incorrect', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            this.ensureScript(Object.values(statement_1.ScreenFillMethod).includes(parsedOptions.fillMethod), `${trans('scripter.ts_action_fillmethod_incorrect', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            statementFullWidthImage.fullScreenOptions = parsedOptions;
                        }
                        const images = (extra1 && extra1.trim()) || null;
                        if (!!images) {
                            const fullWidthImages = JSON.parse(images);
                            // TODO: 로컬라이즈 문구 변경
                            this.ensureScript(!!fullWidthImages.storygameMobile.link, `{${trans('scripter.ts_input_value_1_link_exist}', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            this.ensureScript(!!fullWidthImages.storygamePC.link, `{${trans('scripter.ts_input_value_1_link_exist}', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })}`);
                            statementFullWidthImage.images = fullWidthImages;
                        }
                        else {
                            statementFullWidthImage.images = undefined;
                        }
                        if (remoteScriptStack.length) {
                            this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementFullWidthImage);
                        }
                        else {
                            statements.push(statementFullWidthImage);
                        }
                    }
                    else {
                        const statementImageType = {
                            sourceLine: lineNum,
                            statementType: msgType,
                            chrName,
                            link,
                            width,
                            height,
                            background,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                        };
                        if (remoteScriptStack.length) {
                            this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementImageType);
                        }
                        else {
                            statements.push(statementImageType);
                        }
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.Vibration: {
                    const pattern = (input1 || '').trim();
                    const bg = (background || '').trim();
                    this.ensureScript(bg.length > 0, `${trans('scripter.ts_background_image_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const statementVibration = {
                        sourceLine: lineNum,
                        statementType: msgType,
                        pattern,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    };
                    if (remoteScriptStack.length) {
                        this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementVibration);
                    }
                    else {
                        statements.push(statementVibration);
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.SoundEffect: {
                    const link = (input1 || '').trim();
                    this.ensureScript(!!link, `${trans('scripter.ts_input_value_1_link_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const bg = (background || '').trim();
                    this.ensureScript(bg.length > 0, `${trans('scripter.ts_conditional_formula_background_image', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const statementSoundEffect = {
                        sourceLine: lineNum,
                        statementType: msgType,
                        link,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    };
                    if (remoteScriptStack.length) {
                        this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementSoundEffect);
                    }
                    else {
                        statements.push(statementSoundEffect);
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceIfAchievementEvent:
                case statement_1.STATEMENT_TYPE.AchievementEvent: {
                    const choiceIf = msgType === statement_1.STATEMENT_TYPE.ChoiceIfAchievementEvent;
                    const type = exports.INVERTED_STATEMENT_TYPE_MAP[msgType];
                    const bg = (background || '').trim();
                    this.ensureScript(bg.length > 0, `${trans('scripter.ts_background_image_exist', {
                        value: type,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const numOrZero = (v) => (isNaN(v) ? 0 : v);
                    if (choiceIf) {
                        const choiceIndex = parseInt(chr !== null && chr !== void 0 ? chr : '', 10) - 1; // -1 for 0-based index
                        this.ensureScript(_.isNumber(choiceIndex) &&
                            !isNaN(choiceIndex) &&
                            choiceIndex >= 0 &&
                            choiceIndex < currentChoice.choices.length, `${trans('scripter.ts_achievement_event_option_number_wrong')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        const eventName = (input1 || '').trim();
                        this.ensureScript(!!eventName, `${trans('scripter.ts_event_appearance_event_name_exist', {
                            value: type,
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        const numParam1 = parseInt(input2.trim(), 10);
                        const numParam2 = parseInt((_cond !== null && _cond !== void 0 ? _cond : '').trim(), 10);
                        this.ensureScript(currentChoice !== null, `${trans('scripter.ts_achievement_event_connected_to_option')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        statements.push({
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.ChoiceIfAchievementEvent,
                            event: eventName,
                            choice: choiceIndex,
                            numParam1: numOrZero(numParam1),
                            numParam2: numOrZero(numParam2),
                            background,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                        });
                    }
                    else {
                        const eventName = (chr || '').trim();
                        this.ensureScript(!!eventName, `${trans('scripter.ts_event_appearance_event_name_exist', {
                            value: type,
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        const numParam1 = parseInt(input1.trim(), 10);
                        const numParam2 = parseInt(input2.trim(), 10);
                        statements.push({
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.AchievementEvent,
                            event: eventName,
                            numParam1: numOrZero(numParam1),
                            numParam2: numOrZero(numParam2),
                            background,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                        });
                    }
                    break;
                }
                case statement_1.STATEMENT_TYPE.BGMon: {
                    const type = exports.INVERTED_STATEMENT_TYPE_MAP[msgType];
                    const link = (input1 || '').trim();
                    this.ensureScript(!!link, `${trans('scripter.ts_input_value_1_background_music_link_exist', {
                        value: type,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const bg = (background || '').trim();
                    this.ensureScript(bg.length > 0, `${trans('scripter.ts_conditional_formula_background_image', {
                        value: type,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    statements.push({
                        sourceLine: lineNum,
                        statementType: msgType,
                        link,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    numOfBGMon += 1;
                    break;
                }
                case statement_1.STATEMENT_TYPE.BGMoff: {
                    const type = exports.INVERTED_STATEMENT_TYPE_MAP[msgType];
                    if (numOfBGMon === 0) {
                        this.ensureScript(false, `${trans('scripter.ts_to_set_up_bgm_on', {
                            value1: type,
                            value2: exports.INVERTED_STATEMENT_TYPE_MAP[statement_1.STATEMENT_TYPE.BGMon],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                    }
                    const bg = background.trim();
                    this.ensureScript(bg.length > 0, `${trans('scripter.ts_conditional_formula_background_image', {
                        value: type,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    statements.push({
                        sourceLine: lineNum,
                        statementType: msgType,
                        background,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    numOfBGMoff += 1;
                    break;
                }
                case statement_1.STATEMENT_TYPE.ChoiceIfUpdateItem:
                case statement_1.STATEMENT_TYPE.UpdateItem: {
                    const choiceIf = msgType === statement_1.STATEMENT_TYPE.ChoiceIfUpdateItem;
                    const type = exports.INVERTED_STATEMENT_TYPE_MAP[msgType];
                    const bg = (background || '').trim();
                    this.ensureScript(bg.length > 0, `${trans('scripter.ts_background_image_exist', {
                        value: type,
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const parseUpdateItemInput = this.createParseUpdateItemInput(lineNum, this);
                    const itemUpdate = parseUpdateItemInput(input1);
                    this.ensureScript(itemUpdate.itemName.length >= 1, `${trans('legacy_TextStore_item_name_at_least_one_korean')}, itemName: ${itemUpdate.itemName}`);
                    this.ensureScript(itemUpdate.itemName.length <= 20, `${trans('legacy_TextStore_item_name_max_20_chars')}, itemName: ${itemUpdate.itemName}`);
                    if (choiceIf) {
                        const choiceIndex = parseInt(chr !== null && chr !== void 0 ? chr : '', 10) - 1; // -1 for 0-based index
                        this.ensureScript(_.isNumber(choiceIndex) &&
                            !isNaN(choiceIndex) &&
                            choiceIndex >= 0 &&
                            choiceIndex < currentChoice.choices.length, `${trans('scripter.ts_option_number_item_storage_wrong')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        this.ensureScript(currentChoice !== null, `${trans('scripter.ts_save_items_connected_to_options')} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        statements.push({
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.ChoiceIfUpdateItem,
                            choice: choiceIndex,
                            itemUpdate,
                            background,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                        });
                    }
                    else {
                        const statementUpdateItem = {
                            sourceLine: lineNum,
                            statementType: statement_1.STATEMENT_TYPE.UpdateItem,
                            itemUpdate,
                            background,
                            executeCondition: jsonCondition && jsonCondition.trim(),
                        };
                        if (remoteScriptStack.length) {
                            this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementUpdateItem);
                        }
                        else {
                            statements.push(statementUpdateItem);
                        }
                    }
                    this.defineItem(itemUpdate.itemName);
                    break;
                }
                case statement_1.STATEMENT_TYPE.FullWidthText: {
                    this.ensureScript(!!input1, `${trans('scripter.ts_input_value_message', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
                    const { message, messageWithEffect } = this.setMessageEffect(input1.trim(), lineNum, colorMap);
                    const effect = this.setEffect(colorMap, input2, _cond, undefined);
                    const statementFullWidthText = {
                        sourceLine: lineNum,
                        statementType: msgType,
                        message,
                        background,
                        messageWithEffect,
                        effect,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                        slateData: condParam && condParam,
                    };
                    if (remoteScriptStack.length) {
                        this.defineFallbackRemoteScript(remoteScriptStack[remoteScriptStack.length - 1], chapterName, lineNum, statementFullWidthText);
                    }
                    else {
                        statements.push(statementFullWidthText);
                    }
                    this.extractTemplateString(message, chapterCustomId);
                    break;
                }
                case statement_1.STATEMENT_TYPE.TTSCashing: {
                    this.ensureScript(!!input1, `${trans('scripter.ts_input_value_1_message_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_input_value_1', {
                        value: input1,
                    })}`);
                    const message = input1.trim();
                    const textOptions = this.setTTSOptions(msgType, message, input2, lineNum);
                    statements.push({
                        sourceLine: lineNum,
                        statementType: msgType,
                        message: input1,
                        textOptions,
                        background,
                    });
                    break;
                }
                case statement_1.STATEMENT_TYPE.CallRemoteScript:
                    const startScriptId = (chr || '').trim();
                    this.ensureScript(!!startScriptId, `${trans('scripter.ts_character_script_id_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_character_name', {
                        value: chr,
                    })}`);
                    this.ensureScript(!existRemoteScriptIdMap[startScriptId], `${trans('scripter.ts_same_script_id_exists', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_character_name', {
                        value: chr,
                    })}`);
                    this.ensureScript(!!input1, `${trans('scripter.ts_input_value_1_remote_script_option', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_input_value_1', {
                        value: input1,
                    })}`);
                    let startScriptOption;
                    try {
                        startScriptOption = JSON.parse(input1);
                    }
                    catch (e) {
                        this.ensureScript(false, `${trans('scripter.ts_input_value_1_json_form_check', {
                            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })} ${trans('scripter.ts_input_value_1', {
                            value: input1,
                        })}`);
                    }
                    this.ensureScript(!!startScriptOption.remoteScriptType, `${trans('scripter.ts_input_value_1_remote_script_type_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_input_value_1', {
                        value: input1,
                    })}`);
                    const statementsOption = { params: [], provider: '' };
                    switch (startScriptOption.remoteScriptType) {
                        case statement_1.REMOTE_SCRIPT_TYPE.HelloBot:
                            this.ensureScript(!!startScriptOption.provider && !!startScriptOption.params, `${trans('scripter.ts_input_value_1_hellobot_provider_params', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })} ${trans('scripter.ts_input_value_1', {
                                value: input1,
                            })}`);
                            const resultParams = startScriptOption.params.map((p) => JSON.stringify(p));
                            statementsOption.params = resultParams;
                            statementsOption.provider = startScriptOption.provider;
                            break;
                        case statement_1.REMOTE_SCRIPT_TYPE.StoryPlay:
                            this.ensureScript(!!startScriptOption.provider, `${trans('scripter.ts_input_value_1_storyplay_provider', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })} ${trans('scripter.ts_input_value_1', {
                                value: input1,
                            })}`);
                            statementsOption.provider = startScriptOption.provider;
                            break;
                        case statement_1.REMOTE_SCRIPT_TYPE.ChatGPT:
                            this.ensureScript(!!startScriptOption.prompt && !!startScriptOption.scriptForm, `${trans('scripter.ts_input_value_1_chatgpt_prompt_scriptform', {
                                value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                            })} ${trans('scripter.ts_line_number', {
                                value: lineNum,
                            })} ${trans('scripter.ts_input_value_1', {
                                value: input1,
                            })}`);
                            this.ensureScript(!!startScriptOption.scriptForm.background &&
                                !!startScriptOption.scriptForm.sourceLine &&
                                !!startScriptOption.scriptForm.statementType &&
                                !!startScriptOption.scriptForm.message, `${trans('scripter.ts_input_value_1_chatgpt_scriptform_essential_values', {
                                value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                                value2: lineNum,
                                value3: JSON.stringify(startScriptOption.scriptForm),
                            })}`);
                            // @ts-ignore
                            statementsOption['prompt'] = startScriptOption.prompt;
                            // @ts-ignore
                            statementsOption['scriptForm'] = JSON.stringify(startScriptOption.scriptForm);
                    }
                    statements.push({
                        sourceLine: lineNum,
                        statementType: msgType,
                        scriptId: startScriptId,
                        remoteScriptType: startScriptOption.remoteScriptType,
                        background,
                        ...statementsOption,
                    });
                    existRemoteScriptIdMap[startScriptId] = startScriptId;
                    remoteScriptStack.push(startScriptId);
                    this.defineFallbackRemoteScript(startScriptId, chapterName, lineNum);
                    break;
                case statement_1.STATEMENT_TYPE.FinishRemoteScript:
                    const endScriptId = (chr || '').trim();
                    this.ensureScript(!!endScriptId, `${trans('scripter.ts_character_script_id_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_character_name', {
                        value: chr,
                    })}`);
                    this.ensureScript(endScriptId === remoteScriptStack[remoteScriptStack.length - 1], `${trans('scripter.ts_remote_script_id_mismatch', {
                        value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        value2: lineNum,
                        value3: remoteScriptStack[remoteScriptStack.length - 1],
                        value4: endScriptId,
                    })}`);
                    remoteScriptStack.pop();
                    this.ensureScript(!!input1, `${trans('scripter.ts_input_value_1_remote_script_option', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_input_value_1', {
                        value: input1,
                    })}`);
                    let endScriptOption;
                    try {
                        endScriptOption = JSON.parse(input1);
                    }
                    catch (e) {
                        this.ensureScript(false, `${trans('scripter.ts_input_value_1_json_form_check', {
                            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}, ${trans('scripter.ts_input_value_1', {
                            value: input1,
                        })}`);
                    }
                    this.ensureScript(!!endScriptOption.toBlockAfter, `${trans('scripter.ts_input_value_1_to_block_after_exist', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_input_value_1', {
                        value: input1,
                    })}`);
                    statements.push({
                        sourceLine: lineNum,
                        statementType: msgType,
                        scriptId: endScriptId,
                        toBlockAfter: endScriptOption.toBlockAfter,
                        background,
                    });
                    break;
                case statement_1.STATEMENT_TYPE.FullScreenEffectOn: {
                    this.ensureScript(Object.values(statement_1.FullScreenEffectFileType).includes(condParam), `${trans('scripter.ts_cond_param_require_file_type', {
                        value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                    })} ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })} ${trans('scripter.ts_cond_param_value', {
                        value: condParam,
                    })}`);
                    this.ensureScript(Object.values(statement_1.TransitionStyle).includes(condActionParam), `${trans('scripter.ts_action_parameter_animation_value_exist', {
                        value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        value2: lineNum,
                        value3: condActionParam,
                    })}`);
                    this.ensureScript(_.isNumber(Number(chr)), `${trans('scripter.ts_character_animation_duration_exist', {
                        value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        value2: lineNum,
                    })} ${trans('scripter.ts_character_name', {
                        value: chr,
                    })}`);
                    const statementFullScreenEffect = {
                        sourceLine: lineNum,
                        statementType: msgType,
                        background,
                        duration: parseInt(chr, 10),
                        fileLink: input1,
                        width: parseInt(input2, 10),
                        height: parseInt(_cond, 10),
                        fileType: condParam,
                        fillMethod: _condAction,
                        transitionStyle: condActionParam,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                        images: extra1,
                    };
                    const images = (extra1 && extra1.trim()) || null;
                    if (!!images) {
                        const fullScreenImages = JSON.parse(images);
                        // TODO: 로컬라이즈 문구 변경
                        this.ensureScript(!!fullScreenImages.interactive.link, `{${trans('scripter.ts_input_value_1_link_exist}', {
                            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        this.ensureScript(!!fullScreenImages.storygameMobile.link, `{${trans('scripter.ts_input_value_1_link_exist}', {
                            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        this.ensureScript(!!fullScreenImages.storygamePC.link, `{${trans('scripter.ts_input_value_1_link_exist}', {
                            value: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                        statementFullScreenEffect.images = fullScreenImages;
                    }
                    else {
                        statementFullScreenEffect.images = undefined;
                    }
                    statements.push(statementFullScreenEffect);
                    numOfFullScreenEffectOn += 1;
                    break;
                }
                case statement_1.STATEMENT_TYPE.FullScreenEffectOff: {
                    const type = exports.INVERTED_STATEMENT_TYPE_MAP[msgType];
                    if (numOfFullScreenEffectOn === 0) {
                        this.ensureScript(false, `${trans('scripter.ts_to_set_up_bgm_on', {
                            value1: type,
                            value2: exports.INVERTED_STATEMENT_TYPE_MAP[statement_1.STATEMENT_TYPE.FullScreenEffectOn],
                        })} ${trans('scripter.ts_line_number', {
                            value: lineNum,
                        })}`);
                    }
                    this.ensureScript(Object.values(statement_1.TransitionStyle).includes(condActionParam), `${trans('scripter.ts_action_parameter_animation_value_exist', {
                        value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        value2: lineNum,
                        value3: condActionParam,
                    })}`);
                    this.ensureScript(_.isNumber(Number(chr)), `${trans('scripter.ts_character_animation_duration_exist', {
                        value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        value2: lineNum,
                    })} ${trans('scripter.ts_character_name', {
                        value: chr,
                    })}`);
                    statements.push({
                        sourceLine: lineNum,
                        statementType: msgType,
                        background,
                        duration: parseInt(chr, 10),
                        transitionStyle: condActionParam,
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    numOfFullScreenEffectOff += 1;
                    break;
                }
                case statement_1.STATEMENT_TYPE.Pause: {
                    this.ensureScript(!_.isNaN(Number(chr)), `${trans('scripter.ts_character_numeric_value', {
                        value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        value2: lineNum,
                    })} ${trans('scripter.ts_character_name', {
                        value: chr,
                    })}`);
                    this.ensureScript(Number(chr) >= 0, `${trans('scripter.ts_character_positive_value', {
                        value1: exports.INVERTED_STATEMENT_TYPE_MAP[msgType],
                        value2: lineNum,
                    })} ${trans('scripter.ts_character_name', {
                        value: chr,
                    })}`);
                    statements.push({
                        sourceLine: lineNum,
                        statementType: msgType,
                        background,
                        timeout: parseInt(chr, 10),
                        executeCondition: jsonCondition && jsonCondition.trim(),
                    });
                    break;
                }
                default:
                    this.ensureScript(false, `${trans('scripter.ts_unimplemented_message_type', {
                        value: msgType,
                    })}, ${trans('scripter.ts_line_number', {
                        value: lineNum,
                    })}`);
            }
            if (![
                statement_1.STATEMENT_TYPE.ChoiceProp,
                statement_1.STATEMENT_TYPE.ChoiceOption,
                statement_1.STATEMENT_TYPE.ChoiceSaveProp,
                statement_1.STATEMENT_TYPE.Choice,
                statement_1.STATEMENT_TYPE.ChoiceCharacter,
                statement_1.STATEMENT_TYPE.ChoiceIfUpdateItem,
                statement_1.STATEMENT_TYPE.ChoiceIfAchievementEvent,
                statement_1.STATEMENT_TYPE.ChoiceIfSaveProp,
                statement_1.STATEMENT_TYPE.ChoiceDisplayName,
            ].includes(msgType)) {
                currentChoice = null;
            }
            statements.forEach(statement => {
                currentBlock.statements.push(statement);
            });
            // if (statement) {
            //   // @ts-ignore
            //   currentBlock.statements.push(statement)
            // }
            // const st: STATEMENT = {
            //   sourceLine: processedIndex,
            //   statementType
            // }
            if (processedIndex < noLogAfter) {
                // console.log({ row, st })
            }
            processedIndex += 1;
        }
        if (numOfBGMon >= 1) {
            this.ensureScript(numOfBGMoff >= 1, `${trans('scripter.ts_bgm_or_effect_off_require', {
                value: exports.INVERTED_STATEMENT_TYPE_MAP[statement_1.STATEMENT_TYPE.BGMoff],
            })}`);
            hasBGM = true;
        }
        if (numOfFullScreenEffectOn >= 1) {
            this.ensureScript(numOfFullScreenEffectOff >= 1, `${trans('scripter.ts_bgm_or_effect_off_require', {
                value: exports.INVERTED_STATEMENT_TYPE_MAP[statement_1.STATEMENT_TYPE.FullScreenEffectOff],
            })}`);
        }
        this.ensureScript(!!startingBlock, trans('scripter.ts_start_block_undefined'));
        if (defaultEndingId === null && FALLBACK_DEV_MODE) {
            defaultEndingId = firstEndingId;
        }
        this.ensureScript(defaultEndingId !== null, trans('scripter.ts_no_default_ending_and_guide'));
        const defaultEnding = _.values(this.book.endings).find(en => en.id === defaultEndingId);
        this.ensureScript(!!defaultEnding, trans('scripter.ts_no_default_ending'));
        const isDefaultEndingFinal = (_e = defaultEnding === null || defaultEnding === void 0 ? void 0 : defaultEnding.isFinal) !== null && _e !== void 0 ? _e : false;
        this.ensureScript(!isDefaultEndingFinal || !hasChapterEnding, `${trans('scripter.ts_not_final_chapter_default_ending', {
            value: (_f = defaultEnding === null || defaultEnding === void 0 ? void 0 : defaultEnding.name) !== null && _f !== void 0 ? _f : '',
        })}`);
        let hasError = false;
        postAssertions.forEach(assertion => {
            assertion(blocks); // throw error directly.
            try {
                assertion(blocks);
            }
            catch (ex) {
                this.addEnsureError(ex.message);
                // tslint:disable-next-line:no-console
                console.error(ex);
                hasError = true;
            }
        });
        this.ensureScript(!hasError, trans('scripter.ts_error_progress_impossible'));
        if (FALLBACK_DEV_MODE && chapterCustomId === '') {
            return {
                blocks,
                startingBlock: startingBlock,
                customId: chapterName,
                hasBGM,
            };
        }
        return {
            blocks,
            startingBlock: startingBlock,
            customId: chapterCustomId,
            hasBGM,
        };
    }
    finish() {
        this.postBookAssertions.forEach(assertion => {
            assertion(this.book);
        });
        return JSON.stringify(this.book);
    }
}
exports.Scripter = Scripter;
