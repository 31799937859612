import { GQLOnboardingPopup, GQLONBOARDING_POPUP_TYPE } from '@storyplay/core'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { OnboardingPopupChoiceListableTable } from '../../onboardingPopupChoice/list/OnboardingPopupChoiceListableTable'
import { OnboardingPopupProfileImageListableTable } from '../../onboardingPopupProfileImage/list/OnboardingPopupProfileImageListableTable'
import { OnboardingPopupEditPage } from './OnboardingPopupEditPage'

interface IOnboardingPopupContainerProps {
  storyId: number
  popupId: number
}

export const OnboardingPopupEditPageContainer: React.FC<
  IOnboardingPopupContainerProps
> = (props) => {
  const { storyId, popupId } = props
  const { getOnboardingPopup } = useStoryPlayAPIs()
  const [popup, setPopup] = React.useState<GQLOnboardingPopup | undefined>(
    undefined
  )
  React.useEffect(() => {
    if (popupId !== 0) {
      getOnboardingPopup({ id: popupId }).then((p) => {
        setPopup(p)
      })
      return
    }
    setPopup(undefined)
  }, [popupId, getOnboardingPopup])
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 11)} />
          <hr />
          <OnboardingPopupEditPage storyId={storyId} popupId={popupId} />
        </CardBody>
      </Card>
      {popup &&
        [
          GQLONBOARDING_POPUP_TYPE.Profile,
          GQLONBOARDING_POPUP_TYPE.OtherProfile,
          GQLONBOARDING_POPUP_TYPE.Choice,
        ].includes(popup.type) && (
          <Card>
            <CardBody>
              <div style={{ marginTop: 0 }}>
                <h1>팝업 선택지</h1>
                <p style={{ height: 10 }} />
                <OnboardingPopupChoiceListableTable
                  storyId={storyId}
                  popupId={popupId}
                />
              </div>
            </CardBody>
          </Card>
        )}
      {popup &&
        [
          GQLONBOARDING_POPUP_TYPE.Name,
          GQLONBOARDING_POPUP_TYPE.OtherName,
        ].includes(popup.type) && (
          <Card>
            <CardBody>
              <div style={{ marginTop: 0 }}>
                <h1>팝업 프로필 이미지</h1>
                <p style={{ height: 10 }} />
                <OnboardingPopupProfileImageListableTable
                  storyId={storyId}
                  popupId={popupId}
                />
              </div>
            </CardBody>
          </Card>
        )}
    </>
  )
}
