"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbGNBStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const path_to_regexp_1 = require("path-to-regexp");
const entity_1 = require("../entity");
const studioUrls_1 = require("../studioUrls");
const GNBEventManager_1 = require("./GNBEventManager");
const GNBModalManager_1 = require("./GNBModalManager");
const HbGNBMenuManager_1 = require("./HbGNBMenuManager");
const IGNBModalManager_1 = require("./IGNBModalManager");
const IGNBStore_1 = require("./IGNBStore");
// Global Navigation Bar Store
class HbGNBStore {
    constructor(rootStore) {
        this.pathname = '';
        this.rootStore = rootStore;
        this.modalManager = new GNBModalManager_1.GNBModalManager(this);
        this.menuManager = new HbGNBMenuManager_1.HbGNBMenuManager(this);
        this.eventManager = new GNBEventManager_1.GNBEventManager(this);
        (0, mobx_1.makeObservable)(this, {
            pathname: mobx_1.observable,
            setPathname: mobx_1.action,
            isHiddenGnbHeader: mobx_1.computed,
            currentLayout: mobx_1.computed,
            pageTitleByCurrentLayout: mobx_1.computed,
            me: mobx_1.computed,
        });
    }
    get me() {
        return this.rootStore.loginStore.user;
    }
    get di() {
        return this.rootStore.di;
    }
    // 헬봇에선 임시저장이 없음.
    get featSavedEnabled() {
        return false; // this.rootStore.di.isFeatureEnabled(FEATURE_FLAG.SAVE_FROM_STUDIO)
    }
    get isBotListPage() {
        return this.pathname.startsWith(studioUrls_1.StudioUrls.Story.List);
    }
    getIsBotDetailPage(botId) {
        return this.pathname.startsWith(studioUrls_1.HbStudioUrls.Bot.Detail.Root(botId));
    }
    get isHiddenGnbHeader() {
        const mobileList = [
            IGNBStore_1.GNBStyleLayout.HbBotSkillCreate,
            IGNBStore_1.GNBStyleLayout.HbBotSkillEdit,
            IGNBStore_1.GNBStyleLayout.HbBotManage,
            IGNBStore_1.GNBStyleLayout.HbBlockGroupEdit,
            IGNBStore_1.GNBStyleLayout.HbBotManageEdit,
        ];
        return (this.rootStore.isMobileSize && mobileList.includes(this.currentLayout));
    }
    get pageTitleByCurrentLayout() {
        if (this.currentLayout === IGNBStore_1.GNBStyleLayout.HbBotSkillList) {
            return '스킬 목록';
        }
        if (this.currentLayout === IGNBStore_1.GNBStyleLayout.HbBlockGroupList) {
            return '블록 그룹';
        }
        if (this.currentLayout === IGNBStore_1.GNBStyleLayout.HbAlgorithmList) {
            return '알고리즘 블록';
        }
        return undefined;
    }
    get currentLayout() {
        return this.getCurrentLayout();
    }
    setPathname(location) {
        this.pathname = location;
    }
    onStartingBlockForTestOfChapterEditing() {
        var _a, _b, _c;
        (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.instantTesting.showVersionGuideModal((_c = (_b = this.rootStore.chapterEditing) === null || _b === void 0 ? void 0 : _b.startingBlock) !== null && _c !== void 0 ? _c : null);
    }
    saveChapter() {
        var _a;
        if (!this.featSavedEnabled) {
            return;
        }
        (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.saveDraft().then(() => this.rootStore.showMessage('임시 저장되었습니다.'));
    }
    async applyChapter() {
        const chapterEditing = this.rootStore.chapterEditing;
        const success = await (chapterEditing === null || chapterEditing === void 0 ? void 0 : chapterEditing.applyDraft());
        if (success) {
            this.rootStore.showMessage('성공! 실제 플레이 스크립트에 반영되었습니다.');
        }
        if (success && !!chapterEditing) {
            if (!(chapterEditing === null || chapterEditing === void 0 ? void 0 : chapterEditing.publishedAt) && !chapterEditing.willPublishAt) {
                this.modalManager.openModal({
                    type: IGNBModalManager_1.GNBModal.PublishForChapter,
                    info: {
                        onClose: () => this.modalManager.closeModal(),
                        onPublish: async (willPublishAt) => {
                            await this.publishChapter(chapterEditing, willPublishAt);
                        },
                    },
                });
            }
        }
    }
    async publishChapter(chapterEditing, willPublishAt) {
        try {
            await this.di.server.publishChapter({
                chapterId: chapterEditing.id,
                willPublishAt,
            });
            this.rootStore.showMessage(`${willPublishAt ? '발행이 예약' : '발행'}되었습니다.`);
            this.modalManager.closeModal();
            await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(entity_1.GA4_EVENT_NAME.PUBLISH_CHAPTER);
            await chapterEditing.store.refreshById(chapterEditing.id);
            const shouldShowFreeSettingModal = ![
                core_1.StorySectionType.UGC,
                core_1.StorySectionType.Challenge,
            ].includes(chapterEditing.story.sectionType);
            if (shouldShowFreeSettingModal) {
                this.modalManager.openModal({
                    type: IGNBModalManager_1.GNBModal.ChapterFreeSetting,
                    info: {
                        chapter: chapterEditing,
                        onClose: () => this.modalManager.closeModal(),
                    },
                });
            }
            return true;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            return false;
        }
    }
    getCurrentLayout() {
        if (this.pathname.startsWith(studioUrls_1.StudioUrls.Story.List)) {
            return IGNBStore_1.GNBStyleLayout.HbBotList;
        }
        const manageStoryPattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.ManageStory.Main(':botId'));
        if (!!manageStoryPattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbBotManage;
        }
        const manageStoryEditPattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.ManageStory.Edit(':botId'));
        if (!!manageStoryEditPattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbBotManageEdit;
        }
        const skillListPattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.Skill.List(':botId'));
        if (!!skillListPattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbBotSkillList;
        }
        const skillCreatePattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.Skill.Create(':botId'));
        if (!!skillCreatePattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbBotSkillCreate;
        }
        const skillEditPattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.Skill.Edit(':botId', ':skillId'));
        if (!!skillEditPattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbBotSkillEdit;
        }
        const blockGroupListPattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.BlockGroup.List(':botId'));
        if (!!blockGroupListPattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbBlockGroupList;
        }
        const blockGroupEditPattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.BlockGroup.Edit(':botId', ':groupId'));
        if (!!blockGroupEditPattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbBlockGroupEdit;
        }
        const algorithmBlockGroupListPattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.AlgorithmBlockGroup.List(':botId'));
        if (!!algorithmBlockGroupListPattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbAlgorithmList;
        }
        const botDetailRootPattern = (0, path_to_regexp_1.pathToRegexp)(studioUrls_1.HbStudioUrls.Bot.Detail.Root(':botId'));
        if (!!botDetailRootPattern.exec(this.pathname)) {
            return IGNBStore_1.GNBStyleLayout.HbBotRoot;
        }
        return IGNBStore_1.GNBStyleLayout.Common;
    }
}
exports.HbGNBStore = HbGNBStore;
