"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStudioFile = void 0;
const mobx_1 = require("mobx");
const consts_1 = require("../../../../consts");
const storeUtils_1 = require("../../utils/storeUtils");
/**
 * Character domain object.
 */
class DOStudioFile {
    constructor(store, data) {
        var _a, _b;
        this.fileType = consts_1.StudioFileType.Image;
        this.store = store;
        this.fileId = data.fileId;
        this.storyId = data.storyId;
        this.fileType = data.fileType;
        this.width = data.width;
        this.height = data.height;
        this.link = data.link;
        this.fileName = data.fileName;
        this.resName = (_a = data.resName) !== null && _a !== void 0 ? _a : data.fileName;
        this.displayName = (_b = data.displayName) !== null && _b !== void 0 ? _b : data.fileName;
        this.data = data;
        (0, mobx_1.makeObservable)(this, {
            fileType: mobx_1.observable,
            width: mobx_1.observable,
            height: mobx_1.observable,
            link: mobx_1.observable,
            fileName: mobx_1.observable,
            resName: mobx_1.observable,
            displayName: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    merge(data) {
        const fields = ['resName', 'displayName'];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
}
exports.DOStudioFile = DOStudioFile;
