"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryStore = void 0;
const mobx_1 = require("mobx");
const DOStory_1 = require("./DOStory");
class DOStoryStore {
    constructor(rootStore, doStoryStoreStrategy) {
        this.isLoading = false;
        this.rootStore = rootStore;
        this.data = {};
        this.storyStoreStrategy = new doStoryStoreStrategy(this);
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            isLoading: mobx_1.observable,
            merge: mobx_1.action,
            stories: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    merge(data) {
        const id = data.storyId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOStory_1.DOStory(this, data, this.storyStoreStrategy.doStoryStrategyConstructor);
        }
        return this.data[id];
    }
    /**
     * 특정 작품 id 로 데이터를 로드한다. (좀 더 심플한 형태의 gql 로 받을 수도 있을 듯..)
     */
    async loadStoryById(storyId) {
        // strategy호출로 처리, 헬로봇 일반 블록과 스플쪽을 처리한다.
        this.merge(await this.storyStoreStrategy.loadStoryById(storyId));
    }
    get stories() {
        return Object.values(this.data);
    }
}
exports.DOStoryStore = DOStoryStore;
