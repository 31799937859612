"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbMenuAPI = void 0;
const utils_1 = require("../../../utils");
class HbMenuAPI {
    constructor(server) {
        this.server = server;
    }
    async getList(botId, params) {
        if (params) {
            const queryParams = (0, utils_1.objectToQueryString)(params);
            if (queryParams !== '')
                return this.server.get(`/v1/menu?botId=${botId}&${queryParams}`);
        }
        return this.server.get(`/v1/menu?botId=${botId}`);
    }
    async get(id) {
        return this.server.get(`/v1/menu/${id}`);
    }
    async deleteMenu(id) {
        return this.server.delete(`/v1/menu/${id}`, {});
    }
    async turnUpdate(payload) {
        return this.server.put(`/v1/menu/turn`, payload);
    }
    async menuUpdate(id, payload) {
        return this.server.postFormData(`/v1/menu/${id}`, (0, utils_1.jsonToFormData)(payload));
    }
    async menuCreate(payload) {
        return this.server.postFormData(`/v1/menu`, (0, utils_1.jsonToFormData)(payload));
    }
    getPreviewImages(menuId) {
        return this.server.get(`/v1/preview-image?menuId=${menuId}`);
    }
    deletePreviewImage(previewImageId) {
        return this.server.delete(`/v1/preview-image/${previewImageId}`, {});
    }
    changePreviewImage(payload) {
        const { previewImageId, ...restPayload } = payload;
        return this.server.patchFormData(`/v1/preview-image/${previewImageId}`, (0, utils_1.jsonToFormData)({ ...restPayload }));
    }
    createPreviewImages(menuId, images) {
        const formData = new FormData();
        formData.append('menuId', menuId.toString());
        images.forEach(i => {
            formData.append('images', i);
        });
        return this.server.postFormData('/v1/preview-image', formData);
    }
}
exports.HbMenuAPI = HbMenuAPI;
