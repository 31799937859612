"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbCheckStore = void 0;
const mobx_1 = require("mobx");
const autoLayout_1 = require("../../../autoLayout");
const consts_1 = require("../../../consts");
class HbCheckStore {
    constructor(rootStore) {
        // 현재 보고 있는 봇에 대한 검수 상태
        this.checkStatusByCurrentBot = null;
        // 현재 보고 있는 봇의 검수 데이터 (검수 목록)
        this.checkDataByCurrentBot = null;
        // 검수 요청 실패시 보여줄 데이터 (검수 요청 실패)
        this.errorDataByCurrentBot = null;
        // 라챗반 실패시 보여줄 데이터
        this.liveChatbotErrorDataByCurrentBot = null;
        this.isLoading = false;
        this.currentBot = null;
        // 반려 메세지 표시
        this.isInsecptionReturnModal = false;
        this.setIsInsecptionReturnModal = (bool) => {
            (0, mobx_1.runInAction)(() => {
                this.isInsecptionReturnModal = bool;
            });
        };
        this.rootStore = rootStore;
        (0, mobx_1.makeObservable)(this, {
            isLoading: mobx_1.observable,
            checkStatusByCurrentBot: mobx_1.observable,
            checkDataByCurrentBot: mobx_1.observable,
            errorDataByCurrentBot: mobx_1.observable,
            currentBot: mobx_1.observable,
            isInsecptionReturnModal: mobx_1.observable,
            liveChatbotErrorDataByCurrentBot: mobx_1.observable,
            isDisabledEditing: mobx_1.computed,
        });
    }
    // 봇 검수 상태에 의한 수정 불가능한 상태
    get isDisabledEditing() {
        return (this.checkStatusByCurrentBot === 'REQUEST' ||
            this.checkStatusByCurrentBot === 'CHECKING');
    }
    async loadBotCheckStatus(botId) {
        (0, mobx_1.runInAction)(() => {
            this.resetData();
            this.isLoading = true;
        });
        try {
            const res = await this.rootStore.apiServer.bot.get(botId);
            (0, mobx_1.runInAction)(() => {
                var _a;
                this.checkStatusByCurrentBot = (_a = res.checkStatus) !== null && _a !== void 0 ? _a : null;
                this.currentBot = res;
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        (0, mobx_1.runInAction)(() => {
            this.isLoading = false;
        });
    }
    async loadCheck(botId) {
        (0, mobx_1.runInAction)(() => {
            this.isLoading = true;
        });
        try {
            const bot = await this.rootStore.apiServer.bot.get(botId);
            if (bot.checkStatus) {
                const res = await this.rootStore.apiServer.check.get(botId);
                (0, mobx_1.runInAction)(() => {
                    var _a;
                    this.checkDataByCurrentBot = res !== null && res !== void 0 ? res : null;
                    this.checkStatusByCurrentBot = (_a = res.status) !== null && _a !== void 0 ? _a : null;
                    this.currentBot = bot;
                });
            }
            else {
                (0, mobx_1.runInAction)(() => {
                    this.currentBot = bot;
                });
            }
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        (0, mobx_1.runInAction)(() => {
            this.isLoading = false;
        });
    }
    async check(botId) {
        (0, mobx_1.runInAction)(() => {
            this.isLoading = true;
        });
        try {
            const res = await this.rootStore.apiServer.check.check(botId);
            const botEditing = this.rootStore.storyEditing;
            if ('items' in res) {
                (0, mobx_1.runInAction)(() => {
                    this.errorDataByCurrentBot = res;
                    this.checkDataByCurrentBot = null;
                });
                return;
            }
            if ('botId' in res) {
                (0, mobx_1.runInAction)(() => {
                    this.checkDataByCurrentBot = res;
                    this.errorDataByCurrentBot = null;
                    this.checkStatusByCurrentBot = res.status;
                });
                if (!!res.status) {
                    botEditing === null || botEditing === void 0 ? void 0 : botEditing.mergeHbExtensionData({ checkStatus: res.status });
                }
                return;
            }
            throw new Error(res.message);
        }
        catch (ex) {
            this.rootStore.showError(ex);
            (0, mobx_1.runInAction)(() => {
                this.errorDataByCurrentBot = null;
                this.checkDataByCurrentBot = null;
            });
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isLoading = false;
            });
        }
    }
    async cancelCheck(botId) {
        (0, mobx_1.runInAction)(() => {
            this.isLoading = true;
        });
        try {
            const botEditing = this.rootStore.storyEditing;
            const res = await this.rootStore.apiServer.check.delete(botId);
            botEditing === null || botEditing === void 0 ? void 0 : botEditing.mergeHbExtensionData({ checkStatus: undefined });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        (0, mobx_1.runInAction)(() => {
            this.isLoading = false;
            this.checkStatusByCurrentBot = null;
        });
    }
    resetErrorData() {
        (0, mobx_1.runInAction)(() => {
            this.errorDataByCurrentBot = null;
        });
    }
    resetData() {
        (0, mobx_1.runInAction)(() => {
            this.errorDataByCurrentBot = null;
            this.checkDataByCurrentBot = null;
            this.checkStatusByCurrentBot = null;
            this.isInsecptionReturnModal = false;
            this.currentBot = null;
        });
    }
    getInspectionData(checkStatus) {
        switch (checkStatus) {
            case 'REQUEST':
                return {
                    leftText: '검수 요청 후에는 수정을 할 수 없습니다.',
                    leftTextImage: consts_1.SPImageName.IcnValidationErrorGray,
                    leftTextColor: 'gray-500',
                    theme: autoLayout_1.ButtonTheme.RedFilled,
                    buttonImageName: consts_1.SPImageName.IcnInspectionWhite,
                    buttonText: '검수 취소',
                    mobileExplainText: '검수 진행전',
                    mobileTextColor: 'gray-500',
                    mobileButtonTheme: autoLayout_1.ButtonTheme.RedOutlined,
                };
            case 'CHECKING':
                return {
                    leftText: '지금은 수정이 불가합니다.',
                    leftTextImage: consts_1.SPImageName.IcnValidationError,
                    leftTextColor: 'sub-red-500',
                    theme: autoLayout_1.ButtonTheme.Disabled,
                    buttonImageName: consts_1.SPImageName.IcnInspectionWhite,
                    buttonText: '검수 요청',
                    mobileExplainText: '검수중',
                    mobileButtonReplaceTextImage: consts_1.SPImageName.IcnValidationErrorGray,
                    mobileButtonReplaceText: '지금은 수정이 불가합니다.',
                    mobileTextColor: 'gray-500',
                };
            case 'RETURN':
                return {
                    leftText: '검수가 반려되었습니다.',
                    leftTextImage: consts_1.SPImageName.IcnValidationError,
                    leftTextColor: 'sub-red-500',
                    theme: autoLayout_1.ButtonTheme.RedFilled,
                    buttonImageName: consts_1.SPImageName.IcnInspectionWhite,
                    buttonText: '검수 확인',
                    mobileExplainText: '검수가 반려되었습니다.',
                    mobileExplainTextImage: consts_1.SPImageName.IcnValidationError,
                    mobileTextColor: 'sub-red-500',
                };
            case 'OKAY':
                return {
                    leftText: '검수가 승인되었습니다.',
                    leftTextImage: consts_1.SPImageName.IcnBlueCheck,
                    leftTextColor: 'primary-500',
                    theme: autoLayout_1.ButtonTheme.PrimaryOutlined,
                    buttonImageName: consts_1.SPImageName.IcnInspection,
                    buttonText: '검수 요청',
                    mobileExplainText: '검수가 승인되었습니다.',
                    mobileExplainTextImage: consts_1.SPImageName.IcnBlueCheck,
                    mobileTextColor: 'primary-500',
                };
            default:
                return {
                    theme: autoLayout_1.ButtonTheme.PrimaryOutlined,
                    buttonImageName: consts_1.SPImageName.IcnInspection,
                    buttonText: '검수 요청',
                };
        }
    }
    checkStatusToKorean(status) {
        switch (status) {
            case 'REQUEST':
                return { text: '요청', textColor: 'yellow-800', bgColor: 'yellow-100' };
            case 'EDIT':
                return { text: '수정', textColor: 'green-800', bgColor: 'green-100' };
            case 'RETURN':
                return { text: '반려', textColor: 'red-800', bgColor: 'red-100' };
            case 'OKAY':
                return {
                    text: '승인',
                    textColor: 'primary-800',
                    bgColor: 'primary-100',
                };
            default:
                return { text: '오류', textColor: 'red-800', bgColor: 'red-100' };
        }
    }
    getCheckLabelWithPostPosition(checkItem) {
        const translations = {
            chatBotCheck: '챗봇 정보가',
            menuChecks: '메뉴가',
            blockChecks: '대화블록이',
            algorithmGroupChecks: '알고리즘 블록이',
        };
        return translations[checkItem];
    }
    async liveChatbotUpdate(botId) {
        (0, mobx_1.runInAction)(() => {
            this.isLoading = true;
        });
        try {
            const res = await this.rootStore.apiServer.bot.updateCache(botId);
            if (res.error && res.domain === 'cache') {
                (0, mobx_1.runInAction)(() => {
                    this.liveChatbotErrorDataByCurrentBot = res;
                });
            }
            else {
                this.rootStore.showMessage('라이브 챗봇에 반영했어요');
            }
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
        (0, mobx_1.runInAction)(() => {
            this.isLoading = false;
        });
    }
    async liveChatbotDataReset() {
        (0, mobx_1.runInAction)(() => {
            this.liveChatbotErrorDataByCurrentBot = null;
        });
    }
}
exports.HbCheckStore = HbCheckStore;
