import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLOnboardingPopupProfileImage } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IOnboardingPopupProfileImageListableOption } from '../OnboardingPopupProfileImageCommon'

export function useOnboardingPopupProfileImageListDataLoader(popupId: number) {
  const { listOnboardingPopupProfileImage } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IOnboardingPopupProfileImageListableOption
    ): Promise<ODListableResponseType<GQLOnboardingPopupProfileImage>> {
      const r = await listOnboardingPopupProfileImage({
        page,
        pageSize: 10,
        ...options,
        popupId,
      })
      return r as ODListableResponseType<GQLOnboardingPopupProfileImage>
    },
    [listOnboardingPopupProfileImage, popupId]
  )
}
