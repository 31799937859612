"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBlockHeadStatement = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const validations_1 = require("../../../../validations");
const fields_1 = require("../../fields");
const studioTutorial_1 = require("../../studioTutorial");
const ui_1 = require("../../ui");
const IDOStatement_1 = require("../IDOStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOBlockHeadStatement {
    constructor(block) {
        this.lineType = IDOStatement_1.UILineType.EditorLine;
        this.editorStatementType = IDOStatement_1.EDITOR_STATEMENT_TYPE.BlockHead;
        this.blocksTo = [];
        this.lastValidationResults = [];
        this.isImageFileAcceptor = true;
        this.isPublishedEnding = false;
        this.block = block;
        this.executeConditionField = new fields_1.TextFieldWithUI(`does not support executeConditionField`, () => {
            return '';
        }, v => null, {
            placeholder: 'does not support executeConditionField',
        });
        (0, mobx_1.makeObservable)(this, {
            lastValidationResults: mobx_1.observable,
            blockTagType: mobx_1.computed,
            isNameEditable: mobx_1.computed,
            shouldShowTrash: mobx_1.computed,
            name: mobx_1.computed,
        });
    }
    get blockTagType() {
        return this.block.blockTagType;
    }
    get isNameEditable() {
        return this.block.canChangeName;
    }
    get parentBlock() {
        return this.block;
    }
    set parentBlock(newBlock) {
        (0, mobx_1.runInAction)(() => {
            this.block = newBlock;
        });
    }
    /**
     * 휴지통 아이콘을 보여줘야 하는가?
     */
    get shouldShowTrash() {
        return this.block.isRemovable;
    }
    get name() {
        return new ui_1.InputWithValidation('name', trans('legacy_DOBlockHeadStatement_block_name'), trans('legacy_DOBlockHeadStatement_enter_block_name'), 'text', this.block.name, validations_1.BlockValidations.name, {
            leaveBottomTextPlaceAlways: true,
            placeholderAsBottomText: true,
            enableInPlaceEditing: this.isNameEditable,
            noLabel: true,
            onSubmit: (name) => {
                (0, mobx_1.runInAction)(() => {
                    try {
                        this.block.changeName(name);
                    }
                    catch (ex) {
                        this.block.rootStore.di.showError(ex);
                    }
                    // this.block.name = name
                });
            },
        })
            .setAutoFocus()
            .setEditable(this.isNameEditable);
    }
    fixStatements() {
        //
    }
    onBlockNameChanged(prevName, newName) {
        // nothing to change here.
    }
    get cmdString() {
        return `[HEAD] ${this.blockTagType} ${this.block.name}`;
    }
    clearValidationResults() {
        (0, mobx_1.runInAction)(() => (this.lastValidationResults.length = 0));
    }
    validate() {
        return [];
    }
    get validatorName() {
        return `[ST] Head`;
    }
    generateLines() {
        return [];
    }
    exportMetaDataUpdateActions() {
        return [];
    }
    /**
     * 이 블록의 헤드에 들어가야할 배경이미지 이름을 반환한다.
     */
    get background() {
        return this.block.startingBackground;
    }
    onChangeFile(file) {
        this.block.rootStore.studioTutorialStore
            .markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_BACKGROUND_IMAGE)
            .then();
        this.block.changeStartingBackground(file.resName);
    }
    onBlockRemoved(blockId) {
        //
    }
    get key() {
        return this.block.name;
    }
    onCopyEvent() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.block).onCopyEvent) === null || _b === void 0 ? void 0 : _b.call(_a)) !== null && _c !== void 0 ? _c : null;
    }
    onCutEvent() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.block).onCutEvent) === null || _b === void 0 ? void 0 : _b.call(_a)) !== null && _c !== void 0 ? _c : null;
    }
    get snapshot() {
        return {
            type: 'Block Head',
            blockId: this.block.name,
            blockUniqueId: this.block.uniqueId,
        };
    }
    onAllBlocksOfChapterLoaded() {
        //
    }
    //
    // IStudioFinderSource
    //
    get finderSourceName() {
        return `[ST] Head`;
    }
    getFindResults(keyword) {
        return [];
    }
    replaceTextWith(text, to) {
        //
    }
    selectBySearch() {
        // 블록이 직접 선택될 일은 없다.
    }
    //
    // ExecuteCondition
    //
    get doesExecuteConditionSupport() {
        return false;
    }
    get returnSetExecuteConditionModalOpen() {
        return undefined;
    }
    get hasExecuteCondition() {
        return false;
    }
    get hasSameExecuteConditionWithPrevStatement() {
        return false;
    }
    get hasSameExecuteConditionWithNextStatement() {
        return false;
    }
    get executeConditionOfStatement() {
        return undefined;
    }
    /**
     * 인스턴트 테스팅 버튼을 보여주어야 하는가?
     */
    get canStartInstantTesting() {
        return this.blockTagType !== IDOStatement_1.BlockTagType.ChatBotBlock;
    }
    //
    // IStudioTranslator interface
    //
    getUniquePathForTranslator() {
        // 어차피 getMessagesToTranslate() 에서 null 반환할 것이므로 큰 문제 없음.
        return this.key;
    }
    async getMessagesToTranslate() {
        return null;
    }
    async applyTranslatedMessages(translated) {
        //
    }
    async checkSanityForTranslatedMessages(original, translated) {
        //
    }
    async applyFixedTranslation(fixed) {
        //
    }
}
exports.DOBlockHeadStatement = DOBlockHeadStatement;
