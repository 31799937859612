import {
  BlockingLoadBox,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { GQLPlayerClass } from '@storyplay/common'
import { HoverTR } from '../../../components/commonStyle'
import {
  IPlayerClassListableOption,
  PlayerClassPrimaryKey,
} from '../PlayerClassCommon'
import { UpsertPlayerClassModal } from '../UpsertPlayerClassModal'
import { usePlayerClassListDataLoader } from './PlayerClassListDataLoader'

interface IPlayerClassListableTableProps {
  storyId: number
}

export const PlayerClassListableTable: React.FC<
  IPlayerClassListableTableProps
> = (props) => {
  const { storyId } = props
  const [loading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLPlayerClass,
    IPlayerClassListableOption
  >()
  const [token, refresh] = useCounter()
  const [showPlayerClassModal, setShowPlayerClassModal] = React.useState(false)
  const [playerClassId, setPlayerClassId] = React.useState<number | undefined>(
    undefined
  )
  const dataLoader = usePlayerClassListDataLoader(storyId)

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[PlayerClassPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="이름으로 검색"
              style={searchBoxStyle}
            />
            <div>
              <Button
                block
                color="primary"
                style={{ minWidth: 135 }}
                onClick={() => {
                  setPlayerClassId(undefined)
                  setShowPlayerClassModal(true)
                }}
              >
                플레이어 클래스 추가
              </Button>
            </div>
          </div>
          <ODListablePaginatedTable2
            numColumns={2}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>플레이어 클래스 이름</th>
              </tr>
            )}
            renderRow={(value: GQLPlayerClass) => (
              <>
                <HoverTR
                  key={value.playerClassId}
                  onClick={() => {
                    setPlayerClassId(value.playerClassId)
                    setShowPlayerClassModal(true)
                  }}
                >
                  <td>{value.playerClassId}</td>
                  <td>{value.name}</td>
                </HoverTR>
              </>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
          {showPlayerClassModal && (
            <UpsertPlayerClassModal
              storyId={storyId}
              playerClassId={playerClassId}
              onClose={() => setShowPlayerClassModal(false)}
              onConfirm={() => {
                setShowPlayerClassModal(false)
                refresh()
              }}
            />
          )}
        </CardBody>
      </Card>
    </Provider>
  )
}
