"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbAlgorithmBlockGroupApi = void 0;
class HbAlgorithmBlockGroupApi {
    constructor(server) {
        this.server = server;
    }
    getItems(listConfig) {
        listConfig = {
            sort: 'regDate',
            sortDirection: 'desc',
            ...listConfig,
        };
        return this.server.get(`/v1/algorithm/group`, listConfig);
    }
    getItem(id) {
        return this.server.get(`/v1/algorithm/group/${id}`);
    }
    createItem(payload) {
        return this.server.post(`/v1/algorithm/group`, payload);
    }
    copyItems(payload) {
        return this.server.put(`/v1/algorithm/group/copy`, payload);
    }
    updateItem(id, payload) {
        return this.server.put(`/v1/algorithm/group/${id}`, payload);
    }
    deleteItem(id) {
        return this.server.delete(`/v1/algorithm/group/${id}`, {});
    }
}
exports.HbAlgorithmBlockGroupApi = HbAlgorithmBlockGroupApi;
