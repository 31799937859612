import { ODButton, ODButtonTheme } from '@odc/od-react-belt'
import { BOOK } from '@storyplay/common'
import React, { useState } from 'react'
import Select from 'react-select'
import { Col, Row } from 'reactstrap'
import {
  GQLCharacterMigrationInput,
} from '../../@types/server'
import { Utils } from '../../utils/utils'
import { ODModal, ODModalSize } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

export interface ICharactersMigrationProps {
  name: string
}

interface ICharactersMigrationDialogProps {
  storyId: number
  charactersExist: string[]
  characterToMigrate: ICharactersMigrationProps[] // 신규로 추가되는 속성
  newScriptBook: Pick<BOOK, 'characters'> // 새롭게 파싱된 book 에서의 캐릭터 정보
  onClose: () => void
  onConfirm: (result: GQLCharacterMigrationInput) => void
}

export const CharactersMigrationDialog: React.FC<ICharactersMigrationDialogProps> = (
  props
) => {
  const { storyId, charactersExist, characterToMigrate, newScriptBook, onConfirm, onClose } = props
  const [result, setResult] = useState<GQLCharacterMigrationInput>(() => {
    return {
      storyId,
      mapping: []
    }
  })

  const handleConfirm = async () => {
    const isSetAll = result!.mapping.length === characterToMigrate.length
    if (!isSetAll) {
      Utils.showError(
        `입력되지 않은 캐릭터가 있습니다.`
      )
      return
    }
    onConfirm(result)
  }

  const handleResult = (from: string, to: string) => {
    const existIndex = result!.mapping.findIndex((r) => r.from === from)
    const mapping = result!.mapping
    if (existIndex > -1) {
      mapping[existIndex] = { from, to }
      setResult({
        storyId,
        mapping,
      })
    } else {
      mapping.push({ from, to })
      setResult({
        storyId,
        mapping,
      })
    }
  }

  const [characterOption, setCharacterOption] = React.useState<
    { label: string; value: string | undefined }[]
  >([])
  const newCharacters = Object.keys(newScriptBook.characters)
    .filter(chrName => !charactersExist.includes(chrName))
    .map(chrName => ({ label: chrName, value: chrName }))
  React.useEffect(() => {
    setCharacterOption(
      [...newCharacters, { label: '삭제되는 캐릭터', value: undefined }]
        .map(({ label, value }) => ({
          label,
          value,
        }))
    )
  }, [])

  const isLoading = result === null

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Normal}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="캐릭터 마이그레이션" onClose={onClose} />
        <ODModalBody>
          {isLoading && <span>불러오는 중...</span>}
          {!isLoading && (
            <>
            <Row style={{ marginBottom: 20 }}>
              <Col md={4}><b>(AS-IS)기존 캐릭터</b></Col>
              <Col md={8}><b>(TO-BE)변경되는 캐릭터</b></Col>
            </Row>
            {characterToMigrate.map((chr: ICharactersMigrationProps, index: number) => {
              return (
                <Row style={{ marginBottom: 20 }} key={index}>
                  <Col md={4}>{chr.name}</Col>
                  <Col md={8}>
                    <Select
                      key={`${index}_select`}  
                      isSearchable={true}
                      placeholder="바뀌는 캐릭터 이름 선택"
                      onChange={(v) => {
                        if (v) {
                          // @ts-ignore
                          handleResult(chr.name, v.value)
                        }
                      }}
                      options={characterOption}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 2 }),
                      }}
                    />
                  </Col>
                </Row>
              )
            })}
            </>
          )}
        </ODModalBody>
        {!isLoading && (
          <ODModalFooter>
            <ODButton
              fullWidth
              theme={ODButtonTheme.Primary}
              onClick={handleConfirm}
            >
              진행
            </ODButton>
          </ODModalFooter>
        )}
      </div>
    </ODModal>
  )
}
