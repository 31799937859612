"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALCreateNewStory = void 0;
const core_1 = require("@storyplay/core");
const server_schema_1 = require("../../../../@types/server.schema");
const autoLayout_1 = require("../../../../autoLayout");
const ActionChainBuilder_1 = require("../../../../autoLayout/builder/ActionChainBuilder");
const consts_1 = require("../../../../consts");
const validations_1 = require("../../../validations");
const studioTutorial_1 = require("../studioTutorial");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ALCreateNewStory {
    constructor(parentStore, openBGSelector) {
        this.isCreating = false;
        this.parentStore = parentStore;
        this.openBGSelector = openBGSelector;
    }
    getIsMobileSize() {
        return this.parentStore.rootStore.isMobileSize;
    }
    async submit(name, lastNameOfMainChar, firstNameOfMainChar, desc, genre, mainImageFile) {
        var _a;
        this.isCreating = true;
        if (await this.parentStore.rootStore.createNewStory(name, lastNameOfMainChar, firstNameOfMainChar, desc, genre, (_a = mainImageFile.fileToUpload) !== null && _a !== void 0 ? _a : undefined)) {
            await this.parentStore.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.CREATE_NEW_STORY);
            await this.parentStore.loadStories(this.parentStore.selectedTab, this.parentStore.page, this.parentStore.storiesSortingOptions.value, this.parentStore.filterInput.value);
            this.parentStore.rootStore.di.showMessage(trans('legacy_ALCreateNewStory_work_created')); // this.parentStore.rootStore.di.showMessage('작품이 생성되었습니다!')
            this.isCreating = false;
            return true;
        }
        this.isCreating = false;
        return false;
    }
    buildActionChain() {
        const fillMock = this.parentStore.rootStore.di.devFillMockData;
        const nameField = new ui_1.InputWithValidation('storyName', trans('legacy_ko_work_name'), // 작품명,
        trans('legacy_SPSCreateStoryModal_enter_work_name_at_least_two_letters'), // '작품명을 입력해주세요. (최소 두 글자 이상입니다!)',
        'text', fillMock ? trans('legacy_SPSCreateStoryModal_test') : '', // fillMock ? '테스트' : '',
        validations_1.StoryPropsInputValidation.name, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: ui_1.InputTheme.Default,
        })
            .setRequired(true)
            .setAutoFocus();
        nameField.setEditing(true);
        const genreField = new ui_1.SelectionInput('genre', trans('legacy_SPSCreateStoryModal_genre'), // '장르',
        server_schema_1.GQLCHALLENGE_STORY_GENRE.ROMANCE, core_1.storyUtils
            .getGenresByPlayType(core_1.GQLSTORY_PLAY_TYPE.Interactive)
            .map(item => ({
            name: core_1.STORY_GENRE_TRANSLATED[item],
            value: item,
        })), {
            noLabel: false,
        });
        genreField.setRequired(true);
        const lastNameOfMainCharField = new ui_1.InputWithValidation('lastNameOfMainChar', trans('legacy_ko_main_char_cast_def'), // 작품 주인공 이름 디폴트 (성),
        trans('legacy_SPSCreateStoryModal_max_40_chars'), // 최대 40자입니다.,
        'text', fillMock ? trans('legacy_SPSCreateStoryModal_seaweed') : '', // fillMock ? '김' : '',
        validations_1.StoryPropsInputValidation.defaultLastName, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: ui_1.InputTheme.Default,
        }).setRequired(true);
        const firstNameOfMainCharField = new ui_1.InputWithValidation('firstNameOfMainChar', trans('legacy_ko_main_char_name_def'), // 작품 주인공 이름 디폴트 (이름),
        trans('legacy_SPSCreateStoryModal_min_1_max_40_chars'), // '최소 1자, 최대 40자입니다.',
        'text', fillMock ? trans('legacy_SPSCreateStoryModal_palbong') : '', // fillMock ? '팔봉' : '',
        validations_1.StoryPropsInputValidation.defaultFirstName, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: ui_1.InputTheme.Default,
        }).setRequired(true);
        const descField = new ui_1.InputWithValidation('desc', trans('legacy_SPSCreateStoryModal_work_description'), // 작품 설명,
        trans('legacy_SPSCreateStoryModal_enter_content'), // 내용을 입력해주세요.',
        'textarea', fillMock ? trans('legacy_SPSCreateStoryModal_content') : '', // fillMock ? '내용입니다....' : '',
        null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            maxLength: 1000,
            showMaxLength: true,
        });
        const builder = new ActionChainBuilder_1.ActionChainBuilder('ALCreateNewStory', true);
        const coverImageField = new ui_1.FileInput('mainImage', 'mainImage', null, {
            theme: ui_1.FileTheme.StoryCoverImage,
            showLabel: false,
            aspectRatio: { w: 1, h: 1.4 },
            openBGSelector: field => {
                var _a;
                (_a = this.openBGSelector) === null || _a === void 0 ? void 0 : _a.call(this, field);
                this.parentStore.rootStore.autoLayoutManager.changeNoShowModal(true);
            },
        });
        const createBody = (modalBuilder) => {
            if (this.getIsMobileSize()) {
                modalBuilder.addRow(rowBuilder => {
                    rowBuilder.setClassName('w-full max-w-mini2 self-center');
                    rowBuilder.addCol(col => col.setSingleInput(coverImageField));
                });
            }
            else {
                modalBuilder.addRow(rowBuilder => {
                    rowBuilder.setClassName('w-full max-w-xs self-center');
                    rowBuilder.addCol(col => col.setSingleInput(coverImageField));
                });
            }
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(nameField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(genreField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(lastNameOfMainCharField));
                rowBuilder.addCol(col => col.setSingleInput(firstNameOfMainCharField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(descField));
            });
        };
        if (this.getIsMobileSize()) {
            builder.addMobileModal(modalBuilder => {
                modalBuilder.setIsFullScreen(true);
                modalBuilder.setTitle({
                    layoutType: autoLayout_1.RowType.MobileModalTitle,
                    title: trans('legacy_SPSCreateStoryModal_create_new_work'),
                    closeIconName: consts_1.SPImageName.IcnBtnClose,
                    fontSize: 'base',
                    onClose: () => modalBuilder.cancelAction(),
                });
                createBody(modalBuilder);
                modalBuilder.setFooter({
                    layoutType: autoLayout_1.RowType.ModalFooter,
                    buttons: [
                        {
                            theme: autoLayout_1.ButtonTheme.General,
                            text: trans('legacy_EditAuthorInfoPage_cancellation'),
                            paddingSize: autoLayout_1.ButtonPaddingSize.PY3,
                            className: 'w-full',
                            onClick: () => {
                                modalBuilder.cancelAction();
                            },
                        },
                        {
                            theme: autoLayout_1.ButtonTheme.Filled,
                            text: trans('legacy_ODSEventGuideModal_next'),
                            paddingSize: autoLayout_1.ButtonPaddingSize.PY3,
                            className: 'w-full',
                            onClick: async () => {
                                if (this.isCreating) {
                                    return;
                                }
                                if (await this.submit(nameField.value, lastNameOfMainCharField.value, firstNameOfMainCharField.value, descField.value, genreField.value, coverImageField)) {
                                    modalBuilder.finishAction(null);
                                }
                            },
                        },
                    ],
                });
            });
        }
        else {
            builder.addModal(modalBuilder => {
                modalBuilder.setTitle({
                    layoutType: autoLayout_1.RowType.ModalTitle,
                    title: trans('legacy_SPSCreateStoryModal_create_new_work'),
                    imageName: consts_1.SPImageName.Book,
                    guideEventName: studioTutorial_1.GA4_EVENT_NAME.CREATE_NEW_STORY,
                });
                createBody(modalBuilder);
                modalBuilder.setFooter({
                    layoutType: autoLayout_1.RowType.ModalFooter,
                    buttons: [
                        {
                            theme: autoLayout_1.ButtonTheme.General,
                            text: trans('legacy_EditAuthorInfoPage_cancellation'),
                            onClick: () => {
                                modalBuilder.cancelAction();
                            },
                        },
                        {
                            theme: autoLayout_1.ButtonTheme.Filled,
                            text: trans('legacy_ODSToBlock_make'),
                            onClick: async () => {
                                if (this.isCreating) {
                                    return;
                                }
                                if (await this.submit(nameField.value, lastNameOfMainCharField.value, firstNameOfMainCharField.value, descField.value, genreField.value, coverImageField)) {
                                    modalBuilder.finishAction(null);
                                }
                            },
                        },
                    ],
                });
            });
        }
        return builder.buildActionChain();
    }
}
exports.ALCreateNewStory = ALCreateNewStory;
