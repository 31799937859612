"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOChapter = void 0;
const core_1 = require("@storyplay/core");
const EventEmitter = require("events");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../changeOp");
const consts_1 = require("../../../../consts");
const errors_1 = require("../../../../errors");
const hellobot_storyplay_converter_1 = require("../../../../hellobot-storyplay-converter");
const models_1 = require("../../../../models");
const scripter_1 = require("../../../../scripter/scripter");
const finder_1 = require("../../finder");
const interface_1 = require("../../interface");
const modal_1 = require("../../modal");
const studioUrls_1 = require("../../studioUrls/studioUrls");
const storeUtils_1 = require("../../utils/storeUtils");
const Waiter_1 = require("../../utils/Waiter");
const validation_1 = require("../../validation");
const block_1 = require("../block");
const DOChoiceStore_1 = require("../choice/DOChoiceStore");
const chr_1 = require("../chr");
const instantTesting_1 = require("../instantTesting");
const statement_1 = require("../statement");
const studioTutorial_1 = require("../studioTutorial");
const ui_1 = require("../ui");
const ChapterStatsCalculator_1 = require("./ChapterStatsCalculator");
const convertApplyChapterServerError_1 = require("./convertApplyChapterServerError");
const convertChapterToBook_1 = require("./convertChapterToBook");
const DOChapterEditor_1 = require("./DOChapterEditor");
const FlowChartPositionCalculator_1 = require("./FlowChartPositionCalculator");
const IChapterStudioMetaData_1 = require("./IChapterStudioMetaData");
const IDOChapter_1 = require("./IDOChapter");
const ALChangeChapterModal_1 = require("./ALChangeChapterModal");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
// 자동 저장 간격: 5분
const AUTO_SAVE_TIME_IN_MS = 1000 * 60 * 5;
/**
 * Chapter domain object.
 */
class DOChapter {
    constructor(store, data, parentStory, chapterStudioMetaData) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.channel = new EventEmitter();
        this.flowElements = [];
        // gql 로부터 로드된 default ending. 실제 값은 computed defaultEndingCustomId 를 써야 한다.
        this._defaultEndingCustomId = null;
        this.publishedAt = null;
        this.willPublishAt = null;
        this.hasDraft = false;
        this.numBlocks = null;
        this.numChars = null;
        this.numSens = null;
        this.stats = null;
        this.numBlocksDraft = null;
        this.numCharsDraft = null;
        this.numSensDraft = null;
        this.statsDraft = null;
        this.hasSavedDraft = false;
        this.willFreeAtRaw = null;
        this.freedAtRaw = null;
        this.freeDependencyChapterIndex = null;
        this.freeDependencyPeriod = null;
        this.draft = null;
        this.epubFileLink = null;
        this.primaryProp = null;
        this.startingBlockId = '';
        // 현재 편집중인 블록들에 대한 editor.
        this.blockEditor = null;
        // 값이 존재하면, 해당 캐릭터를 팝업으로 편집중이라는 뜻
        this.chrEditor = null;
        this.lastValidationResults = [];
        this.endings = [];
        this._didInit = false;
        // isSaving = false
        // 수동 저장중인지
        this.isManualSaving = false;
        // 자동 저장중인지
        this.isAutoSaving = false;
        /**
         * 한 번에 하나의 변경점만 적용할 수 있도록 하기 위하여 현재 적용 중인 op 를 갖고 있는다.
         */
        this.opApplying = null;
        /**
         * 최초 로드이후 쌓인 변경점들
         */
        this.opQueue = [];
        /**
         * 무조건 쌓기만 하는 OpLog
         * @private
         */
        this.opLog = [];
        // 디버그용
        this.opBefore = null;
        // 디버그용
        this.opAfter = null;
        // 변경 사항이 있어서, 백업 번들을 저장해야 하는 상황인가?
        this.shouldSaveBackupBundle = false;
        // 서버에 저장되지 않은 항목이 존재하는가?
        this.hasLocalChanges = false;
        /**
         * redo-able op queue.
         */
        this.redoOpQueue = [];
        this.autoSaveTimeOut = null;
        this.store = store;
        this.data = data;
        this.story = parentStory;
        this.isAdult = data.isAdult;
        this.id = data.chapterId;
        this.name = data.name;
        this.chapterIndex = data.chapterIndex;
        this.publishedAt = data.publishedAt;
        this.customId =
            data.customId || store.rootStore.di.generateCustomChapterId();
        this._defaultEndingCustomId = (_b = (_a = data.defaultEnding) === null || _a === void 0 ? void 0 : _a.customId) !== null && _b !== void 0 ? _b : null;
        this.endings = ((_c = data.endings) !== null && _c !== void 0 ? _c : []).map(gqlEnding => parentStory.endingStore.merge(gqlEnding));
        this.chapterScript = (_d = data.chapterScript) !== null && _d !== void 0 ? _d : '';
        this.blockEditor = null; // new BlockEditorStore(this, this.parentChapter)
        this.instantTesting = new instantTesting_1.DOInstantTestingStore(this);
        this.blockStore = new block_1.BlockStore(store.rootStore, this);
        this.choiceStore = new DOChoiceStore_1.DOChoiceStore(store.rootStore, this);
        this.studioMetaData =
            (_e = chapterStudioMetaData !== null && chapterStudioMetaData !== void 0 ? chapterStudioMetaData : this.loadStudioMeta()) !== null && _e !== void 0 ? _e : (0, IChapterStudioMetaData_1.getDefaultChapterStudioMetaData)(); // 디폴트 데이터
        this.statsCalculator = new ChapterStatsCalculator_1.ChapterStatsCalculator(this);
        this.finder = new finder_1.Finder(this);
        this.draft = data.draft;
        this.epubFileLink = (_g = (_f = data.epubFile) === null || _f === void 0 ? void 0 : _f.link) !== null && _g !== void 0 ? _g : null;
        this.primaryProp = (_h = data.primaryProperty) !== null && _h !== void 0 ? _h : null;
        if ('hbExtensionData' in data) {
            this.hbExtensionData = data.hbExtensionData;
        }
        (0, mobx_1.makeObservable)(this, {
            isAdult: mobx_1.observable,
            name: mobx_1.observable,
            startingBlockId: mobx_1.observable,
            // blockEditing: observable,
            blockEditor: mobx_1.observable,
            blockStore: mobx_1.observable,
            chapterIndex: mobx_1.observable,
            chrEditor: mobx_1.observable,
            _defaultEndingCustomId: mobx_1.observable,
            endings: mobx_1.observable,
            chapterScript: mobx_1.observable,
            publishedAt: mobx_1.observable,
            willPublishAt: mobx_1.observable,
            lastValidationResults: mobx_1.observable,
            opApplying: mobx_1.observable,
            flowElements: mobx_1.observable,
            // isSaving: observable,
            hasDraft: mobx_1.observable,
            numBlocks: mobx_1.observable,
            numChars: mobx_1.observable,
            numSens: mobx_1.observable,
            stats: mobx_1.observable,
            numBlocksDraft: mobx_1.observable,
            numCharsDraft: mobx_1.observable,
            numSensDraft: mobx_1.observable,
            statsDraft: mobx_1.observable,
            hasLocalChanges: mobx_1.observable,
            isManualSaving: mobx_1.observable,
            isAutoSaving: mobx_1.observable,
            draft: mobx_1.observable,
            hasSavedDraft: mobx_1.observable,
            epubFileLink: mobx_1.observable,
            startingBlock: mobx_1.computed,
            storyId: mobx_1.computed,
            imageList: mobx_1.computed,
            sfxList: mobx_1.computed,
            characterList: mobx_1.computed,
            mainCharacter: mobx_1.computed,
            fileList: mobx_1.computed,
            previousChapter: mobx_1.computed,
            defaultEndingCustomId: mobx_1.computed,
            isApplyingChange: mobx_1.computed,
            publishState: mobx_1.computed,
            canPublish: mobx_1.computed,
            isSaving: mobx_1.computed,
        });
    }
    init() {
        var _a;
        try {
            if (this._didInit) {
                return;
            }
            this.loadBlocks();
            ((_a = this.data.choices) !== null && _a !== void 0 ? _a : []).forEach(gql => this.choiceStore.merge(gql));
            this._didInit = true;
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.error(ex);
            this.store.rootStore.setUnrecoverableError(new errors_1.SPCError(errors_1.ErrorCode.ErrorOnChapterLoad));
        }
    }
    get isHb() {
        return this.story.rootStore.serviceType === 'hb';
    }
    get didInit() {
        return this._didInit;
    }
    // 수동저장일 경우에만 에디터에서 로딩화면을 보여준다.
    get isSaving() {
        return this.isManualSaving && !this.isAutoSaving;
    }
    /**
     * {data} 를 읽어서 모든 블록들을 생성한다.
     * {forceRemake} 가 true 이면 blockStore 자체를 새롭게 만든다.
     */
    loadBlocks(forceRemake = false) {
        const data = JSON.parse(this.chapterScript);
        if (!this.hasDraft) {
            if (!data.hasOwnProperty('startingBlock')) {
                const startingId = 'AUTO_GEN_STARTING_BLOCK';
                data.startingBlock = startingId;
                if (!data.blocks) {
                    data.blocks = {};
                }
                if (!data.blocks[startingId]) {
                    data.blocks[startingId] = {
                        name: '자동 생성된 시작 블록',
                        statements: [],
                        isEndingBlock: false,
                    };
                }
            }
            (0, mobx_1.runInAction)(() => {
                this.startingBlockId = data.startingBlock;
            });
        }
        const blockStore = forceRemake
            ? new block_1.BlockStore(this.store.rootStore, this)
            : this.blockStore;
        if (!this.hasDraft) {
            Object.keys(data.blocks).forEach(blockId => {
                blockStore.merge(data.blocks[blockId], this);
            });
        }
        blockStore.all.forEach(b => b.onAllBlocksOfChapterLoaded());
        if (forceRemake) {
            (0, mobx_1.runInAction)(() => (this.blockStore = blockStore));
        }
        // 모든 블록이 로드되면, 블록의 위치를 설정한다.
        this.createFlowChartPositionCalculator();
        // draft 는 loadFromDraftBundle 함수에서 로드된 후 수행된다.
        if (!this.hasDraft) {
            this.reLayoutFlowChartElements();
        }
    }
    createFlowChartPositionCalculator() {
        (0, mobx_1.runInAction)(() => {
            this.flowChartPositionCalculator = new FlowChartPositionCalculator_1.FlowChartPositionCalculator(this.blockStore, this.studioMetaData.flowChart, uniqueId => {
                const find = this.blockStore.allNode.find(item => item.uniqueId === uniqueId);
                if (!find) {
                    return null;
                }
                return this.store.rootStore.di.renderFlowChartBlock(find);
            });
        });
    }
    /**
     * 이 챕터에 포함된 FlowChart element 들을 재계산한다.
     */
    reLayoutFlowChartElements() {
        const elements = this.flowChartPositionCalculator.setFlowChartNodePositions();
        (0, mobx_1.runInAction)(() => (this.flowElements = elements));
    }
    merge(data) {
        // 주의: 블록스토어 머지 기능은 없다. 따라서 merge 는 원하는대로 동작하지 않을 수 있다.
        const fields = [
            'name',
            'publishedAt',
            'willPublishAt',
            'updatedAt',
            'chapterIndex',
            'chapterScript',
            'customId',
            'numBlocks',
            'numChars',
            'numSens',
            'stats',
            'numBlocksDraft',
            'numCharsDraft',
            'numSensDraft',
            'statsDraft',
            'willFreeAtRaw',
            'freedAtRaw',
            'freeDependencyChapterIndex',
            'freeDependencyPeriod',
            'hbExtensionData',
            'isAdult',
        ];
        (0, mobx_1.runInAction)(() => {
            var _a;
            fields.forEach(name => {
                // @ts-ignore
                (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
            });
            (0, storeUtils_1.assignIf)(data, 'defaultEnding', v => { var _a; return (this._defaultEndingCustomId = (_a = v === null || v === void 0 ? void 0 : v.customId) !== null && _a !== void 0 ? _a : null); });
            (0, storeUtils_1.assignIf)(data, 'choices', v => v === null || v === void 0 ? void 0 : v.forEach((choice) => this.choiceStore.merge(choice)));
            (0, storeUtils_1.assignIf)(data, 'draft', v => {
                this.hasSavedDraft = !!v;
                this.draft = v;
            });
            (0, storeUtils_1.assignIf)(data, 'epubFile', v => {
                var _a;
                this.epubFileLink = (_a = v === null || v === void 0 ? void 0 : v.link) !== null && _a !== void 0 ? _a : null;
            });
            if (data.hasOwnProperty('endings')) {
                this.endings = ((_a = data.endings) !== null && _a !== void 0 ? _a : []).map(gql => this.story.endingStore.merge(gql));
            }
            if (data.hasOwnProperty('draft')) {
                if (this.hasDraft && !data.draft) {
                    queueMicrotask(() => {
                        this.loadBlocks(true);
                    });
                }
                this.hasDraft = data.draft !== null;
            }
        });
        return this;
    }
    startEditChapterDetail() {
        return new DOChapterEditor_1.DOChapterEditor(this);
    }
    openChangeChapterNameModal() {
        window.sessionStorage.setItem('chapterId', String(this.id));
        this.store.rootStore.autoLayoutManager.addActionChain(new ALChangeChapterModal_1.ALChangeChapterModal(this, this.story).buildActionChain());
    }
    setBlockEditing(block, scroll = true) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            try {
                if (((_b = (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.blocks) === null || _b === void 0 ? void 0 : _b[0]) === block) {
                    return;
                }
                this.blockEditor = new ui_1.BlockEditorStore(block, this);
                if (scroll) {
                    this.blockEditor.scrollToFirstLine(false);
                }
                this.channel.emit('blockSelected', block);
            }
            catch (ex) {
                this.store.rootStore.di.showError(ex, 'Error Type : 167');
            }
        });
    }
    setChrEditing(chr, createNew = false) {
        (0, mobx_1.runInAction)(() => {
            if (createNew) {
                this.chrEditor = new chr_1.DOChrEditStore(null, this.getStory());
            }
            else if (chr) {
                this.chrEditor = new chr_1.DOChrEditStore(chr, this.getStory());
            }
            else {
                this.chrEditor = null;
            }
        });
    }
    get startingBlock() {
        var _a;
        const block = (_a = this.blockStore.getById(this.startingBlockId)) !== null && _a !== void 0 ? _a : null;
        // 헬로우봇일때는 오류가나면 멈춤. 블록이 없는 알고리즘블록들이 있기 때문에 이렇게 해줘야함
        if (!block && !this.hbExtensionData) {
            throw new Error(`This chapter contains no starting block. Failed and escaping : ${this.startingBlockId}`);
        }
        return block;
    }
    get publishState() {
        if (this.publishedAt) {
            return IDOChapter_1.ChapterPublishState.Published;
        }
        else if (this.willPublishAt) {
            return IDOChapter_1.ChapterPublishState.WillPublish;
        }
        return IDOChapter_1.ChapterPublishState.NotPublished;
    }
    /**
     * 특정 아이디 등으로 정의된 리소스가 존재하는지 체크하고, 존재하는 경우 각 타입에 맞추어 결과를 반환한다.
     */
    async fetchResource(resourceType, id) {
        var _a;
        switch (resourceType) {
            case statement_1.ResourceType.EndingImage: {
                const endingImage = (_a = this.endings.find(v => v.name === id)) === null || _a === void 0 ? void 0 : _a.imageFile;
                if (endingImage) {
                    return {
                        status: statement_1.ResourceUploadState.Uploaded,
                        // @ts-ignore
                        result: {
                            uploaded: endingImage,
                        },
                        error: null,
                    };
                }
                return {
                    status: statement_1.ResourceUploadState.NoResource,
                    result: null,
                    error: null,
                };
            }
            default:
                throw new Error(`NIY fetchResource for type : ${resourceType}`);
        }
    }
    printLog() {
        // tslint:disable-next-line:no-console
        console.log(`==================== Printing server data json =============================`);
        // tslint:disable-next-line:no-console
        console.log({ data: this.data });
    }
    get storyId() {
        return this.data.storyId;
    }
    get imageList() {
        return this.fileList.filter(v => v.fileType === consts_1.StudioFileType.Image);
    }
    get sfxList() {
        return this.fileList.filter(v => v.fileType === consts_1.StudioFileType.SFX);
    }
    get characterList() {
        return this.getStory().characterList;
    }
    get mainCharacter() {
        return this.getStory().mainCharacter;
    }
    get fileList() {
        return this.getStory().fileList;
    }
    get chapterEndings() {
        return this.getStory().endingStore.allEndings.filter(v => v.chapterId === this.id && !v.isFinal);
    }
    get previousChapter() {
        return this.getStory().chapterStore.getPreviousChapterOf(this.id);
    }
    setPrimaryProp(prop) {
        (0, mobx_1.runInAction)(() => (this.primaryProp = prop));
    }
    getBlockNameWithoutConflict(name) {
        let n = name;
        for (let i = 0; i < 1000; i += 1) {
            if (!this.blockStore.getById(n)) {
                return n;
            }
            n = n + trans('legacy_DOChapter_copy');
        }
        throw new Error(trans('legacy_DOChapter_block_name_error'));
    }
    createNewBlock(name, withStartingBackground, uniqueId) {
        return this.applyChangesOnChapter({
            opType: changeOp_1.StudioChangeOpType.CreateBlock,
            target: changeOp_1.StudioChangeOpTarget.Chapter,
            name,
            startingBackground: withStartingBackground,
            uniqueId,
        }).catch();
    }
    async createNewBlockInternalOfHb(name) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const converter = new hellobot_storyplay_converter_1.HellobotToStoryplayConverter();
        const hbApi = this.store.rootStore.di.server;
        try {
            const res = await hbApi.hbClient.block.create({
                botId: this.story.storyId,
                groupId: this.id,
                title: name,
                type: 'general',
            });
            const listBlockMessage = await hbApi.hbClient.message.get((_a = res.id) !== null && _a !== void 0 ? _a : -1);
            const listItems = (_d = (_c = (_b = listBlockMessage.items) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.items) !== null && _d !== void 0 ? _d : [];
            const listUserMessages = ((_e = listBlockMessage.userMessage) !== null && _e !== void 0 ? _e : []);
            const blockDataToCreateDoBlock = converter.hbBlockAndMessagesToSpBlock({ ...res, sheetList: [{ id: (_g = (_f = listBlockMessage.items) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.sheetId }] }, [...listItems, ...listUserMessages]);
            const block = this.blockStore.merge(blockDataToCreateDoBlock, this, (_h = res.id) === null || _h === void 0 ? void 0 : _h.toString());
            return {
                reverse: {
                    opType: changeOp_1.StudioChangeOpType.RemoveBlock,
                    target: changeOp_1.StudioChangeOpTarget.Chapter,
                    blockToRemove: block,
                },
            };
        }
        catch (ex) {
            this.story.rootStore.showError(ex);
            return null;
        }
    }
    async createNewBlockInternalOfSp(name, withStartingBackground, uniqueId) {
        const block = this.blockStore.merge({
            isEndingBlock: false,
            name,
            statements: [],
        }, this, uniqueId);
        block.startingBackground = withStartingBackground;
        // 편의를 위해 기본 문장을 추가해준다.
        if (this.store.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.ADD_DEFAULT_TOAST_ON_BLOCK_CREATION)) {
            const st = new statement_1.DOSTToast({
                sourceLine: this.store.rootStore.di.generateSourceLine(),
                statementType: models_1.STATEMENT_TYPE.Toast,
                background: '',
                message: trans('legacy_DOChapter_block_value', { value: name }),
                toastOption: 0,
            }, block);
            (0, mobx_1.runInAction)(() => block.statements.push(st));
        }
        return {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.RemoveBlock,
                target: changeOp_1.StudioChangeOpTarget.Chapter,
                blockToRemove: block,
            },
        };
    }
    async createNewBlockInternal(name, withStartingBackground, uniqueId) {
        if (!!this.hbExtensionData) {
            return this.createNewBlockInternalOfHb(name);
        }
        else {
            return this.createNewBlockInternalOfSp(name, withStartingBackground, uniqueId);
        }
    }
    getBlockOptions(selectedBlockName, withStartingBackgroundOnCreate, onSelect, // 선택된 블록 (string = 신규 생성)
    noSubmit = false // 신규 생성시 블록을 바로 만들 것인가?
    ) {
        var _a;
        const options = this.blockStore.all.map(block => ({
            value: block,
            name: block.name,
            description: block.name,
        }));
        const value = (_a = options.find(v => { var _a; return ((_a = v.value) === null || _a === void 0 ? void 0 : _a.name) === selectedBlockName; })) === null || _a === void 0 ? void 0 : _a.value;
        return new ui_1.SelectionInput('blockOptions', trans('legacy_DOChapter_select_block'), value, options, {
            creatable: true,
            onChangeBeforeSubmit: (block) => {
                (0, mobx_1.runInAction)(() => {
                    if (block) {
                        onSelect === null || onSelect === void 0 ? void 0 : onSelect(block);
                    }
                });
            },
            onChange: (block) => {
                (0, mobx_1.runInAction)(() => {
                    if (block) {
                        onSelect === null || onSelect === void 0 ? void 0 : onSelect(block);
                    }
                });
            },
            onCreateBeforeSubmit: (name) => {
                if (noSubmit) {
                    onSelect === null || onSelect === void 0 ? void 0 : onSelect(name);
                    return;
                }
                this.createNewBlock(name, withStartingBackgroundOnCreate).then(() => {
                    const block = this.blockStore.getById(name);
                    if (block) {
                        (0, mobx_1.runInAction)(() => onSelect === null || onSelect === void 0 ? void 0 : onSelect(block));
                    }
                });
            },
            onCreate: (name) => {
                if (noSubmit) {
                    onSelect === null || onSelect === void 0 ? void 0 : onSelect(name);
                    return;
                }
                this.createNewBlock(name, withStartingBackgroundOnCreate).then(() => {
                    const block = this.blockStore.getById(name);
                    if (block) {
                        (0, mobx_1.runInAction)(() => onSelect === null || onSelect === void 0 ? void 0 : onSelect(block));
                    }
                });
            },
        });
    }
    /**
     * 이 챕터의 디폴트 엔딩의 id 를 반환한다.
     */
    get defaultEndingCustomId() {
        var _a, _b;
        return (_a = this._defaultEndingCustomId) !== null && _a !== void 0 ? _a : (_b = this.chapterEndings[0]) === null || _b === void 0 ? void 0 : _b.customId;
    }
    get serverId() {
        return this.id;
    }
    setDefaultEndingCustomId(endingId) {
        (0, mobx_1.runInAction)(() => (this._defaultEndingCustomId = endingId));
    }
    onSubmit(onResult) {
        (0, mobx_1.runInAction)(() => {
            this.lastValidationResults = this.validate();
            onResult(this.lastValidationResults.length > 0);
        });
    }
    clearValidationResults() {
        (0, mobx_1.runInAction)(() => (this.lastValidationResults.length = 0));
    }
    validate() {
        const results = (0, lodash_1.flatten)(this.blockStore.all.map(block => block.validate()));
        const statements = (0, lodash_1.flatten)(this.blockStore.all.map(b => b.statements));
        // 디폴트 엔딩이 없으면 오류.
        const defaultEndingId = this.defaultEndingCustomId;
        if (!statements.find(st => {
            if (st instanceof statement_1.DOSTEndingSummary ||
                st instanceof statement_1.DOSTFinalEndingSubBlock) {
                return st.endingId === defaultEndingId;
            }
            return false;
        })) {
            (0, mobx_1.runInAction)(() => (this._defaultEndingCustomId = null));
            results.push({
                type: validation_1.StudioValidationType.DefaultEndingDoesNotExist,
                source: this,
                severity: validation_1.StudioValidationSeverity.Error,
                stack: [],
                extra: {},
            });
        }
        let numBGMOn = 0;
        let numBGMOff = 0;
        statements.forEach(st => {
            if (st instanceof statement_1.DOSTBGMon) {
                numBGMOn += 1;
            }
            else if (st instanceof statement_1.DOSTBGMoff) {
                numBGMOff += 1;
            }
        });
        if (numBGMOn > 0 && numBGMOff === 0) {
            results.push({
                type: validation_1.StudioValidationType.BGMOffIsRequired,
                source: this,
                severity: validation_1.StudioValidationSeverity.Error,
                stack: [],
                extra: {},
            });
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    validateWithScripter(runScripterInDumbMode = false) {
        const ret = (0, convertChapterToBook_1.convertChapterToBook)(this, runScripterInDumbMode);
        if (ret.ex) {
            (0, mobx_1.runInAction)(() => {
                ret.logs.forEach(log => {
                    if (log.type === convertChapterToBook_1.ScriptParseMessageType.Error) {
                        this.lastValidationResults.push({
                            severity: validation_1.StudioValidationSeverity.Error,
                            type: validation_1.StudioValidationType.UnhandledScripterError,
                            stack: [],
                            source: this,
                            extra: { message: log.message },
                        });
                    }
                });
            });
        }
        return ret;
    }
    get validatorName() {
        return trans('legacy_DOChapter_chapter_value', {
            value: this.name,
        });
    }
    get cmdString() {
        return this.blockStore.all.map(v => v.cmdString).join('\n\n');
    }
    generateLines() {
        const orderedBlocksBy = this.flowElements
            .filter(item => {
            const block = this.blockStore.getById(item.data.nodeName);
            return (!!block &&
                'position' in item &&
                (block.blocksFrom.length > 0 || block.blocksTo.length > 0));
        })
            .sort((a, b) => {
            const aNode = a;
            const bNode = b;
            const aX = aNode.position.x;
            const bX = bNode.position.x;
            const yA = aNode.position.y;
            const yB = bNode.position.y;
            const isSameY = Math.abs(yB - yA) < 100;
            if (isSameY) {
                return aX - bX;
            }
            return yA - yB;
        })
            .map(item => this.blockStore.getById(item.data.nodeName));
        // flowElements 에서는 이어지지 않았던
        // 엔딩호환데이터블록들을 찾아서 푸시해줍니다.
        for (const block of this.blockStore.all) {
            const included = orderedBlocksBy.includes(block);
            if (!included) {
                orderedBlocksBy.push(block);
            }
        }
        return (0, lodash_1.flatten)(orderedBlocksBy.map(block => block.generateLines()));
    }
    generateSheetCSV() {
        const lines = this.generateLines();
        return (0, storeUtils_1.convertLinesToSheetCSV)(lines, true);
    }
    exportMetaDataUpdateActions() {
        const actions = (0, lodash_1.flatten)(this.blockStore.all.map(b => b.exportMetaDataUpdateActions()));
        return actions;
    }
    generateLinesWithStartingBlock() {
        // 첫 라인에 챕터 아이디를 추가하고 블록을 생성한다.
        const startingBlock = this.startingBlock;
        const lines = this.generateLines();
        lines.unshift({
            columns: [
                ...startingBlock.blockColumns(),
                startingBlock.startingBackground,
                scripter_1.INVERTED_STATEMENT_TYPE_MAP[models_1.STATEMENT_TYPE.ChapterId],
                '',
                this.customId,
            ],
            errors: [],
        });
        return lines;
    }
    async exportChapterSheet(sheetId, clearBeforeRun = false) {
        const di = this.store.rootStore.di;
        const story = this.getStory();
        if (story.isExporting) {
            di.showError(trans('legacy_DOChapter_current_export'));
            return;
        }
        (0, mobx_1.runInAction)(() => (story.isExporting = true));
        try {
            const lines = this.generateLinesWithStartingBlock();
            const metaDataUpdateActions = JSON.stringify(story.exportMetaDataUpdateActions(this));
            await di.server.exportStudioChapterAsSheet({
                chapterName: this.name,
                chapterCustomId: this.customId,
                sheetId,
                encodedLines: JSON.stringify(lines),
                metaDataUpdateActions,
                clearBeforeRun,
            });
        }
        catch (ex) {
            (0, mobx_1.runInAction)(() => (story.lastExportingError = ex));
        }
        finally {
            (0, mobx_1.runInAction)(() => (story.isExporting = false));
        }
    }
    removeBlock(block) {
        var _a;
        const removeOp = {
            opType: changeOp_1.StudioChangeOpType.BulkChange,
            changes: [],
        };
        this.blockStore.all.forEach(b => b.onBlockRemoved(block.id, removeOp.changes));
        (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.onBlockRemoved(block.id);
        removeOp.changes.push({
            opType: changeOp_1.StudioChangeOpType.RemoveBlock,
            target: changeOp_1.StudioChangeOpTarget.Chapter,
            blockToRemove: block,
        });
        this.applyChangesOnChapter(removeOp).catch();
    }
    onBlockNameChanged(prevName, name) {
        if (this.startingBlockId === prevName) {
            (0, mobx_1.runInAction)(() => (this.startingBlockId = name));
        }
    }
    getStory() {
        return this.story;
    }
    get isApplyingChange() {
        return !!this.opApplying;
    }
    /**
     * 이 챕터 내에서 발생한 undo 가 가능한 모든 변경점들을 기록하면서 적용한다.
     * 모든 챕터 내의 변경점은 이 함수에 대한 호출을 통해 거꾸로 전파되도록 구현되어야 한다.
     *
     * @param op 적용할 변경사항
     * @param type 이 op 가 어떻게 수행된 것인가?
     */
    async applyChangesOnChapter(op, type = changeOp_1.StudioChangeActionType.Normal) {
        if (this.isApplyingChange) {
            this.store.rootStore.di.showError(trans('legacy_DOChapter_existing_changes'));
            return;
        }
        const isHb = this.story.rootStore.serviceType === 'hb';
        const isHbAndUndoAndRedo = isHb &&
            [changeOp_1.StudioChangeActionType.Redo, changeOp_1.StudioChangeActionType.Undo].includes(type);
        this.shouldSaveBackupBundle = true;
        (0, mobx_1.runInAction)(() => (this.hasLocalChanges = true));
        // Single Change
        if (op.opType !== changeOp_1.StudioChangeOpType.BulkChange) {
            (0, mobx_1.runInAction)(() => (this.opApplying = op));
            const res = await this.applySingleChangeOp(op, type);
            (0, mobx_1.runInAction)(() => (this.opApplying = null));
            this.reLayoutFlowChartElements();
            // 헬봇에서 undo/redo 시에 api 요청 실패시 throw new Error 를 통해 변경점이 없다는 것을 알리기 위함
            if (isHbAndUndoAndRedo && !res) {
                throw new Error('api 요청 실패로 인해 변경점이 없습니다.');
            }
            return;
        }
        //
        // Bulk Changes
        //
        (0, mobx_1.runInAction)(() => (this.opApplying = op));
        const reverse = {
            opType: changeOp_1.StudioChangeOpType.BulkChange,
            changes: [],
        };
        let lineToFocus;
        let blockToFocus;
        for (const changeOp of op.changes) {
            const ret = await this.applyChangeOpInternal(changeOp, type);
            if (!ret) {
                continue;
            }
            if (ret.reverse.opType === changeOp_1.StudioChangeOpType.BulkChange) {
                ret.reverse.changes.forEach(change => {
                    reverse.changes.unshift(change);
                });
            }
            else {
                reverse.changes.unshift(ret.reverse);
            }
            if (ret.lineToFocus) {
                lineToFocus = ret.lineToFocus;
                blockToFocus = undefined;
            }
        }
        const reverseOp = {
            reverse,
            lineToFocus,
            blockToFocus,
        };
        // 헬봇에서 변경점이 없을때에 대한 처리, queue 에 쌓이지 않도록
        if (isHb && reverse.changes.length < 1) {
            (0, mobx_1.runInAction)(() => (this.opApplying = null));
            this.reLayoutFlowChartElements();
            // undo/redo 시에 api 요청 실패시 throw new Error 를 통해 변경점이 없다는 것을 알리기 위함
            if (isHbAndUndoAndRedo) {
                throw new Error('api 요청 실패로 인해 변경점이 없습니다.');
            }
            return;
        }
        switch (type) {
            case changeOp_1.StudioChangeActionType.Normal:
                this.opQueue.push({ ...op, reverseOp });
                this.redoOpQueue.length = 0; // clear redo queue.
                break;
            case changeOp_1.StudioChangeActionType.Undo:
                this.redoOpQueue.push({ ...op, reverseOp });
                break;
            case changeOp_1.StudioChangeActionType.Redo:
                this.opQueue.push({ ...op, reverseOp });
                break;
        }
        (0, mobx_1.runInAction)(() => (this.opApplying = null));
        this.reLayoutFlowChartElements();
        this.setAutoSave(true);
    }
    async applySingleChangeOp(op, type = changeOp_1.StudioChangeActionType.Normal) {
        const ret = await this.applyChangeOpInternal(op, type);
        if (ret) {
            // op queue 에는 앞에서 진행한 operation 들을 넣고, reverse op 를 함께 기록해 둔다.
            switch (type) {
                case changeOp_1.StudioChangeActionType.Normal:
                    this.opQueue.push({ ...op, reverseOp: ret });
                    this.redoOpQueue.length = 0; // clear redo queue.
                    break;
                case changeOp_1.StudioChangeActionType.Undo:
                    this.redoOpQueue.push({ ...op, reverseOp: ret });
                    break;
                case changeOp_1.StudioChangeActionType.Redo:
                    this.opQueue.push({ ...op, reverseOp: ret });
                    break;
            }
        }
        return ret;
    }
    async applyChangeOpInternal(op, type) {
        var _a, _b, _c, _d;
        (_a = this.opBefore) === null || _a === void 0 ? void 0 : _a.call(this, op);
        try {
            // tslint:disable-next-line:no-console
            // console.log('Applying', getMessageFromChangeOp(this, op))
            this.opLog.push(op);
            switch (op.target) {
                case changeOp_1.StudioChangeOpTarget.Block: {
                    const blockUniqueId = op.blockUniqueId;
                    // 블록에디터에 추가되지 않았으나, 편집중인 문장이 있으면 이것도 추가되어야 한다.
                    const editingLine = (_b = this.blockEditor) === null || _b === void 0 ? void 0 : _b.showEditModalFor;
                    if (!(editingLine instanceof statement_1.DOBlockHeadStatement) &&
                        (editingLine === null || editingLine === void 0 ? void 0 : editingLine.isScript)) {
                        const editing = this.blockEditor.showEditModalFor;
                        const st = editing;
                        if (st.parentBlock.uniqueId === blockUniqueId &&
                            op.hasOwnProperty('lineUniqueId') &&
                            op.lineUniqueId === st.uniqueId) {
                            return st.applyChangeOp(op, type);
                        }
                    }
                    const block = this.blockStore.getByUniqueId(blockUniqueId);
                    return (_c = (await (block === null || block === void 0 ? void 0 : block.applyChangeOp(op, type)))) !== null && _c !== void 0 ? _c : null;
                }
            }
            if (op.target === changeOp_1.StudioChangeOpTarget.Chapter) {
                return this.applyChangeOp(op, type);
            }
            return null;
        }
        finally {
            (_d = this.opAfter) === null || _d === void 0 ? void 0 : _d.call(this, op);
        }
    }
    async applyChangeOp(op, type) {
        if (op.target !== changeOp_1.StudioChangeOpTarget.Chapter) {
            return null;
        }
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.RemoveBlock: {
                if (this.isHb) {
                    const res = await this.blockStore.removeHbBlock(Number(op.blockToRemove.uniqueId));
                    if (!res) {
                        return null;
                    }
                }
                else {
                    this.blockStore.removeBlock(op.blockToRemove.id);
                }
                return {
                    reverse: {
                        opType: changeOp_1.StudioChangeOpType.AddBlock,
                        target: changeOp_1.StudioChangeOpTarget.Chapter,
                        blockToAdd: op.blockToRemove,
                    },
                    lineToFocus: op.blockToRemove.statements[0],
                };
            }
            case changeOp_1.StudioChangeOpType.AddBlock: {
                (0, mobx_1.runInAction)(() => this.blockStore.mergeBlock(op.blockToAdd));
                return {
                    reverse: {
                        opType: changeOp_1.StudioChangeOpType.RemoveBlock,
                        target: changeOp_1.StudioChangeOpTarget.Chapter,
                        blockToRemove: op.blockToAdd,
                    },
                };
            }
            case changeOp_1.StudioChangeOpType.CreateBlock: {
                return this.createNewBlockInternal(op.name, op.startingBackground, op.uniqueId);
            }
            case changeOp_1.StudioChangeOpType.ChangeFlowChartPosition: {
                const reverseOp = await this.setFlowChartPositionInternal(op);
                if (type !== changeOp_1.StudioChangeActionType.Normal) {
                    // Normal 인 경우에는 이미 계산된 상태로 넘어온 것이므로 따로 재계산을 하지 않는다.
                    this.reLayoutFlowChartElements();
                }
                return reverseOp;
            }
        }
    }
    /**
     * 되돌리기 수행. opQueue 에서 하나씩 꺼내서 역으로 적용한다.
     */
    async undo() {
        var _a;
        const op = this.opQueue.pop();
        if (!op) {
            return;
        }
        if (this.story.rootStore.serviceType === 'sp') {
            await this.applyChangesOnChapter(op.reverseOp.reverse, changeOp_1.StudioChangeActionType.Undo);
        }
        if (this.story.rootStore.serviceType === 'hb') {
            try {
                await this.applyChangesOnChapter(op.reverseOp.reverse, changeOp_1.StudioChangeActionType.Undo);
                // applyChangesOnChapter 에서 throw Error 가 됬다는 건 api 요청이 실패했다는 것이므로
                // 빼두었던 op 를 다시 푸시해줍니다.
            }
            catch (ex) {
                this.opQueue.push(op);
            }
        }
        const lineToFocus = op.reverseOp.lineToFocus;
        if (lineToFocus) {
            (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.scrollToGivenLine(lineToFocus);
        }
    }
    /**
     * Redo 수행. redoOpQueue 에서 최신것부터 적용한다.
     */
    async redo() {
        var _a;
        const op = this.redoOpQueue.pop();
        if (!op) {
            return;
        }
        if (this.story.rootStore.serviceType === 'sp') {
            await this.applyChangesOnChapter(op.reverseOp.reverse, changeOp_1.StudioChangeActionType.Redo);
        }
        if (this.story.rootStore.serviceType === 'hb') {
            try {
                await this.applyChangesOnChapter(op.reverseOp.reverse, changeOp_1.StudioChangeActionType.Redo);
                // applyChangesOnChapter 에서 throw Error 가 됬다는 건 api 요청이 실패했다는 것이므로
                // 빼두었던 op 를 다시 푸시해줍니다.
            }
            catch (ex) {
                this.redoOpQueue.push(op);
            }
        }
        const lineToFocus = op.reverseOp.lineToFocus;
        if (lineToFocus) {
            (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.scrollToGivenLine(lineToFocus);
        }
    }
    get snapshot() {
        return {
            startingBlock: this.startingBlockId,
            blocks: this.blockStore.all.map(b => b.snapshot),
            defaultEndingId: this._defaultEndingCustomId,
        };
    }
    getOpLogDebugMessage() {
        let logs = '';
        this.opLog.forEach(op => {
            logs += (0, changeOp_1.getMessageFromChangeOp)(this, op) + '\n';
        });
        return logs;
    }
    clearOpLog() {
        this.opLog.length = 0;
    }
    printOpLog() {
        // tslint:disable-next-line:no-console
        console.log(this.getOpLogDebugMessage());
    }
    debugOnEachOpLog(before, after) {
        this.opBefore = before;
        this.opAfter = after;
    }
    async update(changeSet) {
        const res = await this.story.rootStore.di.server.updateEntityForStudio(this, changeSet);
        return res;
    }
    setBlockFixedPosition(block, pos) {
        this.applyChangesOnChapter({
            opType: changeOp_1.StudioChangeOpType.ChangeFlowChartPosition,
            target: changeOp_1.StudioChangeOpTarget.Chapter,
            block,
            position: pos,
        }).catch();
    }
    resetBlockPositions() {
        const positions = this.flowChartPositionCalculator.saved.blockPosition;
        const op = new changeOp_1.StudioChangeOpFactory(this).startBulk();
        Object.keys(positions).forEach(blockId => {
            const block = this.blockStore.getById(blockId);
            if (block) {
                op.changeFlowChartPosition(block, null);
            }
        });
        op.submitBulk().catch();
    }
    setFlowChartPositionInternal(op) {
        const prevPos = (0, lodash_1.cloneDeep)(this.flowChartPositionCalculator.getFixedNodePosition(op.block));
        this.flowChartPositionCalculator.setFixedNodePosition(op.block, op.position);
        this.saveStudioMeta();
        return {
            reverse: {
                opType: changeOp_1.StudioChangeOpType.ChangeFlowChartPosition,
                target: changeOp_1.StudioChangeOpTarget.Chapter,
                block: op.block,
                position: prevPos,
            },
        };
    }
    get studioMetaConfigKey() {
        return `${interface_1.StudioConfigKey.FlowChartLayout}_${this.id}`;
    }
    saveStudioMeta() {
        this.story.rootStore.di.config.setConfig(this.studioMetaConfigKey, this.studioMetaData);
    }
    loadStudioMeta() {
        return this.story.rootStore.di.config.getConfig(this.studioMetaConfigKey, null);
    }
    serializeToBundle() {
        return {
            customId: this.customId,
            startingBlock: this.startingBlockId,
            blocks: this.blockStore.all.reduce((acc, block) => {
                acc[block.name] = block.serializeToBundle();
                return acc;
            }, {}),
            defaultEndingId: this._defaultEndingCustomId,
        };
    }
    get localBackupBundlePrefix() {
        return this.store.rootStore.di.prefixLocalBackupBundle;
    }
    removeBackupBundleOfLocalStorage() {
        this.store.rootStore.di.localStorage.removeItem((0, storeUtils_1.generateLSBackupBundleKey)(this.id, this.localBackupBundlePrefix));
        this.shouldSaveBackupBundle = false;
    }
    checkBackupBundleFromLocalStorage() {
        try {
            const data = this.store.rootStore.di.localStorage.getItem((0, storeUtils_1.generateLSBackupBundleKey)(this.id, this.localBackupBundlePrefix));
            if (!!data) {
                this.shouldSaveBackupBundle = true;
                const bundle = JSON.parse(data);
                this.store.rootStore.autoLayoutManager.addActionChain(new modal_1.ALTextModal(trans('legacy_DOChapter_load_temp_save'), trans('legacy_DOChapter_unsynced_temp_save'), trans('legacy_DOChapter_browser_saved_version'), async () => {
                    this.loadFromDraftBundle(bundle);
                    this.setBlockEditing(this.startingBlock);
                    return true;
                }, trans('legacy_DOChapter_server_saved_version'), () => {
                    if (!this.hasDraft) {
                        this.loadBlocks(true);
                        this.setBlockEditing(this.startingBlock);
                    }
                }).buildActionChain());
            }
        }
        catch (ex) {
            this.store.rootStore.showError(new errors_1.SPCError(errors_1.ErrorCode.ChapterBundleLoadFailed));
        }
    }
    saveBackupBundleToLocalStorage() {
        if (this.shouldSaveBackupBundle) {
            this.shouldSaveBackupBundle = false;
            this.store.rootStore.di.localStorage.setItem((0, storeUtils_1.generateLSBackupBundleKey)(this.id, this.localBackupBundlePrefix), JSON.stringify(this.serializeToBundle()));
        }
    }
    // 임시저장
    async saveDraft(onError) {
        const waiter = new Waiter_1.Waiter();
        try {
            if (this.isManualSaving) {
                // noinspection ExceptionCaughtLocallyJS
                throw new errors_1.SPCError(errors_1.ErrorCode.ChapterIsSavingAlready);
            }
            (0, mobx_1.runInAction)(() => {
                this.isManualSaving = true;
                this.isAutoSaving = false;
            });
            const calculatedStats = this.statsCalculator.calculateStats();
            await this.store.rootStore.di.server.upsertChapterDraft(this.id, this.serializeToBundle(), null, calculatedStats.numBlocks, calculatedStats.numChars, calculatedStats.numSens, calculatedStats.stats);
            (0, mobx_1.runInAction)(() => {
                this.hasLocalChanges = false;
                this.hasDraft = true;
                // saveDraft 는 따로 데이터를 갱신시키지 않기 때문에 직접 변경
                this.hasSavedDraft = true;
            });
            await this.story.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SAVE_CHAPTER);
            this.removeBackupBundleOfLocalStorage();
            return true;
        }
        catch (ex) {
            onError === null || onError === void 0 ? void 0 : onError(ex);
            this.store.rootStore.showError(ex);
            return false;
        }
        finally {
            await waiter.waitMinimum(700);
            this.setAutoSave(true);
            (0, mobx_1.runInAction)(() => {
                this.isManualSaving = false;
            });
        }
    }
    // 발행하기
    async applyDraft(onError) {
        const waiter = new Waiter_1.Waiter();
        try {
            this.onSubmit(() => null);
            (0, errors_1.ensureC)(this.lastValidationResults.filter(v => v.severity === validation_1.StudioValidationSeverity.Error).length === 0, errors_1.ErrorCode.CannotApplyScriptWhenValidationErrorExists);
            (0, errors_1.ensureC)(!this.isManualSaving, errors_1.ErrorCode.ApplyScriptInProgress);
            (0, mobx_1.runInAction)(() => (this.isManualSaving = true));
            const ret = this.validateWithScripter();
            if (!ret.json) {
                return false;
            }
            //
            // 스튜디오 확장 적용 기능 추가
            //
            // 최종화 엔딩 중에 이미지 변경점이 있는 항목은 엔딩에 해당 아이디를 추가해준다.
            const endingsToUploadImage = (0, lodash_1.flatten)(this.blockStore.all.map(b => b.statements.filter(s => s instanceof statement_1.DOSTFinalEndingSubBlock && s.uploadedImage)));
            let hasImageError = false;
            endingsToUploadImage.forEach(e => {
                var _a, _b, _c;
                const endingInfo = (_b = (_a = ret.book) === null || _a === void 0 ? void 0 : _a.endings) === null || _b === void 0 ? void 0 : _b[e.finalEnding.endingName];
                if (!endingInfo) {
                    hasImageError = true;
                    return;
                }
                endingInfo.studioImageId = (_c = e.uploadedImage) === null || _c === void 0 ? void 0 : _c.fileId;
            });
            if (hasImageError) {
                (0, mobx_1.runInAction)(() => {
                    this.lastValidationResults.push({
                        severity: validation_1.StudioValidationSeverity.Error,
                        type: validation_1.StudioValidationType.UnhandledScripterError,
                        stack: [],
                        source: this,
                        extra: {
                            message: trans('legacy_DOChapter_ending_image_fail'),
                        },
                    });
                });
                return false;
            }
            const calculatedStats = this.statsCalculator.calculateStats();
            // 서버에 변경사항 저장 + 적용 요청
            const res = await this.store.rootStore.di.server.upsertChapterDraft(this.id, this.serializeToBundle(), ret.book, calculatedStats.numBlocks, calculatedStats.numChars, calculatedStats.numSens, calculatedStats.stats);
            (0, mobx_1.runInAction)(() => (this.hasLocalChanges = false));
            // 대표속성 업데이트
            if (this.primaryProp) {
                await this.store.rootStore.di.server.updatePrimaryProperty(this.primaryProp.propId, true);
            }
            if (res.errorsOnApplying) {
                const results = JSON.parse(res.errorsOnApplying).map(err => (0, convertApplyChapterServerError_1.convertApplyChapterServerError)(err, this));
                (0, mobx_1.runInAction)(() => {
                    results.forEach(err => {
                        this.lastValidationResults.push({ ...err, stack: [], source: this });
                    });
                });
            }
            this.removeBackupBundleOfLocalStorage();
            // 적용이 완료되었을 경우에는 데이터베이스의 내용을 모두 반영해야 하므로 작품을 리로드한다.
            if (res.isApplied) {
                await this.store.rootStore.reloadEditor();
                return true;
            }
            return false;
        }
        catch (ex) {
            if (onError) {
                onError(ex);
            }
            else {
                this.store.rootStore.showError(ex);
            }
            return false;
        }
        finally {
            await waiter.waitMinimum(700);
            this.setAutoSave(true);
            (0, mobx_1.runInAction)(() => (this.isManualSaving = false));
        }
    }
    loadFromDraftBundle(bundle) {
        (0, mobx_1.runInAction)(() => {
            this.blockStore = new block_1.BlockStore(this.store.rootStore, this);
            const bs = this.blockStore;
            Object.values(bundle.blocks).forEach(blockBundle => {
                bs.mergeBundle(blockBundle, this);
            });
            this.startingBlockId = bundle.startingBlock;
            this._defaultEndingCustomId = bundle.defaultEndingId;
            this.hasDraft = true;
        });
        // 모든 블록이 로드되면, 블록의 위치를 설정한다.
        this.createFlowChartPositionCalculator();
        this.reLayoutFlowChartElements();
    }
    // 이전 회차가 발행 예약 또는 발행이 되었는지
    get isPreviousChapterPublished() {
        const previousChapter = this.previousChapter;
        if (!previousChapter) {
            return true;
        }
        const isPreviousChapterPublished = !!previousChapter.publishedAt;
        const isPreviousChapterWillPublished = !!previousChapter.willPublishAt;
        return isPreviousChapterPublished || isPreviousChapterWillPublished;
    }
    // 스크립트 오류가 없거나, 이전 회차가 발행 예약 또는 발행이 되어 있는 경우 발행 할 수 있다.
    get canPublish() {
        return (this.lastValidationResults.length === 0 && this.isPreviousChapterPublished);
    }
    async duplicateDraft() {
        try {
            await this.store.rootStore.di.server.duplicateChapterDraft(this.id);
            await this.store.rootStore.reloadEditor();
            return true;
        }
        catch (ex) {
            this.store.rootStore.di.showError(ex);
            return false;
        }
    }
    async removeDraft() {
        try {
            await this.store.rootStore.di.server.removeChapterDraft(this.id);
            if (!this.story.isWebNovel) {
                (0, mobx_1.runInAction)(() => (this.hasDraft = false));
                await this.store.rootStore.reloadEditor();
                queueMicrotask(() => this.loadBlocks(true));
            }
            else {
                (0, mobx_1.runInAction)(() => (this.draft = null));
            }
            return true;
        }
        catch (ex) {
            this.store.rootStore.showError(ex);
            return false;
        }
    }
    pushEditPageAndSetEditBlockBy(ending) {
        // Todo: 엔딩이 있는 블록을 바로 볼 수 있도록 하는 로직 필요
        this.story.rootStore.di.redirectToUrl(studioUrls_1.StudioUrls.Story.Detail.Chapter.Edit(this.story.storyId, ending.chapterId));
    }
    // noProcess: true 면 저장하지 않고 시간만 초기화 한다.
    setAutoSave(noProcess = false) {
        this.autoSaveProcess(this.opLog.length, noProcess).catch(null);
    }
    async autoSaveProcess(logCount, noProcess = false) {
        if (this.autoSaveTimeOut) {
            clearTimeout(this.autoSaveTimeOut);
        }
        if (this.isAutoSaving) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isAutoSaving = true;
        });
        const nowCount = this.opLog.length;
        try {
            // 쌓인 로그의 길이가 다르면 변경점이 있다고 판단하여 자동저장을 진행한다.
            if (nowCount !== logCount && !noProcess && !this.isManualSaving) {
                (0, mobx_1.runInAction)(() => {
                    this.isManualSaving = true;
                    this.isAutoSaving = true;
                });
                const calculatedStats = this.statsCalculator.calculateStats();
                await this.store.rootStore.di.server.upsertChapterDraft(this.id, this.serializeToBundle(), null, calculatedStats.numBlocks, calculatedStats.numChars, calculatedStats.numSens, calculatedStats.stats);
                (0, mobx_1.runInAction)(() => {
                    this.hasLocalChanges = false;
                    this.hasDraft = true;
                    // saveDraft 는 따로 데이터를 갱신시키지 않기 때문에 직접 변경
                    this.hasSavedDraft = true;
                });
                this.removeBackupBundleOfLocalStorage();
                this.store.rootStore.di.showMessage(trans('legacy_DOChapter_auto_save_temp'));
            }
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.error(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.isAutoSaving = false;
                this.isManualSaving = false;
            });
            this.autoSaveTimeOut = +setTimeout(() => {
                this.autoSaveProcess(nowCount);
            }, AUTO_SAVE_TIME_IN_MS);
        }
    }
    removeAutoSave() {
        if (this.autoSaveTimeOut) {
            clearTimeout(this.autoSaveTimeOut);
        }
    }
    //
    // IStudioFinderSource
    //
    get finderSourceName() {
        return `[Chapter] ${this.name}`;
    }
    getFindResults(keyword) {
        return (0, lodash_1.flatten)(this.blockStore.all.map(block => block.getFindResults(keyword)));
    }
    replaceTextWith(text, to) {
        this.blockStore.all.map(block => block.replaceTextWith(text, to));
    }
    selectBySearch() {
        // 챕터가 직접 선택될 일은 없다.
    }
    async addEndingWithIdForLegacy(endingId, endingName) {
        const rootStore = this.store.rootStore;
        const ending = this.endings.find(e => e.endingId === endingId);
        if (!ending) {
            rootStore.di.showError(trans('legacy_DOChapter_no_ending_id', {
                value: endingId,
            }));
            return;
        }
        const op = new changeOp_1.StudioChangeOpFactory(this).startBulk();
        const name = this.blockStore.getNewBlockId(trans('legacy_DOChapter_ending_data_block', {
            value: endingId,
        }));
        const blockUniqueId = rootStore.di.generateInternalHashId();
        const lineUniqueId = rootStore.di.generateInternalHashId();
        const subLinesUniqueIds = (0, lodash_1.range)(1, 3).map(() => rootStore.di.generateInternalHashId());
        op.createNewBlock(name, '', blockUniqueId);
        await op.submitBulk();
        // op 를 부득이하게 둘로 쪼게었다. op 하나에서 수행할 경우 최종화엔딩에서 서브블록 내의
        // 문장이 제대로된 형태로 추가되지 않아 알 수 없는 문제가 발생한다.
        // 예상컨데, 최종화엔딩서브블록안에 있어야 할 문장들이 다른 블록에 붙어있는 듯 하다.
        const block = this.blockStore.getById(name);
        const op2 = new changeOp_1.StudioChangeOpFactory(this).startBulk();
        op2.upsertNewEndBlock(block.uniqueId, ending.isFinal ? statement_1.EndBlockType.FinalEnding : statement_1.EndBlockType.ChapterEnding, lineUniqueId, subLinesUniqueIds);
        // 엔딩의 내용도 변경해 주도록 한다.
        if (ending.isFinal) {
            const sourceLine = rootStore.di.generateSourceLine();
            const d = {
                sourceLine,
                statementType: models_1.STATEMENT_TYPE.FinalEnding,
                endingId: ending.customId,
                endingName: ending.name,
                displayName: ending.displayName,
                background: '',
            };
            op2.replaceData({ block, uniqueId: subLinesUniqueIds[0] }, d);
            const sourceLine2 = rootStore.di.generateSourceLine();
            const d2 = {
                sourceLine: sourceLine2,
                statementType: models_1.STATEMENT_TYPE.CollectionDesc,
                message: ending.collectionDescription,
                background: '',
            };
            op2.replaceData({ block, uniqueId: subLinesUniqueIds[1] }, d2);
            // 엔딩이 디비에 이미 존재하기 때문에 최종화 이미지는 따로 설정하지 않아도 보여지는 듯 하다.
        }
        else {
            const sourceLine = rootStore.di.generateSourceLine();
            const d = {
                sourceLine,
                statementType: models_1.STATEMENT_TYPE.EndingSummary,
                endingId: ending.customId,
                endingName: ending.name,
                displayName: ending.displayName,
                background: '',
            };
            op2.replaceData({ block, uniqueId: lineUniqueId }, d);
        }
        await op2.submitBulk();
    }
    addHbAlgorithmBlocks(id, blocks) {
        const converter = new hellobot_storyplay_converter_1.HellobotToStoryplayConverter();
        // const hbApi = this.store.rootStore.di.server as HbStudioAPIServer
        // const listBlockMessage = await hbApi.hbClient.message.get(res.id ?? -1)
        const blockDataToCreateDoBlocks = blocks.map(block => converter.hbAlgorithmBlockToSpBlock(block));
        // const blockDataToCreateDoBlock = converter.hbAlgorithmBlockToSpBlock(
        //   blocks
        // )
        blockDataToCreateDoBlocks.map(data => {
            this.blockStore.merge(data, this, id);
        });
    }
    // 대사, 속마음의 등장인물 및 대사 속마음의 스토리게임 미리보기에 나오는 등장인물을 일괄 변경한다.
    replaceCharactersOnBubbleAndBackground(replaceChrs, onlyChangeBackgroundChr) {
        this.blockStore.all.forEach(block => {
            block.statements.forEach(st => {
                if (st instanceof statement_1.DOSTBubbleWithChrBase) {
                    for (const replaceChr of replaceChrs) {
                        if (onlyChangeBackgroundChr) {
                            st.replaceBackgroundChrNameIfMatches(replaceChr.oldChrName, replaceChr.newChrName);
                        }
                        else {
                            st.replaceChrNameIfMatches(replaceChr.oldChrName, replaceChr.newChrName);
                            st.replaceBackgroundChrNameIfMatches(replaceChr.oldChrName, replaceChr.newChrName);
                        }
                    }
                }
            });
        });
    }
}
exports.DOChapter = DOChapter;
