import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLRecommendationForFirstUser } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IRecommendationForFirstUserListableOption } from '../RecommendationForFirstUserCommon'

export function useRecommendationForFirstUserListDataLoader() {
  const { listRecommendationForFirstUser } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IRecommendationForFirstUserListableOption
    ): Promise<ODListableResponseType<GQLRecommendationForFirstUser>> {
      const r = await listRecommendationForFirstUser({
        page,
        pageSize: 100,
        ...options,
      })
      return r as ODListableResponseType<GQLRecommendationForFirstUser>
    },
    [listRecommendationForFirstUser]
  )
}
