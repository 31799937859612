import {
  StoryFreeAdOption,
  StoryFreeOption,
  StoryPaidOption,
  STUDIO_STORY_PRICE_SETTING_TYPE,
} from '@storyplay/core'
import React from 'react'

type StoryChapterCoinItemProps = {
  studioPriceSetting: string | null
}

export const StoryChapterCoinItem: React.FC<StoryChapterCoinItemProps> = (
  props
) => {
  const { studioPriceSetting } = props
  if (!studioPriceSetting) {
    return <></>
  }
  try {
    const data: StoryFreeOption | StoryFreeAdOption | StoryPaidOption =
      JSON.parse(studioPriceSetting)
    return (
      <>
        {data.type === STUDIO_STORY_PRICE_SETTING_TYPE.FREE && (
          <span>무료</span>
        )}
        {data.type === STUDIO_STORY_PRICE_SETTING_TYPE.FREE_AD && (
          <span>광고({data.chapterIndex}화 ~)</span>
        )}
        {data.type === STUDIO_STORY_PRICE_SETTING_TYPE.PAID && (
          <span>
            {data.coin}코인({data.chapterIndex}화 ~)
          </span>
        )}
      </>
    )
  } catch (e) {
    return <></>
  }
}
