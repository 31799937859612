import {
  ODEntityLabeled,
  ODEntityRaw,
  ODImageFileInput,
} from '@odc/od-react-belt'
import {
  GQLCHAPTER_COMMENT_TYPE,
  GQLChoice,
  GQLCHOICE_TYPE,
  GQLListableChoice,
  GQLChapterComment,
  GQLChapterCommentCreationByAdminInput,
  GQLChapterCommentUpdateByAdminInput,
  GQLListableUser,
  GQLListableUserInput,
  GQLOkResponse,
  GQLSingleIDInput,
  GQLStory,
} from '@storyplay/core'
import React from 'react'
import Select from 'react-select'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { ODBackRow } from '../../../components/ODBackRow'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import {
  useCounter,
  useODMutation,
  useODQuery,
} from '../../../context/ODCommon'
import {
  GQL_CREATE_CHAPTER_COMMENT_BY_ADMIN,
  GQL_DELETE_CHAPTER_COMMENT_BY_ADMIN,
  GQL_GET_ALL_CHAPTERS,
  GQL_GET_CHAPTER_COMMENT_BY_ADMIN,
  GQL_LIST_USERS,
  GQL_UPDATE_CHAPTER_COMMENT_BY_ADMIN,
} from '../../../hooks/gqls_admin'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { USER_PRIV, USER_PRIV_TO_STRING } from '../../../utils/constants'
import { Utils } from '../../../utils/utils'
import { ChapterCommentListableTable } from '../list/ChapterCommentListableTable'
import { ChapterCommentReportListableTable } from '../list/ChapterCommentReportListableTable'

type ChapterCommentEditContainerProps = {
  ccId: number | null
  storyId: number
}

type Entity = GQLChapterComment

// 필요시 작성
function getValidationSchema(
  values: Partial<
    GQLChapterCommentCreationByAdminInput | GQLChapterCommentUpdateByAdminInput
  >
) {
  return Yup.object().shape({})
}

export const ChapterCommentEditContainer: React.FC<
  ChapterCommentEditContainerProps
> = (props) => {
  const { ccId, storyId } = props
  const innerRef = React.useRef(null)
  const apiListUsers = useODQuery<
    Partial<GQLListableUserInput>,
    GQLListableUser
  >(GQL_LIST_USERS)
  const apiGetStory = useODMutation<GQLSingleIDInput, GQLStory>(
    GQL_GET_ALL_CHAPTERS
  )
  const apiCreate = useODMutation<
    Partial<GQLChapterCommentCreationByAdminInput>,
    Partial<Entity>
  >(GQL_CREATE_CHAPTER_COMMENT_BY_ADMIN)
  const apiUpdate = useODMutation<
    Partial<GQLChapterCommentUpdateByAdminInput>,
    Partial<Entity>
  >(GQL_UPDATE_CHAPTER_COMMENT_BY_ADMIN)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(
    GQL_GET_CHAPTER_COMMENT_BY_ADMIN
  )
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DELETE_CHAPTER_COMMENT_BY_ADMIN
  )
  const { listChoiceOfStory } = useStoryPlayAPIs()
  const [token] = useCounter()

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<
        | GQLChapterCommentCreationByAdminInput
        | GQLChapterCommentUpdateByAdminInput
      >
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (ccId) {
          return apiGet({ id: ccId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            chapterId: null,
            content: '',
            isHidden: false,
            isSpoiler: false,
            numOfLikes: 0,
            parentId: null,
            storyId: null,
            displayUserId: null,
            choiceId: null,
            type: GQLCHAPTER_COMMENT_TYPE.Text,
          }
        }

        return {
          chapterId: data.chapterId,
          content: data.content,
          isHidden: data.isHidden,
          isSpoiler: data.isSpoiler,
          numOfLikes: data.numOfLikes,
          chapter: data.chapter,
          choice: data.choice,
          author: data.author,
          parentId: null,
          storyId: data.storyId,
          choiceId: data.choiceId,
          choiceType: data.type,
          imageFile: data.imageFile,
        }
      },
      saveClientValueToServer: async (
        data: Partial<
          | GQLChapterCommentCreationByAdminInput
          | GQLChapterCommentUpdateByAdminInput
        >
      ) => {
        const { content, isSpoiler, parentId, createdAt, imageFile } =
          data as GQLChapterCommentCreationByAdminInput
        const chapterId = selectedChapterIdRef.current
        const displayUserId = selectedVirtualUserIdRef.current
        if (ccId) {
          await apiUpdate({ id: ccId, ...data })
          Utils.showSuccess('댓글이 수정되었습니다.', 'Success')
        } else {
          await apiCreate({
            storyId,
            chapterId,
            content,
            isSpoiler,
            parentId,
            displayUserId,
            createdAt,
            choiceId: selectedChoiceIdRef.current
              ? selectedChoiceIdRef.current
              : null,
            imageFile,
            type: selectedTypeRef.current
              ? selectedTypeRef.current
              : GQLCHAPTER_COMMENT_TYPE.Text,
          })
          Utils.showSuccess('댓글이 작성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.Edit(storyId, 8)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (ccId) {
          await apiRemove({ id: ccId })
          Utils.showSuccess('댓글이 삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.Edit(storyId, 8)
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [ccId, apiGet, storyId, apiUpdate, apiCreate, token, apiRemove]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<
        | GQLChapterCommentCreationByAdminInput
        | GQLChapterCommentUpdateByAdminInput
      >
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<
        | GQLChapterCommentCreationByAdminInput
        | GQLChapterCommentUpdateByAdminInput
      >
    >(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          Partial<
            | GQLChapterCommentCreationByAdminInput
            | GQLChapterCommentUpdateByAdminInput
          >
        >(options)
      ),
    [options]
  )

  const [virtualUsersOption, setVirtualUsersOption] = React.useState<
    { label: string; value: number }[]
  >([])
  const [chaptersSelectOption, setChaptersSelectOption] = React.useState<
    { label: string; value: number }[]
  >([])
  const selectedVirtualUserIdRef = React.useRef(0)
  const selectedChapterIdRef = React.useRef(0)
  const selectedChoiceIdRef = React.useRef(0)
  const selectedTypeRef = React.useRef<GQLCHAPTER_COMMENT_TYPE | null>(null)
  const [selectChoiceOptions, setSelectChoiceOptions] = React.useState<
    { label: string; value: number | null }[]
  >([])
  const [choiceTypeMap, setChoiceTypeMap] = React.useState<{
    [key: number]: GQLCHAPTER_COMMENT_TYPE
  }>({})
  const [chapterIdState, setChapterIdState] = React.useState<number>(0)

  React.useEffect(() => {
    apiListUsers({ priv: [USER_PRIV.Virtual], pageSize: 500 }).then((r) => {
      setVirtualUsersOption(
        r.list.map((user) => ({
          label: user.name!,
          value: user.userId,
        }))
      )
    })
  }, [apiListUsers])

  React.useEffect(() => {
    apiGetStory({ id: props.storyId }).then((r) => {
      setChaptersSelectOption(
        r.allChapters.map((e) => ({
          value: e.chapterId,
          label: e.name,
        }))
      )
    })
  }, [apiGetStory, props.storyId])

  React.useEffect(() => {
    listChoiceOfStory({
      chapterId: chapterIdState,
      page: 1,
      pageSize: 1000,
    }).then((result: GQLListableChoice) => {
      setSelectChoiceOptions([
        {
          label: '선택지 댓글아님 (회차 댓글)',
          value: null,
        },
        ...result.list
          .filter((choice: GQLChoice) => {
            return (
              choice.choiceType === GQLCHOICE_TYPE.UserInputChoice ||
              choice.showStatistics
            )
          })
          .map((choice) => ({
            label: `${choice.name}`,
            value: choice.choiceId,
          })),
      ])
      setChoiceTypeMap(
        result.list.reduce((acc, { choiceId, showStatistics, choiceType }) => {
          const type =
            choiceType === GQLCHOICE_TYPE.UserInputChoice
              ? GQLCHAPTER_COMMENT_TYPE.UserInputChoice
              : showStatistics
              ? GQLCHAPTER_COMMENT_TYPE.Statistics
              : GQLCHAPTER_COMMENT_TYPE.Text
          // @ts-ignore
          acc[choiceId] = type
          return acc
        }, {})
      )
    })
  }, [listChoiceOfStory, chapterIdState])

  const title = !ccId ? '댓글 작성' : '댓글 설정 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 8)} />
            <hr />
            {!!ccId && (
              <>
                <ODEntityLabeled name={'작성자'} label={'작성자'}>
                  <ODEntityRaw
                    name="author"
                    keyPath="author"
                    render={({ value }) => {
                      return (
                        <span>
                          {value
                            ? `${value.name}(${
                                USER_PRIV_TO_STRING[value.priv!]
                              })`
                            : '없음'}
                        </span>
                      )
                    }}
                  />
                </ODEntityLabeled>
                <ODEntityLabeled name={'회차 정보'} label={'회차 정보'}>
                  <ODEntityRaw
                    name="chapter"
                    keyPath="chapter"
                    render={({ value }) => {
                      return <span>{value ? value.name : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            {!!ccId && (
              <>
                <ODEntityLabeled name={'선택지 정보'} label={'선택지 정보'}>
                  <ODEntityRaw
                    name="choice"
                    keyPath="choice"
                    render={({ value }) => {
                      return <span>{value ? value.name : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            <ODEntityLabeled name={'선택지 이미지'} label={'선택지 이미지'}>
              <ODImageFileInput
                name="imageFile"
                height={200}
                width={200}
                keyPath="imageFile.link"
              />
            </ODEntityLabeled>
            <ODEntityInput
              name="content"
              label="내용"
              placeholder="내용을 입력하세요"
              inputType="textarea"
              inputProps={{ rows: 10, disabled: !!ccId }}
            />
            {!ccId && (
              <>
                <ODEntityLabeled name={'가상유저'} label={'가상유저'}>
                  <Select
                    isSearchable={true}
                    placeholder="가상유저 선택"
                    onChange={(v) => {
                      if (v) {
                        // ts-ignore 삭제
                        // @ts-ignore
                        selectedVirtualUserIdRef.current = v.value
                      }
                    }}
                    options={virtualUsersOption}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </ODEntityLabeled>

                <ODEntityLabeled name={'회차'} label={'회차'}>
                  <Select
                    isSearchable={true}
                    placeholder="회차 선택"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedChapterIdRef.current = v.value
                        // @ts-ignore
                        selectedChoiceIdRef.current = null
                        // @ts-ignore
                        selectedTypeRef.current = null
                        setChapterIdState(selectedChapterIdRef.current)
                      }
                    }}
                    options={chaptersSelectOption}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            {!ccId && !!chapterIdState && (
              <ODEntityLabeled name={'선택지 선택'} label={'선택지 선택'}>
                <Select
                  isSearchable={true}
                  placeholder="선택지 선택"
                  onChange={(v) => {
                    if (v) {
                      // @ts-ignore
                      selectedChoiceIdRef.current = v.value
                      if (selectedChoiceIdRef.current) {
                        selectedTypeRef.current =
                          choiceTypeMap[selectedChoiceIdRef.current]
                      }
                    }
                  }}
                  options={selectChoiceOptions}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 2 }),
                  }}
                />
              </ODEntityLabeled>
            )}

            {!!ccId && (
              <ODEntityInput
                name="numOfLikes"
                label="좋아요 수"
                placeholder="설정하고자 하는 좋아요 수를 입력하세요"
                inputType="number"
              />
            )}
            {!ccId && (
              <ODEntityInput
                label="작성 일자"
                name="createdAt"
                inputType="datetime-local"
                placeholder="작성 일자"
              />
            )}
            <ODEntityLabeled label="숨김?" name="isHidden">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isHidden_true"
                  name="isHidden"
                  keyPath="isHidden"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isHidden_false"
                  name="isHidden"
                  keyPath="isHidden"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="스포일러 포함?" name="isSpoiler">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isSpoiler_true"
                  name="isSpoiler"
                  keyPath="isSpoiler"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isSpoiler_false"
                  name="isSpoiler"
                  keyPath="isSpoiler"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <hr />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={ccId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
      {!!ccId && (
        <>
          <Card>
            <CardBody>
              <div style={{ marginTop: 0 }}>
                <h3>대댓글</h3>
                <p style={{ height: 10 }} />
                <ChapterCommentListableTable
                  storyId={storyId}
                  parentId={ccId}
                />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div style={{ marginTop: 0 }}>
                <h3>신고 내역</h3>
                <p style={{ height: 10 }} />
                <ChapterCommentReportListableTable commentId={ccId} />
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}
