import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../../base/useDetectOutsideClick'
import { ODFBuilder } from '../ODEntityEditorUIBuilder'
import { DragAndDropComponent } from '../ODListable/renderer/DragAndDropComponent'
import { ODF_IMAGE, ODF_TYPE } from './index'

type ODFImageProps = Omit<ODF_IMAGE, 'type'>

const Wrapper = styled.div`
  background-color: transparent;
  border: 1px dashed gray;
  cursor: pointer;
  position: relative;
`

const Img = styled.img`
  object-fit: cover;
`

export function ODFImage(props: ODFImageProps) {
  const formikContext = useFormikContext()

  const [isUploadViewOpen, setIsUploadViewOpen] = React.useState(false)
  const [blobUrl, setBlobUrl] = React.useState('')
  const dndRef = React.useRef<HTMLDivElement>(null)
  useDetectOutsideClick(() => setIsUploadViewOpen(false), dndRef, isUploadViewOpen)

  const { formKey, width, height, alt = '' } = props
  const { setFieldValue, values = {} } = formikContext || {}

  const imgProps = { width, height }

  const v: any = values
  const link: string | undefined = blobUrl || v[formKey]?.link

  const image = React.useMemo(() => {
    return link ? (
      <Img style={{ width, height }} alt={alt} src={link} {...imgProps} />
    ) : (
      <Img
        alt="empty"
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        style={{ width, height }}
        {...imgProps}
      />
    )
  }, [alt, link, width, height, imgProps])

  const handleChange = React.useCallback(
    (file: File, blobUrl: string) => {
      setBlobUrl(blobUrl)
      setFieldValue(formKey, file)
    },
    [formKey, setBlobUrl, setFieldValue]
  )

  // console.log(26, values, props, formKey)

  return (
    <Wrapper
      style={{ width, height, border: isUploadViewOpen ? '0' : undefined }}
      ref={dndRef}
      onDragEnter={() => setIsUploadViewOpen(true)}
      onClick={() => setIsUploadViewOpen(true)}
    >
      <DragAndDropComponent
        style={{ width, height }}
        isOpen={isUploadViewOpen}
        onChange={handleChange}
        onClose={() => setIsUploadViewOpen(false)}
        onDragLeave={() => setIsUploadViewOpen(false)}
        toggle={() => setIsUploadViewOpen((v) => !v)}
      />
      {image}
    </Wrapper>
  )
}

export function createODFImageFile(key: string, data: Omit<ODF_IMAGE, 'type'>) {
  return new ODFBuilder(key, { type: ODF_TYPE.IMAGE, ...data })
}
