import React from 'react'
import { Col, Input, Row } from 'reactstrap'
import { ODButton, ODButtonTheme } from '../../components/base/ODButton'
import { ODModal, ODModalSize } from '../../components/ODModal/ODModal'
import { ODModalBody } from '../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../components/ODModal/ODModalHeader'

interface IUserBanModalProps {
  infoForOpen: IUserBanModalInfoForOpen | null
}

export interface IUserBanModalInfoForOpen {
  userId: number
  onClose: () => void
  onConfirm: (reason: string) => Promise<void>
}

export const UserBanModal: React.FC<IUserBanModalProps> = (props) => {
  const { infoForOpen } = props
  const [reason, setReason] = React.useState('')

  React.useEffect(() => {
    return () => {
      setReason('')
    }
  }, [infoForOpen])

  return (
    <ODModal
      isOpen={!!infoForOpen}
      toggle={() => infoForOpen?.onClose()}
      size={ODModalSize.Normal}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="계정 정지" onClose={infoForOpen?.onClose} />
        <ODModalBody>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>계정 정지 사유</Col>
            <Col md={8}>
              <Input
                onChange={(e) => setReason(e.target.value)}
                value={reason}
                type="textarea"
              />
            </Col>
          </Row>
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            fullWidth
            theme={ODButtonTheme.Primary}
            onClick={() => infoForOpen?.onConfirm(reason)}
          >
            정지하기
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
