"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOHbSaveProp = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const Yup = require("yup");
const fields_1 = require("../../../../fields");
const SelectionFieldWithUI_1 = require("../../../../fields/SelectionFieldWithUI");
class DOHbSaveProp {
    constructor(parent, data) {
        this.propSelectionField = new SelectionFieldWithUI_1.SelectionFieldWithUI('propSelection', () => data.id, () => null, {
            selection: parent.block.parentChapter
                .getStory()
                .storyPropertyStore.all.map(item => ({
                name: item.name,
                value: item.propId,
                description: item.propType, // description 을 통해 string, number 타입을 판단
            })),
            options: {
                noLabel: true,
                placeholder: '속성 검색',
                required: true,
            },
        });
        this.compareValueField = new fields_1.TextFieldWithUI('compareValue', () => { var _a; return (_a = data.value) !== null && _a !== void 0 ? _a : ''; }, () => null, {
            validation: Yup.mixed().test('compareValue', 'compareValue is not valid', value => {
                return this.isValidCompareValue(value);
            }),
            placeholder: '속성값',
            options: {
                noLabel: true,
                required: true,
            },
        });
        (0, mobx_1.makeObservable)(this, {
            isValidPropId: mobx_1.computed,
            isValidAll: mobx_1.computed,
        });
    }
    isValidCompareValue(value) {
        if (!value.trim()) {
            return false;
        }
        const options = this.propSelectionField.input.options;
        const findProp = options.find(option => option.value === this.propSelectionField.value);
        if ((findProp === null || findProp === void 0 ? void 0 : findProp.description) === core_1.GQLSTORY_PROP_TYPE.STRING) {
            return !!this.compareValueField.value;
        }
        if ((findProp === null || findProp === void 0 ? void 0 : findProp.description) === core_1.GQLSTORY_PROP_TYPE.NUMBER) {
            return !isNaN(Number(this.compareValueField.value));
        }
        return false;
    }
    get isValidPropId() {
        return !!this.propSelectionField.value;
    }
    get isValidAll() {
        return [
            this.isValidCompareValue(this.compareValueField.input.value),
            this.isValidPropId,
        ].every(v => v);
    }
    getDataForSubmit() {
        return {
            id: this.propSelectionField.value,
            value: this.compareValueField.value,
        };
    }
}
exports.DOHbSaveProp = DOHbSaveProp;
