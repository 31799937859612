"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jointPointSymbols = void 0;
/**
 * 심볼 생성시에 인수를 넘기지 않아도 심볼 간의 구별에 아무 문제가 없습니다. 생성시에 넘기는 인수는 설명입니다.
 */
const jointPointSymbols = {
    DOSTBubbleBase: {
        applyChangeOp: Symbol('joint point symbol for DOSTBubbleBase.applyChangeOp'),
    },
    DOBlock: {
        applyChangeOp: Symbol('joint point symbol for DOBlock.applyChangeOp'),
    },
    DOSTToBlock: {
        changeToBlockInternal: Symbol('joint point symbol for DOSTBlock.changeToBlockInternal'),
    },
    DOSTMessageImage: {
        onChangeFile: Symbol('joint point symbol for DOSTMessageImage.onChangeFile'),
    },
    DOSTAlgorithm: {
        updateAlgorithmBlockGroup: Symbol('joint point symbol for updateAlgorithmBlockGroup'),
    },
    DOSTHbMessageNext: {
        updateLinkBlock: Symbol('joint point symbol for DOSTHbMessageNext updateLinkBlock'),
    },
};
exports.jointPointSymbols = jointPointSymbols;
