"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BLOCK_EMPTY = exports.BG_EMPTY = exports.COND_EMPTY = exports.ENDING_IMAGE_EMPTY = exports.ACHIEVEMENT_EMPTY = exports.ITEM_EMPTY = exports.PROP_EMPTY = exports.ConditionCategory = exports.ResourceUploadState = exports.ResourceType = exports.EDITOR_STATEMENT_TYPE = exports.EndBlockType = exports.DOSubBlockType = exports.SupportedExecuteConditionStatementTypes = exports.CommonPropOperation = exports.BlockTagType = exports.UILineType = void 0;
const models_1 = require("../../../../models");
var UILineType;
(function (UILineType) {
    UILineType["EditorLine"] = "EditorLine";
    UILineType["SingleLineScript"] = "SingleLineScript";
    UILineType["SubBlockScript"] = "SubBlockScript";
})(UILineType = exports.UILineType || (exports.UILineType = {}));
var BlockTagType;
(function (BlockTagType) {
    BlockTagType["NoTag"] = "NoTag";
    BlockTagType["Start"] = "Start";
    BlockTagType["ChapterEnding"] = "ChapterEnding";
    BlockTagType["FinalEnding"] = "FinalEnding";
    BlockTagType["ChatBotBlock"] = "ChatBotBlock";
})(BlockTagType = exports.BlockTagType || (exports.BlockTagType = {}));
/**
 * PROP_OPERATION, ITEM_OPERATION 을 동일하게 사용하는 속성으로..
 */
var CommonPropOperation;
(function (CommonPropOperation) {
    CommonPropOperation["INCREASE_NUMBER"] = "INCREASE_NUMBER";
    CommonPropOperation["SET_NUMBER"] = "SET_NUMBER";
    CommonPropOperation["CALCULATE"] = "CALCULATE";
    // SET_TEXT 는 PROP 에서만 사용하지만, 이걸 따로 빼자니 수정할 부분들이 많아 보인다.
    // 지금은 해당 enum 에 추가하고, 아이템에서는 사용하지 않는 것으로...
    CommonPropOperation["SET_TEXT"] = "SET_TEXT";
})(CommonPropOperation = exports.CommonPropOperation || (exports.CommonPropOperation = {}));
// 해당 배열의 값들로 문장 실행 조건을 지원하는지 판단합니다.
exports.SupportedExecuteConditionStatementTypes = [
    models_1.STATEMENT_TYPE.Script,
    models_1.STATEMENT_TYPE.FullWidthText,
    models_1.STATEMENT_TYPE.MainCharacterTalk,
    models_1.STATEMENT_TYPE.MainCharacterThink,
    models_1.STATEMENT_TYPE.CharacterTalk,
    models_1.STATEMENT_TYPE.CharacterThink,
    models_1.STATEMENT_TYPE.SaveProp,
    models_1.STATEMENT_TYPE.Toast,
    models_1.STATEMENT_TYPE.MessageImage,
    models_1.STATEMENT_TYPE.MainCharacterMessageImage,
    models_1.STATEMENT_TYPE.FullWidthImage,
    models_1.STATEMENT_TYPE.Vibration,
    models_1.STATEMENT_TYPE.SoundEffect,
    models_1.STATEMENT_TYPE.AchievementEvent,
    models_1.STATEMENT_TYPE.BGMon,
    models_1.STATEMENT_TYPE.BGMoff,
    models_1.STATEMENT_TYPE.UpdateItem,
    models_1.STATEMENT_TYPE.FullScreenEffectOn,
    models_1.STATEMENT_TYPE.FullScreenEffectOff,
    models_1.STATEMENT_TYPE.Pause,
];
var DOSubBlockType;
(function (DOSubBlockType) {
    DOSubBlockType["ConditionSubBlock"] = "ConditionSubBlock";
    DOSubBlockType["Choice"] = "Choice";
    DOSubBlockType["FinalEnding"] = "FinalEnding";
    DOSubBlockType["CallRemoteScript"] = "CallRemoteScript";
})(DOSubBlockType = exports.DOSubBlockType || (exports.DOSubBlockType = {}));
var EndBlockType;
(function (EndBlockType) {
    EndBlockType["Condition"] = "Condition";
    EndBlockType["Choice"] = "Choice";
    EndBlockType["ToBlock"] = "ToBlock";
    EndBlockType["ChapterEnding"] = "ChapterEnding";
    EndBlockType["FinalEnding"] = "FinalEnding";
    EndBlockType["CallRemoteScript"] = "CallRemoteScript";
    EndBlockType["HbMessageNext"] = "HbMessageNext";
    EndBlockType["HbMessageSelect"] = "HbMessageSelect";
    EndBlockType["HbMessageInput"] = "HbMessageInput";
    EndBlockType["HbMessageTarot"] = "HbMessageTarot";
    EndBlockType["HbMessageTemplate"] = "HbMessageTemplate";
    EndBlockType["HbMessageFeedback"] = "HbMessageFeedback";
})(EndBlockType = exports.EndBlockType || (exports.EndBlockType = {}));
var EDITOR_STATEMENT_TYPE;
(function (EDITOR_STATEMENT_TYPE) {
    EDITOR_STATEMENT_TYPE["CurrentLine"] = "CurrentLine";
    EDITOR_STATEMENT_TYPE["BlockHead"] = "BlockHead";
})(EDITOR_STATEMENT_TYPE = exports.EDITOR_STATEMENT_TYPE || (exports.EDITOR_STATEMENT_TYPE = {}));
var ResourceType;
(function (ResourceType) {
    ResourceType["EndingImage"] = "EndingImage";
})(ResourceType = exports.ResourceType || (exports.ResourceType = {}));
var ResourceUploadState;
(function (ResourceUploadState) {
    ResourceUploadState["Checking"] = "Checking";
    ResourceUploadState["NoResource"] = "NoResource";
    ResourceUploadState["Uploading"] = "Uploading";
    ResourceUploadState["Uploaded"] = "Uploaded";
    ResourceUploadState["Error"] = "Error";
})(ResourceUploadState = exports.ResourceUploadState || (exports.ResourceUploadState = {}));
var ConditionCategory;
(function (ConditionCategory) {
    ConditionCategory["Prop"] = "Prop";
    ConditionCategory["MainProp"] = "MainProp";
    ConditionCategory["Item"] = "Item";
    ConditionCategory["PreviousEnding"] = "PreviousEnding";
    ConditionCategory["PlayDataExpr"] = "PlayDataExpr";
})(ConditionCategory = exports.ConditionCategory || (exports.ConditionCategory = {}));
// 앱에서 오류는 나지 않으면서 선택되지 않은 prop 을 명시하기 위한 text
exports.PROP_EMPTY = '__no_prop__';
exports.ITEM_EMPTY = '__no_item__';
exports.ACHIEVEMENT_EMPTY = '__no_achievement__';
exports.ENDING_IMAGE_EMPTY = '__no_ending_image__';
exports.COND_EMPTY = '__no_cond__';
exports.BG_EMPTY = '__배경없음__';
exports.BLOCK_EMPTY = '';
