"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AEventFieldWithUI = void 0;
const SelectionFieldWithUI_1 = require("./SelectionFieldWithUI");
class AEventFieldWithUI extends SelectionFieldWithUI_1.SelectionFieldWithUI {
    constructor(store, fieldId, getter, setter, uiOptions = {}) {
        super(fieldId, getter, setter, {
            ...uiOptions,
            selection: store.generateOptions(),
        });
    }
}
exports.AEventFieldWithUI = AEventFieldWithUI;
