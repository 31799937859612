"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEATURE_FLAG = void 0;
var FEATURE_FLAG;
(function (FEATURE_FLAG) {
    FEATURE_FLAG["ADD_DEFAULT_TOAST_ON_BLOCK_CREATION"] = "ADD_DEFAULT_TOAST_ON_BLOCK_CREATION";
    FEATURE_FLAG["SHOW_DELETE_MODAL"] = "SHOW_DELETE_MODAL";
    FEATURE_FLAG["SAVE_FROM_STUDIO"] = "SAVE_FROM_STUDIO";
    FEATURE_FLAG["SEARCH_AND_REPLACE"] = "SEARCH_AND_REPLACE";
    FEATURE_FLAG["CHAT_BOT_BLOCK"] = "CHAT_BOT_BLOCK";
    FEATURE_FLAG["ADVANCED_FORMULA_IN_STATEMENT"] = "ADVANCED_FORMULA_IN_STATEMENT";
    FEATURE_FLAG["EXECUTE_CONDITION"] = "EXECUTE_CONDITION";
    FEATURE_FLAG["STORY_DASHBOARD"] = "STORY_DASHBOARD";
    FEATURE_FLAG["STORY_WEEKDAYS"] = "STORY_WEEKDAYS";
    FEATURE_FLAG["REMOTE_SCRIPT_CHAT_GPT"] = "REMOTE_SCRIPT_CHAT_GPT";
    FEATURE_FLAG["STORY_PROP_STRING"] = "STORY_PROP_STRING";
    FEATURE_FLAG["DIRECTION"] = "DIRECTION";
    FEATURE_FLAG["WEB_NOVEL"] = "WEB_NOVEL";
    FEATURE_FLAG["AI_BLOCK_GENERATOR"] = "AI_BLOCK_GENERATOR";
    FEATURE_FLAG["EDIT_ENDING_DISPLAY_NAME"] = "EDIT_ENDING_DISPLAY_NAME";
    FEATURE_FLAG["EDIT_ITEM_DISPLAY_NAME"] = "EDIT_ITEM_DISPLAY_NAME";
    FEATURE_FLAG["GLOBAL"] = "GLOBAL";
    FEATURE_FLAG["RICH_EDITOR"] = "RICH_EDITOR";
    FEATURE_FLAG["ADULT_CERTIFICATION"] = "ADULT_CERTIFICATION";
    FEATURE_FLAG["STORY_GAME"] = "STORY_GAME";
    FEATURE_FLAG["MANAGE_STORY_GAME_TABS"] = "MANAGE_STORY_GAME_TABS";
})(FEATURE_FLAG = exports.FEATURE_FLAG || (exports.FEATURE_FLAG = {}));
