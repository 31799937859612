import React from 'react'
import { IODRendererDef, IODRendererProps, ODRendererComponentType, ODRendererEventType } from './common'

export interface ODColorRendererComponentDef extends IODRendererDef {
  component: ODRendererComponentType.ColorRenderer
  eventKey: string
}

interface ODColorRendererProps<ParentContextType, OriginalValueType>
  extends IODRendererProps<string, ODColorRendererComponentDef, ParentContextType, OriginalValueType> {
  value: string // #ffffff
  def: ODColorRendererComponentDef
}

export const ODColorRenderer: React.FC<ODColorRendererProps<any, any>> = (props) => {
  const {
    def: { eventKey },
    value,
    context,
    originalValue,
  } = props

  const [changedValue, setChangedValue] = React.useState(value)

  const handleChange = (e: any) => {
    setChangedValue(e.target.value)
  }

  const handleBlur = () => {
    if (changedValue !== value) {
      context.onEvent?.(
        {
          type: ODRendererEventType.ChangeValue,
          eventKey,
          valueFrom: value,
          valueTo: changedValue,
        },
        { ...context.parentContext, renderedFromOriginalValue: originalValue }
      )
    }
  }

  React.useEffect(() => {
    setChangedValue(value)
  }, [value])

  return <input type="color" value={value} onChange={handleChange} onBlur={handleBlur} />
}

export const ODDefaultColorDef: ODColorRendererComponentDef = {
  component: ODRendererComponentType.ColorRenderer,
  eventKey: 'NotProvided',
}
