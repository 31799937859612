/**
 * ODListableHeader 는 ODListablePaginatedTable 에서 사용하기 위한 컴포넌트이다.
 * 향후 다양한 기능을 추가할 가능성이 있기 때문에 독자 컴포넌트로 분리한다.
 */
import React from 'react'
import { ODListableOption } from './ODListableContext'

export interface ODListableTableColumnHeaderDefinition<T, O extends ODListableOption> {
  id: string | number // ID to distinguish this <td /> from other <td />s. (or <th />
  title: string
  thClass?: string
  thStyle?: object
  hide?: boolean // if set to true, this column will not be evaluated.
}

interface Props<T, O extends ODListableOption> {
  fields: Array<ODListableTableColumnHeaderDefinition<T, O>>
}

export function ODListableHeaders<T, O extends ODListableOption>({ fields }: Props<T, O>) {
  return (
    <>
      {fields.map(
        ({ id, title, thClass = 'text-center', thStyle = {}, hide = false }) =>
          !hide && (
            <th key={id} className={thClass} style={thStyle}>
              {title}
            </th>
          )
      )}
    </>
  )
}
