"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTCallRemoteScript = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const validation_1 = require("../../../validation");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
class DOSTCallRemoteScript extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            scriptId: block.store.rootStore.di.generateCustomRemoteScriptId(),
            statementType: core_1.STATEMENT_TYPE.CallRemoteScript,
            remoteScriptType: core_1.REMOTE_SCRIPT_TYPE.HelloBot,
            provider: '',
            params: [],
            background: '',
        }, block, uniqueId);
        this.isDeletable = false;
        this.canBeCopied = false;
        (0, mobx_1.makeObservable)(this, {
        //
        });
    }
    changeBackgroundInScriptForm(showError = true) {
        if (this.st.remoteScriptType === core_1.REMOTE_SCRIPT_TYPE.ChatGPT &&
            !!this.st.scriptForm) {
            try {
                const parsed = JSON.parse(this.st.scriptForm);
                if (parsed.background !== this.background) {
                    const scriptForm = {
                        ...JSON.parse(this.st.scriptForm),
                        background: this.background,
                    };
                    (0, mobx_1.runInAction)(() => {
                        this.st.scriptForm = JSON.stringify(scriptForm);
                    });
                }
            }
            catch {
                if (showError) {
                    this.rootStore.showError('ChatGPT 답변 설정이 잘못되었습니다. 다시 설정해주세요.');
                }
            }
        }
    }
    serializeToBundle() {
        this.changeBackgroundInScriptForm();
        return super.serializeToBundle();
    }
    get st() {
        return this.data;
    }
    validate() {
        var _a;
        const res = [];
        this.changeBackgroundInScriptForm(false);
        if (this.st.remoteScriptType === core_1.REMOTE_SCRIPT_TYPE.HelloBot ||
            this.st.remoteScriptType === core_1.REMOTE_SCRIPT_TYPE.StoryPlay) {
            if (!this.st.provider) {
                res.push({
                    type: validation_1.StudioValidationType.CallRemoteScriptHasNoProvider,
                    extra: {},
                    severity: validation_1.StudioValidationSeverity.Error,
                    source: this,
                    stack: [],
                });
            }
        }
        if (this.st.remoteScriptType === core_1.REMOTE_SCRIPT_TYPE.HelloBot) {
            if (!((_a = this.st.params) === null || _a === void 0 ? void 0 : _a.slice()[0])) {
                res.push({
                    type: validation_1.StudioValidationType.CallRemoteScriptHasNoParam,
                    extra: {},
                    severity: validation_1.StudioValidationSeverity.Error,
                    source: this,
                    stack: [],
                });
            }
        }
        if (this.st.remoteScriptType === core_1.REMOTE_SCRIPT_TYPE.ChatGPT) {
            if (!this.st.prompt) {
                res.push({
                    type: validation_1.StudioValidationType.ChatGPTCallRemoteScriptHasNoPrompt,
                    extra: {},
                    severity: validation_1.StudioValidationSeverity.Error,
                    source: this,
                    stack: [],
                });
            }
            if (!this.st.scriptForm) {
                res.push({
                    type: validation_1.StudioValidationType.ChatGPTCallRemoteScriptHasNoScriptForm,
                    extra: {},
                    severity: validation_1.StudioValidationSeverity.Error,
                    source: this,
                    stack: [],
                });
            }
            if (!!this.st.scriptForm) {
                try {
                    const parsed = JSON.parse(this.st.scriptForm);
                    if (!parsed.background) {
                        res.push({
                            type: validation_1.StudioValidationType.ChatGPTCallRemoteScriptHasNoBackground,
                            extra: {},
                            severity: validation_1.StudioValidationSeverity.Error,
                            source: this,
                            stack: [],
                        });
                    }
                    if (parsed.sourceLine !== core_1.SCRIPT_FORM_SOURCE_LINE) {
                        res.push({
                            type: validation_1.StudioValidationType.ChatGPTCallRemoteScriptScriptFormInvalidSourceLine,
                            extra: {},
                            severity: validation_1.StudioValidationSeverity.Error,
                            source: this,
                            stack: [],
                        });
                    }
                    if (parsed.message !== core_1.SCRIPT_FORM_MESSAGE) {
                        res.push({
                            type: validation_1.StudioValidationType.ChatGPTCallRemoteScriptScriptFormInvalidMessage,
                            extra: {},
                            severity: validation_1.StudioValidationSeverity.Error,
                            source: this,
                            stack: [],
                        });
                    }
                    if (![
                        core_1.STATEMENT_TYPE.MainCharacterTalk,
                        core_1.STATEMENT_TYPE.MainCharacterThink,
                        core_1.STATEMENT_TYPE.CharacterTalk,
                        core_1.STATEMENT_TYPE.CharacterThink,
                        core_1.STATEMENT_TYPE.FullWidthText,
                        core_1.STATEMENT_TYPE.Script,
                    ].includes(parsed.statementType)) {
                        res.push({
                            type: validation_1.StudioValidationType.ChatGPTCallRemoteScriptScriptFormHasNoStatementType,
                            extra: {},
                            severity: validation_1.StudioValidationSeverity.Error,
                            source: this,
                            stack: [],
                        });
                    }
                    if ((parsed.statementType === core_1.STATEMENT_TYPE.MainCharacterTalk ||
                        parsed.statementType === core_1.STATEMENT_TYPE.MainCharacterThink ||
                        parsed.statementType === core_1.STATEMENT_TYPE.CharacterTalk ||
                        parsed.statementType === core_1.STATEMENT_TYPE.CharacterThink) &&
                        !parsed.chrName) {
                        res.push({
                            type: validation_1.StudioValidationType.ChatGPTCallRemoteScriptScriptFormTalkAndThinkHasNoChr,
                            extra: {},
                            severity: validation_1.StudioValidationSeverity.Error,
                            source: this,
                            stack: [],
                        });
                    }
                }
                catch {
                    res.push({
                        type: validation_1.StudioValidationType.ChatGPTCallRemoteScriptHasNoScriptForm,
                        extra: {},
                        severity: validation_1.StudioValidationSeverity.Error,
                        source: this,
                        stack: [],
                    });
                }
            }
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = res));
        return res;
    }
    /**
     * 이 문장에서 수정을 수행해도 감싸는 서브블록을 변경해야 한다.
     * 따라서 편의함수로 서브블록을 만들어둔다.
     */
    getCallRemoteScriptSubBlock() {
        return this.block.getMySubBlock(this);
    }
    generateSheetColumnsAfterBackground() {
        const generatedOption = (() => {
            var _a, _b;
            if (this.st.remoteScriptType === core_1.REMOTE_SCRIPT_TYPE.HelloBot) {
                return JSON.stringify({
                    remoteScriptType: core_1.REMOTE_SCRIPT_TYPE.HelloBot,
                    provider: this.st.provider,
                    params: (_b = (_a = this.st.params) === null || _a === void 0 ? void 0 : _a.map(item => JSON.parse(item))) !== null && _b !== void 0 ? _b : [],
                });
            }
            if (this.st.remoteScriptType === core_1.REMOTE_SCRIPT_TYPE.StoryPlay) {
                return JSON.stringify({
                    remoteScriptType: core_1.REMOTE_SCRIPT_TYPE.StoryPlay,
                    provider: this.st.provider,
                    params: [],
                });
            }
            if (this.st.remoteScriptType === core_1.REMOTE_SCRIPT_TYPE.ChatGPT) {
                const scriptForm = !!this.st.scriptForm
                    ? JSON.parse(this.st.scriptForm)
                    : '';
                return JSON.stringify({
                    remoteScriptType: core_1.REMOTE_SCRIPT_TYPE.ChatGPT,
                    provider: '',
                    params: [],
                    prompt: this.st.prompt,
                    scriptForm,
                });
            }
            return 'unknown REMOTE_SCRIPT_TYPE';
        })();
        return [this.st.scriptId, generatedOption];
    }
}
exports.DOSTCallRemoteScript = DOSTCallRemoteScript;
