import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { ODButton, ODButtonTheme } from '../../components/base/ODButton'
import { ODIcon, ODIcons } from '../../components/ODIcon'
import { ODModal, ODModalSize } from '../../components/ODModal/ODModal'
import { ODModalBody } from '../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../components/ODModal/ODModalHeader'

interface IShowShareKeyModalProps {
  message: string
  onClose: () => void
}

export const ShowShareKeyModal: React.FC<IShowShareKeyModalProps> = props => {
  const { message, onClose } = props
  return (
    <ODModal isOpen toggle={onClose} size={ODModalSize.Normal}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="공유키" onClose={onClose} headerBottomPadding={30}>
          <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 60 }} />
        </ODModalHeader>
        <ODModalBody>
          <Message>
            <Input type="textarea" value={message} />
          </Message>
        </ODModalBody>
        <ODModalFooter>
          <ODButton style={{ width: '100%' }} theme={ODButtonTheme.Primary} onClick={onClose}>
            닫기
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

const Message = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2f353a;
`
