import { ODListableResponseType } from '@odc/od-react-belt'
import { GQLChoice } from '@storyplay/common'
import React from 'react'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { ICTChoice } from '../ChoiceCommon'

export function useChoiceDetailDataLoader(choiceId: number) {
  const { getChoice } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ICTChoice
    ): Promise<ODListableResponseType<GQLChoice>> {
      const r = await getChoice({
        id: choiceId,
      })
      return {
        list: [r],
        page: 0,
        pageSize: 10,
        totalCount: 5,
      } as ODListableResponseType<GQLChoice>
    },
    [getChoice, choiceId]
  )
}
