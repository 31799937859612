"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2Store = void 0;
const mobx_1 = require("mobx");
const playerReport2IDE_1 = require("../playerReport2IDE");
const DOPlayerReport2_1 = require("./DOPlayerReport2");
class DOPlayerReport2Store {
    constructor(parentStory) {
        this.data = {};
        this.story = parentStory;
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    get rootStore() {
        return this.story.rootStore;
    }
    getById(prId) {
        return this.data[prId];
    }
    merge(data) {
        const id = data.prId;
        if (this.data[id]) {
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOPlayerReport2_1.DOPlayerReport2(this, data);
        }
        return this.data[id];
    }
    startEditing(prId) {
        return new playerReport2IDE_1.PlayerReport2IDE(this.story, prId);
    }
}
exports.DOPlayerReport2Store = DOPlayerReport2Store;
