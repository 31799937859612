import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLStoryProperty } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { COLORS } from '../../../styles/colors'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'
import { IStoryPropertyListableOption, StoryPropertyPrimaryKey } from '../StoryPropertyCommon'
import { useStoryPropertyListDataLoader } from './StoryPropertyListDataLoader'

interface IStoryPropertyListableTableProps {
  storyId: number
}

export const StoryPropertyListableTable: React.FC<IStoryPropertyListableTableProps> = (props) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<GQLStoryProperty, IStoryPropertyListableOption>()
  const [token] = useCounter()
  const dataLoader = useStoryPropertyListDataLoader(storyId)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[StoryPropertyPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox listableContext={Context} placeholder="이름으로 검색" style={searchBoxStyle} />
            {/* 현재 속성은 스프레드시트에 작성된 속성이 동기화 되는 형태이므로 생성버튼으로 생성하면 안됨 */}
            {/* <Link to={SiteUrls.Admin.Story.EditStoryProperty(storyId, 0)} style={{ textDecoration: 'none' }}>
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{ minWidth: 135, borderRadius: 5 }}
              >
                생성
              </ODButton>
            </Link> */}
          </div>
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>속성 아이디</th>
                <th>노출 우선순위</th>
                <th>속성명</th>
                <th>노출 속성명</th>
                <th>속성타입</th>
                <th>속성 최대값</th>
                <th>이미지파일</th>
                <th>핵심속성?</th>
              </tr>
            )}
            renderRow={(value: GQLStoryProperty, context) => {
              return (
                <HoverTR
                  key={value.propId}
                  onClick={() => setRedirect(SiteUrls.Admin.Story.EditStoryProperty(storyId, value.propId))}
                >
                  <td>{value.propId}</td>
                  <td>{value.showOrder}</td>
                  <td>{value.propNameRaw}</td>
                  <td>{value.displayPropName}</td>
                  <td>{Utils.formatStoryPropertyType(value.propType)}{value.unit ? `(${value.unit})`: ''}</td>
                  <td>{value.maxValue ?? '-'}</td>
                  <td>
                    {value.imageFile?.link ? (
                      <img src={value.imageFile!.link} width={100} height={100} alt="story property" />
                    ) : (
                      <span>미등록</span>
                    )}
                  </td>
                  <td>
                    {value.isMain ? (
                      <span style={{ fontWeight: 'bold', color: COLORS.Primary }}>Y</span>
                    ) : (
                      <span style={{ color: COLORS.GRAY600 }}>N</span>
                    )}
                  </td>
                </HoverTR>
              )
            }}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
