"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbGNBMenuManager = void 0;
const mobx_1 = require("mobx");
const _1 = require(".");
const consts_1 = require("../../../consts");
const contextmenu_1 = require("../contextmenu");
const entity_1 = require("../entity");
const finder_1 = require("../finder");
const hbStudioUrls_1 = require("../studioUrls/hbStudioUrls");
const studioUrls_1 = require("../studioUrls/studioUrls");
const IGNBModalManager_1 = require("./IGNBModalManager");
class HbGNBMenuManager {
    constructor(gnbStore) {
        // pc 사이즈의 GNB Menu 의 경우 headless UI 를 통해 상태관리가 되기 때문에
        // isOpen property 는 모바일 사이즈일 경우에만 사용합니다.
        this.isOpen = false;
        this.gnbStore = gnbStore;
        (0, mobx_1.makeObservable)(this, {
            isOpen: mobx_1.observable,
            setIsOpen: mobx_1.action,
            studioMenuTheme: mobx_1.computed,
            currentMenuItems: mobx_1.computed,
            me: mobx_1.computed,
            currentNavMenus: mobx_1.computed,
        });
    }
    get languageMenuItems() {
        return {
            menus: [],
        };
    }
    get me() {
        return this.gnbStore.rootStore.loginStore.user;
    }
    get di() {
        return this.gnbStore.rootStore.di;
    }
    get rootStore() {
        return this.gnbStore.rootStore;
    }
    get gnbModalManager() {
        return this.gnbStore.modalManager;
    }
    setIsOpen(isOpen) {
        this.isOpen = isOpen;
    }
    get studioMenuTheme() {
        if (this.gnbStore.currentLayout !== _1.GNBStyleLayout.EditChapter &&
            this.rootStore.isMobileSize) {
            return 'gray900';
        }
        return undefined;
    }
    get currentMenuItems() {
        const storyMenus = this.gnbStore.currentLayout === _1.GNBStyleLayout.EditChapter
            ? [this.storyMenus]
            : [];
        const storyNavMenus = !!this.rootStore.checkStore.currentBot &&
            this.rootStore.isMobileSize
            ? [this.currentNavMenus]
            : [];
        const divider = storyNavMenus.length > 0
            ? [
                {
                    type: contextmenu_1.StudioMenuType.Separator,
                    backgroundColor: 'black',
                },
            ]
            : [];
        return {
            menus: [
                ...storyNavMenus,
                ...storyMenus,
                ...divider,
                this.helpMenus,
                this.profileMenus,
            ],
            theme: this.studioMenuTheme,
        };
    }
    get currentNavMenus() {
        const currentBot = this.rootStore.checkStore
            .currentBot;
        if (!!currentBot) {
            return this.getStoryNavMenus(currentBot.id);
        }
        return this.getAdminNavMenus();
    }
    getAdminNavMenus() {
        return {
            type: contextmenu_1.StudioMenuType.Category,
            subMenus: [
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnMenuStory,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '작품관리',
                    href: studioUrls_1.StudioUrls.Admin.Story.Main,
                },
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnMenuAuthor,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '작가관리',
                    href: studioUrls_1.StudioUrls.Admin.Author.Main,
                },
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnMenuStats,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '통계관리',
                    href: studioUrls_1.StudioUrls.Admin.Stats.Main,
                },
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnReleaseVersion,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '버전관리',
                    href: studioUrls_1.StudioUrls.Admin.Version.Main,
                },
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnChatbot,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '챗봇관리',
                    href: studioUrls_1.StudioUrls.Admin.Chatbot.Main,
                },
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnMenuMore,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '서비스설정',
                    href: studioUrls_1.StudioUrls.Admin.Settings.Main,
                },
            ],
        };
    }
    getStoryNavMenus(storyId) {
        return {
            type: contextmenu_1.StudioMenuType.Category,
            subMenus: [
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnHbDashboard
                        : consts_1.SPImageName.IcnHbDashboard,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '챗봇 관리',
                    href: hbStudioUrls_1.HbStudioUrls.Bot.Detail.ManageStory.Main(storyId),
                },
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnHbSkillList
                        : consts_1.SPImageName.IcnHbSkillList,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '스킬 목록',
                    href: hbStudioUrls_1.HbStudioUrls.Bot.Detail.Skill.List(storyId),
                },
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnHbChatbotBlock
                        : consts_1.SPImageName.IcnHbChatbotBlock,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '대화 블록 관리',
                    href: hbStudioUrls_1.HbStudioUrls.Bot.Detail.BlockGroup.List(storyId),
                },
                {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnHbAlgorithmBlock
                        : consts_1.SPImageName.IcnHbAlgorithmBlock,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '알고리즘 블록 그룹',
                    href: hbStudioUrls_1.HbStudioUrls.Bot.Detail.AlgorithmBlockGroup.List(storyId),
                },
                // {
                //   type: StudioMenuType.LinkWithIcon,
                //   as: 'NavLink',
                //   icon: this.rootStore.isMobileSize
                //     ? SPImageName.IcnHbReportGroup
                //     : SPImageName.IcnHbReportGroup,
                //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
                //   text: '리포트 그룹',
                //   href: StudioUrls.Story.Detail.ManageEnding.List(storyId),
                // },
                // {
                //   type: StudioMenuType.LinkWithIcon,
                //   as: 'NavLink',
                //   icon: this.rootStore.isMobileSize
                //     ? SPImageName.IcnHbTeachConversation
                //     : SPImageName.IcnHbTeachConversation,
                //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
                //   text: '말 가르치기',
                //   href: StudioUrls.Story.Detail.Prop.List(storyId),
                // },
                // {
                //   type: StudioMenuType.LinkWithIcon,
                //   as: 'NavLink',
                //   icon: this.rootStore.isMobileSize
                //     ? SPImageName.IcnHbBeginConversation
                //     : SPImageName.IcnHbBeginConversation,
                //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
                //   text: '먼저 말 걸기',
                //   href: StudioUrls.Story.Detail.ManageItem.List(storyId),
                // },
                // {
                //   type: StudioMenuType.LinkWithIcon,
                //   as: 'NavLink',
                //   icon: this.rootStore.isMobileSize
                //     ? SPImageName.IcnHbUser
                //     : SPImageName.IcnHbUser,
                //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
                //   text: '사용자',
                //   href: StudioUrls.Story.Detail.PlayerReport.List(storyId),
                // },
                // {
                //   type: StudioMenuType.LinkWithIcon,
                //   as: 'NavLink',
                //   icon: this.rootStore.isMobileSize
                //     ? SPImageName.IcnHbAttribute
                //     : SPImageName.IcnHbAttribute,
                //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
                //   text: '속성',
                //   href: StudioUrls.Story.Detail.ManageMember.List(storyId),
                // },
                // {
                //   type: StudioMenuType.LinkWithIcon,
                //   as: 'NavLink',
                //   icon: SPImageName.IcnHbMatching,
                //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
                //   text: '매칭',
                //   href: StudioUrls.Story.Detail.Dashboard.Root(storyId),
                // },
                // {
                //   type: StudioMenuType.LinkWithIcon,
                //   as: 'NavLink',
                //   icon: this.rootStore.isMobileSize
                //     ? SPImageName.IcnHbStatistics
                //     : SPImageName.IcnHbStatistics,
                //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
                //   text: '통계',
                //   href: StudioUrls.Story.Detail.Etc.Main(storyId),
                // },
                // {
                //   type: StudioMenuType.LinkWithIcon,
                //   as: 'NavLink',
                //   icon: this.rootStore.isMobileSize
                //     ? SPImageName.IcnHbLibrary
                //     : SPImageName.IcnHbLibrary,
                //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
                //   text: '라이브러리',
                //   href: StudioUrls.Story.Detail.Etc.Main(storyId),
                // },
            ],
        };
    }
    // 채팅방 에디터 페이지에서 표시되는 메뉴들
    get storyMenus() {
        const storyEditing = this.rootStore.storyEditing;
        const chapterEditing = this.rootStore.chapterEditing;
        const menusByPathname = (() => {
            var _a;
            const result = [];
            if (!!((_a = this.me) === null || _a === void 0 ? void 0 : _a.canExportStory) ||
                this.rootStore.di.allowStudioExportExcelToAnyOne) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '엑셀 내보내기',
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnExportInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        if (!!chapterEditing && !(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isExporting)) {
                            if (!!(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isAutoExportEnabled)) {
                                storyEditing.resetAutoExport();
                                this.rootStore.showMessage('자동저장을 껐습니다.');
                            }
                        }
                        this.gnbModalManager.openModal({
                            type: IGNBModalManager_1.GNBModal.ExportToSheet,
                        });
                    },
                });
            }
            if (this.gnbStore.currentLayout === _1.GNBStyleLayout.EditChapter &&
                this.rootStore.isMobileSize) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '발행 하기',
                    textTheme: contextmenu_1.StudioMenuElementTheme.TextPrimary500,
                    icon: consts_1.SPImageName.IcnPublishInGNB,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.TextPrimary500,
                    onClick: async () => this.gnbStore.applyChapter(),
                });
            }
            return result;
        })();
        return {
            type: contextmenu_1.StudioMenuType.Category,
            text: this.rootStore.isMobileSize ? undefined : '작품',
            subMenus: [
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '회차 정보',
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnChapterInfoInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        this.gnbModalManager.openModal({
                            type: IGNBModalManager_1.GNBModal.ChapterInfo,
                        });
                    },
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '테스트 플레이 파라메터',
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnTestPlayInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        this.gnbStore.onStartingBlockForTestOfChapterEditing();
                    },
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    // Todo: 추후 바꾸기 기능 구현되면 "찾기/바꾸기"로 변경되어야함
                    text: '찾기',
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnSearchInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        var _a;
                        const chapterEditingBlockEditor = (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.blockEditor;
                        // menu 의 close event 보다 onClick 이벤트가 늦게 호출되도록 하여도,
                        // 가끔 focus, select 메서드보다 더 뒤에 늦게 실행되는 이벤트가 있기 때문에
                        // 검색 인풋의 focus 가 되지 않는 문제 해결을 위해
                        // 실행 시간을 늦추었습니다.
                        setTimeout(() => {
                            var _a, _b, _c;
                            if ((chapterEditingBlockEditor === null || chapterEditingBlockEditor === void 0 ? void 0 : chapterEditingBlockEditor.finder.mode) === finder_1.FinderMode.Find) {
                                (_a = chapterEditingBlockEditor.finderInputRef) === null || _a === void 0 ? void 0 : _a.focus();
                                (_c = (_b = chapterEditingBlockEditor.finderInputRef) === null || _b === void 0 ? void 0 : _b.select) === null || _c === void 0 ? void 0 : _c.call(_b);
                            }
                            else {
                                chapterEditingBlockEditor === null || chapterEditingBlockEditor === void 0 ? void 0 : chapterEditingBlockEditor.finder.setMode(finder_1.FinderMode.Find);
                            }
                        }, 150);
                    },
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '검수하기',
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnInspectInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        chapterEditing === null || chapterEditing === void 0 ? void 0 : chapterEditing.onSubmit(hasIssue => {
                            if (!hasIssue) {
                                this.rootStore.showMessage('발견된 검수 오류가 없습니다.');
                            }
                        });
                    },
                },
                ...menusByPathname,
                {
                    type: contextmenu_1.StudioMenuType.Separator,
                },
            ],
        };
    }
    get helpMenus() {
        var _a;
        const menusByPathname = (() => {
            const result = [];
            if (this.gnbStore.currentLayout === _1.GNBStyleLayout.EditChapter) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '키보드 단축키',
                    onClick: () => {
                        this.gnbModalManager.openModal({
                            type: IGNBModalManager_1.GNBModal.ShortcutInfo,
                        });
                        this.rootStore.studioTutorialStore
                            .markUserStudioTutorialProgress(entity_1.GA4_EVENT_NAME.SHOW_KEYBOARD_SHORTCUT)
                            .then();
                    },
                });
            }
            return result;
        })();
        const isMobileSizeAndNotLayoutEditChapter = this.gnbStore.currentLayout !== _1.GNBStyleLayout.EditChapter &&
            this.rootStore.isMobileSize;
        return {
            type: contextmenu_1.StudioMenuType.Category,
            text: '도움말',
            subMenus: [
                ...menusByPathname,
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '도움말 센터로 이동',
                    rightElement: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : consts_1.SPImageName.IcnPublishInGNB,
                    rightElementTheme: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : contextmenu_1.StudioMenuElementTheme.TextGray500,
                    onClick: () => window.open('https://thingsflow.gitbook.io/studio-guide', '_blank', 'noopener'),
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: 'Q&A',
                    rightElement: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : consts_1.SPImageName.IcnPublishInGNB,
                    rightElementTheme: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : contextmenu_1.StudioMenuElementTheme.TextGray500,
                    onClick: () => window.open('https://game.naver.com/lounge/storyplay/board/13', '_blank', 'noopener'),
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '새로운 기능',
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.ReleaseVersion),
                    rightElement: (_a = this.di.studioVersion) !== null && _a !== void 0 ? _a : '-',
                    rightElementTheme: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : contextmenu_1.StudioMenuElementTheme.TextGray500,
                },
                {
                    type: contextmenu_1.StudioMenuType.Separator,
                    backgroundColor: isMobileSizeAndNotLayoutEditChapter
                        ? 'black'
                        : undefined,
                },
            ],
        };
    }
    get profileMenus() {
        const menusByPathname = (() => {
            var _a, _b, _c;
            const result = [];
            const isAdminPage = this.gnbStore.currentLayout === _1.GNBStyleLayout.Admin;
            if (!!((_a = this.me) === null || _a === void 0 ? void 0 : _a.isSameOrGreaterThanAdmin) && !isAdminPage) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '어드민 페이지로 이동',
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.Admin.Root),
                });
            }
            if (!!((_b = this.me) === null || _b === void 0 ? void 0 : _b.isSameOrGreaterThanAdmin) && isAdminPage) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '스튜디오 페이지로 이동',
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.Story.List),
                });
            }
            if (!((_c = this.me) === null || _c === void 0 ? void 0 : _c.isSSOLogin)) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '비밀번호 변경',
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.ChangePassword),
                });
            }
            return result;
        })();
        return {
            type: contextmenu_1.StudioMenuType.Category,
            text: '프로필',
            subMenus: [
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '작가 정보 수정',
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.EditAuthorInfo),
                },
                ...menusByPathname,
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: '로그아웃',
                    onClick: () => this.rootStore.loginStore.logout(),
                },
            ],
        };
    }
}
exports.HbGNBMenuManager = HbGNBMenuManager;
// TextWithIcon 의 경우 모바일 사이즈가 아닐 경우
// 왼쪽 아이콘이 표시되면 안 됩니다.
function createIconOfTextWIthIcon(icon, isMobile) {
    return isMobile ? icon : undefined;
}
