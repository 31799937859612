"use strict";
/**
 * 권한과 관련된 설정이 변경될 것을 대비하여, 권한 json 의 버전을 관리한다.
 * 주석을 최대한 상세히 달도록 한다.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_STORY_USER_PERM_SCHEMA = exports.DEFAULT_ROLES_PER_STORY_PERM = exports.ACTION_STORY_ROLE_MATRIX = exports.STORY_USER_PRIV = exports.ACTION_ON_BOOK = exports.CURRENT_PERM_VERSION = exports.STORY_PERM_VERSION = void 0;
var STORY_PERM_VERSION;
(function (STORY_PERM_VERSION) {
    STORY_PERM_VERSION["V001_2022_0420"] = "V001_2022_0420";
})(STORY_PERM_VERSION = exports.STORY_PERM_VERSION || (exports.STORY_PERM_VERSION = {}));
exports.CURRENT_PERM_VERSION = STORY_PERM_VERSION.V001_2022_0420;
// 작품에 대한 작업 권한
var ACTION_ON_BOOK;
(function (ACTION_ON_BOOK) {
    ACTION_ON_BOOK["UpdateChapter"] = "UpdateChapter";
    ACTION_ON_BOOK["RemoveChapter"] = "RemoveChapter";
    ACTION_ON_BOOK["PublishChapter"] = "PublishChapter";
    ACTION_ON_BOOK["ModifyCharacter"] = "ModifyCharacter";
    ACTION_ON_BOOK["RemoveCharacter"] = "RemoveCharacter";
    ACTION_ON_BOOK["ModifyProperty"] = "ModifyProperty";
    ACTION_ON_BOOK["ModifyEnding"] = "ModifyEnding";
    ACTION_ON_BOOK["ModifyBackground"] = "ModifyBackground";
    ACTION_ON_BOOK["ViewStory"] = "ViewStory";
    ACTION_ON_BOOK["ViewChoice"] = "ViewChoice";
    ACTION_ON_BOOK["ViewBackground"] = "ViewBackground";
    ACTION_ON_BOOK["CreateChapterDraft"] = "CreateChapterDraft";
    ACTION_ON_BOOK["ViewChapterDraft"] = "ViewChapterDraft";
    ACTION_ON_BOOK["UpdateChapterDraft"] = "UpdateChapterDraft";
    ACTION_ON_BOOK["ManageStudioFiles"] = "ManageStudioFiles";
    ACTION_ON_BOOK["ViewPlayerReport2"] = "ViewPlayerReport2";
    ACTION_ON_BOOK["ModifyPlayerReport2"] = "ModifyPlayerReport2";
    ACTION_ON_BOOK["PublishPlayerReport2"] = "PublishPlayerReport2";
    // 추가된 권한
    ACTION_ON_BOOK["ManageMembers"] = "ManageMembers";
    ACTION_ON_BOOK["ViewMember"] = "ViewMember";
    ACTION_ON_BOOK["RemoveStory"] = "RemoveStory";
    ACTION_ON_BOOK["CreateTestSession"] = "CreateTestSession";
    ACTION_ON_BOOK["ViewTestSession"] = "ViewTestSession";
    ACTION_ON_BOOK["AttendContest"] = "AttendContest";
})(ACTION_ON_BOOK = exports.ACTION_ON_BOOK || (exports.ACTION_ON_BOOK = {}));
// 각 작품별 유저의 권한
var STORY_USER_PRIV;
(function (STORY_USER_PRIV) {
    STORY_USER_PRIV[STORY_USER_PRIV["Owner"] = 10] = "Owner";
    STORY_USER_PRIV[STORY_USER_PRIV["CoEditor"] = 5] = "CoEditor";
    STORY_USER_PRIV[STORY_USER_PRIV["Guest"] = 3] = "Guest";
    STORY_USER_PRIV[STORY_USER_PRIV["Pending"] = 1] = "Pending";
    STORY_USER_PRIV[STORY_USER_PRIV["Rejected"] = 0] = "Rejected";
})(STORY_USER_PRIV = exports.STORY_USER_PRIV || (exports.STORY_USER_PRIV = {}));
exports.ACTION_STORY_ROLE_MATRIX = {
    [ACTION_ON_BOOK.ManageMembers]: STORY_USER_PRIV.Owner,
    [ACTION_ON_BOOK.RemoveStory]: STORY_USER_PRIV.Owner,
    [ACTION_ON_BOOK.PublishPlayerReport2]: STORY_USER_PRIV.Owner,
    [ACTION_ON_BOOK.AttendContest]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.UpdateChapter]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.RemoveChapter]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.PublishChapter]: STORY_USER_PRIV.Owner,
    [ACTION_ON_BOOK.ModifyCharacter]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.RemoveCharacter]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ModifyProperty]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ModifyEnding]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ModifyBackground]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ViewChoice]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ViewBackground]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.CreateChapterDraft]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ViewChapterDraft]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.UpdateChapterDraft]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ManageStudioFiles]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ViewPlayerReport2]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ModifyPlayerReport2]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ViewMember]: STORY_USER_PRIV.CoEditor,
    [ACTION_ON_BOOK.ViewStory]: STORY_USER_PRIV.Guest,
    [ACTION_ON_BOOK.CreateTestSession]: STORY_USER_PRIV.Guest,
    [ACTION_ON_BOOK.ViewTestSession]: STORY_USER_PRIV.Guest,
};
/**
 * 개별 권한 레벨의 디폴트 권한이다. true 로 지정되지 않은 모든 권한은 미허용이다.
 */
exports.DEFAULT_ROLES_PER_STORY_PERM = {
    [STORY_USER_PRIV.Owner]: {
        [ACTION_ON_BOOK.ManageMembers]: true,
        [ACTION_ON_BOOK.RemoveStory]: true,
        [ACTION_ON_BOOK.ManageStudioFiles]: true,
        [ACTION_ON_BOOK.UpdateChapter]: true,
        [ACTION_ON_BOOK.RemoveChapter]: true,
        [ACTION_ON_BOOK.PublishChapter]: true,
        [ACTION_ON_BOOK.ModifyCharacter]: true,
        [ACTION_ON_BOOK.RemoveCharacter]: true,
        [ACTION_ON_BOOK.ModifyEnding]: true,
        [ACTION_ON_BOOK.ModifyBackground]: true,
        [ACTION_ON_BOOK.ViewChoice]: true,
        [ACTION_ON_BOOK.ViewBackground]: true,
        [ACTION_ON_BOOK.CreateChapterDraft]: true,
        [ACTION_ON_BOOK.ViewChapterDraft]: true,
        [ACTION_ON_BOOK.UpdateChapterDraft]: true,
        [ACTION_ON_BOOK.ViewMember]: true,
        [ACTION_ON_BOOK.ViewStory]: true,
        [ACTION_ON_BOOK.PublishPlayerReport2]: true,
        [ACTION_ON_BOOK.ViewPlayerReport2]: true,
        [ACTION_ON_BOOK.ModifyPlayerReport2]: true,
        [ACTION_ON_BOOK.ModifyProperty]: true,
        [ACTION_ON_BOOK.ManageStudioFiles]: true,
        [ACTION_ON_BOOK.CreateTestSession]: true,
        [ACTION_ON_BOOK.ViewTestSession]: true,
        [ACTION_ON_BOOK.AttendContest]: true,
    },
    [STORY_USER_PRIV.CoEditor]: {
        [ACTION_ON_BOOK.UpdateChapter]: true,
        [ACTION_ON_BOOK.RemoveChapter]: true,
        [ACTION_ON_BOOK.ModifyCharacter]: true,
        [ACTION_ON_BOOK.RemoveCharacter]: true,
        [ACTION_ON_BOOK.ModifyEnding]: true,
        [ACTION_ON_BOOK.ModifyBackground]: true,
        [ACTION_ON_BOOK.ViewChoice]: true,
        [ACTION_ON_BOOK.ViewBackground]: true,
        [ACTION_ON_BOOK.CreateChapterDraft]: true,
        [ACTION_ON_BOOK.ViewChapterDraft]: true,
        [ACTION_ON_BOOK.UpdateChapterDraft]: true,
        [ACTION_ON_BOOK.ViewMember]: true,
        [ACTION_ON_BOOK.ViewStory]: true,
        [ACTION_ON_BOOK.ModifyProperty]: true,
        [ACTION_ON_BOOK.ManageStudioFiles]: true,
        [ACTION_ON_BOOK.ViewPlayerReport2]: true,
        [ACTION_ON_BOOK.ModifyPlayerReport2]: true,
        [ACTION_ON_BOOK.CreateTestSession]: true,
        [ACTION_ON_BOOK.ViewTestSession]: true,
        [ACTION_ON_BOOK.AttendContest]: true,
    },
    [STORY_USER_PRIV.Guest]: {
        [ACTION_ON_BOOK.ViewStory]: true,
        [ACTION_ON_BOOK.CreateTestSession]: true,
        [ACTION_ON_BOOK.ViewTestSession]: true,
    },
    [STORY_USER_PRIV.Rejected]: {},
    [STORY_USER_PRIV.Pending]: {},
};
exports.DEFAULT_STORY_USER_PERM_SCHEMA = {
    version: exports.CURRENT_PERM_VERSION,
    overrides: {},
};
