import { ODListableOption } from '@odc/od-react-belt'
import { GQLStoryItem } from '../../@types/server'

export const StoryItemPrimaryKey = 'storyItemId'

// modifiable fields only.
export interface ICTStoryItem extends Omit<GQLStoryItem, 'storyItemId'> {
  storyItemId: number | undefined
}

export interface IStoryItemListableOption extends ODListableOption {
  filter: string
  storyId: number | null
}
