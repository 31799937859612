// ODF = OD-code Form Component
import { RS } from '../ODResources/ODResources'

export const ODF_SIZE_FILL_REMAINING = -1

export enum ODF_TYPE {
  LABEL = 'LABEL',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  IMAGE = 'IMAGE',
  DATETIME = 'DATETIME',
}

export type ODF_COMMON = {
  type: ODF_TYPE
  size: number
  labelKey: string
  readOnly?: boolean
}

export type ODF_LABEL = ODF_COMMON & {
  type: ODF_TYPE.LABEL
  text: RS
}

export type ODF_TEXT = ODF_COMMON & {
  type: ODF_TYPE.TEXT
  placeholder?: RS
  formKey: string
}

export type ODF_TEXTAREA = ODF_COMMON & {
  type: ODF_TYPE.TEXTAREA
  placeholder?: RS
  rows: number
  formKey: string
}

export type ODF_IMAGE = ODF_COMMON & {
  type: ODF_TYPE.IMAGE
  width: number
  height: number
  formKey: string
  alt?: string
}

export type ODF_DATETIME = ODF_COMMON & {
  type: ODF_TYPE.DATETIME
  formKey: string
}
