"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GNBStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const i18n_1 = require("../../i18n");
const entity_1 = require("../entity");
const interface_1 = require("../interface");
const studioUrls_1 = require("../studioUrls/studioUrls");
const GNBEventManager_1 = require("./GNBEventManager");
const GNBMenuManager_1 = require("./GNBMenuManager");
const GNBModalManager_1 = require("./GNBModalManager");
const IGNBModalManager_1 = require("./IGNBModalManager");
const IGNBStore_1 = require("./IGNBStore");
// Global Navigation Bar Store
class GNBStore {
    constructor(rootStore) {
        this.pathname = '';
        this.rootStore = rootStore;
        this.modalManager = new GNBModalManager_1.GNBModalManager(this);
        this.menuManager = new GNBMenuManager_1.GNBMenuManager(this);
        this.eventManager = new GNBEventManager_1.GNBEventManager(this);
        (0, mobx_1.makeObservable)(this, {
            pathname: mobx_1.observable,
            setPathname: mobx_1.action,
            currentLayout: mobx_1.computed,
            me: mobx_1.computed,
        });
    }
    get me() {
        return this.rootStore.loginStore.user;
    }
    get di() {
        return this.rootStore.di;
    }
    get featSavedEnabled() {
        return this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.SAVE_FROM_STUDIO);
    }
    get currentLayout() {
        if (this.pathname.startsWith(studioUrls_1.StudioUrls.ReleaseVersion)) {
            return IGNBStore_1.GNBStyleLayout.Release;
        }
        if (this.pathname.startsWith(studioUrls_1.StudioUrls.Admin.Root)) {
            if (this.pathname.startsWith(studioUrls_1.StudioUrls.Admin.ManageStoryGameTabs.Main)) {
                return IGNBStore_1.GNBStyleLayout.AdminManageStoryGameTabs;
            }
            return IGNBStore_1.GNBStyleLayout.Admin;
        }
        if (this.pathname === studioUrls_1.StudioUrls.Story.List) {
            return IGNBStore_1.GNBStyleLayout.StoryList;
        }
        const storyEditing = this.rootStore.storyEditing;
        const chapterEditing = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.chapterEditing;
        if (!!storyEditing &&
            this.pathname.startsWith(studioUrls_1.StudioUrls.Story.Detail.ManageStory.Main(storyEditing.storyId))) {
            return IGNBStore_1.GNBStyleLayout.StoryManage;
        }
        if (!!storyEditing &&
            this.pathname.startsWith(studioUrls_1.StudioUrls.Story.Detail.Dashboard.Story(storyEditing.storyId))) {
            return IGNBStore_1.GNBStyleLayout.StoryDashboard;
        }
        if (!!storyEditing &&
            this.pathname.startsWith(studioUrls_1.StudioUrls.Story.Detail.Dashboard.Chapter(storyEditing.storyId))) {
            return IGNBStore_1.GNBStyleLayout.ChapterDashboard;
        }
        if (!!storyEditing &&
            this.pathname.startsWith(studioUrls_1.StudioUrls.Story.Detail.Dashboard.PopularStory(storyEditing.storyId))) {
            return IGNBStore_1.GNBStyleLayout.PopularStoryDashboard;
        }
        if (!!storyEditing &&
            !!chapterEditing &&
            this.pathname.startsWith(studioUrls_1.StudioUrls.Story.Detail.Chapter.Edit(storyEditing.storyId, chapterEditing.id))) {
            return IGNBStore_1.GNBStyleLayout.EditChapter;
        }
        if (!!storyEditing &&
            this.pathname.startsWith(`/story/${storyEditing.storyId}/chapter/novel`)) {
            return IGNBStore_1.GNBStyleLayout.EditWebNovelChapter;
        }
        return IGNBStore_1.GNBStyleLayout.Common;
    }
    setPathname(location) {
        this.pathname = location;
    }
    onStartingBlockForTestOfChapterEditing() {
        var _a, _b, _c;
        (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.instantTesting.showVersionGuideModal((_c = (_b = this.rootStore.chapterEditing) === null || _b === void 0 ? void 0 : _b.startingBlock) !== null && _c !== void 0 ? _c : null);
    }
    saveChapter() {
        var _a;
        const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
        if (!this.featSavedEnabled) {
            return;
        }
        (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.saveDraft().then(() => this.rootStore.showMessage(trans('legacy_BlockEditorStore.ts_temporarily_saved')));
    }
    async applyChapter() {
        const chapterEditing = this.rootStore.chapterEditing;
        const success = await (chapterEditing === null || chapterEditing === void 0 ? void 0 : chapterEditing.applyDraft());
        const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
        if (success) {
            this.rootStore.showMessage(trans('legacy_GNBStore.ts_success_script_reflected'));
        }
        if (success && !!chapterEditing) {
            if (!(chapterEditing === null || chapterEditing === void 0 ? void 0 : chapterEditing.publishedAt) && !chapterEditing.willPublishAt) {
                this.modalManager.openModal({
                    type: IGNBModalManager_1.GNBModal.PublishForChapter,
                    info: {
                        onClose: () => this.modalManager.closeModal(),
                        onPublish: async (willPublishAt) => {
                            await this.publishChapter(chapterEditing, willPublishAt);
                        },
                    },
                });
            }
        }
    }
    async publishChapter(chapterEditing, willPublishAt) {
        const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
        try {
            await this.di.server.publishChapter({
                chapterId: chapterEditing.id,
                willPublishAt,
            });
            this.rootStore.showMessage(willPublishAt
                ? trans('legacy_useFunctionsInWebNovelChapterLIstPage_publish_reserved')
                : trans('legacy_useFunctionsInWebNovelChapterLIstPage_published'));
            this.modalManager.closeModal();
            await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(entity_1.GA4_EVENT_NAME.PUBLISH_CHAPTER);
            await chapterEditing.store.refreshById(chapterEditing.id);
            const shouldShowFreeSettingModal = ![
                core_1.StorySectionType.UGC,
                core_1.StorySectionType.Challenge,
            ].includes(chapterEditing.story.sectionType);
            if (shouldShowFreeSettingModal && !chapterEditing.story.isWebNovel) {
                this.modalManager.openModal({
                    type: IGNBModalManager_1.GNBModal.ChapterFreeSetting,
                    info: {
                        chapter: chapterEditing,
                        onClose: () => this.modalManager.closeModal(),
                    },
                });
            }
            return true;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            return false;
        }
    }
}
exports.GNBStore = GNBStore;
