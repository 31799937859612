import React from 'react'
import { AdminLogListContainer } from '../containers/adminLog/AdminLogListContainer'
import { AppConfigEditContainer } from '../containers/appConfig/AppConfigEditContainer'
import { AppConfigListContainer } from '../containers/appConfig/AppConfigListContainer'
import { AppSplashImageEditPageContainer } from '../containers/appSplashImage/detail/AppSplashImageEditPageContainer'
import { AppSplashImageListableTable } from '../containers/appSplashImage/list/AppSplashImageListableTable'
import { BonusTicketCodeEditContainer } from '../containers/bonusTicketCode/BonusTicketCodeEditContainer'
import { BonusTicketCodeListContainer } from '../containers/bonusTicketCode/BonusTicketCodeListContainer'
import { DirectAdsEditPageContainer } from '../containers/directAds/detail/DirectAdsEditPageContainer'
import { DirectAdsListableTable } from '../containers/directAds/list/DirectAdsListableTable'
import { EndingListContainer } from '../containers/ending/EndingListContainer'
import { EPUBStoryEditMainContainer } from '../containers/EPUBStory/EPUBStoryEditMainContainer'
import { EPUBStoryListContainer } from '../containers/EPUBStory/EPUBStoryListContainer'
import { FeaturedEditPageContainer } from '../containers/featured/detail/FeaturedEditPageContainer'
import { FeaturedListableTable } from '../containers/featured/list/FeaturedListableTable'
import { HomeSectionEditPageContainer } from '../containers/homeSection/detail/HomeSectionEditPageContainer'
import { HomeSectionListableTable } from '../containers/homeSection/list/HomeSectionListableTable'
import { ImageFileEditPage } from '../containers/imageFile/ImageFileEditPage'
import { ImageFileListContainer } from '../containers/imageFile/ImageFileListContainer'
import { NoticeEditContainer } from '../containers/notice/NoticeEditContainer'
import { NoticeListContainer } from '../containers/notice/NoticeListContainer'
import { NoticePopupEditContainer } from '../containers/noticePopup/NoticePopupEditContainer'
import { NoticePopupListContainer } from '../containers/noticePopup/NoticePopupListContainer'
import { RecommendationForFirstUserEditPageContainer } from '../containers/recommendationForFirstUser/detail/RecommendationForFirstUserEditPageContainer'
import { RecommendationForFirstUserListableTable } from '../containers/recommendationForFirstUser/list/RecommendationForFirstUserListableTable'
import { SFXFileEditPage } from '../containers/sfxFile/SFXFileEditPage'
import { SFXFileListContainer } from '../containers/sfxFile/SFXFileListContainer'
import { StoryEditMainContainer } from '../containers/story/StoryEditMainContainer'
import { StoryListContainer } from '../containers/story/StoryListContainer'
import { StoryOptionsContainer } from '../containers/storyOptions/StoryOptionsContainer'
import { UserEditContainer } from '../containers/user/UserEditContainer'
import { UserListContainer } from '../containers/user/UserListContainer'
import { UserMyProfileEditContainer } from '../containers/user/UserMyProfileEditContainer'
import { ODRoute } from '../context/ODCommon'
import { SiteUrls } from '../urls'
import { Utils } from '../utils/utils'

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const AdminRoutes: Array<ODRoute> = [
  {
    path: SiteUrls.Admin.User.List,
    exact: true,
    name: '사용자 관리',
    component: UserListContainer,
  },
  {
    path: SiteUrls.Admin.User.Create,
    exact: true,
    name: '신규 사용자',
    component: UserEditContainer,
  },
  {
    path: SiteUrls.Admin.User.EditMyProfile,
    exact: true,
    name: '내 정보 수정',
    component: UserMyProfileEditContainer,
  },
  {
    path: SiteUrls.Admin.User.Edit(':idEditing'),
    exact: true,
    name: '사용자 정보 수정',
    render: (props: any) => (
      <UserEditContainer userId={parseInt(props.match.params.idEditing, 10)} />
    ),
  },
  {
    path: SiteUrls.Admin.Story.Create,
    exact: true,
    name: '신규 인터랙티브 스토리',
    render: () => <StoryEditMainContainer storyId={null} />,
  },
  {
    path: Utils.urlOnly(SiteUrls.Admin.Story.Edit(':idEditing', 0)),
    exact: true,
    name: '인터랙티브 스토리 편집',
    render: (props: any) => (
      <StoryEditMainContainer
        storyId={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.Admin.Story.List.Root,
    exact: true,
    name: '인터랙티브 스토리 관리',
    component: StoryListContainer,
  },
  {
    path: SiteUrls.Admin.EPUBStory.List.Root,
    exact: true,
    name: '웹소설 스토리 관리',
    component: EPUBStoryListContainer,
  },
  {
    path: SiteUrls.Admin.EPUBStory.Create,
    exact: true,
    name: '신규 웹소설 스토리',
    render: () => <EPUBStoryEditMainContainer storyId={null} />,
  },
  {
    path: Utils.urlOnly(SiteUrls.Admin.EPUBStory.Edit(':idEditing', 0)),
    exact: true,
    name: '웹소설 스토리 편집',
    render: (props: any) => (
      <EPUBStoryEditMainContainer
        storyId={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.Admin.Notice.List,
    exact: true,
    name: '공지사항 관리',
    component: NoticeListContainer,
  },
  {
    path: SiteUrls.Admin.Notice.Create,
    exact: true,
    name: '신규 공지사항',
    component: NoticeEditContainer,
  },
  {
    path: SiteUrls.Admin.Notice.Edit(':idEditing'),
    exact: true,
    name: '공지사항 수정',
    render: (props: any) => (
      <NoticeEditContainer
        idEditing={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.Admin.NoticePopup.List,
    exact: true,
    name: '팝업 공지사항 관리',
    component: NoticePopupListContainer,
  },
  {
    path: SiteUrls.Admin.NoticePopup.Create,
    exact: true,
    name: '신규 팝업 공지사항',
    component: NoticePopupEditContainer,
  },
  {
    path: SiteUrls.Admin.NoticePopup.Edit(':idEditing'),
    exact: true,
    name: '팝업 공지사항 수정',
    render: (props: any) => (
      <NoticePopupEditContainer
        idEditing={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.Admin.AppConfig.List,
    exact: true,
    name: '환경설정',
    component: AppConfigListContainer,
  },
  {
    path: SiteUrls.Admin.AppConfig.Update,
    exact: true,
    name: '환경설정 업데이트',
    component: AppConfigEditContainer,
  },
  {
    path: SiteUrls.Admin.Ending.List,
    exact: true,
    name: '모든 엔딩 공유키 확인',
    component: EndingListContainer,
  },
  {
    path: SiteUrls.Admin.ImageFile.List,
    exact: true,
    name: '이미지 파일 관리',
    component: ImageFileListContainer,
  },
  {
    path: SiteUrls.Admin.ImageFile.Create,
    exact: true,
    name: '추가',
    render: (props: any) => <ImageFileEditPage id={null} />,
  },
  {
    path: SiteUrls.Admin.SFXFile.List,
    exact: true,
    name: '효과음 파일 관리',
    component: SFXFileListContainer,
  },
  {
    path: SiteUrls.Admin.SFXFile.Create,
    exact: true,
    name: '추가',
    render: (props: any) => <SFXFileEditPage id={null} />,
  },
  {
    path: SiteUrls.Admin.AdminLog.List,
    exact: true,
    name: '관리자 로그 관리',
    component: AdminLogListContainer,
  },
  {
    path: SiteUrls.Admin.BonusTicketCode.List,
    exact: true,
    name: '보너스 티켓 코드 관리',
    component: BonusTicketCodeListContainer,
  },
  {
    path: SiteUrls.Admin.BonusTicketCode.Create,
    exact: true,
    name: '신규 보너스 티켓 코드',
    component: BonusTicketCodeEditContainer,
  },
  {
    path: SiteUrls.Admin.BonusTicketCode.Edit(':idEditing'),
    exact: true,
    name: '보너스 티켓 코드 수정',
    render: (props: any) => (
      <BonusTicketCodeEditContainer
        idEditing={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.Admin.StoryOptions.List,
    exact: true,
    name: '작품 옵션 관리',
    component: StoryOptionsContainer,
  },
  {
    path: SiteUrls.Admin.Featured.List,
    exact: true,
    name: '피쳐드 관리',
    component: FeaturedListableTable,
  },
  {
    path: SiteUrls.Admin.Featured.Create,
    exact: true,
    name: '신규 피쳐드 추가',
    component: FeaturedEditPageContainer,
  },
  {
    path: SiteUrls.Admin.Featured.Edit(':idEditing'),
    exact: true,
    name: '피쳐드 수정',
    render: (props: any) => (
      <FeaturedEditPageContainer
        featuredId={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.Admin.HomeSection.List,
    exact: true,
    name: '홈 섹션 관리',
    component: HomeSectionListableTable,
  },
  {
    path: SiteUrls.Admin.HomeSection.Create,
    exact: true,
    name: '신규 홈 섹션',
    component: HomeSectionEditPageContainer,
  },
  {
    path: Utils.urlOnly(SiteUrls.Admin.HomeSection.Edit(':idEditing', ':type')),
    exact: true,
    name: '홈 섹션 수정',
    render: (props: any) => {
      console.log('@@homeSection params:', props.match.params)
      return (
        <HomeSectionEditPageContainer
          homeSectionId={parseInt(props.match.params.idEditing, 10)}
          type={props.match.params.type}
        />
      )
    },
  },
  {
    path: SiteUrls.Admin.DirectAds.List,
    exact: true,
    name: '직영광고 관리',
    component: DirectAdsListableTable,
  },
  {
    path: SiteUrls.Admin.DirectAds.Create,
    exact: true,
    name: '직영광고 추가',
    component: DirectAdsEditPageContainer,
  },
  {
    path: SiteUrls.Admin.DirectAds.Edit(':idEditing'),
    exact: true,
    name: '직영광고 수정',
    render: (props: any) => (
      <DirectAdsEditPageContainer
        directAdsId={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.Admin.AppSplashImage.List,
    exact: true,
    name: '스플래쉬 이미지 관리',
    component: AppSplashImageListableTable,
  },
  {
    path: SiteUrls.Admin.AppSplashImage.Create,
    exact: true,
    name: '스플래쉬 이미지 추가',
    component: AppSplashImageEditPageContainer,
  },
  {
    path: SiteUrls.Admin.AppSplashImage.Edit(':idEditing'),
    exact: true,
    name: '스플래쉬 이미지 수정',
    render: (props: any) => (
      <AppSplashImageEditPageContainer
        appSplashImageId={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.Admin.RecommendationForFirstUser.List,
    exact: true,
    name: '첫방문 유저 추천작품 관리',
    component: RecommendationForFirstUserListableTable,
  },
  {
    path: SiteUrls.Admin.RecommendationForFirstUser.Edit(':idEditing'),
    exact: true,
    name: '첫방문 유저 추천작품 수정',
    render: (props: any) => (
      <RecommendationForFirstUserEditPageContainer
        rfuId={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
]
