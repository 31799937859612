"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OP_PRIORITY_NOT_TRANSITIVE = exports.OP_PRIORITY_MAP = exports.encodeSPExprFormula = void 0;
const lodash_1 = require("lodash");
const consts_1 = require("../../consts");
const IPDOperation_1 = require("../IPDOperation");
const parser_1 = require("./parser");
function encodeSPExprFormula(input) {
    return encodeSPExprFormulaInternal(input)[0];
}
exports.encodeSPExprFormula = encodeSPExprFormula;
// 없으면 함수, priority = 1000
exports.OP_PRIORITY_MAP = {
    [IPDOperation_1.PDOperationName.AND]: 2,
    [IPDOperation_1.PDOperationName.OR]: 2,
    [IPDOperation_1.PDOperationName.LT]: 5,
    [IPDOperation_1.PDOperationName.GT]: 5,
    [IPDOperation_1.PDOperationName.LTE]: 5,
    [IPDOperation_1.PDOperationName.GTE]: 5,
    [IPDOperation_1.PDOperationName.Equals]: 5,
    [IPDOperation_1.PDOperationName.NotEquals]: 5,
    [IPDOperation_1.PDOperationName.Add]: 10,
    [IPDOperation_1.PDOperationName.Minus]: 10,
    [IPDOperation_1.PDOperationName.Mul]: 20,
    [IPDOperation_1.PDOperationName.Div]: 20,
    [IPDOperation_1.PDOperationName.Pow]: 30,
};
// 우선순위가 같은 경우라도 operation 이 transitive 하지 않으면 괄호가 필요하다.
exports.OP_PRIORITY_NOT_TRANSITIVE = [2, 5];
/**
 * input 은 json.parse 해서 들어온다고 가정한다.
 */
function encodeSPExprFormulaInternal(input) {
    var _a, _b, _c, _d, _e;
    if ((0, lodash_1.isString)(input)) {
        // console.log(55, input)
        return [input, null];
    }
    // Infix binary operators
    const infixOp = parser_1.InfixBinaryOpMap[input.op];
    if (infixOp) {
        // arg0, arg1 이 infixOp 로 이루어진 상황에서 우선순위가 역전되면 괄호가 필요하다.
        const [arg0Str, op0] = encodeSPExprFormulaInternal(input.args[0]);
        const [arg1Str, op1] = encodeSPExprFormulaInternal(input.args[1]);
        const p0 = op0 ? (_a = exports.OP_PRIORITY_MAP[op0]) !== null && _a !== void 0 ? _a : 1000 : 1000;
        const p1 = op1 ? (_b = exports.OP_PRIORITY_MAP[op1]) !== null && _b !== void 0 ? _b : 1000 : 1000;
        const p = (_c = exports.OP_PRIORITY_MAP[input.op]) !== null && _c !== void 0 ? _c : 1000;
        const str0 = p0 < p || (p0 === p && exports.OP_PRIORITY_NOT_TRANSITIVE.includes(p0))
            ? `(${arg0Str})`
            : arg0Str;
        const str1 = p1 < p || (p1 === p && exports.OP_PRIORITY_NOT_TRANSITIVE.includes(p1))
            ? `(${arg1Str})`
            : arg1Str;
        return [`${str0} ${infixOp} ${str1}`, input.op];
    }
    // 특수 토큰들
    if (input.op === IPDOperation_1.PDOperationName.GetValue) {
        if (input.args[0] === 'props') {
            // 선택지관련 추가 처리
            if ((0, lodash_1.startsWith)(input.args[1], consts_1.UserPropUserChoicePrefix)) {
                return [
                    `[선택지선택:${((_d = input.args[1]) !== null && _d !== void 0 ? _d : '').substring(consts_1.UserPropUserChoicePrefix.length)}]`,
                    null,
                ];
            }
            else if ((0, lodash_1.startsWith)(input.args[1], consts_1.UserPropUserChoiceInputTextPrefix)) {
                return [
                    `[선택지답변:${((_e = input.args[1]) !== null && _e !== void 0 ? _e : '').substring(consts_1.UserPropUserChoiceInputTextPrefix.length)}]`,
                    null,
                ];
            }
            return [`[속성:${input.args[1]}]`, null];
        }
        else if (input.args[0] === 'items') {
            return [`[아이템:${input.args[1]}]`, null];
        }
        throw new Error('Unknown GetValue args.');
    }
    if (input.op === IPDOperation_1.PDOperationName.String) {
        return [`"${input.args[0].replace(/"/g, '\\"')}"`, null];
    }
    return [`${input.op}(${input.args.map(encodeSPExprFormula).join(',')})`, null];
}
