import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { BackgroundEditPage } from './BackgroundEditPage'

interface IStoryHasBackgroundEditPageContainerProps {
  storyId: number
  shfId: number | null
}

export const BackgroundEditPageContainer: React.FC<IStoryHasBackgroundEditPageContainerProps> = (props) => {
  const { storyId, shfId } = props
  console.log(14, storyId, shfId)
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 4)} />
          <hr />
          <BackgroundEditPage storyId={storyId} shfId={shfId} />
        </CardBody>
      </Card>
    </>
  )
}
