import React from 'react'
import * as Yup from 'yup'
import { GQLAppConfig, GQLAppConfigUpdateInput } from '../../@types/server'
import { useCounter, useODMutation, useODQuery } from '../../context/ODCommon'
import { ODEntityInput } from '../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

type AppConfigEditContainerProps = {}

const APP_CONFIG_FULL_SNAPSHOT = `
  configId
  iOSVersion
  aOSVersion
  createdAt
  description
  iOSFeatureFlag
  aOSFeatureFlag
`

const GQL_UPDATE = `
mutation updateAppConfig($data: AppConfigUpdateInput!) {
  updateAppConfig(data: $data) {
    ${APP_CONFIG_FULL_SNAPSHOT}
  }
}
`

const GQL_GET = `
query appConfig {
  appConfig {
    ${APP_CONFIG_FULL_SNAPSHOT}
  }
}
`

type PropsInput = GQLAppConfigUpdateInput
type Entity = GQLAppConfig

const AppConfigPropsInputValidation = {
  iOSVersion: Yup.number(),
  aOSVersion: Yup.number(),
  description: Yup.string(),
  iOSFeatureFlag: Yup.string().nullable(),
  aOSFeatureFlag: Yup.string().nullable(),
}

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    iOSVersion: AppConfigPropsInputValidation.iOSVersion,
    aOSVersion: AppConfigPropsInputValidation.aOSVersion,
    description: AppConfigPropsInputValidation.description,
    iOSFeatureFlag: AppConfigPropsInputValidation.iOSFeatureFlag,
    aOSFeatureFlag: AppConfigPropsInputValidation.aOSFeatureFlag,
  })
}

export const AppConfigEditContainer: React.FC<AppConfigEditContainerProps> = (
  props
) => {
  const innerRef = React.useRef(null)
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(
    GQL_UPDATE
  )
  const apiGet = useODQuery<void, Entity>(GQL_GET)
  const [token] = useCounter()

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>
  >(
    () => ({
      initialValueLoader: async () => {
        return apiGet()
      },
      mapServerValueToClient: async (data) => {
        return {
          id: data!.configId,
          iOSVersion: data!.iOSVersion,
          aOSVersion: data!.aOSVersion,
          description: data!.description,
          iOSFeatureFlag: data!.iOSFeatureFlag,
          aOSFeatureFlag: data!.aOSFeatureFlag,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        if (data.aOSFeatureFlag) {
          let ff
          try {
            ff = JSON.parse(data.aOSFeatureFlag)
          } catch (e) {
            throw new Error(`aOS Feature flag must be JSON`)
          }
          for (const [k, v] of Object.entries(ff)) {
            if (typeof v !== 'boolean') {
              throw new Error(`aOS Feature flag ${k} must be boolean`)
            }
          }
        }

        if (data.iOSFeatureFlag) {
          let ff
          try {
            ff = JSON.parse(data.iOSFeatureFlag)
          } catch (e) {
            throw new Error(`iOS Feature flag must be JSON`)
          }
          for (const [k, v] of Object.entries(ff)) {
            if (typeof v !== 'boolean') {
              throw new Error(`iOS Feature flag ${k} must be boolean`)
            }
          }
        }

        await apiUpdate({ ...data })
        Utils.showSuccess('설정을 업데이트하였습니다.', 'Success')
        return SiteUrls.Admin.AppConfig.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [apiGet, apiUpdate, token]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<Entity, Partial<PropsInput>>
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
      ),
    [options]
  )

  const title = '환경설정 수정'

  return (
    <Provider title={title}>
      <ODEntityInput
        name="iOSVersion"
        label="iOS 버전"
        placeholder="최소 iOS 버전을 입력해주세요."
        inputType="number"
      />
      <ODEntityInput
        name="aOSVersion"
        label="안드로이드 버전"
        placeholder="최소 안드로이드 버전을 입력해주세요."
        inputType="number"
      />
      <ODEntityInput
        name="description"
        label="버전 업데이트 내용"
        placeholder="버전 업데이트 내용을 입력해주세요."
        inputType="text"
      />
      <ODEntityInput
        name="iOSFeatureFlag"
        label="iOS 피쳐 플래그"
        placeholder="{ 기능명: 활성화여부(boolean) } 형태의 JSON string을 입력해주세요."
        inputType="text"
      />
      <ODEntityInput
        name="aOSFeatureFlag"
        label="aOS 피쳐 플래그"
        placeholder="{ 기능명: 활성화여부(boolean) } 형태로 JSON string을 입력해주세요."
        inputType="text"
      />
      <hr />
      <ODEntityEditorFooter saveButtonName="Save" context={Context} />
    </Provider>
  )
}
