"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbSenderAPI = void 0;
class HbSenderAPI {
    constructor(server) {
        this.server = server;
    }
    async createGuest(payload) {
        return this.server.post(`/v2/sender/guest`, payload);
    }
    async createChatBot(payload) {
        return this.server.post(`/v2/sender`, payload);
    }
    async listByBotId(botId) {
        return this.server.get(`/v2/sender?ownerBotId=${botId}`);
    }
}
exports.HbSenderAPI = HbSenderAPI;
