import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  ODImageFileInput,
  useODEntityAPI,
} from '@odc/od-react-belt'
import {
  BOOK,
  GQLCharacterMigrationInput,
  GQLEndingMigrationInput,
  GQLSingleIDInput,
  GQLStory,
  GQLStoryPropsInput,
  GQLStoryPropsMigrationInput,
  MetaDataUpdateAction,
  CHALLENGE_STORY_GENRE,
  STORY_GENRE_TO_KO_STRING,
  ContentsLanguages,
  GQLSTORY_PLAY_TYPE,
  GENDER,
} from '@storyplay/core'
import { createEmptyBookScript } from '@storyplay/common'
import { cloneDeep } from 'apollo-utilities'
import * as faker from 'faker'
import { difference, flatten, pick } from 'lodash'
import moment from 'moment'
import React, { useCallback } from 'react'
import Select from 'react-select'
import { Card, CardBody, Col, Row } from 'reactstrap'
import styled from 'styled-components'
import {
  STORY_ENDING_ARRIVAL_RATE_TYPE,
  STORY_SECTION_TYPE,
  STORY_TYPE,
} from '../../../common'
import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
} from '../../../components/base/ODButton'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import {
  CharactersMigrationDialog,
  ICharactersMigrationProps,
} from '../../../components/ODModal/CharactersMigrationDialog'
import {
  EndingsMigrationDialog,
  IEndingToMigrateInfo,
} from '../../../components/ODModal/EndingsMigrationDialog'
import {
  IPropsInfo,
  PropsMigrationDialog,
} from '../../../components/ODModal/PropsMigrationDialog'
import { SPFormToggleButton } from '../../../components/SPFormToggleButton'
import { useCounter } from '../../../context/ODCommon'
import { useScriptImporter } from '../../../hooks/useScriptImporter'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { AppOptions } from '../../../utils/AppOptions'
import { Utils } from '../../../utils/utils'
import { ICTStory, StoryPrimaryKey } from '../StoryCommon'
import { FormikFieldUpdater, StoryImportField } from '../StoryImportField'
import { StoryScriptLogs } from '../StoryScriptLogs'
import { StoryUploadScriptJson } from './StoryUploadScriptJson'
import { StoryChapterCoinItem } from '../StoryChapterCoinItem'

type PropsInput = GQLStoryPropsInput
type Entity = GQLStory
type ICTEntity = ICTStory // Client-Type

const DEV_SPREAD_SHEETS = [
  { name: 'OD_DEV_SHEET', id: '1CYQBq8XPZl4AizHggIKj9Rreyvtw0frNSvxmygpxc5c' },
  { name: '테스트_001', id: '1ug-R9p4gqL7sJ-XwYkAdwsuVzn4DvIAE6edgCOINN_4' },
  { name: '테스트_002', id: '1SRAx4fZTpKh5lE7towCv_P-MTC37esS5Ogaee5zj19g' },
  { name: '테스트_003', id: '1QA3HravwbIVIKB7Ncap0yPN1QNrARnqk3Vbc1-uhAXE' },
]

const noticeDefaultValue = { title: '연결된 공지 없음', noticeId: null }
const ipSourcingDefaultValue = {
  name: '연결된 ip sourcing 없음',
  ipSourcingId: null,
}

const DEFAULT_DATA: Partial<ICTEntity> = {
  storyId: '',
  name: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  defaultFirstName: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  defaultLastName: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  topic: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  oneLineDesc: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  targetAge: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  coverText: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  storyPropsMigration: null,
  endingMigration: null,
  representedAt: null,
  finishedAt: null,
  // @ts-ignore
  sectionType: STORY_SECTION_TYPE.OnStage,
  showOrder: 1000,
  endingArrivalRateRange: 10,
  // @ts-ignore
  endingArrivalRateType: STORY_ENDING_ARRIVAL_RATE_TYPE.InVisible,
  // @ts-ignore
  type: STORY_TYPE.Chat,
}

const GENRES = Object.keys(STORY_GENRE_TO_KO_STRING)
interface IStoryMainPropsEditPageProps {
  storyId: number | null
}

function diffClientValue<C>(updated: C, original: C): Partial<C> {
  const keysChanged: string[] = []
  Object.keys(updated).forEach((key) => {
    // @ts-ignore
    if (updated[key] !== original[key]) {
      keysChanged.push(key)
    }
  })

  return pick(updated, ...keysChanged)
}

export const StoryMainPropsEditPage: React.FC<IStoryMainPropsEditPageProps> = (
  props
) => {
  const { storyId } = props

  const [startWithStudio, setStartWithStudio] = React.useState(false)
  const finalJSONData = React.useRef<string | null>(null)
  let [detailInfoList, setDetailInfoList] = React.useState<
    { key: string; value: string }[]
  >([])
  let detailInfoRef = React.useRef<{ key: string; value: string }[]>([])
  const isFinalJSONModified = React.useRef(false)

  const [noticeOption, setNoticeOption] = React.useState<
    { label: string; value: number | null }[]
  >([])
  const sectionTypeRef = React.useRef('')
  const [sectionTypeState, setSectionTypeState] = React.useState<string>('')
  const setSectionTypeRef = (v: string) => {
    sectionTypeRef.current = v
    setSectionTypeState(sectionTypeRef.current)
  }
  const selectedNoticeRef = React.useRef<number | null>(null)
  const selectedLangCodeRef = React.useRef<string | null>(null)
  const selectedGenreRef = React.useRef<string | null>(null)

  const [ipSourcingOption, setIpSourcingOption] = React.useState<
    { label: string; value: number | null }[]
  >([])
  const selectedIpSourcingRef = React.useRef<number | null>(null)

  const {
    apiCreateStory,
    apiUpdateStory,
    apiGetStory,
    listNotice,
    apiApplyMgmtSheetToStory,
    listIpSourcing,
  } = useStoryPlayAPIs()

  const setInput = useCallback((input: GQLStoryPropsInput) => {
    input = setInputLocalDate(input, [
      'storyEndsAt',
      'freeTimeLeapStartAt',
      'freeTimeLeapEndAt',
      'purchaseFreeStartAt',
      'finishedAt',
    ])
    input.noticeId = selectedNoticeRef.current
    input.ipSourcingId = selectedIpSourcingRef.current
    input.storyDetailInfo =
      detailInfoRef.current.length > 0
        ? detailInfoRef.current.reduce(
            (acc, { key, value }) => ({
              ...acc,
              [key]: value.split(',').map((d) => d.trim()),
            }),
            {}
          )
        : {}
    input.playType = GQLSTORY_PLAY_TYPE.Interactive
    input.languageCode = selectedLangCodeRef.current
    input.genre = selectedGenreRef.current
    return input
  }, [])

  const setInputLocalDate = (input: GQLStoryPropsInput, dateList: string[]) => {
    dateList.forEach((date) => {
      // @ts-ignore
      if (input[date]) {
        // @ts-ignore
        input[date] = moment(input[date]).toDate()
      }
    })
    return input
  }

  const createAPI = React.useCallback(
    async (input: GQLStoryPropsInput) => {
      if (!finalJSONData.current) {
        throw new Error('스크립트가 로드되지 않았습니다.')
      }
      input = setInput(input)
      return apiCreateStory({ ...input, script: finalJSONData.current })
    },
    [apiCreateStory, setInput]
  )

  const [video, setVideo] = React.useState('')
  const videoFile = React.useRef<File | undefined>(undefined)

  const updateAPI = React.useCallback(
    async (input: GQLStoryPropsInput) => {
      input = setInput(input)
      return apiUpdateStory({
        ...input,
        ...(videoFile.current && { teaserVideoFile: videoFile.current }),
        ...(finalJSONData.current && { script: finalJSONData.current }),
      })
    },
    [apiUpdateStory, setInput]
  )

  const readAPI = React.useCallback(
    async (input: GQLSingleIDInput) => {
      const result = await apiGetStory(input)
      if (result.storyDetailInfo) {
        detailInfoRef.current = Object.keys(result.storyDetailInfo).map(
          (k) => ({ key: k, value: result.storyDetailInfo[k].join(',') })
        )
        setDetailInfoList([...detailInfoRef.current])
      }
      selectedNoticeRef.current = result.noticeId
      const noticeTitle = result.notice
        ? `[${result.notice.noticeId}] ${result.notice.title}`
        : undefined
      const ipSourcingName = result.ipSourcing
        ? `[${result.ipSourcing.ipSourcingId}] ${result.ipSourcing.name}`
        : undefined
      const refStoryName = result.refStory
        ? `[${result.refStory.storyId}] ${result.refStory.name}`
        : '없음'
      selectedLangCodeRef.current = result.languageCode
      selectedGenreRef.current = result.genre
      console.log('@@readAPI langCode:', result.langCode)
      sectionTypeRef.current = result.sectionType
      setSectionTypeState(sectionTypeRef.current)

      return {
        ...result,
        representedAt: moment(result.representedAt).format('YYYY-MM'),
        storyEndsAt: moment(result.storyEndsAt).format('YYYY-MM-DDTHH:mm'),
        willPublishAt: moment(result.willPublishAt).format('YYYY-MM-DDTHH:mm'),
        freeTimeLeapStartAt: moment(result.freeTimeLeapStartAt).format(
          'YYYY-MM-DDTHH:mm'
        ),
        freeTimeLeapEndAt: moment(result.freeTimeLeapEndAt).format(
          'YYYY-MM-DDTHH:mm'
        ),
        purchaseFreeStartAt: moment(result.purchaseFreeStartAt).format(
          'YYYY-MM-DDTHH:mm'
        ),
        finishedAt: moment(result.finishedAt).format('YYYY-MM-DDTHH:mm'),
        noticeTitle,
        ipSourcingName,
        refStoryName,
      }
    },
    [apiGetStory]
  )

  const apis = useODEntityAPI<Entity, ICTEntity, PropsInput, PropsInput>({
    createAPI,
    updateAPI,
    readAPI,
    primaryKeyName: StoryPrimaryKey,
    callUpdateWhenNoChangeFound: true,
  })

  const [sheetId, setSheetId] = React.useState(
    AppOptions.FILL_MOCK_DATA
      ? '1KolKh_ElOAbXV-aEnqZZwpeI7iSdBs0iuoOeoDx9k08'
      : '' // 사본_아무 완결
    // AppOptions.FILL_MOCK_DATA ? '171qHtGYD4U2czb-a3MgHKWi8V1SzTZaVhHkO9s1he1c' : '' // 정물의 집_01
    // AppOptions.FILL_MOCK_DATA ? '1UO-Q08ST-vY1eiE7MR_PUov-yTK5l5BB1c6ouoLX3nI' : ''
    // AppOptions.FILL_MOCK_DATA ? '1hUL7GP4nMizf1OIGNF7nbebk_4LM3ANhi6ua3qcb3eU' : '' // 철벽
    // AppOptions.FILL_MOCK_DATA ? '1I-aaBnzCaLiRaoMx_s486vI2824LPn-_Ly0wnRcin4A' : ''
  )

  const [token, refresh] = useCounter()
  const [isLoadingScript, setIsLoadingScript] = React.useState(false)
  const { loadSheet, data } = useScriptImporter()

  // 속성값 마이그레이션
  const [propsToMigrate, setPropsToMigrate] = React.useState<IPropsInfo[]>([])
  const [propsMigrationResult, setPropsMigrationResult] =
    React.useState<GQLStoryPropsMigrationInput | null>(null)
  const [showPropsMigrationDialog, setShowPropsMigrationDialog] =
    React.useState(false)

  // 엔딩 마이그레이션
  const [endingsToMigrate, setEndingsToMigrate] = React.useState<
    IEndingToMigrateInfo[]
  >([])
  const [endingsMigrationResult, setEndingsMigrationResult] =
    React.useState<GQLEndingMigrationInput | null>(null)
  const [showEndingsMigrationDialog, setShowEndingsMigrationDialog] =
    React.useState(false)

  // 캐릭터 마이그레이션
  const [charactersToMigrate, setCharactersToMigrate] = React.useState<
    ICharactersMigrationProps[]
  >([])
  const [charactersExist, setCharactersExist] = React.useState<string[]>([])
  const [charactersMigrationResult, setCharactersMigrationResult] =
    React.useState<GQLCharacterMigrationInput | null>(null)
  const [showCharactersMigrationDialog, setShowCharactersMigrationDialog] =
    React.useState(false)

  // 관리시트
  const [isUploadingMetaUpdateActions, setIsUploadingMetaUpdateActions] =
    React.useState(false)
  const [metaUpdateActions, setMetaUpdateActions] = React.useState<
    MetaDataUpdateAction[]
  >([])

  const hasPropsMigration = propsToMigrate.length > 0
  const requiresPropsMigration =
    hasPropsMigration && propsMigrationResult === null

  const hasEndingMigration = endingsToMigrate.length > 0
  const requiresEndingsMigration =
    hasEndingMigration && endingsMigrationResult === null

  const hasCharacterMigration = charactersToMigrate.length > 0
  const requiresCharactersMigration =
    hasCharacterMigration && charactersMigrationResult === null

  const handleDetailInfoCreate = () => {
    setDetailInfoList(
      detailInfoList.concat({
        key: '',
        value: '',
      })
    )
    detailInfoRef.current = detailInfoList.concat({
      key: '',
      value: '',
    })
  }

  const writeDetailInfoKey = (e: any) => {
    const idx = detailInfoList.findIndex(
      (_, idx) => `${idx}_key` === e.target.name
    )
    if (idx > -1) detailInfoList[idx].key = e.target.value
    setDetailInfoList([...detailInfoList])
    detailInfoRef.current = [...detailInfoList]
  }

  const writeDetailInfoValue = (e: any) => {
    const idx = detailInfoList.findIndex(
      (_, idx) => `${idx}_value` === e.target.name
    )
    if (idx > -1) detailInfoList[idx].value = e.target.value
    setDetailInfoList([...detailInfoList])
    detailInfoRef.current = [...detailInfoList]
  }

  const removeDetailInfoList = (e: any) => {
    const idx = detailInfoList.findIndex(
      (_, idx) => `${idx}_delete` === e.target.name
    )
    if (idx > -1) detailInfoList.splice(idx, 1)
    setDetailInfoList([...detailInfoList])
    detailInfoRef.current = [...detailInfoList]
  }

  React.useEffect(() => {
    listNotice({ pageSize: 1000, page: null, filter: null }).then((r) => {
      const noticeList = [noticeDefaultValue, ...r.list].map((notice) => ({
        label: notice.title!,
        value: notice.noticeId,
      }))
      setNoticeOption(noticeList)
    })
  }, [listNotice])

  React.useEffect(() => {
    listIpSourcing({ pageSize: 1000 }).then((r) => {
      const ipSourcingList = [ipSourcingDefaultValue, ...r.list].map(
        (ipSourcing) => ({
          label: ipSourcing.name!,
          value: ipSourcing.ipSourcingId!,
        })
      )
      setIpSourcingOption(ipSourcingList)
    })
  }, [listIpSourcing])

  const handleUploadMetaUpdateActions = async () => {
    if (
      isUploadingMetaUpdateActions ||
      metaUpdateActions.length === 0 ||
      !storyId
    ) {
      return
    }
    setIsUploadingMetaUpdateActions(true)

    try {
      await apiApplyMgmtSheetToStory({
        storyId,
        jsonEncodedMetaUpdateActions: JSON.stringify(metaUpdateActions),
      })
      Utils.showSuccess('관리시트의 내용이 적용되었습니다.')
    } catch (ex: any) {
      Utils.showError(ex)
    } finally {
      setIsUploadingMetaUpdateActions(false)
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          {showPropsMigrationDialog && storyId && (
            <PropsMigrationDialog
              storyId={storyId}
              propsToMigrate={propsToMigrate}
              onClose={() => setShowPropsMigrationDialog(false)}
              onConfirm={(v) => {
                setPropsMigrationResult(v)
                setShowPropsMigrationDialog(false)
              }}
            />
          )}
          {showEndingsMigrationDialog && storyId && (
            <EndingsMigrationDialog
              storyId={storyId}
              endingsToMigrate={endingsToMigrate}
              newScriptBook={data.book!}
              onClose={() => setShowEndingsMigrationDialog(false)}
              onConfirm={(v) => {
                setEndingsMigrationResult(v)
                setShowEndingsMigrationDialog(false)
              }}
            />
          )}
          {showCharactersMigrationDialog && storyId && (
            <CharactersMigrationDialog
              storyId={storyId}
              charactersExist={charactersExist}
              characterToMigrate={charactersToMigrate}
              newScriptBook={data.book!}
              onClose={() => setShowCharactersMigrationDialog(false)}
              onConfirm={(v) => {
                setCharactersMigrationResult(v)
                setShowCharactersMigrationDialog(false)
              }}
            />
          )}
          <ODEntity
            resourceId={storyId}
            // @ts-ignore
            api={apis}
            saveButtonName="저장"
            titleCreation="추가"
            titleUpdate="수정"
            diffClientValue={diffClientValue}
            updateSuccessTitle="성공"
            updateSuccessMessage="수정하였습니다."
            // urlAfterDelete={(item: ICTUser) => SiteUrls.Admin.User.Main}
            deleteSuccessTitle="성공"
            deleteSuccessMessage="삭제하였습니다."
            defaultCreateClientData={DEFAULT_DATA}
            urlAfterCreation={(c) => SiteUrls.Admin.Story.Edit(c.storyId)}
            urlAfterUpdate={(c) => refresh()}
            // urlAfterUpdate={(c) => {
            //   return c.publishedAt
            //     ? SiteUrls.Admin.Story.List.Published
            //     : SiteUrls.Admin.Story.List.Pending
            // }}
            noCardWrap
            refreshToken={token}
            onBeforeSubmit={(editedStory: GQLStory) => {
              if (requiresPropsMigration) {
                Utils.showError('속성값 마이그레이션이 필요합니다.')
                return false
              }

              if (requiresEndingsMigration) {
                Utils.showError('엔딩 마이그레이션이 필요합니다.')
                return false
              }

              if (requiresCharactersMigration) {
                Utils.showError('캐릭터 마이그레이션이 필요합니다.')
                return false
              }

              const { allChapters, lastPreviewChapterIndex } = editedStory
              if (
                lastPreviewChapterIndex === undefined ||
                lastPreviewChapterIndex === null
              ) {
                return true
              }

              if (lastPreviewChapterIndex.toString() === '') {
                editedStory.lastPreviewChapterIndex = null
                return true
              }

              const { 0: first, length, [length - 1]: last } = allChapters

              if (lastPreviewChapterIndex < first.chapterIndex) {
                Utils.showError(
                  `lastPreviewChapterIndex는 ${first.chapterIndex}보다 크거나 같아야 합니다.`
                )
                return false
              }

              if (lastPreviewChapterIndex > last.chapterIndex) {
                Utils.showError(
                  `lastPreviewChapterIndex는 ${last.chapterIndex}보다 작거나 같아야 합니다.`
                )
                return false
              }

              return true
            }}
          >
            <ODEntityInput
              keyPath="storyId"
              label="작품 ID"
              name="storyId"
              placeholder="작품 생성 후 자동 생성됩니다."
              inputType="text"
              inputProps={{ disabled: true }}
            />

            {storyId && (
              <ODEntityLabeled label="작품 업데이트 시각" name="updatedAt">
                <ODEntityRaw
                  name="updatedAt"
                  keyPath="updatedAt"
                  render={({ value }) => {
                    return (
                      <div style={{ color: 'green' }}>
                        {Utils.formatDate(value)}
                      </div>
                    )
                  }}
                />
              </ODEntityLabeled>
            )}
            <ODEntityRaw
              name="_script"
              keyPath="_script"
              render={(props) => {
                const existingValue = props.entityContext.state
                  .initialValue as GQLStory
                const userProps = existingValue.userProps
                const existingChapterCustomIds = (
                  existingValue.allChapters ?? []
                ).map((c) => c.customId)
                const existCharacters = existingValue.characters
                const existingEndings: IEndingToMigrateInfo[] = !storyId
                  ? []
                  : flatten(
                      existingValue.allChapters.map((c) => {
                        return c.endings.map((ending) => {
                          const result: IEndingToMigrateInfo = {
                            endingCustomId: ending.customId!,
                            name: ending.name,
                            chapterCustomId: c.customId!,
                            chapterName: c.name,
                            chapterId: c.chapterId,
                            isFinal: ending.isFinal,
                          }
                          return result
                        })
                      })
                    )

                const handleImport = async () => {
                  if (isLoadingScript) {
                    return
                  }
                  setIsLoadingScript(true)
                  loadSheet(sheetId, storyId?.toString() || '', '')
                    .then((result) => {
                      const { json, book } = result!
                      finalJSONData.current = json
                      isFinalJSONModified.current = true

                      // 크롬 브라우저가 다운될 가능성이 높다.
                      // props.formikContext.setFieldValue('script', json!, false)

                      if (storyId) {
                        // Props
                        const existingProps: {
                          [propName: string]: number | string
                        } = JSON.parse(userProps)
                        const newProps = book!.props
                        // const propsRemoved = difference(Object.keys(existingProps), Object.keys(newProps))
                        const propsAdded: string[] = difference(
                          Object.keys(newProps),
                          Object.keys(existingProps)
                        )
                        // 추가된 Props 중에서 이미 기존에 존재하는 챕터에 있는 경우만
                        const propsAddedInExistingChapters = propsAdded.filter(
                          (propName) => {
                            return existingChapterCustomIds.includes(
                              newProps[propName].chapterId
                            )
                          }
                        )

                        setPropsToMigrate(
                          propsAddedInExistingChapters.map((propName) => {
                            const propInfo = newProps[propName]
                            return {
                              name: propInfo.name,
                              dataType: propInfo.propType,
                            }
                          })
                        )

                        // Endings
                        const newEndingCustomIds = Object.keys(
                          book!.endings
                        ).map((k) => book!.endings[k].id)
                        const endingsToMigrate = cloneDeep(existingEndings)
                          .filter(
                            (ending) =>
                              !newEndingCustomIds.includes(
                                ending.endingCustomId
                              )
                          )
                          .filter((ending) => {
                            // 새롭운 스크립트에도 해당 챕터가 여전히 존재해야 함.
                            return Object.entries(book!.chapters).find(
                              ([k, v]) => v.customId === ending.chapterCustomId
                            )
                          })
                        setEndingsToMigrate(endingsToMigrate)

                        // 캐릭터
                        const newCharacterList = Object.keys(
                          book!.characters
                        ).map((chrName) => chrName)
                        const characterToMigrate = existCharacters
                          .filter((c) => !newCharacterList.includes(c.name))
                          .map((c) => ({ name: c.name }))
                        const charactersExist = existCharacters.map(
                          ({ name }) => name
                        )
                        setCharactersToMigrate(characterToMigrate)
                        setCharactersExist(charactersExist)
                        // _관리_ 시트
                        setMetaUpdateActions(book?.metaDataUpdateActions ?? [])
                      }
                    })
                    .catch(Utils.showError)
                    .finally(() => setIsLoadingScript(false))
                }

                const handleStartFromStudio = async () => {
                  const emptyBook: BOOK = createEmptyBookScript()
                  finalJSONData.current = JSON.stringify(emptyBook)
                  isFinalJSONModified.current = true
                  setStartWithStudio(true)
                }

                return (
                  <StoryImportField
                    sheetId={sheetId}
                    setSheetId={setSheetId}
                    handleImport={handleImport}
                    handleStartFromStudio={handleStartFromStudio}
                    hideSpread={startWithStudio}
                    isCreating={!storyId}
                    scriptData={props.value}
                  />
                )
              }}
            />
            {AppOptions.SHOW_DEV_MENU && (
              <ODEntityRaw
                name="storyId"
                keyPath="storyId"
                render={() => {
                  return (
                    <Row style={{ marginBottom: 10 }}>
                      <Col md={3} />
                      {DEV_SPREAD_SHEETS.map(({ id, name }) => {
                        return (
                          <Col md={1} key={name}>
                            <ODButton
                              size={ODButtonSize.Small}
                              theme={ODButtonTheme.PrimaryInvert}
                              onClick={() => setSheetId(id)}
                            >
                              {name}
                            </ODButton>
                          </Col>
                        )
                      })}
                    </Row>
                  )
                }}
              />
            )}
            <StoryScriptLogs logs={data.logs} />
            {storyId && (
              <ODEntityLabeled
                name="storyPropsMigration"
                label="속성값 마이그레이션"
              >
                <ODEntityRaw
                  name="storyPropsMigration"
                  keyPath="storyPropsMigration"
                  render={() => {
                    if (hasPropsMigration) {
                      return (
                        <ODButton
                          size={ODButtonSize.Small}
                          theme={ODButtonTheme.Primary}
                          onClick={() => setShowPropsMigrationDialog(true)}
                        >
                          마이그레이션 창 열기
                        </ODButton>
                      )
                    }
                    return (
                      <div style={{ marginTop: 7 }}>
                        추가된 속성값이 없습니다.
                      </div>
                    )
                  }}
                />
                {requiresPropsMigration && (
                  <div style={{ color: 'red' }}>마이그레이션 필요!</div>
                )}
                {hasPropsMigration && !requiresPropsMigration && (
                  <div style={{ color: 'green' }}>마이그레이션 데이터 있음</div>
                )}
                <FormikFieldUpdater
                  value={propsMigrationResult}
                  formikFieldName={'storyPropsMigration'}
                  enabled={!!storyId}
                />
              </ODEntityLabeled>
            )}
            {storyId && (
              <ODEntityLabeled name="endingMigration" label="엔딩 마이그레이션">
                <ODEntityRaw
                  name="endingMigration"
                  keyPath="endingMigration"
                  render={() => {
                    if (hasEndingMigration) {
                      return (
                        <ODButton
                          size={ODButtonSize.Small}
                          theme={ODButtonTheme.Primary}
                          onClick={() => setShowEndingsMigrationDialog(true)}
                        >
                          마이그레이션 창 열기
                        </ODButton>
                      )
                    }
                    return (
                      <div style={{ marginTop: 7 }}>
                        삭제된 엔딩이 없습니다.
                      </div>
                    )
                  }}
                />
                {requiresEndingsMigration && (
                  <div style={{ color: 'red' }}>마이그레이션 필요!</div>
                )}
                {hasEndingMigration && !requiresEndingsMigration && (
                  <div style={{ color: 'green' }}>마이그레이션 데이터 있음</div>
                )}
                <FormikFieldUpdater
                  value={endingsMigrationResult}
                  formikFieldName={'endingMigration'}
                  enabled={!!storyId}
                />
              </ODEntityLabeled>
            )}
            {storyId && (
              <ODEntityLabeled
                name="characterMigration"
                label="캐릭터 마이그레이션"
              >
                <ODEntityRaw
                  name="characterMigration"
                  keyPath="characterMigration"
                  render={() => {
                    if (hasCharacterMigration) {
                      return (
                        <ODButton
                          size={ODButtonSize.Small}
                          theme={ODButtonTheme.Primary}
                          onClick={() => setShowCharactersMigrationDialog(true)}
                        >
                          마이그레이션 창 열기
                        </ODButton>
                      )
                    }
                    return (
                      <div style={{ marginTop: 7 }}>
                        변경된 캐릭터가 없습니다.
                      </div>
                    )
                  }}
                />
                {requiresCharactersMigration && (
                  <div style={{ color: 'red' }}>마이그레이션 필요!</div>
                )}
                {hasCharacterMigration && !requiresCharactersMigration && (
                  <div style={{ color: 'green' }}>마이그레이션 데이터 있음</div>
                )}
                <FormikFieldUpdater
                  value={charactersMigrationResult}
                  formikFieldName={'characterMigration'}
                  enabled={!!storyId}
                />
              </ODEntityLabeled>
            )}
            {storyId && (
              <ODEntityLabeled
                name="metaUpdateActions"
                label="_관리_ 시트 업로드"
              >
                <ODEntityRaw
                  name="metaUpdateActionsUpload"
                  keyPath="metaUpdateActionsUpload"
                  render={() => {
                    if (isUploadingMetaUpdateActions) {
                      return <div>업로드 중...</div>
                    }

                    if (metaUpdateActions.length > 0) {
                      return (
                        <ODButton
                          size={ODButtonSize.Normal}
                          theme={ODButtonTheme.Primary}
                          onClick={handleUploadMetaUpdateActions}
                        >
                          업로드하기
                        </ODButton>
                      )
                    }
                    return (
                      <div style={{ marginTop: 7 }}>
                        관리시트가 없거나 업로드할 내용이 없습니다.
                      </div>
                    )
                  }}
                />
                {requiresPropsMigration && (
                  <div style={{ color: 'red' }}>마이그레이션 필요!</div>
                )}
                {hasPropsMigration && !requiresPropsMigration && (
                  <div style={{ color: 'green' }}>마이그레이션 데이터 있음</div>
                )}
                <FormikFieldUpdater
                  value={propsMigrationResult}
                  formikFieldName={'storyPropsMigration'}
                  enabled={!!storyId}
                />
              </ODEntityLabeled>
            )}
            <ODEntityInput
              keyPath="name"
              label="작품명"
              name="name"
              placeholder="작품명을 입력해주세요"
              inputType="text"
            />
            <ODEntityLabeled label="작품 유형" name="작품 유형">
              <b>인터랙티브</b>
            </ODEntityLabeled>
            <ODEntityLabeled label="작품 구분" name="작품 구분">
              <ToggleButtonWrapper>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.OnStage}
                  onClick={setSectionTypeRef}
                >
                  정식 작품
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.Dropped}
                  onClick={setSectionTypeRef}
                >
                  보물상자
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.Global}
                  onClick={setSectionTypeRef}
                >
                  글로벌
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.Adult}
                  onClick={setSectionTypeRef}
                >
                  성인 인증
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.UGC}
                  onClick={setSectionTypeRef}
                >
                  UGC
                </SPFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="성인 작품 여부?" name="isAdult">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isAdult_true"
                  name="isAdult"
                  keyPath="isAdult"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isAdult_false"
                  name="isAdult"
                  keyPath="isAdult"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="작품 타입" name="작품 타입">
              <ToggleButtonWrapper>
                <SPFormToggleButton
                  name="type"
                  keyPath="type"
                  value={STORY_TYPE.Novel}
                >
                  노벨(소설형)
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="type"
                  keyPath="type"
                  value={STORY_TYPE.Chat}
                >
                  채팅
                </SPFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled name={'작품장르'} label={'작품장르'}>
              <Row>
                <Col md={2}>
                  <ODEntityRaw
                    name="genre"
                    keyPath="genre"
                    render={({ value }) => {
                      // @ts-ignore
                      const label = STORY_GENRE_TO_KO_STRING[value]
                      return <span>{label ? label : '없음'}</span>
                    }}
                  />
                </Col>
                <Col md={8}>
                  <Select
                    isSearchable={true}
                    placeholder="작품의 장르를 선택해주세요"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedGenreRef.current = v.value
                      }
                    }}
                    options={GENRES.map((genre) => {
                      const k = genre as unknown as CHALLENGE_STORY_GENRE
                      return {
                        label: STORY_GENRE_TO_KO_STRING[k],
                        value: k,
                      }
                    })}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <ODEntityLabeled name={'언어코드'} label={'언어코드'}>
              <Row>
                <Col md={2}>
                  <ODEntityRaw
                    name="languageCode"
                    keyPath="languageCode"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </Col>
                <Col md={8}>
                  <Select
                    isSearchable={true}
                    placeholder="작품의 언어를 선택해주세요"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedLangCodeRef.current = v.value
                      }
                    }}
                    options={ContentsLanguages.map((lng) => ({
                      label: `${lng.code}(${lng.display})`,
                      value: lng.code,
                    }))}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <ODEntityLabeled label="휴재 중?" name="onHiatus">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="onHiatus_true"
                  name="onHiatus"
                  keyPath="onHiatus"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="onHiatus_false"
                  name="onHiatus"
                  keyPath="onHiatus"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="가격 설정(회차)" name="studioPriceSetting">
              <ODEntityRaw
                name="studioPriceSetting"
                keyPath="studioPriceSetting"
                render={({ value }) => {
                  return <StoryChapterCoinItem studioPriceSetting={value} />
                }}
              />
            </ODEntityLabeled>
            <ODEntityLabeled label="동일 웹소설 작품" name="동일 웹소설 작품">
              <ODEntityRaw
                name="refStoryName"
                keyPath="refStoryName"
                render={({ value }) => {
                  return <span>{value}</span>
                }}
              />
            </ODEntityLabeled>
            <ODEntityLabeled label="홈 배너 등록?" name="isBanner">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isBanner_true"
                  name="isBanner"
                  keyPath="isBanner"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isBanner_false"
                  name="isBanner"
                  keyPath="isBanner"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="통구매 가능?" name="purchasbleRaw">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="purchasableRaw_true"
                  name="purchasableRaw"
                  keyPath="purchasableRaw"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="purchasableRaw_false"
                  name="purchasableRaw"
                  keyPath="purchasableRaw"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="originalPrice"
              label="통구매 가격(할인 전)"
              name="originalPrice"
              placeholder="작품 통구매 할인 전 가격을 입력해주세요."
              inputType="number"
            />
            <ODEntityInput
              keyPath="lastPreviewChapterIndex"
              label="통구매 전 무료로 볼 수 있는 마지막 회차 index(1 based)"
              name="lastPreviewChapterIndex"
              placeholder="설정된 회차 인덱스 이하에 해당하는 챕터는 통구매가 안되어도 무료로 플레이 가능합니다."
              inputType="number"
            />
            <ODEntityInput
              keyPath="price"
              label="통구매 가격(할인 후)"
              name="price"
              placeholder="작품 통구매 할인 후 가격을 입력해주세요."
              inputType="number"
            />
            <ODEntityInput
              keyPath="discountRate"
              label="통구매 할인율(가격 업데이트 후 적용됨)"
              name="discountRate"
              placeholder="가격 업데이트 완료 후 적용됩니다"
              inputType="number"
              inputProps={{ disabled: true }}
            />
            <ODEntityLabeled
              name={'작품 주인공 이름 디폴트 설정'}
              label={'작품 주인공 이름 디폴트 설정'}
            >
              <Row>
                <Col md={4}>
                  <ODEntityInput
                    withoutLabel={true}
                    keyPath="defaultLastName"
                    name="defaultLastName"
                    placeholder="작품 주인공 이름 디폴트 (성)"
                    inputType="text"
                  />
                </Col>
                <Col md={4}>
                  <ODEntityInput
                    withoutLabel={true}
                    keyPath="defaultFirstName"
                    name="defaultFirstName"
                    placeholder="작품 주인공 이름 디폴트 (이름)"
                    inputType="text"
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="representedAt"
              label="월간 인기작"
              name="representedAt"
              inputType="month"
              placeholder="월간 인기작"
            />
            <ODEntityInput
              keyPath="storyEndsAt"
              label="작품 만료일"
              name="storyEndsAt"
              inputType="datetime-local"
              placeholder="작품 만료일"
            />
            <ODEntityInput
              keyPath="willPublishAt"
              label="작품 공개 예정일"
              name="willPublishAt"
              inputType="datetime-local"
              placeholder="작품 공개 예정일"
            />
            <ODEntityLabeled name={'IP소싱 ID'} label={'IP소싱 ID'}>
              <Row>
                <Col md={6}>
                  <Select
                    isSearchable={true}
                    placeholder="IP소싱 ID를 선택해주세요."
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedIpSourcingRef.current = v.value
                      }
                    }}
                    options={ipSourcingOption}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </Col>
                <Col md={4}>
                  <ODEntityRaw
                    name="ipSourcingName"
                    keyPath="ipSourcingName"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <ODEntityLabeled label="타임리프 무료 설정" name="timeLeapSetting">
              <div style={{ color: 'green' }}>
                타임리프를 무료로 설정할 기간을 설정하세요.{' '}
                <b>
                  무료 대상 회차 구매 시작일, 타임리프 무료 시작일/종료일, 공지
                  ID 모두 입력해주세요.
                </b>
              </div>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="purchaseFreeStartAt"
              label="무료 대상 회차 구매 시작일"
              name="purchaseFreeStartAt"
              inputType="datetime-local"
              placeholder="무료 대상 회차 구매 시작일"
              inputProps={{ min: '2022-03-03T12:00' }}
            />
            <ODEntityInput
              keyPath="freeTimeLeapStartAt"
              label="타임리프 무료 시작일"
              name="freeTimeLeapStartAt"
              inputType="datetime-local"
              placeholder="타임리프 무료 시작일"
            />
            <ODEntityInput
              keyPath="freeTimeLeapEndAt"
              label="타임리프 무료 종료일"
              name="freeTimeLeapEndAt"
              inputType="datetime-local"
              placeholder="타임리프 무료 종료일"
            />
            <ODEntityLabeled
              name={'타임리프 무료 공지 ID'}
              label={'타임리프 무료 공지 ID'}
            >
              <Row>
                <Col md={6}>
                  <Select
                    isSearchable={true}
                    placeholder="타임리프 무료 공지사항 ID를 선택해주세요."
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedNoticeRef.current = v.value
                      }
                    }}
                    options={noticeOption}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </Col>
                <Col md={6}>
                  <ODEntityRaw
                    name="noticeTitle"
                    keyPath="noticeTitle"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="authorName"
              label="작가 이름"
              name="authorName"
              placeholder="작가 이름을 입력해주세요."
              inputType="text"
            />
            <ODEntityInput
              keyPath="topic"
              label="주제"
              name="topic"
              placeholder="주제를 입력해주세요."
              inputType="text"
            />
            <ODEntityInput
              keyPath="oneLineDesc"
              label="작품 설명"
              name="oneLineDesc"
              placeholder="작품에 대한 설명을 입력해주세요."
              inputType="textarea"
              rows={3}
            />
            <ODEntityInput
              keyPath="shortDesc"
              label="한 줄 설명(15자 이내)"
              name="shortDesc"
              placeholder="작품에 대한 설명을 15자 이내로 입력해주세요. (메인화면에 나올 문구)"
              inputType="text"
              inputProps={{ maxLength: 20 }}
            />
            <ODEntityInput
              keyPath="targetAge"
              label="타깃 연령"
              name="targetAge"
              placeholder="타깃 연령을 입력해주세요."
              inputType="text"
            />
            <ODEntityInput
              keyPath="coverText"
              label="띠지 텍스트"
              name="coverText"
              placeholder="띠지 텍스트를 입력해주세요."
              inputType="text"
            />
            <ODEntityLabeled label="작품 크레딧 설정" name="detailSetting">
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{
                  float: 'left',
                  paddingRight: 10,
                  paddingLeft: 10,
                  marginRight: 10,
                }}
                onClick={handleDetailInfoCreate}
              >
                + 크레딧 추가하기
              </ODButton>
              <div style={{ color: 'blue' }}>
                작품 제작에 참여한 사람이나 단체 정보 등을 입력 할 수 있습니다.
              </div>
            </ODEntityLabeled>
            {detailInfoList.length > 0 && (
              <>
                <ODEntityLabeled
                  name={'작품 크레딧 데이터 작성'}
                  label={'작품 크레딧 데이터 작성'}
                >
                  <ul>
                    {' '}
                    {detailInfoList.map(({ key, value }, idx) => {
                      return (
                        <li key={idx}>
                          {' '}
                          <input
                            style={{ width: '20%' }}
                            type="text"
                            key={`${idx}_key`}
                            name={`${idx}_key`}
                            value={key}
                            placeholder="크레딧 키"
                            onChange={writeDetailInfoKey}
                          />
                          :{' '}
                          <input
                            style={{ width: '70%' }}
                            type="text"
                            key={`${idx}_value`}
                            name={`${idx}_value`}
                            value={value}
                            placeholder="다수의 경우 반점(,) 으로 구분하여 적어주세요. ex) 이철수,김영희"
                            onChange={writeDetailInfoValue}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="button"
                            name={`${idx}_delete`}
                            key={`${idx}_delete`}
                            onClick={removeDetailInfoList}
                            value="X"
                          />
                        </li>
                      )
                    })}
                  </ul>
                </ODEntityLabeled>
              </>
            )}
            <ODEntityLabeled label="엔딩 도착률 설정" name="endingArrivalRate">
              <div style={{ color: 'red' }}>
                <b>엔딩 설계값 변동 퍼센트%</b>와 <b>엔딩 도착률 표시 옵션</b>은{' '}
                <b>슈퍼어드민</b>만 수정할 수 있습니다.
              </div>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="endingArrivalRateRange"
              label="엔딩 설계값 변동 퍼센트%"
              name="endingArrivalRateRange"
              placeholder="설계한 엔딩에 변동률을 주는 %범위를 입력해주세요. ex)10 입력 범위(0~100)"
              inputType="number"
            />
            <ODEntityLabeled
              label="엔딩 도착률 표시 옵션"
              name="엔딩 도착률 표시 옵션"
            >
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="endingArrivalRateType_SettingValue"
                  name="endingArrivalRateType"
                  keyPath="endingArrivalRateType"
                  value={STORY_ENDING_ARRIVAL_RATE_TYPE.SettingValue}
                >
                  설계값
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="endingArrivalRateType_RealValue"
                  name="endingArrivalRateType"
                  keyPath="endingArrivalRateType"
                  value={STORY_ENDING_ARRIVAL_RATE_TYPE.RealValue}
                >
                  실제값
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="endingArrivalRateType_InVisible"
                  name="endingArrivalRateType"
                  keyPath="endingArrivalRateType"
                  value={STORY_ENDING_ARRIVAL_RATE_TYPE.InVisible}
                >
                  도착률 미표시
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="endingArrivalRateType_Unknown"
                  name="endingArrivalRateType"
                  keyPath="endingArrivalRateType"
                  value={STORY_ENDING_ARRIVAL_RATE_TYPE.Unknown}
                >
                  알 수 없음
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="스크립트 데이터" name="script">
              <div style={{ color: 'green' }}>
                스크립트 데이터는 메모리 문제로 보여주지 않습니다.
              </div>
            </ODEntityLabeled>
            {AppOptions.SHOW_DEV_MENU && !storyId && (
              <StoryUploadScriptJson
                onScriptFileLoadedAsText={(text) =>
                  (finalJSONData.current = text)
                }
              />
            )}
            <Row>
              <Col md={6}>
                <ODEntityInput
                  keyPath="finishedAt"
                  label="작품 종료일(지정일로부터 7일후)"
                  name="finishedAt"
                  inputType="datetime-local"
                  placeholder="작품 종료일(지정일로부터 7일후 완결처리)"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityInput
                  keyPath="showOrder"
                  label="노출 우선순위"
                  name="showOrder"
                  placeholder="노출 우선순위를 설정해주세요. 숫자가 높을 수록 먼저 등장합니다."
                  inputType="number"
                />
              </Col>
              <Col md={6}>
                <ODEntityLabeled
                  label="완결 여부?(즉시 완결처리)"
                  name="isFinished"
                >
                  <ToggleButtonWrapper>
                    <ODFormToggleButton
                      key="isFinished_true"
                      name="isFinished"
                      keyPath="isFinished"
                      value={true}
                    >
                      Y
                    </ODFormToggleButton>
                    <ODFormToggleButton
                      key="isFinished_false"
                      name="isFinished"
                      keyPath="isFinished"
                      value={false}
                    >
                      N
                    </ODFormToggleButton>
                  </ToggleButtonWrapper>
                </ODEntityLabeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityLabeled label="커버 이미지" name="mainImageFile">
                  <ODImageFileInput
                    name="mainImageFile"
                    height={330}
                    width={220}
                    keyPath="mainImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
              <Col md={6}>
                <ODEntityLabeled
                  label="미공개 커버 이미지"
                  name="previewImageFile"
                >
                  <ODImageFileInput
                    name="previewImageFile"
                    height={330}
                    width={220}
                    keyPath="previewImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityLabeled
                  label="작품 상세 1:1 이미지"
                  name="introImageFile"
                >
                  <ODImageFileInput
                    name="introImageFile"
                    height={330}
                    width={330}
                    keyPath="introImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
              <Col md={6}>
                <ODEntityLabeled
                  label="작품 가로형 썸네일 4:3 이미지"
                  name="wideImageFile"
                >
                  <ODImageFileInput
                    name="wideImageFile"
                    height={330}
                    width={440}
                    keyPath="wideImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityLabeled
                  label="작품 배너 이미지"
                  name="bannerImageFile"
                >
                  <ODImageFileInput
                    name="bannerImageFile"
                    height={330}
                    width={330}
                    keyPath="bannerImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityLabeled
                  label="메인 캐릭터 프로필 사진"
                  name="mainCharacterImageFile"
                >
                  <ODImageFileInput
                    name="mainCharacterImageFile"
                    height={100}
                    width={100}
                    keyPath="mainCharacterImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
              <Col md={6}>
                <ODEntityLabeled
                  label="메인 캐릭터 이름 고정?"
                  name="isFixMainChrName"
                >
                  <ToggleButtonWrapper>
                    <ODFormToggleButton
                      key="isFixMainChrName_true"
                      name="isFixMainChrName"
                      keyPath="isFixMainChrName"
                      value={true}
                    >
                      Y
                    </ODFormToggleButton>
                    <ODFormToggleButton
                      key="isFixMainChrName_false"
                      name="isFixMainChrName"
                      keyPath="isFixMainChrName"
                      value={false}
                    >
                      N
                    </ODFormToggleButton>
                  </ToggleButtonWrapper>
                </ODEntityLabeled>
              </Col>
            </Row>
            <ODEntityLabeled label="성별" name="mainCharacterGender">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key={'mainCharacterGender_unknown'}
                  name={'mainCharacterGender'}
                  keyPath={'mainCharacterGender'}
                  value={GENDER.Unknown}
                >
                  Unknown
                </ODFormToggleButton>
                <ODFormToggleButton
                  key={'mainCharacterGender_male'}
                  name={'mainCharacterGender'}
                  keyPath={'mainCharacterGender'}
                  value={GENDER.Male}
                >
                  Male
                </ODFormToggleButton>
                <ODFormToggleButton
                  key={'mainCharacterGender_female'}
                  name={'mainCharacterGender'}
                  keyPath={'mainCharacterGender'}
                  value={GENDER.Female}
                >
                  Female
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="mainCharacterPrompt"
              label="주인공 AI 자케 의상 프롬프트"
              name="mainCharacterPrompt"
              placeholder="t-shirt"
              inputType="text"
            />
            <ODEntityInput
              keyPath="mainCharacterLabel"
              label="주인공 레이블"
              name="mainCharacterLabel"
              placeholder="주인공"
              inputType="text"
            />

            <ODEntityLabeled name="teaserVideoFile" label="티저 영상">
              <ODEntityRaw
                name="teaserVideoFile"
                keyPath="teaserVideoFile.link"
                render={(data) => {
                  // TODO: WARNING 로그 확인.
                  if (!video) {
                    setVideo(data.value)
                  }

                  return (
                    <>
                      <input
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files?.[0]
                          if (file) {
                            setVideo(URL.createObjectURL(file))
                            videoFile.current = file
                          }
                        }}
                      />
                      <br />
                      {video && (
                        <video key={video} width="320" height="240" controls>
                          <source type="video/mp4" src={video} />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      {!video && <p>티저 영상 없음. 업로드 해주세요!</p>}
                    </>
                  )
                }}
              />
            </ODEntityLabeled>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <hr />
            </div>
          </ODEntity>
        </CardBody>
      </Card>
    </>
  )
}

const ToggleButtonWrapper = styled.div`
  display: flex;
`
