"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryPrice = void 0;
const mobx_1 = require("mobx");
const DOStoryPriceEditor_1 = require("./DOStoryPriceEditor");
const storeUtils_1 = require("../../utils/storeUtils");
class DOStoryPrice {
    constructor(store, data) {
        var _a;
        this.paidPerChapterSetting = null;
        this.paidPerStorySetting = null;
        this.adPerChapterSetting = null;
        this.freedAtPerChapterSetting = null;
        this.waitFreePerChapterSetting = null;
        this.afterSomePeriodFreePerChapterSetting = null;
        this.timeLeapFreePerChapterSetting = null;
        this.isLoading = false;
        this.serviceCountries = [];
        this.rootStore = store.rootStore;
        this.store = store;
        this.data = data;
        this.serviceCountries = ((_a = data.story) === null || _a === void 0 ? void 0 : _a.serviceCountries) || [];
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            isLoading: mobx_1.observable,
            storyPriceSettingId: mobx_1.observable,
            story: mobx_1.observable,
            countryCode: mobx_1.observable,
            isFree: mobx_1.observable,
            paidPerChapterActivated: mobx_1.observable,
            paidPerChapterSetting: mobx_1.observable,
            paidPerStoryActivated: mobx_1.observable,
            paidPerStorySetting: mobx_1.observable,
            adPerChapterActivated: mobx_1.observable,
            adPerChapterSetting: mobx_1.observable,
            freedAtPerChapterActivated: mobx_1.observable,
            freedAtPerChapterSetting: mobx_1.observable,
            waitFreePerChapterActivated: mobx_1.observable,
            waitFreePerChapterSetting: mobx_1.observable,
            afterSomePeriodFreePerChapterActivated: mobx_1.observable,
            afterSomePeriodFreePerChapterSetting: mobx_1.observable,
            timeLeapFreePerChapterActivated: mobx_1.observable,
            timeLeapFreePerChapterSetting: mobx_1.observable,
            serviceCountries: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    async update(changeSet) {
        if (changeSet.isFree === true) {
            changeSet.paidPerChapterActivated = false;
            changeSet.paidPerStoryActivated = false;
            changeSet.freedAtPerChapterActivated = false;
            changeSet.afterSomePeriodFreePerChapterActivated = false;
            changeSet.waitFreePerChapterActivated = false;
            changeSet.timeLeapFreePerChapterActivated = false;
        }
        const res = await this.rootStore.di.server.updateEntityForStudio(this, changeSet);
        return res;
    }
    merge(data) {
        const fields = [
            'story',
            'storyPriceSettingId',
            'storyId',
            'countryCode',
            'isFree',
            'paidPerChapterActivated',
            'paidPerChapterSetting',
            'paidPerStoryActivated',
            'paidPerStorySetting',
            'adPerChapterActivated',
            'adPerChapterSetting',
            'freedAtPerChapterActivated',
            'freedAtPerChapterSetting',
            'waitFreePerChapterActivated',
            'waitFreePerChapterSetting',
            'afterSomePeriodFreePerChapterActivated',
            'afterSomePeriodFreePerChapterSetting',
            'timeLeapFreePerChapterActivated',
            'timeLeapFreePerChapterSetting',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    startEditStoryPrice() {
        return new DOStoryPriceEditor_1.DOStoryPriceEditor(this);
    }
}
exports.DOStoryPrice = DOStoryPrice;
