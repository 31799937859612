"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTSoundEffect = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const fields_1 = require("../../fields");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTSoundEffect extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.hasModalForEdit = true;
        this.fileField = new fields_1.StudioFileField('sfx', () => ({
            fileId: this.st.fileId,
            fileName: this.st.name,
            link: this.st.link,
        }), file => {
            (0, mobx_1.runInAction)(() => {
                var _a;
                this.st.fileId = file === null || file === void 0 ? void 0 : file.fileId;
                this.st.name = file === null || file === void 0 ? void 0 : file.fileName;
                this.st.link = (_a = file === null || file === void 0 ? void 0 : file.link) !== null && _a !== void 0 ? _a : '';
            });
        });
        (0, mobx_1.makeObservable)(this, {
            link: mobx_1.computed,
            name: mobx_1.computed,
            displayName: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get link() {
        return this.st.link;
    }
    get name() {
        var _a;
        return (_a = this.st.name) !== null && _a !== void 0 ? _a : trans('legacy_DOSTSoundEffect_sound');
    }
    get displayName() {
        var _a;
        if (!this.st.name) {
            return this.name;
        }
        const res = this.block.parentChapter.story.studioFileStore.getByResName(this.st.name);
        return (_a = res === null || res === void 0 ? void 0 : res.displayName) !== null && _a !== void 0 ? _a : this.name;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [...super.editorFields, this.fileField];
    }
    generateSheetColumnsAfterBackground() {
        // if (this.st.fileId) {
        //   throw new Error(`DOSTSoundEffect.fileId exists => 케이스 고려 필요.`)
        // }
        // [, 링크]
        const result = ['', this.link, '', '', '', '', ''];
        if (!!this.executeConditionOfStatement) {
            result.push(this.executeConditionOfStatement);
        }
        return result;
    }
    async submitEditing(opFactory) {
        return super.submitEditing(opFactory).then(r => {
            this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(__1.GA4_EVENT_NAME.SET_SOUND_EFFECT);
            return r;
        });
    }
    //
    // IStudioTranslator interface
    //
    async getMessagesToTranslate() {
        return null;
    }
}
exports.DOSTSoundEffect = DOSTSoundEffect;
