"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOEnding = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
/**
 * Character domain object.
 */
class DOEnding {
    constructor(store, data) {
        this.imageFile = null;
        this.previewImageFile = null;
        this.store = store;
        this.data = data;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            collectionDescription: mobx_1.observable,
            isFinal: mobx_1.observable,
            chapterId: mobx_1.observable,
            imageFile: mobx_1.observable,
            showOrder: mobx_1.observable,
            arrivalRate: mobx_1.observable,
            rateLevel: mobx_1.observable,
            previewImageFile: mobx_1.observable,
            displayName: mobx_1.observable,
            merge: mobx_1.action,
            serverId: mobx_1.computed,
        });
    }
    get serverId() {
        return this.endingId;
    }
    merge(data) {
        const fields = [
            'endingId',
            'name',
            'customId',
            'collectionDescription',
            'isFinal',
            'chapterId',
            'imageFile',
            'showOrder',
            'arrivalRate',
            'rateLevel',
            'previewImageFile',
            'displayName',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    async update(changeSet) {
        const res = await this.store.story.rootStore.di.server.updateEntityForStudio(this, changeSet);
        return res;
    }
}
exports.DOEnding = DOEnding;
