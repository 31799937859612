"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GNBEventManager = void 0;
const mobx_1 = require("mobx");
const i18n_1 = require("../../i18n");
const IGNBModalManager_1 = require("./IGNBModalManager");
const moment = require("moment");
class GNBEventManager {
    constructor(gnbStore) {
        this.eventInfo = null;
        this.webNovelEventInfo = null;
        this.gnbStore = gnbStore;
        (0, mobx_1.makeObservable)(this, {
            eventInfo: mobx_1.observable,
            webNovelEventInfo: mobx_1.observable,
            hasEvent: mobx_1.computed,
        });
    }
    get hasEvent() {
        return !!this.eventInfo;
    }
    get hasWebNovelEvent() {
        return !!this.webNovelEventInfo;
    }
    get webNovelEvent() {
        return this.webNovelEventInfo;
    }
    get isFinishedWebNovelEvent() {
        var _a;
        return this.webNovelEventInfo
            ? moment().isAfter(moment((_a = this.webNovelEventInfo) === null || _a === void 0 ? void 0 : _a.endAt))
            : true;
    }
    get isNowWebNovelEvent() {
        var _a, _b;
        return this.webNovelEventInfo
            ? !moment().isBefore(moment((_a = this.webNovelEventInfo) === null || _a === void 0 ? void 0 : _a.startAt)) &&
                !moment().isAfter(moment((_b = this.webNovelEventInfo) === null || _b === void 0 ? void 0 : _b.endAt))
            : false;
    }
    get modalManager() {
        return this.gnbStore.modalManager;
    }
    get rootStore() {
        return this.gnbStore.rootStore;
    }
    loadCurrentEvent() {
        // 인터랙티브
        this.rootStore.di.server
            .getCurrentContest()
            .then(r => {
            (0, mobx_1.runInAction)(() => {
                this.eventInfo = r;
            });
        })
            .catch(ex => this.rootStore.showError(ex));
        // 웹소설
        this.rootStore.di.server
            .getLatestWebNovelContest()
            .then(r => {
            (0, mobx_1.runInAction)(() => {
                this.webNovelEventInfo = r;
            });
        })
            .catch(ex => this.rootStore.showError(ex));
    }
    openEventGuideModal() {
        this.modalManager.openModal({
            type: IGNBModalManager_1.GNBModal.EventGuide,
        });
    }
    openStoryListForParticipatingInEvent() {
        this.modalManager.openModal({
            type: IGNBModalManager_1.GNBModal.StoryListForParticipatingInEvent,
        });
    }
    openParticipatingInEventModal(story) {
        this.modalManager.openModal({
            type: IGNBModalManager_1.GNBModal.ParticipatingInEvent,
            info: {
                story,
                onClose: () => this.modalManager.closeModal(),
            },
        });
    }
    openEditingStoryParticipatingInEventModal() {
        const story = this.rootStore.storyEditing;
        const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
        const hasEvent = this.hasEvent || (this.hasWebNovelEvent && !this.isFinishedWebNovelEvent);
        if (!hasEvent || !story) {
            return;
        }
        if (!story.isContest) {
            this.gnbStore.modalManager.openModal({
                type: IGNBModalManager_1.GNBModal.ParticipatingInEvent,
                info: {
                    story,
                    onClose: () => this.modalManager.closeModal(),
                },
            });
        }
        else {
            this.rootStore.showMessage(trans('legacy_GNBEventManager.ts_current_event_participation'));
        }
    }
}
exports.GNBEventManager = GNBEventManager;
