export const SiteUrls = {
  Admin: {
    Root: '',
    Main: '/user',
    Login: '/login',
    Logout: '/logout',
    FindPassword: '/findPassword',
    ScriptImporter: '/scriptImporter',
    PasswordTooOld: '/passwordTooOld',
    RegisterOTPKey: '/registerOTPKey',
    User: {
      Main: '/user',
      List: '/user',
      Create: '/user/create',
      // Detail: (id: string | number) => `/user/${id}`,
      Edit: (id: string | number) => `/user/${id}/edit`,
      EditMyProfile: `/myProfile`,
    },
    Story: {
      List: {
        Root: '/story',
        Published: '/story?tab=published_on_stage',
        Pending: '/story?tab=pending_on_stage',
        PublishedChallenge: '/story?tab=published_challenge',
        PendingChallenge: '/story?tab=pending_challenge',
      },
      Main: '/story',
      Create: '/story/create',
      Edit: (id: string | number, tab: number = 0) =>
        `/story/${id}/edit?tab=${tab}`,
      EditChr: (storyId: string | number, chrId: string | number) =>
        `/story/${storyId}/edit?tab=${2}&chrId=${chrId}`,
      EditBackground: (storyId: string | number, shfId: string | number) =>
        `/story/${storyId}/edit?tab=${4}&shfId=${shfId}`,
      EditEnding: (
        storyId: string | number,
        endingId: string | number,
        isFinal: string | boolean
      ) =>
        `/story/${storyId}/edit?tab=${5}&endingId=${endingId}&isFinal=${
          isFinal ? 1 : 0
        }`,
      EditStoryProperty: (storyId: string | number, propId: string | number) =>
        `/story/${storyId}/edit?tab=${6}&propId=${propId}`,
      EditEndingShowsProperty: (
        storyId: string | number,
        endingId: string | number,
        espId: string | number,
        isFinal: string | boolean
      ) =>
        `/story/${storyId}/edit?tab=${5}&endingId=${endingId}&espId=${espId}&isFinal=${
          isFinal ? 1 : 0
        }`,
      EditPreviewEnding: (
        storyId: string | number,
        previewEndingId: string | number
      ) => `/story/${storyId}/edit?tab=${7}&previewEndingId=${previewEndingId}`,
      EditChapterComment: (storyId: string | number, ccId: string | number) =>
        `/story/${storyId}/edit?tab=${8}&ccId=${ccId}`,
      EditStoryItem: (storyId: string | number, storyItemId: string | number) =>
        `/story/${storyId}/edit?tab=${10}&storyItemId=${storyItemId}`,
      EditEndingCard: (
        storyId: string | number,
        endingId: string | number,
        isFinal: string | boolean,
        cardId: string | number
      ) =>
        `/story/${storyId}/edit?tab=${5}&endingId=${endingId}&cardId=${cardId}&isFinal=${
          isFinal ? 1 : 0
        }`,
      EditOnboardingPopup: (
        storyId: string | number,
        popupId: string | number
      ) => `/story/${storyId}/edit?tab=${11}&popupId=${popupId}`,
      EditOnboardingPopupChoice: (
        storyId: string | number,
        popupId: string | number,
        popupChoiceId: string | number
      ) =>
        `/story/${storyId}/edit?tab=${11}&popupId=${popupId}&popupChoiceId=${popupChoiceId}`,
      ListChapter: (storyId: string | number) =>
        `/story/${storyId}/edit?tab=${1}`,
      EditStoryHasRecommendSection: (
        storyId: string | number,
        storyHasRecommendSectionId: string | number
      ) =>
        `/story/${storyId}/edit?tab=${13}&storyHasRecommendSectionId=${storyHasRecommendSectionId}`,
      ListStorygameCharacterPrompt: (storyId: string | number) =>
        `/story/${storyId}/edit?tab=14`,
      EditStorygameCharacterPrompt: (
        storyId: string | number,
        storygameCharacterPromptId: string | number
      ) =>
        `/story/${storyId}/edit?tab=14&storygameCharacterPromptId=${storygameCharacterPromptId}`,
      ListStorygameCharacterAdditionalPrompt: (
        storyId: string | number,
        gender?: string
      ) => `/story/${storyId}/edit?tab=15${gender ? `&gender=${gender}` : ''}`,
      EditStorygameCharacterAdditionalPrompt: (
        storyId: string | number,
        storygameCharacterAdditionalPromptId: string | number
      ) =>
        `/story/${storyId}/edit?tab=15&storygameCharacterAdditionalPromptId=${storygameCharacterAdditionalPromptId}`,
    },
    EPUBStory: {
      List: {
        Root: '/epubStory',
        Published: '/epubStory?tab=published_on_stage',
      },
      Main: '/epubStory',
      Create: '/epubStory/create',
      Edit: (id: string | number, tab: number = 0) =>
        `/epubStory/${id}/edit?tab=${tab}`,
      EditStoryHasRecommendSection: (
        storyId: string | number,
        storyHasRecommendSectionId: string | number
      ) =>
        `/epubStory/${storyId}/edit?tab=${3}&storyHasRecommendSectionId=${storyHasRecommendSectionId}`,
    },
    Notice: {
      List: '/notice',
      Create: '/notice/create',
      Edit: (id: string | number) => `/notice/${id}/edit`,
    },
    NoticePopup: {
      List: '/noticePopup',
      Create: '/noticePopup/create',
      Edit: (id: string | number) => `/noticePopup/${id}/edit`,
    },
    AppConfig: {
      List: '/appConfig',
      Update: '/appConfig/update',
    },
    Ending: {
      List: '/endings',
    },
    ImageFile: {
      List: '/imageFile',
      Create: '/imageFile/new',
    },
    SFXFile: {
      List: '/sfx',
      Create: '/sfx/new',
    },
    AdminLog: {
      List: '/adminLog',
    },
    BonusTicketCode: {
      List: '/bonusTicketCode',
      Create: '/bonusTicketCode/create',
      Edit: (id: string | number) => `/bonusTicketCode/${id}/edit`,
    },
    StoryOptions: {
      List: '/storyOptions',
    },
    Featured: {
      List: '/featured',
      Create: '/featured/create',
      Edit: (id: string | number) => `/featured/${id}/edit`,
    },
    HomeSection: {
      List: '/homeSection',
      Create: '/homeSection/create',
      Edit: (id: string | number, type: string) =>
        `/homeSection/${id}/edit?type=${type}`,
    },
    DirectAds: {
      List: '/directAds',
      Create: '/directAds/create',
      Edit: (id: string | number) => `/directAds/${id}/edit`,
    },
    AppSplashImage: {
      List: '/appSplashImage',
      Create: '/appSplashImage/create',
      Edit: (id: string | number) => `/appSplashImage/${id}/edit`,
    },
    RecommendationForFirstUser: {
      List: '/recommendationForFirstUser',
      Edit: (id: string | number) => `/recommendationForFirstUser/${id}/edit`,
    },
  },
  Author: {
    Root: '/author',
    Main: '/author/main',
  },
  // Play: {
  //   Story: (storyId: number | string) => `/play/story/${storyId}`,
  //   Chapter: (storyId: number | string, chapterName: string) => `/play/story/${storyId}/chapter/${chapterName}`,
  // },
  Terms: '/terms',
  Privacy: '/privacy',
}
