"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOEndingStore = void 0;
const mobx_1 = require("mobx");
const DOEnding_1 = require("./DOEnding");
class DOEndingStore {
    constructor(story) {
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            allEndings: mobx_1.computed,
            allFinalEndings: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    getByName(name) {
        return Object.values(this.data).find(v => v.name === name);
    }
    merge(data) {
        const id = data.endingId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOEnding_1.DOEnding(this, data);
        }
        return this.data[id];
    }
    get allEndings() {
        return Object.values(this.data);
    }
    get allFinalEndings() {
        return Object.values(this.data)
            .filter(v => v.isFinal)
            .sort((a, d) => d.showOrder - a.showOrder);
    }
}
exports.DOEndingStore = DOEndingStore;
