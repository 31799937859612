"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbCheckAPI = void 0;
class HbCheckAPI {
    constructor(server) {
        this.server = server;
    }
    get(botId) {
        return this.server.get(`/v1/bots/${botId}/check`);
    }
    delete(botId) {
        return this.server.delete(`/v1/bots/${botId}/check`, {});
    }
    check(botId) {
        return this.server.post(`/v1/bots/${botId}/check`, {}, { useErrorResponse: true });
    }
}
exports.HbCheckAPI = HbCheckAPI;
