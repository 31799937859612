import { ODIcons } from '../components/ODIcon'
import { ODSiteNav } from '../context/ODCommon'
import { SiteUrls } from '../urls'

export const AdminSiteNavs: ODSiteNav = {
  items: [
    {
      name: '사용자 관리',
      url: SiteUrls.Admin.User.List,
      icon: ODIcons.CorePeople,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '인터랙티브 스토리 관리',
      url: SiteUrls.Admin.Story.List.Published,
      icon: ODIcons.CoreBuilding,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '웹소설 스토리 관리',
      url: SiteUrls.Admin.EPUBStory.List.Published,
      icon: ODIcons.CoreFolderOpen,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '공지사항 관리',
      url: SiteUrls.Admin.Notice.List,
      icon: ODIcons.CoreLibraryAdd,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '팝업 공지사항 관리',
      url: SiteUrls.Admin.NoticePopup.List,
      icon: ODIcons.CoreImagePlus,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '환경 설정 관리',
      url: SiteUrls.Admin.AppConfig.List,
      icon: ODIcons.CoreSettings,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '엔딩 공유키 정보',
      url: SiteUrls.Admin.Ending.List,
      icon: ODIcons.CoreEnvelopeClosed,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '이미지 파일 관리',
      url: SiteUrls.Admin.ImageFile.List,
      icon: ODIcons.CoreGridSlash,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: 'SFX 파일 관리',
      url: SiteUrls.Admin.SFXFile.List,
      icon: ODIcons.CoreAvTimer,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '관리자 접근 로그',
      url: SiteUrls.Admin.AdminLog.List,
      icon: ODIcons.CoreEnvelopeClosed,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '내 프로필 변경',
      url: SiteUrls.Admin.User.EditMyProfile,
      icon: ODIcons.CorePencil,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '보너스 티켓 코드 관리',
      url: SiteUrls.Admin.BonusTicketCode.List,
      icon: ODIcons.CoreHttps,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '작품 옵션 관리',
      url: SiteUrls.Admin.StoryOptions.List,
      icon: ODIcons.CoreTags,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: {
        position: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '피쳐드 관리',
      url: SiteUrls.Admin.Featured.List,
      icon: ODIcons.MaterialStar,
      iconSpanStyle: { fontSize: '1rem' },
      iconStyle: {
        postMessage: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '홈 섹션 관리',
      url: SiteUrls.Admin.HomeSection.List,
      icon: ODIcons.MaterialArrowDownwardIcon,
      iconSpanStyle: { fontSize: '1rem' },
      iconStyle: {
        postMessage: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '직영광고 관리',
      url: SiteUrls.Admin.DirectAds.List,
      icon: ODIcons.Dashboard,
      iconSpanStyle: { fontSize: '1rem' },
      iconStyle: {
        postMessage: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '스플래쉬 이미지 관리',
      url: SiteUrls.Admin.AppSplashImage.List,
      icon: ODIcons.CoreAccountLogout,
      iconSpanStyle: { fontSize: '1rem' },
      iconStyle: {
        postMessage: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
    {
      name: '첫방문 유저 추천 작품 관리',
      url: SiteUrls.Admin.RecommendationForFirstUser.List,
      icon: ODIcons.CoreFrown,
      iconSpanStyle: { fontSize: '1rem' },
      iconStyle: {
        postMessage: 'relative',
        top: 1,
        paddingLeft: 2,
        marginRight: 10,
      },
    },
  ],
}
