"use strict";
// noinspection NonAsciiCharacters
Object.defineProperty(exports, "__esModule", { value: true });
exports.ITEM_OPERATION = exports.REMOTE_SCRIPT_TYPE = exports.PROP_OPERATION = exports.INVERTED_CONDITION_ACTION_MAP = exports.CONDITION_ACTION_MAP = exports.CLOSE_BRACKET = exports.FullScreenEffectFileType = exports.StorygameScreenFillMethod = exports.ScreenFillMethod = exports.TransitionStyle = exports.TextType = exports.ChoiceLayoutType = exports.ImageOperationType = exports.STYLE_TAG_TO_TAG_TYPE = exports.STYLE_TAG = exports.OFFSET_TO_FONT_SIZE_OFFSET_TAG_VALUE = exports.FONT_SIZE_OFFSET_TAG_VALUE_TO_OFFSET = exports.CONDITION_ACTION = exports.INVERTED_CONDITION_TYPE_MAP = exports.CONDITION_TYPE_MAP = exports.CONDITION_TYPE = exports.STATEMENT_TYPE = exports.NUM_MAX_CHOICES = void 0;
const lodash_1 = require("lodash");
/**
 * 선택지는 최대 5개로 고정되어 있다.
 */
exports.NUM_MAX_CHOICES = 5;
var STATEMENT_TYPE;
(function (STATEMENT_TYPE) {
    STATEMENT_TYPE["Condition"] = "Condition";
    STATEMENT_TYPE["Script"] = "Script";
    STATEMENT_TYPE["MainCharacterTalk"] = "MainCharacterTalk";
    STATEMENT_TYPE["CharacterTalk"] = "CharacterTalk";
    STATEMENT_TYPE["MainCharacterThink"] = "MainCharacterThink";
    STATEMENT_TYPE["CharacterThink"] = "CharacterThink";
    STATEMENT_TYPE["Place"] = "Place";
    STATEMENT_TYPE["Time"] = "Time";
    STATEMENT_TYPE["Choice"] = "Choice";
    STATEMENT_TYPE["ChoiceOption"] = "ChoiceOption";
    STATEMENT_TYPE["ChoiceSaveProp"] = "ChoiceSaveProp";
    STATEMENT_TYPE["ChoiceProp"] = "ChoiceProp";
    STATEMENT_TYPE["ChoiceDisplayName"] = "ChoiceDisplayName";
    STATEMENT_TYPE["ChoiceToBlock"] = "ChoiceToBlock";
    STATEMENT_TYPE["ChoiceCharacter"] = "ChoiceCharacter";
    STATEMENT_TYPE["ToBlock"] = "ToBlock";
    STATEMENT_TYPE["BackgroundImage"] = "BackgroundImage";
    STATEMENT_TYPE["Image"] = "Image";
    STATEMENT_TYPE["EndingSummary"] = "EndingSummary";
    STATEMENT_TYPE["FinalEnding"] = "FinalEnding";
    STATEMENT_TYPE["CollectionImage"] = "CollectionImage";
    STATEMENT_TYPE["CollectionDesc"] = "CollectionDesc";
    STATEMENT_TYPE["SaveProp"] = "SaveProp";
    STATEMENT_TYPE["ChoiceUIThinking"] = "ChoiceUIThinking";
    STATEMENT_TYPE["Toast"] = "Toast";
    STATEMENT_TYPE["MessageImage"] = "MessageImage";
    STATEMENT_TYPE["MainCharacterMessageImage"] = "MainCharacterMessageImage";
    STATEMENT_TYPE["FullWidthImage"] = "FullWidthImage";
    STATEMENT_TYPE["Vibration"] = "Vibration";
    STATEMENT_TYPE["SoundEffect"] = "SoundEffect";
    STATEMENT_TYPE["ChapterId"] = "ChapterId";
    STATEMENT_TYPE["AchievementEvent"] = "AchievementEvent";
    STATEMENT_TYPE["BGMon"] = "BGMon";
    STATEMENT_TYPE["BGMoff"] = "BGMoff";
    STATEMENT_TYPE["UpdateItem"] = "UpdateItem";
    STATEMENT_TYPE["FullWidthText"] = "FullWidthText";
    // [선택지 조건 XXX] row. 실제 스크립트 데이터로 등장하며, 새로운 타입 추가시 앱 처리도 필요함.
    STATEMENT_TYPE["ChoiceIfUpdateItem"] = "ChoiceIfUpdateItem";
    STATEMENT_TYPE["ChoiceIfSaveProp"] = "ChoiceIfSaveProp";
    STATEMENT_TYPE["ChoiceIfAchievementEvent"] = "ChoiceIfAchievementEvent";
    STATEMENT_TYPE["TTSCashing"] = "TTSCashing";
    STATEMENT_TYPE["CallRemoteScript"] = "CallRemoteScript";
    STATEMENT_TYPE["FinishRemoteScript"] = "FinishRemoteScript";
    STATEMENT_TYPE["FullScreenEffectOn"] = "FullScreenEffectOn";
    STATEMENT_TYPE["FullScreenEffectOff"] = "FullScreenEffectOff";
    STATEMENT_TYPE["Pause"] = "Pause";
    // 헬봇
    // typing(타이핑)은 스플 스튜디오의 잠시 정지 공통 사용
    STATEMENT_TYPE["ConditionalChoice"] = "ConditionalChoice";
    STATEMENT_TYPE["Algorithm"] = "Algorithm";
    STATEMENT_TYPE["HbMessageNext"] = "HbMessageNext";
    STATEMENT_TYPE["HbText"] = "HbText";
    STATEMENT_TYPE["HbImage"] = "HbImage";
    STATEMENT_TYPE["HbRest"] = "HbRest";
    STATEMENT_TYPE["HbResultImage"] = "HbResultImage";
    STATEMENT_TYPE["HbCarousel"] = "HbCarousel";
    STATEMENT_TYPE["HbReport"] = "HbReport";
    STATEMENT_TYPE["HbAdjustment"] = "HbAdjustment";
    STATEMENT_TYPE["HbCharge"] = "HbCharge";
    STATEMENT_TYPE["HbUrl"] = "HbUrl";
    STATEMENT_TYPE["HbAudio"] = "HbAudio";
    STATEMENT_TYPE["HbLimit"] = "HbLimit";
    STATEMENT_TYPE["HbAdvertisement"] = "HbAdvertisement";
    STATEMENT_TYPE["HbMatching"] = "HbMatching";
    STATEMENT_TYPE["HbJsonApi"] = "HbJsonApi";
    // 헬봇 > 사용자
    STATEMENT_TYPE["HbUserFeedBack"] = "HbUserFeedBack";
    STATEMENT_TYPE["HbUserSelect"] = "HbUserSelect";
    STATEMENT_TYPE["HbUserInput"] = "HbUserInput";
    STATEMENT_TYPE["HbUserTemplate"] = "HbUserTemplate";
    STATEMENT_TYPE["HbUserTarot"] = "HbUserTarot";
    STATEMENT_TYPE["HbUserImageUpload"] = "HbUserImageUpload";
})(STATEMENT_TYPE = exports.STATEMENT_TYPE || (exports.STATEMENT_TYPE = {}));
var CONDITION_TYPE;
(function (CONDITION_TYPE) {
    CONDITION_TYPE["PreviousEndingEqualsTo"] = "PreviousEndingEqualsTo";
    CONDITION_TYPE["PreviousEndingNotEqualsTo"] = "PreviousEndingNotEqualsTo";
    CONDITION_TYPE["PropertyEqualsTo"] = "PropertyEqualsTo";
    CONDITION_TYPE["PropertyNotEqualsTo"] = "PropertyNotEqualsTo";
    CONDITION_TYPE["PropertyGTE"] = "PropertyGTE";
    CONDITION_TYPE["PropertyLTE"] = "PropertyLTE";
    CONDITION_TYPE["NumOfStoryItemEqualsTo"] = "NumOfStoryItemEqualsTo";
    CONDITION_TYPE["NumOfStoryItemNotEqualsTo"] = "NumOfStoryItemNotEqualsTo";
    CONDITION_TYPE["NumOfStoryItemGTE"] = "NumOfStoryItemGTE";
    CONDITION_TYPE["NumOfStoryItemLTE"] = "NumOfStoryItemLTE";
    CONDITION_TYPE["PlayDataExpr"] = "PlayDataExpr";
})(CONDITION_TYPE = exports.CONDITION_TYPE || (exports.CONDITION_TYPE = {}));
// TODO: scripter.ts 에 있는 내용을 삭제하고 여기 것으로 사용하기
exports.CONDITION_TYPE_MAP = {
    '전회차엔딩이 파라메터와 같으면': CONDITION_TYPE.PreviousEndingEqualsTo,
    '전회차엔딩이 파라메터와 다르면': CONDITION_TYPE.PreviousEndingNotEqualsTo,
    '속성값이 같으면': CONDITION_TYPE.PropertyEqualsTo,
    '속성값이 같지 않으면': CONDITION_TYPE.PropertyNotEqualsTo,
    '속성값이 이상일때': CONDITION_TYPE.PropertyGTE,
    '속성값이 이하일때': CONDITION_TYPE.PropertyLTE,
    '속성값이 이상이면': CONDITION_TYPE.PropertyGTE,
    '속성값이 이하이면': CONDITION_TYPE.PropertyLTE,
    '아이템 개수가 같으면': CONDITION_TYPE.NumOfStoryItemEqualsTo,
    '아이템 개수가 다르면': CONDITION_TYPE.NumOfStoryItemNotEqualsTo,
    '아이템 개수가 이상이면': CONDITION_TYPE.NumOfStoryItemGTE,
    '아이템 개수가 이하이면': CONDITION_TYPE.NumOfStoryItemLTE,
    'JSON 조건식 사용': CONDITION_TYPE.PlayDataExpr,
};
exports.INVERTED_CONDITION_TYPE_MAP = (0, lodash_1.invert)(exports.CONDITION_TYPE_MAP);
var CONDITION_ACTION;
(function (CONDITION_ACTION) {
    CONDITION_ACTION["MoveToBlock"] = "MoveToBlock";
})(CONDITION_ACTION = exports.CONDITION_ACTION || (exports.CONDITION_ACTION = {}));
exports.FONT_SIZE_OFFSET_TAG_VALUE_TO_OFFSET = {
    sm: -2,
    md: 0,
    lg: 3,
    xlg: 5,
};
exports.OFFSET_TO_FONT_SIZE_OFFSET_TAG_VALUE = Object.fromEntries(Object.entries(exports.FONT_SIZE_OFFSET_TAG_VALUE_TO_OFFSET).map(item => item.reverse()));
// 해당 이넘의 값이 style 에 할당될 값을 의미합니다.
var STYLE_TAG;
(function (STYLE_TAG) {
    STYLE_TAG["BOLD"] = "bold";
    STYLE_TAG["ITALIC_BOLD"] = "italic_bold";
    STYLE_TAG["ITALIC"] = "italic";
})(STYLE_TAG = exports.STYLE_TAG || (exports.STYLE_TAG = {}));
exports.STYLE_TAG_TO_TAG_TYPE = {
    [STYLE_TAG.BOLD]: 'b',
    [STYLE_TAG.ITALIC_BOLD]: 'ib',
    [STYLE_TAG.ITALIC]: 'i',
};
var ImageOperationType;
(function (ImageOperationType) {
    ImageOperationType["TextOverlay"] = "TextOverlay";
    ImageOperationType["UserInputChoiceOverlay"] = "UserInputChoiceOverlay";
})(ImageOperationType = exports.ImageOperationType || (exports.ImageOperationType = {}));
var ChoiceLayoutType;
(function (ChoiceLayoutType) {
    ChoiceLayoutType["Default"] = "Default";
    ChoiceLayoutType["Statistics"] = "Statistics";
    ChoiceLayoutType["UserInputChoice"] = "UserInputChoice";
    ChoiceLayoutType["ImageChoice"] = "ImageChoice";
})(ChoiceLayoutType = exports.ChoiceLayoutType || (exports.ChoiceLayoutType = {}));
var TextType;
(function (TextType) {
    TextType["Default"] = "Default";
    TextType["TTS"] = "TTS";
})(TextType = exports.TextType || (exports.TextType = {}));
// 애니메이션에 대한 정의
var TransitionStyle;
(function (TransitionStyle) {
    TransitionStyle["None"] = "None";
    TransitionStyle["Fade"] = "Fade";
    TransitionStyle["Zoom"] = "Zoom";
})(TransitionStyle = exports.TransitionStyle || (exports.TransitionStyle = {}));
// 이미지 또는 로띠에 사용될 비율에 대한 정의
var ScreenFillMethod;
(function (ScreenFillMethod) {
    ScreenFillMethod["Stretch"] = "Stretch";
    ScreenFillMethod["AspectRatio"] = "AspectRatio";
})(ScreenFillMethod = exports.ScreenFillMethod || (exports.ScreenFillMethod = {}));
// 스토리게임에 사용될 이미지 또는 로띠에 사용될 비율에 대한 정의
var StorygameScreenFillMethod;
(function (StorygameScreenFillMethod) {
    StorygameScreenFillMethod["Stretch"] = "Stretch";
    StorygameScreenFillMethod["AspectRatioFill"] = "AspectRatioFill";
    StorygameScreenFillMethod["AspectRatioFit"] = "AspectRatioFit";
})(StorygameScreenFillMethod = exports.StorygameScreenFillMethod || (exports.StorygameScreenFillMethod = {}));
var FullScreenEffectFileType;
(function (FullScreenEffectFileType) {
    FullScreenEffectFileType["Image"] = "Image";
    FullScreenEffectFileType["Lottie"] = "Lottie";
})(FullScreenEffectFileType = exports.FullScreenEffectFileType || (exports.FullScreenEffectFileType = {}));
exports.CLOSE_BRACKET = '[/c]';
exports.CONDITION_ACTION_MAP = {
    블록이동: CONDITION_ACTION.MoveToBlock,
};
exports.INVERTED_CONDITION_ACTION_MAP = (0, lodash_1.invert)(exports.CONDITION_ACTION_MAP);
var PROP_OPERATION;
(function (PROP_OPERATION) {
    PROP_OPERATION["INCREASE_NUMBER"] = "INCREASE_NUMBER";
    PROP_OPERATION["SET_NUMBER"] = "SET_NUMBER";
    PROP_OPERATION["CALCULATE"] = "CALCULATE";
    PROP_OPERATION["SET_TEXT"] = "SET_TEXT";
})(PROP_OPERATION = exports.PROP_OPERATION || (exports.PROP_OPERATION = {}));
var REMOTE_SCRIPT_TYPE;
(function (REMOTE_SCRIPT_TYPE) {
    REMOTE_SCRIPT_TYPE["StoryPlay"] = "StoryPlay";
    REMOTE_SCRIPT_TYPE["HelloBot"] = "HelloBot";
    REMOTE_SCRIPT_TYPE["ChatGPT"] = "ChatGPT";
})(REMOTE_SCRIPT_TYPE = exports.REMOTE_SCRIPT_TYPE || (exports.REMOTE_SCRIPT_TYPE = {}));
var ITEM_OPERATION;
(function (ITEM_OPERATION) {
    ITEM_OPERATION["INCREASE_NUMBER"] = "INCREASE_NUMBER";
    ITEM_OPERATION["SET_NUMBER"] = "SET_NUMBER";
    ITEM_OPERATION["CALCULATE"] = "CALCULATE";
})(ITEM_OPERATION = exports.ITEM_OPERATION || (exports.ITEM_OPERATION = {}));
