"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateChoiceAndMerge = void 0;
const core_1 = require("@storyplay/core");
const StudioChangeOpFactory_1 = require("../../../changeOp/StudioChangeOpFactory");
const entity_1 = require("../entity");
const TemplateBase_1 = require("./TemplateBase");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class TemplateChoiceAndMerge extends TemplateBase_1.TemplateBase {
    constructor(editor) {
        super(editor);
        this.isSimpleStringInput = true;
        this.name = trans('legacy_TemplateChoiceAndMerge.ts_multi_choice_to_block');
        this.description = trans('legacy_TemplateChoiceAndMerge.ts_connect_multiple_choices_from_current_block_to_one_block');
        this.tags = [TemplateBase_1.TemplateTag.Block, TemplateBase_1.TemplateTag.BulkEditing];
        this.field = new entity_1.InputWithValidation('templateChoiceAndMerge', trans('legacy_TemplateChoiceAndMerge.ts_template_data_input'), trans('legacy_TemplateChoiceAndMerge.ts_choice_name_and_merging_block_name'), 'textarea', '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
        }).setAutoFocus();
    }
    submit() {
        var _a, _b;
        const lines = this.field.value
            .split('\n')
            .map(v => v.trim())
            .filter(v => v);
        if (lines.length < 4) {
            this.editor.chapterEditing.store.rootStore.di.showError(trans('legacy_TemplateChoiceAndMerge.ts_template_data_is_incorrect'));
            return;
        }
        const lastLine = lines.splice(lines.length - 1, 1)[0];
        const choiceName = lines.splice(0, 1)[0];
        const block = this.editor.lineBlockEditing.doBlock;
        const endBlockId = this.di.generateInternalHashId();
        const f = StudioChangeOpFactory_1.StudioChangeOpFactory.startBulk(this.chapter)
            .upsertNewEndBlock(block.uniqueId, entity_1.EndBlockType.Choice, endBlockId)
            .changeLineName(block.uniqueId, endBlockId, choiceName);
        const choiceUniqueIds = [];
        for (let choiceIndex = 0; choiceIndex < lines.length; choiceIndex += 1) {
            const line = lines[choiceIndex];
            const [name, toBlockName] = line.split(',').map(s => s.trim());
            if (choiceIndex < 2) {
                // 만들어질 때 선택지가 2개 있음.
                f.changeChoiceText({ block: block.uniqueId, uniqueId: endBlockId }, name, choiceIndex);
            }
            else {
                f.addChoice(block.uniqueId, endBlockId, name, entity_1.BLOCK_EMPTY, choiceIndex);
            }
            const blockName = toBlockName !== null && toBlockName !== void 0 ? toBlockName : name;
            let newIntermediateBlockUniqueId = (_a = this.chapter.blockStore.getById(blockName)) === null || _a === void 0 ? void 0 : _a.uniqueId;
            if (!newIntermediateBlockUniqueId) {
                newIntermediateBlockUniqueId = this.di.generateInternalHashId();
                f.createNewBlock(blockName, block.startingBackground, newIntermediateBlockUniqueId);
                f.changeChoiceToBlock(block.uniqueId, endBlockId, choiceIndex, blockName);
            }
            choiceUniqueIds.push(newIntermediateBlockUniqueId);
        }
        const finalBlockName = lastLine;
        let finalBlockUniqueId = (_b = this.chapter.blockStore.getById(finalBlockName)) === null || _b === void 0 ? void 0 : _b.uniqueId;
        if (!finalBlockUniqueId) {
            finalBlockUniqueId = this.di.generateInternalHashId();
            f.createNewBlock(finalBlockName, block.startingBackground, finalBlockUniqueId);
        }
        // tslint:disable-next-line:prefer-for-of
        for (let choiceIndex = 0; choiceIndex < choiceUniqueIds.length; choiceIndex += 1) {
            const id = this.di.generateInternalHashId();
            f.upsertNewEndBlock(choiceUniqueIds[choiceIndex], entity_1.EndBlockType.ToBlock, id);
            f.changeToBlockToBlock(choiceUniqueIds[choiceIndex], id, finalBlockName);
        }
        const op = f.finishBulk();
        return this.chapter.applyChangesOnChapter(op);
    }
}
exports.TemplateChoiceAndMerge = TemplateChoiceAndMerge;
