"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbAlgorithmButtonType = exports.HbUserButtonType = exports.HbButtonType = exports.SpButtonType = exports.BlockEditorInputButtonType = void 0;
/**
 * BlockEditorInput (하단 입력창) 에서 보여주기 위한 버튼의 종류들
 * 헬로우봇과 나눠서 가고 싶었지만 enum + 다른곳에서 많이 사용하고있어서 따로 객체를 관리는 못하고 include방식으로 ui에서 보여줄 예정. 그래서 헬봇은 객체가 따로있고 enum에도 따로 추가해서 2번 해줘여함.
 */
var BlockEditorInputButtonType;
(function (BlockEditorInputButtonType) {
    BlockEditorInputButtonType["Character"] = "Character";
    BlockEditorInputButtonType["Talk"] = "Talk";
    BlockEditorInputButtonType["Think"] = "Think";
    BlockEditorInputButtonType["Action"] = "Action";
    BlockEditorInputButtonType["Toast"] = "Toast";
    BlockEditorInputButtonType["FullWidthText"] = "FullWidthText";
    BlockEditorInputButtonType["SFX"] = "SFX";
    BlockEditorInputButtonType["BGM"] = "BGM";
    BlockEditorInputButtonType["Vibration"] = "Vibration";
    BlockEditorInputButtonType["BackgroundImage"] = "BackgroundImage";
    BlockEditorInputButtonType["FullWidthImage"] = "FullWidthImage";
    BlockEditorInputButtonType["FullScreenEffect"] = "FullScreenEffect";
    BlockEditorInputButtonType["ChrImage"] = "MainChrImage";
    BlockEditorInputButtonType["SaveProp"] = "SaveProp";
    BlockEditorInputButtonType["Pause"] = "Pause";
    BlockEditorInputButtonType["Choice"] = "Choice";
    BlockEditorInputButtonType["Condition"] = "Condition";
    BlockEditorInputButtonType["GoToBlock"] = "GoToBlock";
    BlockEditorInputButtonType["CallChatBot"] = "CallChatBot";
    BlockEditorInputButtonType["Ending"] = "Ending";
    BlockEditorInputButtonType["FinalEnding"] = "FinalEnding";
    BlockEditorInputButtonType["Template"] = "Template";
    BlockEditorInputButtonType["MorePlus"] = "MorePlus";
    // 여기서부턴 헬봇
    BlockEditorInputButtonType["HbMessageImage"] = "HbMessageImage";
    BlockEditorInputButtonType["HbMessageNext"] = "HbMessageNext";
    BlockEditorInputButtonType["AlgorithmBlock"] = "AlgorithmBlock";
    BlockEditorInputButtonType["HbMessageRest"] = "HbMessageRest";
    BlockEditorInputButtonType["HbMessageFeedback"] = "HbMessageFeedback";
    BlockEditorInputButtonType["HbMessageSelect"] = "HbMessageSelect";
    BlockEditorInputButtonType["HbMessageInput"] = "HbMessageInput";
    BlockEditorInputButtonType["HbMessageTemplate"] = "HbMessageTemplate";
    BlockEditorInputButtonType["HbMessageTarot"] = "HbMessageTarot";
})(BlockEditorInputButtonType = exports.BlockEditorInputButtonType || (exports.BlockEditorInputButtonType = {}));
exports.SpButtonType = {
    [BlockEditorInputButtonType.Character]: BlockEditorInputButtonType.Character,
    [BlockEditorInputButtonType.Talk]: BlockEditorInputButtonType.Talk,
    [BlockEditorInputButtonType.Think]: BlockEditorInputButtonType.Think,
    [BlockEditorInputButtonType.Action]: BlockEditorInputButtonType.Action,
    [BlockEditorInputButtonType.Toast]: BlockEditorInputButtonType.Toast,
    [BlockEditorInputButtonType.FullWidthText]: BlockEditorInputButtonType.FullWidthText,
    [BlockEditorInputButtonType.SFX]: BlockEditorInputButtonType.SFX,
    [BlockEditorInputButtonType.BGM]: BlockEditorInputButtonType.BGM,
    [BlockEditorInputButtonType.Vibration]: BlockEditorInputButtonType.Vibration,
    [BlockEditorInputButtonType.BackgroundImage]: BlockEditorInputButtonType.BackgroundImage,
    [BlockEditorInputButtonType.FullWidthImage]: BlockEditorInputButtonType.FullWidthImage,
    [BlockEditorInputButtonType.FullScreenEffect]: BlockEditorInputButtonType.FullScreenEffect,
    [BlockEditorInputButtonType.ChrImage]: BlockEditorInputButtonType.ChrImage,
    [BlockEditorInputButtonType.SaveProp]: BlockEditorInputButtonType.SaveProp,
    [BlockEditorInputButtonType.Pause]: BlockEditorInputButtonType.Pause,
    [BlockEditorInputButtonType.Choice]: BlockEditorInputButtonType.Choice,
    [BlockEditorInputButtonType.Condition]: BlockEditorInputButtonType.Condition,
    [BlockEditorInputButtonType.GoToBlock]: BlockEditorInputButtonType.GoToBlock,
    [BlockEditorInputButtonType.CallChatBot]: BlockEditorInputButtonType.CallChatBot,
    [BlockEditorInputButtonType.Ending]: BlockEditorInputButtonType.Ending,
    [BlockEditorInputButtonType.FinalEnding]: BlockEditorInputButtonType.FinalEnding,
    [BlockEditorInputButtonType.Template]: BlockEditorInputButtonType.Template,
    [BlockEditorInputButtonType.MorePlus]: BlockEditorInputButtonType.MorePlus, // + 더보기 버튼
};
// button include 할 때 Object.values(HelloBotButtonType) 하므로 value값이 중요.
exports.HbButtonType = {
    [BlockEditorInputButtonType.MorePlus]: BlockEditorInputButtonType.MorePlus,
    [BlockEditorInputButtonType.HbMessageImage]: BlockEditorInputButtonType.HbMessageImage,
    [BlockEditorInputButtonType.HbMessageNext]: BlockEditorInputButtonType.HbMessageNext,
    [BlockEditorInputButtonType.AlgorithmBlock]: BlockEditorInputButtonType.AlgorithmBlock,
    [BlockEditorInputButtonType.Pause]: BlockEditorInputButtonType.Pause,
};
exports.HbUserButtonType = {
    [BlockEditorInputButtonType.MorePlus]: BlockEditorInputButtonType.MorePlus,
    [BlockEditorInputButtonType.HbMessageRest]: BlockEditorInputButtonType.HbMessageRest,
    [BlockEditorInputButtonType.HbMessageFeedback]: BlockEditorInputButtonType.HbMessageFeedback,
    [BlockEditorInputButtonType.HbMessageSelect]: BlockEditorInputButtonType.HbMessageSelect,
    [BlockEditorInputButtonType.HbMessageInput]: BlockEditorInputButtonType.HbMessageInput,
    [BlockEditorInputButtonType.HbMessageTarot]: BlockEditorInputButtonType.HbMessageTarot,
    [BlockEditorInputButtonType.HbMessageTemplate]: BlockEditorInputButtonType.HbMessageTemplate,
};
exports.HbAlgorithmButtonType = {
    [BlockEditorInputButtonType.MorePlus]: BlockEditorInputButtonType.MorePlus,
    [BlockEditorInputButtonType.HbMessageImage]: BlockEditorInputButtonType.HbMessageImage,
    [BlockEditorInputButtonType.HbMessageRest]: BlockEditorInputButtonType.HbMessageRest,
    [BlockEditorInputButtonType.Pause]: BlockEditorInputButtonType.Pause,
    [BlockEditorInputButtonType.Pause]: BlockEditorInputButtonType.Pause,
};
