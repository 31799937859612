"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryItemStore = void 0;
const mobx_1 = require("mobx");
const statement_1 = require("../statement");
const DOStoryItem_1 = require("./DOStoryItem");
const IDOStoryItem_1 = require("./IDOStoryItem");
class DOStoryItemStore {
    constructor(story) {
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        this.errorItem = this.merge({
            storyItemId: IDOStoryItem_1.StoryItemErrorId,
            name: statement_1.ITEM_EMPTY,
            isHidden: false,
            imageFile: null,
        });
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            allStoryItems: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    getByName(name) {
        var _a;
        return (_a = Object.values(this.data).find(v => v.name === name)) !== null && _a !== void 0 ? _a : this.errorItem;
    }
    merge(data, isLocallyCreated = false) {
        const id = data.storyItemId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            const item = new DOStoryItem_1.DOStoryItem(this, data, isLocallyCreated);
            if (isLocallyCreated) {
                return item;
            }
            this.data[id] = item;
        }
        return this.data[id];
    }
    removeById(id) {
        delete this.data[id];
    }
    get allStoryItems() {
        return Object.values(this.data).filter(v => !v.isError);
    }
    generateOptions() {
        return this.allStoryItems.map(v => ({
            value: v,
            name: v.name,
        }));
    }
}
exports.DOStoryItemStore = DOStoryItemStore;
