import { useWrappedAPI } from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { AdminLoginPage } from '../components/AdminLoginPage'
import { LOGIN_STATE, useAppContext } from '../context/AppContext'
import { SiteUrls } from '../urls'
import { Utils } from '../utils/utils'
import { OTPInputModal } from './otp/OTPInputModal'

enum LOGIN_OTP_STATE {
  Register = 'Register',
  Check = 'Check',
}

export const AdminLoginContainer: React.FC = () => {
  const {
    loginUser,
    loginAdminWithOTP,
    state: { loginState },
  } = useAppContext()
  const [loading, setLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState(false)
  const [loginOTPState, setLoginOTPState] = React.useState<LOGIN_OTP_STATE | null>(null)

  const handleLogin = async (email: string, pw: string) => {
    try {
      const isRegisteredOTP = await loginUser({ email, password: pw })

      if (isRegisteredOTP) {
        // OTP가 등록이 되어 있다면
        setLoginOTPState(LOGIN_OTP_STATE.Check)
      } else {
        // OTP가 등록이 되어 있지 않다면
        setLoginOTPState(LOGIN_OTP_STATE.Register)
      }
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  const checkOTPToken = useWrappedAPI(
    async (input: { token: string }) => {
      const { token } = input
      const replacedToken = token.replace(' ', '')
      return loginAdminWithOTP({ token: replacedToken })
    },
    loading,
    setLoading,
    {
      onSuccess: async () => {
        window.setTimeout(() => {
          setRedirect(true)
        }, 300)
      },
    }
  )

  const handleCheckOTPToken = async (token: string) => {
    await checkOTPToken({ token })
  }

  if (loginOTPState === LOGIN_OTP_STATE.Register) {
    // return <Redirect to={SiteUrls.Admin.Main} />
    return <Redirect to={SiteUrls.Admin.RegisterOTPKey} />
  }

  if (redirect && loginState === LOGIN_STATE.LoggedIn) {
    return <Redirect to={SiteUrls.Admin.Main} />
  }

  return (
    <div>
      <OTPInputModal
        isOpen={loginOTPState === LOGIN_OTP_STATE.Check}
        onClose={() => {}}
        onConfirm={handleCheckOTPToken}
      />
      <AdminLoginPage onSubmitLogin={handleLogin} />
    </div>
  )
}
