"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTFinalEnding = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const IStudioClipboard_1 = require("../../../clipboard/IStudioClipboard");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTFinalEnding extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: __1.STATEMENT_TYPE.FinalEnding,
            endingId: block.parentChapter.getStory().generateEndingId(block),
            endingName: block.parentChapter.getStory().generateEndingName(),
            displayName: '',
            background: '',
        }, block, uniqueId);
        (0, mobx_1.makeObservable)(this, {
            displayName: mobx_1.computed,
            endingName: mobx_1.computed,
            endingId: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get endingName() {
        return this.st.endingName;
    }
    get displayName() {
        return this.st.displayName || this.st.endingName;
    }
    get endingId() {
        return this.st.endingId;
    }
    /**
     * 이 엔딩이 디폴트 엔딩인가?
     */
    get isDefaultEnding() {
        var _a;
        return ((_a = this.block.parentChapter) === null || _a === void 0 ? void 0 : _a.defaultEndingCustomId) === this.endingId;
    }
    generateSheetColumnsAfterBackground() {
        return [
            this.displayName,
            this.endingName,
            this.endingId,
            this.isDefaultEnding ? '디폴트 엔딩' : '',
        ];
    }
    validate() {
        const results = super.validate();
        const story = this.block.parentChapter.story;
        const mySubBlock = this.parentBlock.getMySubBlock(this);
        story.chapterStore.all.forEach(chapter => {
            chapter.blockStore.all.forEach(block => {
                block.statements.forEach(st => {
                    if ((st instanceof __1.DOSTEndingSummary ||
                        st instanceof __1.DOSTFinalEndingSubBlock) &&
                        st !== mySubBlock) {
                        if (st.endingId === this.endingId) {
                            results.push({
                                type: __1.StudioValidationType.EndingIdDuplicate,
                                source: this,
                                severity: __1.StudioValidationSeverity.Error,
                                stack: [],
                                extra: { chapter, block, st },
                            });
                        }
                        if (st.endingName === this.endingName) {
                            results.push({
                                type: __1.StudioValidationType.EndingNameDuplicate,
                                source: this,
                                severity: __1.StudioValidationSeverity.Error,
                                stack: [],
                                extra: { chapter, block, st },
                            });
                        }
                    }
                });
            });
        });
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    onCopyEvent() {
        var _a;
        const data = (0, lodash_1.cloneDeep)(this.data);
        data.endingId = this.block.parentChapter
            .getStory()
            .generateEndingId(this.block);
        data.endingName = trans('legacy_DOSTEndingSummary_copy_of_value', {
            value: data.endingName,
        });
        data.displayName = trans('legacy_DOSTEndingSummary_copy_of_value', {
            value: (_a = data.displayName) !== null && _a !== void 0 ? _a : '',
        });
        return {
            isStudio: true,
            dataType: IStudioClipboard_1.StudioClipboardDataType.Statements,
            lines: [data],
        };
    }
    replaceData(data) {
        var _a;
        const op = super.replaceData(data);
        (_a = this.block.getMySubBlock(this)) === null || _a === void 0 ? void 0 : _a.setupFields(true);
        return op;
    }
}
exports.DOSTFinalEnding = DOSTFinalEnding;
