"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertApplyChapterServerError = void 0;
const core_1 = require("@storyplay/core");
const validation_1 = require("../../validation");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
/**
 * DOChapter.applyDraft 에서 발생할 수 있는 서버 오류를 제대로 된 오류메시지로
 * 바꾸어주기 위한 클라이언트 코드
 */
function convertApplyChapterServerError(serverError, chapter) {
    var _a, _b, _c, _d;
    if (serverError.type !== validation_1.StudioValidationType.ExceptionDuringTransQuery) {
        return serverError;
    }
    const op = (_b = (_a = serverError.extra) === null || _a === void 0 ? void 0 : _a.op) !== null && _b !== void 0 ? _b : '';
    const ex = (_d = (_c = serverError.extra) === null || _c === void 0 ? void 0 : _c.ex) !== null && _d !== void 0 ? _d : '';
    if (!op || !ex) {
        // tslint:disable-next-line:no-console
        console.error(`Cannot handle apply chapter server error : `, serverError);
        return serverError;
    }
    if (op.startsWith('Removing ending:')) {
        if (ex.includes('foreign key constraint fails') &&
            (ex.includes('user_has_ending') || ex.includes('user_played_story'))) {
            const [, endingIdStr, ...rest] = op.split(':');
            const endingId = parseInt(endingIdStr, 10);
            const endingName = rest.join(':');
            return {
                severity: validation_1.StudioValidationSeverity.Error,
                type: validation_1.StudioValidationType.RemovedEndingHasPlayDataSoCannotApply,
                extra: {
                    endingId,
                    endingName,
                },
                fixable: async () => {
                    return chapter === null || chapter === void 0 ? void 0 : chapter.addEndingWithIdForLegacy(endingId, endingName);
                },
            };
        }
    }
    return {
        severity: validation_1.StudioValidationSeverity.Error,
        type: validation_1.StudioValidationType.UnhandledServerTransQueryError,
        extra: { error: JSON.stringify(serverError) },
    };
    // tslint:disable-next-line:no-console
    console.warn(trans('legacy_convertApplyChapterServerError_unhandled_error'), JSON.stringify(serverError));
    return serverError;
}
exports.convertApplyChapterServerError = convertApplyChapterServerError;
