import React from 'react'
import { InputType } from 'reactstrap/lib/Input'
import styled from 'styled-components'
import { ODInput } from '../../../ODEntityEditor/FormComponents/ODInput'
import { ODButton, ODButtonTheme } from '../../base/ODButton'
import { ODIcon, ODIcons } from '../../ODIcon'
import { ODModal, ODModalSize, ODModalTheme } from '../ODModal'
import { ODModalBody } from '../ODModalBody'
import { ODModalFooter } from '../ODModalFooter'
import { ODModalHeader } from '../ODModalHeader'

export interface IConfirmInputProps {
  title?: string
  message?: string
  yes?: string
  no?: string
  theme?: ODModalTheme
  size?: ODModalSize
  initial?: any
  inputType?: InputType
  placeholder?: string
  mask?: Array<string | RegExp>

  //
  isOpen: boolean
  onConfirm: (value: string | undefined) => void
}

interface IConfirmInputState {
  value: string
}

const Message = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2f353a;
`

export class ConfirmInput extends React.Component<IConfirmInputProps, IConfirmInputState> {
  constructor(props: IConfirmInputProps) {
    super(props)

    this.state = {
      value: props.initial || '',
    }
  }

  onClickYes() {
    this.props.onConfirm(this.state.value)
  }

  onClickNo() {
    this.props.onConfirm(undefined)
  }

  render() {
    const { value } = this.state
    const {
      title = 'Enter input',
      message = 'Please enter input value',
      yes = 'Confirm',
      no = 'Cancel',
      placeholder,
      mask,
      size = ODModalSize.Normal,
      isOpen,
      inputType,
    } = this.props

    return (
      <ODModal isOpen={isOpen} toggle={() => this.onClickNo()} size={size}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title={title} onClose={() => this.onClickNo()} headerBottomPadding={30}>
            <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 60 }} />
          </ODModalHeader>
          <ODModalBody>
            <Message>{message}</Message>
            <div style={{ height: 35 }} />
            <ODInput
              placeholder={placeholder || message}
              inputType={inputType}
              mask={mask}
              onChange={(value) => this.setState({ value })}
              value={value}
            />
          </ODModalBody>
          <ODModalFooter>
            <ODButton style={{ width: '35%' }} theme={ODButtonTheme.Cancel} onClick={() => this.onClickNo()}>
              {no}
            </ODButton>
            <ODButton style={{ width: '65%' }} theme={ODButtonTheme.Primary} onClick={() => this.onClickYes()}>
              {yes}
            </ODButton>
          </ODModalFooter>
        </div>
      </ODModal>
    )
  }
}
