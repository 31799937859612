"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultChapterStudioMetaData = void 0;
function getDefaultChapterStudioMetaData() {
    return {
        flowChart: {
            blockPosition: {},
        },
    };
}
exports.getDefaultChapterStudioMetaData = getDefaultChapterStudioMetaData;
