"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALSelectRadioInput = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const entity_1 = require("../entity");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ALSelectRadioInput {
    constructor(modalTitle, onSubmit, options, existingValue) {
        this.modalTitle = modalTitle;
        this.onSubmit = onSubmit;
        this.options = options;
        this.existingValue = existingValue;
    }
    async submit(changedValue) {
        return this.onSubmit(changedValue);
    }
    buildActionChain() {
        const selection = new entity_1.SelectionInput('selection', 'selection', this.existingValue, this.options, {
            uiType: 'radio',
        });
        const builder = new ActionChainBuilder_1.ActionChainBuilder('ALSelectRadioInput');
        builder.addModal(modalBuilder => {
            modalBuilder.setModalSize('small');
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: this.modalTitle,
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(selection));
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_ALCreateNewChapter_cancel'),
                        paddingSize: autoLayout_1.ButtonPaddingSize.PX6PY2,
                        onClick: () => modalBuilder.cancelAction(),
                    },
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: trans('legacy_ALSelectRadioInput.ts_change'),
                        paddingSize: autoLayout_1.ButtonPaddingSize.PX6PY2,
                        onClick: async () => {
                            if (this.existingValue === selection.value) {
                                modalBuilder.finishAction(null);
                                return;
                            }
                            if (await this.submit(selection.value)) {
                                modalBuilder.finishAction(null);
                                return;
                            }
                        },
                    },
                ],
            });
        });
        return builder.buildActionChain();
    }
}
exports.ALSelectRadioInput = ALSelectRadioInput;
