"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionModalBase = void 0;
class ActionModalBase {
    constructor() {
        this.shouldCancelOnClickOutside = true;
        this.promise = new Promise((resolve, reject) => {
            this.resolver = resolve;
            this.rejecter = reject;
        });
    }
    setCancelOnClickOutside(cancelOnClickOutside) {
        return this;
    }
    setOnActionStarted(cb) {
        this.onStart = cb;
        return this;
    }
    finishAction(res) {
        this.resolver(res);
    }
    cancelAction(ex) {
        this.rejecter(ex);
    }
    buildActionBase() {
        return {
            resolver: this.promise,
            onActionStarted: (res) => {
                var _a;
                (_a = this.onStart) === null || _a === void 0 ? void 0 : _a.call(this, res);
            },
        };
    }
}
exports.ActionModalBase = ActionModalBase;
