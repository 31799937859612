"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALImageWithInputArea = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const consts_1 = require("../../../consts");
const models_1 = require("../../../models");
const entity_1 = require("../entity");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ALImageWithInputArea {
    constructor(existingData, onSubmit, onFinishSubmit) {
        this.existingData = null;
        this.existingData = existingData;
        this.onSubmit = onSubmit;
        this.onFinishSubmit = onFinishSubmit;
    }
    submit(image, // 편집의 경우 없을 수 있음.
    option) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const data = {
            operation: models_1.ImageOperationType.UserInputChoiceOverlay,
            width: (_c = (_a = image === null || image === void 0 ? void 0 : image.width) !== null && _a !== void 0 ? _a : (_b = this.existingData) === null || _b === void 0 ? void 0 : _b.width) !== null && _c !== void 0 ? _c : 0,
            height: (_f = (_d = image === null || image === void 0 ? void 0 : image.height) !== null && _d !== void 0 ? _d : (_e = this.existingData) === null || _e === void 0 ? void 0 : _e.height) !== null && _f !== void 0 ? _f : 0,
            imageUrl: (_j = (_g = image === null || image === void 0 ? void 0 : image.link) !== null && _g !== void 0 ? _g : (_h = this.existingData) === null || _h === void 0 ? void 0 : _h.imageUrl) !== null && _j !== void 0 ? _j : '',
            leftTop: [option.x, option.y],
            rightTop: [option.x + option.width, option.y],
            leftBottom: [option.x, option.y + option.height],
            rightBottom: [option.x + option.width, option.y + option.height],
            align: 'center',
        };
        this.onSubmit(data);
    }
    buildActionChain() {
        const defX = this.existingData ? this.existingData.leftTop[0] : 0;
        const defY = this.existingData ? this.existingData.leftTop[1] : 0;
        const defW = this.existingData
            ? this.existingData.rightTop[0] - this.existingData.leftTop[0]
            : 100;
        const defH = this.existingData
            ? this.existingData.leftBottom[1] - this.existingData.leftTop[1]
            : 100;
        const imageRegionField = new entity_1.ImageRegionSelector(this.existingData
            ? { x: defX, y: defY, width: defW, height: defH }
            : undefined);
        let image = null;
        const xField = new entity_1.InputWithValidation('x-position', trans('legacy_ALImageWithInputArea.ts_x_coordinate'), trans('legacy_ALImageWithInputArea.ts_enter_x_coordinate'), 'text', defX.toString(), null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onBlur: () => {
                imageRegionField.updateRegionPartial({
                    x: parseInt(xField.value, 10) || 0,
                });
            },
            onSubmit: v => {
                imageRegionField.updateRegionPartial({
                    x: parseInt(v, 10) || 0,
                });
            },
        });
        xField.setEditing(true);
        const yField = new entity_1.InputWithValidation('y-position', trans('legacy_ALImageWithInputArea.ts_y_coordinate'), trans('legacy_ALImageWithInputArea.ts_enter_y_coordinate'), 'text', defY.toString(), null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onBlur: () => {
                imageRegionField.updateRegionPartial({
                    y: parseInt(yField.value, 10) || 0,
                });
            },
            onSubmit: () => {
                imageRegionField.updateRegionPartial({
                    y: parseInt(yField.value, 10) || 0,
                });
            },
        });
        yField.setEditing(true);
        const wField = new entity_1.InputWithValidation('width', trans('legacy_ALImageWithInputArea.ts_width'), trans('legacy_ALImageWithInputArea.ts_enter_width'), 'text', defW.toString(), null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onBlur: () => {
                imageRegionField.updateRegionPartial({
                    width: parseInt(wField.value, 10) || 0,
                });
            },
            onSubmit: () => {
                imageRegionField.updateRegionPartial({
                    width: parseInt(wField.value, 10) || 0,
                });
            },
        });
        wField.setEditing(true);
        const hField = new entity_1.InputWithValidation('height', trans('legacy_ALImageWithInputArea.ts_height'), trans('legacy_ALImageWithInputArea.ts_enter_height'), 'text', defH.toString(), null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onBlur: () => {
                imageRegionField.updateRegionPartial({
                    height: parseInt(hField.value, 10) || 0,
                });
            },
            onSubmit: () => {
                imageRegionField.updateRegionPartial({
                    height: parseInt(hField.value, 10) || 0,
                });
            },
        });
        hField.setEditing(true);
        imageRegionField.addOnChangeListener(() => {
            const { x, y, width, height } = imageRegionField.regionData;
            xField.resetWithValue(x.toString());
            yField.resetWithValue(y.toString());
            wField.resetWithValue(width.toString());
            hField.resetWithValue(height.toString());
        });
        const builder = new ActionChainBuilder_1.ActionChainBuilder('ALImageWithInputArea');
        // 편집시에는 이미지는 재선택하지 않는다.
        if (this.existingData) {
            imageRegionField.setSource(this.existingData.imageUrl);
        }
        else {
            builder.pickImage(b => {
                b.setOnActionStarted(() => {
                    // if (!this.editor.canAddToCurrentCursor) {
                    //   b.cancelAction(new Error('문장을 추가할 수 없는 위치입니다.'))
                    // }
                });
                b.setTitle(trans('legacy_ALImageWithInputArea.ts_select_image'))
                    .setOnImagePick(pickedImage => {
                    image = pickedImage;
                    imageRegionField.setSource(pickedImage.link);
                    b.finishAction(pickedImage);
                })
                    .setActionBeforeCancel(() => {
                    b.cancelAction();
                    this.onFinishSubmit();
                })
                    .setCompleteButtonText(trans('legacy_ActionPickImageBuilder_select'));
            });
        }
        builder.addModal(modalBuilder => {
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: trans('legacy_ALImageWithInputArea.ts_add_natural_language_input_area_to_image'),
                imageName: consts_1.SPImageName.MenuNovel,
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(imageRegionField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(xField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(yField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(wField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(hField));
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_ALCreateNewChapter_cancel'),
                        onClick: () => {
                            this.onFinishSubmit();
                            modalBuilder.cancelAction();
                        },
                    },
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: trans('legacy_ALCreateNewChapter_complete'),
                        onClick: () => {
                            this.submit(image, imageRegionField.regionData);
                            this.onFinishSubmit();
                            modalBuilder.finishAction(null);
                        },
                    },
                ],
            });
        });
        return { ...builder.buildActionChain(), editorFields: [] };
    }
}
exports.ALImageWithInputArea = ALImageWithInputArea;
