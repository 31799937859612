"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryPropertyStore = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const statement_1 = require("../statement");
const DOStoryProperty_1 = require("./DOStoryProperty");
const IDOStoryProperty_1 = require("./IDOStoryProperty");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOStoryPropertyStore {
    constructor(story) {
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        this.errorProp = this.merge({
            propId: IDOStoryProperty_1.StoryPropertyErrorId,
            propName: statement_1.PROP_EMPTY,
            propNameRaw: statement_1.PROP_EMPTY,
            isMain: false,
            isUsed: false,
            showOrder: 1,
            maxValue: 100,
            imageFile: null,
            displayPropName: trans('legacy_DOStoryPropertyStore_property_value_error'),
        });
        this.errorMainProp = this.merge({
            propId: IDOStoryProperty_1.StoryPropertyErrorIdMain,
            propName: statement_1.PROP_EMPTY,
            propNameRaw: statement_1.PROP_EMPTY,
            isMain: true,
            isUsed: false,
            showOrder: 1,
            maxValue: 100,
            imageFile: null,
            displayPropName: trans('legacy_DOStoryPropertyStore_property_value_error'),
        });
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            all: mobx_1.computed,
            allMain: mobx_1.computed,
            allNonMain: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    getByName(name, forMain) {
        var _a;
        return ((_a = Object.values(this.data).find(v => v.propName === name)) !== null && _a !== void 0 ? _a : (forMain ? this.errorMainProp : this.errorProp));
    }
    merge(data, isLocallyCreated = false) {
        const id = data.propId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            const prop = new DOStoryProperty_1.DOStoryProperty(this, data, isLocallyCreated);
            if (isLocallyCreated) {
                return prop;
            }
            this.data[id] = prop;
        }
        return this.data[id];
    }
    removeById(id) {
        delete this.data[id];
    }
    get all() {
        return Object.values(this.data).filter(v => !v.isError);
    }
    get allMain() {
        return Object.values(this.data)
            .filter(v => v.isMain && !v.isError)
            .sort((a, d) => d.showOrder - a.showOrder);
    }
    get allNonMain() {
        return Object.values(this.data).filter(v => !v.isMain && !v.isError);
    }
    exportMetaDataUpdateActions() {
        return (0, lodash_1.flatten)(this.all.map(v => v.exportMetaDataUpdateActions()));
    }
    generateOptions(mainProp) {
        return (mainProp ? this.allMain : this.allNonMain).map(v => ({
            value: v,
            name: v.convertedPropName,
        }));
    }
}
exports.DOStoryPropertyStore = DOStoryPropertyStore;
