"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThingsflowAIServer = void 0;
/**
 * 23.05.04 진웅
 * NOTE: 원래 구현은 스튜디오 웹에서 직접 AI 서버를 호출하여 블록을 생성하고자 하였기에 아래와 같은
 * 코드를 구현하였으나, AI 서버의 CORS 문제가 해결이 되지 않아 백엔드에서 호출하는 방식으로 변경하였음.
 * 향후에는 아래 ai 서버를 di 에 inject 하여 서버에서도 이걸 사용해야 함.
 */
class ThingsflowAIServer {
    constructor(client) {
        this.client = client;
    }
    async generateBlocksForChapter(name, synopsis, ending) {
        // return this.client.post(
        //   'https://block-generator.dt.r.appspot.com/generate',
        //   { title: name, content: synopsis, ending }
        // )
        return null;
    }
}
exports.ThingsflowAIServer = ThingsflowAIServer;
