"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2FunctionStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const DOPlayerReport2Function_1 = require("./DOPlayerReport2Function");
const DOPlayerReport2FunctionEditor_1 = require("./DOPlayerReport2FunctionEditor");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOPlayerReport2FunctionStore {
    constructor(rootStore, ide) {
        this.data = {};
        this.rootStore = rootStore;
        this.ide = ide;
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            merge: mobx_1.action,
            all: mobx_1.computed,
        });
    }
    async create(data) {
        var _a, _b, _c, _d;
        try {
            const res = await this.rootStore.di.server.createPlayerReport2Function({
                name: data.name,
                desc: (_a = data.desc) !== null && _a !== void 0 ? _a : '',
                code: (_b = data.code) !== null && _b !== void 0 ? _b : '',
                codeSample: (_c = data.codeSample) !== null && _c !== void 0 ? _c : '',
                paramDesc: (_d = data.paramDesc) !== null && _d !== void 0 ? _d : '',
            });
            return this.merge(res);
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    getById(prId) {
        return this.data[prId];
    }
    async removeById(prfId) {
        try {
            const res = await this.rootStore.di.server.removePlayerReport2Function(prfId);
            if (res.ok) {
                (0, mobx_1.runInAction)(() => {
                    delete this.data[prfId];
                });
                this.rootStore.di.showMessage(trans('legacy_DOPlayerReport2FunctionStore_It_has_been_deleted'));
            }
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    merge(data) {
        const id = data.prfId;
        if (this.data[id]) {
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOPlayerReport2Function_1.DOPlayerReport2Function(this, data);
        }
        return this.data[id];
    }
    get all() {
        return Object.values(this.data).sort((a, d) => a.prfId - d.prfId);
    }
    startEditing(prfId) {
        const e = this.getById(prfId);
        if (!e) {
            throw new Error(`Cannot find report function id : ${prfId}`);
        }
        return new DOPlayerReport2FunctionEditor_1.DOPlayerReport2FunctionEditor(e, this.ide.env);
    }
}
exports.DOPlayerReport2FunctionStore = DOPlayerReport2FunctionStore;
