"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseDoChapterStoreStrategy = void 0;
// 원본 클래스인 챕터스토어에는 DO접두어가 없지만 넣어본다.
class BaseDoChapterStoreStrategy {
    //DoStoryStrategy에도 동일한 주석을 달았지만, 쓸데없이 private을 쓰지 않는다. 숨겨야 할 것만 숨긴다. 그 외에는 드러낸다.
    constructor(doChapterStore) {
        this.doChapterStore = doChapterStore;
    }
}
exports.BaseDoChapterStoreStrategy = BaseDoChapterStoreStrategy;
