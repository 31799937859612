"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChapterStore = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const DOChapter_1 = require("./DOChapter");
class ChapterStore {
    constructor(story, doChapterStoreStrategyConstructor) {
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        this.doChapterStoreStrategy = new doChapterStoreStrategyConstructor(this);
        this.latestPageSortRequest =
            this.doChapterStoreStrategy.initialPageSortRequest;
        // TODO : 최초 로드시에 실제로 쓰는 페이지 소트 리퀘스트를 가져와야 한다.
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            merge: mobx_1.action,
            all: mobx_1.computed,
            lastChapter: mobx_1.computed,
            lastPublishedChapter: mobx_1.computed,
        });
    }
    getPreviousChapterOf(chapterId) {
        var _a;
        const c = this.getById(chapterId);
        if (!c || c.chapterIndex <= 1) {
            return null;
        }
        return ((_a = Object.values(this.data).find(v => v.chapterIndex === c.chapterIndex - 1)) !== null && _a !== void 0 ? _a : null);
    }
    async refreshById(chapterId) {
        try {
            const res = await this.rootStore.di.server.getChapter(chapterId);
            return this.merge(res);
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    async refreshChaptersInStory(storyId) {
        try {
            const res = await this.rootStore.di.server.listChapterForStudio({
                page: 1,
                pageSize: 1000,
                storyId,
            });
            res.list.forEach(gql => this.merge(gql));
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    getById(chapterId) {
        return this.data[chapterId];
    }
    merge(data) {
        const id = data.chapterId;
        if (this.data[id]) {
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOChapter_1.DOChapter(this, data, this.story);
        }
        return this.data[id];
    }
    removeById(chapterId) {
        (0, mobx_1.runInAction)(() => delete this.data[chapterId]);
    }
    get all() {
        return Object.values(this.data).sort((a, d) => a.chapterIndex - d.chapterIndex);
    }
    /**
     * 공개된 가장 최근 챕터
     */
    get lastPublishedChapter() {
        var _a;
        return ((_a = this.all
            .filter(v => v.publishedAt)
            .sort((a, d) => d.chapterIndex - a.chapterIndex)[0]) !== null && _a !== void 0 ? _a : null);
    }
    get firstChapter() {
        return this.all.sort((a, b) => a.chapterIndex - b.chapterIndex)[0];
    }
    get lastChapter() {
        var _a;
        return (_a = (0, lodash_1.last)(this.all)) !== null && _a !== void 0 ? _a : null;
    }
    clear() {
        this.data = {};
    }
    // 헬봇용 알고리즘블록그룹 구하기
    async loadPaginatedChapters(pageSortRequest) {
        const chapters = await this.doChapterStoreStrategy.loadPaginatedChapters(pageSortRequest);
        await (0, mobx_1.runInAction)(async () => {
            // this.clear()
            this.latestPageSortRequest = pageSortRequest;
            chapters.forEach(chapter => this.merge(chapter));
        });
        return Promise.resolve();
    }
    // 헬봇용 알고리즘블록그룹 구하기
    async loadAlgorithmBlock(groupId, paginationConfig) {
        const chapter = await this.doChapterStoreStrategy.loadPaginatedAlgorithmBlock(groupId, paginationConfig);
        await (0, mobx_1.runInAction)(async () => {
            // this.clear()
            this.merge(chapter);
        });
        return Promise.resolve();
    }
}
exports.ChapterStore = ChapterStore;
