"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseClipboardItem = void 0;
const IStudioClipboard_1 = require("./IStudioClipboard");
async function parseClipboardItem(items) {
    if (!items) {
        return null;
    }
    for (const item of items) {
        if (!item.types.includes(IStudioClipboard_1.StudioClipboardType)) {
            continue;
        }
        const encoded = await item.getType(IStudioClipboard_1.StudioClipboardType);
        const text = await encoded.text();
        try {
            const data = JSON.parse(text);
            if (data.isStudio) {
                return data;
            }
        }
        catch (ex) {
            //
        }
    }
    return null;
}
exports.parseClipboardItem = parseClipboardItem;
