"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storyUtils = void 0;
const lodash_1 = require("lodash");
const server_schema_1 = require("../@types/server.schema");
const const_1 = require("../const");
exports.storyUtils = {
    /**
     * JSON.stringify 되어있는 studioPriceSetting 을 STUDIO_STORY_PRICE_OPTION 으로 변환해줍니다.
     */
    parseStudioPriceSetting(studioPriceSetting) {
        const FREE_OPTION = {
            type: const_1.STUDIO_STORY_PRICE_SETTING_TYPE.FREE,
        };
        if (!studioPriceSetting) {
            return FREE_OPTION;
        }
        try {
            const parsed = JSON.parse(studioPriceSetting);
            // 혹시 이상한 값이 온다면 방어 처리
            if (!Object.values(const_1.STUDIO_STORY_PRICE_SETTING_TYPE).includes(parsed.type)) {
                return FREE_OPTION;
            }
            return parsed;
        }
        catch {
            return FREE_OPTION;
        }
    },
    /**
     * STUDIO_STORY_PRICE_OPTION 타입 객체를 기획서에 정의된 텍스트에 맞게 줄여줍니다.
     * ex) 3코인(25화~), 무료, 광고(25화~)
     */
    getDescOfStudioPriceSetting(studioPriceSetting) {
        const studioStoryPriceOption = exports.storyUtils.parseStudioPriceSetting(studioPriceSetting);
        if (!studioStoryPriceOption ||
            studioStoryPriceOption.type === const_1.STUDIO_STORY_PRICE_SETTING_TYPE.FREE) {
            return '무료';
        }
        if (studioStoryPriceOption.type === const_1.STUDIO_STORY_PRICE_SETTING_TYPE.FREE_AD) {
            return `광고(${studioStoryPriceOption.chapterIndex}화~)`;
        }
        return `${studioStoryPriceOption.coin}코인(${studioStoryPriceOption.chapterIndex}화~)`;
    },
    getGenresByPlayType(playType) {
        if (playType === server_schema_1.GQLSTORY_PLAY_TYPE.Interactive) {
            return Object.values(server_schema_1.GQLCHALLENGE_STORY_GENRE).filter(item => ![
                server_schema_1.GQLCHALLENGE_STORY_GENRE.UNKNOWN,
                server_schema_1.GQLCHALLENGE_STORY_GENRE.CONTEST_WINNER,
                server_schema_1.GQLCHALLENGE_STORY_GENRE.MODERN_FANTASY,
                server_schema_1.GQLCHALLENGE_STORY_GENRE.PERIOD_DRAMA,
            ].includes(item));
        }
        return Object.values(server_schema_1.GQLCHALLENGE_STORY_GENRE).filter(item => ![
            server_schema_1.GQLCHALLENGE_STORY_GENRE.UNKNOWN,
            server_schema_1.GQLCHALLENGE_STORY_GENRE.CONTEST_WINNER,
            server_schema_1.GQLCHALLENGE_STORY_GENRE.MODERN_FANTASY,
            server_schema_1.GQLCHALLENGE_STORY_GENRE.SLICE_OF_LIFE,
        ].includes(item));
    },
    generateChapterCustomId() {
        return (new Date().getTime() * 1000 + (0, lodash_1.random)(0, 999)).toString();
    },
};
