"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioRootStore = void 0;
const core_1 = require("@storyplay/core");
const events_1 = require("events");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../@types/server.schema");
const autoLayout_1 = require("../../autoLayout");
const consts_1 = require("../../consts");
const errors_1 = require("../../errors");
const StudioContextMenuManager_1 = require("./contextmenu/StudioContextMenuManager");
const entity_1 = require("./entity");
const DOStoryPriceStore_1 = require("./entity/price/DOStoryPriceStore");
const LoginStore_1 = require("./entity/ui/LoginStore");
const GNBStore_1 = require("./gnb/GNBStore");
const spDoStoryStoreStrategy_1 = require("./strategy/doStoryStoreStrategy/spDoStoryStoreStrategy");
const studioUrls_1 = require("./studioUrls/studioUrls");
const TextStore_1 = require("./TextStore");
const storeUtils_1 = require("./utils/storeUtils");
class StudioRootStore {
    constructor(di) {
        this.channel = new events_1.EventEmitter();
        this.textStore = new TextStore_1.TextStore();
        this.messageColorMap = {};
        this.playerReportCSSLink = '';
        this.storyEditing = null;
        this.storyPrice = null;
        // 이번에 추가된 스토리게임도 storyPrice 와 같은 형식으로 로드한다.
        // 추후 필요하다면 더 간단한 방법으로 스토어에서는 로드함수만 지원하고 컴포넌트단에서 아이디를 통해 불러오는 방식으로 해도 좋을 것 같다.
        // 지금은 하나의 플레이 타입이 추가될때마다 변수, 메서드까지 추가되어야 한다.
        this.storyGamePrice = null;
        // 최종 엔딩 모달 이미지 에디터를 띄우는 경우에 이를 ODSEndingBlockModal 에서 알아야 한다.
        // noShowModal props 를 설정하기 위해서인데, 이 과정이 없으면 텍스트 입력이 제대로 되지 않는다.
        // 이게 좋은 방법이라고 생각되지는 않지만, 현재 모달 설계를 변경하지 않는 이상 더 좋은 방법을 찾지 못했다.
        this.showingImageEditor = false;
        this.showingImageBGSelector = false;
        // 아래 두개의 값은 어드민 이상만 불러올 수 있고, 어드민 이상일때 호출됩니다.
        this.serviceSystemLanguages = [];
        this.serviceCountries = [];
        this.screenSize = core_1.ScreenSize.NotMobile;
        this.loadingUserData = false;
        // 핸들링되지 않은 오류를 설정할 수 있으며, 이 경우 전체 오류 페이지로 이동시키게 된다.
        this.unrecoverableError = null;
        this.di = di;
        this.di.server.setRootStore(this);
        this.userStore = new entity_1.DOUserStore(this);
        this.storyStore = new entity_1.DOStoryStore(this, spDoStoryStoreStrategy_1.SpDoStoryStoreStrategy);
        this.storyPriceStore = new DOStoryPriceStore_1.DOStoryPriceStore(this);
        this.loginStore = new LoginStore_1.LoginStore(this);
        this.studioTutorialStore = new entity_1.DOStudioTutorialStore(this);
        this.contextMenuManager = new StudioContextMenuManager_1.StudioContextMenuManager(this);
        this.gnbStore = new GNBStore_1.GNBStore(this);
        this.autoLayoutManager = new autoLayout_1.AutoLayoutManager(di.showError.bind(di));
        this.channel.setMaxListeners(Number.POSITIVE_INFINITY);
        this.serviceType = 'sp';
        const selection = new entity_1.SelectionInput('storiesFilter', '스토리 리스트 필터', core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.PUBLISHED_AT_DESC, [
            {
                name: '최근 발행순',
                value: core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.PUBLISHED_AT_DESC,
                description: '최근 발행순',
            },
            {
                name: '오래된 발행순',
                value: core_1.GQLLIST_STORY_FOR_WRITER_SORT_OPTION.PUBLISHED_AT_ASC,
                description: '오래된 발행순',
            },
            // {
            //   name: '최신 변경순',
            //   value: StoriesSorting.UpdatedAtDesc,
            //   description: '최신 변경순',
            // },
            // 서버 구현될 때까지 막아둠.
            // {
            //   name: '인기순',
            //   value: StoriesSorting.PopularDesc,
            //   description: '인기순',
            // },
        ], {
            creatable: false,
        });
        this.storyListStore = new entity_1.StoryListStore(this, consts_1.TabForStoryList.UnPublished, selection);
        (0, mobx_1.makeObservable)(this, {
            showingImageEditor: mobx_1.observable,
            showingImageBGSelector: mobx_1.observable,
            messageColorMap: mobx_1.observable,
            playerReportCSSLink: mobx_1.observable,
            screenSize: mobx_1.observable,
            storyEditing: mobx_1.observable,
            storyPrice: mobx_1.observable,
            loadingUserData: mobx_1.observable,
            unrecoverableError: mobx_1.observable,
            serviceSystemLanguages: mobx_1.observable,
            serviceCountries: mobx_1.observable,
            chapterEditing: mobx_1.computed,
            isMobileSize: mobx_1.computed,
        });
    }
    setUnrecoverableError(error) {
        (0, mobx_1.runInAction)(() => (this.unrecoverableError = error));
        if (this.unrecoverableError) {
            // tslint:disable-next-line:no-console
            console.error('## error: ', error);
            this.di.redirectToUrl(studioUrls_1.StudioUrls.GlobalError);
        }
    }
    get isMobileSize() {
        return this.screenSize === core_1.ScreenSize.Mobile;
    }
    setScreenSize(screenSize) {
        (0, mobx_1.runInAction)(() => {
            this.screenSize = screenSize;
        });
    }
    setShowingImageEditor(showing) {
        (0, mobx_1.runInAction)(() => (this.showingImageEditor = showing));
    }
    setShowingImageBGSelector(showing) {
        (0, mobx_1.runInAction)(() => (this.showingImageBGSelector = showing));
    }
    async loadUserData() {
        (0, mobx_1.runInAction)(() => {
            this.loadingUserData = true;
        });
        const di = this.di;
        await di.config.init(this.loginStore.user.userId); // 메서드에서 에러 캐치되고 있음
        await this.studioTutorialStore.loadAllTutorialCompletions(); // 메서드에서 에러 캐치되고 있음
        try {
            const resColors = await di.server.listColors(); // 에러 캐치는 해당 메서드에서 해야 함
            (0, mobx_1.runInAction)(() => {
                this.messageColorMap = resColors.list.reduce((acc, { name, hex }) => ({ ...acc, [name]: hex }), {});
            });
        }
        catch (ex) {
            this.showError(ex);
        }
        (0, mobx_1.runInAction)(() => {
            this.loadingUserData = false;
        });
    }
    startEditing(storyId, onFailed) {
        var _a;
        const story = this.storyStore.getById(storyId);
        if (!story) {
            this.storyStore.loadStoryById(storyId).then(() => {
                this.startEditing(storyId, onFailed);
            }, ex => {
                onFailed(`작품을 로드하는데 실패하였습니다.`);
                this.di.showError(`Failed to load story id : ${storyId}, ${ex.message}`);
                this.setUnrecoverableError(new errors_1.SPCError(errors_1.ErrorCode.StoryNotFoundOnLoad));
            });
            return;
        }
        (_a = this.storyEditing) === null || _a === void 0 ? void 0 : _a.onEndEditing();
        (0, mobx_1.runInAction)(() => {
            this.storyEditing = story;
        });
    }
    startManageStoryPrice(storyId, countryCode, onFailed, playType = server_schema_1.GQLSTORY_PLAY_TYPE.Interactive) {
        const storyPrice = this.storyPriceStore.getById(storyId, countryCode);
        if (!storyPrice) {
            this.storyPriceStore.loadStoryPrice(storyId, countryCode).then(() => {
                this.startManageStoryPrice(storyId, countryCode, onFailed, playType);
            }, ex => {
                onFailed(`작품의 가격정보를 로드하는데 실패하였습니다.`);
                this.di.showError(`Failed to load story price id : ${storyId}, ${ex.message}`);
                this.setUnrecoverableError(new errors_1.SPCError(errors_1.ErrorCode.StoryNotFoundOnLoad));
            });
            return;
        }
        (0, mobx_1.runInAction)(() => {
            if (playType === server_schema_1.GQLSTORY_PLAY_TYPE.Storygame) {
                this.storyGamePrice = storyPrice;
                // Interactive
            }
            else {
                this.storyPrice = storyPrice;
            }
        });
    }
    resetStoryEditing() {
        (0, mobx_1.runInAction)(() => {
            this.storyEditing = null;
        });
    }
    async reloadEditor() {
        var _a, _b;
        const storyId = (_a = this.storyEditing) === null || _a === void 0 ? void 0 : _a.storyId;
        const chapterId = (_b = this.chapterEditing) === null || _b === void 0 ? void 0 : _b.id;
        if (storyId) {
            await this.storyStore.loadStoryById(storyId);
        }
        if (storyId && chapterId) {
            await this.storyEditing.startEditingChapter(chapterId);
        }
    }
    convertColor(color, defColor) {
        var _a, _b;
        if ((0, lodash_1.startsWith)(color !== null && color !== void 0 ? color : '', '#')) {
            return color !== null && color !== void 0 ? color : '';
        }
        return (_b = (_a = this.messageColorMap[color !== null && color !== void 0 ? color : '']) !== null && _a !== void 0 ? _a : color) !== null && _b !== void 0 ? _b : defColor;
    }
    showError(error, title) {
        // tslint:disable-next-line:no-console
        console.error(error);
        this.di.showError(this.textStore.translateError(error), title);
    }
    showMessage(message) {
        this.di.showMessage(message);
    }
    showTutorialCheck(title, bodyMessage, nextButtonName, onClick) {
        this.di.showTutorialCheck(title, bodyMessage, nextButtonName, onClick);
    }
    // 인터랙티브 작품 생성을 위한 메서드
    async createNewStory(name, lastName, firstName, desc, genre, mainImageFile, languageCode, bundleId, isAdult) {
        try {
            const book = JSON.stringify((0, storeUtils_1.createEmptyBookScript)());
            const res = await this.di.server.createStoryForStudio({
                name,
                defaultFirstName: firstName,
                defaultLastName: lastName,
                shortDesc: desc,
                oneLineDesc: '',
                script: book,
                topic: '',
                coverText: '',
                targetAge: '',
                genre,
                mainImageFile,
                languageCode,
                bundleId,
                isAdult,
            });
            return this.storyStore.merge(res);
        }
        catch (ex) {
            this.showError(ex);
            return null;
        }
    }
    get chapterEditing() {
        var _a, _b;
        return (_b = (_a = this.storyEditing) === null || _a === void 0 ? void 0 : _a.chapterEditing) !== null && _b !== void 0 ? _b : null;
    }
    async getServiceLanguages() {
        try {
            const res = await this.di.server.getServiceSystemLanguaes();
            (0, mobx_1.runInAction)(() => {
                this.serviceSystemLanguages = res;
            });
        }
        catch (ex) {
            this.showError(ex);
        }
    }
    async getServiceCountries() {
        try {
            const res = await this.di.server.getServiceCountries();
            (0, mobx_1.runInAction)(() => {
                this.serviceCountries = res;
            });
        }
        catch (ex) {
            this.showError(ex);
        }
    }
}
exports.StudioRootStore = StudioRootStore;
