"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOEndingEditor = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const EntityEditor_1 = require("../EntityEditor");
const fields_1 = require("../fields");
const DBFileFieldWithUI_1 = require("../fields/DBFileFieldWithUI");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOEndingEditor extends EntityEditor_1.EntityEditor {
    constructor(ending) {
        super(ending);
        this.ending = ending;
        this.imageFileField = null;
        this.previewImageFileField = null;
        this.displayNameField = null;
        this.nameField = new ui_1.InputWithValidation('endingName', trans('legacy_DOEndingEditor_ending_id'), // 엔딩명 아이디,
        trans('legacy_DOEndingEditor_enter_title'), // '타이틀을 입력해주세요.',
        'text', ending.name, null, {
            editable: false,
        });
        this.idField = new ui_1.InputWithValidation('endingId', trans('legacy_DOEndingEditor_custom_ending_id'), // '엔딩 엑셀용 커스텀 아이디',
        trans('legacy_DOEndingEditor_enter_id'), // '아이디를 입력해주세요.',
        'text', ending.customId, null, {
            editable: false,
        });
        this.collectionDescriptionField = new ui_1.InputWithValidation('collectionDescriptionId', trans('legacy_DOEndingEditor_enter_ending_content'), // '엔딩에 대한 내용을 입력해주세요.',
        trans('legacy_DOEndingEditor_enter_ending_content'), // '엔딩에 대한 내용을 입력해주세요.',
        'text', ending.collectionDescription, null, {
            editable: false,
        });
        this.displayNameField = new fields_1.TextFieldWithUI('displayName', () => this._target.displayName, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
        {
            label: trans('legacy_DOEndingEditor_ending_name'),
            placeholder: trans('legacy_DOEndingEditor_enter_ending_name'),
            inputType: 'text',
            options: {
                editable: false,
            },
        });
        const chapter = ending.store.story.chapterStore.getById(ending.chapterId);
        this.isDefaultEndingField = new ui_1.SelectionInput('isDefault', trans('legacy_DOEndingEditor_default_ending'), // '디폴트 엔딩',
        (chapter === null || chapter === void 0 ? void 0 : chapter.defaultEndingCustomId) === ending.customId, [
            {
                name: 'true',
                value: true,
            },
            { name: 'false', value: false },
        ], { isDisabled: true });
        const fileFields = [
            {
                key: 'previewImage',
                options: {
                    label: trans('legacy_DOEndingEditor_upload_ending_preview'),
                    options: { showLabel: true },
                },
            },
        ];
        if (ending.isFinal) {
            fileFields.push({
                key: 'image',
                options: {
                    label: trans('legacy_ods_image'),
                    options: { showLabel: true },
                }, // options: { label: '이미지', options: { showLabel: true } },
            });
        }
        fileFields.forEach(fld => {
            ;
            this[`${fld.key}FileField`] = new DBFileFieldWithUI_1.DBFileFieldWithUI(`${fld.key}File`, () => {
                var _a;
                const link = (_a = ending[`${fld.key}File`]) === null || _a === void 0 ? void 0 : _a.link;
                return { link };
            }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        (0, mobx_1.makeObservable)(this, {
        //
        });
    }
    get isFinal() {
        return this._target.isFinal;
    }
    get endingId() {
        return this._target.endingId;
    }
    getEnding() {
        return this.ending.store.getByName(this.ending.name);
    }
}
exports.DOEndingEditor = DOEndingEditor;
