"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryItemEditStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const validations_1 = require("../../../validations");
const ui_1 = require("../ui");
const DOStoryItem_1 = require("./DOStoryItem");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOStoryItemEditStore {
    constructor(story, item, 
    // null 이면 취소인 경우임.
    onSubmit) {
        var _a, _b;
        this.itemImageFile = null;
        this.isSubmitting = false;
        this.story = story;
        this.rootStore = story.rootStore;
        this.itemEditing = item;
        this.itemNameInput = new ui_1.InputWithValidation('itemName', trans('legacy_ODSEditItemModalLayout_item_name'), trans('legacy_ODSEditItemModalLayout_enter_item_name'), 'text', (_a = item === null || item === void 0 ? void 0 : item.name) !== null && _a !== void 0 ? _a : '', validations_1.ItemValidations.name, {
            enableInPlaceEditing: false,
        });
        this.itemDescInput = new ui_1.InputWithValidation('itemDesc', trans('legacy_DOStoryItemEditStore_item_description'), trans('legacy_DOStoryItemEditStore_enter_item_description'), 'text', (_b = item === null || item === void 0 ? void 0 : item.desc) !== null && _b !== void 0 ? _b : '', null, {
            enableInPlaceEditing: false,
        });
        this.onSubmitCallback = onSubmit;
        (0, mobx_1.makeObservable)(this, {
            itemImageFile: mobx_1.observable,
            isSubmitting: mobx_1.observable,
            isCreating: mobx_1.computed,
            itemImageLink: mobx_1.computed,
        });
    }
    onChangeItemImageFile(studioFile) {
        (0, mobx_1.runInAction)(() => {
            this.itemImageFile = studioFile;
        });
    }
    get itemImageLink() {
        var _a, _b, _c, _d, _e;
        return (_e = (_b = (_a = this.itemImageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : (_d = (_c = this.itemEditing) === null || _c === void 0 ? void 0 : _c.imageFile) === null || _d === void 0 ? void 0 : _d.link) !== null && _e !== void 0 ? _e : null;
    }
    onCancel() {
        this.onSubmitCallback(null);
    }
    async onSubmit(onFinish) {
        // 지금은 name 만 검사하면 되지만,
        // 여러 개의 필드를 검사해야 하는 경우에는 한 번에 처리할 수 있는
        // 공용으로 사용할 수 있는 메서드를 구현하기
        const isPrepared = this.itemNameInput.prepareSubmit();
        if (this.isCreating) {
            try {
                validations_1.ItemValidations.noIncludeCommaInName.validateSync(this.itemNameInput.value);
            }
            catch (ex) {
                this.rootStore.showError(this.rootStore.textStore.validationMessageMap[ex.message]);
                return;
            }
        }
        if (!isPrepared) {
            this.rootStore.showError(this.itemNameInput.validationError);
            return;
        }
        if (this.isSubmitting) {
            return;
        }
        (0, mobx_1.runInAction)(() => (this.isSubmitting = true));
        return this.processSubmit()
            .then(() => {
            onFinish === null || onFinish === void 0 ? void 0 : onFinish(null);
            this.onSubmitCallback(this.itemEditing);
        }, err => {
            if (onFinish) {
                onFinish === null || onFinish === void 0 ? void 0 : onFinish(err);
            }
            else {
                this.story.rootStore.showError(err);
            }
        })
            .finally(() => {
            (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
        });
    }
    async processSubmit() {
        var _a;
        const file = this.itemImageFile;
        if (!this.isCreating) {
            const res = await this.itemEditing.update({
                name: this.itemNameInput.value,
                desc: this.itemDescInput.value,
                isHidden: true,
                imageFromStudioFileId: (_a = file === null || file === void 0 ? void 0 : file.fileId) !== null && _a !== void 0 ? _a : null,
            });
            this.story.storyItemStore.merge(res);
        }
        else {
            const item = new DOStoryItem_1.DOStoryItem(this.story.storyItemStore, {
                storyItemId: this.story.getNextLocalNumberId(),
                name: this.itemNameInput.value,
                desc: this.itemDescInput.value,
                isHidden: true,
                imageFile: file,
            });
            await item.create();
            this.itemEditing = item;
        }
    }
    get isCreating() {
        return !this.itemEditing;
    }
}
exports.DOStoryItemEditStore = DOStoryItemEditStore;
