"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOUser = void 0;
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../@types/server.schema");
const consts_1 = require("../../../../consts");
const storeUtils_1 = require("../../utils/storeUtils");
const DOUserEditor_1 = require("./DOUserEditor");
/**
 * Character domain object.
 */
class DOUser {
    constructor(store, data) {
        this.authorName = null;
        this.authorEmail = null;
        this.phone = null;
        this.updateToAuthorAt = null;
        this.banReason = null;
        this.bannedAt = null;
        this.profileFile = null;
        this.ssoLogins = [];
        this.isInternalAuthor = null;
        this.numCompleteTutorial = 0;
        this.canExportStory = null;
        this.store = store;
        this.data = data;
        this.merge(data);
        if ('hbExtensionData' in data) {
            this.hbExtensionData = data.hbExtensionData;
        }
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.observable,
            email: mobx_1.observable,
            isDev: mobx_1.observable,
            priv: mobx_1.observable,
            authorName: mobx_1.observable,
            authorEmail: mobx_1.observable,
            phone: mobx_1.observable,
            updateToAuthorAt: mobx_1.observable,
            banReason: mobx_1.observable,
            bannedAt: mobx_1.observable,
            profileFile: mobx_1.observable,
            canExportStory: mobx_1.observable,
            ssoLogins: mobx_1.observable,
            isInternalAuthor: mobx_1.observable,
            numCompleteTutorial: mobx_1.observable,
            merge: mobx_1.action,
            isNotAuthor: mobx_1.computed,
            isBanned: mobx_1.computed,
            isSSOLogin: mobx_1.computed,
        });
    }
    startEditUserToAuthor() {
        return new DOUserEditor_1.DOUserEditor(this);
    }
    merge(data) {
        const fields = [
            'userId',
            'name',
            'email',
            'isDev',
            'priv',
            'profileFile',
            'updateToAuthorAt',
            'bannedAt',
            'banReason',
            'phone',
            'authorName',
            'authorEmail',
            'canExportStory',
            'ssoLogins',
            'isInternalAuthor',
            'numCompleteTutorial',
            'hbExtensionData',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    get profileImageLink() {
        var _a, _b;
        return (_b = (_a = this.profileFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null;
    }
    get serverId() {
        return this.userId;
    }
    /** 권한이 작가 권한 보다 아래여서, 작가 권한을 부여해야 하는지 */
    get isNotAuthor() {
        return this.priv < consts_1.USER_PRIV.Author;
    }
    get isBanned() {
        return !!this.bannedAt;
    }
    get isSameOrGreaterThanAdmin() {
        return this.priv >= consts_1.USER_PRIV.Admin;
    }
    get isSameOrGreaterThanSuperAuthor() {
        return this.priv >= consts_1.USER_PRIV.SuperAuthor;
    }
    get isSSOLogin() {
        var _a;
        const SSOProviders = Object.values(server_schema_1.GQLSSO_PROVIDER);
        return SSOProviders.includes((_a = this.ssoLogins.slice()[0]) === null || _a === void 0 ? void 0 : _a.ssoProvider);
    }
    async update(changeSet) {
        return this.store.rootStore.di.server.updateEntityForStudio(this, changeSet);
    }
}
exports.DOUser = DOUser;
