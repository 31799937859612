import {
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  ODImageFileInput,
  useCounter,
} from '@odc/od-react-belt'
import React from 'react'
import Select from 'react-select'
import { Card, CardBody, Col, Row } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLCreateOnboardingPopupChoiceInput,
  GQLOnboardingPopupChoice,
  GQLUpdateOnboardingPopupChoiceInput,
} from '../../../@types/server'
import { STATUS } from '../../../common'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { SPFormToggleButton } from '../../../components/SPFormToggleButton'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IOnboardingPopupChoicePageProps {
  storyId: number
  popupId: number
  popupChoiceId: number
}

type Entity = GQLOnboardingPopupChoice

function getValidationSchema(
  values: Partial<
    GQLCreateOnboardingPopupChoiceInput | GQLUpdateOnboardingPopupChoiceInput
  >
) {
  return Yup.object().shape({})
}

export const OnboardingPopupChoiceEditPage: React.FC<
  IOnboardingPopupChoicePageProps
> = (props) => {
  const { storyId, popupId, popupChoiceId } = props
  const innerRef = React.useRef(null)
  const [displayList, setDisplayList] = React.useState<
    { key: string; value: number }[]
  >([])
  const [effectList, setEffectList] = React.useState<
    { key: string; value: number }[]
  >([])
  const displayRef = React.useRef<{ key: string; value: number }[]>([])
  const effectRef = React.useRef<{ key: string; value: number }[]>([])
  const [playerClassOptions, setPlayerClassOptions] = React.useState<
    {
      label: string
      value: number | null
    }[]
  >([])
  const selectedPlayerClassIdRef = React.useRef(undefined)

  const {
    createOnboardingPopupChoice,
    updateOnboardingPopupChoice,
    deleteOnboardingPopupChoice,
    getOnboardingPopupChoice,
    listStoryPropertyOfStory,
    listPlayerClass,
  } = useStoryPlayAPIs()

  const [token] = useCounter()
  const isCreating = popupChoiceId === 0

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<
        | GQLCreateOnboardingPopupChoiceInput
        | GQLUpdateOnboardingPopupChoiceInput
      >
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (popupChoiceId) {
          return getOnboardingPopupChoice({ id: popupChoiceId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            popupId,
            showOrder: 1000,
            imageFile: null,
            content: '',
            popupName: '',
            playerClassName: '',
          }
        }
        if (data.choiceProps.display) {
          displayRef.current = Object.keys(data.choiceProps.display).map(
            (k) => ({ key: k, value: data.choiceProps.display[k] })
          )
          setDisplayList([...displayRef.current])
        }

        if (data.choiceProps.effect) {
          effectRef.current = Object.keys(data.choiceProps.effect)
            .filter((k) => !data.choiceProps.display?.[k])
            .map((k) => ({ key: k, value: data.choiceProps.effect[k] }))
          setEffectList([...effectRef.current])
        }

        return {
          popupChoiceId,
          popupId: data.popupId,
          imageFile: data.imageFile,
          content: data.content,
          showOrder: data.showOrder,
          popupName: data.popup.description,
          status: data.status,
          playerClassName: data.playerClass ? data.playerClass!.name : '없음',
        }
      },
      saveClientValueToServer: async (
        data: Partial<
          | GQLCreateOnboardingPopupChoiceInput
          | GQLUpdateOnboardingPopupChoiceInput
        >
      ) => {
        const { imageFile, showOrder, content, status } =
          data as GQLCreateOnboardingPopupChoiceInput
        const display =
          displayRef.current.length > 0
            ? displayRef.current.reduce(
                (acc, { key, value }) => ({ ...acc, [key]: +value }),
                {}
              )
            : null
        const effect =
          effectRef.current.length > 0 || displayRef.current.length > 0
            ? [...displayRef.current, ...effectRef.current].reduce(
                (acc, { key, value }) => ({ ...acc, [key]: +value }),
                {}
              )
            : null

        if (popupChoiceId) {
          await updateOnboardingPopupChoice({
            id: popupChoiceId,
            showOrder,
            content,
            imageFile,
            status,
            choiceProps: { display, effect },
            playerClassId: selectedPlayerClassIdRef.current,
          })
          Utils.showSuccess('선택지가 수정되었습니다.', 'Success')
        } else {
          await createOnboardingPopupChoice({
            showOrder,
            popupId,
            content,
            imageFile,
            status,
            choiceProps: { display, effect },
            playerClassId: selectedPlayerClassIdRef.current,
          })
          Utils.showSuccess('선택지가 생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.EditOnboardingPopup(storyId, popupId)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (popupId) {
          await deleteOnboardingPopupChoice({ id: popupChoiceId })
          Utils.showSuccess('선택지가 삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.EditOnboardingPopup(storyId, popupId)
      },
      __innerReference: innerRef,
    }),
    [
      popupChoiceId,
      getOnboardingPopupChoice,
      storyId,
      popupId,
      updateOnboardingPopupChoice,
      createOnboardingPopupChoice,
      token,
      deleteOnboardingPopupChoice,
    ]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<
        | GQLCreateOnboardingPopupChoiceInput
        | GQLUpdateOnboardingPopupChoiceInput
      >
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<
        | GQLCreateOnboardingPopupChoiceInput
        | GQLUpdateOnboardingPopupChoiceInput
      >
    >(options)
  )

  React.useEffect(
    () => setOptions(createOptions()),
    [createOptions, createOnboardingPopupChoice]
  )
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          Partial<
            | GQLCreateOnboardingPopupChoiceInput
            | GQLUpdateOnboardingPopupChoiceInput
          >
        >(options)
      ),
    [options]
  )

  let [propsOption, setPropsOption] = React.useState<
    { value: string; label: string }[]
  >([])

  const selectedDisplayPropRef = React.useRef('')
  const selectedEffectPropRef = React.useRef('')
  React.useEffect(() => {
    listStoryPropertyOfStory({ storyId, pageSize: 500, page: 1 }).then(
      (prop) => {
        setPropsOption(
          prop.list
            .filter((p) => p.propType.toString() !== 'STRING')
            .map((p) => ({
              label: `${p.propName} (${p.propNameRaw})`,
              value: p.propName,
            }))
        )
      }
    )
    listPlayerClass({ storyId, pageSize: 100 }).then((playerClass) => {
      setPlayerClassOptions(
        [
          { name: '플레이어 클래스 없음', playerClassId: null },
          ...playerClass.list,
        ].map((p) => ({
          label: p.name,
          value: p.playerClassId,
        }))
      )
    })
  }, [listStoryPropertyOfStory, storyId, listPlayerClass])

  const handleDisplayCreate = () => {
    if (
      !displayList.find(({ key }) => key === selectedDisplayPropRef.current) &&
      !effectList.find(({ key }) => key === selectedDisplayPropRef.current)
    ) {
      setDisplayList(
        displayList.concat({
          key: selectedDisplayPropRef.current,
          value: 0,
        })
      )
      displayRef.current = displayList.concat({
        key: selectedDisplayPropRef.current,
        value: 0,
      })
    }
  }

  const writeDisplayValue = (e: any) => {
    const idx = displayList.findIndex(({ key }) => key === e.target.name)
    if (idx > -1) displayList[idx].value = e.target.value
    setDisplayList([...displayList])
    displayRef.current = [...displayList]
  }

  const removeDisplayList = (e: any) => {
    const idx = displayList.findIndex(({ key }) => key === e.target.name)
    if (idx > -1) displayList.splice(idx, 1)
    setDisplayList([...displayList])
    displayRef.current = [...displayList]
  }

  const handleEffectCreate = () => {
    if (
      !displayList.find(({ key }) => key === selectedEffectPropRef.current) &&
      !effectList.find(({ key }) => key === selectedEffectPropRef.current)
    ) {
      setEffectList(
        effectList.concat({
          key: selectedEffectPropRef.current,
          value: 0,
        })
      )
      effectRef.current = effectList.concat({
        key: selectedEffectPropRef.current,
        value: 0,
      })
    }
  }

  const writeEffectValue = (e: any) => {
    const idx = effectList.findIndex(({ key }) => key === e.target.name)
    if (idx > -1) effectList[idx].value = e.target.value
    setEffectList([...effectList])
    effectRef.current = [...effectList]
  }

  const removeEffectList = (e: any) => {
    const idx = effectList.findIndex(({ key }) => key === e.target.name)
    if (idx > -1) effectList.splice(idx, 1)
    setEffectList([...effectList])
    effectRef.current = [...effectList]
  }

  const title = !popupChoiceId
    ? '온보딩 팝업 선택지 생성'
    : '온보딩 팝업 선택지 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            {!isCreating && (
              <>
                <ODEntityInput
                  keyPath="popupChoiceId"
                  label="선택지 아이디"
                  name="popupChoiceId"
                  placeholder="선택지 아이디 (자동부여)"
                  inputType="text"
                  inputProps={{ disabled: true }}
                />
                <ODEntityLabeled name="팝업 지문" label="팝업 지문">
                  <ODEntityRaw
                    name="popupName"
                    keyPath="popupName"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            <ODEntityLabeled label="이미지 파일" name="imageFile">
              <ODImageFileInput
                name="imageFile"
                height={200}
                width={200}
                keyPath="imageFile.link"
              />
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="content"
              label="선택지 지문"
              name="content"
              placeholder="선택지 지문"
              inputType="text"
            />
            <ODEntityInput
              keyPath="showOrder"
              label="노출 우선순위"
              name="showOrder"
              placeholder="노출 우선순위"
              inputType="number"
            />
            <ODEntityLabeled
              label="온보딩 선택지 상태"
              name="온보딩 선택지 상태"
            >
              <ToggleButtonWrapper>
                <SPFormToggleButton
                  name="status"
                  keyPath="status"
                  value={STATUS.Active}
                >
                  선택 가능
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="status"
                  keyPath="status"
                  value={STATUS.Disabled}
                >
                  선택 불가
                </SPFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled name={'플레이어 클래스'} label={'플레이어 클래스'}>
              <Row>
                <Col md={6}>
                  <Select
                    isSearchable={true}
                    placeholder="사용자의 플레이어 클래스"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedPlayerClassIdRef.current = v.value
                      }
                    }}
                    options={playerClassOptions}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </Col>
                <Col md={6}>
                  <ODEntityRaw
                    name="playerClassName"
                    keyPath="playerClassName"
                    render={({ value }) => {
                      return <span>{value}</span>
                    }}
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <hr />
            <br />
            <ODEntityLabeled
              name={'노출O 적용O 속성'}
              label={'노출O 적용O 속성'}
            >
              <Select
                isSearchable={true}
                placeholder="사용자에게도 보여지고, 초기 속성에도 적용될 속성"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    selectedDisplayPropRef.current = v.value
                    handleDisplayCreate()
                  }
                }}
                options={propsOption}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </ODEntityLabeled>
            {displayList.length > 0 && (
              <>
                <ODEntityLabeled
                  name={'노출O 적용O 속성값 설정'}
                  label={'노출O 적용O 속성값 설정'}
                >
                  <ul>
                    {' '}
                    {displayList.map(({ key, value }, idx) => {
                      return (
                        <li key={key}>
                          {' '}
                          {key}:{' '}
                          <input
                            type="number"
                            key={key}
                            name={key}
                            value={value}
                            onChange={writeDisplayValue}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="button"
                            name={key}
                            key={`${key}_display_${idx}`}
                            onClick={removeDisplayList}
                            value="X"
                          />
                        </li>
                      )
                    })}
                  </ul>
                </ODEntityLabeled>
              </>
            )}
            <hr />
            <br />
            <ODEntityLabeled
              name={'노출X 적용O 속성'}
              label={'노출X 적용O 속성'}
            >
              <Select
                isSearchable={true}
                placeholder="사용자의 초기 속성에 적용될 속성"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    selectedEffectPropRef.current = v.value
                    handleEffectCreate()
                  }
                }}
                options={propsOption}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </ODEntityLabeled>
            {(displayList.length > 0 || effectList.length > 0) && (
              <>
                <ODEntityLabeled
                  name={'노출X 적용O 속성값 설정'}
                  label={'노출X 적용O 속성값 설정'}
                >
                  <ul>
                    {' '}
                    {displayList.map(({ key, value }, idx) => {
                      return (
                        <li key={key}>
                          {' '}
                          {key}:{' '}
                          <input
                            type="number"
                            disabled
                            key={key}
                            name={key}
                            value={value}
                          />
                        </li>
                      )
                    })}
                  </ul>
                  <ul>
                    {' '}
                    {effectList.map(({ key, value }, idx) => {
                      return (
                        <li key={key}>
                          {' '}
                          {key}:{' '}
                          <input
                            type="number"
                            key={key}
                            name={key}
                            value={value}
                            onChange={writeEffectValue}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="button"
                            name={key}
                            key={`${key}_effect_${idx}`}
                            onClick={removeEffectList}
                            value="X"
                          />
                        </li>
                      )
                    })}
                  </ul>
                </ODEntityLabeled>
              </>
            )}
            <hr />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={popupChoiceId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
