"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bindItems = exports.StudioShortcutKeyStrokes = exports.StudioShortcut = void 0;
var StudioShortcut;
(function (StudioShortcut) {
    StudioShortcut["MoveSelectionUp"] = "MoveSelectionUp";
    StudioShortcut["MoveSelectionDown"] = "MoveSelectionDown";
    StudioShortcut["MoveSelectionLeft"] = "MoveSelectionLeft";
    StudioShortcut["MoveSelectionRight"] = "MoveSelectionRight";
    StudioShortcut["MoveButtonSelectionLeft"] = "MoveButtonSelectionLeft";
    StudioShortcut["MoveButtonSelectionRight"] = "MoveButtonSelectionRight";
    StudioShortcut["SelectNextCharacter"] = "SelectNextCharacter";
    StudioShortcut["SelectPrevCharacter"] = "SelectPrevCharacter";
    StudioShortcut["SelectRecentCharacter"] = "SelectRecentCharacter";
    StudioShortcut["EditCurrentSelection"] = "EditCurrentSelection";
    StudioShortcut["DeleteCurrentSelection"] = "DeleteCurrentSelection";
    StudioShortcut["ReorderSelectedUp"] = "ReorderSelectedUp";
    StudioShortcut["ReorderSelectedDown"] = "ReorderSelectedDown";
    // Key
    StudioShortcut["BlurInput"] = "BlurInput";
    StudioShortcut["FocusInput"] = "FocusInput";
    StudioShortcut["SubmitEditing"] = "SubmitEditing";
    StudioShortcut["SubmitLongTextEditing"] = "SubmitLongTextEditing";
    StudioShortcut["CancelEditing"] = "CancelEditing";
    StudioShortcut["OpenSaveProp"] = "OpenSaveProp";
    StudioShortcut["OpenChoice"] = "OpenChoice";
    StudioShortcut["OpenTemplate"] = "OpenTemplate";
    StudioShortcut["ExportNow"] = "ExportNow";
    StudioShortcut["Select1"] = "Select1";
    StudioShortcut["Select2"] = "Select2";
    StudioShortcut["Select3"] = "Select3";
    StudioShortcut["Select4"] = "Select4";
    StudioShortcut["Select5"] = "Select5";
    StudioShortcut["Select1OnTextInput"] = "Select1OnTextInput";
    StudioShortcut["Select2OnTextInput"] = "Select2OnTextInput";
    StudioShortcut["Select3OnTextInput"] = "Select3OnTextInput";
    StudioShortcut["Copy"] = "Copy";
    StudioShortcut["CopyRaw"] = "CopyRaw";
    StudioShortcut["Paste"] = "Paste";
    StudioShortcut["Cut"] = "Cut";
    StudioShortcut["Undo"] = "Undo";
    StudioShortcut["Redo"] = "Redo";
    StudioShortcut["Save"] = "Save";
    StudioShortcut["ToggleFind"] = "ToggleFind";
    StudioShortcut["ToggleFindAndReplace"] = "ToggleFindAndReplace";
    StudioShortcut["StartFind"] = "StartFind";
    StudioShortcut["FindPrevious"] = "FindPrevious";
})(StudioShortcut = exports.StudioShortcut || (exports.StudioShortcut = {}));
exports.StudioShortcutKeyStrokes = {
    [StudioShortcut.MoveSelectionUp]: ['k', 'up'],
    [StudioShortcut.MoveSelectionDown]: ['j', 'down'],
    [StudioShortcut.MoveSelectionLeft]: ['h', 'left'],
    [StudioShortcut.MoveSelectionRight]: ['l', 'right'],
    [StudioShortcut.MoveButtonSelectionLeft]: [
        'ctrl+k',
        'command+k',
        'mod+k',
        'ctrl+,',
        'command+,',
        'mod+,',
    ],
    [StudioShortcut.MoveButtonSelectionRight]: [
        'ctrl+j',
        'command+j',
        'mod+j',
        'ctrl+.',
        'command+.',
        'mod+.',
    ],
    [StudioShortcut.SelectNextCharacter]: [`ctrl+'`, `command+'`, `mod+'`],
    [StudioShortcut.SelectPrevCharacter]: [
        `ctrl+shift+'`,
        `command+shift+'`,
        `mod+shift+'`,
    ],
    [StudioShortcut.SelectRecentCharacter]: [`ctrl+;`, `command+;`, `mod+;`],
    [StudioShortcut.EditCurrentSelection]: ['e', 'f2', 'enter'],
    [StudioShortcut.DeleteCurrentSelection]: ['d', 'del', 'backspace'],
    [StudioShortcut.ReorderSelectedUp]: ['command+up', 'ctrl+up'],
    [StudioShortcut.ReorderSelectedDown]: ['command+down', 'ctrl+down'],
    [StudioShortcut.BlurInput]: 'esc',
    [StudioShortcut.FocusInput]: ['/', 'space'],
    [StudioShortcut.SubmitEditing]: 'enter',
    [StudioShortcut.SubmitLongTextEditing]: ['command+enter', 'ctrl+enter'],
    [StudioShortcut.CancelEditing]: 'esc',
    [StudioShortcut.OpenSaveProp]: 'command+k p',
    [StudioShortcut.OpenChoice]: 'command+k c',
    [StudioShortcut.OpenTemplate]: 'command+k t',
    [StudioShortcut.ExportNow]: 'command+k s',
    [StudioShortcut.Select1]: '1',
    [StudioShortcut.Select2]: '2',
    [StudioShortcut.Select3]: '3',
    [StudioShortcut.Select4]: '4',
    [StudioShortcut.Select5]: '5',
    [StudioShortcut.Select1OnTextInput]: 'alt+1',
    [StudioShortcut.Select2OnTextInput]: 'alt+2',
    [StudioShortcut.Select3OnTextInput]: 'alt+3',
    [StudioShortcut.Copy]: ['command+c', 'ctrl+c'],
    [StudioShortcut.CopyRaw]: ['command+shift+c', 'ctrl+shift+c'],
    [StudioShortcut.Paste]: ['command+v', 'ctrl+v'],
    [StudioShortcut.Cut]: ['command+x', 'ctrl+x'],
    [StudioShortcut.Undo]: ['command+z', 'ctrl+z'],
    [StudioShortcut.Redo]: ['shift+command+z', 'shift+ctrl+z'],
    [StudioShortcut.Save]: ['ctrl+s', 'command+s'],
    [StudioShortcut.ToggleFind]: ['ctrl+f', 'command+f', 'mod+f'],
    [StudioShortcut.ToggleFindAndReplace]: [
        'ctrl+shift+f',
        'command+shift+f',
        'mod+shift+f',
    ],
    [StudioShortcut.StartFind]: ['enter'],
    [StudioShortcut.FindPrevious]: ['shift+enter'],
};
function bindItems(provider, isEnabled, // 만약 이벤트 발생시 호출결과가 false 이면 동작하지 않음.
bindItem) {
    const cleanups = [];
    const bind = (sc, callback, keyAction = 'keydown', options = {}) => {
        provider.bind(exports.StudioShortcutKeyStrokes[sc], (e, combo) => {
            var _a;
            if (!isEnabled()) {
                return;
            }
            callback(e, combo);
            if ((_a = options === null || options === void 0 ? void 0 : options.preventDefault) !== null && _a !== void 0 ? _a : true) {
                e.preventDefault();
            }
        }, keyAction);
        cleanups.push(() => {
            provider.unbind(exports.StudioShortcutKeyStrokes[sc], keyAction);
        });
    };
    bindItem(bind);
    // cleanup
    return () => {
        cleanups.forEach(df => df());
    };
}
exports.bindItems = bindItems;
