"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTFullScreenEffectOn = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const index_1 = require("../../../../../index");
const SelectionFieldWithUI_1 = require("../../fields/SelectionFieldWithUI");
const StudioFileFieldWithUI_1 = require("../../fields/StudioFileFieldWithUI");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTFullScreenEffectOn extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        super(data, block, uniqueId);
        this.hasModalForEdit = true;
        this.initialFileField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('initialFileField', () => null, () => null, this.helper.story, {
            label: '효과 이미지',
            options: {
                imageLink: undefined,
                theme: index_1.FileTheme.OnlySupportedCommonImageModal,
                aspectRatio: {
                    w: 3,
                    h: 1,
                },
                accept: 'image/png, image/jpeg',
            },
        });
        // TODO: localize
        this.chatFileField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('chatImage', () => {
            var _a, _b, _c, _d, _e, _f;
            return ({
                link: (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.interactive.link) !== null && _b !== void 0 ? _b : this.st.fileLink,
                width: (_d = (_c = this.images) === null || _c === void 0 ? void 0 : _c.interactive.width) !== null && _d !== void 0 ? _d : this.st.width,
                height: (_f = (_e = this.images) === null || _e === void 0 ? void 0 : _e.interactive.height) !== null && _f !== void 0 ? _f : this.st.height,
            });
        }, file => {
            (0, mobx_1.runInAction)(() => {
                var _a, _b, _c, _d, _e, _f;
                if (!this.st.images) {
                    return;
                }
                this.st.images.interactive.link = (_a = file === null || file === void 0 ? void 0 : file.link) !== null && _a !== void 0 ? _a : '';
                this.st.images.interactive.width = (_b = file === null || file === void 0 ? void 0 : file.width) !== null && _b !== void 0 ? _b : 0;
                this.st.images.interactive.height = (_c = file === null || file === void 0 ? void 0 : file.height) !== null && _c !== void 0 ? _c : 0;
                // 하위호환성 코드
                this.st.fileLink = (_d = file === null || file === void 0 ? void 0 : file.link) !== null && _d !== void 0 ? _d : '';
                this.st.width = (_e = file === null || file === void 0 ? void 0 : file.width) !== null && _e !== void 0 ? _e : 0;
                this.st.height = (_f = file === null || file === void 0 ? void 0 : file.height) !== null && _f !== void 0 ? _f : 0;
            });
        }, this.helper.story, {
            label: '채팅소설',
            options: {
                imageLink: (_c = (_b = (_a = this.st.images) === null || _a === void 0 ? void 0 : _a.interactive.link) !== null && _b !== void 0 ? _b : this.link) !== null && _c !== void 0 ? _c : undefined,
                theme: index_1.FileTheme.OnlySupportedCommonImageModal,
                aspectRatio: {
                    w: 9,
                    h: 16,
                },
                accept: 'image/png, image/jpeg',
            },
        });
        this.chatFillMethodField = new SelectionFieldWithUI_1.SelectionFieldWithUI('chatFillMethod', () => {
            var _a, _b;
            return (_b = (_a = this.st.images) === null || _a === void 0 ? void 0 : _a.interactive.fillMethod) !== null && _b !== void 0 ? _b : core_1.StorygameScreenFillMethod.AspectRatioFill;
        }, value => {
            (0, mobx_1.runInAction)(() => {
                if (!this.st.images) {
                    return;
                }
                this.st.images.interactive.fillMethod = value;
                // 하위호환성 코드
                // tslint:disable-next-line:prefer-conditional-expression
                if (value === core_1.StorygameScreenFillMethod.Stretch) {
                    this.st.fillMethod = core_1.ScreenFillMethod.Stretch;
                }
                else {
                    this.st.fillMethod = core_1.ScreenFillMethod.AspectRatio;
                }
            });
        }, {
            selection: Object.values(core_1.StorygameScreenFillMethod).map(item => ({
                name: core_1.STORYGAME_SCREEN_FILL_METHOD_TRANSLATED[item],
                value: item,
            })),
        });
        this.mobileFileField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('mobileImage', () => {
            var _a, _b, _c, _d, _e, _f;
            return ({
                link: (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.storygameMobile.link) !== null && _b !== void 0 ? _b : this.st.fileLink,
                width: (_d = (_c = this.images) === null || _c === void 0 ? void 0 : _c.storygameMobile.width) !== null && _d !== void 0 ? _d : this.st.width,
                height: (_f = (_e = this.images) === null || _e === void 0 ? void 0 : _e.storygameMobile.height) !== null && _f !== void 0 ? _f : this.st.height,
            });
        }, file => {
            (0, mobx_1.runInAction)(() => {
                var _a, _b, _c;
                if (!this.st.images) {
                    return;
                }
                this.st.images.storygameMobile.link = (_a = file === null || file === void 0 ? void 0 : file.link) !== null && _a !== void 0 ? _a : '';
                this.st.images.storygameMobile.width = (_b = file === null || file === void 0 ? void 0 : file.width) !== null && _b !== void 0 ? _b : 0;
                this.st.images.storygameMobile.height = (_c = file === null || file === void 0 ? void 0 : file.height) !== null && _c !== void 0 ? _c : 0;
            });
        }, this.helper.story, {
            label: '스토리게임 (MO)',
            options: {
                imageLink: (_f = (_e = (_d = this.st.images) === null || _d === void 0 ? void 0 : _d.storygameMobile.link) !== null && _e !== void 0 ? _e : this.link) !== null && _f !== void 0 ? _f : undefined,
                theme: index_1.FileTheme.OnlySupportedCommonImageModal,
                aspectRatio: {
                    w: 9,
                    h: 16,
                },
                accept: 'image/png, image/jpeg',
            },
        });
        this.mobileFillMethodField = new SelectionFieldWithUI_1.SelectionFieldWithUI('mobileFillMethod', () => {
            var _a, _b;
            return (_b = (_a = this.st.images) === null || _a === void 0 ? void 0 : _a.storygameMobile.fillMethod) !== null && _b !== void 0 ? _b : core_1.StorygameScreenFillMethod.AspectRatioFill;
        }, value => {
            (0, mobx_1.runInAction)(() => {
                if (!this.st.images) {
                    return;
                }
                this.st.images.storygameMobile.fillMethod = value;
            });
        }, {
            selection: Object.values(core_1.StorygameScreenFillMethod).map(item => ({
                name: core_1.STORYGAME_SCREEN_FILL_METHOD_TRANSLATED[item],
                value: item,
            })),
        });
        this.pcFileField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('pcImage', () => {
            var _a, _b, _c, _d, _e, _f;
            return ({
                link: (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.storygamePC.link) !== null && _b !== void 0 ? _b : this.st.fileLink,
                width: (_d = (_c = this.images) === null || _c === void 0 ? void 0 : _c.storygamePC.width) !== null && _d !== void 0 ? _d : this.st.width,
                height: (_f = (_e = this.images) === null || _e === void 0 ? void 0 : _e.storygamePC.height) !== null && _f !== void 0 ? _f : this.st.height,
            });
        }, file => {
            (0, mobx_1.runInAction)(() => {
                var _a, _b, _c;
                if (!this.st.images) {
                    return;
                }
                this.st.images.storygamePC.link = (_a = file === null || file === void 0 ? void 0 : file.link) !== null && _a !== void 0 ? _a : '';
                this.st.images.storygamePC.width = (_b = file === null || file === void 0 ? void 0 : file.width) !== null && _b !== void 0 ? _b : 0;
                this.st.images.storygamePC.height = (_c = file === null || file === void 0 ? void 0 : file.height) !== null && _c !== void 0 ? _c : 0;
            });
        }, this.helper.story, {
            label: '스토리게임 (PC)',
            options: {
                imageLink: (_j = (_h = (_g = this.st.images) === null || _g === void 0 ? void 0 : _g.storygamePC.link) !== null && _h !== void 0 ? _h : this.link) !== null && _j !== void 0 ? _j : undefined,
                theme: index_1.FileTheme.OnlySupportedCommonImageModal,
                aspectRatio: {
                    w: 16,
                    h: 9,
                },
                accept: 'image/png, image/jpeg',
            },
        });
        this.pcFillMethodField = new SelectionFieldWithUI_1.SelectionFieldWithUI('pcFillMethod', () => {
            var _a, _b;
            return (_b = (_a = this.st.images) === null || _a === void 0 ? void 0 : _a.storygamePC.fillMethod) !== null && _b !== void 0 ? _b : core_1.StorygameScreenFillMethod.AspectRatioFill;
        }, value => {
            (0, mobx_1.runInAction)(() => {
                if (!this.st.images) {
                    return;
                }
                this.st.images.storygamePC.fillMethod = value;
            });
        }, {
            selection: Object.values(core_1.StorygameScreenFillMethod).map(item => ({
                name: core_1.STORYGAME_SCREEN_FILL_METHOD_TRANSLATED[item],
                value: item,
            })),
        });
        this.transitionStyleField = new SelectionFieldWithUI_1.SelectionFieldWithUI('transitionStyle', () => this.st.transitionStyle, value => {
            (0, mobx_1.runInAction)(() => {
                this.st.transitionStyle = value;
            });
        }, {
            label: trans('legacy_DOSTFullScreenEffectOff_effect_setting'),
            options: {
                noLabel: false,
            },
            selection: Object.values(core_1.TransitionStyle).map(item => ({
                name: core_1.TRANSITION_STYLE_TRANSLATED[item],
                value: item,
            })),
        });
        (0, mobx_1.makeObservable)(this, {
            link: mobx_1.computed,
            width: mobx_1.computed,
            height: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get images() {
        return this.st.images;
    }
    get link() {
        var _a, _b;
        return (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.interactive.link) !== null && _b !== void 0 ? _b : this.st.fileLink;
    }
    get width() {
        var _a, _b;
        return (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.interactive.width) !== null && _b !== void 0 ? _b : this.st.width;
    }
    get height() {
        var _a, _b;
        return (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.interactive.height) !== null && _b !== void 0 ? _b : this.st.height;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [
            ...super.editorFields,
            this.chatFileField,
            this.chatFillMethodField,
            this.mobileFileField,
            this.mobileFillMethodField,
            this.pcFileField,
            this.pcFillMethodField,
            this.transitionStyleField,
        ];
    }
    // 이미지 데이터를 수정하기 전에 st.images 에 데이터가 존재하지 않을 경우 더미데이터를 넣어 둔다.
    generateDummyImagesIfNoImages() {
        if (!this.st.images) {
            (0, mobx_1.runInAction)(() => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
                this.st.images = {
                    interactive: {
                        link: (_b = (_a = this.chatFileField.value) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : '',
                        width: (_d = (_c = this.chatFileField.value) === null || _c === void 0 ? void 0 : _c.width) !== null && _d !== void 0 ? _d : 0,
                        height: (_f = (_e = this.chatFileField.value) === null || _e === void 0 ? void 0 : _e.height) !== null && _f !== void 0 ? _f : 0,
                        fillMethod: (_g = this.chatFillMethodField.value) !== null && _g !== void 0 ? _g : core_1.StorygameScreenFillMethod.AspectRatioFill,
                    },
                    storygamePC: {
                        link: (_j = (_h = this.pcFileField.value) === null || _h === void 0 ? void 0 : _h.link) !== null && _j !== void 0 ? _j : '',
                        width: (_l = (_k = this.pcFileField.value) === null || _k === void 0 ? void 0 : _k.width) !== null && _l !== void 0 ? _l : 0,
                        height: (_o = (_m = this.pcFileField.value) === null || _m === void 0 ? void 0 : _m.height) !== null && _o !== void 0 ? _o : 0,
                        fillMethod: (_p = this.pcFillMethodField.value) !== null && _p !== void 0 ? _p : core_1.StorygameScreenFillMethod.AspectRatioFill,
                    },
                    storygameMobile: {
                        link: (_r = (_q = this.mobileFileField.value) === null || _q === void 0 ? void 0 : _q.link) !== null && _r !== void 0 ? _r : '',
                        width: (_t = (_s = this.mobileFileField.value) === null || _s === void 0 ? void 0 : _s.width) !== null && _t !== void 0 ? _t : 0,
                        height: (_v = (_u = this.mobileFileField.value) === null || _u === void 0 ? void 0 : _u.height) !== null && _v !== void 0 ? _v : 0,
                        fillMethod: (_w = this.mobileFillMethodField.value) !== null && _w !== void 0 ? _w : core_1.StorygameScreenFillMethod.AspectRatioFill,
                    },
                };
            });
        }
    }
    validate() {
        const res = super.validate();
        const createValidationResult = (type) => {
            return {
                type,
                extra: {},
                severity: index_1.StudioValidationSeverity.Error,
                source: this,
                stack: [],
            };
        };
        if (!this.st.fileLink.trim()) {
            res.push(createValidationResult(index_1.StudioValidationType.FullScreenEffectOnHasInvalidFileLink));
        }
        if (isNaN(Number(this.st.width))) {
            res.push(createValidationResult(index_1.StudioValidationType.FullScreenEffectOnHasInvalidImageWidth));
        }
        if (isNaN(Number(this.st.height))) {
            res.push(createValidationResult(index_1.StudioValidationType.FullScreenEffectOnHasInvalidImageHeight));
        }
        if (!Object.values(core_1.ScreenFillMethod).includes(this.st.fillMethod)) {
            res.push(createValidationResult(index_1.StudioValidationType.FullScreenEffectOnHasInvalidScreenFillMethod));
        }
        if (!Object.values(core_1.TransitionStyle).includes(this.st.transitionStyle)) {
            res.push(createValidationResult(index_1.StudioValidationType.FullScreenEffectOnHasInvalidTransitionStyle));
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = res));
        return res;
    }
    generateSheetColumnsAfterBackground() {
        const result = [
            '300',
            this.link,
            this.width.toString(),
            this.height.toString(),
            core_1.FullScreenEffectFileType.Image,
            this.st.fillMethod,
            this.st.transitionStyle,
            !!this.executeConditionOfStatement
                ? this.executeConditionOfStatement
                : '',
            !!this.st.images ? JSON.stringify(this.st.images) : '', // extra1
        ];
        return result;
    }
    async submitEditing(opFactory) {
        // 저장하기시에 st.images 가 없으면 st.images 데이터를 필드에 쌓인 데이터 기반으로 채운다.
        if (!this.st.images) {
            this.generateDummyImagesIfNoImages();
        }
        return super.submitEditing(opFactory);
    }
}
exports.DOSTFullScreenEffectOn = DOSTFullScreenEffectOn;
