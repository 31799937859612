"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListStudioTutorialByCategory = exports.StudioTutorialCodeMap = exports.tutorialUrlMapByTutorialEvent = void 0;
const core_1 = require("@storyplay/core");
const studioTutorial_enum_1 = require("./studioTutorial.enum");
// 해당 파일들의 export 된 상수들은 DOStudioTutorialStore 에 공급하는 용도입니다.
// 리스트에 대한 접근은 DOStudioTutorialStore 에서 해주시면 될 것 같습니다.
const { trans } = (0, core_1.i18nTextTranslationByClass)();
exports.tutorialUrlMapByTutorialEvent = {
    [studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_NEW_STORY]: 'https://thingsflow.gitbook.io/studio-guide/introduce/createStory',
    [studioTutorial_enum_1.GA4_EVENT_NAME.ENTER_CHAPTER_EDIT_PAGE]: 'https://thingsflow.gitbook.io/studio-guide/introduce/enterEditChapterPage',
    [studioTutorial_enum_1.GA4_EVENT_NAME.INPUT_LINES]: 'https://thingsflow.gitbook.io/studio-guide/introduce/inputLines',
    [studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_CHARACTER]: 'https://thingsflow.gitbook.io/studio-guide/introduce/setCharacter',
    [studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_DEFAULT_CHAPTER_ENDING]: 'https://thingsflow.gitbook.io/studio-guide/introduce/createChapterEnding',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SAVE_CHAPTER]: 'https://thingsflow.gitbook.io/studio-guide/introduce/saveChapter',
    [studioTutorial_enum_1.GA4_EVENT_NAME.DO_INSTANT_TEST]: 'https://thingsflow.gitbook.io/studio-guide/introduce/doInstantTest',
    [studioTutorial_enum_1.GA4_EVENT_NAME.CONNECT_SCENE]: 'https://thingsflow.gitbook.io/studio-guide/blocks_and_choices/connectScene',
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_TEXT_CHOICE]: 'https://thingsflow.gitbook.io/studio-guide/blocks_and_choices/usingTextChoice',
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_IMAGE_CHOICE]: 'https://thingsflow.gitbook.io/studio-guide/blocks_and_choices/usingImageChoice',
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_USER_INPUT_CHOICE]: 'https://thingsflow.gitbook.io/studio-guide/blocks_and_choices/usingUserInputChoice',
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_STATS_CHOICE]: 'https://thingsflow.gitbook.io/studio-guide/blocks_and_choices/usingStatsChoice',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_PROP]: 'https://thingsflow.gitbook.io/studio-guide/2/setProp',
    [studioTutorial_enum_1.GA4_EVENT_NAME.DO_ROUTE_OF_CONDITION]: 'https://thingsflow.gitbook.io/studio-guide/2/doRouteOfCondition',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_ITEM]: 'https://thingsflow.gitbook.io/studio-guide/2/setItem',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_CHARACTER]: 'https://thingsflow.gitbook.io/studio-guide/directions/editCharacter',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_BACKGROUND_IMAGE]: 'https://thingsflow.gitbook.io/studio-guide/directions/setBackgroundImage',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_SOUND_EFFECT]: 'https://thingsflow.gitbook.io/studio-guide/directions/setSoundEffect',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_BGM]: 'https://thingsflow.gitbook.io/studio-guide/directions/setBgm',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_CHAPTER_LIST]: 'https://thingsflow.gitbook.io/studio-guide/preparingForPublication/editChapterList',
    [studioTutorial_enum_1.GA4_EVENT_NAME.EDIT_STORY_INFORMATION]: 'https://thingsflow.gitbook.io/studio-guide/preparingForPublication/editStoryInformation',
    [studioTutorial_enum_1.GA4_EVENT_NAME.PUBLISH_CHAPTER]: 'https://thingsflow.gitbook.io/studio-guide/preparingForPublication/publishChapter',
    [studioTutorial_enum_1.GA4_EVENT_NAME.CHANGE_TEXT_COLOR_OF_LINES]: 'https://thingsflow.gitbook.io/studio-guide/advancedDirectionFunctions/changeTextColorOfLines',
    [studioTutorial_enum_1.GA4_EVENT_NAME.DECORATE_SPEECH_BUBBLE]: 'https://thingsflow.gitbook.io/studio-guide/advancedDirectionFunctions/decorateSpeechBubble',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_TTS]: 'https://thingsflow.gitbook.io/studio-guide/advancedDirectionFunctions/setTts',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SHOW_KEYBOARD_SHORTCUT]: 'https://thingsflow.gitbook.io/studio-guide/advancedFunctions/showKeyboardShortcut',
    [studioTutorial_enum_1.GA4_EVENT_NAME.COPY_TEXT_IN_SPEECH_BUBBLE]: 'https://thingsflow.gitbook.io/studio-guide/advancedFunctions/copyTextInSpeechBubble',
    // [GA4_EVENT_NAME.DIVIDE_BLOCKS]:
    [studioTutorial_enum_1.GA4_EVENT_NAME.DIVIDE_BLOCKS]: 'https://thingsflow.gitbook.io/studio-guide/advancedFunctions/divideBlocks',
    // [GA4_EVENT_NAME.SET_FULL_WIDTH_IMAGE]:
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_FULL_WIDTH_IMAGE]: 'https://thingsflow.gitbook.io/studio-guide/advancedFunctions/setFullWidthImage',
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_TEXT_OVERLAY_IMAGE]: 'https://thingsflow.gitbook.io/studio-guide/advancedFunctions/setTextOverlayImage',
    [studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_VIBRATION]: 'https://thingsflow.gitbook.io/studio-guide/advancedFunctions/createVibration',
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_PROP_FIGURE_OF_SENTENCE_TEMPLATE]: 'https://thingsflow.gitbook.io/studio-guide/advancedFunctions/propFigure',
};
// 시작해보기
const CodeMapAboutBasic = {
    [studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_NEW_STORY]: {
        title: trans('legacy_studioTutorial.const_try_creating_work'),
        description: trans('legacy_studioTutorial.const_work_is_playable_in_story_play_app'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_NEW_STORY],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.ENTER_CHAPTER_EDIT_PAGE]: {
        title: trans('legacy_studioTutorial.const_try_entering_edit_screen'),
        description: trans('legacy_studioTutorial.const_now_fill_in_real_work_content'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.ENTER_CHAPTER_EDIT_PAGE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.INPUT_LINES]: {
        title: trans('legacy_studioTutorial.const_try_entering_dialogue'),
        description: trans('legacy_studioTutorial.const_all_story_play_works_are_dialogue_based'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.INPUT_LINES],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_CHARACTER]: {
        title: trans('legacy_studioTutorial.const_try_setting_characters'),
        description: trans('legacy_studioTutorial.const_set_up_main_and_supporting_characters'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_CHARACTER],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_DEFAULT_CHAPTER_ENDING]: {
        title: trans('legacy_studioTutorial.const_try_creating_ending'),
        description: trans('legacy_studioTutorial.const_chapter_ending_is_ending_each_episode'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_DEFAULT_CHAPTER_ENDING],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.SAVE_CHAPTER]: {
        title: trans('legacy_studioTutorial.const_try_temporary_save'),
        description: trans('legacy_studioTutorial.const_dont_forget_to_save'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SAVE_CHAPTER],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.DO_INSTANT_TEST]: {
        title: trans('legacy_studioTutorial.const_try_instant_testing'),
        description: trans('legacy_studioTutorial.const_try_testing_in_real_app'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.DO_INSTANT_TEST],
    },
};
// 블록과 선택지
const CodeMapAboutBlockAndChoice = {
    [studioTutorial_enum_1.GA4_EVENT_NAME.CONNECT_SCENE]: {
        title: trans('legacy_studioTutorial.const_try_connecting_scenes'),
        description: trans('legacy_studioTutorial.const_no_need_to_always_move_to_new_scene'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.CONNECT_SCENE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_TEXT_CHOICE]: {
        title: trans('legacy_studioTutorial.const.ts_text_choice_branch'),
        description: trans('legacy_studioTutorial.const.ts_choice_branch_utilization'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.USING_TEXT_CHOICE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_IMAGE_CHOICE]: {
        title: trans('legacy_studioTutorial.const.ts_image_choice_branch'),
        description: trans('legacy_studioTutorial.const.ts_image_choice_selection'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.USING_IMAGE_CHOICE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_USER_INPUT_CHOICE]: {
        title: trans('legacy_studioTutorial.const.ts_user_input_choice_branch'),
        description: trans('legacy_studioTutorial.const.ts_user_input_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.USING_USER_INPUT_CHOICE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_STATS_CHOICE]: {
        title: trans('legacy_studioTutorial.const.ts_statistics_choice_branch'),
        description: trans('legacy_studioTutorial.const.ts_statistics_type_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.USING_STATS_CHOICE],
    },
};
// 인터렉티브 스토리 만들기 2
const CodeMapAboutPropAndItemAndSoOn = {
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_PROP]: {
        title: trans('legacy_studioTutorial.const.ts_attribute_value_save'),
        description: trans('legacy_studioTutorial.const.ts_attribute_value_utilization'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_PROP],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.DO_ROUTE_OF_CONDITION]: {
        title: trans('legacy_studioTutorial.const.ts_condition_branch'),
        description: trans('legacy_studioTutorial.const.ts_condition_branch_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.DO_ROUTE_OF_CONDITION],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_ITEM]: {
        title: trans('legacy_studioTutorial.const.ts_item_creation'),
        description: trans('legacy_studioTutorial.const.ts_item_utilization'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_ITEM],
    },
};
// 스플에서 할 수 있는 연출들
const CodeMapAboutDirectionsThatCanBeMade = {
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_CHARACTER]: {
        title: trans('legacy_studioTutorial.const.ts_character_edit'),
        description: trans('legacy_studioTutorial.const.ts_character_edit_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_CHARACTER],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_BACKGROUND_IMAGE]: {
        title: trans('legacy_studioTutorial.const.ts_background_image_setting'),
        description: trans('legacy_studioTutorial.const.ts_background_image_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_BACKGROUND_IMAGE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_SOUND_EFFECT]: {
        title: trans('legacy_studioTutorial.const.ts_sound_effect_application'),
        description: trans('legacy_studioTutorial.const.ts_sound_effect_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_SOUND_EFFECT],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_BGM]: {
        title: trans('legacy_studioTutorial.const.ts_background_music_application'),
        description: trans('legacy_studioTutorial.const.ts_background_music_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_BGM],
    },
};
// 작품 발간 준비하기
const CodeMapAboutPreparingForPublication = {
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_CHAPTER_LIST]: {
        title: trans('legacy_studioTutorial.const.ts_chapter_list_edit'),
        description: trans('legacy_studioTutorial.const.ts_chapter_list_edit_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_CHAPTER_LIST],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.EDIT_STORY_INFORMATION]: {
        title: trans('legacy_studioTutorial.const.ts_work_info_edit'),
        description: trans('legacy_studioTutorial.const.ts_work_info_edit_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.EDIT_STORY_INFORMATION],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.PUBLISH_CHAPTER]: {
        title: trans('legacy_studioTutorial.const.ts_publish'),
        description: trans('legacy_studioTutorial.const.ts_publish_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.PUBLISH_CHAPTER],
    },
};
// 고급 연출 기능들
const CodeMapAboutAdvancedDirectionFunctions = {
    [studioTutorial_enum_1.GA4_EVENT_NAME.CHANGE_TEXT_COLOR_OF_LINES]: {
        title: trans('legacy_studioTutorial.const.ts_try_change_font_color'),
        description: trans('legacy_studioTutorial.const.ts_change_text_color'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.CHANGE_TEXT_COLOR_OF_LINES],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.DECORATE_SPEECH_BUBBLE]: {
        title: trans('legacy_studioTutorial.const.ts_decorate_speech_bubble'),
        description: trans('legacy_studioTutorial.const.ts_change_speech_bubble'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.DECORATE_SPEECH_BUBBLE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_TTS]: {
        title: trans('legacy_studioTutorial.const.ts_apply_tts'),
        description: trans('legacy_studioTutorial.const.ts_tts_explanation'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_TTS],
    },
};
// 고급 기능
const CodeMapAboutAdvancedFunctions = {
    [studioTutorial_enum_1.GA4_EVENT_NAME.SHOW_KEYBOARD_SHORTCUT]: {
        title: trans('legacy_studioTutorial.const.ts_understand_keyboard_shortcuts'),
        description: trans('legacy_studioTutorial.const.ts_use_shortcuts'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SHOW_KEYBOARD_SHORTCUT],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.COPY_TEXT_IN_SPEECH_BUBBLE]: {
        title: trans('legacy_studioTutorial.const.ts_copy_text_in_bubble'),
        description: trans('legacy_studioTutorial.const.ts_copy_text_shortcut'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.COPY_TEXT_IN_SPEECH_BUBBLE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.DIVIDE_BLOCKS]: {
        // [GA4_EVENT_NAME.DIVIDE_BLOCKS]: {
        title: trans('legacy_studioTutorial.const.ts_split_blocks'),
        description: trans('legacy_studioTutorial.const.ts_create_multiple_blocks'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.DIVIDE_BLOCKS], // url: tutorialUrlMapByTutorialEvent[GA4_EVENT_NAME.DIVIDE_BLOCKS],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_FULL_WIDTH_IMAGE]: {
        // [GA4_EVENT_NAME.SET_FULL_WIDTH_IMAGE]: {
        title: trans('legacy_SPSFullWidthImageModal_illustration_image'),
        description: trans('legacy_studioTutorial.const.ts_insert_image'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_FULL_WIDTH_IMAGE], // url: tutorialUrlMapByTutorialEvent[GA4_EVENT_NAME.SET_FULL_WIDTH_IMAGE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.SET_TEXT_OVERLAY_IMAGE]: {
        title: trans('legacy_studioTutorial.const.ts_text_input_image'),
        description: trans('legacy_studioTutorial.const.ts_insert_image'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.SET_TEXT_OVERLAY_IMAGE],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_VIBRATION]: {
        title: trans('legacy_studioTutorial.const.ts_vibration_effect'),
        description: trans('legacy_studioTutorial.const.ts_use_vibration'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.CREATE_VIBRATION],
    },
    [studioTutorial_enum_1.GA4_EVENT_NAME.USING_PROP_FIGURE_OF_SENTENCE_TEMPLATE]: {
        title: trans('legacy_studioTutorial.const.ts_sentence_template'),
        description: trans('legacy_studioTutorial.const.ts_use_attribute_value'),
        url: exports.tutorialUrlMapByTutorialEvent[studioTutorial_enum_1.GA4_EVENT_NAME.USING_PROP_FIGURE_OF_SENTENCE_TEMPLATE],
    },
};
exports.StudioTutorialCodeMap = {
    ...CodeMapAboutBasic,
    ...CodeMapAboutBlockAndChoice,
    ...CodeMapAboutPropAndItemAndSoOn,
    ...CodeMapAboutDirectionsThatCanBeMade,
    ...CodeMapAboutPreparingForPublication,
    ...CodeMapAboutAdvancedDirectionFunctions,
    ...CodeMapAboutAdvancedFunctions,
};
const StudioTutorialCategoryMap = {
    [studioTutorial_enum_1.StudioTutorialCategory.Basic]: {
        categoryName: trans('legacy_studioTutorial.const.ts_start'),
        codeMap: CodeMapAboutBasic,
    },
    [studioTutorial_enum_1.StudioTutorialCategory.BlockAndChoice]: {
        categoryName: trans('legacy_studioTutorial.const.ts_blocks_and_choices'),
        codeMap: CodeMapAboutBlockAndChoice,
    },
    [studioTutorial_enum_1.StudioTutorialCategory.PropAndItemAndSoOn]: {
        categoryName: trans('legacy_studioTutorial.const.ts_interactive_story'),
        codeMap: CodeMapAboutPropAndItemAndSoOn,
    },
    [studioTutorial_enum_1.StudioTutorialCategory.DirectionsThatCanBeMade]: {
        categoryName: trans('legacy_studioTutorial.const.ts_directing_in_spl'),
        codeMap: CodeMapAboutDirectionsThatCanBeMade,
    },
    [studioTutorial_enum_1.StudioTutorialCategory.PreparingForPublication]: {
        categoryName: trans('legacy_studioTutorial.const.ts_prepare_publish'),
        codeMap: CodeMapAboutPreparingForPublication,
    },
    [studioTutorial_enum_1.StudioTutorialCategory.AdvancedDirectionFunctions]: {
        categoryName: trans('legacy_studioTutorial.const.ts_advanced_directing'),
        codeMap: CodeMapAboutAdvancedDirectionFunctions,
    },
    [studioTutorial_enum_1.StudioTutorialCategory.AdvancedFunctions]: {
        categoryName: trans('legacy_studioTutorial.const.ts_advanced_features'),
        codeMap: CodeMapAboutAdvancedFunctions,
    },
};
exports.ListStudioTutorialByCategory = Object.values(studioTutorial_enum_1.StudioTutorialCategory).map(item => {
    const obj = StudioTutorialCategoryMap[item];
    return {
        categoryName: obj.categoryName,
        tutorialCodes: Object.keys(obj.codeMap),
    };
});
