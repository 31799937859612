"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioFileFieldWithUI = void 0;
const consts_1 = require("../../../../consts");
const ui_1 = require("../ui");
const StudioFileField_1 = require("./StudioFileField");
/**
 * 기존 IInputWithValidation 과의 공존을 위하여 존재한다. StudioFileField 를 사용하도록 하되,
 * InputWithValidation 을 연동한다.
 */
class StudioFileFieldWithUI extends StudioFileField_1.StudioFileField {
    constructor(fieldId, getter, setter, story, uiOptions = {}) {
        var _a;
        super(fieldId, getter, setter);
        this.story = story;
        this.input = new ui_1.FileInput(fieldId, (_a = uiOptions.label) !== null && _a !== void 0 ? _a : '', this.defValue, {
            ...uiOptions.options,
            onChange: async (file) => {
                const hashId = story.rootStore.di.generateInternalHashId();
                // TODO: 파일 종류에 따라서 처리를 다르게 해야할 수도 있다.
                const resName = `${this.fieldId}_${hashId}`;
                try {
                    const res = await this.story.studioFileStore.uploadStudioFile(file, consts_1.StudioFileType.Image, resName);
                    this.onChange(res);
                }
                catch (ex) {
                    story.rootStore.di.showError(ex);
                }
            },
        });
    }
}
exports.StudioFileFieldWithUI = StudioFileFieldWithUI;
