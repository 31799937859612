import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLOnboardingPopupChoice } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IOnboardingPopupChoiceListableOption } from '../OnboardingPopupChoiceCommon'

export function useOnboardingPopupChoiceListDataLoader(
  storyId: number | undefined,
  popupId: number | undefined
) {
  const { listOnboardingPopupChoice } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IOnboardingPopupChoiceListableOption
    ): Promise<ODListableResponseType<GQLOnboardingPopupChoice>> {
      const r = await listOnboardingPopupChoice({
        page,
        pageSize: 10,
        ...options,
        popupId,
      })
      return r as ODListableResponseType<GQLOnboardingPopupChoice>
    },
    [listOnboardingPopupChoice, popupId]
  )
}
