"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbAlgorithmDoStoryStoreStrategy = void 0;
const hbDoAlgorithmStoryStrategy_1 = require("../doStoryStrategy/hbDoAlgorithmStoryStrategy");
const baseDoStoryStoreStrategy_1 = require("./baseDoStoryStoreStrategy");
class HbAlgorithmDoStoryStoreStrategy extends baseDoStoryStoreStrategy_1.BaseDoStoryStoreStrategy {
    constructor() {
        super(...arguments);
        this.doStoryStrategyConstructor = hbDoAlgorithmStoryStrategy_1.DoHbAlgorithmStoryStrategy;
    }
    // 이 동작은 알고리즘 스토리의 첫 20개 챕터(알고리즘 블록그룹)을 불러온다.
    async loadStoryById(storyId) {
        const server = this.doStoryStore.rootStore.di.server;
        return server.getAlgorithmStoryForStudio(storyId, {
            page: {
                index: 0,
                size: 20,
            },
            sort: {
                key: 'regDate',
                order: 'asc',
            },
        });
    }
}
exports.HbAlgorithmDoStoryStoreStrategy = HbAlgorithmDoStoryStoreStrategy;
