"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOHbLinkBlock = void 0;
const mobx_1 = require("mobx");
const chapter_1 = require("../../../../chapter");
const SelectionFieldWithUI_1 = require("../../../../fields/SelectionFieldWithUI");
const DOHbConditionItem_1 = require("./DOHbConditionItem");
const DOSTHbMessageNext_const_1 = require("./DOSTHbMessageNext.const");
// 헬봇에서만 존재하며, 기존처럼 STATEMENT data 를 내려받지 않고, 헬봇 데이터만 내려 받습니다.
class DOHbLinkBlock {
    // 조건이 없는 HbLinkBlock을 만들려면 수동으로 items 없는 Data를 넘겨줘야 한다.
    constructor(data, parent) {
        var _a, _b, _c;
        this.conditionItems = [];
        const dataToPut = data !== null && data !== void 0 ? data : {
            blockIds: [],
            logic: 'and',
            items: [],
        };
        this.uniqueId =
            (_b = (_a = dataToPut.id) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : parent.parent.rootStore.di.generateSourceLine().toString();
        this.data = dataToPut;
        this.parent = parent;
        this.logicSelectionField = new SelectionFieldWithUI_1.SelectionFieldWithUI('conditionLogic', () => { var _a; return (_a = dataToPut.logic) !== null && _a !== void 0 ? _a : 'and'; }, () => null, {
            selection: [
                { name: '이고', value: 'and' },
                { name: '이거나', value: 'or' },
            ],
        });
        this.blocksIdSelectionField = parent.createBlockIdsSelection(((_c = data === null || data === void 0 ? void 0 : data.blocks) !== null && _c !== void 0 ? _c : []).map(item => {
            var _a, _b, _c, _d;
            return ({
                name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                category: (_d = (_c = item.groupName) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '-',
            });
        }));
        this.generateConditionItems(data !== null && data !== void 0 ? data : {});
        if (this.conditionItems.length === 0) {
            this.addCondition();
        }
        (0, mobx_1.makeObservable)(this, {
            conditionItems: mobx_1.observable,
            blocksTo: mobx_1.computed,
            flowChartNodesOfOtherGroupBlock: mobx_1.computed,
            isValidBlockIds: mobx_1.computed,
            isValidAll: mobx_1.computed,
            isValidLogic: mobx_1.computed,
        });
    }
    generateConditionItems(data) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.conditionItems =
                (_b = (_a = data.items) === null || _a === void 0 ? void 0 : _a.map(item => new DOHbConditionItem_1.DOHbConditionItem(item, this))) !== null && _b !== void 0 ? _b : [];
        });
    }
    addCondition() {
        (0, mobx_1.runInAction)(() => {
            this.conditionItems = [
                ...this.conditionItems,
                new DOHbConditionItem_1.DOHbConditionItem({}, this),
            ];
        });
    }
    // true on success, false on failure(out of index or last condition)
    removeCondition(index) {
        if (!this.conditionItems) {
            return false;
        }
        if (this.conditionItems.length < 2 ||
            index < 0 ||
            index >= this.conditionItems.length) {
            return false;
        }
        (0, mobx_1.runInAction)(() => {
            this.conditionItems = this.conditionItems.filter((value, vIndex) => index !== vIndex);
        });
        return true;
    }
    get blocksTo() {
        var _a, _b;
        const convertedListToBlocks = (_b = (_a = this.data.blocks) === null || _a === void 0 ? void 0 : _a.map(b => !!b.id
            ? this.parent.parent.block.store.getByUniqueId(b.id.toString())
            : undefined)) !== null && _b !== void 0 ? _b : [];
        const filteredListToBlocks = convertedListToBlocks.filter(b => !!b);
        const res = filteredListToBlocks.map(b => ({
            block: b,
            label: '',
            extra: { statement: this },
        }));
        this.flowChartNodesOfOtherGroupBlock.forEach(b => {
            res.push({
                block: b,
                label: '',
                extra: { statement: this },
            });
        });
        return res;
    }
    // 다른 그룹의 블록을 참조하고 있다면 해당 블록들을 플로우차트에 그리기 위한 데이터를 생성해준다.
    // 해당 데이터는 blockStore 의 allNode 에서 사용되며, 해당 클래스의 blocksTo getter 에서도 사용된다.
    get flowChartNodesOfOtherGroupBlock() {
        var _a, _b;
        const filteredListToBlocks = (_a = this.data.blocks) === null || _a === void 0 ? void 0 : _a.filter(b => !!b.id
            ? !this.parent.parent.block.store.getByUniqueId(b.id.toString())
            : undefined);
        return ((_b = filteredListToBlocks === null || filteredListToBlocks === void 0 ? void 0 : filteredListToBlocks.map(b => {
            var _a, _b, _c, _d, _e;
            const parent = this.parent;
            return {
                uniqueId: (_b = (_a = b.id) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : this.parent.parent.rootStore.di.generateSourceLine().toString(),
                nodeName: (0, DOSTHbMessageNext_const_1.getNameForOtherBlockGroup)((_c = b.title) !== null && _c !== void 0 ? _c : '이름 없음', (_e = (_d = b.groupId) === null || _d === void 0 ? void 0 : _d.toString()) !== null && _e !== void 0 ? _e : this.parent.parent.rootStore.di.generateSourceLine().toString()),
                nodeNamesFrom: [this.parent.parent.block.name],
                blocksTo: [],
                nodesTo: [],
                nodeType: chapter_1.FlowChartNodeType.NodeOtherGroupBlock,
                intrinsicOrder: parent.parent.block.intrinsicOrder,
                shouldComeBeforeOnFlowChart(otherBlock) {
                    if (!!parent.parent.block.intrinsicOrder &&
                        !!otherBlock.intrinsicOrder) {
                        return (parent.parent.block.intrinsicOrder > otherBlock.intrinsicOrder);
                    }
                    return true;
                },
            };
        })) !== null && _b !== void 0 ? _b : []);
    }
    get isValidBlockIds() {
        return this.blocksIdSelectionField.value.length > 0;
    }
    get isValidLogic() {
        return !!this.logicSelectionField.value;
    }
    get isValidAll() {
        return [
            this.isValidBlockIds,
            this.isValidLogic,
            this.conditionItems && this.conditionItems.every(v => v.isValidAll),
        ].every(v => v);
    }
    // 서버에 보낼 데이터 가공
    getDataForSubmit() {
        return {
            items: this.conditionItems &&
                this.conditionItems.map(item => item.getDataForSubmit()),
            logic: this.logicSelectionField.value,
            blockIds: this.blocksIdSelectionField.value,
        };
    }
    getFullData() {
        var _a;
        return {
            items: (_a = this.conditionItems) === null || _a === void 0 ? void 0 : _a.map(item => item.getFullData()),
            logic: this.logicSelectionField.value,
            blocks: this.blocksIdSelectionField.selectedOptions.map(item => ({
                id: item.value,
                title: item.name,
                groupName: item.category,
            })),
        };
    }
}
exports.DOHbLinkBlock = DOHbLinkBlock;
