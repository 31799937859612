import { registerServerErrorMessageMap } from '@odc/od-react-belt'
import { ErrorCode } from '@storyplay/common'

export const Strings = {
  Add: 'Add',
  Edit: 'Edit',
}

type ErrorConvertingFunc = (message: string) => string

export const ServerErrors: { [key: number]: string | ErrorConvertingFunc } = {
  30_000: (msg: string) => {
    if (msg.includes(':')) {
      return msg.split(':')[1]
    }
    return msg
  },
  30_101: `어드민에 접근할 수 없는 계정입니다.`,
  30_102: `비밀번호 조건에 맞지 않습니다.`,
  40_000: `기존 비밀번호가 맞지 않습니다.`,
  [ErrorCode.ServerItemNotFound]: `로그인 세션이 만료되었습니다. 새로고침 후, 다시 로그인해주세요!`,
  // 작품 관리
  [ErrorCode.ChallengeStoryCannotHaveWillPublishAt]: `[작품 공개 예정일] 도전 스토리는 작품 공개 예정일을 입력할 수 없습니다.`,
  [ErrorCode.RepresentDateAlreadyExist]: `[월간 인기작] 해당 월은 이미 월간 인기작이 설정되어 있습니다.`,
  [ErrorCode.InvalidStoryEndingArrivalRateRange]: `[엔딩 설계값 변동 퍼센트%] 0 ~ 100 사이의 수로 입력해주세요.`,
  // 선택지 관리
  [ErrorCode.ChoiceFromPlayerClassAlreadyExist]: '이미 존재하는 통계 입니다!',
  // 속성 관리
  [ErrorCode.SameDisplayPropNameAlreadyExist]:
    '이미 동일한 이름의 노출 속성 이름이 존재합니다.',
  // 온보딩 팝업 관리
  [ErrorCode.NameAndTextCanNotAddPopupChoice]: `이름/설명 팝업은 선택지를 생성할 수 없습니다.`,
  [ErrorCode.NamePopupCanNotInactive]: `주인공 이름 팝업은 비활성화 할 수 없습니다.`,
  [ErrorCode.NeedToAddPopupChoice]: `팝업 선택지를 1개 이상 등록하셔야 합니다.`,
  [ErrorCode.NeedToOneMoreActivePopupChoice]: `선택 가능한 팝업 선택지를 1개 이상 등록하셔야 합니다.`,
  [ErrorCode.OtherPopupHaveToPutCharacterId]: `상대역을 설정하는 캐릭터를 지정해주세요.`,
  [ErrorCode.NamePopupAlreadyExist]: `주인공 이름 팝업이 이미 존재합니다.`,
  [ErrorCode.OtherNamePopupNeedDefaultFirstName]: `(등장 캐릭터 메뉴에서) 설정 캐릭터의 디폴트 이름을 설정해주세요.`,
  [ErrorCode.OtherNamePopupNeedDefaultLastName]: `(등장 캐릭터 메뉴에서) 설정 캐릭터의 디폴트 성을 설정해주세요.`,
  [ErrorCode.ProfilePopupChoiceNeedImageFile]: `프로필 타입의 선택지는 이미지가 들어가야합니다.`,
  // 플레이어 클래스 관리
  [ErrorCode.SameNamePlayerClassAlreadyExist]:
    '이미 동일한 이름의 플레이어 클래스가 존재합니다.',
}

Object.keys(ServerErrors).forEach((key) => {
  // @ts-ignore
  registerServerErrorMessageMap(key, ServerErrors[key])
})
