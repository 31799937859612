"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2RenderBlockEditor = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const errors_1 = require("../../../../errors");
const EntityEditor_1 = require("../EntityEditor");
const fields_1 = require("../fields");
const playerReportContainerHTML_1 = require("../playerReport2/playerReportContainerHTML");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOPlayerReport2RenderBlockEditor extends EntityEditor_1.EntityEditor {
    constructor(block, env) {
        super(block);
        this.outputHTML = null;
        this.env = env;
        const stringFields = [
            {
                key: 'name',
                options: {
                    label: trans('legacy_PlayerReportListPage_name'),
                    placeholder: trans('legacy_DOPlayerReport2RenderBlockEditor_Please_enter_the_unique_name_of_the_render_block'),
                    options: { noLabel: true, editable: false },
                },
            },
            {
                key: 'desc',
                options: {
                    label: trans('legacy_spa_version_item_edit_or_create_container_explanation'),
                    placeholder: trans('legacy_DOPlayerReport2RenderBlockEditor_enter_renderblock_description'),
                    inputType: 'textarea',
                    options: { noLabel: true },
                },
            },
            {
                key: 'code',
                options: {
                    label: trans('legacy_DOPlayerReport2RenderBlockEditor_html_template_code'),
                    placeholder: trans('legacy_DOPlayerReport2RenderBlockEditor_enter_html_code_nunjucks'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'codeSample',
                options: {
                    label: trans('legacy_DOPlayerReport2RenderBlockEditor_sample_for_copy'),
                    placeholder: trans('legacy_DOPlayerReport2RenderBlockEditor_enter_sample_code_renderblock'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'sampleData',
                options: {
                    label: trans('legacy_PlayerReportEditPage_sample_data'),
                    placeholder: trans('legacy_DOPlayerReport2RenderBlockEditor_enter_sample_data_renderblock'),
                    options: { noLabel: true },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new fields_1.TextFieldWithUI(fld.key, () => block[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        this.nameField.input.setRequired(true);
        this.nameField.input.setAutoFocus();
        (0, mobx_1.makeObservable)(this, {
            outputHTML: mobx_1.observable,
        });
    }
    get prbId() {
        return this._target.serverId;
    }
    get renderBlockParamContext() {
        // tslint:disable-next-line:no-eval
        let o = JSON.parse(this.sampleDataField.value || '{}');
        if (!(0, lodash_1.isObject)(o)) {
            const error = new errors_1.SPCError(errors_1.ErrorCode.PLAYER_REPORT2_SAMPLE_DATA_INVALID);
            this._target.rootStore.showError(error.toString());
            o = {};
        }
        return o;
    }
    get context() {
        const renderBlockContext = this.renderBlockParamContext;
        return {
            param: renderBlockContext,
        };
    }
    generateSampleHTML() {
        const code = this.codeField.value;
        try {
            const html = this.env.renderString(code, this.context);
            (0, mobx_1.runInAction)(() => {
                this.outputHTML = (0, playerReportContainerHTML_1.createPlayerReport2HTMLBlock)(this.env, this.nameField.value, html, this._target.rootStore.playerReportCSSLink);
            });
            return true;
        }
        catch (ex) {
            this._target.rootStore.showError(ex);
            return false;
        }
    }
    async submitChanges(onError) {
        if (!this.generateSampleHTML()) {
            return false;
        }
        return super.submitChanges(onError);
    }
}
exports.DOPlayerReport2RenderBlockEditor = DOPlayerReport2RenderBlockEditor;
