import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLAdminLog, GQLListableAdminLog, GQLListableAdminLogInput } from '../../@types/server'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useCounter, useODQuery } from '../../context/ODCommon'
import { ODListableOption, ODListableResponseType, useODListableContext } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'
import { ADMIN_ACTION_TO_STRING } from './AdminLogCommon'

interface Props {}

export interface AdminLogDataLoaderOption extends ODListableOption {
  filter: string | null
}

const FULL_SNAPSHOT = `
  adminLogId
  createdAt
  action
  userId
  user {
    userId
    name
  }
  targetUserId
  targetUser {
    userId
    name
  }
`

const GQL_LIST = `
query listAdminLog($data: ListableAdminLogInput!) {
  listAdminLog(data: $data) {
    list {
      ${FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const AdminLogListContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<GQLAdminLog, AdminLogDataLoaderOption>()

  const listAdminLog = useODQuery<GQLListableAdminLogInput, GQLListableAdminLog>(GQL_LIST)
  const [token] = useCounter()

  const dataLoader = React.useCallback(
    async function AdminLogDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: AdminLogDataLoaderOption
    ): Promise<ODListableResponseType<GQLAdminLog>> {
      const r = await listAdminLog({
        page,
        pageSize,
        filter: options.filter || null,
      })
      return r as ODListableResponseType<GQLAdminLog>
    },
    [listAdminLog]
  )

  const TableDefinition: ODListableTableDefinition<GQLAdminLog, AdminLogDataLoaderOption, any> = [
    {
      id: 'adminLogId',
      title: '기록 아이디',
      transform: (v) => v.adminLogId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'user',
      title: '행위자',
      transform: (v) => (
        <Link to={SiteUrls.Admin.User.Edit(v.userId)}>
          [일련번호 : {v.userId}] {v.user.name}
        </Link>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'action',
      title: '액션',
      transform: (v) => <span>{ADMIN_ACTION_TO_STRING[v.action]}</span>,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'targetUser',
      title: '대상자',
      transform: (v) => {
        if (v.targetUser) {
          return (
            <Link to={SiteUrls.Admin.User.Edit(v.targetUserId!)}>
              [일련번호 : {v.targetUserId}] {v.targetUser.name}
            </Link>
          )
        }

        return '-'
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'createdAt',
      title: '발생 시각',
      transform: (v) => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.adminLogId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            {/*<div style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
            {/*  <ODListableSearchBox*/}
            {/*    listableContext={Context}*/}
            {/*    placeholder="검색하세요."*/}
            {/*    style={{ flexGrow: 6, maxWidth: 600 }}*/}
            {/*  />*/}
            {/*</div>*/}
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩중..'}
              renderEmpty={() => '로그가 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
