import React from 'react'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../../../base/useDetectOutsideClick'
import { IODImageFileRendererDef, IODRendererProps, ODRendererEventType } from './common'
import { DragAndDropComponent } from './DragAndDropComponent'

interface IODImageFileRendererProps<ParentContextType, OriginalValueType>
  extends IODRendererProps<boolean, IODImageFileRendererDef, ParentContextType, OriginalValueType> {
  value: any
  def: IODImageFileRendererDef
}

const Wrapper = styled.div`
  background-color: transparent;
  border: 1px dashed gray;
  cursor: pointer;
  position: relative;
`

const Img = styled.img`
  object-fit: cover;
`

export const ODImageFileRenderer: React.FC<IODImageFileRendererProps<any, any>> = (props) => {
  const {
    def: { alt, height, width, eventKey },
    value,
    context,
    originalValue,
  } = props

  const imgProps = { width, height }
  const [isUploadViewOpen, setIsUploadViewOpen] = React.useState(false)
  const [blobUrl, setBlobUrl] = React.useState('')
  const dndRef = React.useRef<HTMLDivElement>(null)
  useDetectOutsideClick(() => setIsUploadViewOpen(false), dndRef, isUploadViewOpen)

  const link = blobUrl || value

  const image = React.useMemo(() => {
    return link ? (
      <Img style={{ width, height }} alt={alt} src={link} {...imgProps} />
    ) : (
      <Img
        alt="empty"
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        style={{ width, height }}
        {...imgProps}
      />
    )
  }, [alt, link, width, height, imgProps])

  const handleChange = React.useCallback(
    (file: File, blobUrl: string) => {
      setBlobUrl(blobUrl)
      context.onEvent?.(
        {
          type: ODRendererEventType.FileAttached,
          eventKey,
          file: file,
        },
        { ...context.parentContext, renderedFromOriginalValue: originalValue }
      )
    },
    [eventKey, setBlobUrl, context, originalValue]
  )

  return (
    <Wrapper
      style={{ width, height, border: isUploadViewOpen ? '0' : undefined }}
      ref={dndRef}
      onDragEnter={() => setIsUploadViewOpen(true)}
      onClick={() => setIsUploadViewOpen(true)}
    >
      <DragAndDropComponent
        style={{ width, height }}
        isOpen={isUploadViewOpen}
        onChange={handleChange}
        onClose={() => setIsUploadViewOpen(false)}
        onDragLeave={() => setIsUploadViewOpen(false)}
        toggle={() => setIsUploadViewOpen((v) => !v)}
      />
      {image}
    </Wrapper>
  )
}
