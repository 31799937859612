import { SEX_DISTINCTION } from '@storyplay/common'
import React from 'react'
import Select from 'react-select'

interface ITtsSpeakerGenderSelectProps {
  value: SEX_DISTINCTION | undefined
  onChange: (value: SEX_DISTINCTION | undefined) => void
}

export const TtsSpeakerGenderSelect: React.FC<ITtsSpeakerGenderSelectProps> = (
  props
) => {
  const { value, onChange } = props
  const filterTypes = [
    SEX_DISTINCTION.Woman,
    SEX_DISTINCTION.Man,
    SEX_DISTINCTION.Neutrality,
  ]

  const selectOptions = [
    {
      label: '모든 성별',
      value: undefined,
    },
    ...filterTypes.map((filterType) => ({
      label: filterType,
      value: filterType,
    })),
  ]

  return (
    <Select
      placeholder="스피커 성별"
      value={selectOptions.find((v) => v.value === value)}
      // @ts-ignore
      onChange={(v) => onChange(v.value)}
      options={selectOptions}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
