"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumerateEditorField = void 0;
/**
 * 매번 필드를 반환하는 귀찮음을 줄이기 위해 class 멤버 필드에서 에디터 필드를 찾는다.
 */
function enumerateEditorField(target) {
    return Object.getOwnPropertyNames(target)
        .filter(name => {
        // IEditorField 를 이걸로 판별하였음.
        return (target[name] &&
            target[name].hasOwnProperty('fieldId') &&
            target[name].hasOwnProperty('defValue'));
    })
        .map(name => target[name]);
}
exports.enumerateEditorField = enumerateEditorField;
