import {
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  ODImageFileInput,
  useCounter,
} from '@odc/od-react-belt'
import React from 'react'
import Select from 'react-select'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLEndingCard,
  GQLEndingCardCreationInput,
  GQLEndingCardUpdateInput,
} from '../../../@types/server'
import { ENDING_CARD_TYPE } from '../../../common'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { SPFormToggleButton } from '../../../components/SPFormToggleButton'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IEndingCardPageProps {
  storyId: number
  endingId: number
  isFinal: boolean
  cardId: number | null
}

type Entity = GQLEndingCard

function getValidationSchema(
  values: Partial<GQLEndingCardCreationInput | GQLEndingCardUpdateInput>
) {
  return Yup.object().shape({})
}

export const EndingCardEditPage: React.FC<IEndingCardPageProps> = (props) => {
  const { storyId, endingId, isFinal, cardId } = props
  const innerRef = React.useRef(null)
  const {
    createEndingCard,
    updateEndingCard,
    deleteEndingCard,
    getEndingCard,
    apiListCharacters,
  } = useStoryPlayAPIs()

  const [token] = useCounter()
  const isCreating = cardId === 0

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLEndingCardCreationInput | GQLEndingCardUpdateInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (cardId) {
          return getEndingCard({ id: cardId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            showOrder: 1000,
            type: ENDING_CARD_TYPE.ArrivalRate,
            characterId: null,
            characterName: '',
            typeName: '',
          }
        }

        const typeName = data.type
          ? {
              [ENDING_CARD_TYPE.ArrivalRate]: '[작품] 최종 엔딩 도착률',
              [ENDING_CARD_TYPE.ChoiceCount]: '[전체] 선택 누적 횟수',
              [ENDING_CARD_TYPE.EndingCollectRate]: '[작품] 엔딩 수집률',
            }[data.type]
          : ''

        return {
          cardId: data.cardId,
          type: data.type,
          showOrder: data.showOrder,
          imageFile: data.character.imageFile,
          characterId: data.characterId,
          characterName: data.character.name,
          typeName,
        }
      },
      saveClientValueToServer: async (
        data: Partial<GQLEndingCardCreationInput | GQLEndingCardUpdateInput>
      ) => {
        const { type, showOrder } = data as GQLEndingCardCreationInput
        const characterId = selectedCharacterRef.current
          ? selectedCharacterRef.current
          : undefined

        if (cardId) {
          await updateEndingCard({ id: cardId, showOrder, characterId })
          Utils.showSuccess('엔딩 카드가 수정되었습니다.', 'Success')
        } else {
          await createEndingCard({
            endingId,
            type,
            showOrder,
            characterId,
          })
          Utils.showSuccess('엔딩 카드가 생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.EditEnding(storyId, endingId, isFinal)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (cardId) {
          await deleteEndingCard({ id: cardId })
          Utils.showSuccess('엔딩 카드가 삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.EditEnding(storyId, endingId, isFinal)
      },
      __innerReference: innerRef,
    }),
    [
      cardId,
      getEndingCard,
      storyId,
      endingId,
      isFinal,
      updateEndingCard,
      createEndingCard,
      token,
      deleteEndingCard,
    ]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<GQLEndingCardCreationInput | GQLEndingCardUpdateInput>
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<GQLEndingCardCreationInput | GQLEndingCardUpdateInput>
    >(options)
  )

  React.useEffect(
    () => setOptions(createOptions()),
    [createOptions, createEndingCard]
  )
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          Partial<GQLEndingCardCreationInput | GQLEndingCardUpdateInput>
        >(options)
      ),
    [options]
  )

  const [characterOption, setCharacterOption] = React.useState<
    { label: string; value: number }[]
  >([])
  const selectedCharacterRef = React.useRef(0)

  React.useEffect(() => {
    apiListCharacters({ storyId, pageSize: 500 }).then((r) => {
      setCharacterOption(
        r.list.map((character) => ({
          label: character.name!,
          value: character.chrId,
        }))
      )
    })
  }, [apiListCharacters, storyId])

  const title = !cardId ? '엔딩 카드 생성' : '엔딩 카드 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            {!isCreating && (
              <ODEntityInput
                keyPath="cardId"
                label="엔딩 카드 아이디"
                name="cardId"
                placeholder="엔딩 카드 아이디 (자동부여)"
                inputType="text"
                inputProps={{ disabled: true }}
              />
            )}
            <ODEntityLabeled
              label="엔딩 카드 표시 옵션"
              name="엔딩 카드 표시 옵션"
            >
              <ToggleButtonWrapper>
                <SPFormToggleButton
                  name="type"
                  keyPath="type"
                  value={ENDING_CARD_TYPE.ArrivalRate}
                >
                  [작품] 최종 엔딩 도착률
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="type"
                  keyPath="type"
                  value={ENDING_CARD_TYPE.ChoiceCount}
                >
                  [전체] 선택 누적 횟수
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="type"
                  keyPath="type"
                  value={ENDING_CARD_TYPE.EndingCollectRate}
                >
                  [작품] 엔딩 수집률
                </SPFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>

            {!isCreating && (
              <>
                <ODEntityLabeled
                  name={'설정된 엔딩 등장 캐릭터'}
                  label={'설정된 엔딩 등장 캐릭터'}
                >
                  <ODEntityRaw
                    name="characterName"
                    keyPath="characterName"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
                <ODEntityLabeled label="설정된 캐릭터 이미지" name="imageFile">
                  <ODImageFileInput
                    name="imageFile"
                    height={200}
                    width={200}
                    keyPath="imageFile.link"
                  />
                </ODEntityLabeled>
              </>
            )}

            <ODEntityLabeled
              name={'엔딩 카드 등장 캐릭터'}
              label={'엔딩 카드 등장 캐릭터'}
            >
              <Select
                isSearchable={true}
                placeholder="엔딩 카드에 노출 할 등장 캐릭터"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    selectedCharacterRef.current = v.value
                  }
                }}
                options={characterOption}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="showOrder"
              label="노출 우선순위"
              name="showOrder"
              placeholder="노출 우선순위"
              inputType="number"
            />
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: <>정말 삭제하시겠습니까?</>,
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={cardId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
