"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOChoiceStore = void 0;
const mobx_1 = require("mobx");
const DOChoice_1 = require("./DOChoice");
class DOChoiceStore {
    constructor(rootStore, chapter) {
        this.rootStore = rootStore;
        this.chapter = chapter;
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            allChoices: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    getByName(name) {
        return Object.values(this.data).find(v => v.name === name);
    }
    merge(data) {
        const id = data.choiceId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOChoice_1.DOChoice(this, data, this.chapter);
        }
        return this.data[id];
    }
    get allChoices() {
        return Object.values(this.data);
    }
}
exports.DOChoiceStore = DOChoiceStore;
