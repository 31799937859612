import { ODEntity, ODEntityLabeled, ODImageFileInput, useCounter } from '@odc/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { GQLSFXFile, GQLSFXFileCreationInput } from '../../@types/server'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { SiteUrls } from '../../urls'
import { GQL_CREATE_SFX_FILE, GQL_GET_SFX_FILE, GQL_REMOVE_SFX_FILE } from '../../utils/gqls_web'
import { useODEntityAPI } from '../../utils/odGQLEntityLoader'
import { ICTSFXFile, SFXFilePrimaryKey } from './SFXFileCommon'

interface ISFXFileEditPageProps {
  id: number | null
}

const DEFAULT_DATA: Partial<ICTSFXFile> = {
  fileId: 0,
}

export const SFXFileEditPage: React.FC<ISFXFileEditPageProps> = (props) => {
  const { id } = props
  const apis = useODEntityAPI<GQLSFXFile, ICTSFXFile, GQLSFXFileCreationInput, void>({
    createGQL: gql(GQL_CREATE_SFX_FILE),
    readGQL: gql(GQL_GET_SFX_FILE),
    deleteGQL: gql(GQL_REMOVE_SFX_FILE),
    primaryKeyName: SFXFilePrimaryKey,
  })

  const [token] = useCounter()

  return (
    <FlexContentsContainer>
      <Card>
        <CardBody>
          <ODEntity
            resourceId={id}
            // @ts-ignore
            api={apis}
            saveButtonName="저장"
            deleteButtonName="삭제"
            titleCreation="추가"
            titleUpdate="수정"
            updateSuccessTitle="성공"
            updateSuccessMessage="수정하였습니다."
            urlAfterCreation={() => SiteUrls.Admin.SFXFile.List}
            urlAfterDelete={() => SiteUrls.Admin.SFXFile.List}
            deleteSuccessTitle="성공"
            deleteSuccessMessage="삭제하였습니다."
            defaultCreateClientData={DEFAULT_DATA}
            urlAfterUpdate={() => null}
            noCardWrap
            refreshToken={token}
            footerProps={{ buttonStyle: { borderRadius: 9999 } }}
          >
            <ODEntityLabeled label="사운드 이펙트 파일" name="link">
              <ODImageFileInput
                name="file"
                height={92}
                width={92}
                keyPath="link"
                render={(link) => {
                  if (!link) {
                    return false
                  }
                  return <span>파일 첨부됨</span>
                }}
              />
            </ODEntityLabeled>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <hr />
            </div>
          </ODEntity>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
