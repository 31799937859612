"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALChangeChapterModal = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../../autoLayout");
const ActionChainBuilder_1 = require("../../../../autoLayout/builder/ActionChainBuilder");
const consts_1 = require("../../../../consts");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ALChangeChapterModal {
    constructor(chapter, story) {
        this.chapter = chapter;
        this.story = story;
    }
    async submit(nameIn, _isAdult) {
        var _a, _b;
        const name = nameIn.trim();
        const isPublished = !!this.chapter.publishedAt;
        const isSameOrGreaterThanAdmin = (_a = this.story.rootStore.loginStore.user) === null || _a === void 0 ? void 0 : _a.isSameOrGreaterThanAdmin;
        const isAdult = !isPublished || isSameOrGreaterThanAdmin ? _isAdult : undefined;
        try {
            // @ts-ignore
            const res = await this.chapter.update({ name, isAdult });
            return (_b = this.story.rootStore.storyEditing) === null || _b === void 0 ? void 0 : _b.chapterStore.merge(res);
        }
        catch (ex) {
            this.story.rootStore.showError(ex);
        }
    }
    buildActionChain() {
        var _a, _b;
        const nameField = new ui_1.InputWithValidation('chapterName', trans('legacy_DOChapter_name'), trans('legacy_DOChapter_enter_new_name'), 'text', (_a = this.chapter.name) !== null && _a !== void 0 ? _a : '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: ui_1.InputTheme.Default,
            maxLength: 40,
        })
            .setRequired(true)
            .setAutoFocus();
        nameField.setEditing(true);
        const chapterPublishedAt = this.chapter.publishedAt;
        const isSameOrGreaterThanAdmin = (_b = this.story.rootStore.loginStore.user) === null || _b === void 0 ? void 0 : _b.isSameOrGreaterThanAdmin;
        const isAdultField = new ui_1.SelectionInput('isAdult', '연령 기준', this.chapter.isAdult, [
            { name: '', value: true },
            { name: '', value: false },
        ], {
            isDisabled: !chapterPublishedAt ? false : !isSameOrGreaterThanAdmin,
        });
        const builder = new ActionChainBuilder_1.ActionChainBuilder('TemplateCreateNewBlockAction');
        builder.addModal(modalBuilder => {
            modalBuilder.setCancelOnClickOutside(false);
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: '회차 변경',
                imageName: consts_1.SPImageName.IcnEditFile,
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(nameField));
            });
            !this.story.isAdult &&
                modalBuilder.addRow(rowBuilder => {
                    rowBuilder.addCol(col => col.setSelectionInput(isAdultField));
                });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_ALCreateNewChapter_cancel'),
                        onClick: () => {
                            window.sessionStorage.removeItem('chapterId');
                            modalBuilder.cancelAction();
                        },
                    },
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: trans('legacy_ALCreateNewChapter_complete'),
                        onClick: async () => {
                            window.sessionStorage.removeItem('chapterId');
                            const alm = this.story.rootStore.autoLayoutManager;
                            alm.setShowScreenLoading(true);
                            try {
                                if (await this.submit(nameField.value, Boolean(isAdultField.value))) {
                                    modalBuilder.finishAction(null);
                                }
                            }
                            finally {
                                alm.setShowScreenLoading(false);
                            }
                        },
                    },
                ],
            });
        });
        return builder.buildActionChain();
    }
}
exports.ALChangeChapterModal = ALChangeChapterModal;
