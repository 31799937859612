"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOChrOnboardingPopupEditor = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../@types/server.schema");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOChrOnboardingPopupEditor {
    constructor(editStore, chr) {
        var _a, _b, _c, _d, _e, _f, _g;
        this.rootStore = editStore.rootStore;
        this.editStore = editStore;
        this.chr = chr;
        this.otherName =
            (_b = (_a = this.chr.onboardingPopups.filter(v => v.type === server_schema_1.GQLONBOARDING_POPUP_TYPE.OtherName)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : null;
        this.isOnlyName = !!((_c = this.otherName) === null || _c === void 0 ? void 0 : _c.onlyName);
        this.isActive = this.otherName
            ? this.otherName.status === server_schema_1.GQLSTATUS.Active
            : true;
        this.isUpsert = !!this.otherName;
        this.showOrderField = new ui_1.InputWithValidation('showOrder', trans('legacy_DOChrOnboardingPopupEditor_Priority'), '1000', 'number', (_e = (_d = this.otherName) === null || _d === void 0 ? void 0 : _d.showOrder) !== null && _e !== void 0 ? _e : 1000);
        this.descriptionField = new ui_1.InputWithValidation('description', trans('legacy_BlockEditorInput_passage_from_a_novel'), trans('legacy_DOChrOnboardingPopupEditor_Please_enter_the_fingerprint'), 'text', (_g = (_f = this.otherName) === null || _f === void 0 ? void 0 : _f.description) !== null && _g !== void 0 ? _g : '');
        (0, mobx_1.makeObservable)(this, {
            isActive: mobx_1.observable,
            isOnlyName: mobx_1.observable,
            isUpsert: mobx_1.observable,
        });
    }
    setIsActive(v) {
        (0, mobx_1.runInAction)(() => {
            this.isActive = v;
        });
    }
    setIsOnlyName() {
        (0, mobx_1.runInAction)(() => {
            this.isOnlyName = !this.isOnlyName;
        });
    }
    setIsUpsert(v) {
        (0, mobx_1.runInAction)(() => {
            this.isUpsert = v;
        });
    }
    prepareSubmit() {
        if (!this.isUpsert) {
            return;
        }
        if (this.editStore.defaultFirstName.value.trim().length === 0) {
            throw new core_1.SPCError(core_1.ErrorCode.ChrOnboardingRequireFirstName);
        }
        if (!this.isOnlyName &&
            this.editStore.defaultLastName.value.trim().length === 0) {
            throw new core_1.SPCError(core_1.ErrorCode.ChrOnboardingRequireLastName);
        }
    }
    async submit() {
        const server = this.rootStore.di.server;
        this.prepareSubmit();
        if (!this.isUpsert) {
            await this.removeProcess();
            return;
        }
        // 없으면 생성
        if (!this.otherName) {
            const input = {
                type: server_schema_1.GQLONBOARDING_POPUP_TYPE.OtherName,
                storyId: this.chr.store.story.storyId,
                chapterId: this.chr.store.story.chapterStore.firstChapter.id,
                characterId: this.chr.id,
                onlyName: this.isOnlyName,
                description: this.descriptionField.value,
                showOrder: this.showOrderField.value,
                status: this.isActive ? server_schema_1.GQLSTATUS.Active : server_schema_1.GQLSTATUS.Inactive,
            };
            const r = await server.createOnboardingPopup(input);
            this.chr.store.merge({ chrId: this.chr.id, onboardingPopups: [r] });
        }
        else {
            const input = {
                id: this.otherName.popupId,
                onlyName: this.isOnlyName,
                description: this.descriptionField.value,
                showOrder: this.showOrderField.value,
                status: this.isActive ? server_schema_1.GQLSTATUS.Active : server_schema_1.GQLSTATUS.Inactive,
            };
            const r = await server.updateOnboardingPopup(input);
            this.chr.store.merge({ chrId: this.chr.id, onboardingPopups: [r] });
        }
    }
    async removeProcess() {
        const server = this.rootStore.di.server;
        if (!this.otherName) {
            return;
        }
        await server.removeOnboardingPopup({ id: this.otherName.popupId });
        this.chr.removeOnboardingPopup(this.otherName.popupId);
    }
}
exports.DOChrOnboardingPopupEditor = DOChrOnboardingPopupEditor;
