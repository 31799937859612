import { gapi } from 'gapi-script'
import { produce } from 'immer'
import * as React from 'react'
import { useEffect } from 'react'
import { AppOptions } from '../utils/AppOptions'

export type GOOGLE_USER = any

export type USER_PROFILE = {
  googleUser: GOOGLE_USER
  id: string
  email: string
  image: string
  name: string
}

type GoogleAppContextType = {
  // server: ServerInterface
  state: GoogleAppReducerState
  loginGoogle: () => Promise<void>
  logoutGoogle: () => Promise<void>
}

export enum GOOGLE_LOGIN_STATE {
  Checking = 'Checking',
  LoggedOut = 'LoggedOut',
  LoggedIn = 'LoggedIn',
}

type GoogleAppContextProviderProps = {}

type GoogleAppReducerState = {
  loginState: GOOGLE_LOGIN_STATE
  me: USER_PROFILE | null
}

enum GoogleAppActionType {
  SetLoggedIn = 'GoogleApp/SetLoggedIn',
  SetLoggedOut = 'GoogleApp/SetLoggedOut',
  ResetGoogleApp = 'GoogleApp/ResetGoogleApp',
}

type GoogleAppActionSetLoggedIn = { type: GoogleAppActionType.SetLoggedIn; googleUser: GOOGLE_USER }
type GoogleAppActionSetLoggedOut = { type: GoogleAppActionType.SetLoggedOut }
type GoogleAppActionResetGoogleApp = { type: GoogleAppActionType.ResetGoogleApp }

type GoogleAppReducerAction = GoogleAppActionSetLoggedIn | GoogleAppActionSetLoggedOut | GoogleAppActionResetGoogleApp

interface GoogleAppReducer extends React.Reducer<GoogleAppReducerState, GoogleAppReducerAction> {}

function createGoogleAppReducer(): GoogleAppReducer {
  return function (state: GoogleAppReducerState, action: GoogleAppReducerAction): GoogleAppReducerState {
    return produce(state, (draft) => {
      switch (action.type) {
        case GoogleAppActionType.SetLoggedIn: {
          draft.loginState = GOOGLE_LOGIN_STATE.LoggedIn
          const { googleUser } = action
          draft.me = {
            googleUser,
            id: googleUser.getBasicProfile().getId(),
            email: googleUser.getBasicProfile().getEmail(),
            image: googleUser.getBasicProfile().getImageUrl(),
            name: googleUser.getBasicProfile().getName(),
          }
          break
        }
        case GoogleAppActionType.SetLoggedOut:
        case GoogleAppActionType.ResetGoogleApp:
          draft.loginState = GOOGLE_LOGIN_STATE.LoggedOut
          draft.me = null
          break
        default:
          return
      }
    })
  }
}

const actionSetLoggedIn = (googleUser: GOOGLE_USER): GoogleAppActionSetLoggedIn => ({
  type: GoogleAppActionType.SetLoggedIn,
  googleUser,
})
const actionSetLoggedOut = (): GoogleAppActionSetLoggedOut => ({ type: GoogleAppActionType.SetLoggedOut })
const actionResetGoogleApp = (): GoogleAppActionResetGoogleApp => ({ type: GoogleAppActionType.ResetGoogleApp })

function createInitialGoogleAppReducerState(): GoogleAppReducerState {
  return {
    loginState: GOOGLE_LOGIN_STATE.Checking,
    me: null,
  }
}

function createGoogleAppContext() {
  const Context: React.Context<GoogleAppContextType> = React.createContext<GoogleAppContextType>(
    {} as GoogleAppContextType
  )

  const GoogleAppProvider: React.FC<GoogleAppContextProviderProps> = (props) => {
    const { children } = props

    const [state, dispatch] = React.useReducer<GoogleAppReducer>(
      createGoogleAppReducer(),
      createInitialGoogleAppReducerState()
    )

    const logoutGoogle = React.useCallback(async () => {
      let auth2 = gapi.auth2.getAuthInstance()
      await auth2.signOut()
      dispatch(actionResetGoogleApp())
    }, [])

    const loginGoogle = React.useCallback(async () => {
      gapi.auth2.getAuthInstance().signIn()
    }, [])

    useEffect(() => {
      async function loadGoogleOAuth2() {
        // https://github.com/LucasAndrad/gapi-script-live-example/blob/master/src/components/GoogleLogin.js
        const SCOPES = 'https://www.googleapis.com/auth/spreadsheets.readonly'
        const DISCOVERY_DOCS = ['https://sheets.googleapis.com/$discovery/rest?version=v4']

        await new Promise((resolve, reject) => {
          gapi.load('client:auth2', () => {
            gapi.client
              .init({
                apiKey: AppOptions.GOOGLE_API_KEY,
                client_id: AppOptions.GOOGLE_CLIENT_ID,
                scope: SCOPES,
                discoveryDocs: DISCOVERY_DOCS,
              })
              .then(() => {
                gapi.auth2.getAuthInstance().isSignedIn.listen((loggedIn: boolean) => {
                  console.log(128, loggedIn)
                  if (loggedIn) {
                    const googleUser = gapi.auth2.getAuthInstance().currentUser.get()
                    dispatch(actionSetLoggedIn(googleUser))
                  }
                })

                if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                  const googleUser = gapi.auth2.getAuthInstance().currentUser.get()
                  dispatch(actionSetLoggedIn(googleUser))
                } else {
                  dispatch(actionSetLoggedOut())
                }
              }, reject)
          })
        })
      }

      loadGoogleOAuth2().catch((ex) => console.error(ex))
    }, [])

    const context: GoogleAppContextType = {
      state,
      loginGoogle,
      logoutGoogle,
    }
    return <Context.Provider value={context}>{children}</Context.Provider>
  }

  return { Context, Provider: GoogleAppProvider }
}

const { Context, Provider } = createGoogleAppContext()

export const GoogleAppProvider = Provider
export const GoogleAppContext = Context

export function useGoogleAppContext(): GoogleAppContextType {
  return React.useContext(Context)
}
