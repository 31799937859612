import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLStoryItem } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IStoryItemListableOption } from '../StoryItemCommon'

export function useStoryItemListDataLoader(storyId: number) {
  const { listStoryItem } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IStoryItemListableOption
    ): Promise<ODListableResponseType<GQLStoryItem>> {
      const r = await listStoryItem({
        page,
        pageSize: 100,
        ...options,
        storyId,
      })
      return r as ODListableResponseType<GQLStoryItem>
    },
    [listStoryItem, storyId]
  )
}
