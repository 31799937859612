import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import { GQLCHOICE_TYPE, GQLChoice } from '@storyplay/core'
import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { HoverTR } from '../../../components/commonStyle'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { ChoiceFromPlayerClassListableTable } from '../../choiceFromPlayerClass/list/ChoiceFromPlayerClassListableTable'
import { UserSelectedChoiceListableTable } from '../../userSelectedChoice/list/UserSelectedChoiceListableTable'
import { ChoicePrimaryKey, IChoiceListableOption } from '../ChoiceCommon'
import { ChoiceListItem } from '../ChoiceListItem'
import { UpdateChoicePropModal } from '../UpdateChoicePropModal'
import { ChoiceDetailTable } from '../detail/ChoiceDetailTable'
import { useChoiceListDataLoader } from './ChoiceListDataLoader'

interface IChoiceListableTableProps {
  storyId: number
}

export const ChoiceListableTable: React.FC<IChoiceListableTableProps> = (
  props
) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<
    GQLChoice,
    IChoiceListableOption
  >()
  const [token, refresh] = useCounter()
  const dataLoader = useChoiceListDataLoader(storyId)
  const [choiceId, setChoiceId] = React.useState(0)
  const [choiceName, setChoiceName] = React.useState('')
  const [choiceType, setChoiceType] = React.useState<GQLCHOICE_TYPE>(
    GQLCHOICE_TYPE.Default
  )
  const [existPlayerClass, setExistPlayerClass] = React.useState(false)
  const { listPlayerClass } = useStoryPlayAPIs()
  const [showUpdateChoicePropModal, setShowUpdateChoicePropModal] =
    React.useState(false)

  React.useEffect(() => {
    listPlayerClass({ storyId, pageSize: 1 }).then((playerClass) => {
      setExistPlayerClass(playerClass.list.length > 0)
    })
  }, [listPlayerClass, storyId])

  const toolbarStyle = { display: 'flex', marginBottom: 15 }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <>
      <Provider
        dataLoader={dataLoader}
        keyExtractor={(v) => v[ChoicePrimaryKey].toString()}
        pageSize={10}
        refreshToken={token.toString()}
        onDataLoaderError={ODWebUtils.showError}
        searchOnLoad
      >
        <Card style={{ flexGrow: 2 }}>
          <CardBody>
            <div style={toolbarStyle}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="분기명, 챕터명으로 검색"
                style={searchBoxStyle}
              />
            </div>
            <ODListablePaginatedTable2
              numColumns={11}
              listableContext={Context}
              renderHeader={() => (
                <tr>
                  <th>아이디</th>
                  <th>타입</th>
                  <th>챕터명</th>
                  <th>분기명</th>
                  <th>사용자 노출 분기명</th>
                  <th>1번 선택 사용자 수</th>
                  <th>2번 선택 사용자 수</th>
                  <th>3번 선택 사용자 수</th>
                  <th>4번 선택 사용자 수</th>
                  <th>5번 선택 사용자 수</th>
                  <th>통계 속성 관리</th>
                </tr>
              )}
              renderRow={(value: GQLChoice, context) => (
                <>
                  <HoverTR
                    key={value.choiceId}
                    onClick={() => {
                      setChoiceId(value.choiceId)
                      setChoiceName(value.name)
                      setChoiceType(value.choiceType)
                    }}
                  >
                    <td>{value.choiceId}</td>
                    <td>{value.choiceType}</td>
                    <td>{value.chapter.name}</td>
                    <td>
                      <ChoiceListItem choice={value} />
                    </td>
                    <td>{value.displayName}</td>
                    <td>
                      {value.choice1PropName
                        ? `${value.choice1PropName} : `
                        : ''}
                      {value.choice1Selected} 회
                    </td>
                    <td>
                      {value.choice2PropName
                        ? `${value.choice2PropName} : `
                        : ''}
                      {value.choice2Selected} 회
                    </td>
                    <td>
                      {value.choice3PropName
                        ? `${value.choice3PropName} : `
                        : ''}
                      {value.choice3Selected} 회
                    </td>
                    <td>
                      {value.choice4PropName
                        ? `${value.choice4PropName} : `
                        : ''}
                      {value.choice4Selected} 회
                    </td>
                    <td>
                      {value.choice5PropName
                        ? `${value.choice5PropName} : `
                        : ''}
                      {value.choice5Selected} 회
                    </td>
                    <td>
                      <Button
                        block
                        color="primary"
                        style={{ minWidth: 50 }}
                        onClick={() => {
                          setShowUpdateChoicePropModal(true)
                        }}
                      >
                        통계 속성 수정
                      </Button>
                    </td>
                  </HoverTR>
                </>
              )}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage listableContext={Context} />
            {showUpdateChoicePropModal && (
              <UpdateChoicePropModal
                choiceId={choiceId}
                onClose={() => setShowUpdateChoicePropModal(false)}
                onConfirm={() => {
                  setShowUpdateChoicePropModal(false)
                  refresh()
                }}
              />
            )}
          </CardBody>
        </Card>
      </Provider>
      {choiceId !== 0 && (
        <>
          <Card>
            <CardBody>
              <div style={{ marginTop: 0 }}>
                <b style={{ float: 'left' }}>
                  [{choiceId}] {choiceName}&nbsp;
                </b>
                <span>전체 통계</span>
              </div>
              <ChoiceDetailTable choiceId={choiceId} />
            </CardBody>
          </Card>
          {existPlayerClass && (
            <Card>
              <CardBody>
                <div style={{ marginTop: 0 }}>
                  <b style={{ float: 'left' }}>
                    [{choiceId}] {choiceName}&nbsp;
                  </b>
                  <span>플레이어 클래스별 통계</span>
                  <ChoiceFromPlayerClassListableTable
                    choiceId={choiceId}
                    storyId={storyId}
                  />
                </div>
              </CardBody>
            </Card>
          )}
          {choiceType === GQLCHOICE_TYPE.UserInputChoice && (
            <Card>
              <CardBody>
                <div style={{ marginTop: 0 }}>
                  <b style={{ float: 'left' }}>
                    [{choiceId}] {choiceName}&nbsp;
                  </b>
                  <span>사용자 입력 데이터 확인</span>
                  <UserSelectedChoiceListableTable choiceId={choiceId} />
                </div>
              </CardBody>
            </Card>
          )}
        </>
      )}
    </>
  )
}
