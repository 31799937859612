import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODImageFileInput,
  useCounter,
  useODEntityAPI,
} from '@odc/od-react-belt'
import { GENDER } from '@storyplay/core'
import gql from 'graphql-tag'
import React from 'react'
import styled from 'styled-components'
import { GQLChr, GQLChrPropsInput } from '../../../@types/server'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import { SPFormToggleButton } from '../../../components/SPFormToggleButton'
import { GQL_GET_CHR, GQL_UPDATE_CHR } from '../../../hooks/gqls_admin'
import { SiteUrls } from '../../../urls'
import { ChrPrimaryKey, ICTChr } from '../ChrCommon'

interface IChrEditPageProps {
  storyId: number
  chrId: number | null
}

const DEFAULT_DATA: Partial<ICTChr> = {
  chrId: 0,
  name: ``,
  main: false,
  color: '#252526',
  imageFile: null,
  defaultFirstName: '',
  defaultLastName: '',
  displayName: '',
}

export const ChrEditPage: React.FC<IChrEditPageProps> = (props) => {
  const { storyId, chrId } = props
  const apis = useODEntityAPI<GQLChr, ICTChr, any, GQLChrPropsInput>({
    // createGQL: gql(GQL_CREATE_Chr),
    updateGQL: gql(GQL_UPDATE_CHR),
    readGQL: gql(GQL_GET_CHR),
    // deleteGQL: gql(GQL_REMOVE_Chr),
    primaryKeyName: ChrPrimaryKey,
  })

  const [token] = useCounter()

  return (
    <>
      <ODEntity
        resourceId={chrId}
        // @ts-ignore
        api={apis}
        saveButtonName="저장"
        // deleteButtonName="삭제"
        titleCreation="추가"
        titleUpdate="수정"
        updateSuccessTitle="성공"
        updateSuccessMessage="수정하였습니다."
        // urlAfterCreation={() => SiteUrls.Admin.Chr.Main}
        // urlAfterDelete={() => SiteUrls.Admin.Chr.Main}
        // deleteSuccessTitle="성공"
        // deleteSuccessMessage="삭제하였습니다."
        defaultCreateClientData={DEFAULT_DATA}
        urlAfterUpdate={() => SiteUrls.Admin.Story.Edit(storyId, 2)}
        noCardWrap
        refreshToken={token}
      >
        <ODEntityInput
          keyPath="chrId"
          label="캐릭터 아이디"
          name="chrId"
          placeholder="캐릭터 아이디 (자동부여)"
          inputType="text"
          inputProps={{ disabled: true }}
        />
        <ODEntityInput
          keyPath="name"
          label="캐릭터 이름"
          name="name"
          placeholder="캐릭터 이름"
          inputType="text"
          inputProps={{ disabled: true }}
        />
        <ODEntityInput
          keyPath="label"
          label="등장인물 레이블"
          name="label"
          placeholder="등장인물 레이블 (스토리게임에서 노출)"
          inputType="text"
        />
        <ODEntityInput
          keyPath="displayName"
          label="보여지는 캐릭터 이름"
          name="displayName"
          placeholder="보여지는 캐릭터 이름"
          inputType="text"
        />
        <ODEntityInput
          keyPath="defaultLastName"
          label="캐릭터 디폴트 성"
          name="defaultLastName"
          placeholder="캐릭터 디폴트 성"
          inputType="text"
        />
        <ODEntityInput
          keyPath="defaultFirstName"
          label="캐릭터 디폴트 이름"
          name="defaultFirstName"
          placeholder="캐릭터 디폴트 이름"
          inputType="text"
        />
        <ODEntityLabeled label="성별" name="gender">
          <ToggleButtonWrapper>
            <ODFormToggleButton
              key={'gender'}
              name={'gender'}
              keyPath={'gender'}
              value={GENDER.Unknown}
            >
              Unknown
            </ODFormToggleButton>
            <ODFormToggleButton
              key={'gender'}
              name={'gender'}
              keyPath={'gender'}
              value={GENDER.Male}
            >
              Male
            </ODFormToggleButton>
            <ODFormToggleButton
              key={'gender'}
              name={'gender'}
              keyPath={'gender'}
              value={GENDER.Female}
            >
              Female
            </ODFormToggleButton>
          </ToggleButtonWrapper>
        </ODEntityLabeled>
        <ODEntityLabeled label="분류" name="is_main">
          <ToggleButtonWrapper>
            <ODFormToggleButton
              key="main_true"
              name="main"
              keyPath="main"
              value={true}
            >
              메인 캐릭터
            </ODFormToggleButton>
            <ODFormToggleButton
              key="main_false"
              name="main"
              keyPath="main"
              value={false}
            >
              보조 캐릭터
            </ODFormToggleButton>
          </ToggleButtonWrapper>
        </ODEntityLabeled>
        <ODEntityLabeled label="이미지파일" name="imageFile">
          <ODImageFileInput
            name="imageFile"
            height={92}
            width={92}
            keyPath="imageFile.link"
          />
        </ODEntityLabeled>
        <ODEntityLabeled label="캐릭터 컬러" name="color">
          <div style={{ maxWidth: 100 }}>
            <ODEntityInput
              keyPath="color"
              label="캐릭터 컬러"
              name="color"
              placeholder="캐릭터 이름"
              inputType="color"
              withoutLabel
            />
          </div>
        </ODEntityLabeled>
        <ODEntityInput
          keyPath="prompt"
          label="(AI 자케 생성) 의상 프롬프트"
          name="prompt"
          placeholder="t-shirt"
          inputType="text"
        />
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <hr />
        </div>
      </ODEntity>
    </>
  )
}

const ToggleButtonWrapper = styled.div`
  display: flex;
`
