"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryPropsInputValidation = exports.StoryValidation = exports.EndingSummaryValidations = exports.AchievementValidations = exports.PropValidations = exports.ItemValidations = exports.ChrValidations = exports.BlockValidations = void 0;
const core_1 = require("@storyplay/core");
const Yup = require("yup");
const errors_1 = require("../errors");
const store_1 = require("./store");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
exports.BlockValidations = {
    name: Yup.string()
        .min(1, errors_1.ErrorCode.BLOCK_NAME_MIN_LEN_NOT_MET.toString())
        .max(20, errors_1.ErrorCode.BLOCK_NAME_MAX_LEN_NOT_MET.toString())
        .required(errors_1.ErrorCode.BLOCK_NAME_IS_REQUIRED.toString()),
};
const CHR_NAME_MAX_LENGTH = 140;
exports.ChrValidations = {
    NAME_MAX_LENGTH: CHR_NAME_MAX_LENGTH,
    name: Yup.string()
        .min(1, errors_1.ErrorCode.CHR_NAME_MIN_LEN_NOT_MET.toString())
        .max(CHR_NAME_MAX_LENGTH, errors_1.ErrorCode.CHR_NAME_MAX_LEN_NOT_MET.toString()),
};
const ITEM_NAME_MAX_LENGTH = 20;
exports.ItemValidations = {
    NAME_MAX_LENGTH: ITEM_NAME_MAX_LENGTH,
    name: Yup.string()
        .min(1, errors_1.ErrorCode.ITEM_NAME_MIN_LEN_NOT_MET.toString())
        .max(ITEM_NAME_MAX_LENGTH, errors_1.ErrorCode.ITEM_NAME_MAX_LEN_NOT_MET.toString())
        .test('noColon', errors_1.ErrorCode.ItemNameShouldNotIncludeColon.toString(), (value) => !value.includes(':'))
        .test('noWhiteSpace', errors_1.ErrorCode.ItemNameShouldNotStartOrEndWithSpace.toString(), (value) => value.trim().length === value.length)
        .test('noEmptyValue', store_1.StudioValidationType.ItemNameShouldNotBeEmptyValue.toString(), (value) => ![store_1.ITEM_EMPTY, store_1.COND_EMPTY].includes(value)),
    nameInCondition: Yup.string().test('noComma', store_1.StudioValidationType.ItemNameShouldNotIncludeCommaInCondition.toString(), (value) => !value.includes(',')),
    noIncludeCommaInName: Yup.string().test('noComma', store_1.StudioValidationType.ItemNameShouldNotIncludeComma.toString(), (value) => !value.includes(',')),
};
const PROP_NAME_MAX_LENGTH = 30;
exports.PropValidations = {
    NAME_MAX_LENGTH: PROP_NAME_MAX_LENGTH,
    name: Yup.string()
        .min(1, errors_1.ErrorCode.PropNameMinLengthNotMet.toString())
        .max(PROP_NAME_MAX_LENGTH, errors_1.ErrorCode.PropNameMaxLengthNotMet.toString())
        .test('noColon', errors_1.ErrorCode.PropNameShouldNotIncludeColon.toString(), (value) => !value.includes(':'))
        .test('noWhiteSpace', errors_1.ErrorCode.PropNameShouldNotStartOrEndWithSpace.toString(), (value) => value.trim().length === value.length)
        .test('noEmptyValue', store_1.StudioValidationType.PropNameShouldNotBeEmptyValue.toString(), (value) => ![store_1.PROP_EMPTY, store_1.COND_EMPTY].includes(value)),
    nameInCondition: Yup.string().test('noComma', store_1.StudioValidationType.PropNameShouldNotIncludeCommaInCondition.toString(), (value) => !value.includes(',')),
    noIncludeCommaInName: Yup.string().test('noComma', store_1.StudioValidationType.PropNameShouldNotIncludeComma.toString(), (value) => !value.includes(',')),
};
exports.AchievementValidations = {
    name: Yup.string().test('noEmptyValue', store_1.StudioValidationType.AchievementNameShouldNotBeEmptyValue.toString(), (value) => value !== store_1.ACHIEVEMENT_EMPTY),
};
exports.EndingSummaryValidations = {
    content: Yup.string().max(174, trans('legacy_validations.ts_can_enter_up_to_174_characters')),
};
exports.StoryValidation = {
    NameMinLength: 2,
    NameMaxLength: 70,
};
exports.StoryPropsInputValidation = {
    name: Yup.string()
        .min(exports.StoryValidation.NameMinLength, trans('legacy_validations.ts_work_name_must_be_at_least_d_characters', {
        value: exports.StoryValidation.NameMinLength,
    }))
        .max(exports.StoryValidation.NameMaxLength, trans('legacy_validations.ts_work_name_is_maximum_d_characters', {
        value: exports.StoryValidation.NameMaxLength,
    })),
    defaultFirstName: Yup.string()
        .min(1, trans('legacy_validations.ts_name_must_be_at_least_character', {
        value: 1,
    }))
        .max(40, trans('legacy_validations.ts_name_must_be_less_than_haracters', {
        value: 40,
    })),
    defaultLastName: Yup.string().max(40, trans('legacy_validations.ts_surname_must_be_less_than_characters', {
        value: 40,
    })),
};
