import { ODEntityRaw } from '@odc/od-react-belt'
import React from 'react'
import { ODButton, ODButtonTheme } from './base/ODButton'

interface IBPFormToggleButtonProps {
  name: string
  keyPath: string
  value?: any
  onClick?: (value: any) => void
}

const selectTheme = (enabled: boolean) => (enabled ? ODButtonTheme.Primary : ODButtonTheme.PrimaryInvert)

export const SPFormToggleButton: React.FC<IBPFormToggleButtonProps> = (props) => {
  const { name, keyPath, value: expectedValue, children, onClick } = props

  return (
    <ODEntityRaw
      name={name}
      keyPath={keyPath}
      render={({ value, formikContext: { setFieldValue } }) => {
        return (
          <ODButton
            type="button"
            style={{ minWidth: 100, marginRight: 10 }}
            onClick={() => {
              setFieldValue(keyPath, expectedValue === undefined ? !value : expectedValue)
              if (onClick) {
                onClick(expectedValue)
              }
            }}
            theme={expectedValue === undefined ? selectTheme(value) : selectTheme(value === expectedValue)}
          >
            {children}
          </ODButton>
        )
      }}
    />
  )
}
