import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
} from '@odc/od-react-belt'
import { GQLUserSelectedChoice } from '@storyplay/common'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useODListableContext } from '../../../ODListable/ODListableContext'
import {
  IUserSelectedChoiceListableOption,
  UserSelectedChoicePrimaryKey,
} from '../UserSelectedChoiceCommon'
import { useUserSelectedChoiceDataLoader } from './UserSelectedChoiceListDataLoader'

interface IUserSelectedChoiceListableTableProps {
  choiceId: number
}

export const UserSelectedChoiceListableTable: React.FC<
  IUserSelectedChoiceListableTableProps
> = (props) => {
  const { choiceId } = props
  const { Provider, Context } = useODListableContext<
    GQLUserSelectedChoice,
    IUserSelectedChoiceListableOption
  >()
  const [token] = useCounter()
  const dataLoader = useUserSelectedChoiceDataLoader(choiceId)

  const toolbarStyle = { display: 'flex', marginBottom: 15 }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }
  return (
    <>
      <Provider
        dataLoader={dataLoader}
        keyExtractor={(v) => v[UserSelectedChoicePrimaryKey].toString()}
        pageSize={10}
        refreshToken={token.toString()}
        onDataLoaderError={ODWebUtils.showError}
        searchOnLoad
      >
        <Card style={{ flexGrow: 2 }}>
          <CardBody>
            <div style={toolbarStyle}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="사용자 입력 데이터 검색"
                style={searchBoxStyle}
              />
            </div>
            <ODListablePaginatedTable2
              numColumns={4}
              listableContext={Context}
              renderHeader={() => (
                <tr>
                  <th>아이디</th>
                  <th>선택지</th>
                  <th>상대방 입력 데이터</th>
                  <th>사용자 입력 데이터</th>
                </tr>
              )}
              renderRow={(value: GQLUserSelectedChoice, context) => {
                const choiceList = [
                  value.choice.choice1Content,
                  value.choice.choice2Content,
                  value.choice.choice3Content,
                  value.choice.choice4Content,
                  value.choice.choice5Content,
                ]
                return (
                  <tr>
                    <td>{value.uscId}</td>
                    <td>
                      {value.selectedChoice}번&nbsp;
                      {choiceList[value.selectedChoice - 1]}
                    </td>
                    <td>{value.leftUserInputText}</td>
                    <td>{value.rightUserInputText}</td>
                  </tr>
                )
              }}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage listableContext={Context} />
          </CardBody>
        </Card>
      </Provider>
    </>
  )
}
