"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOABadgeStore = void 0;
const mobx_1 = require("mobx");
const DOABadge_1 = require("./DOABadge");
class DOABadgeStore {
    constructor(story) {
        this.story = story;
        this.rootStore = story.rootStore;
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            allABadges: mobx_1.computed,
        });
    }
    getById(id) {
        return this.data[id];
    }
    getByName(name) {
        return Object.values(this.data).find(v => v.name === name);
    }
    merge(data) {
        const id = data.aBadgeId;
        if (this.data[id]) {
            ;
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOABadge_1.DOABadge(this, data);
        }
        return this.data[id];
    }
    get allABadges() {
        return Object.values(this.data);
    }
}
exports.DOABadgeStore = DOABadgeStore;
