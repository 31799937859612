"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockEditorStore = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../changeOp");
const models_1 = require("../../../../models");
const StudioClipboardUtils_1 = require("../../clipboard/StudioClipboardUtils");
const finder_1 = require("../../finder");
const interface_1 = require("../../interface");
const template_1 = require("../../template");
const TemplateAddConnections_1 = require("../../template/TemplateAddConnections");
const TemplateCreateNewBlock_1 = require("../../template/TemplateCreateNewBlock");
const TemplateImageWithOption_1 = require("../../template/TemplateImageWithOption");
const TemplateSplitBlock_1 = require("../../template/TemplateSplitBlock");
const shortcutUtils_1 = require("../../utils/shortcutUtils");
const statement_1 = require("../statement");
const convertStatement_1 = require("../statement/convertStatement");
const DOSTHbBase_1 = require("../statement/DOSTHbBase");
const BlockEditorInput_1 = require("./BlockEditorInput");
const BlockEditorInput_interface_1 = require("./BlockEditorInput.interface");
const IBlockEditable_1 = require("./IBlockEditable");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
/**
 * 스튜디오의 블록 편집기 model. 하단의 입력 ui 는 독자적인 store 로 관리, 여기서 하지 않는다.
 */
class BlockEditorStore {
    constructor(block, chapterEditing) {
        this.inputRef = null;
        this.finderInputRef = null;
        this.showEditModalFor = null;
        this.showDeleteModalFor = null;
        this.showColorPickerModal = null;
        this.showTtsSelectorModalFor = null;
        // 옵션을 만들고 구문에 tts 옵션을 적용시켜주는 모달
        this.showCreateTtsOptionModalFor = null;
        this.showCropImageEditorModalFor = null;
        this.showEndingBGImageSelectorModal = null;
        this.showWritingTextOnImageEditorModal = null;
        // 구문을 롱탭하였을 때 나오는 바텀 시트
        this.showClickableListBottomSheetFor = null;
        this.showSetExecuteConditionModal = null;
        this.showChatGPTPromptModalFor = null;
        this.showImageSelectorModalFor = null;
        this.showTalkStoryGamePreviewModalFor = null;
        /**
         * 오픈되어 있는 모달의 갯수
         */
        this.numModal = 0;
        this.templates = [
            new TemplateCreateNewBlock_1.TemplateCreateNewBlock(this),
            new template_1.TemplateChoiceAndMerge(this),
            new TemplateSplitBlock_1.TemplateSplitBlock(this),
            new TemplateAddConnections_1.TemplateAddConnections(this),
            new TemplateImageWithOption_1.TemplateImageWithOption(this),
        ];
        this.chapterEditing = chapterEditing;
        this.blocks = [block];
        this.lineBlockEditing = block;
        this.lineEditingIndex = block.getLineIndexToStartOnInit();
        // this.lines = [...block.statements, new DOCurrentLineStatement()]
        this.input = new BlockEditorInput_1.BlockEditorInput(this);
        (0, mobx_1.makeObservable)(this, {
            blocks: mobx_1.observable,
            showEditModalFor: mobx_1.observable,
            showDeleteModalFor: mobx_1.observable,
            showColorPickerModal: mobx_1.observable,
            showTtsSelectorModalFor: mobx_1.observable,
            showCreateTtsOptionModalFor: mobx_1.observable,
            showCropImageEditorModalFor: mobx_1.observable,
            chapterEditing: mobx_1.observable,
            lineBlockEditing: mobx_1.observable,
            lineEditingIndex: mobx_1.observable,
            numModal: mobx_1.observable,
            showClickableListBottomSheetFor: mobx_1.observable,
            showEndingBGImageSelectorModal: mobx_1.observable,
            showWritingTextOnImageEditorModal: mobx_1.observable,
            showSetExecuteConditionModal: mobx_1.observable,
            showChatGPTPromptModalFor: mobx_1.observable,
            showImageSelectorModalFor: mobx_1.observable,
            showTalkStoryGamePreviewModalFor: mobx_1.observable,
            setShowEditModalFor: mobx_1.action,
            setShowDeleteModalFor: mobx_1.action,
            setShowColorPickerModal: mobx_1.action,
            setShowTtsSelectorModalFor: mobx_1.action,
            setShowCreateTtsOptionModalFor: mobx_1.action,
            setShowCropImageEditorModalFor: mobx_1.action,
            onModalStateChanged: mobx_1.action,
            setShowClickableListBottomSheetFor: mobx_1.action,
            setShowEndingBGImageSelectorModal: mobx_1.action,
            setShowWritingTextOnImageEditorModal: mobx_1.action,
            setShowSetExecuteConditionModal: mobx_1.action,
            setShowChatGPTPromptModalFor: mobx_1.action,
            setShowImageSelectorModalFor: mobx_1.action,
            setShowTalkStoryGamePreviewModalFor: mobx_1.action,
            lines: mobx_1.computed,
            isShortBlockEditorContainerWidth: mobx_1.computed,
            backgroundForCurrentLine: mobx_1.computed,
            canAddToCurrentCursor: mobx_1.computed,
            selectedLine: mobx_1.computed,
            canCurrentLineMoveUp: mobx_1.computed,
            canCurrentLineMoveDown: mobx_1.computed,
        });
    }
    // isShort 일때 Block > 오른쪽 상단의 삭제 및 수정 아이콘을 내부로 위치시키기 위함
    get isShortBlockEditorContainerWidth() {
        return this.input.width < 510;
    }
    get lines() {
        return (0, lodash_1.flatten)(this.blocks.map(b => {
            const lines = [...b.statements];
            if (b === this.lineBlockEditing) {
                const index = Math.min(Math.max(0, this.lineEditingIndex), lines.length - 1);
                lines.splice(index + 1, 0, new statement_1.DOCurrentLineStatement(b));
            }
            return lines;
        }));
    }
    /**
     * 현재 위치 커서에서의 배경이미지를 반환한다.
     */
    get backgroundForCurrentLine() {
        const index = Math.min(Math.max(0, this.lineEditingIndex), this.lineBlockEditing.statements.length - 1);
        return this.lineBlockEditing.getBackgroundOfStatement(index);
    }
    get indexToAdd() {
        return this.lineEditingIndex;
    }
    /** 현재 선택된 라인을 반환한다. */
    get selectedLine() {
        const blockEditingStatements = this.lineBlockEditing.statements.slice();
        return blockEditingStatements[this.lineEditingIndex];
    }
    get canCurrentLineMoveUp() {
        const { parent, index } = this.getParentEditableForLineAndIndex(this.selectedLine);
        return parent.canChangeOrderUp(index);
    }
    get canCurrentLineMoveDown() {
        const { parent, index } = this.getParentEditableForLineAndIndex(this.selectedLine);
        return parent.canChangeOrderDown(index);
    }
    /**
     * 주어진 {block}의 연결된 다음블록이름이 {nextBlockNameToTest}인가?
     */
    isNextBlock(block, nextBlockNameToTest) {
        var _a;
        const blockIndex = this.blocks.findIndex(b => b === block);
        if (blockIndex < 0 || this.blocks.length < blockIndex + 1) {
            return false;
        }
        return ((_a = [...this.blocks][blockIndex + 1]) === null || _a === void 0 ? void 0 : _a.name) === nextBlockNameToTest;
    }
    isSelectedLineOf(line) {
        return line === this.selectedLine;
    }
    setShowEditModalFor(line) {
        this.showEditModalFor = line;
    }
    setShowDeleteModalFor(line) {
        this.showDeleteModalFor = line;
    }
    setShowTtsSelectorModalFor(input) {
        this.showTtsSelectorModalFor = input;
    }
    setShowCreateTtsOptionModalFor(input) {
        this.showCreateTtsOptionModalFor = input;
    }
    setShowCropImageEditorModalFor(input) {
        this.showCropImageEditorModalFor = input;
    }
    setShowClickableListBottomSheetFor(line) {
        this.showClickableListBottomSheetFor = line;
    }
    setShowEndingBGImageSelectorModal(field) {
        const closeModal = () => (0, mobx_1.runInAction)(() => (this.showEndingBGImageSelectorModal = null));
        this.showEndingBGImageSelectorModal = {
            onClose: closeModal,
            onFinish: v => {
                closeModal();
                this.setShowWritingTextOnImageEditorModal(field, v);
            },
        };
    }
    setShowWritingTextOnImageEditorModal(field, imageUrl) {
        const closeModal = () => (0, mobx_1.runInAction)(() => (this.showWritingTextOnImageEditorModal = null));
        this.showWritingTextOnImageEditorModal = {
            onClose: closeModal,
            onFinish: v => {
                try {
                    field.onChange(v, URL.createObjectURL(v));
                }
                catch (ex) {
                    this.chapterEditing.story.rootStore.showError(ex);
                }
                closeModal();
            },
            imageUrl,
            field,
        };
    }
    // executeCondition 을 지원하는 statement 들만 setExecuteCondition 모달을 오픈합니다.
    setShowSetExecuteConditionModal(supportedExecuteConditionStatement) {
        if (!!(supportedExecuteConditionStatement === null || supportedExecuteConditionStatement === void 0 ? void 0 : supportedExecuteConditionStatement.doesExecuteConditionSupport)) {
            (0, mobx_1.runInAction)(() => {
                this.showSetExecuteConditionModal = supportedExecuteConditionStatement;
            });
        }
        else {
            (0, mobx_1.runInAction)(() => {
                this.showSetExecuteConditionModal = null;
            });
        }
    }
    setShowChatGPTPromptModalFor(st) {
        (0, mobx_1.runInAction)(() => {
            this.showChatGPTPromptModalFor = st;
        });
    }
    setShowImageSelectorModalFor(input) {
        this.showImageSelectorModalFor = input;
    }
    createDeleteModalInfo(targetName, assistantText, onDelete, onClose) {
        return {
            title: trans('legacy_BlockEditorStore.ts_value_delete', {
                value: targetName,
            }),
            bodyText: trans('legacy_BlockEditorStore.ts_selected_value1_value2_delete_confirm', { value1: targetName, value2: assistantText }),
            onDelete,
            onClose,
        };
    }
    createDeleteModalInfoForST(st) {
        const textStore = this.chapterEditing.store.rootStore.textStore;
        // 블록에 포함된 경우에는 블록에서 삭제, 서브블록에 포함된 경우 서브블록에서 삭제하도록 처리한다.
        const removeLineFromContainingEditableBlock = () => {
            const sb = st.parentBlock.getMySubBlock(st);
            if (sb) {
                sb.removeLine(st);
            }
            else {
                if (st instanceof DOSTHbBase_1.DOSTHbBase) {
                    st.removeSelf();
                }
                else {
                    st.parentBlock.removeLine(st);
                }
            }
        };
        const shouldShowEndingWarning = st.isPublishedEnding && !!this.chapterEditing.publishedAt;
        if (shouldShowEndingWarning) {
            return {
                title: trans('legacy_BlockEditorStore.ts_ending_delete'),
                bodyText: trans('legacy_BlockEditorStore.ts_published_chapter_ending_delete_warning'),
                onDelete: removeLineFromContainingEditableBlock,
                onClose: () => this.setShowDeleteModalFor(null),
                requiresCheckboxConfirm: true,
                requiresCheckboxConfirmText: trans('legacy_BlockEditorStore.ts_really_delete_ending'),
            };
        }
        const { name, assistantText } = textStore.getStatementTypeTextForDeleteModal(st.statementType);
        return this.createDeleteModalInfo(name, assistantText, removeLineFromContainingEditableBlock, () => this.setShowDeleteModalFor(null));
    }
    createDeleteModalInfoForSubBlock(sb) {
        const textStore = this.chapterEditing.store.rootStore.textStore;
        const shouldShowEndingWarning = sb.isPublishedEnding;
        if (shouldShowEndingWarning) {
            return {
                title: trans('legacy_BlockEditorStore.ts_ending_delete'),
                bodyText: trans('legacy_BlockEditorStore.ts_published_chapter_ending_delete_warning'),
                onDelete: () => sb.parentBlock.removeLine(sb),
                onClose: () => this.setShowDeleteModalFor(null),
                requiresCheckboxConfirm: true,
                requiresCheckboxConfirmText: trans('legacy_BlockEditorStore.ts_really_delete_ending'),
            };
        }
        const { name, assistantText } = textStore.getSubBlockTypeTextForDeleteModal(sb.subBlockType);
        return this.createDeleteModalInfo(name, assistantText, () => sb.parentBlock.removeLine(sb), () => this.setShowDeleteModalFor(null));
    }
    setShowColorPickerModal(info) {
        var _a, _b;
        // 마음에 드는 방법은 아니지만 예외처리..
        if ((info === null || info === void 0 ? void 0 : info.line) instanceof statement_1.DOSTFullWidthText) {
            (_a = info === null || info === void 0 ? void 0 : info.line) === null || _a === void 0 ? void 0 : _a.startEditing(true);
        }
        else {
            (_b = info === null || info === void 0 ? void 0 : info.line) === null || _b === void 0 ? void 0 : _b.startEditing();
        }
        this.showColorPickerModal = info;
    }
    get canAddToCurrentCursor() {
        const blockEditable = this.lineBlockEditing;
        if (blockEditable.blockEditableType === IBlockEditable_1.BlockEditableType.NormalBlock) {
            // 일반 블록의 경우
            const lineEditingIndex = this.lineEditingIndex;
            if (this.lineBlockEditing.statements.length <= lineEditingIndex) {
                return false;
            }
            const st = this.lineBlockEditing.statements[lineEditingIndex];
            return !(st === null || st === void 0 ? void 0 : st.endBlockType); // 현재 선택된 블록이 마지막이면 편집 불가여야 함.
        }
        else if (blockEditable.blockEditableType ===
            IBlockEditable_1.BlockEditableType.CallRemoteScriptSubBlock) {
            // 원격 블록의 경우, CallRemoteScript 와 FinishRemoteScript 사이에만 삽입 가능
            const sb = blockEditable;
            const lineEditingIndex = this.lineEditingIndex;
            if (sb.statements.length <= lineEditingIndex) {
                return false;
            }
            const indexOfStartScript = sb.statements.findIndex(l => l.isScript &&
                l.statementType ===
                    models_1.STATEMENT_TYPE.CallRemoteScript);
            const indexOfFinishScript = sb.statements.findIndex(l => l.isScript &&
                l.statementType ===
                    models_1.STATEMENT_TYPE.FinishRemoteScript);
            return (lineEditingIndex >= indexOfStartScript &&
                lineEditingIndex < indexOfFinishScript);
        }
        return false;
    }
    /**
     * 주어진 line 이 현재 편집기 상에서 어떤 IBlockEditable 에 속해있는지 체크하여 반환한다.
     * 파라메터를 주지 않으면 현재 현재 선택된 라인을 이용한다.
     */
    getParentEditableForLineAndIndex(lineIn) {
        const line = lineIn !== null && lineIn !== void 0 ? lineIn : this.selectedLine;
        // upsertNewEndBlock 수행시 순간적으로 this.selectedLine 이 undefined 인 경우가 발생한다.
        // 이 경우에는 현재 편집중인 블록의 0번째 문장이 선택되어 있다고 가정하여 반환한다.
        if (!line) {
            return { parent: this.lineBlockEditing, index: 0 };
        }
        // 주어진 line 이 parentBlock 에 속해있다면 IDOBlock 에 속해있다.
        const index = line.parentBlock.statements.findIndex(v => v === line);
        if (index >= 0) {
            return { parent: line.parentBlock, index };
        }
        // 그렇지 않은 경우는 IBlockEditable 에 해당하는 다른 SubBlock 일 것
        const subBlock = line.parentBlock.statements.find(v => v instanceof statement_1.DOBaseScriptSubBlock && v.statements.find(l => l === line));
        const indexInSubBlock = subBlock.statements.findIndex(v => v === line);
        return {
            parent: subBlock,
            index: indexInSubBlock,
        };
    }
    addLine(line) {
        if (!this.canAddToCurrentCursor) {
            // tslint:disable-next-line:no-console
            console.warn(`Cannot add to current line!`);
            this.chapterEditing.store.rootStore.showError(new core_1.SPCError(core_1.ErrorCode.PositionThatCanNotAddLine));
            return null;
        }
        // 현재 커서가 위치한 곳의 IBlockEditable 을 받아와야 한다.
        const currentSelectedLine = this.lineBlockEditing.statements[this.lineEditingIndex];
        const { parent } = this.getParentEditableForLineAndIndex(currentSelectedLine);
        if (!parent.canContainStatement(line)) {
            this.chapterEditing.store.rootStore.showError(trans('legacy_BlockEditorStore.ts_cannot_add_sentence_current_block'));
            return null;
        }
        (0, mobx_1.runInAction)(() => {
            parent.addStatementAtIndex(line, this.indexToAdd + 1);
            this.lineEditingIndex += 1;
            this.scrollToCurrentLine();
        });
        return line;
    }
    scrollToCurrentLine(smooth = true) {
        // rendering 이 오래걸리는 경우 위치가 부정확하여 일부러 딜레이를 줌. 더 좋은 방법이 있으면 좋겠으나,
        // 찾지 못함.
        setTimeout(() => {
            this.chapterEditing.store.rootStore.channel.emit(interface_1.ChannelMessage.ScrollToCurrentLine, { behavior: smooth ? 'smooth' : 'auto' });
        }, 100);
    }
    scrollToFirstLine(smooth = true) {
        const line = this.lineBlockEditing.statements[0];
        if (!line) {
            return;
        }
        return this.scrollToGivenLine(line, smooth);
    }
    scrollToGivenLine(line, smooth = true) {
        // rendering 이 오래걸리는 경우 위치가 부정확하여 일부러 딜레이를 줌. 더 좋은 방법이 있으면 좋겠으나,
        // 찾지 못함.
        setTimeout(() => {
            this.chapterEditing.store.rootStore.channel.emit(interface_1.ChannelMessage.ScrollToGivenLine, { behavior: smooth ? 'smooth' : 'auto', line });
        }, 100);
    }
    onLineClickedForCursorMove(line) {
        const { parent, index } = this.getParentEditableForLineAndIndex(line);
        (0, mobx_1.runInAction)(() => {
            this.lineBlockEditing = parent;
            this.lineEditingIndex = index;
        });
    }
    onBlockClickedForJump(blockOfLine, blockToOrId) {
        // blockId 로 입력되는 경우와 원격 스크립트로 클릭되는 경우를 나눈다.
        // 이는 blockStore 에서 IDOBlock 만 관리하고 다른 타입의 IBlockEditable 을 관리하는
        // 곳이 없기 때문에 어쩔 수 없이 분기처리한 부분이다.
        let blockTo;
        if ((0, lodash_1.isString)(blockToOrId)) {
            // DOBlock 아이디
            blockTo = this.chapterEditing.blockStore.getById(blockToOrId);
            if (!blockTo) {
                return;
            }
        }
        else {
            // 원격 스크립트 블록
            blockTo = blockToOrId;
        }
        (0, mobx_1.runInAction)(() => {
            const index = this.blocks.indexOf(blockOfLine);
            if (index < 0) {
                this.chapterEditing.store.rootStore.di.showError(`Cannot find block clicked : ${blockOfLine.id}`);
                return;
            }
            this.blocks.splice(index + 1, 99999, blockTo);
            // 현재 장면 연결 라인의 전체 영역을 클릭 시 다음 블록을 불러오기 때문에
            // 장면 연결 클릭 시 현 위치가 장면 연결 아래에 표시 될 수 있도록 임시 구현
            // 아래 코드가 없다면 장면 연결 클릭 시 현 위치는 항상 맨 아래에 표시 됨.
            if ((0, lodash_1.last)(blockOfLine.statements) instanceof statement_1.DOSTToBlock) {
                this.lineBlockEditing = blockOfLine;
                this.lineEditingIndex = blockOfLine.statements.length - 1;
                return;
            }
            this.lineBlockEditing = blockTo;
            this.lineEditingIndex = blockTo.statements.length - 1;
        });
    }
    /**
     * 선택지 연결, 장면 연결, 조건 분기 의 편집 팝업에서 종류를 변경한 경우 호출한다.
     */
    changeBlockConnectionModalBlockType(endBlockType) {
        if (this.lineBlockEditing.canUpsertNewEndBlock) {
            this.lineBlockEditing.upsertNewEndBlock(endBlockType, true);
        }
    }
    onBlockRemoved(blockId) {
        (0, mobx_1.runInAction)(() => {
            const index = this.blocks.findIndex(v => v.id === blockId);
            if (index >= 0) {
                // 중간에 삭제된 블록이 존재하면, 그 이후 블록들을 editor 에서 제거한다.
                this.blocks.splice(index, this.blocks.length - index);
                if (this.blocks.length === 0) {
                    // 만약 모두 지워진다면? 시작블록 처음으로 이동
                    this.blocks = [this.chapterEditing.startingBlock];
                    this.lineBlockEditing = this.blocks[0];
                    this.lineEditingIndex = 0;
                }
                else {
                    const editorIsInBlock = this.blocks.find(v => v === this.lineBlockEditing);
                    if (!editorIsInBlock) {
                        // 내가 편집중이던 게 없어졌다.
                        this.lineBlockEditing = (0, lodash_1.last)(this.blocks);
                        this.lineEditingIndex = 0;
                    }
                }
            }
        });
    }
    /**
     * 현재 위치 커서 라인을 이동한다.
     */
    moveSelectedLine(offset) {
        (0, mobx_1.runInAction)(() => {
            if (this.lineEditingIndex + offset < 0) {
                const prev = this.blocks[this.blocks.indexOf(this.lineBlockEditing) - 1];
                if (prev) {
                    this.lineBlockEditing = prev;
                    this.lineEditingIndex = prev.statements.length - 1;
                }
            }
            else if (this.lineEditingIndex + offset >=
                this.lineBlockEditing.statements.length) {
                const next = this.blocks[this.blocks.indexOf(this.lineBlockEditing) + 1];
                if (next) {
                    this.lineBlockEditing = next;
                    this.lineEditingIndex = 0;
                }
            }
            else {
                this.lineEditingIndex = (0, lodash_1.clamp)(this.lineEditingIndex + offset, 0, this.lineBlockEditing.statements.length - 1);
            }
        });
        setTimeout(() => {
            this.chapterEditing.store.rootStore.channel.emit(interface_1.ChannelMessage.ScrollToCurrentLine, { behavior: 'smooth' });
        }, 100);
    }
    /**
     * 현재 선택된 구문이 편집이 가능하다면 편집을 시작한다.
     */
    editCurrentSelection() {
        var _a;
        // mob-x strict
        if (this.lineEditingIndex >= this.lineBlockEditing.statements.length) {
            return;
        }
        const st = this.lineBlockEditing.statements[this.lineEditingIndex];
        (_a = st === null || st === void 0 ? void 0 : st.startEditing) === null || _a === void 0 ? void 0 : _a.call(st);
    }
    /**
     * 현재 선택된 구문이 삭제가 가능하다면 편집을 시작한다.
     */
    deleteCurrentSelection() {
        // mob-x strict
        if (this.lineEditingIndex >= this.lineBlockEditing.statements.length) {
            return;
        }
        const st = this.lineBlockEditing.statements[this.lineEditingIndex];
        if ((st === null || st === void 0 ? void 0 : st.isDeletable) &&
            (st instanceof statement_1.DOBaseScriptStatement ||
                st instanceof statement_1.DOBaseScriptSubBlock)) {
            // 발행된 회차의 엔딩의 경우 무조건 모달을 한 번 띄워준다.
            const shouldShowDeleteModal = st.isPublishedEnding;
            if (shouldShowDeleteModal ||
                this.chapterEditing.store.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.SHOW_DELETE_MODAL)) {
                if (st instanceof statement_1.DOBaseScriptStatement) {
                    this.setShowDeleteModalFor(this.createDeleteModalInfoForST(st));
                }
                else {
                    this.setShowDeleteModalFor(this.createDeleteModalInfoForSubBlock(st));
                }
            }
            else {
                const { parent } = this.getParentEditableForLineAndIndex(st);
                parent.removeLine(st);
            }
        }
    }
    selectWithKeyboard(index) {
        var _a;
        const st = this.lineBlockEditing.statements[this.lineEditingIndex];
        if (st === null || st === void 0 ? void 0 : st.selectNumberByKeyboard) {
            (_a = st === null || st === void 0 ? void 0 : st.selectNumberByKeyboard) === null || _a === void 0 ? void 0 : _a.call(st, index, this);
        }
        else if (st instanceof statement_1.DOBaseScriptStatement) {
            (0, convertStatement_1.convertStatement)(this, st, index);
        }
    }
    handleShortcutWithCurrentStatement(sc) {
        const st = this.lineBlockEditing.statements[this.lineEditingIndex];
        if (st === null || st === void 0 ? void 0 : st.handleKeyboardShortcut) {
            st.handleKeyboardShortcut(sc, this);
        }
    }
    /**
     * 현재 선택된 위치의 문장을 주어진 문장으로 변경합니다.
     */
    replaceCurrentStatement(st) {
        const line = this.lineBlockEditing.statements[this.lineEditingIndex];
        if (line instanceof statement_1.DOBaseScriptStatement) {
            const index = this.lineEditingIndex;
            const op = new changeOp_1.StudioChangeOpFactory(this.lineBlockEditing.parentChapter)
                .startBulk()
                .addLinesToBlock(this.lineBlockEditing.uniqueId, [{ line: st, index }])
                .removeLinesFromBlock(this.lineBlockEditing.uniqueId, [line.uniqueId]);
            op.submitBulk().catch();
        }
    }
    /**
     * 모달창이 뜬 경우에는 onShortcutProviderReady 함수에서 단축키를 동작하지 않도록 하기 위해
     * 모달창의 open -> close 상태를 전달받도록 한다.
     */
    onModalStateChanged(toOpen) {
        this.numModal += toOpen ? 1 : -1;
    }
    changeCurrentLineOrder(isUp) {
        const { parent } = this.getParentEditableForLineAndIndex(this.selectedLine);
        if (isUp) {
            parent.changeOrderUpOf(this.selectedLine);
        }
        else {
            parent.changeOrderDownOf(this.selectedLine);
        }
    }
    /**
     * 단축키 Provider 가 연동되면 액션들을 등록해준다.
     * Input 창이 아닌 블록 에디터에서 발생하는 액션들이라고 보면 된다.
     */
    onShortcutProviderReady(provider) {
        return (0, shortcutUtils_1.bindItems)(provider, () => this.numModal === 0, bind => {
            bind(shortcutUtils_1.StudioShortcut.MoveSelectionUp, e => {
                this.moveSelectedLine(-1);
                e.preventDefault();
            }, 'keydown');
            bind(shortcutUtils_1.StudioShortcut.MoveSelectionDown, e => {
                this.moveSelectedLine(1);
                e.preventDefault();
            }, 'keydown');
            bind(shortcutUtils_1.StudioShortcut.FocusInput, () => { var _a; return (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.focus(); }, 'keyup');
            bind(shortcutUtils_1.StudioShortcut.EditCurrentSelection, e => {
                this.editCurrentSelection();
                e.preventDefault();
            }, 'keydown');
            bind(shortcutUtils_1.StudioShortcut.DeleteCurrentSelection, () => this.deleteCurrentSelection(), 'keyup');
            bind(shortcutUtils_1.StudioShortcut.OpenSaveProp, () => {
                // keystroke 문제 해결을 위해서..
                setImmediate(() => {
                    this.input.onClickButton(BlockEditorInput_interface_1.BlockEditorInputButtonType.SaveProp);
                });
            });
            bind(shortcutUtils_1.StudioShortcut.OpenChoice, () => {
                // keystroke 문제 해결을 위해서..
                setImmediate(() => {
                    this.input.onClickButton(BlockEditorInput_interface_1.BlockEditorInputButtonType.Choice);
                });
            });
            bind(shortcutUtils_1.StudioShortcut.OpenTemplate, () => setImmediate(() => {
                this.input.onClickButton(BlockEditorInput_interface_1.BlockEditorInputButtonType.Template);
            }));
            bind(shortcutUtils_1.StudioShortcut.ExportNow, () => this.chapterEditing.getStory().exportNowIfAutoExportEnabled());
            bind(shortcutUtils_1.StudioShortcut.Select1, () => this.selectWithKeyboard(0));
            bind(shortcutUtils_1.StudioShortcut.Select2, () => this.selectWithKeyboard(1));
            bind(shortcutUtils_1.StudioShortcut.Select3, () => this.selectWithKeyboard(2));
            bind(shortcutUtils_1.StudioShortcut.Select4, () => this.selectWithKeyboard(3));
            bind(shortcutUtils_1.StudioShortcut.Select5, () => this.selectWithKeyboard(4));
            bind(shortcutUtils_1.StudioShortcut.ReorderSelectedDown, () => {
                this.changeCurrentLineOrder(false);
            });
            bind(shortcutUtils_1.StudioShortcut.ReorderSelectedUp, () => {
                this.changeCurrentLineOrder(true);
            });
            // 일반 문장으로 패스하여 Handle
            const scToPass = [
                shortcutUtils_1.StudioShortcut.SelectNextCharacter,
                shortcutUtils_1.StudioShortcut.SelectPrevCharacter,
                shortcutUtils_1.StudioShortcut.SelectRecentCharacter,
            ];
            scToPass.forEach(sc => bind(sc, () => this.handleShortcutWithCurrentStatement(sc)));
            const scToPassWithPreventDefaults = [
                shortcutUtils_1.StudioShortcut.MoveSelectionLeft,
                shortcutUtils_1.StudioShortcut.MoveSelectionRight,
            ];
            scToPassWithPreventDefaults.forEach(sc => bind(sc, e => {
                this.handleShortcutWithCurrentStatement(sc);
                e.preventDefault();
            }, 'keydown'));
            bind(shortcutUtils_1.StudioShortcut.Copy, async () => {
                var _a, _b;
                const ret = (_b = (_a = this.selectedLine) === null || _a === void 0 ? void 0 : _a.onCopyEvent) === null || _b === void 0 ? void 0 : _b.call(_a);
                if (ret) {
                    const di = this.chapterEditing.store.rootStore.di;
                    await di.copyToClipboard(JSON.stringify(ret));
                }
            });
            bind(shortcutUtils_1.StudioShortcut.CopyRaw, async () => {
                var _a, _b;
                const ret = (_b = (_a = this.selectedLine) === null || _a === void 0 ? void 0 : _a.onCopyRawEvent) === null || _b === void 0 ? void 0 : _b.call(_a);
                if (ret) {
                    const di = this.chapterEditing.store.rootStore.di;
                    await di.copyToClipboard(ret);
                }
            });
            bind(shortcutUtils_1.StudioShortcut.Cut, async () => {
                var _a, _b;
                const ret = (_b = (_a = this.selectedLine) === null || _a === void 0 ? void 0 : _a.onCutEvent) === null || _b === void 0 ? void 0 : _b.call(_a);
                if (ret) {
                    const di = this.chapterEditing.store.rootStore.di;
                    await di.copyToClipboard(JSON.stringify(ret));
                }
            });
            bind(shortcutUtils_1.StudioShortcut.Paste, async () => {
                var _a, _b;
                const di = this.chapterEditing.store.rootStore.di;
                const items = await di.readFromClipboard();
                const data = await (0, StudioClipboardUtils_1.parseClipboardItem)(items);
                if (data) {
                    await ((_b = (_a = this.lineBlockEditing).onPasteEvent) === null || _b === void 0 ? void 0 : _b.call(_a, data));
                }
            });
            bind(shortcutUtils_1.StudioShortcut.Redo, async (e) => {
                e.preventDefault();
                this.chapterEditing.redo().catch();
            });
            bind(shortcutUtils_1.StudioShortcut.Undo, async (e) => {
                e.preventDefault();
                this.chapterEditing.undo().catch();
            });
            bind(shortcutUtils_1.StudioShortcut.Save, e => {
                if (!this.chapterEditing.isSaving) {
                    const rs = this.chapterEditing.store.rootStore;
                    this.chapterEditing
                        .saveDraft(ex => rs.showError(ex))
                        .then(success => {
                        if (success) {
                            rs.showMessage(trans('legacy_BlockEditorStore.ts_temporarily_saved'));
                            // Un-comment this to test tutorial popup.
                            // 단축키로 저장하면 볼 수 있음.
                            // rs.showTutorialCheck('튜토리얼 1/21 완료', '작품 만들어보기')
                            // rs.showTutorialAllComplete()
                        }
                    });
                }
                e.preventDefault();
            });
            bind(shortcutUtils_1.StudioShortcut.Select1OnTextInput, e => {
                this.selectWithKeyboard(0);
                e.preventDefault();
            }, 'keypress');
            bind(shortcutUtils_1.StudioShortcut.Select2OnTextInput, e => {
                this.selectWithKeyboard(1);
                e.preventDefault();
            }, 'keypress');
            bind(shortcutUtils_1.StudioShortcut.Select3OnTextInput, e => {
                this.selectWithKeyboard(2);
                e.preventDefault();
            }, 'keypress');
            bind(shortcutUtils_1.StudioShortcut.ToggleFind, e => {
                var _a, _b, _c;
                if (this.finder.mode === finder_1.FinderMode.Find) {
                    (_a = this.finderInputRef) === null || _a === void 0 ? void 0 : _a.focus();
                    (_c = (_b = this.finderInputRef) === null || _b === void 0 ? void 0 : _b.select) === null || _c === void 0 ? void 0 : _c.call(_b);
                }
                else {
                    this.finder.setMode(finder_1.FinderMode.Find);
                }
                e.preventDefault();
            });
            bind(shortcutUtils_1.StudioShortcut.ToggleFindAndReplace, e => {
                this.finder.setMode(finder_1.FinderMode.FindAndReplace);
                e.preventDefault();
            });
        });
    }
    get inputShortcutMap() {
        const keyMap = new Map();
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.SelectNextCharacter], {
            event: () => this.input.selectNextCharacter(),
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.SelectPrevCharacter], {
            event: () => this.input.selectPrevCharacter(),
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.SelectRecentCharacter], {
            event: () => this.input.selectRecentCharacter(),
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.BlurInput], {
            event: () => null,
            withBlur: true,
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.MoveButtonSelectionLeft], {
            event: () => this.input.onMoveSelectedButton(-1),
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.MoveButtonSelectionRight], {
            event: () => this.input.onMoveSelectedButton(1),
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.Select1OnTextInput], {
            event: () => this.selectWithKeyboard(0),
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.Select2OnTextInput], {
            event: () => this.selectWithKeyboard(1),
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.Select3OnTextInput], {
            event: () => this.selectWithKeyboard(2),
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.ToggleFind], {
            event: () => {
                var _a, _b, _c;
                if (this.finder.mode === finder_1.FinderMode.Find) {
                    (_a = this.finderInputRef) === null || _a === void 0 ? void 0 : _a.focus();
                    (_c = (_b = this.finderInputRef) === null || _b === void 0 ? void 0 : _b.select) === null || _c === void 0 ? void 0 : _c.call(_b);
                }
                else {
                    this.finder.setMode(finder_1.FinderMode.Find);
                }
            },
        });
        keyMap.set(shortcutUtils_1.StudioShortcutKeyStrokes[shortcutUtils_1.StudioShortcut.ToggleFindAndReplace], {
            event: () => {
                this.finder.setMode(finder_1.FinderMode.FindAndReplace);
            },
        });
        return keyMap;
    }
    onInputShortcutProviderReady(provider, inputRef) {
        this.inputRef = inputRef;
        return (0, shortcutUtils_1.bindItems)(provider, () => true, bind => {
            bind(shortcutUtils_1.StudioShortcut.SelectNextCharacter, () => this.input.selectNextCharacter());
            bind(shortcutUtils_1.StudioShortcut.SelectPrevCharacter, () => this.input.selectPrevCharacter());
            bind(shortcutUtils_1.StudioShortcut.SelectRecentCharacter, () => this.input.selectRecentCharacter());
            bind(shortcutUtils_1.StudioShortcut.BlurInput, () => { var _a; return (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.blur(); });
            bind(shortcutUtils_1.StudioShortcut.MoveButtonSelectionLeft, () => this.input.onMoveSelectedButton(-1));
            bind(shortcutUtils_1.StudioShortcut.MoveButtonSelectionRight, () => this.input.onMoveSelectedButton(1));
            bind(shortcutUtils_1.StudioShortcut.Select1OnTextInput, e => {
                this.selectWithKeyboard(0);
                e.preventDefault();
            }, 'keypress');
            bind(shortcutUtils_1.StudioShortcut.Select2OnTextInput, e => {
                this.selectWithKeyboard(1);
                e.preventDefault();
            }, 'keypress');
            bind(shortcutUtils_1.StudioShortcut.Select3OnTextInput, e => {
                this.selectWithKeyboard(2);
                e.preventDefault();
            }, 'keypress');
            bind(shortcutUtils_1.StudioShortcut.ToggleFind, e => {
                var _a, _b, _c;
                if (this.finder.mode === finder_1.FinderMode.Find) {
                    (_a = this.finderInputRef) === null || _a === void 0 ? void 0 : _a.focus();
                    (_c = (_b = this.finderInputRef) === null || _b === void 0 ? void 0 : _b.select) === null || _c === void 0 ? void 0 : _c.call(_b);
                }
                else {
                    this.finder.setMode(finder_1.FinderMode.Find);
                }
                e.preventDefault();
            });
            bind(shortcutUtils_1.StudioShortcut.ToggleFindAndReplace, e => {
                this.finder.setMode(finder_1.FinderMode.FindAndReplace);
                e.preventDefault();
            });
        });
    }
    onFindInputShortcutProviderReady(provider, inputRef) {
        this.finderInputRef = inputRef;
        return (0, shortcutUtils_1.bindItems)(provider, () => this.finder.mode !== finder_1.FinderMode.None, bind => {
            bind(shortcutUtils_1.StudioShortcut.ToggleFind, e => {
                var _a;
                if (this.finder.mode === finder_1.FinderMode.Find) {
                    inputRef.focus();
                    (_a = inputRef.select) === null || _a === void 0 ? void 0 : _a.call(inputRef);
                }
                else {
                    this.finder.setMode(finder_1.FinderMode.Find);
                }
                e.preventDefault();
            });
            bind(shortcutUtils_1.StudioShortcut.ToggleFindAndReplace, e => {
                this.finder.setMode(finder_1.FinderMode.FindAndReplace);
                e.preventDefault();
            });
            bind(shortcutUtils_1.StudioShortcut.BlurInput, e => {
                this.finder.setMode(finder_1.FinderMode.None);
                e.preventDefault();
            });
            bind(shortcutUtils_1.StudioShortcut.StartFind, e => {
                this.finder.updateFindResults(false);
                e.preventDefault();
            });
            bind(shortcutUtils_1.StudioShortcut.FindPrevious, e => {
                this.finder.updateFindResults(true);
                e.preventDefault();
            });
        });
    }
    generateSourceLine() {
        return this.chapterEditing.store.rootStore.di.generateSourceLine();
    }
    get finder() {
        return this.chapterEditing.finder;
    }
    setShowTalkStoryGamePreviewModalFor(input) {
        this.showTalkStoryGamePreviewModalFor = input;
    }
}
exports.BlockEditorStore = BlockEditorStore;
