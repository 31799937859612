import { ODVSpace } from '@odc/od-react-belt'
import React, { FC, FormEvent } from 'react'
import styled from 'styled-components'
import QRCode from 'react-qr-code'
import { GQLOTPKeyResponse } from '../../@types/server'

interface IRegisterOTPKeyPage {
  otpInfo: GQLOTPKeyResponse
  handleRegisterOTPKey: (otpKey: string, token: string) => void
}

export const RegisterOTPKeyPage: FC<IRegisterOTPKeyPage> = (props) => {
  const { otpInfo, handleRegisterOTPKey } = props
  const [otpToken, setOtpToken] = React.useState('')

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
  }

  return (
    <Container>
      <Title>OTP 등록</Title>
      <SubTitle>
        안전한 로그인을 위해 OTP 등록이 필요합니다.
        <br />
        아래 절차에 따라 OTP를 먼저 등록해주세요.
      </SubTitle>
      <ODVSpace h={20} />
      <Inner>
        <DescText>1. 본인의 스마트폰에서 OTP 앱을 다운로드해주세요.</DescText>
        <DescText>가능한 앱 예시 : Google Authenticator</DescText>
        <ODVSpace h={10} />
        <DescText>2. 스마트폰에서 아래 QR코드를 스캔하거나 직접 앱에서 아래 정보를 등록하세요.</DescText>
        <LeftPaddingBox>
          <QRCode value={otpInfo.url} size={200} />
          <ODVSpace h={20} />
          <RowTextBox>
            <KeyText>계정</KeyText>
            <ValueText>{otpInfo.email}</ValueText>
          </RowTextBox>
          <RowTextBox>
            <KeyText>시크릿코드</KeyText>
            <ValueText>{otpInfo.secretCode}</ValueText>
          </RowTextBox>
          <RowTextBox>
            <KeyText>기준</KeyText>
            <ValueText>시간 기준</ValueText>
          </RowTextBox>
        </LeftPaddingBox>
        <DescText>3. OTP 앱에서 등록 후 생성된 6자리 인증번호를 입력하고 OTP 등록 버튼을 눌러주세요.</DescText>
        <ODVSpace h={20} />
        <LeftPaddingBox style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
          <label htmlFor="authenticationNumber">인증번호</label>
          <form style={{ width: '100%', textAlign: 'left' }} onSubmit={handleSubmit}>
            <Input
              id="authenticationNumber"
              value={otpToken}
              onChange={(e) => {
                if (e.target.value.length > 7) {
                  return
                }
                setOtpToken(e.target.value)
              }}
            />
            <RegisterButton onClick={() => handleRegisterOTPKey(otpInfo.secretCode, otpToken)}>OTP 등록</RegisterButton>
          </form>
        </LeftPaddingBox>
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`

const Title = styled.h1`
  font-weight: bold;
  text-align: center;
`

const SubTitle = styled.h4`
  font-weight: bold;
  text-align: center;
`

const LeftPaddingBox = styled.div`
  padding-left: 20px;
`

const DescText = styled.p`
  font-size: 19px;
`

const RowTextBox = styled.div`
  display: flex;
  align-items: center;
`
const KeyText = styled.p`
  font-size: 19px;
  font-weight: bold;
  margin-right: 10px;
`
const ValueText = styled.p`
  font-size: 17px;
`

const RegisterButton = styled.button`
  background-color: #00c5c3;
  border: 0;
  width: 100%;
  height: 57px;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
  line-height: 57px;
`

const Input = styled.input`
  text-align: center;
  font-size: 30px;
`
