import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { RecommendationForFirstUserEditPage } from './RecommendationForFirstUserEditPage'

interface IRecommendationForFirstUserEditPageContainerProps {
  rfuId: number
}

export const RecommendationForFirstUserEditPageContainer: React.FC<
  IRecommendationForFirstUserEditPageContainerProps
> = (props) => {
  const { rfuId } = props
  return (
    <>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.RecommendationForFirstUser.List} />
          <hr />
          <RecommendationForFirstUserEditPage rfuId={rfuId} />
        </CardBody>
      </Card>
    </>
  )
}
