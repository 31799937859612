"use strict";
/**
 * 더 이상은 사용하지 않습니다.
 * @storyplay/core 에 있는 것을 사용하세요.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ensureC = exports.SPCError = exports.SPError = exports.ensure = exports.ErrorCode = void 0;
var core_1 = require("@storyplay/core");
Object.defineProperty(exports, "ErrorCode", { enumerable: true, get: function () { return core_1.ErrorCode; } });
Object.defineProperty(exports, "ensure", { enumerable: true, get: function () { return core_1.ensure; } });
Object.defineProperty(exports, "SPError", { enumerable: true, get: function () { return core_1.SPError; } });
Object.defineProperty(exports, "SPCError", { enumerable: true, get: function () { return core_1.SPCError; } });
Object.defineProperty(exports, "ensureC", { enumerable: true, get: function () { return core_1.ensureC; } });
