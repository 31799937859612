"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerReport2IDE = void 0;
const mobx_1 = require("mobx");
const nunjucks = require("nunjucks");
const consts_1 = require("../../../../consts");
const playerReport2_1 = require("../playerReport2");
const playerReport2Function_1 = require("../playerReport2Function");
const playerReport2RenderBlock_1 = require("../playerReport2RenderBlock");
const sampleData = {
    story: {
        id: 1,
        name: '스파이럴',
        mainCharImageLink: '',
    },
    firstName: '수지',
    lastName: '박',
    profileImageFile: null,
    finishedAt: new Date(),
    storyProps: {},
    userProps: {},
    mainProps: {},
    choices: {
        나갈까말까: {
            id: 1,
            selected: 0,
            selectedName: '나간다.',
        },
    },
    chars: {},
    mainChars: {},
    items: {},
    finalEnding: {
        id: 1,
        name: '도망쳤다',
        customId: '도망',
        imageFileLink: 'https://image.link/sample',
        rateLevel: consts_1.EndingRateRangeLevel.Rarity,
    },
    playerClass: {},
};
class PlayerReport2IDE {
    constructor(story, reportId) {
        this.codeEditor = null;
        this.isLoading = false;
        this._report = null;
        this._story = story;
        this._reportId = reportId;
        this.env = nunjucks.configure({ autoescape: false });
        this.functionStore = new playerReport2Function_1.DOPlayerReport2FunctionStore(this.rootStore, this);
        this.renderBlockStore = new playerReport2RenderBlock_1.DOPlayerReport2RenderBlockStore(this.rootStore, this);
        (0, mobx_1.makeObservable)(this, {
            isLoading: mobx_1.observable,
        });
        this.init();
    }
    get rootStore() {
        return this._story.rootStore;
    }
    get storyId() {
        return this._story.storyId;
    }
    async init() {
        (0, mobx_1.runInAction)(() => (this.isLoading = true));
        try {
            const { report, functions, renderBlocks } = await this.rootStore.di.server.loadPlayerReportIDEData(this._reportId);
            this._report = this._story.playerReport2Store.merge(report);
            functions.forEach(gql => this.functionStore.merge(gql));
            renderBlocks.forEach(gql => this.renderBlockStore.merge(gql));
            // TODO: template gallery.
            // 함수들을 nunjucks 환경에 추가해준다.
            this.functionStore.all.forEach(f => {
                // tslint:disable-next-line:no-eval
                this.env.addGlobal(f.name, eval(f.code));
            });
            this.codeEditor = new playerReport2_1.DOPlayerReport2Editor(this._report, this);
            // this.codeEditor.sampleDataField.onChange(
            //   JSON.stringify(sampleData, null, 2)
            // )
            return true;
        }
        catch (ex) {
            this.rootStore.di.showError(ex);
            return false;
        }
        finally {
            (0, mobx_1.runInAction)(() => (this.isLoading = false));
        }
    }
}
exports.PlayerReport2IDE = PlayerReport2IDE;
