import { ODListableResponseType } from '@odc/od-react-belt'
import { GQLStoryHasRecommendSection } from '@storyplay/core'
import React from 'react'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IStoryHasRecommendSectionListableOption } from '../StoryHasRecommendSectionCommon'

export function useStoryHasRecommendSectionListDataLoader(storyId: number) {
  const { listStoryHasRecommendSection } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IStoryHasRecommendSectionListableOption
    ): Promise<ODListableResponseType<GQLStoryHasRecommendSection>> {
      const r = await listStoryHasRecommendSection({
        page,
        pageSize: 10,
        storyId,
        ...options,
      })
      return r as ODListableResponseType<GQLStoryHasRecommendSection>
    },
    [listStoryHasRecommendSection, storyId]
  )
}
