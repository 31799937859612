"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioChangeOpFactory = void 0;
const lodash_1 = require("lodash");
const __1 = require("..");
const DOSTCallRemoteScript_1 = require("../studio/store/entity/statement/script/DOSTCallRemoteScript");
const DOSTFinishRemoteScript_1 = require("../studio/store/entity/statement/script/DOSTFinishRemoteScript");
const DOSTCallRemoteScriptSubBlock_1 = require("../studio/store/entity/statement/script/subBlock/DOSTCallRemoteScriptSubBlock");
const StudioChangeOp_1 = require("./StudioChangeOp");
function lineTarget(t) {
    return {
        target: StudioChangeOp_1.StudioChangeOpTarget.Block,
        lineUniqueId: t.uniqueId,
        blockUniqueId: (0, lodash_1.isString)(t.block) ? t.block : t.block.uniqueId,
    };
}
class StudioChangeOpFactory {
    constructor(chapter) {
        this.bulk = null;
        this.lastOp = null;
        this.chapter = chapter;
    }
    /**
     * Bulk editing 시작한다.
     */
    startBulk() {
        if (this.bulk) {
            throw new Error('Bulk editing in progress.');
        }
        this.bulk = {
            opType: StudioChangeOp_1.StudioChangeOpType.BulkChange,
            changes: [],
        };
        return this;
    }
    /**
     * Bulk editing 을 종료하며 만들어진 Bulk operation 을 반환한다.
     */
    finishBulk() {
        if (!this.bulk) {
            throw new Error('Bulk editing not in progress.');
        }
        const ret = this.bulk;
        this.bulk = null;
        return ret;
    }
    /**
     * 진행중인 bulk editing 을 finish 하고 chapter 에 apply 까지 한다.
     */
    submitBulk() {
        const op = this.finishBulk();
        return this.chapter.applyChangesOnChapter(op);
    }
    /**
     * 마지막 change op 1 개를 submit.
     */
    submitSingle() {
        if (!this.lastOp) {
            throw new Error('No change added.');
        }
        return this.chapter.applyChangesOnChapter(this.lastOp);
    }
    addBulkOp(op) {
        if (!this.bulk) {
            // bulk 가 아니면 single submit 이라 생각하고 lastOp 에 넣어둔다.
            this.lastOp = op;
            return this;
        }
        this.bulk.changes.push(op);
        return this;
    }
    /**
     * 새로운 블록을 생성한다. 단, {name} 필드에 DOBlock 객체가 들어오면 아무것도 하지 않는다.
     * blockOptions 의 구현체를 편리하게 맞추기 위함이다.
     *
     * @param name 생성할 블록의 이름
     * @param withStartingBackground 생성될 블록의 시작배경
     * @param uniqueId 생성될 블록의 unique-id 를 주입
     */
    createNewBlock(name, withStartingBackground, uniqueId) {
        if (!(0, lodash_1.isString)(name)) {
            return this;
        }
        return this.addBulkOp(StudioChangeOpFactory.createNewBlock(name, withStartingBackground, uniqueId));
    }
    /**
     * 블록의 기본 배경(시작 배경)을 변경한다.
     */
    changeBlockStartingBackground(blockUniqueId, background) {
        return this.addBulkOp(StudioChangeOpFactory.changeBlockStartingBackground(blockUniqueId, background));
    }
    /**
     * 특정 블록에서 문장들을 제거한다.
     * @param blockUniqueId 문장을 삭제할 블록
     * @param lineUniqueIds 삭제할 문장들
     */
    removeLinesFromBlock(blockUniqueId, lineUniqueIds) {
        return this.addBulkOp(StudioChangeOpFactory.removeLinesFromBlock(blockUniqueId, lineUniqueIds));
    }
    /**
     * 특정 블록에 문장들을 추가한다. 문장들의 unique id 들은 변경되지 않으나, 블록은 변경된다.
     * @param blockUniqueId 문장을 추가할 블록의 id
     * @param lines 추가할 문장들과 위치
     */
    addLinesToBlock(blockUniqueId, lines) {
        return this.addBulkOp(StudioChangeOpFactory.addLinesToBlock(blockUniqueId, lines));
    }
    /**
     * 특정 서브-블록에 문장들을 추가한다.
     * @param blockUniqueId 문장을 추가할 서브 블록이 포함된 블록의 id
     * @param lineUniqueId 서브 블록의 id
     * @param lines 추가할 문장들과 위치
     */
    addLinesToSubBlock(blockUniqueId, lineUniqueId, lines) {
        return this.addBulkOp(StudioChangeOpFactory.addLinesToSubBlock(blockUniqueId, lineUniqueId, lines));
    }
    /**
     * 블록의 종료타입을 변경한다.
     */
    upsertNewEndBlock(blockUniqueId, endBlockType, newLineUniqueId, // 신규로 생성한 블록 or 문장의 unique id 주입
    // 만약 생성되는 블록이 여러개의 sub-statement 를 갖는 경우 각각에 대한 unique id를
    // 외부에서 넣어줄 수 있다. 순서는 호출자가 알아서..
    subLinesUniqueIds = []) {
        var _a, _b;
        // 블록내에 이미 존재할 수 있는 엔드블록을 제거하고, 새롭게 추가한다.
        const block = this.chapter.blockStore.getByUniqueId(blockUniqueId);
        const ids = block === null || block === void 0 ? void 0 : block.statements.filter(st => st.endBlockType).map(s => s.uniqueId);
        if (ids && ids.length > 0) {
            this.removeLinesFromBlock(blockUniqueId, ids);
        }
        let statement = null;
        // 미래에 생길 블록의 경우 지금 존재하지 않는다. 어차피 addLines 에서 매핑될 것이므로,
        // 잠시만 시작블록으로 두도록 한다.
        const b = (block !== null && block !== void 0 ? block : this.chapter.startingBlock);
        switch (endBlockType) {
            case __1.EndBlockType.Condition: {
                statement = new __1.DOSTConditionSubBlock([
                    new __1.DOSTCondition(null, b, subLinesUniqueIds[0]),
                    new __1.DOSTToBlock(null, b, true, subLinesUniqueIds[1]),
                ], b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.Choice: {
                statement = new __1.DOSTChoiceSubBlock([
                    new __1.DOSTChoice(null, b, subLinesUniqueIds[0]),
                    new __1.DOSTChoiceToBlock(null, b, subLinesUniqueIds[1]),
                ], b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.ToBlock: {
                statement = new __1.DOSTToBlock(null, b, false, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.ChapterEnding: {
                statement = new __1.DOSTEndingSummary(null, b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.FinalEnding: {
                statement = new __1.DOSTFinalEndingSubBlock([
                    new __1.DOSTFinalEnding(null, b, subLinesUniqueIds[0]),
                    new __1.DOSTCollectionDesc(null, b, subLinesUniqueIds[1]),
                    new __1.DOSTCollectionImage(null, b, subLinesUniqueIds[2]),
                ], b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.CallRemoteScript: {
                statement = new DOSTCallRemoteScriptSubBlock_1.DOSTCallRemoteScriptSubBlock([
                    new DOSTCallRemoteScript_1.DOSTCallRemoteScript(null, b, subLinesUniqueIds[0]),
                    new DOSTFinishRemoteScript_1.DOSTFinishRemoteScript(null, b, subLinesUniqueIds[1]),
                ], b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.HbMessageNext: {
                statement = new __1.DOSTHbMessageNext(null, b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.HbMessageSelect: {
                statement = new __1.DOSTHbMessageSelect(null, b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.HbMessageInput: {
                statement = new __1.DOSTHbMessageInput(null, b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.HbMessageTarot: {
                statement = new __1.DOSTHbMessageTarot(null, b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.HbMessageTemplate: {
                statement = new __1.DOSTHbMessageTemplate(null, b, newLineUniqueId);
                break;
            }
            case __1.EndBlockType.HbMessageFeedback: {
                statement = new __1.DOSTHbMessageFeedback(null, b, newLineUniqueId);
                break;
            }
        }
        this.addLinesToBlock(blockUniqueId, [
            { line: statement, index: (_b = (_a = block === null || block === void 0 ? void 0 : block.statements) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0 },
        ]);
        return this;
    }
    /**
     * 다양한 문장들의 메시지(텍스트)를 수정한다.
     */
    changeMessage(line, message) {
        return this.addBulkOp(StudioChangeOpFactory.changeMessage(line, message));
    }
    /**
     * 다양한 문장들의 말풍선 색을 수정한다.
     */
    changeBubbleColor(line, backgroundColor, edgeColor) {
        return this.addBulkOp(StudioChangeOpFactory.changeBubbleColor(line, backgroundColor, edgeColor));
    }
    /**
     * 등장인물이 존재하는 문장의 등장인물을 수정한다.
     */
    changeChr(line, chrName) {
        return this.addBulkOp(StudioChangeOpFactory.changeChr(line, chrName));
    }
    /**
     * 장면 연결 블록의 연결 블록을 변경한다.
     */
    changeToBlockToBlock(blockUniqueId, lineUniqueId, toBlockName, toBlockNames) {
        return this.addBulkOp(StudioChangeOpFactory.changeToBlockToBlock(blockUniqueId, lineUniqueId, toBlockName, toBlockNames));
    }
    /**
     * 선택지 구문의 특정 인덱스 선택지를 삭제한다
     */
    removeChoiceAtIndex(blockUniqueId, lineUniqueId, index) {
        return this.addBulkOp(StudioChangeOpFactory.removeChoiceAtIndex(blockUniqueId, lineUniqueId, index));
    }
    /**
     * 문장의 이름 속성을 변경한다.
     */
    changeLineName(blockUniqueId, lineUniqueId, name) {
        return this.addBulkOp(StudioChangeOpFactory.changeLineName(blockUniqueId, lineUniqueId, name));
    }
    /**
     * 선택지 분기에 선택지를 추가한다.
     */
    addChoice(blockUniqueId, lineUniqueId, choiceName, blockTo, indexToAdd, uniqueId) {
        return this.addBulkOp(StudioChangeOpFactory.addChoice(blockUniqueId, lineUniqueId, choiceName, blockTo, indexToAdd, uniqueId));
    }
    /**
     * 선택지 분기의 특정 선택지의 블록 연결을 변경한다.
     */
    changeChoiceToBlock(blockUniqueId, lineUniqueId, choiceIndex, toBlockName) {
        return this.addBulkOp(StudioChangeOpFactory.changeChoiceToBlock(blockUniqueId, lineUniqueId, choiceIndex, toBlockName));
    }
    // /**
    //  * {lineUniqueId}인 ChoiceSubBlock 에 choiceIfs 문장을 추가한다.
    //  * @param blockUniqueId
    //  * @param lineUniqueId
    //  * @param choiceIfs
    //  */
    // addChoiceIf(
    //   blockUniqueId: string,
    //   lineUniqueId: string,
    //   choiceIfs: DOSTChoiceIf[]
    // ) {
    //   return this.addBulkOp(
    //     StudioChangeOpFactory.addChoiceIf(blockUniqueId, lineUniqueId, choiceIfs)
    //   )
    // }
    /**
     * {lineUniqueId}인 특정 SubBlock 에서 문장들을 삭제한다.
     */
    removeLinesFromSubBlock(blockUniqueId, lineUniqueId, lineUniqueIds) {
        return this.addBulkOp(StudioChangeOpFactory.removeLinesFromSubBlock(blockUniqueId, lineUniqueId, lineUniqueIds));
    }
    /**
     * 선택지로 분기의 {index} 선택지의 텍스트를 {text} 로 변경한다.
     */
    changeChoiceText(line, text, index) {
        return this.addBulkOp(StudioChangeOpFactory.changeChoiceText(line, text, index));
    }
    /**
     * 선택지로 분기의 {choiceIndex} 선택지에서 {choiceIfIndex} 번째의 ChoiceIf 를
     * 추가/삭제/변경 한다.
     */
    changeChoiceIf(line, choiceIndex, choiceIfIndex, data, uniqueIdRemoved // undo 시에 uniqueId 유지를 위해서
    ) {
        return this.addBulkOp(StudioChangeOpFactory.changeChoiceIf(line, choiceIndex, choiceIfIndex, data, uniqueIdRemoved));
    }
    /**
     * 조건으로 분기의 특정 {index} 에 행을 추가한다.
     */
    addCondition(line, conditionData, index, uniqueId) {
        return this.addBulkOp(StudioChangeOpFactory.addCondition(line, conditionData, index, uniqueId));
    }
    /**
     * 조건으로 분기의 특정 {index} 행을 삭제한다.
     */
    removeConditionAtIndex(line, index) {
        return this.addBulkOp(StudioChangeOpFactory.removeConditionAtIndex(line, index));
    }
    /**
     * 조건으로 분기의 특정 {index} 행의 데이터를 변경한다.
     */
    changeCondition(line, index, conditionData) {
        return this.addBulkOp(StudioChangeOpFactory.changeCondition(line, index, conditionData));
    }
    /**
     * 조건으로 분기의 else 블록 데이터를 변경한다.
     */
    changeConditionElseToBlock(line, elseBlockData) {
        return this.addBulkOp(StudioChangeOpFactory.changeConditionElseToBlock(line, elseBlockData));
    }
    /**
     * IEditor 의 특정 field 에 대한 값 변경을 적용한다.
     */
    changeField(line, fieldId, value) {
        return this.addBulkOp(StudioChangeOpFactory.changeField(line, fieldId, value));
    }
    /**
     * 특정 문장의 내용을 통째로 변경한다.
     */
    replaceData(line, data) {
        return this.addBulkOp(StudioChangeOpFactory.replaceData(line, data));
    }
    /**
     * 플로우차트에서 특정 블록의 위치를 변경한다.
     */
    changeFlowChartPosition(block, position) {
        return this.addBulkOp(StudioChangeOpFactory.changeFlowChartPosition(block, position));
    }
    createSelectButton(line, dataForSubmit) {
        return this.addBulkOp(StudioChangeOpFactory.createSelectButton(line, dataForSubmit));
    }
    updateSelectButton(line, dataForSubmit) {
        return this.addBulkOp(StudioChangeOpFactory.updateSelectButton(line, dataForSubmit));
    }
    removeSelectButton(line, buttonId) {
        return this.addBulkOp(StudioChangeOpFactory.removeSelectButton(line, buttonId));
    }
    /**
     * 다른 operation 을 뒤에 추가한다.
     * @param op
     */
    addOperations(op) {
        var _a, _b;
        if (op.opType === StudioChangeOp_1.StudioChangeOpType.BulkChange) {
            op.changes.forEach(c => { var _a, _b; return (_b = (_a = this.bulk) === null || _a === void 0 ? void 0 : _a.changes) === null || _b === void 0 ? void 0 : _b.push(c); });
        }
        else {
            (_b = (_a = this.bulk) === null || _a === void 0 ? void 0 : _a.changes) === null || _b === void 0 ? void 0 : _b.push(op);
        }
        return this;
    }
    /**
     * unique id 를 생성하여 반환한다.
     */
    genId() {
        return this.chapter.store.rootStore.di.generateInternalHashId();
    }
    static startBulk(chapter) {
        const f = new StudioChangeOpFactory(chapter);
        return f.startBulk();
    }
    static createNewBlock(name, withStartingBackground, uniqueId) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.CreateBlock,
            target: StudioChangeOp_1.StudioChangeOpTarget.Chapter,
            name,
            startingBackground: withStartingBackground,
            uniqueId,
        };
    }
    static changeBlockStartingBackground(blockUniqueId, background) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeBlockStartingBackground,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            background,
        };
    }
    static removeLinesFromBlock(blockUniqueId, lineUniqueIds) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.RemoveLines,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueIds,
        };
    }
    static addLinesToBlock(blockUniqueId, lines) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.AddLines,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lines,
        };
    }
    static addLinesToSubBlock(blockUniqueId, lineUniqueId, lines) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.AddLinesToSubBlock,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueId,
            lines,
        };
    }
    static changeMessage(line, message) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeMessage,
            ...lineTarget(line),
            message,
        };
    }
    static changeBubbleColor(line, backgroundColor, edgeColor) {
        const op = {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeBubbleColor,
            ...lineTarget(line),
        };
        op.backgroundColor = backgroundColor;
        op.edgeColor = edgeColor;
        return op;
    }
    static changeChr(line, chrName) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeChr,
            ...lineTarget(line),
            chrName,
        };
    }
    static changeToBlockToBlock(blockUniqueId, lineUniqueId, toBlockName, toBlocknames) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeToBlockToBlock,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueId,
            blockName: toBlockName,
            blockNames: toBlocknames,
        };
    }
    static removeChoiceAtIndex(blockUniqueId, lineUniqueId, index) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeChoiceRemoveChoice,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueId,
            choiceIndex: index,
        };
    }
    static changeLineName(blockUniqueId, lineUniqueId, name) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeLineName,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueId,
            name,
        };
    }
    static addChoice(blockUniqueId, lineUniqueId, choiceName, blockTo, indexToAdd, uniqueId) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeChoiceAddChoice,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueId,
            choiceName,
            blockTo,
            choiceIndex: indexToAdd,
            uniqueId,
        };
    }
    static changeChoiceToBlock(blockUniqueId, lineUniqueId, choiceIndex, toBlockName) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeChoiceToBlock,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueId,
            choiceIndex,
            blockName: toBlockName,
        };
    }
    // static addChoiceIf(
    //   blockUniqueId: string,
    //   lineUniqueId: string,
    //   choiceIfs: DOSTChoiceIf[]
    // ): IStudioChangeAddChoiceIf {
    //   return {
    //     opType: StudioChangeOpType.AddChoiceIf,
    //     target: StudioChangeOpTarget.Block,
    //     blockUniqueId,
    //     lineUniqueId,
    //     choiceIfs,
    //   }
    // }
    static removeLinesFromSubBlock(blockUniqueId, lineUniqueId, lineUniqueIds) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.RemoveLinesFromSubBlock,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueId,
            lineUniqueIds,
        };
    }
    static changeChoiceText(line, text, index) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeChoiceText,
            ...lineTarget(line),
            choiceIndex: index,
            choiceName: text,
        };
    }
    static changeChoiceIf(line, choiceIndex, choiceIfIndex, data, uniqueIdRemoved // undo 시에 uniqueId 유지를 위해서
    ) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeChoiceChoiceIf,
            ...lineTarget(line),
            choiceIndex,
            choiceIfIndex,
            data,
            uniqueIdRemoved,
        };
    }
    static addCondition(line, conditionData, index, uniqueId) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.AddCondition,
            ...lineTarget(line),
            conditionData,
            index,
            uniqueId,
        };
    }
    static removeConditionAtIndex(line, index) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.RemoveConditionAtIndex,
            ...lineTarget(line),
            index,
        };
    }
    static changeCondition(line, index, conditionData) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeConditionAtIndex,
            ...lineTarget(line),
            index,
            conditionData,
        };
    }
    static changeConditionElseToBlock(line, elseBlockData) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeConditionElse,
            ...lineTarget(line),
            elseBlockData,
        };
    }
    static changeField(line, fieldId, value) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeField,
            ...lineTarget(line),
            field: fieldId,
            value,
        };
    }
    static replaceData(line, data) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ReplaceData,
            ...lineTarget(line),
            data,
        };
    }
    static changeFlowChartPosition(block, position) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.ChangeFlowChartPosition,
            target: StudioChangeOp_1.StudioChangeOpTarget.Chapter,
            block,
            position,
        };
    }
    static removeToBlock(blockUniqueId, lineUniqueId, blockNames) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.RemoveToBlock,
            target: StudioChangeOp_1.StudioChangeOpTarget.Block,
            blockUniqueId,
            lineUniqueId,
            blockNames,
        };
    }
    /**
     * 장면연결에서의 블럭제거기능이 된다.
     */
    removeToBlock(name, withStartingBackground, blockNames) {
        if (!(0, lodash_1.isString)(name)) {
            return this;
        }
        return this.addBulkOp(StudioChangeOpFactory.removeToBlock(name, withStartingBackground, blockNames));
    }
    static createSelectButton(line, dataForSubmit) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.CreateSelectButton,
            ...lineTarget(line),
            dataForSubmit,
        };
    }
    static updateSelectButton(line, dataForSubmit) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.SelectButtonChange,
            ...lineTarget(line),
            dataForSubmit,
        };
    }
    static removeSelectButton(line, buttonId) {
        return {
            opType: StudioChangeOp_1.StudioChangeOpType.RemoveSelectButton,
            ...lineTarget(line),
            buttonId,
        };
    }
}
exports.StudioChangeOpFactory = StudioChangeOpFactory;
