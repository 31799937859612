import { ODListableOption } from '@odc/od-react-belt'
import { GQLTTS_EMOTION, TTS_PITCH, TTS_SPEED } from '@storyplay/common'

export const PITCH_LIST = [
  { label: '높게', value: TTS_PITCH.High },
  { label: '기본 톤', value: TTS_PITCH.Default },
  { label: '낮게', value: TTS_PITCH.Low },
]

export const SPEED_LIST = [
  { label: '빠르게', value: TTS_SPEED.Quick },
  { label: '기본 속도', value: TTS_SPEED.Default },
  { label: '느리게', value: TTS_SPEED.Slow },
]

export const EMOTION_LIST = [
  { label: '기본 감정', value: GQLTTS_EMOTION.Default },
  { label: '기쁨', value: GQLTTS_EMOTION.Happy },
  { label: '친절', value: GQLTTS_EMOTION.Kind },
  { label: '분노', value: GQLTTS_EMOTION.Angry },
  { label: '짜증', value: GQLTTS_EMOTION.Annoyance },
  { label: '약한분노', value: GQLTTS_EMOTION.WeekAngry },
  { label: '울부짖음', value: GQLTTS_EMOTION.Howl },
  { label: '울먹임', value: GQLTTS_EMOTION.Cry },
  { label: '놀람', value: GQLTTS_EMOTION.Surprised },
  { label: '공포', value: GQLTTS_EMOTION.Fear },
  { label: '속삭임', value: GQLTTS_EMOTION.Whisper },
  { label: '힘없는', value: GQLTTS_EMOTION.Powerless },
  { label: '취한', value: GQLTTS_EMOTION.Drunken },
  { label: '무미건조', value: GQLTTS_EMOTION.Insipid },
]

export interface ITtsSpeakerListableOption extends ODListableOption {
  filter: string
}

export const TtsSpeakerPrimaryKey = 'ttsSpeakerId'

export const SAMPLE_TEXT = '안녕하세요.'
