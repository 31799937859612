"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropFieldWithUI = void 0;
const mobx_1 = require("mobx");
const SelectionFieldWithUI_1 = require("./SelectionFieldWithUI");
class PropFieldWithUI extends SelectionFieldWithUI_1.SelectionFieldWithUI {
    constructor(propStore, isMain, fieldId, getter, setter, uiOptions = {}) {
        super(fieldId, getter, setter, {
            ...uiOptions,
            selection: propStore.generateOptions(isMain),
        });
        this.isMain = isMain;
        this.propStore = propStore;
    }
    reload() {
        (0, mobx_1.runInAction)(() => {
            this.input.options = this.propStore.generateOptions(this.isMain);
        });
        super.reload();
    }
}
exports.PropFieldWithUI = PropFieldWithUI;
