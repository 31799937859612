"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbAlgorithmDoChapterStoreStrategy = void 0;
const baseDoChapterStoreStrategy_1 = require("./baseDoChapterStoreStrategy");
// 원본 클래스인 챕터스토어에는 DO접두어가 없으나 실수로 보인다.
class HbAlgorithmDoChapterStoreStrategy extends baseDoChapterStoreStrategy_1.BaseDoChapterStoreStrategy {
    constructor() {
        super(...arguments);
        this.initialPageSortRequest = {
            page: {
                index: 0,
                size: 20,
            },
            sort: {
                key: 'regDate',
                order: 'asc',
            },
        };
    }
    // 페이지네이션 챕터 로드 요청에 대한 처리
    async loadPaginatedChapters(pageSortRequest) {
        const server = this.doChapterStore.rootStore.di.server;
        return server.getAlgorithmChaptersForStudio(this.doChapterStore.story.storyId, pageSortRequest);
    }
    async loadPaginatedAlgorithmBlock(groupId, paginationConfig) {
        const server = this.doChapterStore.rootStore.di.server;
        return server.getChapter(groupId, paginationConfig);
    }
}
exports.HbAlgorithmDoChapterStoreStrategy = HbAlgorithmDoChapterStoreStrategy;
