"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonToFormData = exports.objectToQueryString = void 0;
// json => queryString 함수
const objectToQueryString = (obj) => {
    const queryParams = [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            if (value !== undefined) {
                queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }
    }
    return queryParams.join('&');
};
exports.objectToQueryString = objectToQueryString;
function jsonToFormData(json) {
    const formData = new FormData();
    for (const key in json) {
        if (Object.prototype.hasOwnProperty.call(json, key)) {
            const value = json[key];
            if (value instanceof Blob) {
                formData.append(key, value);
            }
            else {
                formData.append(key, value.toString());
            }
        }
    }
    return formData;
}
exports.jsonToFormData = jsonToFormData;
