import { ODListableResponseType } from '@odc/od-react-belt'
import { GQLUserSelectedChoice } from '@storyplay/common'
import React from 'react'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IUserSelectedChoiceListableOption } from '../UserSelectedChoiceCommon'

export function useUserSelectedChoiceDataLoader(choiceId: number) {
  const { listUserSelectedChoice } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IUserSelectedChoiceListableOption
    ): Promise<ODListableResponseType<GQLUserSelectedChoice>> {
      const r = await listUserSelectedChoice({
        page,
        pageSize: 10,
        ...options,
        choiceId,
      })
      return r as ODListableResponseType<GQLUserSelectedChoice>
    },
    [listUserSelectedChoice, choiceId]
  )
}
