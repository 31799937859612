"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryProcessor = void 0;
const taskQueue_1 = require("../taskQueue");
const loadRootStoreWithImportStory_1 = require("./loadRootStoreWithImportStory");
class StoryProcessor {
    async run(story, taskRecipe, taskQueue) {
        const copied = JSON.parse(JSON.stringify(story));
        const tasks = await taskRecipe.map(copied, () => (0, loadRootStoreWithImportStory_1.loadRootStoreWithImportStory)(story));
        const queue = taskQueue !== null && taskQueue !== void 0 ? taskQueue : new taskQueue_1.LocalTaskQueue();
        queue.setTaskHandler(taskRecipe.process.bind(taskRecipe));
        return queue.enqueueTasks(tasks, taskRecipe.reduce.bind(taskRecipe));
    }
}
exports.StoryProcessor = StoryProcessor;
