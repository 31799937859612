import { BOOK, Scripter } from '@storyplay/common'
import { decrypt } from '@storyplay/core'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import React, { useCallback } from 'react'
import { getGoogleSpreadSheetData, Utils } from '../utils/utils'
import { useStoryPlayAPIs } from './useStoryPlayAPIs'

export enum SCRIPT_PARSE_MESSAGE_TYPE {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export type SCRIPT_PARSE_MESSAGE = {
  type: SCRIPT_PARSE_MESSAGE_TYPE
  message: string
}

export function useScriptImporter() {
  const [logs, setLogs] = React.useState<Array<SCRIPT_PARSE_MESSAGE>>([])

  const { listColor, getImportStudioGoogleInfo } = useStoryPlayAPIs()
  const [finalData, setFinalData] = React.useState<string | null>(null)
  const [book, setBook] = React.useState<BOOK | null>(null)
  const [loading, setLoading] = React.useState(false)

  const parseSheets = useCallback(
    async (
      googleSheet: GoogleSpreadsheet,
      id: string = '',
      bookName: string = ''
    ) => {
      /**
       *
       * 아래의 내용에 수정이 가해지는 경우 스튜디오 파트에도 동일한 형태로 수정이 가해져야 합니다.
       * 만약 새로운 기능의 추가 또는 수정 등이 있는 경우 스튜디오에도 반드시 내용을 공유해주세요.
       *
       */
      const scripter = new Scripter()
      scripter.clearEnsure()
      let finalDataToRet: string | null = null
      let book: BOOK | null = null
      try {
        const colorList = (await listColor({ pageSize: 1000 })).list
        const colorMap = colorList.reduce(
          (acc, { name, hex }) => ({ ...acc, [name]: hex }),
          {}
        )
        for (let sheetIdx = 0; sheetIdx < googleSheet.sheetCount; sheetIdx++) {
          const sheetInfo = googleSheet.sheetsByIndex[sheetIdx]
          const { title, rowCount } = sheetInfo
          const sheetData = await sheetInfo.getRows()

          if (title === '_관리_') {
            setLogs((arr) => [
              ...arr,
              {
                type: SCRIPT_PARSE_MESSAGE_TYPE.Info,
                message: `[_관리_] 시트를 발견하였습니다.`,
              },
            ])
            const results = getGoogleSpreadSheetData(sheetData)

            scripter.loadGoogleMgmtSheet(results)
          } else {
            setLogs((arr) => [
              ...arr,
              {
                type: SCRIPT_PARSE_MESSAGE_TYPE.Info,
                message: `챕터 [${title}] : 로드하고 있습니다.`,
              },
            ])
            const range = `${title}!B2:M${rowCount}`
            const results = getGoogleSpreadSheetData(sheetData)
            scripter.loadGoogleSheet(
              googleSheet.spreadsheetId,
              range,
              title,
              results,
              colorMap
            )
          }
        }
        const finalData = scripter.finish()
        setFinalData(finalData)
        book = JSON.parse(finalData)
        console.log(80, `generated book data : `, { finalData })
        setBook(book)
        finalDataToRet = finalData
      } catch (ex: any) {
        setLogs((arr) => [
          ...arr,
          {
            type: SCRIPT_PARSE_MESSAGE_TYPE.Error,
            message: `오류 => ${ex.message}`,
          },
        ])
        console.error(91, ex)
        throw new Error('스크립트 오류가 있습니다.')
      }

      const chaptersCustomId = Object.keys(book!.chapters)
        .map(
          (c) =>
            `[${book!.chapters[c].name}] => [${book!.chapters[c].customId}]`
        )
        .join(', ')

      setLogs((arr) => [
        ...arr,
        {
          type: SCRIPT_PARSE_MESSAGE_TYPE.Info,
          message: `챕터 커스텀 아이디 매핑 정보 => \n${chaptersCustomId}`,
        },
      ])
      setLogs((arr) => [
        ...arr,
        { type: SCRIPT_PARSE_MESSAGE_TYPE.Info, message: '완료했습니다.' },
      ])
      return { json: finalDataToRet, book }
    },
    [listColor, getImportStudioGoogleInfo]
  )

  const loadSheet = React.useCallback(
    async (sheetId: string, id: string = '', bookName: string = '') => {
      setLoading(true)
      try {
        setLogs([])
        const importInfo = await getImportStudioGoogleInfo()
        const getGoogleSheet = async (sheetId: string) => {
          const doc = new GoogleSpreadsheet(sheetId)
          await doc.useServiceAccountAuth({
            client_email: importInfo.clientEmail,
            private_key: decrypt(importInfo.pkey),
          })
          await doc.loadInfo()
          return doc
        }
        const googleSheet = await getGoogleSheet(sheetId)
        return parseSheets(googleSheet, id, bookName)
      } catch (ex: any) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
    },
    [parseSheets]
  )

  const _setFinalData = React.useCallback(
    (finalData: string) => {
      setFinalData(finalData)
      setBook(JSON.parse(finalData))
    },
    [setFinalData]
  )

  return {
    // parseSheets,
    loadSheet,
    setFinalData: _setFinalData,
    data: {
      finalData,
      logs,
      book,
    },
    loading,
  }
}
