"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decrypt = exports.encrypt = void 0;
const crypto = require("crypto");
// https://attacomsian.com/blog/nodejs-encrypt-decrypt-data
const algorithm = 'aes-256-ctr';
const secretKey = 'vOVaweRawei31RIqCc7rdxs01lwHzfr3';
const encrypt = (text) => {
    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
    const hash = {
        iv: iv.toString('hex'),
        content: encrypted.toString('hex'),
    };
    return `${hash.iv}$${hash.content}`;
};
exports.encrypt = encrypt;
const decrypt = (hashStr) => {
    const [iv, content] = hashStr.split('$');
    const hash = {
        iv,
        content,
    };
    const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
    const decrpyted = Buffer.concat([
        decipher.update(Buffer.from(hash.content, 'hex')),
        decipher.final(),
    ]);
    return decrpyted.toString();
};
exports.decrypt = decrypt;
