import { ODListableOption, ODListableResponseType } from '@odc/od-react-belt'
import { GQLStorygameCharacterAdditionalPrompt } from '@storyplay/core'
import React from 'react'
import { useStoryPlayAPIs } from '../../../../hooks/useStoryPlayAPIs'

export function useStorygameCharacterAdditionalPromptListDataLoader(
  storyId: number
) {
  const { listStorygameCharacterAdditionalPrompt } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: { storyId: number } & ODListableOption
    ): Promise<ODListableResponseType<GQLStorygameCharacterAdditionalPrompt>> {
      const r = await listStorygameCharacterAdditionalPrompt({
        page,
        pageSize: 10,
        storyId,
        ...options,
      })
      return r as ODListableResponseType<GQLStorygameCharacterAdditionalPrompt>
    },
    [listStorygameCharacterAdditionalPrompt, storyId]
  )
}
