"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaidPerStoryRow = void 0;
const ui_1 = require("../ui");
class PaidPerStoryRow {
    constructor(editor, data) {
        var _a;
        this.editor = editor;
        this.originData = data;
        this.startIndexField = new ui_1.SelectionInput(`startIndex`, '무료 회차 설정', 1, this.editor.chapterIndexSelection, {
            textAfterField: '부터',
            placeholder: '회차를 입력하세요.',
            onChangeBeforeSubmit: v => {
                this.endIndexField.onChangeOptions(this.editor.chapterIndexSelection.slice(Number(v) - 1));
            },
        });
        this.endIndexField = new ui_1.SelectionInput(`endIndex`, '', (_a = this.originData) !== null && _a !== void 0 ? _a : undefined, this.editor.chapterIndexSelection, {
            noLabel: true,
            textAfterField: '까지',
            placeholder: '회차를 입력하세요.',
            onChangeBeforeSubmit: v => {
                var _a, _b, _c;
                const valueParsed = JSON.parse((_b = (_a = this.editor.paidPerStorySettingField) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '{}');
                valueParsed.chapterIndex = Number(v);
                (_c = this.editor.paidPerStorySettingField) === null || _c === void 0 ? void 0 : _c.onChange(JSON.stringify(valueParsed));
            },
        });
    }
}
exports.PaidPerStoryRow = PaidPerStoryRow;
