"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTRightThink = void 0;
const core_1 = require("@storyplay/core");
const __1 = require("../../../../..");
const DOSTBubbleWithChrBase_1 = require("./DOSTBubbleWithChrBase");
class DOSTRightThink extends DOSTBubbleWithChrBase_1.DOSTBubbleWithChrBase {
    constructor() {
        super(...arguments);
        this.isForMainChr = true;
    }
    get chr() {
        return this.block.parentChapter.mainCharacter;
    }
    get chrName() {
        return '{{성}}{{이름}}';
    }
    async setChr(chr) {
        // cannot change
    }
    replaceToOpposite(newChrName) {
        const dataChanging = {
            ...this.data,
            statementType: core_1.STATEMENT_TYPE.CharacterThink,
            chrName: newChrName,
        };
        this.block.replaceStatement(this, new __1.DOSTLeftThink(dataChanging, this.block));
    }
}
exports.DOSTRightThink = DOSTRightThink;
