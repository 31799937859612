import * as React from 'react'
import styled from 'styled-components'

type ODModalFooterProps = {}

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const ODModalFooter: React.FC<ODModalFooterProps> = ({ children }) => {
  return <FooterWrapper>{children}</FooterWrapper>
}
