import React from 'react'
import { Redirect } from 'react-router-dom'
import { ODButton, ODButtonTheme } from '../../components/base/ODButton'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { ODIcon, ODIcons } from '../../components/ODIcon'
import { ODModal, ODModalSize } from '../../components/ODModal/ODModal'
import { ODModalBody } from '../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../components/ODModal/ODModalHeader'
import { useAppContext } from '../../context/AppContext'
import { useODMutation } from '../../context/ODCommon'
import { GQL_MARK_PASSWORD_AS_CHANGED } from '../../hooks/gqls_admin'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

interface IPasswordTooOldContainerProps {}

export const PasswordTooOldContainer: React.FC<IPasswordTooOldContainerProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState('')
  const { setPasswordTooOld } = useAppContext()

  const mark = useODMutation(GQL_MARK_PASSWORD_AS_CHANGED)
  const later = React.useCallback(async () => {
    setIsLoading(true)
    try {
      await mark({})
      setPasswordTooOld(false)
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setIsLoading(false)
    }
  }, [mark, setPasswordTooOld])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.XLarge}>
      <BlockingLoadBox show={isLoading} message="비밀번호 변경중입니다." />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="비밀번호 변경하기" onClose={() => null} headerBottomPadding={30}>
          <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 60 }} />
        </ODModalHeader>
        <ODModalBody>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
            비밀번호를 변경한지 오래되었습니다. 보안을 위해 비밀번호를 변경해주세요!
          </div>
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            style={{ width: '30%', margin: 10 }}
            theme={ODButtonTheme.PrimaryInvert}
            onClick={() => setPasswordTooOld(false)}
          >
            다음에 변경하기
          </ODButton>
          <ODButton style={{ width: '30%', margin: 10 }} theme={ODButtonTheme.PrimaryInvert} onClick={() => later()}>
            6개월 뒤에 변경하기
          </ODButton>
          <ODButton
            style={{ width: '60%', margin: 10 }}
            theme={ODButtonTheme.Primary}
            onClick={() => {
              setRedirect(SiteUrls.Admin.User.EditMyProfile)
            }}
          >
            변경하러 가기
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
