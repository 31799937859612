import {
  GQLStorygameCharacterPrompt,
  GQLStorygameCharacterPromptPropsInput,
} from '@storyplay/common'
import {
  GQLAddCoinInput,
  GQLApplyMgmtSheetToStoryInput,
  GQLChangeChapterBooleanInput,
  GQLChapter,
  GQLChapterComment,
  GQLChapterCommentUpdateByAdminInput,
  GQLChapterCreationInput,
  GQLChapterOption,
  GQLChapterOptionCreationInput,
  GQLChapterOptionUpdateInput,
  GQLChapterPropsInput,
  GQLChoice,
  GQLChoiceFromPlayerClass,
  GQLChoiceFromPlayerClassCreationInput,
  GQLChoiceFromPlayerClassUpdateInput,
  GQLChr,
  GQLChrPropsInput,
  GQLColor,
  GQLColorCreationInput,
  GQLCreateOnboardingPopupChoiceInput,
  GQLCreateOnboardingPopupInput,
  GQLCreateOnboardingPopupProfileImageInput,
  GQLEndingCard,
  GQLEndingCardCreationInput,
  GQLEndingCardUpdateInput,
  GQLEndingShowsProperty,
  GQLEndingShowsPropertyCreationInput,
  GQLGetAffectionByAdminInput,
  GQLGetAffectionByAdminOutput,
  GQLGetImportStudioGoogleInfoOutput,
  GQLListableAppSplashImage,
  GQLListableChapter,
  GQLListableChapterComment,
  GQLListableChapterCommentInput,
  GQLListableChapterInput,
  GQLListableChapterOptionInput,
  GQLListableChoice,
  GQLListableChoiceFromPlayerClass,
  GQLListableChoiceFromPlayerClassInput,
  GQLListableChoiceInput,
  GQLListableChr,
  GQLListableChrInput,
  GQLListableColor,
  GQLListableColorInput,
  GQLListableEnding,
  GQLListableEndingCard,
  GQLListableEndingCardByAdminInput,
  GQLListableEndingInput,
  GQLListableEndingShowsProperty,
  GQLListableEndingShowsPropertyInput,
  GQLListableFeatured,
  GQLListableFeaturedInput,
  GQLListableHomeSection,
  GQLListableHomeSectionInput,
  GQLListableIpSourcing,
  GQLListableLedger,
  GQLListableLedgerByAdminInput,
  GQLListableNotice,
  GQLListableNoticeInput,
  GQLListableOnboardingPopup,
  GQLListableOnboardingPopupChoice,
  GQLListableOnboardingPopupChoiceInput,
  GQLListableOnboardingPopupInput,
  GQLListableOnboardingPopupProfileImage,
  GQLListableOnboardingPopupProfileImageInput,
  GQLListablePlayerClass,
  GQLListablePlayerClassInput,
  GQLListablePreviewEnding,
  GQLListablePreviewEndingInput,
  GQLListableRecommendSectionStory,
  GQLListableRecommendSectionStoryInput,
  GQLListableStorygameCharacterAdditionalPromptInput,
  GQLListableStorygameCharacterPrompt,
  GQLListableStorygameCharacterPromptInput,
  GQLListableStoryHasBackground,
  GQLListableStoryHasBackgroundInput,
  GQLListableStoryHasRecommendSection,
  GQLListableStoryItem,
  GQLListableStoryItemInput,
  GQLListableStoryProperty,
  GQLListableStoryPropertyInput,
  GQLListableTtsSpeaker,
  GQLListableTtsSpeakerInput,
  GQLListableUserReportChapterComment,
  GQLListableUserReportChapterCommentInput,
  GQLListableUserSelectedChoice,
  GQLListableUserSelectedChoiceInput,
  GQLOkResponse,
  GQLOnboardingPopup,
  GQLOnboardingPopupChoice,
  GQLOnboardingPopupProfileImage,
  GQLPlayerClass,
  GQLPlayerClassCreationInput,
  GQLPlayerClassUpdateInput,
  GQLRecommendSectionStory,
  GQLRecommendSectionStoryCreationInput,
  GQLSingleIDInput,
  GQLStory,
  GQLStorygameCharacterAdditionalPrompt,
  GQLStorygameCharacterAdditionalPromptPropsInput,
  GQLStoryHasRecommendSection,
  GQLStoryHasRecommendSectionCreationInput,
  GQLStoryHasRecommendSectionUpdateInput,
  GQLStoryPropsInput,
  GQLUpdateChoiceInput,
  GQLUpdateOnboardingPopupChoiceInput,
  GQLUpdateOnboardingPopupInput,
  GQLUpdateOnboardingPopupProfileImageInput,
  GQLUser,
  GQLUserMetric,
  GQLUserPropsInputByAdmin,
} from '@storyplay/core'
import { ChangeChapterFreeSettingInput } from '../../../server/src/module/chapter/input'
import {
  ListableInput,
  SingleIDInput,
} from '../../../server/src/module/common/input'
import {
  GQLConvertTextToVoiceInput,
  GQLGetTtsUrlResponse,
  GQLListableAppSplashImageInput,
  GQLListableDirectAds,
  GQLListableDirectAdsInput,
  GQLListableRecommendationForFirstUser,
  GQLListableRecommendationForFirstUserInput,
} from '../@types/server'
import { useODMutation, useODQuery } from '../context/ODCommon'
import {
  GQL_ADD_COIN_BY_ADMIN,
  GQL_ADD_COLOR_BY_ADMIN,
  GQL_APPLY_MGMT_SHEET_TO_STORY,
  GQL_CHANGE_CHAPTER_FREE_SETTING,
  GQL_CHANGE_FREE,
  GQL_CREATE_CHAPTER_FOR_STUDIO,
  GQL_CREATE_CHAPTER_OPTION,
  GQL_CREATE_CHOICE_FROM_PLAYER_CLASS,
  GQL_CREATE_ENDING_CARD,
  GQL_CREATE_ENDING_SHOWS_PROPERTY,
  GQL_CREATE_ONBOARDING_POPUP,
  GQL_CREATE_ONBOARDING_POPUP_CHOICE,
  GQL_CREATE_ONBOARDING_POPUP_PROFILE_IMAGE,
  GQL_CREATE_PLAYER_CLASS,
  GQL_CREATE_RECOMMEND_SECTION_STORY,
  GQL_CREATE_STORY,
  GQL_CREATE_STORY_HAS_RECOMMEND_SECTION,
  GQL_DELETE_CHAPTER_OPTION,
  GQL_DELETE_ENDING_CARD,
  GQL_DELETE_ONBOARDING_POPUP,
  GQL_DELETE_ONBOARDING_POPUP_CHOICE,
  GQL_DELETE_ONBOARDING_POPUP_PROFILE_IMAGE,
  GQL_DELETE_RECOMMEND_SECTION_STORY,
  GQL_DELETE_STORY_HAS_RECOMMEND_SECTION,
  GQL_GET_AFFECTION_BY_ADMIN,
  GQL_GET_ALL_CHAPTERS,
  GQL_GET_CHAPTER_OPTION,
  GQL_GET_CHOICE,
  GQL_GET_CHOICE_FROM_PLAYER_CLASS,
  GQL_GET_COLOR_LIST,
  GQL_GET_ENDING_CARD,
  GQL_GET_IMPORT_GOOGLE_INFO,
  GQL_GET_IP_SOURCING_LIST,
  GQL_GET_NOTICE_LIST,
  GQL_GET_ONBOARDING_POPUP,
  GQL_GET_ONBOARDING_POPUP_CHOICE,
  GQL_GET_ONBOARDING_POPUP_PROFILE_IMAGE,
  GQL_GET_PLAYER_CLASS,
  GQL_GET_RECOMMEND_SECTION_STORY,
  GQL_GET_STORY,
  GQL_GET_STORY_HAS_RECOMMEND_SECTION,
  GQL_GET_TTS_URL,
  GQL_GET_USER_METRIC_BY_ID,
  GQL_LIST_APP_SPLASH_IMAGE,
  GQL_LIST_CHAPTER,
  GQL_LIST_CHAPTER_COMMENT_BY_ADMIN,
  GQL_LIST_CHAPTER_OPTION,
  GQL_LIST_CHOICE,
  GQL_LIST_CHOICE_FROM_PLAYER_CLASS,
  GQL_LIST_CHR,
  GQL_LIST_DIRECT_ADS_BY_ADMIN,
  GQL_LIST_ENDING,
  GQL_LIST_ENDING_CARD_BY_ADMIN,
  GQL_LIST_ENDING_SHOWS_PROPERTY,
  GQL_LIST_FEATURED,
  GQL_LIST_HOME_SECTION,
  GQL_LIST_LEDGER_BY_ADMIN,
  GQL_LIST_ONBOARDING_POPUP,
  GQL_LIST_ONBOARDING_POPUP_CHOICE,
  GQL_LIST_ONBOARDING_POPUP_PROFILE_IMAGE,
  GQL_LIST_PLAYER_CLASS,
  GQL_LIST_PREVIEW_ENDING_BY_ADMIN,
  GQL_LIST_RECOMMENDATION_FOR_FIRST_USER,
  GQL_LIST_RECOMMEND_SECTION_STORY,
  GQL_LIST_STORY_HAS_BACKGROUND,
  GQL_LIST_STORY_HAS_RECOMMEND_SECTION,
  GQL_LIST_STORY_ITEM,
  GQL_LIST_STORY_PROPERTY,
  GQL_LIST_TTS_SPEAKER_LIST,
  GQL_LIST_USER_REPORT_CHAPTER_COMMENT_BY_ADMIN,
  GQL_LIST_USER_SELECTED_CHOICE,
  GQL_PUBLISH_CHAPTER,
  GQL_REMOVE_STORY,
  GQL_UPDATE_CHAPTER,
  GQL_UPDATE_CHAPTER_COMMENT_BY_ADMIN,
  GQL_UPDATE_CHAPTER_OPTION,
  GQL_UPDATE_CHOICE,
  GQL_UPDATE_CHOICE_FROM_PLAYER_CLASS,
  GQL_UPDATE_CHR,
  GQL_UPDATE_ENDING_CARD,
  GQL_UPDATE_ONBOARDING_POPUP,
  GQL_UPDATE_ONBOARDING_POPUP_CHOICE,
  GQL_UPDATE_ONBOARDING_POPUP_PROFILE_IMAGE,
  GQL_UPDATE_PLAYER_CLASS,
  GQL_UPDATE_STORY,
  GQL_UPDATE_STORY_HAS_RECOMMEND_SECTION,
  GQL_UPDATE_USER_BY_ADMIN,
  GQL_LIST_STORYGAME_CHARACTER_PROMPT,
  GQL_GET_STORYGAME_CHARACTER_PROMPT,
  GQL_UPDATE_STORYGAME_CHARACTER_PROMPT,
  GQL_LIST_STORYGAME_CHARACTER_ADDITIONAL_PROMPT,
  GQL_CREATE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT,
  GQL_GET_STORYGAME_CHARACTER_ADDITIONAL_PROMPT,
  GQL_UPDATE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT,
  GQL_DELETE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT,
  GQL_CREATE_STORYGAME_CHARACTER_PROMPT,
} from './gqls_admin'

export function useStoryPlayAPIs() {
  const apiCreateStory = useODMutation<Partial<GQLStoryPropsInput>, GQLStory>(
    GQL_CREATE_STORY
  )
  const apiUpdateStory = useODMutation<Partial<GQLStoryPropsInput>, GQLStory>(
    GQL_UPDATE_STORY
  )
  const apiGetStory = useODQuery<GQLSingleIDInput, GQLStory>(GQL_GET_STORY)
  const apiRemoveStory = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_REMOVE_STORY
  )
  const updateUserByAdmin = useODMutation<
    Partial<GQLUserPropsInputByAdmin>,
    GQLUser
  >(GQL_UPDATE_USER_BY_ADMIN)
  const createChapterForStudio = useODMutation<
    Partial<GQLChapterCreationInput>,
    GQLChapter
  >(GQL_CREATE_CHAPTER_FOR_STUDIO)
  const updateChapter = useODMutation<
    Partial<GQLChapterPropsInput>,
    GQLChapter
  >(GQL_UPDATE_CHAPTER)
  const listChapterOfStory = useODQuery<
    GQLListableChapterInput,
    GQLListableChapter
  >(GQL_LIST_CHAPTER)
  const changeChapterFree = useODMutation<
    Partial<GQLChangeChapterBooleanInput>,
    GQLOkResponse
  >(GQL_CHANGE_FREE)
  const publishChapter = useODMutation<
    Partial<GQLSingleIDInput>,
    GQLOkResponse
  >(GQL_PUBLISH_CHAPTER)
  const changeChapterFreeSetting = useODMutation<
    ChangeChapterFreeSettingInput,
    GQLOkResponse
  >(GQL_CHANGE_CHAPTER_FREE_SETTING)

  const updateChr = useODMutation<Partial<GQLChrPropsInput>, GQLChr>(
    GQL_UPDATE_CHR
  )
  const listChrOfStory = useODQuery<GQLListableChrInput, GQLListableChr>(
    GQL_LIST_CHR
  )

  const listChoiceOfStory = useODQuery<
    Partial<GQLListableChoiceInput>,
    GQLListableChoice
  >(GQL_LIST_CHOICE)

  const getChoice = useODQuery<SingleIDInput, GQLChoice>(GQL_GET_CHOICE)

  const updateChoice = useODMutation<Partial<GQLUpdateChoiceInput>, GQLChoice>(
    GQL_UPDATE_CHOICE
  )

  const listBackgroundOfStory = useODQuery<
    GQLListableStoryHasBackgroundInput,
    GQLListableStoryHasBackground
  >(GQL_LIST_STORY_HAS_BACKGROUND)

  const listEndingOfStory = useODQuery<
    Partial<GQLListableEndingInput>,
    GQLListableEnding
  >(GQL_LIST_ENDING)

  const listStoryPropertyOfStory = useODQuery<
    Partial<GQLListableStoryPropertyInput>,
    GQLListableStoryProperty
  >(GQL_LIST_STORY_PROPERTY)

  const createEndingShowsProperty = useODMutation<
    GQLEndingShowsPropertyCreationInput,
    GQLEndingShowsProperty
  >(GQL_CREATE_ENDING_SHOWS_PROPERTY)
  const listEndingShowsPropertyOfStory = useODQuery<
    Partial<GQLListableEndingShowsPropertyInput>,
    GQLListableEndingShowsProperty
  >(GQL_LIST_ENDING_SHOWS_PROPERTY)

  const listEndingCardByAdmin = useODQuery<
    Partial<GQLListableEndingCardByAdminInput>,
    GQLListableEndingCard
  >(GQL_LIST_ENDING_CARD_BY_ADMIN)

  const getEndingCard = useODQuery<SingleIDInput, GQLEndingCard>(
    GQL_GET_ENDING_CARD
  )

  const createEndingCard = useODMutation<
    Partial<GQLEndingCardCreationInput>,
    GQLEndingCard
  >(GQL_CREATE_ENDING_CARD)

  const updateEndingCard = useODMutation<
    Partial<GQLEndingCardUpdateInput>,
    GQLEndingCard
  >(GQL_UPDATE_ENDING_CARD)

  const deleteEndingCard = useODMutation<Partial<SingleIDInput>, GQLOkResponse>(
    GQL_DELETE_ENDING_CARD
  )

  const listOnboardingPopup = useODQuery<
    Partial<GQLListableOnboardingPopupInput>,
    GQLListableOnboardingPopup
  >(GQL_LIST_ONBOARDING_POPUP)

  const getOnboardingPopup = useODQuery<SingleIDInput, GQLOnboardingPopup>(
    GQL_GET_ONBOARDING_POPUP
  )

  const createOnboardingPopup = useODMutation<
    Partial<GQLCreateOnboardingPopupInput>,
    GQLOnboardingPopup
  >(GQL_CREATE_ONBOARDING_POPUP)

  const updateOnboardingPopup = useODMutation<
    Partial<GQLUpdateOnboardingPopupInput>,
    GQLOnboardingPopup
  >(GQL_UPDATE_ONBOARDING_POPUP)

  const deleteOnboardingPopup = useODMutation<
    Partial<SingleIDInput>,
    GQLOkResponse
  >(GQL_DELETE_ONBOARDING_POPUP)

  const listOnboardingPopupChoice = useODQuery<
    Partial<GQLListableOnboardingPopupChoiceInput>,
    GQLListableOnboardingPopupChoice
  >(GQL_LIST_ONBOARDING_POPUP_CHOICE)

  const getOnboardingPopupChoice = useODQuery<
    SingleIDInput,
    GQLOnboardingPopupChoice
  >(GQL_GET_ONBOARDING_POPUP_CHOICE)

  const createOnboardingPopupChoice = useODMutation<
    Partial<GQLCreateOnboardingPopupChoiceInput>,
    GQLOnboardingPopupChoice
  >(GQL_CREATE_ONBOARDING_POPUP_CHOICE)

  const updateOnboardingPopupChoice = useODMutation<
    Partial<GQLUpdateOnboardingPopupChoiceInput>,
    GQLOnboardingPopupChoice
  >(GQL_UPDATE_ONBOARDING_POPUP_CHOICE)

  const deleteOnboardingPopupChoice = useODMutation<
    Partial<SingleIDInput>,
    GQLOkResponse
  >(GQL_DELETE_ONBOARDING_POPUP_CHOICE)

  const listPreviewEndingOfStory = useODQuery<
    Partial<GQLListablePreviewEndingInput>,
    GQLListablePreviewEnding
  >(GQL_LIST_PREVIEW_ENDING_BY_ADMIN)

  const getUserMetricById = useODQuery<GQLSingleIDInput, GQLUserMetric>(
    GQL_GET_USER_METRIC_BY_ID
  )

  const listChapterCommentOfStory = useODQuery<
    Partial<GQLListableChapterCommentInput>,
    GQLListableChapterComment
  >(GQL_LIST_CHAPTER_COMMENT_BY_ADMIN)

  const updateChapterCommentByAdmin = useODMutation<
    Partial<GQLChapterCommentUpdateByAdminInput>,
    GQLChapterComment
  >(GQL_UPDATE_CHAPTER_COMMENT_BY_ADMIN)

  const getAllChaptersById = useODQuery<GQLSingleIDInput, GQLStory>(
    GQL_GET_ALL_CHAPTERS
  )

  const listUserReportChapterComment = useODQuery<
    Partial<GQLListableUserReportChapterCommentInput>,
    GQLListableUserReportChapterComment
  >(GQL_LIST_USER_REPORT_CHAPTER_COMMENT_BY_ADMIN)

  const listStoryItem = useODQuery<
    Partial<GQLListableStoryItemInput>,
    GQLListableStoryItem
  >(GQL_LIST_STORY_ITEM)

  const listNotice = useODQuery<
    Partial<GQLListableNoticeInput>,
    GQLListableNotice
  >(GQL_GET_NOTICE_LIST)

  const addUserCoin = useODMutation<Partial<GQLAddCoinInput>, GQLOkResponse>(
    GQL_ADD_COIN_BY_ADMIN
  )

  const listColor = useODQuery<
    Partial<GQLListableColorInput>,
    GQLListableColor
  >(GQL_GET_COLOR_LIST)

  const createColor = useODMutation<Partial<GQLColorCreationInput>, GQLColor>(
    GQL_ADD_COLOR_BY_ADMIN
  )

  const apiListCharacters = useODQuery<
    Partial<GQLListableChrInput>,
    GQLListableChr
  >(GQL_LIST_CHR)

  const apiApplyMgmtSheetToStory = useODMutation<
    GQLApplyMgmtSheetToStoryInput,
    GQLStory
  >(GQL_APPLY_MGMT_SHEET_TO_STORY)

  const listIpSourcing = useODQuery<
    Partial<ListableInput>,
    GQLListableIpSourcing
  >(GQL_GET_IP_SOURCING_LIST)

  const listUserLedgerByAdmin = useODQuery<
    Partial<GQLListableLedgerByAdminInput>,
    GQLListableLedger
  >(GQL_LIST_LEDGER_BY_ADMIN)

  const listFeatured = useODQuery<
    Partial<GQLListableFeaturedInput>,
    GQLListableFeatured
  >(GQL_LIST_FEATURED)

  const listStoryForFeatured = `
    query listStoryForFeatured($data: ListableStoryInput!) {
      listStory(data: $data) {
        list {
          storyId
          name
        }
        totalCount
        page
        pageSize
      }
    }`

  const listHomeSection = useODQuery<
    Partial<GQLListableHomeSectionInput>,
    GQLListableHomeSection
  >(GQL_LIST_HOME_SECTION)
  const getPlayerClass = useODQuery<GQLSingleIDInput, GQLPlayerClass>(
    GQL_GET_PLAYER_CLASS
  )

  const createPlayerClass = useODMutation<
    GQLPlayerClassCreationInput,
    GQLPlayerClass
  >(GQL_CREATE_PLAYER_CLASS)

  const updatePlayerClass = useODMutation<
    GQLPlayerClassUpdateInput,
    GQLPlayerClass
  >(GQL_UPDATE_PLAYER_CLASS)

  const listPlayerClass = useODQuery<
    Partial<GQLListablePlayerClassInput>,
    GQLListablePlayerClass
  >(GQL_LIST_PLAYER_CLASS)

  const getChoiceFromPlayerClass = useODQuery<
    GQLSingleIDInput,
    GQLChoiceFromPlayerClass
  >(GQL_GET_CHOICE_FROM_PLAYER_CLASS)

  const createChoiceFromPlayerClass = useODMutation<
    GQLChoiceFromPlayerClassCreationInput,
    GQLChoiceFromPlayerClass
  >(GQL_CREATE_CHOICE_FROM_PLAYER_CLASS)

  const updateChoiceFromPlayerClass = useODMutation<
    GQLChoiceFromPlayerClassUpdateInput,
    GQLChoiceFromPlayerClass
  >(GQL_UPDATE_CHOICE_FROM_PLAYER_CLASS)

  const listChoiceFromPlayerClass = useODQuery<
    Partial<GQLListableChoiceFromPlayerClassInput>,
    GQLListableChoiceFromPlayerClass
  >(GQL_LIST_CHOICE_FROM_PLAYER_CLASS)

  const listTtsSpeaker = useODQuery<
    Partial<GQLListableTtsSpeakerInput>,
    GQLListableTtsSpeaker
  >(GQL_LIST_TTS_SPEAKER_LIST)

  const getTtsUrl = useODMutation<
    Partial<GQLConvertTextToVoiceInput>,
    GQLGetTtsUrlResponse
  >(GQL_GET_TTS_URL)

  const listUserSelectedChoice = useODQuery<
    Partial<GQLListableUserSelectedChoiceInput>,
    GQLListableUserSelectedChoice
  >(GQL_LIST_USER_SELECTED_CHOICE)

  const getAffection = useODQuery<
    GQLGetAffectionByAdminInput,
    GQLGetAffectionByAdminOutput
  >(GQL_GET_AFFECTION_BY_ADMIN)

  const createOnboardingPopupProfileImage = useODMutation<
    GQLCreateOnboardingPopupProfileImageInput,
    GQLOnboardingPopupProfileImage
  >(GQL_CREATE_ONBOARDING_POPUP_PROFILE_IMAGE)

  const getOnboardingPopupProfileImage = useODQuery<
    SingleIDInput,
    GQLOnboardingPopupProfileImage
  >(GQL_GET_ONBOARDING_POPUP_PROFILE_IMAGE)

  const updateOnboardingPopupProfileImage = useODMutation<
    GQLUpdateOnboardingPopupProfileImageInput,
    GQLOnboardingPopupProfileImage
  >(GQL_UPDATE_ONBOARDING_POPUP_PROFILE_IMAGE)

  const listOnboardingPopupProfileImage = useODQuery<
    Partial<GQLListableOnboardingPopupProfileImageInput>,
    GQLListableOnboardingPopupProfileImage
  >(GQL_LIST_ONBOARDING_POPUP_PROFILE_IMAGE)

  const deleteOnboardingPopupProfileImage = useODMutation<
    Partial<SingleIDInput>,
    GQLOkResponse
  >(GQL_DELETE_ONBOARDING_POPUP_PROFILE_IMAGE)

  const createChapterOption = useODMutation<
    GQLChapterOptionCreationInput,
    GQLChapterOption
  >(GQL_CREATE_CHAPTER_OPTION)

  const getChapterOption = useODQuery<SingleIDInput, GQLChapterOption>(
    GQL_GET_CHAPTER_OPTION
  )

  const updateChapterOption = useODMutation<
    GQLChapterOptionUpdateInput,
    GQLChapterOption
  >(GQL_UPDATE_CHAPTER_OPTION)

  const listChapterOption = useODQuery<
    Partial<GQLListableChapterOptionInput>,
    GQLChapterOption
  >(GQL_LIST_CHAPTER_OPTION)

  const deleteChapterOption = useODMutation<
    Partial<SingleIDInput>,
    GQLOkResponse
  >(GQL_DELETE_CHAPTER_OPTION)

  const getImportStudioGoogleInfo = useODQuery<
    undefined,
    GQLGetImportStudioGoogleInfoOutput
  >(GQL_GET_IMPORT_GOOGLE_INFO)

  const listDirectAds = useODQuery<
    Partial<GQLListableDirectAdsInput>,
    GQLListableDirectAds
  >(GQL_LIST_DIRECT_ADS_BY_ADMIN)

  const listAppSplashImage = useODQuery<
    Partial<GQLListableAppSplashImageInput>,
    GQLListableAppSplashImage
  >(GQL_LIST_APP_SPLASH_IMAGE)

  const listRecommendationForFirstUser = useODQuery<
    Partial<GQLListableRecommendationForFirstUserInput>,
    GQLListableRecommendationForFirstUser
  >(GQL_LIST_RECOMMENDATION_FOR_FIRST_USER)

  const listRecommendSectionStory = useODQuery<
    Partial<GQLListableRecommendSectionStoryInput>,
    GQLListableRecommendSectionStory
  >(GQL_LIST_RECOMMEND_SECTION_STORY)

  const deleteRecommendSectionStory = useODMutation<
    Partial<SingleIDInput>,
    GQLOkResponse
  >(GQL_DELETE_RECOMMEND_SECTION_STORY)

  const getRecommendSectionStory = useODQuery<
    Partial<SingleIDInput>,
    GQLRecommendSectionStory
  >(GQL_GET_RECOMMEND_SECTION_STORY)

  const createRecommendSectionStory = useODMutation<
    Partial<GQLRecommendSectionStoryCreationInput>,
    GQLRecommendSectionStory
  >(GQL_CREATE_RECOMMEND_SECTION_STORY)

  const listStoryHasRecommendSection = useODQuery<
    Partial<GQLListableRecommendSectionStoryInput>,
    GQLListableStoryHasRecommendSection
  >(GQL_LIST_STORY_HAS_RECOMMEND_SECTION)

  const deleteStoryHasRecommendSection = useODMutation<
    Partial<SingleIDInput>,
    GQLOkResponse
  >(GQL_DELETE_STORY_HAS_RECOMMEND_SECTION)

  const getStoryHasRecommendSection = useODQuery<
    Partial<SingleIDInput>,
    GQLStoryHasRecommendSection
  >(GQL_GET_STORY_HAS_RECOMMEND_SECTION)

  const createStoryHasRecommendSection = useODMutation<
    Partial<GQLStoryHasRecommendSectionCreationInput>,
    GQLStoryHasRecommendSection
  >(GQL_CREATE_STORY_HAS_RECOMMEND_SECTION)

  const updateStoryHasRecommendSection = useODMutation<
    Partial<GQLStoryHasRecommendSectionUpdateInput>,
    GQLStoryHasRecommendSection
  >(GQL_UPDATE_STORY_HAS_RECOMMEND_SECTION)

  const listStorygameCharacterPrompt = useODQuery<
    Partial<GQLListableStorygameCharacterPromptInput>,
    GQLListableStorygameCharacterPrompt
  >(GQL_LIST_STORYGAME_CHARACTER_PROMPT)

  const createStorygameCharacterPrompt = useODMutation<
    GQLStorygameCharacterPromptPropsInput,
    GQLStorygameCharacterPrompt
  >(GQL_CREATE_STORYGAME_CHARACTER_PROMPT)

  const getStorygameCharacterPrompt = useODQuery<
    GQLSingleIDInput,
    GQLStorygameCharacterPrompt
  >(GQL_GET_STORYGAME_CHARACTER_PROMPT)

  const updateStorygameCharacterPrompt = useODMutation<
    GQLStorygameCharacterPromptPropsInput,
    GQLStorygameCharacterPrompt
  >(GQL_UPDATE_STORYGAME_CHARACTER_PROMPT)

  const listStorygameCharacterAdditionalPrompt = useODQuery<
    GQLListableStorygameCharacterAdditionalPromptInput,
    GQLStorygameCharacterAdditionalPrompt
  >(GQL_LIST_STORYGAME_CHARACTER_ADDITIONAL_PROMPT)

  const createStorygameCharacterAdditionalPrompt = useODMutation<
    GQLStorygameCharacterAdditionalPromptPropsInput,
    GQLStorygameCharacterAdditionalPrompt
  >(GQL_CREATE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT)

  const getStorygameCharacterAdditionalPrompt = useODQuery<
    GQLSingleIDInput,
    GQLStorygameCharacterAdditionalPrompt
  >(GQL_GET_STORYGAME_CHARACTER_ADDITIONAL_PROMPT)

  const updateStorygameCharacterAdditionalPrompt = useODMutation<
    GQLStorygameCharacterAdditionalPromptPropsInput,
    GQLStorygameCharacterAdditionalPrompt
  >(GQL_UPDATE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT)

  const deleteStorygameCharacterAdditionalPrompt = useODMutation<
    GQLSingleIDInput,
    GQLOkResponse
  >(GQL_DELETE_STORYGAME_CHARACTER_ADDITIONAL_PROMPT)

  return {
    apiCreateStory,
    apiUpdateStory,
    apiGetStory,
    apiRemoveStory,

    updateUserByAdmin,

    createChapterForStudio,
    updateChapter,
    changeChapterFree,
    publishChapter,
    listChapterOfStory,
    changeChapterFreeSetting,

    updateChr,
    listChrOfStory,

    listChoiceOfStory,
    getChoice,
    updateChoice,

    listBackgroundOfStory,

    listEndingOfStory,

    listStoryPropertyOfStory,

    createEndingShowsProperty,
    listEndingShowsPropertyOfStory,

    listPreviewEndingOfStory,

    getUserMetricById,

    listChapterCommentOfStory,
    updateChapterCommentByAdmin,
    getAllChaptersById,

    listUserReportChapterComment,

    listStoryItem,

    listEndingCardByAdmin,
    createEndingCard,
    updateEndingCard,
    deleteEndingCard,
    getEndingCard,

    listOnboardingPopup,
    getOnboardingPopup,
    createOnboardingPopup,
    updateOnboardingPopup,
    deleteOnboardingPopup,
    listOnboardingPopupChoice,
    getOnboardingPopupChoice,
    createOnboardingPopupChoice,
    updateOnboardingPopupChoice,
    deleteOnboardingPopupChoice,

    listNotice,

    addUserCoin,

    listColor,
    createColor,

    listIpSourcing,

    apiListCharacters,
    apiApplyMgmtSheetToStory,

    listUserLedgerByAdmin,

    listFeatured,
    listStoryForFeatured,

    listHomeSection,
    getPlayerClass,
    createPlayerClass,
    updatePlayerClass,
    listPlayerClass,

    getChoiceFromPlayerClass,
    createChoiceFromPlayerClass,
    updateChoiceFromPlayerClass,
    listChoiceFromPlayerClass,

    listTtsSpeaker,
    getTtsUrl,

    listUserSelectedChoice,
    getAffection,

    createOnboardingPopupProfileImage,
    getOnboardingPopupProfileImage,
    updateOnboardingPopupProfileImage,
    listOnboardingPopupProfileImage,
    deleteOnboardingPopupProfileImage,

    createChapterOption,
    updateChapterOption,
    getChapterOption,
    listChapterOption,
    deleteChapterOption,

    getImportStudioGoogleInfo,
    listDirectAds,
    listAppSplashImage,

    listRecommendationForFirstUser,

    listRecommendSectionStory,
    deleteRecommendSectionStory,
    getRecommendSectionStory,
    createRecommendSectionStory,

    listStoryHasRecommendSection,
    deleteStoryHasRecommendSection,
    getStoryHasRecommendSection,
    createStoryHasRecommendSection,
    updateStoryHasRecommendSection,

    listStorygameCharacterPrompt,
    createStorygameCharacterPrompt,
    getStorygameCharacterPrompt,
    updateStorygameCharacterPrompt,

    listStorygameCharacterAdditionalPrompt,
    createStorygameCharacterAdditionalPrompt,
    getStorygameCharacterAdditionalPrompt,
    updateStorygameCharacterAdditionalPrompt,
    deleteStorygameCharacterAdditionalPrompt,
  }
}
