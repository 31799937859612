"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTChoice = exports.makeChoiceUserInputImageDefaultValue = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../../changeOp");
const models_1 = require("../../../../../models");
const scripter_1 = require("../../../../../scripter/scripter");
const IStudioClipboard_1 = require("../../../clipboard/IStudioClipboard");
const interface_1 = require("../../../interface");
const ALImageWithInputArea_1 = require("../../../modal/ALImageWithInputArea");
const validation_1 = require("../../../validation");
const fields_1 = require("../../fields");
const SelectionFieldWithUI_1 = require("../../fields/SelectionFieldWithUI");
const UserInputChoiceImageField_1 = require("../../fields/UserInputChoiceImageField");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
const makeChoiceUserInputImageDefaultValue = () => {
    return {
        operation: models_1.ImageOperationType.UserInputChoiceOverlay,
        width: 0,
        height: 0,
        imageUrl: '',
        leftTop: [0, 0],
        rightTop: [0, 0],
        leftBottom: [0, 0],
        rightBottom: [0, 0],
        align: 'center',
    };
};
exports.makeChoiceUserInputImageDefaultValue = makeChoiceUserInputImageDefaultValue;
const makeDefaultChoiceTimeout = (st) => {
    var _a, _b, _c, _d, _e;
    if (!!((_a = st === null || st === void 0 ? void 0 : st.options) === null || _a === void 0 ? void 0 : _a.timeout) && !isNaN(Number((_b = st === null || st === void 0 ? void 0 : st.options) === null || _b === void 0 ? void 0 : _b.timeout))) {
        return st.options.timeout;
    }
    if (((_c = st === null || st === void 0 ? void 0 : st.options) === null || _c === void 0 ? void 0 : _c.layoutType) === models_1.ChoiceLayoutType.Statistics ||
        ((_d = st === null || st === void 0 ? void 0 : st.options) === null || _d === void 0 ? void 0 : _d.layoutType) === models_1.ChoiceLayoutType.UserInputChoice ||
        !!((_e = st === null || st === void 0 ? void 0 : st.options) === null || _e === void 0 ? void 0 : _e.showStatistics)) {
        return -1;
    }
    return 10000;
};
class DOSTChoice extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: models_1.STATEMENT_TYPE.Choice,
            choices: ['선택지1', '선택지2'],
            background: '',
            choiceName: block.store.rootStore.di.generateCustomChoiceId(),
        }, block, uniqueId);
        // 이미지 입력형 일때만 -> 총 5개 가능하므로 5개를 미리 만들어두는 방식을 사용한다.
        // choiceImageInputs: StudioFileFieldWithUI[]
        // choiceImagesUploaded: string[] = range(0, NUM_MAX_CHOICES).map(() => '')
        // choiceBackgroundImage 가 editing 상태인지
        this.isEditingImage = false;
        this.timeoutField = new fields_1.NumberFieldWithUI('timeout', () => {
            return makeDefaultChoiceTimeout(this.st);
        }, value => {
            (0, mobx_1.runInAction)(() => {
                var _a;
                if (!!this.st.options &&
                    ((_a = this.st.options) === null || _a === void 0 ? void 0 : _a.layoutType) !== models_1.ChoiceLayoutType.UserInputChoice) {
                    this.st.options.timeout = value;
                }
            });
        });
        this.layoutField = new SelectionFieldWithUI_1.SelectionFieldWithUI('layoutType', () => this.layoutType, value => {
            this.generateDefaultOptions(value);
        }, {
            onChange: value => {
                ;
                this.block.getMySubBlock(this)._updateChoiceFieldRows();
            },
            selection: [
                {
                    name: trans('legacy_DOSTChoice_text_type'),
                    value: models_1.ChoiceLayoutType.Default,
                },
                {
                    name: trans('legacy_DOSTChoice_user_input_type'),
                    value: models_1.ChoiceLayoutType.UserInputChoice,
                },
                {
                    name: trans('legacy_DOSTChoice_image_type'),
                    value: models_1.ChoiceLayoutType.ImageChoice,
                },
            ],
        });
        this.showStatisticsField = new SelectionFieldWithUI_1.SelectionFieldWithUI('showStatistics', () => {
            var _a, _b, _c;
            if (((_a = this.st.options) === null || _a === void 0 ? void 0 : _a.layoutType) === models_1.ChoiceLayoutType.Statistics) {
                return true; // Statistics 을 선택할 순 없지만 기존 코드와의 호환성을 위해, 해당 타입이면 true 로 해둔다.
            }
            else {
                return (_c = (_b = this.st.options) === null || _b === void 0 ? void 0 : _b.showStatistics) !== null && _c !== void 0 ? _c : false;
            }
        }, value => {
            (0, mobx_1.runInAction)(() => {
                this.actionAfterDefineOptions(options => {
                    if (
                    // Statistics 타입은 현재 선택할 순 없지만, 타입을 걸러내기 위해서 return 처리
                    options.layoutType === models_1.ChoiceLayoutType.Statistics) {
                        return;
                    }
                    options.showStatistics = value;
                });
            });
        }, {
            selection: [
                { name: 'true', value: true },
                { name: 'false', value: false },
            ],
        });
        this.displayNameField = new fields_1.TextFieldWithUI('displayName', () => this.displayName, value => {
            this.actionAfterDefineOptions(options => {
                (0, mobx_1.runInAction)(() => {
                    options.displayName = value;
                });
            });
        }, { options: { noLabel: true } });
        this.leftUserInputTextField = new fields_1.TextFieldWithUI('leftUserInputTextField', () => {
            var _a, _b;
            return ((_a = this.st.options) === null || _a === void 0 ? void 0 : _a.layoutType) === models_1.ChoiceLayoutType.UserInputChoice
                ? (_b = this.st.options.leftUserInputText) !== null && _b !== void 0 ? _b : ''
                : '';
        }, value => {
            (0, mobx_1.runInAction)(() => {
                this.actionAfterDefineOptions(options => {
                    if (options.layoutType === models_1.ChoiceLayoutType.UserInputChoice) {
                        options.leftUserInputText = value;
                    }
                });
            });
        }, {
            options: { noLabel: false },
            label: trans('legacy_DOSTChoice_opponent_dialogue'),
        });
        this.leftUserInputTextField.input.setRequired(true);
        this.choiceUserInputImage = new UserInputChoiceImageField_1.UserInputChoiceImageField('userInputChoiceImage', () => {
            var _a, _b;
            if (((_a = this.st.options) === null || _a === void 0 ? void 0 : _a.layoutType) === models_1.ChoiceLayoutType.UserInputChoice) {
                return ((_b = this.st.options.choiceBackgroundImage) !== null && _b !== void 0 ? _b : (0, exports.makeChoiceUserInputImageDefaultValue)());
            }
            return (0, exports.makeChoiceUserInputImageDefaultValue)();
        }, value => {
            (0, mobx_1.runInAction)(() => {
                this.actionAfterDefineOptions(options => {
                    if (options.layoutType === models_1.ChoiceLayoutType.UserInputChoice) {
                        options.choiceBackgroundImage = value;
                    }
                });
            });
        });
        // 데이터에 options 가 존재하지 않는 경우에 options 를 추가해줌으로써
        // 언제나 일관된 형상의 데이터를 갖고 있도록 한다. (TC 에 영향을 줌)
        if (!this.st.options) {
            this.generateDefaultOptions(this.layoutField.value);
        }
        (0, mobx_1.makeObservable)(this, {
            isEditingImage: mobx_1.observable,
            // choiceImagesUploaded: observable,
            //
            showStatisticsInEditing: mobx_1.computed,
            isUserInputLayoutInEditing: mobx_1.computed,
        });
    }
    get editorFields() {
        const res = [
            ...super.editorFields,
            // 아래의 순서대로 setter 가 실행되기 때문에 순서에 유의해야 할 경우는 주의 필요
            this.layoutField,
            this.showStatisticsField,
            this.displayNameField,
            this.leftUserInputTextField,
            this.choiceUserInputImage,
        ];
        if (this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.DIRECTION)) {
            res.push(this.timeoutField);
        }
        return res;
    }
    get st() {
        return this.data;
    }
    get choiceName() {
        return this.st.choiceName;
    }
    get layoutType() {
        var _a, _b;
        return (_b = (_a = this.st.options) === null || _a === void 0 ? void 0 : _a.layoutType) !== null && _b !== void 0 ? _b : models_1.ChoiceLayoutType.Default;
    }
    get displayName() {
        var _a, _b;
        return (_b = (_a = this.st.options) === null || _a === void 0 ? void 0 : _a.displayName) !== null && _b !== void 0 ? _b : '';
    }
    get showStatisticsInEditing() {
        var _a;
        // noinspection PointlessBooleanExpressionJS
        return (((_a = this.layoutField) === null || _a === void 0 ? void 0 : _a.value) === models_1.ChoiceLayoutType.Statistics ||
            !!this.showStatisticsField.value);
    }
    get isUserInputLayoutInEditing() {
        var _a;
        return ((_a = this.layoutField) === null || _a === void 0 ? void 0 : _a.value) === models_1.ChoiceLayoutType.UserInputChoice;
    }
    getChoiceAt(index) {
        return this.st.choices[index];
    }
    setChoiceName(index, name) {
        this.block.parentChapter
            .applyChangesOnChapter(changeOp_1.StudioChangeOpFactory.changeChoiceText(this, name, index))
            .catch();
    }
    addChoice(choice) {
        (0, mobx_1.runInAction)(() => {
            this.st.choices.push(choice);
        });
    }
    removeChoice(index) {
        (0, mobx_1.runInAction)(() => {
            this.st.choices.splice(index, 1);
        });
    }
    setImageForChoice(index, link) {
        (0, mobx_1.runInAction)(() => {
            ;
            this.st.options.choiceImageUrls[index] = link;
        });
    }
    getImageForChoice(index) {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.st.options) === null || _a === void 0 ? void 0 : _a.choiceImageUrls) === null || _b === void 0 ? void 0 : _b[index]) !== null && _c !== void 0 ? _c : '');
    }
    generateSheetColumnsAfterBackground() {
        // [선택지 이름, 선택지1, 2, ...]
        return [this.choiceName, ...this.st.choices];
    }
    generateLines() {
        const lines = super.generateLines();
        const generatedOptions = (() => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if (((_a = this.st.options) === null || _a === void 0 ? void 0 : _a.layoutType) === models_1.ChoiceLayoutType.Default) {
                return JSON.stringify({
                    layoutType: this.layoutType,
                    displayName: this.st.options.showStatistics
                        ? this.displayName
                        : undefined,
                    showStatistics: this.st.options.showStatistics,
                    timeout: (_b = this.st.options.timeout) !== null && _b !== void 0 ? _b : makeDefaultChoiceTimeout(this.st),
                });
            }
            if (((_c = this.st.options) === null || _c === void 0 ? void 0 : _c.layoutType) === models_1.ChoiceLayoutType.ImageChoice) {
                return JSON.stringify({
                    layoutType: this.layoutType,
                    displayName: this.st.options.showStatistics
                        ? this.displayName
                        : undefined,
                    showStatistics: this.st.options.showStatistics,
                    choiceImageUrls: this.st.choices.map((_, i) => this.st.options.choiceImageUrls[i]),
                    timeout: (_d = this.st.options.timeout) !== null && _d !== void 0 ? _d : makeDefaultChoiceTimeout(this.st),
                });
            }
            if (((_e = this.st.options) === null || _e === void 0 ? void 0 : _e.layoutType) === models_1.ChoiceLayoutType.UserInputChoice) {
                return JSON.stringify({
                    layoutType: this.layoutType,
                    displayName: this.st.options.showStatistics
                        ? this.displayName
                        : undefined,
                    showStatistics: this.st.options.showStatistics,
                    leftUserInputText: this.st.options.leftUserInputText,
                    choiceBackgroundImage: !!((_f = this.st.options.choiceBackgroundImage) === null || _f === void 0 ? void 0 : _f.imageUrl)
                        ? this.st.options.choiceBackgroundImage
                        : undefined,
                });
            }
            if (this.layoutType === models_1.ChoiceLayoutType.Statistics) {
                return JSON.stringify({
                    layoutType: this.layoutType,
                    displayName: this.displayName,
                    timeout: (_h = (_g = this.st.options) === null || _g === void 0 ? void 0 : _g.timeout) !== null && _h !== void 0 ? _h : makeDefaultChoiceTimeout(this.st),
                    // showStatistics: true, // interface 에 없음.
                });
            }
            // 위의 어떤 타입도 해당이 안된다면 기존과 같이 동작되도록 null 처리하여, 위에서 반환된 lines 를 그대로 반환하도록 처리
            return null;
        })();
        // 선택지 옵션 구문을 추가해준다.
        if (!!generatedOptions) {
            lines.push({
                columns: [
                    ...this.block.blockColumns(),
                    this.background,
                    scripter_1.INVERTED_STATEMENT_TYPE_MAP[models_1.STATEMENT_TYPE.ChoiceOption],
                    this.choiceName,
                    generatedOptions,
                ],
                errors: [],
            });
        }
        return lines;
    }
    async applyChangeOp(op, type) {
        switch (op.opType) {
            case changeOp_1.StudioChangeOpType.ChangeChoiceText:
                return this.changeChoiceTextInternal(op.choiceIndex, op.choiceName);
            case changeOp_1.StudioChangeOpType.ChangeLineName:
                return this.changeLineNameInternal(op.name);
            default:
                break;
        }
        return super.applyChangeOp(op, type);
    }
    changeLineNameInternal(name) {
        const prev = this.choiceName;
        (0, mobx_1.runInAction)(() => {
            this.st.choiceName = name;
        });
        return {
            reverse: changeOp_1.StudioChangeOpFactory.changeLineName(this.block.uniqueId, this.uniqueId, prev),
            lineToFocus: this.block.getMySubBlock(this),
        };
    }
    changeChoiceTextInternal(index, text) {
        if (this.st.choices.length < index) {
            return null;
        }
        const prev = this.st.choices[index];
        (0, mobx_1.runInAction)(() => {
            this.st.choices[index] = text;
        });
        return {
            reverse: changeOp_1.StudioChangeOpFactory.changeChoiceText(this, prev, index),
            lineToFocus: this,
        };
    }
    onCopyEvent() {
        const data = (0, lodash_1.cloneDeep)(this.data);
        data.choiceName = this.block.store.rootStore.di.generateCustomChoiceId();
        return {
            isStudio: true,
            dataType: IStudioClipboard_1.StudioClipboardDataType.Statements,
            lines: [data],
        };
    }
    onCreateUserInputImage() {
        this.openImageWithInputAreaModal(null);
    }
    onChangeUserInputImage() {
        var _a;
        this.openImageWithInputAreaModal((_a = this.choiceUserInputImage.value) !== null && _a !== void 0 ? _a : null);
    }
    openImageWithInputAreaModal(existingData) {
        (0, mobx_1.runInAction)(() => (this.isEditingImage = true));
        this.rootStore.autoLayoutManager.addActionChain(new ALImageWithInputArea_1.ALImageWithInputArea(existingData, data => {
            (0, mobx_1.runInAction)(() => {
                this.choiceUserInputImage.onChange(data);
            });
        }, () => {
            (0, mobx_1.runInAction)(() => (this.isEditingImage = false));
        }).buildActionChain());
    }
    // Field 의 setter 함수에서 사용하기 위함
    // callback 의 param 을 통해 options 의 타입을 보장해주기 위함
    actionAfterDefineOptions(callback) {
        // 해당 setter 가 실행될 때 options 은 있을 것이다.
        // 하지만, 기존 데이터에서 layoutField 의 초기값에서 변화가 없으면 처음엔 options 가 생성되지 않을 것이니 options 를 생성해준다.
        if (!this.st.options) {
            this.generateDefaultOptions(this.layoutField.value);
        }
        // layoutField 의 setter 혹은 위의 코드에서 default options 를 생성해주기 때문에 있을 것이지만,
        // 타입 체크 때문에 return 처리
        if (!this.st.options) {
            return;
        }
        callback(this.st.options);
    }
    validate() {
        var _a;
        const results = super.validate();
        if (this.st.options) {
            const layoutType = this.st.options.layoutType;
            const hasNoDisPlayNameResult = {
                type: validation_1.StudioValidationType.StatsChoiceHasNoDisplayName,
                extra: {},
                source: this,
                severity: validation_1.StudioValidationSeverity.Error,
                stack: [],
            };
            if (layoutType === models_1.ChoiceLayoutType.Statistics) {
                if (!this.st.options.displayName) {
                    results.push(hasNoDisPlayNameResult);
                }
            }
            else {
                if (!!this.st.options.showStatistics && !this.st.options.displayName) {
                    results.push(hasNoDisPlayNameResult);
                }
            }
            if (layoutType === models_1.ChoiceLayoutType.UserInputChoice) {
                if (!((_a = this.st.options) === null || _a === void 0 ? void 0 : _a.leftUserInputText.trim())) {
                    results.push({
                        type: validation_1.StudioValidationType.UserInputChoiceNoLeftText,
                        extra: {},
                        source: this,
                        severity: validation_1.StudioValidationSeverity.Error,
                        stack: [],
                    });
                }
            }
            else if (layoutType === models_1.ChoiceLayoutType.ImageChoice) {
                this.st.choices.forEach((_, index) => {
                    const link = this.st.options.choiceImageUrls[index];
                    if (link.trim() === '') {
                        results.push({
                            type: validation_1.StudioValidationType.ImageChoiceNoImage,
                            extra: { index },
                            source: this,
                            severity: validation_1.StudioValidationSeverity.Error,
                            stack: [],
                        });
                    }
                });
            }
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    generateDefaultOptions(layoutType) {
        switch (layoutType) {
            case models_1.ChoiceLayoutType.ImageChoice: {
                (0, mobx_1.runInAction)(() => {
                    this.st.options = {
                        layoutType: models_1.ChoiceLayoutType.ImageChoice,
                        showStatistics: this.showStatisticsField.value,
                        displayName: this.displayNameField.value,
                        choiceImageUrls: (0, lodash_1.range)(1, models_1.NUM_MAX_CHOICES).map(() => ''),
                        timeout: this.timeoutField.value,
                    };
                });
                return;
            }
            case models_1.ChoiceLayoutType.UserInputChoice: {
                (0, mobx_1.runInAction)(() => {
                    this.st.options = {
                        // IUserInputChoiceOptions
                        layoutType: models_1.ChoiceLayoutType.UserInputChoice,
                        showStatistics: this.showStatisticsField.value,
                        leftUserInputText: this.leftUserInputTextField.value,
                        choiceBackgroundImage: this.choiceUserInputImage.value,
                        displayName: this.displayNameField.value,
                    };
                });
                return;
            }
            case models_1.ChoiceLayoutType.Default: {
                (0, mobx_1.runInAction)(() => {
                    this.st.options = {
                        // IDefaultChoiceOptions
                        layoutType: models_1.ChoiceLayoutType.Default,
                        showStatistics: this.showStatisticsField.value,
                        displayName: this.displayNameField.value,
                        timeout: this.timeoutField.value,
                    };
                });
                return;
            }
        }
    }
}
exports.DOSTChoice = DOSTChoice;
