"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOHeroChr = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const DOMainChrOnboardingPopupEditor_1 = require("../onboardingPopup/DOMainChrOnboardingPopupEditor");
const IDOChr_1 = require("./IDOChr");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
const SP_DEFAULT_NAME = '주인공';
class DOHeroChr {
    constructor(store, storyId, imageFile, portraitFile, defaultName) {
        var _a, _b;
        this.color = '';
        this.imageFile = null;
        this.imageLink = null;
        this.isHero = true;
        this.isMain = true;
        this.name = trans('legacy_DOHeroChr_hero'); // name: string = 주인공
        this.displayName = trans('legacy_DOHeroChr_hero'); // displayName: string = '주인공'
        this.onboardingPopups = []; // onboardingPopups: IDOOnboardingPopup[] = []
        // 메인캐릭터는 default 를 없는 것으로 (일단) 처리한다.
        this.defaultFirstName = '';
        this.defaultLastName = '';
        this.onboardingEditor = null;
        this.id = IDOChr_1.HERO_CHR_ID;
        this.store = store;
        this.imageFile = imageFile;
        this.defaultFirstName = (_a = this.store.story.defaultFirstName) !== null && _a !== void 0 ? _a : '';
        this.defaultLastName = (_b = this.store.story.defaultLastName) !== null && _b !== void 0 ? _b : '';
        this.name = defaultName !== null && defaultName !== void 0 ? defaultName : SP_DEFAULT_NAME;
        this.displayName = defaultName !== null && defaultName !== void 0 ? defaultName : SP_DEFAULT_NAME;
        this.portraitFile = portraitFile;
        (0, mobx_1.makeObservable)(this, {
            onboardingEditor: mobx_1.observable,
            imageFile: mobx_1.observable,
            link: mobx_1.computed,
        });
    }
    get link() {
        var _a, _b;
        return (_b = (_a = this.imageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null;
    }
    get chrName() {
        return this.name;
    }
    setImageFile(imageFile) {
        (0, mobx_1.runInAction)(() => (this.imageFile = imageFile));
    }
    async updateImage(image) {
        var _a;
        const server = this.store.rootStore.di.server;
        await server.updateEntityForStudio(this.store.story, {
            mainCharacterImageStudioFileId: (_a = image === null || image === void 0 ? void 0 : image.fileId) !== null && _a !== void 0 ? _a : null,
        });
        (0, mobx_1.runInAction)(() => (this.imageFile = image));
    }
    exportMetaDataUpdateActions() {
        var _a, _b;
        return [
            {
                action: core_1.MetaDataAction.ChrSetProperties,
                scope: (0, core_1.genScopeString)(null),
                chrName: this.name,
                isMain: this.isMain,
                color: this.color,
                link: (_a = this.link) !== null && _a !== void 0 ? _a : '',
                hash: (_b = this.link) !== null && _b !== void 0 ? _b : '',
                isHero: true,
            },
        ];
    }
    removeOnboardingPopup(id) {
        return;
    }
    async update(data) {
        const server = this.store.rootStore.di.server;
        const res = await server.updateEntityForStudio(this.store.story, {
            ...data,
        });
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.defaultLastName = (_a = res.defaultLastName) !== null && _a !== void 0 ? _a : '';
            this.defaultFirstName = (_b = res.defaultFirstName) !== null && _b !== void 0 ? _b : '';
            this.imageFile = res.mainCharacterImageFile;
            this.portraitFile = res.mainCharacterPortraitFile;
        });
    }
    async initOnboardingEditor(editor) {
        const onboarding = await this.store.rootStore.di.server.getMainChrOnboardingPopup({
            id: this.store.story.storyId,
        });
        (0, mobx_1.runInAction)(() => {
            this.onboardingEditor = new DOMainChrOnboardingPopupEditor_1.DOMainChrOnboardingPopupEditor(editor, onboarding);
        });
    }
    shouldBeFilteredBy(keyword) {
        return this.chrName.toLowerCase().includes(keyword);
    }
}
exports.DOHeroChr = DOHeroChr;
