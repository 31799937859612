"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOBaseScriptStatement = void 0;
const core_1 = require("@storyplay/core");
// noinspection NonAsciiCharacters
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../changeOp");
const consts_1 = require("../../../../consts");
const parser_1 = require("../../../../playData/formula/parser");
const scripter_1 = require("../../../../scripter/scripter");
const IStudioClipboard_1 = require("../../clipboard/IStudioClipboard");
const interface_1 = require("../../interface");
const fields_1 = require("../fields");
const DOStatementBase_1 = require("./DOStatementBase");
const IDOStatement_1 = require("./IDOStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
/**
 * Statement domain object.
 */
class DOBaseScriptStatement extends DOStatementBase_1.DOStatementBase {
    constructor(data, block, uniqueId) {
        super(block, uniqueId !== null && uniqueId !== void 0 ? uniqueId : `${data.statementType}_${block.store.rootStore.di.generateInternalHashId()}`);
        this.lineType = IDOStatement_1.UILineType.SingleLineScript;
        // 이 문장이 개별적으로 copy 를 지원하는가?
        this.canBeCopied = true;
        this.data = data;
        this.sourceLine = data.sourceLine;
        this.statementType = data.statementType;
        this.executeConditionField = new fields_1.TextFieldWithUI(`executable_condition_field_${data.sourceLine}`, 
        // 문장 실행 조건을 지원하지 않는 STATEMENT 또는 지원해도 값이 falsy 한 경우에는
        // 빈 스트링 값을 반환합니다.
        () => {
            const asExecuteCondition = this
                .data;
            if (!this.doesExecuteConditionSupport ||
                !asExecuteCondition.executeCondition) {
                return '';
            }
            return this.rootStore.textStore.encodeSPExprFormulaAfterParse(asExecuteCondition.executeCondition);
        }, 
        // 문장 실행 조건을 지원하지 않는 경우 setter 가 실행되지 않습니다.
        v => {
            if (!this.doesExecuteConditionSupport) {
                return;
            }
            (0, mobx_1.runInAction)(() => {
                // falsy 한 값이 들어왔을 때는 JSON.stringify 를 해줄 필요는 없으므로,
                // 빈 스트링 값을 할당해줍니다. 추후 엑셀에 내보낼때도 빈 스트링값으로 내보내지므로
                if (!v) {
                    ;
                    this.data.executeCondition = undefined;
                    return;
                }
                ;
                this.data.executeCondition =
                    JSON.stringify(this.rootStore.textStore.parseFormulaWithFallback(v));
            });
        }, {
            placeholder: trans('legacy_DOBaseScriptStatement_enter_advanced_condition'),
        });
        (0, mobx_1.makeObservable)(this, {
            sourceLine: mobx_1.observable,
            statementType: mobx_1.observable,
            data: mobx_1.observable,
            id: mobx_1.computed,
            blocksTo: mobx_1.computed,
            hasExecuteCondition: mobx_1.computed,
            doesExecuteConditionSupport: mobx_1.computed,
            hasSameExecuteConditionWithPrevStatement: mobx_1.computed,
            hasSameExecuteConditionWithNextStatement: mobx_1.computed,
            executeConditionOfStatement: mobx_1.computed,
            returnSetExecuteConditionModalOpen: mobx_1.computed,
        });
    }
    // merge(data: STATEMENT) {
    //   runInAction(() => {
    //     assignIf(data, 'sourceLine', v => (this.sourceLine = v.sourceLine))
    //     assignIf(
    //       data,
    //       'statementType',
    //       v => (this.statementType = v.statementType)
    //     )
    //   })
    //   return this
    // }
    get id() {
        return this.sourceLine;
    }
    get blocksTo() {
        // override me.
        return [];
    }
    get parentBlock() {
        return this.block;
    }
    set parentBlock(newBlock) {
        (0, mobx_1.runInAction)(() => (this.block = newBlock));
    }
    get editorFields() {
        return [...super.editorFields, this.executeConditionField];
    }
    fixStatements() {
        //
    }
    get cmdString() {
        return `[ST] ${this.statementType} ${JSON.stringify(this.data)}`;
    }
    get validatorName() {
        return `[ST:${this.sourceLine}]`;
    }
    generateSheetColumnsAfterBackground() {
        throw new Error(`generateSheetColumnsAfterBackground() no implement : ${this.statementType}, ${this.constructor.name}`);
        // return ['WARNING: 미구현']
    }
    get scriptMessageType() {
        return scripter_1.INVERTED_STATEMENT_TYPE_MAP[this.statementType];
    }
    generateLines() {
        const errors = [];
        const messageType = this.scriptMessageType;
        if (!messageType) {
            errors.push(`${trans('legacy_DOBaseScriptStatement_unknown_statement')} : ${this.statementType}`);
        }
        const background = this.background;
        if (!background) {
            errors.push(trans('legacy_DOBaseScriptStatement_no_background'));
        }
        let columns = [];
        try {
            columns = this.generateSheetColumnsAfterBackground();
        }
        catch (ex) {
            errors.push(ex.message);
        }
        return [
            {
                columns: [
                    ...this.block.blockColumns(),
                    background,
                    messageType,
                    ...columns,
                ],
                errors,
            },
        ];
    }
    exportMetaDataUpdateActions() {
        return [];
    }
    onBlockRemoved(blockId, changeOp) {
        // does nothing. override when necessary.
    }
    async applyChangeOp(op, type) {
        if (op.opType === changeOp_1.StudioChangeOpType.ReplaceData) {
            return this.replaceData(op.data);
        }
        return super.applyChangeOp(op, type);
    }
    /**
     * raw data 통째로 변경할 때 사용한다.
     */
    replaceData(data) {
        const original = (0, lodash_1.cloneDeep)(this.data);
        (0, mobx_1.runInAction)(() => (this.data = data));
        return {
            reverse: changeOp_1.StudioChangeOpFactory.replaceData(this, original),
            lineToFocus: this,
        };
    }
    get key() {
        return this.data.sourceLine;
    }
    onCopyEvent() {
        return this.canBeCopied
            ? {
                isStudio: true,
                dataType: IStudioClipboard_1.StudioClipboardDataType.Statements,
                lines: [this.data],
            }
            : null;
    }
    onCutEvent() {
        var _a, _b;
        if (this.canBeCopied) {
            const data = this.onCopyEvent();
            (_b = (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.lineBlockEditing) === null || _b === void 0 ? void 0 : _b.removeLine(this);
            return data;
        }
        return null;
    }
    get snapshot() {
        return {
            uniqueId: this.uniqueId,
            statementType: this.statementType,
            data: (0, mobx_1.toJS)(this.data),
        };
    }
    /**
     * 내용적으로 주어진 문장 {e}가 {this}와 동일한지 체크한다.
     */
    isSame(e) {
        if (!(e instanceof DOBaseScriptStatement)) {
            return false;
        }
        return (0, lodash_1.isEqual)(this.data, e.data);
    }
    serializeToBundle() {
        return this.snapshot;
    }
    /**
     * 추가적으로 번들에서 무언가 해야할 경우 override 하여 처리.
     */
    onLoadedFromBundle(bundle) {
        //
    }
    get isReadOnly() {
        return !!this.block.story.isReadOnly;
    }
    openDeleteModal() {
        if (!this.blockEditor) {
            return;
        }
        this.blockEditor.setShowDeleteModalFor(this.blockEditor.createDeleteModalInfoForST(this));
    }
    //
    // IStudioFinderSource
    //
    get finderSourceName() {
        return `[ST:${this.sourceLine}]`;
    }
    /**
     * 개별 문장에서 override 하여 구현해야 한다.
     */
    getFindResults(keyword) {
        return [];
    }
    /**
     * 개별 문장에서 override 하여 구현해야 한다.
     */
    replaceTextWith(text, to) {
        //
    }
    selectBySearch() {
        var _a, _b;
        const c = this.parentBlock.store.parentChapter;
        c.setBlockEditing(this.parentBlock, false);
        (_a = c.blockEditor) === null || _a === void 0 ? void 0 : _a.onLineClickedForCursorMove(this);
        (_b = c.blockEditor) === null || _b === void 0 ? void 0 : _b.scrollToCurrentLine(false);
    }
    get doesExecuteConditionSupport() {
        if (!this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.EXECUTE_CONDITION)) {
            return false;
        }
        return IDOStatement_1.SupportedExecuteConditionStatementTypes.includes(this.data.statementType);
    }
    get returnSetExecuteConditionModalOpen() {
        if (!this.doesExecuteConditionSupport) {
            return undefined;
        }
        return () => { var _a; return (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.setShowSetExecuteConditionModal(this); };
    }
    get hasExecuteCondition() {
        return !!this.data.executeCondition;
    }
    validateExecuteConditionValueOfField() {
        if (!this.doesExecuteConditionSupport) {
            return consts_1.SPExprFormulaStatus.Fail;
        }
        if (this.executeConditionField.value.length === 0) {
            return consts_1.SPExprFormulaStatus.None;
        }
        try {
            const res = (0, parser_1.parseSPExprFormula)(this.executeConditionField.value);
            if ((0, lodash_1.isString)(res)) {
                return consts_1.SPExprFormulaStatus.OnlyCondition;
            }
            if ((0, lodash_1.isObject)(res) &&
                !consts_1.PDOperationNamesThatReturnTruthyOrFalsy.includes(res.op)) {
                return consts_1.SPExprFormulaStatus.OnlyCondition;
            }
            return consts_1.SPExprFormulaStatus.Ok;
        }
        catch (ex) {
            return consts_1.SPExprFormulaStatus.Fail;
        }
    }
    get hasSameExecuteConditionWithPrevStatement() {
        return this.hasSameExecuteConditionWithPrevOrNextStatement(true);
    }
    get hasSameExecuteConditionWithNextStatement() {
        return this.hasSameExecuteConditionWithPrevOrNextStatement(false);
    }
    get executeConditionOfStatement() {
        var _a;
        if (this.hasExecuteCondition) {
            return ((_a = this.data.executeCondition) !== null && _a !== void 0 ? _a : undefined);
        }
        return undefined;
    }
    hasSameExecuteConditionWithPrevOrNextStatement(isUpper) {
        if (!this.hasExecuteCondition) {
            return false;
        }
        const statementToCheck = isUpper
            ? this.parentBlock.getStatementBefore(this)
            : this.parentBlock.getStatementAfter(this);
        if (!statementToCheck || !statementToCheck.hasExecuteCondition) {
            return false;
        }
        const valueOfStatementToCheck = statementToCheck.executeConditionOfStatement;
        return valueOfStatementToCheck === this.executeConditionOfStatement;
    }
}
exports.DOBaseScriptStatement = DOBaseScriptStatement;
