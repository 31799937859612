"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.i18nTextTranslationByClass = void 0;
const i18n_1 = require("./i18n");
const i18nTextTranslationByClass = (fileName = 'sp') => {
    const trans = (key, values = {}) => {
        return i18n_1.i18next.t(`${fileName}.${key}`, values);
    };
    return {
        trans,
    };
};
exports.i18nTextTranslationByClass = i18nTextTranslationByClass;
