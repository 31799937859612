"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioStoreHelper = exports.whenT = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../changeOp");
const consts_1 = require("../../../consts");
const DOBlockHelper_1 = require("./DOBlockHelper");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
async function whenT(name, predicate, opts) {
    var _a, _b;
    const inspect = (_a = opts === null || opts === void 0 ? void 0 : opts.inspect) !== null && _a !== void 0 ? _a : (() => '');
    const timeout = (_b = opts === null || opts === void 0 ? void 0 : opts.timeout) !== null && _b !== void 0 ? _b : 1500;
    return new Promise((resolve, reject) => {
        (0, mobx_1.when)(predicate, {
            name,
            timeout,
        }).then(resolve, () => reject(new Error(`[Timeout] ${name}, ${inspect()}`)));
    });
}
exports.whenT = whenT;
/**
 * Studio 의 기능을 테스트하거나, 스크립팅할 때 필요한 기능들을 모아둔 helper class
 */
class StudioStoreHelper {
    constructor(store) {
        this.store = store;
    }
    get story() {
        return this.store.storyEditing;
    }
    get chapter() {
        return this.store.chapterEditing;
    }
    get editor() {
        return this.store.chapterEditing.blockEditor;
    }
    /**
     * editor.addLine() 으로 문장이 추가된 경우 바로 직전에 추가된 라인을 반환한다.
     */
    lineJustAdded() {
        return this.editor.lines[this.editor.lineEditingIndex - 1];
    }
    async addCharacter(name) {
        this.chapter.setChrEditing(null, true);
        this.chapter.chrEditor.name.onChange(name);
        await new Promise((resolve, reject) => {
            this.chapter.chrEditor.submit(err => {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(null);
                }
            });
        });
        return this.store.storyEditing.chrStore.getByName(name);
    }
    async addFileResource(image, type, name) {
        return this.story.studioFileStore.uploadStudioFile(image, type, name);
    }
    async addBlock(name) {
        const op = new changeOp_1.StudioChangeOpFactory(this.chapter);
        await op.createNewBlock(name, '').submitSingle();
        return this.chapter.blockStore.getById(name);
    }
    getProp(name) {
        return this.story.storyPropertyStore.getByName(name);
    }
    getBlock(name) {
        return this.chapter.blockStore.getById(name);
    }
    async uploadFile(imageFile, resName) {
        const fileStore = this.chapter.story.studioFileStore;
        fileStore.uploadFileToPreventMultipleCall(imageFile, consts_1.StudioFileType.Image, resName);
        await whenT(trans('legacy_StudioStoreHelper.ts_upload_complete'), () => !fileStore.isUploadingFile[resName]);
        return this.store.storyEditing.studioFileStore.getByResName(resName);
    }
    async waitSubmitFinish() {
        return whenT('wait submitEditing()', () => !this.chapter.isApplyingChange);
    }
    startEditingBlock(name) {
        const b = this.getBlock(name);
        this.chapter.setBlockEditing(b);
        return new DOBlockHelper_1.DOBlockHelper(b, this);
    }
    moveCursorTo(index) {
        let i = index;
        const len = this.editor.lines.length;
        while (i < 0 && len > 0) {
            i += len;
        }
        i = i % len;
        const line = this.editor.lines[i];
        this.editor.onLineClickedForCursorMove(line);
    }
}
exports.StudioStoreHelper = StudioStoreHelper;
