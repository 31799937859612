"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBFileFieldWithUI = void 0;
const ui_1 = require("../ui");
const DBFileField_1 = require("./DBFileField");
/**
 * 기존 IInputWithValidation 과의 공존을 위하여 존재한다. DBFileField 를 사용하도록 하되,
 * InputWithValidation 을 연동한다.
 */
class DBFileFieldWithUI extends DBFileField_1.DBFileField {
    constructor(fieldId, getter, setter, uiOptions = {}) {
        var _a;
        super(fieldId, getter, setter);
        // 적어도 최초에는 로컬 업로드된 File 객체는 없다고 가정한다.
        const defValue = this.defValue;
        this.input = new ui_1.FileInput(fieldId, (_a = uiOptions.label) !== null && _a !== void 0 ? _a : '', defValue, {
            ...uiOptions.options,
            onChange: async (file) => {
                this.onChange(file);
            },
            onRemove: () => {
                this.onChange(null);
            },
            imageLink: defValue === null || defValue === void 0 ? void 0 : defValue.link,
        });
    }
}
exports.DBFileFieldWithUI = DBFileFieldWithUI;
