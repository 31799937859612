"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorFieldBase = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
class EditorFieldBase {
    constructor(fieldId, getter, setter) {
        this.isEditable = true;
        this.fieldId = fieldId;
        this.getter = getter;
        this.setter = setter;
        this.defValue = getter();
        this.value = this.defValue;
        (0, mobx_1.makeObservable)(this, {
            value: mobx_1.observable,
            isEditable: mobx_1.observable,
            reload: mobx_1.action,
            onChange: mobx_1.action,
            revertChanges: mobx_1.action,
            hasChange: mobx_1.computed,
            defValue: mobx_1.observable,
        });
    }
    reload() {
        this.defValue = this.getter();
        this.value = this.defValue;
    }
    onChange(newValue) {
        if (this.isEditable) {
            this.value = newValue;
        }
    }
    revertChanges() {
        this.value = this.defValue;
    }
    async commitChanges() {
        await this.setter(this.value);
        (0, mobx_1.runInAction)(() => {
            this.defValue = this.value;
        });
    }
    get hasChange() {
        return !(0, lodash_1.isEqual)(this.defValue, this.value);
    }
    setEditable(editable) {
        (0, mobx_1.runInAction)(() => {
            this.isEditable = editable;
        });
    }
}
exports.EditorFieldBase = EditorFieldBase;
