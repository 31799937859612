"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbMessageNext = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const DOSTHbBase_1 = require("../../../DOSTHbBase");
const IDOStatement_1 = require("../../../IDOStatement");
const DOHbMessageNext_1 = require("./DOHbMessageNext");
class DOSTHbMessageNext extends DOSTHbBase_1.DOSTHbBase {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbMessageNext,
            nextData: {
                linkBlocks: [
                    {
                        blockIds: [], // 조건이 없는 부분을 반드시 하나 만들어야 한다.
                    },
                ],
            },
            background: '',
        }, block, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.HbMessageNext;
        this.hasModalForEdit = true;
        this.messageNext = new DOHbMessageNext_1.DOHbMessageNext(this, this.st.nextData);
        (0, mobx_1.makeObservable)(this, {
            messageNext: mobx_1.observable,
            flowChartNodesOfOtherGroupBlock: mobx_1.computed,
            isValidBlockIds: mobx_1.computed,
        });
    }
    generateMessageNext(data) {
        (0, mobx_1.runInAction)(() => {
            this.messageNext = new DOHbMessageNext_1.DOHbMessageNext(this, data);
        });
    }
    startEditing() {
        this.generateMessageNext(this.st.nextData);
        super.startEditing();
    }
    get isValidBlockIds() {
        return this.messageNext.isValidBlockIds;
    }
    get isValidAll() {
        return this.messageNext.isValidAll;
    }
    // 서버로 보낼 인풋 데이터를 하위 클래스로부터 받아온다.
    getDataForSubmit() {
        if (!this.messageNext) {
            this.generateMessageNext(this.st);
        }
        return {
            ...this.messageNext.getDataForSubmit(),
            type: 'next',
        };
    }
    // 업데이트 반영되는지 테스트 코드. 나중에 지워야함. 안에 함수는 updateLinkBlock에 들어가지 않을까 싶은데..
    updateData(data) {
        var _a, _b, _c, _d;
        if (data.data) {
            super.updateData(data);
            this.generateMessageNext((_a = data.data) !== null && _a !== void 0 ? _a : {});
            this.st.nextData = (_b = data.data) !== null && _b !== void 0 ? _b : {};
            (_d = (_c = this.rootStore.chapterEditing) === null || _c === void 0 ? void 0 : _c.reLayoutFlowChartElements) === null || _d === void 0 ? void 0 : _d.call(_c);
        }
    }
    //
    // flowChart
    //
    get blocksTo() {
        return this.messageNext.blocksTo;
    }
    get flowChartNodesOfOtherGroupBlock() {
        return this.messageNext.flowChartNodesOfOtherGroupBlock;
    }
    getEdgeInfoForFlowChartBy(toBlockUniqueId) {
        return this.messageNext.getEdgeInfoForFlowChartBy(toBlockUniqueId);
    }
    removeHbBlocksBy(blockId) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b, _c;
            this.st.nextData = {
                ...this.st.nextData,
                linkBlocks: (_a = this.st.nextData.linkBlocks) === null || _a === void 0 ? void 0 : _a.map(lb => {
                    var _a;
                    return ({
                        ...lb,
                        blocks: (_a = lb.blocks) === null || _a === void 0 ? void 0 : _a.filter(b => b.id !== blockId),
                    });
                }),
            };
            this.st.hbExtensionData = {
                ...this.st.hbExtensionData,
                data: {
                    ...this.st.hbExtensionData.data,
                    linkBlocks: (_c = (_b = this.st.hbExtensionData.data) === null || _b === void 0 ? void 0 : _b.linkBlocks) === null || _c === void 0 ? void 0 : _c.map((lb) => {
                        var _a;
                        return ({
                            ...lb,
                            blocks: (_a = lb.blocks) === null || _a === void 0 ? void 0 : _a.filter(b => b.id !== blockId),
                        });
                    }),
                },
            };
        });
        this.generateMessageNext(this.st.nextData);
    }
}
exports.DOSTHbMessageNext = DOSTHbMessageNext;
