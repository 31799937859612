"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryPropertyEditStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../@types/server.schema");
const consts_1 = require("../../../../consts");
const validations_1 = require("../../../validations");
const ui_1 = require("../ui");
const DOStoryProperty_1 = require("./DOStoryProperty");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
/**
 * 현재 update 관련된 로직도 있지만 현재는 생성하는 로직만 쓰이고 있다.
 * 해당 store 에서 생성시 실제 서버에도 저장된다.
 */
class DOStoryPropertyEditStore {
    constructor(story, isMain, prop, 
    // name: string,
    // isMain: boolean,
    // null 이면 취소인 경우임.
    onSubmit) {
        var _a, _b, _c, _d, _e;
        this.isSubmitting = false;
        this.story = story;
        this.rootStore = story.rootStore;
        this.propEditing = prop;
        this._isMain = isMain;
        this.propNameInput = new ui_1.InputWithValidation('propName', trans('legacy_DOChapter_name'), trans('legacy_DOStoryPropertyEditStore_enter_name'), 'text', (_a = prop === null || prop === void 0 ? void 0 : prop.name) !== null && _a !== void 0 ? _a : '', validations_1.PropValidations.name, {
            noLabel: true,
        });
        this.propTypeInput = new ui_1.SelectionInput('propType', trans('legacy_DOStoryPropertyEditStore_property_type'), (_b = prop === null || prop === void 0 ? void 0 : prop.propType) !== null && _b !== void 0 ? _b : server_schema_1.GQLSTORY_PROP_TYPE.NUMBER, Object.values(server_schema_1.GQLSTORY_PROP_TYPE).map(item => ({
            name: core_1.STORY_PROP_TYPE_TRANSLATED[item],
            value: item,
        })), {
            isDisabled: this._isMain,
            noLabel: false,
            placeholder: trans('legacy_DOStoryPropertyEditStore_select_property_type'),
        });
        this.descInput = new ui_1.InputWithValidation('propDesc', trans('legacy_DOStoryPropertyEditStore_property_description'), trans('legacy_DOStoryPropertyEditStore_enter_property_description'), 'text', (_c = prop === null || prop === void 0 ? void 0 : prop.desc) !== null && _c !== void 0 ? _c : '', null);
        this.imageFileInput = new ui_1.FileInput('propImage', trans('legacy_DOStoryPropertyEditStore_property_image'), ((_d = this.propEditing) === null || _d === void 0 ? void 0 : _d.imageLink)
            ? { uploaded: { link: (_e = this.propEditing) === null || _e === void 0 ? void 0 : _e.imageLink } }
            : null, {});
        this.onSubmitCallback = onSubmit;
        (0, mobx_1.makeObservable)(this, {
            isSubmitting: mobx_1.observable,
            isMain: mobx_1.computed,
            isCreating: mobx_1.computed,
        });
    }
    onCancel() {
        this.onSubmitCallback(null);
    }
    async onSubmit(onFinish) {
        const isPrepared = this.propNameInput.prepareSubmit();
        if (this.isCreating) {
            try {
                validations_1.PropValidations.noIncludeCommaInName.validateSync(this.propNameInput.value);
            }
            catch (ex) {
                this.rootStore.showError(this.rootStore.textStore.validationMessageMap[ex.message]);
                return;
            }
        }
        if (!isPrepared) {
            this.rootStore.showError(this.propNameInput.validationError);
            return;
        }
        if (this.isSubmitting) {
            return;
        }
        (0, mobx_1.runInAction)(() => (this.isSubmitting = true));
        return this.processSubmit()
            .then(() => {
            onFinish === null || onFinish === void 0 ? void 0 : onFinish(null);
            this.onSubmitCallback(this.propEditing);
        }, err => {
            if (onFinish) {
                onFinish === null || onFinish === void 0 ? void 0 : onFinish(err);
            }
            else {
                this.story.rootStore.showError(err);
            }
        })
            .finally(() => {
            (0, mobx_1.runInAction)(() => (this.isSubmitting = false));
        });
    }
    async processSubmit() {
        var _a;
        const server = this.rootStore.di.server;
        const file = this.imageFileInput.fileToUpload
            ? await server.createStoryHasStudioFile({
                storyId: this.story.storyId,
                file: this.imageFileInput.fileToUpload,
                fileType: consts_1.StudioFileType.Image,
                resName: this.imageFileInput.fileToUpload.name,
            })
            : null;
        if (!this.isCreating) {
            const res = await this.propEditing.update({
                propName: this.propNameInput.value,
                desc: this.descInput.value,
                imageFromStudioFileId: (_a = file === null || file === void 0 ? void 0 : file.fileId) !== null && _a !== void 0 ? _a : null,
            });
            this.story.storyItemStore.merge(res);
        }
        else {
            const entity = new DOStoryProperty_1.DOStoryProperty(this.story.storyPropertyStore, {
                propId: this.story.getNextLocalNumberId(),
                storyId: this.story.storyId,
                propName: this.propNameInput.value,
                propNameRaw: this.propNameInput.value,
                desc: this.descInput.value,
                isMain: this._isMain,
                isUsed: true,
                showOrder: 1000,
                maxValue: null,
                displayPropName: this.propNameInput.value,
                imageFile: file,
                propType: this.propTypeInput.value,
            });
            await entity.create();
            this.propEditing = entity;
        }
    }
    /**
     * 핵심속성 (내 수치)인가, 아니면 일반 속성인가?
     */
    get isMain() {
        return this._isMain;
    }
    get isCreating() {
        return !this.propEditing;
    }
}
exports.DOStoryPropertyEditStore = DOStoryPropertyEditStore;
