import {
  BlockingLoadBox,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { GQLTtsSpeaker } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import {
  ITtsSpeakerListableOption,
  TtsSpeakerPrimaryKey,
  SAMPLE_TEXT,
} from '../TtsSpeakerCommon'
import { useTtsSpeakerListDataLoader } from './TtsSpeakerListDataLoader'
import { SetTtsOptionsModal } from '../SetTtsOptionModal'
import { AudioListenerInternal } from '../AudioListenerInternal'
import { AGE_GROUP, SEX_DISTINCTION } from '@storyplay/common'
import { TtsSpeakerGenderSelect } from '../TtsSpeakerGenderSelect'
import { TtsSpeakerAgeSelect } from '../TtsSpeakerAgeSelect'

export const TtsSpeakerListableTable: React.FC = () => {
  const [loading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLTtsSpeaker,
    ITtsSpeakerListableOption
  >()
  const [token, refresh] = useCounter()
  const [showTtsProjectModal, setShowTtsProjectModal] = React.useState(false)
  const [ttsServerSpeakerId, setTtsServerSpeakerId] = React.useState<
    number | undefined
  >(undefined)
  const [ttsServerSpeakerName, setTtsServerSpeakerName] = React.useState<
    string | undefined
  >(undefined)
  const [sexDistinctionFilter, setSexDistinctionFilter] = React.useState<
    SEX_DISTINCTION | undefined
  >(undefined)
  const [ageFilter, setAgeFilter] = React.useState<AGE_GROUP | undefined>(
    undefined
  )

  const dataLoader = useTtsSpeakerListDataLoader(
    sexDistinctionFilter,
    ageFilter
  )

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[TtsSpeakerPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <div style={{ width: 150, float: 'left' }}>
              <TtsSpeakerGenderSelect
                value={sexDistinctionFilter}
                onChange={setSexDistinctionFilter}
              />
            </div>
            <div style={{ width: 150, float: 'left' }}>
              <TtsSpeakerAgeSelect value={ageFilter} onChange={setAgeFilter} />
            </div>

            <ODListableSearchBox
              listableContext={Context}
              placeholder="이름, 말투, 기타 특징으로 검색"
              style={searchBoxStyle}
            />
            <div>
              <Button
                block
                color="primary"
                onClick={() => {
                  setShowTtsProjectModal(true)
                  setTtsServerSpeakerId(undefined)
                  setTtsServerSpeakerName(undefined)
                }}
              >
                TTS 옵션 JSON 만들기
              </Button>
            </div>
          </div>
          <div style={toolbarStyle}>
            <b style={{ color: 'red' }}>
              TTS 문장은 공백, 특수문자 포함 200자 이내로 만들 수 있어요!
            </b>
          </div>
          <ODListablePaginatedTable2
            numColumns={8}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>TTS 서버 스피커 아이디</th>
                <th>스피커 이름</th>
                <th>성별</th>
                <th>연령대</th>
                <th>말투</th>
                <th>기타 특징</th>
                <th>들어보기</th>
                <th>옵션 만들기</th>
              </tr>
            )}
            renderRow={(value: GQLTtsSpeaker) => (
              <>
                <HoverTR key={value.ttsSpeakerId}>
                  <td>{value.ttsServerSpeakerId}</td>
                  <td>{value.name}</td>
                  <td>{value.sexDistinction}</td>
                  <td>{value.ageGroupList}</td>
                  <td>{value.speechList}</td>
                  <td>{value.extraCharacter}</td>
                  <td>
                    <AudioListenerInternal
                      text={SAMPLE_TEXT}
                      speakerId={value.ttsServerSpeakerId}
                      onUpdate={() => {}}
                    />
                  </td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() => {
                        setShowTtsProjectModal(true)
                        setTtsServerSpeakerId(value.ttsServerSpeakerId)
                        setTtsServerSpeakerName(value.name)
                      }}
                    >
                      만들기
                    </Button>
                  </td>
                </HoverTR>
              </>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
          {showTtsProjectModal && (
            <SetTtsOptionsModal
              ttsServerSpeakerId={ttsServerSpeakerId}
              ttsServerSpeakerName={ttsServerSpeakerName}
              onClose={() => setShowTtsProjectModal(false)}
              onConfirm={() => {
                setShowTtsProjectModal(false)
              }}
            />
          )}
        </CardBody>
      </Card>
    </Provider>
  )
}
