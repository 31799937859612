import React from 'react'
import { IODRendererProps, IODStringRendererDef, ODRendererComponentType, ODRendererEventType } from './common'

interface IODStringRendererProps<ParentContextType, OriginalValueType>
  extends IODRendererProps<boolean, IODStringRendererDef, ParentContextType, OriginalValueType> {
  value: any
  def: IODStringRendererDef
}

export const ODStringRenderer: React.FC<IODStringRendererProps<any, any>> = (props) => {
  const {
    def: { eventKey, style = {}, clickEnabled = false },
    value,
    context,
    originalValue,
  } = props

  const styleDef = clickEnabled ? { cursor: 'pointer' } : {}
  const handleClick = React.useCallback(
    (currentValue: any) => {
      if (!clickEnabled) {
        return
      }

      context.onEvent?.(
        {
          type: ODRendererEventType.OnClick,
          eventKey,
          valueFrom: currentValue,
        },
        { ...context.parentContext, renderedFromOriginalValue: originalValue }
      )
    },
    [clickEnabled, eventKey, originalValue, context]
  )

  return (
    <span style={{ ...styleDef, ...style }} onClick={() => handleClick(value)}>
      {value}
    </span>
  )
}

export const ODDefaultStringDef: IODStringRendererDef = {
  eventKey: 'NotProvided',
  component: ODRendererComponentType.StringRenderer,
  clickEnabled: false,
  style: {},
}
