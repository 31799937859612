import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { FeaturedEditPage } from './FeaturedEditPage'

interface IFeaturedEditPageContainerProps {
  featuredId: number
}

export const FeaturedEditPageContainer: React.FC<
  IFeaturedEditPageContainerProps
> = (props) => {
  const { featuredId } = props
  return (
    <>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Featured.List} />
          <hr />
          <FeaturedEditPage featuredId={featuredId} />
        </CardBody>
      </Card>
    </>
  )
}
