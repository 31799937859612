"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentsLanguages = exports.SystemLanguages = exports.FALLBACK_LANGUAGE_CODE = exports.LANGUAGE_CODE = void 0;
var LANGUAGE_CODE;
(function (LANGUAGE_CODE) {
    LANGUAGE_CODE["ko"] = "ko";
    LANGUAGE_CODE["en"] = "en";
})(LANGUAGE_CODE = exports.LANGUAGE_CODE || (exports.LANGUAGE_CODE = {}));
exports.FALLBACK_LANGUAGE_CODE = LANGUAGE_CODE.ko;
exports.SystemLanguages = [
    {
        code: LANGUAGE_CODE.ko,
        display: '한국어',
    },
    {
        code: LANGUAGE_CODE.en,
        display: 'English',
    },
];
exports.ContentsLanguages = [
    {
        code: 'ko_KR',
        display: '한국어',
    },
    {
        code: 'en_US',
        display: '영어',
    },
    {
        code: 'es_ES',
        display: '스페인어',
    },
    {
        code: 'id_ID',
        display: '인도네시아어',
    },
    {
        code: 'ja_JP',
        display: '일본어',
    },
    {
        code: 'th_TH',
        display: '태국어',
    },
    {
        code: 'zh_CN',
        display: '중국어(간체)',
    },
    {
        code: 'zh_Hant',
        display: '중국어(번체)',
    },
];
// 임시코드 끝
