"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createQueryParams = void 0;
const createQueryParams = (params) => {
    return Object.keys(params)
        .filter(v => params[v] !== undefined)
        .map(k => `${k}=${params[k].toString()}`)
        .join('&');
};
exports.createQueryParams = createQueryParams;
