import {
  BlockingLoadBox, ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLLedger } from '../../../@types/server'
import { LEDGER_AMOUNT_FILTER_FIELD } from '../../../common'
import { HoverTR } from '../../../components/commonStyle'
import { Utils } from '../../../utils/utils'
import { IUserLedgerListableOption } from '../UserCommon'
import { UserLedgerAmountSelect } from '../UserLedgerAmountSelect'
import { useUserLedgerListDataLoader } from './UserLedgerListDataLoader'

interface IUserLedgerListableTableProps {
  userId: number
}

export const UserLedgerListableTable: React.FC<IUserLedgerListableTableProps> = (
  props
) => {
  const { userId } = props
  const [loading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLLedger,
    IUserLedgerListableOption
  >()
  const [token] = useCounter()
  const [amountValue, setAmountValue] = React.useState<LEDGER_AMOUNT_FILTER_FIELD | null>(null)
  const dataLoader = useUserLedgerListDataLoader(userId, amountValue)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = { display: 'flex', marginBottom: 15 }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <>
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v['ledgerId'].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="내용으로 검색"
              style={searchBoxStyle}
            />
            <div style={{ width: 250, marginLeft: 5 }}>
              <UserLedgerAmountSelect
                defaultLabel="모든 금액"
                onChange={(option) => {
                  // for list to refresh
                  setAmountValue(option)
                }}
              />
            </div>
          </div>
          <ODListablePaginatedTable2
            numColumns={6}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>티켓 사용이력 아이디</th>
                <th>금액</th>
                <th>발생일</th>
                <th>내용</th>
              </tr>
            )}
            renderRow={(value: GQLLedger, context) => {
              const amountColor = value.amount >= 0 ? 'green' : 'red'
              return (
                <HoverTR
                  key={value.ledgerId}
                >
                  <td>{value.ledgerId}</td>
                  <td><p style={{ color: amountColor }}>{value.amount}</p></td>
                  <td>{Utils.formatDate(value.createdAt)}</td>
                  <td>{value.reason}</td>
                </HoverTR>
              )
            }}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
    </>
  )
}
