import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import { GQLBonusTicketCode, GQLListableBonusTicketCode, GQLListableBonusTicketCodeInput } from '../../@types/server'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useCounter, useODQuery } from '../../context/ODCommon'
import { ODListableOption, ODListableResponseType, useODListableContext } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

interface Props {}

export interface BonusTicketCodeDataLoaderOption extends ODListableOption {
  filter: string | null
}

const BONUS_TICKET_CODE_FULL_SNAPSHOT = `
  btcId
  code
  desc
  willActivateAt
  willExpireAt
  inactivatedAt
  numOfReuses
  remainingReuses
  ticketAmount
  issuedUserId
  createdAt
`

const GQL_LIST = `
query listBonusTicketCode($data: ListableBonusTicketCodeInput!) {
  listBonusTicketCode(data: $data) {
    list {
      ${BONUS_TICKET_CODE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const BonusTicketCodeListContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<GQLBonusTicketCode, BonusTicketCodeDataLoaderOption>()

  const listBonusTicketCode = useODQuery<GQLListableBonusTicketCodeInput, GQLListableBonusTicketCode>(GQL_LIST)
  const [token] = useCounter()

  const dataLoader = React.useCallback(
    async function BonusTicketCodeDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: BonusTicketCodeDataLoaderOption
    ): Promise<ODListableResponseType<GQLBonusTicketCode>> {
      const r = await listBonusTicketCode({
        page,
        pageSize,
        filter: options.filter || null,
      })
      return r as ODListableResponseType<GQLBonusTicketCode>
    },
    [listBonusTicketCode]
  )

  const TableDefinition: ODListableTableDefinition<GQLBonusTicketCode, BonusTicketCodeDataLoaderOption, any> = [
    {
      id: 'btcId',
      title: '아이디',
      transform: (v) => v.btcId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'code',
      title: '코드',
      transform: (v) => <Link to={SiteUrls.Admin.BonusTicketCode.Edit(v.btcId)}>{v.code}</Link>,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'desc',
      title: '설명',
      transform: (v) => v.desc.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'willActivateAt',
      title: '활성 시작 시각',
      transform: (v) => v.willActivateAt && Utils.formatDate(v.willActivateAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'willExpireAt',
      title: '만료 시각',
      transform: (v) => v.willExpireAt && Utils.formatDate(v.willExpireAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'inactivatedAt',
      title: '무효화 된 시각',
      transform: (v) => v.inactivatedAt && Utils.formatDate(v.inactivatedAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'numOfReuses',
      title: '충전 가능 횟수',
      transform: (v) => v.numOfReuses,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'remainingReuses',
      title: '남은 충전 횟수',
      transform: (v) => v.remainingReuses,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'ticketAmount',
      title: '충전 티켓 장수',
      transform: (v) => v.ticketAmount,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'createdAt',
      title: '등록 시각',
      transform: (v) => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.btcId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="코드나 설명에 포함된 문자로 검색하세요."
                style={{ flexGrow: 6, maxWidth: 600 }}
              />

              <div>
                <Link to={SiteUrls.Admin.BonusTicketCode.Create} style={{ textDecoration: 'none' }}>
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    코드 추가
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩중..'}
              renderEmpty={() => '코드가 없습니다'}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
