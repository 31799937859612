"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOInstantTestingStore = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../@types/server.schema");
const fields_1 = require("../fields");
const studioTutorial_1 = require("../studioTutorial");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOInstantTestingStore {
    constructor(chapter) {
        this.loading = false;
        this._userPropTextFields = [];
        this._userItemTextFields = [];
        this.selectedPreviousChapterEnding = null;
        this.startingBlockForTest = null;
        this.versionGuideModalInfoForOpen = null;
        this.testStartWhenHasErrorModalInfoForOpen = null;
        // 가장 최근에 저장한 데이터, 인스턴스 생성시에 한번만 받아온다.
        this.testSession = null;
        // testSession 의 playData 를 필드들에 붙여넣기 했는지?
        // 한번 붙여넣기 한 이후로는 하지 않기 위함
        this.isPasted = false;
        this.generatedScript = null;
        this.chapter = chapter;
        this.userLastNameTextFields = new fields_1.TextFieldWithUI('lastName', () => this.story.defaultLastName, () => null, {
            label: trans('legacy_DOInstantTestingStore_player_gender'),
            placeholder: trans('legacy_DOInstantTestingStore_enter_player_gender'),
            options: { noLabel: true },
        });
        this.userFirstNameTextFields = new fields_1.TextFieldWithUI('firstName', () => this.story.defaultFirstName, () => null, {
            label: trans('legacy_DOInstantTestingStore_player_name'),
            placeholder: trans('legacy_DOInstantTestingStore_enter_player_name'),
            options: { noLabel: true },
        });
        (0, mobx_1.makeObservable)(this, {
            loading: mobx_1.observable,
            selectedPreviousChapterEnding: mobx_1.observable,
            startingBlockForTest: mobx_1.observable,
            testSession: mobx_1.observable,
            _userPropTextFields: mobx_1.observable,
            _userItemTextFields: mobx_1.observable,
            isPasted: mobx_1.observable,
            testStartWhenHasErrorModalInfoForOpen: mobx_1.observable,
            versionGuideModalInfoForOpen: mobx_1.observable,
            setSelectedPreviousChapterEnding: mobx_1.action,
            setStartingBlockForTest: mobx_1.action,
            previousChapterEndings: mobx_1.computed,
            userPropTextFields: mobx_1.computed,
            userItemTextFields: mobx_1.computed,
        });
    }
    get previousChapterEndings() {
        var _a, _b;
        return ((_b = (_a = this.story.chapterStore.getPreviousChapterOf(this.chapter.id)) === null || _a === void 0 ? void 0 : _a.endings) !== null && _b !== void 0 ? _b : []);
    }
    get userPropTextFields() {
        return this._userPropTextFields;
    }
    get userItemTextFields() {
        return this._userItemTextFields;
    }
    get story() {
        return this.chapter.getStory();
    }
    setSelectedPreviousChapterEnding(ending) {
        this.selectedPreviousChapterEnding = ending;
    }
    setStartingBlockForTest(block) {
        this.startingBlockForTest = block;
    }
    showVersionGuideModal(block) {
        (0, mobx_1.runInAction)(() => {
            this.versionGuideModalInfoForOpen = {
                onClose: () => {
                    (0, mobx_1.runInAction)(() => {
                        this.versionGuideModalInfoForOpen = null;
                    });
                },
                onFinish: () => {
                    (0, mobx_1.runInAction)(() => {
                        this.versionGuideModalInfoForOpen = null;
                    });
                    this.setStartingBlockForTest(block);
                },
            };
        });
    }
    async onStartTest() {
        if (!this.selectedPreviousChapterEnding &&
            this.chapter.chapterIndex > 1 &&
            this.previousChapterEndings.length > 0) {
            this.story.rootStore.showError(new core_1.SPCError(core_1.ErrorCode.InstantTestingHasNoPreviousEnding));
            return;
        }
        if (!this.startingBlockForTest) {
            this.story.rootStore.showError(new core_1.SPCError(core_1.ErrorCode.InstantTestingHasNoStartingBlock));
            return;
        }
        await this.story.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.DO_INSTANT_TEST);
        this.chapter.validate();
        const ret = this.chapter.validateWithScripter();
        const canIgnoreErrors = !!ret.json;
        if (ret.book) {
            const { startingBlock, blocks } = Object.values(ret.book.chapters)[0];
            this.generatedScript = {
                startingBlock,
                blocks,
            };
        }
        else {
            this.generatedScript = null;
        }
        // 에러가 있는 경우 테스트 플레이 파라메터 팝업을 닫고,
        // 검수결과가 있는 테스트 시작 팝업을 오픈한다.
        if (this.chapter.lastValidationResults.length > 0) {
            const startingBlockForTest = this.startingBlockForTest;
            (0, mobx_1.runInAction)(() => {
                this.testStartWhenHasErrorModalInfoForOpen = {
                    onClose: () => (this.testStartWhenHasErrorModalInfoForOpen = null),
                    onForceStart: async () => {
                        if (!canIgnoreErrors) {
                            this.chapter.store.rootStore.showError(new core_1.SPCError(core_1.ErrorCode.HasScriptErrorBeforeStartingInstant));
                            return;
                        }
                        return this.onForceStartTest(startingBlockForTest);
                    },
                    lastValidationResults: this.chapter.lastValidationResults,
                    canIgnoreErrors,
                };
                this.startingBlockForTest = null;
            });
            return;
        }
        const instantTestingPlayData = this.makeInstantTestingPlayData(this.startingBlockForTest, true, this.generatedScript);
        return this.createTestSession(instantTestingPlayData);
    }
    async onForceStartTest(block) {
        const instantTestingPlayData = this.makeInstantTestingPlayData(block, false, this.generatedScript);
        return this.createTestSession(instantTestingPlayData);
    }
    async refresh() {
        this.addNewItemFieldsIfHasNewItems();
        this.addNewPropFieldsIfHasNewProps();
        if (!this.isPasted) {
            await this.getTestSession();
            this.pasteExistingData();
            (0, mobx_1.runInAction)(() => {
                this.isPasted = true;
            });
        }
    }
    // TestSession 데이터를 만들고 testSession Id 를 반환한다.
    async createTestSession(instantTestingPlayData) {
        (0, mobx_1.runInAction)(() => {
            this.loading = true;
        });
        try {
            const convertedData = JSON.stringify(instantTestingPlayData);
            // tslint:disable-next-line:no-console
            console.log('generated data', { convertedData });
            // tslint:disable-next-line:no-console
            console.log('generated script', {
                script: JSON.parse(JSON.parse(convertedData).chapterScript),
            });
            const res = await this.story.rootStore.di.server.createTestSession({
                storyId: this.story.storyId,
                chapterId: this.chapter.id,
                playData: convertedData,
            });
            return res.testSessionId;
        }
        catch (ex) {
            this.chapter.getStory().rootStore.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.loading = false;
            });
        }
    }
    // 새로운 아이템들이 있다면 해당 필드들을 생성해준다.
    addNewItemFieldsIfHasNewItems() {
        const newItems = this.getNewItems();
        if (newItems.length > 0) {
            (0, mobx_1.runInAction)(() => {
                this._userItemTextFields = this._userItemTextFields.concat(newItems.map(item => new fields_1.TextFieldWithUI(item, () => '0', () => null, {
                    label: item,
                    options: { noLabel: true },
                })));
            });
        }
    }
    // 새로운 속성들이 있다면 해당 필드들을 생성해준다.
    addNewPropFieldsIfHasNewProps() {
        const newProps = this.getNewProps();
        if (newProps.length > 0) {
            (0, mobx_1.runInAction)(() => {
                this._userPropTextFields = this._userPropTextFields.concat(newProps.map(item => new fields_1.TextFieldWithUI(item, () => '0', () => null, {
                    label: item,
                    options: { noLabel: true },
                })));
            });
        }
    }
    // 새로운 속성이 있다면 반환한다.
    getNewProps() {
        return (0, lodash_1.difference)(this.story.storyPropertyStore.all
            .filter(item => item.propType === server_schema_1.GQLSTORY_PROP_TYPE.NUMBER)
            .map(item => item.name), this._userPropTextFields.map(item => item.input.label));
    }
    // 새로운 아이템이 있다면 반환한다.
    getNewItems() {
        return (0, lodash_1.difference)(this.story.storyItemStore.allStoryItems.map(item => item.name), this._userItemTextFields.map(item => item.input.label));
    }
    // 가장 최신의 testSession 을 받아와 저장한다.
    async getTestSession() {
        (0, mobx_1.runInAction)(() => {
            this.loading = true;
        });
        try {
            const res = await this.story.rootStore.di.server.getLastTestSession(this.chapter.id);
            if (!res || !res.playData) {
                return;
            }
            (0, mobx_1.runInAction)(() => {
                this.testSession = res;
            });
        }
        catch (ex) {
            this.story.rootStore.showError(ex);
        }
        finally {
            (0, mobx_1.runInAction)(() => {
                this.loading = false;
            });
        }
    }
    // this.testSession.playData 에 저장된 데이터를 알맞는 필드에 붙여넣기 한다.
    pasteExistingData() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        try {
            const parsedPlayData = JSON.parse((_b = (_a = this.testSession) === null || _a === void 0 ? void 0 : _a.playData) !== null && _b !== void 0 ? _b : '{}');
            const parsedUserProps = JSON.parse(parsedPlayData.userProps || '{}');
            const parsedUserItems = JSON.parse(parsedPlayData.userItems || '{}');
            this._userPropTextFields.forEach(item => {
                var _a;
                const existingValue = (_a = parsedUserProps === null || parsedUserProps === void 0 ? void 0 : parsedUserProps[item.input.label]) !== null && _a !== void 0 ? _a : 0;
                item.input.onChange(existingValue.toString());
            });
            this._userItemTextFields.forEach(item => {
                var _a;
                const existingValue = (_a = parsedUserItems === null || parsedUserItems === void 0 ? void 0 : parsedUserItems[item.input.label]) !== null && _a !== void 0 ? _a : 0;
                item.input.onChange(existingValue.toString());
            });
            this.userFirstNameTextFields.input.onChange((_d = (_c = parsedPlayData.story) === null || _c === void 0 ? void 0 : _c.firstName) !== null && _d !== void 0 ? _d : this.story.defaultFirstName);
            this.userLastNameTextFields.input.onChange((_f = (_e = parsedPlayData.story) === null || _e === void 0 ? void 0 : _e.lastName) !== null && _f !== void 0 ? _f : this.story.defaultLastName);
            const endingName = (_g = parsedPlayData.previousEnding) === null || _g === void 0 ? void 0 : _g.name;
            // tslint:disable-next-line:prefer-conditional-expression
            if (endingName) {
                this.selectedPreviousChapterEnding =
                    (_h = this.story.endingStore.getByName(endingName)) !== null && _h !== void 0 ? _h : null;
            }
            else {
                this.selectedPreviousChapterEnding = null;
            }
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.log(ex);
        }
    }
    makeInstantTestingPlayData(startingBlockForTest, passedStudioValidation, chapterScript) {
        var _a, _b, _c, _d, _e, _f;
        const instantTestingPlayData = {
            story: {
                name: this.story.name,
                defaultFirstName: this.story.defaultFirstName,
                defaultLastName: this.story.defaultLastName,
                firstName: this.userFirstNameTextFields.value,
                lastName: this.userLastNameTextFields.value,
                mainImageLink: this.story.mainImageLink,
                mainCharacterImageLink: this.story.mainCharacterImageLink,
            },
            chapter: {
                name: (_a = this.chapter.name) !== null && _a !== void 0 ? _a : '',
            },
            chapterScript: JSON.stringify(chapterScript),
            chapterStartingBlock: (_b = this.chapter.startingBlock.name) !== null && _b !== void 0 ? _b : '',
            playStartingBlock: startingBlockForTest.name,
            userProps: JSON.stringify(Object.fromEntries(this.userPropTextFields.map(item => [
                item.input.label,
                parseInt(item.value, 10),
            ]))),
            userItems: JSON.stringify(Object.fromEntries(this.userItemTextFields.map(item => [
                item.input.label,
                parseInt(item.value, 10),
            ]))),
            previousEnding: {
                id: (_d = (_c = this.selectedPreviousChapterEnding) === null || _c === void 0 ? void 0 : _c.endingId) !== null && _d !== void 0 ? _d : null,
                name: (_f = (_e = this.selectedPreviousChapterEnding) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : '',
            },
            items: this.story.storyItemStore.allStoryItems.map(item => {
                var _a, _b;
                return ({
                    name: item.name,
                    desc: item.desc,
                    imageLink: (_b = (_a = item.imageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null,
                });
            }),
            props: this.story.storyPropertyStore.all.map(item => ({
                propName: item.propName,
                propType: item.propType,
                maxValue: item.maxValue,
                imageLink: item.imageLink,
                isMain: item.isMain,
                showOrder: item.showOrder,
                displayPropName: item.displayPropName,
                unit: item.unit,
                desc: item.desc,
            })),
            chrs: this.story.chrStore.all.map(item => {
                var _a, _b;
                return ({
                    name: item.name,
                    imageLink: (_b = (_a = item.imageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null,
                    main: item.isMain,
                    color: item.color,
                    defaultFirstName: item.defaultFirstName,
                    defaultLastName: item.defaultLastName,
                    displayName: item.name,
                });
            }),
            backgrounds: this.story.storyHasBackgroundStore.all.map(item => {
                var _a, _b;
                return ({
                    name: item.name,
                    imageLink: (_b = (_a = item.file) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null,
                });
            }),
            endings: this.story.endingStore.allEndings.map(item => {
                var _a, _b;
                return ({
                    endingId: item.endingId,
                    name: item.name,
                    description: item.collectionDescription,
                    imageLink: (_b = (_a = item.imageFile) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null,
                    customId: item.customId,
                });
            }),
            achievements: {},
            finalEndings: [],
            passedStudioValidation,
        };
        return instantTestingPlayData;
    }
}
exports.DOInstantTestingStore = DOInstantTestingStore;
