"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioContextMenuManager = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const IStudioClipboard_1 = require("../clipboard/IStudioClipboard");
const StudioClipboardUtils_1 = require("../clipboard/StudioClipboardUtils");
const entity_1 = require("../entity");
const IFlowChartPositionCalculator_1 = require("../entity/chapter/IFlowChartPositionCalculator");
const IStudioContextMenu_1 = require("./IStudioContextMenu");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class StudioContextMenuManager {
    constructor(rootStudio) {
        this.menu = null;
        this.rootStudio = rootStudio;
        (0, mobx_1.makeObservable)(this, {
            menu: mobx_1.observable,
        });
    }
    onBlockEditorStatementClicked(ev, line, editor) {
        var _a;
        const menu = (_a = line.onContextMenuOpen) === null || _a === void 0 ? void 0 : _a.call(line, this, ev, { editor });
        (0, mobx_1.runInAction)(() => (this.menu = menu !== null && menu !== void 0 ? menu : null));
        return !!menu && menu.menus.length > 0;
    }
    /**
     * 플로우차트의 빈 영역이 우클릭되었을 때 호출된다.
     */
    onFlowChartContainerClicked(ev) {
        // noinspection JSIgnoredPromiseFromCall
        this.handleFlowChartContainerClicked(ev);
        return true;
    }
    /**
     * 플로우차트의 빈 영역이 우클릭되었을 때 호출된다.
     */
    async handleFlowChartContainerClicked(ev) {
        const clipboard = await this.rootStudio.di.readFromClipboard();
        const data = await (0, StudioClipboardUtils_1.parseClipboardItem)(clipboard);
        const hasBlockInClipboard = (data === null || data === void 0 ? void 0 : data.dataType) === IStudioClipboard_1.StudioClipboardDataType.Block;
        const name = hasBlockInClipboard ? data.name : '';
        const menus = [];
        if (hasBlockInClipboard) {
            menus.push({
                type: IStudioContextMenu_1.StudioMenuType.TextWithIcon,
                text: trans('legacy_StudioContextMenuManager_copy_of_block', {
                    value: name,
                }),
                onClick: async () => {
                    var _a, _b;
                    const chapter = (_a = this.rootStudio.storyEditing) === null || _a === void 0 ? void 0 : _a.chapterEditing;
                    if (!chapter) {
                        return;
                    }
                    const d = data;
                    const blockName = chapter.getBlockNameWithoutConflict(name);
                    const uniqueId = this.rootStudio.di.generateInternalHashId();
                    await chapter.createNewBlock(blockName, d.startingBackground, uniqueId);
                    const block = chapter.blockStore.getByUniqueId(uniqueId);
                    chapter.setBlockEditing(block);
                    (_b = block.onPasteEvent) === null || _b === void 0 ? void 0 : _b.call(block, d);
                    this.rootStudio.di.showMessage(trans('legacy_StudioContextMenuManager_it_was_created', {
                        value: blockName,
                    }));
                    this.closeMenu();
                },
            });
        }
        (0, mobx_1.runInAction)(() => {
            this.menu =
                menus.length > 0
                    ? {
                        menus,
                        x: ev.pageX,
                        y: ev.pageY,
                    }
                    : null;
        });
        return true;
    }
    /**
     * 플로우차트에서 블록이 우클릭되었을 때 호출된다.
     */
    onFlowChartBlockClicked(ev, node) {
        const di = this.rootStudio.di;
        if (node.nodeType === IFlowChartPositionCalculator_1.FlowChartNodeType.Block) {
            const block = node;
            const menusByIsRemovable = (() => {
                if (block.isRemovable) {
                    return [
                        {
                            type: IStudioContextMenu_1.StudioMenuType.TextWithIcon,
                            text: trans('legacy_StudioContextMenuManager_delete_block', {
                                value: block.name,
                            }),
                            onClick: async () => {
                                this.closeMenu();
                                block.removeBlock(entity_1.BlockRemoveConfirmOption.IfHasPublishedEnding);
                            },
                        },
                    ];
                }
                return [];
            })();
            (0, mobx_1.runInAction)(() => {
                this.menu = {
                    menus: [
                        {
                            text: trans('legacy_StudioContextMenuManager_copy_block', {
                                value: node.nodeName,
                            }),
                            onClick: async () => {
                                var _a;
                                const ret = (_a = block.onCopyEvent) === null || _a === void 0 ? void 0 : _a.call(block);
                                if (ret) {
                                    await di.copyToClipboard(JSON.stringify(ret));
                                }
                                this.closeMenu();
                            },
                            type: IStudioContextMenu_1.StudioMenuType.TextWithIcon,
                        },
                        ...menusByIsRemovable,
                        {
                            type: IStudioContextMenu_1.StudioMenuType.TextWithIcon,
                            text: trans('legacy_StudioContextMenuManager_cut_block', {
                                value: block.name,
                            }),
                            onClick: async () => {
                                var _a;
                                const ret = (_a = block.onCutEvent) === null || _a === void 0 ? void 0 : _a.call(block);
                                if (ret) {
                                    await di.copyToClipboard(JSON.stringify(ret));
                                }
                                this.closeMenu();
                            },
                        },
                    ],
                    x: ev.pageX,
                    y: ev.pageY,
                };
            });
        }
        return true;
    }
    onFlowChartEdgeClicked(ev, line, data) {
        var _a;
        const menu = (_a = line.onContextMenuOpen) === null || _a === void 0 ? void 0 : _a.call(line, this, ev, { edge: data });
        (0, mobx_1.runInAction)(() => (this.menu = menu !== null && menu !== void 0 ? menu : null));
        return !!menu && menu.menus.length > 0;
    }
    closeMenu() {
        (0, mobx_1.runInAction)(() => {
            this.menu = null;
        });
    }
}
exports.StudioContextMenuManager = StudioContextMenuManager;
