"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbConditionOperatorsByNumberAttribute = exports.HbConditionOperatorsByGeneralAttribute = exports.HbConditionOperatorToKoString = void 0;
exports.HbConditionOperatorToKoString = {
    greater: '>',
    isgreater: '≥',
    equals: '==',
    notequals: '!=',
    isless: '≤',
    less: '<',
    exists: '입력값 있음',
    notexists: '입력값 없음',
    includes: '??',
    notincludes: '??',
    min: '??',
    max: '??',
};
exports.HbConditionOperatorsByGeneralAttribute = [
    'greater',
    'isgreater',
    'equals',
    'notequals',
    'isless',
    'less',
    'exists',
    'notexists',
];
exports.HbConditionOperatorsByNumberAttribute = [
    'equals',
    'notequals',
    'exists',
    'notexists',
];
