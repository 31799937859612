import { ODListableOption } from '@odc/od-react-belt'
import { GQLStoryHasBackground } from '../../@types/server'

export const StoryHasBackgroundPrimaryKey = 'shfId'

// modifiable fields only.
export interface ICTStoryHasBackground
  extends Omit<GQLStoryHasBackground, 'shfId'> {
  shfId: number | undefined
}

export interface IStoryHasBackgroundListableOption extends ODListableOption {
  filter: string
  storyId: number | null
}
