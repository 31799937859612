import { ODListableOption } from '@odc/od-react-belt'
import { GQLEnding } from '../../@types/server'

export const EndingPrimaryKey = 'endingId'

// modifiable fields only.
export interface ICTEnding extends Omit<GQLEnding, 'endingId'> {
  endingId: number | undefined
}

export interface IEndingListableOption extends ODListableOption {
  filter: string
  storyId: number | null
}
