import React, { ReactNode } from 'react'
import uuid from 'uuid'
import { ConfirmInput } from './ConfirmInput'
import { ConfirmModal, IConfirmModalProps } from './ConfirmModal'
import {
  IConfirmInputPropsExternal,
  IConfirmModalPropsExternal,
  IODModalContextProviderProps,
  IODModalContextType,
} from './types'

function createModalContext() {
  const Context: React.Context<IODModalContextType> = React.createContext<IODModalContextType>(
    {} as IODModalContextType
  )

  const AppProvider: React.FC<IODModalContextProviderProps> = (props) => {
    const { children } = props

    const [components, setComponents] = React.useState<
      Array<{ render: (props: any) => ReactNode; options: any; key: string }>
    >([])

    const addModalComponent = React.useCallback(
      (render: (key: string, fullOptions: any) => ReactNode, externalOptions: any): Promise<any> => {
        return new Promise((resolve) => {
          const key = uuid.v4()
          const onConfirm = (v: any) => {
            resolve(v)
            setComponents((list) => {
              const item = list.find((v) => v.key === key)
              if (item) {
                item.options = { ...item.options, isOpen: false }
                return [...list]
              }
              return list
            })

            setTimeout(() => {
              // just wait for any animation to end.
              setComponents((list) => {
                return [...list.filter((i) => i.key !== key)]
              })
            }, 3000)
          }
          setComponents((list) => {
            return [
              ...list,
              {
                render: (options: IConfirmModalProps) => render(key, options),
                key,
                options: { ...externalOptions, onConfirm, isOpen: true, key },
              },
            ]
          })
        })
      },
      []
    )

    const confirm = React.useCallback(
      (options: IConfirmModalPropsExternal): Promise<boolean> => {
        return addModalComponent(
          (key: string, options: IConfirmModalProps) => <ConfirmModal {...options} key={key} />,
          options
        )
      },
      [addModalComponent]
    )

    const confirmInput = React.useCallback(
      (options: IConfirmInputPropsExternal): Promise<string | undefined> => {
        return addModalComponent((key, options) => <ConfirmInput {...options} key={key} />, options)
      },
      [addModalComponent]
    )

    const context: IODModalContextType = {
      confirm,
      confirmInput,
    }

    return (
      <Context.Provider value={context}>
        {children}
        <div>
          {components.map(({ render, options }) => {
            return render(options)
          })}
        </div>
      </Context.Provider>
    )
  }

  return { Context, Provider: AppProvider }
}

const { Context, Provider } = createModalContext()
export const ODModalProvider = Provider

export function useODModalContext(): IODModalContextType {
  return React.useContext(Context)
}
