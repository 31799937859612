"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2RenderBlockStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const DOPlayerReport2RenderBlock_1 = require("./DOPlayerReport2RenderBlock");
const DOPlayerReport2RenderBlockEditor_1 = require("./DOPlayerReport2RenderBlockEditor");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOPlayerReport2RenderBlockStore {
    constructor(rootStore, ide) {
        this.data = {};
        this.rootStore = rootStore;
        this.ide = ide;
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            merge: mobx_1.action,
            all: mobx_1.computed,
        });
    }
    async create(data) {
        var _a, _b, _c, _d, _e;
        try {
            const res = await this.rootStore.di.server.createPlayerReport2RenderBlock({
                name: data.name,
                desc: (_a = data.desc) !== null && _a !== void 0 ? _a : '',
                code: (_b = data.code) !== null && _b !== void 0 ? _b : '',
                codeSample: (_c = data.codeSample) !== null && _c !== void 0 ? _c : '',
                sampleData: (_d = data.sampleData) !== null && _d !== void 0 ? _d : '',
                isHidden: (_e = data.isHidden) !== null && _e !== void 0 ? _e : false,
            });
            return this.merge(res);
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    getById(prbId) {
        return this.data[prbId];
    }
    getByName(name) {
        return Object.values(this.data).find(rb => rb.name === name);
    }
    async removeById(prbId) {
        try {
            const res = await this.rootStore.di.server.removePlayerReport2RenderBlock(prbId);
            if (res.ok) {
                (0, mobx_1.runInAction)(() => {
                    delete this.data[prbId];
                });
                this.rootStore.di.showMessage(trans('legacy_DOPlayerReport2FunctionStore_It_has_been_deleted'));
            }
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    merge(data) {
        const id = data.prbId;
        if (this.data[id]) {
            this.data[id].merge(data);
        }
        else {
            this.data[id] = new DOPlayerReport2RenderBlock_1.DOPlayerReport2RenderBlock(this, data, this.ide);
        }
        return this.data[id];
    }
    get all() {
        return Object.values(this.data).sort((a, d) => a.prbId - d.prbId);
    }
    startEditing(prbId) {
        const e = this.getById(prbId);
        if (!e) {
            throw new Error(`Cannot find report render block id : ${prbId}`);
        }
        return new DOPlayerReport2RenderBlockEditor_1.DOPlayerReport2RenderBlockEditor(e, this.ide.env);
    }
}
exports.DOPlayerReport2RenderBlockStore = DOPlayerReport2RenderBlockStore;
