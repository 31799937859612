import { ODModalSize, usePickerTableModal } from '@odc/od-react-belt'
import React from 'react'
import { GQLStoryProperty } from '../../../@types/server'
import { Utils } from '../../../utils/utils'
import { IStoryPropertyListableOption } from '../StoryPropertyCommon'
import { useStoryPropertyListDataLoader } from './StoryPropertyListDataLoader'

const THeads = ['속성 아이디', '속성명', '속성 타입', '속성 최대값', '이미지 파일']

export function useStoryPropertyPickerModal(storyId: number) {
  const dataLoader = useStoryPropertyListDataLoader(storyId)

  return usePickerTableModal<GQLStoryProperty, IStoryPropertyListableOption>({
    size: ODModalSize.XLarge,
    rounded: false,
    pickDefaultOptions: {
      confirmButtonTitle: '추가',
      title: '속성 선택',
    },
    dataLoader,
    singleSelection: true,
    keyExtractor: (v) => v.propId,
    numColumns: THeads.length,
    renderTH: (index: number) => THeads[index],
    renderTD: (index: number, value) => {
      switch (index) {
        case 0:
          return value.propId
        case 1:
          return value.propName
        case 2:
          return Utils.formatStoryPropertyType(value.propType)
        case 3:
          return value.maxValue ?? '-'
        case 4:
          return (
            <>
              {value.imageFile?.link ? (
                <img src={value.imageFile!.link} width={100} height={100} alt="story property" />
              ) : (
                <span>미등록</span>
              )}
            </>
          )
        default:
          return '-'
      }
    },
  })
}
