import * as React from 'react'
import { useAppContext } from '../context/AppContext'
import { AdminSiteNavs } from '../routes/AdminNavs'
import { AdminRoutes } from '../routes/AdminRoutes'
import { SiteUrls } from '../urls'
import { CommonMainLayout } from './CommonMainLayout'
import { PasswordTooOldContainer } from './user/PasswordTooOldContainer'

export const AdminMainLayout: React.FC = () => {
  const {
    state: { passwordTooOld },
  } = useAppContext()

  return (
    <>
      {passwordTooOld && <PasswordTooOldContainer />}
      <CommonMainLayout
        routes={AdminRoutes}
        navs={AdminSiteNavs}
        defaultRedirect={SiteUrls.Admin.Main}
        isAdminLayout
        isInOrgAdmin={false}
      />
    </>
  )
}
