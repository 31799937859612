"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryEditor = exports.StoryTargetAgeToString = exports.StoryTargetAge = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const moment = require("moment");
const interface_1 = require("../../interface");
const EntityEditor_1 = require("../EntityEditor");
const fields_1 = require("../fields");
const DateWithNullableField_1 = require("../fields/DateWithNullableField");
const DBFileFieldWithUI_1 = require("../fields/DBFileFieldWithUI");
const SelectionFieldWithUI_1 = require("../fields/SelectionFieldWithUI");
const studioTutorial_1 = require("../studioTutorial");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
var StoryTargetAge;
(function (StoryTargetAge) {
    StoryTargetAge["All"] = "All";
    StoryTargetAge["Teen"] = "Teen";
    StoryTargetAge["Twenty"] = "Twenty";
    StoryTargetAge["Thirty"] = "Thirty";
    StoryTargetAge["Forty"] = "Forty";
    StoryTargetAge["OverFifty"] = "Fifty";
})(StoryTargetAge = exports.StoryTargetAge || (exports.StoryTargetAge = {}));
exports.StoryTargetAgeToString = {
    [StoryTargetAge.All]: trans('legacy_DOStoryEditor_all'),
    [StoryTargetAge.Teen]: trans('legacy_DOStoryEditor_teenagers'),
    [StoryTargetAge.Twenty]: trans('legacy_DOStoryEditor_20s'),
    [StoryTargetAge.Thirty]: trans('legacy_DOStoryEditor_30s'),
    [StoryTargetAge.Forty]: trans('legacy_DOStoryEditor_40s'),
    [StoryTargetAge.OverFifty]: trans('legacy_DOStoryEditor_50_and_above'),
};
const createCredit = (existingKey, existingValue) => {
    return {
        creditKey: new ui_1.InputWithValidation('creditKey', 'creditKey', '크레딧 타이틀', 'text', existingKey !== null && existingKey !== void 0 ? existingKey : '', null, {
            enableInPlaceEditing: false,
            noLabel: true,
            editable: true,
        }),
        creditValue: new ui_1.InputWithValidation('creditValue', 'creditValue', trans('legacy_DOStoryEditor_write_separated_by_commas_for_multiple_cases'), 'text', existingValue !== null && existingValue !== void 0 ? existingValue : '', null, {
            enableInPlaceEditing: false,
            noLabel: true,
            editable: true,
        }),
    };
};
class DOStoryEditor extends EntityEditor_1.EntityEditor {
    constructor(story) {
        var _a, _b, _c, _d, _e;
        super(story);
        this.defaultFirstNameField = null;
        this.defaultLastNameField = null;
        this.languageCodeField = null;
        this.isAdultField = null;
        this.bannerImageFileField = null;
        // USER_PRIV >= Admin 이상인 경우에만 값이 존재
        this.canPublishField = null;
        this.isSampleField = null;
        this.canUseAchievementField = null;
        this.canUserPlayerReportField = null;
        this.sectionTypeField = null;
        this.refStoryIdField = null;
        this.isShowHomeField = null;
        // USER_PRIV >= SuperAuthor 이상인 경우에만 값이 존재
        this.isOriginalField = null;
        this.existingServiceCountriesField = [];
        this.selectedServiceCountriesField = [];
        this.existingWeekdays = [];
        this.selectedWeekdays = [];
        this.allContentLanguageCodes = [];
        this.bundleCountriesInfo = null;
        this.selectedCountries = [];
        this.storyDetailInfoField = null;
        this.credits = [];
        this.story = null;
        this.story = story;
        const storyDetailInfoKey = Object.keys(story.storyDetailInfo);
        this._target.storyDetailInfo = story.storyDetailInfo;
        !!storyDetailInfoKey.length &&
            storyDetailInfoKey.forEach(key => {
                this.credits = this.credits.concat(createCredit(key, story.storyDetailInfo[key].join(',')));
            });
        const interactiveStringFields = [
            {
                key: 'defaultFirstName',
                options: {
                    label: trans('legacy_ko_main_char_name_def'),
                    placeholder: trans('legacy_DOPlayerReport2Editor_Input_your_name_please'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'defaultLastName',
                options: {
                    label: trans('legacy_ko_main_char_cast_def'),
                    placeholder: trans('legacy_DOStoryEditor_please_enter_gender'),
                    options: { noLabel: true },
                },
            },
        ];
        const stringFields = [
            {
                key: 'name',
                options: {
                    label: trans('legacy_DOChapterEditor_work_name'),
                    placeholder: trans('legacy_DOChapterEditor_enter_work_name'),
                    options: { noLabel: true },
                },
            },
            ...(story.isWebNovel ? [] : interactiveStringFields),
            {
                key: 'topic',
                options: {
                    label: trans('legacy_DOStoryEditor_theme'),
                    placeholder: trans('legacy_DOStoryEditor_please_enter_theme'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'oneLineDesc',
                options: {
                    label: trans('legacy_ALCreateNewStory_work_description'),
                    inputType: 'textarea',
                    placeholder: trans('legacy_DOStoryEditor_please_enter_work_description'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'shortDesc',
                options: {
                    label: trans('legacy_DOStoryEditor_one_line_description'),
                    placeholder: trans('legacy_DOStoryEditor_please_enter_one_line_description'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'targetAge',
                options: {
                    label: trans('legacy_DOStoryEditor_target_age'),
                    placeholder: trans('legacy_DOStoryEditor_target_age_example'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'storyDetailInfo',
                options: {
                    label: '작품 크레딧 설정',
                    placeholder: '',
                    options: { noLabel: true },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new fields_1.TextFieldWithUI(fld.key, () => story[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        this.nameField.input.setRequired(true);
        this.nameField.input.setAutoFocus();
        (_a = this.defaultFirstNameField) === null || _a === void 0 ? void 0 : _a.input.setRequired(true);
        (_b = this.defaultLastNameField) === null || _b === void 0 ? void 0 : _b.input.setRequired(true);
        const fileFields = [
            {
                key: 'mainImage',
                options: {
                    label: trans('legacy_DOStoryEditor_cover_image'),
                    options: { showLabel: false },
                },
            },
            {
                key: 'previewImage',
                options: {
                    label: trans('legacy_DOStoryEditor_unpublished_cover_image'),
                    options: { showLabel: false },
                },
            },
            {
                key: 'introImage',
                options: {
                    label: trans('legacy_DOStoryEditor_work_detail_1_1_image'),
                    options: { showLabel: false },
                },
            },
            {
                key: 'wideImage',
                options: {
                    label: trans('legacy_DOStoryEditor_work_horizontal_thumbnail_4_3_image'),
                    options: { showLabel: false },
                },
            },
            {
                key: 'mainCharacterImage',
                options: {
                    label: trans('legacy_DOStoryEditor_main_character_profile_photo'),
                    options: { showLabel: false },
                },
            },
            {
                key: 'teaserVideo',
                options: {
                    label: trans('legacy_DOStoryEditor_teaser_video'),
                    options: { showLabel: false },
                },
            },
        ];
        if (story.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.WEB_NOVEL) &&
            !!((_c = story.rootStore.loginStore.user) === null || _c === void 0 ? void 0 : _c.isSameOrGreaterThanAdmin)) {
            fileFields.push({
                key: 'bannerImage',
                options: {
                    label: trans('legacy_DOStoryEditor_banner_16_9_image'),
                    options: { showLabel: false },
                },
            });
        }
        fileFields.forEach(fld => {
            ;
            this[`${fld.key}FileField`] = new DBFileFieldWithUI_1.DBFileFieldWithUI(`${fld.key}File`, () => {
                const link = story[`${fld.key}Link`];
                return { link };
            }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        const filteredGenre = [
            core_1.CHALLENGE_STORY_GENRE.UNKNOWN,
            core_1.CHALLENGE_STORY_GENRE.MODERN_FANTASY,
        ];
        const isSameOrGreaterThanSuperAuthor = !!((_d = story.rootStore.loginStore.user) === null || _d === void 0 ? void 0 : _d.isSameOrGreaterThanSuperAuthor);
        if (!isSameOrGreaterThanSuperAuthor) {
            filteredGenre.push(core_1.CHALLENGE_STORY_GENRE.CONTEST_WINNER);
        }
        if (story.playType === core_1.GQLSTORY_PLAY_TYPE.Interactive) {
            filteredGenre.push(core_1.CHALLENGE_STORY_GENRE.PERIOD_DRAMA);
        }
        if (story.playType === core_1.GQLSTORY_PLAY_TYPE.EPUB) {
            filteredGenre.push(core_1.CHALLENGE_STORY_GENRE.SLICE_OF_LIFE);
        }
        const selectionFields = [
            {
                key: 'genre',
                options: {
                    label: trans('legacy_SPSCreateStoryModal_genre'),
                    selection: Object.values(core_1.CHALLENGE_STORY_GENRE)
                        .filter(item => !filteredGenre.includes(item))
                        .map(item => ({
                        name: core_1.STORY_GENRE_TRANSLATED[item],
                        value: item,
                    })),
                },
            },
            {
                key: 'onHiatus',
                options: {
                    label: trans('legacy_DOStoryEditor_is_it_on_break'),
                    selection: [],
                },
            },
            {
                key: 'isAdult',
                options: {
                    label: '연령 기준',
                    selection: [
                        {
                            name: '전체 이용가',
                            value: false,
                        },
                        {
                            name: '청소년 이용불가',
                            value: true,
                        },
                    ],
                },
            },
            {
                key: 'isFinished',
                options: {
                    label: trans('legacy_DOStoryEditor_is_it_finished'),
                    selection: [],
                },
            },
        ];
        if (isSameOrGreaterThanSuperAuthor) {
            const fieldsIfIsSameOrGreaterThanAdmin = [];
            if (this.isFeatureEnabledStoryWeekdays) {
                fieldsIfIsSameOrGreaterThanAdmin.push({
                    key: 'isOriginal',
                    options: {
                        label: trans('legacy_DOStoryEditor_is_it_original_work'),
                        selection: [
                            {
                                name: trans('legacy_DOStoryEditor_allowed'),
                                value: true,
                                description: '',
                            },
                            {
                                name: trans('legacy_DOStoryEditor_not_allowed'),
                                value: false,
                                description: '',
                            },
                        ],
                    },
                });
            }
            fieldsIfIsSameOrGreaterThanAdmin.forEach(item => {
                selectionFields.push(item);
            });
        }
        const isSameOrGreaterThanAdmin = !!((_e = story.rootStore.loginStore.user) === null || _e === void 0 ? void 0 : _e.isSameOrGreaterThanAdmin);
        if (isSameOrGreaterThanAdmin) {
            const interactiveSelectionFields = [
                {
                    key: 'canUseAchievement',
                    options: {
                        label: trans('legacy_DOStoryEditor_achievement_allowed'),
                        selection: [
                            {
                                name: trans('legacy_DOStoryEditor_allowed'),
                                value: true,
                                description: '',
                            },
                            {
                                name: trans('legacy_DOStoryEditor_not_allowed'),
                                value: false,
                                description: '',
                            },
                        ],
                    },
                },
                {
                    key: 'canUserPlayerReport',
                    options: {
                        label: trans('legacy_DOStoryEditor_player_report_allowed'),
                        selection: [
                            {
                                name: trans('legacy_DOStoryEditor_allowed'),
                                value: true,
                                description: '',
                            },
                            {
                                name: trans('legacy_DOStoryEditor_not_allowed'),
                                value: false,
                                description: '',
                            },
                        ],
                    },
                },
            ];
            const fieldsIfIsSameOrGreaterThanAdmin = [
                {
                    key: 'canPublish',
                    options: {
                        label: trans('legacy_DOStoryEditor_publication_allowed'),
                        selection: [
                            {
                                name: trans('legacy_SPAAuthorMainPageContainer_permit'),
                                value: true,
                                description: '',
                            },
                            {
                                name: trans('legacy_SPAAuthorMainPageContainer_non_permission'),
                                value: false,
                                description: '',
                            },
                        ],
                    },
                },
                {
                    key: 'isSample',
                    options: {
                        label: trans('legacy_DOStoryEditor_sample_open'),
                        selection: [
                            {
                                name: trans('legacy_SPAAuthorMainPageContainer_permit'),
                                value: true,
                                description: '',
                            },
                            {
                                name: trans('legacy_SPAAuthorMainPageContainer_non_permission'),
                                value: false,
                                description: '',
                            },
                        ],
                    },
                },
                {
                    key: 'isShowHome',
                    options: {
                        label: '전지면 노출 여부',
                        selection: [
                            {
                                name: '',
                                value: true,
                                description: '',
                            },
                            {
                                name: '',
                                value: false,
                                description: '',
                            },
                        ],
                    },
                },
                ...(story.isWebNovel ? [] : interactiveSelectionFields),
                {
                    key: 'sectionType',
                    options: {
                        label: trans('legacy_DOStoryEditor_work_classification'),
                        selection: [
                            {
                                name: trans('legacy_SPAStoryTable_official_work'),
                                value: core_1.StorySectionType.OnStage,
                                description: trans('legacy_SPAStoryTable_official_work'),
                            },
                            {
                                name: trans('legacy_SPAStoryMainPageContainer_treasure_box'),
                                value: core_1.StorySectionType.Dropped,
                                description: trans('legacy_SPAStoryMainPageContainer_treasure_box'),
                            },
                            {
                                name: trans('legacy_SPAStoryTable_global'),
                                value: core_1.StorySectionType.Global,
                                description: trans('legacy_SPAStoryTable_global'),
                            },
                            {
                                name: trans('legacy_DOStoryEditor_adult_verification'),
                                value: core_1.StorySectionType.Adult,
                                description: trans('legacy_DOStoryEditor_adult_verification'),
                            },
                            {
                                name: trans('legacy_DOStoryEditor_challenge_story_ugc'),
                                value: core_1.StorySectionType.UGC,
                                description: trans('legacy_DOStoryEditor_challenge_story_ugc'),
                            },
                        ],
                    },
                },
                {
                    key: 'refStoryId',
                    options: {
                        label: story.isWebNovel
                            ? trans('legacy_DOStoryEditor_same_interactive')
                            : trans('legacy_DOStoryEditor_same_webnovel'),
                        selection: [],
                        options: {
                            loadOptionsByInputValue: async (v) => {
                                var _a;
                                try {
                                    const res = await this.rootStore.di.server.listStoryByAdmin({
                                        page: 1,
                                        pageSize: 30,
                                        filter: !!v ? v : undefined,
                                        playType: story.isWebNovel
                                            ? core_1.GQLSTORY_PLAY_TYPE.Interactive
                                            : core_1.GQLSTORY_PLAY_TYPE.EPUB,
                                    });
                                    const list = res.list.map(item => ({
                                        name: `${item.storyId}: ${item.name}`,
                                        value: item.storyId,
                                    }));
                                    if (story.refStoryId &&
                                        !list.find(item => item.value === story.refStoryId)) {
                                        list.push({
                                            name: `${story.refStoryId}: ${(_a = story.refStoryName) !== null && _a !== void 0 ? _a : '-'}`,
                                            value: story.refStoryId,
                                        });
                                    }
                                    return list;
                                }
                                catch (ex) {
                                    this.rootStore.showError(ex);
                                    return [];
                                }
                            },
                        },
                    },
                },
            ];
            fieldsIfIsSameOrGreaterThanAdmin.forEach(item => {
                selectionFields.push(item);
            });
        }
        selectionFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new SelectionFieldWithUI_1.SelectionFieldWithUI(fld.key, () => story[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        if (this.isFeatureEnabledStoryWeekdays) {
            this.finishedAtField = new DateWithNullableField_1.DateWithNullableField('finishedAt', () => {
                if (!!this._target.finishedAt) {
                    return this._target.finishedAt;
                }
                return null;
            }, () => null);
        }
        const statusFieldOptions = [
            {
                name: trans('legacy_StoryStatusTabs_serialization'),
                value: core_1.STORY_STATUS_IN_STUDIO.Default,
            },
            {
                name: trans('legacy_DOStoryEditor_on_break'),
                value: core_1.STORY_STATUS_IN_STUDIO.OnHiatus,
            },
            {
                name: trans('legacy_SPSStoryPriceSettingModal_complete'),
                value: core_1.STORY_STATUS_IN_STUDIO.Finished,
            },
        ];
        if (this.isFeatureEnabledStoryWeekdays) {
            statusFieldOptions.push({
                name: trans('legacy_DOStoryEditor_completed_in_7_days'),
                value: core_1.STORY_STATUS_IN_STUDIO.WillBeFinished,
            });
        }
        this.statusField = new ui_1.SelectionInput('statusInStudio', trans('legacy_DOStoryEditor_serial_status'), (() => {
            const s = this._target;
            if (s.isFinished) {
                return core_1.STORY_STATUS_IN_STUDIO.Finished;
            }
            if (!s.isFinished &&
                !!s.finishedAt &&
                this.isFeatureEnabledStoryWeekdays) {
                return core_1.STORY_STATUS_IN_STUDIO.WillBeFinished;
            }
            if (s.onHiatus) {
                return core_1.STORY_STATUS_IN_STUDIO.OnHiatus;
            }
            return core_1.STORY_STATUS_IN_STUDIO.Default;
        })(), statusFieldOptions, {
            onChangeBeforeSubmit: value => {
                var _a, _b, _c, _d;
                switch (value) {
                    case core_1.STORY_STATUS_IN_STUDIO.Default:
                        this.onHiatusField.onChange(false);
                        this.isFinishedField.onChange(false);
                        if (this.isFeatureEnabledStoryWeekdays) {
                            (_a = this.finishedAtField) === null || _a === void 0 ? void 0 : _a.onChange(null);
                        }
                        break;
                    case core_1.STORY_STATUS_IN_STUDIO.OnHiatus:
                        this.onHiatusField.onChange(true);
                        this.isFinishedField.onChange(false);
                        if (this.isFeatureEnabledStoryWeekdays) {
                            (_b = this.finishedAtField) === null || _b === void 0 ? void 0 : _b.onChange(null);
                        }
                        break;
                    case core_1.STORY_STATUS_IN_STUDIO.Finished:
                        this.onHiatusField.onChange(false);
                        this.isFinishedField.onChange(true);
                        if (this.isFeatureEnabledStoryWeekdays) {
                            (_c = this.finishedAtField) === null || _c === void 0 ? void 0 : _c.onChange(null);
                        }
                        break;
                    case core_1.STORY_STATUS_IN_STUDIO.WillBeFinished:
                        if (this.isFeatureEnabledStoryWeekdays) {
                            this.onHiatusField.onChange(false);
                            this.isFinishedField.onChange(false);
                            (_d = this.finishedAtField) === null || _d === void 0 ? void 0 : _d.onChange(moment().toDate());
                        }
                        break;
                }
            },
        });
        // section-type 은 일단 고정으로 추후 고도화.
        (0, mobx_1.makeObservable)(this, {
            credits: mobx_1.observable,
            storyDetailInfoField: mobx_1.observable,
            selectedWeekdays: mobx_1.observable,
            existingWeekdays: mobx_1.observable,
            existingServiceCountriesField: mobx_1.observable,
            selectedServiceCountriesField: mobx_1.observable,
            toggleSelectedWeekdays: mobx_1.action,
            canSelectWeekday: mobx_1.computed,
            isAdultField: mobx_1.observable,
            isShowHomeField: mobx_1.observable,
            loadServiceCountries: mobx_1.action,
            setServiceCountries: mobx_1.action,
            selectedCountries: mobx_1.observable,
            languageCodeField: mobx_1.observable,
            bundleCountriesInfo: mobx_1.observable,
            addCredit: mobx_1.action,
            removeCredit: mobx_1.action,
        });
        this.loadServiceCountries(story.bundleId).catch();
        this.loadStoryWeekdays().catch();
        this.loadLanguageCode(story).catch();
    }
    get isFeatureEnabledStoryWeekdays() {
        return this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.STORY_WEEKDAYS);
    }
    get rootStore() {
        return this._target.rootStore;
    }
    get storyId() {
        return this._target.storyId;
    }
    get canSelectWeekday() {
        var _a, _b;
        if (!this.isFeatureEnabledStoryWeekdays) {
            return false;
        }
        return (!!((_a = this.rootStore.loginStore.user) === null || _a === void 0 ? void 0 : _a.isSameOrGreaterThanAdmin) &&
            ((_b = this.sectionTypeField) === null || _b === void 0 ? void 0 : _b.value) === core_1.StorySectionType.OnStage &&
            (this.statusField.value === core_1.STORY_STATUS_IN_STUDIO.Default ||
                this.statusField.value === core_1.STORY_STATUS_IN_STUDIO.OnHiatus));
    }
    addCredit() {
        this.credits = this.credits.concat(createCredit());
    }
    removeCredit(index) {
        if (this.credits.length === 1) {
            this.credits = [createCredit()];
            return;
        }
        const findIndex = this.credits.findIndex((_, i) => i === index);
        if (findIndex < 0) {
            return;
        }
        this.credits = this.credits.filter((_, i) => i !== findIndex);
        let filterdCredits = {};
        this.credits.forEach(credit => {
            filterdCredits = {
                ...filterdCredits,
                [credit.creditKey.value]: credit.creditValue.value.split(','),
            };
        });
        this._target.storyDetailInfo = filterdCredits;
    }
    async loadStoryWeekdays() {
        if (!this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.STORY_WEEKDAYS)) {
            return;
        }
        try {
            const res = await this.rootStore.di.server.listStoryWeekday({
                storyId: this._target.storyId,
            });
            (0, mobx_1.runInAction)(() => {
                this.existingWeekdays = res.list.map(item => item.weekday);
                this.selectedWeekdays = res.list.map(item => item.weekday);
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    toggleSelectedWeekdays(weekday) {
        const existing = this.selectedWeekdays.includes(weekday);
        if (existing) {
            this.selectedWeekdays = this.selectedWeekdays.filter(item => item !== weekday);
        }
        else {
            this.selectedWeekdays = [...this.selectedWeekdays, weekday];
        }
    }
    setServiceCountries(countries) {
        if (!this.bundleCountriesInfo)
            return;
        const newCountries = countries.map(countries => {
            return { code: countries };
        });
        this.bundleCountriesInfo.serviceCountries = newCountries;
        this.selectedServiceCountriesField = countries;
    }
    async loadLanguageCode(story) {
        var _a;
        try {
            const res = await this.rootStore.di.server.getAllContentLanguages();
            if (((_a = story.rootStore.loginStore.user) === null || _a === void 0 ? void 0 : _a.isSameOrGreaterThanAdmin) &&
                story.sectionType !== core_1.StorySectionType.UGC) {
                const languageOptions = res.map(language => {
                    return {
                        name: language.display,
                        value: language.code,
                        description: '',
                    };
                }) || [];
                this.languageCodeField = new SelectionFieldWithUI_1.SelectionFieldWithUI('languageCode', () => story.languageCode || '', () => null, {
                    label: '언어',
                    selection: languageOptions,
                });
            }
            else {
                this.languageCodeField = new fields_1.TextFieldWithUI('languageCode', () => { var _a; return ((_a = story.language) === null || _a === void 0 ? void 0 : _a.display) || ''; }, () => null, {
                    label: '언어',
                    options: { noLabel: true, editable: false },
                });
            }
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    async loadServiceCountries(bundleId) {
        try {
            if (!bundleId)
                return;
            const res = (await this.rootStore.di.server.getStoryBundleCountriesInfo(bundleId));
            (0, mobx_1.runInAction)(() => {
                this.bundleCountriesInfo = res;
                this.existingServiceCountriesField =
                    (res === null || res === void 0 ? void 0 : res.serviceCountries.map(c => c.code)) || [];
                this.selectedServiceCountriesField =
                    (res === null || res === void 0 ? void 0 : res.serviceCountries.map(c => c.code)) || [];
            });
        }
        catch (ex) {
            this.rootStore.showError(ex);
        }
    }
    async submitChanges(onError) {
        var _a, _b;
        let isChangedWeekday = false;
        this.credits.forEach(credit => {
            var _a;
            this._target.storyDetailInfo = {
                ...this._target.storyDetailInfo,
                [credit.creditKey.value]: credit.creditValue.value.split(','),
            };
            (_a = this.storyDetailInfoField) === null || _a === void 0 ? void 0 : _a.input.onChange(JSON.stringify({
                [credit.creditKey.value]: credit.creditValue.value.split(','),
            }));
        });
        // let isChangedstoryDetailInfo = false
        // if (
        //   Object.keys(this._target.storyDetailInfo).length !== this.credits.length
        // ) {
        //   isChangedstoryDetailInfo = true
        //   this.credits.forEach(credit => {
        //     this._target.storyDetailInfo = {
        //       [credit.creditKey.value]: credit.creditValue.value,
        //     } as any
        //   })
        // }
        if (this.canSelectWeekday) {
            if (!((_a = this.story) === null || _a === void 0 ? void 0 : _a.isWebNovel) && this.selectedWeekdays.length > 2) {
                this.rootStore.showError(trans('legacy_EditStoryForm_max_two_serial_days'));
                return false;
            }
            (0, mobx_1.runInAction)(() => {
                this.isSubmitting = true;
            });
            // [1,2,3] -> [3,4,5]
            const existing = this.existingWeekdays;
            const addedWeekdays = this.selectedWeekdays.filter(item => !existing.includes(item));
            const deletedWeekdays = existing.filter(item => !this.selectedWeekdays.includes(item));
            const hasAddedWeekdays = addedWeekdays.length > 0;
            const hasDeletedWeekdays = deletedWeekdays.length > 0;
            try {
                if (hasAddedWeekdays) {
                    await Promise.all(addedWeekdays.map(item => this.rootStore.di.server.createStoryWeekday({
                        storyId: this._target.storyId,
                        weekday: item,
                    })));
                    isChangedWeekday = true;
                }
                if (hasDeletedWeekdays) {
                    await Promise.all(deletedWeekdays.map(item => this.rootStore.di.server.deleteStoryWeekday({
                        storyId: this._target.storyId,
                        weekday: item,
                    })));
                    isChangedWeekday = true;
                }
                if (hasAddedWeekdays || hasDeletedWeekdays) {
                    await this.loadStoryWeekdays();
                }
            }
            catch (ex) {
                onError === null || onError === void 0 ? void 0 : onError(ex);
            }
            finally {
                (0, mobx_1.runInAction)(() => {
                    this.isSubmitting = false;
                });
            }
        }
        const existingCountries = this.existingServiceCountriesField.sort();
        const selectedCountries = this.selectedServiceCountriesField.sort();
        const isChangeServiceCountries = !(existingCountries.length === selectedCountries.length &&
            existingCountries.every((value, index) => value === selectedCountries[index]));
        if (isChangeServiceCountries && this._target.bundleId) {
            (_b = this.story) === null || _b === void 0 ? void 0 : _b.setServiceCountries(selectedCountries.map(country => {
                return { code: country };
            }));
            this.rootStore.di.server.updateStoryBundleCountries({
                bundleId: this._target.bundleId,
                serviceCountryCodes: selectedCountries,
                storyId: this._target.storyId,
            });
        }
        let res;
        const isChangedFields = this.createChangeSet() !== null;
        // tslint:disable-next-line:prefer-conditional-expression
        if (this.canSelectWeekday && isChangedWeekday && !isChangedFields) {
            res = true;
        }
        else if (isChangeServiceCountries && !isChangedFields) {
            res = true;
        }
        else {
            res = await super.submitChanges(onError);
        }
        if (res) {
            await this.rootStore.studioTutorialStore.markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.EDIT_STORY_INFORMATION);
        }
        return res;
    }
}
exports.DOStoryEditor = DOStoryEditor;
// storyNameInput!: IInputWithValidation<string>
// lastNameOfMainCharInput!: IInputWithValidation<string>
// firstNameOfMainCharInput!: IInputWithValidation<string>
// topicInput!: IInputWithValidation<string>
// descInput!: IInputWithValidation<string>
// shortDescInput!: IInputWithValidation<string>
// coverTextInput!: IInputWithValidation<string>
// coverImageInput!: IFileInput
// previewImageInput!: IFileInput
// introImageFileInput!: IFileInput
// teaserVideoFileInput!: IFileInput
// storySectionTypeInput!: ISelectionInput<GQLSTORY_SECTION_TYPE>
//
// targetAges: StoryTargetAge[] = [StoryTargetAge.All]
// isAgree: boolean = false
//
// constructor(story: IDOStory) {
//   this.story = story
//
//   this.targetAges = [StoryTargetAge.All]
//   this.initForInputs()
//
//   makeObservable(this, {
//     credits: observable,
//     targetAges: observable,
//     isAgree: observable,
//
//     setIsAgree: action,
//     setTargetAges: action,
//   })
// }
//
// setIsAgree(isAgree: boolean) {
//   this.isAgree = isAgree
// }
//
// setTargetAges(targetAge: StoryTargetAge) {
//   if (this.targetAges.includes(targetAge)) {
//     this.targetAges = this.targetAges.filter(
//       existing => existing !== targetAge
//     )
//
//     // 배열에 값이 존재하지 않는다면
//     if (this.targetAges.length === 0) {
//       this.targetAges = [StoryTargetAge.All]
//     }
//   } else {
//     if (targetAge === StoryTargetAge.All) {
//       this.targetAges = [StoryTargetAge.All]
//     } else {
//       this.targetAges = this.targetAges
//         .filter(existing => existing !== StoryTargetAge.All)
//         .concat(targetAge)
//     }
//   }
// }
//
// /** Todo: 존재하는 데이터가 있다면 보여주어야 함 */
// private initForInputs() {
//   this.coverImageInput = new FileInput('coverImage', '커버 이미지', null, {
//     showLabel: false,
//   })
//
//   this.previewImageInput = new FileInput(
//     'previewImage',
//     '미공개 커버이미지',
//     null,
//     { showLabel: false }
//   )
//
//   this.introImageFileInput = new FileInput(
//     'introImageFile',
//     '작품 상세 1:1 이미지',
//     null,
//     { showLabel: false }
//   )
//
//   this.teaserVideoFileInput = new FileInput('teaserVideo', '티저영상', null, {
//     showLabel: false,
//   })
//
//   this.storySectionTypeInput = new SelectionInput(
//     'storySectionType',
//     '작품 분류',
//     GQLSTORY_SECTION_TYPE.OnStage,
//     [
//       {
//         name: '정식 스토리',
//         value: GQLSTORY_SECTION_TYPE.OnStage,
//         description: '',
//       },
//       {
//         name: '도전 스토리',
//         value: GQLSTORY_SECTION_TYPE.Challenge,
//         description: '',
//       },
//     ]
//   )
// }
