"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RowType = void 0;
var RowType;
(function (RowType) {
    RowType["ModalTitle"] = "ModalTitle";
    RowType["MobileModalTitle"] = "MobileModalTitle";
    RowType["ModalFooter"] = "ModalFooter";
    RowType["RowWithColumns"] = "RowWithColumns";
})(RowType = exports.RowType || (exports.RowType = {}));
