"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTPause = void 0;
const mobx_1 = require("mobx");
const fields_1 = require("../../fields");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
class DOSTPause extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.hasModalForEdit = true;
        this.timeoutField = new fields_1.TextFieldWithUI('timeout', () => this.rootStore.serviceType === 'hb'
            ? this.st.timeout.toString()
            : (this.st.timeout / 1000).toString(), async (value) => {
            if (this.rootStore.serviceType === 'hb') {
                const Fixed = Number(Number(value).toFixed(1));
                await (!this.st.hbExtensionData
                    ? this.internalHbCreate(Fixed)
                    : this.internalHbUpdate(Fixed));
            }
            (0, mobx_1.runInAction)(() => {
                this.st.timeout =
                    Number(Number(value).toFixed(1)) *
                        (this.rootStore.serviceType === 'hb' ? 1 : 1000);
            });
        }, {
            options: {
                noLabel: true,
                minValueIfNumberType: 0,
            },
        });
        (0, mobx_1.makeObservable)(this, {
            timeoutInSeconds: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get timeoutInSeconds() {
        return this.rootStore.serviceType === 'hb'
            ? this.st.timeout
            : this.st.timeout / 1000;
    }
    cancelEditing() {
        if (this.rootStore.serviceType === 'hb' && !this.st.hbExtensionData) {
            this.parentBlock.removeLine(this);
        }
        super.cancelEditing();
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [...super.editorFields, this.timeoutField];
    }
    generateSheetColumnsAfterBackground() {
        const result = [
            this.st.timeout.toString(),
            '',
            '',
            '',
            '',
            '',
            '',
        ];
        if (!!this.executeConditionOfStatement) {
            result.push(this.executeConditionOfStatement);
        }
        return result;
    }
    async internalHbCreate(speed) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        try {
            const res = await this.rootStore.apiServer.message.create({
                kind: 'chatbot',
                sheetId: this.parentBlock.hbExtensionData
                    .sheetId,
                senderId: (_c = (_b = (_a = this.blockEditor) === null || _a === void 0 ? void 0 : _a.input.selectedCharacter) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : undefined,
                senderName: (_f = (_e = (_d = this.blockEditor) === null || _d === void 0 ? void 0 : _d.input.selectedCharacter) === null || _e === void 0 ? void 0 : _e.chrName) !== null && _f !== void 0 ? _f : undefined,
                senderProfileUrl: (_j = (_h = (_g = this.blockEditor) === null || _g === void 0 ? void 0 : _g.input.selectedCharacter) === null || _h === void 0 ? void 0 : _h.link) !== null && _j !== void 0 ? _j : undefined,
                turn: !!this.rootStore.isAlgorithmStore
                    ? undefined
                    : (_k = this.blockEditor) === null || _k === void 0 ? void 0 : _k.indexToAdd,
                data: {
                    type: 'typing',
                    speed,
                },
            });
            (0, mobx_1.runInAction)(() => {
                this.st.hbExtensionData = res;
            });
            return res;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw new Error(ex);
        }
    }
    async internalHbUpdate(speed) {
        var _a;
        if (!('hbExtensionData' in this.data) || !((_a = this.data.hbExtensionData) === null || _a === void 0 ? void 0 : _a.id)) {
            return null;
        }
        try {
            const res = await this.rootStore.apiServer.message.update(this.data.hbExtensionData.id, {
                type: 'typing',
                speed,
            });
            (0, mobx_1.runInAction)(() => {
                this.st.hbExtensionData = res;
            });
            return res;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw new Error(ex);
        }
    }
}
exports.DOSTPause = DOSTPause;
