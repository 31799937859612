import { AppBreadcrumb, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarMinimizer } from '@coreui/react'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ODAppSidebarNav } from '../components/sidebar/ODAppSidebarNav'
import { ODHeader } from '../components/ODHeader'
import { useAppContext } from '../context/AppContext'
import { ODRoute, ODSiteNav } from '../context/ODCommon'

type CommonMainLayoutProps = {
  routes: Array<ODRoute>
  navs: ODSiteNav
  defaultRedirect: string
  isAdminLayout: boolean
  isInOrgAdmin: number | null | false // 현재 특정 기관 관리자 페이지 안에 있나? (orgId)
  location?: any
}

export const CommonMainLayout: React.FC<CommonMainLayoutProps> = (props) => {
  const {
    state: { userProfile },
  } = useAppContext()

  const { routes, navs, defaultRedirect, isAdminLayout, isInOrgAdmin, location } = props

  const Loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  )

  return (
    <div className="app">
      <AppHeader fixed>
        <React.Suspense fallback={Loading}>
          <ODHeader
            name={userProfile?.name}
            profile={userProfile}
            isInAdmin={isAdminLayout}
            isInOrgAdmin={isInOrgAdmin}
          />
        </React.Suspense>
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <React.Suspense fallback={Loading}>
            <ODAppSidebarNav navConfig={navs} location={location} />
          </React.Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main" style={{ display: 'flex', flexDirection: 'column' }}>
          <AppBreadcrumb appRoutes={routes} />
          <div
            style={{
              display: 'flex',
              flexGrow: 3,
            }}
          >
            <React.Suspense fallback={Loading}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component || route.render ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      // @ts-ignore
                      name={route.name}
                      render={(props) =>
                        route.component ? <route.component message={route.name} {...props} /> : route.render!(props)
                      }
                    />
                  ) : null
                })}
                <Redirect to={defaultRedirect} />
              </Switch>
            </React.Suspense>
          </div>
        </main>
      </div>
    </div>
  )
}
