import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLHomeSection } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { SiteUrls } from '../../../urls'
import {
  HomeSectionPrimaryKey,
  IHomeSectionListableOption,
} from '../HomeSectionCommon'
import { useHomeSectionListDataLoader } from './HomeSectionListDataLoader'

interface IHomeSectionListableTableProps {}

export const HomeSectionListableTable: React.FC<
  IHomeSectionListableTableProps
> = (props) => {
  const { Provider, Context } = useODListableContext<
    GQLHomeSection,
    IHomeSectionListableOption
  >()
  const [token] = useCounter()
  const dataLoader = useHomeSectionListDataLoader()
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    console.log('@@redirect:', redirect)
    return <Redirect to={redirect} />
  }

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[HomeSectionPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="이름으로 검색"
              style={searchBoxStyle}
            />
            <Link
              to={SiteUrls.Admin.HomeSection.Create}
              style={{ textDecoration: 'none' }}
            >
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{ minWidth: 135, borderRadius: 5 }}
              >
                생성
              </ODButton>
            </Link>
          </div>
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>섹션 타이틀</th>
                <th>트랙킹 key</th>
                <th>섹션 타입</th>
                <th>커스텀 데이터</th>
              </tr>
            )}
            renderRow={(value: GQLHomeSection) => (
              <HoverTR
                key={value.sectionId}
                onClick={() =>
                  setRedirect(
                    SiteUrls.Admin.HomeSection.Edit(
                      value.sectionId,
                      value.type.toString()
                    )
                  )
                }
              >
                <td>{value.sectionId}</td>
                <td>{value.title}</td>
                <td>{value.key}</td>
                <td>{value.type.toString()}</td>
                <td>{value.customData}</td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
