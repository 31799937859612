import React from 'react'
import Select from 'react-select'
import { GQLListableChoice } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'

export type ChapterCommentChoiceSelectFilterType = {
  choiceId: number | null
  name: string
}

interface IChapterCommentChoiceSelectProps {
  chapterId: number | null
  value: number | null
  onChange: (value: number | null) => void
}

export const ChapterCommentChoiceSelect: React.FC<
  IChapterCommentChoiceSelectProps
> = (props) => {
  const { value, chapterId, onChange } = props
  const { listChoiceOfStory } = useStoryPlayAPIs()
  const [selectOptions, setSelectOptions] = React.useState<
    { label: string; value: number | null }[]
  >([])

  React.useEffect(() => {
    listChoiceOfStory({
      chapterId,
      page: 1,
      pageSize: 1000,
    }).then((result: GQLListableChoice) => {
      setSelectOptions([
        {
          label: '모든 선택지의 댓글',
          value: null,
        },
        ...result.list.map((choice) => ({
          label: `${choice.name}`,
          value: choice.choiceId,
        })),
      ])
    })
  }, [listChoiceOfStory, chapterId])

  return (
    <Select
      placeholder="선택지 선택"
      value={selectOptions.find((v) => v.value === value)}
      // @ts-ignore
      onChange={(v) => onChange(v.value)}
      options={selectOptions}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
