"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2Function = void 0;
const storeUtils_1 = require("../../utils/storeUtils");
class DOPlayerReport2Function {
    constructor(store, data) {
        this.desc = '';
        this.code = '';
        this.codeSample = '';
        this.paramDesc = '';
        this.store = store;
        this.prfId = data.prfId;
        this.merge(data);
    }
    merge(data) {
        const fields = [
            'name',
            'desc',
            'code',
            'codeSample',
            'paramDesc',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    get serverId() {
        return this.prfId;
    }
    get rootStore() {
        return this.store.rootStore;
    }
    async update(changeSet) {
        return this.rootStore.di.server.updateEntityForStudio(this, changeSet);
    }
}
exports.DOPlayerReport2Function = DOPlayerReport2Function;
