"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTFullWidthImage = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const TemplateImageWithOption_1 = require("../../../template/TemplateImageWithOption");
const FullWidthImageOptionField_1 = require("../../fields/FullWidthImageOptionField");
const FullWidthImageFullScreenOptionField_1 = require("../../fields/FullWidthImageFullScreenOptionField");
const StudioFileFieldWithUI_1 = require("../../fields/StudioFileFieldWithUI");
const ui_1 = require("../../ui");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
// const SAMPLE_OVERLAY: FULL_WIDTH_IMAGE_STATEMENT = JSON.parse(
//   `{"statementType":"FullWidthImage","width":4000,"height":6000,"link":"http://localhost:8082/files/StudioFile/5da03c9e-147f-4979-be5b-95e957e66ee8","background":"","sourceLine":1649932155547508,"option":{"effects":[{"content":"가나다라가나다라가나다라가나다라","contentWithEffect":[{"text":"가나다라"}],"align":"center","operation":"TextOverlay","leftTop":[187.00000000000006,162],"rightTop":[2867,162],"leftBottom":[187.00000000000006,838],"rightBottom":[2867,838]}]}}`
// )
class DOSTFullWidthImage extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        var _a;
        super(data, block, uniqueId);
        this.hasModalForEdit = true;
        this.isImageFileAcceptor = false;
        this.isPCImageSetting = false;
        this.isMobileImageSetting = false;
        // 현재 삽화 이미지의 st.images 가 json 형태로 내려오는 경우가 있다. 해당 이슈가 꽤 많을 것으로 예상되므로
        // 다음과 같은 방어 코드를 추가한다.
        if (!!this.st.images && typeof this.st.images === 'string') {
            this.st.images = JSON.parse(this.st.images);
        }
        this.imageField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('image', () => ({
            link: this.st.link,
            width: this.st.width,
            height: this.st.height,
        }), file => {
            (0, mobx_1.runInAction)(() => {
                var _a, _b, _c;
                this.st.link = (_a = file === null || file === void 0 ? void 0 : file.link) !== null && _a !== void 0 ? _a : '';
                this.st.width = (_b = file === null || file === void 0 ? void 0 : file.width) !== null && _b !== void 0 ? _b : 0;
                this.st.height = (_c = file === null || file === void 0 ? void 0 : file.height) !== null && _c !== void 0 ? _c : 0;
            });
        }, this.helper.story, {
            label: trans('legacy_TextStore_illustration'),
            options: {
                imageLink: (_a = this.link) !== null && _a !== void 0 ? _a : undefined,
                theme: ui_1.FileTheme.OnlySupportedCommonImageModal,
                aspectRatio: {
                    w: 1.65,
                    h: 1,
                },
                accept: 'image/png, image/jpeg',
            },
        });
        this.imagesField = new FullWidthImageOptionField_1.FullWidthImageOptionField('images', () => this.st.images, images => {
            (0, mobx_1.runInAction)(() => {
                this.st.images = images;
            });
        });
        this.fullScreenOptionField = new FullWidthImageFullScreenOptionField_1.FullWidthImageFullScreenOptionField('fullScreenOption', () => this.st.fullScreenOptions, option => {
            (0, mobx_1.runInAction)(() => {
                this.st.fullScreenOptions = option;
            });
        });
        (0, mobx_1.makeObservable)(this, {
            isMobileImageSetting: mobx_1.observable,
            isPCImageSetting: mobx_1.observable,
            link: mobx_1.computed,
            width: mobx_1.computed,
            height: mobx_1.computed,
            option: mobx_1.computed,
            isTextOverlayImage: mobx_1.computed,
            textOverlayInfo: mobx_1.computed,
            bubbleEffect: mobx_1.computed,
            messageWithEffect: mobx_1.computed,
        });
    }
    createMobileImageField() {
        var _a, _b, _c;
        const mobileImageField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('mobileImage', () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            return ({
                link: (_d = (_b = (_a = this.imagesField.value) === null || _a === void 0 ? void 0 : _a.storygameMobile.link) !== null && _b !== void 0 ? _b : (_c = this.imageField.value) === null || _c === void 0 ? void 0 : _c.link) !== null && _d !== void 0 ? _d : '',
                width: (_h = (_f = (_e = this.imagesField.value) === null || _e === void 0 ? void 0 : _e.storygameMobile.width) !== null && _f !== void 0 ? _f : (_g = this.imageField.value) === null || _g === void 0 ? void 0 : _g.width) !== null && _h !== void 0 ? _h : 0,
                height: (_m = (_k = (_j = this.imagesField.value) === null || _j === void 0 ? void 0 : _j.storygameMobile.height) !== null && _k !== void 0 ? _k : (_l = this.imageField.value) === null || _l === void 0 ? void 0 : _l.height) !== null && _m !== void 0 ? _m : 0,
            });
        }, () => null, this.helper.story, {
            label: '채팅소설 & 스토리게임 (MO)',
            options: {
                showLabel: true,
                theme: ui_1.FileTheme.OnlySupportedCommonImageModal,
                accept: 'image/png, image/jpeg',
                aspectRatio: { w: 9, h: 16 },
            },
        });
        if ((_b = (_a = this.imagesField.value) === null || _a === void 0 ? void 0 : _a.storygameMobile.link) !== null && _b !== void 0 ? _b : (_c = this.imageField.value) === null || _c === void 0 ? void 0 : _c.link) {
            this.isMobileImageSetting = true;
        }
        mobileImageField.onChange = newValue => {
            (0, mobx_1.runInAction)(() => {
                var _a, _b, _c, _d;
                this.isMobileImageSetting = !!newValue;
                mobileImageField.value = newValue;
                if ((_a = this.imagesField.value) === null || _a === void 0 ? void 0 : _a.storygameMobile) {
                    this.imagesField.onChange({
                        ...this.imagesField.value,
                        storygameMobile: {
                            ...this.imagesField.value.storygameMobile,
                            link: (_b = newValue === null || newValue === void 0 ? void 0 : newValue.link) !== null && _b !== void 0 ? _b : '',
                            width: (_c = newValue === null || newValue === void 0 ? void 0 : newValue.width) !== null && _c !== void 0 ? _c : 0,
                            height: (_d = newValue === null || newValue === void 0 ? void 0 : newValue.height) !== null && _d !== void 0 ? _d : 0,
                        },
                    });
                }
            });
        };
        return mobileImageField;
    }
    createPCImageField() {
        var _a, _b, _c;
        const pcImageField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('pcImage', () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            return ({
                link: (_d = (_b = (_a = this.imagesField.value) === null || _a === void 0 ? void 0 : _a.storygamePC.link) !== null && _b !== void 0 ? _b : (_c = this.imageField.value) === null || _c === void 0 ? void 0 : _c.link) !== null && _d !== void 0 ? _d : '',
                width: (_h = (_f = (_e = this.imagesField.value) === null || _e === void 0 ? void 0 : _e.storygamePC.width) !== null && _f !== void 0 ? _f : (_g = this.imageField.value) === null || _g === void 0 ? void 0 : _g.width) !== null && _h !== void 0 ? _h : 0,
                height: (_m = (_k = (_j = this.imagesField.value) === null || _j === void 0 ? void 0 : _j.storygamePC.height) !== null && _k !== void 0 ? _k : (_l = this.imageField.value) === null || _l === void 0 ? void 0 : _l.height) !== null && _m !== void 0 ? _m : 0,
            });
        }, () => null, this.helper.story, {
            label: '스토리게임 (PC)',
            options: {
                showLabel: true,
                theme: ui_1.FileTheme.OnlySupportedCommonImageModal,
                accept: 'image/png, image/jpeg',
                aspectRatio: { w: 16, h: 9 },
            },
        });
        if ((_b = (_a = this.imagesField.value) === null || _a === void 0 ? void 0 : _a.storygamePC.link) !== null && _b !== void 0 ? _b : (_c = this.imageField.value) === null || _c === void 0 ? void 0 : _c.link) {
            this.isPCImageSetting = true;
        }
        pcImageField.onChange = newValue => {
            (0, mobx_1.runInAction)(() => {
                var _a, _b, _c, _d;
                this.isPCImageSetting = !!newValue;
                pcImageField.value = newValue;
                if ((_a = this.imagesField.value) === null || _a === void 0 ? void 0 : _a.storygamePC) {
                    this.imagesField.onChange({
                        ...this.imagesField.value,
                        storygamePC: {
                            ...this.imagesField.value.storygamePC,
                            link: (_b = newValue === null || newValue === void 0 ? void 0 : newValue.link) !== null && _b !== void 0 ? _b : '',
                            width: (_c = newValue === null || newValue === void 0 ? void 0 : newValue.width) !== null && _c !== void 0 ? _c : 0,
                            height: (_d = newValue === null || newValue === void 0 ? void 0 : newValue.height) !== null && _d !== void 0 ? _d : 0,
                        },
                    });
                }
            });
        };
        return pcImageField;
    }
    createDefaultFullScreenOption() {
        this.fullScreenOptionField.onChange({
            fillMethod: core_1.ScreenFillMethod.AspectRatio,
            showTransition: core_1.TransitionStyle.None,
            hideTransition: core_1.TransitionStyle.None,
            duration: 300,
        });
    }
    get st() {
        return this.data;
    }
    get images() {
        return this.st.images;
    }
    get link() {
        var _a, _b;
        return (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.interactive.link) !== null && _b !== void 0 ? _b : this.st.link;
    }
    get width() {
        var _a, _b;
        return (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.interactive.width) !== null && _b !== void 0 ? _b : this.st.width;
    }
    get height() {
        var _a, _b;
        return (_b = (_a = this.images) === null || _a === void 0 ? void 0 : _a.interactive.height) !== null && _b !== void 0 ? _b : this.st.height;
    }
    get option() {
        return this.st.option;
    }
    get isTextOverlayImage() {
        var _a, _b, _c;
        return (((_c = (_b = (_a = this.st.option) === null || _a === void 0 ? void 0 : _a.effects) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.operation) === __1.ImageOperationType.TextOverlay);
    }
    get textOverlayInfo() {
        const op = this.st.option;
        if (op &&
            op.effects.length > 0 &&
            op.effects[0].operation === __1.ImageOperationType.TextOverlay) {
            return op.effects[0];
        }
        return null;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [
            ...super.editorFields,
            this.imageField,
            this.imagesField,
            this.fullScreenOptionField,
        ];
    }
    //
    // IStudioEditor implementations
    //
    startEditing() {
        const editor = this.blockEditor;
        if (!editor) {
            return;
        }
        // 편집방법이 ImageOverlay 인 경우와 아닌 경우가 다르다.
        if (!this.isTextOverlayImage) {
            editor.setShowEditModalFor(this);
            return;
        }
        const t = new TemplateImageWithOption_1.TemplateImageWithOption(editor, this);
        this.rootStore.autoLayoutManager.addActionChain(t.buildActionChain());
    }
    /**
     * ODSImageEditModal 용 interface
     */
    onChangeFile(file) {
        var _a;
        (_a = this.imageField) === null || _a === void 0 ? void 0 : _a.onChange(file);
        this.rootStore.studioTutorialStore
            .markUserStudioTutorialProgress(__1.GA4_EVENT_NAME.SET_FULL_WIDTH_IMAGE)
            .then();
    }
    validate() {
        var _a;
        const res = super.validate();
        const pushValidationResult = (type) => {
            res.push({
                type,
                extra: {},
                severity: __1.StudioValidationSeverity.Error,
                source: this,
                stack: [],
            });
        };
        const fullScreenOptionValue = this.st.fullScreenOptions;
        if (!!fullScreenOptionValue) {
            if (!!((_a = fullScreenOptionValue.fullScreenImageLink) === null || _a === void 0 ? void 0 : _a.trim())) {
                if (isNaN(Number(fullScreenOptionValue.width))) {
                    pushValidationResult(__1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidWidth);
                }
                if (isNaN(Number(fullScreenOptionValue.height))) {
                    pushValidationResult(__1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidHeight);
                }
            }
            if (isNaN(Number(fullScreenOptionValue.duration))) {
                pushValidationResult(__1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidDuration);
            }
            if (!Object.values(core_1.TransitionStyle).includes(fullScreenOptionValue.showTransition)) {
                pushValidationResult(__1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidShowTransition);
            }
            if (!Object.values(core_1.TransitionStyle).includes(fullScreenOptionValue.hideTransition)) {
                pushValidationResult(__1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidHideTransition);
            }
            if (!Object.values(core_1.ScreenFillMethod).includes(fullScreenOptionValue.fillMethod)) {
                pushValidationResult(__1.StudioValidationType.FullWidthImageFullScreenOptionHasInvalidFillMethod);
            }
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = res));
        return res;
    }
    generateSheetColumnsAfterBackground() {
        // [, 링크, 너비, 높이, 이미지 옵션]
        // 현재 삽화 이미지의 st.images 가 json 형태로 내려오는 경우가 있다. 해당 이슈가 꽤 많을 것으로 예상되므로
        // 다음과 같은 방어 코드를 추가한다.
        let images;
        if (!!this.st.images) {
            // tslint:disable-next-line:prefer-conditional-expression
            if (typeof this.st.images === 'object') {
                images = JSON.stringify(this.st.images);
            }
            else {
                images = this.st.images;
            }
        }
        const result = [
            '',
            this.link,
            this.width.toString(),
            this.height.toString(),
            this.option ? JSON.stringify(this.option) : '',
            !!this.st.fullScreenOptions // _condAction
                ? JSON.stringify(this.st.fullScreenOptions)
                : '',
            '',
            !!this.executeConditionOfStatement
                ? this.executeConditionOfStatement
                : '',
            images !== null && images !== void 0 ? images : '',
        ];
        return result;
    }
    get bubbleEffect() {
        return undefined;
    }
    get backgroundColor() {
        return undefined;
    }
    get edgeColor() {
        return undefined;
    }
    get messageWithEffect() {
        if (this.isTextOverlayImage) {
            return this.textOverlayInfo.contentWithEffect;
        }
        return [];
    }
    onContextMenuOpen(manager, ev, extra) {
        const menus = [];
        if (this.option) {
            menus.push({
                text: trans('legacy_DOSTFullWidthImage_copy_text_overlay_data'),
                onClick: async () => {
                    await this.di.copyToClipboard(JSON.stringify(this.option));
                    await this.di.showMessage(trans('legacy_DOSTFullWidthImage_copied_to_clipboard'));
                    manager.closeMenu();
                },
                type: __1.StudioMenuType.TextWithIcon,
            });
        }
        return {
            menus,
            x: ev.pageX,
            y: ev.pageY,
        };
    }
    //
    // IStudioTranslator interface
    //
    async getMessagesToTranslate() {
        return null;
    }
}
exports.DOSTFullWidthImage = DOSTFullWidthImage;
