import {
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  useCounter,
} from '@odc/od-react-belt'
import React from 'react'
import Select from 'react-select'
import { Card, CardBody, Col, Row } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLCreateOnboardingPopupInput,
  GQLOnboardingPopup,
  GQLUpdateOnboardingPopupInput,
  GQLSTATUS,
} from '../../../@types/server'
import {
  ONBOARDING_POPUP_INEQUALITY,
  ONBOARDING_POPUP_PROPERTY,
  ONBOARDING_POPUP_TYPE,
  STATUS,
} from '../../../common'
import { ToggleButtonWrapper } from '../../../components/commonStyle'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import { SPFormToggleButton } from '../../../components/SPFormToggleButton'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'

interface IOnboardingPopupPageProps {
  storyId: number
  popupId: number
}

interface IVisibleConditionsInput {
  property: string | ONBOARDING_POPUP_PROPERTY
  value: number
  inequality: ONBOARDING_POPUP_INEQUALITY
  name?: string
}

const ONBOARDING_POPUP_INEQUALITY_LIST: {
  value: ONBOARDING_POPUP_INEQUALITY
  label: string
}[] = [
  { value: ONBOARDING_POPUP_INEQUALITY.MoreThan, label: '> (크면)' },
  { value: ONBOARDING_POPUP_INEQUALITY.LessThan, label: '< (작으면)' },
  {
    value: ONBOARDING_POPUP_INEQUALITY.EqualOrMoreThan,
    label: '>= (같거나 크면)',
  },
  {
    value: ONBOARDING_POPUP_INEQUALITY.EqualOrLessThan,
    label: '<= (같거나 작으면)',
  },
  { value: ONBOARDING_POPUP_INEQUALITY.Equal, label: '== (같으면)' },
  { value: ONBOARDING_POPUP_INEQUALITY.NotEqual, label: '!= (다르면)' },
]

type Entity = GQLOnboardingPopup

function getValidationSchema(
  values: Partial<GQLCreateOnboardingPopupInput | GQLUpdateOnboardingPopupInput>
) {
  return Yup.object().shape({})
}

export const OnboardingPopupEditPage: React.FC<IOnboardingPopupPageProps> = (
  props
) => {
  const { storyId, popupId } = props
  const innerRef = React.useRef(null)
  const typeRef = React.useRef('')
  const [typeState, setTypeState] = React.useState<string>('')
  const setTypeRef = (v: string) => {
    typeRef.current = v
    setTypeState(typeRef.current)
  }

  const [chapterOption, setChapterOption] = React.useState<
    { label: string; value: number }[]
  >([])

  const {
    createOnboardingPopup,
    updateOnboardingPopup,
    deleteOnboardingPopup,
    getOnboardingPopup,
    getAllChaptersById: apiGetAllChapters,
    apiListCharacters,
    listStoryPropertyOfStory,
    listPlayerClass,
  } = useStoryPlayAPIs()

  const [token] = useCounter()
  const isCreating = popupId === 0
  const [visiblePropsConditions, setVisiblePropsConditions] = React.useState<
    IVisibleConditionsInput[]
  >([])
  const visiblePropsConditionsRef = React.useRef<IVisibleConditionsInput[]>([])
  const [visiblePlayerClassConditions, setVisiblePlayerClassConditions] =
    React.useState<IVisibleConditionsInput[]>([])
  const visiblePlayerClassConditionsRef = React.useRef<
    IVisibleConditionsInput[]
  >([])
  const [playerClassOptions, setPlayerClassOptions] = React.useState<
    {
      label: string
      value: number | null
    }[]
  >([])
  const selectedPlayerClassIdRef = React.useRef(undefined)
  const playerClassMapRef = React.useRef<{
    [key: number]: string
  }>({})

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      Partial<GQLCreateOnboardingPopupInput | GQLUpdateOnboardingPopupInput>
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (popupId) {
          return getOnboardingPopup({ id: popupId })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            showOrder: 1000,
            type: undefined,
            status: STATUS.Inactive as unknown as GQLSTATUS,
            onlyName: false,
            canUploadProfileImage: false,
            chapterId: 0,
            description: '',
            typeName: '',
            chapterName: '',
            characterName: '',
            canAICharacter: false,
            price: 0,
            maxAdCount: 0,
          }
        }
        if (data.visibleConditions) {
          visiblePropsConditionsRef.current = data.visibleConditions
            .filter(
              (v) => v.property !== ONBOARDING_POPUP_PROPERTY.__playerClass__
            )
            .map((v) => ({
              property: v.property,
              inequality: v.inequality,
              value: v.value,
            }))
          setVisiblePropsConditions([...visiblePropsConditionsRef.current])
          visiblePlayerClassConditionsRef.current = data.visibleConditions
            .filter(
              (v) => v.property === ONBOARDING_POPUP_PROPERTY.__playerClass__
            )
            .map((v) => ({
              property: ONBOARDING_POPUP_PROPERTY.__playerClass__,
              inequality: v.inequality,
              value: v.value,
              name: playerClassMapRef.current[v.value],
            }))
          setVisiblePlayerClassConditions([
            ...visiblePlayerClassConditionsRef.current,
          ])
        }

        const typeName = data.type
          ? {
              [ONBOARDING_POPUP_TYPE.Text]: '설명 팝업',
              [ONBOARDING_POPUP_TYPE.Choice]: '선택 팝업',
              [ONBOARDING_POPUP_TYPE.Profile]: '주인공 프로필 팝업',
              [ONBOARDING_POPUP_TYPE.Name]: '주인공 이름 팝업',
              [ONBOARDING_POPUP_TYPE.OtherName]: '상대역 이름 팝업',
              [ONBOARDING_POPUP_TYPE.OtherProfile]: '상대역 프로필 팝업',
              [ONBOARDING_POPUP_TYPE.StorygameProfile]:
                '스토리게임 등장인물 설정 팝업',
            }[data.type]
          : ''
        typeRef.current = data.type.toString()
        setTypeState(typeRef.current)
        return {
          popupId: data.popupId,
          type: data.type,
          showOrder: data.showOrder,
          chapterId: data.chapterId,
          chapterName: data.chapter.name,
          description: data.description,
          status: data.status,
          onlyName: data.onlyName,
          canUploadProfileImage: data.canUploadProfileImage,
          canAICharacter: data.canAICharacter,
          price: data.price,
          maxAdCount: data.maxAdCount,
          characterName: data.character && data.character.name,
          typeName,
        }
      },
      saveClientValueToServer: async (
        data: Partial<
          GQLCreateOnboardingPopupInput | GQLUpdateOnboardingPopupInput
        >
      ) => {
        const {
          type,
          showOrder,
          description,
          onlyName,
          canUploadProfileImage,
          canAICharacter,
          price,
          maxAdCount,
        } = data as GQLCreateOnboardingPopupInput
        const { status } = data as GQLUpdateOnboardingPopupInput
        const chapterId = selectedChapterRef.current
          ? selectedChapterRef.current
          : undefined
        const characterId =
          selectedCharacterRef.current &&
          ['OtherName', 'OtherProfile'].includes(type)
            ? selectedCharacterRef.current
            : undefined
        const playerClassConditions =
          visiblePlayerClassConditionsRef.current.map(({ name, ...p }) => p)
        if (popupId) {
          await updateOnboardingPopup({
            id: popupId,
            showOrder,
            description,
            canUploadProfileImage,
            status,
            onlyName,
            canAICharacter,
            price,
            maxAdCount,
            // @ts-ignore
            visibleConditions: [
              ...visiblePropsConditionsRef.current,
              ...playerClassConditions,
            ],
          })
          Utils.showSuccess('팝업이 수정되었습니다.', 'Success')
        } else {
          await createOnboardingPopup({
            type,
            showOrder,
            chapterId,
            storyId,
            description,
            onlyName,
            canUploadProfileImage,
            canAICharacter,
            price,
            maxAdCount,
            characterId,
            // @ts-ignore
            visibleConditions: [
              ...visiblePropsConditionsRef.current,
              ...playerClassConditions,
            ],
          })
          Utils.showSuccess('팝업이 생성되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.Edit(storyId, 11)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (popupId) {
          await deleteOnboardingPopup({ id: popupId })
          Utils.showSuccess('팝업이 삭제되었습니다.', 'Success')
        }
        return SiteUrls.Admin.Story.Edit(storyId, 11)
      },
      __innerReference: innerRef,
    }),
    [
      popupId,
      getOnboardingPopup,
      storyId,
      updateOnboardingPopup,
      createOnboardingPopup,
      token,
      deleteOnboardingPopup,
    ]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      Partial<GQLCreateOnboardingPopupInput | GQLUpdateOnboardingPopupInput>
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      Partial<GQLCreateOnboardingPopupInput | GQLUpdateOnboardingPopupInput>
    >(options)
  )

  React.useEffect(
    () => setOptions(createOptions()),
    [createOptions, createOnboardingPopup]
  )
  React.useEffect(() => {
    setContext(
      createODEntityEditorContext<
        Entity,
        Partial<GQLCreateOnboardingPopupInput | GQLUpdateOnboardingPopupInput>
      >(options)
    )
    listPlayerClass({ storyId, pageSize: 100 }).then((playerClass) => {
      setPlayerClassOptions(
        playerClass.list.map((p) => ({
          label: p.name,
          value: p.playerClassId,
        }))
      )
      playerClassMapRef.current = playerClass.list.reduce(
        (acc, p) => ({
          ...acc,
          [p.playerClassId]: p.name,
        }),
        {}
      )
    })
  }, [listPlayerClass, options, storyId])

  const selectedChapterRef = React.useRef(0)
  React.useEffect(() => {
    apiGetAllChapters({ id: storyId }).then((story) => {
      setChapterOption(
        story.allChapters
          .filter((c) => c.chapterIndex === 1)
          .map((chapter) => ({
            label: `${chapter.chapterIndex}화 ${chapter.name}`,
            value: chapter.chapterId,
          }))
      )
    })
  }, [apiGetAllChapters, storyId])

  const [characterOption, setCharacterOption] = React.useState<
    { label: string; value: number }[]
  >([])
  const selectedCharacterRef = React.useRef(0)

  React.useEffect(() => {
    apiListCharacters({ storyId, pageSize: 500 }).then((r) => {
      setCharacterOption(
        r.list.map((character) => ({
          label: character.name!,
          value: character.chrId,
        }))
      )
    })
  }, [apiListCharacters, storyId])

  const [propsOption, setPropsOption] = React.useState<
    { value: string; label: string }[]
  >([])
  const selectedPropRef = React.useRef('')
  React.useEffect(() => {
    listStoryPropertyOfStory({ storyId, pageSize: 500, page: 1 }).then(
      (prop) => {
        setPropsOption(
          prop.list
            .filter((p) => p.propType.toString() !== 'STRING')
            .map((p) => ({
              label: `${p.propName} (${p.propNameRaw})`,
              value: p.propName,
            }))
        )
      }
    )
  }, [listStoryPropertyOfStory, storyId])

  const [inequalityOption, setInequalityOption] = React.useState<
    { label: string; value: ONBOARDING_POPUP_INEQUALITY }[]
  >([])

  React.useEffect(() => {
    setInequalityOption(
      ONBOARDING_POPUP_INEQUALITY_LIST.map(({ value, label }) => ({
        label,
        value,
      }))
    )
  }, [])

  const handlePropsConditionCreate = () => {
    setVisiblePropsConditions(
      visiblePropsConditions.concat({
        property: selectedPropRef.current,
        value: 0,
        inequality: ONBOARDING_POPUP_INEQUALITY_LIST[0].value,
      })
    )
    visiblePropsConditionsRef.current = visiblePropsConditions.concat({
      property: selectedPropRef.current,
      value: 0,
      inequality: ONBOARDING_POPUP_INEQUALITY_LIST[0].value,
    })
  }

  const writePropsInequalityValue = (e: any, idx: number) => {
    visiblePropsConditions[idx].inequality = e.value
    setVisiblePropsConditions([...visiblePropsConditions])
    visiblePropsConditionsRef.current = [...visiblePropsConditions]
  }

  const writePropsConditionValue = (e: any) => {
    const idx = +e.target.name.split('_')[1]
    visiblePropsConditions[idx].value = +e.target.value
    setVisiblePropsConditions([...visiblePropsConditions])
    visiblePropsConditionsRef.current = [...visiblePropsConditions]
  }

  const removePropsCondition = (e: any) => {
    const idx = +e.target.name.split('_')[2]
    visiblePropsConditions.splice(idx, 1)
    setVisiblePropsConditions([...visiblePropsConditions])
    visiblePropsConditionsRef.current = [...visiblePropsConditions]
  }

  const handlePlayerClassConditionCreate = () => {
    setVisiblePlayerClassConditions(
      visiblePlayerClassConditions.concat({
        property: ONBOARDING_POPUP_PROPERTY.__playerClass__,
        value: selectedPlayerClassIdRef.current!,
        inequality: ONBOARDING_POPUP_INEQUALITY_LIST[4].value,
        name: playerClassMapRef.current[selectedPlayerClassIdRef.current!],
      })
    )
    visiblePropsConditionsRef.current = visiblePropsConditions.concat({
      property: ONBOARDING_POPUP_PROPERTY.__playerClass__,
      value: selectedPlayerClassIdRef.current!,
      inequality: ONBOARDING_POPUP_INEQUALITY_LIST[4].value,
    })
  }

  const writePlayerClassInequalityValue = (e: any, idx: number) => {
    visiblePlayerClassConditions[idx].inequality = e.value
    setVisiblePlayerClassConditions([...visiblePlayerClassConditions])
    visiblePlayerClassConditionsRef.current = [...visiblePlayerClassConditions]
  }

  const removePlayerClassCondition = (e: any) => {
    const idx = +e.target.name.split('_')[3]
    visiblePlayerClassConditions.splice(idx, 1)
    setVisiblePlayerClassConditions([...visiblePlayerClassConditions])
    visiblePlayerClassConditionsRef.current = [...visiblePlayerClassConditions]
  }

  const title = !popupId ? '온보딩 팝업 생성' : '온보딩 팝업 수정'

  return (
    <>
      <Card>
        <CardBody>
          <Provider title={title}>
            {!isCreating && (
              <>
                <ODEntityInput
                  keyPath="popupId"
                  label="온보딩 팝업 아이디"
                  name="popupId"
                  placeholder="온보딩 팝업 아이디 (자동부여)"
                  inputType="text"
                  inputProps={{ disabled: true }}
                />
                <ODEntityLabeled name="챕터명" label="챕터명">
                  <ODEntityRaw
                    name="chapterName"
                    keyPath="chapterName"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            {isCreating && (
              <>
                <ODEntityLabeled name={'온보딩 챕터'} label={'온보딩 챕터'}>
                  <Select
                    isSearchable={true}
                    placeholder="온보딩 팝업이 나타날 챕터"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedChapterRef.current = v.value
                      }
                    }}
                    options={chapterOption}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </ODEntityLabeled>

                <ODEntityLabeled
                  label="온보딩 팝업 타입"
                  name="온보딩 팝업 타입"
                >
                  <ToggleButtonWrapper>
                    <SPFormToggleButton
                      name="type"
                      keyPath="type"
                      value={ONBOARDING_POPUP_TYPE.Choice}
                      onClick={setTypeRef}
                    >
                      선택 팝업
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="type"
                      keyPath="type"
                      value={ONBOARDING_POPUP_TYPE.Text}
                      onClick={setTypeRef}
                    >
                      설명 팝업
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="type"
                      keyPath="type"
                      value={ONBOARDING_POPUP_TYPE.Name}
                      onClick={setTypeRef}
                    >
                      주인공 이름 팝업
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="type"
                      keyPath="type"
                      value={ONBOARDING_POPUP_TYPE.OtherName}
                      onClick={setTypeRef}
                    >
                      상대역 이름 팝업
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="type"
                      keyPath="type"
                      value={ONBOARDING_POPUP_TYPE.Profile}
                      onClick={setTypeRef}
                    >
                      주인공 프로필 팝업
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="type"
                      keyPath="type"
                      value={ONBOARDING_POPUP_TYPE.OtherProfile}
                      onClick={setTypeRef}
                    >
                      상대역 프로필 팝업
                    </SPFormToggleButton>
                    <SPFormToggleButton
                      name="type"
                      keyPath="type"
                      value={ONBOARDING_POPUP_TYPE.StorygameProfile}
                      onClick={setTypeRef}
                    >
                      스토리게임 등장인물 팝업
                    </SPFormToggleButton>
                  </ToggleButtonWrapper>
                </ODEntityLabeled>
              </>
            )}
            {['Name', 'OtherName'].includes(typeState) && (
              <ODEntityLabeled
                label="이름 설정"
                name="이름 설정(상대역/주인공 이름 팝업 선택시)"
              >
                <ToggleButtonWrapper>
                  <ODFormToggleButton
                    key="onlyNameFalse"
                    name="onlyName"
                    keyPath="onlyName"
                    value={false}
                  >
                    이름+성 입력
                  </ODFormToggleButton>
                  <ODFormToggleButton
                    key="onlyNameTrue"
                    name="onlyName"
                    keyPath="onlyName"
                    value={true}
                  >
                    이름만 입력
                  </ODFormToggleButton>
                </ToggleButtonWrapper>
              </ODEntityLabeled>
            )}
            {['Name', 'OtherName'].includes(typeState) && (
              <ODEntityLabeled
                label="사용자 프로필 업로드 설정"
                name="사용자 프로필 업로드 설정"
              >
                <ToggleButtonWrapper>
                  <ODFormToggleButton
                    key="canUploadProfileImageFalse"
                    name="canUploadProfileImage"
                    keyPath="canUploadProfileImage"
                    value={false}
                  >
                    불가능
                  </ODFormToggleButton>
                  <ODFormToggleButton
                    key="canUploadProfileImageTrue"
                    name="canUploadProfileImage"
                    keyPath="canUploadProfileImage"
                    value={true}
                  >
                    가능
                  </ODFormToggleButton>
                </ToggleButtonWrapper>
              </ODEntityLabeled>
            )}
            {['Name', 'OtherName'].includes(typeState) && (
              <ODEntityLabeled
                label="스겜 AI 캐릭터 활성화"
                name="스겜 AI 캐릭터 활성화"
              >
                <ToggleButtonWrapper>
                  <ODFormToggleButton
                    key="canAICharacterFalse"
                    name="canAICharacter"
                    keyPath="canAICharacter"
                    value={false}
                  >
                    비활성화
                  </ODFormToggleButton>
                  <ODFormToggleButton
                    key="canAICharacterTrue"
                    name="canAICharacter"
                    keyPath="canAICharacter"
                    value={true}
                  >
                    활성화
                  </ODFormToggleButton>
                </ToggleButtonWrapper>
              </ODEntityLabeled>
            )}
            {!isCreating && (
              <>
                <ODEntityLabeled name="타입" label="타입">
                  <ODEntityRaw
                    name="typeName"
                    keyPath="typeName"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            <ODEntityInput
              keyPath="description"
              label="지문"
              name="description"
              placeholder="지문"
              inputType="text"
            />
            {!isCreating && ['OtherProfile', 'OtherName'].includes(typeState) && (
              <>
                <ODEntityLabeled name="설정 캐릭터" label="설정 캐릭터">
                  <ODEntityRaw
                    name="characterName"
                    keyPath="characterName"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            {isCreating && ['OtherProfile', 'OtherName'].includes(typeState) && (
              <ODEntityLabeled
                name={'설정 캐릭터 선택'}
                label={'설정 캐릭터 선택'}
              >
                <Select
                  isSearchable={true}
                  placeholder="사용자가 이름을 설정할 캐릭터"
                  onChange={(v) => {
                    if (v) {
                      // @ts-ignore
                      selectedCharacterRef.current = v.value
                    }
                  }}
                  options={characterOption}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 2 }),
                  }}
                />
              </ODEntityLabeled>
            )}
            <ODEntityInput
              keyPath="showOrder"
              label="노출 우선순위"
              name="showOrder"
              placeholder="노출 우선순위"
              inputType="number"
            />
            {!isCreating && (
              <ODEntityLabeled
                label="온보딩 팝업 활성여부"
                name="온보딩 팝업 활성여부"
              >
                <ToggleButtonWrapper>
                  <SPFormToggleButton
                    name="status"
                    keyPath="status"
                    value={STATUS.Active}
                  >
                    활성
                  </SPFormToggleButton>
                  <SPFormToggleButton
                    name="status"
                    keyPath="status"
                    value={STATUS.Inactive}
                  >
                    비활성
                  </SPFormToggleButton>
                </ToggleButtonWrapper>
              </ODEntityLabeled>
            )}
            {['StorygameProfile'].includes(typeState) && (
              <>
                <ODEntityInput
                  keyPath="price"
                  label="가격설정"
                  name="price"
                  placeholder="가격설정"
                  inputType="number"
                />
                <ODEntityInput
                  keyPath="maxAdCount"
                  label="무료 광고 횟수"
                  name="maxAdCount"
                  placeholder="무료 광고 횟수"
                  inputType="number"
                />
              </>
            )}
            {!['Name'].includes(typeState) && (
              <>
                <ODEntityLabeled
                  name="팝업 노출 속성 조건 설정"
                  label="팝업 노출 속성 조건 설정"
                >
                  <Select
                    isSearchable={true}
                    placeholder="팝업 노출 조건으로 적용 될 속성"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedPropRef.current = v.value
                        handlePropsConditionCreate()
                      }
                    }}
                    options={propsOption}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            {visiblePropsConditions.length > 0 && (
              <>
                <ODEntityLabeled name="속성 조건" label="속성 조건">
                  <ul>
                    {visiblePropsConditions.map(
                      ({ property, value, inequality }, idx) => {
                        const inequalityValue =
                          ONBOARDING_POPUP_INEQUALITY_LIST.find(
                            ({ value: v }) => v === inequality
                          )
                        return (
                          <li key={`props_${idx}`}>
                            <Row style={{ marginBottom: 10 }}>
                              <Col md={2}>
                                <b>{property}</b>
                              </Col>
                              <Col md={3}>
                                <Select
                                  key={`select_props_${idx}`}
                                  isSearchable={true}
                                  placeholder="조건 선택"
                                  value={inequalityValue}
                                  onChange={(v) => {
                                    writePropsInequalityValue(v, idx)
                                  }}
                                  options={inequalityOption}
                                />
                              </Col>
                              <Col md={3}>
                                <input
                                  type="number"
                                  key={idx}
                                  name={`props_${idx}`}
                                  value={value}
                                  onChange={writePropsConditionValue}
                                />
                                &nbsp;&nbsp;&nbsp;
                                <input
                                  type="button"
                                  name={`remove_props_${idx}`}
                                  key={`remove_props_${idx}`}
                                  onClick={removePropsCondition}
                                  value="X"
                                />
                              </Col>
                            </Row>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </ODEntityLabeled>
              </>
            )}
            <hr />
            <br />
            {!['Name'].includes(typeState) && (
              <>
                <ODEntityLabeled
                  name="팝업 노출 플레이어 클래스 조건 설정"
                  label="팝업 노출 플레이어 클래스 조건 설정"
                >
                  <Select
                    isSearchable={true}
                    placeholder="팝업 노출 조건으로 적용 될 플레이어 클래스"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedPlayerClassIdRef.current = v.value
                        handlePlayerClassConditionCreate()
                      }
                    }}
                    options={playerClassOptions}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </ODEntityLabeled>
              </>
            )}
            {visiblePlayerClassConditions.length > 0 && (
              <>
                <ODEntityLabeled
                  name="플레이어 클래스 조건"
                  label="플레이어 클래스 조건"
                >
                  <ul>
                    {visiblePlayerClassConditions.map(
                      ({ property, value, inequality, name }, idx) => {
                        const inequalityValue =
                          ONBOARDING_POPUP_INEQUALITY_LIST.find(
                            ({ value: v }) => v === inequality
                          )
                        return (
                          <li key={`player_class_${idx}`}>
                            <Row style={{ marginBottom: 10 }}>
                              <Col md={2}>
                                <b>{`[${value}] ${name}`}</b>
                              </Col>
                              <Col md={3}>
                                <Select
                                  key={`select_player_class_${idx}`}
                                  isSearchable={true}
                                  placeholder="조건 선택"
                                  value={inequalityValue}
                                  onChange={(v) => {
                                    writePlayerClassInequalityValue(v, idx)
                                  }}
                                  options={inequalityOption.filter((i) =>
                                    [
                                      ONBOARDING_POPUP_INEQUALITY.Equal,
                                      ONBOARDING_POPUP_INEQUALITY.NotEqual,
                                    ].includes(i.value)
                                  )}
                                />
                              </Col>
                              <input
                                type="button"
                                name={`remove_player_class_${idx}`}
                                key={`remove_player_class_${idx}`}
                                onClick={removePlayerClassCondition}
                                value="X"
                              />
                            </Row>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </ODEntityLabeled>
              </>
            )}
            <ODEntityEditorFooter
              saveButtonName="저장"
              deleteConfirmOptions={{
                message: (
                  <>정말 삭제하시겠습니까? (하위 선택지도 함께 삭제 됩니다.)</>
                ),
                yes: '삭제',
                no: '취소',
              }}
              deleteButtonName={popupId ? '삭제' : undefined}
              context={Context}
            />
          </Provider>
        </CardBody>
      </Card>
    </>
  )
}
