"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberFieldWithUI = void 0;
// noinspection ES6PreferShortImport
const InputWithValidation_1 = require("../ui/InputWithValidation");
const NumberField_1 = require("./NumberField");
/**
 * 기존 IInputWithValidation 과의 공존을 위하여 존재한다. NumberField 를 사용하도록 하되,
 * InputWithValidation 을 연동한다.
 */
class NumberFieldWithUI extends NumberField_1.NumberField {
    constructor(fieldId, getter, setter, uiOptions = {}) {
        var _a, _b, _c, _d;
        super(fieldId, getter, setter);
        this.input = new InputWithValidation_1.InputWithValidation(fieldId, (_a = uiOptions.label) !== null && _a !== void 0 ? _a : '', (_b = uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.placeholder) !== null && _b !== void 0 ? _b : '', 'number', this.defValue, (_c = uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.validation) !== null && _c !== void 0 ? _c : null, {
            ...((_d = uiOptions.options) !== null && _d !== void 0 ? _d : {}),
            onChange: value => {
                this.onChange(value);
            },
        });
    }
}
exports.NumberFieldWithUI = NumberFieldWithUI;
