import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLChr } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { SiteUrls } from '../../../urls'
import { ChrPrimaryKey, IChrListableOption } from '../ChrCommon'
import { useChrListDataLoader } from './ChrListDataLoader'
import { ChrMainSelect, IChrMainSelectFilterType } from './ChrMainSelect'

interface IChrListableTableProps {
  storyId: number
}

export const ChrListableTable: React.FC<IChrListableTableProps> = (props) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<
    GQLChr,
    IChrListableOption
  >()
  const [mainFilter, setMainFilter] =
    React.useState<IChrMainSelectFilterType>(null)
  const [token] = useCounter()
  const dataLoader = useChrListDataLoader(storyId, mainFilter)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = { display: 'flex', marginBottom: 15 }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[ChrPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox
              listableContext={Context}
              placeholder="이름으로 검색"
              style={searchBoxStyle}
            />
            <div style={{ width: 250, marginLeft: 15 }}>
              <ChrMainSelect value={mainFilter} onChange={setMainFilter} />
            </div>
          </div>
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>인물 아이디</th>
                <th>캐릭터명[고유 이름](온보딩 대치 이름)</th>
                <th>이미지파일</th>
                <th>메인 캐릭터?</th>
                <th>캐릭터 컬러</th>
              </tr>
            )}
            renderRow={(value: GQLChr, context) => (
              <HoverTR
                key={value.chrId}
                onClick={() =>
                  setRedirect(
                    SiteUrls.Admin.Story.EditChr(storyId, value.chrId)
                  )
                }
              >
                <td>{value.chrId}</td>
                <td>
                  {value.displayName}&nbsp;[{value.name}]&nbsp;
                  {value.defaultFirstName
                    ? ` (${value.defaultLastName || ''}${
                        value.defaultFirstName
                      })`
                    : ''}
                </td>
                <td>
                  {value.imageFile?.link ? (
                    <img
                      src={value.imageFile!.link}
                      width={100}
                      height={100}
                      alt="character"
                    />
                  ) : (
                    <span>미등록</span>
                  )}
                </td>
                <td>{value.main ? '메인 캐릭터' : '보조 캐릭터'}</td>
                <td>
                  <div
                    style={{
                      width: 50,
                      height: 20,
                      backgroundColor: value.color,
                    }}
                  />
                </td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
