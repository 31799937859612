import { Button } from '@material-ui/core'
import { ODEntityLabeled } from '@odc/od-react-belt'
import React, { useState } from 'react'
import { Label } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLBonusTicketCode,
  GQLBonusTicketCodeCreationInput,
  GQLBonusTicketCodeUpdateInput,
  GQLOkResponse,
  GQLSingleIDInput,
} from '../../@types/server'
import { ODFText } from '../../components/ODEditor/ODFComponents/ODFText'
import { useCounter, useODMutation, useODQuery } from '../../context/ODCommon'
import { ODEntityInput } from '../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

type BonusTicketCodeEditContainerProps = {
  idEditing?: number
}

const BONUS_TICKET_CODE_FULL_SNAPSHOT = `
  btcId
  code
  desc
  willActivateAt
  willExpireAt
  inactivatedAt
  numOfReuses
  remainingReuses
  ticketAmount
  issuedUserId
`

export const GQL_LIST_BONUS_TICKET_CODE = `
query listBonusTicketCode($data: ListableBonusTicketCodeInput!) {
  listBonusTicketCode(data: $data) {
    list {
      ${BONUS_TICKET_CODE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const GQL_CREATE = `
mutation createBonusTicketCode($data: BonusTicketCodeCreationInput!) {
  createBonusTicketCode(data: $data) {
    ${BONUS_TICKET_CODE_FULL_SNAPSHOT}
  }
}
`

const GQL_UPDATE = `
mutation updateBonusTicketCode($data: BonusTicketCodeUpdateInput!) {
  updateBonusTicketCode(data: $data) {
    ${BONUS_TICKET_CODE_FULL_SNAPSHOT}
  }
}
`

const GQL_GET = `
query getBonusTicketCode($data: SingleIDInput!) {
  getBonusTicketCode(data: $data) {
    ${BONUS_TICKET_CODE_FULL_SNAPSHOT}
  }
}
`

const GQL_REMOVE = `
mutation deleteBonusTicketCode($data: SingleIDInput!) {
  deleteBonusTicketCode(data: $data) {
    ok
  }
}
`

// const GQL_CHECK_CODE = `
// mutation checkBonusTicketCode($data: SingleCodeInput!) {
//   checkBonusTicketCode(data: $data) {
//     codeExists
//   }
// }
// `

type Entity = GQLBonusTicketCode

const BonusTicketCodePropsInputValidation = {
  desc: Yup.string()
    .min(5, `최소 5자 이상이어야 합니다.`)
    .max(90, `최대 90자입니다.`),
  code: Yup.string()
    .min(2, `최소 2자 이상이어야 합니다.`)
    .max(30, `최대 30자입니다.`),
}

function getValidationSchema(
  values: GQLBonusTicketCodeCreationInput | GQLBonusTicketCodeUpdateInput
) {
  return Yup.object().shape({
    desc: BonusTicketCodePropsInputValidation.desc.required(
      '설명은 필수입니다.'
    ),
    code: BonusTicketCodePropsInputValidation.code.required(
      '코드는 필수입니다.'
    ),
  })
}

export const BonusTicketCodeEditContainer: React.FC<BonusTicketCodeEditContainerProps> = (
  props
) => {
  const { idEditing } = props
  const innerRef = React.useRef(null)
  const apiCreate = useODMutation<
    GQLBonusTicketCodeCreationInput,
    Partial<Entity>
  >(GQL_CREATE)
  const apiUpdate = useODMutation<
    Partial<GQLBonusTicketCodeUpdateInput>,
    Partial<Entity>
  >(GQL_UPDATE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE)
  // const apiCheckCode = useODMutation<GQLSingleCodeInput, GQLCheckBonusTicketCodeResponse>(GQL_CHECK_CODE)
  const [token] = useCounter()

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<
      Entity,
      GQLBonusTicketCodeCreationInput | GQLBonusTicketCodeUpdateInput
    >
  >(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            code: '',
            desc: '',
            inactivatedAt: null,
            numOfReuses: 0,
            remainingReuses: 0,
            ticketAmount: 0,
            willActivateAt: null,
            willExpireAt: null,
          }
        }
        return {
          code: data.code,
          desc: data.desc,
          inactivatedAt: data.inactivatedAt,
          numOfReuses: data.numOfReuses,
          remainingReuses: data.remainingReuses,
          ticketAmount: data.ticketAmount,
          willActivateAt: data.willActivateAt,
          willExpireAt: data.willExpireAt,
        }
      },
      saveClientValueToServer: async (
        data: GQLBonusTicketCodeCreationInput | GQLBonusTicketCodeUpdateInput
      ) => {
        if (idEditing) {
          await apiUpdate({ id: idEditing, ...data })
          Utils.showSuccess('코드를 업데이트하였습니다.', 'Success')
        } else {
          const _data = data as GQLBonusTicketCodeCreationInput
          await apiCreate({
            code: _data.code,
            desc: _data.desc,
            inactivatedAt: _data.inactivatedAt,
            numOfReuses: _data.numOfReuses,
            ticketAmount: _data.ticketAmount,
            willActivateAt: _data.willActivateAt,
            willExpireAt: _data.willExpireAt,
          })
          Utils.showSuccess('이벤트 코드를 추가하였습니다.', 'Success')
        }
        return SiteUrls.Admin.BonusTicketCode.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('이벤트 코드를 삭제하였습니다.', 'Success')
        }
        return SiteUrls.Admin.BonusTicketCode.List
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [idEditing, apiCreate, apiGet, apiUpdate, apiRemove, token]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<
      Entity,
      GQLBonusTicketCodeCreationInput | GQLBonusTicketCodeUpdateInput
    >
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<
      Entity,
      GQLBonusTicketCodeCreationInput | GQLBonusTicketCodeUpdateInput
    >(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<
          Entity,
          GQLBonusTicketCodeCreationInput | GQLBonusTicketCodeUpdateInput
        >(options)
      ),
    [options]
  )

  const title = !idEditing ? '코드 추가' : '코드 수정'
  const [randomCode, setRandomCode] = useState('')

  function getRandomString(str: string, len: number): string {
    if (str.length <= 0) {
      return ''
    }

    const arr = []
    for (let i = 0; i < len; ++i) {
      const index = Math.floor(Math.random() * str.length)
      arr.push(str.charAt(index))
    }

    return arr.join('')
  }

  return (
    <Provider title={title}>
      <ODEntityInput
        name="code"
        label="코드"
        placeholder="코드를 입력하세요"
        inputType="text"
      />
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <span
          style={{
            alignContent: 'space-between',
          }}
        >
          <Label>{randomCode}</Label>
          <Button
            onClick={() => {
              const head = getRandomString('QWERTYUIOPASDFGHJKLZXCVBNM', 3)
              const tail = getRandomString('1234567890', 5)
              setRandomCode(`${head}${tail}`)
            }}
          >
            랜덤코드 생성
          </Button>
        </span>
      </div>
      <ODEntityInput
        name="desc"
        label="설명"
        placeholder="코드에 대한 설명을 입력하세요"
        inputType="text"
      />
      <ODEntityLabeled name="willActivateAt" label="활성화 시작시각">
        <ODFText
          labelKey={'willActivateAt'}
          formKey="willActivateAt"
          inputType="datetime-local"
        />
      </ODEntityLabeled>
      <ODEntityLabeled name="willExpireAt" label="만료 시각">
        <ODFText
          labelKey={'willExpireAt'}
          formKey="willExpireAt"
          inputType="datetime-local"
        />
      </ODEntityLabeled>
      <ODEntityLabeled name="inactivatedAt" label="비활성화 된 시각">
        <ODFText
          labelKey={'inactivatedAt'}
          formKey="inactivatedAt"
          inputType="datetime-local"
        />
      </ODEntityLabeled>
      <ODEntityInput
        name="numOfReuses"
        label="충전 가능 횟수"
        placeholder="충전 가능한 횟수를 입력하세요"
        inputType="number"
      />
      {idEditing && (
        <ODEntityInput
          name="remainingReuses"
          label="남은 충전 횟수"
          placeholder="남은 충전 횟수를 입력하세요"
          inputType="number"
        />
      )}
      <ODEntityInput
        name="ticketAmount"
        label="티켓 장수"
        placeholder="티켓 장수에 대한 설명을 입력하세요"
        inputType="number"
      />
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        deleteConfirmOptions={{
          message: <>정말 삭제하시겠습니까?</>,
          yes: '삭제',
          no: '취소',
        }}
        deleteButtonName={idEditing ? 'Delete' : undefined}
        context={Context}
      />
    </Provider>
  )
}
