import { ODWebUtils, SingleQueryWrapper, useODMutation } from '@odc/od-react-belt'
import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'
import { GQLOTPKeyResponse } from '../../@types/server'
import { ODToastType, showODToast } from '../../components/ODToast'
import { LOGIN_STATE, useAppContext } from '../../context/AppContext'
import { SiteUrls } from '../../urls'
import { RegisterOTPKeyPage } from './RegisterOTPKeyPage'

const GQL_GENERATE_AN_OTP_KEY = `
mutation {
  generateAnOtpKey {
    email
    url
    secretCode
  }
}`

export const RegisterOTPKeyContainer: FC = props => {
  const {
    registerOTPKey,
    state: { loginState },
  } = useAppContext()
  const apiGenerateAnOTPKey = useODMutation<void, GQLOTPKeyResponse>(GQL_GENERATE_AN_OTP_KEY)
  const [redirect, setRedirect] = React.useState(false)

  const handleRegisterOTPKey = React.useCallback(
    async (otpKey: string, token: string) => {
      try {
        const replacedToken = token.replace(' ', '')
        await registerOTPKey({ otpKey, token: replacedToken })
        showODToast(ODToastType.Success, ODToastType.Success, 'OTP 등록이 완료되었습니다.')
        setRedirect(true)
      } catch (ex) {
        ODWebUtils.showError(ex)
      }
    },
    [registerOTPKey]
  )

  if (redirect && loginState === LOGIN_STATE.LoggedIn) {
    return <Redirect to={SiteUrls.Admin.Main} />
  }

  return (
    <div>
      {/*<BlockingLoadBox show={loading} />*/}
      <SingleQueryWrapper deps={[]} getter={() => apiGenerateAnOTPKey()}>
        {({ data }) => {
          return (
            <>
              <RegisterOTPKeyPage otpInfo={data} handleRegisterOTPKey={handleRegisterOTPKey} />
            </>
          )
        }}
      </SingleQueryWrapper>
    </div>
  )
}
