import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLHomeSection } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IHomeSectionListableOption } from '../HomeSectionCommon'

export function useHomeSectionListDataLoader() {
  const { listHomeSection } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IHomeSectionListableOption
    ): Promise<ODListableResponseType<GQLHomeSection>> {
      const r = await listHomeSection({
        page,
        pageSize: 100,
        ...options,
      })
      return r as ODListableResponseType<GQLHomeSection>
    },
    [listHomeSection]
  )
}
