"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTVibration = void 0;
const mobx_1 = require("mobx");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
class DOSTVibration extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        (0, mobx_1.makeObservable)(this, {
            pattern: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get pattern() {
        return this.st.pattern;
    }
    generateSheetColumnsAfterBackground() {
        if (!!this.executeConditionOfStatement) {
            return ['', '', '', '', '', '', '', this.executeConditionOfStatement];
        }
        return [];
    }
    async getMessagesToTranslate() {
        return null;
    }
}
exports.DOSTVibration = DOSTVibration;
