"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHAT_GPT_ANSWER_TYPE_TO_STRING = exports.CHAT_GPT_ANSWER_TYPE = void 0;
const core_1 = require("@storyplay/core");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
var CHAT_GPT_ANSWER_TYPE;
(function (CHAT_GPT_ANSWER_TYPE) {
    CHAT_GPT_ANSWER_TYPE["Talk"] = "Talk";
    CHAT_GPT_ANSWER_TYPE["Think"] = "Think";
    CHAT_GPT_ANSWER_TYPE["Script"] = "Script";
    CHAT_GPT_ANSWER_TYPE["FullWidthText"] = "FullWidthText";
})(CHAT_GPT_ANSWER_TYPE = exports.CHAT_GPT_ANSWER_TYPE || (exports.CHAT_GPT_ANSWER_TYPE = {}));
exports.CHAT_GPT_ANSWER_TYPE_TO_STRING = {
    [CHAT_GPT_ANSWER_TYPE.Talk]: trans('legacy_TextStore_line'),
    [CHAT_GPT_ANSWER_TYPE.Think]: trans('legacy_TextStore_inner_heart'),
    [CHAT_GPT_ANSWER_TYPE.Script]: trans('legacy_TextStore_fingerprint'),
    [CHAT_GPT_ANSWER_TYPE.FullWidthText]: trans('legacy_TextStore_novel'),
};
