// import 'bulma/css/bulma.min.css'
import { ODGlobal } from '@odc/od-react-belt'
import { ODGlobalDef, ODGlobalTheme } from '@odc/odbelt'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import { ApolloProvider } from 'react-apollo-hooks'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Slide, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { applyMiddleware, compose, createStore } from 'redux'
import { QueryParamProvider } from 'use-query-params'
import { createApolloClient } from './apolloClientUtils'
import './App.scss'
import { ResourceProvider } from './components/ODEditor/ODResources/ResourceProvider'
import { ODModalProvider } from './components/ODModal/context/useODModalContext'
import { AdminRoot } from './containers/AdminRoot'
import { AppProvider } from './context/AppContext'
import { GlobalStyle } from './global-styles'
import createRootReducer from './reducers'
import { COLORS } from './styles/colors'
import { SiteUrls } from './urls'
import { AppOptions } from './utils/AppOptions'
import { registerServerErrorMessages } from './utils/registerServerErrorMessages'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

const { client } = createApolloClient(
  async () => sessionStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN),
  AppOptions.SERVER_ADDRESS,
  AppOptions.WS_SERVER_ADDRESS
)

const history = createBrowserHistory()

// const store = createStore(createRootReducer(history), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  createRootReducer(history), // root reducer with router state
  {},
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history) // for dispatching history actions
      // ... other middlewares ...
    )
  )
)

toast.configure()

const containerStyle = {
  zIndex: 1999,
}

const globalThemeDef: ODGlobalDef = {
  sidebar: {
    bgColor: 'black',
    iconColor: '#708393',
    textColor: 'white',
    activeBGColor: '{{primary}}',
    activeIconColor: 'white',
    activeTextColor: 'white',
    hoverBGColor: '#3a4248',
    hoverIconColor: '{{primary}}',
    hoverTextColor: 'white',
  },
  appHeader: { topBorderSize: '0', topBorderColor: '{{primary}}' },
  // css: { linkColor: COLORS.Primary, linkHoverColor: COLORS.ACCENT },
  // palette: { primary: COLORS.Primary, secondary: COLORS.ACCENT },
  logo: { logoString: 'StoryPlay', textColor: '{{primary}}' },
  css: { linkColor: COLORS.Primary, linkHoverColor: COLORS.Primary },
  serviceName: 'StoryPlay',
  theme: ODGlobalTheme.CoreUILike,
  basePalette: {},
  palette: { primary: COLORS.Primary, secondary: COLORS.Primary },
}

registerServerErrorMessages()

function App() {
  return (
    <ODGlobal def={globalThemeDef}>
      <Provider store={store}>
        <ResourceProvider>
          <ConnectedRouter history={history}>
            <ApolloProvider client={client}>
              <AppProvider>
                <GlobalStyle />
                <ODModalProvider>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    style={containerStyle}
                    transition={Slide}
                  />
                  <Router>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <React.Suspense fallback={loading()}>
                        <Switch>
                          <Route
                            path={SiteUrls.Admin.Root}
                            render={(props: any) => <AdminRoot {...props} />}
                          />
                        </Switch>
                      </React.Suspense>
                    </QueryParamProvider>
                  </Router>
                </ODModalProvider>
              </AppProvider>
            </ApolloProvider>
          </ConnectedRouter>
        </ResourceProvider>
      </Provider>
    </ODGlobal>
  )
}

export default App
