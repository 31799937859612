import { RS } from '../ODResources'

export const KO_STRINGS: { [key: number]: string } = {}

const define = (rs: RS, text: string) => {
  KO_STRINGS[rs] = text
}

define(RS.Undefined, '문자열 정보가 없습니다.')
define(RS.NewStory, '신규 작품 추가')
define(RS.EditStory, '작품 수정')
define(RS.LabelStoryScript, '스크립트 데이터')
define(RS.LabelStoryName, '작품명')
define(RS.LabelStoryTopic, '주제')
define(RS.LabelStoryOneLineDesc, '한 줄 설명')
define(RS.LabelStoryTargetAge, '타깃 연령')
define(RS.LabelStoryCoverText, '띠지 텍스트')
define(RS.LabelStoryDefaultFirstName, '작품 주인공 이름 디폴트 (이름)')
define(RS.LabelStoryDefaultLastName, '작품 주인공 이름 디폴트 (성)')
define(
  RS.LabelStoryDefaultFirstNamePlaceHolder,
  '작품 주인공 이름 디폴트 (이름)'
)
define(RS.LabelStoryDefaultLastNamePlaceHolder, '작품 주인공 이름 디폴트 (성)')
define(RS.LabelStoryNamePlaceHolder, '작품명을 입력하세요.')
define(RS.LabelStoryTopicPlaceHolder, '주제를 입력하세요.')
define(RS.LabelStoryOneLineDescPlaceHolder, '한 줄 설명을 입력하세요.')
define(RS.LabelStoryTargetAgePlaceHolder, '타깃 연령을 입력하세요.')
define(
  RS.LabelStoryScriptPlaceHolder,
  '스프레드시트 불러오기를 수행하면 데이터가 채워집니다.'
)
define(RS.LabelStoryCover, '커버 이미지')
define(RS.LabelStoryCoverPreview, '미공개 커버 이미지')
define(RS.LabelStoryMainCharacterImage, '메인 캐릭터 프로필 사진')
define(RS.LabelStoryId, '작품 ID')
define(RS.LabelStoryStoryEndsAt, '작품 만료일')
define(RS.LabelStoryWillPublishAt, '작품 공개 예정일')
