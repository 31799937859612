"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbRest = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../../../../changeOp");
const DOSTHbBase_1 = require("../../../DOSTHbBase");
const DOHbRestButton_1 = require("./DOHbRestButton");
class DOSTHbRest extends DOSTHbBase_1.DOSTHbBase {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbRest,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.buttons = [];
        this.showRestButtonModal = null;
        this.generateButtons(this.st.data);
        (0, mobx_1.makeObservable)(this, {
            buttons: mobx_1.observable,
            showRestButtonModal: mobx_1.observable,
        });
    }
    async applyChangeOp(op, type) {
        if (op.opType === changeOp_1.StudioChangeOpType.CreateSelectButton) {
            return this.applyChangeOpCreateButtonInternal(op);
        }
        if (op.opType === changeOp_1.StudioChangeOpType.SelectButtonChange) {
            return this.applyChangeOpUpdateButtonInternal(op);
        }
        if (op.opType === changeOp_1.StudioChangeOpType.RemoveSelectButton) {
            return this.applyChangeOpRemoveButtonInternal(op);
        }
        return super.applyChangeOp(op, type);
    }
    removeButton(buttonId) {
        if (!buttonId) {
            return;
        }
        this.helper.opFactory().removeSelectButton(this, buttonId).submitSingle();
    }
    get isValidAll() {
        return true;
    }
    /**
     * 버튼의 인스턴스를 받아 모달을 표시해주고, 닫히면 기존 데이터로 다시 버튼들의 클래스를 재생성해준다.
     */
    onShowRestButtonModal(button) {
        (0, mobx_1.runInAction)(() => {
            this.showRestButtonModal = button;
        });
        if (!button) {
            this.generateButtons(this.st.data);
        }
    }
    onShowCreateRestButtonModal() {
        (0, mobx_1.runInAction)(() => {
            this.showRestButtonModal = new DOHbRestButton_1.DOHbRestButton(this, {});
        });
    }
    cancelEditing() {
        super.cancelEditing();
        this.generateButtons(this.st.data);
    }
    getDataForSubmit() {
        var _a, _b;
        if (!this.isFirstCreated) {
            return {
                type: 'rest',
            };
        }
        return {
            type: 'rest',
            buttons: (_b = (_a = this.buttons) === null || _a === void 0 ? void 0 : _a.map(b => b.getDataForSubmit())) !== null && _b !== void 0 ? _b : [],
        };
    }
    updateData(data) {
        var _a;
        super.updateData(data);
        this.generateButtons((_a = data.data) !== null && _a !== void 0 ? _a : {});
    }
    generateButtons(data) {
        (0, mobx_1.runInAction)(() => {
            var _a;
            this.buttons = ((_a = data.buttons) !== null && _a !== void 0 ? _a : []).map(b => new DOHbRestButton_1.DOHbRestButton(this, b));
        });
    }
    async createButtonInternal(button) {
        if (!this.st.hbExtensionData.id) {
            return;
        }
        try {
            const res = await this.apiServer.message.createButton(this.st.hbExtensionData.id, button, this.rootStore.isAlgorithmStore);
            this.updateData(res);
            if (this.showRestButtonModal) {
                (0, mobx_1.runInAction)(() => {
                    this.showRestButtonModal = null;
                });
            }
            return res;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw ex;
        }
    }
    async updateButtonInternal(button) {
        if (!this.st.hbExtensionData.id) {
            return;
        }
        try {
            const res = await this.apiServer.message.updateButton(this.st.hbExtensionData.id, button, this.rootStore.isAlgorithmStore);
            this.updateData(res);
            if (this.showRestButtonModal) {
                (0, mobx_1.runInAction)(() => {
                    this.showRestButtonModal = null;
                });
            }
            return res;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw ex;
        }
    }
    async removeButtonInternal(buttonId) {
        if (!this.st.hbExtensionData.id) {
            return;
        }
        try {
            const res = await this.apiServer.message.deleteButton(this.st.hbExtensionData.id, buttonId, this.rootStore.isAlgorithmStore);
            (0, mobx_1.runInAction)(() => {
                if (!!this.st.hbExtensionData.data &&
                    !!this.st.hbExtensionData.data.buttons) {
                    this.st.hbExtensionData.data.buttons =
                        this.st.hbExtensionData.data.buttons.filter(item => item.id !== buttonId);
                }
                if (!!this.st.data.buttons) {
                    this.st.data.buttons = this.st.data.buttons.filter(item => item.id !== buttonId);
                }
            });
            this.generateButtons(this.st.data);
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw ex;
        }
    }
    async applyChangeOpCreateButtonInternal(op) {
        var _a, _b;
        try {
            await this.createButtonInternal(op.dataForSubmit);
            // 현재는 생성시에 무조건 라스트 턴에 생성된다.
            // 추후 턴이 적용되어 생성된다면 반환값으로 생성된 버튼의 아이디가 필요하다.
            const lastItem = (0, lodash_1.last)((_b = (_a = this.st.data) === null || _a === void 0 ? void 0 : _a.buttons) !== null && _b !== void 0 ? _b : []);
            if (!(lastItem === null || lastItem === void 0 ? void 0 : lastItem.id)) {
                return null;
            }
            return {
                reverse: changeOp_1.StudioChangeOpFactory.removeSelectButton(this, lastItem.id),
                lineToFocus: this,
            };
        }
        catch {
            return null;
        }
    }
    async applyChangeOpUpdateButtonInternal(op) {
        let reverseData;
        const find = this.buttons.find(item => item.data.id === op.dataForSubmit.id);
        if (!find) {
            return null;
        }
        reverseData = find.getOriginDataForSubmit();
        try {
            await this.updateButtonInternal(op.dataForSubmit);
            return {
                reverse: changeOp_1.StudioChangeOpFactory.updateSelectButton(this, reverseData),
                lineToFocus: this,
            };
        }
        catch {
            return null;
        }
    }
    async applyChangeOpRemoveButtonInternal(op) {
        let reverseData;
        const find = this.buttons.find(item => item.data.id === op.buttonId);
        if (!find) {
            return null;
        }
        const { id, ...rest } = find.getDataForSubmit();
        reverseData = rest;
        try {
            await this.removeButtonInternal(op.buttonId);
            return {
                reverse: changeOp_1.StudioChangeOpFactory.createSelectButton(this, reverseData),
                lineToFocus: this,
            };
        }
        catch {
            return null;
        }
    }
}
exports.DOSTHbRest = DOSTHbRest;
