import Select from 'react-select'
import {
  ODButton,
  ODButtonTheme,
  ODModal,
  ODModalBody,
  ODModalFooter,
  ODModalHeader,
  ODModalSize,
} from '@odc/od-react-belt'
import React from 'react'
import { Col, Input, Row } from 'reactstrap'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { Utils } from '../../utils/utils'
import { ChoiceFromPlayerClassSelectFilterType } from './ChoiceFromPlayerClassSelect'

interface ICreateChoiceFromPlayerClassModalProps {
  choiceId: number
  playerClassOptions: ChoiceFromPlayerClassSelectFilterType[]
  onClose: () => void
  onConfirm: () => void
}

export const CreateChoiceFromPlayerClassModal: React.FC<
  ICreateChoiceFromPlayerClassModalProps
> = (props) => {
  const {
    choiceId,
    playerClassOptions: getPlayerClassOptions,
    onClose,
    onConfirm,
  } = props
  const { createChoiceFromPlayerClass, getChoice } = useStoryPlayAPIs()
  const [playerClassId, setPlayerClassId] = React.useState(0)
  const [adminAddCount, setAdminAddCount] = React.useState(0)
  const [selectedChoice, setSelectedChoice] = React.useState(0)
  const [choiceName, setChoiceName] = React.useState('')
  const [playerClassOptions, setPlayerClassOptions] = React.useState<
    {
      label: string
      value: number
    }[]
  >([])
  const [selectedChoiceOptions, setSelectedChoiceOptions] = React.useState<
    {
      label: string
      value: number
    }[]
  >([])

  const handleConfirm = async () => {
    if (!playerClassId || !selectedChoice)
      return Utils.showError('필수 값이 존재하지 않습니다.')
    try {
      await createChoiceFromPlayerClass({
        choiceId,
        playerClassId,
        selectedChoice,
        adminAddCount,
      })
      Utils.showSuccess('통계가 생성되었습니다!')
      onConfirm()
    } catch (e) {
      // @ts-ignore
      Utils.showError(e)
    }
  }

  React.useEffect(() => {
    getChoice({ id: choiceId }).then((choice) => {
      setSelectedChoiceOptions(
        Array.from({ length: choice.numChoice }).map((_, idx) => {
          const value = idx + 1
          const label = `${value}번${
            // @ts-ignore
            choice[`choice${value}PropName`]
              ? // @ts-ignore
                ` (${choice[`choice${value}PropName`]})`
              : ''
          }${
            // @ts-ignore
            choice[`choice${value}Content`]
              ? // @ts-ignore
                ` ${choice[`choice${value}Content`]}`
              : ''
          }`
          return { label, value }
        })
      )
      setChoiceName(
        `[${choice.choiceId}] ${choice.name}${
          choice.displayName ? ` (${choice.displayName})` : ''
        }`
      )
    })
    setPlayerClassOptions(
      getPlayerClassOptions.map((p) => ({
        label: p.name,
        value: p.playerClassId!,
      }))
    )
  }, [getChoice, choiceId, getPlayerClassOptions])
  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Large}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader
          title={`${choiceName}의 통계 생성하기`}
          onClose={onClose}
        ></ODModalHeader>
        <ODModalBody>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>분기명</Col>
            <Col md={8}>
              <Input disabled value={choiceName} type="text" />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>플레이어 클래스</Col>
            <Col md={8}>
              <Select
                isSearchable={true}
                placeholder="플레이어 클래스"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    setPlayerClassId(v.value)
                  }
                }}
                options={playerClassOptions}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>선택지 번호</Col>
            <Col md={8}>
              <Select
                isSearchable={true}
                placeholder="선택지 번호"
                onChange={(v) => {
                  if (v) {
                    // @ts-ignore
                    setSelectedChoice(v.value)
                  }
                }}
                options={selectedChoiceOptions}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 2 }),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>어드민 조정 값(+/-)</Col>
            <Col md={8}>
              <Input
                value={adminAddCount}
                type="number"
                onChange={(e) => setAdminAddCount(+e.target.value)}
              />
            </Col>
          </Row>
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            fullWidth
            theme={ODButtonTheme.Primary}
            onClick={handleConfirm}
          >
            생성하기
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
