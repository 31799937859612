"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTChoiceIf = exports.ChoiceIfPropType = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const server_schema_1 = require("../../../../../@types/server.schema");
const consts_1 = require("../../../../../consts");
const models_1 = require("../../../../../models");
const parser_1 = require("../../../../../playData/formula/parser");
const scripter_1 = require("../../../../../scripter/scripter");
const validations_1 = require("../../../../validations");
const interface_1 = require("../../../interface");
const storeUtils_1 = require("../../../utils/storeUtils");
const validation_1 = require("../../../validation");
const DOStoryItemEditStore_1 = require("../../storyItem/DOStoryItemEditStore");
const DOStoryPropertyEditStore_1 = require("../../storyProperty/DOStoryPropertyEditStore");
const ui_1 = require("../../ui");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const IDOStatement_1 = require("../IDOStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
var ChoiceIfPropType;
(function (ChoiceIfPropType) {
    ChoiceIfPropType["Prop"] = "Prop";
    ChoiceIfPropType["MainProp"] = "MainProp";
    ChoiceIfPropType["Item"] = "Item";
    ChoiceIfPropType["Achievement"] = "Achievement";
})(ChoiceIfPropType = exports.ChoiceIfPropType || (exports.ChoiceIfPropType = {}));
class DOSTChoiceIf extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        // Operation
        this.propOpOptions = null;
        this.propValueInput = null;
        this.aNumParam1Input = null;
        this.aNumParam2Input = null;
        // 속성을 편집할 일이 있을 때 설정된다.
        this.propEditor = null;
        // 아이템을 편집할 일이 있을 때 설정된다.
        this.itemEditor = null;
        this.formulaStatus = consts_1.SPExprFormulaStatus.Ok;
        const propTypeOptions = [
            {
                name: trans('legacy_DOSTChoiceIf_common'),
                value: ChoiceIfPropType.Prop,
                description: '',
            },
            {
                name: trans('legacy_DOSTChoiceIf_my_value'),
                value: ChoiceIfPropType.MainProp,
                description: '',
            },
            {
                name: trans('legacy_ODSAddNewItemModal_item'),
                value: ChoiceIfPropType.Item,
                description: '',
            },
        ];
        if (!!block.story.canUseAchievement) {
            propTypeOptions.push({
                name: trans('legacy_DOSTChoiceIf_achievement'),
                value: ChoiceIfPropType.Achievement,
                description: '',
            });
        }
        this.propTypeOptions = new ui_1.SelectionInput('choiceIfPropType', trans('legacy_DOSTChoiceIf_division'), this.currentPropType, propTypeOptions, {
            creatable: false,
            onChangeBeforeSubmit: (value) => {
                if (value === ChoiceIfPropType.Achievement) {
                    // editing 중에는 찾아지지 않는다. 따라서, last statement 로 가정한다.
                    const parentSubBlock = (0, lodash_1.last)(this.block.statements);
                    const currentPropType = this.currentPropType;
                    if (parentSubBlock.hasAchievementEventAtIndex(this.choiceIndex)) {
                        block.store.rootStore.di.showError(trans('legacy_DOSTChoiceIf_only_one_achievement_event_per_choice'));
                        setImmediate(() => {
                            this.propTypeOptions.onChange(currentPropType);
                        });
                        return;
                    }
                }
                this.changePropType(value);
            },
        });
        this.generatePropNameOptions(null);
        this.generatePropOpOptions(null);
        this.generatePropValueOptions(null);
        this.generateNumParamOptions(null);
        (0, mobx_1.makeObservable)(this, {
            propTypeOptions: mobx_1.observable,
            propNameOptions: mobx_1.observable,
            propOpOptions: mobx_1.observable,
            propValueInput: mobx_1.observable,
            aNumParam1Input: mobx_1.observable,
            aNumParam2Input: mobx_1.observable,
            propEditor: mobx_1.observable,
            itemEditor: mobx_1.observable,
            formulaStatus: mobx_1.observable,
            choiceIndex: mobx_1.computed,
        });
    }
    static convertChoiceSaveProp(st) {
        return st.st.propChoices.map((v, index) => {
            return new DOSTChoiceIf({
                statementType: models_1.STATEMENT_TYPE.ChoiceIfSaveProp,
                background: '',
                sourceLine: st.sourceLine,
                choice: index,
                propUpdate: v,
            }, st.block);
        });
    }
    isPropStringFeatureEnabled() {
        return this.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.STORY_PROP_STRING);
    }
    /**
     * 0-based index
     */
    get choiceIndex() {
        return this.data.choice;
    }
    get currentPropType() {
        switch (this.data.statementType) {
            case models_1.STATEMENT_TYPE.ChoiceIfSaveProp: {
                const propName = this.stSaveProp.propUpdate.propName;
                const isMain = this.block.parentChapter
                    .getStory()
                    .storyPropertyStore.allMain.find(v => v.propName === propName);
                return isMain ? ChoiceIfPropType.MainProp : ChoiceIfPropType.Prop;
            }
            case models_1.STATEMENT_TYPE.ChoiceIfAchievementEvent:
                return ChoiceIfPropType.Achievement;
            case models_1.STATEMENT_TYPE.ChoiceIfUpdateItem:
                return ChoiceIfPropType.Item;
            default:
                throw new Error(`DOSTChoiceIf.currentPropType : Invalid statementType (${this.data.statementType})`);
        }
    }
    get currentPropName() {
        switch (this.data.statementType) {
            case models_1.STATEMENT_TYPE.ChoiceIfSaveProp: {
                return this.stSaveProp.propUpdate.propName;
            }
            case models_1.STATEMENT_TYPE.ChoiceIfAchievementEvent: {
                return this.stAchievement.event;
            }
            case models_1.STATEMENT_TYPE.ChoiceIfUpdateItem: {
                return this.stItem.itemUpdate.itemName;
            }
        }
        return null;
    }
    get currentOpToConvertString() {
        var _a, _b;
        switch (this.data.statementType) {
            case models_1.STATEMENT_TYPE.ChoiceIfSaveProp: {
                const opToString = {
                    [models_1.PROP_OPERATION.INCREASE_NUMBER]: '+',
                    [models_1.PROP_OPERATION.SET_NUMBER]: '=',
                    [models_1.PROP_OPERATION.SET_TEXT]: '=',
                    [models_1.PROP_OPERATION.CALCULATE]: '=',
                    [models_1.PROP_OPERATION.SET_TEXT]: '=',
                };
                if (this.stSaveProp.propUpdate.propOp === models_1.PROP_OPERATION.INCREASE_NUMBER) {
                    if (+this.stSaveProp.propUpdate.value < 0) {
                        return '-';
                    }
                }
                return (_a = opToString[this.stSaveProp.propUpdate.propOp]) !== null && _a !== void 0 ? _a : null;
            }
            case models_1.STATEMENT_TYPE.ChoiceIfUpdateItem: {
                const opToString = {
                    [models_1.ITEM_OPERATION.INCREASE_NUMBER]: '+',
                    [models_1.ITEM_OPERATION.SET_NUMBER]: '=',
                    [models_1.ITEM_OPERATION.CALCULATE]: '=',
                };
                if (this.stItem.itemUpdate.itemOp === models_1.ITEM_OPERATION.INCREASE_NUMBER) {
                    if (+this.stItem.itemUpdate.itemCount < 0) {
                        return '-';
                    }
                }
                return (_b = opToString[this.stItem.itemUpdate.itemOp]) !== null && _b !== void 0 ? _b : null;
            }
        }
        return null;
    }
    get currentPropValue() {
        switch (this.data.statementType) {
            case models_1.STATEMENT_TYPE.ChoiceIfSaveProp: {
                const propUpdateValue = this.stSaveProp.propUpdate.value;
                if (this.stSaveProp.propUpdate.propOp === models_1.PROP_OPERATION.CALCULATE) {
                    return this.rootStore.textStore.encodeSPExprFormulaAfterParse(propUpdateValue);
                }
                if (this.stSaveProp.propUpdate.propOp === models_1.PROP_OPERATION.SET_TEXT) {
                    return propUpdateValue;
                }
                return Math.abs(+propUpdateValue).toString();
            }
            case models_1.STATEMENT_TYPE.ChoiceIfUpdateItem: {
                const itemUpdateValue = this.stItem.itemUpdate.itemCount;
                if (this.stItem.itemUpdate.itemOp === models_1.ITEM_OPERATION.CALCULATE) {
                    return this.rootStore.textStore.encodeSPExprFormulaAfterParse(itemUpdateValue);
                }
                return Math.abs(+itemUpdateValue).toString();
            }
        }
        return null;
    }
    get stAchievement() {
        return this.data;
    }
    get stSaveProp() {
        return this.data;
    }
    get stItem() {
        return this.data;
    }
    changePropType(propType) {
        (0, mobx_1.runInAction)(() => {
            switch (propType) {
                case ChoiceIfPropType.Prop:
                case ChoiceIfPropType.MainProp:
                    this.data = {
                        statementType: models_1.STATEMENT_TYPE.ChoiceIfSaveProp,
                        choice: this.choiceIndex,
                        propUpdate: {
                            propName: IDOStatement_1.PROP_EMPTY,
                            propOp: models_1.PROP_OPERATION.SET_NUMBER,
                            value: '0',
                        },
                        background: '',
                        sourceLine: this.sourceLine,
                    };
                    this.statementType = models_1.STATEMENT_TYPE.ChoiceIfSaveProp;
                    break;
                case ChoiceIfPropType.Item:
                    this.data = {
                        statementType: models_1.STATEMENT_TYPE.ChoiceIfUpdateItem,
                        choice: this.choiceIndex,
                        itemUpdate: {
                            itemName: IDOStatement_1.ITEM_EMPTY,
                            itemOp: models_1.ITEM_OPERATION.SET_NUMBER,
                            itemCount: '1',
                        },
                        background: '',
                        sourceLine: this.sourceLine,
                    };
                    this.statementType = models_1.STATEMENT_TYPE.ChoiceIfUpdateItem;
                    break;
                case ChoiceIfPropType.Achievement:
                    this.data = {
                        statementType: models_1.STATEMENT_TYPE.ChoiceIfAchievementEvent,
                        choice: this.choiceIndex,
                        event: IDOStatement_1.ACHIEVEMENT_EMPTY,
                        numParam1: 0,
                        numParam2: 0,
                        background: '',
                        sourceLine: this.sourceLine,
                    };
                    this.statementType = models_1.STATEMENT_TYPE.ChoiceIfAchievementEvent;
                    break;
            }
            this.generatePropNameOptions(propType);
            this.generatePropOpOptions(propType);
            this.generatePropValueOptions(propType);
            this.generateNumParamOptions(propType);
        });
    }
    generatePropNameOptions(propTypeIn) {
        const propType = propTypeIn !== null && propTypeIn !== void 0 ? propTypeIn : this.currentPropType;
        const options = (() => {
            switch (propType) {
                case ChoiceIfPropType.Prop:
                    return this.block.parentChapter
                        .getStory()
                        .storyPropertyStore.allNonMain.map(v => v.propName);
                case ChoiceIfPropType.MainProp:
                    return this.block.parentChapter
                        .getStory()
                        .storyPropertyStore.allMain.map(v => v.propName);
                case ChoiceIfPropType.Item:
                    return this.block.parentChapter
                        .getStory()
                        .storyItemStore.allStoryItems.map(v => v.name);
                case ChoiceIfPropType.Achievement:
                    return this.block.parentChapter
                        .getStory()
                        .aEventDefStore.allAEventDefs.map(v => v.name);
            }
        })();
        const currentValue = (() => {
            switch (propType) {
                case ChoiceIfPropType.Prop:
                case ChoiceIfPropType.MainProp: {
                    return this.stSaveProp.propUpdate.propName;
                }
                case ChoiceIfPropType.Item: {
                    return this.stItem.itemUpdate.itemName;
                }
                case ChoiceIfPropType.Achievement: {
                    return this.stAchievement.event;
                }
            }
            return '';
        })();
        // 속성의 경우 이름을 변환해주기 위해서
        const convertName = (name) => {
            switch (propType) {
                case ChoiceIfPropType.Prop:
                    return this.block.story.storyPropertyStore.getByName(name, false)
                        .convertedPropName;
                case ChoiceIfPropType.MainProp: {
                    return this.block.story.storyPropertyStore.getByName(name, true)
                        .convertedPropName;
                }
                default:
                    return name;
            }
        };
        (0, mobx_1.runInAction)(() => {
            this.propNameOptions = new ui_1.SelectionInput('choiceIfPropName', trans('legacy_SLGParser_property'), currentValue !== null && currentValue !== void 0 ? currentValue : '', options.map(op => ({
                name: convertName(op),
                value: op,
                description: op,
            })), {
                creatable: propType !== ChoiceIfPropType.Achievement,
                onChangeBeforeSubmit: value => {
                    (0, mobx_1.runInAction)(() => {
                        var _a, _b;
                        switch (this.currentPropType) {
                            case ChoiceIfPropType.Prop:
                            case ChoiceIfPropType.MainProp: {
                                const chapter = this.block.parentChapter;
                                const story = chapter.getStory();
                                this.stSaveProp.propUpdate.propName = value;
                                (_a = this.propValueInput) === null || _a === void 0 ? void 0 : _a.onChange('0');
                                if (this.isPropStringFeatureEnabled()) {
                                    const propFound = story.storyPropertyStore.getByName(value, this.currentPropType === ChoiceIfPropType.MainProp);
                                    if (!!propFound) {
                                        ;
                                        (_b = this.propOpOptions) === null || _b === void 0 ? void 0 : _b.onChange(propFound.propType === server_schema_1.GQLSTORY_PROP_TYPE.STRING
                                            ? models_1.PROP_OPERATION.SET_TEXT
                                            : models_1.PROP_OPERATION.SET_NUMBER);
                                    }
                                }
                                break;
                            }
                            case ChoiceIfPropType.Item: {
                                this.stItem.itemUpdate.itemName = value;
                                break;
                            }
                            case ChoiceIfPropType.Achievement:
                                this.stAchievement.event = value;
                                break;
                        }
                    });
                },
                onCreateBeforeSubmit: name => {
                    (0, mobx_1.runInAction)(() => {
                        switch (propType) {
                            case ChoiceIfPropType.Prop:
                            case ChoiceIfPropType.MainProp: {
                                this.propEditor = new DOStoryPropertyEditStore_1.DOStoryPropertyEditStore(this.block.story, propType === ChoiceIfPropType.MainProp, null, propCreated => {
                                    (0, mobx_1.runInAction)(() => {
                                        if (propCreated) {
                                            switch (this.currentPropType) {
                                                case ChoiceIfPropType.Prop:
                                                case ChoiceIfPropType.MainProp: {
                                                    this.stSaveProp.propUpdate.propName =
                                                        propCreated.propName;
                                                    this.propNameOptions.reloadOptions();
                                                    break;
                                                }
                                            }
                                        }
                                        this.propEditor = null;
                                        this.generatePropNameOptions(propType);
                                    });
                                });
                                this.propEditor.propNameInput.onChange(name);
                                break;
                            }
                            case ChoiceIfPropType.Item: {
                                this.itemEditor = new DOStoryItemEditStore_1.DOStoryItemEditStore(this.block.story, null, item => {
                                    (0, mobx_1.runInAction)(() => {
                                        if (item) {
                                            this.stItem.itemUpdate.itemName = item.name;
                                        }
                                        this.itemEditor = null;
                                        this.generatePropNameOptions(propType);
                                    });
                                });
                                this.itemEditor.itemNameInput.onChange(name);
                                break;
                            }
                            case ChoiceIfPropType.Achievement:
                                throw new Error(`296: Achievement does not support creation on the fly.`);
                        }
                    });
                },
            });
        });
    }
    generatePropOpOptions(propTypeIn) {
        const propType = propTypeIn !== null && propTypeIn !== void 0 ? propTypeIn : this.currentPropType;
        (0, mobx_1.runInAction)(() => {
            var _a;
            switch (propType) {
                case ChoiceIfPropType.Prop:
                case ChoiceIfPropType.MainProp: {
                    const options = [
                        { value: models_1.PROP_OPERATION.SET_NUMBER, name: '=', description: '' },
                        {
                            value: models_1.PROP_OPERATION.INCREASE_NUMBER,
                            name: '+',
                            description: '',
                        },
                    ];
                    const propOpValue = (() => {
                        var _a;
                        return (_a = this.stSaveProp.propUpdate.propOp) !== null && _a !== void 0 ? _a : options[0].value;
                    })();
                    this.propOpOptions = new ui_1.SelectionInput('choiceIfPropOp', trans('legacy_DOSTChoiceIf_operator'), propOpValue, options, {
                        creatable: false,
                        onChangeBeforeSubmit: value => {
                            (0, mobx_1.runInAction)(() => {
                                this.stSaveProp.propUpdate.propOp = value;
                            });
                        },
                        placeholder: '=',
                    });
                    break;
                }
                case ChoiceIfPropType.Item: {
                    const options = [
                        { value: models_1.ITEM_OPERATION.SET_NUMBER, name: '=', description: '' },
                        {
                            value: models_1.ITEM_OPERATION.INCREASE_NUMBER,
                            name: '+',
                            description: '',
                        },
                    ];
                    this.propOpOptions = new ui_1.SelectionInput('choiceIfPropOp', trans('legacy_DOSTChoiceIf_operator'), (_a = this.stItem.itemUpdate.itemOp) !== null && _a !== void 0 ? _a : options[0].value, options, {
                        creatable: false,
                        onChangeBeforeSubmit: value => {
                            (0, mobx_1.runInAction)(() => {
                                this.stItem.itemUpdate.itemOp = value;
                            });
                        },
                        placeholder: '=',
                    });
                    break;
                }
                case ChoiceIfPropType.Achievement:
                    this.propOpOptions = null;
                    break;
            }
        });
    }
    generatePropValueOptions(propTypeIn) {
        const propType = propTypeIn !== null && propTypeIn !== void 0 ? propTypeIn : this.currentPropType;
        (0, mobx_1.runInAction)(() => {
            switch (propType) {
                case ChoiceIfPropType.Prop:
                case ChoiceIfPropType.MainProp: {
                    const defaultValue = (() => {
                        var _a, _b;
                        if (((_a = this.propOpOptions) === null || _a === void 0 ? void 0 : _a.value) === IDOStatement_1.CommonPropOperation.CALCULATE) {
                            return this.rootStore.textStore.encodeSPExprFormulaAfterParse(this.stSaveProp.propUpdate.value);
                        }
                        if (((_b = this.propOpOptions) === null || _b === void 0 ? void 0 : _b.value) === IDOStatement_1.CommonPropOperation.SET_TEXT) {
                            return this.stSaveProp.propUpdate.value;
                        }
                        return parseInt(this.stSaveProp.propUpdate.value, 10) || 0;
                    })();
                    this.propValueInput = new ui_1.InputWithValidation('propValueInput', trans('legacy_DOSTChoiceIf_number_value'), trans('legacy_DOSTChoiceIf_number_value'), 'text', defaultValue, null, {
                        noLabel: true,
                        onChange: value => {
                            (0, mobx_1.runInAction)(() => {
                                var _a;
                                if (((_a = this.propOpOptions) === null || _a === void 0 ? void 0 : _a.value) === IDOStatement_1.CommonPropOperation.CALCULATE) {
                                    try {
                                        const result = (0, parser_1.parseSPExprFormula)(value.toString());
                                        this.formulaStatus = consts_1.SPExprFormulaStatus.Ok;
                                        this.stSaveProp.propUpdate.value = JSON.stringify(result);
                                    }
                                    catch (ex) {
                                        // tslint:disable-next-line:no-console
                                        console.error(ex);
                                        this.formulaStatus = consts_1.SPExprFormulaStatus.Fail;
                                    }
                                    return;
                                }
                                this.stSaveProp.propUpdate.value = value.toString();
                            });
                        },
                    });
                    break;
                }
                case ChoiceIfPropType.Item: {
                    const defaultValue = (() => {
                        var _a;
                        if (((_a = this.propOpOptions) === null || _a === void 0 ? void 0 : _a.value) === IDOStatement_1.CommonPropOperation.CALCULATE) {
                            return this.rootStore.textStore.encodeSPExprFormulaAfterParse(this.stItem.itemUpdate.itemCount);
                        }
                        return parseInt(this.stItem.itemUpdate.itemCount, 10) || 0;
                    })();
                    this.propValueInput = new ui_1.InputWithValidation('itemValueInput', trans('legacy_DOSTChoiceIf_number_value'), trans('legacy_DOSTChoiceIf_number_value'), 'text', defaultValue, null, {
                        noLabel: true,
                        onChange: value => {
                            (0, mobx_1.runInAction)(() => {
                                var _a;
                                if (((_a = this.propOpOptions) === null || _a === void 0 ? void 0 : _a.value) === IDOStatement_1.CommonPropOperation.CALCULATE) {
                                    try {
                                        const result = (0, parser_1.parseSPExprFormula)(value.toString());
                                        this.formulaStatus = consts_1.SPExprFormulaStatus.Ok;
                                        this.stItem.itemUpdate.itemCount = JSON.stringify(result);
                                    }
                                    catch (ex) {
                                        // tslint:disable-next-line:no-console
                                        console.error(ex);
                                        this.formulaStatus = consts_1.SPExprFormulaStatus.Fail;
                                    }
                                    return;
                                }
                                this.stItem.itemUpdate.itemCount = value.toString();
                            });
                        },
                    });
                    break;
                }
                case ChoiceIfPropType.Achievement:
                    this.propValueInput = null;
                    break;
            }
        });
    }
    generateNumParamOptions(propTypeIn) {
        const propType = propTypeIn !== null && propTypeIn !== void 0 ? propTypeIn : this.currentPropType;
        (0, mobx_1.runInAction)(() => {
            switch (propType) {
                case ChoiceIfPropType.Prop:
                case ChoiceIfPropType.MainProp:
                case ChoiceIfPropType.Item: {
                    this.aNumParam1Input = null;
                    this.aNumParam2Input = null;
                    break;
                }
                case ChoiceIfPropType.Achievement:
                    this.aNumParam1Input = new ui_1.InputWithValidation('aNumParam1Input', trans('legacy_DOSTChoiceIf_parameter_1'), trans('legacy_DOSTChoiceIf_parameter_1'), 'number', this.stAchievement.numParam1, null, {
                        noLabel: true,
                        onChange: value => {
                            (0, mobx_1.runInAction)(() => {
                                this.stAchievement.numParam1 = value;
                            });
                        },
                    });
                    this.aNumParam2Input = new ui_1.InputWithValidation('aNumParam2Input', trans('legacy_DOSTChoiceIf_parameter_2'), trans('legacy_DOSTChoiceIf_parameter_2'), 'number', this.stAchievement.numParam2, null, {
                        noLabel: true,
                        onChange: value => {
                            (0, mobx_1.runInAction)(() => {
                                this.stAchievement.numParam2 = value;
                            });
                        },
                    });
                    break;
            }
        });
    }
    validate() {
        const results = super.validate();
        if (this.currentPropType === ChoiceIfPropType.Prop ||
            this.currentPropType === ChoiceIfPropType.MainProp) {
            results.push(...this.runYupValidation(validations_1.PropValidations.name, this.stSaveProp.propUpdate.propName));
            if (this.stSaveProp.propUpdate.propOp === models_1.PROP_OPERATION.SET_TEXT &&
                !this.stSaveProp.propUpdate.value.trim()) {
                results.push({
                    type: validation_1.StudioValidationType.StringPropHasNoValueInChoice,
                    extra: {},
                    severity: validation_1.StudioValidationSeverity.Error,
                    source: this,
                    stack: [],
                });
            }
        }
        if (this.currentPropType === ChoiceIfPropType.Item) {
            results.push(...this.runYupValidation(validations_1.ItemValidations.name, this.stItem.itemUpdate.itemName));
        }
        if (this.currentPropType === ChoiceIfPropType.Achievement) {
            results.push(...this.runYupValidation(validations_1.AchievementValidations.name, this.stAchievement.event));
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    get scriptMessageType() {
        const statementType = {
            [ChoiceIfPropType.Prop]: models_1.STATEMENT_TYPE.ChoiceIfSaveProp,
            [ChoiceIfPropType.MainProp]: models_1.STATEMENT_TYPE.ChoiceIfSaveProp,
            [ChoiceIfPropType.Item]: models_1.STATEMENT_TYPE.ChoiceIfUpdateItem,
            [ChoiceIfPropType.Achievement]: models_1.STATEMENT_TYPE.ChoiceIfAchievementEvent,
        }[this.currentPropType];
        return scripter_1.INVERTED_STATEMENT_TYPE_MAP[statementType];
    }
    generateSheetColumnsAfterBackground() {
        const choiceString = (this.choiceIndex + 1).toString();
        switch (this.currentPropType) {
            case ChoiceIfPropType.Prop:
            case ChoiceIfPropType.MainProp: {
                return [choiceString, (0, storeUtils_1.encodePropUpdate)(this.stSaveProp.propUpdate)];
            }
            case ChoiceIfPropType.Item:
                return [choiceString, (0, storeUtils_1.encodeItemUpdate)(this.stItem.itemUpdate)];
            case ChoiceIfPropType.Achievement:
                return [
                    choiceString,
                    this.stAchievement.event,
                    this.stAchievement.numParam1.toString(),
                    this.stAchievement.numParam2.toString(),
                ];
        }
        return [];
    }
    /**
     * 동일한 문장을 복제한다. unique id 는 다르게 생성될 것이다.
     * clone() 이 필요한 이유는, DOSTChoiceSubBlock 에서 속성값에 대한 편집을 수행할 수 있기 위해서이다.
     */
    clone() {
        return new DOSTChoiceIf((0, lodash_1.cloneDeep)(this.data), this.block);
    }
}
exports.DOSTChoiceIf = DOSTChoiceIf;
