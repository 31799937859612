"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTLeftTalk = void 0;
const core_1 = require("@storyplay/core");
const DOSTBubbleWithChrBase_1 = require("./DOSTBubbleWithChrBase");
const DOSTRightTalk_1 = require("./DOSTRightTalk");
class DOSTLeftTalk extends DOSTBubbleWithChrBase_1.DOSTBubbleWithChrBase {
    constructor() {
        super(...arguments);
        this.isForMainChr = false;
    }
    replaceToOpposite(newChrName) {
        const dataChanging = {
            ...this.data,
            statementType: core_1.STATEMENT_TYPE.MainCharacterTalk,
            chrName: core_1.STUDIO_SHEET_CONST.HERO_NAME,
        };
        this.block.replaceStatement(this, new DOSTRightTalk_1.DOSTRightTalk(dataChanging, this.block));
    }
}
exports.DOSTLeftTalk = DOSTLeftTalk;
