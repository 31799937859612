"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionShowModalBuilder = void 0;
const definition_1 = require("../../definition");
const row_1 = require("../row");
const ActionModalBase_1 = require("./ActionModalBase");
class ActionShowModalBuilder extends ActionModalBase_1.ActionModalBase {
    constructor(shouldNotCloseIfClickOuter) {
        super();
        this.bodyBuilders = [];
        this.modalSize = 'large';
        this.shouldNotCloseIfClickOuter = shouldNotCloseIfClickOuter;
    }
    setModalSize(size) {
        this.modalSize = size;
    }
    setTitle(def) {
        this.title = def;
        return this;
    }
    setFooter(def) {
        this.footer = def;
        return this;
    }
    addRow(cb) {
        const builder = new row_1.RowWithColumnBuilder();
        cb(builder);
        this.bodyBuilders.push(builder);
        return builder;
    }
    buildAction() {
        const rows = this.bodyBuilders.map(b => b.buildRowLayout());
        if (this.title) {
            rows.unshift(this.title);
        }
        if (this.footer) {
            rows.push(this.footer);
        }
        return {
            type: definition_1.ActionType.ShowModal,
            modalSize: this.modalSize,
            ...this.buildActionBase(),
            rows,
            closeOnClickOutside: this.shouldCancelOnClickOutside,
            onClose: () => {
                if (this.shouldNotCloseIfClickOuter) {
                    return;
                }
                this.cancelAction();
            },
        };
    }
}
exports.ActionShowModalBuilder = ActionShowModalBuilder;
