"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionType = void 0;
var ActionType;
(function (ActionType) {
    ActionType["ShowModal"] = "ShowModal";
    ActionType["PickImage"] = "PickImage";
    ActionType["ShowMobileModal"] = "ShowMobileModal";
    ActionType["CompletedTutorial"] = "CompletedTutorial";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
