"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALSingleLineInputModal = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const entity_1 = require("../entity");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ALSingleLineInputModal {
    constructor(rootStore, imageName, modalTitle, label, placeholder, inputTheme, existingValue, onSubmit) {
        this.rootStore = rootStore;
        this.imageName = imageName;
        this.modalTitle = modalTitle;
        this.label = label;
        this.placeholder = placeholder;
        this.inputTheme = inputTheme;
        this.existingValue = existingValue;
        this.onSubmit = onSubmit;
    }
    async submit(name) {
        return this.onSubmit(name);
    }
    buildActionChain() {
        const inputField = new entity_1.InputWithValidation('singleInput', this.label, this.placeholder, 'text', this.existingValue, null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
        })
            .setRequired(true)
            .setAutoFocus();
        inputField.setEditing(true);
        const builder = new ActionChainBuilder_1.ActionChainBuilder('ALSingleLineInputModal');
        builder.addModal(modalBuilder => {
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: this.modalTitle,
                imageName: this.imageName,
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(inputField));
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_ALCreateNewChapter_cancel'),
                        onClick: () => {
                            modalBuilder.cancelAction();
                        },
                    },
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: trans('legacy_ALCreateNewChapter_complete'),
                        onClick: async () => {
                            if (await this.submit(inputField.value)) {
                                modalBuilder.finishAction(null);
                            }
                        },
                    },
                ],
            });
        });
        return builder.buildActionChain();
    }
}
exports.ALSingleLineInputModal = ALSingleLineInputModal;
