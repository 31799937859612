/**
 * ODListableContext 는 하나의 Listable API 에 대한 상태관리를 해주는 컨텍스트이다.
 * 컨텍스트를 생성한 뒤, 반환되는 Context 와 Provider 를 이용할 수 있다.
 * Provider 의 children 컴포넌트들은 Context 를 이용하여 하나의 데이터에 대한 조회 및
 * API 호출을 수행할 수 있다.
 *
 * 대부분의 경우 기능을 래핑한 컴포넌트들 (ex: ODListablePaginationTable)을 사용하면 된다.
 */
import { Draft, produce } from 'immer'
import { findIndex } from 'lodash'
import React, { createContext, ReactNode, useCallback } from 'react'

export enum ODListableStyle {
  TableStyle,
  TimelineStyle,
}

export type ODListableResponseType<T> = {
  list: Array<T>
  totalCount: number
  page: number
  pageSize: number
}

type ItemKey = string
type KeyExtractFunc<T> = (item: T) => ItemKey
export type ODListableOption = { [s: string]: any }

type ODListableReducerOptions<T> = {
  keyExtractor: KeyExtractFunc<T>
}

//
// Actions
//
enum ODListableContextActionType {
  TYPE_SET_LOADING = 'ODListableContext/TYPE_SET_LOADING',
  TYPE_SET_LISTABLE_RESPONSE = 'ODListableContext/TYPE_SET_LISTABLE_RESPONSE',
  TYPE_REFRESH = 'ODListableContext/TYPE_REFRESH',
  TYPE_SET_PAGE_SIZE = 'ODListableContext/TYPE_SET_PAGE_SIZE',
  TYPE_SET_PAGE = 'ODListableContext/TYPE_SET_PAGE',
  TYPE_UPDATE_ITEM = 'ODListableContext/TYPE_UPDATE_ITEM',
  TYPE_UPDATE_LOAD_OPTION = 'ODListableContext/TYPE_UPDATE_LOAD_OPTION',
  TYPE_LOAD_MORE = 'ODListableContext/TYPE_LOAD_MORE',
  TYPE_REMOVE_ITEMS = 'ODListableContext/TYPE_REMOVE_ITEMS',
  TYPE_SET_LOAD_ERROR = 'ODListableContext/TYPE_SET_LOAD_ERROR',
  TYPE_ADD_TO_SELECTED = 'ODListableContext/TYPE_ADD_TO_SELECTED',
  TYPE_REMOVE_FROM_SELECTED = 'ODListableContext/TYPE_REMOVE_FROM_SELECTED',
  TYPE_DESELECT_ALL = 'ODListableContext/TYPE_DESELECT_ALL',
}

interface ActionSetLoading {
  type: ODListableContextActionType.TYPE_SET_LOADING
  loading: boolean
}

const actionSetLoading = (loading: boolean): ActionSetLoading => ({
  type: ODListableContextActionType.TYPE_SET_LOADING,
  loading,
})

interface ActionUpdateWithListableResponse<T> {
  type: ODListableContextActionType.TYPE_SET_LISTABLE_RESPONSE
  response: ODListableResponseType<T>
}

const actionSetListableResponse = <T,>(response: ODListableResponseType<T>): ActionUpdateWithListableResponse<T> => ({
  type: ODListableContextActionType.TYPE_SET_LISTABLE_RESPONSE,
  response,
})

interface ActionRefresh {
  type: ODListableContextActionType.TYPE_REFRESH
}

const actionRefresh = (): ActionRefresh => ({ type: ODListableContextActionType.TYPE_REFRESH })

interface ActionSetPageSize {
  type: ODListableContextActionType.TYPE_SET_PAGE_SIZE
  pageSize: number
}

const actionSetPageSize = (pageSize: number): ActionSetPageSize => ({
  type: ODListableContextActionType.TYPE_SET_PAGE_SIZE,
  pageSize,
})

interface ActionSetPage {
  type: ODListableContextActionType.TYPE_SET_PAGE
  page: number
}

const actionSetPage = (page: number): ActionSetPage => ({ type: ODListableContextActionType.TYPE_SET_PAGE, page })

interface ActionUpdateItem<T> {
  type: ODListableContextActionType.TYPE_UPDATE_ITEM
  item: T
}

const actionUpdateItem = <T,>(item: T): ActionUpdateItem<T> => ({
  type: ODListableContextActionType.TYPE_UPDATE_ITEM,
  item,
})

interface ActionUpdateLoadOption<O extends ODListableOption> {
  type: ODListableContextActionType.TYPE_UPDATE_LOAD_OPTION
  options: O
}

const actionUpdateLoadOptions = <O extends ODListableOption>(options: O): ActionUpdateLoadOption<O> => ({
  type: ODListableContextActionType.TYPE_UPDATE_LOAD_OPTION,
  options,
})

interface ActionLoadMore {
  type: ODListableContextActionType.TYPE_LOAD_MORE
  force: boolean
}

const actionLoadMore = (force: boolean = false): ActionLoadMore => ({
  type: ODListableContextActionType.TYPE_LOAD_MORE,
  force,
})

interface ActionRemoveItems {
  type: ODListableContextActionType.TYPE_REMOVE_ITEMS
  itemKeys: Array<ItemKey>
}

const actionRemoveItems = (itemKeys: Array<ItemKey>): ActionRemoveItems => ({
  type: ODListableContextActionType.TYPE_REMOVE_ITEMS,
  itemKeys,
})

interface ActionSetLoadError {
  type: ODListableContextActionType.TYPE_SET_LOAD_ERROR
  error: Error
}

const actionSetLoadError = (error: Error): ActionSetLoadError => ({
  type: ODListableContextActionType.TYPE_SET_LOAD_ERROR,
  error,
})

interface ActionAddToSelected<T> {
  type: ODListableContextActionType.TYPE_ADD_TO_SELECTED
  items: Array<T>
}

function actionAddToSelected<T>(items: Array<T>): ActionAddToSelected<T> {
  return {
    type: ODListableContextActionType.TYPE_ADD_TO_SELECTED,
    items,
  }
}

interface ActionRemoveFromSelected<T> {
  type: ODListableContextActionType.TYPE_REMOVE_FROM_SELECTED
  items: Array<T>
}

function actionRemoveFromSelected<T>(items: Array<T>): ActionRemoveFromSelected<T> {
  return {
    type: ODListableContextActionType.TYPE_REMOVE_FROM_SELECTED,
    items,
  }
}

interface ActionDeselectAll {
  type: ODListableContextActionType.TYPE_DESELECT_ALL
}

const actionDeselectAll = (): ActionDeselectAll => ({
  type: ODListableContextActionType.TYPE_DESELECT_ALL,
})

export type ODListableReducerAction<T, O extends ODListableOption> =
  | ActionSetLoading
  | ActionUpdateWithListableResponse<T>
  | ActionRefresh
  | ActionSetPageSize
  | ActionSetPage
  | ActionUpdateItem<T>
  | ActionUpdateLoadOption<O>
  | ActionLoadMore
  | ActionRemoveItems
  | ActionSetLoadError
  | ActionAddToSelected<T>
  | ActionRemoveFromSelected<T>
  | ActionDeselectAll

interface ODListableReducer<T, O extends ODListableOption>
  extends React.Reducer<ODListableReducerState<T, O>, ODListableReducerAction<T, O>> {}

export type ODListableReducerState<T, O extends ODListableOption> = {
  callCount: number
  loading: boolean
  list: Array<T>
  itemMap: { [itemKey: string]: T }
  pageToLoad: number
  page: number // currently loaded page. 0 if before initial loading.
  pages: number
  pageSize: number
  totalCount: number
  loadOption: O
  hasMore: boolean
  style: ODListableStyle
  lastLoadErrorAt: number
  selectedItems: Array<T> // itemId array
}

//
// Context APIs
//
type SetPageFunc = (page: number) => void
type SetPageSizeFunc = (pageSize: number) => void
type UpdateItemFunc<T> = (item: T) => void
type UpdateLoadOptionFunc<O extends ODListableOption> = (option: O) => void
type ReloadFunc = () => void
type RefreshFunc = () => void
type LoadMoreFunc = (force?: boolean) => void
type RemoveItemFunc = (itemKeys: Array<ItemKey>) => void

export interface ODListableContextType<T, O extends ODListableOption> {
  state: ODListableReducerState<T, O>
  setPage: SetPageFunc
  setPageSize: SetPageSizeFunc
  updateItem: UpdateItemFunc<T>
  updateLoadOption: UpdateLoadOptionFunc<O>
  reload: ReloadFunc
  refresh: RefreshFunc
  keyExtractor: KeyExtractFunc<T>
  loadMore: LoadMoreFunc
  removeItems: RemoveItemFunc
  addToSelected: (itemIds: Array<T>) => void
  removeFromSelected: (itemIds: Array<T>) => void
  deselectAll: () => void
}

function createListableReducer<T, O extends ODListableOption>(
  options: ODListableReducerOptions<T>
): ODListableReducer<T, O> {
  return function (
    state: ODListableReducerState<T, O>,
    action: ODListableReducerAction<T, O>
  ): ODListableReducerState<T, O> {
    return produce(state, (draft) => {
      switch (action.type) {
        case ODListableContextActionType.TYPE_SET_LOADING:
          draft.loading = action.loading
          break
        case ODListableContextActionType.TYPE_REFRESH:
          if (state.style === ODListableStyle.TimelineStyle) {
            draft.pageToLoad = 1
            draft.page = 0
            draft.hasMore = true
            draft.list = []
            draft.itemMap = {}
          }
          draft.callCount += 1
          break
        case ODListableContextActionType.TYPE_SET_PAGE:
          if (!draft.loading) {
            draft.pageToLoad = action.page
            draft.callCount += 1
          }
          break
        case ODListableContextActionType.TYPE_SET_PAGE_SIZE:
          if (!draft.loading) {
            draft.pageToLoad = 1
            draft.page = 0
            draft.pageSize = Math.min(Math.max(action.pageSize, 5), 100)
            draft.callCount += 1
            draft.hasMore = true
          }
          break
        case ODListableContextActionType.TYPE_UPDATE_LOAD_OPTION: {
          draft.pageToLoad = 1
          draft.page = 0
          draft.hasMore = true
          // @ts-ignore
          draft.loadOption = action.options
          if (state.style === ODListableStyle.TimelineStyle) {
            draft.list = []
            draft.itemMap = {}
          }
          draft.callCount += 1
          break
        }
        case ODListableContextActionType.TYPE_SET_LISTABLE_RESPONSE: {
          const { list, totalCount, page, pageSize } = action.response
          if (draft.style !== ODListableStyle.TimelineStyle || page === 1) {
            // 타임라인 스타일에서는, 첫 번째 리퀘스트만 토탈카운트가 제대로 넘어온다.
            draft.totalCount = totalCount
          }
          draft.page = page
          draft.pageSize = pageSize
          draft.pages = Math.max(Math.ceil(totalCount / pageSize), 1)
          draft.lastLoadErrorAt = 0

          if (draft.style === ODListableStyle.TableStyle) {
            // @ts-ignore
            draft.list = [...list]
          } else if (draft.style === ODListableStyle.TimelineStyle) {
            // @ts-ignore
            draft.list = [...draft.list, ...list]
            if (draft.list.length === 0 || list.length < pageSize) {
              draft.hasMore = false
            }
          }

          draft.list.forEach((item) => {
            const itemKey = options.keyExtractor(item as T)
            draft.itemMap[itemKey] = item
          })
          break
        }
        case ODListableContextActionType.TYPE_UPDATE_ITEM: {
          const { item } = action
          // const converted = Utils.convertDataWithRender(item)
          const key = options.keyExtractor(item)
          const index = findIndex(draft.list, (v) => options.keyExtractor(v as T) === key)
          if (index >= 0) {
            // @ts-ignore
            draft.list.splice(index, 1, item)
            // @ts-ignore
            draft.itemMap[key] = item
          }
          break
        }
        case ODListableContextActionType.TYPE_LOAD_MORE: {
          if ((action.force || draft.hasMore) && !draft.loading) {
            draft.pageToLoad = draft.page + 1
            draft.callCount += 1
          }
          break
        }
        case ODListableContextActionType.TYPE_REMOVE_ITEMS: {
          let removed = 0
          draft.list = draft.list.filter((v) => {
            const index = action.itemKeys.indexOf(options.keyExtractor(v as T))
            if (index === -1) {
              return true
            }
            removed += 1
            return false
          })
          draft.totalCount -= removed
          break
        }
        case ODListableContextActionType.TYPE_SET_LOAD_ERROR: {
          draft.lastLoadErrorAt = new Date().getTime()
          break
        }
        case ODListableContextActionType.TYPE_ADD_TO_SELECTED: {
          action.items.forEach((item) => {
            if (!draft.selectedItems.find((v) => options.keyExtractor(v as T) === options.keyExtractor(item))) {
              draft.selectedItems.push(item as Draft<T>)
            }
          })
          break
        }
        case ODListableContextActionType.TYPE_REMOVE_FROM_SELECTED: {
          action.items.forEach((item) => {
            const index = draft.selectedItems.findIndex(
              (v) => options.keyExtractor(v as T) === options.keyExtractor(item)
            )
            if (index >= 0) {
              draft.selectedItems.splice(index, 1)
            }
          })
          break
        }
        case ODListableContextActionType.TYPE_DESELECT_ALL: {
          draft.selectedItems = []
          break
        }
      }
    })
  }
}

export type ODListableDataLoadFunc<T, O extends ODListableOption> = (
  page: number,
  pageSize: number,
  afterKey: string | null,
  options: O
) => Promise<ODListableResponseType<T>>

interface ODListableContextDevOptions {
  simulateDelay: number
}

interface ODListableContextProps<T, O extends ODListableOption> {
  dataLoader: ODListableDataLoadFunc<T, O>
  keyExtractor: KeyExtractFunc<T>
  afterKeyExtractor?: (lastItem: T | null, state: ODListableReducerState<T, O>) => string | null // Timeline style 일 때 제공해야 한다.
  children?: ReactNode
  pageSize?: number
  devOptions?: ODListableContextDevOptions
  onDataLoaderError?: (ex: Error) => void
  searchOnLoad?: boolean // 시작하자마자 검색을 할 것인가? (default=true)
  refreshToken?: string // 만약 이 값이 변경되면 무조건 refresh 를 호출해준다. (단, falsey 값이 들어오면 무시한다.)
  style?: ODListableStyle
  initialLoadOptions?: Partial<O>
  noLoading?: boolean // 일부 상황에서는 로딩화면을 보여주지 않는 것이 좋을 때가 있다. (ex: 다른 API 로드의 결과물인 경우)
}

function createInitialReducerState<T, O extends ODListableOption>(
  options: Partial<ODListableReducerState<T, O>> = {}
): ODListableReducerState<T, O> {
  return {
    callCount: 0,
    loading: false,
    list: [],
    itemMap: {},
    pageToLoad: 1,
    page: 0, // currently loaded page
    pages: 0,
    pageSize: 10,
    totalCount: -1, // unknown
    loadOption: options.loadOption || ({} as O),
    hasMore: true,
    style: ODListableStyle.TableStyle,
    lastLoadErrorAt: 0,
    selectedItems: [],
    ...options,
  }
}

export function useODListableContext<T, O extends ODListableOption>(name?: string) {
  const [Context] = React.useState(() => {
    return createContext<ODListableContextType<T, O>>({} as ODListableContextType<T, O>)
  })

  const [Provider] = React.useState(() => {
    function Provider(props: ODListableContextProps<T, O>): React.ReactElement {
      const {
        onDataLoaderError,
        children,
        dataLoader,
        pageSize = 10,
        keyExtractor,
        afterKeyExtractor,
        devOptions,
        searchOnLoad = true,
        refreshToken,
        initialLoadOptions,
        noLoading = false,
        style = ODListableStyle.TableStyle,
      } = props
      const { simulateDelay = 0 } = devOptions || {}

      const [state, dispatch] = React.useReducer<ODListableReducer<T, O>>(
        createListableReducer<T, O>({ keyExtractor }),
        createInitialReducerState({ pageSize, style, loadOption: (initialLoadOptions || {}) as O })
      )

      const getListable = useCallback(
        async (state: ODListableReducerState<T, O>, doNotSetLoading = false) => {
          if (state.loading) {
            console.warn('Listable is already loading..')
            return
          }

          try {
            !doNotSetLoading && dispatch(actionSetLoading(true))

            const response = await dataLoader(
              state.pageToLoad,
              state.pageSize,
              afterKeyExtractor?.(state.list[state.list.length - 1] || null, state) || null,
              state.loadOption
            )
            await new Promise((resolve) => setTimeout(resolve, simulateDelay))
            dispatch(actionSetListableResponse(response))
          } catch (ex) {
            if (onDataLoaderError) {
              onDataLoaderError(ex)
            }
            dispatch(actionSetLoadError(ex))
            // onNetworkError && onNetworkError(ex)
            console.error(ex)
          } finally {
            !doNotSetLoading && dispatch(actionSetLoading(false))
          }
        },
        [onDataLoaderError, dataLoader, simulateDelay, afterKeyExtractor]
      )

      const setPage: (page: number) => void = useCallback((page: number) => dispatch(actionSetPage(page)), [dispatch])
      const setPageSize = useCallback((pageSize) => dispatch(actionSetPageSize(pageSize)), [dispatch])
      const updateItem = useCallback((item) => dispatch(actionUpdateItem(item)), [dispatch])
      const refresh = useCallback(() => dispatch(actionRefresh()), [dispatch])
      const updateLoadOption = useCallback((options: O) => dispatch(actionUpdateLoadOptions(options)), [dispatch])
      const reload = useCallback(() => {
        setPage(1)
        dispatch(actionRefresh())
      }, [setPage, dispatch])
      const loadMore = useCallback(
        (force: boolean = false) => {
          dispatch(actionLoadMore(force))
        },
        [dispatch]
      )
      const removeItems = useCallback((itemKeys: Array<ItemKey>) => dispatch(actionRemoveItems(itemKeys)), [dispatch])
      const addToSelected = useCallback((items: Array<T>) => dispatch(actionAddToSelected(items)), [dispatch])
      const removeFromSelected = useCallback((items: Array<T>) => dispatch(actionRemoveFromSelected(items)), [dispatch])
      const deselectAll = useCallback(() => dispatch(actionDeselectAll()), [dispatch])

      React.useEffect(() => {
        if (searchOnLoad) {
          refresh()
        }
      }, [refresh, searchOnLoad])

      React.useEffect(() => {
        if (refreshToken) {
          refresh()
        }
      }, [refresh, refreshToken])

      React.useEffect(() => {
        refresh()
      }, [refresh, getListable])

      React.useEffect(() => {
        if (state.callCount > 0) {
          // noinspection JSIgnoredPromiseFromCall
          getListable(state, noLoading)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [state.callCount])

      const context: ODListableContextType<T, O> = {
        state,
        setPage,
        setPageSize,
        updateItem,
        updateLoadOption,
        reload,
        refresh,
        keyExtractor,
        loadMore,
        removeItems,
        addToSelected,
        removeFromSelected,
        deselectAll,
      }
      return <Context.Provider value={context}>{children}</Context.Provider>
    }

    return Provider
  })

  return { Context, Provider }
}
