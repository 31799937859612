"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTFullScreenEffectOff = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const validation_1 = require("../../../validation");
const SelectionFieldWithUI_1 = require("../../fields/SelectionFieldWithUI");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOSTFullScreenEffectOff extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.hasModalForEdit = true;
        this.transitionStyleField = new SelectionFieldWithUI_1.SelectionFieldWithUI('transitionStyle', () => this.st.transitionStyle, value => {
            (0, mobx_1.runInAction)(() => {
                this.st.transitionStyle = value;
            });
        }, {
            label: trans('legacy_DOSTFullScreenEffectOff_effect_setting'),
            options: {
                noLabel: false,
            },
            selection: Object.values(core_1.TransitionStyle).map(item => ({
                name: core_1.TRANSITION_STYLE_TRANSLATED[item],
                value: item,
            })),
        });
        (0, mobx_1.makeObservable)(this, {});
    }
    get st() {
        return this.data;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [...super.editorFields, this.transitionStyleField];
    }
    validate() {
        const res = super.validate();
        const createValidationResult = (type) => {
            return {
                type,
                extra: {},
                severity: validation_1.StudioValidationSeverity.Error,
                source: this,
                stack: [],
            };
        };
        if (!Object.values(core_1.TransitionStyle).includes(this.st.transitionStyle)) {
            res.push(createValidationResult(validation_1.StudioValidationType.FullScreenEffectOffHasInvalidTransitionStyle));
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = res));
        return res;
    }
    generateSheetColumnsAfterBackground() {
        const result = [
            '300',
            '',
            '',
            '',
            '',
            '',
            this.st.transitionStyle,
        ];
        if (!!this.executeConditionOfStatement) {
            result.push(this.executeConditionOfStatement);
        }
        return result;
    }
}
exports.DOSTFullScreenEffectOff = DOSTFullScreenEffectOff;
