"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALDeleteModal = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const consts_1 = require("../../../consts");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ALDeleteModal {
    constructor(modalTitle, text, onSubmit, confirmButtonText) {
        this.modalTitle = modalTitle;
        this.textUnderTitle = text;
        this.onSubmit = onSubmit;
        this.confirmButtonText =
            confirmButtonText !== null && confirmButtonText !== void 0 ? confirmButtonText : trans('legacy_ALDeleteModal.ts_delete');
    }
    async submit() {
        return this.onSubmit();
    }
    buildActionChain() {
        const builder = new ActionChainBuilder_1.ActionChainBuilder('ALDeleteModal');
        builder.addModal(modalBuilder => {
            modalBuilder.setModalSize('normal');
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: this.modalTitle,
                textUnderTitle: this.textUnderTitle,
                imageName: consts_1.SPImageName.IcnDeleteWarning,
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_EditAuthorInfoPage_cancellation'),
                        onClick: () => {
                            modalBuilder.cancelAction();
                        },
                    },
                    {
                        theme: autoLayout_1.ButtonTheme.RedFilled,
                        text: this.confirmButtonText,
                        onClick: async () => {
                            if (await this.submit()) {
                                modalBuilder.finishAction(null);
                            }
                        },
                    },
                ],
            });
        });
        return builder.buildActionChain();
    }
}
exports.ALDeleteModal = ALDeleteModal;
