"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropOpFieldWithUI = void 0;
const __1 = require("..");
const SelectionFieldWithUI_1 = require("./SelectionFieldWithUI");
class PropOpFieldWithUI extends SelectionFieldWithUI_1.SelectionFieldWithUI {
    constructor(fieldId, getter, setter, uiOptions = {}) {
        super(fieldId, getter, setter, {
            ...uiOptions,
            selection: [
                { value: __1.CommonPropOperation.SET_NUMBER, name: '=' },
                {
                    value: __1.CommonPropOperation.INCREASE_NUMBER,
                    name: '+',
                },
            ],
        });
    }
}
exports.PropOpFieldWithUI = PropOpFieldWithUI;
