"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOMonthlyStoryService = void 0;
const core_1 = require("@storyplay/core");
class DOMonthlyStoryService {
    constructor(rootStore, data) {
        this.countryCode = null;
        this.storyId = null;
        this.yyyymm = null;
        this.data = null;
        this.changeSet = null;
        this.rootStore = rootStore;
        this.data = data !== null && data !== void 0 ? data : null;
        this.merge(data);
    }
    get storyByStoryId() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.story) !== null && _b !== void 0 ? _b : null;
    }
    get serverId() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.monthlyBundleId) !== null && _b !== void 0 ? _b : -1;
    }
    merge(data) {
        if (!data) {
            return this;
        }
        const fields = [
            'monthlyBundleId',
            'countryCode',
            'storyId',
            'yyyymm',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, core_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
    create(changeSet) {
        const req = changeSet.hasOwnProperty('countryCode')
            ? changeSet
            : { ...changeSet, countryCode: '__' };
        return this.rootStore.di.server.createMonthlyBundle(req);
    }
    update(changeSet) {
        this.changeSet = changeSet;
        if (this.serverId === -1) {
            return this.create(changeSet);
        }
        const req = changeSet.hasOwnProperty('yyyymm')
            ? changeSet
            : { ...changeSet, yyyymm: this.yyyymm };
        return this.rootStore.di.server.updateEntityForStudio(this, req);
    }
}
exports.DOMonthlyStoryService = DOMonthlyStoryService;
