import {
  BlockingLoadBox,
  ODButton,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableResponseType,
  ODModal,
  ODModalBody,
  ODModalFooter,
  ODModalHeader,
  ODModalSize,
  ODWebUtils,
  useCounter,
  useODListableContext,
  useODQuery,
} from '@odc/od-react-belt'
import {
  CHOICE_TYPE,
  GQLImageFile,
  GQLListableImageFile,
  GQLListableImageFileInput,
} from '@storyplay/common'
import React from 'react'
import { Col, Row } from 'reactstrap'
import { HoverTR } from '../../components/commonStyle'
import { GQL_LIST_IMAGE_FILE } from '../../utils/gqls_web'
import { Utils } from '../../utils/utils'
import { ImageFilePrimaryKey } from './ImageFileCommon'
import { ImageFileDataLoaderOption } from './ImageFileListContainer'

interface IImageFileMakeJSONModalProps {
  onClose: () => void
  onConfirm: () => void
}

export const ImageFileMakeJSONModal: React.FC<IImageFileMakeJSONModalProps> = (
  props
) => {
  const { onClose, onConfirm } = props
  const [choiceImageUrls, setChoiceImageUrls] = React.useState<string[]>([])
  const [showStatistics, setShowStatistics] = React.useState<boolean>(false)
  const [displayName, setDisplayName] = React.useState<string>('')
  const listImageFiles = useODQuery<
    GQLListableImageFileInput,
    GQLListableImageFile
  >(GQL_LIST_IMAGE_FILE)
  const { Provider, Context } = useODListableContext<
    GQLImageFile,
    ImageFileDataLoaderOption
  >()
  const [loading] = React.useState(false)
  const [token, refresh] = useCounter()
  const dataLoader = React.useCallback(
    async function ImageFileDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ImageFileDataLoaderOption
    ): Promise<ODListableResponseType<GQLImageFile>> {
      const r = await listImageFiles({
        page,
        pageSize,
      })
      return r as ODListableResponseType<GQLImageFile>
    },
    [listImageFiles]
  )
  const handleConfirm = async () => {
    if (showStatistics && !displayName) {
      return Utils.showError('통계 표시 문구를 입력하세요!')
    }
    if (choiceImageUrls.length === 0) {
      return Utils.showError('선택지의 이미지를 1개이상 등록하세요!')
    }
    const options = {
      layoutType: CHOICE_TYPE.ImageChoice,
      showStatistics,
      choiceImageUrls,
    }
    if (showStatistics) {
      // @ts-ignore
      options.displayName = displayName
    }
    navigator.clipboard.writeText(JSON.stringify(options))
    Utils.showSuccess('JSON이 복사되었습니다! 엑셀에 붙여 넣어주세요!')
    onConfirm()
  }
  const removeChoiceUrls = (e: any) => {
    const idx = choiceImageUrls.findIndex((_, idx) => idx === +e.target.name)
    if (idx > -1) choiceImageUrls.splice(idx, 1)
    setChoiceImageUrls([...choiceImageUrls])
  }
  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Normal}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="이미지 선택지 JSON 만들기" onClose={onClose} />
        <ODModalBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v[ImageFilePrimaryKey].toString()}
            pageSize={5}
            refreshToken={token.toString()}
            onDataLoaderError={ODWebUtils.showError}
            searchOnLoad
          >
            <BlockingLoadBox show={loading} />
            <ODListablePaginatedTable2
              numColumns={2}
              listableContext={Context}
              renderHeader={() => (
                <tr>
                  <th>이미지 아이디</th>
                  <th>사진</th>
                </tr>
              )}
              renderRow={(value: GQLImageFile, context) => (
                <HoverTR
                  key={value.fileId}
                  onClick={() => {
                    if (choiceImageUrls.length < 5) {
                      return setChoiceImageUrls(
                        choiceImageUrls.concat(value.link)
                      )
                    }
                    Utils.showError(
                      '선택지 이미지는 최대 5개 등록할 수 있습니다.'
                    )
                  }}
                >
                  <td>{value.fileId}</td>
                  <td>
                    <img src={value.link} width={50} height={50} alt="image" />
                  </td>
                </HoverTR>
              )}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage listableContext={Context} />
          </Provider>
        </ODModalBody>
        <ODModalBody>
          <Row style={{ marginBottom: 20 }}>
            <Col md={4}>통계 표시 여부</Col>
            <Col md={8}>
              <input
                type="checkbox"
                onChange={() => {
                  setShowStatistics(!showStatistics)
                }}
              />
            </Col>
          </Row>
          {showStatistics && (
            <Row style={{ marginBottom: 20 }}>
              <Col md={4}>통계 표시 문구</Col>
              <Col md={8}>
                <input
                  type="text"
                  onChange={(e: any) => {
                    setDisplayName(e.target.value)
                  }}
                />
              </Col>
            </Row>
          )}
          {choiceImageUrls.map((url, idx) => {
            return (
              <Row style={{ marginBottom: 20 }}>
                <Col md={4}>{idx + 1}번째 선택지 이미지</Col>
                <Col md={7}>
                  <img src={url} width={50} height={50} alt="image" />
                </Col>
                <Col md={1}>
                  <input
                    type="button"
                    name={`${idx}`}
                    key={idx}
                    onClick={removeChoiceUrls}
                    value="X"
                  />
                </Col>
              </Row>
            )
          })}
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            fullWidth
            theme={ODButtonTheme.Primary}
            onClick={handleConfirm}
          >
            복사하기
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
