import React from 'react'
import Select from 'react-select'

export type IEndingIsFinalSelectFilterType = boolean | null

interface IEndingIsFinalSelectProps {
  value: IEndingIsFinalSelectFilterType
  onChange: (value: IEndingIsFinalSelectFilterType) => void
}

const SELECT_OPTIONS = [
  { value: true, label: '최종 엔딩만' },
  { value: false, label: '챕터 엔딩만' },
  { value: null, label: '모든 엔딩' },
]

export const EndingIsFinalSelect: React.FC<IEndingIsFinalSelectProps> = (props) => {
  const { value, onChange } = props
  return (
    <Select
      placeholder="최종 엔딩 여부"
      value={SELECT_OPTIONS.find((v) => v.value === value)}
      // @ts-ignore
      onChange={(v) => onChange(v.value)}
      options={SELECT_OPTIONS}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 2 }),
      }}
    />
  )
}
