import {
  BlockingLoadBox,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
  useWrappedAPI,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { GQLEndingShowsProperty } from '../../../@types/server'
import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
} from '../../../components/base/ODButton'
import { HoverTR } from '../../../components/commonStyle'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils/utils'
import { useStoryPropertyPickerModal } from '../../storyProperty/list/useStoryPropertyPicker'
import {
  EndingShowsPropertyPrimaryKey,
  IEndingShowsPropertyListableOption,
} from '../EndingShowsPropertyCommon'
import { useEndingShowsPropertyListDataLoader } from './EndingShowsPropertyListDataLoader'

interface IEndingShowsPropertyListableTableProps {
  storyId: number
  endingId: number
  isFinal: boolean
}

export const EndingShowsPropertyListableTable: React.FC<IEndingShowsPropertyListableTableProps> = (
  props
) => {
  const { storyId, endingId, isFinal } = props
  const [loading, setLoading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLEndingShowsProperty,
    IEndingShowsPropertyListableOption
  >()
  const [token, refresh] = useCounter()
  const dataLoader = useEndingShowsPropertyListDataLoader(storyId, endingId)
  const [redirect, setRedirect] = React.useState('')
  const {
    pick,
    Component: PickerComponent,
    props: pickerProps,
  } = useStoryPropertyPickerModal(storyId)
  const { createEndingShowsProperty: apiCreateESP } = useStoryPlayAPIs()
  const createESP = useWrappedAPI(
    (propId: number) =>
      apiCreateESP({
        endingId,
        storyPropId: propId,
        customImageFile: null,
      }),
    loading,
    setLoading,
    {
      deps: [endingId],
      successMessage: '추가하였습니다.',
    }
  )

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  const handleCreateNew = async () => {
    const picked = await pick()
    if (!picked) {
      return
    }

    const [propId] = picked
    await createESP(propId as number)
    refresh()
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[EndingShowsPropertyPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <PickerComponent {...pickerProps} />
      <BlockingLoadBox show={loading} />
      <div style={toolbarStyle}>
        <ODListableSearchBox
          listableContext={Context}
          placeholder="이름으로 검색"
          style={searchBoxStyle}
        />
        <ODButton
          theme={ODButtonTheme.Primary}
          size={ODButtonSize.Small}
          style={{ minWidth: 135, borderRadius: 5, height: 35 }}
          onClick={handleCreateNew}
        >
          신규 할당
        </ODButton>
      </div>
      <ODListablePaginatedTable2
        numColumns={9}
        listableContext={Context}
        renderHeader={() => (
          <tr>
            <th>속성 아이디</th>
            <th>속성명</th>
            <th>속성타입</th>
            <th>속성 최대값</th>
            <th>이미지파일</th>
            <th>커스텀 이미지파일</th>
          </tr>
        )}
        renderRow={(value: GQLEndingShowsProperty, context) => {
          return (
            <HoverTR
              key={value.espId}
              onClick={() =>
                setRedirect(
                  SiteUrls.Admin.Story.EditEndingShowsProperty(
                    storyId,
                    endingId,
                    value.espId,
                    isFinal
                  )
                )
              }
            >
              <td>{value.storyProp.propId}</td>
              <td>{value.storyProp.propName}</td>
              <td>{Utils.formatStoryPropertyType(value.storyProp.propType)}</td>
              <td>{value.storyProp.maxValue ?? '-'}</td>
              <td>
                {value.storyProp.imageFile?.link ? (
                  <img
                    src={value.storyProp.imageFile!.link}
                    width={100}
                    height={100}
                    alt="ending shows property"
                  />
                ) : (
                  <span>미등록</span>
                )}
              </td>
              <td>
                {value.customImageFile?.link ? (
                  <img
                    src={value.customImageFile!.link}
                    width={100}
                    height={100}
                    alt="ending shows property custom"
                  />
                ) : (
                  <span>미등록</span>
                )}
              </td>
            </HoverTR>
          )
        }}
        eventParentContext={{}}
      />
      <ODListablePagination hideIfSinglePage listableContext={Context} />
    </Provider>
  )
}
