import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  ODImageFileInput,
  useODEntityAPI,
} from '@odc/od-react-belt'
import {
  CHALLENGE_STORY_GENRE,
  GQLSingleIDInput,
  GQLStory,
  GQLStoryPropsInput,
  GQLSTORY_PLAY_TYPE,
  STORY_GENRE_TO_KO_STRING,
  ContentsLanguages,
} from '@storyplay/core'
import * as faker from 'faker'
import { pick } from 'lodash'
import moment from 'moment'
import React, { useCallback } from 'react'
import Select from 'react-select'
import { Card, CardBody, Col, Row } from 'reactstrap'
import styled from 'styled-components'
import {
  STORY_ENDING_ARRIVAL_RATE_TYPE,
  STORY_SECTION_TYPE,
  STORY_TYPE,
} from '../../../common'
import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
} from '../../../components/base/ODButton'
import { ODFormToggleButton } from '../../../components/ODFormToggleButton'
import { SPFormToggleButton } from '../../../components/SPFormToggleButton'
import { useCounter } from '../../../context/ODCommon'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { SiteUrls } from '../../../urls'
import { AppOptions } from '../../../utils/AppOptions'
import { Utils } from '../../../utils/utils'
import { StoryChapterCoinItem } from '../../story/StoryChapterCoinItem'
import { ICTEPUBStory, EPUBStoryPrimaryKey } from '../EPUBStoryCommon'

type PropsInput = GQLStoryPropsInput
type Entity = GQLStory
type ICTEntity = ICTEPUBStory // Client-Type

const ipSourcingDefaultValue = {
  name: '연결된 ip sourcing 없음',
  ipSourcingId: null,
}

const DEFAULT_DATA: Partial<ICTEntity> = {
  storyId: '',
  name: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  defaultFirstName: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  defaultLastName: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  topic: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  oneLineDesc: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  targetAge: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  coverText: !AppOptions.FILL_MOCK_DATA ? '' : faker.lorem.word(),
  storyPropsMigration: null,
  endingMigration: null,
  representedAt: null,
  finishedAt: null,
  // @ts-ignore
  sectionType: STORY_SECTION_TYPE.OnStage,
  showOrder: 1000,
  endingArrivalRateRange: 10,
  // @ts-ignore
  endingArrivalRateType: STORY_ENDING_ARRIVAL_RATE_TYPE.InVisible,
  // @ts-ignore
  type: STORY_TYPE.Chat,
}

const GENRES = Object.keys(STORY_GENRE_TO_KO_STRING)

interface IEPUBStoryMainPropsEditPageProps {
  storyId: number | null
}

function diffClientValue<C extends object>(
  updated: C,
  original: C
): Partial<C> {
  const keysChanged: string[] = []
  Object.keys(updated).forEach((key) => {
    // @ts-ignore
    if (updated[key] !== original[key]) {
      keysChanged.push(key)
    }
  })

  return pick(updated, ...keysChanged)
}

export const EPUBStoryMainPropsEditPage: React.FC<
  IEPUBStoryMainPropsEditPageProps
> = (props) => {
  const { storyId } = props

  let [detailInfoList, setDetailInfoList] = React.useState<
    { key: string; value: string }[]
  >([])
  let detailInfoRef = React.useRef<{ key: string; value: string }[]>([])

  const sectionTypeRef = React.useRef('')
  const [sectionTypeState, setSectionTypeState] = React.useState<string>('')
  const setSectionTypeRef = (v: string) => {
    sectionTypeRef.current = v
    setSectionTypeState(sectionTypeRef.current)
  }
  const selectedNoticeRef = React.useRef<number | null>(null)
  const selectedLangCodeRef = React.useRef<string | null>(null)
  const selectedGenreRef = React.useRef<string | null>(null)

  const [ipSourcingOption, setIpSourcingOption] = React.useState<
    { label: string; value: number | null }[]
  >([])
  const selectedIpSourcingRef = React.useRef<number | null>(null)

  const { apiCreateStory, apiUpdateStory, apiGetStory, listIpSourcing } =
    useStoryPlayAPIs()

  const setInput = useCallback((input: GQLStoryPropsInput) => {
    input = setInputLocalDate(input, ['storyEndsAt', 'finishedAt'])
    input.defaultFirstName = 'null'
    input.ipSourcingId = selectedIpSourcingRef.current
    input.storyDetailInfo =
      detailInfoRef.current.length > 0
        ? detailInfoRef.current.reduce(
            (acc, { key, value }) => ({
              ...acc,
              [key]: value.split(',').map((d) => d.trim()),
            }),
            {}
          )
        : {}
    input.script = '{}'
    input.playType = GQLSTORY_PLAY_TYPE.EPUB
    input.languageCode = selectedLangCodeRef.current
    input.genre = selectedGenreRef.current
    return input
  }, [])

  const setInputLocalDate = (input: GQLStoryPropsInput, dateList: string[]) => {
    dateList.forEach((date) => {
      // @ts-ignore
      if (input[date]) {
        // @ts-ignore
        input[date] = moment(input[date]).toDate()
      }
    })
    return input
  }

  const createAPI = React.useCallback(
    async (input: GQLStoryPropsInput) => {
      input = setInput(input)
      return apiCreateStory(input)
    },
    [apiCreateStory, setInput]
  )

  const [video, setVideo] = React.useState('')
  const videoFile = React.useRef<File | undefined>(undefined)

  const updateAPI = React.useCallback(
    async (input: GQLStoryPropsInput) => {
      input = setInput(input)
      return apiUpdateStory({
        ...input,
        ...(videoFile.current && { teaserVideoFile: videoFile.current }),
      })
    },
    [apiUpdateStory, setInput]
  )

  const readAPI = React.useCallback(
    async (input: GQLSingleIDInput) => {
      const result = await apiGetStory(input)
      if (result.storyDetailInfo) {
        detailInfoRef.current = Object.keys(result.storyDetailInfo).map(
          (k) => ({ key: k, value: result.storyDetailInfo[k].join(',') })
        )
        setDetailInfoList([...detailInfoRef.current])
      }
      selectedNoticeRef.current = result.noticeId
      const noticeTitle = result.notice
        ? `[${result.notice.noticeId}] ${result.notice.title}`
        : undefined
      const ipSourcingName = result.ipSourcing
        ? `[${result.ipSourcing.ipSourcingId}] ${result.ipSourcing.name}`
        : undefined
      const refStoryName = result.refStory
        ? `[${result.refStory.storyId}] ${result.refStory.name}`
        : '없음'
      selectedLangCodeRef.current = result.languageCode
      selectedGenreRef.current = result.genre
      sectionTypeRef.current = result.sectionType
      setSectionTypeState(sectionTypeRef.current)

      return {
        ...result,
        representedAt: moment(result.representedAt).format('YYYY-MM'),
        storyEndsAt: moment(result.storyEndsAt).format('YYYY-MM-DDTHH:mm'),
        willPublishAt: moment(result.willPublishAt).format('YYYY-MM-DDTHH:mm'),
        finishedAt: moment(result.finishedAt).format('YYYY-MM-DDTHH:mm'),
        noticeTitle,
        ipSourcingName,
        refStoryName,
      }
    },
    [apiGetStory]
  )

  const apis = useODEntityAPI<Entity, ICTEntity, PropsInput, PropsInput>({
    createAPI,
    updateAPI,
    readAPI,
    primaryKeyName: EPUBStoryPrimaryKey,
    callUpdateWhenNoChangeFound: true,
  })

  const [token, refresh] = useCounter()

  const handleDetailInfoCreate = () => {
    setDetailInfoList(
      detailInfoList.concat({
        key: '',
        value: '',
      })
    )
    detailInfoRef.current = detailInfoList.concat({
      key: '',
      value: '',
    })
  }

  const writeDetailInfoKey = (e: any) => {
    const idx = detailInfoList.findIndex(
      (_, idx) => `${idx}_key` === e.target.name
    )
    if (idx > -1) detailInfoList[idx].key = e.target.value
    setDetailInfoList([...detailInfoList])
    detailInfoRef.current = [...detailInfoList]
  }

  const writeDetailInfoValue = (e: any) => {
    const idx = detailInfoList.findIndex(
      (_, idx) => `${idx}_value` === e.target.name
    )
    if (idx > -1) detailInfoList[idx].value = e.target.value
    setDetailInfoList([...detailInfoList])
    detailInfoRef.current = [...detailInfoList]
  }

  const removeDetailInfoList = (e: any) => {
    const idx = detailInfoList.findIndex(
      (_, idx) => `${idx}_delete` === e.target.name
    )
    if (idx > -1) detailInfoList.splice(idx, 1)
    setDetailInfoList([...detailInfoList])
    detailInfoRef.current = [...detailInfoList]
  }

  React.useEffect(() => {
    listIpSourcing({ pageSize: 1000 }).then((r) => {
      const ipSourcingList = [ipSourcingDefaultValue, ...r.list].map(
        (ipSourcing) => ({
          label: ipSourcing.name!,
          value: ipSourcing.ipSourcingId!,
        })
      )
      setIpSourcingOption(ipSourcingList)
    })
  }, [listIpSourcing])

  return (
    <>
      <Card>
        <CardBody>
          <ODEntity
            resourceId={storyId}
            // @ts-ignore
            api={apis}
            saveButtonName="저장"
            titleCreation="추가"
            titleUpdate="수정"
            diffClientValue={diffClientValue}
            updateSuccessTitle="성공"
            updateSuccessMessage="수정하였습니다."
            deleteSuccessTitle="성공"
            deleteSuccessMessage="삭제하였습니다."
            defaultCreateClientData={DEFAULT_DATA}
            urlAfterCreation={(c) => SiteUrls.Admin.EPUBStory.Edit(c.storyId)}
            urlAfterUpdate={(c) => refresh()}
            noCardWrap
            refreshToken={token}
            onBeforeSubmit={(editedStory: GQLStory) => {
              const { allChapters, lastPreviewChapterIndex } = editedStory
              if (
                lastPreviewChapterIndex === undefined ||
                lastPreviewChapterIndex === null
              ) {
                return true
              }

              if (lastPreviewChapterIndex.toString() === '') {
                editedStory.lastPreviewChapterIndex = null
                return true
              }

              const { 0: first, length, [length - 1]: last } = allChapters

              if (lastPreviewChapterIndex < first.chapterIndex) {
                Utils.showError(
                  `lastPreviewChapterIndex는 ${first.chapterIndex}보다 크거나 같아야 합니다.`
                )
                return false
              }

              if (lastPreviewChapterIndex > last.chapterIndex) {
                Utils.showError(
                  `lastPreviewChapterIndex는 ${last.chapterIndex}보다 작거나 같아야 합니다.`
                )
                return false
              }

              return true
            }}
          >
            <ODEntityInput
              keyPath="storyId"
              label="작품 ID"
              name="storyId"
              placeholder="작품 생성 후 자동 생성됩니다."
              inputType="text"
              inputProps={{ disabled: true }}
            />

            {storyId && (
              <ODEntityLabeled label="작품 업데이트 시각" name="updatedAt">
                <ODEntityRaw
                  name="updatedAt"
                  keyPath="updatedAt"
                  render={({ value }) => {
                    return (
                      <div style={{ color: 'green' }}>
                        {Utils.formatDate(value)}
                      </div>
                    )
                  }}
                />
              </ODEntityLabeled>
            )}

            <ODEntityInput
              keyPath="name"
              label="작품명"
              name="name"
              placeholder="작품명을 입력해주세요"
              inputType="text"
            />
            <ODEntityLabeled label="작품 유형" name="작품 유형">
              <b>웹소설</b>
            </ODEntityLabeled>
            <ODEntityLabeled label="작품 구분" name="작품 구분">
              <ToggleButtonWrapper>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.OnStage}
                  onClick={setSectionTypeRef}
                >
                  정식 작품
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.Dropped}
                  onClick={setSectionTypeRef}
                >
                  보물상자
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.Global}
                  onClick={setSectionTypeRef}
                >
                  글로벌
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.Adult}
                  onClick={setSectionTypeRef}
                >
                  성인 인증
                </SPFormToggleButton>
                <SPFormToggleButton
                  name="sectionType"
                  keyPath="sectionType"
                  value={STORY_SECTION_TYPE.UGC}
                  onClick={setSectionTypeRef}
                >
                  UGC
                </SPFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="성인 작품 여부?" name="isAdult">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isAdult_true"
                  name="isAdult"
                  keyPath="isAdult"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isAdult_false"
                  name="isAdult"
                  keyPath="isAdult"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled name={'작품장르'} label={'작품장르'}>
              <Row>
                <Col md={2}>
                  <ODEntityRaw
                    name="genre"
                    keyPath="genre"
                    render={({ value }) => {
                      // @ts-ignore
                      const label = STORY_GENRE_TO_KO_STRING[value]
                      return <span>{label ? label : '없음'}</span>
                    }}
                  />
                </Col>
                <Col md={8}>
                  <Select
                    isSearchable={true}
                    placeholder="작품의 장르를 선택해주세요"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedGenreRef.current = v.value
                      }
                    }}
                    options={GENRES.map((genre) => {
                      const k = genre as unknown as CHALLENGE_STORY_GENRE
                      return {
                        label: STORY_GENRE_TO_KO_STRING[k],
                        value: k,
                      }
                    })}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <ODEntityLabeled name={'언어코드'} label={'언어코드'}>
              <Row>
                <Col md={2}>
                  <ODEntityRaw
                    name="languageCode"
                    keyPath="languageCode"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </Col>
                <Col md={8}>
                  <Select
                    isSearchable={true}
                    placeholder="작품의 언어를 선택해주세요"
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedLangCodeRef.current = v.value
                      }
                    }}
                    options={ContentsLanguages.map((lng) => ({
                      label: `${lng.code}(${lng.display})`,
                      value: lng.code,
                    }))}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <ODEntityLabeled label="휴재 중?" name="onHiatus">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="onHiatus_true"
                  name="onHiatus"
                  keyPath="onHiatus"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="onHiatus_false"
                  name="onHiatus"
                  keyPath="onHiatus"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="홈 배너 등록?" name="isBanner">
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="isBanner_true"
                  name="isBanner"
                  keyPath="isBanner"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="isBanner_false"
                  name="isBanner"
                  keyPath="isBanner"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityLabeled label="가격 설정(회차)" name="studioPriceSetting">
              <ODEntityRaw
                name="studioPriceSetting"
                keyPath="studioPriceSetting"
                render={({ value }) => {
                  return <StoryChapterCoinItem studioPriceSetting={value} />
                }}
              />
            </ODEntityLabeled>
            <ODEntityLabeled
              label="동일 인터랙티브 작품"
              name="동일 인터랙티브 작품"
            >
              <ODEntityRaw
                name="refStoryName"
                keyPath="refStoryName"
                render={({ value }) => {
                  return <span>{value}</span>
                }}
              />
            </ODEntityLabeled>
            <ODEntityLabeled
              label="통구매 가능?(기능미구현)"
              name="purchasbleRaw"
            >
              <ToggleButtonWrapper>
                <ODFormToggleButton
                  key="purchasableRaw_true"
                  name="purchasableRaw"
                  keyPath="purchasableRaw"
                  value={true}
                >
                  Y
                </ODFormToggleButton>
                <ODFormToggleButton
                  key="purchasableRaw_false"
                  name="purchasableRaw"
                  keyPath="purchasableRaw"
                  value={false}
                >
                  N
                </ODFormToggleButton>
              </ToggleButtonWrapper>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="originalPrice"
              label="통구매 가격(할인 전)(기능미구현)"
              name="originalPrice"
              placeholder="작품 통구매 할인 전 가격을 입력해주세요."
              inputType="number"
            />
            <ODEntityInput
              keyPath="lastPreviewChapterIndex"
              label="통구매 전 무료로 볼 수 있는 마지막 회차 index(1 based)"
              name="lastPreviewChapterIndex"
              placeholder="설정된 회차 인덱스 이하에 해당하는 챕터는 통구매가 안되어도 무료로 플레이 가능합니다."
              inputType="number"
            />
            <ODEntityInput
              keyPath="price"
              label="통구매 가격(할인 후)(기능미구현)"
              name="price"
              placeholder="작품 통구매 할인 후 가격을 입력해주세요."
              inputType="number"
            />
            <ODEntityInput
              keyPath="discountRate"
              label="통구매 할인율(가격 업데이트 후 적용됨)(기능미구현)"
              name="discountRate"
              placeholder="가격 업데이트 완료 후 적용됩니다"
              inputType="number"
              inputProps={{ disabled: true }}
            />
            <ODEntityInput
              keyPath="representedAt"
              label="월간 인기작"
              name="representedAt"
              inputType="month"
              placeholder="월간 인기작"
            />
            <ODEntityInput
              keyPath="storyEndsAt"
              label="작품 만료일"
              name="storyEndsAt"
              inputType="datetime-local"
              placeholder="작품 만료일"
            />
            <ODEntityInput
              keyPath="willPublishAt"
              label="작품 공개 예정일"
              name="willPublishAt"
              inputType="datetime-local"
              placeholder="작품 공개 예정일"
            />
            <ODEntityLabeled name={'IP소싱 ID'} label={'IP소싱 ID'}>
              <Row>
                <Col md={6}>
                  <Select
                    isSearchable={true}
                    placeholder="IP소싱 ID를 선택해주세요."
                    onChange={(v) => {
                      if (v) {
                        // @ts-ignore
                        selectedIpSourcingRef.current = v.value
                      }
                    }}
                    options={ipSourcingOption}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 2 }),
                    }}
                  />
                </Col>
                <Col md={4}>
                  <ODEntityRaw
                    name="ipSourcingName"
                    keyPath="ipSourcingName"
                    render={({ value }) => {
                      return <span>{value ? value : '없음'}</span>
                    }}
                  />
                </Col>
              </Row>
            </ODEntityLabeled>
            <ODEntityInput
              keyPath="authorName"
              label="작가 이름"
              name="authorName"
              placeholder="작가 이름을 입력해주세요."
              inputType="text"
            />
            <ODEntityInput
              keyPath="topic"
              label="주제"
              name="topic"
              placeholder="주제를 입력해주세요."
              inputType="text"
            />
            <ODEntityInput
              keyPath="oneLineDesc"
              label="작품 설명"
              name="oneLineDesc"
              placeholder="작품에 대한 설명을 입력해주세요."
              inputType="textarea"
              rows={3}
            />
            <ODEntityInput
              keyPath="shortDesc"
              label="한 줄 설명(15자 이내)"
              name="shortDesc"
              placeholder="작품에 대한 설명을 15자 이내로 입력해주세요. (메인화면에 나올 문구)"
              inputType="text"
              inputProps={{ maxLength: 20 }}
            />
            <ODEntityInput
              keyPath="targetAge"
              label="타깃 연령"
              name="targetAge"
              placeholder="타깃 연령을 입력해주세요."
              inputType="text"
            />
            <ODEntityInput
              keyPath="coverText"
              label="띠지 텍스트"
              name="coverText"
              placeholder="띠지 텍스트를 입력해주세요."
              inputType="text"
            />
            <ODEntityLabeled label="작품 크레딧 설정" name="detailSetting">
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{
                  float: 'left',
                  paddingRight: 10,
                  paddingLeft: 10,
                  marginRight: 10,
                }}
                onClick={handleDetailInfoCreate}
              >
                + 크레딧 추가하기
              </ODButton>
              <div style={{ color: 'blue' }}>
                작품 제작에 참여한 사람이나 단체 정보 등을 입력 할 수 있습니다.
              </div>
            </ODEntityLabeled>
            {detailInfoList.length > 0 && (
              <>
                <ODEntityLabeled
                  name={'작품 크레딧 데이터 작성'}
                  label={'작품 크레딧 데이터 작성'}
                >
                  <ul>
                    {' '}
                    {detailInfoList.map(({ key, value }, idx) => {
                      return (
                        <li key={idx}>
                          {' '}
                          <input
                            style={{ width: '20%' }}
                            type="text"
                            key={`${idx}_key`}
                            name={`${idx}_key`}
                            value={key}
                            placeholder="크레딧 키"
                            onChange={writeDetailInfoKey}
                          />
                          :{' '}
                          <input
                            style={{ width: '70%' }}
                            type="text"
                            key={`${idx}_value`}
                            name={`${idx}_value`}
                            value={value}
                            placeholder="다수의 경우 반점(,) 으로 구분하여 적어주세요. ex) 이철수,김영희"
                            onChange={writeDetailInfoValue}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="button"
                            name={`${idx}_delete`}
                            key={`${idx}_delete`}
                            onClick={removeDetailInfoList}
                            value="X"
                          />
                        </li>
                      )
                    })}
                  </ul>
                </ODEntityLabeled>
              </>
            )}
            <Row>
              <Col md={6}>
                <ODEntityInput
                  keyPath="finishedAt"
                  label="작품 종료일(지정일로부터 7일후)"
                  name="finishedAt"
                  inputType="datetime-local"
                  placeholder="작품 종료일(지정일로부터 7일후 완결처리)"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityInput
                  keyPath="showOrder"
                  label="노출 우선순위"
                  name="showOrder"
                  placeholder="노출 우선순위를 설정해주세요. 숫자가 높을 수록 먼저 등장합니다."
                  inputType="number"
                />
              </Col>
              <Col md={6}>
                <ODEntityLabeled
                  label="완결 여부?(즉시 완결처리)"
                  name="isFinished"
                >
                  <ToggleButtonWrapper>
                    <ODFormToggleButton
                      key="isFinished_true"
                      name="isFinished"
                      keyPath="isFinished"
                      value={true}
                    >
                      Y
                    </ODFormToggleButton>
                    <ODFormToggleButton
                      key="isFinished_false"
                      name="isFinished"
                      keyPath="isFinished"
                      value={false}
                    >
                      N
                    </ODFormToggleButton>
                  </ToggleButtonWrapper>
                </ODEntityLabeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityLabeled label="커버 이미지" name="mainImageFile">
                  <ODImageFileInput
                    name="mainImageFile"
                    height={330}
                    width={220}
                    keyPath="mainImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
              <Col md={6}>
                <ODEntityLabeled
                  label="미공개 커버 이미지"
                  name="previewImageFile"
                >
                  <ODImageFileInput
                    name="previewImageFile"
                    height={330}
                    width={220}
                    keyPath="previewImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityLabeled
                  label="작품 상세 1:1 이미지"
                  name="introImageFile"
                >
                  <ODImageFileInput
                    name="introImageFile"
                    height={330}
                    width={330}
                    keyPath="introImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
              <Col md={6}>
                <ODEntityLabeled
                  label="작품 가로형 썸네일 4:3 이미지"
                  name="wideImageFile"
                >
                  <ODImageFileInput
                    name="wideImageFile"
                    height={330}
                    width={440}
                    keyPath="wideImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ODEntityLabeled
                  label="작품 배너 이미지"
                  name="bannerImageFile"
                >
                  <ODImageFileInput
                    name="bannerImageFile"
                    height={330}
                    width={330}
                    keyPath="bannerImageFile.link"
                  />
                </ODEntityLabeled>
              </Col>
            </Row>

            <ODEntityLabeled name="teaserVideoFile" label="티저 영상">
              <ODEntityRaw
                name="teaserVideoFile"
                keyPath="teaserVideoFile.link"
                render={(data) => {
                  // TODO: WARNING 로그 확인.
                  if (!video) {
                    setVideo(data.value)
                  }

                  return (
                    <>
                      <input
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files?.[0]
                          if (file) {
                            setVideo(URL.createObjectURL(file))
                            videoFile.current = file
                          }
                        }}
                      />
                      <br />
                      {video && (
                        <video key={video} width="320" height="240" controls>
                          <source type="video/mp4" src={video} />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      {!video && <p>티저 영상 없음. 업로드 해주세요!</p>}
                    </>
                  )
                }}
              />
            </ODEntityLabeled>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <hr />
            </div>
          </ODEntity>
        </CardBody>
      </Card>
    </>
  )
}

const ToggleButtonWrapper = styled.div`
  display: flex;
`
