import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import { GQLListableNoticePopup, GQLListableNoticePopupInput, GQLNoticePopup } from '../../@types/server'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useCounter, useODQuery } from '../../context/ODCommon'
import { ODListableOption, ODListableResponseType, useODListableContext } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

interface Props {}

export interface NoticePopupDataLoaderOption extends ODListableOption {
  filter: string | null
}

const NOTICE_POPUP_FULL_SNAPSHOT = `
  noticePopupId
  link
  deepLink
  startAt
  endAt
  createdAt
  imageFile {
    link
  }
`

const GQL_LIST = `
query listNoticePopup($data: ListableNoticePopupInput!) {
  listNoticePopup(data: $data) {
    list {
      ${NOTICE_POPUP_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const NoticePopupListContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<GQLNoticePopup, NoticePopupDataLoaderOption>()

  const listNoticePopup = useODQuery<GQLListableNoticePopupInput, GQLListableNoticePopup>(GQL_LIST)
  const [token] = useCounter()

  const dataLoader = React.useCallback(
    async function NoticePopupDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: NoticePopupDataLoaderOption
    ): Promise<ODListableResponseType<GQLNoticePopup>> {
      const r = await listNoticePopup({
        page,
        pageSize,
        filter: options.filter || null,
      })
      return r as ODListableResponseType<GQLNoticePopup>
    },
    [listNoticePopup]
  )

  const TableDefinition: ODListableTableDefinition<GQLNoticePopup, NoticePopupDataLoaderOption, any> = [
    {
      id: 'noticeId',
      title: '공지사항 아이디',
      transform: v => <Link to={SiteUrls.Admin.NoticePopup.Edit(v.noticePopupId)}>{v.noticePopupId}</Link>,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'link',
      title: '링크',
      transform: v => (v.link ? <a href={v.link}>{v.link}</a> : '없음'),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'deepLink',
      title: '딥링크',
      transform: v => (v.deepLink ? <a href={v.deepLink}>{v.deepLink}</a> : '없음'),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'startAt',
      title: '노출시작시각',
      transform: v => (v.startAt ? Utils.formatDate(v.startAt) : '없음'),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'endAt',
      title: '노출종료시각',
      transform: v => (v.endAt ? Utils.formatDate(v.endAt) : '없음'),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'createdAt',
      title: '등록 시각',
      transform: v => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.noticePopupId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="검색하세요."
                style={{ flexGrow: 6, maxWidth: 600 }}
              />

              <div>
                <Link to={SiteUrls.Admin.NoticePopup.Create} style={{ textDecoration: 'none' }}>
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    공지사항 추가
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩중..'}
              renderEmpty={() => '공지사항이 없습니다.'}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
