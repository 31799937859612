import styled from 'styled-components'
import { COLORS } from '../styles/colors'

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const HoverTR = styled.tr`
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.GRAY200};
  }

  &:hover > td {
    background-color: ${COLORS.GRAY200};
  }
`

const ToggleButtonWrapper = styled.div`
  display: flex;
`
type P = {
  border?: string | number
  backgroundColor?: string
  textColor?: string
  textSize?: string
  insetTop?: string
  insetBottom?: string
  extra?: string
  disabled?: boolean
}

const ButtonWrapper = styled.button`
  border: ${(p: P) => p.border};
  background-color: ${(p: P) => (p.disabled ? 'silver' : p.backgroundColor)};
  cursor: ${(p: P) => (p.disabled ? 'not-allowed' : 'pointer')};
  color: ${(p: P) => p.textColor};
  font-size: ${(p: P) => p.textSize};
  text-align: center;
  ${(p: P) => p.extra || ''}
`

const TextWrapper = styled.div`
  margin-top: ${(p: P) => p.insetTop};
  margin-bottom: ${(p: P) => p.insetBottom};
`

export {
  RowWrapper,
  ColumnWrapper,
  HoverTR,
  ToggleButtonWrapper,
  ButtonWrapper,
  TextWrapper,
}
