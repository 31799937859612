"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbStudioAPIServer = void 0;
const core_1 = require("@storyplay/core");
const server_schema_1 = require("../@types/server.schema");
const consts_1 = require("../consts");
const hellobotToStoryplayConverter_1 = require("../hellobot-storyplay-converter/hellobotToStoryplayConverter");
const hbApi_1 = require("./hbApi");
const defaultTimeString = '1970-01-01T00:00:00.000Z';
class HbStudioAPIServer {
    constructor(client, defaultOptions = {}) {
        this.client = client;
        this.hbClient = new hbApi_1.HbApiClient(this.client);
    }
    async getCurrentWebNovelContest() {
        // tslint:disable-next-line:no-object-literal-type-assertion
        return {
            version: 1,
            startAt: new Date(0),
            endAt: new Date(0),
            title: '가짜 콘테스트!',
            link: '',
            type: server_schema_1.GQLSTORY_PLAY_TYPE.EPUB,
        };
    }
    async getLatestWebNovelContest() {
        // tslint:disable-next-line:no-object-literal-type-assertion
        return {
            version: 1,
            startAt: new Date(0),
            endAt: new Date(0),
            title: '가짜 콘테스트!',
            link: '',
            type: server_schema_1.GQLSTORY_PLAY_TYPE.EPUB,
        };
    }
    createBanner(input) {
        throw new Error('Method not implemented.');
    }
    createBottomBanner(input) {
        throw new Error('Method not implemented.');
    }
    listNumPlayOfChapter(data) {
        throw new Error('Method not implemented.');
    }
    listNumCommentOfChapter(data) {
        throw new Error('Method not implemented.');
    }
    getLastChapterIndexByStory(storyId) {
        throw new Error('Method not implemented.');
    }
    getPresignedUrlForStoryImport() {
        throw new Error('Method not implemented.');
    }
    // 모든 존재하는 헬로봇 스토리는 인터랙티브 작품으로 취급한다.
    getStoryPlayType(id) {
        return Promise.resolve({
            playType: server_schema_1.GQLSTORY_PLAY_TYPE.Interactive,
        });
    }
    getWebNovelStoryForStudio(id) {
        throw new Error('Method not implemented.');
    }
    upsertWebNovelChapterDraft(chapterId, bundle, chapterScript, epubFile) {
        throw new Error('Method not implemented.');
    }
    listStoryByAdmin(input) {
        throw new Error('Method not implemented.');
    }
    setRootStore(rootStore) {
        this.rootStore = rootStore;
    }
    async loginWriterWithEmailForStudio(input) {
        try {
            const response = await this.client.put('/v1/signin', input);
            // 응답 데이터를 JSON으로 파싱
            return {
                me: {
                    accessible: response.accessible,
                    email: response.email,
                    userId: response.id,
                    modDate: response.modDate,
                    name: response.name,
                    regDate: response.regDate,
                    type: response.type,
                    isDev: true,
                    priv: 10,
                    hbExtensionData: response,
                },
                token: response.accessToken,
            };
        }
        catch (error) {
            // tslint:disable-next-line:no-console
            console.error(error);
        }
    }
    async getWriterProfileForStudio() {
        const res = await this.client.get('/v1/mypage');
        return {
            createdAt: new Date(),
            updatedAt: new Date(),
            email: res.email,
            lastLoginToken: '',
            lastAccessTime: new Date(),
            unread: 0,
            leftAt: null,
            userId: res.id,
            name: res.name,
            priv: 10,
            isDev: true,
            profileFile: null,
            updateToAuthorAt: new Date(),
            bannedAt: null,
            banReason: null,
            phone: null,
            authorName: res.name,
            authorEmail: res.email,
            canExportStory: true,
            ssoLogins: [],
            isInternalAuthor: true,
            numCompleteTutorial: 0,
            hbExtensionData: res,
        };
    }
    async createChapterForStudio(input) {
        const res = await this.createAlgorithmBlockGroup({
            botId: input.botId,
            logicId: input.logicId,
            title: input.title,
        });
        return res;
    }
    createStoryForStudio(input) {
        throw new Error('Not implemented yet.');
    }
    createStoryHasStudioFile(data) {
        throw new Error('Not implemented yet.');
    }
    async deleteChapterForStudio(chapterId) {
        await this.hbClient.algorithmBlockGroup.deleteItem(chapterId);
        return Promise.resolve({ ok: 200 });
    }
    async getStoryForStudio(botId) {
        var _a, _b;
        const bot = await this.hbClient.bot.get(botId);
        const converter = new hellobotToStoryplayConverter_1.HellobotToStoryplayConverter();
        // https://dev-api.hellobotstudio.com/studio/v1/group?sort=regDate&sortDirection=desc&pageIndex=0&pageSize=10&botId=8&ngsw-bypass=
        try {
            // const blockGroupList = await this.client.get<HbBlockGroupList>(
            //   '/v1/group',
            //   {
            //     sort: 'regDate',
            //     sortDirection: 'desc',
            //     botId,
            //   }
            // )
            const user = await this.getWriterProfileForStudio();
            // let allChapterPromises = [] as Promise<GQLChapter>[]
            // // this is slow
            // if (blockGroupList.items) {
            //   allChapterPromises = blockGroupList.items.map(blockGroup => {
            //     return new Promise<GQLChapter>(async (resolve, reject) => {
            //       if (!blockGroup.blockList) {
            //         reject(new Error('Unknown case 5872134: ' + blockGroup.id))
            //         return
            //       }
            //
            //       try {
            //         resolve({
            //           createdAt: new Date(blockGroup.regDate ?? defaultTimeString),
            //           updatedAt: new Date(blockGroup.modDate ?? defaultTimeString),
            //           chapterId: blockGroup.id!,
            //           name: blockGroup.name!,
            //           story: {}, // TODO
            //           storyId: blockGroup.botId!, // TODO 스킬아이디로 바꿔야 함
            //           publishedAt: new Date(blockGroup.modDate ?? defaultTimeString), // TODO 스킬라챗반 시간으로 바꿔야 함
            //           willPublishAt: new Date(),
            //           price: 7777,
            //           freedAt: new Date(),
            //           freedAtRaw: new Date(),
            //           willFreeAt: new Date(),
            //           willFreeAtRaw: new Date(),
            //           isFinal: true,
            //           // chapterScript: chapterScriptJSON,
            //           scriptFile: null,
            //           scriptFileId: null,
            //           endings: [],
            //           choices: [],
            //           chapterIndex: 0,
            //           isHidden: 1,
            //           syncToken: '',
            //           remainingTimeToFree: 0,
            //           hasNewBadge: true,
            //           customId: null,
            //           defaultEnding: null,
            //           defaultEndingId: null,
            //           hasGraphIcon: false,
            //           freeDependencyChapterIndex: null,
            //           freeDependencyPeriod: null,
            //           isDraft: 0,
            //           isVerified: 0,
            //           hasBGM: false,
            //           onboardingPopups: null,
            //           isTimeLeapFree: false,
            //           draft: null,
            //           numBlocks: null,
            //           numChars: null,
            //           numSens: null,
            //           stats: null,
            //           numBlocksDraft: null,
            //           numCharsDraft: null,
            //           numSensDraft: null,
            //           statsDraft: null,
            //           adsOn: false,
            //           chapterOption: null,
            //           chapterOptionByAdmin: null,
            //           appliedDraftId: -1,
            //         } as unknown as GQLChapter)
            //       } catch (err) {
            //         throw err
            //       }
            //     })
            //   })
            // }
            // const allChapters = await Promise.all(allChapterPromises)
            const hbProperties = await this.hbClient.attribute.list({
                botId,
                pageSize: 100,
                sort: 'userCount',
                sortDirection: 'desc',
                pageIndex: 0,
            });
            const properties = (_b = (_a = hbProperties.items) === null || _a === void 0 ? void 0 : _a.map(d => converter.hbPropertTostoryPropert(d))) !== null && _b !== void 0 ? _b : [];
            const spCharacters = await this.importCharactersFromBot(botId);
            return getStoryForStudioFromHellobotBlockGroupDatas(null, bot, user, [], spCharacters, properties);
        }
        catch (e) {
            // tslint:disable-next-line:no-console
            console.log('EEE', e);
        }
        throw Error('unexpected call.');
    }
    // 페이지가 있는 알고리즘 스토리를 돌려준다.
    async getAlgorithmStoryForStudio(botId, pageSortRequest) {
        var _a, _b;
        const bot = await this.hbClient.bot.get(botId);
        const converter = new hellobotToStoryplayConverter_1.HellobotToStoryplayConverter();
        // https://dev-api.hellobotstudio.com/studio/v1/group?sort=regDate&sortDirection=desc&pageIndex=0&pageSize=10&botId=8&ngsw-bypass=
        try {
            const algorithmBlockGroupList = await this.hbClient.algorithmBlockGroup.getItems({
                botId,
                pageIndex: pageSortRequest.page.index,
                pageSize: pageSortRequest.page.size,
                sortDirection: pageSortRequest.sort.order,
                sort: pageSortRequest.sort.key,
            });
            const user = await this.getWriterProfileForStudio();
            let allChapterPromises = [];
            // this is slow
            if (algorithmBlockGroupList.items) {
                allChapterPromises = algorithmBlockGroupList.items.map(blockGroup => {
                    return new Promise(async (resolve, reject) => {
                        var _a, _b, _c;
                        try {
                            resolve({
                                createdAt: new Date((_a = blockGroup.regDate) !== null && _a !== void 0 ? _a : defaultTimeString),
                                updatedAt: new Date((_b = blockGroup.modDate) !== null && _b !== void 0 ? _b : defaultTimeString),
                                chapterId: blockGroup.id,
                                name: blockGroup.title,
                                story: {},
                                storyId: blockGroup.botId,
                                publishedAt: new Date((_c = blockGroup.modDate) !== null && _c !== void 0 ? _c : defaultTimeString),
                                willPublishAt: new Date(),
                                price: 7777,
                                freedAt: new Date(),
                                freedAtRaw: new Date(),
                                willFreeAt: new Date(),
                                willFreeAtRaw: new Date(),
                                isFinal: true,
                                // chapterScript: chapterScriptJSON,
                                scriptFile: null,
                                scriptFileId: null,
                                endings: [],
                                choices: [],
                                chapterIndex: 0,
                                isHidden: 1,
                                syncToken: '',
                                remainingTimeToFree: 0,
                                hasNewBadge: true,
                                customId: null,
                                defaultEnding: null,
                                defaultEndingId: null,
                                hasGraphIcon: false,
                                freeDependencyChapterIndex: null,
                                freeDependencyPeriod: null,
                                isDraft: 0,
                                isVerified: 0,
                                hasBGM: false,
                                onboardingPopups: null,
                                isTimeLeapFree: false,
                                draft: null,
                                numBlocks: null,
                                numChars: null,
                                numSens: null,
                                stats: null,
                                numBlocksDraft: null,
                                numCharsDraft: null,
                                numSensDraft: null,
                                statsDraft: null,
                                adsOn: false,
                                chapterOption: null,
                                chapterOptionByAdmin: null,
                                appliedDraftId: -1,
                                hbExtensionData: blockGroup,
                            });
                        }
                        catch (err) {
                            throw err;
                        }
                    });
                });
            }
            const allChapters = await Promise.all(allChapterPromises);
            const hbProperties = await this.hbClient.attribute.list({
                botId,
                pageSize: 100,
                sort: 'userCount',
                sortDirection: 'desc',
                pageIndex: 0,
            });
            const properties = (_b = (_a = hbProperties.items) === null || _a === void 0 ? void 0 : _a.map(d => converter.hbPropertTostoryPropert(d))) !== null && _b !== void 0 ? _b : [];
            const spCharacters = await this.importCharactersFromBot(botId);
            // console.log('여기 호출')
            return getStoryForStudioFromHellobotBlockGroupDatas(algorithmBlockGroupList, bot, user, allChapters, spCharacters, properties);
        }
        catch (e) {
            // tslint:disable-next-line:no-console
            console.log('EEE', e);
        }
        throw Error('unexpected call');
    }
    async getAlgorithmChaptersForStudio(storyId, pageSortRequest) {
        const algorithmBlockGroupList = await this.hbClient.algorithmBlockGroup.getItems({
            botId: storyId,
            pageIndex: pageSortRequest.page.index,
            pageSize: pageSortRequest.page.size,
            sortDirection: pageSortRequest.sort.order,
            sort: pageSortRequest.sort.key,
        });
        return algorithmBlockGroupList.items
            ? algorithmBlockGroupList.items.map(blockGroup => {
                var _a, _b, _c;
                return {
                    createdAt: new Date((_a = blockGroup.regDate) !== null && _a !== void 0 ? _a : defaultTimeString),
                    updatedAt: new Date((_b = blockGroup.modDate) !== null && _b !== void 0 ? _b : defaultTimeString),
                    chapterId: blockGroup.id,
                    name: blockGroup.title,
                    story: {},
                    storyId: blockGroup.botId,
                    publishedAt: new Date((_c = blockGroup.modDate) !== null && _c !== void 0 ? _c : defaultTimeString),
                    willPublishAt: new Date(),
                    price: 7777,
                    freedAt: new Date(),
                    freedAtRaw: new Date(),
                    willFreeAt: new Date(),
                    willFreeAtRaw: new Date(),
                    isFinal: true,
                    // chapterScript: chapterScriptJSON,
                    scriptFile: null,
                    scriptFileId: null,
                    endings: [],
                    choices: [],
                    chapterIndex: 0,
                    isHidden: 1,
                    syncToken: '',
                    remainingTimeToFree: 0,
                    hasNewBadge: true,
                    customId: null,
                    defaultEnding: null,
                    defaultEndingId: null,
                    hasGraphIcon: false,
                    freeDependencyChapterIndex: null,
                    freeDependencyPeriod: null,
                    isDraft: 0,
                    isVerified: 0,
                    hasBGM: false,
                    onboardingPopups: null,
                    isTimeLeapFree: false,
                    draft: null,
                    numBlocks: null,
                    numChars: null,
                    numSens: null,
                    stats: null,
                    numBlocksDraft: null,
                    numCharsDraft: null,
                    numSensDraft: null,
                    statsDraft: null,
                    adsOn: false,
                    chapterOption: null,
                    chapterOptionByAdmin: null,
                    appliedDraftId: -1,
                    hbExtensionData: blockGroup,
                };
            })
            : [];
    }
    async importCharactersFromBot(botId) {
        const talkers = await this.hbClient.sender.listByBotId(botId);
        return talkers.map(talker => {
            var _a, _b;
            return {
                createdAt: talker.regDate,
                updatedAt: talker.modDate,
                chrId: talker.senderId,
                name: (_a = talker.senderName) !== null && _a !== void 0 ? _a : '#Invalid Sender Name',
                imageFile: {
                    link: (_b = talker.senderProfileUrl) !== null && _b !== void 0 ? _b : null,
                },
                story: {},
                imageFileId: null,
                storyId: botId,
                syncToken: Math.random().toString,
                main: talker.talkerBotId === botId,
                color: '#000000',
                onboardingPopups: [],
                defaultFirstName: '#Default First Name',
                defaultLastName: '#Default Last Name',
                displayName: talker.senderName,
            };
        });
    }
    removeStoryHasStudioFile(data) {
        throw new Error('Not implemented yet.');
    }
    updateStoryHasStudioFile(data) {
        throw new Error('Not implemented yet.');
    }
    changeChapterFreeSetting(data) {
        throw new Error('Not implemented yet.');
    }
    createContestHasStory(data) {
        throw new Error('Not implemented yet.');
    }
    createEntityForStudio(entity, createInput) {
        throw new Error('Not implemented yet.');
    }
    createOnboardingPopup(data) {
        throw new Error('Not implemented yet.');
    }
    createPlayerReport2Function(data) {
        throw new Error('Not implemented yet.');
    }
    createPlayerReport2RenderBlock(data) {
        throw new Error('Not implemented yet.');
    }
    createStoryWeekday(data) {
        throw new Error('Not implemented yet.');
    }
    createTestSession(data) {
        throw new Error('Not implemented yet.');
    }
    deleteStoryWeekday(data) {
        throw new Error('Not implemented yet.');
    }
    duplicateChapterDraft(chapterId) {
        throw new Error('Not implemented yet.');
    }
    exportStudioChapterAsSheet(input) {
        throw new Error('Not implemented yet.');
    }
    exportStudioStoryAsSheet(input) {
        throw new Error('Not implemented yet.');
    }
    async getChapter(chapterId, paginationConfig) {
        var _a, _b, _c, _d;
        const blockGroup = await this.hbClient.algorithmBlockGroup.getItem(chapterId);
        const pageIndex = (_a = paginationConfig === null || paginationConfig === void 0 ? void 0 : paginationConfig.pageIndex) !== null && _a !== void 0 ? _a : 0;
        const pageSize = (_b = paginationConfig === null || paginationConfig === void 0 ? void 0 : paginationConfig.pageSize) !== null && _b !== void 0 ? _b : 8;
        const sortDirection = (_c = paginationConfig === null || paginationConfig === void 0 ? void 0 : paginationConfig.sortDirection) !== null && _c !== void 0 ? _c : 'asc';
        const sort = (_d = paginationConfig === null || paginationConfig === void 0 ? void 0 : paginationConfig.sort) !== null && _d !== void 0 ? _d : 'regDate';
        const blocks = await this.hbClient.algorithmBlock.getItems({
            groupId: chapterId,
            pageIndex,
            pageSize,
            sortDirection,
            sort,
        });
        const converter = new hellobotToStoryplayConverter_1.HellobotToStoryplayConverter();
        return converter.hbAlgorithGroupBlockToHbAlgorithmGQLChapter(blockGroup, blocks);
    }
    getChapterDraftArchive(chapterDraftArchiveId) {
        throw new Error('Not implemented yet.');
    }
    async getChapterForEditing(blockGroupId) {
        try {
            const blockGroup = await this.hbClient.blockGroup.listWithBlockMessages(blockGroupId);
            // const bot = await this.client.get<HbBot>(`/v1/bots/${blockGroup.botId}`)
            const converter = new hellobotToStoryplayConverter_1.HellobotToStoryplayConverter();
            const chapterScript = JSON.stringify(converter.hbBlockDatasToSpChapterScript(blockGroup));
            return {
                createdAt: blockGroup.regDate,
                updatedAt: blockGroup.modDate,
                chapterId: blockGroup.id,
                name: blockGroup.name || '',
                story: '',
                storyId: blockGroup.botId,
                publishedAt: blockGroup.modDate,
                willPublishAt: new Date(),
                price: 7777,
                freedAt: new Date(),
                freedAtRaw: new Date(),
                willFreeAt: new Date(),
                willFreeAtRaw: new Date(),
                isFinal: true,
                chapterScript,
                scriptFile: null,
                scriptFileId: null,
                endings: [],
                choices: [],
                chapterIndex: 0,
                isHidden: false,
                syncToken: '',
                remainingTimeToFree: null,
                hasNewBadge: false,
                customId: null,
                defaultEnding: null,
                defaultEndingId: null,
                hasGraphIcon: false,
                freeDependencyChapterIndex: null,
                freeDependencyPeriod: null,
                isDraft: 1,
                isVerified: 1,
                hasBGM: false,
                onboardingPopups: null,
                isTimeLeapFree: false,
                draft: null,
                numBlocks: null,
                numChars: null,
                numSens: null,
                stats: null,
                numBlocksDraft: null,
                numCharsDraft: null,
                numSensDraft: null,
                statsDraft: null,
                adsOn: false,
                chapterOption: null,
                chapterOptionByAdmin: null,
                appliedDraftId: -1,
                hbExtensionData: blockGroup,
            };
        }
        catch (err) {
            throw err;
        }
    }
    /**
     *
     * @param blockGroupId 알고리즘블록그룹의 아이디
     * @param page 알고리즘 블록 그룹에서 알고리즘 블록을 가져올 페이지
     * @param pageSize 페이지 크기
     * @returns 해당 페이지의 블록들만을 가진 챕터.
     */
    async getAlgorithmChapterforEditing(blockGroupId, page, pageSize) {
        var _a, _b, _c;
        try {
            const blockGroup = await this.hbClient.algorithmBlockGroup.getItem(blockGroupId);
            const blocks = await this.hbClient.algorithmBlock.getItems({
                groupId: blockGroupId,
                pageIndex: page,
                pageSize,
                sortDirection: 'desc',
                sort: 'regDate',
            });
            // console.log('getAlgorithmChapterforEditing blocks', blocks, blockGroup)
            const converter = new hellobotToStoryplayConverter_1.HellobotToStoryplayConverter();
            const chapterScript = JSON.stringify(converter.hbAlgorithmBlockDatasToSpChapterScript(blocks));
            // console.log('getAlgorithmChapterforEditing', chapterScript)
            return {
                createdAt: new Date((_a = blockGroup.regDate) !== null && _a !== void 0 ? _a : defaultTimeString),
                updatedAt: new Date((_b = blockGroup.modDate) !== null && _b !== void 0 ? _b : defaultTimeString),
                chapterId: blockGroup.id,
                name: blockGroup.title || '',
                story: '',
                storyId: blockGroup.botId,
                publishedAt: new Date((_c = blockGroup.modDate) !== null && _c !== void 0 ? _c : defaultTimeString),
                willPublishAt: new Date(),
                price: 7777,
                freedAt: new Date(),
                freedAtRaw: new Date(),
                willFreeAt: new Date(),
                willFreeAtRaw: new Date(),
                isFinal: true,
                chapterScript,
                scriptFile: null,
                scriptFileId: null,
                endings: [],
                choices: [],
                chapterIndex: 0,
                isHidden: false,
                syncToken: '',
                remainingTimeToFree: null,
                hasNewBadge: false,
                customId: null,
                defaultEnding: null,
                defaultEndingId: null,
                hasGraphIcon: false,
                freeDependencyChapterIndex: null,
                freeDependencyPeriod: null,
                isDraft: 1,
                isVerified: 1,
                hasBGM: false,
                onboardingPopups: null,
                isTimeLeapFree: false,
                draft: null,
                numBlocks: null,
                numChars: null,
                numSens: null,
                stats: null,
                numBlocksDraft: null,
                numCharsDraft: null,
                numSensDraft: null,
                statsDraft: null,
                adsOn: false,
                chapterOption: null,
                chapterOptionByAdmin: null,
                appliedDraftId: -1,
                hbExtensionData: blockGroup,
            };
        }
        catch (err) {
            throw err;
        }
    }
    getContentHasStory(data) {
        throw new Error('Not implemented yet.');
    }
    getConversionStatOfStory(data) {
        throw new Error('Not implemented yet.');
    }
    async getCurrentContest() {
        // tslint:disable-next-line:no-object-literal-type-assertion
        return {
            version: 1,
            startAt: new Date(0),
            endAt: new Date(0),
            title: '가짜 콘테스트!',
            link: '',
            type: server_schema_1.GQLSTORY_PLAY_TYPE.Interactive,
        };
    }
    getFallbackRemoteScriptByStudio(data) {
        throw new Error('Not implemented yet.');
    }
    getLastTestSession(chapterId) {
        throw new Error('Not implemented yet.');
    }
    async getListStoryCountsByStudio() {
        var _a, _b;
        const res = await this.client.get('/v1/bots', {
            sort: 'turn',
            sortDirection: 'asc',
            pageSize: '1',
            view: consts_1.TabForChatbotList.All,
        });
        const res2 = await this.client.get('/v1/bots', {
            sort: 'turn',
            sortDirection: 'asc',
            pageSize: '1',
            view: consts_1.TabForChatbotList.MyChatBot,
        });
        return {
            all: (_a = res.totalCount) !== null && _a !== void 0 ? _a : 0,
            myChatBot: (_b = res2.totalCount) !== null && _b !== void 0 ? _b : 0,
        };
    }
    getMainChrOnboardingPopup(data) {
        throw new Error('Not implemented yet.');
    }
    getNumCommentStatOfStory(data) {
        throw new Error('Not implemented yet.');
    }
    getNumLikeStatOfStory(data) {
        throw new Error('Not implemented yet.');
    }
    getNumPlayStatOfStory(data) {
        throw new Error('Not implemented yet.');
    }
    getPlayerData(data) {
        throw new Error('Not implemented yet.');
    }
    listBlockBounceStatOfChapter(data) {
        throw new Error('Not implemented yet.');
    }
    listChapterComment(data) {
        throw new Error('Not implemented yet.');
    }
    listChapterDraft(data) {
        throw new Error('Not implemented yet.');
    }
    listChapterForStudio(data) {
        throw new Error('Not implemented yet.');
    }
    async listColors() {
        return {
            list: [],
            totalCount: 0,
            page: 1,
            pageSize: 100,
        };
    }
    listRemoteScriptProvider(data) {
        throw new Error('Not implemented yet.');
    }
    listSampleStoryForStudio(data) {
        throw new Error('Not implemented yet.');
    }
    listStoryConversionRankCache(data) {
        throw new Error('Not implemented yet.');
    }
    async listStoryForWriter(input) {
        var _a, _b, _c;
        const res = await this.client.get('/v1/bots', {
            sort: 'turn',
            sortDirection: 'asc',
            pageSize: '8',
            // view:'my-chat-bot','all'
        });
        return {
            list: ((_a = res.items) === null || _a === void 0 ? void 0 : _a.map(bot => ({
                storyId: bot.id,
                name: bot.name,
                allChapters: [],
                mainImageFile: { link: bot.profileImage },
            }))) || [],
            totalCount: ((_b = res.items) === null || _b === void 0 ? void 0 : _b.length) || 0,
            page: 1,
            pageSize: ((_c = res.items) === null || _c === void 0 ? void 0 : _c.length) || 0,
            version: null,
        };
    }
    listStoryRankCache(data) {
        throw new Error('Not implemented yet.');
    }
    listStoryWeekday(data) {
        throw new Error('Not implemented yet.');
    }
    async listStudioTutorialCompletion() {
        return {
            list: [],
            totalCount: 0,
            page: 1,
            pageSize: 100,
        };
    }
    loadPlayerReportIDEData(prId) {
        throw new Error('Not implemented yet.');
    }
    loginWriterWithEmailForHellobot(input) {
        throw new Error('Not implemented yet.');
    }
    markUserStudioTutorialProgress(data) {
        throw new Error('Not implemented yet.');
    }
    publishChapter(data) {
        throw new Error('Not implemented yet.');
    }
    removeChapterDraft(chapterId) {
        throw new Error('Not implemented yet.');
    }
    removeOnboardingPopup(data) {
        throw new Error('Not implemented yet.');
    }
    removePlayerReport2Function(prfId) {
        throw new Error('Not implemented yet.');
    }
    removePlayerReport2RenderBlock(prbId) {
        throw new Error('Not implemented yet.');
    }
    resetUserStudioTutorialProgress(data) {
        throw new Error('Not implemented yet.');
    }
    // 헬로봇 스튜디오에는 스튜디오 파일과 같은 개념이 없다. 물론 봇 자신에게 업로드된 파일을 스튜디오 파일로 칠 수도 있다.
    // 그러나 23-05-11 ImageModal과 관련된 컴포넌트 및 훅 로직에서는 봇 자신에게 업로드된 파일들도 그저 스플의 카테고리 이미지(공통이미지)로 취급하도록 작성했다.
    // 그러므로 모든 이미지가 사용시에 이 변환 과정을 거치게 된다.
    // 이 글을 적으면서 스튜디오 파일 개념을 활용하도록 다른 코드를 변경해볼지 생각해봤으나 그럴 경우 공통이미지를 사용할 때 기존 헬로봇 스튜디오에서 의도치 않은 데이터 복사가 일어나므로
    // 양 쪽의 데이터를 모두 각자의 방식에 최대한 맟추는 편이 조금 더 낫다고 생각하고 있다.(이미 위에 적은 부분을 작업하고 적은 주석이므로 편향된 생각을 하는 것일 수도 있다.)
    async savePublicResourceToStudioFile(data) {
        var _a, _b, _c, _d, _e, _f, _g;
        const { publicResourceFileId, storyId } = data;
        // 퍼블릭 리소스 파일 아이디로부터 원본 정보를 다시 가져와야 한다.
        // 퍼블릭 리소스 파일 아이디는 헬로봇에서의 미디어 라이브러리 파일 아이디가 되어 있다.
        const item = await this.hbClient.media.getItem(publicResourceFileId);
        /**
         * 여기에서 실제로 돌려줘야 하는 내용은 아래와 같다.
         * FileId
         * fileName
         * link
         * width
         * height
         * storyId
         * fileType: 'Image'
         * resName
         * displayName
         */
        // console.log(item.id, 'resolving in savePublicResourceToStudioFile')
        // tslint:disable-next-line:no-object-literal-type-assertion
        return Promise.resolve({
            fileId: (_a = item.id) !== null && _a !== void 0 ? _a : 0,
            fileName: (_b = item.title) !== null && _b !== void 0 ? _b : 'Invalid File Name',
            link: (_c = item.imageUrl) !== null && _c !== void 0 ? _c : '',
            width: (_e = (_d = item.fileInfo) === null || _d === void 0 ? void 0 : _d.width) !== null && _e !== void 0 ? _e : 1000,
            height: (_g = (_f = item.fileInfo) === null || _f === void 0 ? void 0 : _f.height) !== null && _g !== void 0 ? _g : 1000,
            fileType: server_schema_1.GQLSTUDIO_FILE_TYPE.Image,
            resName: item.title,
            displayName: item.title,
        });
    }
    updateChapterIndexes(storyId, field) {
        throw new Error('Not implemented yet.');
    }
    updateContestHasStory(data) {
        throw new Error('Not implemented yet.');
    }
    updateEntityForStudio(entity, changeSet) {
        throw new Error('Not implemented yet.');
    }
    updateOnboardingPopup(data) {
        throw new Error('Not implemented yet.');
    }
    upsertChapterDraft(chapterId, bundle, chapterScript, numBlocks, numChars, numSens, stats) {
        throw new Error('Not implemented yet.');
    }
    updatePrimaryProperty(id, isPrimary) {
        throw new Error('Not implemented yet.');
    }
    upsertMainChrOnboardingPopup(data) {
        throw new Error('Not implemented yet.');
    }
    async tabClickForStudio(data) {
        var _a;
        const { tab, page, sort, filter } = data;
        // 헬봇에서 챗봇 검색기능은 pageSize=10000 부르고 필터해주고 있음. 그래서 api호출 후 이름으로 필터 과정 진행.
        const res = await this.client.get('/v1/bots', {
            sort: 'turn',
            sortDirection: sort,
            pageIndex: page - 1,
            pageSize: '8',
            view: tab,
            filter,
        });
        // tslint:disable-next-line:no-object-literal-type-assertion
        return {
            listable: (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(bot => ({
                storyId: bot.id,
                name: bot.name,
                allChapters: [],
                mainImageFile: { link: bot.profileImage },
                hbExtensionData: bot,
            })),
            totalCount: res.totalCount || 0,
        };
    }
    async getAlgorithmLogic() {
        const logic = await this.hbClient.algorithmBlock.getAlgorithmLogic();
        return logic;
    }
    async createAlgorithmBlockGroup(payload) {
        var _a, _b, _c;
        const blockGroup = await this.hbClient.algorithmBlockGroup.createItem(payload);
        const blocks = await this.hbClient.algorithmBlock.getItems({
            groupId: blockGroup.id,
            pageIndex: 0,
            pageSize: 1000,
            sortDirection: 'asc',
            sort: 'regDate',
        });
        const converter = new hellobotToStoryplayConverter_1.HellobotToStoryplayConverter();
        const chapterScript = JSON.stringify(converter.hbAlgorithmBlockDatasToSpChapterScript(blocks));
        const res = {
            createdAt: new Date((_a = blockGroup.regDate) !== null && _a !== void 0 ? _a : defaultTimeString),
            updatedAt: new Date((_b = blockGroup.modDate) !== null && _b !== void 0 ? _b : defaultTimeString),
            chapterId: blockGroup.id,
            name: blockGroup.title || '',
            story: '',
            storyId: blockGroup.botId,
            publishedAt: new Date((_c = blockGroup.modDate) !== null && _c !== void 0 ? _c : defaultTimeString),
            willPublishAt: new Date(),
            price: 7777,
            freedAt: new Date(),
            freedAtRaw: new Date(),
            willFreeAt: new Date(),
            willFreeAtRaw: new Date(),
            isFinal: true,
            chapterScript,
            scriptFile: null,
            scriptFileId: null,
            endings: [],
            choices: [],
            chapterIndex: 0,
            isHidden: false,
            syncToken: '',
            remainingTimeToFree: null,
            hasNewBadge: false,
            customId: null,
            defaultEnding: null,
            defaultEndingId: null,
            hasGraphIcon: false,
            freeDependencyChapterIndex: null,
            freeDependencyPeriod: null,
            isDraft: 1,
            isVerified: 1,
            hasBGM: false,
            onboardingPopups: null,
            isTimeLeapFree: false,
            draft: null,
            numBlocks: null,
            numChars: null,
            numSens: null,
            stats: null,
            numBlocksDraft: null,
            numCharsDraft: null,
            numSensDraft: null,
            statsDraft: null,
            adsOn: false,
            chapterOption: null,
            chapterOptionByAdmin: null,
            appliedDraftId: -1,
            hbExtensionData: blockGroup,
        };
        return res;
    }
    // payload에 logicId, title만 사용
    async editAlgorithmBlockGroup(blockGroupId, payload) {
        return this.hbClient.algorithmBlockGroup.updateItem(blockGroupId, payload);
    }
    createBundleBanner(input) {
        throw new Error('Not implemented yet.');
    }
    createMonthlyBundle(input) {
        throw new Error('Not implemented yet.');
    }
    getAllContentLanguages() {
        throw new Error('Not implemented yet.');
    }
    getNoticeListFilteredTimeLeapEn() {
        throw new Error('Not implemented yet.');
    }
    getNoticeListFilteredTimeLeapKo() {
        throw new Error('Not implemented yet.');
    }
    getServiceCountries() {
        return Promise.resolve([]);
    }
    getServiceSystemLanguaes() {
        return Promise.resolve([]);
    }
    getStoryBundleCountriesInfo(bundleId) {
        throw new Error('Not implemented yet.');
    }
    getStoryPriceSetting(storyId, countryCode) {
        throw new Error('Not implemented yet.');
    }
    updateStoryBundleCountries({ bundleId, serviceCountryCodes, }) {
        throw new Error('Not implemented yet.');
    }
    createBackground({ storyId, imageFile, displayName, }) {
        throw new Error('Not implemented yet.');
    }
    updateStorygameBackgrounds({ id, imageFile, }) {
        throw new Error('Not implemented yet.');
    }
    updateBackground({ id, imageFile, displayName, }) {
        throw new Error('Not implemented yet.');
    }
    createStoryPreviewForStudio({ storyId, fileType, file, }) {
        throw new Error('Not implemented yet.');
    }
    createTag({ name }) {
        throw new Error('Not implemented yet.');
    }
    searchTags({ name }) {
        throw new Error('Not implemented yet.');
    }
    getAllStoryGameTabs() {
        throw new Error('Not implemented yet');
    }
    updateStoryGameTabOrder(data) {
        throw new Error('Not implemented yet');
    }
    getAllStoryGameSections(data) {
        throw new Error('Not implemented yet');
    }
    createStoryGameSection(data) {
        throw new Error('Not implemented yet');
    }
    updateStoryGameSection(data) {
        throw new Error('Not implemented yet');
    }
    removeStoryGameSection(data) {
        throw new Error('Not implemented yet');
    }
    updateStoryGameSectionOrder(data) {
        throw new Error('Not implemented yet');
    }
    listMinChapterForStudio(data) {
        throw new Error('Not implemented yet');
    }
    toggleStorygameSectionCountry(data) {
        throw new Error('Not implemented yet');
    }
    createImageFile(data) {
        throw new Error('Not implemented yet');
    }
    getImageFile(data) {
        throw new Error('Not implemented yet');
    }
    publishStorygameSection() {
        throw new Error('Not implemented yet');
    }
    getAllStorygameSectionPreviews(data) {
        throw new Error('Not implemented yet');
    }
    getStorygameSection(data) {
        throw new Error('Not implemented yet');
    }
    toggleStorygameCountry(data) {
        throw new Error('Not implemented yet');
    }
    toggleStorygameLanguage(data) {
        throw new Error('Not implemented yet');
    }
    createStorygameBackground(data) {
        throw new Error('Not implemented yet');
    }
    toggleStorygameVisibility(data) {
        throw new Error('Not implemented yet');
    }
    getStorySimpleInfo(storyId) {
        throw new Error('Not implemented yet');
    }
}
exports.HbStudioAPIServer = HbStudioAPIServer;
// 이 함수는 챕터스크립트가 없는 스토리를 돌려준다.
function getStoryForStudioFromHellobotBlockGroupDatas(blockGroupList, bot, user, allChapters, spCharacters, properties) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return {
        createdAt: new Date((_c = (_b = (_a = blockGroupList === null || blockGroupList === void 0 ? void 0 : blockGroupList.items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.regDate) !== null && _c !== void 0 ? _c : defaultTimeString),
        updatedAt: new Date((_f = (_e = (_d = blockGroupList === null || blockGroupList === void 0 ? void 0 : blockGroupList.items) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.modDate) !== null && _f !== void 0 ? _f : defaultTimeString),
        storyId: bot.id || 0,
        name: bot.name,
        defaultFirstName: (_j = (_h = (_g = blockGroupList === null || blockGroupList === void 0 ? void 0 : blockGroupList.items) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : '-',
        defaultLastName: '성',
        author: user,
        authorId: user.userId,
        script: '',
        backgrounds: [],
        chapters: allChapters,
        characters: spCharacters,
        mainImageFile: null,
        mainImageFileId: null,
        previewImageFile: null,
        previewImageFileId: null,
        introImageFile: null,
        introImageFileId: null,
        mainCharacterImageFile: null,
        mainCharacterImageFileId: null,
        teaserVideoFile: null,
        teaserVideoFileId: null,
        publishedAt: null,
        willPublishAt: null,
        showOrder: 1,
        oneLineDesc: 'oneLineDesc',
        isFinished: false,
        topic: 'topic',
        targetAge: 'targetAge',
        userProps: '',
        coinForRestart: 7777,
        syncToken: '',
        coverText: '',
        lastPlay: null,
        endingInfo: {
            numTotalEnding: 1,
            isStoryFinished: false,
            endings: [],
        },
        endingStats: {
            endingWithStats: [],
            endingInfo: {
                numTotalEnding: 1,
                isStoryFinished: false,
                endings: [],
            },
        },
        chapterPurchases: [],
        storyEndsAt: null,
        shareKey: '',
        isSubscribed: false,
        isWaiting: false,
        allChapters,
        properties,
        propsMigration: null,
        propsMigrationUpdatedAt: null,
        previewEndingInfo: {
            numTotalPreviewEnding: 0,
            previewEndings: [],
        },
        sectionType: core_1.GQLSTORY_SECTION_TYPE.Challenge,
        aApiKeyId: null,
        aApiKey: null,
        aProjId: null,
        totalNumOfChapterComments: 0,
        studioFiles: [],
        userItems: '',
        authorName: 'string | null',
        endingArrivalRateType: core_1.GQLSTORY_ENDING_ARRIVAL_RATE_TYPE.Unknown,
        type: core_1.GQLSTORY_TYPE.Novel,
        endingArrivalRateRange: 10,
        shortDesc: 'shortDesc',
        representedAt: null,
        freeTimeLeapStartAt: null,
        freeTimeLeapEndAt: null,
        purchaseFreeStartAt: null,
        isDummy: false,
        wideImageFile: null,
        wideImageFileId: null,
        ipSourcing: null,
        ipSourcingId: null,
        storyItems: [],
        aEventDefs: [],
        hasActiveStoryItem: false,
        hasAchievement: false,
        monthText: null,
        storyDetailInfo: null,
        maxTicket: 7777,
        isTimeLeapFree: true,
        notice: null,
        noticeId: null,
        myFavorite: false,
        isMWS: false,
        isBanner: false,
        studio: {
            story: null,
            allEndings: [],
            playerReportCSSLink: '',
        },
        playerReportId: null,
        playerReport2: null,
        canPublish: null,
        numBlocks: null,
        numChars: null,
        numSens: null,
        stats: null,
        numBlocksDraft: null,
        numCharsDraft: null,
        numSensDraft: null,
        statsDraft: null,
        onHiatus: false,
        purchasableRaw: false,
        purchasable: false,
        lastPreviewChapterIndex: null,
        price: 7777,
        originalPrice: 7777,
        purchased: false,
        discountRate: 8,
        isSample: null,
        isReadOnly: false,
        canUseAchievement: null,
        canUserPlayerReport: null,
        isContest: false,
        numLikes: 0,
        numComment: 0,
        numViews: 0,
        storyGenre: null,
        challengeStoryGenre: null,
        view: 0,
        genre: core_1.GQLCHALLENGE_STORY_GENRE.CONTEST_WINNER,
        isFixMainChrName: null,
        exportForStudio: {
            story: null,
            allEndings: [],
            allChapters: [],
            allEndingCards: [],
            allEndingShowProperties: [],
            allPlayerClasses: [],
            allOnboardingPopups: [],
            allOnboardingPopupChoices: [],
            allOnboardingPopupProfileImages: [],
            playerReportInfo: {
                playerReport2: null,
                functions: [],
                renderBlocks: [],
            },
            allFallbackRemoteScripts: [],
        },
        langCode: 'ko',
        storyWeekdays: null,
        isOriginal: false,
        isNew: false,
        isUp: false,
        firstPublishedAt: null,
        finishedAt: null,
        lastChapterPublishedAt: null,
        monthlyContestPublishedLabel: null,
        playType: server_schema_1.GQLSTORY_PLAY_TYPE.Interactive,
        refStory: null,
        refStoryId: null,
        hbExtensionData: bot,
        novelContestPublishedLabel: null,
        enabledPurchasedChapter: {
            coinAmount: 0,
            chapterCount: null,
            hasAdultChapter: false,
        },
        studioPriceSetting: null,
        isAdult: false,
        bannerImageFile: null,
        bannerImageFileId: null,
        lastPublishedChapterIndex: 0,
        genreKey: null,
        lastChapterIndex: null,
        bundle: null,
        bundleId: null,
        language: null,
        languageCode: null,
        // @ts-ignore
        publishedCountry: null,
        publishedCountryCode: null,
    };
}
