import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { HomeSectionEditPage } from './HomeSectionEditPage'

interface IHomeSectionEditPageContainerProps {
  homeSectionId: number
  type: string
}

export const HomeSectionEditPageContainer: React.FC<
  IHomeSectionEditPageContainerProps
> = (props) => {
  const { homeSectionId, type } = props
  return (
    <>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.HomeSection.List} />
          <hr />
          <HomeSectionEditPage homeSectionId={homeSectionId} type={type} />
        </CardBody>
      </Card>
    </>
  )
}
