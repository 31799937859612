"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOHbMessageSelectButton = void 0;
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const Yup = require("yup");
const ui_1 = require("../../../../ui");
const DOHbMessageNext_1 = require("../next/DOHbMessageNext");
const DOHbSaveProp_1 = require("../prop/DOHbSaveProp");
// 헬봇 선택지의 버튼 하나하나에 대한 클래스, 해당 클래스에서
class DOHbMessageSelectButton {
    constructor(parent, data) {
        this.saveProps = [];
        this.messageNext = null;
        this.showMessageNext = null;
        this.parent = parent;
        this.data = (0, lodash_1.cloneDeep)(data);
        this.initInputs(this.data);
        if (!!this.data.linkBlocks) {
            this.generateMessageNext(this.data.linkBlocks);
        }
        if (!!this.data.linkAttributes) {
            this.generateSaveProp(this.data.linkAttributes);
        }
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            saveProps: mobx_1.observable,
            messageNext: mobx_1.observable,
            showMessageNext: mobx_1.observable,
            isValidAll: mobx_1.computed,
            isValidMessageNext: mobx_1.computed,
            isValidMenuId: mobx_1.computed,
        });
    }
    getOriginDataForSubmit() {
        var _a, _b;
        const { linkBlocks, linkAttributes, ...rest } = this.data;
        return {
            ...rest,
            ...(_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.getOriginDataForSubmit(),
            linkAttributes: (_b = this.data.linkAttributes) === null || _b === void 0 ? void 0 : _b.map(la => ({
                id: la.id,
                value: la.value,
            })),
        };
    }
    create() {
        this.parent.helper
            .opFactory()
            .createSelectButton(this.parent, this.getDataForSubmit())
            .submitSingle();
    }
    update() {
        this.parent.helper
            .opFactory()
            .updateSelectButton(this.parent, this.getDataForSubmit())
            .submitSingle();
    }
    addSaveProp() {
        (0, mobx_1.runInAction)(() => {
            this.saveProps.push(new DOHbSaveProp_1.DOHbSaveProp(this.parent, {}));
        });
    }
    removeSaveProp(indexIn) {
        (0, mobx_1.runInAction)(() => {
            this.saveProps = this.saveProps.filter((_, index) => index !== indexIn);
        });
    }
    onShowMessageNext() {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.showMessageNext = new DOHbMessageNext_1.DOHbMessageNext(this.parent, {
                linkBlocks: (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.linkBlocks) !== null && _b !== void 0 ? _b : [],
            });
        });
    }
    onCloseMessageNext() {
        (0, mobx_1.runInAction)(() => {
            this.showMessageNext = null;
        });
    }
    onSubmitMessageNext() {
        (0, mobx_1.runInAction)(() => {
            var _a;
            this.messageNext = this.showMessageNext;
            this.data.linkBlocks = (_a = this.showMessageNext) === null || _a === void 0 ? void 0 : _a.getFullData().linkBlocks;
            this.showMessageNext = null;
        });
    }
    get isValidMessageNext() {
        if (!!this.messageNext) {
            return this.messageNext.isValidAll;
        }
        return true;
    }
    get isValidMenuId() {
        if (!this.isFreeSelectionInput.value) {
            return this.menuIdSelectionInput.value.length > 0;
        }
        return true;
    }
    get isValidAll() {
        return (this.saveProps.every(p => p.isValidAll) &&
            this.isValidMessageNext &&
            this.isValidMenuId &&
            !!this.buttonNameInput.value.trim());
    }
    getDataForSubmit() {
        var _a, _b, _c, _d;
        const dataByIsFree = this.isFreeSelectionInput.value
            ? {
                isShowAd: !!this.isShowAdSelectionInput.value,
            }
            : {
                isShowAd: false,
                menuId: this.menuIdSelectionInput.value[0],
                isMenu: true,
            };
        const dataById = !!this.data.id
            ? { id: this.data.id, turn: this.data.turn }
            : {};
        return {
            isFree: !!this.isFreeSelectionInput.value,
            ...dataByIsFree,
            ...dataById,
            text: this.buttonNameInput.value,
            linkAttributes: this.saveProps.length > 0
                ? this.saveProps.map(item => item.getDataForSubmit())
                : [],
            linkBlocks: ((_b = (_a = this.messageNext) === null || _a === void 0 ? void 0 : _a.getDataForSubmit().linkBlocks) !== null && _b !== void 0 ? _b : []).length > 0
                ? (_d = (_c = this.messageNext) === null || _c === void 0 ? void 0 : _c.getDataForSubmit().linkBlocks) !== null && _d !== void 0 ? _d : []
                : [],
        };
    }
    generateMessageNext(linkBlocks) {
        if (linkBlocks.length < 1) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.messageNext = new DOHbMessageNext_1.DOHbMessageNext(this.parent, {
                linkBlocks,
            });
            (_b = (_a = this.parent.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.reLayoutFlowChartElements) === null || _b === void 0 ? void 0 : _b.call(_a);
        });
    }
    generateSaveProp(linkAttributes) {
        (0, mobx_1.runInAction)(() => {
            this.saveProps = linkAttributes.map(item => new DOHbSaveProp_1.DOHbSaveProp(this.parent, item));
        });
    }
    initInputs(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        this.buttonNameInput = new ui_1.InputWithValidation('buttonName', '버튼명', '버튼명을 입력해 주세요.', 'text', (_a = data.text) !== null && _a !== void 0 ? _a : '', Yup.string().min(1).required(), {
            noLabel: true,
        });
        this.isFreeSelectionInput = new ui_1.SelectionInput('isFree', '가격 설정', (_b = data.isFree) !== null && _b !== void 0 ? _b : true, [
            { name: '무료 선택지', value: true },
            { name: '유료 선택지', value: false },
        ], {
            noLabel: true,
        });
        this.isShowAdSelectionInput = new ui_1.SelectionInput('isShowAd', 'isShowAd', (_c = data.isShowAd) !== null && _c !== void 0 ? _c : false, [
            { name: 'isShowAd', value: true },
            { name: 'noShowAd', value: false },
        ], {
            noLabel: true,
        });
        this.menuIdSelectionInput = new ui_1.MultiSelectionInput('menuIdSelection', '스킬 선택', '메뉴 검색', !!this.data.menu
            ? [
                {
                    name: (_d = this.data.menu.title) !== null && _d !== void 0 ? _d : '-',
                    value: (_e = this.data.menu.id) !== null && _e !== void 0 ? _e : -1,
                    description: `원가 [ 하트코인 ${(_f = this.data.menu.coin) !== null && _f !== void 0 ? _f : 0} / 원화 ${(_h = (_g = this.data.menu.coinAmount) === null || _g === void 0 ? void 0 : _g.toLocaleString()) !== null && _h !== void 0 ? _h : 0}원 ] 로 보여집니다.`,
                    heartNum: (_j = this.data.menu.coin) !== null && _j !== void 0 ? _j : undefined,
                    leftIconUrl: (_k = this.data.menu.imageUrl) !== null && _k !== void 0 ? _k : undefined,
                },
            ]
            : [], [], {
            required: true,
            onLoadMoreOptions: async (pageIndex, inputText) => {
                var _a, _b;
                try {
                    const res = await this.parent.apiServer.menu.getList(this.parent.block.parentChapter.story.storyId, {
                        sort: 'turn',
                        sortDirection: 'asc',
                        pageIndex,
                        pageSize: 20,
                        filter: inputText,
                        isFree: false,
                        kind: 'clickableMenu',
                    });
                    return ((_b = (_a = res.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                        var _a, _b, _c, _d, _e;
                        return ({
                            name: (_a = item.title) !== null && _a !== void 0 ? _a : '-',
                            value: (_b = item.id) !== null && _b !== void 0 ? _b : -1,
                            description: `원가 [ 하트코인 ${item.coin} / 원화 ${(_c = item.coinAmount) === null || _c === void 0 ? void 0 : _c.toLocaleString()} ] 로 보여집니다.`,
                            heartNum: (_d = item.coin) !== null && _d !== void 0 ? _d : undefined,
                            leftIconUrl: (_e = item.imageUrl) !== null && _e !== void 0 ? _e : undefined,
                        });
                    })) !== null && _b !== void 0 ? _b : []);
                }
                catch (ex) {
                    this.parent.rootStore.showError(ex);
                    return [];
                }
            },
            pageSizeForOptions: 20,
            pageIndexForOptions: 0,
            theme: 'menu',
            isSingleValue: true,
            validate: () => {
                return this.menuIdSelectionInput.value.length > 0;
            },
        });
        this.coinTextInput = new ui_1.InputWithValidation('coin', 'coin', '코인을 입력해주세요.', 'text', (_m = (_l = data.coin) === null || _l === void 0 ? void 0 : _l.toString()) !== null && _m !== void 0 ? _m : '', null, {
            noLabel: true,
        });
        this.productNameTextInput = new ui_1.InputWithValidation('productName', '제품명', '제품명을 입력해 주세요.', 'text', (_o = data.productName) !== null && _o !== void 0 ? _o : '', null, {
            noLabel: true,
        });
        this.coinAmountTextInput = new ui_1.InputWithValidation('coinAmount', '버튼명', '버튼명을 입력해 주세요.', 'text', (_p = data.text) !== null && _p !== void 0 ? _p : '', null, {
            noLabel: true,
        });
    }
}
exports.DOHbMessageSelectButton = DOHbMessageSelectButton;
