"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOUserEditor = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const EntityEditor_1 = require("../EntityEditor");
const fields_1 = require("../fields");
const DBFileFieldWithUI_1 = require("../fields/DBFileFieldWithUI");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class DOUserEditor extends EntityEditor_1.EntityEditor {
    constructor(user) {
        super(user);
        this.user = user;
        this.accountField = new ui_1.InputWithValidation('account', trans('legacy_DOUserEditor.ts_account'), '', 'text', user.email, null, { editable: false, noLabel: true });
        const stringFields = [
            {
                key: 'authorName',
                options: {
                    label: trans('legacy_DOUserEditor.ts_pseudonym'),
                    placeholder: trans('legacy_DOUserEditor.ts_enter_pseudonym_for_storyplay'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'authorEmail',
                options: {
                    label: trans('legacy_DOUserEditor.ts_work_related_email_contact'),
                    placeholder: trans('legacy_DOUserEditor.ts_enter_email'),
                    options: { noLabel: true },
                },
            },
            {
                key: 'phone',
                options: {
                    label: trans('legacy_DOUserEditor.ts_work_related_phone_contact'),
                    placeholder: trans('legacy_DOUserEditor.ts_enter_phone_number'),
                    options: { noLabel: true },
                },
            },
        ];
        stringFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new fields_1.TextFieldWithUI(fld.key, () => { var _a; return (_a = user[fld.key]) !== null && _a !== void 0 ? _a : ''; }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        this.authorNameField.input.setRequired(true);
        this.phoneField.input.setRequired(true);
        const fileFields = [
            {
                key: 'profile',
                options: {
                    label: trans('legacy_DOUserEditor.ts_profile_picture'),
                    options: { showLabel: false },
                },
            },
        ];
        fileFields.forEach(fld => {
            ;
            this[`${fld.key}FileField`] = new DBFileFieldWithUI_1.DBFileFieldWithUI(`${fld.key}`, () => {
                return user[`${fld.key}File`];
            }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        // const selectionFields: {
        //   key: string
        //   options: ISelectionFieldUIOptions<boolean>
        // }[] = [
        //   {
        //     key: 'isAgreeStoriesRelatedToMe',
        //     options: {
        //       label: '내가 참여한 작품에 대한 모든 알림을 수신하겠습니다.',
        //       selection: [
        //         {
        //           name: '수신',
        //           value: true,
        //           description: '',
        //         },
        //         {
        //           name: '미수신',
        //           value: false,
        //           description: '',
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     key: 'isAgreeMyStory',
        //     options: {
        //       label: '내 작품에 대한 알람만 수신하겠습니다.',
        //       selection: [
        //         {
        //           name: '수신',
        //           value: true,
        //           description: '',
        //         },
        //         {
        //           name: '미수신',
        //           value: false,
        //           description: '',
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     key: 'isAgreeEvent',
        //     options: {
        //       label:
        //         '스토리플레이에서 진행하는 이벤트, 프로모션에 관한 광고를 수신하겠습니다.',
        //       selection: [
        //         {
        //           name: '수신',
        //           value: true,
        //           description: '',
        //         },
        //         {
        //           name: '미수신',
        //           value: false,
        //           description: '',
        //         },
        //       ],
        //     },
        //   },
        // ]
        //
        // selectionFields.forEach(fld => {
        //   ;(this as any)[`${fld.key}Field`] = new SelectionFieldWithUI(
        //     fld.key,
        //     () => (user as any)[fld.key],
        //     () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
        //     fld.options
        //   )
        // })
        (0, mobx_1.makeObservable)(this, {
            updateToAuthorAt: mobx_1.computed,
        });
    }
    get updateToAuthorAt() {
        return this._target.updateToAuthorAt;
    }
}
exports.DOUserEditor = DOUserEditor;
