import classNames from 'classnames'
import { ReactNodeLike } from 'prop-types'
import React from 'react'
import { InputAdapter, TextMask } from 'react-text-mask-hoc'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { InputType } from 'reactstrap/lib/Input'
import { ODIcon, ODIcons } from '../../components/ODIcon'

export type ODInputProps = {
  required?: boolean
  readOnly?: boolean
  autoFocus?: boolean

  placeholder: string
  inputType?: InputType
  autoComplete?: string
  icon?: ODIcons
  iconAppend?: boolean // 뒤에 붙일 것인가?
  children?: ReactNodeLike
  prependChildren?: boolean
  appendChildren?: boolean
  mask?: Array<string | RegExp>
  inputProps?: object
  iconProps?: object

  //
  disabled?: boolean
  value?: any
  onChange?: (value: any) => void
}

type InputWithIconProps = {
  icon?: ODIcons
  iconAppend?: boolean // 뒤에 붙일 것인가?
  iconProps?: object
}

export const InputWithIcon: React.FC<InputWithIconProps> = (props) => {
  const { children, icon, iconAppend, iconProps = {} } = props

  if (icon) {
    const IconElement = (
      <InputGroupAddon addonType={iconAppend ? 'append' : 'prepend'}>
        <InputGroupText>
          <ODIcon icon={icon} {...iconProps} />
        </InputGroupText>
      </InputGroupAddon>
    )

    return (
      <InputGroup>
        {!iconAppend && IconElement}
        {children}
        {iconAppend && IconElement}
      </InputGroup>
    )
  }
  return <>{children}</>
}

export function ODInput(props: ODInputProps) {
  const {
    placeholder,
    inputType = 'text',
    inputProps = {},

    autoFocus,
    required,
    readOnly,
    disabled,

    icon,
    iconAppend,
    iconProps = {},

    appendChildren,
    prependChildren,
    children,

    mask,

    value,
    onChange,
  } = props

  const inputItem = (() => {
    if (mask) {
      return (
        <InputWithIcon icon={icon} iconAppend={iconAppend} iconProps={iconProps}>
          <TextMask
            mask={mask}
            Component={InputAdapter}
            className={classNames('form-control')}
            onChange={(e: any) => onChange?.(e.target.value)}
            value={value}
            disabled={disabled}
            {...inputProps}
          />
        </InputWithIcon>
      )
    }

    return (
      <InputWithIcon icon={icon} iconAppend={iconAppend} iconProps={iconProps}>
        <Input
          readOnly={readOnly}
          type={inputType}
          placeholder={placeholder}
          autoFocus={!!autoFocus}
          required={!!required}
          onChange={(e: any) => onChange?.(e.target.value)}
          value={value}
          disabled={disabled}
          {...inputProps}
        />
      </InputWithIcon>
    )
  })()

  return (
    <>
      {children && (
        <div style={{ display: 'flex', width: '100%' }}>
          {prependChildren && children}
          {inputItem}
          {appendChildren && children}
        </div>
      )}
      {!children && inputItem}
    </>
  )
}
