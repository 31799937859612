"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpDoStoryStoreStrategy = void 0;
const core_1 = require("@storyplay/core");
const spDoStoryStrategy_1 = require("../doStoryStrategy/spDoStoryStrategy");
const baseDoStoryStoreStrategy_1 = require("./baseDoStoryStoreStrategy");
/**
 * 이 클래스의 동작은 헬로봇 일반 블록에 대한 동작을 같이 처리한다.
 */
class SpDoStoryStoreStrategy extends baseDoStoryStoreStrategy_1.BaseDoStoryStoreStrategy {
    constructor() {
        super(...arguments);
        this.doStoryStrategyConstructor = spDoStoryStrategy_1.SpDoStoryStrategy;
    }
    async loadStoryById(storyId) {
        const di = this.doStoryStore.rootStore.di;
        const getPlayType = await di.server.getStoryPlayType(storyId);
        if (getPlayType.playType !== core_1.GQLSTORY_PLAY_TYPE.EPUB) {
            return await di.server.getStoryForStudio(storyId);
        }
        else {
            return await di.server.getWebNovelStoryForStudio(storyId);
        }
    }
}
exports.SpDoStoryStoreStrategy = SpDoStoryStoreStrategy;
