import { ODEntityLabeled, ODEntityRaw } from '@odc/od-react-belt'
import { COUNTRY_CODE, SystemLanguages } from '@storyplay/common'
import React from 'react'
import Select from 'react-select'
import { Col, Row } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLNotice,
  GQLNoticeCreationInput,
  GQLNoticeUpdateInput,
  GQLOkResponse,
  GQLSingleIDInput,
} from '../../@types/server'
import { useCounter, useODMutation, useODQuery } from '../../context/ODCommon'
import { ODEntityInput } from '../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

type NoticeEditContainerProps = {
  idEditing?: number
}

const NOTICE_FULL_SNAPSHOT = `
  noticeId
  title
  contents
  langCode
  countryCode
  createdAt
`

const GQL_CREATE = `
mutation createNotice($data: NoticeCreationInput!) {
  createNotice(data: $data) {
    ${NOTICE_FULL_SNAPSHOT}
  }
}
`

const GQL_UPDATE = `
mutation updateNotice($data: NoticeUpdateInput!) {
  updateNotice(data: $data) {
    ${NOTICE_FULL_SNAPSHOT}
  }
}
`

const GQL_GET = `
query getNotice($data: SingleIDInput!) {
  getNotice(data: $data) {
    ${NOTICE_FULL_SNAPSHOT}
  }
}
`

const GQL_REMOVE = `
mutation removeNotice($data: SingleIDInput!) {
  removeNotice(data: $data) {
    ok
  }
}
`

type PropsInput = GQLNoticeCreationInput & GQLNoticeUpdateInput
type Entity = GQLNotice

const NoticePropsInputValidation = {
  title: Yup.string()
    .min(2, `최소 2자 이상이어야 합니다.`)
    .max(80, `최대 80자입니다.`),
  contents: Yup.string()
    .min(2, `최소 2자 이상이어야 합니다.`)
    .max(13000, `최대 1,3000자입니다.`),
}

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    title: NoticePropsInputValidation.title.required('제목은 필수입니다.'),
    contents:
      NoticePropsInputValidation.contents.required('내용은 필수입니다.'),
  })
}

export const NoticeEditContainer: React.FC<NoticeEditContainerProps> = (
  props
) => {
  const { idEditing } = props
  const innerRef = React.useRef(null)
  const apiCreate = useODMutation<Partial<PropsInput>, Partial<Entity>>(
    GQL_CREATE
  )
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(
    GQL_UPDATE
  )
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE)
  const [token] = useCounter()

  const selectedLangCodeRef = React.useRef<string | null>(null)
  const selectedCountryCodeRef = React.useRef<string | null>(null)

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>
  >(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            id: 0,
            title: '',
            contents: '',
            langCode: '',
            countryCode: '',
          }
        }

        selectedLangCodeRef.current = data.langCode
        selectedCountryCodeRef.current = data.countryCode

        return {
          id: data.noticeId,
          title: data.title,
          contents: data.contents,
          langCode: data.langCode,
          countryCode: data.countryCode,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        data.langCode = selectedLangCodeRef.current ?? undefined
        data.countryCode = selectedCountryCodeRef.current ?? undefined

        if (idEditing) {
          await apiUpdate({ id: idEditing, ...data })
          Utils.showSuccess('공지사항을 업데이트하였습니다.', 'Success')
        } else {
          await apiCreate({
            title: data.title,
            contents: data.contents,
            langCode: data.langCode,
            countryCode: data.countryCode,
          })
          Utils.showSuccess('공지사항을 추가하였습니다.', 'Success')
        }
        return SiteUrls.Admin.Notice.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('공지사항을 삭제하였습니다.', 'Success')
        }
        return SiteUrls.Admin.Notice.List
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [idEditing, apiCreate, apiGet, apiUpdate, apiRemove, token]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<Entity, Partial<PropsInput>>
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
      ),
    [options]
  )

  const title = !idEditing ? '공지사항 추가' : '공지사항 수정'

  return (
    <Provider title={title}>
      <ODEntityInput
        name="title"
        label="제목"
        placeholder="제목을 입력하세요"
        inputType="text"
      />
      <ODEntityInput
        name="contents"
        label="내용"
        placeholder="내용을 입력하세요"
        inputType="textarea"
        inputProps={{ rows: 10 }}
      />

      <ODEntityLabeled name={'언어코드'} label={'언어코드'}>
        <Row>
          <Col md={6}>
            <Select
              isSearchable={true}
              placeholder="공지사항의 언어를 선택해주세요"
              onChange={(v) => {
                if (v) {
                  // @ts-ignore
                  selectedLangCodeRef.current = v.value
                }
              }}
              options={SystemLanguages.map((lng) => ({
                label: `${lng.code}(${lng.display})`,
                value: lng.code,
              }))}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 2 }),
              }}
            />
          </Col>
          {idEditing && (
            <Col md={4}>
              <ODEntityRaw
                name="langCode"
                keyPath="langCode"
                render={({ value }) => {
                  return <span>{value ? value : '없음'}</span>
                }}
              />
            </Col>
          )}
        </Row>
      </ODEntityLabeled>

      <ODEntityLabeled name={'국가코드'} label={'국가코드'}>
        <Row>
          <Col md={6}>
            <Select
              isSearchable={true}
              placeholder="공지사항을 제공할 국가를 선택해주세요"
              onChange={(v) => {
                if (v) {
                  // @ts-ignore
                  selectedCountryCodeRef.current = v.value
                }
              }}
              options={[
                { label: 'KR', value: COUNTRY_CODE.KR },
                { label: 'US', value: COUNTRY_CODE.US },
              ]}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 2 }),
              }}
            />
          </Col>
          {idEditing && (
            <Col md={4}>
              <ODEntityRaw
                name="countryCode"
                keyPath="countryCode"
                render={({ value }) => {
                  return <span>{value ? value : '없음'}</span>
                }}
              />
            </Col>
          )}
        </Row>
      </ODEntityLabeled>
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        deleteConfirmOptions={{
          message: <>정말 삭제하시겠습니까?</>,
          yes: '삭제',
          no: '취소',
        }}
        deleteButtonName={idEditing ? 'Delete' : undefined}
        context={Context}
      />
    </Provider>
  )
}
