"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Finder = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const entity_1 = require("../entity");
const IFinder_1 = require("./IFinder");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class Finder {
    constructor(chapter) {
        this.mode = IFinder_1.FinderMode.None;
        this.findText = '';
        this.replaceText = '';
        this.findResultIndex = -1;
        this.findResults = [];
        this.findResultKeyword = ''; // 현재 결과값에 사용된 검색어
        this.chapter = chapter;
        this.findInput = new entity_1.InputWithValidation(`searchInEpisode`, trans('legacy_Finder.ts_search_within_chapter'), trans('legacy_Finder.ts_search_within_chapter'), 'text', '', null, {
            enableInPlaceEditing: false,
            noLabel: true,
        }).setAutoFocus();
        (0, mobx_1.makeObservable)(this, {
            mode: mobx_1.observable,
            findText: mobx_1.observable,
            replaceText: mobx_1.observable,
            findResultIndex: mobx_1.observable,
            findResults: mobx_1.observable,
            setMode: mobx_1.action,
            setFindText: mobx_1.action,
            setReplaceText: mobx_1.action,
            updateFindResults: mobx_1.action,
            selectNextResult: mobx_1.action,
            selectPrevResult: mobx_1.action,
            selectResultAtIndex: mobx_1.action,
            findResultsText: mobx_1.computed,
        });
    }
    setMode(mode) {
        if (this.mode === mode) {
            return;
        }
        this.mode = mode;
        if (mode === IFinder_1.FinderMode.None) {
            // 토글형식으로 동작하면서 검색 포커스 인덱스를 되돌이킨다.
            this.mode = IFinder_1.FinderMode.None;
            this.findResultIndex = -1;
        }
        else {
            this.updateFindResults(false);
        }
    }
    setFindText(text) {
        this.findText = text;
    }
    setReplaceText(text) {
        this.replaceText = text;
    }
    updateFindResults(findPrev) {
        const keyword = this.findInput.value.trim();
        if (this.findResultKeyword === keyword) {
            if (findPrev) {
                this.selectPrevResult();
            }
            else {
                this.selectNextResult();
            }
            return;
        }
        this.findResultKeyword = keyword;
        this.findResultIndex = -1;
        if (keyword.length >= 1) {
            this.findResults = this.chapter.getFindResults(keyword);
            this.selectNextResult();
        }
        else {
            this.findResults = [];
        }
    }
    get findResultsText() {
        if (this.findResults.length > 0) {
            return `${Math.max(this.findResultIndex + 1, 0)}/${this.findResults.length}`;
        }
        return `0/0`;
    }
    selectNextResult() {
        if (this.findResults.length <= 0) {
            return;
        }
        if (this.findResultIndex === this.findResults.length - 1) {
            this.selectResultAtIndex(0);
        }
        else {
            this.selectResultAtIndex(this.findResultIndex + 1);
        }
    }
    selectPrevResult() {
        if (this.findResults.length <= 0) {
            return;
        }
        if (this.findResultIndex > 0) {
            this.selectResultAtIndex(this.findResultIndex - 1);
        }
        else {
            this.selectResultAtIndex(this.findResults.length - 1);
        }
    }
    selectResultAtIndex(index) {
        this.findResultIndex = index;
        if (this.findResults.length === 0) {
            return;
        }
        const target = this.findResults[index];
        target.selectBySearch();
        // @ts-ignore
        console.log(144, index, JSON.parse(JSON.stringify(target.data)));
    }
}
exports.Finder = Finder;
