import {
  ODEntity,
  ODEntityInput,
  ODEntityLabeled,
  ODEntityRaw,
  ODImageFileInput,
  useCounter,
  useODEntityAPI,
} from '@odc/od-react-belt'
import gql from 'graphql-tag'
import React from 'react'
import { GQLEnding, GQLEndingPropsInput } from '../../../@types/server'
import { GQL_GET_ENDING, GQL_UPDATE_ENDING } from '../../../hooks/gqls_admin'
import { SiteUrls } from '../../../urls'
import { EndingPrimaryKey, ICTEnding } from '../EndingCommon'

interface IEndingEditPageProps {
  storyId: number
  endingId: number | null
  isFinal: boolean
}

const DEFAULT_DATA: Partial<ICTEnding> = {
  endingId: 0,
  name: ``,
  previewName: ``,
  showOrder: 1000,
}

export const EndingEditPage: React.FC<IEndingEditPageProps> = (props) => {
  const { storyId, endingId, isFinal } = props
  const apis = useODEntityAPI<GQLEnding, ICTEnding, any, GQLEndingPropsInput>({
    // createGQL: gql(GQL_CREATE_Ending),
    updateGQL: gql(GQL_UPDATE_ENDING),
    readGQL: gql(GQL_GET_ENDING),
    // deleteGQL: gql(GQL_REMOVE_Ending),
    primaryKeyName: EndingPrimaryKey,
  })

  const [token] = useCounter()

  return (
    <>
      <ODEntity
        resourceId={endingId}
        // @ts-ignore
        api={apis}
        saveButtonName="저장"
        // deleteButtonName="삭제"
        titleCreation="추가"
        titleUpdate="수정"
        updateSuccessTitle="성공"
        updateSuccessMessage="수정하였습니다."
        // urlAfterCreation={() => SiteUrls.Admin.Ending.Main}
        // urlAfterDelete={() => SiteUrls.Admin.Ending.Main}
        // deleteSuccessTitle="성공"
        // deleteSuccessMessage="삭제하였습니다."
        defaultCreateClientData={DEFAULT_DATA}
        urlAfterUpdate={() => SiteUrls.Admin.Story.Edit(storyId, 5)}
        noCardWrap
        refreshToken={token}
      >
        <ODEntityInput
          keyPath="endingId"
          label="엔딩 아이디"
          name="endingId"
          placeholder="엔딩 아이디 (자동부여)"
          inputType="text"
          inputProps={{ disabled: true }}
        />
        <ODEntityInput
          keyPath="name"
          label="엔딩명"
          name="name"
          placeholder="엔딩명"
          inputType="text"
          inputProps={{ disabled: true }}
        />
        <ODEntityInput
          keyPath="previewName"
          label="미리보기 엔딩명"
          name="previewName"
          placeholder="엔딩명을 간추려서 적어주세요."
          inputType="text"
        />
        <ODEntityInput
          keyPath="showOrder"
          label="노출 우선순위"
          name="showOrder"
          placeholder="노출 우선순위"
          inputType="number"
        />
        {isFinal && (
          <ODEntityLabeled
            label="엔딩 도착률 설정"
            name="endingArrivalRateByAdmin"
          >
            <div style={{ color: 'red' }}>
              <b>최종 엔딩 도착률</b>은 <b>슈퍼어드민</b>만 수정할 수 있습니다.
            </div>
          </ODEntityLabeled>
        )}
        {isFinal && (
          <ODEntityInput
            keyPath="arrivalRateByAdmin"
            label="최종 엔딩 도착률"
            name="arrivalRateByAdmin"
            placeholder="범위(0~100)"
            inputType="number"
          />
        )}
        <ODEntityLabeled label="분류" name="isFinal">
          <ODEntityRaw
            name="isFinal"
            render={(p) => {
              return <span>{p.value ? '최종 엔딩' : '챕터 엔딩'}</span>
            }}
            keyPath="isFinal"
          />
        </ODEntityLabeled>
        <ODEntityLabeled label="이미지파일" name="imageFile">
          <ODImageFileInput
            name="imageFile"
            height={200}
            width={200}
            keyPath="imageFile.link"
          />
        </ODEntityLabeled>
        <ODEntityLabeled label="부분캡쳐이미지파일" name="previewImageFile">
          <ODImageFileInput
            name="previewImageFile"
            height={200}
            width={200}
            keyPath="previewImageFile.link"
          />
        </ODEntityLabeled>
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <hr />
        </div>
      </ODEntity>
    </>
  )
}
