"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioClipboardDataType = exports.StudioClipboardType = void 0;
// 안정적으로 지원되는 건 text/plain 뿐..
exports.StudioClipboardType = 'text/plain';
var StudioClipboardDataType;
(function (StudioClipboardDataType) {
    StudioClipboardDataType["Statements"] = "Statements";
    StudioClipboardDataType["Block"] = "Block";
})(StudioClipboardDataType = exports.StudioClipboardDataType || (exports.StudioClipboardDataType = {}));
