import { IODListableEventContext, ODListableOption } from '@odc/od-react-belt'
import { GQLAdminLog } from '../../@types/server'

export const AdminLogPrimaryKey = 'adminLogId'

// modifiable fields only.
// export interface ICTAdminLog extends Pick<GQLAdminLog, 'adminLogId'> {
//   // more fields, mapped (if needed)
// }

export interface IAdminLogListableOption extends ODListableOption {
  filter: string
}

export interface IAdminLogEntityContext
  extends IODListableEventContext<GQLAdminLog> {
  //
}

export const ADMIN_ACTION_TO_STRING = {
  ViewUser: '사용자 상세 조회',
  DeactivateUser: '사용자 탈퇴',
  CreateUser: '사용자 생성',
  UpdateUser: '사용자 정보 수정',
  AddCoin: '사용자 코인 추가',
}
