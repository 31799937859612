"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALConfirmModal = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const consts_1 = require("../../../consts");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class ALConfirmModal {
    constructor(rootStore, modalTitle, text, onSubmit, confirmButtonText) {
        this.rootStore = rootStore;
        this.modalTitle = modalTitle;
        this.textUnderTitle = text;
        this.confirmButtonText =
            confirmButtonText !== null && confirmButtonText !== void 0 ? confirmButtonText : trans('legacy_EditAuthorInfoPage_check');
        this.onSubmit = onSubmit;
    }
    async submit() {
        return this.onSubmit();
    }
    buildActionChain() {
        const builder = new ActionChainBuilder_1.ActionChainBuilder('ALConfirmModal');
        builder.addModal(modalBuilder => {
            modalBuilder.setModalSize('normal');
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: this.modalTitle,
                textUnderTitle: this.textUnderTitle,
                imageName: this.rootStore.serviceType === 'hb'
                    ? consts_1.SPImageName.IcnCheckBoxWithTitleHb
                    : consts_1.SPImageName.IcnCheckBoxWithTitle,
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_EditAuthorInfoPage_cancellation'),
                        onClick: () => {
                            modalBuilder.cancelAction();
                        },
                    },
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: this.confirmButtonText,
                        className: 'py-2 px-3',
                        isSizingByPadding: true,
                        onClick: async () => {
                            if (await this.submit()) {
                                modalBuilder.finishAction(null);
                            }
                        },
                    },
                ],
            });
        });
        return builder.buildActionChain();
    }
}
exports.ALConfirmModal = ALConfirmModal;
