"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOCurrentLineStatement = void 0;
const mobx_1 = require("mobx");
const fields_1 = require("../../fields");
const IDOStatement_1 = require("../IDOStatement");
class DOCurrentLineStatement {
    constructor(parentBlock) {
        this.key = '_current_line';
        this.lineType = IDOStatement_1.UILineType.EditorLine;
        this.editorStatementType = IDOStatement_1.EDITOR_STATEMENT_TYPE.CurrentLine;
        this.blocksTo = [];
        this.blockTagType = IDOStatement_1.BlockTagType.NoTag;
        this.lastValidationResults = [];
        this.isPublishedEnding = false;
        this.parentBlock = parentBlock;
        this.executeConditionField = new fields_1.TextFieldWithUI(`does not support executeConditionField`, () => {
            return '';
        }, v => null, {
            placeholder: 'does not support executeConditionField',
        });
        (0, mobx_1.makeObservable)(this, {
            background: mobx_1.computed,
            canEdit: mobx_1.computed,
        });
    }
    fixStatements() {
        //
    }
    onBlockNameChanged(prevName, newName) {
        // nothing to do in this block.
    }
    get cmdString() {
        return '--- EDITOR ---';
    }
    clearValidationResults() {
        (0, mobx_1.runInAction)(() => (this.lastValidationResults.length = 0));
    }
    validate() {
        return [];
    }
    get validatorName() {
        return `[ST:CurrentLine]`;
    }
    generateLines() {
        return [];
    }
    exportMetaDataUpdateActions() {
        return [];
    }
    get background() {
        return this.parentBlock.getBackgroundOfStatement(this);
    }
    onBlockRemoved(blockId) {
        //
    }
    get canEdit() {
        var _a;
        return !!((_a = this.parentBlock.parentChapter.blockEditor) === null || _a === void 0 ? void 0 : _a.canAddToCurrentCursor);
    }
    get snapshot() {
        return '-- current line --';
    }
    onAllBlocksOfChapterLoaded() {
        //
    }
    //
    // IStudioFinderSource
    //
    get finderSourceName() {
        return this.validatorName;
    }
    getFindResults(keyword) {
        return [];
    }
    replaceTextWith(text, to) {
        //
    }
    selectBySearch() {
        // Current line 이 선택될 일은 없다.
    }
    //
    // ExecuteCondition
    //
    get doesExecuteConditionSupport() {
        return false;
    }
    get returnSetExecuteConditionModalOpen() {
        return undefined;
    }
    get hasExecuteCondition() {
        return false;
    }
    get hasSameExecuteConditionWithPrevStatement() {
        return false;
    }
    get hasSameExecuteConditionWithNextStatement() {
        return false;
    }
    get executeConditionOfStatement() {
        return undefined;
    }
    //
    // IStudioTranslator interface
    //
    getUniquePathForTranslator() {
        // 어차피 getMessagesToTranslate() 에서 null 반환할 것이므로 큰 문제 없음.
        return this.key;
    }
    async getMessagesToTranslate() {
        return null;
    }
    async applyTranslatedMessages(translated) {
        //
    }
    async checkSanityForTranslatedMessages(original, translated) {
        //
    }
    async applyFixedTranslation(fixed) {
        //
    }
}
exports.DOCurrentLineStatement = DOCurrentLineStatement;
