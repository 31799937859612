"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbErrorDomainToKoString = void 0;
exports.HbErrorDomainToKoString = {
    message: '메시지',
    block: '블록',
    blockGroup: '블록그룹',
    algorithm: '알고리즘 블록',
    algorithmGroup: '알고리즘 블록그룹',
    reportGroup: '리포트 그룹',
    report: '리포트',
    reportProduct: '리포트 상품',
    rule: '말 가르치기',
    menu: '메뉴',
    api: 'API 호출',
    resultImage: '결과 이미지',
    bots: '',
};
