export const LOCAL_STORAGE_KEY_TOKEN = 'token'
export const LOCAL_STORAGE_KEY_TOKEN_EXPIRE_AT = 'tokenExpireAt' // epoch
export const TOKEN_EXPIRE_AFTER_SEC_WRITER = 60 * 60 * 12
export const TOKEN_EXPIRE_AFTER_SEC_ADMIN = 60 * 30 * 48

export enum STORY_SECTION_TYPE {
  OnStage = 'OnStage', // 정식작품
  Challenge = 'Challenge', // 도전 스토리
  Monthly = 'Monthly', // 월간 대표작
  Dropped = 'Dropped', // 보물상자
  Global = 'Global', // 글로벌 작품
  Adult = 'Adult', // 성인 인증 작품
  UGC = 'UGC', // UGC
}

// 클라이언트에서 요청 시 사용하는 section type
export enum UI_STORY_SECTION_TYPE {
  MWS = 'MWS',
  OnStage = 'OnStage',
  Monthly = 'Monthly',
  Dropped = 'Dropped',
  Banner = 'Banner',
  Global = 'Global',
  Adult = 'Adult',
  UGC = 'UGC',
}

export enum ENDING_RATE_RANGE_LEVEL {
  Unknown = 'Unknown', // 알수없음
  Normal = 'Normal', // 보통
  Rarity = 'Rarity', // 희소
  VeryRarity = 'VeryRarity', // 매우 희소
  Minority = 'Minority', //최고 희소
}

export enum STORY_ENDING_ARRIVAL_RATE_TYPE {
  Unknown = 'Unknown',
  RealValue = 'RealValue',
  SettingValue = 'SettingValue',
  InVisible = 'InVisible',
}

export enum ENDING_CARD_TYPE {
  ArrivalRate = 'ArrivalRate', // 최종 엔딩 도착률
  ChoiceCount = 'ChoiceCount', // 선택의 기로 횟수
  EndingCollectRate = 'EndingCollectRate', // 작품 엔딩 수집률
}

export enum ONBOARDING_POPUP_TYPE {
  Text = 'Text', // 설명 팝업
  Choice = 'Choice', // 선택의 기로 횟수
  Profile = 'Profile', // 프로필 팝업
  Name = 'Name',
  OtherName = 'OtherName', // 상대역 이름 팝업
  OtherProfile = 'OtherProfile', // 상대역 프로필 팝업
  StorygameProfile = 'StorygameProfile',
}

export enum STORY_TYPE {
  Novel = 'Novel',
  Chat = 'Chat',
}

export enum STATUS {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
  Disabled = 'Disabled',
}

export enum ONBOARDING_POPUP_INEQUALITY {
  MoreThan = 'MoreThan', // >
  LessThan = 'LessThan', // <
  EqualOrMoreThan = 'EqualOrMoreThan', // >=
  EqualOrLessThan = 'EqualOrLessThan', // <=
  Equal = 'Equal', // ==
  NotEqual = 'NotEqual', // !=
}

export enum LEDGER_AMOUNT_FILTER_FIELD {
  PlusAmount = 'PlusAmount',
  MinusAmount = 'MinusAmount',
}

export enum ORDER_BY_DIRECTION {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum INDEX {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export enum ONBOARDING_POPUP_PROPERTY {
  __playerClass__ = '__playerClass__',
}

// export enum CHALLENGE_STORY_GENRE {
//   ROMANCE = 'ROMANCE', // 로맨스
//   ROMANCE_FANTASY = 'ROMANCE_FANTASY', // 로맨스 판타지
//   FANTASY = 'FANTASY', // 판타지
//   PERIOD_DRAMA = 'PERIOD_DRAMA', // 시대극
//   MYSTERY_PLAY = 'MYSTERY_PLAY', // 추리&방탈출
//   UNKNOWN = 'UNKNOWN', // 미분류 작품
// }
