"use strict";
/**
 * 스튜디오에서 설정하는 작품 가격 설정
 * 해당 옵션에 의해 회차 가격이 설정됩니다. (2023-05-17 기준 웹소설만 적용)
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.STUDIO_STORY_PRICE_SETTING_TYPE_TO_KO_STRING = exports.STUDIO_STORY_PRICE_SETTING_TYPE = void 0;
var STUDIO_STORY_PRICE_SETTING_TYPE;
(function (STUDIO_STORY_PRICE_SETTING_TYPE) {
    STUDIO_STORY_PRICE_SETTING_TYPE["FREE"] = "FREE";
    STUDIO_STORY_PRICE_SETTING_TYPE["FREE_AD"] = "FREE_AD";
    STUDIO_STORY_PRICE_SETTING_TYPE["PAID"] = "PAID";
})(STUDIO_STORY_PRICE_SETTING_TYPE = exports.STUDIO_STORY_PRICE_SETTING_TYPE || (exports.STUDIO_STORY_PRICE_SETTING_TYPE = {}));
exports.STUDIO_STORY_PRICE_SETTING_TYPE_TO_KO_STRING = {
    [STUDIO_STORY_PRICE_SETTING_TYPE.FREE]: '무료',
    [STUDIO_STORY_PRICE_SETTING_TYPE.FREE_AD]: '광고',
    [STUDIO_STORY_PRICE_SETTING_TYPE.PAID]: '유료',
};
