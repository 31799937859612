"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALParticipateContestModal = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const consts_1 = require("../../../consts");
const entity_1 = require("../entity");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
function createSelectedNumberInputField(index) {
    return new entity_1.InputWithValidation(`selectedNumber${index}`, trans('legacy_ALParticipateContestModal.ts_draw_numbers_for_fair_evaluation'), trans('legacy_ALParticipateContestModal.ts_enter_number_between_1_and_15'), 'number', (0, lodash_1.random)(1, 15), null, {
        noLabel: false,
        theme: entity_1.InputTheme.Default,
        minValueIfNumberType: 1,
        maxValueIfNumberType: 15,
        testId: 'selectedNumberInputInContestModal' + index,
    });
}
// 공모전 참가 신청 및 참가 수정 모달
// 현재는 공모전 이벤트가 끝났으므로, 사용하진 않지만 추후 사용 가능성이 있으므로 삭제 X
class ALParticipateContestModal {
    constructor(rootStore, storyId, storyName, onFinish, onCancelParticipation) {
        this.onFinish = null;
        // 해당 프로퍼티가 있으면 수정 모드
        this.onCancelParticipation = null;
        this.rootStore = rootStore;
        this.storyId = storyId;
        this.storyName = storyName;
        this.onFinish = onFinish !== null && onFinish !== void 0 ? onFinish : null;
        this.onCancelParticipation = onCancelParticipation !== null && onCancelParticipation !== void 0 ? onCancelParticipation : null;
    }
    isEditMode() {
        return !!this.onCancelParticipation;
    }
    async loadContestHasStoryIfIsEdit(isEdit) {
        if (!isEdit) {
            return;
        }
        const apiServer = this.rootStore.di.server;
        const res = await apiServer.getContentHasStory({ id: this.storyId });
        return res;
    }
    async submit(selectedNumbers) {
        var _a;
        const apiServer = this.rootStore.di.server;
        const input = {
            storyId: this.storyId,
            selectedNumbers,
        };
        const hasDifferentNumbers = [...new Set(selectedNumbers)].length === 5;
        if (!hasDifferentNumbers) {
            this.rootStore.showError(new core_1.SPCError(core_1.ErrorCode.ContestNumberDuplicated));
            return false;
        }
        if (this.isEditMode()) {
            try {
                await apiServer.updateContestHasStory(input);
                this.rootStore.showMessage(trans('legacy_ALParticipateContestModal.ts_modified'));
            }
            catch (ex) {
                this.rootStore.showError(ex);
                return false;
            }
            return true;
        }
        try {
            await apiServer.createContestHasStory(input);
            (_a = this.onFinish) === null || _a === void 0 ? void 0 : _a.call(this);
            this.rootStore.showMessage(trans('legacy_ALParticipateContestModal.ts_participated_in_contest'));
        }
        catch (ex) {
            this.rootStore.showError(ex);
            return false;
        }
        return true;
    }
    buildActionChain() {
        const selectedNumberInputFields = Array.from({ length: 5 }).map((_, index) => {
            return createSelectedNumberInputField(index + 1);
        });
        this.loadContestHasStoryIfIsEdit(this.isEditMode())
            .then(res => {
            if (!res) {
                return;
            }
            selectedNumberInputFields.forEach((field, index) => {
                field.onChange(res.selectedNumbers[index] || 1);
            });
        })
            .catch(ex => this.rootStore.showError(ex));
        const builder = new ActionChainBuilder_1.ActionChainBuilder('ALContentParticipatingModal');
        const story = this.rootStore.storyStore.getById(this.storyId);
        builder.addModal(modalBuilder => {
            modalBuilder.setModalSize('normal');
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: this.isEditMode()
                    ? trans('legacy_ALParticipateContestModal.ts_edit_contest_submission')
                    : trans('legacy_ALParticipateContestModal.ts_submit_to_contest'),
                imageName: consts_1.SPImageName.IcnCrown,
                imageClassName: 'text-gray-700',
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleTextInBox({
                    text: trans('legacy_ALParticipateContestModal.ts_work_name', {
                        value: this.storyName,
                    }),
                    boxClassName: 'flex items-center space-x-2.5',
                    textClassName: 'font-bold',
                    badge: !!story && !!story.genre
                        ? {
                            text: core_1.STORY_GENRE_TRANSLATED[story.genre],
                            className: 'bg-pink-100 py-0',
                            textClassName: 'text-pink-800',
                        }
                        : undefined,
                }));
            });
            selectedNumberInputFields.forEach(field => {
                modalBuilder.addRow(rowBuilder => {
                    rowBuilder.addCol(col => col.setSingleInput(field));
                });
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleIcon({
                    name: consts_1.SPImageName.IcnCircleWarning,
                    className: 'text-danger-primary500 relative top-0.75',
                }));
                rowBuilder.addCol(col => col.setSingleText({
                    text: trans('legacy_ALParticipateContestModal.ts_can_be_modified_after_contest_participation'),
                    textClassName: 'text-sm text-danger-primary500',
                }));
            });
            const buttonIfIsEditMode = this.isEditMode()
                ? [
                    {
                        theme: autoLayout_1.ButtonTheme.RedFilled,
                        text: trans('legacy_ALParticipateContestModal.ts_cancel_participation'),
                        onClick: () => {
                            var _a;
                            (_a = this.onCancelParticipation) === null || _a === void 0 ? void 0 : _a.call(this);
                            setImmediate(() => modalBuilder.finishAction(null));
                        },
                        className: 'mr-auto',
                        testId: 'CancelParticipationButtonInContestModal',
                    },
                ]
                : [];
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    ...buttonIfIsEditMode,
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_EditAuthorInfoPage_cancellation'),
                        onClick: () => {
                            modalBuilder.cancelAction();
                        },
                    },
                    {
                        testId: 'FinishButtonInContestModal',
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: this.isEditMode()
                            ? trans('legacy_EditAuthorInfoPage_check')
                            : trans('legacy_ALParticipateContestModal.ts_submit'),
                        onClick: async () => {
                            if (await this.submit(selectedNumberInputFields.map(item => item.value))) {
                                modalBuilder.finishAction(null);
                            }
                        },
                    },
                ],
            });
        });
        return builder.buildActionChain();
    }
}
exports.ALParticipateContestModal = ALParticipateContestModal;
