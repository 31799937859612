"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTCollectionImage = void 0;
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
const IDOStatement_1 = require("../IDOStatement");
// 엔딩이 없이는 이미지가 정의되지 않는다. 이걸 어떻게 처리할 것인가?
// 엔딩에서는 결국 imageFileId 를 사용한다. 그리고 이 컬럼은 File 객체를 갖고 있다.
// 따라서 이 객체를 불러올 수 있어야 한다. createImageFile 이 가능하므로 id 를 갖고 있도록 만들 수 있을 것이다.
class DOSTCollectionImage extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: __1.STATEMENT_TYPE.CollectionImage,
            image: __1.ENDING_IMAGE_EMPTY,
            background: '',
        }, block, uniqueId);
        this.error = null;
        this.imageInfo = null;
        this.status = IDOStatement_1.ResourceUploadState.Checking;
        if (!this.st.image) {
            this.status = IDOStatement_1.ResourceUploadState.NoResource;
        }
        else {
            // ctor 에서 하는 경우 문제가 되므로 setImmediate 를 활용
            setImmediate(() => {
                this.block.parentChapter
                    .fetchResource(IDOStatement_1.ResourceType.EndingImage, this.st.image)
                    .then(res => {
                    (0, mobx_1.runInAction)(() => {
                        this.status = res.status;
                        this.imageInfo = res.result;
                        this.error = res.error;
                    });
                });
            });
        }
        (0, mobx_1.makeObservable)(this, {
            status: mobx_1.observable,
            imageInfo: mobx_1.observable,
            error: mobx_1.observable,
        });
    }
    get st() {
        return this.data;
    }
    generateSheetColumnsAfterBackground() {
        var _a, _b, _c;
        const subBlock = this.block.getMySubBlock(this);
        const endingName = (_c = (_b = (_a = subBlock) === null || _a === void 0 ? void 0 : _a.collectionDesc) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : '-';
        return ['', endingName];
    }
}
exports.DOSTCollectionImage = DOSTCollectionImage;
