import {
  ODWebUtils,
  SingleQueryWrapper,
  useODMutation,
  useODQuery,
} from '@odc/od-react-belt'
import React from 'react'
import { Card, CardBody, Col, FormGroup, Input, Label } from 'reactstrap'
import {
  GQLConnectStoryToAchievementProjectInput,
  GQLOkResponse,
  GQLSingleIDInput,
  GQLStory,
} from '../../@types/server'
import { ODButton, ODButtonTheme } from '../../components/base/ODButton'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { useModalConfirm } from '../../components/ODModal/ODModalConfirm'

interface IAchievementConnectContainerProps {
  storyId: number
}

const GQL_GET_STORY_ACHIEVEMENT_PROJ = `
query getStory($data: SingleIDInput!) {
  getStory(data: $data) {
    storyId
    aApiKeyId
    aApiKey
    aProjId
  }
}
`

const GQL_CONNECT_ACHIEVEMENT = `
mutation connectStoryToAchievementProject($data: ConnectStoryToAchievementProjectInput!) {
  connectStoryToAchievementProject(data: $data) {
    ok
  }
}
`

const GQL_DISCONNECT_ACHIEVEMENT = `
mutation disconnectStoryToAchievementProject($data: SingleIDInput!) {
  disconnectStoryToAchievementProject(data: $data) {
    ok
  }
}
`

export const AchievementConnectContainerInternal: React.FC<{
  story: Partial<GQLStory>
  onUpdate: () => void
}> = (props) => {
  const { story, onUpdate } = props
  const apiConnect = useODMutation<
    GQLConnectStoryToAchievementProjectInput,
    GQLOkResponse
  >(GQL_CONNECT_ACHIEVEMENT)
  const apiDisconnect = useODMutation<GQLSingleIDInput, GQLOkResponse>(
    GQL_DISCONNECT_ACHIEVEMENT
  )

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [projId, setProjId] = React.useState<string>(story.aProjId ?? '')
  const isConnected = story.aApiKey !== null

  const {
    Component: Confirm,
    confirm,
    props: confirmProps,
  } = useModalConfirm({
    title: '연동 제거',
    message: (
      <>
        작품에 연동된 업적 프로젝트를 제거합니다. <p />
        계속하시겠습니까?
      </>
    ),
    yes: '연동 제거',
    no: '취소',
  })

  const connect = async () => {
    if (isSubmitting || projId.trim().length === 0 || isConnected) {
      return
    }
    setIsSubmitting(true)

    try {
      await apiConnect({ projId, storyId: story.storyId! })
      onUpdate()
    } catch (ex) {
      ODWebUtils.showError(ex)
    } finally {
      setIsSubmitting(false)
    }
  }

  const disconnect = async () => {
    if (isSubmitting || !isConnected) {
      return
    }
    if (!(await confirm())) {
      return
    }

    setIsSubmitting(true)

    try {
      await apiDisconnect({ id: story.storyId! })
      onUpdate()
    } catch (ex) {
      ODWebUtils.showError(ex)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {isSubmitting && <BlockingLoadBox show />}
      <Confirm {...confirmProps} />
      <Card>
        <CardBody>
          <FormGroup row>
            <Col md={3}>
              <Label htmlFor={`projId`} style={{ paddingTop: 7 }}>
                업적 프로젝트 ID
              </Label>
            </Col>
            <Col xs="12" md={9}>
              <div style={{ display: 'flex', width: '100%' }}>
                <Input
                  value={projId}
                  onChange={(e) => setProjId(e.target.value)}
                />
              </div>
            </Col>
          </FormGroup>
          {!isConnected && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 20,
              }}
            >
              연동정보가 없습니다.&nbsp;&nbsp;
              <a
                href="https://ods.odcode.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                업적 콘솔
              </a>
              에서 &nbsp;
              <span style={{ color: 'darkred' }}>
                스토리플레이 기관 소속 프로젝트
              </span>
              를 생성하고 연동해주세요.
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!isConnected && (
              <ODButton theme={ODButtonTheme.Primary} onClick={connect}>
                연동하기
              </ODButton>
            )}
            {isConnected && (
              <ODButton
                theme={ODButtonTheme.PrimaryInvert}
                onClick={disconnect}
              >
                연동제거
              </ODButton>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export const AchievementConnectContainer: React.FC<
  IAchievementConnectContainerProps
> = (props) => {
  const { storyId } = props
  const apiGetStory = useODQuery<Partial<GQLSingleIDInput>, GQLStory>(
    GQL_GET_STORY_ACHIEVEMENT_PROJ
  )

  return (
    <SingleQueryWrapper
      deps={[storyId]}
      getter={() => apiGetStory({ id: storyId })}
    >
      {({ reload, data }) => {
        return (
          <AchievementConnectContainerInternal story={data} onUpdate={reload} />
        )
      }}
    </SingleQueryWrapper>
  )
}
