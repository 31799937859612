"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryplayToHellobotConverter = void 0;
const core_1 = require("@storyplay/core");
class StoryplayToHellobotConverter {
    // it needs DOStory as its context of conversion
    constructor(rootStore) {
        this.rootStore = rootStore;
    }
    statementToHbMessage(statement) {
        if (statement.statementType === core_1.STATEMENT_TYPE.CharacterTalk) {
            return this.chrTalkStatementToHbMessage(statement);
        }
        throw new Error('statementToHbMessage : unsupported statement type');
    }
    chrTalkStatementToHbMessage(statement) {
        return {
            data: {
                type: 'text',
                text: statement.message,
            },
            turn: statement.sourceLine,
        };
    }
    // null일 경우 여기서 처리안함.
    // Algorithm, HbMessageNext는 블럭이 생성될때 바로 create가 되는게 아닌 생성할 정보를 다 넣고 저장을 해야 생성됨(헬봇기준)
    newStatementToNewHbMessage(doBlock, doStatement, isAlgorithm, turn) {
        if (doStatement.statementType === core_1.STATEMENT_TYPE.CharacterTalk) {
            return this.newCharacterTalkStatementToNewHbMessage(doBlock, doStatement, isAlgorithm, turn);
        }
        if (doStatement.statementType === core_1.STATEMENT_TYPE.MessageImage) {
            return this.newMessageImageStatementToNewHbMessage(doBlock, doStatement, isAlgorithm, turn);
        }
        if (doStatement.statementType === core_1.STATEMENT_TYPE.Algorithm) {
            return null;
        }
        if (doStatement.statementType === core_1.STATEMENT_TYPE.HbMessageNext) {
            return null;
        }
        if (doStatement.statementType === core_1.STATEMENT_TYPE.Pause) {
            return null;
        }
        throw new Error('newStatementToHbMessage : Statement type is not supported. ' +
            doStatement.statementType);
    }
    newCharacterTalkStatementToNewHbMessage(doBlock, doStatement, isAlgorithm, turn) {
        var _a, _b, _c;
        const speakerId = (_a = doStatement.chr) === null || _a === void 0 ? void 0 : _a.id;
        const speakerName = (_b = doStatement.chr) === null || _b === void 0 ? void 0 : _b.chrName;
        const speakerImage = (_c = doStatement.chr) === null || _c === void 0 ? void 0 : _c.link;
        const id = doStatement.block
            .hbExtensionData.id;
        const sheetId = doStatement.block
            .hbExtensionData.sheetId;
        return {
            data: {
                type: 'text',
                text: '',
            },
            kind: 'chatbot',
            senderId: speakerId,
            senderName: speakerName,
            senderProfileUrl: speakerImage !== null && speakerImage !== void 0 ? speakerImage : undefined,
            ...(isAlgorithm
                ? {
                    algorithmId: id,
                }
                : {
                    sheetId,
                    turn,
                }),
        };
    }
    newMessageImageStatementToNewHbMessage(doBlock, doStatement, isAlgorithm, turn) {
        var _a, _b, _c;
        const speakerId = (_a = doStatement.chr) === null || _a === void 0 ? void 0 : _a.id;
        const speakerName = (_b = doStatement.chr) === null || _b === void 0 ? void 0 : _b.chrName;
        const speakerImage = (_c = doStatement.chr) === null || _c === void 0 ? void 0 : _c.link;
        const messageImageStatement = doStatement.st;
        const id = doStatement.block
            .hbExtensionData.id;
        const sheetId = doStatement.block
            .hbExtensionData.sheetId;
        return {
            data: {
                type: 'image',
                imageId: messageImageStatement.studioFileId, // studioFileId는 새 코드에서는 언제나 존재한다.
            },
            kind: 'chatbot',
            senderId: speakerId,
            senderName: speakerName,
            senderProfileUrl: speakerImage !== null && speakerImage !== void 0 ? speakerImage : undefined,
            ...(isAlgorithm
                ? {
                    algorithmId: id,
                }
                : {
                    sheetId,
                    turn,
                }),
        };
    }
    calculateNewStatementTurn(doBlock, doStatement) {
        var _a;
        const turn = doBlock.statements.findIndex(target => target.sourceLine === doStatement.sourceLine);
        const lastTurn = ((_a = doBlock.statements.length) !== null && _a !== void 0 ? _a : 0) - 1;
        return lastTurn;
    }
}
exports.StoryplayToHellobotConverter = StoryplayToHellobotConverter;
