"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbMediaApi = void 0;
class HbMediaApi {
    constructor(server) {
        this.server = server;
    }
    async getCategories(botId) {
        return this.server.get('/v1/images/category', botId
            ? {
                botId,
            }
            : {});
    }
    async getItems(listConfig) {
        return this.server.get('/v1/images', listConfig !== null && listConfig !== void 0 ? listConfig : {});
    }
    async getItem(id) {
        return this.server.get(`/v1/images/${id}`);
    }
    async createItems(files, useLibrary = false) {
        const formData = new FormData();
        formData.append('useLibrary', String(useLibrary));
        files.forEach(f => formData.append('files', f));
        return this.server.post(`/v1/images`, formData);
    }
}
exports.HbMediaApi = HbMediaApi;
