"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertStatement = void 0;
const core_1 = require("@storyplay/core");
const models_1 = require("../../../../models");
const DOSTFullWidthText_1 = require("./script/DOSTFullWidthText");
const DOSTLeftTalk_1 = require("./script/DOSTLeftTalk");
const DOSTLeftThink_1 = require("./script/DOSTLeftThink");
const DOSTRightTalk_1 = require("./script/DOSTRightTalk");
const DOSTRightThink_1 = require("./script/DOSTRightThink");
const DOSTScript_1 = require("./script/DOSTScript");
const DOSTToast_1 = require("./script/DOSTToast");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
function convertStatement(editor, source, inputIndex) {
    var _a, _b, _c, _d, _e;
    const isTalk = [
        models_1.STATEMENT_TYPE.MainCharacterTalk,
        models_1.STATEMENT_TYPE.CharacterTalk,
    ].includes(source.statementType);
    switch (inputIndex) {
        case 0: {
            if ([models_1.STATEMENT_TYPE.CharacterTalk, models_1.STATEMENT_TYPE.CharacterThink].includes(source.statementType)) {
                editor.replaceCurrentStatement(isTalk
                    ? new DOSTLeftThink_1.DOSTLeftThink({
                        ...source.data,
                        statementType: models_1.STATEMENT_TYPE.CharacterThink,
                    }, source.block)
                    : new DOSTLeftTalk_1.DOSTLeftTalk({
                        ...source.data,
                        statementType: models_1.STATEMENT_TYPE.CharacterTalk,
                    }, source.block));
            }
            else if ([
                models_1.STATEMENT_TYPE.Script,
                models_1.STATEMENT_TYPE.FullWidthText,
                models_1.STATEMENT_TYPE.Toast,
                models_1.STATEMENT_TYPE.MainCharacterTalk,
                models_1.STATEMENT_TYPE.MainCharacterThink,
            ].includes(source.statementType)) {
                let selectedChr = editor.input.selectedCharacter;
                if (selectedChr.isHero) {
                    selectedChr = editor.input.characterList.find(v => !v.isHero);
                }
                if (!selectedChr) {
                    editor.chapterEditing.store.rootStore.di.showError(trans('legacy_convertStatement_no_auxiliary_character'));
                    return;
                }
                const message = (_a = source.message) !== null && _a !== void 0 ? _a : '';
                editor.replaceCurrentStatement(new DOSTLeftTalk_1.DOSTLeftTalk({
                    message,
                    ...source.data,
                    chrName: selectedChr.name,
                    statementType: models_1.STATEMENT_TYPE.CharacterTalk,
                }, source.block));
            }
            break;
        }
        case 1:
            {
                if ([
                    models_1.STATEMENT_TYPE.MainCharacterTalk,
                    models_1.STATEMENT_TYPE.MainCharacterThink,
                    models_1.STATEMENT_TYPE.CharacterThink,
                    models_1.STATEMENT_TYPE.CharacterTalk,
                    models_1.STATEMENT_TYPE.Toast,
                ].includes(source.statementType)) {
                    editor.replaceCurrentStatement(new DOSTScript_1.DOSTScript({
                        message: (_b = source.data.message) !== null && _b !== void 0 ? _b : '',
                        ...source.data,
                        statementType: models_1.STATEMENT_TYPE.Script,
                    }, source.block));
                }
                else if (source.statementType === models_1.STATEMENT_TYPE.Script) {
                    editor.replaceCurrentStatement(new DOSTFullWidthText_1.DOSTFullWidthText({
                        message: (_c = source.data.message) !== null && _c !== void 0 ? _c : '',
                        ...source.data,
                        statementType: models_1.STATEMENT_TYPE.FullWidthText,
                    }, source.block));
                }
                else if (source.statementType === models_1.STATEMENT_TYPE.FullWidthText) {
                    editor.replaceCurrentStatement(new DOSTToast_1.DOSTToast({
                        message: (_d = source.data.message) !== null && _d !== void 0 ? _d : '',
                        toastOption: DOSTToast_1.TOAST_OPTION.NoIcon,
                        ...source.data,
                        statementType: models_1.STATEMENT_TYPE.Toast,
                    }, source.block));
                }
                // TODO:
            }
            break;
        case 2: {
            if ([
                models_1.STATEMENT_TYPE.MainCharacterTalk,
                models_1.STATEMENT_TYPE.MainCharacterThink,
            ].includes(source.statementType)) {
                editor.replaceCurrentStatement(isTalk
                    ? new DOSTRightThink_1.DOSTRightThink({
                        ...source.data,
                        statementType: models_1.STATEMENT_TYPE.MainCharacterThink,
                    }, source.block)
                    : new DOSTRightTalk_1.DOSTRightTalk({
                        ...source.data,
                        statementType: models_1.STATEMENT_TYPE.MainCharacterTalk,
                    }, source.block));
            }
            else if ([
                models_1.STATEMENT_TYPE.Script,
                models_1.STATEMENT_TYPE.FullWidthText,
                models_1.STATEMENT_TYPE.Toast,
                models_1.STATEMENT_TYPE.CharacterThink,
                models_1.STATEMENT_TYPE.CharacterTalk,
            ].includes(source.statementType)) {
                const message = (_e = source.message) !== null && _e !== void 0 ? _e : '';
                editor.replaceCurrentStatement(new DOSTRightTalk_1.DOSTRightTalk({
                    message,
                    ...source.data,
                    chrName: editor.chapterEditing.getStory().mainCharacter.name,
                    statementType: models_1.STATEMENT_TYPE.MainCharacterTalk,
                }, source.block));
            }
            break;
        }
    }
}
exports.convertStatement = convertStatement;
