import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

type ODTabBarButtonProps = {
  active?: boolean
  onClick?: () => void
  tabProps?: Partial<ButtonProps>
}

type ButtonProps = {
  width?: string
  height?: string
  borderRadius?: string
  active?: boolean
  color?: string
}

const BoxActive = styled.div`
  width: ${(p: ButtonProps) => (p.width ? p.width + 'px' : 'auto')};
  height: ${(p: ButtonProps) => p.height || 47}px;
  border-top-left-radius: ${(p: ButtonProps) => p.borderRadius || 4}px;
  border-top-right-radius: ${(p: ButtonProps) => p.borderRadius || 4}px;
  border: 1px solid #c8ced2;
  border-bottom-width: ${(p: ButtonProps) => (p.active ? 0 : 1)}px;
  background-color: ${(p: ButtonProps) => (p.active ? '#ffffff' : '#f0f3f5')};
  border-top: ${(p: ButtonProps) => (p.active ? `4px solid ${p.color || COLORS.Primary}` : '1px solid #c8ced2')};
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  cursor: ${(p: ButtonProps) => (p.active ? 'auto' : 'pointer')};
`

const tabBarClassName = 'od-tabbar-button'

export const ODTabBarButton: React.FC<ODTabBarButtonProps> = ({ tabProps, onClick, active, children }) => {
  return (
    <BoxActive active={!!active} className={tabBarClassName} onClick={onClick} {...tabProps}>
      {children}
    </BoxActive>
  )
}

const Container = styled.div`
  display: flex;

  .${tabBarClassName} {
    margin-right: 4px;
  }

  position: relative;
  top: 1px;
  z-index: 10; // should be higher than the container below.
  overflow-x: scroll;
`

export const ODTabBarContainer: React.FC = ({ children }) => {
  return <Container>{children}</Container>
}
