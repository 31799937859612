import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLChapter } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IChapterListableOption } from '../ChapterCommon'

export function useChapterListDataLoader(storyId: number) {
  const { listChapterOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IChapterListableOption
    ): Promise<ODListableResponseType<GQLChapter>> {
      const r = await listChapterOfStory({
        page,
        pageSize: 100,
        storyId,
        ...options,
        sortOption: null,
      })
      return r as ODListableResponseType<GQLChapter>
    },
    [listChapterOfStory, storyId]
  )
}
