"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelectionInput = void 0;
const mobx_1 = require("mobx");
const DEFAULT_PAGE_INDEX = 1;
const DEFAULT_PAGE_SIZE = 30;
class MultiSelectionInput {
    constructor(id, label, placeholder, selectedOptions, options, extra) {
        var _a, _b, _c, _d, _e, _f, _g;
        this.inputText = '';
        this.inputTextBefore = '';
        this.isOptionsLoading = false;
        this.isCreatable = false;
        this.required = false;
        this.disabled = false;
        this.isSingleValue = false;
        this.theme = 'gray';
        this._onChange = null;
        this.id = id;
        this.label = label;
        this.selectedOptions = selectedOptions;
        this.options = options;
        this.placeholder = placeholder;
        this.extra = extra;
        this.pageIndexForOptions = (_a = extra === null || extra === void 0 ? void 0 : extra.pageIndexForOptions) !== null && _a !== void 0 ? _a : DEFAULT_PAGE_INDEX;
        this.pageSizeForOptions = (_b = extra === null || extra === void 0 ? void 0 : extra.pageSizeForOptions) !== null && _b !== void 0 ? _b : DEFAULT_PAGE_SIZE;
        this.hasMoreOptions = !!(extra === null || extra === void 0 ? void 0 : extra.onLoadMoreOptions);
        this.isCreatable = !!(extra === null || extra === void 0 ? void 0 : extra.onCreate);
        this.required = (_c = extra === null || extra === void 0 ? void 0 : extra.required) !== null && _c !== void 0 ? _c : false;
        this.disabled = (_d = extra === null || extra === void 0 ? void 0 : extra.disabled) !== null && _d !== void 0 ? _d : false;
        this.isSingleValue = (_e = extra === null || extra === void 0 ? void 0 : extra.isSingleValue) !== null && _e !== void 0 ? _e : false;
        this.theme = (_f = extra === null || extra === void 0 ? void 0 : extra.theme) !== null && _f !== void 0 ? _f : 'gray';
        this.validate = extra === null || extra === void 0 ? void 0 : extra.validate;
        this._onChange = (_g = extra === null || extra === void 0 ? void 0 : extra.onChange) !== null && _g !== void 0 ? _g : null;
        (0, mobx_1.makeObservable)(this, {
            selectedOptions: mobx_1.observable,
            options: mobx_1.observable,
            pageIndexForOptions: mobx_1.observable,
            inputTextBefore: mobx_1.observable,
            hasMoreOptions: mobx_1.observable,
            isOptionsLoading: mobx_1.observable,
            inputText: mobx_1.observable,
            disabled: mobx_1.observable,
            value: mobx_1.computed,
        });
    }
    getIsValid(value) {
        if (this.required && !this.disabled) {
            if (!!this.validate) {
                return this.validate() && value.length > 0;
            }
            return value.length > 0;
        }
        return true;
    }
    onChangeDisabled(disabled) {
        (0, mobx_1.runInAction)(() => {
            this.disabled = disabled;
        });
    }
    onChangeInputText(text) {
        (0, mobx_1.runInAction)(() => {
            this.inputText = text;
        });
    }
    get value() {
        return this.selectedOptions.map(option => option.value);
    }
    onChangeSelectedOptions(newOptions) {
        var _a;
        (0, mobx_1.runInAction)(() => {
            this.selectedOptions = newOptions;
        });
        (_a = this._onChange) === null || _a === void 0 ? void 0 : _a.call(this, newOptions);
    }
    async onCreate(inputText) {
        var _a, _b;
        await ((_b = (_a = this.extra) === null || _a === void 0 ? void 0 : _a.onCreate) === null || _b === void 0 ? void 0 : _b.call(_a, inputText));
        await this.loadMoreOptions(true);
    }
    async loadMoreOptions(forceReset) {
        var _a, _b;
        if (!((_a = this.extra) === null || _a === void 0 ? void 0 : _a.onLoadMoreOptions)) {
            return;
        }
        if (this.isOptionsLoading) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            this.isOptionsLoading = true;
        });
        const isReset = this.inputText !== this.inputTextBefore || !!forceReset;
        if (isReset) {
            (0, mobx_1.runInAction)(() => {
                var _a, _b;
                this.pageIndexForOptions =
                    (_b = (_a = this.extra) === null || _a === void 0 ? void 0 : _a.pageIndexForOptions) !== null && _b !== void 0 ? _b : DEFAULT_PAGE_INDEX;
                this.hasMoreOptions = true;
                this.options = [];
            });
        }
        const res = await this.extra.onLoadMoreOptions(this.pageIndexForOptions, isReset ? this.inputText : this.inputTextBefore);
        // nextBlcok 새로 생성 크리에이트 호출이 되었다. 생성이 되었어, 생성이 되었어
        if (!!((_b = res[0]) === null || _b === void 0 ? void 0 : _b.category)) {
            res.forEach(item => {
                if (this.options.length === 0) {
                    (0, mobx_1.runInAction)(() => {
                        var _a;
                        this.options = [{ label: (_a = item.category) !== null && _a !== void 0 ? _a : '-', options: [item] }];
                    });
                }
                else {
                    const lastOption = this.options[this.options.length - 1];
                    if ('label' in lastOption && lastOption.label === item.category) {
                        (0, mobx_1.runInAction)(() => {
                            lastOption.options = [...lastOption.options, item];
                        });
                    }
                    else {
                        (0, mobx_1.runInAction)(() => {
                            var _a;
                            this.options = [
                                ...this.options,
                                {
                                    label: (_a = item.category) !== null && _a !== void 0 ? _a : '-',
                                    options: [item],
                                },
                            ];
                        });
                    }
                }
            });
        }
        else {
            (0, mobx_1.runInAction)(() => {
                this.options = [...this.options, ...res];
            });
        }
        if (res.length < this.pageSizeForOptions) {
            (0, mobx_1.runInAction)(() => {
                this.hasMoreOptions = false;
            });
        }
        else {
            (0, mobx_1.runInAction)(() => {
                this.pageIndexForOptions = this.pageIndexForOptions + 1;
                this.hasMoreOptions = true;
            });
        }
        (0, mobx_1.runInAction)(() => {
            this.isOptionsLoading = false;
            this.inputTextBefore = this.inputText;
        });
    }
    onChangeOptions(newOptions) {
        (0, mobx_1.runInAction)(() => {
            this.options = newOptions;
        });
    }
}
exports.MultiSelectionInput = MultiSelectionInput;
