"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputWithValidation = void 0;
const mobx_1 = require("mobx");
const errors_1 = require("../../../../errors");
const IInputBase_1 = require("./IInputBase");
const IInputWithValidation_1 = require("./IInputWithValidation");
function getErrorCodeWithValidation(v, validation) {
    try {
        validation === null || validation === void 0 ? void 0 : validation.validateSync(v);
        return null;
    }
    catch (ex) {
        const code = parseInt(ex.message, 10);
        if (code.toString() === ex.message) {
            return code;
        }
        // console.error(104, ex)
        return errors_1.ErrorCode.FATAL;
    }
}
class InputWithValidation {
    constructor(id, label, placeholder, inputType, defValue, validation = null, options = {}) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        this.inputModelType = IInputBase_1.InputModelType.InputWithValidation;
        this.editable = true;
        this.hasFocus = false;
        this.focusedAndBlurred = false;
        this.autoComplete = undefined;
        this.required = false;
        this.autoFocus = undefined;
        this.isEditing = false;
        this.maxLength = undefined;
        this.showMaxLength = false;
        this.minValueIfNumberType = undefined;
        this.maxValueIfNumberType = undefined;
        this.testId = undefined;
        this.id = id;
        this.label = label;
        this.placeholder = placeholder;
        this.defValue = defValue;
        this.value = defValue;
        this.validation = validation;
        this.inputType = inputType;
        this._bottomText = options.bottomText;
        this.placeholderAsBottomText = (_a = options.placeholderAsBottomText) !== null && _a !== void 0 ? _a : false;
        this.leaveBottomTextPlaceAlways =
            (_b = options.leaveBottomTextPlaceAlways) !== null && _b !== void 0 ? _b : false;
        this.inPlaceEditingEnabled = (_c = options.enableInPlaceEditing) !== null && _c !== void 0 ? _c : false;
        this.isEditing = false;
        this.noLabel = (_d = options.noLabel) !== null && _d !== void 0 ? _d : false;
        this.onSubmit = options.onSubmit;
        this.maxLength = options.maxLength;
        this.editable = (_e = options.editable) !== null && _e !== void 0 ? _e : true;
        this.showMaxLength = (_f = options.showMaxLength) !== null && _f !== void 0 ? _f : false;
        this.minValueIfNumberType = (_g = options.minValueIfNumberType) !== null && _g !== void 0 ? _g : undefined;
        this.maxValueIfNumberType = (_h = options.maxValueIfNumberType) !== null && _h !== void 0 ? _h : undefined;
        this.testId = (_j = options.testId) !== null && _j !== void 0 ? _j : undefined;
        this.required = (_k = options.required) !== null && _k !== void 0 ? _k : false;
        this.options = options;
        (0, mobx_1.makeObservable)(this, {
            value: mobx_1.observable,
            editable: mobx_1.observable,
            autoComplete: mobx_1.observable,
            autoFocus: mobx_1.observable,
            required: mobx_1.observable,
            hasFocus: mobx_1.observable,
            focusedAndBlurred: mobx_1.observable,
            inPlaceEditingEnabled: mobx_1.observable,
            isEditing: mobx_1.observable,
            noLabel: mobx_1.observable,
            onChange: mobx_1.action,
            isValueEntered: mobx_1.computed,
            validationError: mobx_1.computed,
            bottomText: mobx_1.computed,
            theme: mobx_1.computed,
            disabled: mobx_1.computed,
        });
    }
    get disabled() {
        return !this.editable;
    }
    getIsValid(newValue) {
        if (!!this.validation) {
            try {
                this.validation.validateSync(newValue);
                return true;
            }
            catch {
                return false;
            }
        }
        return true;
    }
    onChange(newValue) {
        var _a, _b, _c, _d;
        if (this.inputType === 'number') {
            // @ts-ignore
            let v = parseInt(newValue, 10);
            if (isNaN(v)) {
                v = 0;
            }
            // @ts-ignore
            this.value = v;
            (_b = (_a = this.options).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, v);
            return;
        }
        this.value = newValue;
        (_d = (_c = this.options).onChange) === null || _d === void 0 ? void 0 : _d.call(_c, newValue);
    }
    get isValueEntered() {
        // @ts-ignore
        return this.value.toString() !== '';
    }
    get bottomText() {
        if (this.placeholderAsBottomText &&
            this.placeholder &&
            this.isValueEntered) {
            return this.placeholder;
        }
        return this._bottomText;
    }
    get validationError() {
        // blur 된 이후에만 오류를 보여준다.
        if (!this.focusedAndBlurred) {
            return null;
        }
        const errorCode = getErrorCodeWithValidation(this.value, this.validation);
        if (errorCode) {
            return new errors_1.SPCError(errorCode);
        }
        return null;
    }
    onFocus() {
        (0, mobx_1.runInAction)(() => {
            this.hasFocus = true;
        });
    }
    onBlur() {
        var _a, _b;
        (_b = (_a = this.options).onBlur) === null || _b === void 0 ? void 0 : _b.call(_a);
        // unmount 시점에 onBlur 가 일어나는 경우 버튼이 안눌려지는 등의 문제가 생겨 약간의 딜레이
        setTimeout(() => {
            (0, mobx_1.runInAction)(() => {
                this.focusedAndBlurred = true;
                this.hasFocus = false;
            });
        }, 300);
    }
    prepareSubmit() {
        (0, mobx_1.runInAction)(() => {
            this.focusedAndBlurred = true;
            this.hasFocus = false;
        });
        return this.validationError === null;
    }
    resetWithValue(v) {
        (0, mobx_1.runInAction)(() => {
            this.value = v;
            this.hasFocus = false;
            this.focusedAndBlurred = false;
        });
    }
    setAutoComplete(c) {
        (0, mobx_1.runInAction)(() => (this.autoComplete = c));
        return this;
    }
    setRequired(c) {
        (0, mobx_1.runInAction)(() => (this.required = c));
        return this;
    }
    setAutoFocus() {
        (0, mobx_1.runInAction)(() => (this.autoFocus = true));
        return this;
    }
    setEditable(e) {
        (0, mobx_1.runInAction)(() => (this.editable = e));
        return this;
    }
    setEditing(editing) {
        var _a;
        (0, mobx_1.runInAction)(() => {
            // TODO: 오류가 있는 상태라면?
            this.isEditing = editing;
        });
        // in-place-editing 일 때에만 editing 이 false -> true 일 때 submit 호출.
        if (!editing && this.options.enableInPlaceEditing) {
            (_a = this.onSubmit) === null || _a === void 0 ? void 0 : _a.call(this, this.value);
        }
    }
    revertChange() {
        (0, mobx_1.runInAction)(() => {
            this.value = this.defValue;
        });
    }
    get theme() {
        var _a;
        return (_a = this.options.theme) !== null && _a !== void 0 ? _a : IInputWithValidation_1.InputTheme.Default;
    }
    get textareaTheme() {
        var _a;
        return (_a = this.options.textareaTheme) !== null && _a !== void 0 ? _a : IInputWithValidation_1.TextareaTheme.FullWidthText;
    }
    get hasChange() {
        return this.defValue !== this.value;
    }
}
exports.InputWithValidation = InputWithValidation;
