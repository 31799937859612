import {
  BlockingLoadBox,
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import { GQLOnboardingPopupProfileImage } from '@storyplay/core'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { STATUS } from '../../../common'
import { HoverTR } from '../../../components/commonStyle'
import { OnboardingPopupProfileImageModal } from '../OnboardingPopupPorfileImageModal'
import {
  OnboardingPopupProfileImagePrimaryKey,
  IOnboardingPopupProfileImageListableOption,
} from '../OnboardingPopupProfileImageCommon'
import { useOnboardingPopupProfileImageListDataLoader } from './OnboardingPopupProfileImageListDataLoader'

interface IOnboardingPopupProfileImageListableTableProps {
  storyId: number
  popupId: number
}

export const OnboardingPopupProfileImageListableTable: React.FC<
  IOnboardingPopupProfileImageListableTableProps
> = (props) => {
  const { popupId, storyId } = props
  const [loading] = React.useState(false)
  const { Provider, Context } = useODListableContext<
    GQLOnboardingPopupProfileImage,
    IOnboardingPopupProfileImageListableOption
  >()
  const [token, refresh] = useCounter()
  const [
    showOnboardingPopupProfileImageModal,
    setShowOnboardingPopupProfileImageModal,
  ] = React.useState(false)
  const [popupProfileImageId, setPopupProfileImageId] =
    React.useState<number>(0)
  const dataLoader = useOnboardingPopupProfileImageListDataLoader(popupId)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[OnboardingPopupProfileImagePrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
              float: 'right',
              marginLeft: 15,
            }}
          >
            <ODButton
              theme={ODButtonTheme.Primary}
              size={ODButtonSize.Small}
              style={{ minWidth: 135, borderRadius: 5, height: 35 }}
              onClick={() => {
                setShowOnboardingPopupProfileImageModal(true)
                setPopupProfileImageId(0)
              }}
            >
              기본 프로필 이미지 등록
            </ODButton>
          </div>
          <ODListablePaginatedTable2
            numColumns={7}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>프로필 이미지 아이디</th>
                <th>사진</th>
                <th>지문</th>
                <th>노출 우선순위</th>
                <th>활성 여부</th>
              </tr>
            )}
            renderRow={(value: GQLOnboardingPopupProfileImage, context) => {
              const StatusName =
                {
                  [STATUS.Active]: '활성',
                  [STATUS.Inactive]: '비활성',
                  [STATUS.Deleted]: '삭제',
                  [STATUS.Disabled]: '선택 불가',
                }[value.status] || ''
              return (
                <HoverTR
                  key={value.popupProfileImageId}
                  onClick={() => {
                    setShowOnboardingPopupProfileImageModal(true)
                    setPopupProfileImageId(value.popupProfileImageId)
                  }}
                >
                  <td>{value.popupProfileImageId}</td>
                  <td>
                    <img
                      src={value.profileImageFile!.link}
                      width={100}
                      height={100}
                      alt="profile image"
                    />
                  </td>
                  <td>{value.description}</td>
                  <td>{value.showOrder}</td>
                  <td>{StatusName}</td>
                </HoverTR>
              )
            }}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
          {showOnboardingPopupProfileImageModal && (
            <OnboardingPopupProfileImageModal
              storyId={storyId}
              popupId={popupId}
              popupProfileImageId={popupProfileImageId}
              onClose={() => setShowOnboardingPopupProfileImageModal(false)}
              onConfirm={() => {
                setShowOnboardingPopupProfileImageModal(false)
                refresh()
              }}
            />
          )}
        </CardBody>
      </Card>
    </Provider>
  )
}
