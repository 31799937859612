"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STUDIO_STORY_PRICE_SETTING_TYPE_TRANSLATED = exports.BANNER_STATE_TRANSLATED = exports.BANNER_LINK_TYPE_TRANSLATED = exports.BANNER_LOCATION_TRANSLATED = exports.CLIENT_TRANSLATED = exports.BANNER_TYPE_TRANSLATED = exports.LANGUAGE_CODE_TRANSLATED = exports.STORY_PLAY_TYPE_TRANSLATED = exports.TRANSITION_STYLE_TRANSLATED = exports.STORYGAME_SCREEN_FILL_METHOD_TRANSLATED = exports.SCREEN_FILL_METHOD_TRANSLATED = exports.STORY_PROP_TYPE_TRANSLATED = exports.STORY_WEEKDAY_TRANSLATED = exports.REMOTE_SCRIPT_TYPE_TRANSLATED = exports.STORY_GENRE_SUBTITLE_TRANSLATED = exports.STORY_GENRE_TRANSLATED = exports.STORY_GENRE_TITLE_TRANSLATED = void 0;
/**
 * 스튜디오 웹에 보여질 모든 텍스트는 해당 파일에서 관리하며,
 * 접미사로 TRANSLATED 를 붙인다.
 */
const server_schema_1 = require("../@types/server.schema");
const const_1 = require("../const/const");
const price_1 = require("../const/price");
const script_1 = require("../script");
const I18nText_1 = require("./I18nText");
const { trans } = (0, I18nText_1.i18nTextTranslationByClass)();
exports.STORY_GENRE_TITLE_TRANSLATED = {
    [const_1.CHALLENGE_STORY_GENRE.CONTEST_WINNER]: trans('const.ts_contest_winners'),
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE]: trans('const.ts_romance_story'),
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: trans('const.ts_romance_fantasy_story'),
    [const_1.CHALLENGE_STORY_GENRE.FANTASY]: trans('const.ts_fantasy_signboard_story'),
    [const_1.CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: trans('const.ts_mystery_thriller_horror_story'),
    [const_1.CHALLENGE_STORY_GENRE.UNKNOWN]: trans('const.ts_unclassical_story'),
    [const_1.CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: trans('const.ts_simulation_story'),
    [const_1.CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: trans('const.ts_martial_arts_alternative_history_story'),
    [const_1.CHALLENGE_STORY_GENRE.BL_GL]: trans('const.ts_bl_gl_story'),
    [const_1.CHALLENGE_STORY_GENRE.FAN_FICTION]: trans('const.ts_fan_pick_parody_story'),
    [const_1.CHALLENGE_STORY_GENRE.ETC]: trans('const.ts_other_stories'),
    // deprecated
    [const_1.CHALLENGE_STORY_GENRE.MODERN_FANTASY]: trans('const.ts_modern_hyundai_fantasy_story'),
};
exports.STORY_GENRE_TRANSLATED = {
    [const_1.CHALLENGE_STORY_GENRE.CONTEST_WINNER]: trans('const.ts_contest_winners'),
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE]: trans('const.ts_romance'),
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: trans('const.ts_romance_fantasy'),
    [const_1.CHALLENGE_STORY_GENRE.FANTASY]: trans('const.ts_fantasy_signboard'),
    [const_1.CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: trans('const.ts_survey_thriller_horror'),
    [const_1.CHALLENGE_STORY_GENRE.UNKNOWN]: trans('const.ts_unclassion'),
    [const_1.CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: trans('const.ts_simulation'),
    [const_1.CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: trans('const.ts_martial_arts_alternative_history'),
    [const_1.CHALLENGE_STORY_GENRE.BL_GL]: trans('const.ts_bl_gl'),
    [const_1.CHALLENGE_STORY_GENRE.FAN_FICTION]: trans('const.ts_fanpic_parody'),
    [const_1.CHALLENGE_STORY_GENRE.ETC]: trans('const.ts_etc'),
    // deprecated
    [const_1.CHALLENGE_STORY_GENRE.MODERN_FANTASY]: trans('const.ts_modern_hyundai_fantasy'),
};
exports.STORY_GENRE_SUBTITLE_TRANSLATED = {
    [const_1.CHALLENGE_STORY_GENRE.CONTEST_WINNER]: trans('const.ts_1st_interactive_story_contest_winners'),
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE]: trans('const.ts_exciting_immersive_romance_works'),
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: trans('const.ts_romance_continues_in_this_world'),
    [const_1.CHALLENGE_STORY_GENRE.FANTASY]: trans('const.ts_adventure_to_another_world'),
    [const_1.CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: trans('const.ts_breathtaking_tension_digging_truth'),
    [const_1.CHALLENGE_STORY_GENRE.UNKNOWN]: trans('const.ts_unclassion'),
    [const_1.CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: trans('const.ts_i_am_the_main_character'),
    [const_1.CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: trans('const.ts_rewriting_history_main_character_is_me'),
    [const_1.CHALLENGE_STORY_GENRE.BL_GL]: trans('const.ts_straight_to_the_hot_story_if_you_have_love'),
    [const_1.CHALLENGE_STORY_GENRE.FAN_FICTION]: trans('const.ts_fan_pick_parody_story'),
    [const_1.CHALLENGE_STORY_GENRE.ETC]: trans('const.ts_meet_fresh_genres'),
    // deprecated
    [const_1.CHALLENGE_STORY_GENRE.MODERN_FANTASY]: trans('const.ts_other_stories_in_our_world'),
};
exports.REMOTE_SCRIPT_TYPE_TRANSLATED = {
    [script_1.REMOTE_SCRIPT_TYPE.HelloBot]: trans('const.ts_hello_bot'),
    [script_1.REMOTE_SCRIPT_TYPE.StoryPlay]: trans('const.ts_story_play'),
    [script_1.REMOTE_SCRIPT_TYPE.ChatGPT]: 'ChatGPT',
};
exports.STORY_WEEKDAY_TRANSLATED = {
    [server_schema_1.GQLSTORY_WEEKDAY.Monday]: trans('const.ts_monday'),
    [server_schema_1.GQLSTORY_WEEKDAY.Tuesday]: trans('const.ts_tuesday'),
    [server_schema_1.GQLSTORY_WEEKDAY.Wednesday]: trans('const.ts_wednesday'),
    [server_schema_1.GQLSTORY_WEEKDAY.Thursday]: trans('const.ts_thursday'),
    [server_schema_1.GQLSTORY_WEEKDAY.Friday]: trans('const.ts_friday'),
    [server_schema_1.GQLSTORY_WEEKDAY.Saturday]: trans('const.ts_saturday'),
    [server_schema_1.GQLSTORY_WEEKDAY.Sunday]: trans('const.ts_sunday'),
};
exports.STORY_PROP_TYPE_TRANSLATED = {
    [server_schema_1.GQLSTORY_PROP_TYPE.STRING]: trans('const.ts_text'),
    [server_schema_1.GQLSTORY_PROP_TYPE.NUMBER]: trans('const.ts_number'),
};
exports.SCREEN_FILL_METHOD_TRANSLATED = {
    [script_1.ScreenFillMethod.Stretch]: trans('const.ts_fill_the_whole'),
    [script_1.ScreenFillMethod.AspectRatio]: trans('const.ts_maintaining_ratio'),
};
// TODO: localize
exports.STORYGAME_SCREEN_FILL_METHOD_TRANSLATED = {
    [script_1.StorygameScreenFillMethod.Stretch]: trans('const.ts_fill_the_whole'),
    [script_1.StorygameScreenFillMethod.AspectRatioFill]: '비율 유지하며 채우기',
    [script_1.StorygameScreenFillMethod.AspectRatioFit]: '비율 유지하며 화면 맞추기',
};
exports.TRANSITION_STYLE_TRANSLATED = {
    [script_1.TransitionStyle.None]: trans('const.ts_doesnt_exist'),
    [script_1.TransitionStyle.Fade]: script_1.TransitionStyle.Fade,
    [script_1.TransitionStyle.Zoom]: script_1.TransitionStyle.Zoom,
};
exports.STORY_PLAY_TYPE_TRANSLATED = {
    [server_schema_1.GQLSTORY_PLAY_TYPE.EPUB]: trans('const.ts_web_novel'),
    [server_schema_1.GQLSTORY_PLAY_TYPE.Interactive]: trans('const.ts_interactive'),
    [server_schema_1.GQLSTORY_PLAY_TYPE.Storygame]: trans('const.ts_storygame'),
};
exports.LANGUAGE_CODE_TRANSLATED = {
    [server_schema_1.GQLLANGUAGE_CODE.ko]: trans('const.ts_korea'),
    [server_schema_1.GQLLANGUAGE_CODE.en]: trans('const.ts_global_english'),
};
exports.BANNER_TYPE_TRANSLATED = {
    [server_schema_1.GQLBANNER_TYPE.Story]: trans('const.ts_registration'),
    [server_schema_1.GQLBANNER_TYPE.Custom]: trans('const.ts_direct_registration'),
};
exports.CLIENT_TRANSLATED = {
    [server_schema_1.GQLCLIENT.App]: trans('const.ts_app'),
    [server_schema_1.GQLCLIENT.Web]: trans('const.ts_web'),
};
exports.BANNER_LOCATION_TRANSLATED = {
    [server_schema_1.GQLBANNER_LOCATION.Home]: trans('const.ts_home'),
    [server_schema_1.GQLBANNER_LOCATION.EPUB]: trans('const.ts_web_novel'),
    [server_schema_1.GQLBANNER_LOCATION.Interactive]: trans('const.ts_interactive'),
};
exports.BANNER_LINK_TYPE_TRANSLATED = {
    [server_schema_1.GQLBANNER_LINK_TYPE.DeepLink]: trans('const.ts_deep_link'),
    [server_schema_1.GQLBANNER_LINK_TYPE.AppExternalBrowserLink]: trans('const.ts_external_browser'),
    [server_schema_1.GQLBANNER_LINK_TYPE.AppInappBrowserLink]: trans('const.ts_in_app_browser'),
    [server_schema_1.GQLBANNER_LINK_TYPE.WebCurrentWindowLink]: trans('const.ts_floating'),
    [server_schema_1.GQLBANNER_LINK_TYPE.WebNewWindowLink]: trans('const.ts_float'),
};
exports.BANNER_STATE_TRANSLATED = {
    [server_schema_1.GQLBANNER_STATE.On]: trans('const.ts_turn_on'),
    [server_schema_1.GQLBANNER_STATE.Off]: trans('const.ts_off'),
    [server_schema_1.GQLBANNER_STATE.Reserved]: trans('const.ts_booking'),
    [server_schema_1.GQLBANNER_STATE.Error]: trans('const.ts_banner_state_error'),
};
exports.STUDIO_STORY_PRICE_SETTING_TYPE_TRANSLATED = {
    [price_1.STUDIO_STORY_PRICE_SETTING_TYPE.FREE]: trans('price.ts_free'),
    [price_1.STUDIO_STORY_PRICE_SETTING_TYPE.FREE_AD]: trans('price.ts_advertisement'),
    [price_1.STUDIO_STORY_PRICE_SETTING_TYPE.PAID]: trans('price.ts_pay'),
};
