import React from 'react'
import { Col, FormGroup } from 'reactstrap'
import { ODEntityEditorContextType } from '../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../ODEntityEditor/ODEntityEditorFooter'
import { OD_DATA_TYPE, ODEntityEditorConfig } from './ODEntityEditorConfig'
import {
  ODF_DATETIME,
  ODF_IMAGE,
  ODF_LABEL,
  ODF_SIZE_FILL_REMAINING,
  ODF_TEXT,
  ODF_TEXTAREA,
  ODF_TYPE,
} from './ODFComponents'
import { createODFImageFile, ODFImage } from './ODFComponents/ODFImage'
import { createODFLabel, ODFLabel } from './ODFComponents/ODFLabel'
import { createODFDateTime, createODFText, createODFTextArea, ODFText } from './ODFComponents/ODFText'
import { RS } from './ODResources/ODResources'

type ROW<UI_PROPS> = {
  columns: Array<ODFBuildable<UI_PROPS>>
}

export type ODF_COMPONENT = ODF_LABEL | ODF_TEXT | ODF_TEXTAREA | ODF_IMAGE | ODF_DATETIME

export interface ODFBuildable<UI_PROPS> {
  key: string
  size: number // ColumnSize
  createComponent(): React.FunctionComponent<UI_PROPS>
}

export class ODFBuilder<UI_PROPS> implements ODFBuildable<UI_PROPS> {
  key: string
  data: ODF_COMPONENT

  constructor(key: string, data: ODF_COMPONENT) {
    this.key = key
    this.data = data
  }

  get size() {
    return this.data.size
  }

  static label = createODFLabel
  static text = createODFText
  static textarea = createODFTextArea

  createComponent(): React.FunctionComponent<UI_PROPS> {
    const { size, type, ...props } = this.data

    switch (type) {
      case ODF_TYPE.LABEL: {
        return (p: UI_PROPS) => <ODFLabel {...(props as Omit<ODF_LABEL, 'size'>)} />
      }
      case ODF_TYPE.TEXT: {
        return (p: UI_PROPS) => <ODFText inputType="text" {...(props as Omit<ODF_TEXT, 'size'>)} />
      }
      case ODF_TYPE.TEXTAREA: {
        const { rows, ...remaining } = props as Omit<ODF_TEXTAREA, 'size'>
        return (p: UI_PROPS) => <ODFText inputType="textarea" inputProps={{ rows }} {...remaining} />
      }
      case ODF_TYPE.IMAGE: {
        return (p: UI_PROPS) => <ODFImage {...(props as Omit<ODF_IMAGE, 'type'>)} />
      }
      case ODF_TYPE.DATETIME: {
        const odfText: ODF_TEXT = {
          type: ODF_TYPE.TEXT,
          // @ts-ignore
          formKey: props.formKey,
          labelKey: props.labelKey,
          size: 0,
        }
        return (p: UI_PROPS) => <ODFText inputType="datetime-local" {...(odfText as Omit<ODF_TEXT, 'size'>)} />
      }
      default:
        return (p: UI_PROPS) => <div>[48123] Unknown component type : {this.data.type}</div>
    }
  }
}

class RowConstructor<UI_PROPS> {
  data: ROW<UI_PROPS> = {
    columns: [],
  }

  rowKey: string

  constructor(rowKey: string) {
    this.rowKey = rowKey
  }

  add(odf: ODFBuildable<UI_PROPS>) {
    this.data.columns.push(odf)
    return this
  }

  createComponent(): React.FunctionComponent<UI_PROPS> {
    return (props: UI_PROPS) => {
      let remainingSize = 12
      return (
        <FormGroup row>
          {this.data.columns.map(buildable => {
            const Component = buildable.createComponent()
            const size = buildable.size === ODF_SIZE_FILL_REMAINING ? remainingSize : buildable.size
            remainingSize -= size
            return (
              <Col md={size} key={buildable.key}>
                <Component {...props} />
              </Col>
            )
          })}
        </FormGroup>
      )
    }
  }
}

export class ODEntityEditorUIBuilder<
  RESOURCE_KEY,
  SERVER_OBJECT_TYPE extends object,
  SERVER_CREATE_INPUT extends object,
  SERVER_UPDATE_INPUT extends object,
  CLIENT_FORM_DATA extends object,
  UI_PROPS
> {
  titleOnCreation: RS = RS.Undefined
  titleOnUpdate: RS = RS.Undefined

  rows: Array<RowConstructor<UI_PROPS>> = []
  private readonly editorConfig?: ODEntityEditorConfig<
    RESOURCE_KEY,
    SERVER_OBJECT_TYPE,
    SERVER_CREATE_INPUT,
    SERVER_UPDATE_INPUT,
    CLIENT_FORM_DATA,
    UI_PROPS
  >
  labelColSize: number = 2

  constructor(
    editorConfig?: ODEntityEditorConfig<
      RESOURCE_KEY,
      SERVER_OBJECT_TYPE,
      SERVER_CREATE_INPUT,
      SERVER_UPDATE_INPUT,
      CLIENT_FORM_DATA,
      UI_PROPS
    >
  ) {
    this.editorConfig = editorConfig
  }

  setTitleOnCreation(rs: RS) {
    this.titleOnCreation = rs
    return this
  }

  setTitleOnEdit(rs: RS) {
    this.titleOnUpdate = rs
    return this
  }

  addField(name: string) {
    console.assert(this.editorConfig, `[19238] editorConfig 가 없습니다. 잘못된 객체 생성입니다.`)
    const field = this.editorConfig!.config.fields.find((v: any) => v.name === name)
    console.assert(field, `[19239] 설정에서 필드명 [${name}]을 찾을 수 없습니다.`)
    console.assert(field!.ui, `[19240] 필드 설정에서 ui 필드를 찾을 수 없습니다.`)
    const f = field!
    const ui = field!.ui!

    return this.pushRow(name, rc => {
      ui.label && rc.add(createODFLabel(`${name}_label`, { labelKey: name, text: ui.label, size: this.labelColSize }))
      switch (f.dataType) {
        case OD_DATA_TYPE.INTEGER:
        case OD_DATA_TYPE.VARCHAR: {
          rc.add(
            createODFText(`${name}_text`, {
              size: ODF_SIZE_FILL_REMAINING,
              placeholder: ui.placeholder,
              readOnly: !!f.readOnly,
              labelKey: name,
              formKey: f.formField as string,
            })
          )
          return
        }
        case OD_DATA_TYPE.LONGTEXT: {
          rc.add(
            createODFTextArea(`${name}_text`, {
              size: ODF_SIZE_FILL_REMAINING,
              placeholder: ui.placeholder,
              readOnly: !!f.readOnly,
              labelKey: name,
              formKey: f.formField as string,
              rows: ui.rows || 5,
            })
          )
          return
        }
        case OD_DATA_TYPE.IMAGE: {
          rc.add(
            createODFImageFile(`${name}_text`, {
              size: ODF_SIZE_FILL_REMAINING,
              labelKey: name,
              formKey: f.formField as string,
              width: ui.width || 100,
              height: ui.height || 100,
            })
          )
          return
        }
        case OD_DATA_TYPE.DATETIME: {
          rc.add(
            createODFDateTime(`${name}_text`, {
              size: ODF_SIZE_FILL_REMAINING,
              readOnly: !!f.readOnly,
              labelKey: name,
              formKey: f.formField as string,
            })
          )
          return
        }
        default:
          console.assert(false, `[481211] Unhandled data type : ${f.dataType}`)
      }
    })
  }

  addAllFields() {
    console.assert(this.editorConfig, `[12398] editorConfig 가 없습니다. 잘못된 객체 생성입니다.`)
    this.editorConfig!.config.fields.forEach((f: any) => {
      f.ui && !f.hide && this.addField(f.name)
    })
    return this
  }

  insertAfter(rowKeyAfter: string, rowKey: string, fn: (row: RowConstructor<UI_PROPS>) => void) {
    const index = this.rows.findIndex(v => v.rowKey === rowKeyAfter)
    console.assert(index >= 0, `[19312] insertAfter - rowKey [${rowKeyAfter}] 가 없습니다.`)
    const rc = new RowConstructor(rowKey)
    fn(rc)
    this.rows.splice(index, 0, rc)
    return this
  }

  pushRow(rowKey: string, fn: (row: RowConstructor<UI_PROPS>) => void) {
    const rc = new RowConstructor(rowKey)
    fn(rc)
    this.rows.push(rc)
    return this
  }

  pushComponentAsRow(rowKey: string, Component: React.FunctionComponent<UI_PROPS>) {
    const rc = new RowConstructor(rowKey)
    rc.add({
      key: rowKey,
      size: 12,
      // @ts-ignore
      createComponent: () => Component,
    })
    this.rows.push(rc)
    return this
  }

  createComponent(renderFooter?: () => React.ReactNode) {
    return (
      props: UI_PROPS & { Context: React.Context<ODEntityEditorContextType<SERVER_OBJECT_TYPE, CLIENT_FORM_DATA>> }
    ) => {
      return (
        <>
          {this.rows.map(row => {
            const RowComponent = row.createComponent()
            return <RowComponent key={row.rowKey} {...props} />
          })}
          <hr />
          <ODEntityEditorFooter
            renderFooter={renderFooter}
            saveButtonName="Save"
            deleteButtonName={!this.editorConfig?.isCreating ? 'Delete' : undefined}
            context={props.Context}
          />
        </>
      )
    }
  }

  setLabelSize(colSize: number) {
    this.labelColSize = colSize
    return this
  }
}
