"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioScriptHelper = void 0;
const StudioChangeOpFactory_1 = require("../../../../changeOp/StudioChangeOpFactory");
/**
 * Sub-Block, Statement 등에서 동일하게 자주 사용되는 내용들을 helper 로 추출하여
 * 활용할 수 있도록 함.
 */
class StudioScriptHelper {
    constructor(block) {
        this.block = block;
    }
    opFactory() {
        return new StudioChangeOpFactory_1.StudioChangeOpFactory(this.block.parentChapter);
    }
    get di() {
        return this.block.parentChapter.store.rootStore.di;
    }
    get story() {
        return this.block.parentChapter.getStory();
    }
    get chrStore() {
        return this.story.chrStore;
    }
    get fileStore() {
        return this.story.studioFileStore;
    }
}
exports.StudioScriptHelper = StudioScriptHelper;
