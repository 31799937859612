import React from 'react'
import { Link } from 'react-router-dom'
import { GQLStory } from '../../@types/server'
import { COLORS } from '../../styles/colors'
import { SiteUrls } from '../../urls'

type StoryListItemProps = {
  story: GQLStory
}

export const EPUBStoryListItem: React.FC<StoryListItemProps> = (props) => {
  const { story } = props
  return (
    <>
      {story.isFinished ? (
        <span>
          <span
            style={{
              padding: '5px',
              backgroundColor: COLORS.Primary,
              color: 'white',
              borderRadius: 5,
              fontSize: 'xx-small',
            }}
          >
            완결
          </span>
          <span> </span>
        </span>
      ) : (
        ''
      )}
      {story.isAdult ? (
        <>
          <span
            style={{
              padding: '1px 2px',
              border: '1px solid red',
              color: 'red',
              borderRadius: 10,
              fontSize: 'small',
              fontWeight: 'bold',
            }}
          >
            19
          </span>
          <span> </span>
        </>
      ) : (
        ''
      )}
      <Link to={SiteUrls.Admin.EPUBStory.Edit(story.storyId)}>
        {story.name}
      </Link>
    </>
  )
}
