"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTHbMessageSelect = void 0;
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const mobx_1 = require("mobx");
const changeOp_1 = require("../../../../../../../changeOp");
const DOSTHbBase_1 = require("../../../DOSTHbBase");
const IDOStatement_1 = require("../../../IDOStatement");
const DOHbMessageSelectButton_1 = require("./DOHbMessageSelectButton");
class DOSTHbMessageSelect extends DOSTHbBase_1.DOSTHbBase {
    constructor(data, block, uniqueId) {
        var _a;
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: core_1.STATEMENT_TYPE.HbUserSelect,
            data: {},
            background: '',
            hbExtensionData: {},
        }, block, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.HbMessageSelect;
        this.hasModalForEdit = false;
        this.buttons = [];
        this.showSelectButtonModal = null;
        this.updateData((_a = this.st.hbExtensionData) !== null && _a !== void 0 ? _a : {});
        (0, mobx_1.makeObservable)(this, {
            buttons: mobx_1.observable,
            showSelectButtonModal: mobx_1.observable,
            flowChartNodesOfOtherGroupBlock: mobx_1.computed,
        });
    }
    async applyChangeOp(op, type) {
        if (op.opType === changeOp_1.StudioChangeOpType.CreateSelectButton) {
            return this.applyChangeOpCreateButtonInternal(op);
        }
        if (op.opType === changeOp_1.StudioChangeOpType.SelectButtonChange) {
            return this.applyChangeOpUpdateButtonInternal(op);
        }
        if (op.opType === changeOp_1.StudioChangeOpType.RemoveSelectButton) {
            return this.applyChangeOpRemoveButtonInternal(op);
        }
        return super.applyChangeOp(op, type);
    }
    removeButton(buttonId) {
        if (!buttonId) {
            return;
        }
        this.helper.opFactory().removeSelectButton(this, buttonId).submitSingle();
    }
    /**
     * 모드 필드에 대한 밸리데이션을 채크한다.
     * 선택지에서는 사용 X, 선택지를 생성, 삭제하는 것을 제외하고는 모두 버튼 생성/수정/삭제 api 를 호출한다.
     */
    get isValidAll() {
        return true;
    }
    /**
     * 버튼의 인스턴스를 받아 모달을 표시해주고, 닫히면 기존 데이터로 다시 버튼들의 클래스를 재생성해준다.
     */
    onShowSelectButtonModal(button) {
        (0, mobx_1.runInAction)(() => {
            this.showSelectButtonModal = button;
        });
        if (!button) {
            this.generateButtons(this.st.data);
        }
    }
    onShowCreateSelectButtonModal() {
        (0, mobx_1.runInAction)(() => {
            this.showSelectButtonModal = new DOHbMessageSelectButton_1.DOHbMessageSelectButton(this, {});
        });
    }
    /**
     *  선택지를 생성시 전달할 인풋 데이터
     */
    getDataForSubmit() {
        return {
            type: 'select',
        };
    }
    //
    // flowChart
    //
    get blocksTo() {
        return (0, lodash_1.flatten)(this.buttons
            .filter(b => !!b.messageNext)
            .map(b => b.messageNext.blocksTo));
    }
    get flowChartNodesOfOtherGroupBlock() {
        return (0, lodash_1.flatten)(this.buttons
            .filter(b => !!b.messageNext)
            .map(b => b.messageNext.flowChartNodesOfOtherGroupBlock));
    }
    getEdgeInfoForFlowChartBy(toBlockUniqueId) {
        const findList = this.buttons
            .filter(b => { var _a; return !!((_a = b.messageNext) === null || _a === void 0 ? void 0 : _a.getEdgeInfoForFlowChartBy(toBlockUniqueId)); })
            .map(b => b.messageNext.getEdgeInfoForFlowChartBy(toBlockUniqueId));
        if (!findList || findList.length < 1) {
            return null;
        }
        return findList.reduce((acc, item) => {
            return {
                ...acc,
                nomCondition: acc.numCondition + item.numCondition,
                numConditionGroup: acc.numConditionGroup + item.numConditionGroup,
            };
        });
    }
    removeHbBlocksBy(blockId) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b, _c, _d;
            this.st.data = {
                ...this.st.data,
                buttons: (_b = (_a = this.st.data.buttons) === null || _a === void 0 ? void 0 : _a.map(b => {
                    var _a;
                    return ({
                        ...b,
                        linkBlocks: ((_a = b.linkBlocks) !== null && _a !== void 0 ? _a : []).map(lb => {
                            var _a;
                            return ({
                                ...lb,
                                blocks: ((_a = lb.blocks) !== null && _a !== void 0 ? _a : []).filter(lbl => lbl.id !== blockId),
                            });
                        }),
                    });
                })) !== null && _b !== void 0 ? _b : [],
            };
            this.st.hbExtensionData = {
                ...this.st.hbExtensionData,
                data: {
                    ...this.st.hbExtensionData.data,
                    buttons: ((_d = (_c = this.st.hbExtensionData.data) === null || _c === void 0 ? void 0 : _c.buttons) !== null && _d !== void 0 ? _d : []).map(b => {
                        var _a;
                        return ({
                            ...b,
                            linkBlocks: ((_a = b.linkBlocks) !== null && _a !== void 0 ? _a : []).map(lb => {
                                var _a;
                                return ({
                                    ...lb,
                                    blocks: ((_a = lb.blocks) !== null && _a !== void 0 ? _a : []).filter(lbl => lbl.id !== blockId),
                                });
                            }),
                        });
                    }),
                },
            };
        });
        this.generateButtons(this.st.data);
    }
    /**
     * 새로운 데이터를 통해 데이터를 업데이트해주고 하위 버튼들의 클래스를 재생성해준다.
     */
    updateData(data) {
        var _a;
        super.updateData(data);
        this.generateButtons((_a = data.data) !== null && _a !== void 0 ? _a : {});
    }
    /**
     * 새로운 데이터를 통해 하위 버튼들의 클래스를 재생성해준다.
     */
    generateButtons(data) {
        (0, mobx_1.runInAction)(() => {
            var _a, _b;
            this.buttons =
                (_b = (_a = data.buttons) === null || _a === void 0 ? void 0 : _a.map(item => new DOHbMessageSelectButton_1.DOHbMessageSelectButton(this, item))) !== null && _b !== void 0 ? _b : [];
        });
    }
    async createButtonInternal(button) {
        if (!this.st.hbExtensionData.id) {
            return;
        }
        try {
            const res = await this.apiServer.message.createButton(this.st.hbExtensionData.id, button);
            this.updateData(res);
            if (this.showSelectButtonModal) {
                (0, mobx_1.runInAction)(() => {
                    this.showSelectButtonModal = null;
                });
            }
            return res;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw ex;
        }
    }
    async updateButtonInternal(button) {
        if (!this.st.hbExtensionData.id) {
            return;
        }
        try {
            const res = await this.apiServer.message.updateButton(this.st.hbExtensionData.id, button);
            this.updateData(res);
            if (this.showSelectButtonModal) {
                (0, mobx_1.runInAction)(() => {
                    this.showSelectButtonModal = null;
                });
            }
            return res;
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw ex;
        }
    }
    async removeButtonInternal(buttonId) {
        if (!this.st.hbExtensionData.id) {
            return;
        }
        try {
            const res = await this.apiServer.message.deleteButton(this.st.hbExtensionData.id, buttonId);
            (0, mobx_1.runInAction)(() => {
                if (!!this.st.hbExtensionData.data &&
                    !!this.st.hbExtensionData.data.buttons) {
                    this.st.hbExtensionData.data.buttons =
                        this.st.hbExtensionData.data.buttons.filter(item => item.id !== buttonId);
                }
                if (!!this.st.data.buttons) {
                    this.st.data.buttons = this.st.data.buttons.filter(item => item.id !== buttonId);
                }
            });
            this.generateButtons(this.st.data);
        }
        catch (ex) {
            this.rootStore.showError(ex);
            throw ex;
        }
    }
    async applyChangeOpCreateButtonInternal(op) {
        var _a, _b;
        try {
            await this.createButtonInternal(op.dataForSubmit);
            // 현재는 생성시에 무조건 라스트 턴에 생성된다.
            // 추후 턴이 적용되어 생성된다면 반환값으로 생성된 버튼의 아이디가 필요하다.
            const lastItem = (0, lodash_1.last)((_b = (_a = this.st.data) === null || _a === void 0 ? void 0 : _a.buttons) !== null && _b !== void 0 ? _b : []);
            if (!(lastItem === null || lastItem === void 0 ? void 0 : lastItem.id)) {
                return null;
            }
            return {
                reverse: changeOp_1.StudioChangeOpFactory.removeSelectButton(this, lastItem.id),
                lineToFocus: this,
            };
        }
        catch {
            return null;
        }
    }
    async applyChangeOpUpdateButtonInternal(op) {
        let reverseData;
        const find = this.buttons.find(item => item.data.id === op.dataForSubmit.id);
        if (!find) {
            return null;
        }
        reverseData = find.getOriginDataForSubmit();
        try {
            await this.updateButtonInternal(op.dataForSubmit);
            return {
                reverse: changeOp_1.StudioChangeOpFactory.updateSelectButton(this, reverseData),
                lineToFocus: this,
            };
        }
        catch {
            return null;
        }
    }
    async applyChangeOpRemoveButtonInternal(op) {
        let reverseData;
        const find = this.buttons.find(item => item.data.id === op.buttonId);
        if (!find) {
            return null;
        }
        const { id, ...rest } = find.getDataForSubmit();
        reverseData = rest;
        try {
            await this.removeButtonInternal(op.buttonId);
            return {
                reverse: changeOp_1.StudioChangeOpFactory.createSelectButton(this, reverseData),
                lineToFocus: this,
            };
        }
        catch {
            return null;
        }
    }
}
exports.DOSTHbMessageSelect = DOSTHbMessageSelect;
