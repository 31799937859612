import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ODBackRow } from '../../../components/ODBackRow'
import { SiteUrls } from '../../../urls'
import { StoryPropertyEditPage } from './StoryPropertyEditPage'

interface IStoryPropertyEditPageContainerProps {
  storyId: number
  propId: number | null
}

export const StoryPropertyEditPageContainer: React.FC<IStoryPropertyEditPageContainerProps> = (props) => {
  const { storyId, propId } = props
  return (
    <>
      <Card>
        <CardBody>
          <ODBackRow link={SiteUrls.Admin.Story.Edit(storyId, 6)} />
          <hr />
          <StoryPropertyEditPage storyId={storyId} propId={propId} />
        </CardBody>
      </Card>
    </>
  )
}
