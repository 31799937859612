"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOPlayerReport2 = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
class DOPlayerReport2 {
    constructor(store, data) {
        this.code = '';
        this.sampleData = '';
        this.ogTitle = null;
        this.ogDesc = null;
        this.ogImageLink = null;
        this.store = store;
        this.prId = data.prId;
        this.updatedAt = data.updatedAt;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            updatedAt: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    merge(data) {
        const fields = [
            'name',
            'code',
            'sampleData',
            'updatedAt',
            'ogTitle',
            'ogDesc',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        (0, storeUtils_1.assignIf)(data, 'ogImageFile', v => { var _a; return (this.ogImageLink = (_a = v === null || v === void 0 ? void 0 : v.link) !== null && _a !== void 0 ? _a : null); });
        return this;
    }
    get serverId() {
        return this.prId;
    }
    get rootStore() {
        return this.store.rootStore;
    }
    async update(changeSet) {
        return this.rootStore.di.server.updateEntityForStudio(this, changeSet);
    }
}
exports.DOPlayerReport2 = DOPlayerReport2;
