import { ODButton, ODButtonTheme } from '@odc/od-react-belt'
import { check } from 'prettier'
import React from 'react'
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { Utils } from '../../utils/utils'

export const PaustServerContainer: React.FC = () => {
  const { getAffection } = useStoryPlayAPIs()
  const [leftText, setLeftText] = React.useState<string>('')
  const [rightText, setRightText] = React.useState<string>('')
  const [affection, setAffection] = React.useState<string>('')

  const checkAffection = async () => {
    try {
      if (!leftText || !rightText) Utils.showError('입력칸을 모두 채워주세요!')
      const { affection: affectionData } = await getAffection({
        leftUserInputText: leftText,
        rightUserInputText: rightText,
      })
      setAffection(affectionData)
    } catch (e: any) {
      Utils.showError(e)
    }
  }

  const keyPressEnter = (e: any) => {
    if (e.key === 'Enter') {
      checkAffection()
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <FormGroup>
            <Row style={{ marginBottom: 5 }}>
              <Col md={3}>
                <Label style={{ paddingTop: 7 }}>상대방 :</Label>
              </Col>
              <Col md={9}>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Input
                    value={leftText}
                    onChange={(e) => {
                      setLeftText(e.target.value)
                      setAffection('')
                    }}
                  ></Input>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: 5 }}>
              <Col md={3}>
                <Label style={{ paddingTop: 7 }}>나 :</Label>
              </Col>
              <Col md={9}>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Input
                    value={rightText}
                    onChange={(e) => {
                      setRightText(e.target.value)
                      setAffection('')
                    }}
                    onKeyPress={keyPressEnter}
                  ></Input>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label style={{ paddingTop: 7 }}>애정도 결과값 :</Label>
              </Col>
              <Col md={9}>
                {affection && (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Input value={affection} disabled></Input>
                  </div>
                )}
                {!affection && (
                  <ODButton
                    theme={ODButtonTheme.Primary}
                    onClick={checkAffection}
                  >
                    결과 보기
                  </ODButton>
                )}
              </Col>
            </Row>
          </FormGroup>
        </CardBody>
      </Card>
    </>
  )
}
