"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColType = void 0;
var ColType;
(function (ColType) {
    ColType["SingleInputWithValidation"] = "SingleInputWithValidation";
    ColType["SingleTextInBox"] = "SingleTextInBox";
    ColType["SingleText"] = "SingleText";
    ColType["SingleIcon"] = "SingleIcon";
    ColType["SelectionInput"] = "SelectionInput";
    ColType["SelectionFieldWithUI"] = "SelectionFieldWithUI";
})(ColType = exports.ColType || (exports.ColType = {}));
