import { GQLColor } from '@storyplay/common'
import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { FlexContentsContainer } from '../../components/base/ODLayouts'
import { useCounter } from '../../context/ODCommon'
import { useStoryPlayAPIs } from '../../hooks/useStoryPlayAPIs'
import { ODListableOption, ODListableResponseType, useODListableContext } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { Utils } from '../../utils/utils'
import { ColorAddModal } from './ColorAddModal'

interface Props {}

export interface ColorDataLoaderOption extends ODListableOption {
  filter: string | null
}

export const ColorContainer: React.FC<Props> = ({ ...props }) => {
  const { Provider, Context } = useODListableContext<GQLColor, ColorDataLoaderOption>()

  const { listColor } = useStoryPlayAPIs()
  const [token, refresh] = useCounter()

  const dataLoader = React.useCallback(
    async function ColorDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ColorDataLoaderOption
    ): Promise<ODListableResponseType<GQLColor>> {
      const r = await listColor({
        page,
        pageSize,
        filter: options.filter || null,
      })
      return r as ODListableResponseType<GQLColor>
    },
    [listColor]
  )

  const [showColorAddModal, setColorAddModal] = React.useState(false)
  const handleConfirm = () => {
    setColorAddModal(false)
    refresh()
  }

  const TableDefinition: ODListableTableDefinition<GQLColor, ColorDataLoaderOption, any> = [
    {
      id: 'colorId',
      title: '아이디',
      transform: (v) => v.colorId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'name',
      title: '색상 이름',
      transform: (v) => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'hex',
      title: '색상 hex 값',
      transform: (v) => v.hex,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'hex',
      title: '색상',
      transform: (v) => <div style={{ width: 60, height: 40, backgroundColor: v.hex }} />,
      thClass: 'text-left',
      className: 'text-left user-td',
    }
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={(v) => v.colorId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="색상 이름이나 색상 hex 값으로 검색하세요."
                style={{ flexGrow: 6, maxWidth: 600 }}
              />

              <div>
                <Button 
                  block color="primary" 
                  style={{ minWidth: 135 }} 
                  onClick={() => {setColorAddModal(true)}}
                >
                  색상 추가
                </Button>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => '로딩중..'}
              renderEmpty={() => '코드가 없습니다'}
              eventParentContext={{}}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
            {showColorAddModal && (
              <ColorAddModal 
                onClose={() => {setColorAddModal(false)}}
                onConfirm={handleConfirm}
              />
            )}
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
