import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLRecommendationForFirstUser } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { COLORS } from '../../../styles/colors'
import { SiteUrls } from '../../../urls'
import {
  IRecommendationForFirstUserListableOption,
  RecommendationForFirstUserPrimaryKey,
} from '../RecommendationForFirstUserCommon'
import { useRecommendationForFirstUserListDataLoader } from './RecommendationForFirstUserListDataLoader'

interface IRecommendationForFirstUserListableTableProps {}

export const RecommendationForFirstUserListableTable: React.FC<
  IRecommendationForFirstUserListableTableProps
> = (props) => {
  const { Provider, Context } = useODListableContext<
    GQLRecommendationForFirstUser,
    IRecommendationForFirstUserListableOption
  >()
  const [token] = useCounter()
  const dataLoader = useRecommendationForFirstUserListDataLoader()
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[RecommendationForFirstUserPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginBottom: 15,
            }}
          >
            <Link
              to={SiteUrls.Admin.DirectAds.Create}
              style={{ textDecoration: 'none' }}
            >
              <ODButton
                theme={ODButtonTheme.Primary}
                size={ODButtonSize.Small}
                style={{ minWidth: 135, borderRadius: 5 }}
              >
                생성
              </ODButton>
            </Link>
          </div> */}
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>작품명</th>
                <th>노출 우선선위</th>
                <th>숨김?</th>
              </tr>
            )}
            renderRow={(value: GQLRecommendationForFirstUser) => (
              <HoverTR
                key={value.rfuId}
                onClick={() =>
                  setRedirect(
                    SiteUrls.Admin.RecommendationForFirstUser.Edit(value.rfuId)
                  )
                }
              >
                <td>{value.rfuId}</td>
                <td>{value.story.name}</td>
                {/* <td>
                  {value.imageFile?.link ? (
                    <img
                      src={value.imageFile.link}
                      width={150}
                      height={150}
                      alt="cover"
                    />
                  ) : (
                    <span>미등록</span>
                  )}
                </td> */}
                <td>{value.showOrder}</td>
                <td>
                  {value.isHidden ? (
                    <span style={{ fontWeight: 'bold', color: COLORS.Primary }}>
                      Y
                    </span>
                  ) : (
                    <span style={{ color: COLORS.GRAY600 }}>N</span>
                  )}
                </td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
