"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCALIZE_KEY_GENRE_SUB_TITLE = exports.LOCALIZE_KEY_GENRE_TITLE = exports.LOCALIZE_KEY_GENRE = void 0;
const const_1 = require("./const");
exports.LOCALIZE_KEY_GENRE = {
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE]: 'common_genre_title_romance',
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: 'common_genre_title_romance_fantasy',
    [const_1.CHALLENGE_STORY_GENRE.FANTASY]: 'common_genre_title_fantasy',
    [const_1.CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: 'common_genre_title_mystery_thriller_horror',
    [const_1.CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: 'common_genre_title_simulation',
    [const_1.CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: 'common_genre_title_eastern_action_alternate_history',
    [const_1.CHALLENGE_STORY_GENRE.BL_GL]: 'common_genre_title_bl_gl',
    [const_1.CHALLENGE_STORY_GENRE.FAN_FICTION]: 'common_genre_title_fanfiction',
    [const_1.CHALLENGE_STORY_GENRE.UNKNOWN]: 'common_genre_title_unclassified',
    [const_1.CHALLENGE_STORY_GENRE.ETC]: 'common_genre_title_etc',
    [const_1.CHALLENGE_STORY_GENRE.CONTEST_WINNER]: 'title_monthly_contest_winner_web_novel',
    [const_1.CHALLENGE_STORY_GENRE.MODERN_FANTASY]: '',
};
exports.LOCALIZE_KEY_GENRE_TITLE = {
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE]: 'home_screen_title_romance',
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: 'home_screen_title_romance_fantasy',
    [const_1.CHALLENGE_STORY_GENRE.FANTASY]: 'home_screen_title_fantasy',
    [const_1.CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: 'home_screen_title_mystery_thriller_horror',
    [const_1.CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: 'home_screen_title_simulation',
    [const_1.CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: 'home_screen_title_eastern_action_alternate_history',
    [const_1.CHALLENGE_STORY_GENRE.BL_GL]: 'home_screen_title_bl_gl',
    [const_1.CHALLENGE_STORY_GENRE.FAN_FICTION]: '',
    [const_1.CHALLENGE_STORY_GENRE.UNKNOWN]: '',
    [const_1.CHALLENGE_STORY_GENRE.ETC]: '',
    [const_1.CHALLENGE_STORY_GENRE.CONTEST_WINNER]: 'home_screen_label_section_wwwnovel',
    [const_1.CHALLENGE_STORY_GENRE.MODERN_FANTASY]: '',
};
exports.LOCALIZE_KEY_GENRE_SUB_TITLE = {
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE]: 'home_screen_label_section_romance',
    [const_1.CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: 'home_screen_label_section_romance_fantasy',
    [const_1.CHALLENGE_STORY_GENRE.FANTASY]: 'home_screen_label_section_fantasy',
    [const_1.CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: 'home_screen_label_section_mystery_thriller_horror',
    [const_1.CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: 'home_screen_label_section_simulation',
    [const_1.CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: 'home_screen_label_section_eastern_action_alternate_history',
    [const_1.CHALLENGE_STORY_GENRE.BL_GL]: 'home_screen_label_section_bl_gl',
    [const_1.CHALLENGE_STORY_GENRE.FAN_FICTION]: '',
    [const_1.CHALLENGE_STORY_GENRE.UNKNOWN]: '',
    [const_1.CHALLENGE_STORY_GENRE.ETC]: '',
    [const_1.CHALLENGE_STORY_GENRE.CONTEST_WINNER]: '',
    [const_1.CHALLENGE_STORY_GENRE.MODERN_FANTASY]: '',
};
