import styled from 'styled-components'
import { SCRIPT_PARSE_MESSAGE_TYPE } from '../../hooks/useScriptImporter'

export const LogsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export type LogTypeProps = {
  type: SCRIPT_PARSE_MESSAGE_TYPE
}

const COLORS_FOR_LOG_TYPE = {
  [SCRIPT_PARSE_MESSAGE_TYPE.Error]: 'red',
  [SCRIPT_PARSE_MESSAGE_TYPE.Warning]: 'yellow',
  [SCRIPT_PARSE_MESSAGE_TYPE.Info]: 'gray',
}

export const LogRow = styled.div`
  display: flex;
  margin-bottom: 3px;
  width: 100%;
`

export const LogType = styled.div`
  min-width: 40px;
  color: ${(p: LogTypeProps) => COLORS_FOR_LOG_TYPE[p.type]};
  border-right: 1px solid gray;
`

export const LogMessage = styled.div`
  margin-left: 20px;
`
