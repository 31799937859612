import { ODEntityRaw } from '@odc/od-react-belt'
import { COUNTRY_CODE, SystemLanguages } from '@storyplay/common'
import React from 'react'
import Select from 'react-select'
import { Col, Row } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLNoticePopup,
  GQLNoticePopupCreationInput,
  GQLNoticePopupUpdateInput,
  GQLOkResponse,
  GQLSingleIDInput,
} from '../../@types/server'
import { ODF_SIZE_FILL_REMAINING } from '../../components/ODEditor/ODFComponents'
import { ODFImage } from '../../components/ODEditor/ODFComponents/ODFImage'
import { ODFText } from '../../components/ODEditor/ODFComponents/ODFText'
import { useCounter, useODMutation, useODQuery } from '../../context/ODCommon'
import { ODEntityInput } from '../../ODEntityEditor/FormComponents/ODEntityInput'
import { ODEntityLabeled } from '../../ODEntityEditor/FormComponents/ODEntityLabeled'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils/utils'

type NoticePopupEditContainerProps = {
  idEditing?: number
}

const NOTICE_POPUP_FULL_SNAPSHOT = `
  noticePopupId
  link
  deepLink
  startAt
  endAt
  createdAt
  imageFile {
    link
  }
  langCode
  countryCode
`

const GQL_CREATE = `
mutation createNoticePopup($data: NoticePopupCreationInput!) {
  createNoticePopup(data: $data) {
    ${NOTICE_POPUP_FULL_SNAPSHOT}
  }
}
`

const GQL_UPDATE = `
mutation updateNoticePopup($data: NoticePopupUpdateInput!) {
  updateNoticePopup(data: $data) {
    ${NOTICE_POPUP_FULL_SNAPSHOT}
  }
}
`

const GQL_GET = `
query getNoticePopup($data: SingleIDInput!) {
  getNoticePopup(data: $data) {
    ${NOTICE_POPUP_FULL_SNAPSHOT}
  }
}
`

const GQL_REMOVE = `
mutation removeNoticePopup($data: SingleIDInput!) {
  removeNoticePopup(data: $data) {
    ok
  }
}
`

type PropsInput = GQLNoticePopupCreationInput & GQLNoticePopupUpdateInput
type Entity = GQLNoticePopup

const NoticePopupPropsInputValidation = {
  link: Yup.string(),
  deepLink: Yup.string(),
}

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    link: NoticePopupPropsInputValidation.link,
    deepLink: NoticePopupPropsInputValidation.deepLink,
  })
}

export const NoticePopupEditContainer: React.FC<
  NoticePopupEditContainerProps
> = (props) => {
  const { idEditing } = props
  const innerRef = React.useRef(null)
  const apiCreate = useODMutation<Partial<PropsInput>, Partial<Entity>>(
    GQL_CREATE
  )
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(
    GQL_UPDATE
  )
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE)
  const [token] = useCounter()

  const selectedLangCodeRef = React.useRef<string | null>(null)
  const selectedCountryCodeRef = React.useRef<string | null>(null)

  const createOptions = React.useCallback<
    () => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>
  >(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async (data) => {
        if (!data) {
          return {
            id: 0,
            link: '',
            deepLink: '',
            startAt: null,
            endAt: null,
            imageFile: null,
            langCode: '',
            countryCode: '',
          }
        }

        selectedLangCodeRef.current = data.langCode
        selectedCountryCodeRef.current = data.countryCode

        return {
          id: data.noticePopupId,
          link: data.link,
          deepLink: data.deepLink,
          startAt: data.startAt,
          endAt: data.endAt,
          imageFile: data.imageFile,
          langCode: data.langCode,
          countryCode: data.countryCode,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        data.langCode = selectedLangCodeRef.current ?? undefined
        data.countryCode = selectedCountryCodeRef.current ?? undefined

        console.log('@@data:', data)

        if (idEditing) {
          await apiUpdate({ id: idEditing, ...data })
          Utils.showSuccess('팝업 공지사항을 업데이트하였습니다.', 'Success')
        } else {
          const { id, ...inputs } = data
          console.log(123, data)
          await apiCreate(inputs)
          Utils.showSuccess('팝업 공지사항을 추가하였습니다.', 'Success')
        }
        return SiteUrls.Admin.NoticePopup.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('팝업 공지사항을 삭제하였습니다.', 'Success')
        }
        return SiteUrls.Admin.NoticePopup.List
      },
      __innerReference: innerRef,
    }),
    // by using token as dep, we can easily update whole thing.
    [idEditing, apiCreate, apiGet, apiUpdate, apiRemove, token]
  )

  const [options, setOptions] = React.useState<
    ODEntityEditorContextOptions<Entity, Partial<PropsInput>>
  >(() => createOptions())
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(
    () =>
      setContext(
        createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
      ),
    [options]
  )

  const title = !idEditing ? '팝업 공지사항 추가' : '팝업 공지사항 수정'

  return (
    <Provider title={title}>
      <ODEntityInput
        name="link"
        label="링크"
        placeholder="링크를 입력하세요"
        inputType="text"
      />
      <ODEntityInput
        name="deepLink"
        label="딥링크"
        placeholder="딥링크를 입력하세요"
        inputType="text"
      />
      <ODEntityLabeled name="startAt" label="노출시작시각">
        <ODFText
          labelKey={'startAt'}
          formKey="startAt"
          inputType="datetime-local"
        />
      </ODEntityLabeled>
      <ODEntityLabeled name="endAt" label="노출종료시각">
        <ODFText
          labelKey={'endAt'}
          formKey="endAt"
          inputType="datetime-local"
        />
      </ODEntityLabeled>
      <ODEntityLabeled name="imageFile" label="이미지 파일">
        <ODFImage
          size={ODF_SIZE_FILL_REMAINING}
          labelKey={'imageFile'}
          width={327}
          height={245}
          formKey={'imageFile'}
        />
      </ODEntityLabeled>
      <ODEntityLabeled name={'언어코드'} label={'언어코드'}>
        <Row>
          <Col md={6}>
            <Select
              isSearchable={true}
              placeholder="공지사항의 언어를 선택해주세요"
              onChange={(v) => {
                if (v) {
                  // @ts-ignore
                  selectedLangCodeRef.current = v.value
                }
              }}
              options={SystemLanguages.map((lng) => ({
                label: `${lng.code}(${lng.display})`,
                value: lng.code,
              }))}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 2 }),
              }}
            />
          </Col>
          {idEditing && (
            <Col md={4}>
              <ODEntityRaw
                name="langCode"
                keyPath="langCode"
                render={({ value }) => {
                  return <span>{value ? value : '없음'}</span>
                }}
              />
            </Col>
          )}
        </Row>
      </ODEntityLabeled>

      <ODEntityLabeled name={'국가코드'} label={'국가코드'}>
        <Row>
          <Col md={6}>
            <Select
              isSearchable={true}
              placeholder="공지사항을 제공할 국가를 선택해주세요"
              onChange={(v) => {
                if (v) {
                  // @ts-ignore
                  selectedCountryCodeRef.current = v.value
                }
              }}
              options={[
                { label: 'KR', value: COUNTRY_CODE.KR },
                { label: 'US', value: COUNTRY_CODE.US },
              ]}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 2 }),
              }}
            />
          </Col>
          {idEditing && (
            <Col md={4}>
              <ODEntityRaw
                name="countryCode"
                keyPath="countryCode"
                render={({ value }) => {
                  return <span>{value ? value : '없음'}</span>
                }}
              />
            </Col>
          )}
        </Row>
      </ODEntityLabeled>
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        deleteConfirmOptions={{
          message: <>정말 삭제하시겠습니까?</>,
          yes: '삭제',
          no: '취소',
        }}
        deleteButtonName={idEditing ? 'Delete' : undefined}
        context={Context}
      />
    </Provider>
  )
}
