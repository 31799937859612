"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALCreateNewChapter = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../../autoLayout");
const ActionChainBuilder_1 = require("../../../../autoLayout/builder/ActionChainBuilder");
const consts_1 = require("../../../../consts");
const interface_1 = require("../../interface");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
// 추후 AI 블록 생성기 개발과정이 충분히 진행되면 제거해도 됨.
const USE_TEST_AI_DATA = false;
class ALCreateNewChapter {
    constructor(story) {
        this.story = story;
    }
    async submit(nameIn, synopsisIn, endingIn, isAdult) {
        const name = nameIn.trim();
        const synopsis = synopsisIn.trim();
        const ending = endingIn.trim();
        return this.story.createChapter(name, { synopsis, ending, isAdult }, ex => this.story.rootStore.showError(ex));
    }
    buildActionChain() {
        const nameField = new ui_1.InputWithValidation('chapterName', trans('legacy_ALCreateNewChapter_episode_name'), trans('legacy_ALCreateNewChapter_enter_episode_name'), 'text', USE_TEST_AI_DATA ? trans('legacy_ALCreateNewChapter_test') : '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: ui_1.InputTheme.Default,
            maxLength: 40,
        })
            .setRequired(true)
            .setAutoFocus();
        nameField.setEditing(true);
        const synopsisField = new ui_1.InputWithValidation('synopsis', trans('legacy_ALCreateNewChapter_episode_summary'), trans('legacy_ALCreateNewChapter_write_summary'), 'textarea', USE_TEST_AI_DATA
            ? trans('legacy_ALCreateNewChapter_love_welcomes_dasom')
            : '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: ui_1.InputTheme.Default,
        }).setRequired(false);
        synopsisField.setEditing(true);
        const endingField = new ui_1.InputWithValidation('endingContents', trans('legacy_ALCreateNewChapter_last_scene'), trans('legacy_ALCreateNewChapter_write_last_scene'), 'text', USE_TEST_AI_DATA
            ? trans('legacy_ALCreateNewChapter_love_gives_case')
            : '', null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: ui_1.InputTheme.Default,
        }).setRequired(false);
        endingField.setEditing(true);
        const isAdultField = new ui_1.SelectionInput('isAdult', '연령 기준', false, [
            { name: '', value: true },
            { name: '', value: false },
        ]);
        const di = this.story.rootStore.di;
        const isAIBlockGeneratorEnabled = di.isFeatureEnabled(interface_1.FEATURE_FLAG.AI_BLOCK_GENERATOR);
        const builder = new ActionChainBuilder_1.ActionChainBuilder('TemplateCreateNewBlockAction');
        builder.addModal(modalBuilder => {
            modalBuilder.setCancelOnClickOutside(false);
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: trans('legacy_ALCreateNewChapter_create_new_episode'),
                imageName: consts_1.SPImageName.MenuNovel,
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(nameField));
            });
            if (isAIBlockGeneratorEnabled) {
                modalBuilder.addRow(rowBuilder => {
                    rowBuilder.addCol(col => col.setSingleText({
                        text: trans('legacy_ALCreateNewChapter_ai_creates_blocks'),
                        textClassName: 'text-sm text-gray-500',
                    }));
                });
                modalBuilder.addRow(rowBuilder => {
                    rowBuilder.addCol(col => col.setSingleInput(synopsisField));
                });
                modalBuilder.addRow(rowBuilder => {
                    rowBuilder.addCol(col => col.setSingleInput(endingField));
                });
            }
            !this.story.isAdult &&
                modalBuilder.addRow(rowBuilder => {
                    rowBuilder.addCol(col => col.setSelectionInput(isAdultField));
                });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_ALCreateNewChapter_cancel'),
                        onClick: () => {
                            modalBuilder.cancelAction();
                        },
                    },
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: trans('legacy_ALCreateNewChapter_complete'),
                        onClick: async () => {
                            const alm = this.story.rootStore.autoLayoutManager;
                            alm.setShowScreenLoading(true);
                            try {
                                if (await this.submit(nameField.value, synopsisField.value, endingField.value, Boolean(isAdultField.value))) {
                                    modalBuilder.finishAction(null);
                                }
                            }
                            finally {
                                alm.setShowScreenLoading(false);
                            }
                        },
                    },
                ],
            });
        });
        return builder.buildActionChain();
    }
}
exports.ALCreateNewChapter = ALCreateNewChapter;
