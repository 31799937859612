const createFields = (fieldArray: string[]) => fieldArray.join('\n')

const COMMON_IMAGE_FILE_FIELDS = ['fileId', 'width', 'height', 'link']
export const IMAGE_FILE_FULL_SNAPSHOT = `
  ${createFields(COMMON_IMAGE_FILE_FIELDS)}
`

export const GQL_CREATE_IMAGE_FILE = `
mutation createImageFile($data: ImageFileCreationInput!) {
  createImageFile(data: $data) {
    ${IMAGE_FILE_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_IMAGE_FILE = `
query getImageFile($data: SingleIDInput!) {
  getImageFile(data: $data) {
    ${IMAGE_FILE_FULL_SNAPSHOT}
  }
}`

export const GQL_REMOVE_IMAGE_FILE = `
mutation removeImageFile($data: SingleIDInput!) {
  removeImageFile(data: $data) {
    ok
  }
}
`

export const GQL_LIST_IMAGE_FILE = `
query listImageFile($data: ListableImageFileInput!) {
  listImageFile(data: $data) {
    list {
      ${IMAGE_FILE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const COMMON_SFX_FILE_FIELDS = ['fileId', 'link', 'mimeType']
export const SFX_FILE_FULL_SNAPSHOT = `
  ${createFields(COMMON_SFX_FILE_FIELDS)}
`

export const GQL_CREATE_SFX_FILE = `
mutation createSFXFile($data: SFXFileCreationInput!) {
  createSFXFile(data: $data) {
    ${SFX_FILE_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_SFX_FILE = `
query getSFXFile($data: SingleIDInput!) {
  getSFXFile(data: $data) {
    ${SFX_FILE_FULL_SNAPSHOT}
  }
}`

export const GQL_REMOVE_SFX_FILE = `
mutation removeSFXFile($data: SingleIDInput!) {
  removeSFXFile(data: $data) {
    ok
  }
}
`

export const GQL_LIST_SFX_FILE = `
query listSFXFile($data: ListableSFXFileInput!) {
  listSFXFile(data: $data) {
    list {
      ${SFX_FILE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`
