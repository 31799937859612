"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextareaTheme = exports.InputTheme = void 0;
var InputTheme;
(function (InputTheme) {
    InputTheme["BlockName"] = "BlockName";
    InputTheme["Script"] = "Script";
    InputTheme["SearchInEpisode"] = "SearchInEpisode";
    InputTheme["Default"] = "Default";
    // https://tailwindui.com/components/application-ui/forms/form-layouts
    // Labels on left 참고
    // LabelsOnLeft = 'LabelsOnLeft', // TODO: 좀 더 컴팩트한 버전의 UI 가 필요할 듯.
})(InputTheme = exports.InputTheme || (exports.InputTheme = {}));
var TextareaTheme;
(function (TextareaTheme) {
    // Talk
    TextareaTheme["LeftTalk"] = "LeftTalk";
    TextareaTheme["LeftThink"] = "LeftThink";
    TextareaTheme["RightTalk"] = "RightTalk";
    TextareaTheme["RightThink"] = "RightThink";
    TextareaTheme["Script"] = "Script";
    TextareaTheme["EndingName"] = "EndingName";
    TextareaTheme["FinalEndingSummary"] = "EndingSummary";
    TextareaTheme["FullWidthText"] = "FullWidthText";
    TextareaTheme["Prompt"] = "Prompt";
    TextareaTheme["NormalSize"] = "NormalSize";
    TextareaTheme["Default"] = "Default";
})(TextareaTheme = exports.TextareaTheme || (exports.TextareaTheme = {}));
