"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIServerBaseImpl = void 0;
const lodash_1 = require("lodash");
const error_1 = require("../error");
class APIServerBaseImpl {
    constructor(client, defaultOptions = {}) {
        this.client = client;
        this.options = {
            timeout: 10000,
            simulateDelay: 0,
            pickFirstKey: true,
            operationName: 'unnamed_operation',
            throwRawError: false,
            ...defaultOptions,
        };
    }
    async query(query, data, options = {}, additionalVariables) {
        var _a, _b;
        const op = {
            ...this.options,
            ...options,
        };
        await this.simulateDelay(op.simulateDelay);
        try {
            const res = await this.client.query(query, data, op.operationName, additionalVariables);
            if (op.pickFirstKey) {
                // @ts-ignore
                return res.data[Object.keys(res.data)[0]];
            }
            return res.data;
        }
        catch (ex) {
            if (op.throwRawError) {
                throw ex;
            }
            if ((0, lodash_1.startsWith)(ex.message, 'SP')) {
                const code = parseInt((_b = (_a = ex.message.split(' ')[0]) === null || _a === void 0 ? void 0 : _a.split('-')) === null || _b === void 0 ? void 0 : _b[1], 10);
                if (!isNaN(code)) {
                    throw new error_1.SPError(code);
                }
            }
            // TODO: unhandled error
            const err = new error_1.SPError(error_1.ErrorCode.FATAL);
            // @ts-ignore
            err.origin = ex;
            throw err;
        }
    }
    async mutate(query, keyOfFiles, data, options = {}, additionalVariables) {
        var _a, _b;
        const op = {
            ...this.options,
            ...options,
        };
        await this.simulateDelay(op.simulateDelay);
        try {
            const res = await this.client.mutate(query, keyOfFiles, data, op.operationName, additionalVariables);
            if (op.pickFirstKey) {
                // @ts-ignore
                return res.data[Object.keys(res.data)[0]];
            }
            return res.data;
        }
        catch (ex) {
            if (op.throwRawError) {
                throw ex;
            }
            if ((0, lodash_1.startsWith)(ex.message, 'SP')) {
                const code = parseInt((_b = (_a = ex.message.split(' ')[0]) === null || _a === void 0 ? void 0 : _a.split('-')) === null || _b === void 0 ? void 0 : _b[1], 10);
                if (!isNaN(code)) {
                    throw new error_1.SPError(code);
                }
            }
            // TODO: unhandled error
            const err = new error_1.SPError(error_1.ErrorCode.FATAL);
            // @ts-ignore
            err.origin = ex;
            throw err;
        }
    }
    async simulateDelay(delay) {
        if (delay > 0) {
            return new Promise(resolve => setTimeout(resolve, this.options.simulateDelay));
        }
    }
}
exports.APIServerBaseImpl = APIServerBaseImpl;
