"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTCollectionDesc = void 0;
const mobx_1 = require("mobx");
const __1 = require("../../../../..");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
class DOSTCollectionDesc extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data !== null && data !== void 0 ? data : {
            sourceLine: block.store.rootStore.di.generateSourceLine(),
            statementType: __1.STATEMENT_TYPE.CollectionDesc,
            message: '',
            background: '',
        }, block, uniqueId);
        (0, mobx_1.makeObservable)(this, {
            message: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get message() {
        return this.st.message;
    }
    generateSheetColumnsAfterBackground() {
        return ['', this.message];
    }
    validate() {
        const results = super.validate();
        if (!this.message) {
            results.push({
                type: __1.StudioValidationType.FinalEndingHasNoDesc,
                stack: [],
                source: this,
                severity: __1.StudioValidationSeverity.Error,
                extra: {},
            });
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
}
exports.DOSTCollectionDesc = DOSTCollectionDesc;
