import { ODListableOption } from '@odc/od-react-belt'
import { GQLEndingShowsProperty } from '../../@types/server'

export const EndingShowsPropertyPrimaryKey = 'espId'

// modifiable fields only.
export interface ICTEndingShowsProperty
  extends Omit<GQLEndingShowsProperty, 'espId'> {
  espId: number | undefined
}

export interface IEndingShowsPropertyListableOption extends ODListableOption {
  storyId: number | null
  endingId: number | null
}
