"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryGameEditor = void 0;
const core_1 = require("@storyplay/core");
const EntityEditor_1 = require("../EntityEditor");
const DBFileFieldWithUI_1 = require("../fields/DBFileFieldWithUI");
const StoryGameFileFieldWithUI_1 = require("../fields/StoryGameFileFieldWithUI");
const ui_1 = require("../ui");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
const mobx_1 = require("mobx");
const DateWithNullableField_1 = require("../fields/DateWithNullableField");
const SelectionFieldWithUI_1 = require("../fields/SelectionFieldWithUI");
class DOStoryGameEditor extends EntityEditor_1.EntityEditor {
    constructor(story) {
        super(story);
        this.storygamePreviewFileFields = [];
        this.storygamePreviewIdsFiled = null;
        this.storygameTagIdsFiled = null;
        this.selectedTags = [];
        this.preBookField = null;
        this.preBookReleaseDateField = null;
        this.storygameReleaseDateField = null;
        this.isStorygameHiddenField = null;
        this.story = story;
        // 지금은 커버이미지 한개밖에 없지만 나중에 추가될 수도 있으므로 기존로직 그대로 둔다.
        const fileFields = [
            {
                key: 'storygameCoverImage',
                options: {
                    label: trans('legacy_DOStoryEditor_cover_image'),
                    options: { showLabel: false, theme: ui_1.FileTheme.StoryGameCoverImage },
                },
            },
        ];
        fileFields.forEach(fld => {
            ;
            this[`${fld.key}FileField`] = new DBFileFieldWithUI_1.DBFileFieldWithUI(`${fld.key}File`, () => {
                const link = story[`${fld.key}Link`];
                return { link };
            }, () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        this.storygameUploadFileField = new StoryGameFileFieldWithUI_1.StoryGameFileFieldWithUI(`storygameUpload`, () => null, // ui dummy라서 getter, setter가 필요없다.
        () => null, this.story, {
            label: 'upload field',
            onUploaded: (file, storyPreviewId, fileType) => {
                this.appendPreviewFile(file.link, storyPreviewId, fileType);
            },
            options: {
                showLabel: false,
                theme: ui_1.FileTheme.StoryGameUploadItem,
            },
        });
        this.storygamePreviewFileFields = this._target.storygamePreviews.map(previewInfo => this.createStoryGameFileField(previewInfo.file.link, previewInfo.storyPreviewId, previewInfo.fileType));
        this.storygamePreviewIdsFiled = new SelectionFieldWithUI_1.SelectionFieldWithUI('storygamePreviewIds', () => {
            var _a, _b;
            return (_b = (_a = this.storygamePreviewFileFields) === null || _a === void 0 ? void 0 : _a.map((field) => Number(field.input.label))) !== null && _b !== void 0 ? _b : [];
        }, () => null, {
            label: 'preivew ids',
            selection: [],
            options: {
                required: true,
            },
        });
        const selectionFields = [
            {
                key: 'isStorygameHidden',
                options: {
                    label: '스토리 게임 노출 여부',
                    selection: [
                        {
                            name: '노출',
                            value: false,
                        },
                        {
                            name: '미노출',
                            value: true,
                        },
                    ],
                },
            },
        ];
        const preBookTempField = {
            key: 'preBook',
            options: {
                label: '사전예약 여부',
                selection: [
                    {
                        name: '설정함',
                        value: true,
                    },
                    {
                        name: '설정 안함',
                        value: false,
                    },
                ],
                options: {
                    noLabel: true,
                    isDisabled: true,
                },
            },
        };
        this.preBookField = new SelectionFieldWithUI_1.SelectionFieldWithUI(preBookTempField.key, () => false, () => null, preBookTempField.options);
        selectionFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new SelectionFieldWithUI_1.SelectionFieldWithUI(fld.key, () => story[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        this.storygameReleaseDateField = new DateWithNullableField_1.DateWithNullableField('storygameReleaseDate', () => {
            if (!!this._target.storygameReleaseDate) {
                return this._target.storygameReleaseDate;
            }
            return null;
        }, () => null);
        this.storygameTagIdsFiled = new SelectionFieldWithUI_1.SelectionFieldWithUI('storygameTagIds', () => this._target.storyTags.map(v => v.storyTagId), () => null, {
            label: '태그',
            selection: [],
            options: {
                required: true,
            },
        });
        this.selectedTags = story.storyTags; // 리스트에 보여주기 위한 용도
        (0, mobx_1.makeObservable)(this, {
            storygameCoverImageFileField: mobx_1.observable,
            selectedTags: mobx_1.observable,
            storygamePreviewFileFields: mobx_1.observable,
            storygamePreviewIdsFiled: mobx_1.observable,
            storygameTagIdsFiled: mobx_1.observable,
            storygameReleaseDateField: mobx_1.observable,
            preBookField: mobx_1.observable,
            preBookReleaseDateField: mobx_1.observable,
            isStorygameHiddenField: mobx_1.observable,
        });
    }
    get rootStore() {
        return this._target.rootStore;
    }
    get storyId() {
        return this._target.storyId;
    }
    createStoryGameFileField(link, previewId, fileType) {
        return new DBFileFieldWithUI_1.DBFileFieldWithUI(`storygamePreviewFile_${previewId}`, () => ({ link }), () => null, // viewing만 하기때문에 setter가 필요없다.
        {
            label: `${previewId}`,
            options: {
                showLabel: false,
                theme: fileType === 'Video'
                    ? ui_1.FileTheme.StoryGamePreviewVideo
                    : ui_1.FileTheme.StoryGamePreviewImage,
            },
        });
    }
    appendPreviewFile(link, previewId, type) {
        (0, mobx_1.runInAction)(() => {
            const newPreviewField = this.createStoryGameFileField(link, previewId, type);
            (0, mobx_1.runInAction)(() => {
                this.storygamePreviewFileFields.push(newPreviewField);
            });
            this.reloadPreviewIds();
        });
    }
    movePreviewFile(direction, input) {
        (0, mobx_1.runInAction)(() => {
            const currentIndex = this.storygamePreviewFileFields.findIndex(field => field.input === input);
            const newIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
            if (newIndex >= 0 && newIndex < this.storygamePreviewFileFields.length) {
                ;
                [
                    this.storygamePreviewFileFields[currentIndex],
                    this.storygamePreviewFileFields[newIndex],
                ] = [
                    this.storygamePreviewFileFields[newIndex],
                    this.storygamePreviewFileFields[currentIndex],
                ];
                this.reloadPreviewIds();
            }
        });
    }
    removePreviewFile(input) {
        (0, mobx_1.runInAction)(() => {
            this.storygamePreviewFileFields = this.storygamePreviewFileFields.filter(fileField => fileField.input !== input);
            this.reloadPreviewIds();
        });
    }
    reloadPreviewIds() {
        var _a;
        (_a = this.storygamePreviewIdsFiled) === null || _a === void 0 ? void 0 : _a.onChange(this.storygamePreviewFileFields.map(field => Number(field.input.label)));
    }
    setTags(tags) {
        (0, mobx_1.runInAction)(() => {
            var _a;
            this.selectedTags = tags;
            (_a = this.storygameTagIdsFiled) === null || _a === void 0 ? void 0 : _a.onChange(tags.map(v => v.storyTagId));
        });
    }
    async submitChanges(onError) {
        var _a, _b;
        const isChangedFields = this.createChangeSet() !== null;
        // tslint:disable-next-line:prefer-conditional-expression
        return (_b = (await ((_a = super.submitChanges) === null || _a === void 0 ? void 0 : _a.call(this, onError)))) !== null && _b !== void 0 ? _b : false;
    }
}
exports.DOStoryGameEditor = DOStoryGameEditor;
