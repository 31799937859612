import {
  GQLEndingMigrationInput,
  GQLStory,
  GQLStoryPropsMigrationInput,
} from '../../@types/server'

export interface ICTStory extends Omit<GQLStory, 'storyId'> {
  storyId: number | ''
  storyPropsMigration: GQLStoryPropsMigrationInput | null
  endingMigration: GQLEndingMigrationInput | null
}

export const StoryPrimaryKey = 'storyId'
