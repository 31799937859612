"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbBlockAPI = void 0;
const hbApiUtils_1 = require("../hbApiUtils");
class HbBlockAPI {
    constructor(server) {
        this.server = server;
    }
    async create(payload) {
        return this.server.post(`/v1/blocks`, payload);
    }
    async delete(id) {
        return this.server.delete(`/v1/blocks/${id}`, {});
    }
    async get(id) {
        return this.server.get(`/v1/blocks/${id}`);
    }
    async getListBlock(botId, pageIndex, pageSize, filter) {
        return this.server.get(`/v1/blocks?sort=groupId&sortDirection=desc&${(0, hbApiUtils_1.createQueryParams)({
            botId,
            pageIndex,
            pageSize,
            filter,
        })}&ngsw-bypass=`);
    }
}
exports.HbBlockAPI = HbBlockAPI;
