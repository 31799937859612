"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTFinalEndingSubBlock = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const __1 = require("../../../../../..");
const validations_1 = require("../../../../../validations");
const fields_1 = require("../../../fields");
const SelectionFieldWithUI_1 = require("../../../fields/SelectionFieldWithUI");
const StudioFileFieldWithUI_1 = require("../../../fields/StudioFileFieldWithUI");
const ui_1 = require("../../../ui");
const DOBaseScriptSubBlock_1 = require("../../DOBaseScriptSubBlock");
const IDOStatement_1 = require("../../IDOStatement");
const DOSTCollectionDesc_1 = require("../DOSTCollectionDesc");
const DOSTCollectionImage_1 = require("../DOSTCollectionImage");
const DOSTFinalEnding_1 = require("../DOSTFinalEnding");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
const ACCEPTABLE_STATEMENTS = [
    __1.STATEMENT_TYPE.FinalEnding,
    __1.STATEMENT_TYPE.CollectionDesc,
    __1.STATEMENT_TYPE.CollectionImage,
];
const FinalEndingStatementOrder = {
    [__1.STATEMENT_TYPE.FinalEnding]: 1,
    [__1.STATEMENT_TYPE.CollectionDesc]: 2,
    [__1.STATEMENT_TYPE.CollectionImage]: 3,
};
class DOSTFinalEndingSubBlock extends DOBaseScriptSubBlock_1.DOBaseScriptSubBlock {
    constructor(statements, block, uniqueId) {
        super(statements, block, IDOStatement_1.DOSubBlockType.FinalEnding, uniqueId);
        this.endBlockType = IDOStatement_1.EndBlockType.FinalEnding;
        this.blockTagType = IDOStatement_1.BlockTagType.FinalEnding;
        // 최종화엔딩의 경우 단순 링크가 아닌 DB 의 Entity 에 직접 이미지가 매핑되므로 다른 방식으로 처리되어야 한다.
        // 이미지가 변경된 경우 링크 주소. 이 주소가 존재하면, StudioHasStudioFile 로 변경되었다는 의미이다.
        this.uploadedImage = null;
        this.didSetupFields = false;
        queueMicrotask(() => this.setupFields());
        (0, mobx_1.makeObservable)(this, {
            uploadedImage: mobx_1.observable,
            endingId: mobx_1.computed,
            endingName: mobx_1.computed,
            imageLink: mobx_1.computed,
            // isSubmitDisabled: computed,
            isDefaultEnding: mobx_1.computed,
        });
    }
    mergeStatement(statement) {
        if (statement.lineType === IDOStatement_1.UILineType.SingleLineScript &&
            ACCEPTABLE_STATEMENTS.includes(statement.statementType)) {
            (0, mobx_1.runInAction)(() => {
                this.statements.push(statement);
            });
            return true;
        }
        return false;
    }
    getOrderForChoiceStatement(st) {
        // @ts-ignore
        return FinalEndingStatementOrder[st.statementType];
    }
    fixStatements() {
        (0, mobx_1.runInAction)(() => {
            const st0 = this.statements.find(v => v instanceof DOSTFinalEnding_1.DOSTFinalEnding);
            if (!st0) {
                this.statements.push(new DOSTFinalEnding_1.DOSTFinalEnding(null, this.block));
            }
            const st = this.statements.find(v => v instanceof DOSTCollectionDesc_1.DOSTCollectionDesc);
            if (!st) {
                this.statements.push(new DOSTCollectionDesc_1.DOSTCollectionDesc(null, this.block));
            }
            const st2 = this.statements.find(v => v instanceof DOSTCollectionImage_1.DOSTCollectionImage);
            if (!st2) {
                this.statements.push(new DOSTCollectionImage_1.DOSTCollectionImage(null, this.block));
            }
        });
    }
    get blocksTo() {
        return [];
    }
    get finalEnding() {
        return this.getStatementOfStatementType(__1.STATEMENT_TYPE.FinalEnding);
    }
    get collectionDesc() {
        return this.getStatementOfStatementType(__1.STATEMENT_TYPE.CollectionDesc);
    }
    get collectionImage() {
        return this.getStatementOfStatementType(__1.STATEMENT_TYPE.CollectionImage);
    }
    get endingId() {
        return this.finalEnding.endingId;
    }
    get endingName() {
        return this.finalEnding.endingName;
    }
    get displayName() {
        return this.finalEnding.displayName || this.displayNameField.value;
    }
    //
    // IEditor implementations
    //
    get editorFields() {
        return [
            ...super.editorFields,
            this.isDefaultEndingField,
            this.nameField,
            this.displayNameField,
            this.summaryField,
            this.imageField,
        ];
    }
    /**
     * 이 엔딩이 스튜디오에서 보여줄 이미지 링크.
     */
    get imageLink() {
        var _a, _b, _c, _d;
        const story = this.block.parentChapter.getStory();
        const ending = story.endingStore.getByName(this.finalEnding.endingName);
        // 현재 교체된 업로드 이미지 > 업로드된 엔딩 이미지 > 기본 플레이스홀더 이미지
        return (_d = (_b = (_a = this.uploadedImage) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : (_c = ending === null || ending === void 0 ? void 0 : ending.imageFile) === null || _c === void 0 ? void 0 : _c.link) !== null && _d !== void 0 ? _d : null;
    }
    getStory() {
        return this.block.parentChapter.getStory();
    }
    getEnding() {
        const story = this.block.parentChapter.getStory();
        return story.endingStore.getByName(this.finalEnding.endingName);
    }
    canUploadPreviewImage() {
        return !!this.getEnding();
    }
    /**
     * 이 엔딩이 디폴트 엔딩인가?
     */
    get isDefaultEnding() {
        var _a;
        return (((_a = this.block.parentChapter) === null || _a === void 0 ? void 0 : _a.defaultEndingCustomId) ===
            this.finalEnding.endingId);
    }
    validate() {
        const results = super.validate();
        if (!this.imageLink) {
            results.push({
                type: __1.StudioValidationType.FinalEndingHasNoImage,
                stack: [],
                source: this,
                severity: __1.StudioValidationSeverity.Error,
                extra: {},
            });
        }
        (0, mobx_1.runInAction)(() => (this.lastValidationResults = results));
        return results;
    }
    exportMetaDataUpdateActions() {
        const actions = super.exportMetaDataUpdateActions();
        // 언제나 시트에 최종 정보를 갖고 있을 수 있도록 하기 위해 업로드가 신규로 된 경우와,
        // 엔딩에 업로드된 경우로 관리시트를 분리하여 링크 저장.
        if (this.uploadedImage) {
            // 만약 방금 업로드한 이미지가 있다면, 이 링크를 저장한다.
            const link = this.uploadedImage.link;
            actions.push({
                action: __1.MetaDataAction.EndingSetImage,
                scope: (0, core_1.genScopeString)(this.block.parentChapter.customId),
                endingId: this.finalEnding.endingId,
                endingName: this.finalEnding.endingName,
                link,
                hash: link,
                // hash: `${this.block.store.rootStore.di.serverHashId}:StudioFile:${this.uploadedImage.fileId}`,
            });
        }
        else if (!!this.imageLink) {
            // 서버에 저장된 엔딩의 이미지가 있다면,
            const link = this.imageLink;
            actions.push({
                action: __1.MetaDataAction.EndingSetImage,
                scope: (0, core_1.genScopeString)(this.block.parentChapter.customId),
                endingId: this.finalEnding.endingId,
                endingName: this.finalEnding.endingName,
                link,
                hash: link,
            });
        }
        return actions;
    }
    get snapshot() {
        var _a;
        const def = super.snapshot;
        if (this.uploadedImage) {
            def.uploadedImage = (_a = this.uploadedImage) === null || _a === void 0 ? void 0 : _a.link;
        }
        return def;
    }
    serializeToBundle() {
        var _a;
        const bundle = super.serializeToBundle();
        if (this.uploadedImage) {
            bundle.extra = (_a = bundle.extra) !== null && _a !== void 0 ? _a : {};
            bundle.extra.uploadedImage = {
                link: this.uploadedImage.link,
                fileId: this.uploadedImage.fileId,
                fileName: this.uploadedImage.fileName,
                width: this.uploadedImage.width,
                height: this.uploadedImage.height,
                resName: this.uploadedImage.resName,
            };
        }
        return bundle;
    }
    onLoadedFromBundle(bundle) {
        var _a, _b;
        super.onLoadedFromBundle(bundle);
        this.setupFields();
        this.uploadedImage = (_b = (_a = bundle.extra) === null || _a === void 0 ? void 0 : _a.uploadedImage) !== null && _b !== void 0 ? _b : null;
    }
    finishMerging() {
        super.finishMerging();
        // merge 가 끝나기 전에 fixStatements 가 불리면 안되기 때문에 이렇게 수정함.
        this.setupFields();
    }
    cancelEditing() {
        const ending = this.getEnding();
        if (this.canUploadPreviewImage() && !!(ending === null || ending === void 0 ? void 0 : ending.previewImageFile)) {
            this.previewImageField.resetWithExistingFile({
                uploaded: ending.previewImageFile,
            });
        }
        super.cancelEditing();
    }
    /**
     * 외부에서 호출하기 위한 용도는 아니나, sub-statement 에서 호출해주어야 하여 public 으로 둡니다.
     */
    setupFields(forceSetup = false) {
        var _a, _b;
        if (this.didSetupFields && !forceSetup) {
            return;
        }
        this.didSetupFields = true;
        this.idInput = new ui_1.InputWithValidation('endingId', trans('legacy_DOEndingEditor_custom_ending_id'), trans('legacy_DOEndingEditor_enter_id'), 'text', this.finalEnding.endingId, null, { editable: false });
        this.isDefaultEndingField = new SelectionFieldWithUI_1.SelectionFieldWithUI('isDefaultEnding', () => this.isDefaultEnding, v => {
            this.block.parentChapter.setDefaultEndingCustomId(v ? this.endingId : null);
        }, {
            label: trans('legacy_DOEndingEditor_default_ending'),
            selection: [
                { name: 'Y', value: true },
                { name: 'N', value: false },
            ],
        });
        this.nameField = new fields_1.TextFieldWithUI('endingName', () => this.finalEnding.endingName, name => (0, mobx_1.runInAction)(() => (this.finalEnding.st.endingName = name)), {
            label: trans('legacy_DOSTEndingSummary_ending_name_id_unchangeable'),
            inputType: 'text',
            placeholder: trans('legacy_DOSTFinalEndingSubBlock_enter_ending_name'),
            options: { noLabel: false, editable: false },
        });
        this.displayNameField = new fields_1.TextFieldWithUI('displayName', () => this.finalEnding.displayName, name => (0, mobx_1.runInAction)(() => (this.finalEnding.st.displayName = name)), {
            label: trans('legacy_DOEndingEditor_ending_name'),
            inputType: 'text',
            placeholder: trans('legacy_DOSTFinalEndingSubBlock_enter_ending_name'),
            options: { noLabel: false },
        });
        this.summaryField = new fields_1.TextFieldWithUI('endingSummary', () => this.collectionDesc.message, v => (0, mobx_1.runInAction)(() => (this.collectionDesc.st.message = v)), {
            label: trans('legacy_DOEndingEditor_enter_ending_content'),
            placeholder: trans('legacy_DOSTFinalEndingSubBlock_enter_content'),
            inputType: 'textarea',
            validation: validations_1.EndingSummaryValidations.content,
            options: {
                maxLength: 174,
                noLabel: false,
            },
        });
        this.imageField = new StudioFileFieldWithUI_1.StudioFileFieldWithUI('endingImage', () => {
            var _a, _b;
            const story = this.block.parentChapter.getStory();
            const ending = story.endingStore.getByName(this.finalEnding.endingName);
            return (_b = (_a = this.uploadedImage) !== null && _a !== void 0 ? _a : ending === null || ending === void 0 ? void 0 : ending.imageFile) !== null && _b !== void 0 ? _b : null;
        }, file => (0, mobx_1.runInAction)(() => (this.uploadedImage = file)), this.helper.story, {
            options: { imageLink: (_a = this.imageLink) !== null && _a !== void 0 ? _a : undefined },
        });
        const previewImageFile = (_b = this.getEnding()) === null || _b === void 0 ? void 0 : _b.previewImageFile;
        this.previewImageField = new ui_1.FileInput('endingPortionImage', trans('legacy_DOEndingEditor_upload_ending_preview'), !!previewImageFile ? { uploaded: previewImageFile } : null, {
            showLabel: true,
        });
    }
    get isPublishedEnding() {
        return !!this.block.parentChapter.publishedAt;
    }
    //
    // Studio translator
    //
    async getMessagesToTranslate() {
        return {
            messages: [this.endingName, this.collectionDesc.message],
        };
    }
    async applyTranslatedMessages(translated) {
        const [endingName, collectionMessage] = translated.messages;
        (0, mobx_1.runInAction)(() => {
            this.finalEnding.st.endingName = endingName;
            this.collectionDesc.st.message = collectionMessage;
        });
    }
}
exports.DOSTFinalEndingSubBlock = DOSTFinalEndingSubBlock;
