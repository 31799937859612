import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLLedger } from '../../../@types/server'
import { LEDGER_AMOUNT_FILTER_FIELD } from '../../../common'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IUserLedgerListableOption } from '../UserCommon'

export function useUserLedgerListDataLoader(
  userId: number | undefined,
  amountFilter: LEDGER_AMOUNT_FILTER_FIELD | null
) {
  const { listUserLedgerByAdmin } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IUserLedgerListableOption
    ): Promise<ODListableResponseType<GQLLedger>> {
      const r = await listUserLedgerByAdmin({
        page,
        pageSize: 10,
        ...options,
        userId,
        amountFilter,
      })
      return r as ODListableResponseType<GQLLedger>
    },
    [listUserLedgerByAdmin, userId, amountFilter]
  )
}
