"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculate = exports.calculateJson = exports.setSeed = void 0;
const lodash_1 = require("lodash");
const IPDOperation_1 = require("../IPDOperation");
// tslint:disable-next-line:no-var-requires
const SeedRandom = require('seedrandom');
// setSeed 를 수행하지 않는 경우에는 찐랜덤.
let rng = new SeedRandom();
/**
 * upcId 를 이용하여 매 챕터 시작시 pseudo-random 을 결정합니다.
 * 만약 음수값을 전달받으면 time-stamp 에 의한 진짜 랜덤값으로 선택합니다.
 *
 * @param upcId
 */
function setSeed(upcId) {
    if (upcId < 0) {
        rng = new SeedRandom();
        return;
    }
    rng = new SeedRandom(upcId.toString());
}
exports.setSeed = setSeed;
function calculateJson(operation, playData) {
    // 안드로이드 \n이슈 때문에, string이지만 string으로 다시 만든다.
    const playDataString = JSON.stringify(playData).replace(/\\n/g, '');
    const operationString = JSON.stringify(operation).replace(/\\n/g, '');
    try {
        const jsonOperation = JSON.parse(JSON.parse(operationString));
        const res = calculate(jsonOperation, JSON.parse(JSON.parse(playDataString))); // string > string 두번했기에, parsing도 두번 풀어준다.
        // 앱에서 실수를 0으로 처리하고 있으므로 임의로 정수로 변경하여 반환한다.
        const value = parseInt(res, 10);
        if ((0, lodash_1.isNaN)(value)) {
            return res;
        }
        return value.toString();
    }
    catch (e) {
        // string > string 두번했기에, parsing도 두번 풀어준다.
        const res = calculate(JSON.parse(operationString), JSON.parse(JSON.parse(playDataString)));
        const value = parseInt(res, 10);
        if ((0, lodash_1.isNaN)(value)) {
            return res;
        }
        return value.toString();
    }
}
exports.calculateJson = calculateJson;
function calculate(operationOrRawValue, playData) {
    var _a;
    if ((0, lodash_1.isString)(operationOrRawValue)) {
        return operationOrRawValue;
    }
    try {
        switch (operationOrRawValue.op) {
            case IPDOperation_1.PDOperationName.Rand:
                return rand(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.GetValue:
                return getValue(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.Add:
                return add(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.Sum:
                return sum(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.Minus:
                return minus(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.Mul:
                return multiply(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.Div:
                return divide(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.Pow:
                return power(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.LT:
                return lt(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.GT:
                return gt(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.LTE:
                return lte(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.GTE:
                return gte(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.Equals:
                return equals(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.NotEquals:
                return notEquals(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.AND:
                return and(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.OR:
                return or(operationOrRawValue, playData);
            case IPDOperation_1.PDOperationName.MaxPropKey:
                return maxKey(operationOrRawValue, playData, 'props');
            case IPDOperation_1.PDOperationName.MinPropKey:
                return minKey(operationOrRawValue, playData, 'props');
            case IPDOperation_1.PDOperationName.MaxItemKey:
                return maxKey(operationOrRawValue, playData, 'items');
            case IPDOperation_1.PDOperationName.MinItemKey:
                return minKey(operationOrRawValue, playData, 'items');
            case IPDOperation_1.PDOperationName.String:
                return operationOrRawValue.args[0].toString();
            default:
                throw new Error('no arguments!');
        }
    }
    catch (ex) {
        // tslint:disable-next-line:no-console
        // console.error(ex)
        return (_a = operationOrRawValue.def) !== null && _a !== void 0 ? _a : '0';
    }
}
exports.calculate = calculate;
function rand(operation, playData) {
    const arg0 = parseFloat(calculate(operation.args[0], playData));
    const arg1 = parseFloat(calculate(operation.args[1], playData));
    const max = Math.max(arg0, arg1);
    const min = Math.min(arg0, arg1);
    const num = Math.round(rng() * (max - min)) + min;
    return num.toString();
}
function getValue(operation, playData) {
    const args = operation.args;
    let d = playData;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < args.length; i += 1) {
        const arg = args[i];
        const prop = calculate(arg, playData);
        d = d[prop];
        if (d === undefined) {
            throw new Error('failed to get prop.');
        }
    }
    return d.toString();
}
function isTruthyString(value) {
    try {
        const parsed = parseFloat(value);
        if ((0, lodash_1.isNaN)(parsed)) {
            return false;
        }
        return parsed !== 0;
    }
    catch (ex) {
        return false;
    }
}
function convertStringToNormalNumber(value) {
    try {
        const parsed = parseFloat(value);
        if ((0, lodash_1.isNaN)(parsed)) {
            return 0;
        }
        return parsed;
    }
    catch (ex) {
        return 0;
    }
}
function lt(operation, playData) {
    const arg0 = parseFloat(calculate(operation.args[0], playData));
    const arg1 = parseFloat(calculate(operation.args[1], playData));
    return arg0 < arg1 ? '1' : '0';
}
function gt(operation, playData) {
    const arg0 = parseFloat(calculate(operation.args[0], playData));
    const arg1 = parseFloat(calculate(operation.args[1], playData));
    return arg0 > arg1 ? '1' : '0';
}
function lte(operation, playData) {
    const arg0 = parseFloat(calculate(operation.args[0], playData));
    const arg1 = parseFloat(calculate(operation.args[1], playData));
    return arg0 <= arg1 ? '1' : '0';
}
function gte(operation, playData) {
    const arg0 = parseFloat(calculate(operation.args[0], playData));
    const arg1 = parseFloat(calculate(operation.args[1], playData));
    return arg0 >= arg1 ? '1' : '0';
}
function equals(operation, playData) {
    const arg0 = calculate(operation.args[0], playData);
    const arg1 = calculate(operation.args[1], playData);
    return arg0 === arg1 ? '1' : '0';
}
function notEquals(operation, playData) {
    const arg0 = calculate(operation.args[0], playData);
    const arg1 = calculate(operation.args[1], playData);
    return arg0 !== arg1 ? '1' : '0';
}
function and(operation, playData) {
    return operation.args.every(arg => isTruthyString(calculate(arg, playData)))
        ? '1'
        : '0';
}
function or(operation, playData) {
    return operation.args.some(arg => isTruthyString(calculate(arg, playData)))
        ? '1'
        : '0';
}
function sum(operation, playData) {
    return operation.args
        .reduce((acc, arg) => acc + convertStringToNormalNumber(calculate(arg, playData)), 0)
        .toString();
}
function add(operation, playData) {
    return sum(operation, playData);
}
function minus(operation, playData) {
    const arg0 = convertStringToNormalNumber(calculate(operation.args[0], playData));
    const arg1 = convertStringToNormalNumber(calculate(operation.args[1], playData));
    return (arg0 - arg1).toString();
}
function multiply(operation, playData) {
    const arg0 = convertStringToNormalNumber(calculate(operation.args[0], playData));
    const arg1 = convertStringToNormalNumber(calculate(operation.args[1], playData));
    return (arg0 * arg1).toString();
}
function divide(operation, playData) {
    const arg0 = convertStringToNormalNumber(calculate(operation.args[0], playData));
    const arg1 = convertStringToNormalNumber(calculate(operation.args[1], playData));
    return (arg0 / arg1).toString();
}
function power(operation, playData) {
    const arg0 = convertStringToNormalNumber(calculate(operation.args[0], playData));
    const arg1 = convertStringToNormalNumber(calculate(operation.args[1], playData));
    return (arg0 ** arg1).toString();
}
function maxKey(operation, playData, type) {
    const keyValue = operation.args.reduce((max, arg) => {
        const key = calculate(arg, playData);
        const value = calculate({ op: IPDOperation_1.PDOperationName.GetValue, args: [type, arg], def: '0' }, playData);
        return max.value > +value ? max : { key, value: +value };
    }, { key: '-', value: Number.MIN_SAFE_INTEGER });
    if (!keyValue.key || keyValue.key === '-') {
        throw new Error('can not get max data!');
    }
    return keyValue.key;
}
function minKey(operation, playData, type) {
    const keyValue = operation.args.reduce((min, arg) => {
        const key = calculate(arg, playData);
        const value = calculate({ op: IPDOperation_1.PDOperationName.GetValue, args: [type, arg], def: '0' }, playData);
        return min.value < +value ? min : { key, value: +value };
    }, { key: '-', value: Number.MAX_SAFE_INTEGER });
    if (!keyValue.key || keyValue.key === '-') {
        throw new Error('can not get max data!');
    }
    return keyValue.key;
}
