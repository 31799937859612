import React from 'react'
import styled from 'styled-components'
import { ODButton, ODButtonTheme } from '../../base/ODButton'
import { ODIcon, ODIcons } from '../../ODIcon'
import { ODModal, ODModalSize, ODModalTheme } from '../ODModal'
import { ODModalBody } from '../ODModalBody'
import { ODModalFooter } from '../ODModalFooter'
import { ODModalHeader } from '../ODModalHeader'

export interface IConfirmModalProps {
  title: string
  message: string
  yes?: string
  no?: string
  theme?: ODModalTheme
  size?: ODModalSize

  //
  isOpen: boolean
  onConfirm: (confirmed: boolean) => void
}

interface IConfirmModalState {}

const Message = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2f353a;
`

export class ConfirmModal extends React.Component<IConfirmModalProps, IConfirmModalState> {
  constructor(props: IConfirmModalProps) {
    super(props)

    this.state = {}
  }

  onClickYes() {
    this.props.onConfirm(true)
  }

  onClickNo() {
    this.props.onConfirm(false)
  }

  render() {
    const { title, message, yes, no, size, isOpen } = this.props

    return (
      <ODModal isOpen={isOpen} toggle={() => this.onClickNo()} size={size}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title={title} onClose={() => this.onClickNo()} headerBottomPadding={30}>
            <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 60 }} />
          </ODModalHeader>
          <ODModalBody>
            <Message>{message}</Message>
          </ODModalBody>
          <ODModalFooter>
            <ODButton style={{ width: '35%' }} theme={ODButtonTheme.Cancel} onClick={() => this.onClickNo()}>
              {no}
            </ODButton>
            <ODButton style={{ width: '65%' }} theme={ODButtonTheme.Primary} onClick={() => this.onClickYes()}>
              {yes}
            </ODButton>
          </ODModalFooter>
        </div>
      </ODModal>
    )
  }
}
