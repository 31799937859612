"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldsHolderHelper = void 0;
class FieldsHolderHelper {
    constructor(holder) {
        this.holder = holder;
    }
    get(fieldId) {
        return this.holder.editorFields.find(v => v.fieldId === fieldId);
    }
}
exports.FieldsHolderHelper = FieldsHolderHelper;
