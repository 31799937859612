import {
  BlockingLoadBox,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODWebUtils,
  useCounter,
  useODListableContext,
  useWrappedAPI,
} from '@odc/od-react-belt'
import { GQLChoiceFromPlayerClass } from '@storyplay/common'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import { Clickable } from '../../../components/base/Clickable'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { useODModalContext } from '../../../components/ODModal/context/useODModalContext'
import {
  ChoiceFromPlayerClassPrimaryKey,
  IChoiceFromPlayerClassListableOption,
} from '../ChoiceFromPlayerClassCommon'
import {
  ChoiceFromPlayerClassSelect,
  ChoiceFromPlayerClassSelectFilterType,
} from '../ChoiceFromPlayerClassSelect'
import { useChoiceFromPlayerClassListDataLoader } from './ChoiceFromPlayerClassListDataLoader'
import { INDEX } from '../../../common'
import { CreateChoiceFromPlayerClassModal } from '../CreateChoiceFromPlayerClassModal'

interface IChoiceFromPlayerClassListableTableProps {
  choiceId: number
  storyId: number
}

export const ChoiceFromPlayerClassListableTable: React.FC<
  IChoiceFromPlayerClassListableTableProps
> = (props) => {
  const { choiceId, storyId } = props
  const [loading, setLoading] = React.useState(false)
  const {
    listPlayerClass,
    updateChoiceFromPlayerClass: apiUpdateChoiceFromPlayerClass,
  } = useStoryPlayAPIs()
  const { Provider, Context } = useODListableContext<
    GQLChoiceFromPlayerClass,
    IChoiceFromPlayerClassListableOption
  >()
  const [token, refresh] = useCounter()
  const [showChoiceFromPlayerClassModal, setShowChoiceFromPlayerClassModal] =
    React.useState(false)
  const [playerClassIdFilter, setPlayerClassIdFilter] = React.useState<
    number | undefined
  >(undefined)
  const [playerClassOptions, setPlayerClassOptions] = React.useState<
    ChoiceFromPlayerClassSelectFilterType[]
  >([])
  const dataLoader = useChoiceFromPlayerClassListDataLoader(
    choiceId,
    playerClassIdFilter
  )
  const { confirm, confirmInput } = useODModalContext()
  const [redirect, setRedirect] = React.useState('')

  const updateChoiceFromPlayerClass = useWrappedAPI(
    ({
      choiceFromPlayerClassId,
      value,
    }: {
      choiceFromPlayerClassId: number
      value: number
    }) =>
      apiUpdateChoiceFromPlayerClass({
        id: choiceFromPlayerClassId,
        adminAddCount: value,
      }),
    loading,
    setLoading,
    {
      deps: [apiUpdateChoiceFromPlayerClass],
      onSuccess: () => {
        ODWebUtils.showSuccess('수정했습니다.')
        refresh()
      },
    }
  )

  React.useEffect(() => {
    listPlayerClass({ storyId, pageSize: 100 }).then((playerClass) => {
      setPlayerClassOptions(
        playerClass.list.map((p) => ({
          name: p.name,
          playerClassId: p.playerClassId,
        }))
      )
    })
  }, [listPlayerClass, storyId])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[ChoiceFromPlayerClassPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={{ width: 250, marginLeft: 5, float: 'left' }}>
            <ChoiceFromPlayerClassSelect
              value={playerClassIdFilter}
              onChange={setPlayerClassIdFilter}
              filterTypes={playerClassOptions}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
              float: 'right',
              marginLeft: 15,
            }}
          >
            <Button
              block
              color="primary"
              style={{ minWidth: 135 }}
              onClick={() => {
                setShowChoiceFromPlayerClassModal(true)
              }}
            >
              플레이어 클래스 별 선택지 통계 추가
            </Button>
          </div>
          <ODListablePaginatedTable2
            numColumns={8}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>아이디</th>
                <th>플레이어 클래스</th>
                <th>선택지</th>
                <th>사용자 선택</th>
                <th>어드민 조정 값(+/-)</th>
                <th>최종 합계(사용자+어드민)</th>
                <th>사용자 노출 확률(+어드민)</th>
                <th>실제 확률</th>
              </tr>
            )}
            renderRow={(value: GQLChoiceFromPlayerClass) => {
              const choicePropsName =
                {
                  [INDEX.ONE]: value.choice.choice1PropName,
                  [INDEX.TWO]: value.choice.choice2PropName,
                  [INDEX.THREE]: value.choice.choice3PropName,
                  [INDEX.FOUR]: value.choice.choice4PropName,
                  [INDEX.FIVE]: value.choice.choice5PropName,
                }[value.selectedChoice as INDEX] || ''
              const choiceContent =
                {
                  [INDEX.ONE]: value.choice.choice1Content,
                  [INDEX.TWO]: value.choice.choice2Content,
                  [INDEX.THREE]: value.choice.choice3Content,
                  [INDEX.FOUR]: value.choice.choice4Content,
                  [INDEX.FIVE]: value.choice.choice5Content,
                }[value.selectedChoice as INDEX] || ''
              const adminAddCountColor =
                value.adminAddCount >= 0 ? 'green' : 'red'
              return (
                <tr key={value.choiceFromPlayerClassId}>
                  <td>{value.choiceFromPlayerClassId}</td>
                  <td>{value.playerClass.name}</td>
                  <td>
                    {value.selectedChoice}번
                    {choicePropsName ? ` (${choicePropsName})` : ''}
                    {choiceContent ? ` ${choiceContent}` : ''}
                  </td>
                  <td>{value.totalCount}</td>
                  <td>
                    <Clickable
                      onClick={async () => {
                        const toValue = await confirmInput({
                          initial: value.adminAddCount,
                          inputType: 'number',
                          title: '어드민 조정 값 수정',
                          message: `어드민에서 +/- 할 값을 입력해주세요.`,
                        })

                        if (!toValue) {
                          return
                        }

                        return updateChoiceFromPlayerClass({
                          choiceFromPlayerClassId:
                            value.choiceFromPlayerClassId,
                          value: +toValue,
                        })
                      }}
                    >
                      <p style={{ color: adminAddCountColor }}>
                        {value.adminAddCount}
                      </p>
                    </Clickable>
                  </td>
                  <td>{value.totalCount + value.adminAddCount}</td>
                  <td>{value.choiceRate} %</td>
                  <td>{value.realChoiceRate} %</td>
                </tr>
              )
            }}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
          {showChoiceFromPlayerClassModal && (
            <CreateChoiceFromPlayerClassModal
              choiceId={choiceId}
              playerClassOptions={playerClassOptions}
              onClose={() => setShowChoiceFromPlayerClassModal(false)}
              onConfirm={() => {
                setShowChoiceFromPlayerClassModal(false)
                refresh()
              }}
            />
          )}
        </CardBody>
      </Card>
    </Provider>
  )
}
