import React from 'react'
import { Redirect } from 'react-router-dom'
import { TOKEN_EXPIRE_AFTER_SEC_ADMIN, TOKEN_EXPIRE_AFTER_SEC_WRITER } from '../common'
import { LOGIN_STATE, useAppContext } from '../context/AppContext'
import { SiteUrls } from '../urls'
import { USER_PRIV } from '../utils/constants'

type RequireLoginProps = {}

export const RequireLogin: React.FC<RequireLoginProps> = (props) => {
  const {
    state: { loginState, userProfile },
    tokenExpireAt,
  } = useAppContext()

  const [redirect, setRedirect] = React.useState('')
  const isWriter = (userProfile?.priv ?? 0) <= USER_PRIV.Author

  // 토큰 expire time 에 대하여 타이머를 걸고, 이 시간이 지나면 로그아웃 처리를 수행한다.
  React.useEffect(() => {
    if (tokenExpireAt) {
      const logoutAfter =
        (isWriter ? TOKEN_EXPIRE_AFTER_SEC_WRITER : TOKEN_EXPIRE_AFTER_SEC_ADMIN) * 1000 +
        tokenExpireAt -
        new Date().getTime()
      const handler = setTimeout(() => {
        sessionStorage.clear()
        setRedirect(SiteUrls.Admin.Logout)
      }, logoutAfter)
      return () => clearTimeout(handler)
    }
  }, [tokenExpireAt, isWriter])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (loginState === LOGIN_STATE.LoggedOut) {
    return <Redirect to={SiteUrls.Admin.Login} />
  }

  return <div>{props.children}</div>
}
