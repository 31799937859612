"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorageConfigProvider = exports.SHARED_CONFIG_KEYS = void 0;
const mobx_1 = require("mobx");
const store_1 = require("../store");
exports.SHARED_CONFIG_KEYS = {
    DEFAULT_MAIN_CHARACTER_NAME: 'DEFAULT_MAIN_CHARACTER_NAME'
};
class LocalStorageConfigProvider {
    constructor(localStorage) {
        this.userId = 0;
        this.data = {};
        this.localStorage = localStorage;
        (0, mobx_1.makeObservable)(this, {
            userId: mobx_1.observable,
            data: mobx_1.observable,
            storageKey: mobx_1.computed,
            isFlowChartMiniMapOn: mobx_1.computed,
        });
    }
    async init(userId) {
        try {
            (0, mobx_1.runInAction)(() => {
                this.userId = userId;
                this.data = JSON.parse(this.localStorage.getItem(this.storageKey) || '{}');
            });
        }
        catch (ex) {
            (0, mobx_1.runInAction)(() => {
                this.data = {};
            });
        }
    }
    setConfig(configKey, value) {
        (0, mobx_1.runInAction)(() => (this.data[configKey] = value));
        this.writeToStorage();
    }
    getConfig(configKey, defValue) {
        if (this.data.hasOwnProperty(configKey)) {
            return this.data[configKey];
        }
        return defValue;
    }
    get storageKey() {
        return `studio_config_${this.userId}`;
    }
    get isFlowChartMiniMapOn() {
        return this.getConfig(store_1.StudioConfigKey.FlowChartMinMapOnOff, true);
    }
    set isFlowChartMiniMapOn(v) {
        this.setConfig(store_1.StudioConfigKey.FlowChartMinMapOnOff, v);
    }
    writeToStorage() {
        this.localStorage.setItem(this.storageKey, JSON.stringify(this.data));
    }
}
exports.LocalStorageConfigProvider = LocalStorageConfigProvider;
