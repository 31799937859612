"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadRootStoreWithImportStory = void 0;
/* tslint:disable:no-console */
const core_1 = require("@storyplay/core");
const studio_1 = require("../studio");
/**
 * 번역 기능 개발 이후에 추가된 내용들에 대한 default props
 */
const defaultStoryProps = {
    bannerImageFile: null,
    bannerImageFileId: null,
};
/**
 * IImportStory 를 스튜디오 로직을 활용하여 로드하기 위하여 di mocking 작업을 수행하고 story.startEditing 을 호출한다.
 */
async function loadRootStoreWithImportStory(story) {
    const noop = () => null;
    // 꼭 필요한 것만 채워서 동작하도록 만든다.
    // 스튜디오 코드가 좀 더 이뻤다면 좋았을텐데. :(
    let hashId = 1000;
    const genHash = () => (hashId += 1000);
    const di = {
        config: { getConfig: () => null },
        showMessage: (message) => {
            console.log('----------');
            console.log(message);
            console.log('----------');
        },
        server: {
            setRootStore: noop,
            getStoryPlayType: () => core_1.GQLSTORY_PLAY_TYPE.Interactive,
            getStoryForStudio: async (storyId) => {
                var _a;
                // root store 를 로드하여 DOChapter 를 활용할 수 있는 곳까지 최대한 환경을 만들어준다.
                return {
                    ...defaultStoryProps,
                    ...story,
                    storyId,
                    allChapters: (() => {
                        // RootStore 에서는 필요로 하지만 dump 에는 없는 데이터를 임의로 넣어준다.
                        return story.allChapters.map(c => ({
                            ...c,
                            endings: c.endings.map(e => ({
                                ...e,
                                arrivalRate: 0,
                                rateLevel: 0,
                            })),
                        }));
                    })(),
                    totalNumOfChapterComments: 0,
                    genre: (_a = story.genre) !== null && _a !== void 0 ? _a : '로맨스',
                };
            },
            getChapterForEditing: async (chapterId) => {
                const c = story.allChapters.find(v => v.chapterId === chapterId);
                return {
                    ...c,
                };
            },
        },
        isFeatureEnabled: () => true,
        showError: noop,
        generateSourceLine: genHash,
        generateInternalHashId: genHash,
        renderFlowChartBlock: noop,
        localStorage: { getItem: () => null },
    };
    const rs = new studio_1.StudioRootStore(di);
    await rs.storyStore.loadStoryById(1);
    rs.startEditing(1, ex => console.error(ex));
    return rs;
}
exports.loadRootStoreWithImportStory = loadRootStoreWithImportStory;
