import { useFormikContext } from 'formik'
import React from 'react'
import { AppOptions } from '../../utils/AppOptions'

type ODEntityEditorHeaderProps<C> = {
  /**
   * appOptions.enableDevOptions = true 인 경우, 헤더를 클릭하여 랜덤 데이터를 생성하도록 할 수 있다.
   */
  populateDevData?: () => C
  title: string
}

export function ODEntityEditorHeader<C>({ title, populateDevData }: ODEntityEditorHeaderProps<C>) {
  const enableDevOptions = AppOptions.FILL_MOCK_DATA

  const { setFieldValue } = useFormikContext()

  const update = React.useCallback(() => {
    if (enableDevOptions && populateDevData) {
      const values = populateDevData!()
      Object.keys(values).forEach((key) => {
        // @ts-ignore
        setFieldValue(key, values[key])
      })
    }
  }, [populateDevData, enableDevOptions, setFieldValue])

  const style = enableDevOptions ? { cursor: 'pointer' } : {}

  return (
    <strong style={style} onClick={update}>
      {title}
    </strong>
  )
}
