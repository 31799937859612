"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextFieldWithUI = void 0;
// noinspection ES6PreferShortImport
const InputWithValidation_1 = require("../ui/InputWithValidation"); // import 변경하면 cyclic import 문제 발생
const TextField_1 = require("./TextField");
/**
 * 기존 IInputWithValidation 과의 공존을 위하여 존재한다. TextField 를 사용하도록 하되,
 * InputWithValidation 을 연동한다.
 */
class TextFieldWithUI extends TextField_1.TextField {
    constructor(fieldId, getter, setter, uiOptions = {}) {
        var _a, _b, _c, _d, _e;
        super(fieldId, getter, setter);
        this.noPropagation = false;
        this.input = new InputWithValidation_1.InputWithValidation(fieldId, (_a = uiOptions.label) !== null && _a !== void 0 ? _a : '', (_b = uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.placeholder) !== null && _b !== void 0 ? _b : '', (_c = uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.inputType) !== null && _c !== void 0 ? _c : 'text', this.defValue, (_d = uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.validation) !== null && _d !== void 0 ? _d : null, {
            ...((_e = uiOptions.options) !== null && _e !== void 0 ? _e : {}),
            onChange: value => {
                if (!this.noPropagation) {
                    this.onChange(value);
                }
            },
        });
    }
    reload() {
        // 위치를 옮겼는데 맞는 것인지 질문 필요
        super.reload();
        this.noPropagation = true;
        this.input.onChange(this.defValue);
        this.noPropagation = false;
    }
}
exports.TextFieldWithUI = TextFieldWithUI;
