"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOOnboardingPopup = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
class DOOnboardingPopup {
    constructor(data) {
        this.characterId = null;
        this.data = data;
        this.popupId = data.popupId;
        this.type = data.type;
        this.status = data.status;
        this.showOrder = data.showOrder;
        this.description = data.description;
        this.onlyName = data.onlyName;
        this.characterId = data.characterId;
        this.merge(data);
        (0, mobx_1.makeObservable)(this, {
            status: mobx_1.observable,
            showOrder: mobx_1.observable,
            description: mobx_1.observable,
            onlyName: mobx_1.observable,
            merge: mobx_1.action,
        });
    }
    merge(data) {
        const fields = [
            'type',
            'status',
            'showOrder',
            'description',
            'onlyName',
            'characterId',
        ];
        fields.forEach(name => {
            // @ts-ignore
            (0, storeUtils_1.assignIf)(data, name, v => (this[name] = v));
        });
        return this;
    }
}
exports.DOOnboardingPopup = DOOnboardingPopup;
