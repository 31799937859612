"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionPickImageBuilder = void 0;
const definition_1 = require("../../definition");
const ActionModalBase_1 = require("./ActionModalBase");
class ActionPickImageBuilder extends ActionModalBase_1.ActionModalBase {
    constructor() {
        super();
        this.title = '이미지';
        this.completeButtonText = '선택';
        this.cb = null;
        this.actionBeforeCancel = null;
    }
    setOnImagePick(cb) {
        this.cb = cb;
        return this;
    }
    setTitle(title) {
        this.title = title;
        return this;
    }
    setCompleteButtonText(text) {
        this.completeButtonText = text;
        return this;
    }
    setActionBeforeCancel(action) {
        this.actionBeforeCancel = action;
        return this;
    }
    buildAction() {
        return {
            type: definition_1.ActionType.PickImage,
            ...this.buildActionBase(),
            title: this.title,
            completeButtonText: this.completeButtonText,
            onPick: (image) => {
                var _a;
                (_a = this.cb) === null || _a === void 0 ? void 0 : _a.call(this, image);
            },
            onClose: () => {
                var _a;
                (_a = this.actionBeforeCancel) === null || _a === void 0 ? void 0 : _a.call(this);
                // this.cancelAction(new Error('User closed'))
            },
        };
    }
}
exports.ActionPickImageBuilder = ActionPickImageBuilder;
