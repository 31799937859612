"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateBase = exports.TemplateTag = void 0;
/**
 * 템플릿(부가기능)에 태그를 추가하여 원하는 기능을 쉽게 브라우징할 수 있도록 한다.
 */
var TemplateTag;
(function (TemplateTag) {
    TemplateTag["BulkEditing"] = "BulkEditing";
    TemplateTag["Image"] = "Image";
    TemplateTag["Block"] = "Block";
})(TemplateTag = exports.TemplateTag || (exports.TemplateTag = {}));
class TemplateBase {
    constructor(editor) {
        this.editor = editor;
    }
    get chapter() {
        return this.editor.chapterEditing;
    }
    get di() {
        return this.chapter.store.rootStore.di;
    }
    async applyChangeOp(op, type) {
        return null;
    }
}
exports.TemplateBase = TemplateBase;
