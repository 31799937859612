"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbGrammarAPI = void 0;
class HbGrammarAPI {
    constructor(server) {
        this.server = server;
    }
    async list() {
        return this.server.get('/v1/grammars');
    }
}
exports.HbGrammarAPI = HbGrammarAPI;
