"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColBuilder = void 0;
const IColLayout_1 = require("./IColLayout");
class ColBuilder {
    constructor(row) {
        this.data = null;
        this.rowBuilder = row;
    }
    setSingleInput(field) {
        this.data = {
            layoutType: IColLayout_1.ColType.SingleInputWithValidation,
            field,
        };
        return this;
    }
    setSingleTextInBox(field) {
        this.data = {
            layoutType: IColLayout_1.ColType.SingleTextInBox,
            field,
        };
        return this;
    }
    setSingleText(field) {
        this.data = {
            layoutType: IColLayout_1.ColType.SingleText,
            field,
        };
        return this;
    }
    setSingleIcon(field) {
        this.data = {
            layoutType: IColLayout_1.ColType.SingleIcon,
            field,
        };
        return this;
    }
    setSelectionInput(field) {
        this.data = {
            layoutType: IColLayout_1.ColType.SelectionFieldWithUI,
            field,
        };
        return this;
    }
    buildColLayout() {
        if (!this.data) {
            throw new Error(`buildColLayout() empty column.`);
        }
        return this.data;
    }
}
exports.ColBuilder = ColBuilder;
