"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOMonthlyStoryServiceEditor = void 0;
const core_1 = require("@storyplay/core");
const EntityEditor_1 = require("../../store/entity/EntityEditor");
const DateWithNullableField_1 = require("../../store/entity/fields/DateWithNullableField");
const SelectionFieldWithUI_1 = require("../../store/entity/fields/SelectionFieldWithUI");
const mobx_1 = require("mobx");
const lodash_1 = require("lodash");
const getOptionNameOfStoryIdSelection = (story) => {
    var _a;
    return `${story.storyId} : ${story.name} (${core_1.STORY_PLAY_TYPE_TO_KO_STRING[(_a = story.playType) !== null && _a !== void 0 ? _a : core_1.GQLSTORY_PLAY_TYPE.Interactive]})`;
};
class DOMonthlyStoryServiceEditor extends EntityEditor_1.EntityEditor {
    constructor(monthlyStory) {
        var _a;
        super(monthlyStory);
        this.countryCodeField = null;
        this.yyyymmField = null;
        this.tempOptionForSearchingStory = null;
        this.storyOptions = null;
        if (!!monthlyStory.storyByStoryId) {
            this.tempOptionForSearchingStory = {
                name: getOptionNameOfStoryIdSelection(monthlyStory.storyByStoryId),
                value: monthlyStory.storyByStoryId.storyId,
            };
        }
        (0, mobx_1.makeObservable)(this, {
            countryCodeField: mobx_1.observable,
        });
        this.loadCountryCode(monthlyStory).catch();
        const selectionFields = [
            {
                key: 'storyId',
                options: {
                    label: '작품 선택',
                    selection: [],
                    options: {
                        placeholder: '작품명을 검색해주세요',
                        onChangeBeforeSubmit: v => {
                            const find = this.storyIdField.input.options.find(option => option.value === v);
                            if (!!find) {
                                this.tempOptionForSearchingStory = find;
                            }
                        },
                        loadOptionsByInputValue: async (v) => {
                            try {
                                if (!v) {
                                    if (!!this.tempOptionForSearchingStory) {
                                        return [{ ...this.tempOptionForSearchingStory }];
                                    }
                                    return [];
                                }
                                const res = await this._target.rootStore.di.server.listStoryByAdmin({
                                    page: 1,
                                    pageSize: 30,
                                    filter: !!v ? v : undefined,
                                    // playType: GQLSTORY_PLAY_TYPE.Interactive, // GQLSTORY_PLAY_TYPE.EPUB,
                                });
                                this.storyOptions = res.list;
                                const list = res.list.map(item => ({
                                    name: getOptionNameOfStoryIdSelection(item),
                                    value: item.storyId,
                                }));
                                if (!!this.tempOptionForSearchingStory &&
                                    !list.find(item => { var _a; return item.value === ((_a = this.tempOptionForSearchingStory) === null || _a === void 0 ? void 0 : _a.value); })) {
                                    list.push({
                                        ...this.tempOptionForSearchingStory,
                                    });
                                }
                                return list;
                            }
                            catch (ex) {
                                this._target.rootStore.showError(ex);
                                return [];
                            }
                        },
                    },
                },
            },
        ];
        selectionFields.forEach(fld => {
            ;
            this[`${fld.key}Field`] = new SelectionFieldWithUI_1.SelectionFieldWithUI(fld.key, () => monthlyStory[fld.key], () => null, // submit 에서 처리하므로 여기서는 사용하지 않아도 된다.
            fld.options);
        });
        if (!this.isEditing) {
            (_a = this.countryCodeField) === null || _a === void 0 ? void 0 : _a.input.onChange(core_1.COUNTRY_CODE_BY_STUDIO_WEB.KR);
        }
        this.yyyymmField = new DateWithNullableField_1.DateWithNullableField('yyyymm', () => {
            var _a;
            return (_a = this._target.yyyymm) !== null && _a !== void 0 ? _a : new Date();
        }, () => null);
    }
    get rootStore() {
        return this._target.rootStore;
    }
    get isEditing() {
        return this._target.serverId !== -1;
    }
    async loadCountryCode(monthlyStory) {
        try {
            const res = await this.rootStore.di.server.getServiceCountries();
            const countryOptions = res
                .map(country => {
                return {
                    name: core_1.COUNTRY_CODE_TO_KO_STRING[country.code],
                    value: country.code,
                };
            })
                .sort((a, b) => a.name.localeCompare(b.name)) || [];
            this.countryCodeField = new SelectionFieldWithUI_1.SelectionFieldWithUI('countryCode', () => { var _a, _b; return (_b = (_a = monthlyStory.countryCode) !== null && _a !== void 0 ? _a : countryOptions[0].value) !== null && _b !== void 0 ? _b : ''; }, () => null, {
                label: '노출 국가',
                selection: countryOptions,
            });
        }
        catch (error) {
            this.rootStore.showError(error);
        }
    }
    createChangeSet() {
        const changeSet = {};
        this.editorFields.forEach(fld => {
            this.onFieldValueChangeFound(fld, fld.value, changeSet);
        });
        return (0, lodash_1.isEmpty)(changeSet) ? null : changeSet;
    }
    async submitChanges(onError) {
        var _a;
        if (!((_a = this.yyyymmField) === null || _a === void 0 ? void 0 : _a.value)) {
            this._target.rootStore.showError('등록할 월을 입력해주세요.');
            return false;
        }
        if (!this.storyIdField.value) {
            this._target.rootStore.showError('작품을 선택해주세요.');
            return false;
        }
        return super.submitChanges(onError);
    }
}
exports.DOMonthlyStoryServiceEditor = DOMonthlyStoryServiceEditor;
