import {
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODWebUtils,
  useCounter,
  useODListableContext,
} from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLStoryHasBackground } from '../../../@types/server'
import { HoverTR } from '../../../components/commonStyle'
import { SiteUrls } from '../../../urls'
import { IStoryHasBackgroundListableOption, StoryHasBackgroundPrimaryKey } from '../BackgroundCommon'
import { useStoryHasBackgroundListDataLoader } from './BackgroundListDataLoader'

interface IStoryHasBackgroundListableTableProps {
  storyId: number
}

export const BackgroundListableTable: React.FC<IStoryHasBackgroundListableTableProps> = (props) => {
  const { storyId } = props
  const { Provider, Context } = useODListableContext<GQLStoryHasBackground, IStoryHasBackgroundListableOption>()
  const [token] = useCounter()
  const dataLoader = useStoryHasBackgroundListDataLoader(storyId)
  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const toolbarStyle = { display: 'flex', marginBottom: 15 }
  const searchBoxStyle = { flexGrow: 6, maxWidth: 600 }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={(v) => v[StoryHasBackgroundPrimaryKey].toString()}
      pageSize={10}
      refreshToken={token.toString()}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 2 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <ODListableSearchBox listableContext={Context} placeholder="이름으로 검색" style={searchBoxStyle} />
          </div>
          <ODListablePaginatedTable2
            numColumns={9}
            listableContext={Context}
            renderHeader={() => (
              <tr>
                <th>배경 내부 아이디</th>
                <th>배경명</th>
                <th>이미지파일</th>
              </tr>
            )}
            renderRow={(value: GQLStoryHasBackground) => (
              <HoverTR
                key={value.shfId}
                onClick={() => setRedirect(SiteUrls.Admin.Story.EditBackground(storyId, value.shfId))}
              >
                <td>{value.shfId}</td>
                <td>{value.name}</td>
                <td>
                  {value.file?.link ? (
                    <img src={value.file!.link} width={100} height={100 * 2.165} alt="background" />
                  ) : (
                    <span>미등록</span>
                  )}
                </td>
              </HoverTR>
            )}
            eventParentContext={{}}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
