import { STORY_PROP_TYPE } from '@storyplay/common'
import { GoogleSpreadsheetRow } from 'google-spreadsheet'
import * as Josa from 'josa-js'
import { isFunction, isString, random, startsWith } from 'lodash'
import moment from 'moment'
import { ToastOptions } from 'react-toastify'
import { GQLSTORY_PROP_TYPE } from '../@types/server'
import { ODToastType, showODToast } from '../components/ODToast'
import { ServerErrors } from '../i18n/res'
import { AppOptions } from './AppOptions'

const josaPairs = [
  ['[[은/는]]', '은/는'],
  ['[[이/가]]', '이/가'],
  ['[[을/를]]', '을/를'],
  ['[[와/과]]', '와/과'],
]

export function convertJosa(msg: string): string {
  let converted = msg

  for (const pairs of josaPairs) {
    const [token, checkToken] = pairs

    do {
      const josaIndex = converted.indexOf(token)
      if (josaIndex >= 1) {
        const before = converted[josaIndex - 1]
        const josa = Josa.c(before, checkToken)
        converted = converted.replace(token, josa)
      } else {
        break
      }
    } while (true)
  }

  return converted
}

const templateRegEx = /{{([\s\S]+?)}}/g

export const SSO_PROVIDER_STRING: { [key: string]: string } = {
  GOOGLE: '구글',
  KAKAO: '카카오',
  FACEBOOK: '페이스북',
  APPLE: '애플',
}

export function parseTitleAndIdFromSheetTitle(title: string): {
  title: string
  id: string
} {
  try {
    const res = title.matchAll(templateRegEx)
    const comps = [...res]
    return {
      id: comps[1][1],
      title: comps[0][1],
    }
  } catch (ex) {
    console.error(42, ex)
    return { id: '', title: '' }
  }
}

export const getGoogleSpreadSheetData = (sheets: GoogleSpreadsheetRow[]) => {
  return sheets.map((s) => {
    return Object.keys(s).reduce((acc: string[], k, idx) => {
      if (3 <= idx && s[k] !== undefined) {
        return [...acc, s[k]]
      }
      return acc
    }, [])
  })
}

const isErrorCannotConnectToServer = (e: any) => e.message === 'Failed to fetch'
let lastServerConnectionError: number | null = null

export const Utils = {
  extractGQLResponse(r: any) {
    return r.data[Object.keys(r.data)[0]]
  },
  parseErrorMessage(error: Error) {
    const graphQLErrorPrefix = 'GraphQL error: '
    return ((e) => {
      try {
        if (isErrorCannotConnectToServer(e)) {
          if (
            lastServerConnectionError &&
            lastServerConnectionError + 2000 > new Date().getTime()
          ) {
            return null // don't show duplicated error. (using throttle with rx-js would be better)
          }
          lastServerConnectionError = new Date().getTime()
          return 'Cannot connect to server.'
        }

        if (startsWith(e.message, graphQLErrorPrefix)) {
          return e.message.substring(graphQLErrorPrefix.length)
        }

        return e.message || e
      } catch (ex) {
        return e.message || e
      }
    })(error)
  },
  /**
   * 오류의 종류에 따라서 적절하게 오류를 보여준다. 해당 컨테이너/컴포넌트에서 자체 오류 처리가 없는
   * 경우 전역적으로 활용한다.
   */
  showError(
    error: Error | string,
    title: string = '',
    options: Partial<ToastOptions> = {}
  ) {
    console.error(error)

    if (isString(error)) {
      showODToast(ODToastType.Error, title, error)
      return
    }

    let msg = (Utils.parseErrorMessage(error) || 'Unknown error') as string
    if (startsWith(msg, 'SP-')) {
      const errorCode = parseInt(msg.split(' ')[0].split('SP-')[1], 10)
      const m = ServerErrors[errorCode]
      if (isFunction(m)) {
        msg = m(msg)
      } else {
        msg = (ServerErrors[errorCode] as string) || msg
      }
    }

    if (msg) {
      showODToast(ODToastType.Error, 'Error', msg, {
        ...AppOptions.TOAST_ERROR_OPTIONS,
        ...options,
      })
    }
  },
  showInfo(
    message: string,
    title: string = '',
    options: Partial<ToastOptions> = {}
  ) {
    showODToast(ODToastType.Info, title, message, {
      ...AppOptions.TOAST_INFO_OPTIONS,
      ...options,
    })
  },
  showSuccess(
    message: string,
    title: string = '',
    options: Partial<ToastOptions> = {}
  ) {
    showODToast(ODToastType.Success, title, message, {
      ...AppOptions.TOAST_SUCCESS_OPTIONS,
      ...options,
    })
  },
  formatDate(data: any): string {
    return moment(data).format('lll')
  },
  formatMonth(data: any): string {
    return moment(data).format('YYYY-MM')
  },
  noop(...args: Array<any>) {
    // nothing.
  },
  /**
   * 주어진 JSON 데이터를 파싱하나, 실패하는 경우에도 디폴트값을 반환한다.
   */
  parseJSONSafe<T>(s: string, defValue: T): T {
    try {
      return JSON.parse(s)
    } catch (ex) {
      console.warn('Parsing json failed', s)
      return defValue
    }
  },
  colorsForDecay(days: number): string {
    if (days >= -30) {
      return 'green'
    }

    if (days < -60) {
      return 'red'
    }
    return 'gray'
  },
  urlOnly(url: string) {
    return url.split('?')[0]
  },
  formatStoryPropertyType(propType: STORY_PROP_TYPE | GQLSTORY_PROP_TYPE) {
    if ((propType as unknown as STORY_PROP_TYPE) === STORY_PROP_TYPE.NUMBER) {
      return '숫자'
    }
    return '텍스트'
  },
  convertSecToString(sec: number) {
    let remaining = Math.max(sec, 0)
    const day = Math.floor(remaining / (60 * 60 * 24))
    remaining -= day * 60 * 60 * 24
    const hour = Math.floor(remaining / (60 * 60))
    remaining -= hour * 60 * 60
    const min = Math.floor(remaining / 60)
    remaining -= min * 60

    return [
      { value: day, postfix: '일' },
      { value: hour, postfix: '시간' },
      { value: min, postfix: '분' },
      { value: remaining, postfix: '초' },
    ]
      .filter((v) => v.value > 0)
      .map((v) => `${v.value}${v.postfix}`)
      .join(' ')
  },
  generateSourceLine() {
    // TODO: 좀 더 정확하게 unique 한 방식이 필요하다. 가능하면 number 가 아니면 좋겠는데..
    return new Date().getTime() * 1000 + random(0, 999)
  },
}
