"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageFromChangeOp = void 0;
const studio_1 = require("../studio");
const StudioChangeOp_1 = require("./StudioChangeOp");
function getMessageFromChangeOp(c, op) {
    const m = [`[${op.opType}]`];
    const bid = op.blockUniqueId;
    const b = bid ? c.blockStore.getByUniqueId(bid) : null;
    if (bid) {
        if (b) {
            // m.push(`b: [${b.name}:${bid}]`)
            m.push(`b: [${b.name}]`);
        }
        else {
            m.push(`b: [NotFound:${bid}]`);
        }
    }
    const lid = op.lineUniqueId;
    if (lid) {
        if (b) {
            const l = b.statements.find(v => v.uniqueId === lid);
            if (l) {
                if (l instanceof studio_1.DOBaseScriptSubBlock) {
                    // m.push(`l: [${(l as IDOScriptSubBlock).subBlockType}:${lid}]`)
                    m.push(`l: [SB:${l.subBlockType}]`);
                }
                else {
                    // m.push(`l: [${(l as IDOScriptStatementType).statementType}:${lid}]`)
                    m.push(`l: [${l.statementType}]`);
                }
            }
            else {
                m.push(`l: [NotFound:${lid}]`);
            }
        }
    }
    switch (op.opType) {
        case StudioChangeOp_1.StudioChangeOpType.CreateBlock: {
            m.push(`블록 생성, 블록명:[${op.name}], 배경:[${op.startingBackground}]`);
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.AddLines: {
            op.lines.forEach(l => {
                m.push(`\n [index:${l.index}]에 추가.`);
                m.push(`\n  ${l.line.cmdString.split('\n').join('\n  ')}`);
            });
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.AddLinesToSubBlock: {
            op.lines.forEach(l => {
                m.push(`\n [index:${l.index}]에 추가.`);
                m.push(`\n  ${l.line.cmdString.split('\n').join('\n  ')}`);
            });
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.ChangeChoiceToBlock: {
            m.push(`[${op.choiceIndex}] 블록이동변경:[${op.blockName}]`);
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.ChangeChoiceAddChoice: {
            m.push(`[${op.choiceIndex}] 에 선택지 추가, 선택지:[${op.choiceName}], 블록:[${op.blockTo}]`);
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.ChangeChoiceText: {
            m.push(`[${op.choiceIndex}] 선택지명 변경, 선택지:[${op.choiceName}]`);
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.ChangeChoiceRemoveChoice: {
            m.push(`[${op.choiceIndex}] 선택지 삭제`);
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.ChangeChoiceChoiceIf: {
            const h = op.data;
            m.push(`[${op.choiceIndex}] 속성:[${h ? '추가/변경' : '삭제'}], 속성인덱스:[${op.choiceIfIndex}], 데이터:[${JSON.stringify(op.data)}]`);
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.RemoveConditionAtIndex: {
            m.push(`[${op.index}] 조건 삭제`);
            break;
        }
        case StudioChangeOp_1.StudioChangeOpType.ChangeField: {
            m.push(`필드[${op.field}] 값 변경 => ${op.value}`);
            break;
        }
        default:
            m.push(`No custom message.`);
            break;
    }
    return m.join(' ');
}
exports.getMessageFromChangeOp = getMessageFromChangeOp;
