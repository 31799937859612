"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nunjucks = void 0;
// 여기서 필요한 것들
//
// env => 만들어야 함.
//  N 분에 한 번 리프레시
// playerReport 에 저장된 code
// sampleData => UPS 에서 실제로 추출한 데이터
// DOPlayerReport2Editor.generateSampleHTML() => 실제 최종 결과물을 만들어 내는 부분이다.
//
//  GET Link
//    /playerReport/{upsId}?refresh=1 -> 권한 없이 public 으로 조회할 수 있도록.
//      1. 이미 생성된 데이터가 있나? => refresh=1 이면 그래도 다시 생성, 아니면 있는 데이터 html 그대로 res.text(...)
//      2. 없으면 upsId -> 생성 -> 저장 -> res.text(...)
//
// 다른 PlayerReport2 로 보고서 생성할 수 있도록 param 고려
const core_1 = require("@storyplay/core");
const nunjucks = require("nunjucks");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class Nunjucks {
    constructor(serverUrl) {
        this.renderBlocks = [];
        this.env = nunjucks.configure({ autoescape: false });
        this.serverUrl = serverUrl;
    }
    addFunction(name, code) {
        try {
            // tslint:disable-next-line:no-eval
            this.env.addGlobal(name, eval(code));
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.error(`[PlayerReport2Function] FailedAddFunction name: ${name}`, ex);
        }
    }
    addRenderBlocks(name, code) {
        const index = this.renderBlocks.findIndex(v => v.name === name);
        if (index > 0) {
            this.renderBlocks.splice(index, 1, { name, code });
        }
        else {
            this.renderBlocks.push({ name, code });
        }
    }
    generateSampleHTML(title, reportCode, playData, cssLink) {
        this.env.addGlobal('play', playData);
        return this.env.renderString(reportCode, this.getContext(playData));
    }
    getContext(play) {
        return {
            play,
            rb: (templateName, param) => {
                const renderBlock = this.getByName(templateName);
                if (!renderBlock) {
                    throw new Error(trans('legacy_DOPlayerReport2Editor_Rendable_blocks_cannot_be_found', { value: templateName }));
                }
                return this.env.renderString(renderBlock.code, { play, param });
            },
        };
    }
    getByName(name) {
        return this.renderBlocks.find(v => v.name === name);
    }
}
exports.Nunjucks = Nunjucks;
