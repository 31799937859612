"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateImageWithOption = void 0;
const core_1 = require("@storyplay/core");
const autoLayout_1 = require("../../../autoLayout");
const ActionChainBuilder_1 = require("../../../autoLayout/builder/ActionChainBuilder");
const StudioChangeOpFactory_1 = require("../../../changeOp/StudioChangeOpFactory");
const consts_1 = require("../../../consts");
const models_1 = require("../../../models");
const scripter_1 = require("../../../scripter/scripter");
const entity_1 = require("../entity");
const IMessageWithEffectStatement_1 = require("../entity/statement/script/IMessageWithEffectStatement");
const TemplateBase_1 = require("./TemplateBase");
const { trans } = (0, core_1.i18nTextTranslationByClass)();
class TemplateImageWithOption extends TemplateBase_1.TemplateBase {
    constructor(editor, lineEditing) {
        super(editor);
        this.name = trans('legacy_TemplateImageWithOption.ts_image_with_text');
        this.description = trans('legacy_TemplateImageWithOption.ts_use_text_on_illustration_image');
        this.tags = [TemplateBase_1.TemplateTag.Image];
        this.lineEditing = lineEditing;
    }
    submit(image, // 편집의 경우 없을 수 있음.
    text, option, align) {
        var _a, _b, _c, _d, _e, _f, _g;
        const colorMap = this.editor.chapterEditing.store.rootStore.messageColorMap;
        const line = this.lineEditing;
        const sourceLine = (_a = line === null || line === void 0 ? void 0 : line.sourceLine) !== null && _a !== void 0 ? _a : this.editor.generateSourceLine();
        const scripter = new scripter_1.Scripter();
        const { message, messageWithEffect } = scripter.setMessageEffect.call({ ensureScript: () => null, getEndTag: scripter.getEndTag }, text, sourceLine, colorMap);
        const data = {
            statementType: models_1.STATEMENT_TYPE.FullWidthImage,
            width: (_c = (_b = image === null || image === void 0 ? void 0 : image.width) !== null && _b !== void 0 ? _b : line === null || line === void 0 ? void 0 : line.width) !== null && _c !== void 0 ? _c : 0,
            height: (_e = (_d = image === null || image === void 0 ? void 0 : image.height) !== null && _d !== void 0 ? _d : line === null || line === void 0 ? void 0 : line.height) !== null && _e !== void 0 ? _e : 0,
            link: (_g = (_f = image === null || image === void 0 ? void 0 : image.link) !== null && _f !== void 0 ? _f : line === null || line === void 0 ? void 0 : line.link) !== null && _g !== void 0 ? _g : '',
            background: '',
            sourceLine,
            option: {
                effects: [
                    {
                        content: message,
                        contentWithEffect: messageWithEffect,
                        align,
                        operation: models_1.ImageOperationType.TextOverlay,
                        leftTop: [option.x, option.y],
                        rightTop: [option.x + option.width, option.y],
                        leftBottom: [option.x, option.y + option.height],
                        rightBottom: [option.x + option.width, option.y + option.height],
                    },
                ],
            },
        };
        const targetBlock = this.editor.lineBlockEditing;
        const op = new StudioChangeOpFactory_1.StudioChangeOpFactory(targetBlock.parentChapter);
        if (!this.editor.canAddToCurrentCursor) {
            this.chapter.store.rootStore.showError(new core_1.SPCError(core_1.ErrorCode.PositionThatCanNotAddLine));
            return;
        }
        if (!line) {
            op.addLinesToBlock(targetBlock.uniqueId, [
                {
                    line: new entity_1.DOSTFullWidthImage(data, this.editor.lineBlockEditing.doBlock),
                    index: this.editor.indexToAdd,
                },
            ]);
        }
        else {
            op.replaceData(line, data);
        }
        this.editor.chapterEditing.store.rootStore.studioTutorialStore
            .markUserStudioTutorialProgress(entity_1.GA4_EVENT_NAME.SET_TEXT_OVERLAY_IMAGE)
            .then();
        return op.submitSingle();
    }
    buildActionChain() {
        var _a;
        const line = this.lineEditing;
        const info = line === null || line === void 0 ? void 0 : line.textOverlayInfo;
        const content = (info === null || info === void 0 ? void 0 : info.contentWithEffect)
            ? (0, IMessageWithEffectStatement_1.encodeMessageWithEffect)(info === null || info === void 0 ? void 0 : info.contentWithEffect)
            : (_a = info === null || info === void 0 ? void 0 : info.content) !== null && _a !== void 0 ? _a : '';
        const defX = info ? info.leftTop[0] : 0;
        const defY = info ? info.leftTop[1] : 0;
        const defW = info ? info.rightTop[0] - info.leftTop[0] : 100;
        const defH = info ? info.leftBottom[1] - info.leftTop[1] : 100;
        const defAlign = info ? info.align : 'center';
        const imageRegionField = new entity_1.ImageRegionSelector(line ? { x: defX, y: defY, width: defW, height: defH } : undefined);
        let image = null;
        const contentField = new entity_1.InputWithValidation('content', trans('legacy_TemplateImageWithOption.ts_text'), trans('legacy_TemplateImageWithOption.ts_enter_text'), 'text', content, null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onChange: () => {
                //
            },
        });
        const xField = new entity_1.InputWithValidation('x-position', trans('legacy_ALImageWithInputArea.ts_x_coordinate'), trans('legacy_ALImageWithInputArea.ts_enter_x_coordinate'), 'text', defX.toString(), null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onBlur: () => {
                imageRegionField.updateRegionPartial({
                    x: parseInt(xField.value, 10) || 0,
                });
            },
            onSubmit: v => {
                imageRegionField.updateRegionPartial({
                    x: parseInt(v, 10) || 0,
                });
            },
        });
        xField.setEditing(true);
        const yField = new entity_1.InputWithValidation('y-position', trans('legacy_ALImageWithInputArea.ts_y_coordinate'), trans('legacy_ALImageWithInputArea.ts_enter_y_coordinate'), 'text', defY.toString(), null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onBlur: () => {
                imageRegionField.updateRegionPartial({
                    y: parseInt(yField.value, 10) || 0,
                });
            },
            onSubmit: () => {
                imageRegionField.updateRegionPartial({
                    y: parseInt(yField.value, 10) || 0,
                });
            },
        });
        yField.setEditing(true);
        const wField = new entity_1.InputWithValidation('width', trans('legacy_ALImageWithInputArea.ts_width'), trans('legacy_ALImageWithInputArea.ts_enter_width'), 'text', defW.toString(), null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onBlur: () => {
                imageRegionField.updateRegionPartial({
                    width: parseInt(wField.value, 10) || 0,
                });
            },
            onSubmit: () => {
                imageRegionField.updateRegionPartial({
                    width: parseInt(wField.value, 10) || 0,
                });
            },
        });
        wField.setEditing(true);
        const hField = new entity_1.InputWithValidation('height', trans('legacy_ALImageWithInputArea.ts_height'), trans('legacy_ALImageWithInputArea.ts_enter_height'), 'text', defH.toString(), null, {
            enableInPlaceEditing: false,
            noLabel: false,
            editable: true,
            theme: entity_1.InputTheme.Default,
            onBlur: () => {
                imageRegionField.updateRegionPartial({
                    height: parseInt(hField.value, 10) || 0,
                });
            },
            onSubmit: () => {
                imageRegionField.updateRegionPartial({
                    height: parseInt(hField.value, 10) || 0,
                });
            },
        });
        hField.setEditing(true);
        const alignField = new entity_1.SelectionInput('alignment', trans('legacy_TemplateImageWithOption.ts_alignment'), defAlign, [
            {
                value: 'center',
                name: trans('legacy_TemplateImageWithOption.ts_center_alignment'),
                description: trans('legacy_TemplateImageWithOption.ts_center_alignment'),
            },
            {
                value: 'left',
                name: trans('legacy_TemplateImageWithOption.ts_left_alignment'),
                description: trans('legacy_TemplateImageWithOption.ts_left_alignment'),
            },
            {
                value: 'right',
                name: trans('legacy_TemplateImageWithOption.ts_right_alignment'),
                description: trans('legacy_TemplateImageWithOption.ts_right_alignment'),
            },
        ], {
            creatable: false,
            placeholder: trans('legacy_TemplateImageWithOption.ts_choose_alignment_method'),
        });
        imageRegionField.addOnChangeListener(() => {
            const { x, y, width, height } = imageRegionField.regionData;
            xField.resetWithValue(x.toString());
            yField.resetWithValue(y.toString());
            wField.resetWithValue(width.toString());
            hField.resetWithValue(height.toString());
        });
        const builder = new ActionChainBuilder_1.ActionChainBuilder('TemplateImageWithOptionAction');
        // 편집시에는 이미지는 재선택하지 않는다.
        if (line) {
            imageRegionField.setSource(line.link);
        }
        else {
            builder.pickImage(b => {
                b.setOnActionStarted(() => {
                    if (!this.editor.canAddToCurrentCursor) {
                        b.cancelAction();
                        this.chapter.store.rootStore.showError(new core_1.SPCError(core_1.ErrorCode.PositionThatCanNotAddLine));
                    }
                });
                b.setTitle(trans('legacy_ALImageWithInputArea.ts_select_image'))
                    .setOnImagePick(pickedImage => {
                    image = pickedImage;
                    imageRegionField.setSource(pickedImage.link);
                    b.finishAction(pickedImage);
                })
                    .setCompleteButtonText(trans('legacy_ActionPickImageBuilder_select'));
            });
        }
        builder.addModal(modalBuilder => {
            modalBuilder.setTitle({
                layoutType: autoLayout_1.RowType.ModalTitle,
                title: trans('legacy_TemplateImageWithOption.ts_add_text_to_image'),
                imageName: consts_1.SPImageName.MenuNovel,
                guideEventName: entity_1.GA4_EVENT_NAME.SET_TEXT_OVERLAY_IMAGE,
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(imageRegionField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(contentField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(xField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(yField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(wField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(hField));
            });
            modalBuilder.addRow(rowBuilder => {
                rowBuilder.addCol(col => col.setSingleInput(alignField));
            });
            modalBuilder.setFooter({
                layoutType: autoLayout_1.RowType.ModalFooter,
                buttons: [
                    {
                        theme: autoLayout_1.ButtonTheme.General,
                        text: trans('legacy_ALCreateNewChapter_cancel'),
                        onClick: () => {
                            modalBuilder.cancelAction();
                        },
                    },
                    {
                        theme: autoLayout_1.ButtonTheme.Filled,
                        text: trans('legacy_ALCreateNewChapter_complete'),
                        onClick: () => {
                            this.submit(image, contentField.value, imageRegionField.regionData, alignField.value);
                            modalBuilder.finishAction(null);
                        },
                    },
                ],
            });
        });
        return { ...builder.buildActionChain(), editorFields: [] };
    }
}
exports.TemplateImageWithOption = TemplateImageWithOption;
