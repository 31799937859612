import { ODEntityRaw } from '@odc/od-react-belt'
import React from 'react'
import { ODButton, ODButtonSize, ODButtonTheme } from './base/ODButton'

interface IODFormToggleButtonProps {
  name: string
  keyPath: string
  value?: any
  width?: number
}

const selectTheme = (enabled: boolean) => (enabled ? ODButtonTheme.Primary : ODButtonTheme.PrimaryInvert)

export const ODFormToggleButton: React.FC<IODFormToggleButtonProps> = (props) => {
  const { name, keyPath, value: expectedValue, children, width = 140 } = props

  return (
    <ODEntityRaw
      name={name}
      keyPath={keyPath}
      render={({ value, formikContext: { setFieldValue } }) => {
        return (
          <div style={{ width }}>
            <ODButton
              type="button"
              fullWidth
              onClick={() => setFieldValue(keyPath, expectedValue === undefined ? !value : expectedValue)}
              theme={expectedValue === undefined ? selectTheme(value) : selectTheme(value === expectedValue)}
              size={ODButtonSize.Small}
            >
              {children}
            </ODButton>
          </div>
        )
      }}
    />
  )
}
