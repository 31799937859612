"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOSTBackground = void 0;
const mobx_1 = require("mobx");
const fields_1 = require("../../fields");
const studioTutorial_1 = require("../../studioTutorial");
const DOBaseScriptStatement_1 = require("../DOBaseScriptStatement");
/**
 * 실제로 시트를 통해서 사용하진 않으나, 스튜디오와 시트의 연동을 위해 미리 준비되어 있던 이 구문을 활용한다.
 */
class DOSTBackground extends DOBaseScriptStatement_1.DOBaseScriptStatement {
    constructor(data, block, uniqueId) {
        super(data, block, uniqueId);
        this.hasModalForEdit = true;
        this.isImageFileAcceptor = true;
        this.selectedFile = null;
        this.imageField = new fields_1.StudioFileField('image', () => {
            var _a, _b;
            const dbImage = this.block.parentChapter.story.storyHasBackgroundStore.getByName(this.st.image);
            if (dbImage) {
                return {
                    link: (_b = (_a = dbImage.file) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : '',
                    resName: this.st.image,
                };
            }
            return {
                link: '',
                resName: this.st.image,
            };
        }, file => {
            (0, mobx_1.runInAction)(() => {
                var _a;
                this.st.image = (_a = file === null || file === void 0 ? void 0 : file.resName) !== null && _a !== void 0 ? _a : '';
                this.selectedFile = file;
            });
        });
        (0, mobx_1.makeObservable)(this, {
            name: mobx_1.computed,
            displayName: mobx_1.computed,
        });
    }
    get st() {
        return this.data;
    }
    get name() {
        return this.st.image;
    }
    get displayName() {
        var _a;
        if (!this.st.image) {
            return this.name;
        }
        const res = this.block.parentChapter.story.studioFileStore.getByResName(this.st.image);
        return (_a = res === null || res === void 0 ? void 0 : res.displayName) !== null && _a !== void 0 ? _a : this.name;
    }
    get editorFields() {
        return [...super.editorFields, this.imageField];
    }
    generateLines() {
        // 시트에는 들어가지 않는다.
        return [];
    }
    onChangeFile(file) {
        this.rootStore.studioTutorialStore
            .markUserStudioTutorialProgress(studioTutorial_1.GA4_EVENT_NAME.SET_BACKGROUND_IMAGE)
            .then();
        return this.helper
            .opFactory()
            .changeField(this, 'image', file)
            .submitSingle();
    }
}
exports.DOSTBackground = DOSTBackground;
