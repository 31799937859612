"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoLayoutManager = void 0;
const mobx_1 = require("mobx");
class AutoLayoutManager {
    constructor(onError) {
        this.actionChainQueue = [];
        this.currentActionChain = null;
        this.currentAction = null;
        this.shouldShowScreenLoading = false;
        this.noShowModal = false;
        this.currentActionIndex = 0;
        this.loopIndex = 0;
        this.onError = onError;
        (0, mobx_1.makeObservable)(this, {
            actionChainQueue: mobx_1.observable,
            currentActionChain: mobx_1.observable,
            currentAction: mobx_1.observable,
            noShowModal: mobx_1.observable,
            shouldShowScreenLoading: mobx_1.observable,
            addActionChain: mobx_1.action,
        });
    }
    changeNoShowModal(noShowModal) {
        (0, mobx_1.runInAction)(() => {
            this.noShowModal = noShowModal;
        });
    }
    addActionChain(ac) {
        if (ac.actions.length === 0) {
            return;
        }
        this.actionChainQueue.push(ac);
        this.triggerNext(undefined);
    }
    setShowScreenLoading(show) {
        (0, mobx_1.runInAction)(() => {
            this.shouldShowScreenLoading = show;
        });
        return this;
    }
    /**
     * 만약 현재 수행 중인 액션이 없다면, 큐에서 다음 액션을 넣는다.
     */
    triggerNext(prevValue) {
        this.loopIndex += 1;
        if (this.loopIndex > 100000) {
            return;
        }
        (0, mobx_1.runInAction)(() => {
            var _a, _b, _c, _d;
            if (!this.currentActionChain && this.actionChainQueue.length > 0) {
                const ac = this.actionChainQueue.shift();
                this.currentActionChain = ac !== null && ac !== void 0 ? ac : null;
                this.currentActionIndex = 0;
                this.currentAction =
                    (_c = (_b = (_a = this.currentActionChain) === null || _a === void 0 ? void 0 : _a.actions) === null || _b === void 0 ? void 0 : _b[this.currentActionIndex]) !== null && _c !== void 0 ? _c : null;
                (_d = this.currentAction) === null || _d === void 0 ? void 0 : _d.onActionStarted(prevValue);
            }
            else if (this.currentActionChain) {
                if (this.currentActionIndex <
                    this.currentActionChain.actions.length - 1) {
                    this.currentAction = null;
                    // 기존 컴포넌트가 unmount 되지 않고 다음 모달이 mount 되는 문제를 해결하기 위해
                    // setImmediate 를 걸어서 해결
                    setImmediate(() => {
                        (0, mobx_1.runInAction)(() => {
                            var _a;
                            this.currentActionIndex += 1;
                            this.currentAction =
                                this.currentActionChain.actions[this.currentActionIndex];
                            this.triggerCurrentAction();
                            (_a = this.currentAction) === null || _a === void 0 ? void 0 : _a.onActionStarted(prevValue);
                        });
                    });
                    return;
                }
                else {
                    this.currentActionChain = null;
                    this.currentAction = null;
                    const ac = this.actionChainQueue.shift();
                    if (ac) {
                        // 기존 컴포넌트가 unmount 되지 않고 다음 모달이 mount 되는 문제를 해결하기 위해
                        // setImmediate 를 걸어서 해결
                        setImmediate(() => {
                            (0, mobx_1.runInAction)(() => {
                                var _a, _b, _c, _d;
                                this.currentActionChain = ac !== null && ac !== void 0 ? ac : null;
                                this.currentActionIndex = 0;
                                this.currentAction =
                                    (_c = (_b = (_a = this.currentActionChain) === null || _a === void 0 ? void 0 : _a.actions) === null || _b === void 0 ? void 0 : _b[this.currentActionIndex]) !== null && _c !== void 0 ? _c : null;
                                this.triggerCurrentAction();
                                (_d = this.currentAction) === null || _d === void 0 ? void 0 : _d.onActionStarted(prevValue);
                            });
                        });
                        return;
                    }
                }
            }
        });
        this.triggerCurrentAction();
    }
    triggerCurrentAction() {
        if (!this.currentAction) {
            return;
        }
        this.currentAction.resolver.then(value => {
            this.triggerNext(value);
        }, ex => {
            if (ex) {
                this.onError(ex);
                // tslint:disable-next-line:no-console
                console.error(`currentAction canceled :`, ex === null || ex === void 0 ? void 0 : ex.message);
            }
            (0, mobx_1.runInAction)(() => {
                this.currentActionChain = null;
                this.currentAction = null;
                this.triggerNext(undefined);
            });
        });
    }
}
exports.AutoLayoutManager = AutoLayoutManager;
