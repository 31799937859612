"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSLGGrammar = exports.SLGSupportStatementTypes = void 0;
/**
 * 한줄 문법 파서의 구현체입니다.
 * SLG = Single Line Grammar
 */
const core_1 = require("@storyplay/core");
const lodash_1 = require("lodash");
const errors_1 = require("../errors");
const parser_1 = require("../playData/formula/parser");
const i18n_1 = require("../studio/i18n");
const { trans } = (0, i18n_1.i18nTextTranslationByClass)();
/**
 * 다른 곳에서 사용할 수 있도록 한줄 문법이 지원하는 STATEMENT_TYPE 만 모아둔다.
 */
exports.SLGSupportStatementTypes = [
    core_1.STATEMENT_TYPE.Script,
    core_1.STATEMENT_TYPE.MainCharacterTalk,
    core_1.STATEMENT_TYPE.MainCharacterThink,
    core_1.STATEMENT_TYPE.CharacterTalk,
    core_1.STATEMENT_TYPE.CharacterThink,
    core_1.STATEMENT_TYPE.SaveProp,
    core_1.STATEMENT_TYPE.Toast,
    core_1.STATEMENT_TYPE.MessageImage,
    core_1.STATEMENT_TYPE.MainCharacterMessageImage,
    core_1.STATEMENT_TYPE.FullWidthImage,
    core_1.STATEMENT_TYPE.Vibration,
    core_1.STATEMENT_TYPE.SoundEffect,
    core_1.STATEMENT_TYPE.UpdateItem,
    core_1.STATEMENT_TYPE.FullWidthText,
];
const genDefaultState = () => ({
    background: '',
    chr: '',
    statements: [],
    lastOp: null,
});
/**
 * 들어온 문자열을 MessageWithEffect 로 파싱합니다.
 */
function decodeMessageWithEffect(messageIn) {
    const scripter = new core_1.Scripter();
    return scripter.setMessageEffect.call({ ensureScript: () => null, getEndTag: scripter.getEndTag }, messageIn, 0, {});
}
/**
 * 이미지 링크에 대한 문법을 파싱합니다.
 * 너비,높이,링크 입니다.
 *
 * 500,500,https://...
 */
function parseAndCheckImageLinkContent(content) {
    const [wStr, hStr, ...rest] = content.split(',');
    const link = rest.join(',').trim();
    const w = parseInt(wStr, 10);
    const h = parseInt(hStr, 10);
    if (!w || w <= 0) {
        throw new Error(trans('legacy_SLGParser_cannot_determine_image_width'));
    }
    if (!h || h <= 0) {
        throw new Error(trans('legacy_SLGParser_cannot_determine_image_height'));
    }
    if (!link) {
        throw new Error(trans('legacy_SLGParser_cannot_determine_image_link'));
    }
    return { w, h, link };
}
/**
 * 속성값 또는 아이템 수치 저장문법을 파싱합니다.
 *
 * 속성:속성키값=수식
 * 아이템:아이템키값=수식
 */
function parseAndCheckSavePropsMeta(content) {
    const [front, ...opComps] = content.split('=');
    const [typeStr, keyIn] = front.split(':');
    const type = typeStr === '속성' ? 'prop' : typeStr === '아이템' ? 'item' : null;
    if (!type) {
        throw new Error(trans('legacy_SLGParser_cannot_determine_property_item'));
    }
    const key = keyIn.trim();
    if (!key) {
        throw new Error(trans('legacy_SLGParser_cannot_determine_property_item_key'));
    }
    const rawPDFormula = opComps.join('=');
    let pd;
    try {
        pd = (0, parser_1.parseSPExprFormula)(rawPDFormula);
    }
    catch (ex) {
        throw new Error(trans('legacy_SLGParser_error_in_advanced_formula'));
    }
    return {
        type,
        name: key,
        pd,
    };
}
/**
 * parseSLGGrammar 에서 사용하기 위한 명령어 목록입니다.
 */
const SLGOperator = {
    SelectChr: {
        opName: '@',
        noConsecutiveOpAllowed: true,
        fn: (s, content) => {
            s.chr = content;
        },
    },
    Toast0: {
        opName: '^0',
        fn: (s, content, options) => {
            const st = {
                statementType: core_1.STATEMENT_TYPE.Toast,
                background: options.lastBackground,
                message: content,
                sourceLine: options.sourceLine,
                toastOption: 0,
            };
            s.statements.push(st);
        },
    },
    Toast1: {
        opName: '^1',
        fn: (s, content, options) => {
            const st = {
                statementType: core_1.STATEMENT_TYPE.Toast,
                background: options.lastBackground,
                message: content,
                sourceLine: options.sourceLine,
                toastOption: 1,
            };
            s.statements.push(st);
        },
    },
    Toast2: {
        opName: '^2',
        fn: (s, content, options) => {
            const st = {
                statementType: core_1.STATEMENT_TYPE.Toast,
                background: options.lastBackground,
                message: content,
                sourceLine: options.sourceLine,
                toastOption: 2,
            };
            s.statements.push(st);
        },
    },
    MainChrTalk: {
        opName: '<',
        fn: (s, content, options) => {
            const { message, messageWithEffect } = decodeMessageWithEffect(content);
            const st = {
                statementType: core_1.STATEMENT_TYPE.MainCharacterTalk,
                background: options.lastBackground,
                message,
                messageWithEffect,
                sourceLine: options.sourceLine,
                chrName: `{{성}}{{이름}}`,
            };
            s.statements.push(st);
        },
    },
    MainChrThink: {
        opName: '<?',
        fn: (s, content, options) => {
            const { message, messageWithEffect } = decodeMessageWithEffect(content);
            const st = {
                statementType: core_1.STATEMENT_TYPE.MainCharacterThink,
                background: options.lastBackground,
                message,
                messageWithEffect,
                sourceLine: options.sourceLine,
                chrName: `{{성}}{{이름}}`,
            };
            s.statements.push(st);
        },
    },
    ChrTalk: {
        opName: '>',
        fn: (s, content, options) => {
            if (!s.chr) {
                options.reportError(trans('legacy_SLGParser_no_character_selected'));
                return;
            }
            const { message, messageWithEffect } = decodeMessageWithEffect(content);
            const st = {
                statementType: core_1.STATEMENT_TYPE.CharacterTalk,
                background: options.lastBackground,
                message,
                messageWithEffect,
                sourceLine: options.sourceLine,
                chrName: s.chr,
            };
            s.statements.push(st);
        },
    },
    ChrThink: {
        opName: '>?',
        fn: (s, content, options) => {
            if (!s.chr) {
                options.reportError(trans('legacy_SLGParser_no_character_selected'));
                return;
            }
            const { message, messageWithEffect } = decodeMessageWithEffect(content);
            const st = {
                statementType: core_1.STATEMENT_TYPE.CharacterThink,
                background: options.lastBackground,
                message,
                messageWithEffect,
                sourceLine: options.sourceLine,
                chrName: s.chr,
            };
            s.statements.push(st);
        },
    },
    Script: {
        opName: '*',
        fn: (s, content, options) => {
            const { message, messageWithEffect } = decodeMessageWithEffect(content);
            const st = {
                statementType: core_1.STATEMENT_TYPE.Script,
                background: options.lastBackground,
                message,
                messageWithEffect,
                sourceLine: options.sourceLine,
            };
            s.statements.push(st);
        },
    },
    Vibrate: {
        opName: '~',
        noContentAllowed: true,
        fn: (s, content, options) => {
            const st = {
                statementType: core_1.STATEMENT_TYPE.Vibration,
                background: options.lastBackground,
                sourceLine: options.sourceLine,
                pattern: '',
            };
            s.statements.push(st);
        },
    },
    Image: {
        opName: '%',
        fn: (s, content, options) => {
            try {
                const { w, h, link } = parseAndCheckImageLinkContent(content);
                const st = {
                    statementType: core_1.STATEMENT_TYPE.FullWidthImage,
                    background: options.lastBackground,
                    width: w,
                    height: h,
                    link,
                    sourceLine: options.sourceLine,
                };
                s.statements.push(st);
            }
            catch (ex) {
                options.reportError(ex.message);
            }
        },
    },
    MainChrMessageImage: {
        opName: '<%',
        fn: (s, content, options) => {
            try {
                const { w, h, link } = parseAndCheckImageLinkContent(content);
                const st = {
                    statementType: core_1.STATEMENT_TYPE.MainCharacterMessageImage,
                    background: options.lastBackground,
                    width: w,
                    height: h,
                    link,
                    sourceLine: options.sourceLine,
                    chrName: `{{성}}{{이름}}`,
                };
                s.statements.push(st);
            }
            catch (ex) {
                options.reportError(ex.message);
            }
        },
    },
    ChrMessageImage: {
        opName: '>%',
        fn: (s, content, options) => {
            if (!s.chr) {
                options.reportError(trans('legacy_SLGParser_no_character_selected'));
                return;
            }
            try {
                const { w, h, link } = parseAndCheckImageLinkContent(content);
                const st = {
                    statementType: core_1.STATEMENT_TYPE.MessageImage,
                    background: options.lastBackground,
                    width: w,
                    height: h,
                    link,
                    sourceLine: options.sourceLine,
                    chrName: s.chr,
                };
                s.statements.push(st);
            }
            catch (ex) {
                options.reportError(ex.message);
            }
        },
    },
    SavePropsMeta: {
        opName: '#',
        fn: (s, content, options) => {
            try {
                const { type, pd, name } = parseAndCheckSavePropsMeta(content);
                if (type === 'prop') {
                    const st = {
                        statementType: core_1.STATEMENT_TYPE.SaveProp,
                        background: options.lastBackground,
                        sourceLine: options.sourceLine,
                        propUpdate: {
                            propOp: core_1.PROP_OPERATION.CALCULATE,
                            propName: name,
                            value: JSON.stringify(pd),
                        },
                    };
                    s.statements.push(st);
                }
                else {
                    const st = {
                        statementType: core_1.STATEMENT_TYPE.UpdateItem,
                        background: options.lastBackground,
                        sourceLine: options.sourceLine,
                        itemUpdate: {
                            itemOp: core_1.ITEM_OPERATION.CALCULATE,
                            itemName: name,
                            itemCount: JSON.stringify(pd),
                        },
                    };
                    s.statements.push(st);
                }
            }
            catch (ex) {
                options.reportError(ex.message);
            }
        },
    },
};
/**
 * 명령어 체크시, 명령어 길이가 긴 것부터 체크하도록 정렬합니다.
 * 예를 들어 ">?" 명령어는 ">" 명령어보다 먼저 체크되어야 합니다.
 */
const SLGOperators = Object.keys(SLGOperator).sort((op1, op2) => SLGOperator[op2].opName.length - SLGOperator[op1].opName.length);
/**
 * 스튜디오에서 사용하는 방식으로 겹치지 않는 source line 을 생성합니다.
 */
function generateSourceLineRandom() {
    return new Date().getTime() * 1000 + (0, lodash_1.random)(0, 999);
}
/**
 * 한줄 문법으로 이루어진 문장을 파싱하고 결과를 반환합니다.
 *
 * onError 를 통하여 상세한 오류의 line number 와 내용을 파악할 수 있습니다.
 */
function parseSLGGrammar(inputString, options) {
    const { onError, stateIn, generateSourceLine = generateSourceLineRandom, } = options;
    const state = { ...genDefaultState(), ...(stateIn !== null && stateIn !== void 0 ? stateIn : {}) };
    const lines = inputString.split('\n').map(s => s.trim());
    const reportError = (lineIndex, message) => {
        onError === null || onError === void 0 ? void 0 : onError(new errors_1.SPCError(errors_1.ErrorCode.SLGParserError, { lineIndex, message }));
    };
    for (const [index, line] of lines.entries()) {
        if (line.length === 0) {
            continue;
        }
        let isHandled = false;
        for (const opKey of SLGOperators) {
            const { opName, fn, noContentAllowed = false, noConsecutiveOpAllowed = false, } = SLGOperator[opKey];
            if (!line.startsWith(opName)) {
                continue;
            }
            const content = line.substring(opName.length).trim();
            if (content.length === 0 && !noContentAllowed) {
                reportError(index, trans('legacy_SLGParser_There_is_no_content'));
                continue;
            }
            fn(state, content, {
                reportError: (message) => reportError(index, message),
                generateSourceLine,
                sourceLine: generateSourceLine(),
                lastBackground: state.background,
            });
            state.lastOp = noConsecutiveOpAllowed ? null : opKey;
            isHandled = true;
            break;
        }
        if (!isHandled) {
            if (line.length > 0 && state.lastOp) {
                const { fn } = SLGOperator[state.lastOp];
                fn(state, line.trim(), {
                    reportError: (message) => reportError(index, message),
                    generateSourceLine,
                    sourceLine: generateSourceLine(),
                    lastBackground: state.background,
                });
                continue;
            }
            reportError(index, trans('legacy_SLGParser_Cannot_interpret_sentence'));
        }
    }
    return state;
}
exports.parseSLGGrammar = parseSLGGrammar;
