"use strict";
/**
 * HbGNBMenuManager가 주로 쓰게 될 헬로봇 스튜디오용의 url에 대한 처리를 담당한다.
 * 여기에서 정의하는 주소는 기존의 studioUrls와 중복이 없어야 한다.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbStudioUrls = void 0;
const createQueryParams_1 = require("./createQueryParams");
// 대소문자는 기존의 StudioUrls를 따라간다.
exports.HbStudioUrls = {
    Bot: {
        Detail: {
            Root: (botId) => `/bot/${botId}`,
            ManageStory: {
                Main: (botId) => `/bot/${botId}/detail`,
                Edit: (botId) => `/bot/${botId}/detail/edit`,
            },
            Skill: {
                List: (botId) => `/bot/${botId}/skill`,
                Create: (botId) => `/bot/${botId}/skill/create`,
                Edit: (botId, skillId) => `/bot/${botId}/skill/edit/${skillId}`,
            },
            AlgorithmBlockGroup: {
                List: (botId) => `/bot/${botId}/algorithm`,
                Edit: (botId, algorithmBlockGroupId, blockPageIndex, blockPageSize) => {
                    const query = (0, createQueryParams_1.createQueryParams)({
                        blockPageIndex,
                        blockPageSize,
                    });
                    return `/bot/${botId}/algorithm/${algorithmBlockGroupId}${query ? `?${query}` : ''}`;
                },
            },
            BlockGroup: {
                List: (botId) => `/bot/${botId}/chapter`,
                // 인터랙티브 편집
                Edit: (botId, blockGroupId, blockId, version, // chapterDraftArchiveId
                showBlockEditor) => {
                    const query = (0, createQueryParams_1.createQueryParams)({
                        blockId,
                        version,
                        showBlockEditor,
                    });
                    return `/bot/${botId}/chapter/${blockGroupId}${query ? `?${query}` : ''}`;
                },
                WebNovelEdit: (botId, blockGroupId, version // chapterDraftArchiveId
                ) => {
                    const query = (0, createQueryParams_1.createQueryParams)({
                        version,
                    });
                    return `/bot/${botId}/chapter/novel/${blockGroupId}${query ? `?${query}` : ''}`;
                },
            },
        },
    },
};
