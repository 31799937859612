"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioMenuElementThemeToClassName = exports.StudioMenuElementTheme = exports.StudioMenuType = void 0;
var StudioMenuType;
(function (StudioMenuType) {
    StudioMenuType["TitleWithIcon"] = "TitleWithIcon";
    StudioMenuType["TextWithIcon"] = "TextWithIcon";
    StudioMenuType["TextWithSelectIcon"] = "TextWithSelectIcon";
    StudioMenuType["Separator"] = "Separator";
    StudioMenuType["Spacer"] = "Spacer";
    StudioMenuType["Category"] = "Category";
    StudioMenuType["LinkWithIcon"] = "LinkWithIcon";
})(StudioMenuType = exports.StudioMenuType || (exports.StudioMenuType = {}));
// Menu 에 렌더되는 텍스트 또는 이미지의 스타일을 해당 enum 으로 관리합니다.
// 아마 대부분의 경우 컬러 속성을 결정하기 위해 Theme 을 설정할 것으로 예상합니다.
// ElementTheme 을 내려주지 않을 경우는 ODSMenuItem 에 있는 className 으로 적용됩니다.
var StudioMenuElementTheme;
(function (StudioMenuElementTheme) {
    StudioMenuElementTheme["TextPrimary500"] = "Primary500";
    StudioMenuElementTheme["TextGray500"] = "Gray500";
    StudioMenuElementTheme["TextLgBoldGray900"] = "TextLgBoldGray900";
    StudioMenuElementTheme["TextBaseGray800"] = "TextBaseGray800";
    StudioMenuElementTheme["TextBaseSubRed500"] = "TextBaseSubRed500";
    StudioMenuElementTheme["TextBaseGray400"] = "TextBaseGray400";
    StudioMenuElementTheme["TextSmGray600"] = "TextSmGray600";
    StudioMenuElementTheme["TextSmGray700"] = "TextSmGray700";
    StudioMenuElementTheme["TextBaseGray700"] = "TextBaseGray700";
    StudioMenuElementTheme["IcnW6H6Gray700"] = "IcnW6H6Gray700";
})(StudioMenuElementTheme = exports.StudioMenuElementTheme || (exports.StudioMenuElementTheme = {}));
exports.StudioMenuElementThemeToClassName = {
    [StudioMenuElementTheme.TextPrimary500]: 'text-primary-500',
    [StudioMenuElementTheme.TextGray500]: 'text-gray-500',
    [StudioMenuElementTheme.TextLgBoldGray900]: 'text-gray-900 font-bold text-lg',
    [StudioMenuElementTheme.TextBaseGray800]: 'text-gray-800 text-base',
    [StudioMenuElementTheme.TextBaseSubRed500]: 'text-sub-red-500 text-base',
    [StudioMenuElementTheme.TextBaseGray400]: 'as:text-gray-400 text-base',
    [StudioMenuElementTheme.TextSmGray600]: 'text-gray-600 text-sm',
    [StudioMenuElementTheme.TextSmGray700]: 'text-gray-700 text-sm',
    [StudioMenuElementTheme.TextBaseGray700]: 'text-gray-700 text-base',
    [StudioMenuElementTheme.IcnW6H6Gray700]: 'w-7 h-7 text-gray-700',
};
