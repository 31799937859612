import { ODModal, ODModalBody, ODModalHeader, ODModalSize, ODVSpace } from '@odc/od-react-belt'
import React, { FC, FormEvent } from 'react'
import styled from 'styled-components'

interface IOTPInputModal {
  isOpen: boolean
  onClose: () => void
  onConfirm: (token: string) => void
}

export const OTPInputModal: FC<IOTPInputModal> = (props) => {
  const { isOpen, onClose, onConfirm } = props
  const [otpToken, setOtpToken] = React.useState('')

  const handleConfirm = () => {
    onConfirm(otpToken)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
  }

  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Large}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={'OTP 로그인'} titleColor={'#00c5c3'} />
        <ODModalBody>
          <Message>
            <SubTitle>안전한 로그인을 위해 OTP 인증번호를 입력해주세요.</SubTitle>
            <ODVSpace h={40} />
            <DescText>1. OTP 앱에서 생성된 6자리 인증번호를 입력하고 인증 확인 버튼을 눌러주세요.</DescText>
            <ODVSpace h={20} />
            <LeftPaddingBox style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
              <form style={{ width: '100%', textAlign: 'left' }} onSubmit={handleSubmit}>
                <label htmlFor="authenticationNumber" style={{ textAlign: 'left' }}>
                  인증번호
                </label>
                <Input
                  id="authenticationNumber"
                  value={otpToken}
                  onChange={(e) => {
                    if (e.target.value.length > 7) {
                      return
                    }
                    setOtpToken(e.target.value)
                  }}
                />
                <RegisterButton onClick={handleConfirm}>인증 확인</RegisterButton>
              </form>
            </LeftPaddingBox>
          </Message>
        </ODModalBody>
      </div>
    </ODModal>
  )
}

const Message = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2f353a;
`

const SubTitle = styled.h4`
  font-weight: bold;
`

const DescText = styled.div`
  font-size: 21px;
`

const RegisterButton = styled.button`
  background-color: #00c5c3;
  border: 0;
  width: 100%;
  height: 57px;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
  line-height: 57px;
`

const LeftPaddingBox = styled.div`
  padding-left: 60px;
`

const Input = styled.input`
  text-align: center;
  font-size: 48px;
`
