"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryGameFileFieldWithUI = void 0;
const core_1 = require("@storyplay/core");
const ui_1 = require("../ui");
const StudioFileField_1 = require("./StudioFileField");
class StoryGameFileFieldWithUI extends StudioFileField_1.StudioFileField {
    constructor(fieldId, getter, setter, story, uiOptions = {}) {
        var _a;
        super(fieldId, getter, setter);
        this.story = story;
        this.onUploaded = uiOptions.onUploaded;
        this.input = new ui_1.FileInput(fieldId, (_a = uiOptions.label) !== null && _a !== void 0 ? _a : '', this.defValue, {
            ...uiOptions.options,
            onChange: async (file) => {
                var _a;
                if (!!story.storyId && !!file) {
                    try {
                        const fileType = file.type.split('/')[0] === 'video'
                            ? core_1.GQLSTORYGAME_PREVIEW_RESOURCE_TYPE.Video
                            : core_1.GQLSTORYGAME_PREVIEW_RESOURCE_TYPE.Image;
                        const res = await this.story.rootStore.di.server.createStoryPreviewForStudio({
                            storyId: story.storyId,
                            fileType,
                            file,
                        });
                        (_a = this.onUploaded) === null || _a === void 0 ? void 0 : _a.call(this, res.file, res.storyPreviewId, fileType);
                    }
                    catch (ex) {
                        story.rootStore.di.showError(ex);
                    }
                }
            },
        });
    }
}
exports.StoryGameFileFieldWithUI = StoryGameFileFieldWithUI;
