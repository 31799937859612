"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageRegionSelector = void 0;
const mobx_1 = require("mobx");
const storeUtils_1 = require("../../utils/storeUtils");
const IInputBase_1 = require("./IInputBase");
function isDifferentRegion(d1, d2) {
    return (d1.x !== d2.x ||
        d1.y !== d2.y ||
        d1.width !== d2.width ||
        d1.height !== d2.height);
}
class ImageRegionSelector {
    constructor(initialRegionData) {
        this.inputModelType = IInputBase_1.InputModelType.ImageRegionSelector;
        this.src = null;
        this.onChangeListeners = [];
        this.regionData = initialRegionData !== null && initialRegionData !== void 0 ? initialRegionData : {
            x: 0,
            y: 0,
            width: 100,
            height: 100,
        };
        (0, mobx_1.makeObservable)(this, {
            regionData: mobx_1.observable,
        });
    }
    addOnChangeListener(listener) {
        this.onChangeListeners.push(listener);
        return () => {
            this.onChangeListeners.splice(this.onChangeListeners.findIndex(listener), 1);
        };
    }
    onRegionChanged(data, propagate = true) {
        this.sanitizeImageRegionData(data);
        // 데이터가 recursive 하게 변경되는 문제를 막기 위해
        if (isDifferentRegion(data, this.regionData)) {
            (0, mobx_1.runInAction)(() => {
                this.regionData = { ...data };
            });
            if (propagate) {
                this.onRegionUpdated();
            }
        }
    }
    updateRegionPartial(data) {
        const newData = this.sanitizeImageRegionData({
            ...this.regionData,
            ...data,
        });
        // @ts-ignore
        this.onRegionChanged(newData, false);
    }
    /**
     * 입력으로 오는 region data 를 정리하여 저장한다. in-place edit
     */
    sanitizeImageRegionData(data) {
        // @ts-ignore
        Object.keys(data).forEach(k => (data[k] = (0, storeUtils_1.roundImageOffset)(data[k])));
        return data;
    }
    onRegionUpdated() {
        this.onChangeListeners.forEach(f => f());
    }
    setSource(src) {
        (0, mobx_1.runInAction)(() => {
            this.src = src;
        });
    }
}
exports.ImageRegionSelector = ImageRegionSelector;
