"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputModelType = void 0;
var InputModelType;
(function (InputModelType) {
    InputModelType["InputWithValidation"] = "InputWithValidation";
    InputModelType["ImageRegionSelector"] = "ImageRegionSelector";
    InputModelType["MultiSelectionInput"] = "MultiSelectionInput";
    InputModelType["ColorPicker"] = "ColorPicker";
    InputModelType["FileInput"] = "FileInput";
    InputModelType["SelectionInput"] = "SelectionInput";
    InputModelType["SelectionFieldWithUI"] = "SelectionFieldWithUI";
})(InputModelType = exports.InputModelType || (exports.InputModelType = {}));
