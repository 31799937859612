"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowChartPositionCalculator = void 0;
const react_flow_renderer_1 = require("../../../../@types/react-flow-renderer");
const DEBUG_LOG = false;
// tslint:disable-next-line:no-console
const log = (...args) => DEBUG_LOG && console.log(...args);
function gb(node) {
    return node.uniqueId;
}
class FlowChartPositionCalculator {
    // 플로우차트 이르 변경
    constructor(flowChart, saved, renderFlowChartBlock) {
        this.nodeMap = {};
        this.flowChart = flowChart;
        this.saved = saved;
        this.renderFlowChartBlock = renderFlowChartBlock;
    }
    getNodeBy(nodeName) {
        const nodeInMap = this.nodeMap[nodeName];
        if (!!nodeInMap) {
            return nodeInMap;
        }
        return this.flowChart.allNode.find(node => node.nodeName === nodeName);
    }
    /**
     * 특정 블록 위치에 대한 고정값 (예: 사용자가 지정한 블록 위치)을 반환한다.
     */
    getFixedNodePosition(node) {
        var _a;
        return (_a = this.saved.blockPosition[gb(node)]) !== null && _a !== void 0 ? _a : null;
    }
    /**
     * 특정 블록 위치에 대한 고정값 (예: 사용자가 지정한 블록 위치)을 설정한다.
     */
    setFixedNodePosition(node, pos) {
        if (!pos) {
            delete this.saved.blockPosition[gb(node)];
        }
        else {
            this.saved.blockPosition[gb(node)] = pos;
        }
    }
    setFlowChartNodePositions() {
        for (const node of this.flowChart.allNode) {
            this.nodeMap[node.nodeName] = node;
        }
        const saved = this.saved.blockPosition;
        const nodeIdsIncluded = new Set();
        const nodePosDetermined = {};
        const flowElements = [];
        const undeterminedNodes = [];
        const edgeIdTaken = {};
        const setNodeWithPosition = (node, pos) => {
            nodePosDetermined[gb(node)] = { node, ...pos };
            flowElements.push({
                id: node.nodeName,
                data: {
                    label: this.renderFlowChartBlock(node.uniqueId),
                    nodeName: node.nodeName,
                },
                position: pos,
            });
            const index = undeterminedNodes.findIndex(v => v.uniqueId === node.uniqueId);
            if (index >= 0) {
                undeterminedNodes.splice(index, 1);
            }
            log(`[${node.nodeName}] (x: ${pos.x}, y: ${pos.y})`);
            saved[gb(node)] = pos;
        };
        const getEdgeId = (nodeFrom, nodeTo) => {
            var _a;
            const key = `__edge__${gb(nodeFrom)}=>${gb(nodeTo)}`;
            const count = (_a = edgeIdTaken[key]) !== null && _a !== void 0 ? _a : 0;
            edgeIdTaken[key] = (count !== null && count !== void 0 ? count : 0) + 1;
            return `${key}_${count}`;
        };
        /**
         * 저장된 위치값이 있는 경우 이를 설정한다.
         * 모든 edge 를 먼저 넣어준다.
         */
        const visitNodeFirstCycle = (nodeName, isStartingNode = false) => {
            const nodeFound = this.getNodeBy(nodeName);
            if (!nodeFound) {
                return;
            }
            // 블록을 방문한 적이 있으면 종료한다.
            if (nodeIdsIncluded.has(gb(nodeFound))) {
                return;
            }
            // 블록방문기록을 추가한다.
            nodeIdsIncluded.add(gb(nodeFound));
            // 이 블록의 저장된 위치값이 있는지 확인한다.
            const pos = saved[gb(nodeFound)];
            if (pos) {
                setNodeWithPosition(nodeFound, pos);
            }
            else {
                // 시작블록은 저장된 값이 없는 경우 0, 0 으로 고정한다.
                if (isStartingNode) {
                    setNodeWithPosition(nodeFound, { x: 0, y: 0 });
                }
                else {
                    // 이 블록으로 연결되는 블록을 찾아, 결정된 위치가 있는지 본다.
                    // 위치가 결정되지 않았다면, 다시 계산하기 위한 큐에 넣는다.
                    undeterminedNodes.push(nodeFound);
                }
            }
            // 이 블록에서 연결되는 모든 블록을 찾는다.
            const nodesConnected = nodeFound.nodesTo;
            nodesConnected.forEach((nodeTo, index) => {
                var _a, _b;
                // edge 를 연결하고,
                flowElements.push({
                    id: getEdgeId(nodeFound, {
                        uniqueId: (_b = (_a = this.getNodeBy(nodeTo.nodeName)) === null || _a === void 0 ? void 0 : _a.uniqueId) !== null && _b !== void 0 ? _b : nodeTo.nodeName,
                    }),
                    source: nodeFound.nodeName,
                    target: nodeTo.nodeName,
                    arrowHeadType: react_flow_renderer_1.ArrowHeadType.ArrowClosed,
                    label: nodeTo.label,
                    animated: false,
                    // edge를 커스텀하게 표시하기 위해 임시적으로 추가
                    type: 'custom',
                    data: { nodeFrom: nodeFound, nodeTo },
                });
                // 연결된 block 을 visit 하도록 한다.
                visitNodeFirstCycle(nodeTo.nodeName);
            });
        };
        try {
            const startingNode = this.getNodeBy(this.flowChart.startingNodeName);
            if (!startingNode) {
                return [];
            }
            // starting block 부터 연결된 블록들을 차트에 추가한다.
            visitNodeFirstCycle(startingNode.nodeName, true);
            // 방문한 적이 없는 블록을 찾아서 추가한다.
            this.flowChart.allNode.forEach(node => {
                if (!nodeIdsIncluded.has(gb(node))) {
                    visitNodeFirstCycle(node.nodeName);
                }
            });
        }
        catch (ex) {
            // tslint:disable-next-line:no-console
            console.error(`Error generating chart!`, ex);
            return [];
        }
        // x 값을 좌우로 늘려나가면서 블록과 overlap 되지 않는 위치를 찾는다.
        const OVERLAP_WIDTH = 300;
        const OVERLAP_HEIGHT = 100;
        const Y_OFFSET_FOR_CONNECTION = 200;
        const FIND_X_OFFSET = 50;
        const Y_POS_OF_NEW_CLUSTER = 0;
        // noinspection PointlessArithmeticExpressionJS
        const X_POS_OF_NEW_CLUSTER = 0 + OVERLAP_WIDTH;
        const hasOverlap = (pos) => {
            const { x, y } = pos;
            return !!Object.values(nodePosDetermined).find(e => {
                const isOverlayY = (e.y >= y && e.y <= y + OVERLAP_HEIGHT) ||
                    (y >= e.y && y <= e.y + OVERLAP_HEIGHT);
                const isOverlapX = (e.x >= x && e.x <= x + OVERLAP_WIDTH) ||
                    (x >= e.x && x <= e.x + OVERLAP_WIDTH);
                // console.log(156, isOverlapX, isOverlayY, { ...pos, ey: e.y, ex: e.x })
                return isOverlapX && isOverlayY;
            });
        };
        const findPositionNear = (pos, increaseOnly = false) => {
            const { x, y } = pos;
            const r = hasOverlap(pos);
            if (!r) {
                return { x, y };
            }
            for (let i = 1; i < 1000; i += 1) {
                const newPos = { x: pos.x + i * FIND_X_OFFSET, y: pos.y };
                if (!hasOverlap(newPos)) {
                    return newPos;
                }
                const newPos2 = { x: pos.x - i * FIND_X_OFFSET, y: pos.y };
                if (!increaseOnly && !hasOverlap(newPos2)) {
                    return newPos2;
                }
            }
            // failed.
            return { x, y };
        };
        // Cycle 2
        // undeterminedBlocks 을 돌면서, blocksFrom 중에 위치가 결정된 것이 있다면,
        // 해당 블록에서 +y offset 한 곳에서부터 -/+ 하면서 겹치는 데이터가 없는 곳에 넣는다.
        const runCycle2 = () => {
            let hasChange = false; // 이번 싸이클에서 변경된 것이 있는가?
            const orderedNodes = undeterminedNodes.sort((a, d) => a.shouldComeBeforeOnFlowChart(d) ? 1 : -1);
            for (const node of orderedNodes) {
                const nodesFrom = node.nodeNamesFrom;
                log(`runCycle2() [${node.nodeName}] from [${nodesFrom
                    .map(b => b)
                    .join(',')}]`);
                let maxYPos = -Infinity;
                let xPos = 0;
                const fromNodesOrdered = nodesFrom
                    .map(item => this.getNodeBy(item))
                    .filter(item => !!item)
                    .sort((a, d) => {
                    return a.shouldComeBeforeOnFlowChart(d) ? 1 : -1;
                });
                for (const fromNode of fromNodesOrdered) {
                    const ret = nodePosDetermined[gb(fromNode)];
                    if (!ret) {
                        continue;
                    }
                    if (ret.y >= maxYPos) {
                        maxYPos = ret.y;
                        xPos = ret.x;
                        log(`runCycle2() [${node.nodeName}] determined block => [${fromNode} (${ret.x}, ${ret.y})]`);
                    }
                }
                if (maxYPos !== -Infinity) {
                    const finalPos = findPositionNear({
                        x: xPos,
                        y: maxYPos + Y_OFFSET_FOR_CONNECTION,
                    });
                    log(`runCycle2() [${node.nodeName}] finding pos near : (${xPos}, ${maxYPos + Y_OFFSET_FOR_CONNECTION}) => (${finalPos.x}, ${finalPos.y})`);
                    setNodeWithPosition(node, finalPos);
                    hasChange = true;
                }
            }
            return hasChange;
        };
        while (undeterminedNodes.length > 0) {
            const hasChange = runCycle2();
            if (!hasChange) {
                const node = undeterminedNodes[0];
                const pos = findPositionNear({ x: X_POS_OF_NEW_CLUSTER, y: Y_POS_OF_NEW_CLUSTER }, true);
                setNodeWithPosition(node, pos);
            }
        }
        return flowElements;
    }
}
exports.FlowChartPositionCalculator = FlowChartPositionCalculator;
