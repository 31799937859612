import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLChr } from '../../../@types/server'
import { useStoryPlayAPIs } from '../../../hooks/useStoryPlayAPIs'
import { IChrListableOption } from '../ChrCommon'

export function useChrListDataLoader(storyId: number, mainFilter: boolean | null) {
  const { listChrOfStory } = useStoryPlayAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IChrListableOption
    ): Promise<ODListableResponseType<GQLChr>> {
      const r = await listChrOfStory({
        page,
        pageSize: 100,
        storyId,
        mainFilter,
        ...options,
      })
      return r as ODListableResponseType<GQLChr>
    },
    [listChrOfStory, storyId, mainFilter]
  )
}
