"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GNBModalManager = void 0;
const mobx_1 = require("mobx");
class GNBModalManager {
    constructor(gnbStore) {
        this.isOpenModal = null;
        this.gnbStore = gnbStore;
        (0, mobx_1.makeObservable)(this, {
            isOpenModal: mobx_1.observable,
            openModal: mobx_1.action,
            closeModal: mobx_1.action,
        });
    }
    openModal(open) {
        this.isOpenModal = open;
    }
    closeModal() {
        this.isOpenModal = null;
    }
}
exports.GNBModalManager = GNBModalManager;
