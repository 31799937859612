"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOStoryPriceStore = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const DOStoryPrice_1 = require("./DOStoryPrice");
class DOStoryPriceStore {
    constructor(rootStore) {
        this.isLoading = false;
        this.rootStore = rootStore;
        this.data = {};
        (0, mobx_1.makeObservable)(this, {
            data: mobx_1.observable,
            isLoading: mobx_1.observable,
            loadStoryPrice: mobx_1.action,
            merge: mobx_1.action,
        });
    }
    getById(id, countryCode) {
        return (this.data[id] &&
            this.data[id][countryCode || core_1.COUNTRY_CODE_BY_STUDIO_WEB.KR]);
    }
    merge(data) {
        const id = data.storyId;
        const countryCode = data.countryCode || core_1.COUNTRY_CODE_BY_STUDIO_WEB.KR;
        if (this.data[id] && this.data[id][countryCode]) {
            ;
            this.data[id][countryCode].merge(data);
        }
        else {
            this.data[id] = {};
            this.data[id][countryCode] = new DOStoryPrice_1.DOStoryPrice(this, data);
        }
        return this.data[id][countryCode];
    }
    /**
     * 작품의 가격 정보 데이터를 로드한다.
     */
    async loadStoryPrice(storyId, countryCode) {
        const di = this.rootStore.di;
        const res = await di.server.getStoryPriceSetting(storyId, countryCode);
        this.merge(res);
    }
}
exports.DOStoryPriceStore = DOStoryPriceStore;
